import React, { Component } from "react";
import { Auth } from "aws-amplify";

import Button from "@material-ui/core/Button";
import Utils from "./utils";
import { CSVReader } from "react-papaparse";

class App extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  handleReadCSV = data => {
    console.log(data);
    this.props.done(data);
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleImportOffer = () => {
    this.fileInput.current.click();
  };

  render() {
    return (
      <div>
        {this.props.title}
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{ display: "none" }}
          onError={this.handleOnError}
          configOptions={{ header: true }}
        />
        <button onClick={this.handleImportOffer}>Import</button>
      </div>
    );
  }
}

class Uploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async thing1() {
    console.log("starting put schedules into responses");
    var schedules = this.state.schedules;
    var responses = this.state.responses;
    var usrs = await Utils.getAllUsers({ name: { contains: "~" } });

    schedules.forEach(a => {
      // console.log("~"+a.Promoter)
      usrs.forEach(b => {
        // console.log(b.name)
        if ("~" + a.Promoter === b.name) a.promoterid = b.id;
      });
    });

    responses.forEach(a => {
      a.responserating = a["Rating"];
      schedules.forEach(b => {
        if (b["Promotion Identifier"] === a["Promotion Identifier"]) {
          var kys = Object.keys(b);
          kys.forEach(q => {
            a[q] = b[q];
          });
        }
      });
    });

    console.log(responses);
    var prds = [];
    responses.forEach(a => {
      var found = false;
      prds.forEach(b => {
        if (b.productline === a["Product Line"] && b.product === a.Product)
          found = true;
      });
      if (!found)
        if (
          typeof a.Product !== "undefined" &&
          typeof a["Product Line"] !== "undefined"
        )
          prds.push({ productline: a["Product Line"], product: a.Product });
    });

    console.log(prds);
    this.setState({ responses: responses, prds: prds });
    console.log("end put schedules into responses");
  }

  async thing2() {
    console.log("starting match products");
    var products = await Utils.getAllProductLines();
    var prods = this.state.prds;
    prods.forEach(a => {
      products.forEach(b => {
        if (b.products.indexOf(a.product.trim()) > -1) a.productlineid = b.id;
      });
      if (a.product === "Caramel Chocolate Pudding")
        a.productlineid = "05e02bdf-b924-433c-9469-48e9ed784d13";
      if (a.product === "Other")
        a.productlineid = "05e02bdf-b924-433c-9469-48e9ed784d13";
      if (a.product === "The Menu - Bread&Butter Pudding")
        a.productlineid = "05e02bdf-b924-433c-9469-48e9ed784d13";
      if (a.product === "The Menu- Double Choc")
        a.productlineid = "05e02bdf-b924-433c-9469-48e9ed784d13";
      if (a.product === "The Menu- Malva Pudding")
        a.productlineid = "05e02bdf-b924-433c-9469-48e9ed784d13";
      if (a.product === "The Menu- Sticky Toffee")
        a.productlineid = "05e02bdf-b924-433c-9469-48e9ed784d13";
      if (a.product === "Warm Pudding")
        a.productlineid = "05e02bdf-b924-433c-9469-48e9ed784d13";
      if (a.product === "Dairymaid Mega Almond")
        a.productlineid = "07d71a0d-2f7f-45f9-b8c3-7c412e36508e";
      if (a.product === "Dairymaid Mega Cookies")
        a.productlineid = "07d71a0d-2f7f-45f9-b8c3-7c412e36508e";
      if (a.product === "Dairymaid Mega Cookies")
        a.productlineid = "07d71a0d-2f7f-45f9-b8c3-7c412e36508e";
      if (a.product === "MEGA Almond")
        a.productlineid = "07d71a0d-2f7f-45f9-b8c3-7c412e36508e";
      if (a.product === "MEGA Almond")
        a.productlineid = "07d71a0d-2f7f-45f9-b8c3-7c412e36508e";
      if (a.product === "MEGA Double Crunch")
        a.productlineid = "07d71a0d-2f7f-45f9-b8c3-7c412e36508e";
      if (a.product === "MEGA White Almond")
        a.productlineid = "07d71a0d-2f7f-45f9-b8c3-7c412e36508e";
      if (a.product === "MEGA White Almond")
        a.productlineid = "07d71a0d-2f7f-45f9-b8c3-7c412e36508e";
      if (a.product === "1.5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "1.5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "1.5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "1.5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "1.5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 1.8L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 1.8L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 1.8L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 1.8L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 1.8L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 1.8L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 2L Ice Cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 2L Ice Cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 2L Ice Cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid 5L Ice cream")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid Cookies 1.5 Litres")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "Dairymaid Hazelnut Praline 1.5 Litres")
        a.productlineid = "16484b7b-c32e-4300-ac2f-cf333e23c116";
      if (a.product === "LAMB and WATT Tonic")
        a.productlineid = "18df1d8f-0dc1-4be2-bc6b-875a799c583f";
      if (a.product === "Whitely Neill Aloe and cucumber")
        a.productlineid = "18df1d8f-0dc1-4be2-bc6b-875a799c583f";
      if (a.product === "Whitely Neill Aloe and cucumber")
        a.productlineid = "18df1d8f-0dc1-4be2-bc6b-875a799c583f";
      if (a.product === "Whitley Neill Protea and hibiscus")
        a.productlineid = "18df1d8f-0dc1-4be2-bc6b-875a799c583f";
      if (a.product === "Whitley Neill Protea and hibiscus")
        a.productlineid = "18df1d8f-0dc1-4be2-bc6b-875a799c583f";
      if (a.product === "Red Square Energiser Vodka")
        a.productlineid = "5db73879-eafe-49a2-8585-8974138b884d";
      if (a.product === "Red Square Engergiser Gin")
        a.productlineid = "5db73879-eafe-49a2-8585-8974138b884d";
      if (a.product === "Red Square Orange - Passion Fruit")
        a.productlineid = "5db73879-eafe-49a2-8585-8974138b884d";
      if (a.product === "Red Square Orange Ice")
        a.productlineid = "5db73879-eafe-49a2-8585-8974138b884d";
      if (a.product === "Red Square Orange Ice")
        a.productlineid = "5db73879-eafe-49a2-8585-8974138b884d";
      if (a.product === "Skinny Diva  Passion Fruit")
        a.productlineid = "88da4b36-4434-415d-9a71-2d7b417a6b9a";
      if (a.product === "Skinny Diva Peach Punch")
        a.productlineid = "88da4b36-4434-415d-9a71-2d7b417a6b9a";
      if (a.product === "Skinny Diva Strawberry Swirl")
        a.productlineid = "88da4b36-4434-415d-9a71-2d7b417a6b9a";
      if (a.product === "Buffelsfontein Brandy 750ml")
        a.productlineid = "92f02435-df0a-435c-ae3c-6883a821ca53";
      if (a.product === "Buffelsfontein en Kola 6pack")
        a.productlineid = "92f02435-df0a-435c-ae3c-6883a821ca53";
      if (a.product === "Buffelsfontein en Kola Single")
        a.productlineid = "92f02435-df0a-435c-ae3c-6883a821ca53";
      if (a.product === "Caramelo Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Caramelo Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Caramelo Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Dairymaid Pint (480ml)")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Dairymaid Pint (480ml)")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Dairymaid Pint (480ml)")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Dairymilk Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Dairymilk Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Dairymilk Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Lunchbar Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Lunchbar Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Oreo Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Oreo Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "Oreo Pint")
        a.productlineid = "9676cc04-e76e-4a7b-beb2-309763172588";
      if (a.product === "CADBURY CRUNCHIE STICK")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Crunchie Sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Crunchie Sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Crunchie Sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "CADBURY DAIRYMILK STICK")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Dairymilk Sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Dairymilk Sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Dairymilk Sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Lunchbar ")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Lunchbar ")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Lunchbar ")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Lunchbar ")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Lunchbar Dream")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Cadbury Lunchbar Dream")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid cones/sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid cones/sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid cones/sticks")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid Jive")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid King Cone")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid King Cone Classic")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid King Cone Flake")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid Stracciatella 1.5 Litres")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Dairymaid Tin Roof 1.5 Litres")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Oreo King Cone")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Oreo King Cone")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Oreo Sandwich")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "OTHER IMPULSE -Dairymaid")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "OTHER IMPULSE -Dairymaid")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "OTHER IMPULSE -Dairymaid")
        a.productlineid = "bcd12c66-e967-4919-a2c4-6d1bfb4a3749";
      if (a.product === "Aged Chilli Sauce")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Aged Chilli Sauce")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Aged Chilli Sauce")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Lava")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Lava")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Lava")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "LRG Prego")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "LRG Prego")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam EXTRA HOT Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam EXTRA HOT Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam EXTRA HOT Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam HOT Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam HOT Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam HOT Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam Lemon & Garlic")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam Lemon & Garlic")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam Lemon & Garlic")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam MILD Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam MILD Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Mozam MILD Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Portuguese Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Portuguese Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Portuguese Peri Peri")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Prego")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Prego")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Prego")
        a.productlineid = "fafc9acf-8a45-41a0-a6ba-d85869db0d92";
      if (a.product === "Ekuzeni Cakes")
        a.productlineid = "4f8f5515-ab87-43f6-9648-bceadf9d3c64";
    });

    prods.forEach(a => {
      if (typeof a.productlineid === "undefined") console.log(a);
    });

    console.log(prods);

    var responses = this.state.responses;
    responses.forEach(a => {
      prods.forEach(b => {
        if (typeof a.Product !== "undefined" && a.Product === b.product)
          a.productlineid = b.productlineid;
      });
    });
    console.log(responses);
    this.setState({ responses: responses });
    var newres = [];
    responses.forEach(a => {
      if (typeof a.productlineid !== "undefined") newres.push(a);
    });
    var cli = await Utils.getAllClients();
    newres.forEach(a => {
      cli.forEach(b => {
        if (a.Client === b.clientname) a.clientid = b.id;
      });
    });

    var newres2 = [];
    newres.forEach(a => {
      if (typeof a.clientid !== "undefined") newres2.push(a);
    });

    this.setState({ responses: newres2 });

    console.log(newres2);
    console.log("end match products");
  }

  thing3() {
    console.log("start group by pline and client");
    var responses = this.state.responses;
    var newres = [];
    responses.forEach(a => {
      var found = false;
      newres.forEach(b => {
        if (a.clientid === b.clientid && a.productlineid === b.productlineid) {
          found = true;
          b.data.push(a);
        }
      });
      if (!found)
        newres.push({
          clientid: a.clientid,
          productlineid: a.productlineid,
          data: [a]
        });
    });
    console.log(newres);
    this.setState({ responses: newres });
    console.log("end group by pline and client");
  }

  async findpromoters() {
    var responses = this.state.responses;
    var promoters = [];
    responses.forEach(a => {
      promoters.push("~" + a.Promoter);
    });
    promoters = Utils.removeDuplicates(promoters);
    console.log(promoters);
    // for (var i =0; i<promoters.length;i++){
    //   let s  = await Utils.createUser({
    //     cognitoid: "NA",
    //     name: promoters[i],
    //     type: "Promoter",
    //     email: "NA",
    //     access: "all",
    //     label: "Promoter Plus"
    //
    //   })
    //   console.log(s)
    // }
  }

  async doPromoterInvite(promotername, username, label) {
    try {
      let a = await Auth.signUp({
        username: username,
        password: username,
        attributes: {
          email: username // optional
        }
      });
      this.setState({ status: "User created, activating." });
      await Utils.createUser({
        id: a.userSub,
        name: promotername,
        email: username,
        type: "Promoter",
        clients: "all",
        label: label
      });
      this.setState({
        promotername: "",
        promocode: "",
        status: "Promoter created"
      });
      this.updateList(this.state.allclients);
    } catch (err) {
      Utils.log(err);
      this.setState({ status: "Error creating account. Promoter exists." });
    }
  }

  async addpromoters() {
    var responses = this.state.lwcnewusers;
    console.log(responses);
    for (var i = 0; i < responses.length; i++) {
      try {
        var username = "";
        var promotername = "";
        let a = await Auth.signUp({
          username: username,
          password: username,
          attributes: {
            email: username // optional
          }
        });
        let s = await Utils.createUser({
          cognitoid: a.userSub,
          name: promotername,
          type: "Promoter",
          email: username,

          clients: "all",
          label: "LWC"
        });
        console.log(s);
      } catch (err) {
        Utils.log(err);
      }
    }
  }

  makeschedules() {
    console.log("start make schedules");
    var responses = this.state.responses;
    responses.forEach(a => {
      var scheds = [];
      a.data.forEach(b => {
        scheds.push(b["Promotion Identifier"]);
      });
      scheds = Utils.removeDuplicates(scheds);
      var newsch = [];
      scheds.forEach(d => {
        newsch.push({ schedid: d, responses: [] });
      });
      a.data.forEach(z => {
        newsch.forEach(x => {
          if (z["Promotion Identifier"] === x.schedid) x.responses.push(z);
        });
      });

      a.scheds = newsch;
    });
    console.log(responses);
    this.setState({ responses: responses });
    console.log("end make schedules");
  }

  async formateverything() {
    console.log("start format the data to get ready for insertion");
    var prods = await Utils.getAllProductLines();
    var responses = this.state.responses;
    responses.forEach(a => {
      a.promotionName = "archive";
      a.promoterPlusPromotionClientId = a.clientid;
      a.productline = a.productlineid;
      a.products = "{}";
      prods.forEach(q => {
        if (a.productlineid === q.id) a.products = JSON.stringify(q.products);
      });
      a.questions =
        '[{"question":"Saletype","type":"toggle","items":"Sale,Taste"},{"question":"product","type":"toggle","items":"\\"Whitley Neill Gin\\""},{"question":"repeattype","type":"toggle","items":"First time, Repeat"},{"question":"carttype","type":"toggle","items":"Basket, Trolley, None"},{"question":"gender","type":"toggle","items":"Female, Other, Male"},{"question":"race","type":"toggle","items":"Asian, Black, Coloured, Indian, White"},{"question":"taste","type":"multi","items":"Sour, Salty, Sweet, Bitter, Spicy"},{"question":"age","type":"toggle","items":"16-20, 21-30, 31-50, 51+"}]';
      a.colours =
        '{"background":"#fff","backgroundfont":"#000","inactivebackground":"#90a4ae","inactivefont":"#000","activebackground":"#0d47a1","activefont":"#fff"}';
      a.label = "Promoter Plus";
      var maxdt = new Date("1999-01-01T01:01:01");
      var mindt = new Date("2050-01-01T01:01:01");
      a.scheds.forEach(b => {
        b.responses.forEach(c => {
          b.promoterId = c.promoterid;
          var start = c["Promotion Start Date"];
          // console.log(start)
          var end = c["Promotion End Date"];
          start = start.replace(" ", "T").replace(",", ".");
          end = end.replace(" ", "T").replace(",", ".");
          start = new Date(start);
          end = new Date(end);
          // start.setHours(start.getHours()+2)
          // end.setHours(end.getHours()+2)

          if (start < mindt) mindt = start;
          if (end > maxdt) maxdt = end;
          // console.log(start)
          // console.log(mindt)
          b.promoterStartDate = mindt;
          b.promoterEndDate = maxdt;
          b.promoterLocation = JSON.stringify({
            address: c.Location + ", South Africa"
          });
          b.signoffmanagername = c["Signed Off By"];
          b.signoffmanageremail = c["Email address"];
          b.signofftimestamp = end;
          b.signoffmanagerphonenumber = c["Contact number"];
          b.signoffmanagerrating = c["Rating"];
          b.signoffmanagercomments = c["Comment"];

          c.user = c.promoterid;
          // c.schedule: PromoterPlusSchedule @connection(name: "ScheduleReponse2")
          c.timestamp = start;
          var participanttype = "Sale";
          if (c["Participant Type"] === "Taster") participanttype = "Taste";
          if (c["Repitition Type"] === "First Time")
            c["Repitition Type"] = "First time";
          if (c["Repitition Type"] === "Repeat Buyer")
            c["Repitition Type"] = "Repeat";
          var resp = [
            {
              items: "Sale,Taste",
              question: "Saletype",
              response: participanttype,
              type: "toggle"
            },
            {
              items: c["Product"],
              question: "product",
              response: c["Product"],
              type: "toggle"
            },
            {
              items: "Asian, Black, Coloured, Indian, White",
              question: "race",
              response: c["Participant Race"],
              type: "toggle"
            },
            {
              items: "First time, Repeat",
              question: "repeattype",
              response: c["Repitition Type"],
              type: "toggle"
            },
            {
              items: "Basket, Trolley, None",
              question: "carttype",
              response: c["Buying Power"],
              type: "toggle"
            },
            {
              items: "Female, Other, Male",
              question: "gender",
              response: c["Participant Gender"],
              type: "toggle"
            },
            {
              items: "16-20, 21-30, 31-50, 51+",
              question: "age",
              response: c["Participant Age Range"],
              type: "toggle"
            },
            {
              items: "rating",
              question: "rating",
              response: c["responserating"].substr(0, 1),
              type: "rating"
            },
            {
              items: "additionalQuestions",
              question: "additionalQuestions",
              response: c["Product Feedback"],
              type: "additionalQuestions"
            }
          ];
          c.response = JSON.stringify(resp);
        });
      });
      // mindt.setHours(mindt.getHours()+2)
      // maxdt.setHours(maxdt.getHours()+2)
      a.startDate = mindt;
      a.endDate = maxdt;
    });
    console.log(responses);
    this.setState({ responses: responses });
    console.log("end format the data to get ready for insertion");
  }

  async addcamp() {
    console.log("start add camp");
    // var campid = 0
    var responses = this.state.responses;

    try {
      // var camp = {};
      // camp.id = "ef775e4f-0a85-438f-bb21-290b1f52b282";
      // var campid = 1;

      for (var campid = 1; campid < responses.length; campid++) {
        // for (var campid  = ; campid < 1;campid++){
        let camp = await Utils.createPromotion(responses[campid]);
        console.log(camp);

        for (var i = 0; i < responses[campid].scheds.length; i++) {
          var sch = responses[campid].scheds[i];
          sch.promotionid = camp.id;
          if (sch.signoffmanagername === "") sch.signoffmanagername = null;
          if (sch.signoffmanageremail === "") sch.signoffmanageremail = null;

          if (sch.signoffmanagerphonenumber === "")
            sch.signoffmanagerphonenumber = null;
          if (sch.signoffmanagerrating === "") sch.signoffmanagerrating = null;
          if (sch.signoffmanagercomments === "")
            sch.signoffmanagercomments = null;
          sch.signoffmanagerrating = Number(sch.signoffmanagerrating);
          if (isNaN(sch.signoffmanagerrating)) sch.signoffmanagerrating = 0;
          // console.log(sch)
          let scheds = await Utils.BULKcreatePromotionSchedule(sch);
          // console.log(scheds)
          var reses = [];
          for (
            var j = 0;
            j < responses[campid].scheds[i].responses.length;
            j++
          ) {
            var res = responses[campid].scheds[i].responses[j];
            res.scheduleID = scheds.id;
            res.userid = res.promoterid;
            reses.push(res);
          }
          var s = await Promise.all(reses.map(Utils.createPromotionResponse));
          // console.log(s.length)
          // let resp = await Utils.createPromotionResponse(res);
          console.log(campid + " " + i + " " + j + " " + s.length);
        }
      }
    } catch (err) {
      console.log(err);
    }
    console.log("done");
    console.log("end add camp");
  }

  // async delscheds() {
  //   console.log("start delscheds");
  //
  //   var responses = this.state.delschedules;
  //   console.log(responses);
  //   var sch = [];
  //   responses.forEach(z => sch.push(z.id));
  //   var sch2 = [...new Set(sch)];
  //   console.log(sch2);
  //   var sch3 = [];
  //   for (var i = 1280; i < 1300; i++) {
  //     var a = sch2.slice(i * 100, (i + 1) * 100);
  //     var individualSchedulesDetail = await Promise.all(
  //       a.map(Utils.deleteResponse)
  //     );
  //     console.log(i*1000);
  //     console.log(individualSchedulesDetail);
  //     // sch3.push(a)
  //   }
  //
  //   // console.log(sch3);
  //
  //   // for (var i = 0; i < sch2.length; i++) {
  //   //   let s = await Utils.deleteResponse(sch2[i]);
  //   //   console.log(s)
  //   // }
  //
  //   console.log("end delscheds");
  // }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: 500 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <App
            done={d => this.setState({ schedules: d.data })}
            title="schedules"
          />

          <App
            done={d => this.setState({ responses: d.data })}
            title="responses"
          />
          <App
            done={d => this.setState({ lwcnewusers: d.data })}
            title="LWC users for Calvin"
          />
          <App
            done={d => this.setState({ delschedules: "" })}
            title="delschedules"
          />
        </div>
        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.thing1();
          }}
        >
          put schedules into responses
        </Button>
        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.thing2();
          }}
        >
          match products
        </Button>
        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.thing3();
          }}
        >
          group by pline and client
        </Button>
        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          disabled
          color="primary"
          onClick={() => {
            this.findpromoters();
          }}
        >
          find promoters
        </Button>
        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          disabled
          color="primary"
          onClick={() => {
            this.addpromoters();
          }}
        >
          ADD LWC for calvin
        </Button>
        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.makeschedules();
          }}
        >
          make schedules
        </Button>
        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.formateverything();
          }}
        >
          format the data to get ready for insertion
        </Button>

        <Button
          style={{
            margin: 5,
            textTransform: "none"
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.addcamp();
          }}
        >
          add camp
        </Button>

      </div>
    );
  }
}
export default Uploader;
