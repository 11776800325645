import React from "react";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import Utils from "./utils";
import Button from "@material-ui/core/Button";

export default class PromoConsumeSection extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.consumeData)
    this.state = {
      items: this.props.consumeData.consumablesetup === null? []:JSON.parse(
        this.props.consumeData.consumablesetup
      ).consumableAssetChips.concat(
        JSON.parse(this.props.consumeData.consumablesetup).fixedAssetChips
      ),
      clientid: this.props.consumeData.id,
      popopen: false,
      poptext: 0,
      popopenitem: ""
    };
  }
  async saveConsumeAdjustments(value, item, userid, clientid) {
    await Utils.createConsumeResponse({
      userID: userid,
      scheduleID: "adjust",
      timestamp: new Date(),
      usage: JSON.stringify([{ amount: value, item }]),
      promoterPlusConsumedConsumableIDId: clientid
    });
    this.props.onUpdateUserConsume({userid,value,item})
  }

  valueSection(eitem, e) {
    if (typeof eitem !== "undefined") {
      let amount = this.props.liveConsumeData.filter(
        z => z.userID === this.props.userID
      )[0];
      if (typeof amount !== "undefined")
        amount = amount.usage.filter(z => z.item === eitem);
      else amount = "";
      if (amount.length > 0) amount = " (" + amount[0].amount + ")";
      else amount = "";
      return eitem + amount;
    } else {
      let amount = this.props.liveConsumeData.filter(
        z => z.userID === this.props.userID
      )[0];
      if (typeof amount !== "undefined")
        amount = amount.usage.filter(z => z.item === e);
      else amount = "";
      if (amount.length > 0) amount = " (" + amount[0].amount + ")";
      else amount = "";
      return e + amount;
    }
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row", marginLeft: 10 , flexWrap: 'wrap'}}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.popopen}
          onClose={() => this.setState({ popopen: false })}
        >
          <div
            style={{
              position: "absolute",
              backgroundColor: "#ccc",
              top: "50%",
              left: "50%",
              borderRadius: 5
            }}
          >
            <div style={{ display: "flex", alignItems: "center", margin: 10 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>{this.state.popopenitem}</div>
                <TextField
                  style={{ color: "#374799" }}
                  id="outlined-name"
                  label={"Adjust up or down"}
                  value={this.state.poptext}
                  onChange={change => {
                    let val = change.target.value
                    if (val ==="") val =0
                    this.setState({ poptext: parseInt(val) });
                  }}
                  margin="normal"
                />
              </div>
              <SaveIcon
                onClick={() => {
                  this.saveConsumeAdjustments(
                    this.state.poptext,
                    this.state.popopenitem,
                    this.props.userID,
                    this.state.clientid
                  );
                  this.setState({ popopen: false, poptext: 0 });
                }}
              />
            </div>
          </div>
        </Modal>
        {this.state.items.map((e, i) => (
          <Paper
            key={i}
            style={{
              backgroundColor: typeof e.item === "undefined" ? "#aaa" : "#ccc",
              marginRight: 10, marginBottom: 10
            }}
          > <Button   onClick={() =>
              this.setState({ popopen: true, popopenitem: e.item || e })
            }>
            <div style={{ textTransform:'none', margin: 3, display: "flex", alignItems: "center" , fontSize: 14}}>
              {this.valueSection(e.item, e)}

              <EditIcon

              />
            </div>
            </Button>
          </Paper>
        ))}
      </div>
    );
  }
}
