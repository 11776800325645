import React from "react";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import ExifOrientationImg from "react-exif-orientation-img";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import Utils from "./utils";

class ModalImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editModalDataSales: 0,
      editModalDataTaste: 0
    };
  }

  makeImagesInModal(input) {
    return input.map((item, index) => (
      <div key={index} style={{ display: "flex", justifyContent: "center" }}>
        <ExifOrientationImg src={item} alt="" />
      </div>
    ));
  }

  updateForm(input) {
    var output = "";
    if (input === 0) output = "";
    else if (isNaN(input)) output = "";
    else output = input;
    return output;
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          style={{
            content: {
            width: "40%",
            height:"40%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",

            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 99
          }}
          ariaHideApp={false}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <CloseIcon
              style={{
                fontSize: 60,
                color: "#666",
                justifyContent: "flex-end",
                marginLeft: "90%"
              }}
              onClick={() => this.props.closeMe()}
            />
            Sales: {this.props.data.sales}
            <TextField
              style={{ color: "#374799", width: "95%" }}
              id="outlined-name"
              multiline
              label="Adjust sales"
              value={this.updateForm(this.state.editModalDataSales)}
              margin="normal"
              variant="outlined"
              onChange={change =>
                this.setState({
                  editModalDataSales: parseInt(change.target.value)
                })
              }
            />
            <br />
            Taste: {this.props.data.taste}
            <TextField
              style={{ color: "#374799", width: "95%" }}
              id="outlined-name"
              multiline
              label="Adjust taste"
              value={this.updateForm(this.state.editModalDataTaste)}
              margin="normal"
              variant="outlined"
              onChange={change =>
                this.setState({
                  editModalDataTaste: parseInt(change.target.value)
                })
              }
            />
            <Button
              style={{ marginLeft: 20, alignSelf: "center" }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.props.updateSalesData({
                  editModalDataSales: this.state.editModalDataSales,
                  editModalDataTaste: this.state.editModalDataTaste,
                  editModalData: this.props.data
                });
                this.setState({ editModalDataSales: 0, editModalDataTaste: 0 });
              }}
            >
              Save
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
export default ModalImage;
