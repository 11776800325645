import React from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";

class PromotionsQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateQuestion(question, index, newItemValue) {
    var oldQuestions = this.props.input;
    oldQuestions[index][question] = newItemValue;
    this.props.updater(oldQuestions);
  }

  makeQuestionRows(input) {
    return input.map((item, index) => {
      return (
        <TableRow key={index} style={{}}>
          <TableCell style={{ padding: 0, width: 180 }}>
            <FormControl
              variant="outlined"
              style={{ width: 180, marginTop: 8 }}
            >
              <InputLabel>Question type</InputLabel>
              <Select
                value={item.type}
                onChange={change => {
                  this.updateQuestion("type", index, change.target.value);
                }}
                input={<OutlinedInput labelWidth={100} />}
              >
                <MenuItem value={"toggle"}> Single choice</MenuItem>
                <MenuItem value={"multi"}> Multiple choice</MenuItem>
                <MenuItem value={"additionalQuestions"}> Free text</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ padding: 0, width: 250 }}>
            <TextField
              style={{ color: "#374799", width: 400 }}
              id="outlined-name"
              label="QuestionID (not visible on screen)"
              value={item.question}
              onChange={change => {
                this.updateQuestion("question", index, change.target.value);
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 0, width: 400 }}>
            <TextField
              style={{ color: "#374799", width: 400 }}
              id="outlined-name"
              label="Response options (visible on screen)"
              value={item.items}
              onChange={change => {
                this.updateQuestion("items", index, change.target.value);
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <Button
              style={{}}
              variant="contained"
              color="primary"
              disabled={index < 2 ? true : false}
              onClick={() => {
                var oldQuestions = this.props.input;
                oldQuestions.splice(index, 1);
                this.props.updater(oldQuestions);
              }}
            >
              Delete
            </Button>
            <DownIcon
              style={{}}
              onClick={() => {
                var oldQuestions = this.props.input;
                if (index < this.props.input.length - 1 && index > 1)
                  this.array_move(oldQuestions, index, index + 1);
                this.props.updater(oldQuestions);
              }}
            />
            <UpIcon
              onClick={() => {
                var oldQuestions = this.props.input;
                if (index > 2) this.array_move(oldQuestions, index, index - 1);
                this.props.updater(oldQuestions);
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  render() {
    return (
      <div>
        <Table>
          <TableBody style={{ padding: 0 }}>
            {this.makeQuestionRows(this.props.input || null)}
          </TableBody>
        </Table>
        <Button
          style={{ width: 200 }}
          variant="contained"
          color="primary"
          onClick={() => {
            var oldQuestions = this.props.input;
            oldQuestions.push({
              question: "",
              type: "",
              items: []
            });
            this.setState({ questions: oldQuestions });
          }}
        >
          Add another question
        </Button>
      </div>
    );
  }
}
export default withRouter(PromotionsQuestions);
