import React from "react";
import Utils from "./utils";
import { isMobile } from "react-device-detect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import GPSIcon from "@material-ui/icons/Room";
import Signinicon from "@material-ui/icons/LibraryBooks";
import StockTakeIcon from "@material-ui/icons/Assignment";
import PromoterIcon from "@material-ui/icons/Person";
// import CloseIcon from "@material-ui/icons/Close";
import ExitIcon from "@material-ui/icons/PanTool";
import EditIcon from "@material-ui/icons/Edit";
import StandIcon from "@material-ui/icons/StoreMallDirectory";
import Tooltip from "@material-ui/core/Tooltip";
class Desktop extends React.Component {
  simpleCount(input, type) {
    var output = 0;
    input.responses.items.forEach(function(e) {
      e.response.forEach(function(r) {
        if (r.question === "Saletype" && r.response === type) output += 1;
      });
    });
    return output;
  }

  simpleStockCount(input) {
    var output = 0;
    if (input) {
      input.forEach(function(e) {
        output += e.count;
      });
    }
    return output;
  }

  calcdistance(location, gps) {
    if (gps !== null) {

      var dLat = (location.geo.lat - gps.geo.lat) * 0.0174532925199433;
      var dLon = (location.geo.lng - gps.geo.lng) * 0.0174532925199433;
      var ae =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(gps.geo.lat * 0.0174532925199433) *
      Math.cos(gps.geo.lng * 0.0174532925199433);
      var c = 2 * Math.atan2(Math.sqrt(ae), Math.sqrt(1 - ae)) * 6371 * 1.3;
      return c;
    } else return 999
  }

  calcSignoffGPS(time, location, gps) {
    let dist =  this.calcdistance(location, gps);

    if (time) {
      if (dist > 2) return "#9c27b0";
      else return "#009688";
    } else return "#ff5722";
  }

  async getURI(arr, typename) {
    for (var i = 0; i < arr.length; i++) {
      arr[i].picURI = await Utils.getPicFromS3(arr[i].filename);
    }
    // console.log(arr)
    this.props.showImages(this.findPhoto(arr, typename));
  }

  findPhoto(arr, type) {
    var output = [];
    if (arr)
      arr.forEach(function(e) {
        if (type !== "Other photos") {
          if (e.phototype === type) output.push(e.picURI);
        } else {
          if (
            e.phototype !== "Sign in" &&
            e.phototype !== "Stand" &&
            e.phototype !== "Promoter"
          )
            output.push(e.picURI);
        }
      });
    return output;
  }

  countPhotos(input, phototype) {
    var count = 0;
    input.promotionPhotos.items.forEach(function(a) {
      if (a.phototype === phototype) count += 1;
    });
    return count;
  }
  calcConsumption(input) {
    let output = [];
    if (input.length > 0) {
      input[0].items.forEach(z => {
        let total = 0;
        z.amounts.forEach(t => (total += t.amount));
        let usage = z.amounts.filter(t => t.type === "consumed");
        let totalusage = 0;
        usage.forEach(t => (totalusage += t.amount));
        let usagerate = parseFloat(totalusage) / parseFloat(usage.length);
        if (isNaN(usagerate)) usagerate = 0.0;
        if (usagerate < 0)
          output.push({ runout: (total / usagerate) * -1, item: z.item });
      });
    }
    output = output.filter(z => z.runout <= 10);
    let summarytext = "OK";
    if (output.length > 0) {
      let items = [];
      output.forEach(z => items.push(z.item));
      summarytext = items.join(", ");
      summarytext += " low";
    }

    return summarytext;
  }

  makePromoterBody(input) {
    // console.log(input)
    var schedules = [];
    input.forEach(function(e) {
      e.promotionScheduler.items.forEach(function(f) {
        f.photos = JSON.parse(e.photos);
        if (e.photos === null)
          f.photos = [
            { name: "Sign in", repeating: false, order: 0 },
            { name: "Promoter", repeating: false, order: 1 },
            { name: "Stand", repeating: false, order: 2 },
            { name: "Other photos", repeating: true, order: 3 }
          ];
        f.photos = f.photos.map(a => a.name);
      });
    });
    input.forEach(function(e) {
      schedules = schedules.concat(e.promotionScheduler.items);
    });

    var newschedules = [];
    schedules.forEach(function(s) {
      // console.log("table s.startdate");
      // console.log(s.startdate);

      // s.startdate = new Date(s.startdate);
      // s.enddate = new Date(s.enddate);
      // var promoenddate = new Date(Date.now()).toISOString().split("T")[0];

      // var promoenddate = (new Date("2021-01-01T00:00:00")).toISOString().split("T")[0];
      // var enddate = new Date(promoenddate + "T23:59:59");
      // var startdate = new Date(promoenddate + "T00:00:00");
      // console.log(startdate)
      // if (s.startdate > startdate && s.startdate < enddate) {
      //   // console.log(s)
      // }
      newschedules.push(s);
    });
    // Utils.log("here");
    // Utils.log(newschedules);
    schedules = newschedules;

    schedules.forEach(function(s) {
      // console.log("table s.startdate");
      // console.log(s.startdate);
      // s.startdate = new Date(s.startdate);
      // s.startdate.setHours(s.startdate.getHours() + 2);
      s.orderpoints = 0;
      if (s.gps) s.orderpoints += 3;
      if (s.signindone) s.orderpoints += 3;
      if (s.signofftimestamp) s.orderpoints += 1;
      if (s.stocktakedone) s.orderpoints += 2;
      let output2 = 0;
      s.responses.items.forEach(function(e) {
        e.response.forEach(function(r) {
          if (r.question === "Saletype" && r.response === "Sale") output2 += 1;
        });
      });
      if (output2) s.orderpoints += 3;
      let output3 = 0;
      s.responses.items.forEach(function(e) {
        e.response.forEach(function(r) {
          if (r.question === "Saletype" && r.response === "Taste") output3 += 1;
        });
      });
      if (output3) s.orderpoints += 1;
      if (
        s.promotionPhotos.items.filter(a => a.phototype === "Sign in").length >
        0
      )
        s.orderpoints += 3;
      if (
        s.promotionPhotos.items.filter(a => a.phototype === "Stand").length > 0
      )
        s.orderpoints += 2;
      if (
        s.promotionPhotos.items.filter(a => a.phototype === "Other photos")
          .length > 0
      )
        s.orderpoints += 2;
      if (
        s.promotionPhotos.items.filter(a => a.phototype === "Promoter").length >
        0
      )
        s.orderpoints += 3;
      // if (s.standpicdone) s.orderpoints += 2;
      // if (s.otheritemsdone) s.orderpoints += 1;
      // if (s.promoterpicdone) s.orderpoints += 2;
    });
    schedules.sort((a, b) =>
      a.orderpoints > b.orderpoints ? 1 : b.orderpoints > a.orderpoints ? -1 : 0
    );

    return schedules.map((item, index) => (
      <TableRow key={index} style={{ flexWrap: "wrap" }}>
        <TableCell>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              color: "#666"
            }}
          >
            <div>
              <b>Promoter:</b>
              {" " + item.user.name}
            </div>
            <div>
              <b>Store:</b>
              {" " + item.location.address.split(",")[0]}
            </div>
            <div>
              <b>Start time:</b>
              {" " +
                item.startdate.substr(0, 10) +
                " @ " +
                item.startdate.substr(11, 5)}
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              color: "#666"
            }}
          >
            <div>
              <b>Client:</b>
              {" " + item.promotionID.client.clientname}
            </div>
            <div>
              <b>Brand:</b>
              {" " + item.promotionID.productLine.productline}
            </div>
            <div
              onClick={() => {
                // console.log(item)
                if (item.stocktakevalues !== null)
                  this.props.showStock(item.stocktakevalues);
              }}
            >
              <b>Stock on hand:</b>
              {" " + this.simpleStockCount(item.stocktakevalues)}
            </div>
            <div
              onClick={() =>
                this.props.showConsModal(
                  this.props.consumptiondata.filter(
                    z => z.userid === item.user.id
                  )
                )
              }
            >
              <b>Inventory status: </b>
              {this.calcConsumption(
                this.props.consumptiondata.filter(
                  z => z.userid === item.user.id
                )
              )}
            </div>
          </div>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <GPSIcon
              style={{
                marginLeft: 3,
                fontSize: 50,
                color: item.gps ? "#009688" : "#ff5722"
              }}
            />
          </div>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Signinicon
              style={{
                marginLeft: 3,
                color:
                  item.photos.indexOf("Sign in") === -1
                    ? "#aaa"
                    : item.promotionPhotos.items.filter(
                        a => a.phototype === "Sign in"
                      ).length > 0
                    ? "#009688"
                    : "#ff5722",
                fontSize: 50
              }}
              onClick={() => this.getURI(item.promotionPhotos.items, "Sign in")}
            />
          </div>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <PromoterIcon
              style={{
                marginLeft: 3,
                color:
                  item.photos.indexOf("Promoter") === -1
                    ? "#aaa"
                    : item.promotionPhotos.items.filter(
                        a => a.phototype === "Promoter"
                      ).length > 0
                    ? "#009688"
                    : "#ff5722",

                fontSize: 50
              }}
              onClick={() =>
                this.getURI(item.promotionPhotos.items, "Promoter")
              }
            />
          </div>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <StandIcon
              style={{
                marginLeft: 3,
                color:
                  item.photos.indexOf("Stand") === -1
                    ? "#aaa"
                    : item.promotionPhotos.items.filter(
                        a => a.phototype === "Stand"
                      ).length > 0
                    ? "#009688"
                    : "#ff5722",
                fontSize: 50
              }}
              onClick={() => this.getURI(item.promotionPhotos.items, "Stand")}
            />
          </div>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              fontSize: 50,
              color: "#666"
            }}
            onClick={() =>
              this.getURI(item.promotionPhotos.items, "Other photos")
            }
          >
            {
              item.promotionPhotos.items.filter(
                a =>
                  a.phototype !== "Stand" &&
                  a.phototype !== "Sign in" &&
                  a.phototype !== "Promoter"
              ).length
            }
          </div>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              fontSize: 50,
              color: "#666"
            }}
            onClick={() => {
              this.props.showEditModal({
                sales: this.simpleCount(item, "Sale"),
                taste: this.simpleCount(item, "Taste"),
                userid: item.user.id,
                scheduleID: item.id,
                responses: item.responses
              });
            }}
          >
            {this.simpleCount(item, "Sale")}{" "}
          </div>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              fontSize: 50,
              color: "#666"
            }}
            onClick={() => {
              // console.log(item)
              this.props.showEditModal({
                sales: this.simpleCount(item, "Sale"),
                taste: this.simpleCount(item, "Taste"),
                userid: item.user.id,
                scheduleID: item.id,
                responses: item.responses
              });
            }}
          >
            {this.simpleCount(item, "Taste")}
          </div>
        </TableCell>
        <TableCell style={{ padding: 4 }}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Tooltip
              title={
                this.calcdistance(item.location, item.signoffgps)!==999?(this.calcdistance(item.location, item.signoffgps).toFixed(1) +
                " km away"):"Unknown"
              }
            >
              <ExitIcon
                style={{
                  marginLeft: 3,
                  color: this.calcSignoffGPS(
                    item.signofftimestamp,
                    item.location,
                    item.signoffgps
                  ),
                  fontSize: 50
                }}
                onClick={() => {
                  this.props.showSignoff({
                    signoffmanagercomments: item.signoffmanagercomments,
                    signoffmanageremail: item.signoffmanageremail,
                    signoffmanagername: item.signoffmanagername,
                    signoffmanagerphonenumber: item.signoffmanagerphonenumber,
                    signoffmanagerrating: item.signoffmanagerrating,
                    signofftimestamp: item.signofftimestamp,
                    signoffgps: item.signoffgps
                  });
                }}
              />
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    ));
  }

  makePromoterBodyVERT(input) {
    var schedules = [];
    // Utils.log("FIRST here");
    // Utils.log(input);
    input.forEach(function(e) {
      schedules = schedules.concat(e.promotionScheduler.items);
    });

    schedules.forEach(function(s) {
      s.orderpoints = 0;
      if (s.gps) s.orderpoints += 3;
      if (s.signindone) s.orderpoints += 3;
      if (s.signofftimestamp) s.orderpoints += 1;
      if (s.stocktakedone) s.orderpoints += 2;

      if (
        s.promotionPhotos.items.filter(a => a.phototype === "Sign in").length >
        0
      )
        s.orderpoints += 3;
      if (
        s.promotionPhotos.items.filter(a => a.phototype === "Stand").length > 0
      )
        s.orderpoints += 2;
      if (
        s.promotionPhotos.items.filter(a => a.phototype === "Other photos")
          .length > 0
      )
        s.orderpoints += 2;
      if (
        s.promotionPhotos.items.filter(a => a.phototype === "Promoter").length >
        0
      )
        s.orderpoints += 3;
    });
    schedules.sort((a, b) =>
      a.orderpoints > b.orderpoints ? 1 : b.orderpoints > a.orderpoints ? -1 : 0
    );
    schedules = JSON.parse(JSON.stringify(schedules));
    // Utils.log("here");
    // Utils.log(schedules);
    return schedules.map((item, index) => (
      <Table key={index}>
        <TableBody>
          <TableRow style={{ flexWrap: "wrap", borderBottomWidth: "0px" }}>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <b>Promoter</b>
            </TableCell>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                {item.user.name.replace("@promoterplus.com", "")}

                <img
                  style={{ marginLeft: 3 }}
                  alt=""
                  src={
                    this.findPhoto(item.promotionPhotos.items, "Promoter")[0]
                  }
                  height={30}
                  onClick={() =>
                    this.props.showImages(
                      this.findPhoto(item.promotionPhotos.items, "Promoter")
                    )
                  }
                />
                <CameraIcon
                  style={{
                    marginLeft: 3,
                    color: item.promoterpicdone ? "#009688" : "#ff5722"
                  }}
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow style={{ flexWrap: "wrap" }}>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <b>Client</b>
            </TableCell>
            <TableCell style={{ borderBottomWidth: 0 }}>
              {item.promotionID.client.clientname}
            </TableCell>
          </TableRow>
          <TableRow style={{ flexWrap: "wrap" }}>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <b>Location</b>
            </TableCell>

            <TableCell style={{ borderBottomWidth: 0 }}>
              <div style={{ maxWidth: 300 }}>{item.location.address}</div>
            </TableCell>
          </TableRow>
          <TableRow style={{ flexWrap: "wrap" }}>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <b>Signed in</b>
            </TableCell>

            <TableCell style={{ borderBottomWidth: 0 }}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                {item.gps ? item.gps.date.substr(0, 16).replace("T", " ") : ""}
                <img
                  alt=""
                  src={this.findPhoto(item.promotionPhotos.items, "Sign in")[0]}
                  style={{ marginLeft: 3 }}
                  height={30}
                  onClick={() =>
                    this.props.showImages(
                      this.findPhoto(item.promotionPhotos.items, "Sign in")
                    )
                  }
                />
                <CameraIcon
                  style={{
                    marginLeft: 3,
                    color: item.signindone ? "#009688" : "#ff5722"
                  }}
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow style={{ flexWrap: "wrap" }}>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <b>Signed off</b>
            </TableCell>

            <TableCell style={{ borderBottomWidth: 0 }}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                {new Date(item.signofftimestamp)
                  .toISOString()
                  .substr(0, 16)
                  .replace("T", " ")
                  .replace("1970-01-01 00:00", "")}
                <ExitIcon
                  style={{
                    marginLeft: 3,
                    color: item.signofftimestamp ? "#009688" : "#ff5722"
                  }}
                  onClick={() => {
                    this.setState({
                      signOffModalOpen: true,
                      signOffModalData: {
                        signoffmanagercomments: item.signoffmanagercomments,
                        signoffmanageremail: item.signoffmanageremail,
                        signoffmanagername: item.signoffmanagername,
                        signoffmanagerphonenumber:
                          item.signoffmanagerphonenumber,
                        signoffmanagerrating: item.signoffmanagerrating,
                        signofftimestamp: item.signofftimestamp
                      }
                    });
                  }}
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow style={{ flexWrap: "wrap" }}>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <b>Duties</b>
            </TableCell>

            <TableCell style={{ borderBottomWidth: 0 }}>
              <GPSIcon
                style={{
                  marginLeft: 3,
                  color: item.gps ? "#009688" : "#ff5722"
                }}
              />
              <StockTakeIcon
                style={{
                  marginLeft: 3,
                  color: item.stocktakedone ? "#009688" : "#ff5722"
                }}
                onClick={() => {
                  if (item.stocktakevalues)
                    this.setState({
                      stockModalOpen: true,
                      stockModalData: item.stocktakevalues
                    });
                }}
              />
              <StandIcon
                style={{
                  marginLeft: 3,
                  color: item.standpicdone ? "#009688" : "#ff5722"
                }}
                onClick={() =>
                  this.props.showImages(
                    this.findPhoto(item.promotionPhotos.items, "Stand")
                  )
                }
              />
              <CameraIcon
                style={{
                  marginLeft: 3,
                  color: item.otheritemsdone ? "#009688" : "#ff5722"
                }}
                onClick={() =>
                  this.props.showImages(
                    this.findPhoto(item.promotionPhotos.items, "Other photos")
                  )
                }
              />
            </TableCell>
          </TableRow>
          <TableRow style={{ flexWrap: "wrap" }}>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <b>Sales</b>
            </TableCell>

            <TableCell style={{ borderBottomWidth: 0 }}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                {this.simpleCount(item, "Sale")}{" "}
                <EditIcon
                  style={{
                    marginLeft: 3,
                    color: "#3f51b5"
                  }}
                  onClick={() => {
                    this.props.showEditModal({
                      sales: this.simpleCount(item, "Sale"),
                      taste: this.simpleCount(item, "Taste"),
                      userid: item.user.id,
                      scheduleID: item.id,
                      responses: item.responses
                    });
                  }}
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow style={{ flexWrap: "wrap" }}>
            <TableCell style={{ borderBottomWidth: 0 }}>
              <b>Tastes</b>
            </TableCell>

            <TableCell style={{ borderBottomWidth: 0 }}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row"
                }}
                onClick={() => {
                  // console.log(item);
                  this.props.showEditModal({
                    sales: this.simpleCount(item, "Sale"),
                    taste: this.simpleCount(item, "Taste"),
                    userid: item.user.id,
                    scheduleID: item.id,
                    responses: item.responses
                  });
                }}
              >
                {this.simpleCount(item, "Taste")}
                <EditIcon
                  style={{
                    marginLeft: 3,
                    color: "#3f51b5"
                  }}
                  onClick={() =>
                    this.setState({
                      editModalOpen: true
                    })
                  }
                />
              </div>
            </TableCell>
          </TableRow>
          <TableRow style={{ flexWrap: "wrap" }}>
            <TableCell
              style={{ borderBottomWidth: 3, borderBottomColor: "#555" }}
            >
              <b>Products</b>
            </TableCell>
            <TableCell
              style={{ borderBottomWidth: 3, borderBottomColor: "#555" }}
            >
              {this.simpleStockCount(item.stocktakevalues)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    ));
  }
  render() {
    // console.log("this.props.data");
    // console.log(this.props.data);
    return (
      <div>
        {!isMobile ? (
          <Table>
            <TableHead>
              <TableRow style={{ flexWrap: "wrap" }}>
                <TableCell>Promotion info</TableCell>
                <TableCell>Brand info</TableCell>
                <TableCell style={{ padding: 4 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Check in
                  </div>
                </TableCell>
                <TableCell style={{ padding: 4 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Sign in
                  </div>
                </TableCell>
                <TableCell style={{ padding: 4 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Promoter
                  </div>
                </TableCell>
                <TableCell style={{ padding: 4 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Stand
                  </div>
                </TableCell>
                <TableCell style={{ padding: 4 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Photos
                  </div>
                </TableCell>
                <TableCell style={{ padding: 4 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Sales
                  </div>
                </TableCell>
                <TableCell style={{ padding: 4 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Tasters
                  </div>
                </TableCell>
                <TableCell style={{ padding: 4 }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    Sign off
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{this.makePromoterBody(this.props.data)}</TableBody>
          </Table>
        ) : (
          <div>
            <h4>Promoters</h4>
            {this.makePromoterBodyVERT(this.props.data)}
          </div>
        )}
      </div>
    );
  }
}
export default Desktop;
