import React from "react";
import Button from "@material-ui/core/Button";
import Utils from "./utils";
import { Auth } from "aws-amplify";
import aws_exports from "./aws-exports"; // if you are using Amplify CLI
import Amplify from "aws-amplify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Download from "./download";
import ImageUploader from "react-images-upload";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

Amplify.configure(aws_exports);

class ClientsAndProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newClient: "",
      newProductLine: "",
      newProducts: "",
      allclients: [],
      allproductlines: [],
      CLstatus: "",
      CLreportstatus:"",
      addClientLabel: "",
      addProductsLabel: "",
      LLstatus: "",
      disableclientlabelchange: true,
      disableproductlabelchange: true,
      Pstatus: "",
      filter: {},
      allLabels: [],
      newLabel: "",
      WLallowed: false,
      disablefaqsave: false,
      faqstatus: ""
    };
  }

  async update() {
    let cl = await Utils.getAllClients(this.state.filter);
    let pl = await Utils.getAllProductLines(this.state.filter);
    let ll = await Utils.getAllLabels();
    this.setState({ allclients: cl, allproductlines: pl, allLabels: ll });
    // Utils.log(cl);
    // Utils.log(pl);
  }

  async componentDidMount() {
    let p = await Auth.currentAuthenticatedUser();
    // Utils.log(s);
    let t = await Utils.getUser(p.username);
    // Utils.log(t);
    if (t.label === "All") {
      this.setState({ filter: null });
      this.setState({
        WLallowed: true,
        disableclientlabelchange: false,
        disableproductlabelchange: false
      });
    } else {
      this.setState({
        filter: { label: { eq: t.label } },
        addClientLabel: t.label,
        addProductsLabel: t.label
      });
    }
    var admin = false;
    if (t.type === "Admin") admin = true;
    if (!admin) this.props.history.push("/");

    this.update();
  }

  async savereport(id) {
    this.setState({CLreportstatus:"  Saving..."});
    var reportconfig = "";
    this.state.allclients.forEach(a => {
      if (a.id === id) reportconfig = a.reportconfig;
    });
    if (reportconfig === "") reportconfig = null
    await Utils.saveDesign({ id: id, reportconfig: reportconfig });
    this.timer = setInterval(() => {
      this.setState({ CLreportstatus: "" });
    }, 4000);
  }

  async saveNewClient(input, label) {
    var C = await Utils.createClient(input, label);
    if (C) {
      this.setState({
        CLstatus: "Client saved",
        newClient: "",
        allclients: await Utils.getAllClients(this.state.filter)
      });
    }
  }
  async saveNewLabel(input) {
    try {
      var C = await Utils.createLabel(input);
      if (C) {
        this.setState({
          LLstatus: "Label saved",
          newLabel: "",
          allLabels: await Utils.getAllLabels()
        });
      }
    } catch (err) {
      Utils.log(err);
    }
  }

  async deleteClient(input) {
    var C = await Utils.deleteClient(input);
    // Utils.log(C)
    if (C) {
      this.setState({
        CLstatus: "Client deleted",
        allclients: await Utils.getAllClients(this.state.filter)
      });
    }
  }
  async deleteLabel(input) {
    var C = await Utils.deleteLabel(input);
    // Utils.log(C)
    if (C) {
      this.setState({
        LLstatus: "Label deleted",
        allLabels: await Utils.getAllLabels()
      });
    }
  }
  async saveNewProductLine(inputPL, inputP, inputLabel) {
    Utils.log(inputPL);
    Utils.log(inputP);
    var C = await Utils.createPromoterPlusProduct(inputPL, inputP, inputLabel);
    Utils.log(C);
    if (C) {
      this.setState({
        Pstatus: "Products saved",
        newClient: "",
        allproductlines: await Utils.getAllProductLines(this.state.filter)
      });
    }
  }

  async deleteProductLine(input) {
    var C = await Utils.deletePromoterPlusProduct(input);
    // Utils.log(C)
    if (C) {
      this.setState({
        Pstatus: "Products deleted",
        allproductlines: await Utils.getAllProductLines(this.state.filter)
      });
    }
  }

  makeClientTableRows(input) {
    // Utils.log(input)
    return input.map((item, index) => {
      return (
        <TableRow key={index} style={{}}>
          <TableCell style={{ padding: 0, width: 222, paddingTop: 17 }}>
            {item.label}
          </TableCell>
          <TableCell style={{ padding: 0, width: 222, paddingTop: 17 }}>
            {item.clientname}
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.deleteClient(item.id);
              }}
            >
              Delete
            </Button>
          </TableCell>
          <TableCell style={{ padding: 0, width: 222, paddingTop: 17 }}>
            <TextField
              style={{ color: "#374799", margin: 0 }}
              id="outlined-name"

              label="Client report embed"
              value={item.reportconfig || ""}
              onChange={change => {
                var oldclients = this.state.allclients;
                oldclients.forEach(a => {
                  if (a.id === item.id) a.reportconfig = change.target.value;
                });
                this.setState({ allclients: oldclients });
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.savereport(item.id);
              }}
            >
              Save Report
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  makeLabelTableRows(input) {
    // Utils.log(input)
    return input.map((item, index) => {
      return (
        <TableRow key={index} style={{}}>
          <TableCell style={{ padding: 0, width: 222, paddingTop: 17 }}>
            {item.label}
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.deleteLabel(item.id);
              }}
            >
              Delete
            </Button>
          </TableCell>
          <TableCell>
            <TextField
              style={{ color: "#374799", margin: 0, width: 500 }}
              id="outlined-name"
              label="Config "
              value={item.config}
              onChange={change => {
                var oldlabels = this.state.allLabels;
                oldlabels[index].config = change.target.value;
                this.setState({ allLabels: oldlabels });
              }}
              margin="normal"
              variant="outlined"
            />
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.updateLabelCongif(item.id, item.config);
              }}
            >
              Save config
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  async updateLabelCongif(id, config) {
    await Utils.updateLabel({ id: id, config: config });
    // Utils.log(s)
  }

  async saveIconForFaq(id, image) {
    this.setState({ disablefaqsave: true });
    // console.log(id);
    // console.log(image);
    let s = await Utils.saveFAQPicToS3({
      filename: Utils.generateName(),
      picture: image
    });
    // console.log(s);
    var oldproductlines = this.state.allproductlines;
    oldproductlines.forEach(a => {
      if (a.id === id) a.faqicon = s.key;
    });
    this.setState({ allproductlines: oldproductlines, disablefaqsave: false });
  }

  // async savePDFForFaq(id, image) {
  //   console.log(id);
  //   console.log(image);
  //   let s = await Utils.saveFAQPDFToS3({ filename: Utils.generateName(),picture: image[0] });
  //   console.log(s);
  //   var oldproductlines = this.state.allproductlines
  //   oldproductlines.forEach(a=>{
  //     if (a.id === id) a.faqpdf= s.key
  //   })
  //   this.setState({allproductlines:oldproductlines});
  // }

  async saveFAQ(id) {
    this.setState({ faqstatus: "  Saving..." });
    var itemtosave = {};

    var oldproductlines = this.state.allproductlines;
    oldproductlines.forEach(a => {
      if (a.id === id) itemtosave = a;
    });
    // console.log(itemtosave)
    if (itemtosave.faqpdf.trim() === "") itemtosave.faqpdf = null;
    if (itemtosave.faqtext.trim() === "") itemtosave.faqtext = null;
    await Utils.updateFAQofProduct({
      id: itemtosave.id,
      faqpdf: itemtosave.faqpdf,
      faqicon: itemtosave.faqicon,
      faqtext: itemtosave.faqtext
    });

    // console.log(faq)
    this.setState({ faqstatus: "   Saved" });
    this.timer = setInterval(() => {
      this.setState({ faqstatus: "" });
    }, 4000);
  }

  async saveproduct(id) {
    this.setState({ faqstatus: "  Saving..." });
    var itemtosave = {};

    var oldproductlines = this.state.allproductlines;
    oldproductlines.forEach(a => {
      if (a.id === id) itemtosave = a;
    });
    // console.log(itemtosave)
    await Utils.updateProductInProductLine({
      id: itemtosave.id,
      products: itemtosave.products,

    });

    // console.log(faq)
    this.setState({ faqstatus: "   Saved" });
    this.timer = setInterval(() => {
      this.setState({ faqstatus: "" });
    }, 4000);
  }

  makeProductTableRows(input) {
    // Utils.log(input)
    return input.map((item, index) => {
      return (
        <TableRow key={index} style={{}}>
          <TableCell style={{ padding: 0 }}>{item.label}</TableCell>
          <TableCell style={{ padding: 0 }}>{item.productline}</TableCell>
          <TableCell style={{ padding: 0 }}>  <TextField
              style={{ color: "#374799", margin: 0 }}
              id="outlined-name"
              multiline
              label="FAQ title text"
              value={item.products || ""}
              onChange={change => {
                var oldproductlines = this.state.allproductlines;
                oldproductlines[index].products = change.target.value;

                this.setState({ allproductlines: oldproductlines });
              }}
              margin="normal"
              variant="outlined"
            /></TableCell>
            <TableCell>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                color="primary"
                onClick={() => {
                    this.saveproduct(item.id);
                }}
              >
                Save
              </Button>
            </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.deleteProductLine(item.id);
              }}
            >
              Delete
            </Button>
          </TableCell>
          <TableCell style={{ padding: 0 }}>
            <TextField
              style={{ color: "#374799", margin: 0 }}
              id="outlined-name"
              multiline
              label="FAQ title text"
              value={item.faqtext || ""}
              onChange={change => {
                var oldproductlines = this.state.allproductlines;
                oldproductlines.forEach(a => {
                  if (a.id === item.id) a.faqtext = change.target.value;
                });
                this.setState({ allproductlines: oldproductlines });
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 0 }}>
            <ImageUploader
              withIcon={false}
              buttonText="Upload icon (JPG/PNG)"
              withPreview={false}
              label=""
              buttonStyles={{
                backgroundColor: item.faqicon === null ? "#666" : "#558B2F"
              }}
              fileContainerStyle={{
                margin: 0,
                padding: 0,
                backgroundColor: "#ddd",
                shadowColor: "#ddd",
                borderWidth: 0,
                borderRadius: 0,
                borderColor: "#ddd",
                boxShadow: `0px 0px 0px 0`,
                display: "flex",
                flexDirection: "row",
                alignSelf: "center"
              }}
              singleImage={true}
              onChange={(pictureFiles, pictureDataURLs) => {
                console.log(pictureFiles);
                console.log(pictureDataURLs[0].split(",")[1]);
                this.saveIconForFaq(item.id, pictureDataURLs[0].split(",")[1]);
              }}
              imgExtension={[".jpg", ".png", "jpeg"]}
              maxFileSize={5242880}
            />
          </TableCell>
          <TableCell style={{ padding: 0 }}>
            <TextField
              style={{ color: "#374799", margin: 0 }}
              id="outlined-name"
              multiline
              label="FAQ PDF URL"
              value={item.faqpdf || ""}
              onChange={change => {
                var oldproductlines = this.state.allproductlines;
                oldproductlines.forEach(a => {
                  if (a.id === item.id) a.faqpdf = change.target.value;
                });
                this.setState({ allproductlines: oldproductlines });
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              disabled={this.state.disablefaqsave}
              onClick={() => {
                this.saveFAQ(item.id);
              }}
            >
              Save FAQ
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    return (
      <div style={{ color: "#666" }}>
        <h2 style={{ margin: 10 }}> Clients and products</h2>
        {this.state.WLallowed ? (
          <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h1>White Label</h1>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: "column" }}>
              <div>
                <Table>
                  <TableBody style={{ padding: 0 }}>
                    {this.makeLabelTableRows(this.state.allLabels || null)}
                  </TableBody>
                </Table>
              </div>
              <div>
                <h2>Add Label</h2>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    style={{ color: "#374799", margin: 0 }}
                    id="outlined-name"
                    multiline
                    label="Label"
                    value={this.state.newLabel}
                    onChange={change => {
                      this.setState({ newLabel: change.target.value });
                    }}
                    margin="normal"
                    variant="outlined"
                  />

                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.saveNewLabel(this.state.newLabel);
                    }}
                  >
                    Add new Label to database
                  </Button>
                  {this.state.LLstatus}
                </div>
              </div>
              <div style={{ marginTop: 50 }}>
                <h4>Download all data (slow)</h4>
                <Download
                  file={"listPromoterPlusPromotions"}
                  title={"promotions"}
                />
                <Download file={"listPromoterPlusPhotoss"} title={"photos"} />
                <Download
                  file={"listPromoterPlusResponsess"}
                  title={"responses"}
                />
                <Download
                  file={"listPromoterPlusSchedules"}
                  title={"schedules"}
                />
                <Download file={"listPromoterPlusClients"} title={"clients"} />
                <Download
                  file={"listPromoterPlusProducts"}
                  title={"products"}
                />
                <Download file={"listPromoterPlusUsers"} title={"users"} />
                <Download file={"listPromoterPlusLabelss"} title={"labels"} />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <div />
        )}
        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h1>Clients{this.state.CLreportstatus}</h1>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ flexDirection: "column" }}>
            <div>
              <Table>
                <TableBody style={{ padding: 0 }}>
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, width: 222, paddingTop: 17 }}
                    >
                      Label
                    </TableCell>
                    <TableCell
                      style={{ padding: 0, width: 222, paddingTop: 17 }}
                    >
                      Client
                    </TableCell>
                  </TableRow>
                  {this.makeClientTableRows(this.state.allclients || null)}
                </TableBody>
              </Table>
            </div>
            <div>
              <h2>Add Client</h2>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControl
                  variant="outlined"
                  style={{ padding: 0, margin: 0, width: 200 }}
                >
                  <InputLabel>Label</InputLabel>
                  <Select
                    disabled={this.state.disableclientlabelchange}
                    value={this.state.addClientLabel}
                    onChange={change => {
                      this.setState({ addClientLabel: change.target.value });
                    }}
                    input={<OutlinedInput labelWidth={73} />}
                  >
                    {this.state.allLabels.map((item, index) => (
                      <MenuItem value={item.label} key={index}>
                        {" "}
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  style={{ color: "#374799", margin: 0 }}
                  id="outlined-name"
                  multiline
                  label="Client Name"
                  value={this.state.newClient}
                  onChange={change => {
                    this.setState({ newClient: change.target.value });
                  }}
                  margin="normal"
                  variant="outlined"
                />

                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.saveNewClient(
                      this.state.newClient,
                      this.state.addClientLabel
                    );
                  }}
                >
                  Add Client to database
                </Button>
                {this.state.CLstatus}
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h1>Products</h1>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ flexDirection: "column" }}>
            <div>
              <h2>Product list{this.state.faqstatus}</h2>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Label</TableCell>
                    <TableCell>Product line</TableCell>
                    <TableCell>Products</TableCell>
                    <TableCell>Delete</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Icon</TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>Save</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ padding: 0 }}>
                  {this.makeProductTableRows(
                    this.state.allproductlines || null
                  )}
                </TableBody>
              </Table>
            </div>
            <div>
              <h2>Add Products</h2>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormControl
                  variant="outlined"
                  style={{ padding: 0, margin: 0, width: 200 }}
                >
                  <InputLabel>Label</InputLabel>
                  <Select
                    disabled={this.state.disableproductlabelchange}
                    value={this.state.addProductsLabel}
                    onChange={change => {
                      this.setState({ addProductsLabel: change.target.value });
                    }}
                    input={<OutlinedInput labelWidth={73} />}
                  >
                    {this.state.allLabels.map((item, index) => (
                      <MenuItem value={item.label} key={index}>
                        {" "}
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  style={{ color: "#374799", margin: 0 }}
                  id="outlined-name"
                  multiline
                  label="Product Line"
                  value={this.state.newProductLine}
                  onChange={change => {
                    this.setState({ newProductLine: change.target.value });
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  style={{ color: "#374799", margin: 0, width: 300 }}
                  id="outlined-name"
                  multiline
                  label="Products list (comma seperated)"
                  value={this.state.newProducts}
                  onChange={change => {
                    this.setState({ newProducts: change.target.value });
                  }}
                  margin="normal"
                  variant="outlined"
                />

                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.saveNewProductLine(
                      this.state.newProductLine,
                      this.state.newProducts,
                      this.state.addProductsLabel
                    );
                  }}
                >
                  Add Products to database
                </Button>

                {this.state.Pstatus}
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default ClientsAndProducts;
