/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPromoterPlusPromotion = /* GraphQL */ `
  mutation CreatePromoterPlusPromotion(
    $input: CreatePromoterPlusPromotionInput!
  ) {
    createPromoterPlusPromotion(input: $input) {
      id
      label
      promotionName
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
      }
      productLine
      products
      questions
      promotionScheduler {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      photos
      startdate
      enddate
      colours
    }
  }
`;
export const updatePromoterPlusPromotion = /* GraphQL */ `
  mutation UpdatePromoterPlusPromotion(
    $input: UpdatePromoterPlusPromotionInput!
  ) {
    updatePromoterPlusPromotion(input: $input) {
      id
      label
      promotionName
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
      }
      productLine
      products
      questions
      promotionScheduler {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      photos
      startdate
      enddate
      colours
    }
  }
`;
export const deletePromoterPlusPromotion = /* GraphQL */ `
  mutation DeletePromoterPlusPromotion(
    $input: DeletePromoterPlusPromotionInput!
  ) {
    deletePromoterPlusPromotion(input: $input) {
      id
      label
      promotionName
      client {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
      }
      productLine
      products
      questions
      promotionScheduler {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      photos
      startdate
      enddate
      colours
    }
  }
`;
export const createPromoterPlusPhotos = /* GraphQL */ `
  mutation CreatePromoterPlusPhotos($input: CreatePromoterPlusPhotosInput!) {
    createPromoterPlusPhotos(input: $input) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      filename
      phototype
      label
    }
  }
`;
export const updatePromoterPlusPhotos = /* GraphQL */ `
  mutation UpdatePromoterPlusPhotos($input: UpdatePromoterPlusPhotosInput!) {
    updatePromoterPlusPhotos(input: $input) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      filename
      phototype
      label
    }
  }
`;
export const deletePromoterPlusPhotos = /* GraphQL */ `
  mutation DeletePromoterPlusPhotos($input: DeletePromoterPlusPhotosInput!) {
    deletePromoterPlusPhotos(input: $input) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      filename
      phototype
      label
    }
  }
`;
export const createPromoterPlusResponses = /* GraphQL */ `
  mutation CreatePromoterPlusResponses(
    $input: CreatePromoterPlusResponsesInput!
  ) {
    createPromoterPlusResponses(input: $input) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      response
      label
    }
  }
`;
export const updatePromoterPlusResponses = /* GraphQL */ `
  mutation UpdatePromoterPlusResponses(
    $input: UpdatePromoterPlusResponsesInput!
  ) {
    updatePromoterPlusResponses(input: $input) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      response
      label
    }
  }
`;
export const deletePromoterPlusResponses = /* GraphQL */ `
  mutation DeletePromoterPlusResponses(
    $input: DeletePromoterPlusResponsesInput!
  ) {
    deletePromoterPlusResponses(input: $input) {
      id
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      schedule {
        id
        promotionID {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        user {
          id
          cognitoid
          label
          name
          email
          homelocation
          type
          access
          phone
        }
        responses {
          nextToken
        }
        promotionPhotos {
          nextToken
        }
        startdate
        enddate
        location
        gps
        signoffgps
        photosdone
        signindone
        stocktakedone
        stocktakevalues
        standpicdone
        promoterpicdone
        otheritemsdone
        signoffmanagername
        signofftimestamp
        signoffmanageremail
        signoffmanagerphonenumber
        signoffmanagerrating
        signoffmanagercomments
        label
      }
      timestamp
      response
      label
    }
  }
`;
export const createPromoterPlusSchedule = /* GraphQL */ `
  mutation CreatePromoterPlusSchedule(
    $input: CreatePromoterPlusScheduleInput!
  ) {
    createPromoterPlusSchedule(input: $input) {
      id
      promotionID {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
        }
        productLine
        products
        questions
        promotionScheduler {
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      responses {
        items {
          id
          timestamp
          response
          label
        }
        nextToken
      }
      promotionPhotos {
        items {
          id
          timestamp
          filename
          phototype
          label
        }
        nextToken
      }
      startdate
      enddate
      location
      gps
      signoffgps
      photosdone
      signindone
      stocktakedone
      stocktakevalues
      standpicdone
      promoterpicdone
      otheritemsdone
      signoffmanagername
      signofftimestamp
      signoffmanageremail
      signoffmanagerphonenumber
      signoffmanagerrating
      signoffmanagercomments
      label
    }
  }
`;
export const updatePromoterPlusSchedule = /* GraphQL */ `
  mutation UpdatePromoterPlusSchedule(
    $input: UpdatePromoterPlusScheduleInput!
  ) {
    updatePromoterPlusSchedule(input: $input) {
      id
      promotionID {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
        }
        productLine
        products
        questions
        promotionScheduler {
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      responses {
        items {
          id
          timestamp
          response
          label
        }
        nextToken
      }
      promotionPhotos {
        items {
          id
          timestamp
          filename
          phototype
          label
        }
        nextToken
      }
      startdate
      enddate
      location
      gps
      signoffgps
      photosdone
      signindone
      stocktakedone
      stocktakevalues
      standpicdone
      promoterpicdone
      otheritemsdone
      signoffmanagername
      signofftimestamp
      signoffmanageremail
      signoffmanagerphonenumber
      signoffmanagerrating
      signoffmanagercomments
      label
    }
  }
`;
export const deletePromoterPlusSchedule = /* GraphQL */ `
  mutation DeletePromoterPlusSchedule(
    $input: DeletePromoterPlusScheduleInput!
  ) {
    deletePromoterPlusSchedule(input: $input) {
      id
      promotionID {
        id
        label
        promotionName
        client {
          id
          clientname
          label
          reportconfig
          icon
          consumablesetup
        }
        productLine
        products
        questions
        promotionScheduler {
          nextToken
        }
        photos
        startdate
        enddate
        colours
      }
      user {
        id
        cognitoid
        label
        name
        email
        homelocation
        type
        access
        schedule {
          nextToken
        }
        phone
      }
      responses {
        items {
          id
          timestamp
          response
          label
        }
        nextToken
      }
      promotionPhotos {
        items {
          id
          timestamp
          filename
          phototype
          label
        }
        nextToken
      }
      startdate
      enddate
      location
      gps
      signoffgps
      photosdone
      signindone
      stocktakedone
      stocktakevalues
      standpicdone
      promoterpicdone
      otheritemsdone
      signoffmanagername
      signofftimestamp
      signoffmanageremail
      signoffmanagerphonenumber
      signoffmanagerrating
      signoffmanagercomments
      label
    }
  }
`;
export const createPromoterPlusClient = /* GraphQL */ `
  mutation CreatePromoterPlusClient($input: CreatePromoterPlusClientInput!) {
    createPromoterPlusClient(input: $input) {
      id
      clientname
      label
      promotion {
        items {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        nextToken
      }
      reportconfig
      icon
      consumableusage {
        items {
          id
          userID
          scheduleID
          timestamp
          usage
        }
        nextToken
      }
      consumablesetup
    }
  }
`;
export const updatePromoterPlusClient = /* GraphQL */ `
  mutation UpdatePromoterPlusClient($input: UpdatePromoterPlusClientInput!) {
    updatePromoterPlusClient(input: $input) {
      id
      clientname
      label
      promotion {
        items {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        nextToken
      }
      reportconfig
      icon
      consumableusage {
        items {
          id
          userID
          scheduleID
          timestamp
          usage
        }
        nextToken
      }
      consumablesetup
    }
  }
`;
export const deletePromoterPlusClient = /* GraphQL */ `
  mutation DeletePromoterPlusClient($input: DeletePromoterPlusClientInput!) {
    deletePromoterPlusClient(input: $input) {
      id
      clientname
      label
      promotion {
        items {
          id
          label
          promotionName
          productLine
          products
          questions
          photos
          startdate
          enddate
          colours
        }
        nextToken
      }
      reportconfig
      icon
      consumableusage {
        items {
          id
          userID
          scheduleID
          timestamp
          usage
        }
        nextToken
      }
      consumablesetup
    }
  }
`;
export const createPromoterPlusProduct = /* GraphQL */ `
  mutation CreatePromoterPlusProduct($input: CreatePromoterPlusProductInput!) {
    createPromoterPlusProduct(input: $input) {
      id
      clientid
      productline
      products
      label
      faqpdf
      faqicon
      faqtext
    }
  }
`;
export const updatePromoterPlusProduct = /* GraphQL */ `
  mutation UpdatePromoterPlusProduct($input: UpdatePromoterPlusProductInput!) {
    updatePromoterPlusProduct(input: $input) {
      id
      clientid
      productline
      products
      label
      faqpdf
      faqicon
      faqtext
    }
  }
`;
export const deletePromoterPlusProduct = /* GraphQL */ `
  mutation DeletePromoterPlusProduct($input: DeletePromoterPlusProductInput!) {
    deletePromoterPlusProduct(input: $input) {
      id
      clientid
      productline
      products
      label
      faqpdf
      faqicon
      faqtext
    }
  }
`;
export const createPromoterPlusUser = /* GraphQL */ `
  mutation CreatePromoterPlusUser($input: CreatePromoterPlusUserInput!) {
    createPromoterPlusUser(input: $input) {
      id
      cognitoid
      label
      name
      email
      homelocation
      type
      access
      schedule {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      phone
    }
  }
`;
export const updatePromoterPlusUser = /* GraphQL */ `
  mutation UpdatePromoterPlusUser($input: UpdatePromoterPlusUserInput!) {
    updatePromoterPlusUser(input: $input) {
      id
      cognitoid
      label
      name
      email
      homelocation
      type
      access
      schedule {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      phone
    }
  }
`;
export const deletePromoterPlusUser = /* GraphQL */ `
  mutation DeletePromoterPlusUser($input: DeletePromoterPlusUserInput!) {
    deletePromoterPlusUser(input: $input) {
      id
      cognitoid
      label
      name
      email
      homelocation
      type
      access
      schedule {
        items {
          id
          startdate
          enddate
          location
          gps
          signoffgps
          photosdone
          signindone
          stocktakedone
          stocktakevalues
          standpicdone
          promoterpicdone
          otheritemsdone
          signoffmanagername
          signofftimestamp
          signoffmanageremail
          signoffmanagerphonenumber
          signoffmanagerrating
          signoffmanagercomments
          label
        }
        nextToken
      }
      phone
    }
  }
`;
export const createPromoterPlusLabels = /* GraphQL */ `
  mutation CreatePromoterPlusLabels($input: CreatePromoterPlusLabelsInput!) {
    createPromoterPlusLabels(input: $input) {
      id
      label
      config
    }
  }
`;
export const updatePromoterPlusLabels = /* GraphQL */ `
  mutation UpdatePromoterPlusLabels($input: UpdatePromoterPlusLabelsInput!) {
    updatePromoterPlusLabels(input: $input) {
      id
      label
      config
    }
  }
`;
export const deletePromoterPlusLabels = /* GraphQL */ `
  mutation DeletePromoterPlusLabels($input: DeletePromoterPlusLabelsInput!) {
    deletePromoterPlusLabels(input: $input) {
      id
      label
      config
    }
  }
`;
export const createPromoterPlusConsumed = /* GraphQL */ `
  mutation CreatePromoterPlusConsumed(
    $input: CreatePromoterPlusConsumedInput!
  ) {
    createPromoterPlusConsumed(input: $input) {
      id
      userID
      scheduleID
      consumableID {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
      }
      timestamp
      usage
    }
  }
`;
export const updatePromoterPlusConsumed = /* GraphQL */ `
  mutation UpdatePromoterPlusConsumed(
    $input: UpdatePromoterPlusConsumedInput!
  ) {
    updatePromoterPlusConsumed(input: $input) {
      id
      userID
      scheduleID
      consumableID {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
      }
      timestamp
      usage
    }
  }
`;
export const deletePromoterPlusConsumed = /* GraphQL */ `
  mutation DeletePromoterPlusConsumed(
    $input: DeletePromoterPlusConsumedInput!
  ) {
    deletePromoterPlusConsumed(input: $input) {
      id
      userID
      scheduleID
      consumableID {
        id
        clientname
        label
        promotion {
          nextToken
        }
        reportconfig
        icon
        consumableusage {
          nextToken
        }
        consumablesetup
      }
      timestamp
      usage
    }
  }
`;
export const createPromoterPlusProjectTracker = /* GraphQL */ `
  mutation CreatePromoterPlusProjectTracker(
    $input: CreatePromoterPlusProjectTrackerInput!
  ) {
    createPromoterPlusProjectTracker(input: $input) {
      id
      test
    }
  }
`;
export const updatePromoterPlusProjectTracker = /* GraphQL */ `
  mutation UpdatePromoterPlusProjectTracker(
    $input: UpdatePromoterPlusProjectTrackerInput!
  ) {
    updatePromoterPlusProjectTracker(input: $input) {
      id
      test
    }
  }
`;
export const deletePromoterPlusProjectTracker = /* GraphQL */ `
  mutation DeletePromoterPlusProjectTracker(
    $input: DeletePromoterPlusProjectTrackerInput!
  ) {
    deletePromoterPlusProjectTracker(input: $input) {
      id
      test
    }
  }
`;
