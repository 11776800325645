import React from "react";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
class ModalImage extends React.Component{

  makeStockInModal(input) {
    //input = JSON.parse(input);
    return input.map((item, index) => (
      <TableRow key={index}>
        <TableCell>{item.stockItem}</TableCell>
        <TableCell>{item.count}</TableCell>
      </TableRow>
    ));
  }


  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          style={{
            content: {
            width: "40%",
            height:"40%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",

            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 99
          }}
          ariaHideApp={false}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <CloseIcon
              style={{
                fontSize: 60,
                color: "#666",
                justifyContent: "flex-end",
                marginLeft: "95%"
              }}
              onClick={() => this.props.closeMe()}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.makeStockInModal(this.props.data)}
              </TableBody>
            </Table>
          </div>
        </Modal>
      </div>
    );
  }
}
export default ModalImage
