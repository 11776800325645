import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CampaignCard from "./campaigncard2";
import TextField from "@material-ui/core/TextField";
import SearchFilterCampaign from "./searchfiltercampaign2";
import { isMobile } from "react-device-detect";
// import Utils from "./utils";

class Dash2Filters extends React.Component {
  makeButtonsOfPromotions(input) {
    // console.log(input)
    return input.map((item, index) => (
      <CampaignCard
        key={index}
        data={item}
        clientName={item.client.clientname}
        onEditPress={id => {
          this.props.goToPromotion({id,client:item.client.id});
        }}
        onPress={id => {
          var oldOptions = this.props.initialOptions;
          oldOptions.promotions[index].selected = !oldOptions.promotions[index]
            .selected;
          this.props.filterOutput(oldOptions);
        }}
      />
    ));
  }

  render() {
    return (
      <ExpansionPanel expanded={this.props.expanded||false}>
        <ExpansionPanelSummary onClick={()=>this.props.onExpand(!this.props.expanded)} expandIcon={<ExpandMoreIcon  />}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 19, marginRight: 50, fontWeight: "bold" }}>
              Filter
            </div>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-between"
            }}
          >
            <div>
              <TextField
                style={{ color: "#374799", margin: 10 }}
                id="outlined-name"
                label="Campaign start date"
                defaultValue={
                  this.props.initialOptions.promoliststartdate
                    .toISOString()
                    .split("T")[0]
                }
                onChange={change => {
                  if (change.target.value !== "") {
                    var oldOptions = this.props.initialOptions;
                    oldOptions.promoliststartdate = new Date(
                      change.target.value + "T00:00:00"
                    );
                    this.props.campaignFilterOutput(oldOptions);
                  }
                }}
                type="date"
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799", margin: 10 }}
                id="outlined-name"
                label="Campaign end date"
                defaultValue={
                  this.props.initialOptions.promolistenddate
                    .toISOString()
                    .split("T")[0]
                }
                onChange={change => {
                  if (change.target.value !== "") {
                    var oldOptions = this.props.initialOptions;
                    oldOptions.promolistenddate = new Date(
                      change.target.value + "T23:59:59"
                    );
                    this.props.campaignFilterOutput(oldOptions);
                  }
                }}
                type="date"
                margin="normal"
                variant="outlined"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",

                alignItems: "center"
              }}
            >
              <TextField
                style={{ color: "#374799", margin: 10 }}
                id="outlined-name"
                label="Data start date"
                defaultValue={
                  this.props.initialOptions.firstdate
                    .toISOString()
                    .split("T")[0]
                }
                onChange={change => {
                  if (change.target.value !== "") {
                    var oldOptions = this.props.initialOptions;
                    oldOptions.firstdate = new Date(
                      change.target.value + "T00:00:00"
                    );
                    this.props.campaignFilterOutput(oldOptions);
                  }
                }}
                type="date"
                margin="normal"
                variant="outlined"
              />
              <TextField
                style={{ color: "#374799", margin: 10 }}
                id="outlined-name"
                label="Data end date"
                defaultValue={
                  this.props.initialOptions.lastdate.toISOString().split("T")[0]
                }
                onChange={change => {
                  if (change.target.value !== "") {
                    var oldOptions = this.props.initialOptions;
                    oldOptions.lastdate = new Date(
                      change.target.value + "T23:59:59"
                    );
                    this.props.campaignFilterOutput(oldOptions);
                  }
                }}
                type="date"
                margin="normal"
                variant="outlined"
              />
              <SearchFilterCampaign
                users={this.props.initialOptions.products}
                input={this.props.initialOptions.productsSelected}
                width={isMobile ? "250" : "500px"}
                onChange={input => {
                  var oldOptions = this.props.initialOptions;
                  oldOptions.productsSelected = input;
                  this.props.filterOutput(oldOptions);
                }}
                title={"Products"}
              />
              <div style={{ margin: 5 }} />
              <SearchFilterCampaign
                users={this.props.initialOptions.regions}
                width={isMobile ? "250" : "500px"}
                input={this.props.initialOptions.regionsSelected}
                onChange={input => {
                  var oldOptions = this.props.initialOptions;
                  oldOptions.regionsSelected = input;
                  this.props.filterOutput(oldOptions);
                }}
                title={"Regions"}
              />
              <div style={{ margin: 5 }} />
              <SearchFilterCampaign
                users={this.props.initialOptions.promoters}
                width={isMobile ? "250" : "500px"}
                input={this.props.initialOptions.promotersSelected}
                onChange={input => {
                  var oldOptions = this.props.initialOptions;
                  oldOptions.promotersSelected = input;
                  this.props.filterOutput(oldOptions);
                }}
                title={"Promoters"}
              />
              <div style={{ margin: 5 }} />
              <SearchFilterCampaign
                users={this.props.initialOptions.clients}
                width={isMobile ? "250" : "500px"}
                input={this.props.initialOptions.clientsSelected}
                onChange={input => {
                  var oldOptions = this.props.initialOptions;
                  oldOptions.clientsSelected = input;
                  this.props.filterOutput(oldOptions);
                }}
                title={"Clients"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap"
              }}
            >
              {this.makeButtonsOfPromotions(
                this.props.initialOptions.promotions
              )}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default Dash2Filters;
