import React from "react";
import { withRouter } from "react-router-dom";
// import Utils from "./utils";
import GoogleMapReact from "google-map-react";
import Place from "@material-ui/icons/MyLocation";
import Tooltip from "@material-ui/core/Tooltip";

const AnyReactComponent = ({ text, colour }) => (
  <Tooltip title={text}>
    <Place style={{ marginLeft: -11, marginTop: -11, color: colour }} />
  </Tooltip>
);

class PromotionsMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  makeMapMarkers(input) {
    var markers = [];
    input.forEach(function(e) {
      e.promotionScheduler.items.forEach(function(f) {
        var lat = "";
        var lng = "";
        if (f.gps) {
          lat = f.gps.geo.lat;
          lng = f.gps.geo.lng;
        }
        if (f.location !== '{"address":""}') {
          var expectedlocations = f.location.geo;
          markers.push({
            promoterName: f.user.name,
            promoterLocationEXP: { geo: expectedlocations },
            promoterLocationACT: {
              geo: {
                lat: lat,
                lng: lng
              }
            }
          });
        }
      });
    });


    var output = [];
     // console.log("logs")
     //  console.log(input)
     //   console.log(markers)
    markers.forEach(function(a) {
      if (a.promoterLocationACT.geo.lat === ""){
        if (a.promoterLocationEXP.geo) output.push({
          name: a.promoterName + " (not checked in)",
          geo: {
            lat: a.promoterLocationEXP.geo.lat,
            lng: a.promoterLocationEXP.geo.lng
          },
          color: "#f00"
        });}
      else {
        // console.log("indiv")
        // console.log(a)

        if (a.promoterLocationEXP.geo ===null || typeof a.promoterLocationEXP.geo === "undefined") {
          output.push({
            name: a.promoterName + " (promoter does not have a store)",
            geo: {
              lat: a.promoterLocationACT.geo.lat,
              lng: a.promoterLocationACT.geo.lng
            },
            color: "#f00"
          });
        } else {
          var dLat =
            (a.promoterLocationACT.geo.lat - a.promoterLocationEXP.geo.lat) *
            0.0174532925199433;
          var dLon =
            (a.promoterLocationACT.geo.lng - a.promoterLocationEXP.geo.lng) *
            0.0174532925199433;
          var ae =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) *
              Math.sin(dLon / 2) *
              Math.cos(a.promoterLocationEXP.geo.lat * 0.0174532925199433) *
              Math.cos(a.promoterLocationEXP.geo.lng * 0.0174532925199433);
          var c = 2 * Math.atan2(Math.sqrt(ae), Math.sqrt(1 - ae)) * 6371 * 1.3;
          // Utils.log(c)
          if (c < 2)
            output.push({
              name: a.promoterName + " (correct location)",
              geo: {
                lat: a.promoterLocationEXP.geo.lat,
                lng: a.promoterLocationEXP.geo.lng
              },
              color: "#0f0"
            });
          else {
            output.push({
              name: a.promoterName + " (promoter too far from this store)",
              geo: {
                lat: a.promoterLocationEXP.geo.lat,
                lng: a.promoterLocationEXP.geo.lng
              },
              color: "#666"
            });
            output.push({
              name: a.promoterName + " (promoter too far)",
              geo: {
                lat: a.promoterLocationACT.geo.lat,
                lng: a.promoterLocationACT.geo.lng
              },
              color: "#f00"
            });
          }
        }
      }
    });
    // Utils.log("output");
    //  Utils.log(output);
    return output.map((item, index) => (
      <AnyReactComponent
        key={index}
        lat={item.geo.lat}
        lng={item.geo.lng}
        text={item.name}
        colour={item.color}
      />
    ));
  }

  render() {
    // Utils.log("this.props.markers")
     // Utils.log(this.props.markers)
    return (
      <div style={{ height: "100vh", width: "100%" }}>

        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDqX0qSKZ-GHCA-_7FFmtAsFtC_INd9UR4"
          }}
          defaultCenter={{
            lat: -26.04731,
            lng: 28.095623
          }}
          defaultZoom={11}
        >
          {this.makeMapMarkers(this.props.markers)}
        </GoogleMapReact>
      </div>
    );
  }
}
export default withRouter(PromotionsMaps);
