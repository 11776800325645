import React from "react";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";

export default class ConsumableChipFixed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
  }

  FixNullsConsumeSection(input) {
    if (typeof input === "string") input = JSON.parse(input)
    if (input === null)
      return { fixedAssetChips: [], consumableAssetChips: [] };
    else {
      if (typeof input.fixedAssetChips === "string") input.fixedAssetChips = JSON.parse(input.fixedAssetChips)
      if (typeof input.consumableAssetChips === "string") input.consumableAssetChips = JSON.parse(input.consumableAssetChips)
      return input};
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center"
        }}
      >
        <h4 style={{ marginRight: 10 }}>Fixed asset</h4>
        <TextField
          style={{ color: "#374799", marginRight: 10 }}
          id="outlined-name"
          label="Add fixed asset"
          value={this.state.text}
          onChange={change => {
            this.setState({
              text: change.target.value
            });
          }}
          margin="normal"
          variant="outlined"
        />
        <Button
          onClick={() => {
            var allclients = this.props.input;

            allclients.forEach(z => {
              if (z.id === this.props.id) {
                z.consumablesetup = this.FixNullsConsumeSection(
                  z.consumablesetup
                );
                z.consumablesetup.fixedAssetChips.push(this.state.text.trim());
                z.consumablesetup.fixedAssetChips = [...new Set(z.consumablesetup.fixedAssetChips)]
              }
            });
            this.setState({text:""})
            this.props.onChange(allclients);
          }}
        >
          <AddIcon />
        </Button>

        {this.FixNullsConsumeSection(
          this.props.input.filter(z => z.id === this.props.id)[0]
            .consumablesetup
        ).fixedAssetChips.map((e, i) => (
          <Chip
            key={i}
            label={e}
            style={{
              backgroundColor: "#aaa",
              marginRight: 10
            }}
            onDelete={() => {
              var allclients = this.props.input;
              allclients.forEach(z => {
                if (z.id === this.props.id) {
                  z.consumablesetup.fixedAssetChips.splice(i, 1);
                }
              });
              this.props.onChange(allclients);
            }}
          />
        ))}
      </div>
    );
  }
}
