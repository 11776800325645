import React from "react";
import "./App.css";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import Utils from "./utils";
// import MenuIcon from "@material-ui/icons/Menu";
import SignIn from "./signin";
import SignInMobile from "./signinmobile";
import SignUp from "./signup";
import Forgot from "./forgot";
import Compat from "./compat";
import Invited from "./invited";
import Downloader from "./downloader";
import Mobile from "./mobile";
import Report from "./report";
import ReportBI from "./reportBI";
import Action from "./action";
import Temppage from "./temppage";
import SignOffSummary from "./signoffsummary";
import Uploader from "./uploader";
import Repair from "./repair";
import SignOff from "./signoff";
import Photo from "./photo";
// import CampaignDashboard from "./campaigndashboard";
import CampaignDashboard2 from "./campaigndashboard3";
import ClientDashboard from "./clientdashboard";
// import OperationDashboard from "./operationdashboard";
import Drawer from "@material-ui/core/Drawer";
import Nav from "./nav";
import InviteUser from "./inviteuser";
import Promotions from "./promotions";
import PromotionsEdit from "./promotionsedit";
import PromotionsEntry from "./promotionsentry";
import ClientsAndProducts from "./clientsandproducts";
import Summary from "./summary";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerWidth: 0,
      drawerOpen: true,
      drawerMini: true,
      pageName: "Campaign Dashboard"
    };
  }

  render() {
    if (isMobile)
      return (
        <Router>
          <div>
            <Route path="/signup" component={SignUp} />
            <Route path="/signinmobile" component={SignInMobile} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/invited" component={Invited} />
            <Route path="/" exact component={Mobile} />
            <Route path="/action" exact component={Action} />
            <Route path="/temppage" exact component={Temppage} />
            <Route path="/photo" exact component={Photo} />
            <Route path="/compat" exact component={Compat} />
            <Route path="/signoff" exact component={SignOff} />
            <Route path="/operations" component={CampaignDashboard2} />
          </div>
        </Router>
      );
    else
      return (
        <Router>
          <div style={{ backgroundColor: "#eeeeee" }}>
            <Drawer
              variant="persistent"
              anchor="left"
              open={this.state.drawerOpen}
            >
              <Nav
                getWidth={w => this.setState({ drawerWidth: w })}
                mini={() =>
                  this.setState({ drawerMini: !this.state.drawerMini })
                }
                setPageName={input => this.setState({ pageName: input })}
              />
            </Drawer>
            <div
              style={{
                paddingLeft: this.state.drawerMini ? 150 : 250,
                margin: 0
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }} />
              <Route path="/" exact component={CampaignDashboard2} />
              <Route path="/cdashboard" exact component={ClientDashboard} />
              <Route path="/signup" component={SignUp} />
              <Route path="/signin" component={SignIn} />
              <Route path="/downloader" component={Downloader} />
              <Route path="/forgot" component={Forgot} />
              <Route path="/uploader" component={Uploader} />
              <Route path="/repair" component={Repair} />
              <Route path="/signoffsummary" component={SignOffSummary} />
              <Route path="/invited" component={Invited} />
              <Route path="/inviteuser" component={InviteUser} />
              <Route path="/report" component={Report} />
              <Route path="/reportBI" component={ReportBI} />

              <Route path="/promotionsentry" component={PromotionsEntry} />
              <Route path="/promotions" component={Promotions} />
              <Route path="/promotionsedit" component={PromotionsEdit} />
              <Route
                path="/clientsandproducts"
                component={ClientsAndProducts}
              />
              <Route path="/summary" component={Summary} />
            </div>
          </div>
        </Router>
      );
  }
}

export default App;
