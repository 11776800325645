import React from "react";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import ExifOrientationImg from "react-exif-orientation-img";
// import Dialog from "@material-ui/core/Dialog";
class ModalImage extends React.Component {
  makeImagesInModal(input) {
    return input.map((item, index) => (
      <div key={index} style={{ display: "flex", justifyContent: "center" }} >
        <ExifOrientationImg src={item} alt="" width={"100%"} />
      </div>
    ));
  }

  render() {
    return (
      <Modal
        style={{
          content: {
          width: "70%",
          height:"70%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          }
          // width: "50%",
          // maxWidth: "60%",
          // top: "50%",
          // left: "50%",
          // right: "auto",
          // bottom: "auto",
          // marginRight: "10%",
          // // transform: "translate(-50%, -50%)",
          // zIndex: 99
        }}
        maxWidth="80%"
        isOpen={this.props.isOpen}
        ariaHideApp={false}

      >
      <CloseIcon
        style={{
          position:'sticky',
          left:10,
          top:10,
          marginLeft: "90%",
          fontSize: 60,
          color: "#666",
          justifyContent: "flex-end",

        }}
        onClick={() => this.props.closeMe()}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          {this.makeImagesInModal(this.props.images)}
        </div>
      </Modal>
    );
  }
}
export default ModalImage;
