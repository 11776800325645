import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { isMobile, isIOS } from "react-device-detect";
import Button from "@material-ui/core/Button";
import Utils from "./utils";
import "react-html5-camera-photo/build/css/index.css";
import ImageUploader from "react-images-upload";
import imageCompression from "browser-image-compression";
// var Buffer = require("buffer/").Buffer;

class Photo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      time: 0,
      isOn: false,
      start: 0,
      seconds: 0,
      timer: 30,
      picture: {},
      preview: false,
      lockaccept: false
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser().catch(() =>
      this.props.history.push("/signinmobile")
    );
    if (!isMobile) this.props.history.push("/desktop");
  }
  startTimer(starttime) {
    this.setState({
      isOn: true,
      status: "Hold steady",
      time: this.state.time,
      start: Date.now() - this.state.time
    });
    this.timer = setInterval(() => {
      var timeInSeconds = parseInt((Date.now() - this.state.start) / 1000);
      if (timeInSeconds !== this.state.seconds)
        if (this.state.seconds === starttime) this.stopTimer();
    }, 1);
  }

  stopTimer() {
    this.setState({ isOn: false });
    clearInterval(this.timer);
    console.log("click");
  }

  resetTimer() {
    this.setState({ time: 0, isOn: false });
  }

  onCameraError(error) {
    console.error("onCameraError", error);
  }

  onCameraStart(stream) {
    Utils.log("onCameraStart");
  }

  onCameraStop() {
    Utils.log("onCameraStop");
  }

  async savePicToS3(packet) {
    if (navigator.onLine && (await Utils.isFastInternet())) {
      let pic = await Utils.savePicToS3(packet);
      await Utils.cleanupOfflineData("savePicToS3");
      if (!isIOS) navigator.vibrate([100, 100, 100, 100, 100]);
      if (pic)
        if (this.props.location.state.type === "Sign in")
          this.props.location.state.scheduleID.signindone = true;
      if (this.props.location.state.type === "Stand")
        this.props.location.state.scheduleID.standpicdone = true;
      if (this.props.location.state.type === "Promoter")
        this.props.location.state.scheduleID.promoterpicdone = true;
      if (this.props.location.state.type === "Other photos")
        this.props.location.state.scheduleID.otheritemsdone = true;
      this.props.history.push("/action", {
        type: this.props.location.state.type,
        promotion: this.props.location.state.promotion,
        user: this.props.location.state.user,
        scheduleID: this.props.location.state.scheduleID
      });
    } else {
      Utils.addOffLineDataStore("savePicToS3", packet);
      if (!isIOS) navigator.vibrate(1000);
      if (this.props.location.state.type === "Sign in")
        this.props.location.state.scheduleID.signindone = true;
      if (this.props.location.state.type === "Stand")
        this.props.location.state.scheduleID.standpicdone = true;
      if (this.props.location.state.type === "Promoter")
        this.props.location.state.scheduleID.promoterpicdone = true;
      if (this.props.location.state.type === "Other photos")
        this.props.location.state.scheduleID.otheritemsdone = true;
      this.props.history.push("/action", {
        type: this.props.location.state.type,
        promotion: this.props.location.state.promotion,
        user: this.props.location.state.user,
        scheduleID: this.props.location.state.scheduleID
      });
    }
  }

  async upload() {
    this.setState({ status: "Uploading, please wait" });
    var packet = {};
    try {
      let r = await imageCompression.getFilefromDataUrl(this.state.picture);
      var s = await imageCompression(r, { maxSizeMB: 0.7 });
      var t = await imageCompression.getDataUrlFromFile(s);
      packet.picture = t;
      var extension = packet.picture
        .split(",")[0]
        .split(";")[0]
        .split(":")[1]
        .split("/")[1];
      packet.filename = Utils.generateName() + "." + extension;
      packet.userid = this.props.location.state.user.id;
      packet.promoterPlusPhotosScheduleId = this.props.location.state.scheduleID.id;
      var date = new Date(Date.now());
      date.setHours(date.getHours() + 2);
      packet.timestamp = date;
      packet.phototype = this.props.location.state.type;

      this.savePicToS3(packet);
    } catch (err) {
      console.log(err);
    }
  }

  onTakePhoto(dataUri) {
    this.setState({ picture: dataUri, preview: true });
  }

  render() {
    return (
      <div>
        {isIOS ? (
          <Button
            style={{
              textTransform: "none",
              width: "100%"
            }}
            variant="contained"
            color="primary"
            onClick={() =>
              this.props.history.push("/action", {
                type: this.props.location.state.type,
                promotion: this.props.location.state.promotion,
                user: this.props.location.state.user,
                scheduleID: this.props.location.state.scheduleID
              })
            }
          >
            Back
          </Button>
        ) : (
          <div />
        )}

        {this.state.preview ? (
          <div
            style={{
              margin: 10,
              color: "#666",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <h2>Preview</h2>
            {this.state.status}
            <img alt="" width="100%" src={this.state.picture} />
            <Button
              style={{
                margin: 5,
                textTransform: "none"
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ preview: false, picture: {} });
              }}
              width="100%"
            >
              Retry
            </Button>
            <Button
              style={{
                margin: 5,
                textTransform: "none"
              }}
              disabled={this.state.lockaccept}
              variant="contained"
              color="primary"
              onClick={() => {
                this.setState({ lockaccept: true });
                this.upload();
              }}
            >
              Accept
            </Button>
          </div>
        ) : (
          <div
            style={{
              margin: 10,
              color: "#666",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <h2>Upload photo</h2>
            <ImageUploader
              withIcon={false}
              buttonText="Take/Upload photo"
              withPreview={false}
              label=""
              fileContainerStyle={{
                margin: 0,
                padding: 0,
                backgroundColor: "#eeeeee",
                shadowColor: "#ddd",
                borderWidth: 0,
                borderRadius: 0,
                borderColor: "#ddd",
                boxShadow: `0px 0px 0px 0`,
                display: "flex",
                flexDirection: "row",
                alignSelf: "center"
              }}
              singleImage={true}
              onChange={(pictureFiles, pictureDataURLs) => {
                // this.compress(pictureDataURLs[0])
                // console.log(pictureDataURLs[0])
                this.onTakePhoto(pictureDataURLs[0]);
              }}
              imgExtension={[".jpg", ".png", "jpeg"]}
              maxFileSize={7242880}
            />

            <div>{this.state.status}</div>
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(Photo);
