import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";


// import logo from './logo.svg';
// import './App.css';
import { withRouter } from "react-router-dom";
// import { withAuthenticator } from "aws-amplify-react"; // or 'aws-amplify-react-native';
// import Amplify from "aws-amplify";
// import aws_exports from "./aws-exports"; // if you are using Amplify CLI
// Amplify.configure(aws_exports);

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      pin: "",
      code: "",
      status: ""
    };
  }

  getReset(phone) {

    Auth.forgotPassword(phone)
      .then(result => this.setState({ status: "Reset code emailed to you" }))
      .catch(result => this.setState({ status: result.message }));
  }

  doneReset(phone, code, pin) {

    Auth.forgotPasswordSubmit(phone, code, pin)
      .then(result => this.props.history.push("/signin"))
      .catch(result => this.setState({ status: result.message }));
  }

  render() {
    return (
      <div
        style={{ color: "#374799", margin: 5, marginLeft: 10, marginRight: 10 }}
      >

        <TextField
          style={{ color: "#374799", minWidth: "100%" }}
          id="outlined-name"
          label="Email"
          value={this.state.phone}
          onChange={change => {
            this.setState({ phone: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <Button
          style={{ background: "#03a9fc", marginTop: 15, width: "100%" }}
          variant="contained"
          color="primary"
          onClick={() => this.getReset(this.state.phone.toLowerCase().trim())}
        >
          Get reset code via email
        </Button>
        <TextField
          style={{ color: "#374799", minWidth: "100%" }}
          id="outlined-name"
          label="Reset code"
          value={this.state.code}
          onChange={change => {
            this.setState({ code: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          style={{ color: "#374799", minWidth: "100%" }}
          id="outlined-password-input"
          type="password"
          label="New password"
          value={this.state.pin}
          onChange={change => {
            this.setState({ pin: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />

        <br />
        <Button
          style={{ background: "#03a9fc", marginTop: 15, width: "100%" }}
          variant="contained"
          color="primary"
          onClick={() =>
            this.doneReset(
              this.state.phone.toLowerCase().trim(),
              this.state.code.trim(),
              this.state.pin.trim()
            )
          }
        >
          Reset password
        </Button>
        <br />
        {this.state.status}
      </div>
    );
  }
}

export default withRouter(Forgot);
