import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Utils from "./utils";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import IntegrationAutosuggest from "./autosuggest";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
// import TableHead from "@material-ui/core/TableHead";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import PromotionsMaps from "./promotionsmaps";
import StartCampIcon from "@material-ui/icons/FlashOn";
import DateIcon from "@material-ui/icons/DateRange";
import QuestionIcon from "@material-ui/icons/LiveHelp";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
// import CupIcon from "@material-ui/icons/LocalCafe";
import LocationIcon from "@material-ui/icons/AddLocation";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
// import Chip from "@material-ui/core/Chip";
import PromotionsPromoters from "./promotionspromoterstree";
import PromotionsQuestions from "./promotionsquestions";
import PromotionsQuestionsPhotos from "./promotionsquestionsphotos";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SwatchesPicker } from "react-color";
import Button from "@material-ui/core/Button";

// import MenuIcon from "@material-ui/icons/Menu";
// import AddIcon from "@material-ui/icons/Add";

const AnyReactComponent = ({ text }) => (
  <div style={{ fontSize: 20 }}>{text}</div>
);

class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5,
          marginRight: 10
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    let clientid = this.props.history.location.search.replace("?", "");
    clientid = clientid
      .split("&")
      .filter(z => z.indexOf("client") > -1)[0]
      .split("=")[1];
    var date = new Date(Date.now());
    date.setHours(date.getHours() + 2);
    this.state = {
      promotionName: "",
      startDate: date,
      endDate: date,
      startTime: "07:00",
      endTime: "17:00",
      client: clientid,
      productline: "",
      products: "",
      promoters: [],
      promotertree: [],
      users: [],
      allclients: [],
      photos: [
        { name: "Sign in", repeating: false, order: 0 },
        { name: "Promoter", repeating: false, order: 1 },
        { name: "Stand", repeating: false, order: 2 },
        { name: "Customer interactions", repeating: true, order: 3 }
      ],
      questions: [
        {
          question: "Saletype",
          type: "toggle",
          items: "Sale,Taste"
        },
        {
          question: "product",
          type: "toggle",
          items: "This will come from the promotion details section"
        },
        {
          question: "repeattype",
          type: "toggle",
          items: "First time, Repeat"
        },
        {
          question: "carttype",
          type: "toggle",
          items: "Basket, Trolley, None"
        },
        {
          question: "gender",
          type: "toggle",
          items: "Female, Other, Male"
        },
        {
          question: "race",
          type: "toggle",
          items: "Asian, Black, Coloured, Indian, White"
        },
        {
          question: "taste",
          type: "multi",
          items: "Sour, Salty, Sweet, Bitter, Spicy"
        },
        {
          question: "age",
          type: "toggle",
          items: "16-20, 21-30, 31-50, 51+"
        }

      ],
      allproductlines: [],
      colours: {
        background: "#fff",
        backgroundfont: "#000",
        inactivebackground: "#90a4ae",
        inactivefont: "#000",
        activebackground: "#0d47a1",
        activefont: "#fff"
      },
      status: "",
      consumableSetup: [],
      locations: [],
      edit: false,
      editid: "",
      label: "",
      alert: "",
      consumptionitems: []
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  validationCheck() {
    var valid = true;
    if (this.state.promoters.length === 10) {
      this.doSnackBar("No promoters added...");
      valid = false;
    } else if (this.state.questions.length === 0) {
      this.doSnackBar("No questions added...");
      valid = false;
    } else if (this.state.promotionName === "") {
      this.doSnackBar("No promotion name...");
      valid = false;
    } else if (this.state.client === "") {
      this.doSnackBar("No client selected...");
      valid = false;
    } else if (this.state.productline === "") {
      this.doSnackBar("No productline selected...");
      valid = false;
    } else if (this.state.products === "") {
      this.doSnackBar("No products selected...");
      valid = false;
    }

    var d1 =
      this.state.startDate.toISOString().substr(0, 10) + "T00:00:00.000Z";

    var sd1 = new Date(d1);
    var d2 = this.state.endDate.toISOString().substr(0, 10) + "T23:59:59.000Z";
    var ed1 = new Date(d2);
    if (ed1 < sd1) {
      this.doSnackBar("Campaign dates invalid");
      valid = false;
    }
    this.state.promotertree.forEach(a => {
      a.nodes.forEach(b => {
        if (typeof b.dates !== "undefined") {
          b.dates.forEach(c => {
            c.setHours(c.getHours() + 2);
            var d3 =
              c.toISOString().substr(0, 10) + "T" + b.startTime + ":00.000Z";
            var sd2 = new Date(d3);
            if (sd2 < sd1 || sd2 > ed1) {
              this.doSnackBar(
                "Promoter " + b.label + " dates not within campaign dates"
              );
              valid = false;
            }
          });
        } else {
          this.doSnackBar("dates not added");
          valid = false;
        }
      });
    });
    return valid;
  }

  async deletePromoterPlusPromotion(input) {
    // Utils.log(this.props.location.state.id)
    let s = await Utils.deletePromoterPlusPromotion(
      this.state.editid
    );
    // console.log(s);

    for (var i = 0; i < s.promotionScheduler.items.length; i++) {
      await Utils.deletePromoterPlusSchedule(s.promotionScheduler.items[i].id);
    }

    this.props.history.push("/");
  }

  maketree(input) {
    let output = [];
    let collectedids = [];

    input.forEach(a => {
      collectedids.push(a.id);
      let location = JSON.parse(a.location);
      if (output.filter(z => z.key === location.address).length === 0) {
        let item = {
          location,
          key: location.address,
          label: location.address.split(",")[0],
          nodes: []
        };
        if (item.nodes.filter(z => z.key === a.user.name).length === 0) {
          item.nodes.push({
            promoter: a.user,
            data: [],
            key: a.user.name,
            label: a.user.name,
            dates: [],
            startTime: a.startdate.substr(11, 5),
            endTime: a.enddate.substr(11, 5)
          });
        }
        output.push(item);
      }
      if (output.filter(z => z.key === location.address).length > 0) {
        output.forEach(q => {
          if (q.key === location.address) {
            let item = q;
            if (item.nodes.filter(z => z.key === a.user.name).length === 0) {
              item.nodes.push({
                promoter: a.user,
                data: [],
                key: a.user.name,
                label: a.user.name,
                dates: [],
                startTime: a.startdate.substr(11, 5),
                endTime: a.enddate.substr(11, 5)
              });
            }
          }
        });
      }
    });
    this.setState({ collectedids });
    input.forEach(a => {
      let location = JSON.parse(a.location);
      output.forEach(b => {
        b.nodes.forEach(c => {
          if (c.key === a.user.name && b.key === location.address) {
            let dt = new Date(a.startdate.substr(0, 10) + "T00:00:00.000Z");
            dt.setHours(dt.getHours() - 2);
            c.dates.push(dt);
            c.data.push(a);
          }
        });
      });
    });
    return output;
  }

  async getConsumableDataByUserID(id) {
    this.setState(prevState => {
      let consumptionitems = prevState.consumptionitems;
      if (consumptionitems.filter(z => z.userID === id.userid).length === 0)
        consumptionitems.push({ userID: id.userid, usage: [] });
      if (
        consumptionitems
          .filter(z => z.userID === id.userid)[0]
          .usage.filter(z => z.item === id.item).length === 0
      )
        consumptionitems
          .filter(z => z.userID === id.userid)[0]
          .usage.push({ item: id.item, amount: parseInt(id.value) });
      else
        consumptionitems
          .filter(z => z.userID === id.userid)[0]
          .usage.filter(z => z.item === id.item)[0].amount += parseInt(
          id.value
        );
      return { ...prevState, consumptionitems };
    });
  }

  async getConsumableData(id) {
    function compressor(input) {
      const result = input.reduce((acc, d) => {
        const found = acc.find(a => a.item === d.item);
        if (!found) acc.push({ item: d.item, amount: d.amount });
        else {
          found.amount += parseInt(d.amount);
        }
        return acc;
      }, []);

      return result;
    }

    function clean(consumptionitems, input) {
      input.data.forEach(z => {
        if (consumptionitems.filter(a => a.userID === z.userID).length === 0)
          consumptionitems.push(z);
        else if (
          consumptionitems.filter(a => a.userID === z.userID).length > 0
        ) {
          consumptionitems.filter(
            a => a.userID === z.userID
          )[0].usage = consumptionitems
            .filter(a => a.userID === z.userID)[0]
            .usage.concat(z.usage);
        }
      });
      return consumptionitems;
    }

    this.doSnackBar("Collecting inventory");
    let s = await Utils.getConsumableDataNEW(id, null);
    this.setState(prevState => {
      let consumptionitems = prevState.consumptionitems;
      consumptionitems = clean(consumptionitems, s);
      consumptionitems.forEach(z => {
        z.usage = compressor(z.usage);
      });

      return { ...prevState, consumptionitems };
    });
    while (s.nextToken !== null) {
      this.doSnackBar("Collected page, getting more");
      s = await Utils.getConsumableDataNEW(id, s.nextToken);
      this.setState(prevState => {
        let consumptionitems = prevState.consumptionitems;
        consumptionitems = clean(consumptionitems, s);
        consumptionitems.forEach(z => {
          z.usage = compressor(z.usage);
        });

        return { ...prevState, consumptionitems };
      });
    }
    if (s.nextToken === null) this.doSnackBar("Collection done");
  }

  async componentDidMount() {
    if (this.props.history.location.search.indexOf("edit") > -1) {
      //   // this.setState({ status: "Getting data. Please wait..." });
      this.doSnackBar("Getting data. Please wait...");
    }
    Auth.currentAuthenticatedUser().catch(() =>
      this.props.history.push("/signin")
    );
    if (isMobile) this.props.history.push("/mobile");
    var date = new Date(Date.now());
    date.setHours(date.getHours() + 2);

    let p = await Auth.currentAuthenticatedUser();
    let t = await Utils.getUser(p.username);
    if (t.label === "All") this.setState({ alert: "DONT DO THIS IN RED MODE" });
    if (t.type !== "Admin") this.props.history.push("/");
    this.setState({ label: t.label });
    // let cl = await Utils.getAllClients({ label: { eq: t.label } });

    let clientid = this.props.history.location.search.replace("?", "");
    clientid = clientid
      .split("&")
      .filter(z => z.indexOf("client") > -1)[0]
      .split("=")[1];
    let cl = await Utils.getClientByID(clientid);
    this.getConsumableData(clientid);
    let pl = await Utils.getAllProductLines({ label: { eq: t.label } });
    pl = pl.filter(z=>z.clientid===clientid)
    let users = await Utils.getAllUsers({
      label: { eq: t.label },
      name: { notContains: "~" }
    });
    users.forEach(z => {
      if (z.homelocation !== null) z.homelocation = JSON.parse(z.homelocation);
    });
    this.setState({
      users,
      allclients: cl,
      allproductlines: pl
    });
    var promo = {};
    // console.log(this.props.history.location)

    var urltype = this.props.history.location.search;
    if (urltype.indexOf("edit") > -1) {
      // this.setState({ status: "Getting data. Please wait..." });
      // this.doSnackBar("Getting data. Please wait...");

      let promoid = urltype;
      promoid = promoid
        .split("&")
        .filter(z => z.indexOf("edit") > -1)[0]
        .split("=")[1];
      promo = await Utils.getPromotionByPromotionIdNEW(promoid);
      let promotertree = this.maketree(promo.promotionScheduler.items);

      // var tt = [];
      // var r = promo.promotionScheduler.items[0];
      // r.promotionid = promo.id
      // r.promoterId = r.user.id
      // r.promoterLocation = r.location
      // r.promoterStartDate = new Date(r.startdate);
      // r.promoterEndDate = new Date(r.enddate);
      // for (var i = 0; i < 200; i++) {
      //   tt.push(r);
      // }
      // await Promise.all(tt.map(Utils.createPromotionScheduleNORETURN));

      var scheduleids = [];
      promo.promotionScheduler.items.forEach(function(s) {
        scheduleids.push(s.id);
      });
      let users = await Promise.all(scheduleids.map(Utils.getScheduleById));
      promo.promotionScheduler.items.forEach(function(s) {
        users.forEach(function(u) {
          if (s.id === u.id) {
            s.userid = u.user.id;
            s.username = u.user.name;
          }
        });
      });
      var promoters = [];
      if (promo.photos === null)
        promo.photos = JSON.stringify(this.state.photos);
      promo.promotionScheduler.items.forEach(function(s) {
        var item = {};
        item.promoterEndDate = s.enddate.split("T")[0];
        item.promoterEndTime = s.enddate.split("T")[1].substr(0, 5);
        item.promoterStartDate = s.startdate.split("T")[0];
        item.promoterStartTime = s.startdate.split("T")[1].substr(0, 5);
        item.promoterLocation = JSON.parse(s.location);
        item.promoterId = s.userid;
        item.promoterName = s.username;
        item.scheduleID = s.id;
        promoters.push(item);
      });
      promoters = promoters.sort((a, b) => {
        if (
          a.promoterStartDate + a.promoterName >
          b.promoterStartDate + b.promoterName
        )
          return 1;
        else if (
          a.promoterStartDate + a.promoterName <
          b.promoterStartDate + b.promoterName
        )
          return -1;
        else return 0;
      });
      // console.log(cons);
      this.setState({
        statusbar: false,
        promotertree,
        promotionName: promo.promotionName,
        startDate: new Date(promo.startdate),
        endDate: new Date(promo.enddate),
        startTime: promo.startdate.split("T")[1].substr(0, 5),
        endTime: promo.enddate.split("T")[1].substr(0, 5),
        client: promo.client.id,
        productline: promo.productLine,
        products: JSON.parse(promo.products),
        promoters: promoters,
        edit: true,
        editid: promo.id,
        questions: JSON.parse(promo.questions),
        photos: JSON.parse(promo.photos),
        colours: JSON.parse(promo.colours)
      });
    }
  }

  getTimes() {
    return Utils.getTimeOptionsShort().map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));
  }

  makeMapMarkers(input) {
    var output = [];
    input.forEach(function(e) {
      if (e.promoterLocation.geo) output.push(e);
    });
    // Utils.log(this.state.output);
    if (output.length > 0)
      return output.map((item, index) => (
        <AnyReactComponent
          key={index}
          lat={item.promoterLocation.geo.lat}
          lng={item.promoterLocation.geo.lng}
          text={item.name}
        />
      ));
    else return null;
  }

  getNames(input) {
    var output = "";
    input.forEach(function(e, idx) {
      if (idx > 0) output += ", ";
      output += e.name;
    });
    return output;
  }

  getProductNames() {
    return this.state.allproductlines.map((item, index) => (
      <MenuItem key={index} value={item.id} name={item.name}>
        {item.productline}
      </MenuItem>
    ));
  }

  checker(input) {
    // console.log(input);
  }

  async deleteSchedule(input) {
    this.doSnackBar("Deleting.  Please wait...");
    await Utils.deletePromoterPlusSchedule(input);
    this.componentDidMount();
  }
  async saveSchedule(input) {
    this.doSnackBar("Saving. Please wait...");
    input.id = input.scheduleID;

    input.promoterLocation = JSON.stringify(input.promoterLocation);
    var t3 = new Date(input.promoterStartDate + "T" + input.promoterStartTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterStartDate = t3;
    delete input.promoterStartTime;
    t3 = new Date(input.promoterEndDate + "T" + input.promoterEndTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterEndDate = t3;
    delete input.promoterEndTime;
    await Utils.updatePromotionSchedule(input);
    this.componentDidMount();
  }

  async saveNewSchedule(input) {
    this.doSnackBar("Saving. Please wait...");
    input.promotionid = this.state.editid;

    input.promoterLocation = JSON.stringify(input.promoterLocation);
    var t3 = new Date(input.promoterStartDate + "T" + input.promoterStartTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterStartDate = t3;
    delete input.promoterStartTime;
    t3 = new Date(input.promoterEndDate + "T" + input.promoterEndTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterEndDate = t3;
    delete input.promoterEndTime;
    await Utils.createPromotionSchedule(input);
    this.componentDidMount();
  }

  async saveEditedPromotion() {
    var promotioncomplete = {};
    promotioncomplete.id = this.state.editid;
    promotioncomplete.questions = this.state.questions;
    promotioncomplete.products = JSON.stringify(this.state.products);
    promotioncomplete.questions.forEach(function(e) {
      if (e.question === "product") {
        e.items = promotioncomplete.products;
      }
    });
    promotioncomplete.questions = JSON.stringify(promotioncomplete.questions);
    promotioncomplete.photos = JSON.stringify(this.state.photos);
    promotioncomplete.promotionName = this.state.promotionName;
    promotioncomplete.promoterPlusPromotionClientId = this.state.client;
    promotioncomplete.productline = this.state.productline;
    promotioncomplete.colours = JSON.stringify(this.state.colours);
    var t2 =
      this.state.startDate.toISOString().substr(0, 10) +
      "T" +
      this.state.startTime;
    var t3 = new Date(t2);
    t3.setHours(t3.getHours() + 2);
    promotioncomplete.startDate = t3;
    t2 =
      this.state.endDate.toISOString().substr(0, 10) + "T" + this.state.endTime;
    t3 = new Date(t2);
    t3.setHours(t3.getHours() + 2);
    promotioncomplete.endDate = t3;
    promotioncomplete.label = this.state.label;
    let s = await Utils.updatePromotion(promotioncomplete);

    let promoters = [];
    let missingids = [];
    let collectedids = this.state.collectedids;
    let newschedules = [];
    this.state.promotertree.forEach(a => {
      a.nodes.forEach(b => {
        b.dates.forEach(c => {
          c.setHours(c.getHours() + 2);
          var d3 =
            c.toISOString().substr(0, 10) + "T" + b.startTime + ":00.000Z";
          var sd2 = new Date(d3);
          var d4 = c.toISOString().substr(0, 10) + "T" + b.endTime + ":00.000Z";
          var sd3 = new Date(d4);
          let id = null;
          if (
            b.data &&
            b.data.filter(
              d => d.startdate.substr(0, 10) === c.toISOString().substr(0, 10)
            ).length > 0
          ) {
            id = b.data.filter(
              d => d.startdate.substr(0, 10) === c.toISOString().substr(0, 10)
            )[0].id;

            promoters.push({
              id: id,
              promotionid: s.id,
              promoterLocation: JSON.stringify(a.location),
              promoterStartDate: sd2.toISOString(),
              promoterEndDate: sd3.toISOString(),
              promoterId: b.promoter.id
            });
          } else {
            newschedules.push({
              promotionid: s.id,
              promoterLocation: JSON.stringify(a.location),
              promoterStartDate: sd2.toISOString(),
              promoterEndDate: sd3.toISOString(),
              promoterId: b.promoter.id
            });
          }
        });
      });
    });

    collectedids.forEach(z => {
      if (promoters.filter(x => x.id === z).length === 0) missingids.push(z);
    });

    this.doSnackBar("Step 1/3 Deleteing unused schedules");
    while (missingids.length > 50) {
      this.doSnackBar(
        "Step 1/3 Deleteing unused schedules 50 of " + missingids.length
      );
      await Promise.all(
        missingids.splice(0, 50).map(Utils.deletePromoterPlusSchedule)
      );
    }
    await Promise.all(missingids.map(Utils.deletePromoterPlusSchedule));
    this.doSnackBar("Step 2/3 Create new schedules");
    while (newschedules.length > 50) {
      this.doSnackBar(
        "Step 2/3 Create new schedules 50 of " + newschedules.length
      );
      await Promise.all(
        newschedules.splice(0, 50).map(Utils.createPromotionSchedule)
      );
    }
    await Promise.all(newschedules.map(Utils.createPromotionSchedule));
    this.doSnackBar("Step 3/3 Update existing schedules");
    while (promoters.length > 50) {
      this.doSnackBar(
        "Step 3/3 Update existing schedules 50 of " + promoters.length
      );
      await Promise.all(
        promoters.splice(0, 50).map(Utils.updatePromotionSchedule)
      );
    }
    await Promise.all(promoters.map(Utils.updatePromotionSchedule));
    this.doSnackBar("Done");

    this.doSnackBar("Saved");
    this.props.history.push("/");
  }

  async savePromotion(input) {
    this.doSnackBar("Saving 0 of " + input.promoters.length + "...");
    let s = await Utils.createPromotion(input);
    input.promoters.forEach(function(e) {
      e.promotionid = s.id;
      e.promoterLocation = JSON.stringify(e.promoterLocation);
    });
    while (input.promoters.length > 50) {
      this.doSnackBar("Saving 50 of " + input.promoters.length);
      await Promise.all(
        input.promoters.splice(0, 50).map(Utils.createPromotionSchedule)
      );
    }
    await Promise.all(input.promoters.map(Utils.createPromotionSchedule));

    // var sw = this.state.consumableAdjustments;
    // sw.forEach(z => {
    //   z.usage = JSON.stringify([{ item: z.element, amount: -z.value }]);
    //   delete z.element;
    //   delete z.value;
    //   z.scheduleID = "adjustment";
    //   z.promoterPlusConsumedConsumableIDId = s.id;
    //   z.timestamp = new Date(Date.now());
    //   z.userID = z.id;
    //   delete z.id;
    // });
    // await Promise.all(sw.map(Utils.createConsumeResponse));
    this.setState({ promoters: [] });
    this.doSnackBar("Saved");
    this.props.history.push("/");
  }

  render() {
    // console.log(this.state.promoters)
    return (
      <div style={{ color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Paper style={{ margin: 10, backgroundColor: "#ddd" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: 10,
              marginRight: 10
            }}
          >
            <h1
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <PIcon
                icon={<StartCampIcon style={{ fontSize: 37 }} />}
                size="40px"
                colour="#7ed221"
              />
              Start Campaign
            </h1>
          </div>
        </Paper>
        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
                // justifyContent: "center",
                // backgroundColor: "#fff",
                // color: "#000",
                // borderRadius: 5,
                // marginRight: 10
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <DateIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Promotion details</h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Client: {this.state.allclients.clientname}
                </div>
                <TextField
                  style={{ color: "#374799" }}
                  id="outlined-name"
                  label="Promotion Name"
                  value={this.state.promotionName}
                  onChange={change => {
                    this.setState({ promotionName: change.target.value });
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <br />
                <TextField
                  style={{ color: "#374799" }}
                  id="outlined-name"
                  label="Start date"
                  value={this.state.startDate.toISOString().substr(0, 10)}
                  onChange={change => {
                    var date = new Date(change.target.value + "T02:00:00");
                    this.setState({ startDate: date });
                  }}
                  type="date"
                  margin="normal"
                  variant="outlined"
                />

                <FormControl variant="outlined" style={{ marginTop: 16 }}>
                  <InputLabel>Start time</InputLabel>
                  <Select
                    value={this.state.startTime}
                    onChange={change =>
                      this.setState({ startTime: change.target.value })
                    }
                    input={<OutlinedInput labelWidth={73} />}
                  >
                    {this.getTimes()}
                  </Select>
                </FormControl>

                <TextField
                  style={{ color: "#374799" }}
                  id="outlined-name"
                  label="End date"
                  value={this.state.endDate.toISOString().substr(0, 10)}
                  onChange={change => {
                    var date = new Date(change.target.value + "T02:00:00");

                    this.setState({ endDate: date });
                  }}
                  type="date"
                  margin="normal"
                  variant="outlined"
                />
                <FormControl variant="outlined" style={{ marginTop: 16 }}>
                  <InputLabel>End time</InputLabel>
                  <Select
                    value={this.state.endTime}
                    onChange={change =>
                      this.setState({ endTime: change.target.value })
                    }
                    input={<OutlinedInput labelWidth={73} />}
                  >
                    {this.getTimes()}
                  </Select>
                </FormControl>
                <br />

                <FormControl
                  variant="outlined"
                  style={{ padding: 0, marginTop: 10, width: 222 }}
                >
                  <InputLabel>Product Line</InputLabel>
                  <Select
                    value={this.state.productline || ""}
                    onChange={change => {
                      var prods = "";
                      this.state.allproductlines.forEach(function(e) {
                        if (e.id === change.target.value) prods = e.products;
                      });
                      // Utils.log(change.target.value)
                      this.setState({
                        productline: change.target.value,
                        products: prods
                      });
                    }}
                    input={<OutlinedInput labelWidth={123} />}
                  >
                    {this.getProductNames()}
                  </Select>
                </FormControl>
                <br />
                <TextField
                  style={{ color: "#374799", marginTop: 10, width: 600 }}
                  id="outlined-name"
                  multiline
                  label="Products"
                  value={this.state.products}
                  disabled={true}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    marginRight: 20,
                    color: this.state.colours.backgroundfont,
                    backgroundColor: this.state.colours.background
                  }}
                >
                  Sample Colour Choice
                  <br />
                  <Button
                    style={{
                      backgroundColor: this.state.colours.inactivebackground,
                      color: this.state.colours.inactivefont
                    }}
                    variant="contained"
                  >
                    Inactive
                  </Button>
                  <Button
                    style={{
                      backgroundColor: this.state.colours.activebackground,
                      color: this.state.colours.activefont
                    }}
                    variant="contained"
                  >
                    Active
                  </Button>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>
                  Background colour:{" "}
                  <SwatchesPicker
                    width="600"
                    height="200"
                    onChange={change => {
                      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                        change.hex
                      );
                      var L =
                        0.2126 * parseInt(result[1], 16) +
                        0.7152 * parseInt(result[2], 16) +
                        0.0722 * parseInt(result[3], 16);
                      var fontcolour = "#ffffff";
                      // Utils.log(L);
                      if (L > 128) fontcolour = "#000000";
                      var oldColour = this.state.colours;
                      oldColour.background = change.hex;
                      oldColour.backgroundfont = fontcolour;
                      this.setState({ colours: oldColour });
                    }}
                    color={this.state.colours.background}
                  />
                  Inactive button colour:
                  <SwatchesPicker
                    width="600"
                    height="200"
                    color={this.state.colours.inactivebackground}
                    onChange={change => {
                      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                        change.hex
                      );
                      var L =
                        0.2126 * parseInt(result[1], 16) +
                        0.7152 * parseInt(result[2], 16) +
                        0.0722 * parseInt(result[3], 16);
                      var fontcolour = "#ffffff";
                      // Utils.log(L);
                      if (L > 128) fontcolour = "#000000";
                      var oldColour = this.state.colours;
                      oldColour.inactivebackground = change.hex;
                      oldColour.inactivefont = fontcolour;
                      this.setState({ colours: oldColour });
                    }}
                  />
                  Active colour:{" "}
                  <SwatchesPicker
                    width="600"
                    height="200"
                    color={this.state.colours.activebackground}
                    onChange={change => {
                      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                        change.hex
                      );
                      var L =
                        0.2126 * parseInt(result[1], 16) +
                        0.7152 * parseInt(result[2], 16) +
                        0.0722 * parseInt(result[3], 16);
                      var fontcolour = "#ffffff";
                      // Utils.log(L);
                      if (L > 128) fontcolour = "#000000";
                      var oldColour = this.state.colours;
                      oldColour.activebackground = change.hex;
                      oldColour.activefont = fontcolour;
                      this.setState({ colours: oldColour });
                    }}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
                // justifyContent: "center",
                // backgroundColor: "#fff",
                // color: "#000",
                // borderRadius: 5,
                // marginRight: 10
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <QuestionIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Questions ({this.state.questions.length})</h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PromotionsQuestions
              input={this.state.questions}
              updater={input => this.setState({ questions: input })}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
                // justifyContent: "center",
                // backgroundColor: "#fff",
                // color: "#000",
                // borderRadius: 5,
                // marginRight: 10
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <PhotoIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Photos ({this.state.photos.length})</h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PromotionsQuestionsPhotos
              input={this.state.photos}
              updater={input => {
                // console.log(input)
                this.setState({ photos: input });
              }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <LocationIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Promoters </h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}
            >
              <div style={{ width: "100%" }}>
                <PromotionsPromoters
                  input={this.state.promotertree}
                  consumeData={this.state.allclients}
                  liveConsumeData={this.state.consumptionitems}
                  onUpdateUserConsume={id => {
                    this.getConsumableDataByUserID(id);
                  }}
                  users={this.state.users}
                  startTime={this.state.startTime}
                  endTime={this.state.endTime}
                  startDate={this.state.startDate}
                  onChange={input => {
                    this.setState({ promotertree: input });
                  }}
                  onDelete={id => {
                    this.deleteSchedule(id);
                  }}
                  onSave={id => {
                    // console.log(id);
                    if (typeof id.scheduleID === "undefined")
                      this.saveNewSchedule(id);
                    else this.saveSchedule(id);
                  }}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Button
          style={{ margin: 10 }}
          color="primary"
          variant="contained"
          onClick={() => {
            if (this.validationCheck()) {
              if (!this.state.edit) {
                this.doSnackBar("Saving...");
                var promotioncomplete = {};
                promotioncomplete.promoters = [];

                this.state.promotertree.forEach(a => {
                  a.nodes.forEach(b => {
                    b.dates.forEach(c => {
                      c.setHours(c.getHours() + 2);
                      var d3 =
                        c.toISOString().substr(0, 10) +
                        "T" +
                        b.startTime +
                        ":00.000Z";
                      var sd2 = new Date(d3);
                      var d4 =
                        c.toISOString().substr(0, 10) +
                        "T" +
                        b.endTime +
                        ":00.000Z";
                      var sd3 = new Date(d4);
                      promotioncomplete.promoters.push({
                        promoterLocation: a.location,
                        promoterStartDate: sd2,
                        promoterEndDate: sd3,
                        promoterId: b.promoter.id
                      });
                    });
                  });
                });

                promotioncomplete.questions = this.state.questions;
                promotioncomplete.products = JSON.stringify(
                  this.state.products
                );
                promotioncomplete.questions.forEach(function(e) {
                  if (e.question === "product") {
                    e.items = promotioncomplete.products;
                  }
                });
                promotioncomplete.questions = JSON.stringify(
                  promotioncomplete.questions
                );
                promotioncomplete.photos = JSON.stringify(this.state.photos);
                promotioncomplete.promotionName = this.state.promotionName;
                promotioncomplete.promoterPlusPromotionClientId = this.state.client;
                promotioncomplete.productline = this.state.productline;
                promotioncomplete.colours = JSON.stringify(this.state.colours);
                var t2 =
                  this.state.startDate.toISOString().substr(0, 10) +
                  "T" +
                  this.state.startTime;
                var t3 = new Date(t2);
                t3.setHours(t3.getHours() + 2);
                promotioncomplete.startDate = t3;
                t2 =
                  this.state.endDate.toISOString().substr(0, 10) +
                  "T" +
                  this.state.endTime;
                t3 = new Date(t2);
                t3.setHours(t3.getHours() + 2);
                promotioncomplete.endDate = t3;
                promotioncomplete.label = this.state.label;
                // console.log(JSON.stringify(promotioncomplete, null, 4));

                this.savePromotion(promotioncomplete);
              } else this.saveEditedPromotion();
            }
          }}
        >
          Save Promotion
        </Button>

        <Button
          style={{ margin: 10 }}
          color="primary"
          variant="contained"
          disabled={!this.state.edit}
          onClick={() => {
            this.deletePromoterPlusPromotion(
              this.props.history.location.search.replace("?edit=", "")
            );
          }}
        >
          Delete Promotion
        </Button>
        {this.state.status}
      </div>
    );
  }
}
export default withRouter(Promotions);
