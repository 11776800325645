import React from "react";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
// import ExifOrientationImg from "react-exif-orientation-img";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class ModalImage extends React.Component{

  makeSignOffInModal(input) {
    // input = JSON.parse(input);
    return (
      <TableBody>
        <TableRow>
          <TableCell>Manager name:</TableCell>
          <TableCell>{input.signoffmanagername}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Manager email:</TableCell>
          <TableCell>{input.signoffmanageremail}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Manager number:</TableCell>
          <TableCell>{input.signoffmanagerphonenumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Manager rating:</TableCell>
          <TableCell>{input.signoffmanagerrating}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Manager comments:</TableCell>
          <TableCell>{input.signoffmanagercomments}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Date:</TableCell>
          <TableCell>{input.signofftimestamp}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>GPS:</TableCell>
          <TableCell>{JSON.stringify(input.signoffgps)}</TableCell>
        </TableRow>

      </TableBody>
    );
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          style={{
            content: {
            width: "40%",
            height:"40%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          },
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",

            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            zIndex: 99
          }}
          ariaHideApp={false}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <CloseIcon
              style={{
                fontSize: 60,
                color: "#666",
                justifyContent: "flex-end",
                marginLeft: "90%"
              }}
              onClick={() => this.props.closeMe()}
            />
            <Table>
              {this.makeSignOffInModal(this.props.data)}
            </Table>
          </div>
        </Modal>
      </div>
    );
  }
}
export default ModalImage
