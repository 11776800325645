import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { isMobile, isIOS } from "react-device-detect";
import Button from "@material-ui/core/Button";
import Utils from "./utils";
import TextField from "@material-ui/core/TextField";
import StarFull from "@material-ui/icons/Star";
import Snackbar from "@material-ui/core/Snackbar";

const primary = "#bdbdbd";
const success = "#3f51b5";

class SignOff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      managername: "",
      manageremail: "",
      managerphonenumber: "",
      rating: 0,
      comments: ""
    };
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser().catch(() =>
      this.props.history.push("/signin")
    );
    if (!isMobile) this.props.history.push("/desktop");
    if (!this.props.location.state) this.props.history.push("/");
  }

  ToggleGroupRating(input) {
    return input.map((item, index) => (
      <StarFull
        key={index}
        style={{
          width: 100 / input.length + "%",
          color: this.state.rating < item ? primary : success
        }}
        fontSize="large"
        onClick={() => {
          this.setState({ rating: item });
        }}
      />
    ));
  }

  async doSignOff() {
    // Utils.log(this.props.location.state.scheduleID.id);
    var output = {};
    var date = new Date(Date.now());
    date.setHours(date.getHours() + 2);
    output.signoffmanagername = this.state.managername || " ";
    output.signofftimestamp = date;
    output.signoffmanageremail = this.state.manageremail || " ";
    output.signoffmanagerphonenumber = this.state.managerphonenumber || " ";
    output.signoffmanagerrating = this.state.rating || " ";
    output.signoffmanagercomments = this.state.comments || " ";
    output.id = this.props.location.state.scheduleID.id;
    if (navigator.onLine && (await Utils.isFastInternet())) {
      await Utils.signOffByScheduleId(output);
      await Utils.cleanupOfflineData("signOffByScheduleId");
      if (!isIOS) navigator.vibrate([100, 100, 100, 100, 100]);
    } else {
      Utils.addOffLineDataStore("signOffByScheduleId", output);
      if (!isIOS) navigator.vibrate(1000);
    }
    this.setState({ stocktakedone: true });
    // Utils.log(s);
    this.props.history.push("/");
  }

  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <h3>Signing off feedback by Manager</h3>
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="Manager name"
          value={this.state.managername}
          onChange={change => {
            this.setState({ managername: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="Manager email"
          value={this.state.manageremail}
          onChange={change => {
            this.setState({ manageremail: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="Manager phone number"
          value={this.state.managerphonenumber}
          onChange={change => {
            this.setState({ managerphonenumber: change.target.value });
          }}
          InputProps={{ type: "number" }}
          margin="normal"
          variant="outlined"
        />

        {this.ToggleGroupRating(["1", "2", "3", "4", "5"])}
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="Manager comments"
          multiline
          value={this.state.comments}
          onChange={change => {
            this.setState({ comments: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <Button
          style={{
            textTransform: "none",
            width: "100%",
            height: 60
          }}
          variant="contained"
          color="primary"
          onClick={() => {
            let done = false;
            if (this.state.managername.trim() === "")
              this.doSnackBar("Manager name cannot be blank");
            if (this.state.manageremail.trim() === "")
              this.doSnackBar("Manager email cannot be blank");
            if (this.state.managerphonenumber.trim() === "")
              this.doSnackBar("Manager number cannot be blank");
            if (this.state.rating === 0)
              this.doSnackBar("Rating cannot be blank");
            if (this.state.comments.trim() === "")
              this.doSnackBar("Manager comments cannot be blank");
            if (
              this.state.managername.trim() !== "" &&
              this.state.manageremail.trim() !== "" &&
              this.state.managerphonenumber.trim() !== "" &&
              this.state.rating > 0 &&
              this.state.comments.trim() !== ""
            )
              done = true;
            if (done) this.doSignOff();
          }}
        >
          Sign off
        </Button>
        <Button
          style={{
            textTransform: "none",
            width: "100%",
            marginTop: 20,
            height: 60
          }}
          variant="contained"
          color="primary"
          onClick={() => this.props.history.push("/action")}
        >
          Back
        </Button>
      </div>
    );
  }
}
export default withRouter(SignOff);
