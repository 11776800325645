import "whatwg-fetch";
import React from "react";
import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import Background from "./bg.jpg";
import Utils from "./utils";

// import logo from "./logo.png";
// import Avatar from "@material-ui/core/Avatar";
import InputAdornment from "@material-ui/core/InputAdornment";
 import SignalBadIcon from "@material-ui/icons/SignalCellularConnectedNoInternet4Bar";
 import SignalGoodIcon from "@material-ui/icons/SignalCellular4Bar";

// import TickIcon from "@material-ui/icons/CheckCircle";
// import WarnIcon from "@material-ui/icons/Warning";
// import FailIcon from "@material-ui/icons/Error";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      phoneADMIN:"",
      pin: "",
      status: "",
      gpspermission: false,
      currentuser: null,
      signalisgood:false
    };
  }

  async tester() {
    var c = await Utils.isFastInternet();
    // console.log(c);
    this.setState({ signalisgood: c});
  }

  async componentDidMount() {
    this.setState({ signalisgood: await Utils.isFastInternet()});
    try {
      var s = await Auth.currentAuthenticatedUser();
      this.setState({ currentuser: s,signalisgood: await Utils.isFastInternet()});
      // console.log(s);
    } catch (err) {
      console.log(err);
    }
  }

  async signin(email, pin1) {
    var username = null;
    // console.log(this.state.currentuser);
    // console.log(email);
    if (this.state.currentuser !== null) {
      if (this.state.currentuser.attributes.email === email)
        this.props.history.push("/");
      else {
        localStorage.clear()
        this.setState({ status: "Signing you in..." });
        try {
          let s = await Auth.signIn({
            username: email,
            password: email
          });
          if (s) {
            this.props.history.push("/");
            // window.location.reload();
          }
        } catch (e) {
          this.setState({ status: e.message });
        }
      }
    } else {
      this.setState({ status: "Signing you in..." });
      try {
        let s = await Auth.signIn({
          username: email,
          password: email
        });

        if (s) {
          this.props.history.push("/");
          // window.location.reload();
        }
      } catch (e) {
        this.setState({ status: e.message });
      }
    }

    return username;
  }

  async signinadmin(email, pin1) {
    var username = null;
    this.setState({ status: "Signing you in..." });
    try {
      let s = await Auth.signIn({
        username: email,
        password: pin1
      });
      if (s) {
        this.props.history.push("/");
        window.location.reload();
      }
    } catch (e) {
      this.setState({ status: e.message });
    }

    return username;
  }

  render() {
    return (
      <div
        style={{
          backgroundImage: `url(${Background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: 740,
          width: "100%",
          color: "#fff"
        }}
      >
        <div
          style={{
            color: "#fff",
            margin: 5,
            marginLeft: 10,
            marginRight: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div
            style={{
              color: "#fff",
              fontWeight: "400",
              fontSize: 30,
              marginTop: "30%"
            }}
          >
            Promoter Plus
          </div>
          <div
            style={{
              color: "#fff",
              fontWeight: "400",
              fontSize: 20,
              marginTop: "80%"
            }}
          >
            More than just promotions
          </div>


          <TextField
            style={{
              color: "#374799",
              minWidth: "100%",
              backgroundColor: "#fff"
            }}
            id="outlined-nam1e"
            label="Promoter code"
            value={this.state.phone}
            onChange={change => {
              this.setState({ phone: change.target.value });
            }}
            onKeyDown={change => {
              // console.log(change.keyCode)
              if (change.keyCode === 13)
                this.signin(
                  this.state.phone.toLowerCase().trim() + "@promoterplus.com"
                );
            }}
            margin="normal"
            variant="filled"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  onClick={() =>
                    this.signin(
                      this.state.phone.toLowerCase().trim() +
                        "@promoterplus.com"
                    )
                  }
                >
                  ➡
                </InputAdornment>
              )
            }}
          />
          Signal quality{" "}{this.state.signalisgood?<SignalGoodIcon />:<SignalBadIcon style={{color: "#f00"}}/>}  <div
              onClick={() => {
                localStorage.removeItem("lastofflinetest");
                this.tester();
              }}
            >
              {" "}Recheck
            </div>
          <div style={{ color: "#FFEB3B", fontSize: 20 }}>
            {this.state.status}
          </div>

          <div style={{ marginTop: 110, width: "100%" }}>
            <TextField
              style={{
                color: "#374799",
                minWidth: "100%",
                backgroundColor: "#fff",
                padding: 0,
                margin: 0
              }}
              id="outlined-name2"
              label="Admin username"
              value={this.state.phoneADMIN}
              onChange={change => {
                this.setState({ phoneADMIN: change.target.value });
              }}
              margin="normal"
              variant="filled"
            />

            <TextField
              style={{
                color: "#374799",
                minWidth: "100%",
                backgroundColor: "#fff",
                padding: 0,
                margin: 0
              }}
              id="outlined-nam3e"
              label="Admin password"
              value={this.state.pin}
              onChange={change => {
                this.setState({ pin: change.target.value });
              }}
              type="password"
              margin="normal"
              onKeyDown={change => {
                // console.log(change.keyCode)
                if (change.keyCode === 13)
                  this.signinadmin(
                    this.state.phoneADMIN.toLowerCase().trim(),
                    this.state.pin.trim()
                  );
              }}
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => {
                      this.signinadmin(
                        this.state.phoneADMIN.toLowerCase().trim(),
                        this.state.pin.trim()
                      );
                    }}
                  >
                    ➡
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
// <Button
//   style={{ background: "#03a9fc", width: "10%" , height: 57, marginTop: 10, marginLeft: 3}}
//   variant="contained"
//   color="primary"
//   onClick={() => {
//     this.signin(
//       this.state.phone.toLowerCase().trim() + "@promoterplus.com"
//     );
//   }}
//   >
//   ➡
// </Button>

export default withRouter(SignIn);
