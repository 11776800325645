import React from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
// import Utils from "./utils";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/AddCircle";
// import DeleteIcon from "@material-ui/icons/RemoveCircle";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      search: ""
    };
  }

  matcher(itm, arr) {
    var output = true;
    arr.forEach(function(e) {
      if (e.promoterId === itm) output = false;
    });
    return output;
  }

  matcherSearch(itm, searchtext) {
    // console.log("logger")
    //     console.log(itm)
    //     console.log(searchtext)
    var output = false;
    if (itm.toLowerCase().indexOf(searchtext.toLowerCase()) > -1) output = true;
    if (searchtext === "") output = true;
    return output;
  }

setBlink(input){
  if (typeof input === 'undefined')
    return "#f00"
    else return null

}


  render() {
    // Utils.log("this.props.users");
    // Utils.log(this.props.users);
    // Utils.log("this.props.input");
    // Utils.log(this.props.input);

    return (
      <div>
        <Dialog
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{this.props.title}</DialogTitle>
          <div style={{ margin: 10, display: "flex", flexDirection: "row" }}>
            <TextField
              style={{ color: "#374799", width: "100%" }}
              id="outlined-name"
              label="Search"
              value={this.state.search}
              margin="normal"
              variant="outlined"
              onChange={change =>
                this.setState({ search: change.target.value })
              }
            />
            <Button
              color="primary"
              style={{ margin: 2 }}
              onClick={() => {
                this.setState({ search: "" });
              }}
            >
              clear
            </Button>
          </div>
          <div style={{ margin: 10 }}>Available</div>
          <div
            style={{
              width: "500px",

              margin: 10,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap"
            }}
          >
            {this.props.users.map((item, index) => (
              <div key={index}>
                {(item.type === "Promoter") &
                this.matcherSearch(item.name, this.state.search) ? (
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ margin: 2 }}
                    onClick={() => {
                      this.setState({ dialogOpen: false });
                      this.props.onChange(item);
                    }}
                  >
                    {item.name + " "}
                    <AddIcon style={{ marginLeft: 5 }} />
                  </Button>
                ) : (
                  <div />
                )}
              </div>
            ))}
          </div>

          <Button
            color="primary"
            variant="contained"
            style={{ margin: 50 }}
            onClick={() => {
              this.setState({ dialogOpen: false });
            }}
          >
            Done
          </Button>
        </Dialog>

        <Button
        style={{backgroundColor: this.setBlink(this.props.nodes)}}
          onClick={() => {
            this.setState({ dialogOpen: true });
          }}
        >
          <PersonAddIcon />
        </Button>
      </div>
    );
  }
}

export default SearchFilter;
