import React from "react";
import Button from "@material-ui/core/Button";
import { graphqlOperation, API } from "aws-amplify";
import * as queries from "./graphql/queries";
import Utils from "./utils";

class Download extends React.Component {
  ConvertToCSV(objArray) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = Object.keys(objArray[0])+ "\r\n";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line !== "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  }

  async getfile(filter) {
    var limit = 1000
    var csv = [];
    let user = await API.graphql(
      graphqlOperation(queries[filter], { limit: limit })
    );
    var thekeys = Object.keys(user.data[filter].items[0]);
    user.data[filter].items.forEach(function(e) {
      thekeys.forEach(function(k) {
        // Utils.log(e[k])
        if (typeof e[k] === "object" && e[k] !== null) e[k] = e[k].id;
      });
      csv.push(e);
    });
    var nextToken = user.data[filter].nextToken;
    while (nextToken !== null) {
      user = await API.graphql(
        graphqlOperation(queries[filter], { limit: limit, nextToken: nextToken })
      );
      nextToken = user.data[filter].nextToken;
      user.data[filter].items.forEach(function(e) {
        thekeys.forEach(function(k) {
          if (typeof e[k] === "object" && e[k] !== null) e[k] = e[k].id;
        });
        csv.push(e);
      });
    }

    Utils.log(this.ConvertToCSV(csv))
    const element = document.createElement("a");
   const file = new Blob([this.ConvertToCSV(csv)], {type: 'text/plain'});
   element.href = URL.createObjectURL(file);
   element.download = this.props.file+".csv";
   document.body.appendChild(element); // Required for this to work in FireFox
   element.click();
    return csv;
  }

  render() {
    return (
      <div>

        <Button
          style={{ margin: 5 }}
          variant="contained"
          color="primary"
          onClick={() => {
            this.getfile(this.props.file);
          }}
        >
          {this.props.title}
        </Button>
      </div>
    );
  }
}
export default Download;
