import React, { Component } from "react";
// import { Auth } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Utils from "./utils";
import { CSVReader } from "react-papaparse";
class App extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  handleReadCSV = data => {
    console.log(data);
    this.props.done(data);
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleImportOffer = () => {
    this.fileInput.current.click();
  };

  render() {
    return (
      <div>
        {this.props.title}
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{ display: "none" }}
          onError={this.handleOnError}
          configOptions={{ header: true }}
        />
        <button onClick={this.handleImportOffer}>Import</button>
      </div>
    );
  }
}
class Repair extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleinput: "",
      promoid: "",
      sch: { enddate: null, location: null, startdate: null }
    };
  }

  async getpromo(id) {
    let s = await Utils.getPromotionByPromotionId(id);

    console.log(s);
    for (var i = 0; i < s.promotionScheduler.items.length; i++) {
      console.log(
        await Utils.getScheduleById(s.promotionScheduler.items[i].id)
      );
    }

    // this.setState({promoid:s});
  }

async getschedcsv(input){
  for (var i=0; i<input.length-1;i++){
    // for (var i=0; i<1;i++){
    try{
// if (input[i].location ==='{"address":""}') input[i].location = '{"address":null}'
      // this.setState(  {sch: { enddate: input[i].enddate, location: input[i].location, startdate: input[i].startdate }});
      var loc = (JSON.parse(input[i].location))
      if (loc.address === "") loc.address=null

      let s = await Utils.REPAIRupdatePromotionSchedule({ id:input[i].id, enddate: input[i].enddate, location: JSON.stringify(loc), startdate: input[i].startdate });
      console.log(s);
    }
    catch (err) {
      console.log(input[i])
    }
  }
}


  async getsched(id) {
    let s = await Utils.getScheduleById(id);
    delete s.responses;
    delete s.promotionPhotos;
    delete s.promotionID;
    console.log(s);
    this.setState({ sch: s });
  }

  async saver() {
    let s = await Utils.REPAIRupdatePromotionSchedule(this.state.sch);
    console.log(s);
  }
  render() {
    return (
      <div>
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="get promtoion"
          value={this.state.promoid}
          onChange={change => {
            this.setState({ promoid: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <Button onClick={() => this.getpromo(this.state.promoid)}>
          get prmotion
        </Button>
        <br />
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="get schedule"
          value={this.state.scheduleinput}
          onChange={change => {
            this.setState({ scheduleinput: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <Button onClick={() => this.getsched(this.state.scheduleinput)}>
          get schedu
        </Button>
        <App
          done={d => this.setState({ schedulescsv: d.data })}
          title="schedulescsv"
        />
        <Button onClick={() => this.getschedcsv(this.state.schedulescsv)}>
          repair from csv
        </Button>
        <br />
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="start date"
          value={this.state.sch.startdate || ""}
          onChange={change => {
            var oldsch = this.state.sch;
            oldsch.startdate = change.target.value;
            this.setState({ sch: oldsch });
          }}
          margin="normal"
          variant="outlined"
        />
        <br />
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="end date"
          value={this.state.sch.enddate || ""}
          onChange={change => {
            var oldsch = this.state.sch;
            oldsch.enddate = change.target.value;
            this.setState({ sch: oldsch });
          }}
          margin="normal"
          variant="outlined"
        />
        <br />
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="location"
          value={this.state.sch.location || ""}
          onChange={change => {
            var oldsch = this.state.sch;
            oldsch.location = change.target.value;
            this.setState({ sch: oldsch });
          }}
          margin="normal"
          variant="outlined"
        />
        <Button
          onClick={() => {
            var oldsch = this.state.sch;
            oldsch.location = JSON.parse(oldsch.location);
            this.setState({ sch: oldsch });
          }}
        >
          stringer
        </Button>

        <Button onClick={() => this.saver()}>save</Button>
        <br />
        <TextField
          style={{ color: "#374799", width: "100%" }}
          id="outlined-name"
          label="start date"
          value={JSON.stringify(this.state.sch || "", null, 4)}
          multiline={true}
          margin="normal"
          variant="outlined"
        />
      </div>
    );
  }
}
export default Repair;
