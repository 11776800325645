
const STPUtils =  [
  {'stp':319531,'address':'319531, EGL - SOWETO, NOMBULELO MAJOVA, THE WAY TAVERN, 344 STAND NO, RAMOKONOPI SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.36710,'lng':28.16180}},
{'stp':388201,'address':'388201, EGL - SOWETO, NOMBULELO MAJOVA, GHETTO INN, 296 STAND NO, RAMOKONOPI SECTION KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.36151,'lng':28.16265}},
{'stp':390448,'address':'390448, EGL - SOWETO, NOMBULELO MAJOVA, MFUNDO INN KATLEHONG, 8872 ERF NO, VIKING STREET MOLELEKI SECTION, EXTENSION 2, KATLEHONG, South Africa','geo':{'lat':-26.38865,'lng':28.17686}},
{'stp':390646,'address':'390646, EGL - SOWETO, NOMBULELO MAJOVA, MAKAWUSIS PLACE, 6695 STAND NO, MOLELEKI SECTION KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.37730,'lng':28.17490}},
{'stp':391691,'address':'391691, EGL - SOWETO, NOMBULELO MAJOVA, THUSAVHATHU S PLACE, 2043 STAND NO9, LIKHOLE, EXTENSION 1, KATLEHONG, South Africa','geo':{'lat':-26.37325,'lng':28.16064}},
{'stp':392032,'address':'392032, EGL - SOWETO, NOMBULELO MAJOVA, VUMBUKA TAVERN, 15285 ERF, ZONKIZIZWE, EXTENSION 6, KATLEHONG, South Africa','geo':{'lat':-26.37490,'lng':28.17880}},
{'stp':466492,'address':'466492, EGL - SOWETO, NOMBULELO MAJOVA, SAMS PLACE, THEMBELIHLE STREET, LIKOLE EXT 1, #, KATLEHONG, South Africa','geo':{'lat':-26.37812,'lng':28.15639}},
{'stp':467526,'address':'467526, EGL - SOWETO, NOMBULELO MAJOVA, NTOK\'S TAVERN, 1 MNGADI SECTION, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.36580,'lng':28.14850}},
{'stp':469772,'address':'469772, EGL - SOWETO, NOMBULELO MAJOVA, MPHOS TAVERN, 63 STAND NO, MOSHOESHOE SECTION KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.32048,'lng':28.15784}},
{'stp':472989,'address':'472989, EGL - SOWETO, NOMBULELO MAJOVA, DINANGWE TAVERN, 552 RAMOKONOPI, SECTION KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.36470,'lng':28.15850}},
{'stp':482347,'address':'482347, EGL - SOWETO, NOMBULELO MAJOVA, BONGIS INN, 180 STAND NUMBER, KWANELE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37388,'lng':28.14867}},
{'stp':483148,'address':'483148, EGL - SOWETO, NOMBULELO MAJOVA, KWENELE PARK JOINT, 491 STAND NO, KWANELE SECT, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37775,'lng':28.14895}},
{'stp':486908,'address':'486908, EGL - SOWETO, NOMBULELO MAJOVA, SILVER S PLACE, 2033 STAND NO, ZONKIZIZWE, #, KATLEHONG, South Africa','geo':{'lat':-26.41037,'lng':28.17857}},
{'stp':543523,'address':'543523, EGL - SOWETO, NOMBULELO MAJOVA, MABUNDAS TAVERN, STAND NO, ERF 2674 FOURTH AVENUE, LIKOLE EXTENSION 1, KATLEHONG, South Africa','geo':{'lat':-26.38270,'lng':28.16600}},
{'stp':544888,'address':'544888, EGL - SOWETO, NOMBULELO MAJOVA, MDLALOSE TAVERN, 07 STAND NO, RAMOKONOPI KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.36662,'lng':28.14970}},
{'stp':546326,'address':'546326, EGL - SOWETO, NOMBULELO MAJOVA, MGABABAS TAVERN, 3183 STAND NO, DIKOLE EXTENSION 1, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37172,'lng':28.15957}},
{'stp':550417,'address':'550417, EGL - SOWETO, NOMBULELO MAJOVA, TASIES TAVERN PERMIT, 182 LIKOLE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36731,'lng':28.16197}},
{'stp':550825,'address':'550825, EGL - SOWETO, NOMBULELO MAJOVA, TERECIA S TAVERN, 956 KHUMALO VALLEY, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38005,'lng':28.16639}},
{'stp':552102,'address':'552102, EGL - SOWETO, NOMBULELO MAJOVA, CHECHE S TAVERN, 6768 MOLELEKI EXT 2, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37730,'lng':28.17490}},
{'stp':552453,'address':'552453, EGL - SOWETO, NOMBULELO MAJOVA, THE ZONE BOTTLE LIQUOR STORE, 819 MDLADLA SHOPPING CENTRE, KATLEHONG, EKURHULENI, KATLEHONG, South Africa','geo':{'lat':-26.36090,'lng':28.16120}},
{'stp':553806,'address':'553806, EGL - SOWETO, NOMBULELO MAJOVA, MOKGOBAS INN PERMIT, 279 RAMKONOPI EAST, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36010,'lng':28.16360}},
{'stp':553819,'address':'553819, EGL - SOWETO, NOMBULELO MAJOVA, NHLANHLAS PLACE PERMIT, 1169 KHUMALO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37150,'lng':28.16440}},
{'stp':553900,'address':'553900, EGL - SOWETO, NOMBULELO MAJOVA, BRICKS AND THABZO, 334 RAMOKONOPI EAST, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36300,'lng':28.16310}},
{'stp':553906,'address':'553906, EGL - SOWETO, NOMBULELO MAJOVA, TATIS INN PERMIT, 1127 MOLELEKI SECTION, EXT 1, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36703,'lng':28.16148}},
{'stp':561042,'address':'561042, EGL - SOWETO, NOMBULELO MAJOVA, C TO C TAVERN, 203 MAGAGULA HEIGHTS, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.42780,'lng':28.19250}},
{'stp':574711,'address':'574711, EGL - SOWETO, NOMBULELO MAJOVA, TEMFAS TAVERN, RAMOKONOPI WEST, ERF 792, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36493,'lng':28.15433}},
{'stp':580547,'address':'580547, EGL - SOWETO, NOMBULELO MAJOVA, KUNIS TAVERN, 7695 MOLELEKI SECTION, EXT 2, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38449,'lng':28.17916}},
{'stp':580875,'address':'580875, EGL - SOWETO, NOMBULELO MAJOVA, SPOORNET TAVERN, 7720 MOLELEKI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38470,'lng':28.18020}},
{'stp':580895,'address':'580895, EGL - SOWETO, NOMBULELO MAJOVA, MORITING TAVERN, 8666 MAPAI STREET, EXT 2, MOLELEKI, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38700,'lng':28.17710}},
{'stp':602873,'address':'602873, EGL - SOWETO, NOMBULELO MAJOVA, SABELOS PLACE, 224 RAMOKONOPI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36020,'lng':28.15960}},
{'stp':603420,'address':'603420, EGL - SOWETO, NOMBULELO MAJOVA, MALIS PLACE, 1925 LIKOLE STREET, EXT 1, SILUMA VIEW, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37520,'lng':28.16020}},
{'stp':604799,'address':'604799, EGL - SOWETO, NOMBULELO MAJOVA, MEMBERS ONLY, 8047 MOLELEKI SECTION  EXT 02, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38220,'lng':28.17960}},
{'stp':605684,'address':'605684, EGL - SOWETO, NOMBULELO MAJOVA, SHIRLEY\'S TAVERN, ERF 181 ,PTN 134, SILUMA VIEW, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38280,'lng':28.15890}},
{'stp':605942,'address':'605942, EGL - SOWETO, NOMBULELO MAJOVA, SOLOMONS PLACE, 460 KHUMALO VALLEY, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37990,'lng':28.16430}},
{'stp':605995,'address':'605995, EGL - SOWETO, NOMBULELO MAJOVA, JOELES PLACE, 2364 KATLEHONG SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37884,'lng':28.18145}},
{'stp':606050,'address':'606050, EGL - SOWETO, NOMBULELO MAJOVA, DORIS INN, 339 APHANE STREET, KHUMALO VALLEY, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37850,'lng':28.16780}},
{'stp':606461,'address':'606461, EGL - SOWETO, NOMBULELO MAJOVA, DONKINI INN, 439 MABASO STREET, KHUMALO VALLEY, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37854,'lng':28.16314}},
{'stp':606728,'address':'606728, EGL - SOWETO, NOMBULELO MAJOVA, MDALA TUCKSHOP, 1042 MOLELEKI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37020,'lng':28.16740}},
{'stp':606986,'address':'606986, EGL - SOWETO, NOMBULELO MAJOVA, SARAHS TAVERN, 8371 MOLELEKI EXT 02, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38820,'lng':28.17480}},
{'stp':625823,'address':'625823, EGL - SOWETO, NOMBULELO MAJOVA, MANKAYI CAFE AND LIQUOR, 2106 KWENZEKILE, KATLEHONG SOUTH, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38100,'lng':28.18300}},
{'stp':626703,'address':'626703, EGL - SOWETO, NOMBULELO MAJOVA, MSIZAS TAVERN PERMIT, 1107 MALATOLA STREET, KHUMALO VALLEY, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37950,'lng':28.16260}},
{'stp':627144,'address':'627144, EGL - SOWETO, NOMBULELO MAJOVA, MAFA TAVERN, 130 KHUMALO VALLEY, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37370,'lng':28.16620}},
{'stp':627285,'address':'627285, EGL - SOWETO, NOMBULELO MAJOVA, NTOMBELA TAVERN, 542 MASAKHANE STREET  EXT 2, MNGADI, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36210,'lng':28.14990}},
{'stp':627573,'address':'627573, EGL - SOWETO, NOMBULELO MAJOVA, NONCEDO INN PERMIT, 8535 MARUMO STREET, BLOCK E, EXT 2, MOLELEKI, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38830,'lng':28.17650}},
{'stp':627748,'address':'627748, EGL - SOWETO, NOMBULELO MAJOVA, NEW ORLEANS PERMIT, 171 NGWENYA STREET, TWALA, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32780,'lng':28.14860}},
{'stp':627798,'address':'627798, EGL - SOWETO, NOMBULELO MAJOVA, BROWNS INN PERMIT, 323 RAMOKONOPI EAST, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36293,'lng':28.16462}},
{'stp':627803,'address':'627803, EGL - SOWETO, NOMBULELO MAJOVA, VUYIS INN PERMIT, 58 KATLEHONG GARDENS, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38580,'lng':28.15620}},
{'stp':627981,'address':'627981, EGL - SOWETO, NOMBULELO MAJOVA, NDLONDLOZIS TAVERN PERMIT, 1730 TUMELO STREET, EXT 1 LIKOLE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37540,'lng':28.15570}},
{'stp':628505,'address':'628505, EGL - SOWETO, NOMBULELO MAJOVA, MOSES INN PERMIT, 15331 ZONKIZIZWE EXT 6, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.39830,'lng':28.17750}},
{'stp':628508,'address':'628508, EGL - SOWETO, NOMBULELO MAJOVA, SHEBEEN TAVERN PERMIT, 4907 ZONKIZIZWE ZONE 6, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.40230,'lng':28.18860}},
{'stp':628658,'address':'628658, EGL - SOWETO, NOMBULELO MAJOVA, KHETHAS INN PERMIT, 342 A SIPHAMANDLA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37540,'lng':28.16920}},
{'stp':629586,'address':'629586, EGL - SOWETO, NOMBULELO MAJOVA, TINYS INN PERMIT, 404 MNGADI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36060,'lng':28.14450}},
{'stp':629863,'address':'629863, EGL - SOWETO, NOMBULELO MAJOVA, BAMLOYILE PERMIT, 637 RAMOKONOPI STREET, KATLEHONG EAST, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36340,'lng':28.16370}},
{'stp':630595,'address':'630595, EGL - SOWETO, NOMBULELO MAJOVA, LAZI SHEBEEN PERMIT, 1144 MAKHUBU STREET, NHLAPO, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30510,'lng':28.15010}},
{'stp':630650,'address':'630650, EGL - SOWETO, NOMBULELO MAJOVA, GOOD HOPE, 2206 PULA STREET, SILUMA VIEW, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37710,'lng':28.15840}},
{'stp':630796,'address':'630796, EGL - SOWETO, NOMBULELO MAJOVA, TSHIAMISO TRADING 34 CC, 2035 PHENDUKA STREET, SILUMA VIEW, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37270,'lng':28.16100}},
{'stp':635481,'address':'635481, EGL - SOWETO, NOMBULELO MAJOVA, MAVIVS TAVERN, 886 KHUMALO VALLEY, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.37840,'lng':28.16760}},
{'stp':635653,'address':'635653, EGL - SOWETO, NOMBULELO MAJOVA, ROSES INN, 49 KHUMALO VALLEY MALETE CRESCENT, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37370,'lng':28.16360}},
{'stp':635761,'address':'635761, EGL - SOWETO, NOMBULELO MAJOVA, THE NGALO S TAVERN, 54 KWANELE SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37553,'lng':28.15223}},
{'stp':635768,'address':'635768, EGL - SOWETO, NOMBULELO MAJOVA, CLUB VILLAGE, 1638 KATLEHONG SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37590,'lng':28.17740}},
{'stp':635849,'address':'635849, EGL - SOWETO, NOMBULELO MAJOVA, TKS INN, 1148 PULE STREET, SILUMAVIEW ON STREET 4, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37550,'lng':28.15510}},
{'stp':636119,'address':'636119, EGL - SOWETO, NOMBULELO MAJOVA, ALASKA SHEBEEN, 489 MALATOLA STREET, KHUMALO VALLEY, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37900,'lng':28.16310}},
{'stp':636123,'address':'636123, EGL - SOWETO, NOMBULELO MAJOVA, THOBEJANES TAVERN, 6725 MOLELEKI SECTION, EXT 2 KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37609,'lng':28.17491}},
{'stp':636140,'address':'636140, EGL - SOWETO, NOMBULELO MAJOVA, PATRICIA SHEBEEN, 524 ZONE 1, MANDELA PARK, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31140,'lng':28.14420}},
{'stp':637479,'address':'637479, EGL - SOWETO, NOMBULELO MAJOVA, BASEMENT NIGHT CLUB, 767 MANDELA PARK, ZONE 3, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31040,'lng':28.14350}},
{'stp':637485,'address':'637485, EGL - SOWETO, NOMBULELO MAJOVA, SIGASA\'S TAVERN, 2711 SELOMA VIEW, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.38418,'lng':28.16686}},
{'stp':646709,'address':'646709, EGL - SOWETO, NOMBULELO MAJOVA, CORNELIUS TAVERN, 1710 KATLEHONG SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37835,'lng':28.17892}},
{'stp':646720,'address':'646720, EGL - SOWETO, NOMBULELO MAJOVA, NOMHLE\'S TAVERN, 8483 MOLELEKI SECTION EXT 2, MOLELEKI SECTION EXT 2, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38780,'lng':28.17470}},
{'stp':647100,'address':'647100, EGL - SOWETO, NOMBULELO MAJOVA, LINDIWE, 654 TWALA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33270,'lng':28.14840}},
{'stp':647258,'address':'647258, EGL - SOWETO, NOMBULELO MAJOVA, FREDDY\'S INN, 1485  MOLELEKI SECTION, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.37020,'lng':28.16630}},
{'stp':647376,'address':'647376, EGL - SOWETO, NOMBULELO MAJOVA, LETSHABA BAR LOUNGE, 478 NTEMA STREET, KHUMALO VALLEY, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37970,'lng':28.16360}},
{'stp':647627,'address':'647627, EGL - SOWETO, NOMBULELO MAJOVA, SHITA\'S TAVERN, 2290 DIKOLE EXT 1, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37750,'lng':28.16170}},
{'stp':649926,'address':'649926, EGL - SOWETO, NOMBULELO MAJOVA, SDAS TAVERN, 620 RAMOKONOPI WEST, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36450,'lng':28.15760}},
{'stp':650230,'address':'650230, EGL - SOWETO, NOMBULELO MAJOVA, DINAHS TUCKSHOP, 172 KWANELE SOUTH, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.37348,'lng':28.14986}},
{'stp':652853,'address':'652853, EGL - SOWETO, NOMBULELO MAJOVA, KWA TOLOS INN, 7169 MOLELEKI SECTION, EXTENSION 2, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.38560,'lng':28.17250}},
{'stp':653067,'address':'653067, EGL - SOWETO, NOMBULELO MAJOVA, BUSIS SHEBEEN, 193 MONAHENG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31890,'lng':28.15710}},
{'stp':653126,'address':'653126, EGL - SOWETO, NOMBULELO MAJOVA, PASIS TAVERN, 798 MOLELEKI SECTION, EXT 1, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37020,'lng':28.16250}},
{'stp':653214,'address':'653214, EGL - SOWETO, NOMBULELO MAJOVA, GUGUS PLACE, SHREDBLOCK NO 1, 521 MOSHOESHOE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32360,'lng':28.15970}},
{'stp':653221,'address':'653221, EGL - SOWETO, NOMBULELO MAJOVA, DIBONGS TAVERN, 550 RAMOKONOPI WEST, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36490,'lng':28.15860}},
{'stp':653230,'address':'653230, EGL - SOWETO, NOMBULELO MAJOVA, A K TAVERN, 6436 ZORRO STREET, MOLELEKI EXTENSION 20, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37710,'lng':28.17390}},
{'stp':653253,'address':'653253, EGL - SOWETO, NOMBULELO MAJOVA, HOLOS INN, 832 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31260,'lng':28.15020}},
{'stp':653260,'address':'653260, EGL - SOWETO, NOMBULELO MAJOVA, PHOKAS INN, 501 RAMOKONOPI WEST, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36450,'lng':28.16100}},
{'stp':653602,'address':'653602, EGL - SOWETO, NOMBULELO MAJOVA, MLAZIS INN, 187 187 LIKOLE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36760,'lng':28.16190}},
{'stp':675358,'address':'675358, EGL - SOWETO, NOMBULELO MAJOVA, MABEES SHEEBEN, 610 KWENELE SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37720,'lng':28.15230}},
{'stp':680389,'address':'680389, EGL - SOWETO, NOMBULELO MAJOVA, JS INN, 2055 PHEDUKA STREET, LIKOLE EXT 1, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.37260,'lng':28.16020}},
{'stp':682578,'address':'682578, EGL - SOWETO, NOMBULELO MAJOVA, EZUMBENI INN, 613 MNISI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35690,'lng':28.15510}},
{'stp':685778,'address':'685778, EGL - SOWETO, NOMBULELO MAJOVA, PHOKENG TAVERN, 3058 DIKOLE STREET, GERMISTON, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.37180,'lng':28.15540}},
{'stp':686153,'address':'686153, EGL - SOWETO, NOMBULELO MAJOVA, SIPHOS INN, 555 PHAKE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33790,'lng':28.15970}},
{'stp':695742,'address':'695742, EGL - SOWETO, NOMBULELO MAJOVA, LOTTOS INN, 331 KWENELE SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37520,'lng':28.14950}},
{'stp':733563,'address':'733563, EGL - SOWETO, NOMBULELO MAJOVA, KGOLA S TAVERN, ERF 796 RAMOKONOPI WEST, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36477,'lng':28.15382}},
{'stp':741326,'address':'741326, EGL - SOWETO, NOMBULELO MAJOVA, BUTIS TAVERN, SILUMA VIEW TOWNSHIP, ERF 430 KATLEHONG GARDENS, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38750,'lng':28.16090}},
{'stp':750388,'address':'750388, EGL - SOWETO, NOMBULELO MAJOVA, NTOMBIS TAVERN, LANGA STREET, ERF 9009, VOSLOORUS EXTENSION 20, KATLEHONG, South Africa','geo':{'lat':-26.36980,'lng':28.18120}},
{'stp':754913,'address':'754913, EGL - SOWETO, NOMBULELO MAJOVA, SASAS INN, PHUDUNGWANE STREET, ERF 7648 MOLELEKI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38280,'lng':28.17740}},
{'stp':755654,'address':'755654, EGL - SOWETO, NOMBULELO MAJOVA, NOMSAS INN, 20 9469 EXTENSION, VOSLOORUS, #, VOSLOORUS, South Africa','geo':{'lat':-26.37521,'lng':28.18326}},
{'stp':757263,'address':'757263, EGL - SOWETO, NOMBULELO MAJOVA, MAKHANDA TAVERN, BUYAFUTHI HOSTEL 295, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.36314,'lng':28.14463}},
{'stp':759831,'address':'759831, EGL - SOWETO, NOMBULELO MAJOVA, KHENSIS PLACE, 430 RAMKONOPI WEST, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.36276,'lng':28.16136}},
{'stp':765066,'address':'765066, EGL - SOWETO, NOMBULELO MAJOVA, FIRE S SHEBEEN, 3533 STAND NO, MOLELEKI EXT 1, #, KATLEHONG, South Africa','geo':{'lat':-26.36129,'lng':28.16827}},
{'stp':772795,'address':'772795, EGL - SOWETO, NOMBULELO MAJOVA, E LIQUOR BOTTLE STORE, STAND NO, ERF 7704, MOLELEKI EXT 2, KATLEHONG, South Africa','geo':{'lat':-26.38420,'lng':28.17770}},
{'stp':774732,'address':'774732, EGL - SOWETO, NOMBULELO MAJOVA, MASH S INN, 2548 STAND NO, SILUMA VIEW, #, KATLEHONG, South Africa','geo':{'lat':-26.38265,'lng':28.16345}},
{'stp':780353,'address':'780353, EGL - SOWETO, NOMBULELO MAJOVA, MAMA GEE, 0279 STAND NO, MAGAGULA HEIGHTS KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.42908,'lng':28.19124}},
{'stp':786153,'address':'786153, EGL - SOWETO, NOMBULELO MAJOVA, FREDDIES PLACE, MZWANDILE STREET, ERF 8577 MOLELEKI SECTION, EXTENSION 3, KATLEHONG, South Africa','geo':{'lat':-26.38865,'lng':28.17686}},
{'stp':799431,'address':'799431, EGL - SOWETO, NOMBULELO MAJOVA, ZIYAKHIPHA, STAND NUMBER, PHUTHUNGWANE STREET EXT 2, MOLEKI, KATLEHONG, South Africa','geo':{'lat':-26.38050,'lng':28.17710}},
{'stp':313197,'address':'313197, EGL - SOWETO, NOMBULELO MAJOVA, NTOMBI\'S PLACE, 3762 ERF NO, ACHMAT DANGOR, EDEN PARK PHASE 2, EDEN PARK, South Africa','geo':{'lat':-26.37834,'lng':28.11745}},
{'stp':383603,'address':'383603, EGL - SOWETO, NOMBULELO MAJOVA, MATABELE TAVERN, 5032 ERF NO, ZAIRE STREET, PALM RIDGE EXT 4, PALM RIDGE, South Africa','geo':{'lat':-26.38323,'lng':28.14053}},
{'stp':388377,'address':'388377, EGL - SOWETO, NOMBULELO MAJOVA, PIGO TAVERN, 4318 ERF NO, MOTHOBI MUTLOA STREET, EDEN PARK EXT 5, EDEN PARK, South Africa','geo':{'lat':-26.38024,'lng':28.11942}},
{'stp':389086,'address':'389086, EGL - SOWETO, NOMBULELO MAJOVA, BONAQUA S PLACE, 6205 STAND NO, PALM RIDGE EXTENSION 5, #, PALM RIDGE, South Africa','geo':{'lat':-26.39205,'lng':28.15895}},
{'stp':392280,'address':'392280, EGL - SOWETO, NOMBULELO MAJOVA, THE VALUE, 4243 STAND NO, HONDA STREET EDEN PARK, PHASE 4. ERF NO . 4243, EDEN PARK, South Africa','geo':{'lat':-26.38150,'lng':28.12260}},
{'stp':393416,'address':'393416, EGL - SOWETO, NOMBULELO MAJOVA, LASVEGAS TAVERN, 14574 ERF, UKUFUNA STREET, PALM RIDE EXTENSION 8 ALBERTON, PALM RIDGE, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':393441,'address':'393441, EGL - SOWETO, NOMBULELO MAJOVA, MOTHODINI TAVERN, 10896 STAND NO, OSIZWENI STREET, PALM RIDGE EXTENSION 7, PALM RIDGE, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':460071,'address':'460071, EGL - SOWETO, NOMBULELO MAJOVA, ROCKAFELLAS PLACE, 12294 STAND NO, MKUNYA STREET, PALM RIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.40336,'lng':28.15660}},
{'stp':462245,'address':'462245, EGL - SOWETO, NOMBULELO MAJOVA, ONE LOVE RASTAS, 9298 STAND NO, MFOLOZI STREET, PARK RIDGE EXT 6, PALM RIDGE, South Africa','geo':{'lat':-26.40399,'lng':28.15225}},
{'stp':462425,'address':'462425, EGL - SOWETO, NOMBULELO MAJOVA, MOREKU TAVERN, 6604 MBOKOTO STREET, MOLELEKI SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.34065,'lng':28.16091}},
{'stp':463372,'address':'463372, EGL - SOWETO, NOMBULELO MAJOVA, ENGELS INN, 3803 PHASE 2, #, #, EDEN PARK, South Africa','geo':{'lat':-26.37666,'lng':28.11911}},
{'stp':466420,'address':'466420, EGL - SOWETO, NOMBULELO MAJOVA, NANDOS TAVERN, 13378 UMSEBENZI STREET, PALM RIDGE, #, PALM RIDGE, South Africa','geo':{'lat':-26.40956,'lng':28.16463}},
{'stp':466465,'address':'466465, EGL - SOWETO, NOMBULELO MAJOVA, POLINA\'S INN, 4048 STAND NO, NOKO RAMABULA, EDEN PARK EXT 5, EDEN PARK, South Africa','geo':{'lat':-26.38026,'lng':28.11648}},
{'stp':468772,'address':'468772, EGL - SOWETO, NOMBULELO MAJOVA, SEKO\'S TAVERN, 17194 STAND NO, PALM RIDGE EXT 09, KATLEHONG, PALM RIDGE, South Africa','geo':{'lat':-26.41298,'lng':28.14587}},
{'stp':469071,'address':'469071, EGL - SOWETO, NOMBULELO MAJOVA, NGHALAMONA TAVERN, SIYADUMA STREET, ERF 2648, PALM RIDGE EXT 2, PALM RIDGE, South Africa','geo':{'lat':-26.40259,'lng':28.14392}},
{'stp':472047,'address':'472047, EGL - SOWETO, NOMBULELO MAJOVA, EMZAWENI INN, 11799 STAND NO, ODEKE STREET, ERF 11799 PALM RIDGE EXT 7, PALM RIDGE, South Africa','geo':{'lat':-26.40530,'lng':28.15720}},
{'stp':476825,'address':'476825, EGL - SOWETO, NOMBULELO MAJOVA, HLUBUKANI TAVERN, 11848 MKUNYA STREET, EXTENSION 7, PALM RIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.40646,'lng':28.15649}},
{'stp':482258,'address':'482258, EGL - SOWETO, NOMBULELO MAJOVA, PULENG TAVERN, 1500 ERF NO, MYEZANE STREET, GREENFIELD, ALBERTON, South Africa','geo':{'lat':-26.39525,'lng':28.12823}},
{'stp':484788,'address':'484788, EGL - SOWETO, NOMBULELO MAJOVA, MAPLAKENIS TAVERN, 9096 STAND NUMBER, CONGO STREET, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.40318,'lng':28.15275}},
{'stp':484810,'address':'484810, EGL - SOWETO, NOMBULELO MAJOVA, NATHIS TAVERN, 8801 STAND NUMBER, DANUBE STREET, EXT 6, PALM RIDGE, South Africa','geo':{'lat':-26.40020,'lng':28.14993}},
{'stp':496316,'address':'496316, EGL - SOWETO, NOMBULELO MAJOVA, MABUZA INN, 9368 ERF NO, BENUES, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.40357,'lng':28.15193}},
{'stp':547554,'address':'547554, EGL - SOWETO, NOMBULELO MAJOVA, MOSCOS TAVERN, ERF 1137, NINETH AVENUE, ZONKIZIZWE ZONE, KATLEHONG, South Africa','geo':{'lat':-26.41037,'lng':28.17857}},
{'stp':561126,'address':'561126, EGL - SOWETO, NOMBULELO MAJOVA, JACKS INN, 17 ALFA STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37260,'lng':28.12660}},
{'stp':561296,'address':'561296, EGL - SOWETO, NOMBULELO MAJOVA, JACKS INN, 8494 PALM RIDGE EXT 6, PALM RIDGE, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.39920,'lng':28.15172}},
{'stp':580562,'address':'580562, EGL - SOWETO, NOMBULELO MAJOVA, LEPHOTO TAVERN, 263 ZONE 03, ZONKIZIZWE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.40780,'lng':28.17750}},
{'stp':602864,'address':'602864, EGL - SOWETO, NOMBULELO MAJOVA, PHUMELELAS TAVERN, 1780 EXTENSION 1, PALMRIDGE, EDEN PARK, PALM RIDGE, South Africa','geo':{'lat':-26.40531,'lng':28.14825}},
{'stp':603371,'address':'603371, EGL - SOWETO, NOMBULELO MAJOVA, DICK PUB, 455 MOSELEKE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34660,'lng':28.15920}},
{'stp':603850,'address':'603850, EGL - SOWETO, NOMBULELO MAJOVA, POSH SPORT BAR, 13344 PALM RIDGE EXT 8, PALM RIDGE, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.41120,'lng':28.16400}},
{'stp':603871,'address':'603871, EGL - SOWETO, NOMBULELO MAJOVA, JAZZ INN TAVERN, 7 FELICIA STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.39150,'lng':28.14330}},
{'stp':604119,'address':'604119, EGL - SOWETO, NOMBULELO MAJOVA, NORAHS PLACE, STAND NO, ERF 387, 2 CELTIS ROAD, PALM RIDGE, South Africa','geo':{'lat':-26.39350,'lng':28.15300}},
{'stp':604212,'address':'604212, EGL - SOWETO, NOMBULELO MAJOVA, MOLLY\'S JAZZ BAR, 16 AUDIE STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37200,'lng':28.12630}},
{'stp':604838,'address':'604838, EGL - SOWETO, NOMBULELO MAJOVA, EMALANGENI, 2847 ZONE 1, ZONKIZIZWE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.41720,'lng':28.17320}},
{'stp':605076,'address':'605076, EGL - SOWETO, NOMBULELO MAJOVA, MAJOZI TAVERN, 905 MOFOKENG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35427,'lng':28.16259}},
{'stp':605170,'address':'605170, EGL - SOWETO, NOMBULELO MAJOVA, TO MUCH HEAVEN, 43 BENZ STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37268,'lng':28.12349}},
{'stp':605542,'address':'605542, EGL - SOWETO, NOMBULELO MAJOVA, TIBANE TAVERN, 13544 PALMRIDGE EXT 8, PALM RIDGE, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.41170,'lng':28.16380}},
{'stp':605680,'address':'605680, EGL - SOWETO, NOMBULELO MAJOVA, BEN\'S TAVERN, 2558 NKAKI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.38228,'lng':28.12497}},
{'stp':605820,'address':'605820, EGL - SOWETO, NOMBULELO MAJOVA, 5050 TAVERN, 2163 EXT 02, PALM RIDGE, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.39800,'lng':28.14380}},
{'stp':606716,'address':'606716, EGL - SOWETO, NOMBULELO MAJOVA, PHINDIS TAVERN, 625 KWANELE SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37760,'lng':28.15120}},
{'stp':607101,'address':'607101, EGL - SOWETO, NOMBULELO MAJOVA, MOONLIGHT INN, 2328 UGAGANE STREET, GREENFIELDS, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.40000,'lng':28.13370}},
{'stp':607115,'address':'607115, EGL - SOWETO, NOMBULELO MAJOVA, SIS FIKILES PLACE, 13277 TSIETSI SECTION  EXT 8, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.40990,'lng':28.16420}},
{'stp':625895,'address':'625895, EGL - SOWETO, NOMBULELO MAJOVA, HLALETHEMBENI TAVERN PERMIT, 8456 PALMRIDGE EXT 6, PALM RIDGE, ALBERTON, EDEN PARK, South Africa','geo':{'lat':-26.39832,'lng':28.15142}},
{'stp':627162,'address':'627162, EGL - SOWETO, NOMBULELO MAJOVA, MELROSE PLACE PERMIT, 1698 TINA SONKE, EXT 03, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.37130,'lng':28.10570}},
{'stp':627238,'address':'627238, EGL - SOWETO, NOMBULELO MAJOVA, CONTINENTAL RESTAURANT, PLOT 13 DRIFT STREET, KLIPRIVER, KLIPRIVER, ALBERTON, South Africa','geo':{'lat':-26.42110,'lng':28.09850}},
{'stp':627400,'address':'627400, EGL - SOWETO, NOMBULELO MAJOVA, NOZIS TAVERN, 1563 MQWALOTI CRESENT, GREENFIELDS, EDEN PARK, TOKOZA, South Africa','geo':{'lat':-26.39650,'lng':28.13110}},
{'stp':627794,'address':'627794, EGL - SOWETO, NOMBULELO MAJOVA, VIVA KHOZA GENERAL DEALER PERMIT, 12554 KHULE STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.40940,'lng':28.15380}},
{'stp':628333,'address':'628333, EGL - SOWETO, NOMBULELO MAJOVA, MADOLO  LIQOUR STORE, 1568 UMTHOLO CRESENT, GREENFIELD, #, EDEN PARK, South Africa','geo':{'lat':-26.39580,'lng':28.13120}},
{'stp':628440,'address':'628440, EGL - SOWETO, NOMBULELO MAJOVA, MANCANE SHEBEEN, 3030 SAUSAGE TREE STREET, GREENFIELDS TOWNSHIP, #, EDEN PARK, South Africa','geo':{'lat':-26.39070,'lng':28.13467}},
{'stp':628656,'address':'628656, EGL - SOWETO, NOMBULELO MAJOVA, GENEVAS SPORTS BAR PERMIT, CNR PETERSON & HONDA STR, ALBERTON, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.38200,'lng':28.12500}},
{'stp':628749,'address':'628749, EGL - SOWETO, NOMBULELO MAJOVA, SESIS TAVERN PERMIT, 1303 ZONKIZIZWE ZONE 4, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.41276,'lng':28.18408}},
{'stp':629141,'address':'629141, EGL - SOWETO, NOMBULELO MAJOVA, MATIKOS PLACE PERMIT,  2 FALENCIA STREET, PALM RIDGE, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.39110,'lng':28.14350}},
{'stp':629618,'address':'629618, EGL - SOWETO, NOMBULELO MAJOVA, KETSIS INN PERMIT, 157 ERF 3184 FRANKLIN SONN STREET, #, EDEN PARK EXTENSION 5, TOKOZA, South Africa','geo':{'lat':-26.37536,'lng':28.11835}},
{'stp':630625,'address':'630625, EGL - SOWETO, NOMBULELO MAJOVA, TEBZAS TAVERN PERMIT, 1198 EXT 1, PALM RIDGE, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.40440,'lng':28.14130}},
{'stp':632731,'address':'632731, EGL - SOWETO, NOMBULELO MAJOVA, J PIETERSEN SUPERMARKET AND DIST, 7 MAZDA STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37680,'lng':28.13340}},
{'stp':636075,'address':'636075, EGL - SOWETO, NOMBULELO MAJOVA, LUPALULE TUCKSHOP, 1645 MTHOLO CRESCENT, GREENFIELDS, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.39560,'lng':28.12980}},
{'stp':639892,'address':'639892, EGL - SOWETO, NOMBULELO MAJOVA, SARAH\'S EXCLUSIVE TAVERN, 91 WYNKWAARD LINE, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.38670,'lng':28.12260}},
{'stp':640468,'address':'640468, EGL - SOWETO, NOMBULELO MAJOVA, DAVI TAVERN, 35 COMBRETUM ROAD, PALM RIDGE, EDEN PARK, PALM RIDGE, South Africa','geo':{'lat':-26.39700,'lng':28.14980}},
{'stp':646721,'address':'646721, EGL - SOWETO, NOMBULELO MAJOVA, NOMBULELO\'S TAVERN, 690 VERBENA CRESCENT, GREENFIELDS, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.39050,'lng':28.13223}},
{'stp':646750,'address':'646750, EGL - SOWETO, NOMBULELO MAJOVA, KHANYISA TAVERN, 14254 NTOBOZANE STREET, EXT 8 KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.41260,'lng':28.15840}},
{'stp':647348,'address':'647348, EGL - SOWETO, NOMBULELO MAJOVA, ZULU SE KOUSE, 30 ARIEL STREET, EDENPARK, EDENPARK, EDEN PARK, South Africa','geo':{'lat':-26.38690,'lng':28.12430}},
{'stp':647677,'address':'647677, EGL - SOWETO, NOMBULELO MAJOVA, EMMANUEL TAVERN, 4085, EXT 3, PALMRIDGE, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.39750,'lng':28.13470}},
{'stp':647680,'address':'647680, EGL - SOWETO, NOMBULELO MAJOVA, BLUE BIRD TAVERN, STAND 4066, EXT 3, PALMRIDGE, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.39719,'lng':28.13521}},
{'stp':648266,'address':'648266, EGL - SOWETO, NOMBULELO MAJOVA, MAKHOSI TAVERN, 3541 PALMRIDGE EXT 3, PALMRIDGE, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.39840,'lng':28.13800}},
{'stp':648645,'address':'648645, EGL - SOWETO, NOMBULELO MAJOVA, MOKONI TAVERN, STAND 3931, PALMRIDGE EXT 3, GERMISTON, PALM RIDGE, South Africa','geo':{'lat':-26.40260,'lng':28.13640}},
{'stp':648972,'address':'648972, EGL - SOWETO, NOMBULELO MAJOVA, ZECA TAVERN, 5550 PALMRIDGE, PALMRIDGE EXT 4, GERMISTON, PALM RIDGE, South Africa','geo':{'lat':-26.38800,'lng':28.13567}},
{'stp':650206,'address':'650206, EGL - SOWETO, NOMBULELO MAJOVA, FANNYS TAVERN, 39 MAZDA STREET, EDEN PARK, ALBERTON, EDEN PARK, South Africa','geo':{'lat':-26.37730,'lng':28.13210}},
{'stp':650210,'address':'650210, EGL - SOWETO, NOMBULELO MAJOVA, CARMINS INN, 4 CORONA STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37739,'lng':28.12801}},
{'stp':650214,'address':'650214, EGL - SOWETO, NOMBULELO MAJOVA, THOLES SHEBEEN, 117 GREENFIELDS, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.39080,'lng':28.12490}},
{'stp':650232,'address':'650232, EGL - SOWETO, NOMBULELO MAJOVA, PLEASURE DOME TAVERN, 94 LEON STREET, EDENPARK, ALBERTON, EDEN PARK, South Africa','geo':{'lat':-26.38600,'lng':28.13140}},
{'stp':653129,'address':'653129, EGL - SOWETO, NOMBULELO MAJOVA, MZALAS TAVERN, 4522 EXTENSION 3, PALMRIDGE, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.39920,'lng':28.13440}},
{'stp':653195,'address':'653195, EGL - SOWETO, NOMBULELO MAJOVA, MARGARET TAVERN, 12483 EXTENSION 8, PALMRIDGE, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.40720,'lng':28.15600}},
{'stp':653205,'address':'653205, EGL - SOWETO, NOMBULELO MAJOVA, MOLIEHIS INN, 3601 LIKOLE SECTION, EXTENSION 2, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36790,'lng':28.15640}},
{'stp':653234,'address':'653234, EGL - SOWETO, NOMBULELO MAJOVA, ZOO LAKE, 4 DAIMLER STREET, EDEN PARK, ALBERTON, EDEN PARK, South Africa','geo':{'lat':-26.37630,'lng':28.12110}},
{'stp':653238,'address':'653238, EGL - SOWETO, NOMBULELO MAJOVA, TSWELOPELE SHEBEEN, 1673 THINASONKE EXTENSION 3, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36970,'lng':28.10540}},
{'stp':653890,'address':'653890, EGL - SOWETO, NOMBULELO MAJOVA, LINDIS TAVERN, 7386 NAMUHATSI STREET, MOLELEKI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38550,'lng':28.17260}},
{'stp':654136,'address':'654136, EGL - SOWETO, NOMBULELO MAJOVA, SIKA TAVERN, B 7402 SONWABO EXT 5, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.39380,'lng':28.16240}},
{'stp':655078,'address':'655078, EGL - SOWETO, NOMBULELO MAJOVA, GRANNYS INN, 626 TYOBEKA STREET, KHUMALO VALLEY, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37280,'lng':28.16530}},
{'stp':664326,'address':'664326, EGL - SOWETO, NOMBULELO MAJOVA, BONGANIS TAVERN, 79 SALI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35497,'lng':28.15116}},
{'stp':664367,'address':'664367, EGL - SOWETO, NOMBULELO MAJOVA, THEMBIVANGELI, 874 KATLEHONG GARDENS, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38502,'lng':28.15600}},
{'stp':664929,'address':'664929, EGL - SOWETO, NOMBULELO MAJOVA, ZAMA ALWAYS, 6899 YIMVU STREET, SONWABA EXT 5, BLOCK C, PALM RIDGE, South Africa','geo':{'lat':-26.39690,'lng':28.16520}},
{'stp':666146,'address':'666146, EGL - SOWETO, NOMBULELO MAJOVA, CECES PLACE, 13 FIAT STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37847,'lng':28.12896}},
{'stp':666190,'address':'666190, EGL - SOWETO, NOMBULELO MAJOVA, PATS PLACE, 101 FLAME BEECH WOOD STREET, GREENFIELDS, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.39110,'lng':28.12530}},
{'stp':666253,'address':'666253, EGL - SOWETO, NOMBULELO MAJOVA, ESIBAYENI, 539 UMBRELLA STREET, GREENFIELD, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.39180,'lng':28.12860}},
{'stp':666803,'address':'666803, EGL - SOWETO, NOMBULELO MAJOVA, JOES PLACE, 5703 PALMRIDGE EXT 4,  EXT 4, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.38640,'lng':28.13390}},
{'stp':668298,'address':'668298, EGL - SOWETO, NOMBULELO MAJOVA, ROGERS TAVERN, 2865 PALM RIDGE, EXTENSION 2, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.40110,'lng':28.14460}},
{'stp':672939,'address':'672939, EGL - SOWETO, NOMBULELO MAJOVA, SHALABANIS PLACE, 5228 PALM RIDGE, EXTENSION 4, GERMISTON, PALM RIDGE, South Africa','geo':{'lat':-26.38540,'lng':28.14110}},
{'stp':698393,'address':'698393, EGL - SOWETO, NOMBULELO MAJOVA, PHUMULA TAVERN, 4151 4151  MBALAMBALA STREET, EXT 3, #, PALM RIDGE, South Africa','geo':{'lat':-26.39400,'lng':28.13560}},
{'stp':708316,'address':'708316, EGL - SOWETO, NOMBULELO MAJOVA, GENEVA LIQUORS, 32 HONDA STREET, EDEN PARK, ALBERTON, EDEN PARK, South Africa','geo':{'lat':-26.38230,'lng':28.12500}},
{'stp':719230,'address':'719230, EGL - SOWETO, NOMBULELO MAJOVA, MOKOENA TAVERN, 1608 THINASONKE, EXTENSION 3, #, TOKOZA, South Africa','geo':{'lat':-26.37000,'lng':28.10550}},
{'stp':744347,'address':'744347, EGL - SOWETO, NOMBULELO MAJOVA, TLOKWENG TAVERN, 4579 CELTIS STREET, PALMRIDGE, KATLEHONG, PALM RIDGE, South Africa','geo':{'lat':-26.39960,'lng':28.13710}},
{'stp':751107,'address':'751107, EGL - SOWETO, NOMBULELO MAJOVA, MOTHODINI TAVERN, OSIZWENI STREET, ERF 10897, PALM RIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.40490,'lng':28.16660}},
{'stp':751411,'address':'751411, EGL - SOWETO, NOMBULELO MAJOVA, SIZA KANCANE TAVERN, STAND NO, ERF 1215 THINASONKE, EXTENSION 3, TOKOZA, South Africa','geo':{'lat':-26.36460,'lng':28.10110}},
{'stp':752080,'address':'752080, EGL - SOWETO, NOMBULELO MAJOVA, BAFOKENG TAVERN, NGELE STREET, ERF 1758, PALM RIDGE EXTENSION 1, PALM RIDGE, South Africa','geo':{'lat':-26.40518,'lng':28.14721}},
{'stp':754527,'address':'754527, EGL - SOWETO, NOMBULELO MAJOVA, T R S TAVERN, 14350 NTOMBAZNAE STREET EXT 8, PALM RIDGE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.41228,'lng':28.15725}},
{'stp':763174,'address':'763174, EGL - SOWETO, NOMBULELO MAJOVA, MMANKUNA LIQUOR DISTRIBUTORS, 5804 STAND NO, EXT 4, #, PALM RIDGE, South Africa','geo':{'lat':-26.38800,'lng':28.13290}},
{'stp':779881,'address':'779881, EGL - SOWETO, NOMBULELO MAJOVA, LOTUS RESTAURANT, 1021 STAND NO, SOETDORING AND TEEBOS, #, PALM RIDGE, South Africa','geo':{'lat':-26.38863,'lng':28.14034}},
{'stp':782738,'address':'782738, EGL - SOWETO, NOMBULELO MAJOVA, STEVE S INN, STAND NO, ERF 3234 ZONKEZIZWE TOWNSHIP, #, KATLEHONG, South Africa','geo':{'lat':-26.35850,'lng':28.16120}},
{'stp':786089,'address':'786089, EGL - SOWETO, NOMBULELO MAJOVA, T J TAVERN, STAND NO, ERF 11348 PAL RIDGE EXT 7, #, KATLEHONG, South Africa','geo':{'lat':-26.40340,'lng':28.16270}},
{'stp':787047,'address':'787047, EGL - SOWETO, NOMBULELO MAJOVA, MSHENGO SHEBEEN, 10544 STAND NO, PALM RIDGE, EXTENSION 7, PALM RIDGE, South Africa','geo':{'lat':-26.40310,'lng':28.16431}},
{'stp':789557,'address':'789557, EGL - SOWETO, NOMBULELO MAJOVA, TERA S TAVERN, 5609 STAND NNO, DAN SEREOTE STREET EDEN PARK EXT 5, #, PALM RIDGE, South Africa','geo':{'lat':-26.38810,'lng':28.12060}},
{'stp':789576,'address':'789576, EGL - SOWETO, NOMBULELO MAJOVA, HILAB KHANGELE TAVERN, STAND NO, 1385 EXT PALMRIDGE, FOCUS AREA, PALM RIDGE, South Africa','geo':{'lat':-26.40495,'lng':28.14312}},
{'stp':795833,'address':'795833, EGL - SOWETO, NOMBULELO MAJOVA, LAS VEGAS INN, 10437 STAND NO, EXTENSION 7 SONWABO, #, KATLEHONG, South Africa','geo':{'lat':-26.39940,'lng':28.15730}},
{'stp':799742,'address':'799742, EGL - SOWETO, NOMBULELO MAJOVA, MOSHAPES TAVERN, SEFOKABOLEA STREET, ERF 8212, PALM RIDGE EXTENSION 6, PALM RIDGE, South Africa','geo':{'lat':-26.39720,'lng':28.15367}},
{'stp':306845,'address':'306845, EGL - SOWETO, NOMBULELO MAJOVA, KATIS PUB, 13401 ERF NO, 59 YENDE STREET, TOKOZA EXTENSION 5, TOKOZA, South Africa','geo':{'lat':-26.36055,'lng':28.12903}},
{'stp':351985,'address':'351985, EGL - SOWETO, NOMBULELO MAJOVA, MORNING GROOVE TAVERN, 1291 STAND NO, TOKOZA EXT 2, ERF 11962, #, TOKOZA, South Africa','geo':{'lat':-26.36848,'lng':28.13503}},
{'stp':390153,'address':'390153, EGL - SOWETO, NOMBULELO MAJOVA, ZETS PLACE, 13475 ERF, PHOLA PARK EXTENSION 5, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36667,'lng':28.12867}},
{'stp':463380,'address':'463380, EGL - SOWETO, NOMBULELO MAJOVA, SDUMO TAVERN, 5251 STAND NO, PHOLA PARK, THOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36532,'lng':28.12396}},
{'stp':485000,'address':'485000, EGL - SOWETO, NOMBULELO MAJOVA, BOTLENG SHEBEEN, 1854/1 STAND NO, PHIRI CRESCENT, EXTENSION 1, TOKOZA, South Africa','geo':{'lat':-26.36240,'lng':28.13340}},
{'stp':489614,'address':'489614, EGL - SOWETO, NOMBULELO MAJOVA, THE ZAMA, 2 STAND NUMBER, 201849 OTHANDWENI LOCATION, THOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36229,'lng':28.13034}},
{'stp':490824,'address':'490824, EGL - SOWETO, NOMBULELO MAJOVA, T M LIQUOR STORE, 6194 STAND NO, MATALA STREET, THOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34265,'lng':28.13510}},
{'stp':549430,'address':'549430, EGL - SOWETO, NOMBULELO MAJOVA, MZWAPHI TAVERN, STAND NO, ERF 11655 NTAKE STREET, TOKOZA EXT 2, TOKOZA, South Africa','geo':{'lat':-26.36539,'lng':28.13905}},
{'stp':550815,'address':'550815, EGL - SOWETO, NOMBULELO MAJOVA, NOTANDO TAVERN PERMIT, 4381 EXTENSION 3, PALM RIDGE, PALM RIDGE, EDEN PARK, South Africa','geo':{'lat':-26.39790,'lng':28.13660}},
{'stp':551625,'address':'551625, EGL - SOWETO, NOMBULELO MAJOVA, LINDI S TAVERN, STAND10905 MOTSAMAI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36490,'lng':28.13430}},
{'stp':551720,'address':'551720, EGL - SOWETO, NOMBULELO MAJOVA, THEMBI S INN, 1701 PHIRI CRESENT, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36240,'lng':28.13340}},
{'stp':551843,'address':'551843, EGL - SOWETO, NOMBULELO MAJOVA, MTSHWALAS INN, 7876 TSHIRELA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35860,'lng':28.13400}},
{'stp':552086,'address':'552086, EGL - SOWETO, NOMBULELO MAJOVA, MOSS MORE TAVERN, STAND 13709, EXT 2 PHOLA PARK, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36700,'lng':28.13100}},
{'stp':552339,'address':'552339, EGL - SOWETO, NOMBULELO MAJOVA, UNIT F LIQUORS, 12003 YENDY STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35930,'lng':28.14170}},
{'stp':552640,'address':'552640, EGL - SOWETO, NOMBULELO MAJOVA, SIYAZAMA TAVERN, STAND 13929, TLHWARE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.37220,'lng':28.13600}},
{'stp':552667,'address':'552667, EGL - SOWETO, NOMBULELO MAJOVA, PHUTHADIJHABA, 9501 KUMALO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36940,'lng':28.14290}},
{'stp':552750,'address':'552750, EGL - SOWETO, NOMBULELO MAJOVA, LOLO S INN, 1616 BOTODI CRESCENT, OTHANDWENI, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36290,'lng':28.13140}},
{'stp':553114,'address':'553114, EGL - SOWETO, NOMBULELO MAJOVA, MBANJWA TAVERN, STAND 13032, PHOLA PARK EXT 1, EXT 1, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36310,'lng':28.12800}},
{'stp':553492,'address':'553492, EGL - SOWETO, NOMBULELO MAJOVA, WHITE HOUSE TAVERN,  6123 MOTSELE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34250,'lng':28.13760}},
{'stp':553535,'address':'553535, EGL - SOWETO, NOMBULELO MAJOVA, CASTAS JOINT, 7396 SEBOKO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35670,'lng':28.13620}},
{'stp':553567,'address':'553567, EGL - SOWETO, NOMBULELO MAJOVA, TIMING BAR LOUNGE, CNR MADONDO & MDAKANE STS, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33950,'lng':28.14080}},
{'stp':553800,'address':'553800, EGL - SOWETO, NOMBULELO MAJOVA, MASPRINGS PLACE PERMIT, 6631 TENYANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34554,'lng':28.13435}},
{'stp':553843,'address':'553843, EGL - SOWETO, NOMBULELO MAJOVA, MOTSWALA WA THABO, 7847 MAHANO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35860,'lng':28.13390}},
{'stp':553844,'address':'553844, EGL - SOWETO, NOMBULELO MAJOVA, THABILES TAVERN PERMIT, 1218 MOROBISI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36570,'lng':28.13610}},
{'stp':553860,'address':'553860, EGL - SOWETO, NOMBULELO MAJOVA, RIAS PLACE PERMIT, 6185 MOLOI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34210,'lng':28.13530}},
{'stp':553935,'address':'553935, EGL - SOWETO, NOMBULELO MAJOVA, P H MAMPURU, 3056 NKAKI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35794,'lng':28.14126}},
{'stp':560338,'address':'560338, EGL - SOWETO, NOMBULELO MAJOVA, CHIPPAS TAVERN, 2683 NKAKI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35380,'lng':28.14100}},
{'stp':560532,'address':'560532, EGL - SOWETO, NOMBULELO MAJOVA, OLLY\'S TAVERN, 627 LENONG STREET, TOKOZA, EXT 2, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36390,'lng':28.14040}},
{'stp':561132,'address':'561132, EGL - SOWETO, NOMBULELO MAJOVA, LIMPOPO INN, MPYE STREET, ERF 6157 EXTENSION 1, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35720,'lng':28.13050}},
{'stp':561286,'address':'561286, EGL - SOWETO, NOMBULELO MAJOVA, GUMBI S INN, 2950 TLHOARE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35540,'lng':28.14280}},
{'stp':571802,'address':'571802, EGL - SOWETO, NOMBULELO MAJOVA, RAINBOW TAVERN, 9159 TSHELE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35660,'lng':28.13030}},
{'stp':571803,'address':'571803, EGL - SOWETO, NOMBULELO MAJOVA, MAJUBAS TAVERN, 13555 PHOLA PARK, EXT 5, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36840,'lng':28.12820}},
{'stp':580523,'address':'580523, EGL - SOWETO, NOMBULELO MAJOVA, STANLEY\'S TAVERN, 20 TLADI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36805,'lng':28.13483}},
{'stp':580541,'address':'580541, EGL - SOWETO, NOMBULELO MAJOVA, NTOMBIS INN, 1656 SIMELANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36240,'lng':28.13150}},
{'stp':580550,'address':'580550, EGL - SOWETO, NOMBULELO MAJOVA, ALICE TAVERN, 10 LETLASA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34040,'lng':28.13340}},
{'stp':580878,'address':'580878, EGL - SOWETO, NOMBULELO MAJOVA, VIVALDIS PLACE, 67 STUDEBAKER STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.38060,'lng':28.13530}},
{'stp':602896,'address':'602896, EGL - SOWETO, NOMBULELO MAJOVA, OASIS TAVERN, 7294 MOEPSHE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35650,'lng':28.13490}},
{'stp':603077,'address':'603077, EGL - SOWETO, NOMBULELO MAJOVA, MIYA CORNE LUNCH, 8006 KHUMALO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35360,'lng':28.13180}},
{'stp':603078,'address':'603078, EGL - SOWETO, NOMBULELO MAJOVA, KIDDIES AND ABBIES, 8904 TOPAZ CRESCENT, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35500,'lng':28.12700}},
{'stp':603240,'address':'603240, EGL - SOWETO, NOMBULELO MAJOVA, MAPARINGS TAVERN PERMIT, 7311 MOTUMI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35730,'lng':28.13530}},
{'stp':603375,'address':'603375, EGL - SOWETO, NOMBULELO MAJOVA, TLOKWENG INN, 10018 MAKENG STREET, EXT 5, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36860,'lng':28.13170}},
{'stp':604025,'address':'604025, EGL - SOWETO, NOMBULELO MAJOVA, SBA INN, 9669 NKOANE STREET, EXTENTION 2, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.37130,'lng':28.13910}},
{'stp':604750,'address':'604750, EGL - SOWETO, NOMBULELO MAJOVA, THABILE S TAVERN, 6060 KHUMALO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34200,'lng':28.13840}},
{'stp':605978,'address':'605978, EGL - SOWETO, NOMBULELO MAJOVA, LUCKYS TAVERN, 8401 MAMELLO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36500,'lng':28.14120}},
{'stp':606017,'address':'606017, EGL - SOWETO, NOMBULELO MAJOVA, UNCLE LAWS TAVERN, 8376 LANGA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36260,'lng':28.14110}},
{'stp':606019,'address':'606019, EGL - SOWETO, NOMBULELO MAJOVA, THATHOHATSI INN, 6053 KHUMALO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34170,'lng':28.13850}},
{'stp':606253,'address':'606253, EGL - SOWETO, NOMBULELO MAJOVA, LERATO TAVERN, 6708 MOLEFETSE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34556,'lng':28.13298}},
{'stp':606703,'address':'606703, EGL - SOWETO, NOMBULELO MAJOVA, SHEILA S TAVERN, 7201 RAMONOTSI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35490,'lng':28.13290}},
{'stp':607199,'address':'607199, EGL - SOWETO, NOMBULELO MAJOVA, LESOTHO INN, 6843 MAPATANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35620,'lng':28.13220}},
{'stp':607210,'address':'607210, EGL - SOWETO, NOMBULELO MAJOVA, CINNA INN, 1574 SIMELANE STREET EXT 1,  TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36260,'lng':28.13110}},
{'stp':625465,'address':'625465, EGL - SOWETO, NOMBULELO MAJOVA, MAHLANGA TUCK SHOP, 3237 NOGWAJA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35843,'lng':28.14036}},
{'stp':625494,'address':'625494, EGL - SOWETO, NOMBULELO MAJOVA, NOMSAS INN, 4 LETLATSA CRESENT, THINTWA SECTION, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34040,'lng':28.13360}},
{'stp':625767,'address':'625767, EGL - SOWETO, NOMBULELO MAJOVA, TSELENGS INN PERMIT, 7523 KHUMALO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35790,'lng':28.12990}},
{'stp':626561,'address':'626561, EGL - SOWETO, NOMBULELO MAJOVA, MONGWENI TAVERN, 10187 BOSTON CRESCENT, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36990,'lng':28.13080}},
{'stp':626681,'address':'626681, EGL - SOWETO, NOMBULELO MAJOVA, KAYINGANA TAVERN PERMIT, 12487 PHOLA PARK EXT 1, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36260,'lng':28.12480}},
{'stp':626766,'address':'626766, EGL - SOWETO, NOMBULELO MAJOVA, B AND B, 6120 MASHISHI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34210,'lng':28.13740}},
{'stp':627104,'address':'627104, EGL - SOWETO, NOMBULELO MAJOVA, SLENDERS TAVERN, 1851 MTSHALI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34880,'lng':28.13640}},
{'stp':627131,'address':'627131, EGL - SOWETO, NOMBULELO MAJOVA, BOYS INN PERMIT, 7866 MOEPHSE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36030,'lng':28.13370}},
{'stp':627178,'address':'627178, EGL - SOWETO, NOMBULELO MAJOVA, THEMBIS PLACE PERMIT, 249 MDAKANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33860,'lng':28.14150}},
{'stp':627572,'address':'627572, EGL - SOWETO, NOMBULELO MAJOVA, LIZZYS INN, 6343 SEKONYELA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34480,'lng':28.13430}},
{'stp':627808,'address':'627808, EGL - SOWETO, NOMBULELO MAJOVA, SISPS TAVERN PERMIT, 1053 NKOSI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34500,'lng':28.13920}},
{'stp':627973,'address':'627973, EGL - SOWETO, NOMBULELO MAJOVA, REBECCAS INN PERMIT, 6997 SENTSENYANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35690,'lng':28.13220}},
{'stp':627980,'address':'627980, EGL - SOWETO, NOMBULELO MAJOVA, MIKES TAVERN PERMIT, 9006 TSOMANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35540,'lng':28.12950}},
{'stp':628122,'address':'628122, EGL - SOWETO, NOMBULELO MAJOVA, KHANIMAMBO TAVERN, 7440 TSOTETSI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35740,'lng':28.13680}},
{'stp':628123,'address':'628123, EGL - SOWETO, NOMBULELO MAJOVA, ZOLAS TAVERN PERMIT, 6413 PITSO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34440,'lng':28.13580}},
{'stp':628342,'address':'628342, EGL - SOWETO, NOMBULELO MAJOVA, BAHLAKWANENG PALACE PERMIT, 6169 MPYE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34400,'lng':28.13320}},
{'stp':628373,'address':'628373, EGL - SOWETO, NOMBULELO MAJOVA, TUMIS INN PERMIT, BHEJANE STREET 8516, UNIT F, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36280,'lng':28.14200}},
{'stp':628494,'address':'628494, EGL - SOWETO, NOMBULELO MAJOVA, MENYANES PLACE PERMIT, 7462 TSOTETSI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35670,'lng':28.13680}},
{'stp':628889,'address':'628889, EGL - SOWETO, NOMBULELO MAJOVA, PATS PLACE PERMIT, 8338 MOHOLODI STREET, UNIT F, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36050,'lng':28.13880}},
{'stp':628942,'address':'628942, EGL - SOWETO, NOMBULELO MAJOVA, NXUMALOS TAVERN PERMIT, 1587 ELANGENI STREET, EXT 1, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35997,'lng':28.12880}},
{'stp':630141,'address':'630141, EGL - SOWETO, NOMBULELO MAJOVA, SPORO SHEBEEN PERMIT, 6092 NTHOROANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34145,'lng':28.13743}},
{'stp':630143,'address':'630143, EGL - SOWETO, NOMBULELO MAJOVA, EKHONENI PERMIT, 5319 PHOLO PARK, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36320,'lng':28.12310}},
{'stp':630640,'address':'630640, EGL - SOWETO, NOMBULELO MAJOVA, TA ZEE PERMIT, 7536 SEBOTSA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35880,'lng':28.13010}},
{'stp':630652,'address':'630652, EGL - SOWETO, NOMBULELO MAJOVA, MBHELE AND SON, 13973 PHOLA PARK  EXT 2, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.37400,'lng':28.13550}},
{'stp':631076,'address':'631076, EGL - SOWETO, NOMBULELO MAJOVA, E M NDLOVU SHEBEEN, 8665 TLOU STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36650,'lng':28.14170}},
{'stp':632575,'address':'632575, EGL - SOWETO, NOMBULELO MAJOVA, TUDOR INN, ERF 12989, KATHORUS MARKET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36460,'lng':28.12910}},
{'stp':635494,'address':'635494, EGL - SOWETO, NOMBULELO MAJOVA, LOLOS PLACE, 975 NDEBELE ST, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34383,'lng':28.13931}},
{'stp':636012,'address':'636012, EGL - SOWETO, NOMBULELO MAJOVA, MAMOEKETSI SHEBEEN, THINANE STREET, ERF 6276, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34410,'lng':28.13540}},
{'stp':636891,'address':'636891, EGL - SOWETO, NOMBULELO MAJOVA, SPRINGBOK TAVERN, ERF 172 MPILISWENI SECTION, MPILISWENI SECTION, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33820,'lng':28.13490}},
{'stp':637724,'address':'637724, EGL - SOWETO, NOMBULELO MAJOVA, UNCLE BHUTI\'S TAVERN, 88 PULEDHI DRIVE, TOKOZA, ALBERTON, TOKOZA, South Africa','geo':{'lat':-26.34570,'lng':28.12990}},
{'stp':638119,'address':'638119, EGL - SOWETO, NOMBULELO MAJOVA, BIG MAMA, 6178 MOLOI STREET, TOKOZA, ALBERTON, TOKOZA, South Africa','geo':{'lat':-26.34250,'lng':28.13590}},
{'stp':646703,'address':'646703, EGL - SOWETO, NOMBULELO MAJOVA, MATHAFENI TAVERN, 2330 UGAGANE STREET, GREENFIELDS, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.40055,'lng':28.13357}},
{'stp':649868,'address':'649868, EGL - SOWETO, NOMBULELO MAJOVA, ZOS INN, 6139 MOLOI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34255,'lng':28.13642}},
{'stp':649901,'address':'649901, EGL - SOWETO, NOMBULELO MAJOVA, SPUTLES TAVERN, 3347 MOKOENA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35560,'lng':28.13900}},
{'stp':649902,'address':'649902, EGL - SOWETO, NOMBULELO MAJOVA, PEGGYS TAVERN, 7444 TSOTETSI STREET, TOKOZA EXTENSION 1, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35770,'lng':28.13680}},
{'stp':650174,'address':'650174, EGL - SOWETO, NOMBULELO MAJOVA, KWA MAKAZI, 12957 EXT 1 PHOLA PARK, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36500,'lng':28.12830}},
{'stp':650203,'address':'650203, EGL - SOWETO, NOMBULELO MAJOVA, MADLAMINIS TAVERN, 12640 PHOLA PARK EXT 1, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36580,'lng':28.12780}},
{'stp':650204,'address':'650204, EGL - SOWETO, NOMBULELO MAJOVA, AUNTYS TAVERN, 6603 PITSO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34620,'lng':28.13450}},
{'stp':650263,'address':'650263, EGL - SOWETO, NOMBULELO MAJOVA, MOTSHIDOS INN, 2945 PHOKOJEE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35500,'lng':28.14260}},
{'stp':650280,'address':'650280, EGL - SOWETO, NOMBULELO MAJOVA, MSHINIS INN, 1654 SIMELANE STREET, TOKOZA EXTENSION 1, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36260,'lng':28.13170}},
{'stp':650343,'address':'650343, EGL - SOWETO, NOMBULELO MAJOVA, SPOCKS INN, 18 ALFA STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37250,'lng':28.12660}},
{'stp':650355,'address':'650355, EGL - SOWETO, NOMBULELO MAJOVA, SEKUNJALOS PLACE, 9328 LEBOHANE STREET, EXTENTION 2, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36923,'lng':28.14181}},
{'stp':650359,'address':'650359, EGL - SOWETO, NOMBULELO MAJOVA, STOFFIES INN, 6719 MARONYANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34540,'lng':28.13380}},
{'stp':653122,'address':'653122, EGL - SOWETO, NOMBULELO MAJOVA, MANYANGANYIS TAVERN, 1726 MHLUZI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36140,'lng':28.13440}},
{'stp':653207,'address':'653207, EGL - SOWETO, NOMBULELO MAJOVA, BOYSEENS TAVERN, 7561 STAND NO, KHUMALO STREET, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36000,'lng':28.12900}},
{'stp':653208,'address':'653208, EGL - SOWETO, NOMBULELO MAJOVA, DAVIDS TAVERN, 201 THINTWA VILLAGE, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34270,'lng':28.13290}},
{'stp':653750,'address':'653750, EGL - SOWETO, NOMBULELO MAJOVA, TSHABALALAS TUCKSHOP, 1206 SIZWE STREET, EXTENSION 2, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36640,'lng':28.13460}},
{'stp':660162,'address':'660162, EGL - SOWETO, NOMBULELO MAJOVA, LEBOTENG TAVERN, 3310 MKHONTO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35590,'lng':28.14040}},
{'stp':661378,'address':'661378, EGL - SOWETO, NOMBULELO MAJOVA, LETSATSI \'S TAVERN, 9243 ZWANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33880,'lng':28.13940}},
{'stp':664740,'address':'664740, EGL - SOWETO, NOMBULELO MAJOVA, POPPIES PLACE, 60 THINTWA VILLAGE, KHUZWAYO STREET, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34390,'lng':28.13150}},
{'stp':667396,'address':'667396, EGL - SOWETO, NOMBULELO MAJOVA, KWABETTYS TAVERN, 5290 EXTENIOSN 1, POLA PARK, ALBERTON, EDEN PARK, South Africa','geo':{'lat':-26.36381,'lng':28.12197}},
{'stp':670442,'address':'670442, EGL - SOWETO, NOMBULELO MAJOVA, HLANGANANI SHEBEEN, 5301 PALM RIDGE, PALM RIDGE, PALM RIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.38606,'lng':28.13888}},
{'stp':686933,'address':'686933, EGL - SOWETO, NOMBULELO MAJOVA, DINANGWE INN, EXTENSION 02, ERF 11764, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36700,'lng':28.13830}},
{'stp':696528,'address':'696528, EGL - SOWETO, NOMBULELO MAJOVA, PHOLA PARK BOTTLE STORE, 9190 YENDE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.36730,'lng':28.12600}},
{'stp':700412,'address':'700412, EGL - SOWETO, NOMBULELO MAJOVA, BHOSE TAVERN, 5044 5044  POLAR PARK, #, #, TOKOZA, South Africa','geo':{'lat':-26.36200,'lng':28.12240}},
{'stp':704529,'address':'704529, EGL - SOWETO, NOMBULELO MAJOVA, NIKELWA TAVERN, 75 PULEDI DRIVE, #, #, TOKOZA, South Africa','geo':{'lat':-26.34470,'lng':28.13050}},
{'stp':708147,'address':'708147, EGL - SOWETO, NOMBULELO MAJOVA, HLATHI TAVERN, 13120 PHOLA PARK, #, #, TOKOZA, South Africa','geo':{'lat':-26.36305,'lng':28.12842}},
{'stp':720073,'address':'720073, EGL - SOWETO, NOMBULELO MAJOVA, BROWN FACE TAVERN, 6588 TENYANE STREET, TOKOZA, #, TOKOZA, South Africa','geo':{'lat':-26.34680,'lng':28.13350}},
{'stp':753860,'address':'753860, EGL - SOWETO, NOMBULELO MAJOVA, MQOLIWE TAVERN, STAND NO, ERF 13683, #, TOKOZA, South Africa','geo':{'lat':-26.36640,'lng':28.13010}},
{'stp':759706,'address':'759706, EGL - SOWETO, NOMBULELO MAJOVA, SKHULU S INN, 6980 SENTSIENYANE STREET, #, #, TOKOZA, South Africa','geo':{'lat':-26.35718,'lng':28.13290}},
{'stp':761610,'address':'761610, EGL - SOWETO, NOMBULELO MAJOVA, MA ARIES SHEEBEN, 12974 STAND NO, PHOLA PARK EXTENSION 1, #, TOKOZA, South Africa','geo':{'lat':-26.36718,'lng':28.13319}},
{'stp':765161,'address':'765161, EGL - SOWETO, NOMBULELO MAJOVA, NEW CASTLE INN, 8770 MATSIMELA STREET, THOKOZA GARDENS, #, TOKOZA, South Africa','geo':{'lat':-26.35170,'lng':28.12780}},
{'stp':772655,'address':'772655, EGL - SOWETO, NOMBULELO MAJOVA, MASEFAKOS INN, 62 KHUZWAYO STREET, #, #, KATLEHONG, South Africa','geo':{'lat':-26.34375,'lng':28.13164}},
{'stp':774343,'address':'774343, EGL - SOWETO, NOMBULELO MAJOVA, CLEMENTINA S INN, 0053 YENDE STREET, ERF 13398 PHOLA PARK EXT 5, #, KATLEHONG, South Africa','geo':{'lat':-26.36667,'lng':28.12867}},
{'stp':777406,'address':'777406, EGL - SOWETO, NOMBULELO MAJOVA, THANDA BANTU TAVERN, 0094 NH LAPHO STREET ALBERTON, ERF 1105, #, TOKOZA, South Africa','geo':{'lat':-26.34654,'lng':28.13990}},
{'stp':784056,'address':'784056, EGL - SOWETO, NOMBULELO MAJOVA, CORNER BUSY, YENDE STREET, ERF 9190 PHOLAPARK EXTENSION 1, #, TOKOZA, South Africa','geo':{'lat':-26.36730,'lng':28.12680}},
{'stp':785630,'address':'785630, EGL - SOWETO, NOMBULELO MAJOVA, ZIM KHAYA TAVERN, SEREMA STREET, ERF 538 MPLISWENI TOWNSHIP, #, TOKOZA, South Africa','geo':{'lat':-26.33882,'lng':28.13572}},
{'stp':787204,'address':'787204, EGL - SOWETO, NOMBULELO MAJOVA, CUTTER S INN, 0063 KHUZWAYO STREET, THINTHWA VILLAGE, #, TOKOZA, South Africa','geo':{'lat':-26.34365,'lng':28.13156}},
{'stp':794018,'address':'794018, EGL - SOWETO, NOMBULELO MAJOVA, KA CORNENG, 3112 MSIMBITHI STREET, THOKOZA, #, TOKOZA, South Africa','geo':{'lat':-26.35778,'lng':28.14182}},
{'stp':797430,'address':'797430, EGL - SOWETO, NOMBULELO MAJOVA, KHONTOS PLACE, UWATELE STREET, ERF 2366 GREENFIELDS SECTION, #, TOKOZA, South Africa','geo':{'lat':-26.40145,'lng':28.13320}},
{'stp':317959,'address':'317959, EGL - SOWETO, NOMBULELO MAJOVA, MPHUTHI LIQUOR STORE, 460 STAND NUMBER, CNR MONDAG NKATI STREET, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34604,'lng':28.15961}},
{'stp':382620,'address':'382620, EGL - SOWETO, NOMBULELO MAJOVA, TRIANGLE PUB AND RESTAURANT, 383 STAND NUMBER, NGHOUI STREET, MOKOENA SECTION, KATLEHONG, South Africa','geo':{'lat':-26.35163,'lng':28.15736}},
{'stp':390644,'address':'390644, EGL - SOWETO, NOMBULELO MAJOVA, BONITA S PLACE, 3924 STAND NO, #, #, SPRUITVIEW, South Africa','geo':{'lat':-26.31880,'lng':28.20247}},
{'stp':391163,'address':'391163, EGL - SOWETO, NOMBULELO MAJOVA, TOOTS TAVERN, 86 STAND NO, MOSILIKI SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.34480,'lng':28.15900}},
{'stp':392010,'address':'392010, EGL - SOWETO, NOMBULELO MAJOVA, MA-SEKOLANYANE S PLACE, 4171 PORTION 91 OF ERF, ROODEKOP EXTENSION 21, #, SPRUITVIEW, South Africa','geo':{'lat':-26.32027,'lng':28.20512}},
{'stp':392115,'address':'392115, EGL - SOWETO, NOMBULELO MAJOVA, DA BASE LIQUORS, 1286 STAND NO, DLADLA STREET, EXTENSION 1, SWARTRUGGENS, South Africa','geo':{'lat':-26.26704,'lng':28.12156}},
{'stp':392227,'address':'392227, EGL - SOWETO, NOMBULELO MAJOVA, GQANCELAS PLACE, 451 NO,MAKHOBA STREET,MOSELEKE EAST, #, #, KATLEHONG, South Africa','geo':{'lat':-26.34904,'lng':28.16474}},
{'stp':392543,'address':'392543, EGL - SOWETO, NOMBULELO MAJOVA, ORION RESTAURANT, 69 ERF, KGOTSO STREET, NCALA SECTION KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34324,'lng':28.14414}},
{'stp':460063,'address':'460063, EGL - SOWETO, NOMBULELO MAJOVA, M AND M LIQOURS, 212 LEBEA STREET, MOSELEKI SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.34548,'lng':28.15639}},
{'stp':461478,'address':'461478, EGL - SOWETO, NOMBULELO MAJOVA, NCUMISA TAVERN, STAND NO, ERF 600 MASANGO STREET MAVIMBELA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34870,'lng':28.14538}},
{'stp':468766,'address':'468766, EGL - SOWETO, NOMBULELO MAJOVA, ERIC\'S TAVERN, 105 STAND NO, NADUSTRIA EXT 1, GOLF STREET, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31454,'lng':28.14382}},
{'stp':473720,'address':'473720, EGL - SOWETO, NOMBULELO MAJOVA, ANNAS PLACE, NQAILA STREET, ERF 668, MONISE SECTION, KATLEHONG, South Africa','geo':{'lat':-26.35673,'lng':28.15663}},
{'stp':488569,'address':'488569, EGL - SOWETO, NOMBULELO MAJOVA, HOUSE OF SOUL, 734 STAND NO, KAMANGA STREET, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31538,'lng':28.18142}},
{'stp':550307,'address':'550307, EGL - SOWETO, NOMBULELO MAJOVA, DD TSOTETSI, 561 MAVIMBELA SECTION, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.34810,'lng':28.14820}},
{'stp':550406,'address':'550406, EGL - SOWETO, NOMBULELO MAJOVA, PUNKIS RESTUARANT, STANDPLAS 689, MABASO STREET, #, VOSLOORUS, South Africa','geo':{'lat':-26.34860,'lng':28.20780}},
{'stp':551482,'address':'551482, EGL - SOWETO, NOMBULELO MAJOVA, TWO SISTERS TAVERN, 284 MONISE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35760,'lng':28.16110}},
{'stp':552648,'address':'552648, EGL - SOWETO, NOMBULELO MAJOVA, MABUTI S TAVERN, 18 ZUMA TOWNSHIP, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34520,'lng':28.15190}},
{'stp':553072,'address':'553072, EGL - SOWETO, NOMBULELO MAJOVA, BLUE WATERS TAVERN, 628 MABASO ROAD, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34820,'lng':28.20750}},
{'stp':553654,'address':'553654, EGL - SOWETO, NOMBULELO MAJOVA, TINA TURNER TAVERN, ERF 598, MOSELEKE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35008,'lng':28.16017}},
{'stp':553838,'address':'553838, EGL - SOWETO, NOMBULELO MAJOVA, BOSS INN PERMIT, 863 NHLAPO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34420,'lng':28.13810}},
{'stp':553865,'address':'553865, EGL - SOWETO, NOMBULELO MAJOVA, NHLAPO OFF SALES, 17 & 82 MAVIMBELA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34680,'lng':28.14920}},
{'stp':560272,'address':'560272, EGL - SOWETO, NOMBULELO MAJOVA, BABIKIS PLACE PERMIT, 1897 SEKONYELA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34630,'lng':28.13680}},
{'stp':560354,'address':'560354, EGL - SOWETO, NOMBULELO MAJOVA, ROBBIES TAVERN, STAND 313, NDLAZI TOWNSHIP, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35250,'lng':28.15510}},
{'stp':560608,'address':'560608, EGL - SOWETO, NOMBULELO MAJOVA, VUKUZENZELE INN, 317 MAVIMBELA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34660,'lng':28.14810}},
{'stp':561117,'address':'561117, EGL - SOWETO, NOMBULELO MAJOVA, PAGEAN INN, 538  MOSELEKE SECTION, #, #, KATLEHONG, South Africa','geo':{'lat':-26.34790,'lng':28.16070}},
{'stp':561125,'address':'561125, EGL - SOWETO, NOMBULELO MAJOVA, BUTI S TAVERN, 1410 MTAMBO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35030,'lng':28.14260}},
{'stp':561298,'address':'561298, EGL - SOWETO, NOMBULELO MAJOVA, THABOS INN, 96 MOSELEKI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34810,'lng':28.16180}},
{'stp':580524,'address':'580524, EGL - SOWETO, NOMBULELO MAJOVA, JUNIORS TAVERN, 770 STAND NO, MOSELEKI EAST SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35450,'lng':28.16470}},
{'stp':580882,'address':'580882, EGL - SOWETO, NOMBULELO MAJOVA, DOCTOR DOCTIONS TAVERN, 470 MAVIMBELA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34636,'lng':28.14432}},
{'stp':581043,'address':'581043, EGL - SOWETO, NOMBULELO MAJOVA, KWASHISANYAMA JOMY\'S, 274 KHUMALO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33930,'lng':28.14030}},
{'stp':602887,'address':'602887, EGL - SOWETO, NOMBULELO MAJOVA, TTBS INN, 228 MASAKHANE STREET, SALI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35570,'lng':28.14990}},
{'stp':603524,'address':'603524, EGL - SOWETO, NOMBULELO MAJOVA, SBO TUCK SHOP, 33 ZUMA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34550,'lng':28.15130}},
{'stp':604060,'address':'604060, EGL - SOWETO, NOMBULELO MAJOVA, CALLYS TAVERN, 71 MONISE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35780,'lng':28.16180}},
{'stp':604976,'address':'604976, EGL - SOWETO, NOMBULELO MAJOVA, OUPAS TAVERN, 731 RADEBE STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35250,'lng':28.14910}},
{'stp':605060,'address':'605060, EGL - SOWETO, NOMBULELO MAJOVA, MAGGIES TAVERN, 2159 MAKUKULELE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35040,'lng':28.13550}},
{'stp':605878,'address':'605878, EGL - SOWETO, NOMBULELO MAJOVA, FOUR DLADLA, 4 RADEBE SECTION, KATLEHONG NORTH, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35830,'lng':28.14990}},
{'stp':605976,'address':'605976, EGL - SOWETO, NOMBULELO MAJOVA, PAANDA S TAVERN, 1538 NKAKI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34670,'lng':28.13900}},
{'stp':606026,'address':'606026, EGL - SOWETO, NOMBULELO MAJOVA, SANGWENI TUCKSHOP, 2240 MAKUKULE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35190,'lng':28.13480}},
{'stp':606040,'address':'606040, EGL - SOWETO, NOMBULELO MAJOVA, NEW YEARS INN, 69 MAVIMBELA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34976,'lng':28.14921}},
{'stp':606463,'address':'606463, EGL - SOWETO, NOMBULELO MAJOVA, TSHIDIS PLACE, 12598 LEKABELANE STREET, EASTFIELD, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33610,'lng':28.19640}},
{'stp':606965,'address':'606965, EGL - SOWETO, NOMBULELO MAJOVA, BOTTOM\'S INN, 31 RAMOKONOPI, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35910,'lng':28.15500}},
{'stp':616211,'address':'616211, EGL - SOWETO, NOMBULELO MAJOVA, JESSEYS TAVERN, 2280 MAKHUKHULE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34990,'lng':28.13570}},
{'stp':625126,'address':'625126, EGL - SOWETO, NOMBULELO MAJOVA, MACARINA TAVERN, 570 RADEBE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35450,'lng':28.14920}},
{'stp':625816,'address':'625816, EGL - SOWETO, NOMBULELO MAJOVA, SESIS TAVERN PERMIT, 1688 ZONDO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34890,'lng':28.13760}},
{'stp':626641,'address':'626641, EGL - SOWETO, NOMBULELO MAJOVA, KINNYS INN PERMIT, 264 MOKOENA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35020,'lng':28.15770}},
{'stp':627115,'address':'627115, EGL - SOWETO, NOMBULELO MAJOVA, NKWASHNAIZA, 3514 NDIPHE STREET, PHUMULA, ROODEKOP, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31641,'lng':28.20778}},
{'stp':627401,'address':'627401, EGL - SOWETO, NOMBULELO MAJOVA, SAMBO S TAVERN, 958 THATO STREET, MANDELA PARK, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30980,'lng':28.14640}},
{'stp':627822,'address':'627822, EGL - SOWETO, NOMBULELO MAJOVA, THANDIS TAVERN PERMIT, 686 MDULI STREET, RADEBE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35250,'lng':28.14670}},
{'stp':628207,'address':'628207, EGL - SOWETO, NOMBULELO MAJOVA, GRACES PLACE, 2566 QWABE STREET, EVEREST, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.35130,'lng':28.13940}},
{'stp':628343,'address':'628343, EGL - SOWETO, NOMBULELO MAJOVA, SOLLY PUB PERMIT, 6683  MARIMBA GARDE, EXT 9, PUPUPU, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34660,'lng':28.22320}},
{'stp':628355,'address':'628355, EGL - SOWETO, NOMBULELO MAJOVA, MINDLOS INN PERMIT, 793 MAVIMBELA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35140,'lng':28.14730}},
{'stp':628509,'address':'628509, EGL - SOWETO, NOMBULELO MAJOVA, THOKSONS INN PERMIT, 1263 MHLONGO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34904,'lng':28.14332}},
{'stp':630624,'address':'630624, EGL - SOWETO, NOMBULELO MAJOVA, ZANDIS INN, 1221 KGANANE ROAD, ZULU SECTION, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35200,'lng':28.20600}},
{'stp':630841,'address':'630841, EGL - SOWETO, NOMBULELO MAJOVA, MAKIS PLACE, 302 SEGOANE STREET, MOFOKENG SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35070,'lng':28.16520}},
{'stp':636024,'address':'636024, EGL - SOWETO, NOMBULELO MAJOVA, KADI A TAVERN, 942 NCALA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34583,'lng':28.13720}},
{'stp':636548,'address':'636548, EGL - SOWETO, NOMBULELO MAJOVA, SKHUNGAS TAVERN, 541 NCALA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34420,'lng':28.14520}},
{'stp':636590,'address':'636590, EGL - SOWETO, NOMBULELO MAJOVA, DUCK CITY TAVERN, 637 MNISI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35610,'lng':28.15590}},
{'stp':636704,'address':'636704, EGL - SOWETO, NOMBULELO MAJOVA, SEKOS TAVERN, 1166 NHLAPO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34518,'lng':28.13798}},
{'stp':636822,'address':'636822, EGL - SOWETO, NOMBULELO MAJOVA, WRONG TAVERN, 223 MOSILIKI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34690,'lng':28.15610}},
{'stp':637192,'address':'637192, EGL - SOWETO, NOMBULELO MAJOVA, HLOKS TAVERN, 423 MONISI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35570,'lng':28.16030}},
{'stp':637233,'address':'637233, EGL - SOWETO, NOMBULELO MAJOVA, MAZI\'S PUB AND SNOOKER, 124 HLONGWANE SECTION, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.34732,'lng':28.15390}},
{'stp':638731,'address':'638731, EGL - SOWETO, NOMBULELO MAJOVA, MAKATE TAVERN, 175 RAMOKONOPI EAST, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.35770,'lng':28.16400}},
{'stp':641588,'address':'641588, EGL - SOWETO, NOMBULELO MAJOVA, ESTHERS TUCKSHOP, 365 NDLAZI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35010,'lng':28.15560}},
{'stp':642216,'address':'642216, EGL - SOWETO, NOMBULELO MAJOVA, OUSIES TAVERN, 225 HLONGWANE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34700,'lng':28.15510}},
{'stp':646664,'address':'646664, EGL - SOWETO, NOMBULELO MAJOVA, NGHALAMONA TRADING, 847 MAGOROZA STREET, MOFOKENG SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35320,'lng':28.16370}},
{'stp':646708,'address':'646708, EGL - SOWETO, NOMBULELO MAJOVA, JABU\'S TAVERN, 47 RADEBE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35270,'lng':28.14980}},
{'stp':646712,'address':'646712, EGL - SOWETO, NOMBULELO MAJOVA, PATRICIA \'S SHEBEEN, 416 MOKOENA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35330,'lng':28.15700}},
{'stp':647189,'address':'647189, EGL - SOWETO, NOMBULELO MAJOVA, MOKGOTSIS PLACE, 15907 UJEJANE STREET, EXT 16, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34080,'lng':28.18940}},
{'stp':650058,'address':'650058, EGL - SOWETO, NOMBULELO MAJOVA, AMANDIBA TAVERN, 976 ZONE 04 NADASTRIA, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30880,'lng':28.14600}},
{'stp':650094,'address':'650094, EGL - SOWETO, NOMBULELO MAJOVA, BROER, 462 MOSELEKI EAST, MOFOKENG SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34930,'lng':28.16720}},
{'stp':652810,'address':'652810, EGL - SOWETO, NOMBULELO MAJOVA, MEDIA INN, 130 MOSELEKE, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34967,'lng':28.16120}},
{'stp':653134,'address':'653134, EGL - SOWETO, NOMBULELO MAJOVA, MANDOZAS TAVERN, 333 RADEBE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35710,'lng':28.14780}},
{'stp':653201,'address':'653201, EGL - SOWETO, NOMBULELO MAJOVA, MAMOSIAS TAVERN, 80 MOFOKENG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34810,'lng':28.16410}},
{'stp':653210,'address':'653210, EGL - SOWETO, NOMBULELO MAJOVA, BOYS TAVERN, 1965 QABA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34840,'lng':28.13590}},
{'stp':653219,'address':'653219, EGL - SOWETO, NOMBULELO MAJOVA, SOKAYAS TAVERN, 981 MOFOKENG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35560,'lng':28.16250}},
{'stp':653222,'address':'653222, EGL - SOWETO, NOMBULELO MAJOVA, GRANNYS PLACE, 43 RADEBE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35310,'lng':28.14970}},
{'stp':653223,'address':'653223, EGL - SOWETO, NOMBULELO MAJOVA, JUVYS TAVERN, 350 MOKOENA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35450,'lng':28.15650}},
{'stp':653232,'address':'653232, EGL - SOWETO, NOMBULELO MAJOVA, SIYAZWELANAS TAVERN, 388 RADEBE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35600,'lng':28.14770}},
{'stp':653233,'address':'653233, EGL - SOWETO, NOMBULELO MAJOVA, THIBOS PLACE, 259 MOSILIKI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34690,'lng':28.15640}},
{'stp':653751,'address':'653751, EGL - SOWETO, NOMBULELO MAJOVA, TSHEPIS SHEBEEN, 468 MOSELEKE STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34629,'lng':28.16051}},
{'stp':654126,'address':'654126, EGL - SOWETO, NOMBULELO MAJOVA, MANKOLIS TAVERN, 433 MONISE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35580,'lng':28.15890}},
{'stp':655413,'address':'655413, EGL - SOWETO, NOMBULELO MAJOVA, IDAHS PLACE, 376 MOKOENA SECTION, KATLEHONG, ALBERTON, KATLEHONG, South Africa','geo':{'lat':-26.35150,'lng':28.15700}},
{'stp':658592,'address':'658592, EGL - SOWETO, NOMBULELO MAJOVA, FOURS TAVERN, 692 MANDELA PARK, ZONE 1, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31300,'lng':28.14570}},
{'stp':659376,'address':'659376, EGL - SOWETO, NOMBULELO MAJOVA, NTOMBIS INN, 1507 NTULI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34840,'lng':28.13970}},
{'stp':664313,'address':'664313, EGL - SOWETO, NOMBULELO MAJOVA, NTSWAKI S TAVERN, 805 MORELEKI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34572,'lng':28.17988}},
{'stp':668051,'address':'668051, EGL - SOWETO, NOMBULELO MAJOVA, NDONDOS PLACE, 12 MONISE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35530,'lng':28.15340}},
{'stp':681705,'address':'681705, EGL - SOWETO, NOMBULELO MAJOVA, STATION 5, 5307 THATSENG STREET, EXT 22, ROODEKOP, SPRUITVIEW, South Africa','geo':{'lat':-26.31240,'lng':28.20040}},
{'stp':686186,'address':'686186, EGL - SOWETO, NOMBULELO MAJOVA, H AND T PLACE, 64 PHASE 4A, BUHLE PARK, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.29270,'lng':28.18940}},
{'stp':698250,'address':'698250, EGL - SOWETO, NOMBULELO MAJOVA, MATSENA BOTTLE STORE, 121 TSWELOTELE STREET, RADEBE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35880,'lng':28.14670}},
{'stp':707029,'address':'707029, EGL - SOWETO, NOMBULELO MAJOVA, MEDUPI BOTTLE STORE, 2006 MABUYA ST, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34890,'lng':28.13460}},
{'stp':712338,'address':'712338, EGL - SOWETO, NOMBULELO MAJOVA, SHENGU TAVERN, STAND NO 666, MOFOKENG SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.35573,'lng':28.16692}},
{'stp':719304,'address':'719304, EGL - SOWETO, NOMBULELO MAJOVA, J N LEAH LIQUOR STORE N DISTRIBUTIO, 47 MPHIKE STREET, MOKOENA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35430,'lng':28.15584}},
{'stp':723883,'address':'723883, EGL - SOWETO, NOMBULELO MAJOVA, CONNIES PLACE, ERF 516, TUMELO STREET, NADUSTRIA EXTENSION 1 KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31210,'lng':28.14370}},
{'stp':725779,'address':'725779, EGL - SOWETO, NOMBULELO MAJOVA, MTSHUBUNGU S PLACE, 13043 TSHETLO CRESCENT EXT 23, EAST FIELDS, #, VOSLOORUS, South Africa','geo':{'lat':-26.33570,'lng':28.20710}},
{'stp':754294,'address':'754294, EGL - SOWETO, NOMBULELO MAJOVA, JABU S LIQUOR, 204 STAND NO, MOFOKENG SECTION KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.34990,'lng':28.16354}},
{'stp':755918,'address':'755918, EGL - SOWETO, NOMBULELO MAJOVA, FIKILES TAVERN, 1616 MNUMZANE STREET, #, #, TOKOZA, South Africa','geo':{'lat':-26.34760,'lng':28.13890}},
{'stp':763516,'address':'763516, EGL - SOWETO, NOMBULELO MAJOVA, SEAS PLACE, 111 MOFOKENG SECTION, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.34860,'lng':28.16270}},
{'stp':775087,'address':'775087, EGL - SOWETO, NOMBULELO MAJOVA, WENZY S INN, 186 STAND NO, MOSELIKI SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.34483,'lng':28.15957}},
{'stp':775625,'address':'775625, EGL - SOWETO, NOMBULELO MAJOVA, EZINYOKENI TAVERN, STAND NO, ERF 440  NTOKU STREET NADUSTRIA EXT 1, katlhehong, KATLEHONG, South Africa','geo':{'lat':-26.31148,'lng':28.14230}},
{'stp':786370,'address':'786370, EGL - SOWETO, NOMBULELO MAJOVA, NGWENYA S TAVERN, 730 STAND NO, MOFOKENG SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.35506,'lng':28.16536}},
{'stp':787072,'address':'787072, EGL - SOWETO, NOMBULELO MAJOVA, TAKE 5, 0140 MAKHOBA STREET, MOFOKENG SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.34905,'lng':28.16253}},
{'stp':313231,'address':'313231, EGL - SOWETO, NOMBULELO MAJOVA, GUBEVU LIQUOR STORE, 897 STAND NO, NCALA SECTION KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.33810,'lng':28.14742}},
{'stp':381873,'address':'381873, EGL - SOWETO, NOMBULELO MAJOVA, YONKE INTO INHALGENE, 20 STAND NO, TWALA SECTION,, #, KATLEHONG, South Africa','geo':{'lat':-26.33149,'lng':28.14874}},
{'stp':391168,'address':'391168, EGL - SOWETO, NOMBULELO MAJOVA, GADINI PHOLA PLACE, 3231 KATLEHONG SOUTH EXTENSION 9, BLOCK D, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.38019,'lng':28.18676}},
{'stp':462059,'address':'462059, EGL - SOWETO, NOMBULELO MAJOVA, COME CHILL WITH BONGANI, 869 NCALA SECTION, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.33813,'lng':28.14801}},
{'stp':467648,'address':'467648, EGL - SOWETO, NOMBULELO MAJOVA, SIX\'S PLACE, 318 STAND NO, HLAHATSI, SECTION KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33586,'lng':28.15458}},
{'stp':471277,'address':'471277, EGL - SOWETO, NOMBULELO MAJOVA, BELLINAH TAVERN, 13 STAND NO, MOSHOESHOE SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.32305,'lng':28.16072}},
{'stp':473428,'address':'473428, EGL - SOWETO, NOMBULELO MAJOVA, DOMINOEZ PUB AND RESTAURANT, STAND NO, ERF 906 SIMILANE STREET TWALA SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.33656,'lng':28.14756}},
{'stp':547110,'address':'547110, EGL - SOWETO, NOMBULELO MAJOVA, ACES PLACE, 8510 MOTLOUNGE SECTION, #, #, KATLEHONG, South Africa','geo':{'lat':-26.31424,'lng':28.16073}},
{'stp':548777,'address':'548777, EGL - SOWETO, NOMBULELO MAJOVA, EDLADLENI SPORTS BAR AND RESTAURANT, ERF 469, NCALA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34066,'lng':28.14857}},
{'stp':550688,'address':'550688, EGL - SOWETO, NOMBULELO MAJOVA, WAKI S TAVERN, 8 HOSPITAL STREET, PHAKE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33070,'lng':28.15700}},
{'stp':552067,'address':'552067, EGL - SOWETO, NOMBULELO MAJOVA, MEISIES TAVERN, 316 CIBI STREET, PHOOKO SECTION, KATLEHONG GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.33690,'lng':28.16340}},
{'stp':552605,'address':'552605, EGL - SOWETO, NOMBULELO MAJOVA, TSAKIS TAVERN, STAND 233 ROETS DRIVE, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35470,'lng':28.18920}},
{'stp':552769,'address':'552769, EGL - SOWETO, NOMBULELO MAJOVA, KATLEHONG CIRCLE BOTTLE STORE, 1090 A TSHONGWENI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31110,'lng':28.15620}},
{'stp':553323,'address':'553323, EGL - SOWETO, NOMBULELO MAJOVA, LAKA BAR LOUNGE, 2 SHOP NO, MOSHOESHOE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32490,'lng':28.15820}},
{'stp':553451,'address':'553451, EGL - SOWETO, NOMBULELO MAJOVA, MTSHALI LIQ STORE, 478 STAND STREET, TSOLO SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33930,'lng':28.15390}},
{'stp':553609,'address':'553609, EGL - SOWETO, NOMBULELO MAJOVA, MOSSAS TAVERN, 374 STAND NO, TSILO STREET MOSHOESHOE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31984,'lng':28.16054}},
{'stp':553632,'address':'553632, EGL - SOWETO, NOMBULELO MAJOVA, CK TAVERN, 361 PADIMA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34010,'lng':28.16160}},
{'stp':553964,'address':'553964, EGL - SOWETO, NOMBULELO MAJOVA, ZAKES TAVERN PERMIT, 411 HLAHATSE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33730,'lng':28.15420}},
{'stp':560404,'address':'560404, EGL - SOWETO, NOMBULELO MAJOVA, THABMA TAVERN, 532 PHAKE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33710,'lng':28.16030}},
{'stp':571800,'address':'571800, EGL - SOWETO, NOMBULELO MAJOVA, CLUB 75, 306 NGEMA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33280,'lng':28.15040}},
{'stp':604253,'address':'604253, EGL - SOWETO, NOMBULELO MAJOVA, LELE TAVERN, 16 TWALA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33157,'lng':28.14913}},
{'stp':604963,'address':'604963, EGL - SOWETO, NOMBULELO MAJOVA, MIKES KITCHEN SHEBEEN, 176 MOPEDI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32880,'lng':28.15940}},
{'stp':605049,'address':'605049, EGL - SOWETO, NOMBULELO MAJOVA, SYLVIAS TAVERN, 220 PHADIMA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34182,'lng':28.16309}},
{'stp':605682,'address':'605682, EGL - SOWETO, NOMBULELO MAJOVA, TSELENGSS INN, 500 PHOKO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33800,'lng':28.16180}},
{'stp':605784,'address':'605784, EGL - SOWETO, NOMBULELO MAJOVA, CHRISS INN, 342 MOSHOESHOE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32560,'lng':28.15720}},
{'stp':605853,'address':'605853, EGL - SOWETO, NOMBULELO MAJOVA, MAWAS PLACE, 79 PADIMA SECTION, KATLEHONG NORTH, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33960,'lng':28.16340}},
{'stp':605909,'address':'605909, EGL - SOWETO, NOMBULELO MAJOVA, MEISIE, 184 TSOLO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34059,'lng':28.15761}},
{'stp':605943,'address':'605943, EGL - SOWETO, NOMBULELO MAJOVA, BILLYS PLACE, 389 SONTONGA STR PHAKE SECTION, PHAKE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33477,'lng':28.16157}},
{'stp':605983,'address':'605983, EGL - SOWETO, NOMBULELO MAJOVA, EMALANGENI LIQUOR STORE, ERF 434 HLATHI STREET, NGEMA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33340,'lng':28.15150}},
{'stp':606918,'address':'606918, EGL - SOWETO, NOMBULELO MAJOVA, SOFTYS INN, 218 GOBA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34010,'lng':28.15110}},
{'stp':606949,'address':'606949, EGL - SOWETO, NOMBULELO MAJOVA, ROSE TAVERN, 65 MOTSAMAI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32810,'lng':28.16000}},
{'stp':607088,'address':'607088, EGL - SOWETO, NOMBULELO MAJOVA, SEKES INN, 221 PHAKE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33330,'lng':28.16097}},
{'stp':607384,'address':'607384, EGL - SOWETO, NOMBULELO MAJOVA, KGONTYS TAVERN, 119 PHAKE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33165,'lng':28.15799}},
{'stp':625969,'address':'625969, EGL - SOWETO, NOMBULELO MAJOVA, SHAPIRO BOTTLE STORE, 118 BINASE STREET, MOTSAMAI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32750,'lng':28.16150}},
{'stp':626685,'address':'626685, EGL - SOWETO, NOMBULELO MAJOVA, SANKUS PLACE PERMIT, 308 MOPEDI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32930,'lng':28.16180}},
{'stp':626788,'address':'626788, EGL - SOWETO, NOMBULELO MAJOVA, TSHEPISOS INN PERMIT, 104 MOTSAMAI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32740,'lng':28.16000}},
{'stp':627898,'address':'627898, EGL - SOWETO, NOMBULELO MAJOVA, ENOS SHEBEEN PERMIT, 130 BAHOPA STREET, PALIME SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34070,'lng':28.16420}},
{'stp':628120,'address':'628120, EGL - SOWETO, NOMBULELO MAJOVA, LITTLE BIT FOUNTAIN PERMIT, 40 TWALA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33120,'lng':28.14830}},
{'stp':628732,'address':'628732, EGL - SOWETO, NOMBULELO MAJOVA, HAPPY FAMILY PERMIT, 575 MASAKHANE STREET, NCALA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34183,'lng':28.14978}},
{'stp':629157,'address':'629157, EGL - SOWETO, NOMBULELO MAJOVA, NOMSAS PARADISE PERMIT, 28 PHOOKO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33169,'lng':28.16364}},
{'stp':629492,'address':'629492, EGL - SOWETO, NOMBULELO MAJOVA, JONDIS TAVERN, 533 MOTSAMAI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32940,'lng':28.16370}},
{'stp':629500,'address':'629500, EGL - SOWETO, NOMBULELO MAJOVA, PHOROZAS INN PERMIT, 144 HLATHI STREET, NGEMA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33340,'lng':28.15170}},
{'stp':629677,'address':'629677, EGL - SOWETO, NOMBULELO MAJOVA, STRESS FREE PUB AND GRILL PERMIT, 402 SIX STREET, MOSHOESHOE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32220,'lng':28.15840}},
{'stp':630585,'address':'630585, EGL - SOWETO, NOMBULELO MAJOVA, TSHIDI S SHEBEEN, 226 PHADIMA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34110,'lng':28.16290}},
{'stp':630620,'address':'630620, EGL - SOWETO, NOMBULELO MAJOVA, FK TAVERN, 75 MATHIMUNYE STREET, GOBA, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34040,'lng':28.15040}},
{'stp':635161,'address':'635161, EGL - SOWETO, NOMBULELO MAJOVA, LUCAS TAVERN, 9505 MOTLOUNG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31750,'lng':28.16200}},
{'stp':636568,'address':'636568, EGL - SOWETO, NOMBULELO MAJOVA, JC BOTTLE STORE, 545 BOHOPA STREET, PHADIMA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34065,'lng':28.16091}},
{'stp':637659,'address':'637659, EGL - SOWETO, NOMBULELO MAJOVA, TSILENG\'S SHEBEEN, 191 MOPEDI SECTION, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.32920,'lng':28.15980}},
{'stp':640417,'address':'640417, EGL - SOWETO, NOMBULELO MAJOVA, VIOLETS PLACE, 7543 MOERANE STREET, MOTLOUNG SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31672,'lng':28.16066}},
{'stp':646108,'address':'646108, EGL - SOWETO, NOMBULELO MAJOVA, LEVY\'S SHEBEEN, TSOLO SECTION 459, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.34010,'lng':28.15330}},
{'stp':647995,'address':'647995, EGL - SOWETO, NOMBULELO MAJOVA, THE JOINT, 441 KONYANE STREET, PHOOKO SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33798,'lng':28.16425}},
{'stp':652841,'address':'652841, EGL - SOWETO, NOMBULELO MAJOVA, SPECTRAS PLACE, 441 MOSHOESHOE SECTION, GERMISTON, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32210,'lng':28.15900}},
{'stp':652842,'address':'652842, EGL - SOWETO, NOMBULELO MAJOVA, THEMBAS TAVERN, 9561 MOTLOUNG SECTION, GERMISTON, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31781,'lng':28.16270}},
{'stp':652851,'address':'652851, EGL - SOWETO, NOMBULELO MAJOVA, MVULAZAMAS PLACE, 6052 MOTLOUNG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31870,'lng':28.15980}},
{'stp':652855,'address':'652855, EGL - SOWETO, NOMBULELO MAJOVA, JABUS PLACE, 2567 MOTLOUNG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31610,'lng':28.15790}},
{'stp':652912,'address':'652912, EGL - SOWETO, NOMBULELO MAJOVA, POGGOS INN, 414 MOSHOESHOE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32390,'lng':28.15900}},
{'stp':652919,'address':'652919, EGL - SOWETO, NOMBULELO MAJOVA, ZODWAS PLACE, 187 MOSHOSHOE SECTION, GERMISTON, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32230,'lng':28.15770}},
{'stp':652972,'address':'652972, EGL - SOWETO, NOMBULELO MAJOVA, POPPYS PLACE, 233 GOBA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34180,'lng':28.15110}},
{'stp':653121,'address':'653121, EGL - SOWETO, NOMBULELO MAJOVA, HORSES PLACE, 9105 MOTLOUNG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31881,'lng':28.16208}},
{'stp':653174,'address':'653174, EGL - SOWETO, NOMBULELO MAJOVA, PULES TAVERN, 142 TSOLO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34150,'lng':28.15630}},
{'stp':653193,'address':'653193, EGL - SOWETO, NOMBULELO MAJOVA, PULANES PLACE, 9547 MOTLOUNG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31934,'lng':28.16286}},
{'stp':653199,'address':'653199, EGL - SOWETO, NOMBULELO MAJOVA, BOSSES PLACE, 305 TSOLO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33903,'lng':28.15700}},
{'stp':653220,'address':'653220, EGL - SOWETO, NOMBULELO MAJOVA, BUTAS TAVERN, 277 PHOOKO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33571,'lng':28.16364}},
{'stp':653241,'address':'653241, EGL - SOWETO, NOMBULELO MAJOVA, SONNIES INN, 441 PHAKE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33390,'lng':28.15880}},
{'stp':653242,'address':'653242, EGL - SOWETO, NOMBULELO MAJOVA, TEFOS PLACE, 489 HLAHATSI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33850,'lng':28.15881}},
{'stp':653259,'address':'653259, EGL - SOWETO, NOMBULELO MAJOVA, JULIAS INN, 890 TWALA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33470,'lng':28.14660}},
{'stp':653261,'address':'653261, EGL - SOWETO, NOMBULELO MAJOVA, MALINDIS PLACE, 144 PALIME SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34050,'lng':28.16260}},
{'stp':653262,'address':'653262, EGL - SOWETO, NOMBULELO MAJOVA, SARAHS SHEBEEN, 262 MOPEDI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33047,'lng':28.15770}},
{'stp':653265,'address':'653265, EGL - SOWETO, NOMBULELO MAJOVA, MAMPES INN, 328 PHADIMA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34323,'lng':28.16199}},
{'stp':654157,'address':'654157, EGL - SOWETO, NOMBULELO MAJOVA, DISIZAS TAVERN, 414 TSHOLO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33995,'lng':28.15563}},
{'stp':655243,'address':'655243, EGL - SOWETO, NOMBULELO MAJOVA, TSWELOPELES INN, 241 PHADIME SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34310,'lng':28.16250}},
{'stp':656712,'address':'656712, EGL - SOWETO, NOMBULELO MAJOVA, REFILOES TAVERN, 105 HLAHATSI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33290,'lng':28.15450}},
{'stp':657702,'address':'657702, EGL - SOWETO, NOMBULELO MAJOVA, TSHEPOS PLACE, 224 MOPEDI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33000,'lng':28.15970}},
{'stp':658858,'address':'658858, EGL - SOWETO, NOMBULELO MAJOVA, RIBS TAVERN, 380 PHOOKO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33690,'lng':28.16340}},
{'stp':664369,'address':'664369, EGL - SOWETO, NOMBULELO MAJOVA, FANTAS INN, 58 MOTSAMAI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32780,'lng':28.15920}},
{'stp':759224,'address':'759224, EGL - SOWETO, NOMBULELO MAJOVA, SEYA O BUTSI LIQUOR STORE, STAND NO, ERF 521 SHOP 1 SHREDBLICK STREET, #, KATLEHONG, South Africa','geo':{'lat':-26.32371,'lng':28.15961}},
{'stp':761193,'address':'761193, EGL - SOWETO, NOMBULELO MAJOVA, NCAMANA S INN, 65 STAND NO, MOPEDI SECTION SELEKI SECTION KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.32880,'lng':28.15680}},
{'stp':780342,'address':'780342, EGL - SOWETO, NOMBULELO MAJOVA, KHUMALO S TAVERN, 660 STAND NO, TWALA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33300,'lng':28.14790}},
{'stp':781580,'address':'781580, EGL - SOWETO, NOMBULELO MAJOVA, MPHOS TUCKSHOP, 209 STAND NO, TSAMELA STREET, PHOOKO SECTION KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33540,'lng':28.16460}},
{'stp':781611,'address':'781611, EGL - SOWETO, NOMBULELO MAJOVA, KELLOGS INN, 294 STAND NO, MOSHOESHOE SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.32070,'lng':28.15960}},
{'stp':786948,'address':'786948, EGL - SOWETO, NOMBULELO MAJOVA, NGOBESE S TAVERN, 653 STAND NO, TWALA SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.33455,'lng':28.14547}},
{'stp':794231,'address':'794231, EGL - SOWETO, NOMBULELO MAJOVA, WHITEY HOUSE, 625 STAND NO, PHADIMA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33987,'lng':28.16077}},
{'stp':795038,'address':'795038, EGL - SOWETO, NOMBULELO MAJOVA, LEHAKWENG LA BATAUNG, KHOABANE STREET, ERF 483 PALIME SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34307,'lng':28.15998}},
{'stp':378690,'address':'378690, EGL - SOWETO, NOMBULELO MAJOVA, PENWELLS PLACE, 1090A STAND NO, #, #, KATLEHONG, South Africa','geo':{'lat':-26.31110,'lng':28.15620}},
{'stp':391117,'address':'391117, EGL - SOWETO, NOMBULELO MAJOVA, DIEKETSO S PLACE, 2099 STAND NO, NHLAPHO SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.31110,'lng':28.15068}},
{'stp':393104,'address':'393104, EGL - SOWETO, NOMBULELO MAJOVA, COLOM PUB, 533 STAND NO, SHOP 2 MABONA STREET, SKOSANA SECTION, KATLEHONG, South Africa','geo':{'lat':-26.32652,'lng':28.15205}},
{'stp':469471,'address':'469471, EGL - SOWETO, NOMBULELO MAJOVA, MASEKO TAVERN, ERF 166, NGWENYA STREET, TWALA SECTION, KATLEHONG, South Africa','geo':{'lat':-26.32760,'lng':28.14780}},
{'stp':470763,'address':'470763, EGL - SOWETO, NOMBULELO MAJOVA, THAMIS TAVERN, MASAKHANE STREET, MAKULA SECTION, ERF 02 KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32418,'lng':28.15507}},
{'stp':487275,'address':'487275, EGL - SOWETO, NOMBULELO MAJOVA, MZITHOS INN, STAND NO, A 50, MAZIBUKO STREET, TOKOZA, South Africa','geo':{'lat':-26.33058,'lng':28.14468}},
{'stp':489747,'address':'489747, EGL - SOWETO, NOMBULELO MAJOVA, TSALAS INN, 962 STAND NO, KUBHEKA STREET, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34358,'lng':28.14008}},
{'stp':550173,'address':'550173, EGL - SOWETO, NOMBULELO MAJOVA, THEMBIS TAVERN PERMIT, 239 TWALA SECTION, #, #, KATLEHONG, South Africa','geo':{'lat':-26.32863,'lng':28.14628}},
{'stp':550818,'address':'550818, EGL - SOWETO, NOMBULELO MAJOVA, COLOM LIQUOR STORE, 533 TSHANGANA STREET, SKOSANA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32640,'lng':28.15200}},
{'stp':550907,'address':'550907, EGL - SOWETO, NOMBULELO MAJOVA, TIMING LIQUOR STORE, CNR MADONDO & MDAKANE STREETS, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33939,'lng':28.14115}},
{'stp':551030,'address':'551030, EGL - SOWETO, NOMBULELO MAJOVA, NKALA AND SONS TAVERN, STAND 4018 TSHONGWENI, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30950,'lng':28.15909}},
{'stp':552147,'address':'552147, EGL - SOWETO, NOMBULELO MAJOVA, TIGER TAVERN, 99 MAZIBUKO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33552,'lng':28.14229}},
{'stp':552587,'address':'552587, EGL - SOWETO, NOMBULELO MAJOVA, HAMBUZOBUYA LIQUOR STORE, 150B SKOSANA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32290,'lng':28.15050}},
{'stp':553639,'address':'553639, EGL - SOWETO, NOMBULELO MAJOVA, EASY STR LIQUOR STORE, STAND H 100, MONAHENG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32180,'lng':28.15470}},
{'stp':553836,'address':'553836, EGL - SOWETO, NOMBULELO MAJOVA, VELIS TAVERN, 504 TSHABALALA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34010,'lng':28.14160}},
{'stp':553882,'address':'553882, EGL - SOWETO, NOMBULELO MAJOVA, ROYS PLACE PERMIT, 600 SKHOSANA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32750,'lng':28.14972}},
{'stp':553913,'address':'553913, EGL - SOWETO, NOMBULELO MAJOVA, JEANIES TUCKSHOP PERMIT, 166 MAPHANGA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31838,'lng':28.14841}},
{'stp':553953,'address':'553953, EGL - SOWETO, NOMBULELO MAJOVA, MTHETHWAS PUB PERMIT, 1792 TSHONGWENI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30440,'lng':28.15480}},
{'stp':560345,'address':'560345, EGL - SOWETO, NOMBULELO MAJOVA, NGNS TAVERN, ERF A30 MAZIBUKO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.32870,'lng':28.14450}},
{'stp':560528,'address':'560528, EGL - SOWETO, NOMBULELO MAJOVA, J MAJA S PLACE, 1025 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31310,'lng':28.14900}},
{'stp':561299,'address':'561299, EGL - SOWETO, NOMBULELO MAJOVA, VILAKAZI TAVERN, 238 MALITENT STREET, CREDI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32006,'lng':28.15093}},
{'stp':561302,'address':'561302, EGL - SOWETO, NOMBULELO MAJOVA, TSHIDI S  TAVERN, A 70 (7997) MAVUSO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.32859,'lng':28.14463}},
{'stp':580384,'address':'580384, EGL - SOWETO, NOMBULELO MAJOVA, MAMKI S TAVERN, STAND NO, ERF 286 MAPHISA STREET, NHLAPHO SECTION, KATLEHONG, South Africa','geo':{'lat':-26.30910,'lng':28.15260}},
{'stp':603123,'address':'603123, EGL - SOWETO, NOMBULELO MAJOVA, THABAUS PLACE, 3517 PULUTSAME STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30990,'lng':28.15810}},
{'stp':603124,'address':'603124, EGL - SOWETO, NOMBULELO MAJOVA, THAPELOS TAVERN, 856 NHLAPHO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30530,'lng':28.15000}},
{'stp':603276,'address':'603276, EGL - SOWETO, NOMBULELO MAJOVA, M AND J TAVERN, 233 TWALA SECTION,, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32790,'lng':28.14650}},
{'stp':603688,'address':'603688, EGL - SOWETO, NOMBULELO MAJOVA, TINYS INN, 893 MDAKANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34360,'lng':28.13910}},
{'stp':604254,'address':'604254, EGL - SOWETO, NOMBULELO MAJOVA, WENDY\'S TAVERN, 529 SABI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34140,'lng':28.14210}},
{'stp':604715,'address':'604715, EGL - SOWETO, NOMBULELO MAJOVA, CHINA INN, 580 SHAI STREET, NHLAPO SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30701,'lng':28.15280}},
{'stp':605212,'address':'605212, EGL - SOWETO, NOMBULELO MAJOVA, ARTHURS TAVERN, 268 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30743,'lng':28.15126}},
{'stp':605534,'address':'605534, EGL - SOWETO, NOMBULELO MAJOVA, PETERS TAVERN, 428 SKOSANA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32600,'lng':28.15030}},
{'stp':605797,'address':'605797, EGL - SOWETO, NOMBULELO MAJOVA, DANIES PLACE, 39 MONAHENG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31746,'lng':28.15508}},
{'stp':606043,'address':'606043, EGL - SOWETO, NOMBULELO MAJOVA, MAZET, 549 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31120,'lng':28.15320}},
{'stp':606095,'address':'606095, EGL - SOWETO, NOMBULELO MAJOVA, ZAKES PLACE, 1940 TSHONGWENI SECTION, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.30506,'lng':28.15716}},
{'stp':625560,'address':'625560, EGL - SOWETO, NOMBULELO MAJOVA, ALLYS TAVERN, 1401 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31156,'lng':28.15532}},
{'stp':626680,'address':'626680, EGL - SOWETO, NOMBULELO MAJOVA, BONGIS PLACE PERMIT, 222 SKOSANA MATHEBULA STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32460,'lng':28.15290}},
{'stp':627204,'address':'627204, EGL - SOWETO, NOMBULELO MAJOVA, JOES TAVERN, 78 A RAKOMA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.32870,'lng':28.14370}},
{'stp':627574,'address':'627574, EGL - SOWETO, NOMBULELO MAJOVA, SEBEKO TAVERN, 517 SKHOSANA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32710,'lng':28.15020}},
{'stp':627575,'address':'627575, EGL - SOWETO, NOMBULELO MAJOVA, PETERS INN PERMIT, 375 MNYANI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33950,'lng':28.14330}},
{'stp':627810,'address':'627810, EGL - SOWETO, NOMBULELO MAJOVA, DIX TRADING, 1047 MANDELA PARK ZONE 4, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30615,'lng':28.14693}},
{'stp':627835,'address':'627835, EGL - SOWETO, NOMBULELO MAJOVA, MANTWAS PLACE PERMIT, 1086 MAKHUBU STREET, TSHONGWENI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31440,'lng':28.14835}},
{'stp':628165,'address':'628165, EGL - SOWETO, NOMBULELO MAJOVA, SIMPLE THING TAVERN, 1774 MOLOTO STREET, TSHONGWENI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30604,'lng':28.15614}},
{'stp':628688,'address':'628688, EGL - SOWETO, NOMBULELO MAJOVA, PUB INN PERMIT, 253 MATHABELA STREET, SKHOSANA, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32390,'lng':28.15060}},
{'stp':629134,'address':'629134, EGL - SOWETO, NOMBULELO MAJOVA, KWAPHINDANGENE PERMIT, 161 HLANHANE HOLOMISA, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30440,'lng':28.14870}},
{'stp':629501,'address':'629501, EGL - SOWETO, NOMBULELO MAJOVA, NTAMOS PLACE PERMIT, 132 LAMULA STREET, NHLAPO, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31019,'lng':28.14997}},
{'stp':630097,'address':'630097, EGL - SOWETO, NOMBULELO MAJOVA, FOX TAVERN PERMIT, 243 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30795,'lng':28.15187}},
{'stp':630142,'address':'630142, EGL - SOWETO, NOMBULELO MAJOVA, FARASI TAVERN PERMIT, 132 MAZIBUKO STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33530,'lng':28.14240}},
{'stp':630548,'address':'630548, EGL - SOWETO, NOMBULELO MAJOVA, SDUDLAS SHEBEEN, 237 HLAHANE STREET, HOLOMISA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30480,'lng':28.14950}},
{'stp':631044,'address':'631044, EGL - SOWETO, NOMBULELO MAJOVA, MAJUZA SHEBEEN, 412 HLATSHWAYO STREET, NHLAPO, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30720,'lng':28.15194}},
{'stp':635482,'address':'635482, EGL - SOWETO, NOMBULELO MAJOVA, PHILLIPS TAVERN, 291 ZITHUMANE, THWALA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32851,'lng':28.14721}},
{'stp':636356,'address':'636356, EGL - SOWETO, NOMBULELO MAJOVA, GREENS SHEBEEN, 552 SHABALALA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34260,'lng':28.14160}},
{'stp':636779,'address':'636779, EGL - SOWETO, NOMBULELO MAJOVA, NTOMBIS TUCKSHOP, 3545 MATLOUNG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31270,'lng':28.15730}},
{'stp':637110,'address':'637110, EGL - SOWETO, NOMBULELO MAJOVA, MTHUNZINI TAVERN, 7610 ROODEKOP, EXT 31 ROODEKOP, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.29770,'lng':28.15640}},
{'stp':637899,'address':'637899, EGL - SOWETO, NOMBULELO MAJOVA, DINAHS INN, 216 MONAHENG SECTION, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.32171,'lng':28.15604}},
{'stp':638109,'address':'638109, EGL - SOWETO, NOMBULELO MAJOVA, SESIS INN, 847 NHLAPO STREET, 847 NHLAPO STREET, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34350,'lng':28.13860}},
{'stp':638228,'address':'638228, EGL - SOWETO, NOMBULELO MAJOVA, KUBE\'S INN, 1267 NHLAPO SECTION, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.31330,'lng':28.14960}},
{'stp':638534,'address':'638534, EGL - SOWETO, NOMBULELO MAJOVA, MERCY, 4530 NTSHONGWENI, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31022,'lng':28.15873}},
{'stp':639483,'address':'639483, EGL - SOWETO, NOMBULELO MAJOVA, NTSIKIS TAVERN, 8044 MAZIBUKO STREET, THOKOZA, ALBERTON, TOKOZA, South Africa','geo':{'lat':-26.32990,'lng':28.14470}},
{'stp':639873,'address':'639873, EGL - SOWETO, NOMBULELO MAJOVA, THOKOZA LIQUOR STORE, 8060 KHUMALO STREET, THOKOZA, ALBERTON, TOKOZA, South Africa','geo':{'lat':-26.32970,'lng':28.14420}},
{'stp':641299,'address':'641299, EGL - SOWETO, NOMBULELO MAJOVA, ZAMOKWAKHE SHEBEEN, 2045 NKABINDE STREET, NHLAPO SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31430,'lng':28.14810}},
{'stp':642727,'address':'642727, EGL - SOWETO, NOMBULELO MAJOVA, LEVIS PLACE, 4567 MOTLOUNG SECTION, MOTLOUNG SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30600,'lng':28.16080}},
{'stp':646359,'address':'646359, EGL - SOWETO, NOMBULELO MAJOVA, CHARMANS TAVERN, 610  NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30560,'lng':28.14960}},
{'stp':648236,'address':'648236, EGL - SOWETO, NOMBULELO MAJOVA, MAVUSANA TUCKSHOP, 8993 GAMA CR, EXT 8, RATANDA, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.56670,'lng':28.32100}},
{'stp':649618,'address':'649618, EGL - SOWETO, NOMBULELO MAJOVA, SIMONS TAVERN, 719 MADONDO STREET ERF, CNR MDAKANE STREET, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33960,'lng':28.14093}},
{'stp':649863,'address':'649863, EGL - SOWETO, NOMBULELO MAJOVA, THE GIRLS TAVERN, 538 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31180,'lng':28.15020}},
{'stp':650213,'address':'650213, EGL - SOWETO, NOMBULELO MAJOVA, PHINDIS PLACE, 1028 NGUBENI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34480,'lng':28.14080}},
{'stp':650547,'address':'650547, EGL - SOWETO, NOMBULELO MAJOVA, ROSE TAVERN, 1056 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31240,'lng':28.15310}},
{'stp':650548,'address':'650548, EGL - SOWETO, NOMBULELO MAJOVA, MADIKIS PLACE, 874 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30567,'lng':28.15124}},
{'stp':652821,'address':'652821, EGL - SOWETO, NOMBULELO MAJOVA, FORIS TAVERN, 218 CREDI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32071,'lng':28.15015}},
{'stp':652856,'address':'652856, EGL - SOWETO, NOMBULELO MAJOVA, TWINS INN, 1521 TSHONGWENI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30960,'lng':28.15560}},
{'stp':653246,'address':'653246, EGL - SOWETO, NOMBULELO MAJOVA, GIGIS PLACE, 463 MAPHANGA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32166,'lng':28.14630}},
{'stp':653758,'address':'653758, EGL - SOWETO, NOMBULELO MAJOVA, KWA NDLOVU E TAVERN, 610 TSHABANGU STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34280,'lng':28.14090}},
{'stp':655080,'address':'655080, EGL - SOWETO, NOMBULELO MAJOVA, LINDIS TAVERN, 1049 NHLAPO SECTION, KATLEHONG, ALBERTON, KATLEHONG, South Africa','geo':{'lat':-26.31260,'lng':28.15210}},
{'stp':655102,'address':'655102, EGL - SOWETO, NOMBULELO MAJOVA, THULAS INN, 490 HOLOMISA PARK, HLAHANE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30590,'lng':28.14530}},
{'stp':657727,'address':'657727, EGL - SOWETO, NOMBULELO MAJOVA, SARAFINAS TAVERN, 108 MONAHENG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32030,'lng':28.15580}},
{'stp':657735,'address':'657735, EGL - SOWETO, NOMBULELO MAJOVA, KHARIK S PLACE, 202 SKHOSANA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32394,'lng':28.15131}},
{'stp':665356,'address':'665356, EGL - SOWETO, NOMBULELO MAJOVA, DIE SKIP PUB (NTO), NIGEL ROAD, RAYDALE, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.50165,'lng':28.35597}},
{'stp':666011,'address':'666011, EGL - SOWETO, NOMBULELO MAJOVA, THANDIS TAVERN, 664 MDAKANE STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34230,'lng':28.13980}},
{'stp':668858,'address':'668858, EGL - SOWETO, NOMBULELO MAJOVA, LOLOS PLACE, 3032 TSHONGWENI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31170,'lng':28.15720}},
{'stp':670413,'address':'670413, EGL - SOWETO, NOMBULELO MAJOVA, ONS\' PLEK (NTO), 10 MEYER STREET, HEIDELBERG CENTER, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.50796,'lng':28.36272}},
{'stp':686003,'address':'686003, EGL - SOWETO, NOMBULELO MAJOVA, TELLI LIQUOR OUTLET, ERF NO 01, TOKOLOHONG AGRICULTURAL VILLAGE, RATANDA, RATANDA, South Africa','geo':{'lat':-26.57390,'lng':28.32230}},
{'stp':692422,'address':'692422, EGL - SOWETO, NOMBULELO MAJOVA, PHUMELELE SHEBEEN, 425 HLAHANE STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30610,'lng':28.14690}},
{'stp':709539,'address':'709539, EGL - SOWETO, NOMBULELO MAJOVA, POPPY S INN, ERF 490 SKOSANA SECTION, VILAKAZI STREET, #, KATLEHONG, South Africa','geo':{'lat':-26.32708,'lng':28.14992}},
{'stp':716019,'address':'716019, EGL - SOWETO, NOMBULELO MAJOVA, KAYS LIQUOR STORE, 5020 MOTHEO STREET, MOTLOUNG SECTION,  KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31221,'lng':28.15845}},
{'stp':719399,'address':'719399, EGL - SOWETO, NOMBULELO MAJOVA, MASAKHANE PUB AND GRILL, 2521 TSHONGWEDI SECTION, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.31030,'lng':28.15690}},
{'stp':721004,'address':'721004, EGL - SOWETO, NOMBULELO MAJOVA, MOTLOUNG BOULEVARD TAVERN, ERF 5022 MOTLOUNG SECTION, KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.31230,'lng':28.15860}},
{'stp':726394,'address':'726394, EGL - SOWETO, NOMBULELO MAJOVA, E AND P TAVERN, 332 DONE LANE, JAMESON PARK, #, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.45290,'lng':28.41040}},
{'stp':726727,'address':'726727, EGL - SOWETO, NOMBULELO MAJOVA, VUYI S INN, 1563 TSHONGWENI SECTION, #, #, KATLEHONG, South Africa','geo':{'lat':-26.30477,'lng':28.15292}},
{'stp':748702,'address':'748702, EGL - SOWETO, NOMBULELO MAJOVA, JAZZ PUB AND GRILL, MASEMOLA STREET ERF 456, SHOSANA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32670,'lng':28.15070}},
{'stp':755112,'address':'755112, EGL - SOWETO, NOMBULELO MAJOVA, KHULU S BEER HALL, 01 STAND NO, NHLAPO SECTION KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.30760,'lng':28.14880}},
{'stp':755664,'address':'755664, EGL - SOWETO, NOMBULELO MAJOVA, JOE S TAVERN, 5883 NTOMBELA STREET, EXT 23 RATANDA, #, RATANDA, South Africa','geo':{'lat':-26.56550,'lng':28.32490}},
{'stp':779486,'address':'779486, EGL - SOWETO, NOMBULELO MAJOVA, KWANJILO ETSHWALENI, 144 STAND NO, MAJOLA STREET KATLEHONG GERMISTON, #, KATLEHONG, South Africa','geo':{'lat':-26.32161,'lng':28.15019}},
{'stp':779706,'address':'779706, EGL - SOWETO, NOMBULELO MAJOVA, BONGI S TAVERN, 1308 STAND NO, NHLAPO SECTION, KATLEHONG SECTION, KATLEHONG, South Africa','geo':{'lat':-26.31373,'lng':28.14820}},
{'stp':792077,'address':'792077, EGL - SOWETO, NOMBULELO MAJOVA, MABOSHS TAVERN, 160 TWALA SECTION, #, #, KATLEHONG, South Africa','geo':{'lat':-26.32713,'lng':28.14797}},
{'stp':794110,'address':'794110, EGL - SOWETO, NOMBULELO MAJOVA, MAMA G PLACE, STAND NO, ERF 2098 HLATSHWAYO STREET, NHLAPO KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31135,'lng':28.15072}},
{'stp':389588,'address':'389588, EGL - SOWETO, THABISO MOTLHAOLENG, G CORNER TAVERN, 6559 STAND NO, MARIMBA GARDENS EXTENSION 9 VOSLOORUS, BOKSBURG, VOSLOORUS, South Africa','geo':{'lat':-26.34777,'lng':28.21898}},
{'stp':390291,'address':'390291, EGL - SOWETO, THABISO MOTLHAOLENG, ZEN 63 RESTAURANT AND LOUNGE, 63 STAND NO, TSWELOPELE AND SEBOBANE STREET, MONISE SECTION, KATLEHONG, South Africa','geo':{'lat':-26.35859,'lng':28.16138}},
{'stp':390809,'address':'390809, EGL - SOWETO, THABISO MOTLHAOLENG, MAKOANYANE ELEVEN, 1130 STAND NO, #, #, KATLEHONG, South Africa','geo':{'lat':-26.38654,'lng':28.15170}},
{'stp':391169,'address':'391169, EGL - SOWETO, THABISO MOTLHAOLENG, CNR LIMPOPO TAVERN, 420 PHADIMA SECTION, #, #, KATLEHONG, South Africa','geo':{'lat':-26.34410,'lng':28.15976}},
{'stp':465695,'address':'465695, EGL - SOWETO, THABISO MOTLHAOLENG, DLAMINIS PLACE, 17028 MODIBO STREET, #, #, VOSLOORUS, South Africa','geo':{'lat':-26.36646,'lng':28.23135}},
{'stp':470795,'address':'470795, EGL - SOWETO, THABISO MOTLHAOLENG, MBATHAS TAVERN, UMQHUBI STREET, EXT 26 ERF 165223, VOSLOORUS BOSKBURG, VOSLOORUS, South Africa','geo':{'lat':-26.35211,'lng':28.22813}},
{'stp':472122,'address':'472122, EGL - SOWETO, THABISO MOTLHAOLENG, GRILLERS PUB AND SHISANYAMA, 655 BUTHELEZI STREET, TWALA SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.33274,'lng':28.14853}},
{'stp':476116,'address':'476116, EGL - SOWETO, THABISO MOTLHAOLENG, BKS LIQOUR STORE, 1391 STAND NUMBER, KHOZA, STREET, VOSLOORUS, South Africa','geo':{'lat':-26.35250,'lng':28.20792}},
{'stp':482257,'address':'482257, EGL - SOWETO, THABISO MOTLHAOLENG, EMASOFENI TAVERN, 8121 STAND NO, MO-KOATATSI CRES EXT, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34919,'lng':28.21389}},
{'stp':483193,'address':'483193, EGL - SOWETO, THABISO MOTLHAOLENG, ANTOWAVE INN, 03742 ERF NO 3742 NOMHLE, 3742 NOMHLE, PALMRIDGE EXT 3, PALM RIDGE, South Africa','geo':{'lat':-26.40182,'lng':28.13826}},
{'stp':486264,'address':'486264, EGL - SOWETO, THABISO MOTLHAOLENG, MAIN ROAD INN, 1842 STAND NO, KENOSI STREET, MAILULA PARK, KATLEHONG, South Africa','geo':{'lat':-26.36950,'lng':28.18984}},
{'stp':486380,'address':'486380, EGL - SOWETO, THABISO MOTLHAOLENG, BLU RESTAURANT AND PUB, 63 STAND NO, TSWELOPELE STREET, MONISE SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.35859,'lng':28.16138}},
{'stp':486612,'address':'486612, EGL - SOWETO, THABISO MOTLHAOLENG, TEAM PLACE, 10/21750 STAND NO, SEFUDUFUDI STREET, EXT 6 PONONG, VOSLOORUS, South Africa','geo':{'lat':-26.32910,'lng':28.20080}},
{'stp':489004,'address':'489004, EGL - SOWETO, THABISO MOTLHAOLENG, PITTSTOP LIQUORS, 13 SHOP NO, REMAINDER 3 OF ERF 41, TEEBOS AVE, PALMRIDGE, PALM RIDGE, South Africa','geo':{'lat':-26.38893,'lng':28.14124}},
{'stp':494844,'address':'494844, EGL - SOWETO, THABISO MOTLHAOLENG, MILLION PUB, 283 STAND NO, MAGOCWANE STREET, NEALA SECTION, KATLEHONG, South Africa','geo':{'lat':-26.34307,'lng':28.14527}},
{'stp':544716,'address':'544716, EGL - SOWETO, THABISO MOTLHAOLENG, TSHIDIS CAR WASH, 16406 IKHAYA STREET, VOSLOORUS EXT 4, #, VOSLOORUS, South Africa','geo':{'lat':-26.35101,'lng':28.21266}},
{'stp':549387,'address':'549387, EGL - SOWETO, THABISO MOTLHAOLENG, MOLEBAS CAFE, STAND NO, ERF 3031 MOKGAKO STREET, VOSLOORUS EXT 1, VOSLOORUS, South Africa','geo':{'lat':-26.35247,'lng':28.19259}},
{'stp':550311,'address':'550311, EGL - SOWETO, THABISO MOTLHAOLENG, DRIVE INN, KHUBEKASTRAAT 96, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34850,'lng':28.20980}},
{'stp':550549,'address':'550549, EGL - SOWETO, THABISO MOTLHAOLENG, MDU N TAVERN, STAND 8204 MASANAT LAKE, MARIMBA GARDENS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34930,'lng':28.21620}},
{'stp':551028,'address':'551028, EGL - SOWETO, THABISO MOTLHAOLENG, KATTIS BAR RESTURANT CC, 544 TWALA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33160,'lng':28.14650}},
{'stp':551286,'address':'551286, EGL - SOWETO, THABISO MOTLHAOLENG, NHLAPO RESTAURANT, MOBOLI STREET, 17 & 83 MAVIMBELA SE, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34690,'lng':28.14930}},
{'stp':551445,'address':'551445, EGL - SOWETO, THABISO MOTLHAOLENG, BILLION S HOUSE TAVERN, 340 MOFOKENG SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35120,'lng':28.16580}},
{'stp':551531,'address':'551531, EGL - SOWETO, THABISO MOTLHAOLENG, ENTOKOZWENI TAVERN, 1035  ZONKIZIZWE STREET, ZONE 4, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.41035,'lng':28.18188}},
{'stp':552247,'address':'552247, EGL - SOWETO, THABISO MOTLHAOLENG, BELLA GARDENS TAVERN, 111 DUBAZANA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34523,'lng':28.20623}},
{'stp':552272,'address':'552272, EGL - SOWETO, THABISO MOTLHAOLENG, ROUND ONE TAVERN, 469 GAMPU STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34690,'lng':28.20700}},
{'stp':552505,'address':'552505, EGL - SOWETO, THABISO MOTLHAOLENG, C CLASS INN, STAND 1150 MOFOKENG STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35106,'lng':28.19563}},
{'stp':552927,'address':'552927, EGL - SOWETO, THABISO MOTLHAOLENG, SEFATENG INN, 60 MOSELEKE STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34410,'lng':28.15976}},
{'stp':553372,'address':'553372, EGL - SOWETO, THABISO MOTLHAOLENG, JAZZ HOUSE TAVERN, ERF1203 TSHONGWENI SECTION, TSHONGWENI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30860,'lng':28.15490}},
{'stp':553518,'address':'553518, EGL - SOWETO, THABISO MOTLHAOLENG, DIXIE\'S TAVERN, SITE 2100 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31169,'lng':28.15101}},
{'stp':553831,'address':'553831, EGL - SOWETO, THABISO MOTLHAOLENG, CHACKLAS PLACE PERMIT, 6473 INKOSIAMODLANGA STR, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34770,'lng':28.21730}},
{'stp':553950,'address':'553950, EGL - SOWETO, THABISO MOTLHAOLENG, MOTLEKES TAVERN PERMIT, 301 MOSILIKI SECTION, KATLEHONG, KATLEHONGQ, KATLEHONG, South Africa','geo':{'lat':-26.34490,'lng':28.16040}},
{'stp':553957,'address':'553957, EGL - SOWETO, THABISO MOTLHAOLENG, SOX INN, 6648  SEPHOKA STREET, EXT 9, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34600,'lng':28.22040}},
{'stp':560464,'address':'560464, EGL - SOWETO, THABISO MOTLHAOLENG, 2020, 06 STAND, 1584 PT1, TSHONGWENI SECTION, KATLEHONG, South Africa','geo':{'lat':-26.30743,'lng':28.14550}},
{'stp':560590,'address':'560590, EGL - SOWETO, THABISO MOTLHAOLENG, JOSEPH CINDY, 329 VOSLOORUS  EXT 7, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.38377,'lng':28.20530}},
{'stp':560598,'address':'560598, EGL - SOWETO, THABISO MOTLHAOLENG, WINNIE RAMIDARO, 2134 HATTINGH STREET, SPRUITVIEW, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31480,'lng':28.17420}},
{'stp':580419,'address':'580419, EGL - SOWETO, THABISO MOTLHAOLENG, KENNYS TAVERN, 17844 LEBABAI STREET, EXT 25, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36678,'lng':28.22845}},
{'stp':580514,'address':'580514, EGL - SOWETO, THABISO MOTLHAOLENG, PIDODO AND PAPADODO INN, 7044 SUNRIZE, EXT 2, ZONKIZIZWE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38970,'lng':28.19320}},
{'stp':580563,'address':'580563, EGL - SOWETO, THABISO MOTLHAOLENG, GRANNYS TAVERN, 515 GAMA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34870,'lng':28.20420}},
{'stp':580884,'address':'580884, EGL - SOWETO, THABISO MOTLHAOLENG, SISTERS INN - PERMIT, 107 NDLANZI STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35310,'lng':28.15440}},
{'stp':602862,'address':'602862, EGL - SOWETO, THABISO MOTLHAOLENG, JESSIES TAVERN, 1349 NKOSI CRESCENT, SPRUITVIEW, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.32310,'lng':28.18060}},
{'stp':602898,'address':'602898, EGL - SOWETO, THABISO MOTLHAOLENG, KUMALOS TAVERN, 2072 HATTINGH STREET, SPRUITVIEW, VOSLOORUS, SPRUITVIEW, South Africa','geo':{'lat':-26.31330,'lng':28.17350}},
{'stp':603272,'address':'603272, EGL - SOWETO, THABISO MOTLHAOLENG, KHWEMBUS TAVERN, 13415 MCULO STREET, MZAMO ACRES, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.32800,'lng':28.18230}},
{'stp':603352,'address':'603352, EGL - SOWETO, THABISO MOTLHAOLENG, AUNTIES PLACE, 2465 JAMA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36088,'lng':28.20100}},
{'stp':603521,'address':'603521, EGL - SOWETO, THABISO MOTLHAOLENG, PINKYS PLACE, 7135 SAM SEKOATI AVENUE, EXT 9, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35494,'lng':28.21956}},
{'stp':603798,'address':'603798, EGL - SOWETO, THABISO MOTLHAOLENG, REUBENS TAVERN, 697 MHLONGO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35680,'lng':28.21030}},
{'stp':604964,'address':'604964, EGL - SOWETO, THABISO MOTLHAOLENG, DAIZYS TAVERN, 7161 LE EVAKAKO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35330,'lng':28.22000}},
{'stp':605633,'address':'605633, EGL - SOWETO, THABISO MOTLHAOLENG, MINA NAWE EYETHU, 64 MOMA STR, EXT 28, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37692,'lng':28.20024}},
{'stp':605692,'address':'605692, EGL - SOWETO, THABISO MOTLHAOLENG, OUMAS PLACE, 6483 NKOSIAMADLANGA STR, EXT 9 MARIMBA GARDEN, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34680,'lng':28.21760}},
{'stp':605870,'address':'605870, EGL - SOWETO, THABISO MOTLHAOLENG, NKOPANES PLACE, 78 MOSHOESHOE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31840,'lng':28.15850}},
{'stp':605875,'address':'605875, EGL - SOWETO, THABISO MOTLHAOLENG, THULANI INN, 696 MHL0NGO STREET,  EXT 2, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35654,'lng':28.21040}},
{'stp':606904,'address':'606904, EGL - SOWETO, THABISO MOTLHAOLENG, MOHLALAS TAVERN, 4121 REST N PEACE STREET, NGUNI SECTION, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35840,'lng':28.20990}},
{'stp':606968,'address':'606968, EGL - SOWETO, THABISO MOTLHAOLENG, MPUMI S CABIN, 20631 LEFOKOTSANA STREET  MFUNDO PA, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34050,'lng':28.20860}},
{'stp':607089,'address':'607089, EGL - SOWETO, THABISO MOTLHAOLENG, DUMISAS PLACE PERMIT, 893 MAVIMBELA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35213,'lng':28.14899}},
{'stp':607336,'address':'607336, EGL - SOWETO, THABISO MOTLHAOLENG, WENDYS CATERING AND REFRE, 7400 TZOKO CLOSE, MARIMBA GARDENS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35450,'lng':28.21830}},
{'stp':607541,'address':'607541, EGL - SOWETO, THABISO MOTLHAOLENG, BUKKY BAR, 1152 STAND NO, 17 BLAUWBOK STREET, LEONDALE, SPRUITVIEW, South Africa','geo':{'lat':-26.30023,'lng':28.18143}},
{'stp':610424,'address':'610424, EGL - SOWETO, THABISO MOTLHAOLENG, WHITE HOUSE (NTO), 13474/5 MZAMO ACRES, SPRUITVIEW, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.32690,'lng':28.18490}},
{'stp':625482,'address':'625482, EGL - SOWETO, THABISO MOTLHAOLENG, VUYISWAS PLACE, 13636 MOAGI ROAD, ENKUNDLENI STREET, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.32890,'lng':28.18680}},
{'stp':627974,'address':'627974, EGL - SOWETO, THABISO MOTLHAOLENG, SONTOS PLACE PERMIT, 30 PHASE 6B, VOSLOORUS, EXT 28, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37123,'lng':28.19473}},
{'stp':628104,'address':'628104, EGL - SOWETO, THABISO MOTLHAOLENG, NORAHS PUB AND RESTAURANT PERMIT, 6820  SAM SEKOATI AVE  MARIMBA GARD, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34923,'lng':28.21942}},
{'stp':628208,'address':'628208, EGL - SOWETO, THABISO MOTLHAOLENG, KHOZAS TAVERN PERMIT, 8013 MASIANOKE STREET, EXT 9 MARIMBA GARDENS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34760,'lng':28.21400}},
{'stp':629932,'address':'629932, EGL - SOWETO, THABISO MOTLHAOLENG, MASECHABAS PLACE PERMIT, 2362 BOUWER STREET, SPRUITVIEW, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.32220,'lng':28.17610}},
{'stp':630232,'address':'630232, EGL - SOWETO, THABISO MOTLHAOLENG, ISOLOMZI PLACE, 59 HAWTHORN AVENUE, PALM RIDGE, EDEN PARK, PALM RIDGE, South Africa','geo':{'lat':-26.39219,'lng':28.15028}},
{'stp':630798,'address':'630798, EGL - SOWETO, THABISO MOTLHAOLENG, JOSHUA TUCKSHOP, 20614 IROZALANE STREET, EXT 30 MFUN, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33975,'lng':28.20924}},
{'stp':631177,'address':'631177, EGL - SOWETO, THABISO MOTLHAOLENG, MOSS INN, 20738 LEFOKOTSANE STREET, MFUNDO PARK, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34035,'lng':28.21050}},
{'stp':632758,'address':'632758, EGL - SOWETO, THABISO MOTLHAOLENG, GAMBU LIQUOR, 1703 MAKGALEMELE ROAD, NGUNI SECTIO, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35590,'lng':28.20640}},
{'stp':636027,'address':'636027, EGL - SOWETO, THABISO MOTLHAOLENG, PN TAVERN, 2529 KATLEHONG SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37735,'lng':28.18634}},
{'stp':636341,'address':'636341, EGL - SOWETO, THABISO MOTLHAOLENG, GESTA\'S PLACE, 1285 PHAROE STREET, SPRUITVIEW, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.32462,'lng':28.18233}},
{'stp':636661,'address':'636661, EGL - SOWETO, THABISO MOTLHAOLENG, MORAKILE TAVERN, 175 TSOLO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34030,'lng':28.15630}},
{'stp':636840,'address':'636840, EGL - SOWETO, THABISO MOTLHAOLENG, JOSEPHINES TAVERN, 20561 INTUKWANE STREET EXT30, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34004,'lng':28.20808}},
{'stp':636920,'address':'636920, EGL - SOWETO, THABISO MOTLHAOLENG, COME RELAX TAVERN, 16659 EXT 26 INGALA ST, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35095,'lng':28.22491}},
{'stp':637331,'address':'637331, EGL - SOWETO, THABISO MOTLHAOLENG, THE MELODING RESTAURANT, 336 CNR TYAWI & MOERANE STREET, MOSHOESHOE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32470,'lng':28.15780}},
{'stp':639769,'address':'639769, EGL - SOWETO, THABISO MOTLHAOLENG, LOST CITY BOTTLE STORE, 9069 MOSHAKHANE STREET, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30640,'lng':28.15820}},
{'stp':640392,'address':'640392, EGL - SOWETO, THABISO MOTLHAOLENG, LOXION LIQUOR, 352 NDOBE STREET, SPRUITVIEW, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.32140,'lng':28.18660}},
{'stp':641082,'address':'641082, EGL - SOWETO, THABISO MOTLHAOLENG, ENTOKOZWENI LIQUOR DISTRIBUTORS, 1036 ZONKIZIZWE STREET, ZONE 4, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.41040,'lng':28.18190}},
{'stp':641925,'address':'641925, EGL - SOWETO, THABISO MOTLHAOLENG, ZWAKALA, 171-173 CNR BIERMAN & NOMBHELA DR, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34147,'lng':28.21609}},
{'stp':642620,'address':'642620, EGL - SOWETO, THABISO MOTLHAOLENG, NOMSAS INN, 16573 SEINOLI STREET, EXTENSION 26, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35269,'lng':28.22535}},
{'stp':646109,'address':'646109, EGL - SOWETO, THABISO MOTLHAOLENG, MOONLIGHT LIQUOR STORE, 193 NGEMA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33500,'lng':28.15100}},
{'stp':647395,'address':'647395, EGL - SOWETO, THABISO MOTLHAOLENG, SMILING TREE SNACK BAR, STAND NO 824, RAMOKONOPI WEST, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.35874,'lng':28.16215}},
{'stp':647662,'address':'647662, EGL - SOWETO, THABISO MOTLHAOLENG, MORIATE JULY TAVERN, 5126 PHUMULA GARDENS EXT 22, SPRIUTVIEW, KATLEHONG, SPRUITVIEW, South Africa','geo':{'lat':-26.31320,'lng':28.19450}},
{'stp':648294,'address':'648294, EGL - SOWETO, THABISO MOTLHAOLENG, PHUMLANI TAVERN, 6937 LEROLE STREET, EXTENSION 31, ROODEKOP, SPRUITVIEW, South Africa','geo':{'lat':-26.29500,'lng':28.15130}},
{'stp':650271,'address':'650271, EGL - SOWETO, THABISO MOTLHAOLENG, ROOTS TAVERN, 2965 STAND NO, MOLELEKI EXTENSION 1, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37168,'lng':28.16272}},
{'stp':650273,'address':'650273, EGL - SOWETO, THABISO MOTLHAOLENG, RHUMBAS INN, 6278 LEFULE STREET, MARIMBA EXT 9, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34490,'lng':28.21610}},
{'stp':650297,'address':'650297, EGL - SOWETO, THABISO MOTLHAOLENG, D E K RESTAURANT, STAND NO 736, MOFOKENG SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35430,'lng':28.16529}},
{'stp':650587,'address':'650587, EGL - SOWETO, THABISO MOTLHAOLENG, TSHIDIS TAVERN, 21141 EXT 20, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37380,'lng':28.18500}},
{'stp':652235,'address':'652235, EGL - SOWETO, THABISO MOTLHAOLENG, JL LOUNGE, 1997 TSHONGWENI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31072,'lng':28.15642}},
{'stp':653135,'address':'653135, EGL - SOWETO, THABISO MOTLHAOLENG, MERITING, 102 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30902,'lng':28.15178}},
{'stp':653213,'address':'653213, EGL - SOWETO, THABISO MOTLHAOLENG, EUNICES TAVERN, 590 IKHETHINI ROAD, EXTENSION 7, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36090,'lng':28.19410}},
{'stp':653224,'address':'653224, EGL - SOWETO, THABISO MOTLHAOLENG, BANYANAS PUB AND GRILL, 1293 MC BOTHA DRIVE, VOSLOORUS, VOSLOORUS, KATLEHONG, South Africa','geo':{'lat':-26.35448,'lng':28.19669}},
{'stp':653755,'address':'653755, EGL - SOWETO, THABISO MOTLHAOLENG, THANDIS TAVERN, 8299 PHAKOE STREET, VOSLOORUS EXTENSION 9, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34995,'lng':28.21526}},
{'stp':653864,'address':'653864, EGL - SOWETO, THABISO MOTLHAOLENG, ELIS INN, 10378 SEFOFANE STREET, VOSLOORUS EXTENSION 14, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35467,'lng':28.22712}},
{'stp':653885,'address':'653885, EGL - SOWETO, THABISO MOTLHAOLENG, KA SDUDLAS TAVERN, 10434 ITYHOLO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35531,'lng':28.22658}},
{'stp':653887,'address':'653887, EGL - SOWETO, THABISO MOTLHAOLENG, BAYANAS PLACE, 11566 INYAMAZANE STREET, EXT 14, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36350,'lng':28.22480}},
{'stp':653894,'address':'653894, EGL - SOWETO, THABISO MOTLHAOLENG, SOPHIES PLACE, 10861 UKUSUKA STREET, EXTENSION 14, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36180,'lng':28.22540}},
{'stp':654857,'address':'654857, EGL - SOWETO, THABISO MOTLHAOLENG, BHEKIS TAVERN, 9337 SONTANGA ROAD, PALMRIDGE, EXTENSION 6, PALM RIDGE, South Africa','geo':{'lat':-26.40522,'lng':28.15377}},
{'stp':664260,'address':'664260, EGL - SOWETO, THABISO MOTLHAOLENG, MAKHOSI TAVERN, 2828 THABO STREET, DIKOLE EXTENSION 1, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38539,'lng':28.16476}},
{'stp':664319,'address':'664319, EGL - SOWETO, THABISO MOTLHAOLENG, HURRICANES PLACE, 105 PHAKE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33130,'lng':28.15720}},
{'stp':664329,'address':'664329, EGL - SOWETO, THABISO MOTLHAOLENG, MAPOMAS PLACE, NO 7033 SITHOHIMELA STREET, VOSLOORUS, VOSLOORUS EXT 9, VOSLOORUS, South Africa','geo':{'lat':-26.35280,'lng':28.22250}},
{'stp':664371,'address':'664371, EGL - SOWETO, THABISO MOTLHAOLENG, SALOMES INN, 6397 IN NKEKANE CRESCENT, EXTENSION 9, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34700,'lng':28.21590}},
{'stp':664885,'address':'664885, EGL - SOWETO, THABISO MOTLHAOLENG, MAMOKITI, 2039 MOLELEKI, EXTENSION 3, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38381,'lng':28.18963}},
{'stp':666885,'address':'666885, EGL - SOWETO, THABISO MOTLHAOLENG, ELLESNS TAVERN, 404 MOTLOPI STREET, PONONG, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.32590,'lng':28.19533}},
{'stp':667173,'address':'667173, EGL - SOWETO, THABISO MOTLHAOLENG, ENDAWENI SHISA NYAMA CAR WASH, 1403 MOKGAKO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35486,'lng':28.19566}},
{'stp':668503,'address':'668503, EGL - SOWETO, THABISO MOTLHAOLENG, TWILIGHT LOUNGE, 334 BEMBO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35624,'lng':28.18856}},
{'stp':668731,'address':'668731, EGL - SOWETO, THABISO MOTLHAOLENG, LINCO CITY, STANDPLAAS 33, SKOSANA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33305,'lng':28.14769}},
{'stp':671362,'address':'671362, EGL - SOWETO, THABISO MOTLHAOLENG, BALCONY CLUB, 5021 MOTLOUNG SECTION NO, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.31220,'lng':28.15850}},
{'stp':684139,'address':'684139, EGL - SOWETO, THABISO MOTLHAOLENG, THE ZONE, 10904 LERUMO STREET, EXT 14, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35915,'lng':28.22223}},
{'stp':684173,'address':'684173, EGL - SOWETO, THABISO MOTLHAOLENG, EKUPHOLENI LODGE, 1186 HLOPHE STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35140,'lng':28.20500}},
{'stp':684417,'address':'684417, EGL - SOWETO, THABISO MOTLHAOLENG, PADIS TAVERN, 227 PHOOKO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32781,'lng':28.17242}},
{'stp':690721,'address':'690721, EGL - SOWETO, THABISO MOTLHAOLENG, VOLI\'S PUB AND GRILL (NTO), 17 NKOANYANA STREET, KATHLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34679,'lng':28.14842}},
{'stp':695584,'address':'695584, EGL - SOWETO, THABISO MOTLHAOLENG, SIPHOS INN, 8012 MASIANOKE STREET, MARIMBA GARDENS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34740,'lng':28.21410}},
{'stp':698497,'address':'698497, EGL - SOWETO, THABISO MOTLHAOLENG, TOP CHILLAS, 8168 MANDISA STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33472,'lng':28.14650}},
{'stp':703460,'address':'703460, EGL - SOWETO, THABISO MOTLHAOLENG, MILLION LIQUOR, 283 MAGOCWANE STREET, ERF 283 NCALA SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.34307,'lng':28.14527}},
{'stp':708469,'address':'708469, EGL - SOWETO, THABISO MOTLHAOLENG, EZENKENI PUB AND GRILL, ERF 4544, TSHONGWENI SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.31162,'lng':28.15671}},
{'stp':710313,'address':'710313, EGL - SOWETO, THABISO MOTLHAOLENG, TLALI S TAVERN, ERF 349 LESIKA STREET, EXT 5, PONONG, SPRUITVIEW, South Africa','geo':{'lat':-26.32770,'lng':28.19090}},
{'stp':720954,'address':'720954, EGL - SOWETO, THABISO MOTLHAOLENG, JUSTICE TAVERN, 988 SPRUITVIEW, #, #, VOSLOORUS, South Africa','geo':{'lat':-26.32068,'lng':28.18030}},
{'stp':730514,'address':'730514, EGL - SOWETO, THABISO MOTLHAOLENG, GARDEN PUB AND GRILL, MPS MFUNDO PARK SQUARE, LEFOKOTSANE STREET ERF 20631, VOSLOORUS X30, VOSLOORUS, South Africa','geo':{'lat':-26.34040,'lng':28.20846}},
{'stp':731945,'address':'731945, EGL - SOWETO, THABISO MOTLHAOLENG, MAKITI (NTO), CNR HEIDELBERG & SWARTKOPPIES RDS, ALBERTON, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.28070,'lng':28.12350}},
{'stp':742387,'address':'742387, EGL - SOWETO, THABISO MOTLHAOLENG, CHRISTOS TAVERN, 260 SILUMA DRIVE, SPRUITVIEW, #, KATLEHONG, South Africa','geo':{'lat':-26.32433,'lng':28.18322}},
{'stp':754398,'address':'754398, EGL - SOWETO, THABISO MOTLHAOLENG, BENS SHEBEEN, 3249 STAND NO, EXT 9 KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.37874,'lng':28.18954}},
{'stp':761061,'address':'761061, EGL - SOWETO, THABISO MOTLHAOLENG, GRAB AND CHOW, 1582 ROETS DRIVE, VOSLOORUS, #, VOSLOORUS, South Africa','geo':{'lat':-26.35782,'lng':28.19257}},
{'stp':765336,'address':'765336, EGL - SOWETO, THABISO MOTLHAOLENG, KASI CHILLAS PUB, 302 STAND NO, MOKOENA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.41440,'lng':28.17464}},
{'stp':767568,'address':'767568, EGL - SOWETO, THABISO MOTLHAOLENG, RONTY CARLO INN, 01 STAND NO, SKHOSANA SECTION KATLEHONG, #, KATLEHONG, South Africa','geo':{'lat':-26.32184,'lng':28.14916}},
{'stp':767886,'address':'767886, EGL - SOWETO, THABISO MOTLHAOLENG, WHITE HOUSE, 13474/5 STAND NO, MZAMO CRESENT, EXT 11, KATLEHONG, South Africa','geo':{'lat':-26.32718,'lng':28.18510}},
{'stp':777398,'address':'777398, EGL - SOWETO, THABISO MOTLHAOLENG, KEPISING TAVERN, 8226 MASIANOKE STREET, VOSLOORUS, #, VOSLOORUS, South Africa','geo':{'lat':-26.35143,'lng':28.21399}},
{'stp':794063,'address':'794063, EGL - SOWETO, THABISO MOTLHAOLENG, MELODING LOUNGE AND RESTAURANT, 336 STAND NO, MOSHOSHE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32485,'lng':28.15794}},
{'stp':797881,'address':'797881, EGL - SOWETO, THABISO MOTLHAOLENG, EMZWINKINI  BUY AND BRAAI, STAND NO, ERF 312 RADEBE SECTION, #, KATLEHONG, South Africa','geo':{'lat':-26.35833,'lng':28.14718}},
{'stp':799477,'address':'799477, EGL - SOWETO, THABISO MOTLHAOLENG, KHOTSO PUB AND GRILL HOUSE, 8600 ERF KGOTSO STREET, UNIT F TOKOZA TOWNSHIP, #, TOKOZA, South Africa','geo':{'lat':-26.36490,'lng':28.14238}},
{'stp':389759,'address':'389759, EGL - SOWETO, THABISO MOTLHAOLENG, VUKANI BOTTLE STORE, 1309 STAND NO, NDOYE, VOSLOORUS, BOKSBURG, #, VOSLOORUS, South Africa','geo':{'lat':-26.36669,'lng':28.18601}},
{'stp':390921,'address':'390921, EGL - SOWETO, THABISO MOTLHAOLENG, GEORGE S PLACE, 7134 STAND NO, #, #, KATLEHONG, South Africa','geo':{'lat':-26.38920,'lng':28.19541}},
{'stp':391166,'address':'391166, EGL - SOWETO, THABISO MOTLHAOLENG, THE SPACE TAVERN, 262 STAND NO, MBIZI STREET, #, VOSLOORUS, South Africa','geo':{'lat':-26.37786,'lng':28.20607}},
{'stp':392199,'address':'392199, EGL - SOWETO, THABISO MOTLHAOLENG, SIBONGILES PLACE, 314 NO, MOSEHLA STREET,EXTENSION 28, #, #, VOSLOORUS, South Africa','geo':{'lat':-26.36186,'lng':28.23253}},
{'stp':392713,'address':'392713, EGL - SOWETO, THABISO MOTLHAOLENG, ACE S PLACE, 11507 STAND NO, IDELANGOZI STREET, VOSLOORUS EXT 14, VOSLOORUS, South Africa','geo':{'lat':-26.36553,'lng':28.22070}},
{'stp':392810,'address':'392810, EGL - SOWETO, THABISO MOTLHAOLENG, IKWEZI BOTTLE STORE, 21710 STAND NO, BIERMAN ROAD EXT 29, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34309,'lng':28.20828}},
{'stp':464731,'address':'464731, EGL - SOWETO, THABISO MOTLHAOLENG, THULO\'S PLACE, 17071 STAND NO, MOHLAKO EXT 25, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36951,'lng':28.22947}},
{'stp':466602,'address':'466602, EGL - SOWETO, THABISO MOTLHAOLENG, TOPS AT SPAR (CHRISHANI), STAND NO, BIERMAN CNR BRICKFIELD STREETS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34454,'lng':28.17999}},
{'stp':467305,'address':'467305, EGL - SOWETO, THABISO MOTLHAOLENG, EYETHU TAVERN, ERF 3575, CETSHWAYO STREET, KATLEHONG SOUTH, KATLEHONG, South Africa','geo':{'lat':-26.38151,'lng':28.19448}},
{'stp':469683,'address':'469683, EGL - SOWETO, THABISO MOTLHAOLENG, EMILYS PLACE, STAND NO 8 Ext 28 Phase 1, ERF 20925  GWAYI STREET, #, VOSLOORUS, South Africa','geo':{'lat':-26.37655,'lng':28.20384}},
{'stp':471079,'address':'471079, EGL - SOWETO, THABISO MOTLHAOLENG, TITI\'S TAVERN, 3648 STAND NO, KATLEHONG SOUTH, #, KATLEHONG, South Africa','geo':{'lat':-26.38043,'lng':28.19245}},
{'stp':473719,'address':'473719, EGL - SOWETO, THABISO MOTLHAOLENG, MAPHUMULOS TAVERN, 938 STAND NO, CHURCHILL MOKOENA STREET, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36196,'lng':28.21589}},
{'stp':479202,'address':'479202, EGL - SOWETO, THABISO MOTLHAOLENG, SHOPRITE LIQUORSHOPE VOSLOORUS 3904, SHOP 5 NALEDI MALL, MC BOTHA DRIVE, VOSLOORUS ERF 6160, VOSLOORUS, South Africa','geo':{'lat':-26.35024,'lng':28.19983}},
{'stp':484060,'address':'484060, EGL - SOWETO, THABISO MOTLHAOLENG, MIKES TAVERN, 21518 STAND NO, GALAXY STREETVOSLOORUS EXTENSION 20, KATLENONG SOUTH, KATLEHONG, South Africa','geo':{'lat':-26.37642,'lng':28.18834}},
{'stp':488444,'address':'488444, EGL - SOWETO, THABISO MOTLHAOLENG, PNP GC66 PNP LIQUOR STORE VOSLOORUS, 6258 ERF, PORTION 1 SAM SEKOATI STREET, BOKSBURG, VOSLOORUS, South Africa','geo':{'lat':-26.34436,'lng':28.21676}},
{'stp':547314,'address':'547314, EGL - SOWETO, THABISO MOTLHAOLENG, MOSS REFRESHMENT, 5157 ERF, MBONANI ROAD VOSLORUS EXT 2, #, VOSLOORUS, South Africa','geo':{'lat':-26.36338,'lng':28.21123}},
{'stp':550238,'address':'550238, EGL - SOWETO, THABISO MOTLHAOLENG, B F N LIQUOR STORE, SHOP 2,1646 MATSAPA STREET, VOSLOORUS EXT 3, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36690,'lng':28.18960}},
{'stp':550583,'address':'550583, EGL - SOWETO, THABISO MOTLHAOLENG, FUNIS TAVERN, ERF 2936, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35869,'lng':28.20757}},
{'stp':550601,'address':'550601, EGL - SOWETO, THABISO MOTLHAOLENG, ALETTAS TAVERN, ERF 3763, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36379,'lng':28.20373}},
{'stp':551786,'address':'551786, EGL - SOWETO, THABISO MOTLHAOLENG, MIAMI VICE TAVERN, STAND 1347 MALULEKE STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36610,'lng':28.21500}},
{'stp':551822,'address':'551822, EGL - SOWETO, THABISO MOTLHAOLENG, FRIENDLY TAVERN, 146 IMPALANGA ROAD, EXT 8,  VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36630,'lng':28.19990}},
{'stp':552234,'address':'552234, EGL - SOWETO, THABISO MOTLHAOLENG, FENYANES TAVERN, ERF 171, MASIANOKE STRAAT, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35000,'lng':28.21270}},
{'stp':552578,'address':'552578, EGL - SOWETO, THABISO MOTLHAOLENG, FOURWAYS SPORT BAR, CNR BARRY MARAIS & SAM SEKOATI AVE, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34274,'lng':28.21721}},
{'stp':552905,'address':'552905, EGL - SOWETO, THABISO MOTLHAOLENG, EAST FIELD LIQUOR LAND, 2682 MATLALA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36180,'lng':28.20390}},
{'stp':553092,'address':'553092, EGL - SOWETO, THABISO MOTLHAOLENG, LERATONG INN, 111 STAND NO, MONGU STREET, EXT 28, VOSLOORUS, South Africa','geo':{'lat':-26.37350,'lng':28.20440}},
{'stp':553241,'address':'553241, EGL - SOWETO, THABISO MOTLHAOLENG, MATHAMBO TOPS, CNR K123 POVINCIAL & MOAGI STR, SPRUITVIEW, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.32600,'lng':28.18540}},
{'stp':553531,'address':'553531, EGL - SOWETO, THABISO MOTLHAOLENG, LUCKYS BOTTLE STORE, BARRY MARAIS ROAD, ERF 171 FHR CENTRE NO 136, #, VOSLOORUS, South Africa','geo':{'lat':-26.34140,'lng':28.21730}},
{'stp':553826,'address':'553826, EGL - SOWETO, THABISO MOTLHAOLENG, BUTIZAS TAVERN PERMIT, 1571 MOLELEKI EXT 3, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38503,'lng':28.18642}},
{'stp':553927,'address':'553927, EGL - SOWETO, THABISO MOTLHAOLENG, GRIZAS TAVERN PERMIT, 1607 MOLELEKI STREET, EXT 3, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38410,'lng':28.18570}},
{'stp':580476,'address':'580476, EGL - SOWETO, THABISO MOTLHAOLENG, YIZO YIZO TAVERN, 368 MBANDE STREET PORTION 27, PHASE 2VEXT 28, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37980,'lng':28.20270}},
{'stp':580885,'address':'580885, EGL - SOWETO, THABISO MOTLHAOLENG, CECILIAS PLACE, 2070 MOLELEKI SECTION EXT 3, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38409,'lng':28.19218}},
{'stp':581188,'address':'581188, EGL - SOWETO, THABISO MOTLHAOLENG, BEAUTYS TAVERN, 17087 MOHLOPHI STREET, EXT 25, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36820,'lng':28.22990}},
{'stp':602874,'address':'602874, EGL - SOWETO, THABISO MOTLHAOLENG, EMAPLANKENI TAVERN, 302 NKOANE STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36660,'lng':28.20300}},
{'stp':603212,'address':'603212, EGL - SOWETO, THABISO MOTLHAOLENG, SALLYS PLACE, 17941 MOHLOPI CRESCENT, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36813,'lng':28.23038}},
{'stp':604002,'address':'604002, EGL - SOWETO, THABISO MOTLHAOLENG, MANACS TAVERN, 6736 ZONKIZIZWE, EXT 2, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38810,'lng':28.19720}},
{'stp':604520,'address':'604520, EGL - SOWETO, THABISO MOTLHAOLENG, RANDYS TAVERN, 6482 ZONKIZIZWE EXT 2, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38610,'lng':28.19770}},
{'stp':605874,'address':'605874, EGL - SOWETO, THABISO MOTLHAOLENG, MGWABA DISTRIBUTOR, 7508 ZONE 07 ZONKIZIZWE, ZONKIZIZWE, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.39125,'lng':28.19607}},
{'stp':605900,'address':'605900, EGL - SOWETO, THABISO MOTLHAOLENG, ZOMBAS TUCKSHOP, 3214 EXT 9 BLOCK D, KATLEHONG SOUTH, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38100,'lng':28.19370}},
{'stp':605903,'address':'605903, EGL - SOWETO, THABISO MOTLHAOLENG, SOMLANDELA TUCKSHOP, 8690 SUNRISE, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38860,'lng':28.19930}},
{'stp':605934,'address':'605934, EGL - SOWETO, THABISO MOTLHAOLENG, MAPULES PLACE, 106 MBIZI STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37662,'lng':28.20468}},
{'stp':605964,'address':'605964, EGL - SOWETO, THABISO MOTLHAOLENG, NDOFIRES TAVERN, 6570 ZONKIZIZWE EXT 2  SUNRISE, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38540,'lng':28.19900}},
{'stp':606462,'address':'606462, EGL - SOWETO, THABISO MOTLHAOLENG, SBALI TAVERN, 1310 NDOBE STREET, MAILULA PARK, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36669,'lng':28.18581}},
{'stp':627821,'address':'627821, EGL - SOWETO, THABISO MOTLHAOLENG, BINCAS PLACE PERMIT, 62 SAMPORO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.38381,'lng':28.20546}},
{'stp':628209,'address':'628209, EGL - SOWETO, THABISO MOTLHAOLENG, STEVES RATANANG TAVERN, 1342 MATHEMA STREET, MAILULA PARK, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36750,'lng':28.18690}},
{'stp':628704,'address':'628704, EGL - SOWETO, THABISO MOTLHAOLENG, MOLAPO BUTCHERY PERMIT, 224 KGOPANE STREET, MAILULA PARK, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37060,'lng':28.19060}},
{'stp':628758,'address':'628758, EGL - SOWETO, THABISO MOTLHAOLENG, SFISOS PALACE PERMIT, 472 PHUPHU STREET, MABUYA PARK, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35827,'lng':28.18475}},
{'stp':629256,'address':'629256, EGL - SOWETO, THABISO MOTLHAOLENG, LUCY S PLACE, 21177 EXT 20, AQUERIUS STREET, #, VOSLOORUS, South Africa','geo':{'lat':-26.37560,'lng':28.18440}},
{'stp':629412,'address':'629412, EGL - SOWETO, THABISO MOTLHAOLENG, BLACK MOVE INN PERMIT, 179  IPALANGA STREET EXT 8, ROCKVIL, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36636,'lng':28.19983}},
{'stp':630829,'address':'630829, EGL - SOWETO, THABISO MOTLHAOLENG, TEBOGOS PLACE, 17465 MONAMANE STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36070,'lng':28.22900}},
{'stp':631107,'address':'631107, EGL - SOWETO, THABISO MOTLHAOLENG, AUSI NAKIS LIQUOR STORE, BIERMAN RD, M  BOTHA DRIVE EXT 29, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34290,'lng':28.20930}},
{'stp':631643,'address':'631643, EGL - SOWETO, THABISO MOTLHAOLENG, AMERICAN TAVERN, 498 TEMA STREET EXT 3, MAILULA PARK, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37310,'lng':28.18770}},
{'stp':635532,'address':'635532, EGL - SOWETO, THABISO MOTLHAOLENG, SOLOMON\'S TAVERN, 021355 EXTENTION 20, MAILULA PARK, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37500,'lng':28.18590}},
{'stp':636087,'address':'636087, EGL - SOWETO, THABISO MOTLHAOLENG, SOCCER TUCKSHOP, 108 MBABANE & TSHANENI, EXT 28 VOSLOORUS, #, VOSLOORUS, South Africa','geo':{'lat':-26.37929,'lng':28.19600}},
{'stp':637032,'address':'637032, EGL - SOWETO, THABISO MOTLHAOLENG, MALUANA TAVERN, 20907 1MAUNO STREET, VOSLOORUS EXT 28, BOKSBURG, VOSLOORUS, South Africa','geo':{'lat':-26.37191,'lng':28.19442}},
{'stp':641432,'address':'641432, EGL - SOWETO, THABISO MOTLHAOLENG, UBISI TAVERN, 121 PORTION 50, EXT 28, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.38222,'lng':28.20494}},
{'stp':647337,'address':'647337, EGL - SOWETO, THABISO MOTLHAOLENG, NOMSA\'S PLACE, 20925 EXTENSION 28, ERF, 293 ZIMBABWE STREET, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37841,'lng':28.20463}},
{'stp':648069,'address':'648069, EGL - SOWETO, THABISO MOTLHAOLENG, MATLALA S TAVERN, 1642 PHULENG CRESCENT, MAILULA PARK, EXTENSION 3, VOSLOORUS, South Africa','geo':{'lat':-26.36730,'lng':28.19030}},
{'stp':648886,'address':'648886, EGL - SOWETO, THABISO MOTLHAOLENG, FONTANA TAVERN, 1650 MOLELEKI SECTION EXT 3, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38308,'lng':28.18576}},
{'stp':651185,'address':'651185, EGL - SOWETO, THABISO MOTLHAOLENG, NKOSI AND SONS, 2238 KATLEHONG SOUTH, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37920,'lng':28.18270}},
{'stp':651734,'address':'651734, EGL - SOWETO, THABISO MOTLHAOLENG, CAMBRIDGE FOOD PTY LTD VOSLOORUS LI, M C BOTHA DRIVE AND IDUMBE STS, VOSLOORUS SHOPPING CENTRE SHP NUMBER 2, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34320,'lng':28.20680}},
{'stp':653859,'address':'653859, EGL - SOWETO, THABISO MOTLHAOLENG, LETHABONGS PALACE, 21183 AQUARIAS STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37600,'lng':28.18427}},
{'stp':654446,'address':'654446, EGL - SOWETO, THABISO MOTLHAOLENG, HARARE TAVERN, 4220 ZEKWA ROAD, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36250,'lng':28.20830}},
{'stp':654469,'address':'654469, EGL - SOWETO, THABISO MOTLHAOLENG, TMS PLACE, 159 MBANDE STREET, PORTION 27 PHASE 2, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37880,'lng':28.19890}},
{'stp':654829,'address':'654829, EGL - SOWETO, THABISO MOTLHAOLENG, PASSAGE BEER GARDENS, 473 ISICHWE STREET, VOSLOORUS EXT 7, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36300,'lng':28.19510}},
{'stp':661781,'address':'661781, EGL - SOWETO, THABISO MOTLHAOLENG, PUB IN DIE VLEI (NTO), 54 STADEBAKER STREET, EDENPARK, EDENPARK, EDEN PARK, South Africa','geo':{'lat':-26.37527,'lng':28.12605}},
{'stp':664696,'address':'664696, EGL - SOWETO, THABISO MOTLHAOLENG, ZAINABS INN, 5076 PALM RIDGE, EXTENSION 4, ALBERTON, PALM RIDGE, South Africa','geo':{'lat':-26.38520,'lng':28.13850}},
{'stp':668839,'address':'668839, EGL - SOWETO, THABISO MOTLHAOLENG, ZINYOKA\'S TAVERN, 3259 KATLEHONG, EXTENSION 9, KATLEHONG SOUTH, KATLEHONG, South Africa','geo':{'lat':-26.37948,'lng':28.18967}},
{'stp':680394,'address':'680394, EGL - SOWETO, THABISO MOTLHAOLENG, NKOSIS TUCKSHOP, 194 NYEZANE STREET, EXT7, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36060,'lng':28.19470}},
{'stp':684297,'address':'684297, EGL - SOWETO, THABISO MOTLHAOLENG, MOSES INN, 14848 ZONKIZIZWE, EXT 3, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38715,'lng':28.20287}},
{'stp':686010,'address':'686010, EGL - SOWETO, THABISO MOTLHAOLENG, MASINGAS LIQUOR AND MINERALS, 3464 KATLEHONG SOUTH, EXT 9, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38100,'lng':28.19180}},
{'stp':689037,'address':'689037, EGL - SOWETO, THABISO MOTLHAOLENG, PHINDIS PLACE, 42 MUBONDO, EXT 28, PORTION 53, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.38326,'lng':28.20555}},
{'stp':692241,'address':'692241, EGL - SOWETO, THABISO MOTLHAOLENG, KING GEORGE\'S TAVERN, 2966 STAND, KATLEHONG SOUTH, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.38157,'lng':28.18681}},
{'stp':698326,'address':'698326, EGL - SOWETO, THABISO MOTLHAOLENG, LIQUOR CITY CHRIS HANI, CNR BIERMAN & BRICKFIELD RD, SHOP 50 EXT 31,  VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34410,'lng':28.18440}},
{'stp':709083,'address':'709083, EGL - SOWETO, THABISO MOTLHAOLENG, SINQOBILE LIQUOR TRADERS, 1 MOGWERE STREET NO, PORTION 18 EXT 28 PHASE 1, #, VOSLOORUS, South Africa','geo':{'lat':-26.37291,'lng':28.20592}},
{'stp':721005,'address':'721005, EGL - SOWETO, THABISO MOTLHAOLENG, SABELO AND P TAVERN, PORTION 103 OF ERF 20906, 103 SHAKAWE STREET,PHASE 6B, EXT 28, VOSLOORUS, South Africa','geo':{'lat':-26.37160,'lng':28.19610}},
{'stp':726101,'address':'726101, EGL - SOWETO, THABISO MOTLHAOLENG, EKONENI BOTTLE STORE, ERF 24 MOAGI STREET EXT 4, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36342,'lng':28.20762}},
{'stp':747831,'address':'747831, EGL - SOWETO, THABISO MOTLHAOLENG, MLIKOS TAVERN, ERF 2649 MOLELEKI EXT  9, KATLEHONG EXT 9, KATLEHONG EXT 9, KATLEHONG, South Africa','geo':{'lat':-26.38553,'lng':28.18916}},
{'stp':755652,'address':'755652, EGL - SOWETO, THABISO MOTLHAOLENG, MAZOHS PLACE, 20927 VOSLOORUS, EXTENSION 28, #, VOSLOORUS, South Africa','geo':{'lat':-26.37617,'lng':28.20133}},
{'stp':758566,'address':'758566, EGL - SOWETO, THABISO MOTLHAOLENG, MPILISWENI TAVERN, 2942 GREEN MAMBA STREET, KATLEHONG SOUTH, #, KATLEHONG, South Africa','geo':{'lat':-26.38084,'lng':28.18454}},
{'stp':769116,'address':'769116, EGL - SOWETO, THABISO MOTLHAOLENG, TOP ONE LIQUOR DISTRIBUTORS, 14919 BIERMAN ROAD, EXT 31 VOSLOORUS, #, VOSLOORUS, South Africa','geo':{'lat':-26.34410,'lng':28.18617}},
{'stp':771243,'address':'771243, EGL - SOWETO, THABISO MOTLHAOLENG, GOLD SPOT OVERLAND VOSLOORUS, STAND NO, ERF 19935 CORNER BIERMAN AND, NOMBHELA STREET EXT 29, VOSLOORUS, South Africa','geo':{'lat':-26.34188,'lng':28.21533}},
{'stp':776349,'address':'776349, EGL - SOWETO, THABISO MOTLHAOLENG, VOSLOORUS TOPS LIQUOR STORE, STAND NO, ERF 18381  SHOP 1 SPAR SHOPPING CENTRE, CORNER SEKOATI AND BARRY MARAIS, VOSLOORUS, South Africa','geo':{'lat':-26.34300,'lng':28.21770}},
{'stp':780296,'address':'780296, EGL - SOWETO, THABISO MOTLHAOLENG, SPENZA S TAVERN, STAND NO, ERF 7172, ZONKIZIZWE EXTENSION 2, KATLEHONG, South Africa','geo':{'lat':-26.39061,'lng':28.19550}},
{'stp':782836,'address':'782836, EGL - SOWETO, THABISO MOTLHAOLENG, MAKUBUNG TAVERN, THULO STREET, ERF 2730, MOLELEKI SECTION EXTENSION 3, KATLEHONG, South Africa','geo':{'lat':-26.38093,'lng':28.18433}},
{'stp':787527,'address':'787527, EGL - SOWETO, THABISO MOTLHAOLENG, LERATOLAKA TAVERN, 2668 STAND NO, KATLEHONG  EXT 9, #, KATLEHONG, South Africa','geo':{'lat':-26.37890,'lng':28.18580}},
{'stp':797180,'address':'797180, EGL - SOWETO, THABISO MOTLHAOLENG, SELEPES PLACE, 2107 KHOTSO STREET, MOLELEKI EXTENSION 3, #, KATLEHONG, South Africa','geo':{'lat':-26.38542,'lng':28.18932}},
{'stp':311394,'address':'311394, EGL - SOWETO, THABISO MOTLHAOLENG, ROCCO MAMAS RESTAURANT MEYERSDAL (N, SHOP 23DE MARIONETTE SHOPPING CENTR, CNR BLUECRANE & MICHELLE AVENUES, MEYERSDAL, ALBERTON, South Africa','geo':{'lat':-26.28713,'lng':28.09549}},
{'stp':384674,'address':'384674, EGL - SOWETO, THABISO MOTLHAOLENG, TAXI RANK SPORTSBAR, 3 SHOP NO, PARK CENTRE NUMBER 40, PADSTOW STREET, ALBERTON, South Africa','geo':{'lat':-26.27308,'lng':28.12349}},
{'stp':388207,'address':'388207, EGL - SOWETO, THABISO MOTLHAOLENG, LOK FU CHINESE & SUSHI RESTAURANT (, SHOP NO. 22 MEYERSDAL MALL, MICHELLE AVENUE, MEYERSDAL, ALBERTON, South Africa','geo':{'lat':-26.28980,'lng':28.09110}},
{'stp':389083,'address':'389083, EGL - SOWETO, THABISO MOTLHAOLENG, THE CIGAR LOUNGE (NTO), ERF: 467, SHOP NO:2, ALBERANTE BUILDING, NO:31 GENERAL, ALBERTS STREET, RANDHART, ALBERTON, South Africa','geo':{'lat':-26.28673,'lng':28.11576}},
{'stp':390165,'address':'390165, EGL - SOWETO, THABISO MOTLHAOLENG, SOUTH AFRICAN CUISINE, 03 SHOP NO, MAYBERRY PARK SHOPPING CENTRE, CNR JG STRYDOM AND KERSHOUT STREET, ALBERTON, South Africa','geo':{'lat':-26.32034,'lng':28.12145}},
{'stp':391484,'address':'391484, EGL - SOWETO, THABISO MOTLHAOLENG, E LIQUOR EXPRESS VILLAGE SQUARE, 646 ERF NO 644, SHOP NO 07 VILLAGE SQUARE S CENTRE NO 46, VOORTREKKER, ALBERTON, South Africa','geo':{'lat':-26.26712,'lng':28.11974}},
{'stp':393196,'address':'393196, EGL - SOWETO, THABISO MOTLHAOLENG, ICE HYPER LIQUORS, 274 ERF NO, STREET NO 45678 VOORTREKKER, ALBERTON JOHANNESBURG, KATLEHONG, South Africa','geo':{'lat':-26.24999,'lng':28.11020}},
{'stp':467219,'address':'467219, EGL - SOWETO, THABISO MOTLHAOLENG, MZANSI LIQUORS ALBERTON, 50 VOORTREKKER ROAD, SHOP NO 9, NEW REDRUTH ERF 996, ALBERTON, South Africa','geo':{'lat':-26.26807,'lng':28.12187}},
{'stp':470940,'address':'470940, EGL - SOWETO, THABISO MOTLHAOLENG, CHECKERS LIQUORSHOP 69230 NEW MARKE, 1 HEIDELBERG ROAD, CNR SWARTKOPPIES & RING ROAD EAST, NEW MARKET EXTENSION 15 ERF 248, ALBERTON, South Africa','geo':{'lat':-26.28134,'lng':28.12396}},
{'stp':475675,'address':'475675, EGL - SOWETO, THABISO MOTLHAOLENG, CASA MINHA, 1144 ERF, OLIVE TREE, GARDEN CENTRE, MULBARTON, South Africa','geo':{'lat':-26.30174,'lng':28.05208}},
{'stp':477740,'address':'477740, EGL - SOWETO, THABISO MOTLHAOLENG, MS BOTTLE STORE, ERF 449 SHOP NO 4, 38 GARFIELD STREET, GARMAVILLE CENTRE, CNR POTGIETER STREET, ALBERTON, South Africa','geo':{'lat':-26.30054,'lng':28.11770}},
{'stp':483152,'address':'483152, EGL - SOWETO, THABISO MOTLHAOLENG, TIN CUP RESTAURANT AND DRIVING RANG, 101 NO SWARTKOPPIES ROAD, SWARTKOPPIES ROAD, JOHANNESBURG SOUTH, ALBERTON, South Africa','geo':{'lat':-26.30094,'lng':28.11745}},
{'stp':483794,'address':'483794, EGL - SOWETO, THABISO MOTLHAOLENG, PANAROTTIS NEW MARKET MALL, 248 ERF, SHOP NO 43, NEWMARKET MALL, ALBERTON, South Africa','geo':{'lat':-26.27888,'lng':28.12549}},
{'stp':484422,'address':'484422, EGL - SOWETO, THABISO MOTLHAOLENG, LITTLE EAGLE SPUR NEWMARKET, STAND NO, ERF 248 NEWMARKET PARK, SHOP 41 NEWMARKET MALL CNR ASCOT AND, ALBERTON, South Africa','geo':{'lat':-26.27730,'lng':28.12740}},
{'stp':485103,'address':'485103, EGL - SOWETO, THABISO MOTLHAOLENG, JOHN DORY S NEW MARKET, STAND NO, ERF248 SHOP 38, NEW MARKET MALL CORNER OF ASCOT, ALBERTON, South Africa','geo':{'lat':-26.27846,'lng':28.12504}},
{'stp':486772,'address':'486772, EGL - SOWETO, THABISO MOTLHAOLENG, ALRODE LIQUOR CITY, STAND NO, ERF 462 ALRODE CENTRE NO 1, BENTONIE STREET CRNR, ALBERTON, South Africa','geo':{'lat':-26.31807,'lng':28.13201}},
{'stp':487159,'address':'487159, EGL - SOWETO, THABISO MOTLHAOLENG, APACHE S BAR AND NIGHT CLUB, 0025 STAND NO, FLAMINK STREET ALRODE, ALRODE EXTENSION 7, ALBERTON, South Africa','geo':{'lat':-26.32577,'lng':28.12668}},
{'stp':488905,'address':'488905, EGL - SOWETO, THABISO MOTLHAOLENG, CHICKEN WORLD (NTO), 4 GLENANDA VILLAGE SHOP CENTRE, CNR VORSTER AND GLEN ROADS, TSWANE, GLENVISTA, South Africa','geo':{'lat':-26.27183,'lng':28.03814}},
{'stp':488906,'address':'488906, EGL - SOWETO, THABISO MOTLHAOLENG, GOLD RUSH NEW MARKET MALL (NTO), ERF 248 SHOP 26 NEWMARKET MALL, CNR ASCOT AND HEIDELBERG RD, JOHANNESBURG, ALBERTON, South Africa','geo':{'lat':-26.28007,'lng':28.12613}},
{'stp':489783,'address':'489783, EGL - SOWETO, THABISO MOTLHAOLENG, PIZZA DEL FORNO MEYERSDAL (NTO), SHOP NR 6  DE MARIONETTE SHOPPING C, CNR MICHELLE & BLUE CRANE, MEYERSDAL, ALBERTON, South Africa','geo':{'lat':-26.28620,'lng':28.09545}},
{'stp':491172,'address':'491172, EGL - SOWETO, THABISO MOTLHAOLENG, PNP GC98-PNP LIQUOR STORE(ALBERTON, STAND NO, ALBERTON SHOPPING CENTRE, CNR OF VOORTREKKER AND DU PLESSIS ROAD, ALBERTON, South Africa','geo':{'lat':-26.26347,'lng':28.12216}},
{'stp':491517,'address':'491517, EGL - SOWETO, THABISO MOTLHAOLENG, ROCCOMAMAS RESTAURANT - NEW MARKET, SHOP 27&28 NEWMARKET MALL S.C., NEWMARKET, #, ALBERTON, South Africa','geo':{'lat':-26.27784,'lng':28.12482}},
{'stp':550085,'address':'550085, EGL - SOWETO, THABISO MOTLHAOLENG, ALBERTON TENNIS CLUB, CORNER 2ND AVE & DU PLESSIS RD, FLORENTIA, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26765,'lng':28.12491}},
{'stp':550188,'address':'550188, EGL - SOWETO, THABISO MOTLHAOLENG, ALBERTON BOWLING CLUB, CNR 2DE LAAN & PADSTOW STREET, FLORENTIA, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.27190,'lng':28.13030}},
{'stp':550390,'address':'550390, EGL - SOWETO, THABISO MOTLHAOLENG, TUDOR PUB AND GRILL, CNR ABEL MOLLER AND VERMOOTENS STRE, BRACKENHURST, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.30990,'lng':28.10690}},
{'stp':550449,'address':'550449, EGL - SOWETO, THABISO MOTLHAOLENG, 19 TH HOLE SPORTS BAR, STAND NUMBER, SHOP NUMBER 18, 2ND AVENUE SHOPPING CENTRE, ALBERTON, South Africa','geo':{'lat':-26.27420,'lng':28.14370}},
{'stp':550491,'address':'550491, EGL - SOWETO, THABISO MOTLHAOLENG, LIQUOR CITY BASSONIA, CNR COMARO & SOETDORING AVENUE, BASSONIA, GLENVISTA, GLENVISTA, South Africa','geo':{'lat':-26.27850,'lng':28.06710}},
{'stp':550664,'address':'550664, EGL - SOWETO, THABISO MOTLHAOLENG, GLENVISTA BUITEKLUB, KARRUSBURG STREET, GLENVISTA, GLENVISTA, GLENVISTA, South Africa','geo':{'lat':-26.28240,'lng':28.05690}},
{'stp':550704,'address':'550704, EGL - SOWETO, THABISO MOTLHAOLENG, MIMMOS MEYERSDAL, JOHAN VAN DER MERWE DRIVE, MEYERSDAL, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.29060,'lng':28.09070}},
{'stp':550708,'address':'550708, EGL - SOWETO, THABISO MOTLHAOLENG, HARBOUR FISH AND GRILL, STAND NO 2055 SHOP NO 39, MEYERSDAL MANN, CORNER HENNIE ALBERTS AND MICHELLE AVENU, ALBERTON, South Africa','geo':{'lat':-26.29060,'lng':28.09070}},
{'stp':550748,'address':'550748, EGL - SOWETO, THABISO MOTLHAOLENG, OASIS PUB AND BISTRO, SHOP NO 2 AND 4, CNR ONYX & DOLPHINIAN ROADS, STAND 13 MAYBERRY PARK, ALBERTON, South Africa','geo':{'lat':-26.33180,'lng':28.11640}},
{'stp':550829,'address':'550829, EGL - SOWETO, THABISO MOTLHAOLENG, SHOPRITE LIQUORSHIP ALBERTON NORTH, 2 53 CNR, PIETER UYS AVE & KRITZINGER ROAD, FLORENTIA, ALBERTON, South Africa','geo':{'lat':-26.26090,'lng':28.13210}},
{'stp':551042,'address':'551042, EGL - SOWETO, THABISO MOTLHAOLENG, ACTION SPORTS MEYERSDAL, CNR MITCHELL & JOCHEM VAN BRUGGEN, RANDHART, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.28750,'lng':28.10340}},
{'stp':551261,'address':'551261, EGL - SOWETO, THABISO MOTLHAOLENG, GLENVISTA LIQUORS, 184-200 BIGGARSBERG ROAD, GLENVISTA, GLENVISTA, GLENVISTA, South Africa','geo':{'lat':-26.28600,'lng':28.05380}},
{'stp':551435,'address':'551435, EGL - SOWETO, THABISO MOTLHAOLENG, THE PROMISE PUB AND GRILL, MICHELLE AVENUE, 40 MEYERSDAL MALL, MEYERSDAL, ALBERTON, South Africa','geo':{'lat':-26.29060,'lng':28.09050}},
{'stp':551463,'address':'551463, EGL - SOWETO, THABISO MOTLHAOLENG, PARKLANDS EATING HOUSE, CNR 54 PARKLANDS DRIVE & PRINSLOO S, ALBERTON, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.25551,'lng':28.13511}},
{'stp':551515,'address':'551515, EGL - SOWETO, THABISO MOTLHAOLENG, RIODIZIO TA CHURRASCO, 92 LETABA STREET, BRACKENDOWNS, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.34680,'lng':28.09550}},
{'stp':551631,'address':'551631, EGL - SOWETO, THABISO MOTLHAOLENG, MANGO MOON, 29 EIKA AVENUE, GEN ALBERTS PARK, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.29150,'lng':28.12400}},
{'stp':551673,'address':'551673, EGL - SOWETO, THABISO MOTLHAOLENG, ALBERTON CRICKET CLUB, SECTION 379 ELANDSFONTEIN, GREWAR PARK, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.27100,'lng':28.12860}},
{'stp':551808,'address':'551808, EGL - SOWETO, THABISO MOTLHAOLENG, PICK N PAY BRACKEN CITY, CNR ROY CAMBELL HENNIE ALBERTS, & RAE FRANKEL STS,BRACKENHURST, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.31490,'lng':28.09750}},
{'stp':551901,'address':'551901, EGL - SOWETO, THABISO MOTLHAOLENG, ALRODE SOUTH INN, CNR BOSWORTH & DELFOS STREET, ALRODE, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.34430,'lng':28.12780}},
{'stp':551932,'address':'551932, EGL - SOWETO, THABISO MOTLHAOLENG, SHOPRITE LIQUORSHOP ALBERTON 89317, STAND NO, CNR WILLIAM DABS AND OLD PAARL ROAD, BRACKENFALL, ALBERTON, South Africa','geo':{'lat':-26.26890,'lng':28.12070}},
{'stp':551986,'address':'551986, EGL - SOWETO, THABISO MOTLHAOLENG, RETSOS LIQUOR, JACQUELINE MALL VENTER ST, RANDHART, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.29710,'lng':28.11620}},
{'stp':552014,'address':'552014, EGL - SOWETO, THABISO MOTLHAOLENG, BRACKENHURST VINEYARD LIQUOR CELLAR, CNR ABEL MOLLER & VERMOOTEN STR, BRACKENHURST, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.30960,'lng':28.10730}},
{'stp':552036,'address':'552036, EGL - SOWETO, THABISO MOTLHAOLENG, SPUR STEAK RANCH TA LITTLE EAGLE, CNR SWARTKOPPIES AND LINCOLN ROAD, ALBERTON, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.28040,'lng':28.12400}},
{'stp':552306,'address':'552306, EGL - SOWETO, THABISO MOTLHAOLENG, TIME OUT POOL CLUB, 4 MASSELSPOORT STREET, BRACKENDOWNS, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.33970,'lng':28.09550}},
{'stp':552373,'address':'552373, EGL - SOWETO, THABISO MOTLHAOLENG, STUMBLE INN, PORTION 1 OF ERF 571, 66A SEVENTH AVENUE, ALBERTON NORTH, ALBERTON, South Africa','geo':{'lat':-26.25930,'lng':28.12360}},
{'stp':552385,'address':'552385, EGL - SOWETO, THABISO MOTLHAOLENG, MACYS SPAR AND TOPS, STAND NO, SHOP 1 AND 2 RANHART SHOPPING CENTRE, GENERAL ALBERTS AVENUE, ALBERTON, South Africa','geo':{'lat':-26.28720,'lng':28.11620}},
{'stp':552418,'address':'552418, EGL - SOWETO, THABISO MOTLHAOLENG, LOCO LIQUOR BRACKENDOWNS, CR DELPHINIUM & KALKOENTJIE RD, BRACKENHURST, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.32730,'lng':28.09920}},
{'stp':552555,'address':'552555, EGL - SOWETO, THABISO MOTLHAOLENG, BRACKENHURST BOWLING CLUB, CNR PRINCE ALBERT STREET, BRACKENHURST, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.31425,'lng':28.11027}},
{'stp':552789,'address':'552789, EGL - SOWETO, THABISO MOTLHAOLENG, FOO WAH CHINESE RESTAURANT, CNR VOSTER DRIVE AND  LE ROUX STR, GLENANDA, GLENANDA, GLENVISTA, South Africa','geo':{'lat':-26.27190,'lng':28.03840}},
{'stp':552837,'address':'552837, EGL - SOWETO, THABISO MOTLHAOLENG, LIQUOR CITY BRACKENGATE, 1774 CNR ANDRIES & DE WAAL STREET, BRACKENDOWNS EXT 2, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.33980,'lng':28.09560}},
{'stp':552856,'address':'552856, EGL - SOWETO, THABISO MOTLHAOLENG, PICK N PAY MULBARTON GF29, CORNER KLIPRIVER AND JORDI ROAD, SHOP NO A2 PANORAMA SHOP AND LEISURE, MULBERTON EXTENSION 7, GLENVISTA, South Africa','geo':{'lat':-26.29660,'lng':28.04630}},
{'stp':552967,'address':'552967, EGL - SOWETO, THABISO MOTLHAOLENG, ALBERTON SQUASH CLUB, REM OF ERF 947, FLORENTIA X01, REM OF PORTION 379 ELANDSFONTEIN, ALBERTON, South Africa','geo':{'lat':-26.26910,'lng':28.12740}},
{'stp':553013,'address':'553013, EGL - SOWETO, THABISO MOTLHAOLENG, SLABBERTS BOTTLE STORE, 28 C VOORTREKKER ROAD, NEW REDRUTH, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26470,'lng':28.12150}},
{'stp':553292,'address':'553292, EGL - SOWETO, THABISO MOTLHAOLENG, YZZIES POOL CLUB, SHOP 8 & 9, CNR VAAL & BENDORE STREETS, BRACKENDOWNS, ALBERTON, South Africa','geo':{'lat':-26.33890,'lng':28.08530}},
{'stp':553335,'address':'553335, EGL - SOWETO, THABISO MOTLHAOLENG, ALRODE LIQUORS AND TAVERN, 1 BENTONITE STREET, ALRODE, ALBERTON, TOKOZA, South Africa','geo':{'lat':-26.31810,'lng':28.13220}},
{'stp':553751,'address':'553751, EGL - SOWETO, THABISO MOTLHAOLENG, HOTSPOT LIQUOR STORE, 50 VAN RIEBEEK AVENUE, ALBERTON, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.25900,'lng':28.12800}},
{'stp':607125,'address':'607125, EGL - SOWETO, THABISO MOTLHAOLENG, ALBERTON RUGBY CLUB SPORTSBAR, CORNER SECOND AVENUE & BRAUN AVENUE, VERWOERDPARK, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.27650,'lng':28.13260}},
{'stp':619912,'address':'619912, EGL - SOWETO, THABISO MOTLHAOLENG, ALBERTON SOCCER CLUB (NTO), BRAUN STREET, ALBERTON, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.27748,'lng':28.13557}},
{'stp':625845,'address':'625845, EGL - SOWETO, THABISO MOTLHAOLENG, GLENANDA SPORTS BETTING, 3A SHOP, #, GLENANDA VILLAGE SHOPPING CENTRE, GLENVISTA, South Africa','geo':{'lat':-26.27223,'lng':28.03850}},
{'stp':626113,'address':'626113, EGL - SOWETO, THABISO MOTLHAOLENG, IL FORNO MEDITARANEAN, 11 STAND NO, DION CENTRE, NEW REDRUTH EXT 1, ALBERTON, South Africa','geo':{'lat':-26.26060,'lng':28.11840}},
{'stp':630516,'address':'630516, EGL - SOWETO, THABISO MOTLHAOLENG, BUSINESS BREAK, 7 KRITZINGER STREET, FLORENTIA, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26050,'lng':28.13110}},
{'stp':631072,'address':'631072, EGL - SOWETO, THABISO MOTLHAOLENG, ROCKY PEAK SPUR, CNR VERMOOTEN & MCBRIDE STREETS, ALBERTON, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.30930,'lng':28.10890}},
{'stp':631728,'address':'631728, EGL - SOWETO, THABISO MOTLHAOLENG, LIQUOR CITY ALBERTON, CNR SWARTKOPPIES AND HEIDELBERG RD, SHOP NO 12 LEMON TREE, CNR SHORTKOPPIES AND HEILDERBERG RD, ALBERTON, South Africa','geo':{'lat':-26.28115,'lng':28.12370}},
{'stp':632423,'address':'632423, EGL - SOWETO, THABISO MOTLHAOLENG, 2ND AVENUE LIQUORS STORE, SHOP NO 9, RAND CITY SHOPPING CENTRE, CORNER OF SECOND AVENUE AND BRAUN ROAD, ALBERTON, South Africa','geo':{'lat':-26.27430,'lng':28.14370}},
{'stp':634132,'address':'634132, EGL - SOWETO, THABISO MOTLHAOLENG, ADEGA RESTAURANT ALBERTON, CNR ST AUSTELL AND CAMBORNE RD, NEW REDRUTH VILLAGE, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26160,'lng':28.11340}},
{'stp':636029,'address':'636029, EGL - SOWETO, THABISO MOTLHAOLENG, PICK N PAY REDRUTH, MCKINNON & AUSTELL STS, NEW REDRUTH, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26170,'lng':28.11630}},
{'stp':647370,'address':'647370, EGL - SOWETO, THABISO MOTLHAOLENG, BOSVELD LIQUOR INN, CNR DU PLESSIS AND SECOND AVENUE, FLORENTIA, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26561,'lng':28.12839}},
{'stp':648640,'address':'648640, EGL - SOWETO, THABISO MOTLHAOLENG, GINOS LIQUOR STORE, SOUTH SHOPPING CENTRE, CNR BENDOR AND VAAL STREETS, BRACKENDOWNS EXT 4 ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.33900,'lng':28.08500}},
{'stp':649272,'address':'649272, EGL - SOWETO, THABISO MOTLHAOLENG, MEYERSDAL TOPS, SHOP 9 AND 10, MEYERSDAL MALL CNR MIHELLE AVE AND, HENNIE ALBERTS STREET, ALBERTON, South Africa','geo':{'lat':-26.28980,'lng':28.09110}},
{'stp':649962,'address':'649962, EGL - SOWETO, THABISO MOTLHAOLENG, ALBERTON HYPER LIQUORS, VOORTREKKER AVE&PADTOW STS, NEW REDRUTH, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.27350,'lng':28.12230}},
{'stp':654440,'address':'654440, EGL - SOWETO, THABISO MOTLHAOLENG, KIPLING LIQUOR STORE, 12 KIPLING STREET, ERF 391 RACEVIEW, RANDHURT, ALBERTON, South Africa','geo':{'lat':-26.27830,'lng':28.11370}},
{'stp':654505,'address':'654505, EGL - SOWETO, THABISO MOTLHAOLENG, PIATTO ALBERTON (NTO), CNR ST AUSTELL & CAMBUORNE RDS, NEW REDRUTH VILLAGE, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26264,'lng':28.11299}},
{'stp':656102,'address':'656102, EGL - SOWETO, THABISO MOTLHAOLENG, SPIRITS PUB AND GRILL, NO 73 CHARL CILLIERS STREET, ERF 118, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26270,'lng':28.12460}},
{'stp':668375,'address':'668375, EGL - SOWETO, THABISO MOTLHAOLENG, BALL BREAKERS PUB (NTO), SHOP 5 DE MARIONETTE SHOPPING CENT, MEYERSDAL, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.28855,'lng':28.09405}},
{'stp':668376,'address':'668376, EGL - SOWETO, THABISO MOTLHAOLENG, MIKES GETAWAY (NTO), 20 LOUIS TRICHARDT STREET, ALBERTON NORTH, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26159,'lng':28.12803}},
{'stp':669455,'address':'669455, EGL - SOWETO, THABISO MOTLHAOLENG, PIATO BASSONIA (NTO), TOP FLOOR CAMARO VIEW CENTER, CORNER CAMARO STREET, BASSONIA, GLENVISTA, South Africa','geo':{'lat':-26.27577,'lng':28.06378}},
{'stp':669491,'address':'669491, EGL - SOWETO, THABISO MOTLHAOLENG, HOLLYWOOD TAB (NTO), CNR HEIDELBERG &ELANDSFONTEIN RD, ALBERTON, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.28095,'lng':28.12868}},
{'stp':670694,'address':'670694, EGL - SOWETO, THABISO MOTLHAOLENG, CHICKEN BASKET, 184 BIGGARSBERG ROAD, ERF 2792 SHOP U36 GLENVISTA SHOPPING CEN, GLENVISTA EXT 5, GLENVISTA, South Africa','geo':{'lat':-26.28560,'lng':28.05290}},
{'stp':684422,'address':'684422, EGL - SOWETO, THABISO MOTLHAOLENG, VERWOERDPARK LIQUORS, 54 KRITZINGER STREET, VERWOERDPARK, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26540,'lng':28.14070}},
{'stp':687262,'address':'687262, EGL - SOWETO, THABISO MOTLHAOLENG, NEVIS LIQUOR STORE, CNR ONYX & DELPHINIUM RDS, SHP 8 CALHE CNTR,MAYBERRY PARK, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.33180,'lng':28.11640}},
{'stp':688941,'address':'688941, EGL - SOWETO, THABISO MOTLHAOLENG, RATTLESNAKES (NTO), GENERAL ALBERT STREET 29, RANDHART, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.28750,'lng':28.11570}},
{'stp':689590,'address':'689590, EGL - SOWETO, THABISO MOTLHAOLENG, LIQUOR CITY APPLE TREE, 2-3 SHOP NO, ALBERTON VALUE CENTRE, ST AUSTELL STREET CNR RING ROAD WEST, ALBERTON, South Africa','geo':{'lat':-26.26080,'lng':28.11610}},
{'stp':689900,'address':'689900, EGL - SOWETO, THABISO MOTLHAOLENG, MANNYS POOL CLUB CC, STAND NO 404, 64 VAN RIEBEECK AVENUE, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26210,'lng':28.12650}},
{'stp':706693,'address':'706693, EGL - SOWETO, THABISO MOTLHAOLENG, NEW MARKET DRIVING RANGE, 102 SECOND AVENUE, BEING PORTION 372 AND 374 OF THE FARM, ELANDSFONTEIN 108 IR FLORENTIA, ALBERTON, South Africa','geo':{'lat':-26.27231,'lng':28.13264}},
{'stp':708319,'address':'708319, EGL - SOWETO, THABISO MOTLHAOLENG, READING COUNTRY CLUB, 40 FORES STREET, #, #, ALBERTON, South Africa','geo':{'lat':-26.26530,'lng':28.10870}},
{'stp':708421,'address':'708421, EGL - SOWETO, THABISO MOTLHAOLENG, CASA ROSSI, 52 VORSTER AND CNR GLEN AVENUE, ERF 528, VISTA PLACE, GLENVISTA, South Africa','geo':{'lat':-26.27490,'lng':28.03640}},
{'stp':709764,'address':'709764, EGL - SOWETO, THABISO MOTLHAOLENG, DEALZ LIQUOR, CNR KRITZINGER PIETERUYS & PARKLAND, FLORENTIA,  ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26130,'lng':28.13190}},
{'stp':726565,'address':'726565, EGL - SOWETO, THABISO MOTLHAOLENG, FAHREINHEIT SEAFOOD AND GRILL RESTA, 0025 STAND NO, FLAME CESCENT, DOWEGLEN, ALBERTON, South Africa','geo':{'lat':-26.28140,'lng':28.12310}},
{'stp':726919,'address':'726919, EGL - SOWETO, THABISO MOTLHAOLENG, LITTLE ITALY GLENVISTA (NTO), 30 JOAN ROAD, GLENANDA, GLENANDA, GLENVISTA, South Africa','geo':{'lat':-26.28750,'lng':28.05790}},
{'stp':727635,'address':'727635, EGL - SOWETO, THABISO MOTLHAOLENG, TURN N TENDER BASSONIA, SHOP NO FF2 UPPER LEVEL, CAMARO VIEW SHOPPING CENTER STD 5546, CAMARO ROAD STEENBOK AND SOETDORING STR, GLENVISTA, South Africa','geo':{'lat':-26.27902,'lng':28.06687}},
{'stp':737151,'address':'737151, EGL - SOWETO, THABISO MOTLHAOLENG, TAJ INDIAN RESTAURANT (NTO), 687 CNR ABEL MOLLER & MCBRIDE STS, BRACKENHURST, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.30860,'lng':28.10630}},
{'stp':747260,'address':'747260, EGL - SOWETO, THABISO MOTLHAOLENG, THE TURNING POINT (NTO), CNR BIGGERSBURG & SNEEUBERG STS, GLENVISTA, GLENVISTA, GLENVISTA, South Africa','geo':{'lat':-26.28660,'lng':28.05390}},
{'stp':756906,'address':'756906, EGL - SOWETO, THABISO MOTLHAOLENG, MAKRO ALBERTON, CNR ASCOT & HEIDELBERG RDS, NEWMARKET PARK EXT 15, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.28040,'lng':28.12960}},
{'stp':781917,'address':'781917, EGL - SOWETO, THABISO MOTLHAOLENG, NEWS CAFE MEYERSDAL, STAND NO, CORNER MICHELLE AVE SHOP NO 25, DE MARIONETTE SHOPPING CENTRE MEYERS, ALBERTON, South Africa','geo':{'lat':-26.28699,'lng':28.09702}},
{'stp':785843,'address':'785843, EGL - SOWETO, THABISO MOTLHAOLENG, EYAKHO CAR WASH RESTAURANT, 77 VOORTREKKER ROAD, ERVEN 824 AND 826 77 AND 79, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.27170,'lng':28.12232}},
{'stp':786737,'address':'786737, EGL - SOWETO, THABISO MOTLHAOLENG, OK LIQUOR STORE MAYBERRYPARK, STAND NO, ERF 1542 MAYBERY PARK SHOPPING CENTRE, CORNER JG STRIJDOOM AND KERBHOUT, ALBERTON, South Africa','geo':{'lat':-26.32034,'lng':28.12145}},
{'stp':789059,'address':'789059, EGL - SOWETO, THABISO MOTLHAOLENG, CHURRASQUEIRA RESTAURANT, 37 WEBB STREET, ERF 858 BRACKENHURST, #, ALBERTON, South Africa','geo':{'lat':-26.29060,'lng':28.09070}},
{'stp':797623,'address':'797623, EGL - SOWETO, THABISO MOTLHAOLENG, PAPA JOES PUB AND GRILL, MARITZ STRRET AND 2ND AVE, ERF 235 SHOPS 3  4 AND 5 PICKERS PLACE, ALBERTON NORTH, ALBERTON, South Africa','geo':{'lat':-26.25723,'lng':28.13048}},
{'stp':798458,'address':'798458, EGL - SOWETO, THABISO MOTLHAOLENG, GAME LIQUOR ALBERTON 425, STAND NO, C/O REDRUTH AND VOORTREKKER STREET, #, ALBERTON, South Africa','geo':{'lat':-26.26240,'lng':28.12221}},
{'stp':798543,'address':'798543, EGL - SOWETO, THABISO MOTLHAOLENG, TOTS LIQOURS, 1711 ERF, PORTION 3 OF ERF, 200 MICHELLE AVENUE, ALBERTON, South Africa','geo':{'lat':-26.28722,'lng':28.10533}},
{'stp':799931,'address':'799931, EGL - SOWETO, THABISO MOTLHAOLENG, MITZYS PUB, STAND NO, ERF 465 SHOPS 1 AND 2, 27 GENERAL ALBERTS AVENUE, ALBERTON, South Africa','geo':{'lat':-26.28701,'lng':28.11660}},
{'stp':314562,'address':'314562, EGL - SOWETO, THABISO MOTLHAOLENG, P AND S SPORTS BAR, 183 STAND NUMBER, NUMBER 3, ROMYN STREET, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.52914,'lng':28.36485}},
{'stp':315769,'address':'315769, EGL - SOWETO, THABISO MOTLHAOLENG, PNP VICTORIAN SHOPPING CENTRE GF91, 3523 STAND NO,  SHOP NO.3 VICTORIAN SHOPP. CENTRE, VICTORIAN CENTRE, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49540,'lng':28.35350}},
{'stp':320866,'address':'320866, EGL - SOWETO, THABISO MOTLHAOLENG, BACKSTAGE TAVERN, 6398 ERF NO, DENNE AVENUE, EXTENSION 26, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.54039,'lng':28.36240}},
{'stp':385598,'address':'385598, EGL - SOWETO, THABISO MOTLHAOLENG, MAVUSAS INN, 5637/99 ERF NO, NO 5637 NTOMBELA STREET, RATANDA EXT 23, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.53997,'lng':28.35620}},
{'stp':388731,'address':'388731, EGL - SOWETO, THABISO MOTLHAOLENG, AMAROK PUB, 4209 STAND NO ERF, DENNE AVENUE, #, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.53421,'lng':28.35841}},
{'stp':391022,'address':'391022, EGL - SOWETO, THABISO MOTLHAOLENG, MIRRIAMS TAVERN, 6619 STAND NO, MALENDELA STREET EXT 26, #, RATANDA, South Africa','geo':{'lat':-26.57059,'lng':28.32036}},
{'stp':392217,'address':'392217, EGL - SOWETO, THABISO MOTLHAOLENG, COOL CALM AND COLLECTED, 6498 ERF NO, MBOKAZI STREET HEIDELBERG, EXT 26, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.55472,'lng':28.32900}},
{'stp':392255,'address':'392255, EGL - SOWETO, THABISO MOTLHAOLENG, 1ST CORNER TAVERN, 780 STAND NO, SIKHUKHUNI  STREET, OBED MTHOMBENI, NKOSI  HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.55823,'lng':28.32603}},
{'stp':392631,'address':'392631, EGL - SOWETO, THABISO MOTLHAOLENG, MINKIES TAVERN, 6207 MNGQCOSIA STREET, EXT 7, RATANDA, RATANDA, South Africa','geo':{'lat':-26.56848,'lng':28.32168}},
{'stp':393344,'address':'393344, EGL - SOWETO, THABISO MOTLHAOLENG, LOVE CORNER, 1244 STAND NO, OLIVER TAMBO STREET, OBED MTHOMBENI NKOSI, RATANDA, South Africa','geo':{'lat':-26.54087,'lng':28.34151}},
{'stp':393345,'address':'393345, EGL - SOWETO, THABISO MOTLHAOLENG, E CAR WASH LIFESTYLE, 6049 ERF, MTHIMKHULU STREET, RATANDA EXTENSION 23, RATANDA, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':464872,'address':'464872, EGL - SOWETO, THABISO MOTLHAOLENG, SHOPRITE LIQOURSHOP HEIDELBERG, 01 STAND NO, ERF 3429, CORNER VOORTREKKER AND STRYDOM STREET, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.50033,'lng':28.35907}},
{'stp':466756,'address':'466756, EGL - SOWETO, THABISO MOTLHAOLENG, CATHYS PLACE, STAND NO, ERF 6334 ZAKES MACHITIJIE STREET, EXT 26, RATANDA, South Africa','geo':{'lat':-26.53936,'lng':28.36293}},
{'stp':470327,'address':'470327, EGL - SOWETO, THABISO MOTLHAOLENG, MASIBAMBANENI TAVERN, 4349 STAND NO, EXT 23 RATHANDA, #, RATANDA, South Africa','geo':{'lat':-26.53641,'lng':28.36369}},
{'stp':472052,'address':'472052, EGL - SOWETO, THABISO MOTLHAOLENG, HLUPHE TAVERN, 3615 ERF, GWEBE STREET, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.53217,'lng':28.35556}},
{'stp':473451,'address':'473451, EGL - SOWETO, THABISO MOTLHAOLENG, MASHININI TAVERN, 5806 SHONGWE STREET, EXT 23, RATANDA LOCATION, RATANDA, South Africa','geo':{'lat':-26.54080,'lng':28.36191}},
{'stp':476997,'address':'476997, EGL - SOWETO, THABISO MOTLHAOLENG, ABC LIQUORS, 4611 STAND NO, DENNE ROAD, EXTENSION 23, RATANDA, South Africa','geo':{'lat':-26.53309,'lng':28.35493}},
{'stp':477213,'address':'477213, EGL - SOWETO, THABISO MOTLHAOLENG, MOLEFIS SHEBEEN, 5479 STAND NO, EXTENSION 7 MATLALA STREET, RATANDA, RATANDA, South Africa','geo':{'lat':-26.57289,'lng':28.32304}},
{'stp':477217,'address':'477217, EGL - SOWETO, THABISO MOTLHAOLENG, MOPHEME TAVERN HEILDELBERG, 2587 STAND NO, MOTSELE STREET, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55270,'lng':28.32730}},
{'stp':485542,'address':'485542, EGL - SOWETO, THABISO MOTLHAOLENG, EMABHELENI TAVERN, 5687 STAND NO, MOHALE STREET, EXT 7 RATANDA, RATANDA, South Africa','geo':{'lat':-26.57358,'lng':28.32427}},
{'stp':491057,'address':'491057, EGL - SOWETO, THABISO MOTLHAOLENG, PICARDI REBEL RATANDA, 2 SHOP NO, C/O PROTEA ROAD AND BLESBOK, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55841,'lng':28.33419}},
{'stp':540081,'address':'540081, EGL - SOWETO, THABISO MOTLHAOLENG, PHILOSOPHY TAVERN, TSHUNGU STREET, ERF 2598 RATANDA, #, RATANDA, South Africa','geo':{'lat':-26.55060,'lng':28.32230}},
{'stp':540150,'address':'540150, EGL - SOWETO, THABISO MOTLHAOLENG, PONTSHO S PUB AND GRILL, 7863 THABO MBEKI DRIVE, EXTENSION 6 RATANDA, #, RATANDA, South Africa','geo':{'lat':-26.55710,'lng':28.31420}},
{'stp':547107,'address':'547107, EGL - SOWETO, THABISO MOTLHAOLENG, DAVIDS INN, 5609 STAND NO, EXTENSION 7, RATANDA, RATANDA, South Africa','geo':{'lat':-26.57500,'lng':28.32517}},
{'stp':549176,'address':'549176, EGL - SOWETO, THABISO MOTLHAOLENG, TA SKAS TAVERN, MABOE STREET, ERF 6880, RATANDA EXTENSION 3, RATANDA, South Africa','geo':{'lat':-26.55872,'lng':28.31619}},
{'stp':549412,'address':'549412, EGL - SOWETO, THABISO MOTLHAOLENG, XOLA MTHAKTAHI TAVERN, 7408 STAND NO, MAKHANYA CRESCENT RATANDA EXT 5, #, RATANDA, South Africa','geo':{'lat':-26.54970,'lng':28.32102}},
{'stp':550144,'address':'550144, EGL - SOWETO, THABISO MOTLHAOLENG, SPEAK EASY TAVERN, STAND 1017 CINDI STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55470,'lng':28.32990}},
{'stp':550420,'address':'550420, EGL - SOWETO, THABISO MOTLHAOLENG, MITI S TAVERN, 714 MNGUNI STREET, HEIDELBERG, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.55320,'lng':28.33550}},
{'stp':550462,'address':'550462, EGL - SOWETO, THABISO MOTLHAOLENG, ROUND 14 LIQUOR STORE, 4165  HEIDELBERG ROAD, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55450,'lng':28.32660}},
{'stp':550500,'address':'550500, EGL - SOWETO, THABISO MOTLHAOLENG, RENSBURG CELLARS, CNR KORT AND EUGENE MARAIS STREET, RENSBURG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.52270,'lng':28.37240}},
{'stp':550545,'address':'550545, EGL - SOWETO, THABISO MOTLHAOLENG, SAUSILITO SPUR, CNR VOORTREKKER & JACOBSTRAAT, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49480,'lng':28.35320}},
{'stp':550674,'address':'550674, EGL - SOWETO, THABISO MOTLHAOLENG, NKOSIS TAVERN, STANDPLAS 2497 SAKENGSTRAAT & MODIS, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.54960,'lng':28.32390}},
{'stp':550797,'address':'550797, EGL - SOWETO, THABISO MOTLHAOLENG, RATANDA BOTTLE STORE, ERF 2838 RATANDA PROSPER, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55260,'lng':28.32770}},
{'stp':551417,'address':'551417, EGL - SOWETO, THABISO MOTLHAOLENG, MAAK N PLAN DRANKWINKEL, PLOT 56, GOODHOPE RD, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.55710,'lng':28.15360}},
{'stp':551512,'address':'551512, EGL - SOWETO, THABISO MOTLHAOLENG, HOFFELDT RESTAURANT, PORTION 59 OF 33 FARM, BOSCHOEK, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.54260,'lng':28.29580}},
{'stp':552139,'address':'552139, EGL - SOWETO, THABISO MOTLHAOLENG, THANDA RESTAURANT, 215  HERTZ STREET, 54 MERTZ STREET, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.50260,'lng':28.35950}},
{'stp':552404,'address':'552404, EGL - SOWETO, THABISO MOTLHAOLENG, GOLD LIQUOR STORE, 38 SCHOEMAN STREET, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.50750,'lng':28.36090}},
{'stp':552670,'address':'552670, EGL - SOWETO, THABISO MOTLHAOLENG, ISIKHATI ESIKHULU TAVERN, 348 EMLANGENI STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55270,'lng':28.33150}},
{'stp':552715,'address':'552715, EGL - SOWETO, THABISO MOTLHAOLENG, HEIDELBERG GOLF CLUB, STATION STREET, RENSBURG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.50630,'lng':28.37440}},
{'stp':553381,'address':'553381, EGL - SOWETO, THABISO MOTLHAOLENG, LADYS NIGHT TAVERN, MTHETHWA STR 1152, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55660,'lng':28.33080}},
{'stp':553771,'address':'553771, EGL - SOWETO, THABISO MOTLHAOLENG, PALAZOLA TAVERN, ERF NO 2838BLESSBOK STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55270,'lng':28.32730}},
{'stp':560182,'address':'560182, EGL - SOWETO, THABISO MOTLHAOLENG, MAMMYS JOINT PERMIT, 2303 SEROTE STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.54920,'lng':28.32670}},
{'stp':603384,'address':'603384, EGL - SOWETO, THABISO MOTLHAOLENG, HEIDELBURG TOPS, STAND NO, SHOP 5 HEIDELBERG SQUARE, CNR VOORTREKKER AND MERZ STREETS, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.50180,'lng':28.36000}},
{'stp':603560,'address':'603560, EGL - SOWETO, THABISO MOTLHAOLENG, HEIDELBERG PUB AND RESTAURANT, 39 VERWOERD STREET, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49830,'lng':28.35760}},
{'stp':605962,'address':'605962, EGL - SOWETO, THABISO MOTLHAOLENG, SIDNEYS PLACE, 346 MOSELIKI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34540,'lng':28.15780}},
{'stp':625508,'address':'625508, EGL - SOWETO, THABISO MOTLHAOLENG, JOHNNYS PUB, 1236 MPANZA STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55738,'lng':28.32989}},
{'stp':627165,'address':'627165, EGL - SOWETO, THABISO MOTLHAOLENG, VINCENT INN PERMIT, 8420 LETSOENE STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55480,'lng':28.32110}},
{'stp':627970,'address':'627970, EGL - SOWETO, THABISO MOTLHAOLENG, HLOMBANES PLACE PERMIT, 7175 KHANYE ROAD, SEDIBENG, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.56030,'lng':28.31960}},
{'stp':629875,'address':'629875, EGL - SOWETO, THABISO MOTLHAOLENG, PERCYS TAVERN PERMIT, 2373 MPONENG STREET, SILUMA VIEW, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37690,'lng':28.16120}},
{'stp':630766,'address':'630766, EGL - SOWETO, THABISO MOTLHAOLENG, MAGRACE TAVERN, 3971 OUPA QHINEBA STREET, RATANDA EXTENSION 4, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.55560,'lng':28.33640}},
{'stp':630788,'address':'630788, EGL - SOWETO, THABISO MOTLHAOLENG, CHICAGO BULLS LIQUOR STORE, CNR TOKYO SEXWALE&MAVUSA EXT7, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.56770,'lng':28.32320}},
{'stp':631071,'address':'631071, EGL - SOWETO, THABISO MOTLHAOLENG, LAURAS PLACE, 4013 PHANGA STREET, EXT 23, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53130,'lng':28.36100}},
{'stp':635746,'address':'635746, EGL - SOWETO, THABISO MOTLHAOLENG, SAMANGA JAZZ CAFE, 8444 HOKO CRESENT, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55330,'lng':28.32150}},
{'stp':635772,'address':'635772, EGL - SOWETO, THABISO MOTLHAOLENG, PHINDI\'S PLACE, 4976 MOKHONONG & BLACKWOOD STS, EXT 23 RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53770,'lng':28.35440}},
{'stp':636028,'address':'636028, EGL - SOWETO, THABISO MOTLHAOLENG, CHIP CORNER SHEBEEN, 5966 MIYA CRESENT, EXT 7, RATANDA, RATANDA, South Africa','geo':{'lat':-26.56820,'lng':28.32700}},
{'stp':636220,'address':'636220, EGL - SOWETO, THABISO MOTLHAOLENG, THABOS INN, 3715 BAMBISA STREET, RATANDA EXT 23, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.53150,'lng':28.35740}},
{'stp':636555,'address':'636555, EGL - SOWETO, THABISO MOTLHAOLENG, SUMESAN LIQUOR STORE, 8046 STAND NO, HEIDELBERG CENTRE, KISMET ROAD, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.51530,'lng':28.35500}},
{'stp':636573,'address':'636573, EGL - SOWETO, THABISO MOTLHAOLENG, DA BLACK ACE PUB AND GRILL, 1207 PROTEA ROAD, RATANDA TOWNSHIP, #, RATANDA, South Africa','geo':{'lat':-26.55630,'lng':28.32920}},
{'stp':638046,'address':'638046, EGL - SOWETO, THABISO MOTLHAOLENG, NONCEBAS PLACE, 418 MAKGALE STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55740,'lng':28.32840}},
{'stp':640923,'address':'640923, EGL - SOWETO, THABISO MOTLHAOLENG, LISEBOS, 2411 MOLETSANE STREET, RATANDA, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.54760,'lng':28.32610}},
{'stp':641520,'address':'641520, EGL - SOWETO, THABISO MOTLHAOLENG, TOPS AT RENSBURG, 67 ROMYN STREET, RENSBURG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.52210,'lng':28.37360}},
{'stp':641839,'address':'641839, EGL - SOWETO, THABISO MOTLHAOLENG, MITIS TWO, 4428 AKASIA,  EXT 23 RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53410,'lng':28.36100}},
{'stp':646694,'address':'646694, EGL - SOWETO, THABISO MOTLHAOLENG, MALIZO TAVERN, 5382  DENNED AVENUE, EXT 23 RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53753,'lng':28.36051}},
{'stp':646713,'address':'646713, EGL - SOWETO, THABISO MOTLHAOLENG, TOGETHERNESS INN, 2658 MATLALE  STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55150,'lng':28.32290}},
{'stp':646725,'address':'646725, EGL - SOWETO, THABISO MOTLHAOLENG, ALINAH\'S TAVERN, 1864 HEIDELBERG ROAD, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55710,'lng':28.32360}},
{'stp':647293,'address':'647293, EGL - SOWETO, THABISO MOTLHAOLENG, KAMZA\'S TAVERN, 5137 MOAGI STREET, RATANDA, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.56920,'lng':28.32040}},
{'stp':648119,'address':'648119, EGL - SOWETO, THABISO MOTLHAOLENG, MABALENG TAVERN, 6483 RATHANDA EXT 26, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53890,'lng':28.36500}},
{'stp':648120,'address':'648120, EGL - SOWETO, THABISO MOTLHAOLENG, BOTSHOTSO\'S PLACE, 5240 MOAGI STREET, RATANDA EXT 7, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.57070,'lng':28.32180}},
{'stp':649601,'address':'649601, EGL - SOWETO, THABISO MOTLHAOLENG, YOYO\'S TAVERN, 4689 MOKHOMONG STREET, HEIDELBERG, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.53460,'lng':28.35510}},
{'stp':649869,'address':'649869, EGL - SOWETO, THABISO MOTLHAOLENG, PULENG TAVERN, 3981 EXT 4, RATHANDA, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.55630,'lng':28.33590}},
{'stp':650320,'address':'650320, EGL - SOWETO, THABISO MOTLHAOLENG, SUN INTERNATIONAL, 3151 ORANJE STREET, SHALIMAR RIDGE, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.51680,'lng':28.34970}},
{'stp':650450,'address':'650450, EGL - SOWETO, THABISO MOTLHAOLENG, LIZOS TAVERN, 5322 EXT 23, MOKGOMUNGU, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.53680,'lng':28.35910}},
{'stp':651831,'address':'651831, EGL - SOWETO, THABISO MOTLHAOLENG, TSHABALALA BOTTLE STORE, 2876 NHLAPO STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55080,'lng':28.33050}},
{'stp':652858,'address':'652858, EGL - SOWETO, THABISO MOTLHAOLENG, THEMBISILES TAVERN, 6114 EXTENSION 7, RATANDA, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.56660,'lng':28.32490}},
{'stp':653735,'address':'653735, EGL - SOWETO, THABISO MOTLHAOLENG, NKAMELA TAVERN, 5207 SERERO STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53800,'lng':28.35630}},
{'stp':654395,'address':'654395, EGL - SOWETO, THABISO MOTLHAOLENG, JOHN SHEBEEN, 5690 MPHOTHOLO STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53980,'lng':28.35810}},
{'stp':662532,'address':'662532, EGL - SOWETO, THABISO MOTLHAOLENG, SMILEY S TUCKSHOP, 4099 NGEMA  STREET, EXTENSION 23, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53225,'lng':28.35969}},
{'stp':662574,'address':'662574, EGL - SOWETO, THABISO MOTLHAOLENG, NOMVULAS INN, 727 ZWANE STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55180,'lng':28.33450}},
{'stp':662680,'address':'662680, EGL - SOWETO, THABISO MOTLHAOLENG, THANDABANTU, 145 TOKOLOHONG AGRI VILLAGE, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.57705,'lng':28.32343}},
{'stp':663088,'address':'663088, EGL - SOWETO, THABISO MOTLHAOLENG, M TUCKSHOP, 6386 EXTENSION 26, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.54150,'lng':28.36370}},
{'stp':664388,'address':'664388, EGL - SOWETO, THABISO MOTLHAOLENG, IRENES, 2098 MAMABOLO STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.54890,'lng':28.32890}},
{'stp':665358,'address':'665358, EGL - SOWETO, THABISO MOTLHAOLENG, ARMY GYM SPORTS CLUB (NTO), LOUW STREET, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49434,'lng':28.36326}},
{'stp':665359,'address':'665359, EGL - SOWETO, THABISO MOTLHAOLENG, ARMY GYM OFFICERS CLUB (NTO), LOUW STREET, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49474,'lng':28.36385}},
{'stp':665360,'address':'665360, EGL - SOWETO, THABISO MOTLHAOLENG, ARMY GYM N.C.O\'S CLUB (NTO), LOUW STREET, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49434,'lng':28.36326}},
{'stp':667068,'address':'667068, EGL - SOWETO, THABISO MOTLHAOLENG, RASTAS TAVERN, 5661 EXTENSION 23 LOCATION, HEIDELBERG, RATANDA, RATANDA, South Africa','geo':{'lat':-26.54070,'lng':28.35640}},
{'stp':668049,'address':'668049, EGL - SOWETO, THABISO MOTLHAOLENG, SA ARMY GYMNASIUM, CNR LOUW AND JACOB STREET, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49440,'lng':28.36340}},
{'stp':680398,'address':'680398, EGL - SOWETO, THABISO MOTLHAOLENG, FAITHS PLACE, 3655 MTJILIBE STREET, EXT 23, RATANDA, RATANDA, South Africa','geo':{'lat':-26.53210,'lng':28.35460}},
{'stp':681438,'address':'681438, EGL - SOWETO, THABISO MOTLHAOLENG, QUALITY LIQUOR STORE, 9 MEYER STREET, HEIDELBURG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.50817,'lng':28.36180}},
{'stp':683125,'address':'683125, EGL - SOWETO, THABISO MOTLHAOLENG, MADIDAS INN, 6319 MAYISA STREET, EXT 7, RATANDA, RATANDA, South Africa','geo':{'lat':-26.56597,'lng':28.32524}},
{'stp':687019,'address':'687019, EGL - SOWETO, THABISO MOTLHAOLENG, R14 CLASSIC JOINT RESTAURANT AND PU, CNR HEIDELBERG AND BOSCHHOEK STREET, ERF 8823, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55460,'lng':28.32680}},
{'stp':687422,'address':'687422, EGL - SOWETO, THABISO MOTLHAOLENG, BUTI SHEBEEN, 6055 MTHIMKHULU STREET, EXTENSION 23, RATANDA, RATANDA, South Africa','geo':{'lat':-26.54240,'lng':28.36080}},
{'stp':687584,'address':'687584, EGL - SOWETO, THABISO MOTLHAOLENG, MABALENG SPORTS BAR, 2 ZUID STREET, CNR R23 ROAD FARM HOUTPOORT, RENSBURG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.53507,'lng':28.36718}},
{'stp':691524,'address':'691524, EGL - SOWETO, THABISO MOTLHAOLENG, CENIOR PIZZA (NTO), UECKMAN WEG, HEIDELBERG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.57353,'lng':28.32361}},
{'stp':699173,'address':'699173, EGL - SOWETO, THABISO MOTLHAOLENG, A J TAVERN, ERF NO 8379, ZULU CRESCENT STREET, RATANDA EXTENSION 1, RATANDA, South Africa','geo':{'lat':-26.55250,'lng':28.32200}},
{'stp':699247,'address':'699247, EGL - SOWETO, THABISO MOTLHAOLENG, TAMENENG YA ARBY, 5285 5285  MNISI STREET, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.56891,'lng':28.32175}},
{'stp':728288,'address':'728288, EGL - SOWETO, THABISO MOTLHAOLENG, MNTUNGWA S PUB AND RESTAURANT, 8649 SKHOSANA STREET, RATANDA EXT 1, #, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.55300,'lng':28.32070}},
{'stp':744343,'address':'744343, EGL - SOWETO, THABISO MOTLHAOLENG, CINO PUB, 72 NHLAPO STREET, RATANDA, #, RATANDA, South Africa','geo':{'lat':-26.55050,'lng':28.33100}},
{'stp':754731,'address':'754731, EGL - SOWETO, THABISO MOTLHAOLENG, MAJIMAS SHEBEEN, 8570 THUSI STREET, EXT 1 RATANDA, #, RATANDA, South Africa','geo':{'lat':-26.55509,'lng':28.31929}},
{'stp':754745,'address':'754745, EGL - SOWETO, THABISO MOTLHAOLENG, NDOZI S PLACE, 4739 STAND NO, VILAKAZI STREET RATANDA HEIDELBURG, #, RATANDA, South Africa','geo':{'lat':-26.53460,'lng':28.35420}},
{'stp':755151,'address':'755151, EGL - SOWETO, THABISO MOTLHAOLENG, MASAKHANE TAVERN, 195 TOKOLOHONG, EXTENSION 7, RATANDA, RATANDA, South Africa','geo':{'lat':-26.57370,'lng':28.32150}},
{'stp':759205,'address':'759205, EGL - SOWETO, THABISO MOTLHAOLENG, BATLOUNG AND NKOSI, 5751 STAND NO, MOGARI STREET RATHANDA HEIDELBERG, #, RATANDA, South Africa','geo':{'lat':-26.57300,'lng':28.32510}},
{'stp':763230,'address':'763230, EGL - SOWETO, THABISO MOTLHAOLENG, ZODWA S INN, MOKGOMONG STREET, ERF 5322, RATANDA EXTENSION 23, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.53680,'lng':28.35920}},
{'stp':768358,'address':'768358, EGL - SOWETO, THABISO MOTLHAOLENG, THABO LIQUOR STORE, STAND NO, ERF 4209, DENNE AVENUE, RATANDA, South Africa','geo':{'lat':-26.53423,'lng':28.35850}},
{'stp':780323,'address':'780323, EGL - SOWETO, THABISO MOTLHAOLENG, MOLLO S TAVERN, 5747 STAND NO, MOGARI STREET EXT 7 RATANDA, HEIDELBERG, RATANDA, South Africa','geo':{'lat':-26.57341,'lng':28.32572}},
{'stp':781587,'address':'781587, EGL - SOWETO, THABISO MOTLHAOLENG, LERA INN, STAND NO, LEKOTA DRIVE, RATANDA  EXT 8, RATANDA, South Africa','geo':{'lat':-26.56581,'lng':28.31886}},
{'stp':781847,'address':'781847, EGL - SOWETO, THABISO MOTLHAOLENG, KIEPERSOL LIQOUR STORE, ROMEIN STREET, ERF 196 SHOP N0 2, RENSBURG, RATANDA, South Africa','geo':{'lat':-26.52962,'lng':28.36485}},
{'stp':781987,'address':'781987, EGL - SOWETO, THABISO MOTLHAOLENG, EMTHUNZINI PUB AND GRILL, MOKHELE STREET, ERF 5665 RATANDA, EXTENSION 7, RATANDA, South Africa','geo':{'lat':-26.57359,'lng':28.32330}},
{'stp':795802,'address':'795802, EGL - SOWETO, THABISO MOTLHAOLENG, THE NEW JOINT, NHLAPHO STREET, ERF 2877 NHLAPHO STREET, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55090,'lng':28.33063}},
{'stp':797615,'address':'797615, EGL - SOWETO, THABISO MOTLHAOLENG, GAME LIQUOR HEIDELBERG 277, 7 MAKAPAN STREET, SHOP NO 6, IN THE HEIDELBERG MALL, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49480,'lng':28.35320}},
{'stp':798847,'address':'798847, EGL - SOWETO, THABISO MOTLHAOLENG, EMPILO SHEBEEN, 2699 STAND NO, BOSHOEK STREET, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55217,'lng':28.32353}},
{'stp':798890,'address':'798890, EGL - SOWETO, THABISO MOTLHAOLENG, MA VEE TAVERN, 5185 STAND NO, EXTENSION 23, #, RATANDA, South Africa','geo':{'lat':-26.53850,'lng':28.35450}},
{'stp':798908,'address':'798908, EGL - SOWETO, THABISO MOTLHAOLENG, EMASOFENI INN, 5267 SISHINGA STREET, #, #, RATANDA, South Africa','geo':{'lat':-26.53739,'lng':28.35644}},
{'stp':799695,'address':'799695, EGL - SOWETO, THABISO MOTLHAOLENG, CHECKERS LIQRSHOP 82543 HEIDELBERG, STAND NUMBER, SHOP 1 HEIDELBERG MALL  CNR N3 AND R42, JACOBS STREET HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49300,'lng':28.37420}},
{'stp':381328,'address':'381328, EGL - SOWETO, THABISO MOTLHAOLENG, RIVERS TAVERN, 597 ERF NO, CINTSO STREET, #, VOSLOORUS, South Africa','geo':{'lat':-26.34886,'lng':28.19291}},
{'stp':383168,'address':'383168, EGL - SOWETO, THABISO MOTLHAOLENG, MARY S INN BILA TRADING, STAND NO, SKOSANA STREET, KATLEHONG, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.32580,'lng':28.15270}},
{'stp':384548,'address':'384548, EGL - SOWETO, THABISO MOTLHAOLENG, KWA SOMPISI LIQUOR STORE, 6023 STAND NUMBER, KHUMALO STREET, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.33918,'lng':28.13954}},
{'stp':387973,'address':'387973, EGL - SOWETO, THABISO MOTLHAOLENG, MAS LIQUOR STORE, STAND NUMBER, HEIDELBERG ROAD, PLOT 13, ALBERTON, South Africa','geo':{'lat':-26.42116,'lng':28.09909}},
{'stp':388888,'address':'388888, EGL - SOWETO, THABISO MOTLHAOLENG, BATLOUNG TAVERN, 2013 STAND NO, LIKOLE, EXTENSION 1, #, KATLEHONG, South Africa','geo':{'lat':-26.37563,'lng':28.16267}},
{'stp':392649,'address':'392649, EGL - SOWETO, THABISO MOTLHAOLENG, TRIANGLE BOTTLE STORE, 383 STAND NO, MOKOENA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35174,'lng':28.15681}},
{'stp':393432,'address':'393432, EGL - SOWETO, THABISO MOTLHAOLENG, PNP FRANCHISE STORE MATLALAS MARKET, 257 STAND NO, MDAKANA STREET, #, TOKOZA, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':472327,'address':'472327, EGL - SOWETO, THABISO MOTLHAOLENG, SHOPRTIE LIQUOR SHOP TOKOZA 36665, STAND NO, PORTION 6 OF ERF 9102 SHOP 2, CORNER KHUMALO AND MATSEMELA STREET, TOKOZA, South Africa','geo':{'lat':-26.35307,'lng':28.15418}},
{'stp':485310,'address':'485310, EGL - SOWETO, THABISO MOTLHAOLENG, GRANNYS TAVERN, 12322 STAND NO, ISICHALO EXTENSION 14, ERF 123220000, VOSLOORUS, South Africa','geo':{'lat':-26.37000,'lng':28.22540}},
{'stp':488577,'address':'488577, EGL - SOWETO, THABISO MOTLHAOLENG, TRIPPLE M TAVERN, 3045 ERF, MOKGABO ROAD, EXT 1, VOSLOORUS, South Africa','geo':{'lat':-26.35364,'lng':28.19339}},
{'stp':490618,'address':'490618, EGL - SOWETO, THABISO MOTLHAOLENG, MAJWENG PUB, 10950 STAND NO, LSITULO STREET, EXT 14, VOSLOORUS, South Africa','geo':{'lat':-26.36072,'lng':28.22394}},
{'stp':549084,'address':'549084, EGL - SOWETO, THABISO MOTLHAOLENG, NEW PORT NO 75, STAND NO, ERF 20916 75 MACHEKE STREET, PHASE 7A EXT 8, VOSLOORUS, South Africa','geo':{'lat':-26.36986,'lng':28.20000}},
{'stp':550292,'address':'550292, EGL - SOWETO, THABISO MOTLHAOLENG, CITY BING LIQUOR STORE, 141 BOSWORTH STREET, ALRODE, ALBERTON, TOKOZA, South Africa','geo':{'lat':-26.32410,'lng':28.13780}},
{'stp':550338,'address':'550338, EGL - SOWETO, THABISO MOTLHAOLENG, PITSO S TAVERN, 977 MOSHU STREET  EXT 3, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36330,'lng':28.18620}},
{'stp':550355,'address':'550355, EGL - SOWETO, THABISO MOTLHAOLENG, KWAZENZELE BOTTLE STORE, 334 MOTSAMAI STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35621,'lng':28.18869}},
{'stp':550591,'address':'550591, EGL - SOWETO, THABISO MOTLHAOLENG, SHAGI BOTTLE STORE, 1893 AN 1896 NKAKI STREET, TOKOZA, TOKOZA, TOKOZA, South Africa','geo':{'lat':-26.34590,'lng':28.13720}},
{'stp':550631,'address':'550631, EGL - SOWETO, THABISO MOTLHAOLENG, MOSHATE YA MOSHATE ENTERPRISES PTY, 689 RAMOKONOPI WEST, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.36360,'lng':28.15440}},
{'stp':550751,'address':'550751, EGL - SOWETO, THABISO MOTLHAOLENG, VICTOR S INN, 11306 STAND NO, EXTENSION 14 VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36510,'lng':28.22360}},
{'stp':550769,'address':'550769, EGL - SOWETO, THABISO MOTLHAOLENG, E T LIQUOR STORE, CNR MOKGAKO & HATTING STR, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34780,'lng':28.18800}},
{'stp':550774,'address':'550774, EGL - SOWETO, THABISO MOTLHAOLENG, SHIBASES LIQUOR, 164 TWALA STREET, TWALA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32740,'lng':28.14750}},
{'stp':551240,'address':'551240, EGL - SOWETO, THABISO MOTLHAOLENG, JACK CITY TAVERN, 2957 MOKGARA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35369,'lng':28.19287}},
{'stp':551331,'address':'551331, EGL - SOWETO, THABISO MOTLHAOLENG, GOMMAS TAVERN, 2604 DIBETSO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35428,'lng':28.18999}},
{'stp':551553,'address':'551553, EGL - SOWETO, THABISO MOTLHAOLENG, PARADISE TAVERN, 1309 M C BOTHA DRIVE, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35380,'lng':28.19690}},
{'stp':551563,'address':'551563, EGL - SOWETO, THABISO MOTLHAOLENG, SECHABA LIQUOR STORE, STAND 4 CNR MOAGI & HATTINGH STREET, VOSLOORUS EXT 1, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34830,'lng':28.19650}},
{'stp':551688,'address':'551688, EGL - SOWETO, THABISO MOTLHAOLENG, SAMS INN, 320  MOSELEKE SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.34480,'lng':28.15900}},
{'stp':551721,'address':'551721, EGL - SOWETO, THABISO MOTLHAOLENG, PORDO SOL, CNR MAZDA & LEYLAND STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37750,'lng':28.13190}},
{'stp':551735,'address':'551735, EGL - SOWETO, THABISO MOTLHAOLENG, SANTA LUCIA RESTAURANT, OLD VEREENINGING ROAD, DALESIDE, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.42160,'lng':28.08510}},
{'stp':552096,'address':'552096, EGL - SOWETO, THABISO MOTLHAOLENG, ROSES TAVERN, ERF 7827 EXT 9, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35380,'lng':28.21750}},
{'stp':552509,'address':'552509, EGL - SOWETO, THABISO MOTLHAOLENG, CAMBRIDGE TAVERN, 47 DISHEGO ROAD, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34842,'lng':28.19815}},
{'stp':552622,'address':'552622, EGL - SOWETO, THABISO MOTLHAOLENG, KATLEHONG TOPS, CNR LEKWANE &  HOSPITAL STREET,, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32710,'lng':28.14500}},
{'stp':552696,'address':'552696, EGL - SOWETO, THABISO MOTLHAOLENG, MALOMA TAVERN, 10555 MORENA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36210,'lng':28.22720}},
{'stp':552983,'address':'552983, EGL - SOWETO, THABISO MOTLHAOLENG, MARY\'S INN TAVERN, 276 SKOSANA SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.32580,'lng':28.15270}},
{'stp':553328,'address':'553328, EGL - SOWETO, THABISO MOTLHAOLENG, FIX LIQUOR STORE, CNR KHOZA & MAUBANE STREETS, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36160,'lng':28.20020}},
{'stp':553400,'address':'553400, EGL - SOWETO, THABISO MOTLHAOLENG, MAMHLOPHES TAVERN, 2993 MOKGAKO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35165,'lng':28.19534}},
{'stp':553537,'address':'553537, EGL - SOWETO, THABISO MOTLHAOLENG, SAMS INN LIQUOR STORE, 896 NHLAPO SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.30690,'lng':28.15340}},
{'stp':553660,'address':'553660, EGL - SOWETO, THABISO MOTLHAOLENG, JOEL S TAVERN, 20034 EXT 30 MFUNDO PARK, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34160,'lng':28.20180}},
{'stp':553701,'address':'553701, EGL - SOWETO, THABISO MOTLHAOLENG, SEBOLELOS TAVERN, 20208 IKLABA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33995,'lng':28.20464}},
{'stp':553880,'address':'553880, EGL - SOWETO, THABISO MOTLHAOLENG, JOES INN PERMIT, 103 PORTION 48, EXT 28, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37740,'lng':28.21080}},
{'stp':553898,'address':'553898, EGL - SOWETO, THABISO MOTLHAOLENG, T P MNGOMEZULU PERMIT, 10583 MAKHURA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35907,'lng':28.22623}},
{'stp':560401,'address':'560401, EGL - SOWETO, THABISO MOTLHAOLENG, CONDI S PLACE, 1281 TSEKE STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35296,'lng':28.19615}},
{'stp':560530,'address':'560530, EGL - SOWETO, THABISO MOTLHAOLENG, CHICKS TAVERN, 17123 MOGOTLHORE STREET, EXT 25, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36770,'lng':28.23090}},
{'stp':580400,'address':'580400, EGL - SOWETO, THABISO MOTLHAOLENG, PAT S PLACE, 16192 IMBUZANA STREET, EAST FIELD E, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33840,'lng':28.19910}},
{'stp':604847,'address':'604847, EGL - SOWETO, THABISO MOTLHAOLENG, JOEL F TAVERN, 273 NAMAPA STREET, EXT 28, PHASE 2, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37869,'lng':28.20182}},
{'stp':604977,'address':'604977, EGL - SOWETO, THABISO MOTLHAOLENG, DITLOUNGS TAVERN, 12667 SETOLEMORU STREET EXT 23, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33610,'lng':28.20260}},
{'stp':605941,'address':'605941, EGL - SOWETO, THABISO MOTLHAOLENG, POPPYS TAVERN, 156 KINSHASA STREET, EXT 28, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.37150,'lng':28.21670}},
{'stp':605984,'address':'605984, EGL - SOWETO, THABISO MOTLHAOLENG, TEBOS PLACE, 1124 MOGASHOA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35190,'lng':28.19590}},
{'stp':605994,'address':'605994, EGL - SOWETO, THABISO MOTLHAOLENG, THREE SISTERS TAVERN, 639 CINTSO STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34850,'lng':28.19540}},
{'stp':606266,'address':'606266, EGL - SOWETO, THABISO MOTLHAOLENG, J J INN, 12678 TEKWANE STREET, EXT 23, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33560,'lng':28.19800}},
{'stp':606268,'address':'606268, EGL - SOWETO, THABISO MOTLHAOLENG, BRA MIKE, CNR FREY & HARDEKOOL, PALM RIDGE, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.39390,'lng':28.15160}},
{'stp':607069,'address':'607069, EGL - SOWETO, THABISO MOTLHAOLENG, CONIES PLACE, 3053 NAPE ROAD, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36130,'lng':28.19760}},
{'stp':607276,'address':'607276, EGL - SOWETO, THABISO MOTLHAOLENG, BOSS SHEBEEN, 63 ULONDOLOZO STREET, EXTENSION 7, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36140,'lng':28.19210}},
{'stp':625148,'address':'625148, EGL - SOWETO, THABISO MOTLHAOLENG, BAFOKENG LIQUOR STORE, 1092 CNR MOGOTLANE & MOGASHOA STR, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35080,'lng':28.19130}},
{'stp':626682,'address':'626682, EGL - SOWETO, THABISO MOTLHAOLENG, DUMIS TAVERN PERMIT, 11184 MOTSI STREET EXT 14, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36240,'lng':28.22150}},
{'stp':626725,'address':'626725, EGL - SOWETO, THABISO MOTLHAOLENG, TOCKY\'S INN TAVERN, 142 MONISI SECTION, KATLEHONG, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.35820,'lng':28.15940}},
{'stp':627082,'address':'627082, EGL - SOWETO, THABISO MOTLHAOLENG, M A S LIQUOR STORE, 13 DRIFT STREET, KLIPRIVIER ROAD, GARTHDALE, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.42448,'lng':28.09801}},
{'stp':627630,'address':'627630, EGL - SOWETO, THABISO MOTLHAOLENG, MANKWALI TAVERN PERMIT, 264 UMBENGULA STREET, GREENFIELDS, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.39170,'lng':28.12740}},
{'stp':630587,'address':'630587, EGL - SOWETO, THABISO MOTLHAOLENG, MAKHADO SHEBEEN PERMIT, 18181 LEOKA CRESCENT, EXT 25, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36470,'lng':28.23080}},
{'stp':630641,'address':'630641, EGL - SOWETO, THABISO MOTLHAOLENG, TLAKA\'S INN, 6853 STAND NO, PHASE 4 BLOEMSIDE, BOTSHABELO, VOSLOORUS, South Africa','geo':{'lat':-26.34680,'lng':28.19340}},
{'stp':630736,'address':'630736, EGL - SOWETO, THABISO MOTLHAOLENG, JIMMYS INN, 12634 THALABODIBA STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33440,'lng':28.19960}},
{'stp':630765,'address':'630765, EGL - SOWETO, THABISO MOTLHAOLENG, BIG SCREEN, 3657 MAUBANE STREET ROCKVILLE EXT 8, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36280,'lng':28.20370}},
{'stp':631069,'address':'631069, EGL - SOWETO, THABISO MOTLHAOLENG, KONGOS INN, 15979 INCEDE STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34020,'lng':28.19230}},
{'stp':632382,'address':'632382, EGL - SOWETO, THABISO MOTLHAOLENG, OASIS STORE, 10687 SELEPE STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36030,'lng':28.22660}},
{'stp':635972,'address':'635972, EGL - SOWETO, THABISO MOTLHAOLENG, BARNEY TAVERN, 993 PUSELETSO STREET, LIKOLE SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37240,'lng':28.15320}},
{'stp':636088,'address':'636088, EGL - SOWETO, THABISO MOTLHAOLENG, DULCIE\'S TAVERN, 4026 REST IN PEACE, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35870,'lng':28.21330}},
{'stp':636099,'address':'636099, EGL - SOWETO, THABISO MOTLHAOLENG, MANDLAS TAVERN, 325 CHERE STREET, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34770,'lng':28.19080}},
{'stp':636105,'address':'636105, EGL - SOWETO, THABISO MOTLHAOLENG, LILLYS POLLAS JAZZ INN, 343 UMHLABA STREET, EXT 8 VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36700,'lng':28.20390}},
{'stp':637386,'address':'637386, EGL - SOWETO, THABISO MOTLHAOLENG, SELINAS INN, 2963 MOKGARA STREET, VOSLOORUS, BOKSBURG, VOSLOORUS, South Africa','geo':{'lat':-26.35400,'lng':28.19320}},
{'stp':638099,'address':'638099, EGL - SOWETO, THABISO MOTLHAOLENG, JABSIE INN, 15634 UBAMBO STREET, EXT 16 VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34130,'lng':28.18160}},
{'stp':638622,'address':'638622, EGL - SOWETO, THABISO MOTLHAOLENG, ESTOFINI INN, 3495 NGCOBO STREET, EXT 8 VOSLOORUS, BOKSBURG, VOSLOORUS, South Africa','geo':{'lat':-26.36343,'lng':28.20230}},
{'stp':640802,'address':'640802, EGL - SOWETO, THABISO MOTLHAOLENG, LIQUOR CITY KATLEHONG, SHOP 30 SONTONGA MALL, HLONGWANE STREET, AP KHUMALO, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.37457,'lng':28.16267}},
{'stp':641429,'address':'641429, EGL - SOWETO, THABISO MOTLHAOLENG, SIMUNYE CASH SALES, 12957 KUKUKU STREET X23, EASTFIELD, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33620,'lng':28.20350}},
{'stp':647436,'address':'647436, EGL - SOWETO, THABISO MOTLHAOLENG, B MKWANAZI TAVERN, 11551 INYAMAZANE STREET, EXT 14, VOSLOORUS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36270,'lng':28.22550}},
{'stp':648173,'address':'648173, EGL - SOWETO, THABISO MOTLHAOLENG, GETTY\'S PLACE, 108 PHALANE STREET, SOTHO, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35820,'lng':28.19622}},
{'stp':650202,'address':'650202, EGL - SOWETO, THABISO MOTLHAOLENG, MBATHANA BOTTLE STORE, 61 PARCARD STREET, EDEN PARK, EDEN PARK, EDEN PARK, South Africa','geo':{'lat':-26.37630,'lng':28.13360}},
{'stp':653202,'address':'653202, EGL - SOWETO, THABISO MOTLHAOLENG, MUSAS INN, 15792 ISIHULUHULU STREET, EXT 16, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34210,'lng':28.18380}},
{'stp':653247,'address':'653247, EGL - SOWETO, THABISO MOTLHAOLENG, JUDITHS PLACE, 20 BURUNDI STREET, PORTION 43 PHASE 3B, EXTENSION 28, VOSLOORUS, South Africa','geo':{'lat':-26.37600,'lng':28.21250}},
{'stp':653724,'address':'653724, EGL - SOWETO, THABISO MOTLHAOLENG, LUCKYS PLACE, 61 STAND NO, DIBETSO ROAD, SOTHO SECTION, VOSLOORUS, South Africa','geo':{'lat':-26.34909,'lng':28.19735}},
{'stp':653886,'address':'653886, EGL - SOWETO, THABISO MOTLHAOLENG, L TS PLACE, 10619 MAKHURA STREET, EXT 14, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35890,'lng':28.22700}},
{'stp':654828,'address':'654828, EGL - SOWETO, THABISO MOTLHAOLENG, PAKISTAN TAVERN, 70 70 UNDOLOZO STREET, VOSLOORUS EXT 7, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36085,'lng':28.19155}},
{'stp':655416,'address':'655416, EGL - SOWETO, THABISO MOTLHAOLENG, SPOKS INN, 1809 PHINZI ROAD, RUSIOO EXTENSION 11, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35910,'lng':28.19450}},
{'stp':664335,'address':'664335, EGL - SOWETO, THABISO MOTLHAOLENG, TSFPS PLACE, 16008 INCEDI STREET, EXT 16, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34010,'lng':28.19240}},
{'stp':682781,'address':'682781, EGL - SOWETO, THABISO MOTLHAOLENG, MASHEGOS TAVERN, 73 PHASE 7, EXT 28, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36931,'lng':28.19981}},
{'stp':687119,'address':'687119, EGL - SOWETO, THABISO MOTLHAOLENG, MOTLOUNG TAVERN, 18046 MOLOPE CRESCENT, EXTENSION 25, VOOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.36250,'lng':28.23140}},
{'stp':687444,'address':'687444, EGL - SOWETO, THABISO MOTLHAOLENG, MJEBES TAVERN, 21749/18 NONG STREET, SEFFOIFOOI STREET EXTENSION 06, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.32910,'lng':28.20080}},
{'stp':689375,'address':'689375, EGL - SOWETO, THABISO MOTLHAOLENG, SHOPRITE KATLEHONG 59172, CNR MOSHEU  AND HOSPITAL STREETS, 24 MOTSE WA LIJANE SHP CENT HLAHATSI, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33120,'lng':28.15450}},
{'stp':699176,'address':'699176, EGL - SOWETO, THABISO MOTLHAOLENG, EMBIZWENI TAVERN, 119 CHIBIA STREET, EXTENSION 28, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.38027,'lng':28.20707}},
{'stp':701065,'address':'701065, EGL - SOWETO, THABISO MOTLHAOLENG, MPHOS INN, 16056 UJEJANE STREET, EXT 16, #, VOSLOORUS, South Africa','geo':{'lat':-26.34080,'lng':28.18940}},
{'stp':710179,'address':'710179, EGL - SOWETO, THABISO MOTLHAOLENG, ESIHLAHLENI TAVERN, 17051 UMZUKUZA STREET, VOSLOORUS EXT 25, #, VOSLOORUS, South Africa','geo':{'lat':-26.36763,'lng':28.22934}},
{'stp':719333,'address':'719333, EGL - SOWETO, THABISO MOTLHAOLENG, JABU S TAVERN, ERF 11802 IXOLO STREET, VOSLOORUS EXT 14, #, VOSLOORUS, South Africa','geo':{'lat':-26.36646,'lng':28.22159}},
{'stp':758610,'address':'758610, EGL - SOWETO, THABISO MOTLHAOLENG, SEKETE LIQUOR STORE, 1 SHOP NO, ERF 6155, LESEDI CITY, VOSLOORUS, South Africa','geo':{'lat':-26.35470,'lng':28.19710}},
{'stp':771239,'address':'771239, EGL - SOWETO, THABISO MOTLHAOLENG, KHOBAS TAVERN, 175128 MONGATE STREET, #, #, VOSLOORUS, South Africa','geo':{'lat':-26.35870,'lng':28.23163}},
{'stp':773381,'address':'773381, EGL - SOWETO, THABISO MOTLHAOLENG, PHOLA TAVERN, STAND NO, PORTION 40 ERF CORNER KINGSHASA AND, ADDIS ABABA STREET, VOSLOORUS, South Africa','geo':{'lat':-26.37111,'lng':28.21639}},
{'stp':774322,'address':'774322, EGL - SOWETO, THABISO MOTLHAOLENG, MABOE S PLACE, 1166 STAND NO, HLATSHWAYO STREET NGUNI SECTION, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.35192,'lng':28.20301}},
{'stp':780362,'address':'780362, EGL - SOWETO, THABISO MOTLHAOLENG, BUSIE NO 4, STAND NO, ROETS DRIVE 4 MABUYA PARK, #, VOSLOORUS, South Africa','geo':{'lat':-26.35467,'lng':28.18521}},
{'stp':793670,'address':'793670, EGL - SOWETO, THABISO MOTLHAOLENG, SAMS LIQUOR STORE TOKOZA, STAND NO, ERF 9185 SHOP 2 KHUMALO STREET, #, TOKOZA, South Africa','geo':{'lat':-26.36055,'lng':28.12903}},
{'stp':552534,'address':'552534, EGL - SOWETO, EAST HYBRID SOUTH, SPELLBOUND RESTAURANT, CNR DE WAAL & RAE FRANKEL STREET, BRACKENDOWNS, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.32060,'lng':28.08620}},
{'stp':625990,'address':'625990, EGL - SOWETO, EAST HYBRID SOUTH, BELLAS PUB, CNR GEN ALBERTS & ROSSLYN STR, RANDHART, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.28760,'lng':28.11580}},
{'stp':665892,'address':'665892, EGL - SOWETO, EAST HYBRID SOUTH, CABALLEROS, CNR RINGROAD WEST AND VOORTREKKER, SHOP 4A ALBERTON CROSSING, NEW REDRUTH, ALBERTON, South Africa','geo':{'lat':-26.27160,'lng':28.12120}},
{'stp':706963,'address':'706963, EGL - SOWETO, EAST HYBRID SOUTH, NIBBLERS PARADISE, 18381 shop 1, Kikis Building, 62 Fick Street, ERF 747, Florentia Ext 1, ALBERTON, South Africa','geo':{'lat':-26.27170,'lng':28.13310}},
{'stp':393346,'address':'393346, EGL - ISANDO, EAST DEF MGR, SIYA S LOUNGE AND SHISA NYAMA CAR W, 15959 STAND NO, SAM NTULI STREET, TSAKANE EXTENSION 5, TSAKANE, South Africa','geo':{'lat':-26.35868,'lng':28.35761}},
{'stp':627180,'address':'627180, EGL - SOWETO, NOMBULELO MAJOVA, MAVUSANAS INN PERMIT, 1304 EXT 2, RONDEBULT, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31180,'lng':28.21680}},
{'stp':766737,'address':'766737, EGL - SOWETO, NOMBULELO MAJOVA, MAVIS PLACE, 237 STAND NO, BUHLE PARK PHASE 1B KLIPPOORTJIE, #, KATLEHONG, South Africa','geo':{'lat':-26.28681,'lng':28.20378}},
{'stp':658068,'address':'658068, EGL - SOWETO, NOMBULELO MAJOVA, POLLAS (NTO), 40 KINSHASA STREET, PHASE 3, EXT 28, VOSLOORUS, South Africa','geo':{'lat':-26.23862,'lng':28.31954}},
{'stp':656732,'address':'656732, EGL - SOWETO, NOMBULELO MAJOVA, MAV TAVERN (NTO), 5185 MOTAUNG ST EXT 23, RATANDA, RATANDA, RATANDA, South Africa','geo':{'lat':-26.55528,'lng':28.32828}},
{'stp':304949,'address':'304949, EGL - SOWETO, TAI HIGGO, MAMPHEU, 1337 STAND NO, PHILLIP STREET, #, LENASIA, South Africa','geo':{'lat':-26.42119,'lng':27.87858}},
{'stp':315771,'address':'315771, EGL - SOWETO, TAI HIGGO, BLUE LAGON, 3368 STAND NO, #, #, LAWLEY, South Africa','geo':{'lat':-26.38430,'lng':27.81035}},
{'stp':388457,'address':'388457, EGL - SOWETO, TAI HIGGO, CORNER BUSY, 885 STAND NO, SIMUNYE STREET, MOUNTAINVIEW, #, ENNERDALE, South Africa','geo':{'lat':-26.41055,'lng':27.86333}},
{'stp':392098,'address':'392098, EGL - SOWETO, TAI HIGGO, ABRAMS PLACE, 600 NKAWU STREET, KEKANA PARK, #, #, ENNERDALE, South Africa','geo':{'lat':-26.39976,'lng':27.90444}},
{'stp':392754,'address':'392754, EGL - SOWETO, TAI HIGGO, THREE KINGS, 29 NO., BANGALORE DRIVE, #, LENASIA, South Africa','geo':{'lat':-27.85507,'lng':26.33270}},
{'stp':393110,'address':'393110, EGL - SOWETO, TAI HIGGO, DENZIL S TAVERN, 4409/70 SYLVINE STREET, ENNERDALE EXT 6, #, ENNERDALE, South Africa','geo':{'lat':-26.41703,'lng':27.84887}},
{'stp':461601,'address':'461601, EGL - SOWETO, TAI HIGGO, RAYS INN, STAND NO, 1631 EXT 1, LAWLEY, LAWLEY, South Africa','geo':{'lat':-26.39474,'lng':27.81407}},
{'stp':466149,'address':'466149, EGL - SOWETO, TAI HIGGO, LOZIS, 6264  EXT 8, ANTONITE CRESCENT, #, ENNERDALE, South Africa','geo':{'lat':-26.41990,'lng':27.81520}},
{'stp':471871,'address':'471871, EGL - SOWETO, TAI HIGGO, DIPATI, 18 STAND NO, BLUE HEAD STREET, LAWLEY EXT 1, LAWLEY, South Africa','geo':{'lat':-26.38824,'lng':27.80908}},
{'stp':472369,'address':'472369, EGL - SOWETO, TAI HIGGO, KB KHANYILE, 3668 FINETOWN, #, #, ENNERDALE, South Africa','geo':{'lat':-26.41384,'lng':27.86824}},
{'stp':476097,'address':'476097, EGL - SOWETO, TAI HIGGO, HAPPY LIQUOR TAVERN, STAND NO, GALIEB EBRAHIM ESSOP, GRASMERE STATION 2010 5th AVENUE, ENNERDALE, South Africa','geo':{'lat':-26.42655,'lng':27.86841}},
{'stp':478352,'address':'478352, EGL - SOWETO, TAI HIGGO, MOHLAKENG PUB, ERF 263/23, NO 2874, FINETOWN, ENNERDALE, South Africa','geo':{'lat':-26.41387,'lng':27.86477}},
{'stp':481067,'address':'481067, EGL - SOWETO, TAI HIGGO, ZETETO INN, 205 STAND NO, PHILLIP STREET, FINETOWN, LENASIA, South Africa','geo':{'lat':-26.42858,'lng':27.86718}},
{'stp':485984,'address':'485984, EGL - SOWETO, TAI HIGGO, ALL IN ONE, 0141 STAND NO, SCORPIO STREET, ENNERDALE EXT 1, ENNERDALE, South Africa','geo':{'lat':-26.40293,'lng':27.84116}},
{'stp':494758,'address':'494758, EGL - SOWETO, TAI HIGGO, DITAUNG INN, 874 MTHONYANA STREET BLOCK A POORTJ, #, #, LAWLEY, South Africa','geo':{'lat':-26.45576,'lng':27.76724}},
{'stp':540927,'address':'540927, EGL - SOWETO, TAI HIGGO, HAPPY LIQUOR STORE, STAND NO, HAPPY SHOPPING COMPLEX, GRASMERE STATION NO 210 FIFTH AVENUE, ENNERDALE, South Africa','geo':{'lat':-26.42607,'lng':27.86336}},
{'stp':550013,'address':'550013, EGL - SOWETO, TAI HIGGO, ELLA BY NIGHT TAVERN, 6625 ERIGON CRESCENT, ENNERDALE EXT 2, #, ENNERDALE, South Africa','geo':{'lat':-26.39700,'lng':27.84060}},
{'stp':550487,'address':'550487, EGL - SOWETO, TAI HIGGO, JET LIQUOR STORE, 20 TUNGSTEN PLACE MIGSON MANOR, ENNERDALE, #, ENNERDALE, South Africa','geo':{'lat':-26.41620,'lng':27.87340}},
{'stp':551803,'address':'551803, EGL - SOWETO, TAI HIGGO, SUN TIME LIQUOR STORE, PORT 122 OLD VEREENIGING RD HARTZEN, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.38080,'lng':27.97450}},
{'stp':561090,'address':'561090, EGL - SOWETO, TAI HIGGO, SESIS PLACE, 6029/19 CNR CRYOLITE & QUARTZ, ENNERDALE EXT 8, #, ENNERDALE, South Africa','geo':{'lat':-26.42040,'lng':27.81370}},
{'stp':580353,'address':'580353, EGL - SOWETO, TAI HIGGO, SUZANS PLACE, 1024 MACKEREL RD, LAWLEY EXT 1, #, LAWLEY, South Africa','geo':{'lat':-26.39631,'lng':27.81190}},
{'stp':580540,'address':'580540, EGL - SOWETO, TAI HIGGO, GOOD FELLA\'S TAVERN, 61 NEPTUNE STREET, ENNERDALE EXT 1, #, ENNERDALE, South Africa','geo':{'lat':-26.40680,'lng':27.83990}},
{'stp':586003,'address':'586003, EGL - SOWETO, TAI HIGGO, QUEENS INN, STAND 855, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.45640,'lng':27.76730}},
{'stp':586108,'address':'586108, EGL - SOWETO, TAI HIGGO, LAPOLOGA INN, 166/7 BLOCK C, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.46176,'lng':27.77054}},
{'stp':592626,'address':'592626, EGL - SOWETO, TAI HIGGO, DANIELS SHEBEEN, STAND 2185, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.45982,'lng':27.77206}},
{'stp':601030,'address':'601030, EGL - SOWETO, TAI HIGGO, TSILANES TAVERN, STAND 1110, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.45620,'lng':27.77042}},
{'stp':603345,'address':'603345, EGL - SOWETO, TAI HIGGO, SUNRISE TRADING, 1072 PHILLIP STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42570,'lng':27.87020}},
{'stp':603393,'address':'603393, EGL - SOWETO, TAI HIGGO, EMLANJENIS INN CERT, STAND 1400, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.45988,'lng':27.76271}},
{'stp':604575,'address':'604575, EGL - SOWETO, TAI HIGGO, FINETOWN ENTERTAINMENT, 3762 BEATRICE STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41602,'lng':27.86983}},
{'stp':605320,'address':'605320, EGL - SOWETO, TAI HIGGO, ISAIAHS TAVERN, 36 PHILLIP STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42820,'lng':27.86580}},
{'stp':605359,'address':'605359, EGL - SOWETO, TAI HIGGO, BENNYS TAVERN, 2552, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41380,'lng':27.86090}},
{'stp':605732,'address':'605732, EGL - SOWETO, TAI HIGGO, TSHIDIS TAVERN PERMIT FINETOWN, 1052 PHILLIP STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42532,'lng':27.86876}},
{'stp':606796,'address':'606796, EGL - SOWETO, TAI HIGGO, MASHELES PLACE, 2906/5680 STAND NO, LAWLEY EXT 2, #, LAWLEY, South Africa','geo':{'lat':-26.28581,'lng':27.87006}},
{'stp':607399,'address':'607399, EGL - SOWETO, TAI HIGGO, MOSELES INN, 7037 THABANG STREET LAWLEY EXT3, LAWLEY EXT 3, #, LAWLEY, South Africa','geo':{'lat':-26.38128,'lng':27.80895}},
{'stp':625945,'address':'625945, EGL - SOWETO, TAI HIGGO, SUE S PLACE, 15 GRANAAT CRESCENT, Ennerdale ext 5, #, ENNERDALE, South Africa','geo':{'lat':-26.41240,'lng':27.82750}},
{'stp':627527,'address':'627527, EGL - SOWETO, TAI HIGGO, GOQOS INN PERMIT, 1076 TRIPOD CRESCENT, LAWLEY EXT 1, #, LAWLEY, South Africa','geo':{'lat':-26.39780,'lng':27.80920}},
{'stp':627552,'address':'627552, EGL - SOWETO, TAI HIGGO, MHLANGAS TAVERN PERMIT, 1323 PHILLIP STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42550,'lng':27.87160}},
{'stp':629442,'address':'629442, EGL - SOWETO, TAI HIGGO, JOE\'S JAZZ HOUSE, 1960, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.46170,'lng':27.77060}},
{'stp':629662,'address':'629662, EGL - SOWETO, TAI HIGGO, MUMS INN PERMIT, 5539/29 ERF, CORUNDUM SREET, ENNERDALE EXTENSION 8, LENASIA, South Africa','geo':{'lat':-26.40830,'lng':27.81990}},
{'stp':629946,'address':'629946, EGL - SOWETO, TAI HIGGO, PINKY\'S RES PLACE, 519 HEIRING CIRCLE, LAWLEY EXT 1, #, LAWLEY, South Africa','geo':{'lat':-26.39020,'lng':27.81410}},
{'stp':630364,'address':'630364, EGL - SOWETO, TAI HIGGO, GIVENS INN, 5 MAIN ROAD LAWLEY STATION, LAWLEY, #, LAWLEY, South Africa','geo':{'lat':-26.35320,'lng':27.83060}},
{'stp':630401,'address':'630401, EGL - SOWETO, TAI HIGGO, EMAKHEHLENI TAVERN, 2676, LAWLEY EXT 2, #, LAWLEY, South Africa','geo':{'lat':-26.37990,'lng':27.80120}},
{'stp':630744,'address':'630744, EGL - SOWETO, TAI HIGGO, MISH REST PUB, 3354 TITANIUM STR, LENASIA SOUTH EXT 7, #, LENASIA, South Africa','geo':{'lat':-26.41670,'lng':27.87290}},
{'stp':631139,'address':'631139, EGL - SOWETO, TAI HIGGO, SHEZI TAVERN, 2408, LAWLEY EXT 3 & 4, #, LAWLEY, South Africa','geo':{'lat':-26.37589,'lng':27.80441}},
{'stp':631398,'address':'631398, EGL - SOWETO, TAI HIGGO, MOTLOANGS INN, 12 RUBY CRESCENT, ENNERDALE EXT 13, #, ENNERDALE, South Africa','geo':{'lat':-26.42240,'lng':27.84700}},
{'stp':632438,'address':'632438, EGL - SOWETO, TAI HIGGO, DENISES PLACE, PLOT 10  9TH AVENUE, MID ENNERDALE, #, ENNERDALE, South Africa','geo':{'lat':-26.42020,'lng':27.83660}},
{'stp':632453,'address':'632453, EGL - SOWETO, TAI HIGGO, FRASER\'S INN, 2810 PHILIP STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41330,'lng':27.86360}},
{'stp':633272,'address':'633272, EGL - SOWETO, TAI HIGGO, GOING UP, 6160 DYKE CRESCENT, ENNERDALE EXT 8, #, ENNERDALE, South Africa','geo':{'lat':-26.42027,'lng':27.81844}},
{'stp':634026,'address':'634026, EGL - SOWETO, TAI HIGGO, DANES INN, 3 SYLVINE STREET, ENNERDALE EXT 6, #, ENNERDALE, South Africa','geo':{'lat':-26.41980,'lng':27.85240}},
{'stp':634163,'address':'634163, EGL - SOWETO, TAI HIGGO, KOENAS PARADISE, 511 PHILLIP STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42570,'lng':27.86380}},
{'stp':634749,'address':'634749, EGL - SOWETO, TAI HIGGO, ROSE\'S TAVERN, 185 GEMINI STREET, ENNERDALE EXT 1, #, ENNERDALE, South Africa','geo':{'lat':-26.40090,'lng':27.83660}},
{'stp':634775,'address':'634775, EGL - SOWETO, TAI HIGGO, NOMVULAS INN, 6560 STALAGNITE STREET, ENNERDALE EXT 8, #, ENNERDALE, South Africa','geo':{'lat':-26.42210,'lng':27.82560}},
{'stp':636010,'address':'636010, EGL - SOWETO, TAI HIGGO, SAMS PLACE, 8082 PAUL STREET, LAWLEY EXT 2, #, LAWLEY, South Africa','geo':{'lat':-26.38600,'lng':27.81010}},
{'stp':636984,'address':'636984, EGL - SOWETO, TAI HIGGO, TROMPIES POOL CLUB, 173 POSEIDON STREET, ENNERDALE EXT 3, #, ENNERDALE, South Africa','geo':{'lat':-26.40740,'lng':27.83950}},
{'stp':639117,'address':'639117, EGL - SOWETO, TAI HIGGO, PEACE CORNER, 603 PHILLIP STREET BLOCK 2, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42400,'lng':27.86390}},
{'stp':640265,'address':'640265, EGL - SOWETO, TAI HIGGO, SALANTIS INN, 30 ONYX AVENUE, ENNERDALE EXT 13, #, ENNERDALE, South Africa','geo':{'lat':-26.42180,'lng':27.84510}},
{'stp':640781,'address':'640781, EGL - SOWETO, TAI HIGGO, LAS VEGAS TAVERN, 1530, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42190,'lng':27.86330}},
{'stp':641578,'address':'641578, EGL - SOWETO, TAI HIGGO, PHUZA MNANDI BOTTLE STORE, 2584 FINE STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41310,'lng':27.86160}},
{'stp':641843,'address':'641843, EGL - SOWETO, TAI HIGGO, SETJHABA\'S PLACE, 2855 VANTOUNTEN DRIVE, WIELERSFARM, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.41390,'lng':27.89810}},
{'stp':641851,'address':'641851, EGL - SOWETO, TAI HIGGO, ZAKES TAVERN, 1938B MOUNT PLEASANT, WIELERSFARM, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.41010,'lng':27.90230}},
{'stp':641879,'address':'641879, EGL - SOWETO, TAI HIGGO, SHAKE\'S TAVERN, F72269, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41830,'lng':27.85950}},
{'stp':641894,'address':'641894, EGL - SOWETO, TAI HIGGO, THIBA\'S INN, 2242, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41920,'lng':27.85950}},
{'stp':641961,'address':'641961, EGL - SOWETO, TAI HIGGO, BONGANI\'S PLACE, 1751, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41910,'lng':27.86360}},
{'stp':641969,'address':'641969, EGL - SOWETO, TAI HIGGO, VIOLET\'S INN, 330 LENGAO STREET, WIELERSFARM, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.40420,'lng':27.89960}},
{'stp':641983,'address':'641983, EGL - SOWETO, TAI HIGGO, NOMBULELOS TAVERN, 6603 5TH AVENUE, LAWLEY ESTATE EXT 3, #, LAWLEY, South Africa','geo':{'lat':-26.38020,'lng':27.80530}},
{'stp':642005,'address':'642005, EGL - SOWETO, TAI HIGGO, DINANGWE PAULINAH\'S INN, 3278, WIELERSFARM, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.41430,'lng':27.89700}},
{'stp':642298,'address':'642298, EGL - SOWETO, TAI HIGGO, MAMA JACK, 275 PHILLIP STREET, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42943,'lng':27.86878}},
{'stp':642379,'address':'642379, EGL - SOWETO, TAI HIGGO, JET TAVERN, 20 TUNGTEN PLACE CNR BARIUM STR, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41620,'lng':27.87370}},
{'stp':647101,'address':'647101, EGL - SOWETO, TAI HIGGO, JOHNNYS TAVERN, 1671 PHILLIP STREET, FINE TOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41980,'lng':27.86330}},
{'stp':649133,'address':'649133, EGL - SOWETO, TAI HIGGO, SOUL TO SOUL, 803 MKHIWANE STR KANANA PARK SOUTH, WIELERSFARM, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.42000,'lng':27.89990}},
{'stp':649385,'address':'649385, EGL - SOWETO, TAI HIGGO, ELLA STAR TAVERN, 5522 ST POTASIUM, ENNERDALE EXT 8, #, ENNERDALE, South Africa','geo':{'lat':-26.40990,'lng':27.82280}},
{'stp':651002,'address':'651002, EGL - SOWETO, TAI HIGGO, MADAMS PLACE, PLOT 27 BLIGNAUTSRUS A/, VOORTREKKE, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.41090,'lng':27.95020}},
{'stp':652381,'address':'652381, EGL - SOWETO, TAI HIGGO, AGNELINDIS PLACE, 2826 5TH AVENUE, LAWLEY EXT 3, #, LAWLEY, South Africa','geo':{'lat':-26.38240,'lng':27.80570}},
{'stp':652640,'address':'652640, EGL - SOWETO, TAI HIGGO, THOKO S SHEBEEN, STAND NO, ERF881 PHASE 1 BLOCK B POORTJIE TOWNSHIP, ORANGE FARMS, LAWLEY, South Africa','geo':{'lat':-26.45640,'lng':27.76770}},
{'stp':653635,'address':'653635, EGL - SOWETO, TAI HIGGO, KOCHAS INN, 271 CRESMER, MOUNTAIN VIEW, #, LENASIA, South Africa','geo':{'lat':-26.42840,'lng':27.86220}},
{'stp':656579,'address':'656579, EGL - SOWETO, TAI HIGGO, DULLAHS TAVERN, 18 HEDERA AVENUE, ENNERDALE EXT 2, #, ENNERDALE, South Africa','geo':{'lat':-26.39820,'lng':27.84140}},
{'stp':659704,'address':'659704, EGL - SOWETO, TAI HIGGO, THOMAS MAHONINI INN PERMIT (NTO), 617, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.25350,'lng':27.90060}},
{'stp':664294,'address':'664294, EGL - SOWETO, TAI HIGGO, LUCKY SEVEN, 204, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41270,'lng':27.85790}},
{'stp':665470,'address':'665470, EGL - SOWETO, TAI HIGGO, BONGANIS PLACE, 3837 3RD AVENUE, LAWLEY 2, #, LAWLEY, South Africa','geo':{'lat':-26.38630,'lng':27.79390}},
{'stp':669080,'address':'669080, EGL - SOWETO, TAI HIGGO, ELDAHS PLACE, 6522 TRIDYMITE CRESCENT, ENNERDALE EXT 8, #, ENNERDALE, South Africa','geo':{'lat':-26.42160,'lng':27.82470}},
{'stp':669492,'address':'669492, EGL - SOWETO, TAI HIGGO, DONZAS INN, 246 RAMOKGELE STREET, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.45390,'lng':27.76890}},
{'stp':670194,'address':'670194, EGL - SOWETO, TAI HIGGO, CHEERS (NTO), 1753 KANANA, GRASMERE, #, ENNERDALE, South Africa','geo':{'lat':-26.40809,'lng':27.88671}},
{'stp':670370,'address':'670370, EGL - SOWETO, TAI HIGGO, SHUKUSHUKUMA INN (NTO), 1716 THULA MTWANA, GRASMERE, #, ENNERDALE, South Africa','geo':{'lat':-26.42895,'lng':27.89972}},
{'stp':671249,'address':'671249, EGL - SOWETO, TAI HIGGO, MZALAS PLACE, 12 TRIPLITE SLOT, ENNERDALE, #, ENNERDALE, South Africa','geo':{'lat':-26.41870,'lng':27.85020}},
{'stp':671600,'address':'671600, EGL - SOWETO, TAI HIGGO, BLACK GATES, 54 ALLIE SINGLE, ENNERDALE EXT 9, #, ENNERDALE, South Africa','geo':{'lat':-26.41280,'lng':27.83360}},
{'stp':672809,'address':'672809, EGL - SOWETO, TAI HIGGO, MVULAS PLACE, 292, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.45400,'lng':27.76820}},
{'stp':674636,'address':'674636, EGL - SOWETO, TAI HIGGO, DUPS, 55 VIRGO STREET, ENNERDALE EXT 1, #, ENNERDALE, South Africa','geo':{'lat':-26.40160,'lng':27.84180}},
{'stp':687594,'address':'687594, EGL - SOWETO, TAI HIGGO, RETHABILE LIQUOR STORE, 118 CENTRAL ROAD, FINETOWN, GRASMERE, ENNERDALE, South Africa','geo':{'lat':-26.41720,'lng':27.86010}},
{'stp':701913,'address':'701913, EGL - SOWETO, TAI HIGGO, CS PLACE, 5539/59 ZIRCONIUM STREET, #, ENNERDALE EXT 8, ENNERDALE, South Africa','geo':{'lat':-26.40840,'lng':27.82050}},
{'stp':722787,'address':'722787, EGL - SOWETO, TAI HIGGO, JABUS PLACE, 1428 THULAMTWANA SETTLEMENT, LENASIA, #, LENASIA, South Africa','geo':{'lat':-26.43210,'lng':27.89630}},
{'stp':726596,'address':'726596, EGL - SOWETO, TAI HIGGO, J AND R LIQUOR STORE, 139 FARM HARTZENBERGFONTEIN 332 OLD, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.41880,'lng':27.95800}},
{'stp':730178,'address':'730178, EGL - SOWETO, TAI HIGGO, D CH MAWAYNE SPORTS BAR, 32 NICCOLLETE CRESENT, ENNERDALE EXT 5, #, ENNERDALE, South Africa','geo':{'lat':-26.41980,'lng':27.82880}},
{'stp':737380,'address':'737380, EGL - SOWETO, TAI HIGGO, CYNTHIA S PALCE, 50 ZARIET CRESCENT, ENNERDALE EXT 5, #, ENNERDALE, South Africa','geo':{'lat':-26.41610,'lng':27.82680}},
{'stp':745565,'address':'745565, EGL - SOWETO, TAI HIGGO, OWEN S INN TAVERN, 12 SHOP NO, ERF 3608 ENNEX SHOPPING CENTRE, AGAAT STREET ENNERDALE EXT 5, ENNERDALE, South Africa','geo':{'lat':-26.41710,'lng':27.82470}},
{'stp':764825,'address':'764825, EGL - SOWETO, TAI HIGGO, MAVUTA VAYITWINI LIQUOR S TAVERN, ZITHA STREET, ERF 3738, LAWLEY EXT 10, LAWLEY, South Africa','geo':{'lat':-26.38906,'lng':27.79276}},
{'stp':769667,'address':'769667, EGL - SOWETO, TAI HIGGO, TALLS PLACE, 614  STAND NO, 9 FOREMAN STREET, #, LAWLEY, South Africa','geo':{'lat':-26.45596,'lng':27.76345}},
{'stp':769846,'address':'769846, EGL - SOWETO, TAI HIGGO, JAMES INN, 47  STAND NO, HALITE CRESCENT, ODIN PARK, ENNERDALE, South Africa','geo':{'lat':-26.39700,'lng':27.84060}},
{'stp':774221,'address':'774221, EGL - SOWETO, TAI HIGGO, DJ S INN, STAND NO, STREET NUMBER 28, ALABASTER ENNERDALE EXTENSION 10, ENNERDALE, South Africa','geo':{'lat':-26.42130,'lng':27.83940}},
{'stp':775684,'address':'775684, EGL - SOWETO, TAI HIGGO, THREE STAR, 3998 HINGALWI STREET, #, #, LAWLEY, South Africa','geo':{'lat':-26.38247,'lng':27.80571}},
{'stp':780193,'address':'780193, EGL - SOWETO, TAI HIGGO, FUNDISWAS TAVERN, 271 STAND NO, KAPOK, #, ENNERDALE, South Africa','geo':{'lat':-26.42596,'lng':27.82672}},
{'stp':783837,'address':'783837, EGL - SOWETO, TAI HIGGO, JEALOUS INN TAVERN, STAND NO, ERF 554 PHELA CLOSE WILLARS FARM, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.40647,'lng':27.90414}},
{'stp':793996,'address':'793996, EGL - SOWETO, TAI HIGGO, KHOMANANI INN, 881 STAND NO, WEILLARS FARM, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.38822,'lng':27.88861}},
{'stp':799619,'address':'799619, EGL - SOWETO, TAI HIGGO, CORNER TO CORNER TAVERN, 1271 KANANA PARK, #, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.41796,'lng':27.90325}},
{'stp':383271,'address':'383271, EGL - SOWETO, TAI HIGGO, DANS INN, 1296 STAND NO, ZONE 1, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.24634,'lng':27.94634}},
{'stp':389858,'address':'389858, EGL - SOWETO, TAI HIGGO, SIMANGELE S SHEBEEN, STAND NO, #, #, LENASIA, South Africa','geo':{'lat':-26.37843,'lng':27.87712}},
{'stp':389890,'address':'389890, EGL - SOWETO, TAI HIGGO, POP BOTTLES LIQUORS, 173 STAND NO, 7TH AVENUE MID ENNERDALE, #, ENNERDALE, South Africa','geo':{'lat':-26.41209,'lng':27.83931}},
{'stp':391164,'address':'391164, EGL - SOWETO, TAI HIGGO, SKILA TRAIDING, 2594 STAND NO, MOKWENE STREET, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27590,'lng':27.84634}},
{'stp':392132,'address':'392132, EGL - SOWETO, TAI HIGGO, GOODFELLOWS LIQUOR STORE, 505 STAND NO, SHOP 1 AND 2 ,ENNERDALE SHOPPING CENTER, 170 CARINA ROAD CORNER OF POSEIDON ROAD, ENNERDALE, South Africa','geo':{'lat':-26.40170,'lng':27.83629}},
{'stp':393299,'address':'393299, EGL - SOWETO, TAI HIGGO, PHEZULU SA HOLDINGS PTY LTD, 6785 ERF, SHEFFIELD STREET, LENASIA SOUTH EXT 4, LENASIA SOUTH, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':464387,'address':'464387, EGL - SOWETO, TAI HIGGO, MUZIS INN, 32 STAND NO, HEATHER CRESCENT LEHAE, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.35251,'lng':27.88973}},
{'stp':472124,'address':'472124, EGL - SOWETO, TAI HIGGO, BRA GS PLACE, 3222 STAND NO, 16 MAMBA CRESCENT, DIEPKLLOF ZONE 2 SOWETO, DIEPKLOOF, South Africa','geo':{'lat':-26.25228,'lng':27.94553}},
{'stp':484595,'address':'484595, EGL - SOWETO, TAI HIGGO, ZONE 14 BOTTLE STORE, 23 STAND NO, NGOBO STREET, ORLANDO, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24583,'lng':27.92231}},
{'stp':489573,'address':'489573, EGL - SOWETO, TAI HIGGO, MIDWAY LIQUOR WHOLESALERS, 3672 STAND NUMBER, BRAMISCHERVILLE DRIVE, BRAAMFISCHER, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19394,'lng':27.85651}},
{'stp':550178,'address':'550178, EGL - SOWETO, TAI HIGGO, BRODY S LIQUOR STORE, 97 ARLBERG AVE, ELDORADO PARK EXT 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28920,'lng':27.92090}},
{'stp':550632,'address':'550632, EGL - SOWETO, TAI HIGGO, NONCEBA BOTTLE STORE, 1200 MAYIBUYE GARAGE, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.25950,'lng':27.91460}},
{'stp':550701,'address':'550701, EGL - SOWETO, TAI HIGGO, BRUNOS LIQUOR MART, 8571 VOLTA STREET, LENASIA EXT 10, #, LENASIA, South Africa','geo':{'lat':-26.34320,'lng':27.85890}},
{'stp':550875,'address':'550875, EGL - SOWETO, TAI HIGGO, MR ES INN, 1617 LITHOMBENI STREET, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28360,'lng':27.86820}},
{'stp':551187,'address':'551187, EGL - SOWETO, TAI HIGGO, HADZHI BOTTLE STORE, 4125 CNR MHLABA DR & KUBAYI ST, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28790,'lng':27.86790}},
{'stp':551549,'address':'551549, EGL - SOWETO, TAI HIGGO, FETSI S TAVERN, 1506 MOLETLEDI STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26970,'lng':27.84950}},
{'stp':551852,'address':'551852, EGL - SOWETO, TAI HIGGO, BOYZ LIQUOR STORE, 96 EAST ROAD, ELDORADO PARK EXT 9, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29380,'lng':27.89030}},
{'stp':551859,'address':'551859, EGL - SOWETO, TAI HIGGO, WIELERSFARM LIQUOR STORE, PLOT 6 ALTHEA AGRICULTURAL HOLDINGS, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.42420,'lng':27.90980}},
{'stp':552410,'address':'552410, EGL - SOWETO, TAI HIGGO, BIG DADDYS DEVLAND, CNR EAST ST & HOUTHAMMER ROAD, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.27940,'lng':27.93240}},
{'stp':552574,'address':'552574, EGL - SOWETO, TAI HIGGO, UBUNTU LIQUOR, PORTION 30 LOT 3 GROUND FLOOR, 30 UNION ROAD, KLIPRIVIEROOG ESTATE, ELDORADO PARK, South Africa','geo':{'lat':-26.27860,'lng':27.88900}},
{'stp':552728,'address':'552728, EGL - SOWETO, TAI HIGGO, GEORGIES INN, 3236 MOSIA STR, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24610,'lng':27.93720}},
{'stp':552891,'address':'552891, EGL - SOWETO, TAI HIGGO, GOLDEN HIGHWAY LIQUOR STORE, PLOT 44 UNIVILLE OK CENTRE GOLDEN H, LENASIA, #, LENASIA, South Africa','geo':{'lat':-26.39503,'lng':27.87779}},
{'stp':553842,'address':'553842, EGL - SOWETO, TAI HIGGO, MANGOVUS INN PERMIT, STAND 10825 1261A KUBU STR, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26060,'lng':27.91330}},
{'stp':560391,'address':'560391, EGL - SOWETO, TAI HIGGO, REGGIES PUB, 1683 LEGWALE STR, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25460,'lng':27.85350}},
{'stp':561103,'address':'561103, EGL - SOWETO, TAI HIGGO, SIYAKHULA TAVERN, 100 NANCEFIELD HOSTEL, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25630,'lng':27.89950}},
{'stp':561159,'address':'561159, EGL - SOWETO, TAI HIGGO, TOWN ROAD LIQUOR STORE, 3 TOWN ROAD, ENNERDALE, #, ENNERDALE, South Africa','geo':{'lat':-26.41020,'lng':27.83320}},
{'stp':580537,'address':'580537, EGL - SOWETO, TAI HIGGO, KIPPIES PLACE, 57 FONTEIN PLACE, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29500,'lng':27.89950}},
{'stp':602902,'address':'602902, EGL - SOWETO, TAI HIGGO, MIDWAY WHOLESALERS, MIDWAY WHOLESALERS, ERF 155 KLIPRIVIERSOOG, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28810,'lng':27.84980}},
{'stp':604044,'address':'604044, EGL - SOWETO, TAI HIGGO, DODOS TAVERN, 1277 GWALE STR, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26900,'lng':27.84670}},
{'stp':604338,'address':'604338, EGL - SOWETO, TAI HIGGO, WHITE HOUSE, 240 MPHAHLELE STR, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25490,'lng':27.86680}},
{'stp':605355,'address':'605355, EGL - SOWETO, TAI HIGGO, VIMEX, 167 SIXTH AVE, MID ENNERDALE, #, ENNERDALE, South Africa','geo':{'lat':-26.41720,'lng':27.84410}},
{'stp':606531,'address':'606531, EGL - SOWETO, TAI HIGGO, M N D MASEKO AND SONS, 1313 MUSSELCRACKER CR, LAWLEY EXT 1, #, LAWLEY, South Africa','geo':{'lat':-26.39350,'lng':27.81450}},
{'stp':606543,'address':'606543, EGL - SOWETO, TAI HIGGO, ZOZOS PLACE, 215, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28628,'lng':27.93877}},
{'stp':625093,'address':'625093, EGL - SOWETO, TAI HIGGO, PETERS TAVERN, 1655 UNION ROAD, KLIPTOWN, #, ELDORADO PARK, South Africa','geo':{'lat':-26.27679,'lng':27.88709}},
{'stp':626839,'address':'626839, EGL - SOWETO, TAI HIGGO, SHLOBOS TAVERN, 8246 A, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25720,'lng':27.94750}},
{'stp':628250,'address':'628250, EGL - SOWETO, TAI HIGGO, LAWLEY LIQOUR STORE, 65 MAIN LAWLEY ROAD, LAWLEY, #, LAWLEY, South Africa','geo':{'lat':-26.35480,'lng':27.83130}},
{'stp':630410,'address':'630410, EGL - SOWETO, TAI HIGGO, ZAKES, 1594 7th AVENUE, LAWLEY EXT 2, #, LAWLEY, South Africa','geo':{'lat':-26.38260,'lng':27.80085}},
{'stp':630989,'address':'630989, EGL - SOWETO, TAI HIGGO, MPO MURIDILI, 2150 THEMBELIHLE EXT 2, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37920,'lng':27.89370}},
{'stp':631281,'address':'631281, EGL - SOWETO, TAI HIGGO, TSOPLE\'S PLACE, 1418 PETE STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27070,'lng':27.84770}},
{'stp':633501,'address':'633501, EGL - SOWETO, TAI HIGGO, LIMPOPO LIQUOR STORE, 940/1 LIMPOPO STREET, SENAOANE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27300,'lng':27.86800}},
{'stp':635047,'address':'635047, EGL - SOWETO, TAI HIGGO, JAY\'S LIQUOR STORE, 5523 UNION STREET, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.27929,'lng':27.89271}},
{'stp':636807,'address':'636807, EGL - SOWETO, TAI HIGGO, CLUB 707, 607 BURRACUDA AVENUE, LAWLEY EXT 1, #, LAWLEY, South Africa','geo':{'lat':-26.38990,'lng':27.80910}},
{'stp':638774,'address':'638774, EGL - SOWETO, TAI HIGGO, MOEK PLACES SENIOR BAR AND RES, 3041 MATHITHIBALA STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26900,'lng':27.89490}},
{'stp':639115,'address':'639115, EGL - SOWETO, TAI HIGGO, MARIAS PLACE, FT 787, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.42290,'lng':27.86720}},
{'stp':646840,'address':'646840, EGL - SOWETO, TAI HIGGO, SOLOMONS INN, 1730 PLOT NO, FINETOWN, #, ENNERDALE, South Africa','geo':{'lat':-26.41860,'lng':27.86460}},
{'stp':647108,'address':'647108, EGL - SOWETO, TAI HIGGO, MAM DA, 169 TAKALANI STREET, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27750,'lng':27.86970}},
{'stp':649092,'address':'649092, EGL - SOWETO, TAI HIGGO, CARO\'S INN, 612 MANDIWANO STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27960,'lng':27.87420}},
{'stp':650290,'address':'650290, EGL - SOWETO, TAI HIGGO, BAFANAS INN, 1597 MSALWA STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27540,'lng':27.86310}},
{'stp':650437,'address':'650437, EGL - SOWETO, TAI HIGGO, SARAH, 22 NEPHRITE CRESCENT, ENNERDALE EXT 5, #, ENNERDALE, South Africa','geo':{'lat':-26.41890,'lng':27.82790}},
{'stp':653435,'address':'653435, EGL - SOWETO, TAI HIGGO, ZAMA ZAMA INN, 1514, VLAKFONTEIN EXT 1, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37500,'lng':27.88610}},
{'stp':747323,'address':'747323, EGL - SOWETO, TAI HIGGO, SYNKA LIQUORS, STREET NO, BLOCK J1 CORNER, WIMBLEDON ROAD KAISHA AVENUE, LENASIA, South Africa','geo':{'lat':-26.39400,'lng':27.87510}},
{'stp':747658,'address':'747658, EGL - SOWETO, TAI HIGGO, VHADAUS TAVERN, STREET NO, 3801 A ZONE 4, PIMVILLE, PIMVILLE, South Africa','geo':{'lat':-26.27010,'lng':27.89710}},
{'stp':774991,'address':'774991, EGL - SOWETO, TAI HIGGO, JOE JOHNS, 664 STAND NO, A LENKOE STREET PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27110,'lng':27.85040}},
{'stp':778871,'address':'778871, EGL - SOWETO, TAI HIGGO, GREEN GATE, 1083 STAND NO, 21 FONTEIN STREET ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29502,'lng':27.89730}},
{'stp':778969,'address':'778969, EGL - SOWETO, TAI HIGGO, MALUVELES TAVERN, 3628 STAND NO, EXT 3, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38886,'lng':27.88872}},
{'stp':781066,'address':'781066, EGL - SOWETO, TAI HIGGO, SIYAPHUMELELA INN, 0762 STAND NO, N SECTION THEMBELIHLE TOWNSHIP, #, LENASIA, South Africa','geo':{'lat':-26.33727,'lng':27.86570}},
{'stp':320501,'address':'320501, EGL - SOWETO, TAI HIGGO, PICK N PAY GS03-PNP LIQ STORE EMABH, 3297/884 ERF NO, EMABHELENI CENTRE, 884 VILAKAZI STREET, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.27116,'lng':27.87543}},
{'stp':351381,'address':'351381, EGL - SOWETO, TAI HIGGO, SHOPRITE LIQUORSHOP ENNERDALE LS 04, 1 STAND NO, MARAIS CLOSE ENNERDALE EXT 9, PORTION 1 OF ERF 5450, ENNERDALE, South Africa','geo':{'lat':-26.40325,'lng':27.83465}},
{'stp':385206,'address':'385206, EGL - SOWETO, TAI HIGGO, PICK N PAY DOBSONVILLE SHOPPING CEN, 75 ERF, CORNER OF CHRISTIAAN DUBE STREET, ELIAS MOTSOALEDI ROAD (M77), DOBSONVILLE, South Africa','geo':{'lat':-26.23068,'lng':27.83735}},
{'stp':388786,'address':'388786, EGL - SOWETO, TAI HIGGO, LEATLILE LEROU BOTTLE STORE, 132 STAND NO, UBUNYE DRIVE, SHOP2, BRAAMFISCHER COMPLEX, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19718,'lng':27.85668}},
{'stp':389864,'address':'389864, EGL - SOWETO, TAI HIGGO, PICK N PAY GS13  LINDA S MARKET, 5882 STAND NO, CNR MOKOKA AND MODJADJI STREET, #, PIMVILLE, South Africa','geo':{'lat':-26.25869,'lng':27.91519}},
{'stp':390422,'address':'390422, EGL - SOWETO, TAI HIGGO, PICK N PAY GG12 PNP LIQUOR SENAOANE, 1930 STAND NO, MABALANE STREET, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27281,'lng':27.86478}},
{'stp':468738,'address':'468738, EGL - SOWETO, TAI HIGGO, BROTHERS LIQUOR STORE, 2878 PHASE 1, 132 DUNYE RD ERF 2878, SHOP NO 2  CNR BRAAMFISCHER DRIVE, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.19770,'lng':27.85544}},
{'stp':469988,'address':'469988, EGL - SOWETO, TAI HIGGO, CHECKERS LIQSHOP 6679 MALL OF THE S, STAND NO, CNR SWARTKOPPIES AND KLIPRIVIER DRIVE, ASPEN LAKE, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31362,'lng':28.05830}},
{'stp':473058,'address':'473058, EGL - SOWETO, TAI HIGGO, GAME LIQUOR MALL OF SOUTH 286, STAND NO, SHOP G008 MALL OF THE SOUTH, CNR OF KLIPRIVIER AND SWARTKOPPIES RDS, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31584,'lng':28.05670}},
{'stp':473829,'address':'473829, EGL - SOWETO, TAI HIGGO, LIQUOR CITY ELDOS, STAND NO, UNIT 21, CRYSTAL MALL, CNR OF CAVENDISH, AND GEORGE ELLIOT STREET, ELDORADO PARK, South Africa','geo':{'lat':-26.28343,'lng':27.90504}},
{'stp':474107,'address':'474107, EGL - SOWETO, TAI HIGGO, BOXER SUPERLIQUORS PROTEA GLEN 254, ERF 3702 SHOPS 72 AND 73, GLEN SHOPPING CENTRE, PRETEA BOULEVARD AND RED CURRENT STREET, PROTEA, South Africa','geo':{'lat':-26.27318,'lng':27.81653}},
{'stp':477159,'address':'477159, EGL - SOWETO, TAI HIGGO, PICK N PAY LIQUOR STORE MALL OF THE, PORTION 37 THE FARM LIEFDE AND VRED, 104JR NEW MALL OF THE SOUTH SHOPPING CEN, CORNER OF KLIPRIVER DRIVE (R556) AND, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31281,'lng':28.05842}},
{'stp':480927,'address':'480927, EGL - SOWETO, TAI HIGGO, PNP FRANCHISE GM-53 FAMILY MOLETSAN, 22 SHOP NO, TSELES SHOPPPING CENTRE 2228, MATLAMO AND MULIWA STREETS, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.28444,'lng':27.85438}},
{'stp':481118,'address':'481118, EGL - SOWETO, TAI HIGGO, PICK N PAY FRANCHISE GG23, PROTEA NORTH SHOPPING CENTRE, CNR MDALOSE AND NDABA DRIVE, PROTEA NORTH, PROTEA, South Africa','geo':{'lat':-26.27256,'lng':27.83237}},
{'stp':488686,'address':'488686, EGL - SOWETO, TAI HIGGO, ACES LIQUOR STORE, 3608 STAND NO, AGRAAT STREET, ENNERDALE EXTENSION 5, ENNERDALE, South Africa','geo':{'lat':-26.41611,'lng':27.82676}},
{'stp':490226,'address':'490226, EGL - SOWETO, TAI HIGGO, PNP GS04 PNP MAKHETHA S LIQUOR STOR, STAND NO 1345 TO 1348, GROUND FLOOR MAKHETHA S SHOPPING CENTRE, LIMAKASO STREET CNR MOAHLOLI AND MPHUTI, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24502,'lng':27.87041}},
{'stp':491372,'address':'491372, EGL - SOWETO, TAI HIGGO, SHOPRITE LIQUORSHOP DEVLAND 35392, 38667 STAND NO, JAN NECFKER DRIVE, DEVLAND EXT 27, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29513,'lng':27.92442}},
{'stp':494727,'address':'494727, EGL - SOWETO, TAI HIGGO, PNP GS05  PNP LIQUOR STORE MANDISA, 1 ERF SHOP NO, 3487 VUNDLA DRIVE,  MOROKA SOWETO, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.25784,'lng':27.86732}},
{'stp':540103,'address':'540103, EGL - SOWETO, TAI HIGGO, PICK N PAY FAMILY GG01 BARA CITY, STAND NO, OLD POTCHEFSTROOM ERF 24464, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.25788,'lng':27.94301}},
{'stp':551180,'address':'551180, EGL - SOWETO, TAI HIGGO, SJJ ORLANDO CIRCLE LIQUOR STORE, 2752 STAND NO, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24410,'lng':27.93040}},
{'stp':551366,'address':'551366, EGL - SOWETO, TAI HIGGO, TINTI S BOTTLE STORE, SHOP 5 PALMS  CNR MAIN& FLORA, NATURENA, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.27640,'lng':27.96150}},
{'stp':551406,'address':'551406, EGL - SOWETO, TAI HIGGO, MAYFIELD PARK LIQUOR MARKET, 28 SPRINGBOK STREET, MAYFIELD PARK, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31170,'lng':28.01770}},
{'stp':552042,'address':'552042, EGL - SOWETO, TAI HIGGO, AJODHAS RESTAURANT AND BOTTLE STORE, 2589 GEMSBOK AVENUE, LENASIA EXT 1, #, LENASIA, South Africa','geo':{'lat':-26.31930,'lng':27.82800}},
{'stp':552173,'address':'552173, EGL - SOWETO, TAI HIGGO, LIQUOR CITY LENASIA, 02655 STAND NUMBER, CRN GEMSBOK AND PELICAN STREET, JOHANNESBURG, LENASIA, South Africa','geo':{'lat':-26.32150,'lng':27.82760}},
{'stp':553192,'address':'553192, EGL - SOWETO, TAI HIGGO, COLUMBINE CELLARS, S5 COLUMBINE CROSSING CNR COLUMBINE, MONDEOR EXT 5, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.26990,'lng':27.98800}},
{'stp':606799,'address':'606799, EGL - SOWETO, TAI HIGGO, SHOPRITE DOBSONVILLE 54627, 12469 STEVE KGAME DRIVE, DOBSONVILLE EXT 7, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23120,'lng':27.84430}},
{'stp':625113,'address':'625113, EGL - SOWETO, TAI HIGGO, 707 BOTTLE STORE, 9269 TUMAHOLE STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25570,'lng':27.87490}},
{'stp':628600,'address':'628600, EGL - SOWETO, TAI HIGGO, LOCO LIQ MONDEOR, 82 SWARTGOUD ST COLUMBINE SQ, MONDEOR, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.27180,'lng':28.02910}},
{'stp':630032,'address':'630032, EGL - SOWETO, TAI HIGGO, LIQUOR CITY SOUTHGATE, SHOP 15 SOUTHGATE VALUE MART, SOUTHGATE, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.26605,'lng':27.97803}},
{'stp':633626,'address':'633626, EGL - SOWETO, TAI HIGGO, LIQUOR CITY SOWETO, 10559 8 MOHLOKA STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26210,'lng':27.89940}},
{'stp':637818,'address':'637818, EGL - SOWETO, TAI HIGGO, SHOPRITE ZOLA 44022, SHOPRITE S/C 2705/13 BENDILE RD, ZOLA, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24190,'lng':27.84660}},
{'stp':639416,'address':'639416, EGL - SOWETO, TAI HIGGO, PICK N PAY SOWETO, 2127 OLD POTCH RD MAPONYA MALL, KLIPSPRUIT EXT 5, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25700,'lng':27.90360}},
{'stp':648591,'address':'648591, EGL - SOWETO, TAI HIGGO, LIQUOR CITY DOBSONVILLE, SHOP 71 DOBSONVILLE S/C CNR RDPOORT, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21270,'lng':27.86870}},
{'stp':655026,'address':'655026, EGL - SOWETO, TAI HIGGO, BOUNDARY TOPS, 97 BOUNDARY RD CNR JOHNSON & VINCEN, ELDORADO PARK EXT 9, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29920,'lng':27.89000}},
{'stp':655082,'address':'655082, EGL - SOWETO, TAI HIGGO, ELDO TOPS, 9 WITTEBERG STREET, ELDORADO PARK EXT 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28850,'lng':27.92220}},
{'stp':667194,'address':'667194, EGL - SOWETO, TAI HIGGO, LIQUOR CITY PROTEA GARDENS, SHOP 94 PROTEA GARDENS MALL CNR ALE, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28630,'lng':27.84880}},
{'stp':667531,'address':'667531, EGL - SOWETO, TAI HIGGO, BOTTOMS UP LIQUOR STORE, SHOP 8 MONDEOR VILLAGE S/C CNR COLU, MONDEOR, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.27770,'lng':28.00970}},
{'stp':671308,'address':'671308, EGL - SOWETO, TAI HIGGO, PICK N PAY MEADOWLANDS FGG08, SHOP 12 & MEADOWPOINT SC HECKROODT, MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22040,'lng':27.90000}},
{'stp':682044,'address':'682044, EGL - SOWETO, TAI HIGGO, SHOPRITE BARA MALL 58621, SHOP 3 CNR NICHOLAAS DR & OLD POTCH, DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25730,'lng':27.93080}},
{'stp':683747,'address':'683747, EGL - SOWETO, TAI HIGGO, BRAAMFISCHER TOPS, CNR FREEDOM DR & AMANDLA BLVD, BRAAMFISHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20220,'lng':27.86660}},
{'stp':685985,'address':'685985, EGL - SOWETO, TAI HIGGO, PICK N PAY DOBSONVILLE, 10415 MAHAJANE DR SHOP 1A DOBSONVIL, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23110,'lng':27.83790}},
{'stp':701147,'address':'701147, EGL - SOWETO, TAI HIGGO, WALKERVILLE GROCCER, CNR MAIN AND 4TH STREET, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.41770,'lng':27.95780}},
{'stp':707114,'address':'707114, EGL - SOWETO, TAI HIGGO, PICK N PAY SOUTHGATE, SOUTHGATE SHOPPING CENTRE, STAND NO 3 CORNER COLUMBIBE AND, RIFLE RANGE ROAD, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.26500,'lng':27.98140}},
{'stp':758875,'address':'758875, EGL - SOWETO, TAI HIGGO, SHOPRITE DIEPKLOOOF 39037, 1076 STAND NO, MARTHINUS SMULTS DRIVE, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24102,'lng':27.94995}},
{'stp':773691,'address':'773691, EGL - SOWETO, TAI HIGGO, CAMBRIDGE FOOD BARA HOTEL, 24459 STAND NO, KOKANA STREET ZONE 6, DEIPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.25800,'lng':27.94090}},
{'stp':774181,'address':'774181, EGL - SOWETO, TAI HIGGO, PICK N PAY DIEPKLOOF GC70, STAND NO, ERF 15988 - 15995, CORNER IMMINK DRIVE & PATRICK ROAD, DIEPKLOOF, South Africa','geo':{'lat':-26.24246,'lng':27.94858}},
{'stp':774297,'address':'774297, EGL - SOWETO, TAI HIGGO, KIBLER PARK TOPS LIQUOR STORE, 1213 STAND NO, KIBLER PARK MANSIONS 6 STANMORE CRESCENT, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.32450,'lng':28.00900}},
{'stp':775068,'address':'775068, EGL - SOWETO, TAI HIGGO, SHOPRITE LIQUORSHOP JABULANI 39053, STAND NO, ERVEN 2196 & 2197, SHOP 1A JABULANI MALL, JABULANI, SOWETO, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24952,'lng':27.85663}},
{'stp':778855,'address':'778855, EGL - SOWETO, TAI HIGGO, SHOPRITE ELDORADO PARK 66452, STANDS 4070 AND 4084, SHOP NO 5 SHOPRITE SHOPPING CENTRE, ELDORADO PARK EXTENSION 5, ELDORADO PARK, South Africa','geo':{'lat':-26.29124,'lng':27.90706}},
{'stp':778857,'address':'778857, EGL - SOWETO, TAI HIGGO, SHOPRITE NDOFAYA MEADOWLANDS 66347, STAND NO, CNR HEKROODT AND ODENNDAAL STREET, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.22213,'lng':27.90042}},
{'stp':779747,'address':'779747, EGL - SOWETO, TAI HIGGO, LIQUOR CITY LENASIA, STATION PLACE ROAD, PORTION 1-14 OF ERF 192, AND PORTION 2-4 OF ERF 178, LENASIA, South Africa','geo':{'lat':-26.31766,'lng':27.82704}},
{'stp':795092,'address':'795092, EGL - SOWETO, TAI HIGGO, OLD POTCH DISCOUNT LIQUOR, 2122 STAND NO, DHLAMINI EXTENSION 5 NTULI STREET, CNR OF OLD POTCHEFSTROOM ROAD, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.26581,'lng':27.87953}},
{'stp':795514,'address':'795514, EGL - SOWETO, TAI HIGGO, PICK N PAY FAMILY GF67 WINCHESTER C, 1629 STAND NO, WINCHESTER GREEN SHOPPING CENTRE SHOP NO, 1A CORNER SWARTGOUD & DELPHIUM STREETS, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.26900,'lng':28.02050}},
{'stp':318728,'address':'318728, EGL - SOWETO, TAI HIGGO, MATHEBULA, 9053 STAND NUMBER, BRAAMFISCHER, ROODERPOORT, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18751,'lng':27.86731}},
{'stp':387958,'address':'387958, EGL - SOWETO, TAI HIGGO, JUSTICE S PLACE, 13643 STAND NO, PHASE 2, BRAAMFISHER, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19156,'lng':27.86989}},
{'stp':388399,'address':'388399, EGL - SOWETO, TAI HIGGO, JACKO S PLACE, 10212 ERF, BRAAMFISCHER PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20032,'lng':27.85656}},
{'stp':388651,'address':'388651, EGL - SOWETO, TAI HIGGO, ZOZO S TAVERN, 3167 STAND NO, CONSORT DRIVE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20151,'lng':27.85639}},
{'stp':388698,'address':'388698, EGL - SOWETO, TAI HIGGO, NGCAMU S PLACE, 3209 STAND NO, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19695,'lng':27.86742}},
{'stp':388895,'address':'388895, EGL - SOWETO, TAI HIGGO, KWAMSOJA, 14457/100 STAND NO, PHASE 2 BRAAMFISCHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20337,'lng':27.85415}},
{'stp':388953,'address':'388953, EGL - SOWETO, TAI HIGGO, ONLY THE BRAVE 1, 5145 STAND NO, TYPHOON STREET EXT 2, BRAAMFISCHER, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19861,'lng':27.86903}},
{'stp':460650,'address':'460650, EGL - SOWETO, TAI HIGGO, NGUBS INN, 6423 UNUNYE DRIVE, BRAAMFISHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20135,'lng':27.85006}},
{'stp':460774,'address':'460774, EGL - SOWETO, TAI HIGGO, TWINS PLACE, 8601 PHASE 2 STAND NO, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19006,'lng':27.85955}},
{'stp':463529,'address':'463529, EGL - SOWETO, TAI HIGGO, GREENHOUSE, 15490 STAND NO, ABBEY&ARCHERY DRIVE, PHASE 3, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18669,'lng':27.83722}},
{'stp':468767,'address':'468767, EGL - SOWETO, TAI HIGGO, MKONWANA INN TAVERN, 7469 STAND, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20918,'lng':27.84895}},
{'stp':470952,'address':'470952, EGL - SOWETO, TAI HIGGO, MAVIMBELA\'S PLACE, 17952 STAND NO, BRAAMFISCHER, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19527,'lng':27.82588}},
{'stp':471048,'address':'471048, EGL - SOWETO, TAI HIGGO, MAZETS PLACE, 2915 STAND NO, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20057,'lng':27.85614}},
{'stp':478357,'address':'478357, EGL - SOWETO, TAI HIGGO, THULAMSINDOS PLACE, 1320 STAND NO, EXTENSION 2, BRAAMFISCHERVILLE, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19081,'lng':27.85981}},
{'stp':479091,'address':'479091, EGL - SOWETO, TAI HIGGO, NOKOS PLACE, 1981 STAND NUMBER, SESOTHO STREET, PHASE 1, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19684,'lng':27.85900}},
{'stp':485770,'address':'485770, EGL - SOWETO, TAI HIGGO, NTOMBIS TAVERN, 17682 STAND NO, BRAAMFISHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19330,'lng':27.82948}},
{'stp':486074,'address':'486074, EGL - SOWETO, TAI HIGGO, NDOSIS PLACE, 1688 STAND NUMBER, ACHIEVE BRAAMFISCHER, EXT 12 13, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19061,'lng':27.82154}},
{'stp':486423,'address':'486423, EGL - SOWETO, TAI HIGGO, ZINGYS TAVERN, 18409 STAND NO, EXT 13/14, PHASE 4, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20248,'lng':27.82130}},
{'stp':490168,'address':'490168, EGL - SOWETO, TAI HIGGO, GA MAHUMED, 2816 STAND NO, BRAAMFISCHER, PHASE 1, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19903,'lng':27.85501}},
{'stp':491254,'address':'491254, EGL - SOWETO, TAI HIGGO, LUCKY S PLACE, 125624 STAND NO, BRAAMFICHER PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18980,'lng':27.84130}},
{'stp':544193,'address':'544193, EGL - SOWETO, TAI HIGGO, KUBAYI TAVERN, 9707 STAND NO, BRAAMFISCHER, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19187,'lng':27.86497}},
{'stp':550773,'address':'550773, EGL - SOWETO, TAI HIGGO, FAT S TAVERN, 575, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20800,'lng':27.86060}},
{'stp':553055,'address':'553055, EGL - SOWETO, TAI HIGGO, TSHIDIS PLACE PERMIT, 818B VINCENT ROAD, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21670,'lng':27.90690}},
{'stp':553908,'address':'553908, EGL - SOWETO, TAI HIGGO, MATLAKALAS PLACE PERMIT, 4935 PHASE 1, BRAAMFISCHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19877,'lng':27.86383}},
{'stp':560336,'address':'560336, EGL - SOWETO, TAI HIGGO, LIZZYS PLACE, 893, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20670,'lng':27.86410}},
{'stp':560386,'address':'560386, EGL - SOWETO, TAI HIGGO, MIKES TAVERN, 3412, BRAAMFISCHERVILLE EXT 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19880,'lng':27.86650}},
{'stp':560477,'address':'560477, EGL - SOWETO, TAI HIGGO, EASYS PLACE, 9971, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19669,'lng':27.86799}},
{'stp':561181,'address':'561181, EGL - SOWETO, TAI HIGGO, FATS PLACE, 1873 VUZANE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22110,'lng':27.87330}},
{'stp':561182,'address':'561182, EGL - SOWETO, TAI HIGGO, QUEENZOS PLACE, 5021  PHASE 1, BRAAMFISCHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20040,'lng':27.86230}},
{'stp':561237,'address':'561237, EGL - SOWETO, TAI HIGGO, SIPHOS TAVERN, 13035, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19010,'lng':27.87170}},
{'stp':580357,'address':'580357, EGL - SOWETO, TAI HIGGO, MEMES PLACE, 5878, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19670,'lng':27.84920}},
{'stp':580773,'address':'580773, EGL - SOWETO, TAI HIGGO, MAJERYS PLACE, 104A  VINCENT ROAD, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21400,'lng':27.89880}},
{'stp':581275,'address':'581275, EGL - SOWETO, TAI HIGGO, NDILEKA PERMIT, 3695, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19730,'lng':27.85810}},
{'stp':603156,'address':'603156, EGL - SOWETO, TAI HIGGO, TEBOGO\'S PLACE, 518B PHATUDI STR, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21690,'lng':27.89520}},
{'stp':604457,'address':'604457, EGL - SOWETO, TAI HIGGO, AGNES SOFT DRINK, 3150 SANDERS ROAD, ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22240,'lng':27.89440}},
{'stp':605396,'address':'605396, EGL - SOWETO, TAI HIGGO, DIEPS INN, 6749, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20570,'lng':27.85420}},
{'stp':605945,'address':'605945, EGL - SOWETO, TAI HIGGO, CAT PLACE, 726 MADIKWE STR, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21955,'lng':27.89282}},
{'stp':605947,'address':'605947, EGL - SOWETO, TAI HIGGO, PHUMAS INN, 2758 YEZA ROAD, BRAAMFISHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20100,'lng':27.85240}},
{'stp':605948,'address':'605948, EGL - SOWETO, TAI HIGGO, KHUMALOS INN, 2430, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19630,'lng':27.85470}},
{'stp':606217,'address':'606217, EGL - SOWETO, TAI HIGGO, REBONE INN, 7156 STAND NO, BRAAMFISCHERVILLE  PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20190,'lng':27.84950}},
{'stp':606497,'address':'606497, EGL - SOWETO, TAI HIGGO, MADLOMOS TAVERN, 2215  PHASE 1, BRAAMFISCHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20250,'lng':27.86680}},
{'stp':606791,'address':'606791, EGL - SOWETO, TAI HIGGO, RATSOMA ENTERPRISE, 4654 BRAAMFISCHERVILLE, PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20045,'lng':27.86116}},
{'stp':607182,'address':'607182, EGL - SOWETO, TAI HIGGO, B AND K TAVERN, 7363, BRAAMFISCHERVILLE  PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20670,'lng':27.85190}},
{'stp':627120,'address':'627120, EGL - SOWETO, TAI HIGGO, TITIS PUB AND RESTAURANT PERMIT, 3721, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19690,'lng':27.85910}},
{'stp':627894,'address':'627894, EGL - SOWETO, TAI HIGGO, MODISES PLACE, 170A MOTSWEDI STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21570,'lng':27.89950}},
{'stp':627956,'address':'627956, EGL - SOWETO, TAI HIGGO, KIDOS PLACE PERMIT, 1889, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20788,'lng':27.86064}},
{'stp':628240,'address':'628240, EGL - SOWETO, TAI HIGGO, METHEMBES PLACE, 15854, BRAAMFISCHERVILLE PHASE 3, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20280,'lng':27.85452}},
{'stp':630159,'address':'630159, EGL - SOWETO, TAI HIGGO, TIRO S PUB AND GRILL, 294 SANDERS STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22160,'lng':27.89750}},
{'stp':630860,'address':'630860, EGL - SOWETO, TAI HIGGO, BAFANA\'S TAVERN, 5101 PULE AVENUE, BRAAMFISCHERVILLE EXT 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19869,'lng':27.87248}},
{'stp':631043,'address':'631043, EGL - SOWETO, TAI HIGGO, SIBONGILES PLACE, 821, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20710,'lng':27.86340}},
{'stp':631093,'address':'631093, EGL - SOWETO, TAI HIGGO, KHOSIS TUCK, 2748 BRAAMFISCHERVILLE PHASE 1, BRAAMFISCHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20070,'lng':27.85310}},
{'stp':631118,'address':'631118, EGL - SOWETO, TAI HIGGO, SAKIS PLACE, 18432 BRAAMFISCHERVILLE, PHASE 4, #, BRAAMFISCHER, South Africa','geo':{'lat':-26.20214,'lng':27.82074}},
{'stp':631750,'address':'631750, EGL - SOWETO, TAI HIGGO, SMACK DOWN, 7412 KHAYA BLOCK, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20890,'lng':27.85170}},
{'stp':631904,'address':'631904, EGL - SOWETO, TAI HIGGO, IKES PLACE, 5148 PULA AVENUE, BRAAMFISCHERVILLE EXT 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19830,'lng':27.86950}},
{'stp':632121,'address':'632121, EGL - SOWETO, TAI HIGGO, VUSI\'S INN, 10546, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19210,'lng':27.86100}},
{'stp':632166,'address':'632166, EGL - SOWETO, TAI HIGGO, NEM TUCKSHOP, 3178 braamfischer phase1, BRAAMFISCHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19740,'lng':27.86270}},
{'stp':632709,'address':'632709, EGL - SOWETO, TAI HIGGO, T-MAN\'S TUCKSHOP, 12210, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19310,'lng':27.85040}},
{'stp':633745,'address':'633745, EGL - SOWETO, TAI HIGGO, DANGER\'S INN, 10852, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19080,'lng':27.85820}},
{'stp':636690,'address':'636690, EGL - SOWETO, TAI HIGGO, MJIJOS PUB AND GRILL, 560 B SEBELE STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22832,'lng':27.90019}},
{'stp':636713,'address':'636713, EGL - SOWETO, TAI HIGGO, NINI\'S TAVERN, 10776, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19210,'lng':27.85900}},
{'stp':638832,'address':'638832, EGL - SOWETO, TAI HIGGO, NKOSIYETHUS INN, 2672, BRAAMFISCHERVILLE EXT 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19970,'lng':27.85500}},
{'stp':639019,'address':'639019, EGL - SOWETO, TAI HIGGO, ROSE PLACE, 6389, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20370,'lng':27.85190}},
{'stp':639108,'address':'639108, EGL - SOWETO, TAI HIGGO, LANGAS PLACE, 495B VAN ONSELEN ROAD, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21660,'lng':27.89590}},
{'stp':640408,'address':'640408, EGL - SOWETO, TAI HIGGO, KG CORNER SPOT AND PUB, 2139, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20330,'lng':27.86860}},
{'stp':641460,'address':'641460, EGL - SOWETO, TAI HIGGO, SEBENZA\'S PLACE, 5849, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19630,'lng':27.85030}},
{'stp':641465,'address':'641465, EGL - SOWETO, TAI HIGGO, TUMIS INN, 14457 STAND NO, 84 PHASE 2, BRAAMFISCHERVILLE, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19150,'lng':27.84940}},
{'stp':641518,'address':'641518, EGL - SOWETO, TAI HIGGO, SOPHIE\'S TAVERN, 8851, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18756,'lng':27.86208}},
{'stp':641820,'address':'641820, EGL - SOWETO, TAI HIGGO, EMANTOMBAZANENIS INN, 14457/63, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19060,'lng':27.84950}},
{'stp':642098,'address':'642098, EGL - SOWETO, TAI HIGGO, ANNA\'S PLACE, 8589, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19010,'lng':27.85870}},
{'stp':646900,'address':'646900, EGL - SOWETO, TAI HIGGO, JAMINA\'S PLACE, 3443 MEKGWE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21610,'lng':27.87180}},
{'stp':648678,'address':'648678, EGL - SOWETO, TAI HIGGO, KELE\'S PLACE, STAND NO 237-678 / 4678, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20460,'lng':27.85970}},
{'stp':648691,'address':'648691, EGL - SOWETO, TAI HIGGO, SHIPALANE\'S PLACE, 16023 EXT 12 & 13, BRAAMFISCHERVILLE EXT 12 & 13, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19800,'lng':27.84340}},
{'stp':649653,'address':'649653, EGL - SOWETO, TAI HIGGO, KO DI TILING, 226D MOTSEPE STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21820,'lng':27.89880}},
{'stp':650222,'address':'650222, EGL - SOWETO, TAI HIGGO, JALAMBAS INN, ERF 22986, 101B VINCENT ROAD, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21400,'lng':27.89820}},
{'stp':659444,'address':'659444, EGL - SOWETO, TAI HIGGO, DINAMENG LIQUORS, 1892 SEBEKO STR, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22000,'lng':27.87290}},
{'stp':660424,'address':'660424, EGL - SOWETO, TAI HIGGO, MUSA\'S TUCKSHOP, 2858 STAND NO, BLOCK 5 THULANI DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21750,'lng':27.83110}},
{'stp':661050,'address':'661050, EGL - SOWETO, TAI HIGGO, GREEEN HOUSE (NTO), 15490, BRAAMFISCHERVILLE PHASE 3, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.24255,'lng':27.90192}},
{'stp':663012,'address':'663012, EGL - SOWETO, TAI HIGGO, MANTATE, 02080 STAND NO, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20560,'lng':27.86730}},
{'stp':671096,'address':'671096, EGL - SOWETO, TAI HIGGO, STANS PLACE, 13356, BRAAMFISCHERVILLE PHASE 9, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19310,'lng':27.86910}},
{'stp':671668,'address':'671668, EGL - SOWETO, TAI HIGGO, THABEAS PLACE, 3528 MEKGWE STREET, #, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21610,'lng':27.87190}},
{'stp':685924,'address':'685924, EGL - SOWETO, TAI HIGGO, JOZI, ERF 4401, BRAAMFISCHERVILLE EXT 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19930,'lng':27.86080}},
{'stp':687596,'address':'687596, EGL - SOWETO, TAI HIGGO, CHINA TOWN TAVERN, 1818 VUZANE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22050,'lng':27.87260}},
{'stp':692999,'address':'692999, EGL - SOWETO, TAI HIGGO, MACHIPISANA TAVERN, 9794, BRAAMFISCHERVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19030,'lng':27.86790}},
{'stp':703986,'address':'703986, EGL - SOWETO, TAI HIGGO, HANNYS PLACE, 10553, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19260,'lng':27.86120}},
{'stp':705108,'address':'705108, EGL - SOWETO, TAI HIGGO, MAGOGO, 15225, BRAAMFISCHERVILLE PHASE 3, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18670,'lng':27.83000}},
{'stp':706759,'address':'706759, EGL - SOWETO, TAI HIGGO, MAMIYA, 3537, BRAAMFISCHERVILLE  PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19890,'lng':27.86480}},
{'stp':712167,'address':'712167, EGL - SOWETO, TAI HIGGO, LUCKY\'S TAVERN, 6737, BRAAMFISCHERVILLE  PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20540,'lng':27.85390}},
{'stp':728028,'address':'728028, EGL - SOWETO, TAI HIGGO, THABANES PLACE, 3722, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19690,'lng':27.85910}},
{'stp':731714,'address':'731714, EGL - SOWETO, TAI HIGGO, CAROLINES PLACE, 401 LEPETLE STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21890,'lng':27.89720}},
{'stp':747375,'address':'747375, EGL - SOWETO, TAI HIGGO, HLUNGWANES PLACE, 2365 ERF, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19700,'lng':27.85590}},
{'stp':747376,'address':'747376, EGL - SOWETO, TAI HIGGO, HAZELS PLACE, ERF 2695, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20020,'lng':27.85330}},
{'stp':757314,'address':'757314, EGL - SOWETO, TAI HIGGO, WHITE HOUSE TAVERN, 2332 STAND NO, BRAAMFISHERVILLE, ROODEPOORT, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19590,'lng':27.85240}},
{'stp':758331,'address':'758331, EGL - SOWETO, TAI HIGGO, SKOSANAS PLACE, 18238 ABBOT STREET, PHASE 4, BRAAMFISHERVILLE, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20030,'lng':27.82180}},
{'stp':762991,'address':'762991, EGL - SOWETO, TAI HIGGO, SHALALA S PLACE, 11001 STAND NO, PHASE 2, BRAAMFISCHERVILLE, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18810,'lng':27.83790}},
{'stp':764740,'address':'764740, EGL - SOWETO, TAI HIGGO, CHICKS PLACE, 1956 STAND NO, BRAAMFISHER PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20330,'lng':27.86710}},
{'stp':779647,'address':'779647, EGL - SOWETO, TAI HIGGO, NONHLANHLA S PLACE, 528 B STAND NO,  SECHELE STREET ZONE 2 MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21773,'lng':27.89446}},
{'stp':790640,'address':'790640, EGL - SOWETO, TAI HIGGO, MARIAS TAVERN, 12756 STAND NO, PHASE 2, BRAAMFISHERVILLE, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18980,'lng':27.84130}},
{'stp':794681,'address':'794681, EGL - SOWETO, TAI HIGGO, SESIS INN, 3796 STAND NO, 25 GRAPE STREET EXT 1, MZONGWANA ADMIN AREA, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19955,'lng':27.85959}},
{'stp':795014,'address':'795014, EGL - SOWETO, TAI HIGGO, MPHOS SOFT DRINK, STAND NO, ERF 6946, BRAAMFISHERVILLE PHASE 2, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20328,'lng':27.84879}},
{'stp':312664,'address':'312664, EGL - SOWETO, TAI HIGGO, MAMA JOY\'S INN, 5425 STAND NO, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28704,'lng':27.93849}},
{'stp':312710,'address':'312710, EGL - SOWETO, TAI HIGGO, TSAKANI, 5095 STAND NO, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28576,'lng':27.93831}},
{'stp':312721,'address':'312721, EGL - SOWETO, TAI HIGGO, TIBIES PLACE, 6006 STAND NO, EXT 33, FREEDOM PARK, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28813,'lng':27.94215}},
{'stp':377877,'address':'377877, EGL - SOWETO, TAI HIGGO, PULES PLACE, 5420 STAND NO, #, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28723,'lng':27.93794}},
{'stp':389142,'address':'389142, EGL - SOWETO, TAI HIGGO, KOENA LIQOUR STORE, 7829 STAND NO, KOKWANA STREET, DIEPKLOOF, ZONE 6, DIEPKLOOF, South Africa','geo':{'lat':-26.25732,'lng':27.94121}},
{'stp':390945,'address':'390945, EGL - SOWETO, TAI HIGGO, PALA S PLACE, 5700 STAND NO, #, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28992,'lng':27.94255}},
{'stp':390954,'address':'390954, EGL - SOWETO, TAI HIGGO, SIZAS PLACE, 8524 STAND NO, NKULULEKO STREET DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25725,'lng':27.95084}},
{'stp':392486,'address':'392486, EGL - SOWETO, TAI HIGGO, WISE AND GRACE, 1091120 MARS STREET, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29674,'lng':27.93427}},
{'stp':393387,'address':'393387, EGL - SOWETO, TAI HIGGO, DAN S PLACE, 3375 MEDLER STREET, EXT 26, NATURENA, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':472637,'address':'472637, EGL - SOWETO, TAI HIGGO, NTOMBIS INN, 108 STAND NO, 3090 INDUS STREET EXT 30, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30272,'lng':27.94195}},
{'stp':476413,'address':'476413, EGL - SOWETO, TAI HIGGO, PRIVEE, 6535 STAND NO, MAHAL PARK, DEVLAND, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28814,'lng':27.93491}},
{'stp':486082,'address':'486082, EGL - SOWETO, TAI HIGGO, MOUNTAIN SIDE, STAND NUMBER, 3811/55 MASANGO STREET, FREEDOM PARK, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28485,'lng':27.94057}},
{'stp':490167,'address':'490167, EGL - SOWETO, TAI HIGGO, JABULANI S TAVERN, 3811 STAND NUMBER, 75 FREEDOM PARK, SOWETO, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28622,'lng':27.93882}},
{'stp':550383,'address':'550383, EGL - SOWETO, TAI HIGGO, EIKENHOF LIQUOR STORE, PLOT 17 KLIPRIVIER STATION ROAD, EIKENHOF, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.33210,'lng':27.98560}},
{'stp':550916,'address':'550916, EGL - SOWETO, TAI HIGGO, CHELSEA LIQUOR STORE, ERF 10 AND 11 GOLDEN HIGHWAY, ARMADALE, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.27710,'lng':27.94480}},
{'stp':551019,'address':'551019, EGL - SOWETO, TAI HIGGO, PRO S INN, 1090 TLHOMPHANANG STREET, DEVLAND EXT 27, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30060,'lng':27.92830}},
{'stp':551525,'address':'551525, EGL - SOWETO, TAI HIGGO, MATHULAS INN, STAND 8062B, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25760,'lng':27.93930}},
{'stp':552280,'address':'552280, EGL - SOWETO, TAI HIGGO, GWAVA S PLACE, 8312 A, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25870,'lng':27.94700}},
{'stp':552930,'address':'552930, EGL - SOWETO, TAI HIGGO, NKS TAVERN, 8347 ZONE 6, DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25920,'lng':27.94760}},
{'stp':553167,'address':'553167, EGL - SOWETO, TAI HIGGO, NARE S INN, 8097A 8097A ZONE 6, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25890,'lng':27.93780}},
{'stp':553933,'address':'553933, EGL - SOWETO, TAI HIGGO, SECHABAS PLACE, 2452 PORTER STREET, NATURENA EXT 19, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.29370,'lng':27.95130}},
{'stp':560323,'address':'560323, EGL - SOWETO, TAI HIGGO, JUNIORS INN PERMIT, 2876 HAMILTON STREET, NATURENA EXT 19, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.29830,'lng':27.94980}},
{'stp':560324,'address':'560324, EGL - SOWETO, TAI HIGGO, TNTS PLACE PERMIT, 8244 B NKAMBULE STREET, ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25740,'lng':27.94770}},
{'stp':560466,'address':'560466, EGL - SOWETO, TAI HIGGO, MUZIS TAVERN, 3010/31 VAN VOUW STREET, NATURENA EXT 11, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28900,'lng':27.95000}},
{'stp':581122,'address':'581122, EGL - SOWETO, TAI HIGGO, MDLULIS PLACE, 8235B, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25680,'lng':27.94710}},
{'stp':603166,'address':'603166, EGL - SOWETO, TAI HIGGO, KWA NATHI INN, 3108 MPUMELELO ROAD, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30040,'lng':27.94000}},
{'stp':603616,'address':'603616, EGL - SOWETO, TAI HIGGO, RUTHS INN, 2318 CNR NELSON & WRIGHT STR, NATURENA EXT 19, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.29250,'lng':27.94930}},
{'stp':604276,'address':'604276, EGL - SOWETO, TAI HIGGO, VICKYS PUB AND RESTAURANT, 2236 WALLACE STR, NATURENA  EXT 19, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.29370,'lng':27.94760}},
{'stp':605517,'address':'605517, EGL - SOWETO, TAI HIGGO, SAMS PLACE, 2874 HAMILTON STREET, NATURENA EXT 19, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.29800,'lng':27.94980}},
{'stp':605899,'address':'605899, EGL - SOWETO, TAI HIGGO, E AND B, 2343 BENSON STR, NATURENA EXT 19, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.29370,'lng':27.94770}},
{'stp':606511,'address':'606511, EGL - SOWETO, TAI HIGGO, VERAS PLACE, 1127/30 MPUMELELO STREET, DEVLAND EXT 27, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30060,'lng':27.92680}},
{'stp':607180,'address':'607180, EGL - SOWETO, TAI HIGGO, NATALIES PLACE, 192 FREEDOM PARK, DEVLAND EXT 1, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28610,'lng':27.93410}},
{'stp':625212,'address':'625212, EGL - SOWETO, TAI HIGGO, THANDANI INN PERMIT, 4269 VOTING STATION STR, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29599,'lng':27.92643}},
{'stp':625222,'address':'625222, EGL - SOWETO, TAI HIGGO, VUYOS INN, 1123/140 THUTHUKANI STR, DEVLAND EXT 27, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30010,'lng':27.92500}},
{'stp':627939,'address':'627939, EGL - SOWETO, TAI HIGGO, LACOSTE TAVERN PERMIT, 3118 RODEALDER STREET, NATURENA, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.29270,'lng':27.96150}},
{'stp':628854,'address':'628854, EGL - SOWETO, TAI HIGGO, MACHIPISA PERMIT, 6357, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28840,'lng':27.93380}},
{'stp':629493,'address':'629493, EGL - SOWETO, TAI HIGGO, TONNYS TAVERN PERMIT, 8544A, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25710,'lng':27.94990}},
{'stp':630400,'address':'630400, EGL - SOWETO, TAI HIGGO, SONWABOS INN PERMIT, 3623 STAND NO, 5 ALTAIR STREET, FREEDOM PARK, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29774,'lng':27.93717}},
{'stp':630777,'address':'630777, EGL - SOWETO, TAI HIGGO, FANNIS TAVERN, 1620 FREEDOM PARK, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29450,'lng':27.93260}},
{'stp':632145,'address':'632145, EGL - SOWETO, TAI HIGGO, JB TAVERN, 2232 STAND NO, NOVA STREET FREEDOMPARK DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29680,'lng':27.93610}},
{'stp':633308,'address':'633308, EGL - SOWETO, TAI HIGGO, CAME\'S TAVERN, 2684 INKWENKWEZI, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29970,'lng':27.93930}},
{'stp':634148,'address':'634148, EGL - SOWETO, TAI HIGGO, PAPIS PLACE, 594 HONEY SUCKLE STR ALVEDA, EXT 2, KIBLER PARK, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.32220,'lng':28.00390}},
{'stp':634633,'address':'634633, EGL - SOWETO, TAI HIGGO, BATAUNGS PLACE, 54/3813 FREEDOM PARK, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29270,'lng':27.93020}},
{'stp':635155,'address':'635155, EGL - SOWETO, TAI HIGGO, MARK S PLACE, 8263 KANANA STREET, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25680,'lng':27.94400}},
{'stp':635432,'address':'635432, EGL - SOWETO, TAI HIGGO, NDIPHIWE\'S INN, 8508, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25830,'lng':27.95040}},
{'stp':635455,'address':'635455, EGL - SOWETO, TAI HIGGO, REGGIS PLACE, 89 LEO STREET, ENNERDALE EXT 1, #, ENNERDALE, South Africa','geo':{'lat':-26.40220,'lng':27.83990}},
{'stp':636270,'address':'636270, EGL - SOWETO, TAI HIGGO, PAT\'S INN, 5341 FREEDOM PARK, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28410,'lng':27.93750}},
{'stp':639018,'address':'639018, EGL - SOWETO, TAI HIGGO, MAZIBUKOS TAVERN, 1116, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28840,'lng':27.93180}},
{'stp':639359,'address':'639359, EGL - SOWETO, TAI HIGGO, MARIOS PLACE, 5783 FREEDOM PARK, DEVLAND EXT 33, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28800,'lng':27.94000}},
{'stp':639361,'address':'639361, EGL - SOWETO, TAI HIGGO, FREEDOM PARK LOUNGE, 4474 JAN DE NECKER DRIVE, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29590,'lng':27.92620}},
{'stp':641987,'address':'641987, EGL - SOWETO, TAI HIGGO, MILUVA\'S SHEBEEN, 4803 MAHALA PARK, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28490,'lng':27.94100}},
{'stp':642019,'address':'642019, EGL - SOWETO, TAI HIGGO, BROWN TABLE, 1206 NGAZA STREET, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29190,'lng':27.93520}},
{'stp':642210,'address':'642210, EGL - SOWETO, TAI HIGGO, THEMBIES INN, 5575, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28530,'lng':27.94370}},
{'stp':642240,'address':'642240, EGL - SOWETO, TAI HIGGO, RAYMOND SHEBEEN, 5494 FREEDOM PARK, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28600,'lng':27.94160}},
{'stp':642268,'address':'642268, EGL - SOWETO, TAI HIGGO, THANDEKILES SHEBEEN, 5187 FREEDOM PARK, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28540,'lng':27.93700}},
{'stp':642498,'address':'642498, EGL - SOWETO, TAI HIGGO, GLADYS PLACE, 1547 STAND NO, URAMUS STREET FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29020,'lng':27.93230}},
{'stp':642652,'address':'642652, EGL - SOWETO, TAI HIGGO, MANTOMBI\'S INN, 01126-104, DEVLAND EXT 27, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29947,'lng':27.92605}},
{'stp':646471,'address':'646471, EGL - SOWETO, TAI HIGGO, EIKENHOF TAVERN, PLOT 17 OLIFANTSVLEI CNR KLIPRIVIER, EIKENHOF, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.33260,'lng':27.98610}},
{'stp':646589,'address':'646589, EGL - SOWETO, TAI HIGGO, MAAGA TAVERN, 94, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28570,'lng':27.93520}},
{'stp':647686,'address':'647686, EGL - SOWETO, TAI HIGGO, MAMKHULU\'S PLACE, 1129/18 LEBOHANG STREET, DEVLAND EXT 27, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29950,'lng':27.93100}},
{'stp':648867,'address':'648867, EGL - SOWETO, TAI HIGGO, VELI\'S INN, 8438 DLAMINI STREET, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25640,'lng':27.94830}},
{'stp':650330,'address':'650330, EGL - SOWETO, TAI HIGGO, MATHABOS PLACE, 1123/93, DEVLAND EXT 27, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29902,'lng':27.92341}},
{'stp':652907,'address':'652907, EGL - SOWETO, TAI HIGGO, QEDIS PLACE, 8196B MABAXA STREET, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25690,'lng':27.94790}},
{'stp':653636,'address':'653636, EGL - SOWETO, TAI HIGGO, MABOTJA TAVERN, 3792 NKWEKWEZI STREET, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29900,'lng':27.93730}},
{'stp':661330,'address':'661330, EGL - SOWETO, TAI HIGGO, DENNNISSS PLACE, 2358 2358 FREEDOM PARK, TAURAES  STREET EXT 30, DEVLAND, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29770,'lng':27.93650}},
{'stp':661561,'address':'661561, EGL - SOWETO, TAI HIGGO, CHOMA S PLACE, 492 MAHOGANY STR ALVEDA EXT 2, KIBLER PARK, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.32150,'lng':28.00160}},
{'stp':667891,'address':'667891, EGL - SOWETO, TAI HIGGO, MUKHOMI TAVERN, 4978 FREEDOM PARK, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28370,'lng':27.94010}},
{'stp':676583,'address':'676583, EGL - SOWETO, TAI HIGGO, CARTERS INN, 3298 MYRTLE ROAD, NATURENA EXT 26, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.29508,'lng':27.96028}},
{'stp':682606,'address':'682606, EGL - SOWETO, TAI HIGGO, MAVENDENI, 3403 GEMINI STREET, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30110,'lng':27.94250}},
{'stp':687765,'address':'687765, EGL - SOWETO, TAI HIGGO, PINKYS TAVERN, 1530 EVENING STAR STREET, DEVLAND EXT 30, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29520,'lng':27.93390}},
{'stp':701734,'address':'701734, EGL - SOWETO, TAI HIGGO, GRANNYS, 4470, FREEDOM PARK EXT  2, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29570,'lng':27.92550}},
{'stp':701833,'address':'701833, EGL - SOWETO, TAI HIGGO, SCOTCH TAVERN, 33/6158, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28470,'lng':27.93370}},
{'stp':703073,'address':'703073, EGL - SOWETO, TAI HIGGO, MADABZAN, 5705 ZIMBABWE, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28620,'lng':27.94180}},
{'stp':703383,'address':'703383, EGL - SOWETO, TAI HIGGO, MASHUDU\'S TAVERN, 5191, FREEDOM PARK EXT 33, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28500,'lng':27.93730}},
{'stp':718212,'address':'718212, EGL - SOWETO, TAI HIGGO, KHOSAS TAVERN, 1690 SERIOUS STREET, FREEDOM PARK, DEVLAND, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29460,'lng':27.93090}},
{'stp':719502,'address':'719502, EGL - SOWETO, TAI HIGGO, HAPPY CITY, 1173 CAPELA STREET, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29170,'lng':27.93330}},
{'stp':720469,'address':'720469, EGL - SOWETO, TAI HIGGO, ZEEMAN\'S PLACE, 6238 MAHALA PARK, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28680,'lng':27.93430}},
{'stp':726321,'address':'726321, EGL - SOWETO, TAI HIGGO, DINANGWE INN, 3508 LETSATSI STREET, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30230,'lng':27.94270}},
{'stp':727930,'address':'727930, EGL - SOWETO, TAI HIGGO, MAKHUBELAS SHEBEEN, N92/97 STAND NO, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30470,'lng':27.94180}},
{'stp':731793,'address':'731793, EGL - SOWETO, TAI HIGGO, KHABAS PUB, 8112M, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25880,'lng':27.94200}},
{'stp':748000,'address':'748000, EGL - SOWETO, TAI HIGGO, FIKILES TAVERN, 1131-75, EXT  27, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30180,'lng':27.93440}},
{'stp':752793,'address':'752793, EGL - SOWETO, TAI HIGGO, ZODWA S PLACE, 8033A STAND NO, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25720,'lng':27.93800}},
{'stp':761120,'address':'761120, EGL - SOWETO, TAI HIGGO, MAAGA S BOTTLE STORE, 94 STAND NO, FREEDOM PARK, GOLDEN TRANGLE, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28570,'lng':27.93520}},
{'stp':767980,'address':'767980, EGL - SOWETO, TAI HIGGO, MOSES PLACE, 777 STAND NO, MOTSWALEDI ZONE 6 DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25660,'lng':27.94310}},
{'stp':768415,'address':'768415, EGL - SOWETO, TAI HIGGO, FIHLIWE S PLACE, 16987 STAND NO, 2640 MPUMELELO ROAD, FREEDOM PARK, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30120,'lng':27.93680}},
{'stp':775511,'address':'775511, EGL - SOWETO, TAI HIGGO, BASOTHONG S TAVERN, 5163 STAND NO, FREEDOM PARK MAHALA PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28640,'lng':27.93940}},
{'stp':775860,'address':'775860, EGL - SOWETO, TAI HIGGO, GETTYS LIQUOR SHOP, 8267 KHANAN STREET, ERF 23817, ZONE 6, DIEPKLOOF, South Africa','geo':{'lat':-26.25754,'lng':27.94410}},
{'stp':786912,'address':'786912, EGL - SOWETO, TAI HIGGO, NTWANANO, 8013 B STAND NO, ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25663,'lng':27.94013}},
{'stp':787722,'address':'787722, EGL - SOWETO, TAI HIGGO, ABENGUNI EXCLUSIVE, 1720 STAND NO, CALLISTO CRESS, FREEDOMPARK, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29420,'lng':27.93190}},
{'stp':787838,'address':'787838, EGL - SOWETO, TAI HIGGO, LIMPOPOS PLACE, 3009/21 BEYERS STREET, EXT 11, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.28658,'lng':27.94860}},
{'stp':792793,'address':'792793, EGL - SOWETO, TAI HIGGO, SUHIS PLACE, 24455 STAND NO, #, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25777,'lng':27.94746}},
{'stp':798577,'address':'798577, EGL - SOWETO, TAI HIGGO, KWA MFENE ZIYAVOVA, 1092/83 STAND NO, SIYAYA FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30442,'lng':27.94118}},
{'stp':318791,'address':'318791, EGL - SOWETO, TAI HIGGO, SAMMIS SHEBEEN, 2493 ERF NO, PROTEA SOUTH, SOWETO, PROTEA, South Africa','geo':{'lat':-26.28584,'lng':27.84735}},
{'stp':377411,'address':'377411, EGL - SOWETO, TAI HIGGO, YENDES, 1940 STAND NO, MANGROVE STREET, PROTEA GLEN EXT 1, PROTEA, South Africa','geo':{'lat':-26.27098,'lng':27.81455}},
{'stp':387821,'address':'387821, EGL - SOWETO, TAI HIGGO, CORNER HYS, 4426 STAND NO, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28486,'lng':27.84101}},
{'stp':388398,'address':'388398, EGL - SOWETO, TAI HIGGO, KWANDABA S PLACE, 82 STAND NO, SAGEWOOD STREET, #, PROTEA, South Africa','geo':{'lat':-26.26208,'lng':27.81145}},
{'stp':388966,'address':'388966, EGL - SOWETO, TAI HIGGO, THE LOUNGE, 2342 STAND NO, #, #, PROTEA, South Africa','geo':{'lat':-26.28401,'lng':27.84005}},
{'stp':389615,'address':'389615, EGL - SOWETO, TAI HIGGO, GA SEFOFANE LIFESTYLE, 3103 STAND NO, WINDSOR STREET, PROTEA SOUTH, PROTEA, South Africa','geo':{'lat':-26.28678,'lng':27.83806}},
{'stp':461600,'address':'461600, EGL - SOWETO, TAI HIGGO, KAYBEES INN, 105 STAND NO, THULWA STREET, #, PROTEA, South Africa','geo':{'lat':-26.27150,'lng':27.81580}},
{'stp':464740,'address':'464740, EGL - SOWETO, TAI HIGGO, MYAMBO LIQUOR DISTRIBUTORS, 3611 STAND NO, MADZENA STREET EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28948,'lng':27.85841}},
{'stp':474916,'address':'474916, EGL - SOWETO, TAI HIGGO, SIAS INN, 2491 LURULI STREET, CHAWELA EXT 7, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28072,'lng':27.85880}},
{'stp':476087,'address':'476087, EGL - SOWETO, TAI HIGGO, T AND Z LIQUOR STORE, 2174 STAND NO, MHLABA STREET, CHIAWELO, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27990,'lng':27.85502}},
{'stp':482401,'address':'482401, EGL - SOWETO, TAI HIGGO, THE GLEN INN, 1224 STAND NO, MAHOGANY STREET, #, PROTEA, South Africa','geo':{'lat':-26.26586,'lng':27.81351}},
{'stp':487411,'address':'487411, EGL - SOWETO, TAI HIGGO, THOPIS INN, 2314 STAND NO, CLERK STREET, PROTEA, PROTEA, South Africa','geo':{'lat':-26.28389,'lng':27.84111}},
{'stp':490152,'address':'490152, EGL - SOWETO, TAI HIGGO, PHINDIS PLACE, 5125 STAND NUMBER, CAPE ROBIN STREET, PROTEA GLEN, PROTEA, South Africa','geo':{'lat':-26.28067,'lng':27.82443}},
{'stp':541676,'address':'541676, EGL - SOWETO, TAI HIGGO, BILISI TARVEN, 2108 ROCKY STREET, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28412,'lng':27.84199}},
{'stp':545812,'address':'545812, EGL - SOWETO, TAI HIGGO, J M TAVERN, 4572 UNION STREET, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28215,'lng':27.83613}},
{'stp':550403,'address':'550403, EGL - SOWETO, TAI HIGGO, Z P S AND SONS, 2657 CROSSLEY STREET, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28370,'lng':27.83810}},
{'stp':550519,'address':'550519, EGL - SOWETO, TAI HIGGO, KG TAVERN, 135 GATEWAY CRESENT, MIDWAY, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28920,'lng':27.84850}},
{'stp':550534,'address':'550534, EGL - SOWETO, TAI HIGGO, CORNER HOUSE TAVERN, 2648, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27950,'lng':27.86050}},
{'stp':550539,'address':'550539, EGL - SOWETO, TAI HIGGO, EDDIES INN, STAND 2051 - 2054, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.26930,'lng':27.83550}},
{'stp':551970,'address':'551970, EGL - SOWETO, TAI HIGGO, VICTORIA FALLS TAVERN, 481 BOTHA STREET, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.27740,'lng':27.83290}},
{'stp':553587,'address':'553587, EGL - SOWETO, TAI HIGGO, LIEZY INN, 1016 NALEDI EXTENSION 2, SOWETO, #, PROTEA, South Africa','geo':{'lat':-26.26390,'lng':27.82430}},
{'stp':553809,'address':'553809, EGL - SOWETO, TAI HIGGO, VENDA SUN INN PERMIT, 1754A MLATI STREET, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28010,'lng':27.86200}},
{'stp':553811,'address':'553811, EGL - SOWETO, TAI HIGGO, TOCHS PLACE PERMIT, 1767A MLATIS STREET, SOWETO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28030,'lng':27.86210}},
{'stp':553869,'address':'553869, EGL - SOWETO, TAI HIGGO, BOBS INN, 3606 MAZIBUKO STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28900,'lng':27.85960}},
{'stp':560330,'address':'560330, EGL - SOWETO, TAI HIGGO, BEAUTYS TAVERN PERMIT, 646 MADIKANE STR, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.27550,'lng':27.83430}},
{'stp':560444,'address':'560444, EGL - SOWETO, TAI HIGGO, MOELES LAPENG, 613 KODI STR, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.27540,'lng':27.83180}},
{'stp':560570,'address':'560570, EGL - SOWETO, TAI HIGGO, PHILLYS INN, 4436 YUGAGA STREET, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.28470,'lng':27.81980}},
{'stp':580383,'address':'580383, EGL - SOWETO, TAI HIGGO, MUKEGULUNIS TAVERN, 3486 MAZIBUKO STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28850,'lng':27.85690}},
{'stp':580501,'address':'580501, EGL - SOWETO, TAI HIGGO, MARABAS TAVERN, 1994/46 CANDLEWOOD STR, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.27320,'lng':27.81650}},
{'stp':603602,'address':'603602, EGL - SOWETO, TAI HIGGO, JAZZ CORNER, 2720 EMPHIRE ROAD, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28210,'lng':27.83630}},
{'stp':604422,'address':'604422, EGL - SOWETO, TAI HIGGO, TENDIS TAVERN, 4798 MULUTANYI STR, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28210,'lng':27.85520}},
{'stp':604549,'address':'604549, EGL - SOWETO, TAI HIGGO, NEW ORLEANS, 1959 RAMOKGADI STR, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.28750,'lng':27.84330}},
{'stp':605321,'address':'605321, EGL - SOWETO, TAI HIGGO, TIMHAKA, 390314 TSAKANE STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28680,'lng':27.86440}},
{'stp':605415,'address':'605415, EGL - SOWETO, TAI HIGGO, PROTEA INN TAVERN, 2397 KGALATI CNR KUNENE STR, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.27420,'lng':27.83610}},
{'stp':605505,'address':'605505, EGL - SOWETO, TAI HIGGO, MPHAIS PLACE, 3200 MPHAPHULI STR, CHIAWELO 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28478,'lng':27.85798}},
{'stp':607335,'address':'607335, EGL - SOWETO, TAI HIGGO, MUM G TAVERN, 2518 OLD POTCH ROAD, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28210,'lng':27.85780}},
{'stp':624744,'address':'624744, EGL - SOWETO, TAI HIGGO, VIA S INN, 4258/60 MHLABA DRIVE, ERF 4258/60 MHLANGA DRIVE, CHIAWELO TOWNSHIP, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28713,'lng':27.86730}},
{'stp':625213,'address':'625213, EGL - SOWETO, TAI HIGGO, TUMIS PLACE, 557 NGQOYI STR, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.27880,'lng':27.83290}},
{'stp':626129,'address':'626129, EGL - SOWETO, TAI HIGGO, JANES TAVERN PERMIT, 1286 OLIFANTSOOR STR, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.26680,'lng':27.81570}},
{'stp':627088,'address':'627088, EGL - SOWETO, TAI HIGGO, SHIRLEYS PLACE PERMIT, 5902 SPORTED EAGLE OWL STREET, PROTEA GLEN EXT 4, #, PROTEA, South Africa','geo':{'lat':-26.28520,'lng':27.82830}},
{'stp':627774,'address':'627774, EGL - SOWETO, TAI HIGGO, TSAKANE BUTCHERY PERMIT, 3627 MAZIBUKO STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28940,'lng':27.85730}},
{'stp':629956,'address':'629956, EGL - SOWETO, TAI HIGGO, IRENE LUKHELE PERMIT, 5113 MOLIO STREET, PROTEA GLEN EXT 4, #, PROTEA, South Africa','geo':{'lat':-26.28130,'lng':27.82450}},
{'stp':630688,'address':'630688, EGL - SOWETO, TAI HIGGO, DAVID MABE, 4893, PROTEA GLEN EXT 4, #, PROTEA, South Africa','geo':{'lat':-26.28270,'lng':27.82360}},
{'stp':631040,'address':'631040, EGL - SOWETO, TAI HIGGO, KUBAYI\'S INN, 3829 MADZENA STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28910,'lng':27.86040}},
{'stp':631719,'address':'631719, EGL - SOWETO, TAI HIGGO, SOPHIES TAVERN, 1863 SOBU KHWE STREET, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.27090,'lng':27.83200}},
{'stp':632333,'address':'632333, EGL - SOWETO, TAI HIGGO, DANGARI\'S PLACE, 3301 NEFEFE STREET, CHIAWELO 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28390,'lng':27.85630}},
{'stp':632340,'address':'632340, EGL - SOWETO, TAI HIGGO, CORNER POCKET, 3852 KUBAYI STREET, CHIAWELO EXT, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28970,'lng':27.85990}},
{'stp':632385,'address':'632385, EGL - SOWETO, TAI HIGGO, LOI\'S ISLAND, 615 TSAMANE STREET, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28410,'lng':27.85260}},
{'stp':632593,'address':'632593, EGL - SOWETO, TAI HIGGO, MUMSY\'S PLACE, 611 TSAMENI STREET, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28440,'lng':27.85220}},
{'stp':633396,'address':'633396, EGL - SOWETO, TAI HIGGO, CONNIES JOINT, 2893, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.26980,'lng':27.83250}},
{'stp':633894,'address':'633894, EGL - SOWETO, TAI HIGGO, MARIAS INN, 3003 NEFEFE STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28230,'lng':27.85900}},
{'stp':634294,'address':'634294, EGL - SOWETO, TAI HIGGO, HOWARD\'S PLACE, 3675/208 QUARREY STREET, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28420,'lng':27.84570}},
{'stp':636004,'address':'636004, EGL - SOWETO, TAI HIGGO, ANNY\'S PLACE, 3128 NEPHALAMA STR, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28290,'lng':27.85970}},
{'stp':638057,'address':'638057, EGL - SOWETO, TAI HIGGO, DAN\'S INN (FR), 3420 YELLOW BILLED KITE STR, PROTEA GLEN  EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27850,'lng':27.82070}},
{'stp':638089,'address':'638089, EGL - SOWETO, TAI HIGGO, HAPPY\'S PLACE, 3271 CAPE VULTURE STR, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27950,'lng':27.82250}},
{'stp':638498,'address':'638498, EGL - SOWETO, TAI HIGGO, MBATHA\'S PLACE, 2929 NXUMALO STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28390,'lng':27.86300}},
{'stp':638522,'address':'638522, EGL - SOWETO, TAI HIGGO, PAPA GS PLACE, 5057 NEFEFE STREET, CHIAWELO EXT 4, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28580,'lng':27.85420}},
{'stp':638823,'address':'638823, EGL - SOWETO, TAI HIGGO, PRISCILLA\'S TAVERN, 8989/3 SIXAXAVISHA STREET, PROTEA GLEN EXT 3, #, PROTEA, South Africa','geo':{'lat':-26.28390,'lng':27.81800}},
{'stp':638834,'address':'638834, EGL - SOWETO, TAI HIGGO, RENNIES PLACE, 4220, PROTEA GLEN EXT 3, #, PROTEA, South Africa','geo':{'lat':-26.28330,'lng':27.81790}},
{'stp':638841,'address':'638841, EGL - SOWETO, TAI HIGGO, EMAHLANGENI\'S PLACE, 409 MADIKANE STREET, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.27600,'lng':27.83290}},
{'stp':639095,'address':'639095, EGL - SOWETO, TAI HIGGO, MAKHALES PLACE, 3307 NEFEFE STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28370,'lng':27.85680}},
{'stp':639310,'address':'639310, EGL - SOWETO, TAI HIGGO, VIRGIS PLACE, 3424 KUBAYI STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28950,'lng':27.85670}},
{'stp':639318,'address':'639318, EGL - SOWETO, TAI HIGGO, ELISA\'S PLACE, 2384 MULILO STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28090,'lng':27.85530}},
{'stp':639360,'address':'639360, EGL - SOWETO, TAI HIGGO, ASNATH INN, 2899 KANYENDA STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28430,'lng':27.86390}},
{'stp':640230,'address':'640230, EGL - SOWETO, TAI HIGGO, BENNYS TUCKSHOP, 5094 NEFEFE STREET, CHIAWELO EXT 4, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28560,'lng':27.85370}},
{'stp':640525,'address':'640525, EGL - SOWETO, TAI HIGGO, MAGOGO\'S INN, 2912 NXIMALO STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28540,'lng':27.86410}},
{'stp':641657,'address':'641657, EGL - SOWETO, TAI HIGGO, AUNTIE\'S PLACE, 2451, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28170,'lng':27.85660}},
{'stp':642566,'address':'642566, EGL - SOWETO, TAI HIGGO, SHWI AND TAPS KITCHEN, 1994/39 GIFT STREET, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.27280,'lng':27.81630}},
{'stp':647415,'address':'647415, EGL - SOWETO, TAI HIGGO, LEMON TWIST, 3377, PROTEA SOUTH ZONE 7, #, PROTEA, South Africa','geo':{'lat':-26.28890,'lng':27.84430}},
{'stp':648761,'address':'648761, EGL - SOWETO, TAI HIGGO, MAPULA, 3636, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27990,'lng':27.81840}},
{'stp':648764,'address':'648764, EGL - SOWETO, TAI HIGGO, ADELINE\'S PLACE, 3470 FISH EAGLE STREET, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.27779,'lng':27.82113}},
{'stp':649204,'address':'649204, EGL - SOWETO, TAI HIGGO, RAMBO\'S PLACE, 3073 NEFEFE STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28230,'lng':27.85900}},
{'stp':649424,'address':'649424, EGL - SOWETO, TAI HIGGO, MOTONE\'S PLACE, 16 PROTEA SQUARE RED CURRENT STR, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.27960,'lng':27.81660}},
{'stp':649916,'address':'649916, EGL - SOWETO, TAI HIGGO, RATAEMANE, 2826 NATAL FRANCOLINE STREET, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27590,'lng':27.82120}},
{'stp':650111,'address':'650111, EGL - SOWETO, TAI HIGGO, CORNER MONGWE, 2668 MONGWE STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27910,'lng':27.85930}},
{'stp':650565,'address':'650565, EGL - SOWETO, TAI HIGGO, DOCTORS PLACE, 3955 STAND NO, MAHLALELA STREET CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28860,'lng':27.86320}},
{'stp':650979,'address':'650979, EGL - SOWETO, TAI HIGGO, PHAKOS TAVERN, 6581 NTSAWISI STREET, CHIAWELO EXT 5, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28840,'lng':27.85470}},
{'stp':651176,'address':'651176, EGL - SOWETO, TAI HIGGO, LUCAS INN, 3102, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27920,'lng':27.82610}},
{'stp':651400,'address':'651400, EGL - SOWETO, TAI HIGGO, MUSHAVHI, 4905, PROTEA GLEN EXT 4, #, PROTEA, South Africa','geo':{'lat':-26.28270,'lng':27.82460}},
{'stp':652597,'address':'652597, EGL - SOWETO, TAI HIGGO, TOBEKAS SHEBEEN, 2532, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28720,'lng':27.84210}},
{'stp':654575,'address':'654575, EGL - SOWETO, TAI HIGGO, SANKARANI GROOVE BAR, 2774, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28390,'lng':27.86210}},
{'stp':660535,'address':'660535, EGL - SOWETO, TAI HIGGO, MOKGOSI S TAVERN, 3128 DOVER STREET, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28724,'lng':27.83843}},
{'stp':667914,'address':'667914, EGL - SOWETO, TAI HIGGO, MAGOLONGONDO PLACE, 2403, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28657,'lng':27.84291}},
{'stp':668031,'address':'668031, EGL - SOWETO, TAI HIGGO, CHILAS PLACE, 5596, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.28010,'lng':27.82910}},
{'stp':669384,'address':'669384, EGL - SOWETO, TAI HIGGO, MAWELA TAVERN, 2699, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27630,'lng':27.82140}},
{'stp':669674,'address':'669674, EGL - SOWETO, TAI HIGGO, AWELA S TAVERN, 3147 TSIWANE STREET, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28500,'lng':27.85900}},
{'stp':670591,'address':'670591, EGL - SOWETO, TAI HIGGO, GOODNESS, 2433 DUNDEE STREET, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28780,'lng':27.84400}},
{'stp':690160,'address':'690160, EGL - SOWETO, TAI HIGGO, TAMIAS PLACE, 513 QUEEN NINE STR, PROTEA GLEN, PHASE 4, PROTEA, South Africa','geo':{'lat':-26.27950,'lng':27.81670}},
{'stp':701039,'address':'701039, EGL - SOWETO, TAI HIGGO, BANDAS HIDE OUT, 5802, PROTEA GLEN EXT 4, #, PROTEA, South Africa','geo':{'lat':-26.28629,'lng':27.82708}},
{'stp':701733,'address':'701733, EGL - SOWETO, TAI HIGGO, RASIKHINYAS CORNER, 2446 PON PON STREET, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27070,'lng':27.81450}},
{'stp':702731,'address':'702731, EGL - SOWETO, TAI HIGGO, MUKUMIS PLACE, STAND NO, ERF 3201, MPHAPHUDI STREET, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28420,'lng':27.85830}},
{'stp':752123,'address':'752123, EGL - SOWETO, TAI HIGGO, SWEETS TAVERN, 5043 STAND NO, TLHLOKOTLHLOKO STREET, PROTEA GLEN EXTENSION 4, PROTEA, South Africa','geo':{'lat':-26.28450,'lng':27.82870}},
{'stp':756156,'address':'756156, EGL - SOWETO, TAI HIGGO, FACE TO FACE, SEGAWOD STREET, ERF 19993, PROTEA GLEN, PROTEA, South Africa','geo':{'lat':-26.27150,'lng':27.81580}},
{'stp':758303,'address':'758303, EGL - SOWETO, TAI HIGGO, SARAH S PLACE, 4539 STAND NO, USINGA STREET PROTEA GLEN EXT 3, #, PROTEA, South Africa','geo':{'lat':-26.26690,'lng':27.81550}},
{'stp':762777,'address':'762777, EGL - SOWETO, TAI HIGGO, BENMORE TAVERN, 118 BLACKBARK STREET, GLEN EXT 1, #, PROTEA, South Africa','geo':{'lat':-26.26390,'lng':27.80920}},
{'stp':762939,'address':'762939, EGL - SOWETO, TAI HIGGO, TSAKANI LIQUOR, 2447 UNION STREET, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28750,'lng':27.84330}},
{'stp':764824,'address':'764824, EGL - SOWETO, TAI HIGGO, VENDA SON, 2098  KUNENE STREET, #, #, PROTEA, South Africa','geo':{'lat':-26.26980,'lng':27.83570}},
{'stp':768708,'address':'768708, EGL - SOWETO, TAI HIGGO, JC TUCKSHOP AND TAVERN, 5106 UMLILO STREET, EXT 4, #, PROTEA, South Africa','geo':{'lat':-26.28200,'lng':27.82310}},
{'stp':772702,'address':'772702, EGL - SOWETO, TAI HIGGO, NDI NNYI S PLACE, 4854 STAND NO, 12 NGUNGUNYANE STREET EXT 2, CHIAWELO, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28480,'lng':27.86170}},
{'stp':774617,'address':'774617, EGL - SOWETO, TAI HIGGO, ELLET RAPHALANI, 2293 STAND NO, MHLABA DRIVE EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27858,'lng':27.86207}},
{'stp':776699,'address':'776699, EGL - SOWETO, TAI HIGGO, DAVIDS PLACE, 4959 STAND NO, KUBAYI STREET, EXTENSION 2, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28790,'lng':27.86790}},
{'stp':779018,'address':'779018, EGL - SOWETO, TAI HIGGO, RINGO TAVERN, 6574 NTSWANWASI STREET, EXT 5, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28893,'lng':27.85462}},
{'stp':794379,'address':'794379, EGL - SOWETO, TAI HIGGO, THOKOS PLACE, 6746 STAND NO, CHIAWELO EXT 5, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.29060,'lng':27.85640}},
{'stp':796871,'address':'796871, EGL - SOWETO, TAI HIGGO, MSEETY  PLACE, 3426 ZONE 7, #, #, PROTEA, South Africa','geo':{'lat':-26.29093,'lng':27.83926}},
{'stp':797510,'address':'797510, EGL - SOWETO, TAI HIGGO, JOYCES INN, 2747 CARON STREET, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27356,'lng':27.81925}},
{'stp':797513,'address':'797513, EGL - SOWETO, TAI HIGGO, MAGGIES PLACE, 22 STAND NO, WISANI LINK EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28753,'lng':27.85793}},
{'stp':799579,'address':'799579, EGL - SOWETO, TAI HIGGO, MAGEZI, 2301 STAND  NO, CLARKE STREET, #, PROTEA, South Africa','geo':{'lat':-26.28412,'lng':27.84199}},
{'stp':311542,'address':'311542, EGL - SOWETO, TAI HIGGO, BUSY CORNER, 3747 STAND NO, DODO STREET, VLAKFONTEIN, LENASIA SOUTH, South Africa','geo':{'lat':-26.38578,'lng':27.89007}},
{'stp':315737,'address':'315737, EGL - SOWETO, TAI HIGGO, BANYANA LOUNGE, 93F EXT 9, STAND NO, #, #, LENASIA, South Africa','geo':{'lat':-26.33358,'lng':27.85940}},
{'stp':388276,'address':'388276, EGL - SOWETO, TAI HIGGO, CHINA S TAVERN, 4730 STAND NO, MOUNTSHASTA STREET, EXT 4, LENASIA SOUTH, South Africa','geo':{'lat':-26.39456,'lng':27.85578}},
{'stp':388678,'address':'388678, EGL - SOWETO, TAI HIGGO, KWA VUSI S TAVERN, 2280 STAND NO, #, #, LENASIA, South Africa','geo':{'lat':-26.33397,'lng':27.85984}},
{'stp':390515,'address':'390515, EGL - SOWETO, TAI HIGGO, RND LIQUORS, 44 STAND NO, GEMSBOK STREET, BEING Erf3029, LENASIA,, #, LENASIA, South Africa','geo':{'lat':-26.32036,'lng':27.82795}},
{'stp':390648,'address':'390648, EGL - SOWETO, TAI HIGGO, LUCKYS INN, 2319 STAND NO, THEMBALIHLE LENASIA, #, LENASIA, South Africa','geo':{'lat':-26.33898,'lng':27.85927}},
{'stp':391173,'address':'391173, EGL - SOWETO, TAI HIGGO, WINNIE S INN, 1036 STAND NO, HOSPITAL HILLS LENASIA, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38032,'lng':27.85573}},
{'stp':393280,'address':'393280, EGL - SOWETO, TAI HIGGO, LIQUID YARD TAVERN, 4179 IBIS CRES STREET, #, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38451,'lng':27.88573}},
{'stp':393371,'address':'393371, EGL - SOWETO, TAI HIGGO, LENZ STATION LIQUOR EXPRESSS, PORTION 159 (A PORTION OF PORTION 5, THE FARM RIETFONTEIN 301 IQ, LENZ STATION SHOPPING CENTRE, LENASIA, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':461517,'address':'461517, EGL - SOWETO, TAI HIGGO, MARIAS TAVERN, 3933 STAND NO, VLAKFONTEIN EXT 3, #, LENASIA, South Africa','geo':{'lat':-26.38407,'lng':27.88786}},
{'stp':464512,'address':'464512, EGL - SOWETO, TAI HIGGO, PALILE TAVERN, 2619 STAND NO, VLAKFONTEIN EXT 3, MAPUTO SECTION, LENASIA SOUTH, South Africa','geo':{'lat':-26.39250,'lng':27.89060}},
{'stp':465700,'address':'465700, EGL - SOWETO, TAI HIGGO, NANCY\'S, 1792 STAND NO, LEHAE TOWNSHIP, JOHANNESBURG, LENASIA SOUTH, South Africa','geo':{'lat':-26.34556,'lng':27.88794}},
{'stp':468569,'address':'468569, EGL - SOWETO, TAI HIGGO, RENDANIS PLACE, 1038 HOSPITAL HILLS, #, #, LENASIA, South Africa','geo':{'lat':-26.38290,'lng':27.85650}},
{'stp':471303,'address':'471303, EGL - SOWETO, TAI HIGGO, BIG SHOW, 2373 VLAKFONTEIN, EXT 2, LENASIA SOUTH, LENASIA SOUTH, South Africa','geo':{'lat':-26.37483,'lng':27.88627}},
{'stp':472058,'address':'472058, EGL - SOWETO, TAI HIGGO, SIZA\'S PLACE, 914 STAND NO, VLAKFONTEIN, EXT 1, LENASIA SOUTH, South Africa','geo':{'lat':-26.37190,'lng':27.88880}},
{'stp':472174,'address':'472174, EGL - SOWETO, TAI HIGGO, MASUS PLACE, 11621 STAND NO, HELIODOR CRESCENT, LENASIA EXT 13, LENASIA SOUTH, South Africa','geo':{'lat':-26.31508,'lng':27.86388}},
{'stp':472242,'address':'472242, EGL - SOWETO, TAI HIGGO, KING SHOMOMBAS PLACE, 2148 STAND NO, 15 MARIGOLD STREET, LEHAE TOWNSHIP, LENASIA SOUTH, South Africa','geo':{'lat':-26.35115,'lng':27.88822}},
{'stp':476126,'address':'476126, EGL - SOWETO, TAI HIGGO, KHUMALOS PLACE(SSKBS), 5856 STAND NO, MOUNT AUX, SOURCE SOUTH, LENASIA, South Africa','geo':{'lat':-26.40139,'lng':27.86971}},
{'stp':477234,'address':'477234, EGL - SOWETO, TAI HIGGO, BUHLE BOTTLE STORE, 10758 STAND NO, AIRBORNE STREET, EXTENSION 13, LENASIA, South Africa','geo':{'lat':-26.30917,'lng':27.84792}},
{'stp':477235,'address':'477235, EGL - SOWETO, TAI HIGGO, SHABBAS PLACE, 2748 EXT 3, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39620,'lng':27.89094}},
{'stp':479158,'address':'479158, EGL - SOWETO, TAI HIGGO, BASOTHONGS TAVERN, 1884 STAND NO, JSMINE STREET, LEHAE, LENASIA SOUTH, South Africa','geo':{'lat':-26.34840,'lng':27.88660}},
{'stp':479793,'address':'479793, EGL - SOWETO, TAI HIGGO, ESTERS HOT SPOT, 4047 STAND N0, BRANDBURG STREET, LENASIA SOUTH EXTENSION 4, LENASIA SOUTH, South Africa','geo':{'lat':-26.39910,'lng':27.86520}},
{'stp':480892,'address':'480892, EGL - SOWETO, TAI HIGGO, SIYAPHAMBILE, 5474 STAND NUMBER, VLAKFONTEIN, LENASIA, LENASIA, South Africa','geo':{'lat':-26.37524,'lng':27.88703}},
{'stp':482315,'address':'482315, EGL - SOWETO, TAI HIGGO, VOSTERS INN, 275613 STAND NUMBER, EXT 13, VLOCKFONTEIN, LENASIA SOUTH, South Africa','geo':{'lat':-26.39727,'lng':27.89128}},
{'stp':482322,'address':'482322, EGL - SOWETO, TAI HIGGO, THANDIS LOUNGE, 5578 STAND NO, MATTERHORN STREET, LENASIA SOUTH EXT FOUR, LENASIA SOUTH, South Africa','geo':{'lat':-26.40079,'lng':27.87009}},
{'stp':482323,'address':'482323, EGL - SOWETO, TAI HIGGO, GERDIONS DISTRIBUTION AND TAVERN, 4146 STAND NO, VLAKFONTEIN EXT 3, LENASIA, LENASIA, South Africa','geo':{'lat':-26.37995,'lng':27.88517}},
{'stp':487860,'address':'487860, EGL - SOWETO, TAI HIGGO, DA DAP SHOP, STAND NO, ERF 1879, SHOP NO. 7, CORNER PETREA STARING AND WAMBELDON, LENASIA, South Africa','geo':{'lat':-26.38496,'lng':27.84829}},
{'stp':490149,'address':'490149, EGL - SOWETO, TAI HIGGO, NHOSES TAVERN, 2046 STAND NO, EXT 2 VLAKFONTEIN, #, LENASIA, South Africa','geo':{'lat':-26.37919,'lng':27.89206}},
{'stp':490900,'address':'490900, EGL - SOWETO, TAI HIGGO, NKALAKATAS PLACE, 1353/15 STAND NUMBER, VLAKFONTEIN EXT 1, LENASIA SOUTH, LENASIA SOUTH, South Africa','geo':{'lat':-26.37203,'lng':27.89160}},
{'stp':491170,'address':'491170, EGL - SOWETO, TAI HIGGO, MANANA TAVERN, 0037 STAND NO, HOSPITAL HILLS, EXT 1, LENASIA SOUTH, South Africa','geo':{'lat':-26.37823,'lng':27.85263}},
{'stp':491453,'address':'491453, EGL - SOWETO, TAI HIGGO, B6 DEIZAS PLACE, 1215 STAND NUMBER, VLAKFONTEIN EXT 1, LENASIA SOUTH, LENASIA SOUTH, South Africa','geo':{'lat':-26.38715,'lng':27.88833}},
{'stp':544697,'address':'544697, EGL - SOWETO, TAI HIGGO, GREENSIDE PLACE, 616915 STAND NO, VLAKFONTEIN EXT 11, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37314,'lng':27.88938}},
{'stp':546909,'address':'546909, EGL - SOWETO, TAI HIGGO, CK INN, 1249 CRENEBILL CRES, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.34343,'lng':27.89059}},
{'stp':551216,'address':'551216, EGL - SOWETO, TAI HIGGO, FIVE TRADERS LIQUOR STORE, 3461 PYRENEES STREET, LENASIA SOUTH EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39570,'lng':27.85770}},
{'stp':552944,'address':'552944, EGL - SOWETO, TAI HIGGO, ZAK PARK LIQUOR STORE, 1137-001 TUMERIC & CLOVE DR, ZAKARIYA PARK, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.36680,'lng':27.89170}},
{'stp':553394,'address':'553394, EGL - SOWETO, TAI HIGGO, SHEFFIELD PLAZA BOTTLE STORE, SHOP 5 SHEFFIELD PLAZA S/C ERF 74, LENASIA SOUTH, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.40060,'lng':27.87300}},
{'stp':561084,'address':'561084, EGL - SOWETO, TAI HIGGO, MANDOS PLACE, 5750 MATTERHORN STREET, LENASIA SOUTH, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.40060,'lng':27.87140}},
{'stp':561236,'address':'561236, EGL - SOWETO, TAI HIGGO, DAWOODS INN, 5251 MOUNT ISA PLACE, LENASIA SOUTH EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39520,'lng':27.86610}},
{'stp':580385,'address':'580385, EGL - SOWETO, TAI HIGGO, SESI\'S INN, 4551 MOUNT ROSA STREET, LENASIA SOUTH, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.40040,'lng':27.85270}},
{'stp':604042,'address':'604042, EGL - SOWETO, TAI HIGGO, LUCKY ELIAS NDLOVU, 3758N THEMBELIHLE, LENASIA EXT 10, #, LENASIA, South Africa','geo':{'lat':-26.33520,'lng':27.86880}},
{'stp':604043,'address':'604043, EGL - SOWETO, TAI HIGGO, PHUZAMOYAS TAVERN, 1063 LANCASTER STR, HOSPITAL HILL SOUTH, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38299,'lng':27.85824}},
{'stp':606104,'address':'606104, EGL - SOWETO, TAI HIGGO, JULY LUCAS MADONSELA, 3145, VLAKFONTEIN EXT 3, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39540,'lng':27.89000}},
{'stp':606738,'address':'606738, EGL - SOWETO, TAI HIGGO, JOLINKOMOS PLACE, 3088 MAIN STREET ZAKARIYA PARK, VLAKFONTEIN EXT 3, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39270,'lng':27.88840}},
{'stp':607467,'address':'607467, EGL - SOWETO, TAI HIGGO, MAKULAS PLACE, 4817 TYGERBERG PLACE, LENASIA SOUTH EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39860,'lng':27.86260}},
{'stp':626799,'address':'626799, EGL - SOWETO, TAI HIGGO, MARLOS TAVERN PERMIT, 4141 BERNINA PLACE, LENASIA SOUTH EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39580,'lng':27.86280}},
{'stp':627715,'address':'627715, EGL - SOWETO, TAI HIGGO, SPHAMANDLAS TAVERN PERMIT, 2576, VLAKFONTEIN EXT 3, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38750,'lng':27.88860}},
{'stp':627754,'address':'627754, EGL - SOWETO, TAI HIGGO, THE ODESSEY, STAND NO, SHOP NO 5A LENZ STATION MALL, PART OF PORTION 5 OF THE FARM, LENASIA, South Africa','geo':{'lat':-26.31821,'lng':27.82368}},
{'stp':627766,'address':'627766, EGL - SOWETO, TAI HIGGO, LUCYS TAVERN PERMIT, 6349 JEFFERSON STREET, LENASIA SOUTH EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.40740,'lng':27.86840}},
{'stp':628132,'address':'628132, EGL - SOWETO, TAI HIGGO, LUCKYS TAVERN PERMIT, 840 PENNYROYAL CRESCENT, ZAKARIYA PARK EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.36320,'lng':27.90346}},
{'stp':628593,'address':'628593, EGL - SOWETO, TAI HIGGO, MATSIMBES TUCKSHOP PERMIT, 5159 MAYIBUYE STREET, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37374,'lng':27.87677}},
{'stp':629935,'address':'629935, EGL - SOWETO, TAI HIGGO, TERRYS PLACE, 11860 TABRIZ STREET, LENASIA EXT 13, #, LENASIA, South Africa','geo':{'lat':-26.31890,'lng':27.86580}},
{'stp':630835,'address':'630835, EGL - SOWETO, TAI HIGGO, JL TAVERN, 11003 MABLE STREET, LENASIA EXT 13, #, LENASIA, South Africa','geo':{'lat':-26.31343,'lng':27.85428}},
{'stp':630988,'address':'630988, EGL - SOWETO, TAI HIGGO, PETERS PLACE, 13127 F1536 HOSPITAL HILL, LENASIA SOUTH, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38250,'lng':27.86010}},
{'stp':631006,'address':'631006, EGL - SOWETO, TAI HIGGO, ROCKS AND ROSES, 5604 HINDU KUSH STREET, LENASIA SOUTH EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39540,'lng':27.87110}},
{'stp':631301,'address':'631301, EGL - SOWETO, TAI HIGGO, BROWN HOUSE TAVERN, 1620 THEMBELIHLE EXT 2, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37661,'lng':27.89003}},
{'stp':631942,'address':'631942, EGL - SOWETO, TAI HIGGO, NOMVULAS INN, 4273 SIERRANAVADA STREET, LENASIA SOUTH, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39670,'lng':27.85920}},
{'stp':632578,'address':'632578, EGL - SOWETO, TAI HIGGO, ESTHER S TAVERN, 9085 STAND NO, 127 SHARI STREET EXT 10 LENASIA, #, LENASIA, South Africa','geo':{'lat':-26.34440,'lng':27.86650}},
{'stp':632915,'address':'632915, EGL - SOWETO, TAI HIGGO, MOSIAS PLACE, 1894 LONA STREET THEMBELIHLE, LENASIA EXT 10, #, LENASIA, South Africa','geo':{'lat':-26.33750,'lng':27.86230}},
{'stp':634875,'address':'634875, EGL - SOWETO, TAI HIGGO, MAJOSIS PLACE, 11139 PENDANT STREET, LENASIA EXT 13, #, LENASIA, South Africa','geo':{'lat':-26.31271,'lng':27.85719}},
{'stp':635380,'address':'635380, EGL - SOWETO, TAI HIGGO, LENASIA SUID DRANKWINKEL, SHOP 9&10 JIVAN S/C STARLING STR, LENASIA SOUTH, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38400,'lng':27.84840}},
{'stp':635688,'address':'635688, EGL - SOWETO, TAI HIGGO, PAULA\'S GENERAL DEALER, 124 CLOVE DRIVE, ZAKARIYA PARK, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37040,'lng':27.89290}},
{'stp':636266,'address':'636266, EGL - SOWETO, TAI HIGGO, ENKAWINI, 5531 GWALA-GWALA STREET, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37490,'lng':27.87890}},
{'stp':636442,'address':'636442, EGL - SOWETO, TAI HIGGO, SARAHS PLACE, 2120 TEMBELIHLE  EXT  2, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37820,'lng':27.89170}},
{'stp':636943,'address':'636943, EGL - SOWETO, TAI HIGGO, EBUNKOSINI BENKOSI, 10928 TORBANITE CRESCENT, LENASIA EXT 13, #, LENASIA, South Africa','geo':{'lat':-26.31210,'lng':27.85130}},
{'stp':637559,'address':'637559, EGL - SOWETO, TAI HIGGO, MAMAS INN, 53 ANEMONE AVENUE, LENASIA EXT 2, #, LENASIA, South Africa','geo':{'lat':-26.32210,'lng':27.82920}},
{'stp':638933,'address':'638933, EGL - SOWETO, TAI HIGGO, THANDABANTU SHOP, 5985, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37964,'lng':27.88085}},
{'stp':639043,'address':'639043, EGL - SOWETO, TAI HIGGO, THIKO\'S LIQUOR RESTAURANT (ExFR), 2484 VLAKFONTEIN, ZAKARIYA PARK, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37728,'lng':27.89145}},
{'stp':639540,'address':'639540, EGL - SOWETO, TAI HIGGO, STEPHES PLACE, 316 BRAHMAPUTRA STREET, LENASIA EXT 11A, #, LENASIA, South Africa','geo':{'lat':-26.33040,'lng':27.85230}},
{'stp':639671,'address':'639671, EGL - SOWETO, TAI HIGGO, ZAMOBUHLE\'S INN, 2919, VLAKFONTEIN EXT 2, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39820,'lng':27.89050}},
{'stp':639936,'address':'639936, EGL - SOWETO, TAI HIGGO, PETRUS\' PLACE, 10984 TRISULA STREET, LENASIA EXT 13, #, LENASIA, South Africa','geo':{'lat':-26.31532,'lng':27.85563}},
{'stp':640227,'address':'640227, EGL - SOWETO, TAI HIGGO, E AND T PLACE, 11729 KONTIKI CRESCENT, LENASIA EXT 13, #, LENASIA, South Africa','geo':{'lat':-26.31710,'lng':27.86640}},
{'stp':640349,'address':'640349, EGL - SOWETO, TAI HIGGO, RAILWAY LIQUOR STORE, LENZ STATION S/C LENASIA DR, LENASIA, #, LENASIA, South Africa','geo':{'lat':-26.32020,'lng':27.82370}},
{'stp':641654,'address':'641654, EGL - SOWETO, TAI HIGGO, AFRICAS INN, 9 PELICAN STREET, LENASIA, #, LENASIA, South Africa','geo':{'lat':-26.32120,'lng':27.82640}},
{'stp':641664,'address':'641664, EGL - SOWETO, TAI HIGGO, ALFIKEN TUCKSHOP, 487 LEHAE, LENASIA EXT 10, #, LENASIA, South Africa','geo':{'lat':-26.34470,'lng':27.89680}},
{'stp':641712,'address':'641712, EGL - SOWETO, TAI HIGGO, POPO\'S PLACE, 4171D THEMBALIHLE, LENASIA EXT 9, #, LENASIA, South Africa','geo':{'lat':-26.33400,'lng':27.87100}},
{'stp':641950,'address':'641950, EGL - SOWETO, TAI HIGGO, PEPSI WHOLESALE DISTRIBUTION, 6777 SHEFFIELD ROAD, LENASIA SOUTH EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.40060,'lng':27.87190}},
{'stp':642078,'address':'642078, EGL - SOWETO, TAI HIGGO, PHENDUKA\'S TAVERN, 1708N THEMBALIHLE, LENASIA EXT 10, #, LENASIA, South Africa','geo':{'lat':-26.33940,'lng':27.86200}},
{'stp':642358,'address':'642358, EGL - SOWETO, TAI HIGGO, SEKUSILE MPONQUSHE, 5632, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38050,'lng':27.87660}},
{'stp':642375,'address':'642375, EGL - SOWETO, TAI HIGGO, MALIYAKHE\'S SHEBEEN, 5767, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37843,'lng':27.87712}},
{'stp':642565,'address':'642565, EGL - SOWETO, TAI HIGGO, ZANZA\'S TAVERN, 4378 VLAKFONTEIN, ZAKARIYA PARK, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38210,'lng':27.88525}},
{'stp':648674,'address':'648674, EGL - SOWETO, TAI HIGGO, THOKOS PLACE, 813 THEMBELIHLE EXT 1, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37040,'lng':27.88880}},
{'stp':650441,'address':'650441, EGL - SOWETO, TAI HIGGO, SHANIS TAVERN, 56 SHILAGNA  AVENUE, LENASIA EXT 4, #, LENASIA, South Africa','geo':{'lat':-26.33040,'lng':27.83260}},
{'stp':653840,'address':'653840, EGL - SOWETO, TAI HIGGO, MAGAZA TAVERN, 3489, VLAKFONTEIN EXT 3, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39310,'lng':27.88920}},
{'stp':655473,'address':'655473, EGL - SOWETO, TAI HIGGO, EMTHONJENI TAVERN, 6145 KIASHA PARK, VLAKFONTEIN PROPER, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38060,'lng':27.87930}},
{'stp':659886,'address':'659886, EGL - SOWETO, TAI HIGGO, SOLLYS TAVERN, 10788 AIRBONE STR, LENASIA EXT 13, #, LENASIA, South Africa','geo':{'lat':-26.31030,'lng':27.85000}},
{'stp':664790,'address':'664790, EGL - SOWETO, TAI HIGGO, MANDLA S JAZZ BIT, 3534 STAND NO, LEHAE EXTENSION, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.33730,'lng':27.86470}},
{'stp':665661,'address':'665661, EGL - SOWETO, TAI HIGGO, PASHASH, 924 ZAKHENI STREET, VLAKFONTEIN EXT 1, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37210,'lng':27.88920}},
{'stp':666721,'address':'666721, EGL - SOWETO, TAI HIGGO, THEMBALIHLE TAVERN, 9358 ARCTURUS STREET, LENASIA EXT 10, #, LENASIA, South Africa','geo':{'lat':-26.33867,'lng':27.87371}},
{'stp':666800,'address':'666800, EGL - SOWETO, TAI HIGGO, POPPYS PLACE, STAND NO, ERF 3551 CALF STREET, LEHAE SUBURB LENASIA, LENASIA SOUTH, South Africa','geo':{'lat':-26.34690,'lng':27.88360}},
{'stp':672526,'address':'672526, EGL - SOWETO, TAI HIGGO, EMBAWULENI, 3507 THEMBELIHLE EXT 9, LENASIA, #, LENASIA, South Africa','geo':{'lat':-26.33580,'lng':27.87220}},
{'stp':685879,'address':'685879, EGL - SOWETO, TAI HIGGO, STATION TAVERN, 159 LENASIA DR SHOP 34A LENZ MALL, LENASIA, #, LENASIA, South Africa','geo':{'lat':-26.31960,'lng':27.82360}},
{'stp':705496,'address':'705496, EGL - SOWETO, TAI HIGGO, MAHARAJS PLACE, 3828 ZAGROSS STREET, LENASIA SOUTH EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39400,'lng':27.87010}},
{'stp':727023,'address':'727023, EGL - SOWETO, TAI HIGGO, KWA MAJOLA LIQUOR TRADERS, STAND NO 5669, VLAKFONTEIN PROPER REGION G, JOHANNESBURG, LENASIA SOUTH, South Africa','geo':{'lat':-26.37940,'lng':27.87420}},
{'stp':727383,'address':'727383, EGL - SOWETO, TAI HIGGO, BOITUMELONG, 1894 SECTION N THEMBELIHLE EXT 10, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.33713,'lng':27.86268}},
{'stp':738057,'address':'738057, EGL - SOWETO, TAI HIGGO, SITHOLES TAVERN, 5153, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37477,'lng':27.87691}},
{'stp':739154,'address':'739154, EGL - SOWETO, TAI HIGGO, LINDIWE S PLACE, 764 ZACHARIA PARK, #, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.36830,'lng':27.89830}},
{'stp':762054,'address':'762054, EGL - SOWETO, TAI HIGGO, KHOROMBIS TAVERN, 1121 LEHAE STREET, Lenasia, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.34450,'lng':27.89270}},
{'stp':762903,'address':'762903, EGL - SOWETO, TAI HIGGO, TEBOGO S PLACE, 4657 STAND NO, OLD VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37540,'lng':27.88220}},
{'stp':764954,'address':'764954, EGL - SOWETO, TAI HIGGO, NDOZ INN, 2083 STSND NO, VLAKFONTEIN EXT 2, LENASIA, LENASIA SOUTH, South Africa','geo':{'lat':-26.37820,'lng':27.89160}},
{'stp':768405,'address':'768405, EGL - SOWETO, TAI HIGGO, THE TAVERN DOWN TOWN, STAND NO, 19 ALGOA PLACE EXT, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29330,'lng':27.89840}},
{'stp':774684,'address':'774684, EGL - SOWETO, TAI HIGGO, WHITEHOUSE SHEBEEN, STAND NO, 285 F SECTION THEMBALIHLE EXT 9, #, LENASIA, South Africa','geo':{'lat':-26.33499,'lng':27.85905}},
{'stp':780189,'address':'780189, EGL - SOWETO, TAI HIGGO, ODES TAVERN, 4548 STAND NO, PHASE 3, #, LENASIA, South Africa','geo':{'lat':-26.34661,'lng':27.87888}},
{'stp':781481,'address':'781481, EGL - SOWETO, TAI HIGGO, MABATHO SHEBEEN, 22 STAND NO, CROCODILE STREET LEHAE LENASIA, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.28261,'lng':27.82474}},
{'stp':785780,'address':'785780, EGL - SOWETO, TAI HIGGO, GATSHENIS TAVERN, 1393 HOPE STREET, #, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.34592,'lng':27.89227}},
{'stp':785895,'address':'785895, EGL - SOWETO, TAI HIGGO, BHEKIS PLACE, 5051 STAND NO, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37441,'lng':27.87855}},
{'stp':787240,'address':'787240, EGL - SOWETO, TAI HIGGO, PAULINAS PLACE, 991 STAND NO, ZAKARIYA PARK, EXTENSION 4, LENASIA SOUTH, South Africa','geo':{'lat':-26.37040,'lng':27.88875}},
{'stp':790928,'address':'790928, EGL - SOWETO, TAI HIGGO, SUPPRUIS TAVERN, 2756 STAND NO, EXT 3, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39725,'lng':27.89115}},
{'stp':792785,'address':'792785, EGL - SOWETO, TAI HIGGO, TEMBA TAVERN, 3031 STAND NO, EXT 3, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38928,'lng':27.88676}},
{'stp':795580,'address':'795580, EGL - SOWETO, TAI HIGGO, HAPPY TAVERN, 33 STAND NO, MOUNT JEFFERSON STREET EXT 4, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.40709,'lng':27.86961}},
{'stp':798201,'address':'798201, EGL - SOWETO, TAI HIGGO, SUZANS TAVERN, 0180N STAND NO, THEMBELIHLE, #, LENASIA, South Africa','geo':{'lat':-26.33926,'lng':27.86919}},
{'stp':798573,'address':'798573, EGL - SOWETO, TAI HIGGO, KWA MNAKA TARVEN, 3833 STAND NO, #, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.38548,'lng':27.88972}},
{'stp':308826,'address':'308826, EGL - SOWETO, AVUKILE MKOKA, KHOSI\'S TAVERN, 229A STAND NO, INDWA STREET ZONE 7, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.21498,'lng':27.89105}},
{'stp':311540,'address':'311540, EGL - SOWETO, AVUKILE MKOKA, LIHLES INN, 117A STAND NO,  INKONJANE ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20961,'lng':27.88898}},
{'stp':383628,'address':'383628, EGL - SOWETO, AVUKILE MKOKA, NTOKOZO S PLACE, 3803 STAND NO, ZONE 10 MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21130,'lng':27.88278}},
{'stp':386649,'address':'386649, EGL - SOWETO, AVUKILE MKOKA, YARONA, 17458 STAND NO, ZONE 1, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.21052,'lng':27.89638}},
{'stp':387838,'address':'387838, EGL - SOWETO, AVUKILE MKOKA, EMAPLANKENI, 3043 B STAND NO, ZONE 10 MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21585,'lng':27.88305}},
{'stp':388670,'address':'388670, EGL - SOWETO, AVUKILE MKOKA, KWAZIKWAKHE S PLACE, 141D STAND NO,  ZONE 1, MEADOWLANDS, SOWETO, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21726,'lng':27.91769}},
{'stp':388805,'address':'388805, EGL - SOWETO, AVUKILE MKOKA, MAROPENG INN, 588B STAND NO, LEBONYANE STREET, MEADOWLANDS,, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20770,'lng':27.89361}},
{'stp':391610,'address':'391610, EGL - SOWETO, AVUKILE MKOKA, SAMMI S INN, 549 STAND NO, LEEBAROPE STREET ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20510,'lng':27.88841}},
{'stp':463611,'address':'463611, EGL - SOWETO, AVUKILE MKOKA, MOGAMI LIQUOR STORE, 275 ODENDAAL ROAD, ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21386,'lng':27.87843}},
{'stp':474929,'address':'474929, EGL - SOWETO, AVUKILE MKOKA, SYDNEYS PLACE, ERF 24456, MEADOWLANDS TOWNSHIP, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21690,'lng':27.89287}},
{'stp':482325,'address':'482325, EGL - SOWETO, AVUKILE MKOKA, SIBONGILES PLACE, 2628B ZONE 10 MEADOWLANDS, #, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21388,'lng':27.88319}},
{'stp':482366,'address':'482366, EGL - SOWETO, AVUKILE MKOKA, WSTD LIQUOR STORE, 3163 STAND NO, ZONE 10, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.22265,'lng':27.88080}},
{'stp':487371,'address':'487371, EGL - SOWETO, AVUKILE MKOKA, SUMAIS INN, 464B STAND NUMBER, MODUBU STREET, ZONE 3, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.21950,'lng':27.90674}},
{'stp':547100,'address':'547100, EGL - SOWETO, AVUKILE MKOKA, DLONGOLOS PLACE, 2748 B STAND NO, ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21790,'lng':27.88350}},
{'stp':548722,'address':'548722, EGL - SOWETO, AVUKILE MKOKA, SIZAKCANE TAVERN,  STAND NO, 226 H ZONE 11,  MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.20220,'lng':27.87979}},
{'stp':550395,'address':'550395, EGL - SOWETO, AVUKILE MKOKA, MOSUOE S PLACE, ERF 752 A, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21390,'lng':27.90230}},
{'stp':550696,'address':'550696, EGL - SOWETO, AVUKILE MKOKA, LULUS TAVERN, 998 BANGWATU STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21300,'lng':27.90810}},
{'stp':550874,'address':'550874, EGL - SOWETO, AVUKILE MKOKA, MUMMYS PLACE PERMIT, 1937 RAMALONGWANA STREET, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20990,'lng':27.88210}},
{'stp':551944,'address':'551944, EGL - SOWETO, AVUKILE MKOKA, MAMA AFRICA INN, 199B LENGAU STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21000,'lng':27.90070}},
{'stp':552167,'address':'552167, EGL - SOWETO, AVUKILE MKOKA, ALEX S TAVERN, 256 NKU STREET, MEADOWLANDS  ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21020,'lng':27.89800}},
{'stp':553219,'address':'553219, EGL - SOWETO, AVUKILE MKOKA, CASPERS TAVERN, ERF 145B MOSHOESHOE STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21540,'lng':27.89980}},
{'stp':553383,'address':'553383, EGL - SOWETO, AVUKILE MKOKA, V S TAVERN, STAND 113, MEADOWLANDS ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21040,'lng':27.88710}},
{'stp':553509,'address':'553509, EGL - SOWETO, AVUKILE MKOKA, LOUISVILLE TAVERN, STAND 1238 B, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20540,'lng':27.88330}},
{'stp':553621,'address':'553621, EGL - SOWETO, AVUKILE MKOKA, GORDONS PLACE, 449B PHUDUSUDU STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21300,'lng':27.89770}},
{'stp':553922,'address':'553922, EGL - SOWETO, AVUKILE MKOKA, NYAMANES PLACE PERMIT, 751, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21400,'lng':27.90200}},
{'stp':553929,'address':'553929, EGL - SOWETO, AVUKILE MKOKA, KWA LETTIE PERMIT, 2595B, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21500,'lng':27.87760}},
{'stp':560200,'address':'560200, EGL - SOWETO, AVUKILE MKOKA, ABEK S PLACE, 30316 SOTEBA STREET, MEADOWLANDS EXT 13, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20460,'lng':27.87500}},
{'stp':560389,'address':'560389, EGL - SOWETO, AVUKILE MKOKA, JOMOS TAVERN, 24 MOLOPE STREET, MEADOWLANDS ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21070,'lng':27.88800}},
{'stp':560468,'address':'560468, EGL - SOWETO, AVUKILE MKOKA, XIKWARHANIS PLACE, 3059A, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22190,'lng':27.88130}},
{'stp':560469,'address':'560469, EGL - SOWETO, AVUKILE MKOKA, MINKYS PLACE, 2446B, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21390,'lng':27.87820}},
{'stp':560470,'address':'560470, EGL - SOWETO, AVUKILE MKOKA, EVES PLACE, 464 B LEEBAROPE STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20930,'lng':27.89170}},
{'stp':560476,'address':'560476, EGL - SOWETO, AVUKILE MKOKA, JABU AND MIKE INN, 30159, MEADOWLANDS EXT 11, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20220,'lng':27.87560}},
{'stp':560516,'address':'560516, EGL - SOWETO, AVUKILE MKOKA, TADISES INN, 1360A, MEADOWLANDS ZONE, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20460,'lng':27.87920}},
{'stp':560546,'address':'560546, EGL - SOWETO, AVUKILE MKOKA, LETSOALOS PLACE, 1300, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20490,'lng':27.88150}},
{'stp':560547,'address':'560547, EGL - SOWETO, AVUKILE MKOKA, ETHELS PLACE, 30295, MEADOWLANDS  EXT 13, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20410,'lng':27.87480}},
{'stp':561107,'address':'561107, EGL - SOWETO, AVUKILE MKOKA, BAP\'S PLACE, 538 PUDUFUDU STR, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21270,'lng':27.89670}},
{'stp':561111,'address':'561111, EGL - SOWETO, AVUKILE MKOKA, MASTERS PLACE, BOND HOUSE 29570 ROODEPOORT RD, MEADOWLANDS EXT 12, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20740,'lng':27.87550}},
{'stp':561133,'address':'561133, EGL - SOWETO, AVUKILE MKOKA, DIPHOLO\'S PLACE, 471 MOROBANOKA STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21280,'lng':27.89870}},
{'stp':561164,'address':'561164, EGL - SOWETO, AVUKILE MKOKA, RIBS PLACE, 29286, MEADOWLANDS EXT 12, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20870,'lng':27.87250}},
{'stp':580396,'address':'580396, EGL - SOWETO, AVUKILE MKOKA, TIKIS PLACE, 1242 B, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20520,'lng':27.88330}},
{'stp':580398,'address':'580398, EGL - SOWETO, AVUKILE MKOKA, MMAMPEDI\'S PLACE, 2214, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21390,'lng':27.88310}},
{'stp':580456,'address':'580456, EGL - SOWETO, AVUKILE MKOKA, BHEKI\'S PLACE, 1663B, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20683,'lng':27.87975}},
{'stp':580457,'address':'580457, EGL - SOWETO, AVUKILE MKOKA, HAZELS PLACE, 1434B, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20580,'lng':27.88330}},
{'stp':580713,'address':'580713, EGL - SOWETO, AVUKILE MKOKA, DAVIDS INN, 1234A FORBES STR, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20550,'lng':27.88380}},
{'stp':603129,'address':'603129, EGL - SOWETO, AVUKILE MKOKA, PETRUS MASAO, 2280B, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21340,'lng':27.87687}},
{'stp':603170,'address':'603170, EGL - SOWETO, AVUKILE MKOKA, KHOSI PLACE, 3597, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21550,'lng':27.88320}},
{'stp':603172,'address':'603172, EGL - SOWETO, AVUKILE MKOKA, PAUL MAKHUBA, 3470 B, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21820,'lng':27.88190}},
{'stp':604420,'address':'604420, EGL - SOWETO, AVUKILE MKOKA, SEGO, 838C TSHIPA STR, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21530,'lng':27.90510}},
{'stp':604455,'address':'604455, EGL - SOWETO, AVUKILE MKOKA, ELIZABETH MOSIA, 1977 B, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20970,'lng':27.88310}},
{'stp':605398,'address':'605398, EGL - SOWETO, AVUKILE MKOKA, BILLYS INN, 1750, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20734,'lng':27.88087}},
{'stp':605519,'address':'605519, EGL - SOWETO, AVUKILE MKOKA, ELIZABETHS PLACE, 2905 MATLALA STREET, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21940,'lng':27.87460}},
{'stp':605723,'address':'605723, EGL - SOWETO, AVUKILE MKOKA, MVULENIS PLACE, 3556B, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21600,'lng':27.88110}},
{'stp':605745,'address':'605745, EGL - SOWETO, AVUKILE MKOKA, VANGAURD JAZZ INN, 1827B FORBES RD, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20710,'lng':27.88410}},
{'stp':605746,'address':'605746, EGL - SOWETO, AVUKILE MKOKA, EUNICES PUB, 1234B FORBES ROAD, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20580,'lng':27.88390}},
{'stp':606218,'address':'606218, EGL - SOWETO, AVUKILE MKOKA, MOSCOW TUCK SHOP, 2371A, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21240,'lng':27.87720}},
{'stp':606533,'address':'606533, EGL - SOWETO, AVUKILE MKOKA, OUMAS INN, 3758, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21760,'lng':27.88380}},
{'stp':627092,'address':'627092, EGL - SOWETO, AVUKILE MKOKA, MALINDIS PLACE PERMIT, 427A NTSU STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21000,'lng':27.89000}},
{'stp':627474,'address':'627474, EGL - SOWETO, AVUKILE MKOKA, MITTAS PLACE PERMIT, 692B MADUBANE STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21260,'lng':27.90400}},
{'stp':627928,'address':'627928, EGL - SOWETO, AVUKILE MKOKA, VENDA SUN TAVERN PERMIT, 584 LEBONYANE STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21190,'lng':27.88870}},
{'stp':629300,'address':'629300, EGL - SOWETO, AVUKILE MKOKA, MAMBALIS PLACE PERMIT, 29617 MASERU STREET, MEADOWLANDS EXT 12, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20660,'lng':27.87500}},
{'stp':629726,'address':'629726, EGL - SOWETO, AVUKILE MKOKA, PHILLIS INN PERMIT, 2215, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21390,'lng':27.88310}},
{'stp':630884,'address':'630884, EGL - SOWETO, AVUKILE MKOKA, PHUSELETSOS PLACE, 489A MASERU STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20890,'lng':27.89180}},
{'stp':631381,'address':'631381, EGL - SOWETO, AVUKILE MKOKA, PRISCILLAS PLACE, 2618 A, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21540,'lng':27.87740}},
{'stp':633426,'address':'633426, EGL - SOWETO, AVUKILE MKOKA, JACKIE\'S PLACE, 12234, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20340,'lng':27.88200}},
{'stp':633545,'address':'633545, EGL - SOWETO, AVUKILE MKOKA, SABAS TAVERN, 3686 A, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21730,'lng':27.88310}},
{'stp':633546,'address':'633546, EGL - SOWETO, AVUKILE MKOKA, NOMPI\'S INN, 3681A, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21707,'lng':27.88391}},
{'stp':634540,'address':'634540, EGL - SOWETO, AVUKILE MKOKA, KHEHLA\'S CAFE, 77A MAMGWASHI STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21420,'lng':27.88850}},
{'stp':636712,'address':'636712, EGL - SOWETO, AVUKILE MKOKA, NICKY\'S PLACE, 2128A, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21350,'lng':27.88140}},
{'stp':637067,'address':'637067, EGL - SOWETO, AVUKILE MKOKA, DUMELA\'S TAVERN, 827A TUVA STREET, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21710,'lng':27.88680}},
{'stp':637194,'address':'637194, EGL - SOWETO, AVUKILE MKOKA, NHLECK\'S PLACE, 3675 A, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21670,'lng':27.88360}},
{'stp':637284,'address':'637284, EGL - SOWETO, AVUKILE MKOKA, TITANIC PUB, 3508, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21520,'lng':27.88100}},
{'stp':637758,'address':'637758, EGL - SOWETO, AVUKILE MKOKA, RAZMATAZZ, 1076 MONGAGANG STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21350,'lng':27.90740}},
{'stp':639436,'address':'639436, EGL - SOWETO, AVUKILE MKOKA, SONTOS PLACE, 877A KOTI STREET, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21610,'lng':27.88730}},
{'stp':640259,'address':'640259, EGL - SOWETO, AVUKILE MKOKA, TSHEPIS PLACE, 13356 STAND NO, NO 7 KGARAKGOKGO ZONE 8, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.20700,'lng':27.88530}},
{'stp':640395,'address':'640395, EGL - SOWETO, AVUKILE MKOKA, THEMBI\'S PLACE, 3405A, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22040,'lng':27.88320}},
{'stp':641018,'address':'641018, EGL - SOWETO, AVUKILE MKOKA, NKOSIS PLACE, 280A NKOSI STREET, MEADOWLANDS ZONE 11, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21560,'lng':27.91450}},
{'stp':641370,'address':'641370, EGL - SOWETO, AVUKILE MKOKA, PULEZAS PLACE, 2318A, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21040,'lng':27.87760}},
{'stp':641496,'address':'641496, EGL - SOWETO, AVUKILE MKOKA, MEROPENG\'S PLACE, 694A MADUBANE STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21250,'lng':27.90410}},
{'stp':641661,'address':'641661, EGL - SOWETO, AVUKILE MKOKA, FRANK\'S PLACE, 710B MOGOKOJANE STREET, MEADOWLANDS ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20660,'lng':27.88850}},
{'stp':641662,'address':'641662, EGL - SOWETO, AVUKILE MKOKA, NEMSONDAS INN, 507 LETSOGO STREET, MEADOWLANDS ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20830,'lng':27.88700}},
{'stp':642209,'address':'642209, EGL - SOWETO, AVUKILE MKOKA, THULIS PLACE, 153A MAMASELANOKA STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20930,'lng':27.89980}},
{'stp':642218,'address':'642218, EGL - SOWETO, AVUKILE MKOKA, MAWE\'S PLACE, 12254, MEADOWLANDS ZONE 9, SOWETO, MEADOWLANDS, South Africa','geo':{'lat':-26.20390,'lng':27.88190}},
{'stp':642259,'address':'642259, EGL - SOWETO, AVUKILE MKOKA, EASY\'S PLACE, 1847A, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20840,'lng':27.88360}},
{'stp':642264,'address':'642264, EGL - SOWETO, AVUKILE MKOKA, BABA\'S PLACE, 1785A, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20830,'lng':27.88320}},
{'stp':642315,'address':'642315, EGL - SOWETO, AVUKILE MKOKA, NOMSA S PLACE, 63A MOKGALO STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21090,'lng':27.90230}},
{'stp':642556,'address':'642556, EGL - SOWETO, AVUKILE MKOKA, SYDNEYS PLACE, 28679  MADUBANE STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21130,'lng':27.90510}},
{'stp':646287,'address':'646287, EGL - SOWETO, AVUKILE MKOKA, GEORGIE S INN, 334A MASASAWANE STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21420,'lng':27.90130}},
{'stp':647142,'address':'647142, EGL - SOWETO, AVUKILE MKOKA, OUMA\'S TAVERN, 2675, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21680,'lng':27.87590}},
{'stp':647622,'address':'647622, EGL - SOWETO, AVUKILE MKOKA, MANGO\'S PLACE, 3394, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21970,'lng':27.88250}},
{'stp':650284,'address':'650284, EGL - SOWETO, AVUKILE MKOKA, CHAPPIE, 1901 MEADOWLANDS TOWNSHIP, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20980,'lng':27.88050}},
{'stp':650331,'address':'650331, EGL - SOWETO, AVUKILE MKOKA, SUZYS PLACE, 1571 FORBES ROAD, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20710,'lng':27.87790}},
{'stp':653627,'address':'653627, EGL - SOWETO, AVUKILE MKOKA, JOMOS PLACE, 13273 ERF NO, 707 ZONE 8, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.20690,'lng':27.88890}},
{'stp':653709,'address':'653709, EGL - SOWETO, AVUKILE MKOKA, YVONNES PLACE, 838F, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21550,'lng':27.90510}},
{'stp':659942,'address':'659942, EGL - SOWETO, AVUKILE MKOKA, BALAS TAVERN PERMIT (NTO), 155 KGARAKGOGO STR, MEADOWLANDS ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21148,'lng':27.88586}},
{'stp':663006,'address':'663006, EGL - SOWETO, AVUKILE MKOKA, KWA GCIBS, 94B VUMVENGANE STREE, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21390,'lng':27.88900}},
{'stp':663031,'address':'663031, EGL - SOWETO, AVUKILE MKOKA, SKHUMBUZOS PLACE, 352 B MOTEKWANE STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21130,'lng':27.89210}},
{'stp':663040,'address':'663040, EGL - SOWETO, AVUKILE MKOKA, SALOMES INN (NTO), STAND 699, MEADOWLANDS ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20605,'lng':27.88928}},
{'stp':691747,'address':'691747, EGL - SOWETO, AVUKILE MKOKA, GLADY\'S TAVERN, 130 STAND NO A, 5 INKONJANE STREET PORTION 14616, ZONE 7 MEDADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.21230,'lng':27.88950}},
{'stp':710205,'address':'710205, EGL - SOWETO, AVUKILE MKOKA, TSHIDI\'S PLACE, 2427B, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21340,'lng':27.87680}},
{'stp':734498,'address':'734498, EGL - SOWETO, AVUKILE MKOKA, DINGAANI TUCK INN, 3013B, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22088,'lng':27.88099}},
{'stp':747493,'address':'747493, EGL - SOWETO, AVUKILE MKOKA, VOVOS PLACE, 873 BATLAPING STRET B, ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21470,'lng':27.90450}},
{'stp':752906,'address':'752906, EGL - SOWETO, AVUKILE MKOKA, DUNKS PLACE, 3723B STAND NO, ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21880,'lng':27.88380}},
{'stp':757533,'address':'757533, EGL - SOWETO, AVUKILE MKOKA, DAVID S PLACE, 27359 STAND NO, MEADOWSLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22110,'lng':27.88150}},
{'stp':768701,'address':'768701, EGL - SOWETO, AVUKILE MKOKA, VIOLET S PLACE, 2642 STAND NO A, ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21550,'lng':27.87920}},
{'stp':770654,'address':'770654, EGL - SOWETO, AVUKILE MKOKA, MAVIS PLACE, 66B STAND NO, ZONE 7, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.21009,'lng':27.89023}},
{'stp':771183,'address':'771183, EGL - SOWETO, AVUKILE MKOKA, THEMBA S INN,  2957 STAND NO, ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22050,'lng':27.87990}},
{'stp':772705,'address':'772705, EGL - SOWETO, AVUKILE MKOKA, SILAS MODAU INN, 393B STAND NO, MOLOPE STREET ZONE 7 MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21047,'lng':27.88709}},
{'stp':773998,'address':'773998, EGL - SOWETO, AVUKILE MKOKA, SISTER SISTER NEW SPORT, 4488 UKHOZI STREET, ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21760,'lng':27.88380}},
{'stp':778062,'address':'778062, EGL - SOWETO, AVUKILE MKOKA, NTSIKIS INN, 3232 ZONE 10, MEADOWLANDS, SOWETO, MEADOWLANDS, South Africa','geo':{'lat':-26.22039,'lng':27.88315}},
{'stp':785006,'address':'785006, EGL - SOWETO, AVUKILE MKOKA, THEMBAS PLACE, 2877A STAND NO, ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21785,'lng':27.88347}},
{'stp':790980,'address':'790980, EGL - SOWETO, AVUKILE MKOKA, DLANGA BUTHERY AND TAKE AWAY, 388 STAND NO, ZONE 07, MEADOWSLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.21048,'lng':27.88703}},
{'stp':794792,'address':'794792, EGL - SOWETO, AVUKILE MKOKA, OUMAS INN, 2933 B STAND NO, ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21952,'lng':27.88015}},
{'stp':379616,'address':'379616, EGL - SOWETO, AVUKILE MKOKA, ISAAC S INN, 4141 STAND NO, MPHEMPHETHO STREET, 126 DOBSONVILLE TOWNSHIP, DOBSONVILLE, South Africa','geo':{'lat':-26.22596,'lng':27.85797}},
{'stp':388290,'address':'388290, EGL - SOWETO, AVUKILE MKOKA, JOSHUA S PLACE, 2269 STAND NO, NKWE STREET, DOBSONVILLE, DOBSONVILLE, South Africa','geo':{'lat':-26.21902,'lng':27.86198}},
{'stp':390794,'address':'390794, EGL - SOWETO, AVUKILE MKOKA, LA BILLA LIQUOR STORE, 3738 STAND NO, #, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21851,'lng':27.85733}},
{'stp':390819,'address':'390819, EGL - SOWETO, AVUKILE MKOKA, UPSIDE DOWN LIQUOR, 3505 STAND NO, VAN ONSELEN ROAD ZONE 10, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.21096,'lng':27.88188}},
{'stp':391557,'address':'391557, EGL - SOWETO, AVUKILE MKOKA, BOGARE BOTTLE STORE, 4336 ERF NO,    CNR STREET LUTHU, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.21297,'lng':27.87135}},
{'stp':392119,'address':'392119, EGL - SOWETO, AVUKILE MKOKA, JDS TAVERN, 344 STAND NO, MIMOSA STREET, DOBSONVILLE GARDENS, DOBSONVILLE, South Africa','geo':{'lat':-26.22415,'lng':27.80517}},
{'stp':470941,'address':'470941, EGL - SOWETO, AVUKILE MKOKA, TLADIS PLACE, STAND NO, REMAINDER OF ERF 4, SHOPS 24 AND 25, STELE STREET. DOBSONVILLE, DOBSONVILLE, South Africa','geo':{'lat':-26.21783,'lng':27.87007}},
{'stp':486424,'address':'486424, EGL - SOWETO, AVUKILE MKOKA, MAPS TAVERN, 114 STAND NO, MLOTYWA STREET, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22491,'lng':27.85753}},
{'stp':490174,'address':'490174, EGL - SOWETO, AVUKILE MKOKA, MASHEGOS PLACE, 10272  STAND NO, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21046,'lng':27.87121}},
{'stp':490847,'address':'490847, EGL - SOWETO, AVUKILE MKOKA, MPUMELELO, 2223 STAND NO, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20180,'lng':27.86602}},
{'stp':540959,'address':'540959, EGL - SOWETO, AVUKILE MKOKA, CHINAS PLACE, 1328 STAND NO, PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21105,'lng':27.86175}},
{'stp':550147,'address':'550147, EGL - SOWETO, AVUKILE MKOKA, HORSE SHOE TAVERN, ERF 522, ZONDI 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23180,'lng':27.86770}},
{'stp':550170,'address':'550170, EGL - SOWETO, AVUKILE MKOKA, MCUNU BOTTLE STORE, 953 NKOMA ROAD, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23500,'lng':27.86460}},
{'stp':550475,'address':'550475, EGL - SOWETO, AVUKILE MKOKA, TSHABALALA P M PERMIT, 896 VUKA STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23530,'lng':27.86250}},
{'stp':550623,'address':'550623, EGL - SOWETO, AVUKILE MKOKA, DOBSONVILLE LIQUOR STORE, 3227 NOHLANHLA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22660,'lng':27.86260}},
{'stp':550629,'address':'550629, EGL - SOWETO, AVUKILE MKOKA, JOES PLACE, 2367 MOTHOA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21710,'lng':27.86020}},
{'stp':551169,'address':'551169, EGL - SOWETO, AVUKILE MKOKA, CATHY S TAVERN, 3758 MLOTYWA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22120,'lng':27.85730}},
{'stp':552751,'address':'552751, EGL - SOWETO, AVUKILE MKOKA, MALOME MIKE S INN, 2655 ANDRIES MOLAUDI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22160,'lng':27.85860}},
{'stp':552954,'address':'552954, EGL - SOWETO, AVUKILE MKOKA, LATELA PUSO TAVERN, 1202 LETHULI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21850,'lng':27.86510}},
{'stp':553142,'address':'553142, EGL - SOWETO, AVUKILE MKOKA, CHURCH SQUARE TAVERN, 1052 MAIKANE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22160,'lng':27.86700}},
{'stp':553665,'address':'553665, EGL - SOWETO, AVUKILE MKOKA, BLACK JERSEY INN, 2976 SIBISI STREET BLOCK 7, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22790,'lng':27.85880}},
{'stp':553846,'address':'553846, EGL - SOWETO, AVUKILE MKOKA, MABIS RESTAURANT AND PUB PERMIT, 1801 MABITSELA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22180,'lng':27.87070}},
{'stp':553850,'address':'553850, EGL - SOWETO, AVUKILE MKOKA, LOUISAS TAVERN, 2533 MASHAO STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21879,'lng':27.86017}},
{'stp':560411,'address':'560411, EGL - SOWETO, AVUKILE MKOKA, MATHENGATHENGISA, 181 SIPHIWE VILLAGE, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21630,'lng':27.86670}},
{'stp':560544,'address':'560544, EGL - SOWETO, AVUKILE MKOKA, SIMPLY THE BEST, 3895 MPHEPHETO DRIVE, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22480,'lng':27.85800}},
{'stp':560553,'address':'560553, EGL - SOWETO, AVUKILE MKOKA, TSAKANES PLACE, 729, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20850,'lng':27.86240}},
{'stp':561231,'address':'561231, EGL - SOWETO, AVUKILE MKOKA, DODULAS PLACE, 228 MZIDANE STREET, ZONDI 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23300,'lng':27.86430}},
{'stp':603603,'address':'603603, EGL - SOWETO, AVUKILE MKOKA, DODGES INN, 2336 MAIN ROAD, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22410,'lng':27.86230}},
{'stp':604069,'address':'604069, EGL - SOWETO, AVUKILE MKOKA, SUSTER INN, 1327 MASINGAFI STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23540,'lng':27.86760}},
{'stp':606509,'address':'606509, EGL - SOWETO, AVUKILE MKOKA, THAMYS PLACE, 958 MBULI STR, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23400,'lng':27.86440}},
{'stp':625926,'address':'625926, EGL - SOWETO, AVUKILE MKOKA, CITY TAVERN PERMIT, 366 LETHULI STR SIPHIWE VILLAGE, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21750,'lng':27.86600}},
{'stp':627071,'address':'627071, EGL - SOWETO, AVUKILE MKOKA, EYETHU GENERAL DEALER PERMIT, 1149 NKOSI STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23730,'lng':27.86510}},
{'stp':627541,'address':'627541, EGL - SOWETO, AVUKILE MKOKA, MAMANES PLACE PERMIT, 1128 CHRISTIANA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23270,'lng':27.93110}},
{'stp':628809,'address':'628809, EGL - SOWETO, AVUKILE MKOKA, MARSH PLACE PERMIT, 935 MAIN ROAD, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22320,'lng':27.86700}},
{'stp':630611,'address':'630611, EGL - SOWETO, AVUKILE MKOKA, NANAS TAVERN, 2621 MOTHOA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22280,'lng':27.85930}},
{'stp':630840,'address':'630840, EGL - SOWETO, AVUKILE MKOKA, MENGIS PLACE, 2362 JONAS MOABI DRIVE, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21630,'lng':27.86020}},
{'stp':630976,'address':'630976, EGL - SOWETO, AVUKILE MKOKA, SANCHOS INN, 391, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21046,'lng':27.87121}},
{'stp':631213,'address':'631213, EGL - SOWETO, AVUKILE MKOKA, MUDAUS INN, 2109 MOEPENG STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21760,'lng':27.86340}},
{'stp':631463,'address':'631463, EGL - SOWETO, AVUKILE MKOKA, TIN HOUSE TAVERN, 4144 MAFU STR, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22660,'lng':27.85760}},
{'stp':631794,'address':'631794, EGL - SOWETO, AVUKILE MKOKA, STANLEY\'S INN, 1016, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20940,'lng':27.86040}},
{'stp':633595,'address':'633595, EGL - SOWETO, AVUKILE MKOKA, BIG WINDOWS PLACE, 2219 SEKWELE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21960,'lng':27.86200}},
{'stp':633889,'address':'633889, EGL - SOWETO, AVUKILE MKOKA, KGAREBE, 1946 MOEPENG STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21970,'lng':27.86290}},
{'stp':634503,'address':'634503, EGL - SOWETO, AVUKILE MKOKA, KWA MA, 4132 MONDI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22610,'lng':27.85690}},
{'stp':634531,'address':'634531, EGL - SOWETO, AVUKILE MKOKA, FLY\'S INN, 8157 IVORY COAST, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21420,'lng':27.85670}},
{'stp':634881,'address':'634881, EGL - SOWETO, AVUKILE MKOKA, TIMGATE LIQUOR STORE, ERF 6521, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23880,'lng':27.91810}},
{'stp':636340,'address':'636340, EGL - SOWETO, AVUKILE MKOKA, BOIKIE\'S PLACE, 194 MATOMELA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22680,'lng':27.87210}},
{'stp':636958,'address':'636958, EGL - SOWETO, AVUKILE MKOKA, PUNA\'S PLACE, 1757 RABORIFI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22030,'lng':27.87130}},
{'stp':638396,'address':'638396, EGL - SOWETO, AVUKILE MKOKA, BIG MAMA\'S PLACE, 2204 JAIROS MOGANO STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21910,'lng':27.86200}},
{'stp':638494,'address':'638494, EGL - SOWETO, AVUKILE MKOKA, LILYS PLACE, 3954 MUTLE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22370,'lng':27.85690}},
{'stp':638497,'address':'638497, EGL - SOWETO, AVUKILE MKOKA, WINNY\'S PLACE, 2921 MNGADI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22700,'lng':27.86040}},
{'stp':638846,'address':'638846, EGL - SOWETO, AVUKILE MKOKA, DANS PLACE, 488 SITHA STREET, ZONDI 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23220,'lng':27.86710}},
{'stp':638847,'address':'638847, EGL - SOWETO, AVUKILE MKOKA, LUNGILES PLACE, 1192 THWALA STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23540,'lng':27.86640}},
{'stp':638872,'address':'638872, EGL - SOWETO, AVUKILE MKOKA, TINYS PLACE, 1402 HLUBI STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23696,'lng':27.87807}},
{'stp':638881,'address':'638881, EGL - SOWETO, AVUKILE MKOKA, NTOMBIKAYISE\'S PLACE, 439 MABELE STREET, ZONDI 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23140,'lng':27.86750}},
{'stp':638943,'address':'638943, EGL - SOWETO, AVUKILE MKOKA, OUTSIDE HELP, 954 DZANA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22350,'lng':27.86810}},
{'stp':638944,'address':'638944, EGL - SOWETO, AVUKILE MKOKA, UNCLES PLACE, 500 SIPHIWE VILLAGE, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21760,'lng':27.86600}},
{'stp':638945,'address':'638945, EGL - SOWETO, AVUKILE MKOKA, TAU\'S PLACE, 1473 MOLIFE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21870,'lng':27.86820}},
{'stp':638946,'address':'638946, EGL - SOWETO, AVUKILE MKOKA, MAGOGOS PLACE, 1776 ROODEPOORT ROAD, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22110,'lng':27.87200}},
{'stp':638947,'address':'638947, EGL - SOWETO, AVUKILE MKOKA, GERT\'S PLACE, 3012 SIBISI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22990,'lng':27.85910}},
{'stp':639167,'address':'639167, EGL - SOWETO, AVUKILE MKOKA, DERRICKS INN, 1203, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21270,'lng':27.85900}},
{'stp':639545,'address':'639545, EGL - SOWETO, AVUKILE MKOKA, ZAHLO\'S PLACE, 261 VINCET STREET MMESI PARK, DOBSONVILLE NORTH, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21060,'lng':27.86730}},
{'stp':640310,'address':'640310, EGL - SOWETO, AVUKILE MKOKA, BUYISWA\'S PLACE, 1666, BRAAMFISCHER PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21040,'lng':27.86440}},
{'stp':640347,'address':'640347, EGL - SOWETO, AVUKILE MKOKA, PAMS INN, 1228, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21100,'lng':27.85990}},
{'stp':640456,'address':'640456, EGL - SOWETO, AVUKILE MKOKA, GAZIS PLACE, 435 SIPHIWE VILLAGE 2, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21670,'lng':27.86500}},
{'stp':640669,'address':'640669, EGL - SOWETO, AVUKILE MKOKA, LEBONE\'S INN, 1596, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20920,'lng':27.86390}},
{'stp':641190,'address':'641190, EGL - SOWETO, AVUKILE MKOKA, MINAH\'S PLACE, 1394 MAIN ROAD, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22230,'lng':27.87030}},
{'stp':641328,'address':'641328, EGL - SOWETO, AVUKILE MKOKA, SIMANGELE\'S PLACE, 314 WILLIAM STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22570,'lng':27.86980}},
{'stp':641413,'address':'641413, EGL - SOWETO, AVUKILE MKOKA, MOOLA\'S PLACE, 3101 GUBANXA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22970,'lng':27.86110}},
{'stp':641493,'address':'641493, EGL - SOWETO, AVUKILE MKOKA, SIPHOS PLACE, 3723 MPHEPHETO DRIVE, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21880,'lng':27.85770}},
{'stp':641497,'address':'641497, EGL - SOWETO, AVUKILE MKOKA, MAPOPS PLACE, 2528 MPEPETO DRIVE, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21960,'lng':27.85800}},
{'stp':641898,'address':'641898, EGL - SOWETO, AVUKILE MKOKA, THOKO S INN, 3795 MLOTSHWA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21880,'lng':27.85720}},
{'stp':642074,'address':'642074, EGL - SOWETO, AVUKILE MKOKA, THANDANANI TUCKSHOP, 90 SITHOLE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22300,'lng':27.87180}},
{'stp':642159,'address':'642159, EGL - SOWETO, AVUKILE MKOKA, CHRISTINAS SHEBEEN, 2532 ANDRIE MOLAUDI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21964,'lng':27.85830}},
{'stp':642177,'address':'642177, EGL - SOWETO, AVUKILE MKOKA, DESIRE\'S INN, 474 MOGOROSI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22690,'lng':27.86950}},
{'stp':642180,'address':'642180, EGL - SOWETO, AVUKILE MKOKA, MONKES INN, 305 NDUNGANYELA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22630,'lng':27.87050}},
{'stp':646103,'address':'646103, EGL - SOWETO, AVUKILE MKOKA, KONOSI\'S PLACE, 4201 MNDUNGE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22950,'lng':27.85790}},
{'stp':646285,'address':'646285, EGL - SOWETO, AVUKILE MKOKA, THANDEKAS PLACE, 162 MATOMELA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22500,'lng':27.87180}},
{'stp':648113,'address':'648113, EGL - SOWETO, AVUKILE MKOKA, EMASOFENI, 2890 HLOBO STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22558,'lng':27.86085}},
{'stp':648199,'address':'648199, EGL - SOWETO, AVUKILE MKOKA, TEBOGO INN, 1474 1474 THABANG STREET, BRAAMFISCHER, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21310,'lng':27.86030}},
{'stp':648422,'address':'648422, EGL - SOWETO, AVUKILE MKOKA, BANDA\'S PLACE, 7840 NDEBELE STREET, DOBSONVILLE EXT 2, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22200,'lng':27.85340}},
{'stp':648426,'address':'648426, EGL - SOWETO, AVUKILE MKOKA, SABATHA\'S PLACE, 13 MKHUBENI STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23310,'lng':27.85920}},
{'stp':653623,'address':'653623, EGL - SOWETO, AVUKILE MKOKA, MPUMELELOS PLACE, 1387, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21140,'lng':27.86150}},
{'stp':655016,'address':'655016, EGL - SOWETO, AVUKILE MKOKA, MUZIS PLACE, 427 WILLIAM STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22380,'lng':27.86930}},
{'stp':659460,'address':'659460, EGL - SOWETO, AVUKILE MKOKA, LIZO\'S INN (NTO), EFR 1695 MOSHUME STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.27230,'lng':27.78790}},
{'stp':661113,'address':'661113, EGL - SOWETO, AVUKILE MKOKA, KWA MBOMVU, 008 STAND NO, SIMPHIWE VILLAGE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21700,'lng':27.86790}},
{'stp':661568,'address':'661568, EGL - SOWETO, AVUKILE MKOKA, NATHIS PLACE, 1087, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23520,'lng':27.86430}},
{'stp':663061,'address':'663061, EGL - SOWETO, AVUKILE MKOKA, ZWENI S BUTHERY, STAND NO, SHOP 64 MAIN AND  LUTHULI RD DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21920,'lng':27.86190}},
{'stp':669485,'address':'669485, EGL - SOWETO, AVUKILE MKOKA, GIYANE TAVERN, 840 GWELE STREET, OLD DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22460,'lng':27.86710}},
{'stp':702640,'address':'702640, EGL - SOWETO, AVUKILE MKOKA, MEMES SOUL FOOD, 751HASHE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22420,'lng':27.86770}},
{'stp':708025,'address':'708025, EGL - SOWETO, AVUKILE MKOKA, SOKWALISA ROUTE 24 7, 191 KONJWA STREET, ZONDI 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23197,'lng':27.86440}},
{'stp':733874,'address':'733874, EGL - SOWETO, AVUKILE MKOKA, VUYO S PLACE, 1792, BRAAMFISHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20900,'lng':27.86490}},
{'stp':753590,'address':'753590, EGL - SOWETO, AVUKILE MKOKA, MAZIBUKO TUCKSHOP, 693 STAND NO, SIPHIWE VILLAGE, DOBSONVILLE, DOBSONVILLE, South Africa','geo':{'lat':-26.21730,'lng':27.86470}},
{'stp':753663,'address':'753663, EGL - SOWETO, AVUKILE MKOKA, INDINGILIZI DISTRIBUTORS, 85 ROODEPOORT ROAD, DOBSONVILLE, GAUTENG PROVINCE, DOBSONVILLE, South Africa','geo':{'lat':-26.22170,'lng':27.87240}},
{'stp':756730,'address':'756730, EGL - SOWETO, AVUKILE MKOKA, MERIAM S PLACE, 1742 STAND NO, DRD ERF 237-742 PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20880,'lng':27.86460}},
{'stp':764814,'address':'764814, EGL - SOWETO, AVUKILE MKOKA, IRENES PLACE, 1670 MAPHUTHO STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25730,'lng':27.84380}},
{'stp':778042,'address':'778042, EGL - SOWETO, AVUKILE MKOKA, JABUS PLACE, 78 STAND NO, NDLEBE STREET 10, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23071,'lng':27.86248}},
{'stp':779743,'address':'779743, EGL - SOWETO, AVUKILE MKOKA, DINEOS PLACE, 16 SWAZILAND STREET, ERF 8131, BRAAMFISCHERVILLE EXTENSION 5, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21441,'lng':27.85618}},
{'stp':785795,'address':'785795, EGL - SOWETO, AVUKILE MKOKA, ZIBAS PLACE, 1246 STAND NO, ZONDI TOWNSHIP, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23625,'lng':27.86725}},
{'stp':794326,'address':'794326, EGL - SOWETO, AVUKILE MKOKA, KENE INN, 1252 NTLAILANE STREET, #, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21902,'lng':27.86501}},
{'stp':309793,'address':'309793, EGL - SOWETO, AVUKILE MKOKA, NOMZAMO\'S CORNER LIQUOR STORE, 1804 STAND NO, USUTHU STREET, SENAOANE TOWNSHIP, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27750,'lng':27.85917}},
{'stp':382493,'address':'382493, EGL - SOWETO, AVUKILE MKOKA, BIG SHOWS PLACE, 654 STAND NUMBER, CHIAWELO, SOWETO, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27828,'lng':27.87652}},
{'stp':388285,'address':'388285, EGL - SOWETO, AVUKILE MKOKA, NGUZUMELA S TAVERN, 5987 ERF NO, STAND NO 685 16 MOKGALO ST, CHIAWELO EXT 3, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27799,'lng':27.84548}},
{'stp':388637,'address':'388637, EGL - SOWETO, AVUKILE MKOKA, PILGRIM S INN, 659/662 STAND NO, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27029,'lng':27.85029}},
{'stp':388810,'address':'388810, EGL - SOWETO, AVUKILE MKOKA, POWER LIQOUR STORE, 1460 STAND NO, LETSIE STREET, MAPETLA, SOWETO, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26786,'lng':27.84725}},
{'stp':390265,'address':'390265, EGL - SOWETO, AVUKILE MKOKA, MADIDES PLACE, 1579 NTOKOZWENI STREET, DLAMINI, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27314,'lng':27.87897}},
{'stp':390616,'address':'390616, EGL - SOWETO, AVUKILE MKOKA, ZONE 14 INN, 0944 STAND NO, CHIAWELO EXT 3, JOHANNESBURG, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27005,'lng':27.84257}},
{'stp':392481,'address':'392481, EGL - SOWETO, AVUKILE MKOKA, KING FEZEKA, 1361 STAND NO, SANDAWANE STREET, DLAMINI 1, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27193,'lng':27.88022}},
{'stp':462227,'address':'462227, EGL - SOWETO, AVUKILE MKOKA, SHOES TAVERN, 1632 ERF, SENOANE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27708,'lng':27.86214}},
{'stp':466296,'address':'466296, EGL - SOWETO, AVUKILE MKOKA, VINDUBE LIQOUR STORE, 1094 STAND NO, BUDELI  ERF 1094, CHIAWELO, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28077,'lng':27.86724}},
{'stp':470927,'address':'470927, EGL - SOWETO, AVUKILE MKOKA, ENTABENIS PLACE, 125 STAND NO, CHIAWELO EXTENSION 3, PO CHIAWELO, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27635,'lng':27.84711}},
{'stp':482319,'address':'482319, EGL - SOWETO, AVUKILE MKOKA, NOMA YINI LIQUOR STORE, 2541/5 MIKOSI PARK LANE, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28341,'lng':27.86979}},
{'stp':547124,'address':'547124, EGL - SOWETO, AVUKILE MKOKA, GOGOS INN, 199 MOSUBELE STREET, #, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27996,'lng':27.84572}},
{'stp':550661,'address':'550661, EGL - SOWETO, AVUKILE MKOKA, HONEYS PLACE, 1332 SANDAWANE STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27520,'lng':27.87730}},
{'stp':551024,'address':'551024, EGL - SOWETO, AVUKILE MKOKA, CHICAGO TAVERN, 399 VELI STREET, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27950,'lng':27.86930}},
{'stp':551201,'address':'551201, EGL - SOWETO, AVUKILE MKOKA, TOM S TAVERN, 1525 A NETHOMBENI STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28470,'lng':27.87000}},
{'stp':551270,'address':'551270, EGL - SOWETO, AVUKILE MKOKA, DHLAMINI LIQUOR STORE, 968/971 DALI NYEBO STREET, DLAMINI ZONE 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27880,'lng':27.87630}},
{'stp':551585,'address':'551585, EGL - SOWETO, AVUKILE MKOKA, THOKOZILE S LIQUOR STORE, 1532 MGOASE STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27460,'lng':27.87710}},
{'stp':551728,'address':'551728, EGL - SOWETO, AVUKILE MKOKA, KWA MANGWEMBUZI, 1340 NGWAYUMA STREET, SENAONA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27480,'lng':27.86470}},
{'stp':551926,'address':'551926, EGL - SOWETO, AVUKILE MKOKA, MADIDE S PLACE, 1579 NTOKOZWENI STREET, DLAMINI, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27287,'lng':27.87938}},
{'stp':551993,'address':'551993, EGL - SOWETO, AVUKILE MKOKA, LETTIE\'S PLACE TAVERN, 1846 MZILIKAZI STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27750,'lng':27.85160}},
{'stp':552447,'address':'552447, EGL - SOWETO, AVUKILE MKOKA, XIGAZA INN, 499 CHIAWELO, SOWETO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27850,'lng':27.87150}},
{'stp':553152,'address':'553152, EGL - SOWETO, AVUKILE MKOKA, PONNY\'S CLUB RESTAURANT, 618 NDLOVU STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27120,'lng':27.87650}},
{'stp':553397,'address':'553397, EGL - SOWETO, AVUKILE MKOKA, M NET TAVERN, 2083 MANTSENA STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27860,'lng':27.85380}},
{'stp':553779,'address':'553779, EGL - SOWETO, AVUKILE MKOKA, ROBERTS INN, 1582 MOLOTLEGI STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26860,'lng':27.85080}},
{'stp':553827,'address':'553827, EGL - SOWETO, AVUKILE MKOKA, ANDYS INN PERMIT, 1457 NETSHINDAULU STREET B, ERF 5758 EXT 1, SOWETO, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28680,'lng':27.87130}},
{'stp':553840,'address':'553840, EGL - SOWETO, AVUKILE MKOKA, GRACE INN, 1567A CHAUKE STREET, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28570,'lng':27.86990}},
{'stp':560307,'address':'560307, EGL - SOWETO, AVUKILE MKOKA, GUGUS INN PERMIT, 785 NGWEKHULU STR, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28273,'lng':27.87410}},
{'stp':560325,'address':'560325, EGL - SOWETO, AVUKILE MKOKA, BUTIS PLACE PERMIT, 2003, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.26900,'lng':27.87920}},
{'stp':560442,'address':'560442, EGL - SOWETO, AVUKILE MKOKA, MIKE 1313 PERMIT, 1313 LUANAME STR, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28340,'lng':27.86980}},
{'stp':560495,'address':'560495, EGL - SOWETO, AVUKILE MKOKA, ANNA\'S PLACE, 1691 THOHOYANDO STR, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28180,'lng':27.86610}},
{'stp':603163,'address':'603163, EGL - SOWETO, AVUKILE MKOKA, SIX SISTERS, 1936 TSHWENE STREET, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27160,'lng':27.84290}},
{'stp':604115,'address':'604115, EGL - SOWETO, AVUKILE MKOKA, MJIMIS PLACE, 1542  MOLOTLEGI STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27069,'lng':27.84850}},
{'stp':604121,'address':'604121, EGL - SOWETO, AVUKILE MKOKA, MABIZAS INN, 117 BONGIWE STR, DLAMINI 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27275,'lng':27.87062}},
{'stp':604339,'address':'604339, EGL - SOWETO, AVUKILE MKOKA, LEGZO, 837 SANDILE STREET, DLAMINI, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27520,'lng':27.87430}},
{'stp':604456,'address':'604456, EGL - SOWETO, AVUKILE MKOKA, NDONDZIS PLACE, BLOCK A NO 5 CHIAWELO FLATS, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27810,'lng':27.84440}},
{'stp':604471,'address':'604471, EGL - SOWETO, AVUKILE MKOKA, MALINDIS INN, 1743 NTOKOZWENI STR, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27150,'lng':27.88030}},
{'stp':604551,'address':'604551, EGL - SOWETO, AVUKILE MKOKA, TATIS PLACE, 180 TAKALANI STR, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27600,'lng':27.87000}},
{'stp':604587,'address':'604587, EGL - SOWETO, AVUKILE MKOKA, SBU\'S INN, 833 SANDILE STR, DLAMINI EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28299,'lng':27.87500}},
{'stp':605504,'address':'605504, EGL - SOWETO, AVUKILE MKOKA, SOLLYS PLACE, 188 OLD POTCH ROAD, DLAMINI 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27200,'lng':27.87350}},
{'stp':606498,'address':'606498, EGL - SOWETO, AVUKILE MKOKA, WELHEMINAH MASHANISI, 1657A CHAUKE STR, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28410,'lng':27.86700}},
{'stp':606514,'address':'606514, EGL - SOWETO, AVUKILE MKOKA, MANTSHOS PLACE, 210 TAKALANE STR, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27900,'lng':27.86820}},
{'stp':606805,'address':'606805, EGL - SOWETO, AVUKILE MKOKA, VICKS INN, 39 MOLOATHLA STR, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27720,'lng':27.84490}},
{'stp':607169,'address':'607169, EGL - SOWETO, AVUKILE MKOKA, TRANSKEI, FLAT 3 BLOCK O CHIAWELO FLATS, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27376,'lng':27.84245}},
{'stp':607181,'address':'607181, EGL - SOWETO, AVUKILE MKOKA, DUBES INN, 106 NXASANE STR, DLAMINI  EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.26990,'lng':27.87920}},
{'stp':607186,'address':'607186, EGL - SOWETO, AVUKILE MKOKA, EMABHUJWANI, 1770 NTOKOZWENI STR, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27190,'lng':27.88000}},
{'stp':607398,'address':'607398, EGL - SOWETO, AVUKILE MKOKA, STYLES PLACE, 1450 NETSHIDAULA STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28560,'lng':27.87260}},
{'stp':625110,'address':'625110, EGL - SOWETO, AVUKILE MKOKA, THOMAS TAVERN, 362 BONGIWE STR, DLAMINI 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27030,'lng':27.87160}},
{'stp':625205,'address':'625205, EGL - SOWETO, AVUKILE MKOKA, M T COOL, 493 DALINJEBO STR DLAMINI, DLAMINI, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27530,'lng':27.87240}},
{'stp':625919,'address':'625919, EGL - SOWETO, AVUKILE MKOKA, ZAMIMPILOS PLACE PERMIT, 1575A MACHEKE STR, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28550,'lng':27.86850}},
{'stp':625938,'address':'625938, EGL - SOWETO, AVUKILE MKOKA, POPS INN PERMIT, 820 RHAMASHIA STR, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28250,'lng':27.87300}},
{'stp':625973,'address':'625973, EGL - SOWETO, AVUKILE MKOKA, LIMPOPOS, 1552 UHLELO STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27710,'lng':27.86360}},
{'stp':626125,'address':'626125, EGL - SOWETO, AVUKILE MKOKA, DOLLYS INN PERMIT, 62, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27920,'lng':27.84503}},
{'stp':628581,'address':'628581, EGL - SOWETO, AVUKILE MKOKA, MATHIBELAS PLACE PERMIT, 1615 MANOTSI STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27040,'lng':27.84990}},
{'stp':628681,'address':'628681, EGL - SOWETO, AVUKILE MKOKA, AUNTIE LOLOS PLACE PERMIT, 2930A LEFAO STREET, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27070,'lng':27.84480}},
{'stp':629548,'address':'629548, EGL - SOWETO, AVUKILE MKOKA, MBUYIS PLACE PERMIT, 1459 MOTWESI STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27460,'lng':27.88100}},
{'stp':629663,'address':'629663, EGL - SOWETO, AVUKILE MKOKA, SEFATENG PERMIT, 1624 MANOTSHE STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27130,'lng':27.84910}},
{'stp':629718,'address':'629718, EGL - SOWETO, AVUKILE MKOKA, STEVES PLACE, 1246 TSHIOVE STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28300,'lng':27.87000}},
{'stp':630324,'address':'630324, EGL - SOWETO, AVUKILE MKOKA, STRICKES PLACE PERMIT, 835 SANDILE STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27520,'lng':27.87410}},
{'stp':630742,'address':'630742, EGL - SOWETO, AVUKILE MKOKA, BONDO INN, 785 A, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27240,'lng':27.85250}},
{'stp':631456,'address':'631456, EGL - SOWETO, AVUKILE MKOKA, MAZIYA TAVARN, 1395 SANDAWANA STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27530,'lng':27.87860}},
{'stp':631475,'address':'631475, EGL - SOWETO, AVUKILE MKOKA, CHILL OUT ZONE, 215 MTAMBO STREET FOXLAKE, DLAMINI EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27100,'lng':27.87940}},
{'stp':631816,'address':'631816, EGL - SOWETO, AVUKILE MKOKA, VUSIS TAVERN, 2463 PHATHUDI STR, MAPETLA EXT 2, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27420,'lng':27.85210}},
{'stp':632120,'address':'632120, EGL - SOWETO, AVUKILE MKOKA, MY LOVING, 1772 MTAMBO STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27110,'lng':27.87910}},
{'stp':633007,'address':'633007, EGL - SOWETO, AVUKILE MKOKA, BEAUTY\'S INN, 338 SEKHUKHUNI STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27050,'lng':27.84540}},
{'stp':633081,'address':'633081, EGL - SOWETO, AVUKILE MKOKA, JERRYS INN, 1637 NTOKOZWENI STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27350,'lng':27.88030}},
{'stp':633561,'address':'633561, EGL - SOWETO, AVUKILE MKOKA, MATHEWS PLACE, 683 MOKHALO STREET, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27920,'lng':27.84500}},
{'stp':633571,'address':'633571, EGL - SOWETO, AVUKILE MKOKA, SYDNEY\'S INN, 170 MOSU STREET, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27880,'lng':27.84670}},
{'stp':633573,'address':'633573, EGL - SOWETO, AVUKILE MKOKA, DIBABAS PLACE, 1273 MFISHANE STREET, DLAMINI, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27610,'lng':27.88060}},
{'stp':633706,'address':'633706, EGL - SOWETO, AVUKILE MKOKA, DIBS DELA TAVERN, 415 TAKALANE STREET, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27840,'lng':27.86960}},
{'stp':634030,'address':'634030, EGL - SOWETO, AVUKILE MKOKA, BUCIBOS TAVERN, 1148 STAND NO, UMKHOMAZI STREET SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27450,'lng':27.86960}},
{'stp':634655,'address':'634655, EGL - SOWETO, AVUKILE MKOKA, EKASI TAVERN, 1923 TSHWENE STREET, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27190,'lng':27.84350}},
{'stp':634982,'address':'634982, EGL - SOWETO, AVUKILE MKOKA, ERIC SHEBEEN, 4235 VUKEYA STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28680,'lng':27.86900}},
{'stp':635703,'address':'635703, EGL - SOWETO, AVUKILE MKOKA, MORE FIRE, 2068 MNINGA STREET, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27890,'lng':27.85290}},
{'stp':635808,'address':'635808, EGL - SOWETO, AVUKILE MKOKA, FNB, 2081 LEFOKA STR, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27320,'lng':27.84490}},
{'stp':638014,'address':'638014, EGL - SOWETO, AVUKILE MKOKA, OROS INN, 2260 MALATSI WEST STREET, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27370,'lng':27.84830}},
{'stp':638829,'address':'638829, EGL - SOWETO, AVUKILE MKOKA, SITHEBES PLACE, 1836 UMVOTI STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27800,'lng':27.86040}},
{'stp':638845,'address':'638845, EGL - SOWETO, AVUKILE MKOKA, SAMS TAVERN, 1174 LUANAME STREET, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28090,'lng':27.86540}},
{'stp':638880,'address':'638880, EGL - SOWETO, AVUKILE MKOKA, ALEXANDER\'S PLACE, 1923 KHANDA STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27870,'lng':27.86200}},
{'stp':640552,'address':'640552, EGL - SOWETO, AVUKILE MKOKA, KHEKU\'S PLACE, 299 OLD POTCH ROAD, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27760,'lng':27.86610}},
{'stp':641711,'address':'641711, EGL - SOWETO, AVUKILE MKOKA, ZERO\'S PLACE, 1291 LUANAME STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28300,'lng':27.86890}},
{'stp':641717,'address':'641717, EGL - SOWETO, AVUKILE MKOKA, NGOMA\'S PLACE, 1340 TSHIOVHE STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28469,'lng':27.87310}},
{'stp':641982,'address':'641982, EGL - SOWETO, AVUKILE MKOKA, MEISIE BY NIGHT TAVERN, 1704A NETHOMBENI STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28160,'lng':27.86480}},
{'stp':647365,'address':'647365, EGL - SOWETO, AVUKILE MKOKA, KWA EMZA, 1531A  NAPAWE STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28470,'lng':27.86900}},
{'stp':647740,'address':'647740, EGL - SOWETO, AVUKILE MKOKA, KIDI\'S PLACE, 834 INGWEMPISI STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27220,'lng':27.86730}},
{'stp':648848,'address':'648848, EGL - SOWETO, AVUKILE MKOKA, ANELE\'S PLACE, 1569 INSUZE STREET, SENAONE TOWNSHIP, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27690,'lng':27.86370}},
{'stp':649098,'address':'649098, EGL - SOWETO, AVUKILE MKOKA, NCANE\'S PLACE, FLAT 5 BLOCK N TSHITHUTHUNI STR, CHIAWELO 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27370,'lng':27.84230}},
{'stp':650190,'address':'650190, EGL - SOWETO, AVUKILE MKOKA, CHRISTINES INN, 183 MAMAKWANE STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27690,'lng':27.86930}},
{'stp':652587,'address':'652587, EGL - SOWETO, AVUKILE MKOKA, MASIYEPHAMBIU, 739, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27570,'lng':27.84400}},
{'stp':652627,'address':'652627, EGL - SOWETO, AVUKILE MKOKA, MOOLAS PLACE, 585 VILAKAZI STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27140,'lng':27.87560}},
{'stp':655091,'address':'655091, EGL - SOWETO, AVUKILE MKOKA, TLADIS INN, 3054 KHETHISA STREET, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27568,'lng':27.85050}},
{'stp':663707,'address':'663707, EGL - SOWETO, AVUKILE MKOKA, CONNIE S INN, 88 SALUKWANDA STREET, DLAMINI 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27310,'lng':27.87130}},
{'stp':664255,'address':'664255, EGL - SOWETO, AVUKILE MKOKA, WELL OF JAZZ, 1721 NETHOMBENI STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28190,'lng':27.86520}},
{'stp':683081,'address':'683081, EGL - SOWETO, AVUKILE MKOKA, PARKAS INN, NO 04 BLOCK G CHIAWELO FLATS, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27855,'lng':27.84420}},
{'stp':689226,'address':'689226, EGL - SOWETO, AVUKILE MKOKA, SCREAMERS DEJAVU, 1916 STAND NO, CHIAWELO EXT 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27780,'lng':27.85060}},
{'stp':726971,'address':'726971, EGL - SOWETO, AVUKILE MKOKA, NELLY S RESTAURANT, ERF 1429 TSHABUSE STREET, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28150,'lng':27.87110}},
{'stp':727766,'address':'727766, EGL - SOWETO, AVUKILE MKOKA, KIDS PUB, 234 JIYANE STREET, DLAMINI 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27060,'lng':27.87320}},
{'stp':740511,'address':'740511, EGL - SOWETO, AVUKILE MKOKA, LIRA S TAVERN, 18 EXT 1 PROTE SOUTH, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.27830,'lng':27.84260}},
{'stp':753776,'address':'753776, EGL - SOWETO, AVUKILE MKOKA, MASHUDUS PLACE, 290 STAND NO, CHAIWELO, EXTENSION 2, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27830,'lng':27.86510}},
{'stp':757442,'address':'757442, EGL - SOWETO, AVUKILE MKOKA, LEBO S PLACE, 385 STAND NO, CHAIWELO EXTENSION 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28100,'lng':27.85000}},
{'stp':768076,'address':'768076, EGL - SOWETO, AVUKILE MKOKA, GERALD BONGINKOSI, 284 RITA STREET, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28170,'lng':27.84880}},
{'stp':771354,'address':'771354, EGL - SOWETO, AVUKILE MKOKA, KID PLACE, 1037 FUNEKILE STREET, #, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27760,'lng':27.87590}},
{'stp':786978,'address':'786978, EGL - SOWETO, AVUKILE MKOKA, LALU S PLACE TAVERN, 705/76 MAKHAVHU STREET, MAKHAVHU, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28155,'lng':27.87625}},
{'stp':315173,'address':'315173, EGL - SOWETO, AVUKILE MKOKA, STYLES INN, 3643 STAND NO, MATLALA STREET DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21874,'lng':27.87476}},
{'stp':379811,'address':'379811, EGL - SOWETO, AVUKILE MKOKA, MX S PLACE, 2736 STAND  NO, #, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24619,'lng':27.87589}},
{'stp':383538,'address':'383538, EGL - SOWETO, AVUKILE MKOKA, OBIE S PLACE, 2143 A STAND NO, KHOZA STREET, ZOLA 2, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24162,'lng':27.83881}},
{'stp':387863,'address':'387863, EGL - SOWETO, AVUKILE MKOKA, TEE GEE, 10237 STAND NO, KAAMOTHO STREET, DOBSONVILLE, DOBSONVILLE, South Africa','geo':{'lat':-26.23514,'lng':27.82707}},
{'stp':388990,'address':'388990, EGL - SOWETO, AVUKILE MKOKA, THERESIA PLACE, 3529 ERF NO, #, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24568,'lng':27.84304}},
{'stp':463758,'address':'463758, EGL - SOWETO, AVUKILE MKOKA, TJD LIQUOR STORE, 2736 STAND NO, ZWELISHA STREET, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24378,'lng':27.84653}},
{'stp':468051,'address':'468051, EGL - SOWETO, AVUKILE MKOKA, BONGI\'S PLACE, STAND NO, STAND 12822/15 EXT 4, DOBSONVILLE, DOBSONVILLE, South Africa','geo':{'lat':-26.22620,'lng':27.84410}},
{'stp':471124,'address':'471124, EGL - SOWETO, AVUKILE MKOKA, NGUBANE LIQUOR STORE, 721 KHUMALO STREET, ZOLA ERF NUMBER 7210000, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24212,'lng':27.83077}},
{'stp':474394,'address':'474394, EGL - SOWETO, AVUKILE MKOKA, MKHULUS PLACE, 11779 STAND NO, EXTENSION 4, MBAIMBAI STREET, DOBSONVILLE, South Africa','geo':{'lat':-26.22780,'lng':27.84334}},
{'stp':485210,'address':'485210, EGL - SOWETO, AVUKILE MKOKA, FLAMBO LIQUOR STORE, 192 STAND NUMBER, MONKO, TLADI SOWETO, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25607,'lng':27.84140}},
{'stp':550139,'address':'550139, EGL - SOWETO, AVUKILE MKOKA, KA 77 TAVERN, ERF 77 ROODEPOORT WEG, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22310,'lng':27.87284}},
{'stp':550497,'address':'550497, EGL - SOWETO, AVUKILE MKOKA, GREENHOUSE, 705 KGOTLE STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25229,'lng':27.84143}},
{'stp':551091,'address':'551091, EGL - SOWETO, AVUKILE MKOKA, NGETHE S INN, 2189B TSATSINYANA STREET, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24670,'lng':27.83380}},
{'stp':551910,'address':'551910, EGL - SOWETO, AVUKILE MKOKA, JEFFS PLACE, 240 BOLANE ROAD, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24870,'lng':27.84400}},
{'stp':552017,'address':'552017, EGL - SOWETO, AVUKILE MKOKA, S AND S TAVERN, 1449A CELE STREET, ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23910,'lng':27.84050}},
{'stp':552531,'address':'552531, EGL - SOWETO, AVUKILE MKOKA, EMNDENI BAZAAR RESTAURANT, 754 CNR EMNDENI BAZAAR RESTAURANT, EMDENI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24390,'lng':27.82920}},
{'stp':552770,'address':'552770, EGL - SOWETO, AVUKILE MKOKA, SOWETO MINI LIQUOR STORE, 2422/31 BOLANI ROAD, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24860,'lng':27.84070}},
{'stp':553240,'address':'553240, EGL - SOWETO, AVUKILE MKOKA, IVAN S PLACE, 9761 MOGWAI STREET, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23330,'lng':27.83080}},
{'stp':553333,'address':'553333, EGL - SOWETO, AVUKILE MKOKA, MOTHA S TAVERN, 1331 B ZICI STREET, ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23810,'lng':27.84020}},
{'stp':553539,'address':'553539, EGL - SOWETO, AVUKILE MKOKA, FAT S INN, 1157 LEDWABA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21398,'lng':27.88316}},
{'stp':560225,'address':'560225, EGL - SOWETO, AVUKILE MKOKA, ZAKES CABIN, 849 BADIMAPHUTHI STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25200,'lng':27.83800}},
{'stp':560234,'address':'560234, EGL - SOWETO, AVUKILE MKOKA, BEN MOAS PLACE PERMIT, 8335 MOKOENA STREET,   EXT 2, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23050,'lng':27.85210}},
{'stp':560248,'address':'560248, EGL - SOWETO, AVUKILE MKOKA, FREEDOM TAVERN-PERMIT, 1075 NKOSI STREET, JABULANI TOWNSHIP, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23770,'lng':27.85630}},
{'stp':560383,'address':'560383, EGL - SOWETO, AVUKILE MKOKA, HLOMUKAS PUB AND GRILL, 1596 A ZWANE STREET, ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23770,'lng':27.84520}},
{'stp':560396,'address':'560396, EGL - SOWETO, AVUKILE MKOKA, SMEKS, 395 BUNDWINI STR MESI PARK, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21040,'lng':27.87120}},
{'stp':560412,'address':'560412, EGL - SOWETO, AVUKILE MKOKA, MY LOVIES, 9564 MAARONGANYE STREET, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23180,'lng':27.83070}},
{'stp':560443,'address':'560443, EGL - SOWETO, AVUKILE MKOKA, TSHABALALAS PLACE, 3045 MPANZA STREET, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24670,'lng':27.84740}},
{'stp':560478,'address':'560478, EGL - SOWETO, AVUKILE MKOKA, CANDYS PLACE, 1455 BUTHELEZI DRIVE, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24180,'lng':27.85320}},
{'stp':561153,'address':'561153, EGL - SOWETO, AVUKILE MKOKA, J J TAVERN, 6058 ZWELITSHA STR, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24340,'lng':27.84710}},
{'stp':561204,'address':'561204, EGL - SOWETO, AVUKILE MKOKA, SHATI S PLACE, 555 TLHAPI STREET, TLADI TOWNSHIP, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25210,'lng':27.84430}},
{'stp':580372,'address':'580372, EGL - SOWETO, AVUKILE MKOKA, M JS PLACE, 1815B DONDOLO STREET, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24390,'lng':27.83480}},
{'stp':580382,'address':'580382, EGL - SOWETO, AVUKILE MKOKA, CORSI CAN INN, 740 A BUTHELEZI STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24190,'lng':27.83220}},
{'stp':580788,'address':'580788, EGL - SOWETO, AVUKILE MKOKA, MALOMES PLACE-PERMIT, 162 MAKHENE STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25140,'lng':27.84740}},
{'stp':603344,'address':'603344, EGL - SOWETO, AVUKILE MKOKA, VERAS PLACE, 941 NKOSI STR, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23910,'lng':27.85170}},
{'stp':604122,'address':'604122, EGL - SOWETO, AVUKILE MKOKA, MASITO O TSWELAPELE, 8047 STEVE KGANE DR, DOBSONVILLE EXT 2, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22830,'lng':27.85100}},
{'stp':604579,'address':'604579, EGL - SOWETO, AVUKILE MKOKA, THULANIS INN, 10749 NGCOKOVANA STR, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23140,'lng':27.84080}},
{'stp':606544,'address':'606544, EGL - SOWETO, AVUKILE MKOKA, SKEPE, 2207A TSATSINYANA STR, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24670,'lng':27.83530}},
{'stp':606883,'address':'606883, EGL - SOWETO, AVUKILE MKOKA, LITTLE ROCK, 3042A MPANZA STR, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24670,'lng':27.84770}},
{'stp':609686,'address':'609686, EGL - SOWETO, AVUKILE MKOKA, BENJAMIN, 9973 STAND NO, RAMPHOMANE STREET, DOBSONVILLE EXT 3, DOBSONVILLE, South Africa','geo':{'lat':-26.23350,'lng':27.83590}},
{'stp':625920,'address':'625920, EGL - SOWETO, AVUKILE MKOKA, KHUNJUS TAVERN PERMIT, 803A THUSINI STR, ZOLA TOWNSHIP, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24280,'lng':27.83340}},
{'stp':626830,'address':'626830, EGL - SOWETO, AVUKILE MKOKA, FOUR DLADLA PERMIT, 10960, DOBSONVILLE  EXT 2, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22820,'lng':27.85430}},
{'stp':627892,'address':'627892, EGL - SOWETO, AVUKILE MKOKA, MGM LIQUOR STORE, 2416 JABAVU ROAD, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24730,'lng':27.84090}},
{'stp':629491,'address':'629491, EGL - SOWETO, AVUKILE MKOKA, NONES INN, 480B ZWANI STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23970,'lng':27.83280}},
{'stp':630162,'address':'630162, EGL - SOWETO, AVUKILE MKOKA, SIZAKELES TAVERN PERMIT, 1507B MDLALOSE STREET, ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23900,'lng':27.84300}},
{'stp':630429,'address':'630429, EGL - SOWETO, AVUKILE MKOKA, MPHE MOTHO LIQOUR WHOLESALERS PER, 861 MOTLHWARE STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25330,'lng':27.83840}},
{'stp':631077,'address':'631077, EGL - SOWETO, AVUKILE MKOKA, KKK TRIPLE K PLACE, 1036 SEFAULO STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25550,'lng':27.83940}},
{'stp':631091,'address':'631091, EGL - SOWETO, AVUKILE MKOKA, HOUSING AND JOY INN, 1265 MOTSAMAINYANA STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25600,'lng':27.84130}},
{'stp':631125,'address':'631125, EGL - SOWETO, AVUKILE MKOKA, CHILL FACTOR, 418B MATLATSI STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23890,'lng':27.83740}},
{'stp':631681,'address':'631681, EGL - SOWETO, AVUKILE MKOKA, BUTIS PLACE, 120 THEBEYAPELO STREET, TLADI LOCATION, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25460,'lng':27.83910}},
{'stp':631815,'address':'631815, EGL - SOWETO, AVUKILE MKOKA, THUNDER\'S PLACE, 2787 A, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24370,'lng':27.84830}},
{'stp':632142,'address':'632142, EGL - SOWETO, AVUKILE MKOKA, PUNSU\'S PLACE, 2472A TSHAWE STREET, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24670,'lng':27.84340}},
{'stp':632422,'address':'632422, EGL - SOWETO, AVUKILE MKOKA, DJEMBA\'S PLACE, 389 RADEBE STREET, EMDENI NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24030,'lng':27.82880}},
{'stp':632696,'address':'632696, EGL - SOWETO, AVUKILE MKOKA, THENJIWE\'S PLACE, 968 NHLAPO STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23430,'lng':27.85720}},
{'stp':633318,'address':'633318, EGL - SOWETO, AVUKILE MKOKA, SHIWES PLACE, 602A NXUMALO STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24090,'lng':27.83010}},
{'stp':633379,'address':'633379, EGL - SOWETO, AVUKILE MKOKA, HLOMO\'S PUB, 670 BOMA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25350,'lng':27.83020}},
{'stp':633425,'address':'633425, EGL - SOWETO, AVUKILE MKOKA, SOFT PUB, 773 THEBEYAPELO STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25300,'lng':27.83900}},
{'stp':633609,'address':'633609, EGL - SOWETO, AVUKILE MKOKA, KENNYS PLACE, 306 MAJOE STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25100,'lng':27.84950}},
{'stp':636667,'address':'636667, EGL - SOWETO, AVUKILE MKOKA, MIKE\'S INN, 618 MAKHASO STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25010,'lng':27.84910}},
{'stp':638775,'address':'638775, EGL - SOWETO, AVUKILE MKOKA, FIRES PLACE, 12336 MOPANI CRESCENT, DOBSONVILLE EXT 5, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23120,'lng':27.84500}},
{'stp':638785,'address':'638785, EGL - SOWETO, AVUKILE MKOKA, KEKES INN, 8168 MOTSIEEME STREET, DOBSONVILLE EXT 2, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22800,'lng':27.85340}},
{'stp':638848,'address':'638848, EGL - SOWETO, AVUKILE MKOKA, MEHLO\'S PLACE, 155A MKHWANAZI STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23700,'lng':27.83200}},
{'stp':638860,'address':'638860, EGL - SOWETO, AVUKILE MKOKA, TRAPPIE\'S PLACE, 739 BUTHELEZI STREET, ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23630,'lng':27.83810}},
{'stp':638962,'address':'638962, EGL - SOWETO, AVUKILE MKOKA, NYEMBES PLACE, 1215 NKOSI STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23760,'lng':27.85870}},
{'stp':638966,'address':'638966, EGL - SOWETO, AVUKILE MKOKA, JABUS TUCKSHOP, 822 NKOSI STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23930,'lng':27.85010}},
{'stp':639096,'address':'639096, EGL - SOWETO, AVUKILE MKOKA, NORMANS INN, 245-212 STAND NO, 174 SOKWAALA STREET, JABULANI, DOBSONVILLE, South Africa','geo':{'lat':-26.23735,'lng':27.85075}},
{'stp':639105,'address':'639105, EGL - SOWETO, AVUKILE MKOKA, PHINDILES INN, 2932 A SAMA STREET, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24660,'lng':27.84730}},
{'stp':639106,'address':'639106, EGL - SOWETO, AVUKILE MKOKA, SMALLS INN, 2075B SEHOOLE STREET, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24560,'lng':27.83730}},
{'stp':639351,'address':'639351, EGL - SOWETO, AVUKILE MKOKA, MANDLA INN, 2696B KHAMBULE AVENUE, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24500,'lng':27.84510}},
{'stp':640128,'address':'640128, EGL - SOWETO, AVUKILE MKOKA, ZANELES INN, 1105A HLATSHWAYO STREET, ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23580,'lng':27.83950}},
{'stp':640549,'address':'640549, EGL - SOWETO, AVUKILE MKOKA, MAGEBA \'S JOINT, 1016 MTETWA STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23740,'lng':27.85420}},
{'stp':640619,'address':'640619, EGL - SOWETO, AVUKILE MKOKA, LAPENG INN, 585 MOKWA STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25310,'lng':27.84540}},
{'stp':640777,'address':'640777, EGL - SOWETO, AVUKILE MKOKA, EASY SPEAKS TAVERN, 1165 B NDIMANDE STREET, ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23530,'lng':27.84090}},
{'stp':641013,'address':'641013, EGL - SOWETO, AVUKILE MKOKA, MAMODJADJIS PLACE, 688 KOOTLE STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25140,'lng':27.84120}},
{'stp':641025,'address':'641025, EGL - SOWETO, AVUKILE MKOKA, BIG FIVE INN, 82 MODUBU STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25040,'lng':27.83890}},
{'stp':641375,'address':'641375, EGL - SOWETO, AVUKILE MKOKA, MAMPIS PLACE, 1834A BENYA STREET, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24210,'lng':27.83920}},
{'stp':641899,'address':'641899, EGL - SOWETO, AVUKILE MKOKA, JOYCE\'S PLACE, 1632 NKONYANE STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24280,'lng':27.85470}},
{'stp':642192,'address':'642192, EGL - SOWETO, AVUKILE MKOKA, NGEVA\'S TAVERN, 2040B KHUMALO STREET, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24380,'lng':27.83870}},
{'stp':642249,'address':'642249, EGL - SOWETO, AVUKILE MKOKA, NKAMULA\'S PLACE, 271A NJONGO STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23700,'lng':27.83380}},
{'stp':646491,'address':'646491, EGL - SOWETO, AVUKILE MKOKA, MOLOI\'S TAVERN, 73 THAPANE STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24960,'lng':27.84700}},
{'stp':646742,'address':'646742, EGL - SOWETO, AVUKILE MKOKA, XOLI\'S CORNER, 2454A, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24820,'lng':27.84110}},
{'stp':650181,'address':'650181, EGL - SOWETO, AVUKILE MKOKA, CELIS PLACE, 765 DLANGAMANDLA STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23870,'lng':27.85021}},
{'stp':653932,'address':'653932, EGL - SOWETO, AVUKILE MKOKA, CEBZIN PLACE, 57 KHOZA STREET, ZONDI 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23040,'lng':27.86270}},
{'stp':660221,'address':'660221, EGL - SOWETO, AVUKILE MKOKA, CARTERS PLACE, 1946, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24570,'lng':27.85170}},
{'stp':661222,'address':'661222, EGL - SOWETO, AVUKILE MKOKA, MPUMALANGA TAVERN, 2598B MASHAYA STREET, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24800,'lng':27.84450}},
{'stp':661560,'address':'661560, EGL - SOWETO, AVUKILE MKOKA, MAGUBANE S PLACE, 2367 A, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24860,'lng':27.83620}},
{'stp':664167,'address':'664167, EGL - SOWETO, AVUKILE MKOKA, DLALA\'S PLACE, 2366 STAND NO, MATSHAYA STREET, ZOLA 2, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24860,'lng':27.83638}},
{'stp':672506,'address':'672506, EGL - SOWETO, AVUKILE MKOKA, THEMBIS INN, 2414 JABAVU ROAD, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24710,'lng':27.84090}},
{'stp':683486,'address':'683486, EGL - SOWETO, AVUKILE MKOKA, SISI S INN, 1287, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23850,'lng':27.85840}},
{'stp':701628,'address':'701628, EGL - SOWETO, AVUKILE MKOKA, ETHININI PLACE, 1282 MASOKA STREET WILLOW PARK, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23810,'lng':27.85820}},
{'stp':725661,'address':'725661, EGL - SOWETO, AVUKILE MKOKA, THANDABANTU BOTTLE STORE, ERF 3084 5 TEMBA DRIVE, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24750,'lng':27.84820}},
{'stp':750378,'address':'750378, EGL - SOWETO, AVUKILE MKOKA, VELL S INN, 10759 STREET NO, NGCOKOVANA STREET, DOBSONVILLE EXT 3, DOBSONVILLE, South Africa','geo':{'lat':-26.23150,'lng':27.84040}},
{'stp':765508,'address':'765508, EGL - SOWETO, AVUKILE MKOKA, MARIKANA JOINT, 12440 STAND NO, DOBSONVILLE, EXTENSION 7, DOBSONVILLE, South Africa','geo':{'lat':-26.23290,'lng':27.84220}},
{'stp':767183,'address':'767183, EGL - SOWETO, AVUKILE MKOKA, FACKS PLACE, 1769 MAHOLWANE STREET, ZOLA 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24006,'lng':27.84659}},
{'stp':768886,'address':'768886, EGL - SOWETO, AVUKILE MKOKA, NQO S INN, 878 B STAND NO, ZOLA 3, P O KWA XUMA, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24270,'lng':27.83570}},
{'stp':777736,'address':'777736, EGL - SOWETO, AVUKILE MKOKA, MAGOGO\'S PLACE (NTO), 834 MASOKA STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23970,'lng':27.85034}},
{'stp':777827,'address':'777827, EGL - SOWETO, AVUKILE MKOKA, ACES TAVERN (NTO), 2066A TWALA STR, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24345,'lng':27.83933}},
{'stp':777916,'address':'777916, EGL - SOWETO, AVUKILE MKOKA, B P TAVERN, 0065 BENYA STREET, ERVEN 1974/1975 SHOP 3, MDLENEVU SHOPPING CENTRE, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24240,'lng':27.83850}},
{'stp':783846,'address':'783846, EGL - SOWETO, AVUKILE MKOKA, SHOES PLACE, 0235\' STAND NO, #, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25228,'lng':27.84787}},
{'stp':786913,'address':'786913, EGL - SOWETO, AVUKILE MKOKA, AMANKOSI LIQUOR STORE, BUTHELEZI STREET, ERF 1358, JABULANI, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24197,'lng':27.85121}},
{'stp':794328,'address':'794328, EGL - SOWETO, AVUKILE MKOKA, VUSIS TAVERN, 59 BABINAPHUTHI STREET, #, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24928,'lng':27.84117}},
{'stp':794459,'address':'794459, EGL - SOWETO, AVUKILE MKOKA, KEMZAS PLACE, 338 STAND NO, Dikotsi street, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25054,'lng':27.84544}},
{'stp':318430,'address':'318430, EGL - SOWETO, AVUKILE MKOKA, PYG LIQOUR, 1803 STAND NO, #, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27403,'lng':27.83289}},
{'stp':389076,'address':'389076, EGL - SOWETO, AVUKILE MKOKA, NDANE\'S PLACE, 257 STAND, JABULANI, JOHANNESBURG, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24191,'lng':27.85723}},
{'stp':390141,'address':'390141, EGL - SOWETO, AVUKILE MKOKA, KWA NOKAMA, 1045 STAND NO, NDLANGAMANDLA STREET, JABULANE, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24912,'lng':27.85785}},
{'stp':390296,'address':'390296, EGL - SOWETO, AVUKILE MKOKA, MPHAZIMA LIQUOR STORE, 2608 STAND NO, BOLANI ROAD JABULANI SHOPPING CENTRE, EXTENSION 1, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24390,'lng':27.86542}},
{'stp':473408,'address':'473408, EGL - SOWETO, AVUKILE MKOKA, MREHOS PLACE, 1726 HLALELE STREET, MAPETLA EXTENSION 1, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27044,'lng':27.84560}},
{'stp':476589,'address':'476589, EGL - SOWETO, AVUKILE MKOKA, THIBOS PLACE, 239 STAND NUMBER, SECHELE STREET, MAPETLA, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26206,'lng':27.85179}},
{'stp':478225,'address':'478225, EGL - SOWETO, AVUKILE MKOKA, KHUNGA SOBAHLE TAVERN, 5 ERF NO, MABALANE STREET, MAPETLA, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26236,'lng':27.84826}},
{'stp':491782,'address':'491782, EGL - SOWETO, AVUKILE MKOKA, THEBUZA LIQUOR STORE, 1955 ERF NO, M APHOTO STREET, TLADI, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25737,'lng':27.84390}},
{'stp':551005,'address':'551005, EGL - SOWETO, AVUKILE MKOKA, LOVELANE TAVERN, 1120 MOLELE STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24640,'lng':27.86690}},
{'stp':551929,'address':'551929, EGL - SOWETO, AVUKILE MKOKA, SHAKARA LIQUOR STORE,  2329 30 KOMA ROAD, JABULANI EXT 1, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25210,'lng':27.85450}},
{'stp':552593,'address':'552593, EGL - SOWETO, AVUKILE MKOKA, KWA XUMA BIG TIME TAVERN, 1090 MOHAPI STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25460,'lng':27.84790}},
{'stp':553808,'address':'553808, EGL - SOWETO, AVUKILE MKOKA, CONNIES INN, 861 MOETI STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25110,'lng':27.85290}},
{'stp':553816,'address':'553816, EGL - SOWETO, AVUKILE MKOKA, MAMOKGELES INN PERMIT, 1724 HLALELE STR, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27030,'lng':27.84550}},
{'stp':553883,'address':'553883, EGL - SOWETO, AVUKILE MKOKA, PETERS PLACE PERMIT, 406 MOLOI STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25360,'lng':27.85160}},
{'stp':553897,'address':'553897, EGL - SOWETO, AVUKILE MKOKA, TLAKS PLACE PERMIT, 509 KOMA ROAD, MAPETLA EAST, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26070,'lng':27.85640}},
{'stp':560306,'address':'560306, EGL - SOWETO, AVUKILE MKOKA, ERICS PLACE PERMIT, 929 RALEFUTSO STR, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25150,'lng':27.85350}},
{'stp':560483,'address':'560483, EGL - SOWETO, AVUKILE MKOKA, MONICAS INN, 2538 LUTHULATSHEPI STR, NALEDI EXT 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26330,'lng':27.83150}},
{'stp':561070,'address':'561070, EGL - SOWETO, AVUKILE MKOKA, MZAYONI S  PLACE, 1502 STAND NO, MAPETLA STREET, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26814,'lng':27.85016}},
{'stp':580363,'address':'580363, EGL - SOWETO, AVUKILE MKOKA, BONGIS INN, 1335 MOSIA STR, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25920,'lng':27.84720}},
{'stp':580365,'address':'580365, EGL - SOWETO, AVUKILE MKOKA, PAPA TEX TAVERN, 1945 MOHALE STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25810,'lng':27.85220}},
{'stp':580373,'address':'580373, EGL - SOWETO, AVUKILE MKOKA, OUPAS PLACE, 1140 SEKU STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24820,'lng':27.86690}},
{'stp':603158,'address':'603158, EGL - SOWETO, AVUKILE MKOKA, DANS INN, 2890 LEFAO STREET, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27050,'lng':27.84330}},
{'stp':603696,'address':'603696, EGL - SOWETO, AVUKILE MKOKA, NIXIES TAVERN, 464 SEHELE STR, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25223,'lng':27.85180}},
{'stp':604049,'address':'604049, EGL - SOWETO, AVUKILE MKOKA, MARIKANE SPORTS BAR, 1161 STAND NO, NTSHABENG STR, SOWETO, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25508,'lng':27.84741}},
{'stp':604323,'address':'604323, EGL - SOWETO, AVUKILE MKOKA, KHETHANGS BAR, 2059 MOLEWA STR, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.26150,'lng':27.84560}},
{'stp':605515,'address':'605515, EGL - SOWETO, AVUKILE MKOKA, MAKODIS PLACE, 58 MAKHUDU STR, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24875,'lng':27.85658}},
{'stp':606510,'address':'606510, EGL - SOWETO, AVUKILE MKOKA, NESTERS INN, 2619 MOTITINYANE STR, NALEDI EXT 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26110,'lng':27.83500}},
{'stp':606527,'address':'606527, EGL - SOWETO, AVUKILE MKOKA, THIBOS PLACE, 1625 SEDUKU STR, TLADI TOWNSHIP, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25720,'lng':27.84320}},
{'stp':607168,'address':'607168, EGL - SOWETO, AVUKILE MKOKA, MAURE, 327 SEKHUKHUNE STR, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.25990,'lng':27.85490}},
{'stp':607315,'address':'607315, EGL - SOWETO, AVUKILE MKOKA, GIFTS INN, 2547 PHAKWE STR, NALEDI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26340,'lng':27.83100}},
{'stp':607316,'address':'607316, EGL - SOWETO, AVUKILE MKOKA, T J S PLACE, 1107 MOLELE STR, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24760,'lng':27.86600}},
{'stp':625107,'address':'625107, EGL - SOWETO, AVUKILE MKOKA, SULAS TAVERN, 1779 KOMA ROAD, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24720,'lng':27.85410}},
{'stp':625111,'address':'625111, EGL - SOWETO, AVUKILE MKOKA, STEPHENS PLACE, 2262 MOLIWA STR, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25430,'lng':27.85060}},
{'stp':625209,'address':'625209, EGL - SOWETO, AVUKILE MKOKA, EMANGCAMANENI PLACE PERMIT, 3396 TSAMAISO STREET, NALEDI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26320,'lng':27.83870}},
{'stp':625211,'address':'625211, EGL - SOWETO, AVUKILE MKOKA, KULIS INN, 1146 MOSHOESHOE STR, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24810,'lng':27.86630}},
{'stp':626235,'address':'626235, EGL - SOWETO, AVUKILE MKOKA, JEANETTES INN PERMIT, 1180 KGODUMO STREET, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25970,'lng':27.85920}},
{'stp':626438,'address':'626438, EGL - SOWETO, AVUKILE MKOKA, D I EMMERENG PERMIT, 653 KHOMO STREET, MAPETLA EAST, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26300,'lng':27.85600}},
{'stp':630811,'address':'630811, EGL - SOWETO, AVUKILE MKOKA, JUNIORS SPOT, 665 DIRE STREET, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25782,'lng':27.85954}},
{'stp':630851,'address':'630851, EGL - SOWETO, AVUKILE MKOKA, ETHELS PLACE, 588 TLADI DIKOTSI STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25285,'lng':27.84586}},
{'stp':630886,'address':'630886, EGL - SOWETO, AVUKILE MKOKA, FUNKY MAMA, 659 KGOMO STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26320,'lng':27.85520}},
{'stp':631632,'address':'631632, EGL - SOWETO, AVUKILE MKOKA, TSIETSIS PLACE, 683 KHOMO STREET, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.18663,'lng':27.83697}},
{'stp':632280,'address':'632280, EGL - SOWETO, AVUKILE MKOKA, MAMA ROSE INN, 276 SEKHUKHUNE STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26100,'lng':27.85350}},
{'stp':632530,'address':'632530, EGL - SOWETO, AVUKILE MKOKA, LADIES AND GENTS, 2917 MAPHALALA STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26350,'lng':27.84860}},
{'stp':632601,'address':'632601, EGL - SOWETO, AVUKILE MKOKA, T AND N S PLACE, 1558 KOMELLO STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.26020,'lng':27.84140}},
{'stp':632704,'address':'632704, EGL - SOWETO, AVUKILE MKOKA, MAFIKILE\'S PLACE, 226 DUMELANG STREET, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25310,'lng':27.86430}},
{'stp':632707,'address':'632707, EGL - SOWETO, AVUKILE MKOKA, RENKI\'S PLACE, 414 KEKANA STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26104,'lng':27.85402}},
{'stp':632753,'address':'632753, EGL - SOWETO, AVUKILE MKOKA, MPHO\'S SHEBEEN, 249 MPHAHLELE STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26230,'lng':27.85200}},
{'stp':633415,'address':'633415, EGL - SOWETO, AVUKILE MKOKA, NOZI\'S INN, 883 SEKHUKHUNI STREET, CHIAWELO EXT 3, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.26950,'lng':27.84310}},
{'stp':633592,'address':'633592, EGL - SOWETO, AVUKILE MKOKA, PEPE\'S GENERAL DEALER, 1101 MOLELE STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24820,'lng':27.86570}},
{'stp':633593,'address':'633593, EGL - SOWETO, AVUKILE MKOKA, SEFATENG, 646 RAMMATU STREET, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25670,'lng':27.85960}},
{'stp':634265,'address':'634265, EGL - SOWETO, AVUKILE MKOKA, GOMAGOMA, 2806, NALEDI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26540,'lng':27.83410}},
{'stp':634874,'address':'634874, EGL - SOWETO, AVUKILE MKOKA, DUKES PLACE, 1451 MOLINA STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25900,'lng':27.84900}},
{'stp':635582,'address':'635582, EGL - SOWETO, AVUKILE MKOKA, VENDA SUN, 466 RAMORUWE STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26130,'lng':27.85450}},
{'stp':636743,'address':'636743, EGL - SOWETO, AVUKILE MKOKA, ABC BOTTLE STORE, 87012 RALEFUTSO STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25170,'lng':27.85350}},
{'stp':636957,'address':'636957, EGL - SOWETO, AVUKILE MKOKA, MKHARIS PLACE, 1581 STAND NO, 2788 MOROTHO STREET, NALEDI EXTENSION 1, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26335,'lng':27.83262}},
{'stp':637196,'address':'637196, EGL - SOWETO, AVUKILE MKOKA, MDM\'S PLACE, 1602 TLADI LEGWALE STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25575,'lng':27.84247}},
{'stp':637379,'address':'637379, EGL - SOWETO, AVUKILE MKOKA, SONTOS INN, 1029 MATLOMO STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25440,'lng':27.84610}},
{'stp':637857,'address':'637857, EGL - SOWETO, AVUKILE MKOKA, ALEFA\'S PLACE, 391 MOLOI STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25390,'lng':27.85290}},
{'stp':639107,'address':'639107, EGL - SOWETO, AVUKILE MKOKA, NCELA\'S PLACE, 362 NGWENYA STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23920,'lng':27.86000}},
{'stp':640192,'address':'640192, EGL - SOWETO, AVUKILE MKOKA, ABIE\'S PLACE, 70 NDLEBE STREET, ZONDI 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23063,'lng':27.86253}},
{'stp':640562,'address':'640562, EGL - SOWETO, AVUKILE MKOKA, CHIPPAS INN, 1116 NYILIKA STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23720,'lng':27.86380}},
{'stp':640740,'address':'640740, EGL - SOWETO, AVUKILE MKOKA, FLOYD\'S PLACE, 212 MAKAKOLA STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25400,'lng':27.84660}},
{'stp':641331,'address':'641331, EGL - SOWETO, AVUKILE MKOKA, CANUS TAVERN, 3097 LEPHOI STREET, NALEDI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26220,'lng':27.83710}},
{'stp':641991,'address':'641991, EGL - SOWETO, AVUKILE MKOKA, CINDIS TUCKSHOP, 1554 BUTHELEZI STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24413,'lng':27.85772}},
{'stp':642006,'address':'642006, EGL - SOWETO, AVUKILE MKOKA, RAM\'S INN, 170 ROODEPOORT STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23690,'lng':27.87800}},
{'stp':646466,'address':'646466, EGL - SOWETO, AVUKILE MKOKA, TA SPISH INN, 1804 KOMA STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24760,'lng':27.85400}},
{'stp':648124,'address':'648124, EGL - SOWETO, AVUKILE MKOKA, MAGGIE\'S PLACE, 1702 MOKOENA STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25510,'lng':27.85360}},
{'stp':648447,'address':'648447, EGL - SOWETO, AVUKILE MKOKA, KENNYS PLACE, 2024 NTSANE STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25880,'lng':27.85320}},
{'stp':648883,'address':'648883, EGL - SOWETO, AVUKILE MKOKA, MADIBUSARY, 1248 KOMA ROAD, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26050,'lng':27.85650}},
{'stp':649117,'address':'649117, EGL - SOWETO, AVUKILE MKOKA, KEDIBONE\'S PLACE, 836 MOETA STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24960,'lng':27.85250}},
{'stp':649165,'address':'649165, EGL - SOWETO, AVUKILE MKOKA, VUSIS PLACE, 561 MOLOTO STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26210,'lng':27.85590}},
{'stp':649370,'address':'649370, EGL - SOWETO, AVUKILE MKOKA, NANKI\'S PLACE, 3226 LETLHABA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26360,'lng':27.83740}},
{'stp':649423,'address':'649423, EGL - SOWETO, AVUKILE MKOKA, ZANU JAZZ PLACE, ERF 1687, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25477,'lng':27.85403}},
{'stp':649930,'address':'649930, EGL - SOWETO, AVUKILE MKOKA, NTONGOLOZI, 625 ZIKATE STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24280,'lng':27.85770}},
{'stp':649991,'address':'649991, EGL - SOWETO, AVUKILE MKOKA, SEBOKOLOS PLACE, 198 SECHELE STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26360,'lng':27.85040}},
{'stp':654981,'address':'654981, EGL - SOWETO, AVUKILE MKOKA, R 6 50, 408 MHLONGO STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23770,'lng':27.86280}},
{'stp':663508,'address':'663508, EGL - SOWETO, AVUKILE MKOKA, MAPETLA INN (NTO), 617 KGOMO STR, MAPETLA EAST, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26359,'lng':27.85205}},
{'stp':664621,'address':'664621, EGL - SOWETO, AVUKILE MKOKA, SAMS INN, 1405 MAVI STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24950,'lng':27.86530}},
{'stp':665006,'address':'665006, EGL - SOWETO, AVUKILE MKOKA, KOBANGS PLACE, 2138 STAND NO A, BATLOHILE STREET NALENDI TOWNSHIP, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.26030,'lng':27.83917}},
{'stp':681782,'address':'681782, EGL - SOWETO, AVUKILE MKOKA, SYLLYS INN, 829 RAMONOTSHI STREET, MAPETLA EAST, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26470,'lng':27.85140}},
{'stp':683410,'address':'683410, EGL - SOWETO, AVUKILE MKOKA, SU INN, 567 BAKAE STREET, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25560,'lng':27.86150}},
{'stp':684296,'address':'684296, EGL - SOWETO, AVUKILE MKOKA, KILIMANJARO BOTTLE STORE, 641 ZIKODE STREET, JABULANI SOUTH, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24130,'lng':27.85860}},
{'stp':689012,'address':'689012, EGL - SOWETO, AVUKILE MKOKA, 2 SONS TAVERN, STAND 2069/79 MKIZE STREET, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24750,'lng':27.85130}},
{'stp':695851,'address':'695851, EGL - SOWETO, AVUKILE MKOKA, WHITE GATE PERMIT, 2643 THLATHLANE STREET, NALEDI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26114,'lng':27.83503}},
{'stp':695915,'address':'695915, EGL - SOWETO, AVUKILE MKOKA, GUMBIS DISCOUNT LIQUOR STORE, 412 JWARA STREET, JABULANI TOWNSHIP, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.23810,'lng':27.86270}},
{'stp':699662,'address':'699662, EGL - SOWETO, AVUKILE MKOKA, CHICKS JOINT, 510 THIPE STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.22625,'lng':27.90723}},
{'stp':753247,'address':'753247, EGL - SOWETO, AVUKILE MKOKA, MBONELAS INN, 1771 STAND NO, KOMA ROAD JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24610,'lng':27.85450}},
{'stp':756096,'address':'756096, EGL - SOWETO, AVUKILE MKOKA, FANYANE, 1441 STAND NO, ERF 1441 QWADI STREET TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25900,'lng':27.84060}},
{'stp':757944,'address':'757944, EGL - SOWETO, AVUKILE MKOKA, NCANES PLACE, 863 STAND NO, RAMONOTSI STREET MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26460,'lng':27.85380}},
{'stp':763517,'address':'763517, EGL - SOWETO, AVUKILE MKOKA, BHEKISIZWE PLACE, 1287 STAND NO, MAKAPANE STREET, MAPETLA, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26628,'lng':27.84945}},
{'stp':765506,'address':'765506, EGL - SOWETO, AVUKILE MKOKA, BENS CORNER, 1763 STAND NO, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25690,'lng':27.85460}},
{'stp':768012,'address':'768012, EGL - SOWETO, AVUKILE MKOKA, THREE SISTERS, 1890 LEGWALE STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25920,'lng':27.84340}},
{'stp':774427,'address':'774427, EGL - SOWETO, AVUKILE MKOKA, DLAMINI S INN, 6 STAND NO, FUNDANI STREET STAND 1320-A ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24059,'lng':27.84030}},
{'stp':792576,'address':'792576, EGL - SOWETO, AVUKILE MKOKA, MKHULUS INN, 53 B CRUTSE STREET, #, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25150,'lng':27.88391}},
{'stp':320867,'address':'320867, EGL - SOWETO, AVUKILE MKOKA, HILL TOP LIQUOR, STAND NO, 92 TSWALI STREET, ERF NO 65484894848, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.22993,'lng':27.86374}},
{'stp':377502,'address':'377502, EGL - SOWETO, AVUKILE MKOKA, MONTANA BOTTLE STORE, 27953 ERF NO, 209G PHIRIPHIRI STREET, ZONE 4, MEADOWLANDS, South Africa','geo':{'lat':-26.27013,'lng':27.84271}},
{'stp':382614,'address':'382614, EGL - SOWETO, AVUKILE MKOKA, BIG BAZA DISTRIBUTORS, 27956 STAND NUMBER, NO 209, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.19921,'lng':27.87653}},
{'stp':392685,'address':'392685, EGL - SOWETO, AVUKILE MKOKA, ZODWA LIQUOR STORE, 332 STAND NO, ELAND ROAD PENNYVILLE, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.21823,'lng':27.93923}},
{'stp':550062,'address':'550062, EGL - SOWETO, AVUKILE MKOKA, MAPONYA LIQUOR STORE, STAND 1982, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25620,'lng':27.82220}},
{'stp':550067,'address':'550067, EGL - SOWETO, AVUKILE MKOKA, MAPONYA BOTTLE STORE, 1980 CNR MAHALAFELE TSHEDI, AND THABO RDS, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23740,'lng':27.90020}},
{'stp':550397,'address':'550397, EGL - SOWETO, AVUKILE MKOKA, MAPEA S LIQUOR STORE, 1541 NTSANE STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.26030,'lng':27.84940}},
{'stp':550419,'address':'550419, EGL - SOWETO, AVUKILE MKOKA, SAINT M DISTRIBUTORS, SHOP 3 COMPLEX 3 INDUSTRIA, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23910,'lng':27.91500}},
{'stp':550729,'address':'550729, EGL - SOWETO, AVUKILE MKOKA, FLOYDS PLACE, 505 VAN ONSELAN STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21580,'lng':27.89380}},
{'stp':550800,'address':'550800, EGL - SOWETO, AVUKILE MKOKA, MAKHOSONKE TAVERN, 88A INDONI STREET, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22410,'lng':27.89430}},
{'stp':551357,'address':'551357, EGL - SOWETO, AVUKILE MKOKA, BON VIAN LIQUOR STORE, CNR SOFASONKE & MOFOKENG STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23720,'lng':27.92780}},
{'stp':551644,'address':'551644, EGL - SOWETO, AVUKILE MKOKA, KHUL S INN TAVERN, STAND 205 B, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22730,'lng':27.90020}},
{'stp':551797,'address':'551797, EGL - SOWETO, AVUKILE MKOKA, MAKHUZENI LIQUOR STORE, 1750 MANJO STREET, ZOLA NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24000,'lng':27.84650}},
{'stp':551804,'address':'551804, EGL - SOWETO, AVUKILE MKOKA, MAMSEYS INN, 3729, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21790,'lng':27.88350}},
{'stp':551884,'address':'551884, EGL - SOWETO, AVUKILE MKOKA, MGABADELI LIQUOR STORE, 1724/8 MDAWENE STREET, ZOLA, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23730,'lng':27.84690}},
{'stp':552251,'address':'552251, EGL - SOWETO, AVUKILE MKOKA, QUEENS INN, 14A THABABUSIU STREET, ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21340,'lng':27.89560}},
{'stp':552326,'address':'552326, EGL - SOWETO, AVUKILE MKOKA, LEISURE TAVERN, 536 MADONDO STREET, MOFOLO SOUTH, #, MOFOLO, South Africa','geo':{'lat':-26.25440,'lng':27.88710}},
{'stp':552403,'address':'552403, EGL - SOWETO, AVUKILE MKOKA, DHLADHLA LIQUOR STORE, 2333 ERF NO, PORTION 1, DUBE, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23590,'lng':27.90170}},
{'stp':552445,'address':'552445, EGL - SOWETO, AVUKILE MKOKA, FOURMANS INN TAVERN, 3943 MLOTYWA STREET, DOBSONVILLE, SOWETO, DOBSONVILLE, South Africa','geo':{'lat':-26.22590,'lng':27.85530}},
{'stp':552557,'address':'552557, EGL - SOWETO, AVUKILE MKOKA, EMDENI DISCOUNT LIQUOR, 1329/30 EMDENI TOWNSHIP CNR MANDLA, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24720,'lng':27.82310}},
{'stp':553004,'address':'553004, EGL - SOWETO, AVUKILE MKOKA, MOLOI SHOPPING CENTRE LIQUOR STORE, 579 BOTQNI STREET, EMDENI NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24430,'lng':27.81930}},
{'stp':553190,'address':'553190, EGL - SOWETO, AVUKILE MKOKA, TSHABALALA LIQUOR STORE, 1585 MLANGENI STREET, SECTION B, JABAVU, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25750,'lng':27.87820}},
{'stp':553257,'address':'553257, EGL - SOWETO, AVUKILE MKOKA, O T S PLACE, 456 VERBENA STREET, PROTEA GLEN PHASE 1, #, PROTEA, South Africa','geo':{'lat':-26.26036,'lng':27.81050}},
{'stp':553395,'address':'553395, EGL - SOWETO, AVUKILE MKOKA, CHESTERS CLUB, 1107 NYILIKA STREET, ZONDI, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23620,'lng':27.86310}},
{'stp':553919,'address':'553919, EGL - SOWETO, AVUKILE MKOKA, TITIS TAVERN, 1180 AMANDLA BOULEVARD ROAD, BRAAMFISHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21180,'lng':27.85860}},
{'stp':560312,'address':'560312, EGL - SOWETO, AVUKILE MKOKA, MOTSWEDING PERMIT, 1156 LEKGWALE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25130,'lng':27.81980}},
{'stp':560550,'address':'560550, EGL - SOWETO, AVUKILE MKOKA, MONICAS PLACE, 2219 MOFUTSANYANE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23720,'lng':27.93240}},
{'stp':561094,'address':'561094, EGL - SOWETO, AVUKILE MKOKA, RICHARDS TAVERN, 4325 BLOCK 7 THULANI, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22340,'lng':27.82240}},
{'stp':580451,'address':'580451, EGL - SOWETO, AVUKILE MKOKA, NGALO GESTOES INN, ERF 374 A ZWANE & MASEKO STR, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23850,'lng':27.83490}},
{'stp':581133,'address':'581133, EGL - SOWETO, AVUKILE MKOKA, SANTOROS PLACE, 130A MKHWANAZI STR, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23840,'lng':27.82910}},
{'stp':581280,'address':'581280, EGL - SOWETO, AVUKILE MKOKA, CORNER INN, 12419, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19290,'lng':27.84840}},
{'stp':605352,'address':'605352, EGL - SOWETO, AVUKILE MKOKA, HAPPYS TAVERN, 852 HERBY MDINGI STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24140,'lng':27.92240}},
{'stp':606046,'address':'606046, EGL - SOWETO, AVUKILE MKOKA, JOSHUAS INN, 214B DICHABE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24950,'lng':27.82670}},
{'stp':606645,'address':'606645, EGL - SOWETO, AVUKILE MKOKA, TOKYOS TAVERN, 437A PERI PERI STR, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22610,'lng':27.89240}},
{'stp':628583,'address':'628583, EGL - SOWETO, AVUKILE MKOKA, MASHININIS CORNER PERMIT, 10100 CNR PHAKATHI AND KHUPHE STS, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23390,'lng':27.83550}},
{'stp':630991,'address':'630991, EGL - SOWETO, AVUKILE MKOKA, SIPHOS PLACE, 4586 MASITENYANE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24560,'lng':27.92170}},
{'stp':631231,'address':'631231, EGL - SOWETO, AVUKILE MKOKA, IFF PLACE, 572 NOMWA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22450,'lng':27.86830}},
{'stp':631341,'address':'631341, EGL - SOWETO, AVUKILE MKOKA, LETAPENG, 1418 MOGOROSI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22210,'lng':27.86830}},
{'stp':631471,'address':'631471, EGL - SOWETO, AVUKILE MKOKA, MABUNDAS PLACE, 3947 DRD, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20270,'lng':27.86040}},
{'stp':633532,'address':'633532, EGL - SOWETO, AVUKILE MKOKA, NKGONO\'S PLACE, 2079A LETHOBA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25550,'lng':27.83440}},
{'stp':635232,'address':'635232, EGL - SOWETO, AVUKILE MKOKA, TSO\'S PLACE, 4675 BLOCK 6 SNAKE PARK, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21920,'lng':27.82440}},
{'stp':636269,'address':'636269, EGL - SOWETO, AVUKILE MKOKA, CROSS ROADS LIQUOR STORE, 1057 ROODEPOORT RD, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25026,'lng':27.88400}},
{'stp':638746,'address':'638746, EGL - SOWETO, AVUKILE MKOKA, THE CORNER HOUSE TAVERN, 1098 MOHAPI STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25450,'lng':27.84660}},
{'stp':640144,'address':'640144, EGL - SOWETO, AVUKILE MKOKA, STEVES PLACE, 837 A STAND NO, MOLOSE STREET, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25060,'lng':27.83560}},
{'stp':640390,'address':'640390, EGL - SOWETO, AVUKILE MKOKA, JAMES YABO, 3773A, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21720,'lng':27.88490}},
{'stp':641498,'address':'641498, EGL - SOWETO, AVUKILE MKOKA, MATHOLESVILLE TUCK AND TAVERN, 122 GALADA STREET, MATHOLESVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.16990,'lng':27.84700}},
{'stp':641698,'address':'641698, EGL - SOWETO, AVUKILE MKOKA, MARADONA\'S PLACE, 462 GCALEKA STREET, MATHOLESVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.16910,'lng':27.85130}},
{'stp':642352,'address':'642352, EGL - SOWETO, AVUKILE MKOKA, KAHLENI MAGENTS, 2787 MONDI STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22550,'lng':27.85980}},
{'stp':646899,'address':'646899, EGL - SOWETO, AVUKILE MKOKA, NOMAZIZIS PLACE, 593 TSHEZI STREET, ZONDI 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23070,'lng':27.86950}},
{'stp':649657,'address':'649657, EGL - SOWETO, AVUKILE MKOKA, JOYCE AND SON, 1499 KOPANO STR GREEN VILLAGE, DOORNKOP EXT 1, #, DOORNKOP, South Africa','geo':{'lat':-26.24080,'lng':27.80540}},
{'stp':652910,'address':'652910, EGL - SOWETO, AVUKILE MKOKA, MAPULAS INN, 1104 MAROJO STREET, TLADI LOCATION, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25690,'lng':27.83890}},
{'stp':655371,'address':'655371, EGL - SOWETO, AVUKILE MKOKA, MTHOMBENI LIQUOR STORE, 1228 CNR GOMBA&SOFASONKE STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24290,'lng':27.92330}},
{'stp':664274,'address':'664274, EGL - SOWETO, AVUKILE MKOKA, RONNIES TAVERN, 1182B, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25200,'lng':27.81990}},
{'stp':686646,'address':'686646, EGL - SOWETO, AVUKILE MKOKA, THABOS PLACE, 1765 CHALKER AVENUE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24060,'lng':27.89840}},
{'stp':687901,'address':'687901, EGL - SOWETO, AVUKILE MKOKA, GIFT TAVERN, 596, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24370,'lng':27.87200}},
{'stp':689059,'address':'689059, EGL - SOWETO, AVUKILE MKOKA, SIBONELA LIQUOR STORE, ERF 18769, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24140,'lng':27.86120}},
{'stp':692348,'address':'692348, EGL - SOWETO, AVUKILE MKOKA, DR SHAUNS PLACE, 16637, BRAAMFISCHERVILLE EXT 12 PHASE 3, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19130,'lng':27.82400}},
{'stp':701698,'address':'701698, EGL - SOWETO, AVUKILE MKOKA, PHOMOLONG LIQUORS, 7843A, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23060,'lng':27.91200}},
{'stp':716963,'address':'716963, EGL - SOWETO, AVUKILE MKOKA, IGUGULETHU LIQUOR STORE, 5460 DHLOMO STREET, EMNDENI SOUTH, SOWETO, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24510,'lng':27.82260}},
{'stp':718586,'address':'718586, EGL - SOWETO, AVUKILE MKOKA, CWABISA LIQUOR, 327 MOFOLO NORTH, #, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22410,'lng':27.87470}},
{'stp':732822,'address':'732822, EGL - SOWETO, AVUKILE MKOKA, IVYS PLACE, 14151, BRAAMFISHERVILLE EXT 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19502,'lng':27.87109}},
{'stp':782954,'address':'782954, EGL - SOWETO, AVUKILE MKOKA, MOFOLO CENTRAL, 0661 STAND NO B, MACHABA DRIVE, #, MOFOLO, South Africa','geo':{'lat':-26.23460,'lng':27.88388}},
{'stp':799291,'address':'799291, EGL - SOWETO, AVUKILE MKOKA, MABHEBEZA LIQUORE STORE, 683 ERF, CORNER CHEETAH & WILDCAT STREET, PENNYVILLE EXT1, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.21478,'lng':27.94917}},
{'stp':308827,'address':'308827, EGL - SOWETO, DENNIS TSEPE, TS LIQUOR STORE, 6943 STAND NO, XUMA STREET, ZOLA EXTENSION, SOWETO, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23550,'lng':27.82698}},
{'stp':318065,'address':'318065, EGL - SOWETO, DENNIS TSEPE, VUMISA RESTAURANT, 578/00 ERF NO, NDLOVU STREET, DUBE VILLAGE, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23483,'lng':27.89004}},
{'stp':320918,'address':'320918, EGL - SOWETO, DENNIS TSEPE, MGAZI S PLACE, 178 STAND NO, LANGALIBALELE DRIVE, #, MOFOLO, South Africa','geo':{'lat':-26.22313,'lng':27.87637}},
{'stp':386657,'address':'386657, EGL - SOWETO, DENNIS TSEPE, NHLANHLAS PLACE, 189 STAND NUMBER, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23433,'lng':27.89784}},
{'stp':388188,'address':'388188, EGL - SOWETO, DENNIS TSEPE, MAGIC S PLACE, 605 ERF NO, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23369,'lng':27.89304}},
{'stp':388297,'address':'388297, EGL - SOWETO, DENNIS TSEPE, MBATHAS PLACE, 376B STAND NUMBER, HEALD ROAD ZONE 6, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.21823,'lng':27.89239}},
{'stp':388916,'address':'388916, EGL - SOWETO, DENNIS TSEPE,  CASA MINHA BUY AND BRAAI, 2358 stand no, MCUBE DRIVE, DUBE VILLAGE, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24453,'lng':27.89328}},
{'stp':389603,'address':'389603, EGL - SOWETO, DENNIS TSEPE, STARGO PUB, 695A STAND NO, UMKHUZE STREET,, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22621,'lng':27.89625}},
{'stp':390428,'address':'390428, EGL - SOWETO, DENNIS TSEPE, VUKUZENZELE INN, 311 STAND NO, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25815,'lng':27.89234}},
{'stp':391175,'address':'391175, EGL - SOWETO, DENNIS TSEPE, SEBE S PLACE, 11261 STAND NO, BUTSHUGU STREET, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24666,'lng':27.93099}},
{'stp':481869,'address':'481869, EGL - SOWETO, DENNIS TSEPE, MBUTIS INN, 635B STAND NUMBER, SIBASA STREET, ZONE 5, MEADOWLANDS, South Africa','geo':{'lat':-26.23043,'lng':27.90031}},
{'stp':484607,'address':'484607, EGL - SOWETO, DENNIS TSEPE, XOLIS INN, 358 ZONE 11, #, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23073,'lng':27.89007}},
{'stp':490842,'address':'490842, EGL - SOWETO, DENNIS TSEPE, ROYS PLACE, 724B STAND NO, ODENDAAL ROAD, ZONE 5 MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.22941,'lng':27.89663}},
{'stp':550373,'address':'550373, EGL - SOWETO, DENNIS TSEPE, TSAKANE CORNER 18 TAVERN, 457B, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22840,'lng':27.89430}},
{'stp':550670,'address':'550670, EGL - SOWETO, DENNIS TSEPE, SUFFERING CATS, 2183 MERAPELO STREET, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24000,'lng':27.89470}},
{'stp':550727,'address':'550727, EGL - SOWETO, DENNIS TSEPE, SILVER TRAY TAVERN, 233 233 MNCUBE DRIVE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23070,'lng':27.89000}},
{'stp':551411,'address':'551411, EGL - SOWETO, DENNIS TSEPE, OUPA S TAVERN, 39B JAMA STREET, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22230,'lng':27.89720}},
{'stp':552074,'address':'552074, EGL - SOWETO, DENNIS TSEPE, ESKOMPLAZI PUB CC, 1371 MAHLOBO STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.23100,'lng':27.88130}},
{'stp':552277,'address':'552277, EGL - SOWETO, DENNIS TSEPE, LIZZY INN, 1074 MNCUBE DRIVE, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24236,'lng':27.89438}},
{'stp':553003,'address':'553003, EGL - SOWETO, DENNIS TSEPE, GOLDEN CENTRE, 1219 MLANGENI STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.23040,'lng':27.87860}},
{'stp':553874,'address':'553874, EGL - SOWETO, DENNIS TSEPE, NTSIKIS PLACE PERMIT, 247 MNCUBE DRIVE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23230,'lng':27.89100}},
{'stp':553932,'address':'553932, EGL - SOWETO, DENNIS TSEPE, GEORGES PLACE PERMIT, 137 IKWEZI DRIVE, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22750,'lng':27.87380}},
{'stp':553966,'address':'553966, EGL - SOWETO, DENNIS TSEPE, THEMBANES PLACE PERMIT, 523 MKHUNU STREET, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.23390,'lng':27.88580}},
{'stp':560388,'address':'560388, EGL - SOWETO, DENNIS TSEPE, SOLANIS INN, 86 ISIGWE STREET, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22440,'lng':27.88770}},
{'stp':560447,'address':'560447, EGL - SOWETO, DENNIS TSEPE, MAMSIES INN, 489 MNISI & NALA STREET, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.23450,'lng':27.88340}},
{'stp':561093,'address':'561093, EGL - SOWETO, DENNIS TSEPE, VUYIS RENDZOVOUS, 1145 NHLAPO STREET, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24520,'lng':27.89070}},
{'stp':561112,'address':'561112, EGL - SOWETO, DENNIS TSEPE, FIKIS INN, 2324 MNCUBE DRIVE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22820,'lng':27.88590}},
{'stp':561272,'address':'561272, EGL - SOWETO, DENNIS TSEPE, NDEBI\'S PLACE, 254 MNCUBE DRIVE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23330,'lng':27.89130}},
{'stp':580963,'address':'580963, EGL - SOWETO, DENNIS TSEPE, PEOPLE\'S INN, 1082 MATSHABA DRIVE, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.23910,'lng':27.88740}},
{'stp':603151,'address':'603151, EGL - SOWETO, DENNIS TSEPE, VANTIS PLACE, 505E QINA STR, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22630,'lng':27.89560}},
{'stp':603655,'address':'603655, EGL - SOWETO, DENNIS TSEPE, PRINS PLACE, 239 TSHINAVHENI STR, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22560,'lng':27.89100}},
{'stp':604480,'address':'604480, EGL - SOWETO, DENNIS TSEPE, NKULULEKOS PLACE, 1836 TSHEKEDI STR, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23919,'lng':27.90038}},
{'stp':604489,'address':'604489, EGL - SOWETO, DENNIS TSEPE, ROSINAS PLACE, 775E SHILUVANI STR, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23160,'lng':27.89820}},
{'stp':604550,'address':'604550, EGL - SOWETO, DENNIS TSEPE, BAFANAS PLACE, 789 MILLA STR, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22660,'lng':27.87800}},
{'stp':604585,'address':'604585, EGL - SOWETO, DENNIS TSEPE, SPARKSS INN, 481B SOMKELE STR, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22720,'lng':27.89440}},
{'stp':605304,'address':'605304, EGL - SOWETO, DENNIS TSEPE, MONTONAS PLACE, 201 TSHINAVHENI STR, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22500,'lng':27.89010}},
{'stp':605849,'address':'605849, EGL - SOWETO, DENNIS TSEPE, TSHIDIS JOINT, 504, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21892,'lng':27.89271}},
{'stp':606008,'address':'606008, EGL - SOWETO, DENNIS TSEPE, MZWANDILES PLACE, 429A UCEDE STREET, ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21849,'lng':27.89058}},
{'stp':606211,'address':'606211, EGL - SOWETO, DENNIS TSEPE, PULANES INN, 1441 MTIPA STR, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24600,'lng':27.89470}},
{'stp':606800,'address':'606800, EGL - SOWETO, DENNIS TSEPE, BILLYS JOINT, 1008 SALMON STR, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23940,'lng':27.89270}},
{'stp':606801,'address':'606801, EGL - SOWETO, DENNIS TSEPE, EDDYS PLACE, 353 LEROTHO STREET, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23370,'lng':27.89030}},
{'stp':625070,'address':'625070, EGL - SOWETO, DENNIS TSEPE, ZODWAS INN, 2116 CRAIG AVENUE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23840,'lng':27.89790}},
{'stp':625208,'address':'625208, EGL - SOWETO, DENNIS TSEPE, ANGELAS INN, 2033 MASILO STREET, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23930,'lng':27.89650}},
{'stp':628001,'address':'628001, EGL - SOWETO, DENNIS TSEPE, KINGS PLACE PERMIT, 1061A NTLEBI STREET, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25810,'lng':27.91470}},
{'stp':628723,'address':'628723, EGL - SOWETO, DENNIS TSEPE, SIS MA PERMIT, 1350 MAHLOBO STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.23140,'lng':27.88090}},
{'stp':629302,'address':'629302, EGL - SOWETO, DENNIS TSEPE, BELLINAS PLACE PERMIT, 129A SAUNDERS STREET, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22260,'lng':27.89440}},
{'stp':629949,'address':'629949, EGL - SOWETO, DENNIS TSEPE, STYLES PLACE, 692A GAMA STREET, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21890,'lng':27.88720}},
{'stp':629969,'address':'629969, EGL - SOWETO, DENNIS TSEPE, NDOWENIS PLACE PERMIT, 197A, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22490,'lng':27.89160}},
{'stp':630445,'address':'630445, EGL - SOWETO, DENNIS TSEPE, SANELES PLACE, 256 MEKHLOMAKHULA STREET, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23340,'lng':27.89120}},
{'stp':630836,'address':'630836, EGL - SOWETO, DENNIS TSEPE, SONIS TAVERN, 393 TSWINE STREET, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22880,'lng':27.89340}},
{'stp':631239,'address':'631239, EGL - SOWETO, DENNIS TSEPE, SHONISANI MIRIAM MATHIVHA, 781 A GAMA STREET, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21740,'lng':27.88610}},
{'stp':631372,'address':'631372, EGL - SOWETO, DENNIS TSEPE, JOEL\'S CABIN, 699B GAMA STREET, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21980,'lng':27.88830}},
{'stp':632119,'address':'632119, EGL - SOWETO, DENNIS TSEPE, MLAMBO\'S INN, 10 MNCUBE DRIVE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22820,'lng':27.88690}},
{'stp':632521,'address':'632521, EGL - SOWETO, DENNIS TSEPE, SIZA\'S INN, 443B FORBES ROAD, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22590,'lng':27.89290}},
{'stp':633356,'address':'633356, EGL - SOWETO, DENNIS TSEPE, GLORIA\'S BEER, 783D KUTAMA STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22684,'lng':27.89112}},
{'stp':633369,'address':'633369, EGL - SOWETO, DENNIS TSEPE, LINDIWE\'S PLACE, EFR 20684 800E SIBASA STR, MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23290,'lng':27.90090}},
{'stp':633393,'address':'633393, EGL - SOWETO, DENNIS TSEPE, TSHEPO\'S TAVERN, 476 NGWENYA STREET, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23560,'lng':27.89160}},
{'stp':633542,'address':'633542, EGL - SOWETO, DENNIS TSEPE, BIBIS INN, 276 NYANGWA STREET, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.22960,'lng':27.88440}},
{'stp':633727,'address':'633727, EGL - SOWETO, DENNIS TSEPE, GOGO\'S TUCKSHOP, 117A JAMA STREET, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22330,'lng':27.89230}},
{'stp':635262,'address':'635262, EGL - SOWETO, DENNIS TSEPE, MPUMIS PLACE, 2306 GEORGE NKOMO STR, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22760,'lng':27.88560}},
{'stp':636687,'address':'636687, EGL - SOWETO, DENNIS TSEPE, KRAP AND KRY PLACE, 402A SIGWE STREET, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21980,'lng':27.89160}},
{'stp':636762,'address':'636762, EGL - SOWETO, DENNIS TSEPE, MZWANDILE\'S INN, 3663 DLAMINI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23747,'lng':27.87298}},
{'stp':638767,'address':'638767, EGL - SOWETO, DENNIS TSEPE, MPHANDAS INN, 879 TSHABALALA STREET, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.23720,'lng':27.88510}},
{'stp':638981,'address':'638981, EGL - SOWETO, DENNIS TSEPE, ANTIE\'S PLACE, 1747 MATIME STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22630,'lng':27.87900}},
{'stp':639148,'address':'639148, EGL - SOWETO, DENNIS TSEPE, KAMLOTSHWA, 593 LOBENGULA STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22730,'lng':27.87610}},
{'stp':639297,'address':'639297, EGL - SOWETO, DENNIS TSEPE, ESIHLAHLENIS INN, 566B MAKHOBA STREET, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.23540,'lng':27.88320}},
{'stp':639350,'address':'639350, EGL - SOWETO, DENNIS TSEPE, ZODWAS PLACE, 150 JONES STREET, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23030,'lng':27.88910}},
{'stp':639643,'address':'639643, EGL - SOWETO, DENNIS TSEPE, BOBO\'S PLACE, 306 GUMEDE STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22700,'lng':27.87480}},
{'stp':640348,'address':'640348, EGL - SOWETO, DENNIS TSEPE, REMEMBER\'S INN, 526 BULAWAYO STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22463,'lng':27.87730}},
{'stp':640848,'address':'640848, EGL - SOWETO, DENNIS TSEPE, RACHEL\'S PLACE, 894A TSHIDZUMBA STREET, ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23450,'lng':27.90240}},
{'stp':641340,'address':'641340, EGL - SOWETO, DENNIS TSEPE, BOYS TAVERN, 184 LANGALIBALELE STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22880,'lng':27.87510}},
{'stp':641421,'address':'641421, EGL - SOWETO, DENNIS TSEPE, MAGAYA\'S TAVERN, 649 EKUPOLENI STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22770,'lng':27.87650}},
{'stp':641501,'address':'641501, EGL - SOWETO, DENNIS TSEPE, T AND M, 951 SALMON STREET, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24190,'lng':27.89310}},
{'stp':641658,'address':'641658, EGL - SOWETO, DENNIS TSEPE, BRA SAM\'S PLACE, 468A SHIBA STREET, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.23380,'lng':27.88380}},
{'stp':642204,'address':'642204, EGL - SOWETO, DENNIS TSEPE, VOVO\'S PLACE, 1865 TSUBAKI STREET, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24110,'lng':27.90020}},
{'stp':642316,'address':'642316, EGL - SOWETO, DENNIS TSEPE, GEORGINA\'S PLACE, 84 MKHIZE STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22800,'lng':27.87400}},
{'stp':642548,'address':'642548, EGL - SOWETO, DENNIS TSEPE, KILLER\'S PLACE, 155 IKWEZI DRIVE, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22350,'lng':27.87520}},
{'stp':647410,'address':'647410, EGL - SOWETO, DENNIS TSEPE, NKHANGWENIS PLACE, 758A NKUNA STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23201,'lng':27.89722}},
{'stp':647417,'address':'647417, EGL - SOWETO, DENNIS TSEPE, MALITSATSI\'S PLACE, 645B NKUNA STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23080,'lng':27.89870}},
{'stp':647774,'address':'647774, EGL - SOWETO, DENNIS TSEPE, THEMBI\'S PLACE, 1396 MNCUBE DRIVE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24480,'lng':27.89390}},
{'stp':648435,'address':'648435, EGL - SOWETO, DENNIS TSEPE, EMILY\'S PLACE, 677 MNISI STREET, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.23660,'lng':27.88470}},
{'stp':648902,'address':'648902, EGL - SOWETO, DENNIS TSEPE, KBK INN, 1446 SELEPE STREET, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24530,'lng':27.89540}},
{'stp':650224,'address':'650224, EGL - SOWETO, DENNIS TSEPE, KWA MAPUMULO, 901 DLAMINI STREET, MOLOFO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.23210,'lng':27.87740}},
{'stp':651156,'address':'651156, EGL - SOWETO, DENNIS TSEPE, UMHLANGA ROCKS, 1062 STAND NO, NAPELAAN, MOFOLO CENTRE, MOFOLO, South Africa','geo':{'lat':-26.23740,'lng':27.88690}},
{'stp':654982,'address':'654982, EGL - SOWETO, DENNIS TSEPE, MOSES PLACE, 22163 MZINGI STREET, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22560,'lng':27.89580}},
{'stp':661248,'address':'661248, EGL - SOWETO, DENNIS TSEPE, GO BOYS TAVERN, 693 STAND NO,  MPELA STREET DUBE VILLAGE SOWETO, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23835,'lng':27.89170}},
{'stp':663123,'address':'663123, EGL - SOWETO, DENNIS TSEPE, MAGIC\'S PLACE (NTO), 605 MAKHALEMELE STREET, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23740,'lng':27.89110}},
{'stp':665067,'address':'665067, EGL - SOWETO, DENNIS TSEPE, XOLIS INN, 866 TSHABALALA STREET, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.23670,'lng':27.88660}},
{'stp':686996,'address':'686996, EGL - SOWETO, DENNIS TSEPE, CHAIRMAN\'S PLACE, 781 STAND NO G, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23110,'lng':27.89900}},
{'stp':687009,'address':'687009, EGL - SOWETO, DENNIS TSEPE, MENKIES TAVERN, ERF 460 THWALA STREET, KLIPSPRUIT TOWNSHIP, #, PIMVILLE, South Africa','geo':{'lat':-26.25580,'lng':27.91010}},
{'stp':689786,'address':'689786, EGL - SOWETO, DENNIS TSEPE, THEMBAS PLACE, 521A SENZANGAKHONA STREET, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22580,'lng':27.89740}},
{'stp':696741,'address':'696741, EGL - SOWETO, DENNIS TSEPE, MVULANIS PLACE, 1092 NGWENISO STREET, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.23310,'lng':27.87970}},
{'stp':697210,'address':'697210, EGL - SOWETO, DENNIS TSEPE, SASAS LOUNGE, 614A VINCENT ROAD, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21950,'lng':27.88940}},
{'stp':703072,'address':'703072, EGL - SOWETO, DENNIS TSEPE, ZAMANIMAKHOSI, 303 DUBE HOSTEL, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23490,'lng':27.89860}},
{'stp':712657,'address':'712657, EGL - SOWETO, DENNIS TSEPE, MZALAS PLACE, 584B SENZANGAKHONA STR, MEADOWLANDS ZONE 4, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22880,'lng':27.89640}},
{'stp':724701,'address':'724701, EGL - SOWETO, DENNIS TSEPE, MSHENGU DISTRIBUTORS, STAND NO 00728, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.23080,'lng':27.87500}},
{'stp':754310,'address':'754310, EGL - SOWETO, DENNIS TSEPE, BUTHANE DISTRIBUTORS, 778 STAND NO, NGWENYA STREET DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23660,'lng':27.89220}},
{'stp':755980,'address':'755980, EGL - SOWETO, DENNIS TSEPE, MAME S TAVERN, 289 STAND NO, TSAKANE STREET ZONE 4 MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22680,'lng':27.89110}},
{'stp':755991,'address':'755991, EGL - SOWETO, DENNIS TSEPE, NINE WEST TAVERN, 804 B STAND NO, LOBENGULA STREET ZONE 5 MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23440,'lng':27.90100}},
{'stp':758326,'address':'758326, EGL - SOWETO, DENNIS TSEPE, PHELAMANDLA TAVERN, 11 STAND NO, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23520,'lng':27.90010}},
{'stp':767035,'address':'767035, EGL - SOWETO, DENNIS TSEPE, EVALINAH S PLACE, 629B STAND NO, ZONE 5, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.23160,'lng':27.90050}},
{'stp':770084,'address':'770084, EGL - SOWETO, DENNIS TSEPE, DUBA DUBA LIQUOR STORE, 832 STANS NO, SHOP NO 2 DUBA DUBA COMPLEX,  MCNUBE DRIVE DUBE TOWNSHIP, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23780,'lng':27.89410}},
{'stp':772676,'address':'772676, EGL - SOWETO, DENNIS TSEPE, THOBEKAS INN, 1048 STAND NO, NAPE STREET 7 MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.23880,'lng':27.88760}},
{'stp':775480,'address':'775480, EGL - SOWETO, DENNIS TSEPE, MARTHAS INN, STAND NO, 667B-46 TSHWIRI STREET ZONE 6 MEADOWLAND, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21924,'lng':27.88839}},
{'stp':783857,'address':'783857, EGL - SOWETO, DENNIS TSEPE, ZITO S INN, 1069 STAND NO, MOFOLO NORTH TOWNSHIP, #, MOFOLO, South Africa','geo':{'lat':-26.23279,'lng':27.87993}},
{'stp':785771,'address':'785771, EGL - SOWETO, DENNIS TSEPE, PATS PLACE, 1268 CETSHWAYO STREET, #, #, MOFOLO, South Africa','geo':{'lat':-26.23095,'lng':27.87933}},
{'stp':792075,'address':'792075, EGL - SOWETO, DENNIS TSEPE, GODFATHER S TAVERN, 0389 STAND NO, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23417,'lng':27.89063}},
{'stp':797663,'address':'797663, EGL - SOWETO, DENNIS TSEPE, DARRINGTONS TAKE AWAY, 1314 SOSIBO STREET, #, #, MOFOLO, South Africa','geo':{'lat':-26.23054,'lng':27.88092}},
{'stp':799670,'address':'799670, EGL - SOWETO, DENNIS TSEPE, OUMAKIES PLACE, 774 NGWENYA STREET, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23707,'lng':27.89246}},
{'stp':384745,'address':'384745, EGL - SOWETO, DENNIS TSEPE, LN MABASO LIQOUR STORE, 6166 STAND NO, MSENGE NALEDI X1, ERF 061660, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25749,'lng':27.81727}},
{'stp':388677,'address':'388677, EGL - SOWETO, DENNIS TSEPE, SKANZA S INN, 1191A STAND NO,  KHOSIMANG STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24925,'lng':27.81937}},
{'stp':391620,'address':'391620, EGL - SOWETO, DENNIS TSEPE, CHINA S PLACE, 103B MASAKENG STREET, #, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24519,'lng':27.82063}},
{'stp':467365,'address':'467365, EGL - SOWETO, DENNIS TSEPE, MA ALIS PLACE, STAND NO, 156 A GUGU STREET, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.25198,'lng':27.82374}},
{'stp':468390,'address':'468390, EGL - SOWETO, DENNIS TSEPE, VUYIS PLACE, 1027A STAND NO, EMDENI SOUTH SOWETO, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24536,'lng':27.82475}},
{'stp':481400,'address':'481400, EGL - SOWETO, DENNIS TSEPE, KGOKGA S PLACE, 2258 STAND NO, MOLOPE STREET, NALEDI, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25849,'lng':27.83273}},
{'stp':481592,'address':'481592, EGL - SOWETO, DENNIS TSEPE, KGABO NJOMANE LIQUOR STORE, 1865/6 STAND NO, BATSWAKAE STREET, CNR BATSERWE STREET, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25603,'lng':27.83189}},
{'stp':490151,'address':'490151, EGL - SOWETO, DENNIS TSEPE, JOES TAVERN, 0121B STAND NUMBER, MASAKE STREET, NALEDI, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25155,'lng':27.82116}},
{'stp':490901,'address':'490901, EGL - SOWETO, DENNIS TSEPE, MAFILAS PLACE, 1257B STAND NUMBER, MASOKO STREET, NALEDI, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23866,'lng':27.85231}},
{'stp':550098,'address':'550098, EGL - SOWETO, DENNIS TSEPE, SWAZI TAVERN, 1742 TSHANGASI STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24880,'lng':27.81640}},
{'stp':550296,'address':'550296, EGL - SOWETO, DENNIS TSEPE, MAGGIE S TAVERN, 1064A BATSWANA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25350,'lng':27.83390}},
{'stp':550872,'address':'550872, EGL - SOWETO, DENNIS TSEPE, ACE TAVERN, 843A ORBERT STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24690,'lng':27.83160}},
{'stp':551129,'address':'551129, EGL - SOWETO, DENNIS TSEPE, TONIJOMA LIQUOR STORE, 1560 4 SEROBEDI STREET, NALEDI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25460,'lng':27.82900}},
{'stp':551359,'address':'551359, EGL - SOWETO, DENNIS TSEPE, ANGELAS PLACE, 1512 B PHAKANE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25390,'lng':27.82390}},
{'stp':551925,'address':'551925, EGL - SOWETO, DENNIS TSEPE, MANZINI TAVERN - EMDENI SOUTH, ERF 1466A, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24790,'lng':27.82150}},
{'stp':580688,'address':'580688, EGL - SOWETO, DENNIS TSEPE, TAIFS PLACE, 37 BOTANI STR, EMDENI TOWNSHIP, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23900,'lng':27.82660}},
{'stp':603680,'address':'603680, EGL - SOWETO, DENNIS TSEPE, MAMTOZI, 1006B NGCOBO STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24650,'lng':27.82840}},
{'stp':604476,'address':'604476, EGL - SOWETO, DENNIS TSEPE, MANDLAS TAVERN, 2377 MNGUNI STR, DOORNKOP EXT 1, #, DOORNKOP, South Africa','geo':{'lat':-26.25110,'lng':27.80730}},
{'stp':604711,'address':'604711, EGL - SOWETO, DENNIS TSEPE, SOLLY\'S PLACE, 39 STAND  NO, MOTHATSI STREET, NALEDI SOWETO, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25410,'lng':27.81960}},
{'stp':605402,'address':'605402, EGL - SOWETO, DENNIS TSEPE, JOES PLACE, 3214 NARE STR, EMDENI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24560,'lng':27.81280}},
{'stp':606193,'address':'606193, EGL - SOWETO, DENNIS TSEPE, SHOTIS PLACE, 1776A HAMBANI STR, EMDENI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24820,'lng':27.81770}},
{'stp':606194,'address':'606194, EGL - SOWETO, DENNIS TSEPE, KARIBA JAZZ JOINT, 22B MATSHAYA ROAD, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24900,'lng':27.82090}},
{'stp':606512,'address':'606512, EGL - SOWETO, DENNIS TSEPE, OUMAS INN, 1631B NTSHWE STR, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25630,'lng':27.82770}},
{'stp':625108,'address':'625108, EGL - SOWETO, DENNIS TSEPE, GATSHENI, 59 NGWENYA STR, EMDENI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23980,'lng':27.82620}},
{'stp':626525,'address':'626525, EGL - SOWETO, DENNIS TSEPE, VERIS JAZZ JOINT PERMIT, 1794B MTHATSALATSANE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25750,'lng':27.82770}},
{'stp':627550,'address':'627550, EGL - SOWETO, DENNIS TSEPE, V T C TAVERN PERMIT, 650 MARWEDI STREET, EMDENI NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24390,'lng':27.82790}},
{'stp':627767,'address':'627767, EGL - SOWETO, DENNIS TSEPE, BREES TAVERN PERMIT, 1543A  ZAMUKULUNGISA STR, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24520,'lng':27.81972}},
{'stp':630447,'address':'630447, EGL - SOWETO, DENNIS TSEPE, JAVAS PLACE, 2273 VILAKAZI STREET, DOORNKOP EXT, #, DOORNKOP, South Africa','geo':{'lat':-26.25080,'lng':27.80680}},
{'stp':630746,'address':'630746, EGL - SOWETO, DENNIS TSEPE, MOFFATS TAVERN, 2683 SHEZI STREET, EMDENI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24220,'lng':27.81660}},
{'stp':630813,'address':'630813, EGL - SOWETO, DENNIS TSEPE, NTSOAKIS PLACE, 929 B KGAGA MASIGO STR, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25190,'lng':27.83570}},
{'stp':632887,'address':'632887, EGL - SOWETO, DENNIS TSEPE, SHEILA S PLACE, 1670 NGSHWE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25640,'lng':27.82790}},
{'stp':633004,'address':'633004, EGL - SOWETO, DENNIS TSEPE, ZODWA S PLACE, 1795B MPHAHLALATSA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25740,'lng':27.82750}},
{'stp':633005,'address':'633005, EGL - SOWETO, DENNIS TSEPE, EMSLEY\'S PLACE, 1802B NTSHWE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24275,'lng':27.81397}},
{'stp':633010,'address':'633010, EGL - SOWETO, DENNIS TSEPE, THOKO S PLACE, 1648B PHUTHI STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25540,'lng':27.82880}},
{'stp':633309,'address':'633309, EGL - SOWETO, DENNIS TSEPE, GLADY\'S PLACE, 830A TSHANGA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25010,'lng':27.83490}},
{'stp':633353,'address':'633353, EGL - SOWETO, DENNIS TSEPE, BILLYS TAVERN, 1354B BHEKUZULU STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24680,'lng':27.82310}},
{'stp':633497,'address':'633497, EGL - SOWETO, DENNIS TSEPE, BALESENG\'S PLACE, 1656A NTSHWE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25450,'lng':27.83000}},
{'stp':633530,'address':'633530, EGL - SOWETO, DENNIS TSEPE, NOKI\'S PLACE, 1840A TUMELO STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25530,'lng':27.83130}},
{'stp':633539,'address':'633539, EGL - SOWETO, DENNIS TSEPE, PHUNYA SELESELE, 954A KHIZA STR, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24720,'lng':27.82850}},
{'stp':633547,'address':'633547, EGL - SOWETO, DENNIS TSEPE, SONTO S PLACE, 3262 NARE STREET, EMDENI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24858,'lng':27.83622}},
{'stp':634008,'address':'634008, EGL - SOWETO, DENNIS TSEPE, BAFETANES TAVERN, 547 PHAKOE STREET, EMDENI EXT 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23730,'lng':27.81950}},
{'stp':635396,'address':'635396, EGL - SOWETO, DENNIS TSEPE, KOMANES PLACE, 1700B DUMELANG STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25720,'lng':27.82490}},
{'stp':635958,'address':'635958, EGL - SOWETO, DENNIS TSEPE, COUNTRY\'S PLACE, 923 MACHABA DRIVE, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.23810,'lng':27.88490}},
{'stp':638911,'address':'638911, EGL - SOWETO, DENNIS TSEPE, DUDUS PLACE, 3021 QOAGA STREET, EMDENI EXT, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24280,'lng':27.81390}},
{'stp':639085,'address':'639085, EGL - SOWETO, DENNIS TSEPE, KEITUMETSE\'S PLACE, 988A MATLALA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25130,'lng':27.83220}},
{'stp':640235,'address':'640235, EGL - SOWETO, DENNIS TSEPE, RAMOHAMA\'S INN, 857A MOLOSE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25060,'lng':27.83350}},
{'stp':640237,'address':'640237, EGL - SOWETO, DENNIS TSEPE, GEORGE\'S INN, 1076A MAGOMA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25410,'lng':27.83550}},
{'stp':640391,'address':'640391, EGL - SOWETO, DENNIS TSEPE, THOMA\'S PLACE, 1925 MOLOPE STREET, NALEDI TOWNSHIP, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25890,'lng':27.83210}},
{'stp':640393,'address':'640393, EGL - SOWETO, DENNIS TSEPE, TSHETLHA\'S PLACE, 1297A MANTHANSI STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25370,'lng':27.81970}},
{'stp':640396,'address':'640396, EGL - SOWETO, DENNIS TSEPE, MOMO\'S PLACE, 392A THABO STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25120,'lng':27.82710}},
{'stp':640402,'address':'640402, EGL - SOWETO, DENNIS TSEPE, THUSIS PLACE, 1570A ZAMUKULUNGISA STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24630,'lng':27.81870}},
{'stp':640548,'address':'640548, EGL - SOWETO, DENNIS TSEPE, WITS INN, ERF 4007 1578B DLOMO STREET, EMDENI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24800,'lng':27.81890}},
{'stp':640611,'address':'640611, EGL - SOWETO, DENNIS TSEPE, JERRY\'S PLACE, 553B MOKALANE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25230,'lng':27.82980}},
{'stp':641417,'address':'641417, EGL - SOWETO, DENNIS TSEPE, LUCKY S PLACE, 241 NAPE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25015,'lng':27.82478}},
{'stp':641522,'address':'641522, EGL - SOWETO, DENNIS TSEPE, MAGEBA\'S CORNER, 2276 ELIAS MASEKO STREET, DUBE EXT 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22640,'lng':27.88650}},
{'stp':641585,'address':'641585, EGL - SOWETO, DENNIS TSEPE, NDUMO\'S PLACE, 1090B DLOMO STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24500,'lng':27.82390}},
{'stp':641718,'address':'641718, EGL - SOWETO, DENNIS TSEPE, JERRYS PLACE, 466A KOPASE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25290,'lng':27.82640}},
{'stp':641779,'address':'641779, EGL - SOWETO, DENNIS TSEPE, JENNY S SHEBEEN, 934A ZULU STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24770,'lng':27.82820}},
{'stp':641780,'address':'641780, EGL - SOWETO, DENNIS TSEPE, BENNY\'S PLACE, 1457A KGARIBE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25570,'lng':27.82240}},
{'stp':641905,'address':'641905, EGL - SOWETO, DENNIS TSEPE, WHITE HOUSE PLACE, 439 PHAKOE STREET, EMDENI EXT 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23850,'lng':27.81690}},
{'stp':641913,'address':'641913, EGL - SOWETO, DENNIS TSEPE, TITOS PLACE, 872B MOTSWALEDI STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25100,'lng':27.83250}},
{'stp':646669,'address':'646669, EGL - SOWETO, DENNIS TSEPE, MAMPE\'S PLACE, 1761B RALEKWALO STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25820,'lng':27.82470}},
{'stp':647145,'address':'647145, EGL - SOWETO, DENNIS TSEPE, MBUSOS TAVERN, 1602A MBERENG STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25210,'lng':27.81670}},
{'stp':648064,'address':'648064, EGL - SOWETO, DENNIS TSEPE, MALGAS PLACE, 1572 B ZAMUKULUNGISA ST, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24460,'lng':27.82880}},
{'stp':648423,'address':'648423, EGL - SOWETO, DENNIS TSEPE, ABIE\'S PLACE, 383A THABO STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25070,'lng':27.82520}},
{'stp':648884,'address':'648884, EGL - SOWETO, DENNIS TSEPE, FOUNTAIN\'S INN, 288B DICHABE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24970,'lng':27.82680}},
{'stp':649105,'address':'649105, EGL - SOWETO, DENNIS TSEPE, MZALA\'S PLACE, 1625A MOEKOENA STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25170,'lng':27.81650}},
{'stp':649363,'address':'649363, EGL - SOWETO, DENNIS TSEPE, MODI\'S PLACE, 431A MAHUNE STEET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25220,'lng':27.82560}},
{'stp':649367,'address':'649367, EGL - SOWETO, DENNIS TSEPE, HA MO MEISI, 2082 A LETHOBA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25560,'lng':27.83520}},
{'stp':649935,'address':'649935, EGL - SOWETO, DENNIS TSEPE, SIPHOS PLACE, 8 B ROODEPOORT ROAD, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.23770,'lng':27.87860}},
{'stp':650675,'address':'650675, EGL - SOWETO, DENNIS TSEPE, MOIPONE INN, 1435B LEGWALE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25410,'lng':27.82910}},
{'stp':653624,'address':'653624, EGL - SOWETO, DENNIS TSEPE, SISONKE TAVERN, 677 NJOKWENI STREET, EMDENI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24350,'lng':27.82780}},
{'stp':659711,'address':'659711, EGL - SOWETO, DENNIS TSEPE, BIG BOY INN, 632B STAND NO, KOPESE STREET NALEDI SOWETO, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25370,'lng':27.82990}},
{'stp':661147,'address':'661147, EGL - SOWETO, DENNIS TSEPE, SOFANA\'S PLACE (NTO), 229 DILIZA STREET, EMDENI NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24200,'lng':27.82762}},
{'stp':663379,'address':'663379, EGL - SOWETO, DENNIS TSEPE, MOTLANES PLACE, 2193A STAND NO, SEBOKA STREET,NALEDI.KWAXUMA, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25780,'lng':27.83565}},
{'stp':663525,'address':'663525, EGL - SOWETO, DENNIS TSEPE, NTOMBI S INN, 1640B NTSHWE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25690,'lng':27.82590}},
{'stp':663529,'address':'663529, EGL - SOWETO, DENNIS TSEPE, OUPA\'S PLACE (NTO), 3161 GWARA STREET, NALEDI EXT 1, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26295,'lng':27.83632}},
{'stp':670116,'address':'670116, EGL - SOWETO, DENNIS TSEPE, MADLALISAS TAVERN, 251 BHAQA STREET, NALEDI EXT 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25880,'lng':27.81600}},
{'stp':683522,'address':'683522, EGL - SOWETO, DENNIS TSEPE, TATA TAVERN, 836A VELILE STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24560,'lng':27.83000}},
{'stp':697907,'address':'697907, EGL - SOWETO, DENNIS TSEPE, JULAS INN, 1767 B  MOHULWANE STREET, ZOLA 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24820,'lng':27.82910}},
{'stp':701638,'address':'701638, EGL - SOWETO, DENNIS TSEPE, KGARONA PUB, 579 MOJALEFA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25280,'lng':27.82990}},
{'stp':702732,'address':'702732, EGL - SOWETO, DENNIS TSEPE, MVULENIS PLACE, 380 STAND NO, MCEZE STREET, EXTENSION 2, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25570,'lng':27.81440}},
{'stp':703924,'address':'703924, EGL - SOWETO, DENNIS TSEPE, JONNYS PLACE, 1301B TANDANANI STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24820,'lng':27.82240}},
{'stp':709435,'address':'709435, EGL - SOWETO, DENNIS TSEPE, SANNAH S PLACE, 993B MATLALA STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25240,'lng':27.83260}},
{'stp':719623,'address':'719623, EGL - SOWETO, DENNIS TSEPE, ZAKES INN, 2481 NTSHERE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.26040,'lng':27.83170}},
{'stp':750154,'address':'750154, EGL - SOWETO, DENNIS TSEPE, MABATHA INN, 542/3 DLAMINI STREET, ERF 3317, EMDENI TOWNSHIP, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24430,'lng':27.82020}},
{'stp':752021,'address':'752021, EGL - SOWETO, DENNIS TSEPE, INGRAM S TAVERN, 390 STREET NO, NALEDI EXT 2, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25670,'lng':27.81470}},
{'stp':756741,'address':'756741, EGL - SOWETO, DENNIS TSEPE, PAPALIS PLACE, 1709 A NEBO STREET, EMNDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24980,'lng':27.81600}},
{'stp':765000,'address':'765000, EGL - SOWETO, DENNIS TSEPE, BADELA BUTHERIES, 3305 NJOKWANE STREET, EMDENI NORTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24470,'lng':27.81940}},
{'stp':787609,'address':'787609, EGL - SOWETO, DENNIS TSEPE, LIZZYS PLACE, 4778 STAND NO, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24530,'lng':27.82830}},
{'stp':790960,'address':'790960, EGL - SOWETO, DENNIS TSEPE, TWO SISTAZ, 1022A BONA STREET, #, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25433,'lng':27.83288}},
{'stp':799489,'address':'799489, EGL - SOWETO, DENNIS TSEPE, MAHUSHES PLACE, 562B ZAMUKULUNGISA STREET, #, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24463,'lng':27.82094}},
{'stp':799631,'address':'799631, EGL - SOWETO, DENNIS TSEPE, CALIFORNIA TAVERN, 1153A STAND NO, GUMEDE AVENUE EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24796,'lng':27.82431}},
{'stp':799700,'address':'799700, EGL - SOWETO, DENNIS TSEPE, MORWALA, 1861A BATSWAKAE STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24815,'lng':27.82963}},
{'stp':308451,'address':'308451, EGL - SOWETO, DENNIS TSEPE, MAGGIES PLACE, 0547 STAND NO, BLOCK 8, DOORNKOP, DOORNKOP, South Africa','geo':{'lat':-26.22646,'lng':27.82975}},
{'stp':383558,'address':'383558, EGL - SOWETO, DENNIS TSEPE, THANDI S INN, 3407 ERF, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21537,'lng':27.83604}},
{'stp':387934,'address':'387934, EGL - SOWETO, DENNIS TSEPE, MC LIQOUR ZONE, 1779 STAND NO, SNAKE PARK, DOORNKOP, SOWETO, ROODEPOORT, DOORNKOP, South Africa','geo':{'lat':-26.21818,'lng':27.82043}},
{'stp':388952,'address':'388952, EGL - SOWETO, DENNIS TSEPE, THE SKY, 34711 STAND NO, BLOCK 10, THULANI TOWNSHIP, #, DOORNKOP, South Africa','geo':{'lat':-26.22129,'lng':27.82797}},
{'stp':392082,'address':'392082, EGL - SOWETO, DENNIS TSEPE, SABZAS INN, 41,45TH STREET NO 2000,BLOCK 3 SNAK, #, #, DOORNKOP, South Africa','geo':{'lat':-26.21808,'lng':27.81906}},
{'stp':465832,'address':'465832, EGL - SOWETO, DENNIS TSEPE, NOMANGESI CHILLING PLACE, 803 STAND NO, COSMOS STREET, DOBSONVILLE GARDENS, DOBSONVILLE, South Africa','geo':{'lat':-26.23113,'lng':27.81106}},
{'stp':470789,'address':'470789, EGL - SOWETO, DENNIS TSEPE, PORSCHE PLACE, 2045 STAND NO, PHASE 1, BRAAMFISCHERVILLE, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19685,'lng':27.85916}},
{'stp':470950,'address':'470950, EGL - SOWETO, DENNIS TSEPE, VUKAZENZELE TAVERN, 2831 STAND NO, BLOCK 5, THULANI EXTENSION 1, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21747,'lng':27.83118}},
{'stp':472365,'address':'472365, EGL - SOWETO, DENNIS TSEPE, MUZI PLACE, 160 BRAAMFISCHER, PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20757,'lng':27.85764}},
{'stp':472921,'address':'472921, EGL - SOWETO, DENNIS TSEPE, CHILLASS LOUNGE, 5429 STAND NO, EXT 2, THULANI DOORNKOP, DOORNKOP, South Africa','geo':{'lat':-26.22110,'lng':27.83420}},
{'stp':474343,'address':'474343, EGL - SOWETO, DENNIS TSEPE, WHITE HOUSE SHEBEEN, 3808 STAND NO, BLOCK 7 SNAKE PARK, #, DOORNKOP, South Africa','geo':{'lat':-26.22513,'lng':27.82550}},
{'stp':541698,'address':'541698, EGL - SOWETO, DENNIS TSEPE, GCINASES TAVERN, 8908 STAND NO, EXTENSION 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23148,'lng':27.81526}},
{'stp':551362,'address':'551362, EGL - SOWETO, DENNIS TSEPE, UMTHOMBE RESTAURANT, 3487 MEKGWE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21580,'lng':27.86940}},
{'stp':551714,'address':'551714, EGL - SOWETO, DENNIS TSEPE, SELEBOGO LIQUOR STORE, 2795 BLOCK 5 THULANI EXT 1, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21990,'lng':27.82990}},
{'stp':552524,'address':'552524, EGL - SOWETO, DENNIS TSEPE, LAPIS PLACE PERMIT, 1578, DOORNKOP EXT 1, #, DOORNKOP, South Africa','geo':{'lat':-26.24140,'lng':27.80750}},
{'stp':553863,'address':'553863, EGL - SOWETO, DENNIS TSEPE, LENA BONGI MTHIMKHULU-PERMIT, 34209 THULANI EXT 11, #, #, DOORNKOP, South Africa','geo':{'lat':-26.21540,'lng':27.82058}},
{'stp':553864,'address':'553864, EGL - SOWETO, DENNIS TSEPE, ANGIES PLACE PERMIT, 5915 EXT 2, THULANI, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.22080,'lng':27.83960}},
{'stp':553867,'address':'553867, EGL - SOWETO, DENNIS TSEPE, MAMSWAZI PERMIT, 34171 THULANI EXT 11, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21682,'lng':27.81938}},
{'stp':553894,'address':'553894, EGL - SOWETO, DENNIS TSEPE, KATSOS PLACE PERMIT, 35368 STAND NO, EXT 10 THULANI, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.22220,'lng':27.81680}},
{'stp':553915,'address':'553915, EGL - SOWETO, DENNIS TSEPE, MARTINO S PLACE-PERMIT, 34351 THULANI EXT 11, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21550,'lng':27.82030}},
{'stp':553943,'address':'553943, EGL - SOWETO, DENNIS TSEPE, MASISIZANENE PERMIT, 34422 MNGADI STR THULANI EXT 11, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21600,'lng':27.82120}},
{'stp':560318,'address':'560318, EGL - SOWETO, DENNIS TSEPE, VUKUZENZELE PLACE PERMIT, 5510 THULANI EXT 2, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22270,'lng':27.83640}},
{'stp':560319,'address':'560319, EGL - SOWETO, DENNIS TSEPE, ZAZAS PLACE PERMIT, 476 BLOCK 3 THULANI, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22620,'lng':27.83310}},
{'stp':560320,'address':'560320, EGL - SOWETO, DENNIS TSEPE, BTS PLACE PERMIT, 1560 SUNRISE PARK, DOORNKOP EXT 1, #, DOORNKOP, South Africa','geo':{'lat':-26.24110,'lng':27.80700}},
{'stp':561105,'address':'561105, EGL - SOWETO, DENNIS TSEPE, FENCE BERRY\'S INN, 933 FELICIA STREET, DOBSONVILLE GARDENS, #, DOORNKOP, South Africa','geo':{'lat':-26.23440,'lng':27.81300}},
{'stp':561156,'address':'561156, EGL - SOWETO, DENNIS TSEPE, POYOS PLACE, 1288 BLOCK 9 SNAKE PARK THULANI TOW, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22690,'lng':27.82000}},
{'stp':580350,'address':'580350, EGL - SOWETO, DENNIS TSEPE, NYAWOS INN, 9214 STAND NO, 40 DR MAELANA STREET, DOBSONVILLE EXTENSION 3, DOBSONVILLE, South Africa','geo':{'lat':-26.23580,'lng':27.82240}},
{'stp':580361,'address':'580361, EGL - SOWETO, DENNIS TSEPE, TSAKANES TAVERN, 5182 BLOCK 1 THULANI, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21780,'lng':27.83400}},
{'stp':580375,'address':'580375, EGL - SOWETO, DENNIS TSEPE, KHUMALO S TAVERN, 5431 BLOCK 2, #, SNAKE PARK, DOORNKOP, South Africa','geo':{'lat':-26.22080,'lng':27.83450}},
{'stp':581279,'address':'581279, EGL - SOWETO, DENNIS TSEPE, VANGIS PLACE, 3770 THUBELISHA STR GREEN VILLAGE, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.23810,'lng':27.81200}},
{'stp':603161,'address':'603161, EGL - SOWETO, DENNIS TSEPE, SIBONGILES INN, 2037 BLOCK 9, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22464,'lng':27.81771}},
{'stp':603162,'address':'603162, EGL - SOWETO, DENNIS TSEPE, ROSES PLACE, 4105 BLOCK 7, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22200,'lng':27.82280}},
{'stp':603697,'address':'603697, EGL - SOWETO, DENNIS TSEPE, THAMIS PLACE, 5654 DHALONA STR THULANI EXT 2, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22070,'lng':27.83560}},
{'stp':605400,'address':'605400, EGL - SOWETO, DENNIS TSEPE, THAMIS INN, 2839, BRAAMFISCHERVILLE  PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19900,'lng':27.85610}},
{'stp':605410,'address':'605410, EGL - SOWETO, DENNIS TSEPE, DUMIS PLACE, 9409 MBALO DRIVE, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23550,'lng':27.82160}},
{'stp':606508,'address':'606508, EGL - SOWETO, DENNIS TSEPE, BACK STAGE, 100 SIPHIWE VILLAGE, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21590,'lng':27.86660}},
{'stp':606795,'address':'606795, EGL - SOWETO, DENNIS TSEPE, M AND M INN, 2727 GREEN VILLAGE, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.23713,'lng':27.80633}},
{'stp':609206,'address':'609206, EGL - SOWETO, DENNIS TSEPE, FEFES PLACE, 32451 STAND NO, 5638 BLOCK 2, DOORKNOP, DOORNKOP, South Africa','geo':{'lat':-26.28782,'lng':27.84933}},
{'stp':626245,'address':'626245, EGL - SOWETO, DENNIS TSEPE, GAGULAS INN, 3397 GREEN VILLAGE, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.23730,'lng':27.80580}},
{'stp':626379,'address':'626379, EGL - SOWETO, DENNIS TSEPE, NGWENYAS TAVERN, 496  PRINCESS DIANA AVE, MATHOLESVILLE, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.16890,'lng':27.85220}},
{'stp':629305,'address':'629305, EGL - SOWETO, DENNIS TSEPE, DINANIS PLACE PERMIT, 8964 MPHAHLELE STREET, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23230,'lng':27.81800}},
{'stp':629761,'address':'629761, EGL - SOWETO, DENNIS TSEPE, EPALAMENTENI PERMIT, 5419 BLOCK 2 THULANI, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22030,'lng':27.83520}},
{'stp':629959,'address':'629959, EGL - SOWETO, DENNIS TSEPE, MSHINIS INN, 5486 THULANI BLOCK 2, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22130,'lng':27.83710}},
{'stp':630156,'address':'630156, EGL - SOWETO, DENNIS TSEPE, GUMZAS PLACE, 9262 CNR LETHOKO STREET, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23500,'lng':27.82350}},
{'stp':630217,'address':'630217, EGL - SOWETO, DENNIS TSEPE, NKUNZI TAVERN, 45557 THULANI, DOORNKOP EXT 4, #, DOORNKOP, South Africa','geo':{'lat':-26.22070,'lng':27.80650}},
{'stp':630648,'address':'630648, EGL - SOWETO, DENNIS TSEPE, THISHI S PLACE CERT, 5495 WOLK STREET, BRAAMFISCHERVILLE EXT 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20230,'lng':27.87100}},
{'stp':630745,'address':'630745, EGL - SOWETO, DENNIS TSEPE, DAPHNE\'S PLACE, 8947, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23170,'lng':27.81750}},
{'stp':631122,'address':'631122, EGL - SOWETO, DENNIS TSEPE, THULANES PLACE, 34339 BLOCK 11, DOORNKOP EXT 3, #, DOORNKOP, South Africa','geo':{'lat':-26.21650,'lng':27.82020}},
{'stp':631134,'address':'631134, EGL - SOWETO, DENNIS TSEPE, MIKES PLACE, 9329 MANETSI STREET, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23410,'lng':27.82350}},
{'stp':631369,'address':'631369, EGL - SOWETO, DENNIS TSEPE, PRIMA, 2448 THULANI EXT 6, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21790,'lng':27.82390}},
{'stp':632718,'address':'632718, EGL - SOWETO, DENNIS TSEPE, TASTE OF THE CITY, 2220 BLOCK 6 THULANI, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21660,'lng':27.82540}},
{'stp':633310,'address':'633310, EGL - SOWETO, DENNIS TSEPE, MAWETHU INN, 4065 THUBELISHA PARK PHASE 1, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.24030,'lng':27.81030}},
{'stp':633602,'address':'633602, EGL - SOWETO, DENNIS TSEPE, ESTERS INN, 2890 THUBELISHA PHASE 4, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.23500,'lng':27.80550}},
{'stp':633685,'address':'633685, EGL - SOWETO, DENNIS TSEPE, BROTHER ELIAS INN, 3513 BLOCK 4 SNAKE PARK, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22240,'lng':27.83080}},
{'stp':634027,'address':'634027, EGL - SOWETO, DENNIS TSEPE, OUMAS PLACE, 3742 THUBELISHA STREET, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.23920,'lng':27.81070}},
{'stp':635831,'address':'635831, EGL - SOWETO, DENNIS TSEPE, JIMMY\'S TAVERN, 1524 BLOCK 9, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22940,'lng':27.82360}},
{'stp':636278,'address':'636278, EGL - SOWETO, DENNIS TSEPE, BLESSING\'S PLACE, 5337 BLOCK 1 THULANI, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21670,'lng':27.83710}},
{'stp':639317,'address':'639317, EGL - SOWETO, DENNIS TSEPE, OFENTSE, 2451 BLOCK 6, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21800,'lng':27.82410}},
{'stp':640553,'address':'640553, EGL - SOWETO, DENNIS TSEPE, PICK AND PAYS PLACE, 3343 GREEN VILLAGE, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.23780,'lng':27.80470}},
{'stp':640568,'address':'640568, EGL - SOWETO, DENNIS TSEPE, ZANELE\'S PLACE, 195 BLOCK 3 SILVERTON, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22410,'lng':27.83260}},
{'stp':641032,'address':'641032, EGL - SOWETO, DENNIS TSEPE, NHLANHLA S PLACE, 1152 THULANI, ROODEPOORT, #, DOORNKOP, South Africa','geo':{'lat':-26.22920,'lng':27.82470}},
{'stp':641342,'address':'641342, EGL - SOWETO, DENNIS TSEPE, THOLIWE\'S PLACE, 2445, BRAAMFISCHERVILLE EXT 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19670,'lng':27.85350}},
{'stp':641517,'address':'641517, EGL - SOWETO, DENNIS TSEPE, KWAVANGELI\'S PLACE, 631B KHANYILE STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23980,'lng':27.83290}},
{'stp':641934,'address':'641934, EGL - SOWETO, DENNIS TSEPE, MABESANA\'S PLACE, 630 MBONAMBI STREET, ZOLA 3, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24140,'lng':27.83010}},
{'stp':642270,'address':'642270, EGL - SOWETO, DENNIS TSEPE, STRESS FREE LAPA INN, 9059/60 ZEMBE STREET, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23320,'lng':27.81890}},
{'stp':646523,'address':'646523, EGL - SOWETO, DENNIS TSEPE, THARI E NTSHO PLACE, 1458 3894 BLOCK 4, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.24290,'lng':27.81170}},
{'stp':646567,'address':'646567, EGL - SOWETO, DENNIS TSEPE, QUEENS PLACE, 4050B BLOCK 7 SNAKE PARK, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22150,'lng':27.82150}},
{'stp':647069,'address':'647069, EGL - SOWETO, DENNIS TSEPE, BATSWANENG PLACE, 3109 BLOCK 5 SNAKE PARK, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21840,'lng':27.83110}},
{'stp':648901,'address':'648901, EGL - SOWETO, DENNIS TSEPE, PROGRESS TAVERN, 4515 BLOCK 6, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22030,'lng':27.82500}},
{'stp':649130,'address':'649130, EGL - SOWETO, DENNIS TSEPE, KIKI\'S PLACE, ERF 3907 BLOCK 4, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22410,'lng':27.82820}},
{'stp':649681,'address':'649681, EGL - SOWETO, DENNIS TSEPE, MARGRET INN, 34607 BLOCK 11, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21860,'lng':27.81790}},
{'stp':650071,'address':'650071, EGL - SOWETO, DENNIS TSEPE, KENNY S TARVEN, STAND 4245 SILVERTOWN BLOCK 7, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22430,'lng':27.82130}},
{'stp':650194,'address':'650194, EGL - SOWETO, DENNIS TSEPE, TAKIS PLACE, 2707 BLOCK 5, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22080,'lng':27.82810}},
{'stp':652571,'address':'652571, EGL - SOWETO, DENNIS TSEPE, GLORIAS PLACE, 4167 THULANI EXT 1 BLOCK 7, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22220,'lng':27.82080}},
{'stp':658651,'address':'658651, EGL - SOWETO, DENNIS TSEPE, THEMBI S PLACE, 9588 MAARONGANYANE DRIVE, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22947,'lng':27.83362}},
{'stp':659442,'address':'659442, EGL - SOWETO, DENNIS TSEPE, DICHABE DR PERMIT, 3799 BLOCK 7, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.23778,'lng':27.80470}},
{'stp':659490,'address':'659490, EGL - SOWETO, DENNIS TSEPE, SIZAKANCANE INN, 920 FELICIA STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23415,'lng':27.81376}},
{'stp':660196,'address':'660196, EGL - SOWETO, DENNIS TSEPE, WELCOME SPAZA SHOP, 9134 STAND NO, MBALO DRIVE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23300,'lng':27.82480}},
{'stp':660255,'address':'660255, EGL - SOWETO, DENNIS TSEPE, EMASOFENI, 4757 STAND NO, BLOCK 1 THULANI, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21660,'lng':27.83310}},
{'stp':660263,'address':'660263, EGL - SOWETO, DENNIS TSEPE, BOTTOMS UP (NTO), 564 BLOCK 8 SNAKE PARK, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22690,'lng':27.82770}},
{'stp':660348,'address':'660348, EGL - SOWETO, DENNIS TSEPE, HA JOYCE, STAND NO, ERF 45846, DOORNKOP EXT 12, DOORNKOP, South Africa','geo':{'lat':-26.22410,'lng':27.80670}},
{'stp':660351,'address':'660351, EGL - SOWETO, DENNIS TSEPE, ISAAC TUCKSHOP, 2314 STAND NO,  BLOCK 5, DOORNKOP, DOORNKOP, South Africa','geo':{'lat':-26.22000,'lng':27.82680}},
{'stp':660521,'address':'660521, EGL - SOWETO, DENNIS TSEPE, MNDEMANES TAVERN, 35214 BLOCK 10, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22400,'lng':27.81520}},
{'stp':660651,'address':'660651, EGL - SOWETO, DENNIS TSEPE, STOKIES, 34939 STAND NO,  BLOCK 10 THULANE DOORNKOP, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.23728,'lng':27.81947}},
{'stp':661116,'address':'661116, EGL - SOWETO, DENNIS TSEPE, MIKES TAVERN, 11695, Dobsonville Ext4, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21849,'lng':27.83110}},
{'stp':661117,'address':'661117, EGL - SOWETO, DENNIS TSEPE, CHUNAS PLACE, 12431, Dobsonville Ext5, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22078,'lng':27.82802}},
{'stp':667424,'address':'667424, EGL - SOWETO, DENNIS TSEPE, JC INN (NTO), 79 ROSE STR, DOBSONVILLE GARDENS, #, DOORNKOP, South Africa','geo':{'lat':-26.22680,'lng':27.82020}},
{'stp':684826,'address':'684826, EGL - SOWETO, DENNIS TSEPE, PAPA MIYA, 2885, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19840,'lng':27.85610}},
{'stp':685974,'address':'685974, EGL - SOWETO, DENNIS TSEPE, CRAY TAVERN, 5727 BLOCK 2, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22130,'lng':27.83630}},
{'stp':701646,'address':'701646, EGL - SOWETO, DENNIS TSEPE, THIBOS INN, 2619 BLOCK 5 THULANI EXT 1, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21930,'lng':27.82870}},
{'stp':705305,'address':'705305, EGL - SOWETO, DENNIS TSEPE, STAN PLACE, 16018, BRAAMFISCHERVILLE PHASE 12, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19083,'lng':27.85996}},
{'stp':705308,'address':'705308, EGL - SOWETO, DENNIS TSEPE, NTEBAS PLACE, 2932 BLOCK 5 THULANI EXT 1, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21660,'lng':27.83050}},
{'stp':708245,'address':'708245, EGL - SOWETO, DENNIS TSEPE, MHOBOS PLACE, 45698 IMPALA ROAD, DOORNKOP EXT 4, #, DOORNKOP, South Africa','geo':{'lat':-26.22330,'lng':27.80600}},
{'stp':717632,'address':'717632, EGL - SOWETO, DENNIS TSEPE, SARAHS INN, 3701 BLOCK 4, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22430,'lng':27.82700}},
{'stp':719895,'address':'719895, EGL - SOWETO, DENNIS TSEPE, MANS PLACE, 2021, THULANI EXT 9, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.22470,'lng':27.81880}},
{'stp':723177,'address':'723177, EGL - SOWETO, DENNIS TSEPE, SIPHO\'S TAVERN, 322 BLOCK 3, THULANI, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.22580,'lng':27.83380}},
{'stp':739229,'address':'739229, EGL - SOWETO, DENNIS TSEPE, ZODWA S PLACE, 1856 BLOCK 9, #, #, DOORNKOP, South Africa','geo':{'lat':-26.22650,'lng':27.81690}},
{'stp':745339,'address':'745339, EGL - SOWETO, DENNIS TSEPE, GODFREY TUCK SHOP, 1163 BLOCK 8 DOORNKOP, #, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.22880,'lng':27.82600}},
{'stp':747351,'address':'747351, EGL - SOWETO, DENNIS TSEPE, ACE INN, 6472 STAND NO, 13 DOORNKOP EXT, #, DOORNKOP, South Africa','geo':{'lat':-26.22440,'lng':27.80950}},
{'stp':749981,'address':'749981, EGL - SOWETO, DENNIS TSEPE, ROSINAHS PLACE, 34569 STAND NO, EXTENSION 11, DOORNKOP  SOWETO, DOORNKOP, South Africa','geo':{'lat':-26.21740,'lng':27.81860}},
{'stp':751289,'address':'751289, EGL - SOWETO, DENNIS TSEPE, VUVUS INN, 5511 STAND NO, THULANI EXT 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.22280,'lng':27.83650}},
{'stp':760296,'address':'760296, EGL - SOWETO, DENNIS TSEPE, ROADHOUSE TUCKSHOP, 34767 STAND NO, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.21900,'lng':27.81690}},
{'stp':772674,'address':'772674, EGL - SOWETO, DENNIS TSEPE, MIMI PLACE, 7096 STAND NO, PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20427,'lng':27.85042}},
{'stp':781598,'address':'781598, EGL - SOWETO, DENNIS TSEPE, MTHOMBENI SHEBEEN, STAND NO, ERF 13219 PH 11, BRAAMFISCHERVILLE EXT 9 DBN ROODEPOORT, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19312,'lng':27.87491}},
{'stp':787638,'address':'787638, EGL - SOWETO, DENNIS TSEPE, MAMLEE, STAND NO, 237-219 DRD, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21916,'lng':27.82447}},
{'stp':795006,'address':'795006, EGL - SOWETO, DENNIS TSEPE, FLOSSYS PLACE, 2189 STAND NO, INFORMATION SETHEMENT, #, DOORNKOP, South Africa','geo':{'lat':-26.22289,'lng':27.82610}},
{'stp':799293,'address':'799293, EGL - SOWETO, DENNIS TSEPE, BOIKETLONG PUB, 2793 STAND NO, BLOCK 5, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21840,'lng':27.83110}},
{'stp':799453,'address':'799453, EGL - SOWETO, DENNIS TSEPE, TONYS PLACE, 46101 STAND NO, EXT 4 DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22629,'lng':27.83313}},
{'stp':312398,'address':'312398, EGL - SOWETO, DENNIS TSEPE, E\'SOCIAL LINK (NTO), 54 DRCC STREET, DOORNKOP EXT 1, #, NOT ASSIGNED, South Africa','geo':{'lat':-26.24900,'lng':27.80549}},
{'stp':312720,'address':'312720, EGL - SOWETO, DENNIS TSEPE, BADELA FOOD AND WINE, 546 STAND NO, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24340,'lng':27.90470}},
{'stp':381903,'address':'381903, EGL - SOWETO, DENNIS TSEPE, THE ESTABLISHMENT, 2 SHOP NO, DIEPKLOOF SQUARE CORNER IMMINK, CUYLER DRIVE ZONE 3, DIEPKLOOF, South Africa','geo':{'lat':-26.23999,'lng':27.94952}},
{'stp':390487,'address':'390487, EGL - SOWETO, DENNIS TSEPE, MAKHELWANE RESTAURANT, 6974 STAND NO, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23839,'lng':27.90885}},
{'stp':390573,'address':'390573, EGL - SOWETO, DENNIS TSEPE, SOWETO INGRESS, 1A SHOP NO, CHRIS HANI ROAD, ERF 23866 DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.25913,'lng':27.94702}},
{'stp':390973,'address':'390973, EGL - SOWETO, DENNIS TSEPE, VUYO\'S RESTAURANT (NTO), 8038 VILAKAZI STREET, ORLANDO WEST, SOWETO, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':391059,'address':'391059, EGL - SOWETO, DENNIS TSEPE, MELI S PUB, 3576 MOHLOKA STREET, BLUE FLAME COMPLEX, ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26459,'lng':27.90220}},
{'stp':392372,'address':'392372, EGL - SOWETO, DENNIS TSEPE, CUBANA LATINO CAFFE- SOUTHGATE, 2 ERF NO. SHOP NO.1,CNR RIFLE RANGE, AND COLUMBINE AVE,SOUTHGA VALUE MARKET,, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.26519,'lng':27.97929}},
{'stp':392427,'address':'392427, EGL - SOWETO, DENNIS TSEPE, THATHE RESTAURANT AND LOUNGE, 3,7588 SHOP NO ,, MAKHUTSO STREET,ZONE 3 DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':392487,'address':'392487, EGL - SOWETO, DENNIS TSEPE, CLUB MONTE CARLO TAVERN, 3564 ZONE 3, PIMUILLE SOWETO, #, PIMVILLE, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':392516,'address':'392516, EGL - SOWETO, DENNIS TSEPE, MOJA CAFE, 5976 SKOTA STREET, ORLANDO EAST,, #, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24193,'lng':27.93193}},
{'stp':392684,'address':'392684, EGL - SOWETO, DENNIS TSEPE, TARANTINO FAST FOOD, 1147B GUMEDE AVE, EMDENI SOUTH, ERF NO 1147B, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24420,'lng':27.82251}},
{'stp':461225,'address':'461225, EGL - SOWETO, DENNIS TSEPE, NANDOS MAPONYA, STAND NO, ERF 2127 SHOP 154 GROUND LEVEL, MAPONYA SHOPPING CENTRE, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.26700,'lng':27.89750}},
{'stp':465645,'address':'465645, EGL - SOWETO, DENNIS TSEPE, GOODFELLAS SPORTS CAFE, STAND NO, ERVEN 2856,2867 , SHOP 6, STARLING PARK, LENASIA SOUTH, South Africa','geo':{'lat':-26.31223,'lng':27.83274}},
{'stp':467691,'address':'467691, EGL - SOWETO, DENNIS TSEPE, ROCOMAMAS MALL OF THE SOUTH (NTO), Shop mall of the south, Corner Swartkoppie and Kliprivier drive, Aspen hills, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31558,'lng':28.06241}},
{'stp':467692,'address':'467692, EGL - SOWETO, DENNIS TSEPE, SOUTH DAKOTA SPUR MALL OF THE SOUTH, Shop mall of the south, corner Swartkoppie and kliprivier drive, Aspen hills, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31558,'lng':28.06241}},
{'stp':469964,'address':'469964, EGL - SOWETO, DENNIS TSEPE, COL\'CACCHIO (NTO), MALL OF THE SOUTH, Cnr swartkoppie and kliprivier drive, As, #, ELDORADO PARK, South Africa','geo':{'lat':-26.31558,'lng':28.06241}},
{'stp':472626,'address':'472626, EGL - SOWETO, DENNIS TSEPE, TISANIS PLACE, TSHABALALA STREET, ERF 2642, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24289,'lng':27.93320}},
{'stp':473379,'address':'473379, EGL - SOWETO, DENNIS TSEPE, NYANE, 4687 TSOTETSI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24686,'lng':27.92195}},
{'stp':476127,'address':'476127, EGL - SOWETO, DENNIS TSEPE, BAFOKENG CORNER PUB AND LOUNGE, 333/4 STAND NUMBER, SAGILA STREET, PHIRI, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26675,'lng':27.85544}},
{'stp':480161,'address':'480161, EGL - SOWETO, DENNIS TSEPE, DOPPIO ZERO - MALL OF THE SOUTH (NT, CORNER SWART KOPPIES & KLIPRIVIER, ASPEN HILLS, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31558,'lng':28.06241}},
{'stp':481877,'address':'481877, EGL - SOWETO, DENNIS TSEPE, MYTHOS - MALL OF THE SOUTH (NTO), SHOP F113, MALL OF THE SOUTH, CORNER OF KLIPRIVIER AND SWARTKOPPIE RD, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31558,'lng':28.06241}},
{'stp':482367,'address':'482367, EGL - SOWETO, DENNIS TSEPE, LIQUOR AT OPEN NDOFAYA, 715A STAND NO, ODENDAAL ROAD ZONE 5, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.22745,'lng':27.89747}},
{'stp':483664,'address':'483664, EGL - SOWETO, DENNIS TSEPE, PALMS BUTCHERY LOUNGE AND GRILL, 83/299 STAND NO, MHLABA STREET, CHIAWELO, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28678,'lng':27.86927}},
{'stp':483898,'address':'483898, EGL - SOWETO, DENNIS TSEPE, KWA-LICHABA CHISANYAMA (NTO), 8033 Xorile Street, Orlando West, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23825,'lng':27.91171}},
{'stp':485242,'address':'485242, EGL - SOWETO, DENNIS TSEPE, DITSALENG, 29 STAND NUMBER, MACHINE STREET, MIDWAY, PROTEA, South Africa','geo':{'lat':-26.29041,'lng':27.85435}},
{'stp':550793,'address':'550793, EGL - SOWETO, DENNIS TSEPE, SOUTH DOWNS COUNTRY CLUB, FARM RIETVLEI NO 17, JACKSONSDRIF, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31257,'lng':28.01572}},
{'stp':551341,'address':'551341, EGL - SOWETO, DENNIS TSEPE, MAMOTLATSIS PLACE, 37B MOSHOESHOE STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21360,'lng':27.89460}},
{'stp':551711,'address':'551711, EGL - SOWETO, DENNIS TSEPE, 525 DUDUS CORNER, STAND 1702, MEADOWLANDS ZONE 9, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20860,'lng':27.88030}},
{'stp':551807,'address':'551807, EGL - SOWETO, DENNIS TSEPE, NKULULEKO INN TAVERN, 1435 THANGANI STREET, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26150,'lng':27.90900}},
{'stp':552136,'address':'552136, EGL - SOWETO, DENNIS TSEPE, HACKERS ARMS TA BELLS INN, PORT 19 DUIKER CNR GAZELLE FARM, RIETVLEI 101  RISPARK AH, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.32530,'lng':28.03280}},
{'stp':552187,'address':'552187, EGL - SOWETO, DENNIS TSEPE, THETHA LIQUOR STORE, 842/3 KININI STR CNR MACHABA DR, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.23730,'lng':27.88450}},
{'stp':552298,'address':'552298, EGL - SOWETO, DENNIS TSEPE, LIBERTY TAVERN, 967 RALEFUTSO STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25335,'lng':27.85375}},
{'stp':552365,'address':'552365, EGL - SOWETO, DENNIS TSEPE, KRAAL KOMBUIS, 46 IMPALA ROAD, KLIPRIVIERBERG NATURE RESERVE, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29930,'lng':28.02970}},
{'stp':560551,'address':'560551, EGL - SOWETO, DENNIS TSEPE, CASABLANCA INN, 1753 MOKOENA STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25580,'lng':27.85420}},
{'stp':561100,'address':'561100, EGL - SOWETO, DENNIS TSEPE, SEDIBENG PUB, 3166, MEADOWLANDS ZONE 10, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22340,'lng':27.88130}},
{'stp':580386,'address':'580386, EGL - SOWETO, DENNIS TSEPE, MAGGIES PLACE, 6186 MARTHINUS SMUTS DRIVE, DIEPKLOOF ZONE 4, #, DIEPKLOOF ZONE 4, South Africa','geo':{'lat':-26.25758,'lng':27.95171}},
{'stp':580452,'address':'580452, EGL - SOWETO, DENNIS TSEPE, MOTSWALAS PLACE, 790 THULARE STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22050,'lng':27.89240}},
{'stp':603174,'address':'603174, EGL - SOWETO, DENNIS TSEPE, FATS PLACE, 3489 ZONE 10, MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21639,'lng':27.88043}},
{'stp':605733,'address':'605733, EGL - SOWETO, DENNIS TSEPE, ONE ZERS, 794 B THULARE STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22040,'lng':27.89230}},
{'stp':607402,'address':'607402, EGL - SOWETO, DENNIS TSEPE, MOFOLO BOTTLE STORE, 1613 NKOSI STREET, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.25060,'lng':27.88560}},
{'stp':608165,'address':'608165, EGL - SOWETO, DENNIS TSEPE, SUNDOWNER (NTO), CNR KLIPRIVIER & RANDWATER BOARD, LIEFDE & VREDE, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.32593,'lng':28.06611}},
{'stp':626832,'address':'626832, EGL - SOWETO, DENNIS TSEPE, SAKHUMUZI RESTUARANT PERMIT, 6980 VILAKAZI STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23910,'lng':27.90990}},
{'stp':629549,'address':'629549, EGL - SOWETO, DENNIS TSEPE, KAYALETHU PERMIT, 1920 MPANE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23670,'lng':27.94120}},
{'stp':630609,'address':'630609, EGL - SOWETO, DENNIS TSEPE, ROADHOUSE LIQUOR STORE, 3353 ROODEPOORT ROAD, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21540,'lng':27.87140}},
{'stp':631472,'address':'631472, EGL - SOWETO, DENNIS TSEPE, PETERS PLACE, 10296 RAMATLOTLO STR, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23470,'lng':27.82830}},
{'stp':633548,'address':'633548, EGL - SOWETO, DENNIS TSEPE, TOZI\'S PLACE, 949, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24280,'lng':27.94590}},
{'stp':634780,'address':'634780, EGL - SOWETO, DENNIS TSEPE, THOKOZILES COOL PLACE, 1532 MNGWAS STREET, DLAMINI 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27460,'lng':27.87710}},
{'stp':640202,'address':'640202, EGL - SOWETO, DENNIS TSEPE, VICTORS INN, 2342 NGWENYAMA STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25240,'lng':27.94540}},
{'stp':646465,'address':'646465, EGL - SOWETO, DENNIS TSEPE, WILD SPEAR SPUR, SHOP 106 JABULANI MALL CNR KOMA & B, JABULANI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25010,'lng':27.85750}},
{'stp':648518,'address':'648518, EGL - SOWETO, DENNIS TSEPE, LIQUOR HOUSE MANGALANI, SHOP 5 MANGALANI CONVENIENCE CENTRE, CORNER OLD POTCH ROAD AND TSIANDA STREET, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28330,'lng':27.85440}},
{'stp':649002,'address':'649002, EGL - SOWETO, DENNIS TSEPE, PRIMI BAZALA, SHOP 145 MAPONYA MALL OLD POTCH RD, KLIPSPRUIT EXT 5, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25690,'lng':27.90390}},
{'stp':649573,'address':'649573, EGL - SOWETO, DENNIS TSEPE, NEWS CAFE SOWETO, SHOP 330 MAPONYA MALL OLD POTCH RD, KLIPSPRUIT EXT 5, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25970,'lng':27.90160}},
{'stp':650588,'address':'650588, EGL - SOWETO, DENNIS TSEPE, MBOMA LIQUOR STORE, 15466 CNR ABBUSE & ASSURE DRIVE, BRAAMFISHERVILLE EXT 12 (PHASE 3), #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18520,'lng':27.83250}},
{'stp':651001,'address':'651001, EGL - SOWETO, DENNIS TSEPE, PANYAZAS PLACE, 3274 VUNDLA DRIVE, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25510,'lng':27.87470}},
{'stp':652094,'address':'652094, EGL - SOWETO, DENNIS TSEPE, PAP AND NIC LIQUOR STORE, SHOP 1 3790 MARTHINUS SMUTS DR, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23710,'lng':27.94660}},
{'stp':654797,'address':'654797, EGL - SOWETO, DENNIS TSEPE, DANISH LOUNGE, 4882 IMMINK DRIVE, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23920,'lng':27.95060}},
{'stp':657440,'address':'657440, EGL - SOWETO, DENNIS TSEPE, GOLDEN FALLS SPUR, SHOP 27 COLUMBINE SQUARE COLUMBINE, SUIDEROORD, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.27200,'lng':28.02940}},
{'stp':663282,'address':'663282, EGL - SOWETO, DENNIS TSEPE, LIDO COUNTRY LODGE (NTO), TRAVEL LODGE HOTEL FARM OLIFANTS, EIKENHOF, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.32703,'lng':27.99374}},
{'stp':686954,'address':'686954, EGL - SOWETO, DENNIS TSEPE, MELI (NTO), 3576 MOHLOKA STREET, PIMVILLE  ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26270,'lng':27.89890}},
{'stp':687646,'address':'687646, EGL - SOWETO, DENNIS TSEPE, ROCKAFELLA, 247 CNR MMAKAU & MASIANE STR, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25350,'lng':27.86280}},
{'stp':689039,'address':'689039, EGL - SOWETO, DENNIS TSEPE, DEJAVOUS POOL LOUNGE, SHOP1&2 MONDEOR VILLAGE CNR MONDE &, MONDEOR, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.27770,'lng':28.00970}},
{'stp':689149,'address':'689149, EGL - SOWETO, DENNIS TSEPE, EYE OF AFRICA (NTO),  33 CAYMAN RD, EIKENHOF, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.36342,'lng':28.02086}},
{'stp':690344,'address':'690344, EGL - SOWETO, DENNIS TSEPE, THE BASEMENT LOUNGE ROUTE 82, 9 OLD VEREENIGING RD, SOUTHFORK, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31390,'lng':27.99260}},
{'stp':690687,'address':'690687, EGL - SOWETO, DENNIS TSEPE, ENTOKOZWENI SPORTS BAR, 1123, 270 DEVLAND AND EXT 27, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.30070,'lng':27.92500}},
{'stp':696100,'address':'696100, EGL - SOWETO, DENNIS TSEPE, THEE BASEMENT, SHOP 3 ASPEN VILLAGE SC ASPEN LA, MULBARTON, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31070,'lng':28.05370}},
{'stp':697871,'address':'697871, EGL - SOWETO, DENNIS TSEPE, LAPENG HOTEL (NTO), R557 RANDVAAL ROAD, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.43976,'lng':27.96920}},
{'stp':701165,'address':'701165, EGL - SOWETO, DENNIS TSEPE, THE OZ PUB AND GRILL, STAND 2025-2027 NTSANE STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25880,'lng':27.85320}},
{'stp':702637,'address':'702637, EGL - SOWETO, DENNIS TSEPE, MAPETLA LIQUOR STORE, ERF 5, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26310,'lng':27.84750}},
{'stp':712358,'address':'712358, EGL - SOWETO, DENNIS TSEPE, DISOUFENG PUB, E STR SHOP 30 DOBSONVILLE POINT MOH, DOBSONVILLE EXT 3, #, DOBSONVILLE, South Africa','geo':{'lat':-26.23034,'lng':27.83806}},
{'stp':713166,'address':'713166, EGL - SOWETO, DENNIS TSEPE, ZWIDE KA LANGA BOTTLE STORE, 1237 DUMISA STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24810,'lng':27.82610}},
{'stp':717689,'address':'717689, EGL - SOWETO, DENNIS TSEPE, KHWELA LIQUOR STORE, ERF 1636,NYUSHMAN STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23600,'lng':27.87210}},
{'stp':718062,'address':'718062, EGL - SOWETO, DENNIS TSEPE, CHAF POZI, NICOLAS ROAD ORLANDO TOWERS, ORLANDO PRESINCT, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.25450,'lng':27.92590}},
{'stp':728885,'address':'728885, EGL - SOWETO, DENNIS TSEPE, WANDIES PLACE, 618&619 MAKHALEMELE STREET, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23770,'lng':27.88950}},
{'stp':729509,'address':'729509, EGL - SOWETO, DENNIS TSEPE, C B M LIQUOR STORE, 2473 THIBEDI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24230,'lng':27.93200}},
{'stp':729544,'address':'729544, EGL - SOWETO, DENNIS TSEPE, PANAROTTIS MAPONYA MALL (NTO), MAPONYA MALL 2127 CHRIS HANI RD, KLIPSPRUIT EXT 5, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25999,'lng':27.90358}},
{'stp':751413,'address':'751413, EGL - SOWETO, DENNIS TSEPE, PH NETWORK CAFE (NTO), 555 MUSTANG STREET, #, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27304,'lng':27.99524}},
{'stp':752927,'address':'752927, EGL - SOWETO, DENNIS TSEPE, TAVERN RESTAURANT, 400 SHOP NO, ERF 2127, THE MAPONYA MALL SHOPPING CENTRE, PIMVILLE, South Africa','geo':{'lat':-26.25940,'lng':27.90150}},
{'stp':754480,'address':'754480, EGL - SOWETO, DENNIS TSEPE, PDL BAR, PIMVILLE ZONE 7, ERF 11666, SOWETO, PIMVILLE, South Africa','geo':{'lat':-26.26810,'lng':27.90320}},
{'stp':757120,'address':'757120, EGL - SOWETO, DENNIS TSEPE, MDU S LIQUOR STORE, MANJO STREET, ERF 1560, ZOLA NORTH, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24050,'lng':27.84110}},
{'stp':757265,'address':'757265, EGL - SOWETO, DENNIS TSEPE, NQABA ONE LOVE LOUNGE, 1186 B STAND NO, ZULWANE STREET ZONE 1,  ZWELISHA AND ZULWANE STREET ZONE 1, DIEPKLOOF, South Africa','geo':{'lat':-26.24370,'lng':27.94870}},
{'stp':760895,'address':'760895, EGL - SOWETO, DENNIS TSEPE, ZONE 6, STAND NO, ERF 24463 UNIT 28 BLACKCHAIN, SHOPPING CENTRE CHRISHANI RD ZONE 6, DIEPKLOOF, South Africa','geo':{'lat':-26.25840,'lng':27.94370}},
{'stp':763947,'address':'763947, EGL - SOWETO, DENNIS TSEPE, WHITEHOUSE, 93A MMSIMANGO STREET, ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22420,'lng':27.90030}},
{'stp':768729,'address':'768729, EGL - SOWETO, DENNIS TSEPE, NEXDOR (NTO), 6979 VILAKAZI STREET, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23910,'lng':27.90980}},
{'stp':771317,'address':'771317, EGL - SOWETO, DENNIS TSEPE, LA CADI RESTAURANT AND PUB, STAND NO, ERF 649 KININI STREET, MOFOLO CENTRAL, MOFOLO, South Africa','geo':{'lat':-26.23780,'lng':27.88420}},
{'stp':309578,'address':'309578, EGL - SOWETO, DENNIS TSEPE, BIG MAMMA S PUB, 1120 STAND NO, MOLELE STREET, MOLAPO, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25686,'lng':27.85989}},
{'stp':381499,'address':'381499, EGL - SOWETO, DENNIS TSEPE, KLIPPIES RESTAURANT, 37 STAND NO, UNION ROAD PIMVILLE, ZONE 9 PORTION 7 OF 11915, PIMVILLE, South Africa','geo':{'lat':-26.27661,'lng':27.88822}},
{'stp':383594,'address':'383594, EGL - SOWETO, DENNIS TSEPE, FETSIS CLUB, 1057 STAND NUMBER, PHERA STREET, WHITE CITY, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24875,'lng':27.88027}},
{'stp':388408,'address':'388408, EGL - SOWETO, DENNIS TSEPE, POWER HOTEL, 1460 STAND NO, LETSIE STREET, MAPETLA, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26786,'lng':27.84725}},
{'stp':388591,'address':'388591, EGL - SOWETO, DENNIS TSEPE, STRESS FREE LAPA, 9059/60 STAND NO, ZEMBE STREET, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22978,'lng':27.81819}},
{'stp':388700,'address':'388700, EGL - SOWETO, DENNIS TSEPE, BK TAVERN, 6647/47 STAND NO,  PZN STREET, DEVLAND EXT 30, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28351,'lng':27.92888}},
{'stp':388947,'address':'388947, EGL - SOWETO, DENNIS TSEPE, ROADHOUSE PUB AND GRILL, 3353 STAND NO, #, #, DOBSONVILLE, South Africa','geo':{'lat':-26.24222,'lng':27.84193}},
{'stp':389023,'address':'389023, EGL - SOWETO, DENNIS TSEPE, CHERRY ON TOP, 1241 STAND NO, BHEDLA STREET, ZOLA NORTH, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.23345,'lng':27.84276}},
{'stp':390121,'address':'390121, EGL - SOWETO, DENNIS TSEPE, JB S TAVERN, 2287 STAND NO, PASTY STREET PROTEA GLEN, EXT2, PROTEA, South Africa','geo':{'lat':-26.23735,'lng':27.92217}},
{'stp':390158,'address':'390158, EGL - SOWETO, DENNIS TSEPE, SWEET SUNDAY, 149 STAND, RATHEBE STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23728,'lng':27.92189}},
{'stp':390822,'address':'390822, EGL - SOWETO, DENNIS TSEPE, INFERNO GRILL AND PUB, 3738 STAND NO, #, #, DOBSONVILLE, South Africa','geo':{'lat':-26.21884,'lng':27.85803}},
{'stp':391314,'address':'391314, EGL - SOWETO, DENNIS TSEPE, IMBO PUB, 1628 STAND NO 1625, #, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27060,'lng':27.87934}},
{'stp':391619,'address':'391619, EGL - SOWETO, DENNIS TSEPE, MEATKHONA CHISANYAMA, STAND 24 FERN STREET, #, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.34640,'lng':27.87298}},
{'stp':391704,'address':'391704, EGL - SOWETO, DENNIS TSEPE, STARTING POINT, 1446 STAND NO, IMMINK DRIVE,, DIEPKLOOF, ZONE 1, DIEPKLOOF, South Africa','geo':{'lat':-26.25453,'lng':27.94102}},
{'stp':392457,'address':'392457, EGL - SOWETO, DENNIS TSEPE, PHINY S BRAAI AND BUY, 6044 CRYOLITTLE STREET, #, #, ENNERDALE, South Africa','geo':{'lat':-26.41457,'lng':27.81514}},
{'stp':460754,'address':'460754, EGL - SOWETO, DENNIS TSEPE, LIZOBUYA FANTASY, ERF 2336 875A, MVUNDLA STREET, CORNER MVUNDLA AND MARALANE STREET, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.28788,'lng':27.86851}},
{'stp':462101,'address':'462101, EGL - SOWETO, DENNIS TSEPE, BLACK LEGENDS SPORTS BAR, 6715 SHOP 5 ERF, GROUND FLOOR FLORA CENTRE 77 N 79, 6715, ELDORADO PARK, South Africa','geo':{'lat':-26.29651,'lng':27.91350}},
{'stp':471132,'address':'471132, EGL - SOWETO, DENNIS TSEPE, CHILLEX PUB AND GRILL, STAND NO, IKWEZI STATION CNR SIGADA AND MASHABA, MOFOLO NORTH, MOFOLO, South Africa','geo':{'lat':-26.23051,'lng':27.87776}},
{'stp':473536,'address':'473536, EGL - SOWETO, DENNIS TSEPE, LA CASA, PRTOEA BOULEVARD, ERF 2905 PROTEA GLEN EXT 2, SOWETO, PROTEA, South Africa','geo':{'lat':-26.28328,'lng':27.81790}},
{'stp':473671,'address':'473671, EGL - SOWETO, DENNIS TSEPE, GALISTOS, 1838 ERF, NDABA STREET, PROTEA NORTH, PROTEA, South Africa','geo':{'lat':-26.27172,'lng':27.83217}},
{'stp':479891,'address':'479891, EGL - SOWETO, DENNIS TSEPE, CHILL SPOT LOUNGE, 0251 STAND NO, FLAMINGO STREET EXTENSION II, LENSIA, LENASIA, South Africa','geo':{'lat':-26.32923,'lng':27.83560}},
{'stp':482256,'address':'482256, EGL - SOWETO, DENNIS TSEPE, SPOTT IN CAFE, MOKOENA STREET KLIPSPRUIT, #, #, PIMVILLE, South Africa','geo':{'lat':-26.25679,'lng':27.90613}},
{'stp':484604,'address':'484604, EGL - SOWETO, DENNIS TSEPE, MANNYS NETWORK LOUNGE, STAND NUMBER, SHOP NUMBER 4, CHRIS HANI AND TSANDA STREET, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28226,'lng':27.85662}},
{'stp':486381,'address':'486381, EGL - SOWETO, DENNIS TSEPE, KENNYS CORNER, 11770 STAND NO, MOHOKA STREET PIMVILLE, #, PIMVILLE, South Africa','geo':{'lat':-26.26717,'lng':27.90006}},
{'stp':487334,'address':'487334, EGL - SOWETO, DENNIS TSEPE, TAFEKS RESTAURANT LOUNGE, 108 STAND NO, #, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24953,'lng':27.84121}},
{'stp':487375,'address':'487375, EGL - SOWETO, DENNIS TSEPE, FUTTIES PALLACE, 0277 STAND NO, NYANGWA STREET, MOFOLO CENTRAL, MOFOLO, South Africa','geo':{'lat':-26.22934,'lng':27.88415}},
{'stp':487850,'address':'487850, EGL - SOWETO, DENNIS TSEPE, JOSEPH S INN, 2473 STAND NO, NEETLE LEHAE IN, LENASIA, ELDORADO PARK, South Africa','geo':{'lat':-26.35410,'lng':27.89129}},
{'stp':489247,'address':'489247, EGL - SOWETO, DENNIS TSEPE, AJ CHAFF POZZY BED AND BREAKFAST, STAND NO, PORTION 8 ERF 54, NO 35 CORFU STREET, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.27499,'lng':27.99032}},
{'stp':491631,'address':'491631, EGL - SOWETO, DENNIS TSEPE, CONSTANCE TAVERN, 22 STAND NO, GAMA STREET MEADOWLANDS, ERF 63019, MEADOWLANDS, South Africa','geo':{'lat':-26.21871,'lng':27.88697}},
{'stp':491797,'address':'491797, EGL - SOWETO, DENNIS TSEPE, MEMES PLACE, 2678 STAND NO, MEADOWLANDS EXT 10,, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21691,'lng':27.87636}},
{'stp':550172,'address':'550172, EGL - SOWETO, DENNIS TSEPE, ITIRELENG TAVERN, STAND 4110 BLOCK 7, DOORNKOP, #, DOORNKOP, South Africa','geo':{'lat':-26.22200,'lng':27.82350}},
{'stp':550445,'address':'550445, EGL - SOWETO, DENNIS TSEPE, NALEDI BY NIGHT TAVERN, 1351 B MATSIDISO STREET, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25430,'lng':27.82180}},
{'stp':550543,'address':'550543, EGL - SOWETO, DENNIS TSEPE, PAPPIE PLACE, 311 MSOLWA STREET, DLAMINI 2, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27000,'lng':27.87430}},
{'stp':550684,'address':'550684, EGL - SOWETO, DENNIS TSEPE, CROWN MINE GOLF CLUB BOOYSENS, BOOYSENS RESERVE ROAD, BOOYSENS, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23330,'lng':27.99190}},
{'stp':550715,'address':'550715, EGL - SOWETO, DENNIS TSEPE, THE HIDEAWAY, PLOT 498 CIDER RD WALKERS FRUIT FAR, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.47936,'lng':27.96669}},
{'stp':551185,'address':'551185, EGL - SOWETO, DENNIS TSEPE, THE ROCK PUB AND GRILL, 01887 STAND NO, SHOP NO 3, PARADISE SHOPPING CENTRE, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26500,'lng':27.87200}},
{'stp':551499,'address':'551499, EGL - SOWETO, DENNIS TSEPE, JOHANNESBURG PRISON OFFICERS CLUB, 9 MAIN STREET, MEREDALE, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.27120,'lng':27.96710}},
{'stp':551604,'address':'551604, EGL - SOWETO, DENNIS TSEPE, GUNDI S TAVERN, 780A BENDILE STR, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24450,'lng':27.82910}},
{'stp':551888,'address':'551888, EGL - SOWETO, DENNIS TSEPE, OASIS PUB AND RESTAURANT, 1120 MOLELE STR CNR TSHEPO, MOLAPO TOWNSHIP, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26010,'lng':27.86020}},
{'stp':552107,'address':'552107, EGL - SOWETO, DENNIS TSEPE, UKHAMBA LIQUOR STORE, PORTION 10 SITE 24443 BARAGWANATH R, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25480,'lng':27.95040}},
{'stp':552883,'address':'552883, EGL - SOWETO, DENNIS TSEPE, ROBBY S PLACE, 5634, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27730,'lng':27.90730}},
{'stp':553010,'address':'553010, EGL - SOWETO, DENNIS TSEPE, FANTASY PUB AND RESTAURANT, 2589 GEMSBOK AVE CNR CONCORD, LENASIA EXT 1, #, LENASIA, South Africa','geo':{'lat':-26.31940,'lng':27.82790}},
{'stp':553937,'address':'553937, EGL - SOWETO, DENNIS TSEPE, WALKERVILLE AGRICULTURAL SOCIETY, PLOT 104 HARTZENBERGFONTEIN, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.40420,'lng':27.96460}},
{'stp':553951,'address':'553951, EGL - SOWETO, DENNIS TSEPE, VELLYS PLACE PERMIT, 191, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20657,'lng':27.85885}},
{'stp':560313,'address':'560313, EGL - SOWETO, DENNIS TSEPE, GLENS PLACE PERMIT, 11115 BUTSHINGI STR, ORLANDO WEST EXT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23530,'lng':27.91540}},
{'stp':603145,'address':'603145, EGL - SOWETO, DENNIS TSEPE, MOLEWAS INN, 7421 MAHLATSI STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24420,'lng':27.95760}},
{'stp':603159,'address':'603159, EGL - SOWETO, DENNIS TSEPE, CHARLES PLACE, 9829 JASKEI STREET, BRAAMFISHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19063,'lng':27.86515}},
{'stp':603160,'address':'603160, EGL - SOWETO, DENNIS TSEPE, THULETHU INN, 383 BLENHEIM STR ORMONDE VIEW, MONDEOR, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.24900,'lng':27.98120}},
{'stp':603634,'address':'603634, EGL - SOWETO, DENNIS TSEPE, TLAKIS TAVERN, 7941, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.21070,'lng':27.85140}},
{'stp':604577,'address':'604577, EGL - SOWETO, DENNIS TSEPE, ACES, 3608 STAND NO, ENNEX SHOPPING CENTRE, SHOP 13, AGRAAT STREET, ENNERDALE, South Africa','geo':{'lat':-26.41724,'lng':27.82473}},
{'stp':605411,'address':'605411, EGL - SOWETO, DENNIS TSEPE, KHUMOS INN, 4051, BRAAMFISCHERVILLE PHASE 1, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20220,'lng':27.85820}},
{'stp':606506,'address':'606506, EGL - SOWETO, DENNIS TSEPE, CONDISCH PUB AND GRILL, 1150 NTSHABENG STR, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24340,'lng':27.89290}},
{'stp':625106,'address':'625106, EGL - SOWETO, DENNIS TSEPE, VIRGYS PLACE, 5251 BOND HOUSE, BRAAMFISHERVILLE EXT 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19990,'lng':27.87140}},
{'stp':625913,'address':'625913, EGL - SOWETO, DENNIS TSEPE, THE ZONE PERMIT, 190 EMDENI NORTH, JOHANNESBURG, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24140,'lng':27.82760}},
{'stp':630160,'address':'630160, EGL - SOWETO, DENNIS TSEPE, BLONDIES PLACE PERMIT, 740 MARTINUS SMUT DRIVE, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24020,'lng':27.94150}},
{'stp':633793,'address':'633793, EGL - SOWETO, DENNIS TSEPE, NEW SPOT, 7548, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20700,'lng':27.84910}},
{'stp':633961,'address':'633961, EGL - SOWETO, DENNIS TSEPE, KHULUS PLACE, 1606 SAGEWOOD AVENUE, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27100,'lng':27.81780}},
{'stp':635142,'address':'635142, EGL - SOWETO, DENNIS TSEPE, MABUTOS TAVERN, TSHIANDA STREET, CHIAWELO EXT 3, ERF 674 MABUTO SHIOPPING CENTRE, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28250,'lng':27.85320}},
{'stp':637665,'address':'637665, EGL - SOWETO, DENNIS TSEPE, BETTY\'S PLACE, 480 DIMAKATSO STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24410,'lng':27.87110}},
{'stp':639147,'address':'639147, EGL - SOWETO, DENNIS TSEPE, SONTI S PLACE, 16715 STAND NO, BRAAMFISCHERVILLE PHASE 3, EXT 12, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19672,'lng':27.84920}},
{'stp':639811,'address':'639811, EGL - SOWETO, DENNIS TSEPE, EVENING STAR ENTERTAINMENT, 538 UMGENI STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26890,'lng':27.86500}},
{'stp':641338,'address':'641338, EGL - SOWETO, DENNIS TSEPE, JOHANNA\'S INN, 1718 MASHOME STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.22090,'lng':27.87020}},
{'stp':641525,'address':'641525, EGL - SOWETO, DENNIS TSEPE, OUPA\'S INN, 10792, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19070,'lng':27.85990}},
{'stp':641665,'address':'641665, EGL - SOWETO, DENNIS TSEPE, ZAMA ZAMA INN, 212 ROBS STREET, MEADOWLANDS ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21150,'lng':27.88480}},
{'stp':647472,'address':'647472, EGL - SOWETO, DENNIS TSEPE, RAMS SHEBEEN, 9430 STAND NO, BRAAMFISCHERVILLE PHASE 2, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.18740,'lng':27.86390}},
{'stp':650185,'address':'650185, EGL - SOWETO, DENNIS TSEPE, MOKGETLES PLACE, 211A MOEMESI STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21770,'lng':27.89790}},
{'stp':659548,'address':'659548, EGL - SOWETO, DENNIS TSEPE, DIEMERENG PUB (NTO), 8034 B, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25740,'lng':27.93730}},
{'stp':663302,'address':'663302, EGL - SOWETO, DENNIS TSEPE, KATS (NTO), C/O HEINZ RD & 11 STANMORE, KIBLER PARK, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31342,'lng':28.01534}},
{'stp':665725,'address':'665725, EGL - SOWETO, DENNIS TSEPE, WALKERVILLE SPORTS BAR, PORTION 139 TALDERIC CENTRE, HARTZENBERGFONTEIN VEREENIGING, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.46910,'lng':27.95276}},
{'stp':670193,'address':'670193, EGL - SOWETO, DENNIS TSEPE, JOHNNY WALKER PUB & GRILL (NTO), PLOT 110, WALKERVILLE, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.40226,'lng':27.96014}},
{'stp':685895,'address':'685895, EGL - SOWETO, DENNIS TSEPE, NGOBENI INN, 524 LEEBAROPE STREET, MEADOWLANDS ZONE 8, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20760,'lng':27.88660}},
{'stp':688130,'address':'688130, EGL - SOWETO, DENNIS TSEPE, TYSONS PALACE, 3214B MOTLHO STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26990,'lng':27.89930}},
{'stp':688775,'address':'688775, EGL - SOWETO, DENNIS TSEPE, LAPENG PUB (NTO), NDABA DRIVE, PROTEA POINT, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.27270,'lng':27.83150}},
{'stp':688785,'address':'688785, EGL - SOWETO, DENNIS TSEPE, TSALANG PUB, ERF NO 10923, BRAAMFISCHERVILLE EXT 7, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.19030,'lng':27.85690}},
{'stp':690371,'address':'690371, EGL - SOWETO, DENNIS TSEPE, HERMANS PLACE, STAND 2678 CNR STEER, PROTEA SOUTH, #, PROTEA, South Africa','geo':{'lat':-26.28280,'lng':27.83840}},
{'stp':698437,'address':'698437, EGL - SOWETO, DENNIS TSEPE, FLAMES, 14 PAUL CRESCENT, ENNERDALE EXT 9, #, ENNERDALE, South Africa','geo':{'lat':-26.40560,'lng':27.82010}},
{'stp':700591,'address':'700591, EGL - SOWETO, DENNIS TSEPE, SPEARS DARTS AND DARTS SOCIAL CLUB, 997 SHIFFIELD ROAD, LENASIA SOUTH, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39272,'lng':27.87066}},
{'stp':700602,'address':'700602, EGL - SOWETO, DENNIS TSEPE, TORINOS, SHOP 10311 ASPEN VILLAGE SC ASPEN L, ASPEN HILLS, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.31083,'lng':28.05364}},
{'stp':701018,'address':'701018, EGL - SOWETO, DENNIS TSEPE, SISTERS SISTERS SPORTS PUB & REST (, 1561, DLAMINI TOWNSHIP, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27370,'lng':27.87660}},
{'stp':701675,'address':'701675, EGL - SOWETO, DENNIS TSEPE, AFRICA UNITED, SHOP 3 SOUTHGATE SC COLUMBINE AVE, SOUTHGATE, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.26605,'lng':27.98177}},
{'stp':713953,'address':'713953, EGL - SOWETO, DENNIS TSEPE, THE VIEW LOUNGE, SHOP1 GF 1214 PUTSIA CNR MOLEFE STR, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24310,'lng':27.89290}},
{'stp':717428,'address':'717428, EGL - SOWETO, DENNIS TSEPE, ONE FIVE PUB AND RESTAURANT, 1520 NETHOMBENI CNR MAVIMBELA ST, CHIAWELO EXT 1, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.28530,'lng':27.87120}},
{'stp':720708,'address':'720708, EGL - SOWETO, DENNIS TSEPE, ROOTS RESTAURANT AND GALLERY, 1345-8 LIMAKASO STR CNR MOAHOLI, JABAVU CENTRAL WESTERN, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24550,'lng':27.87080}},
{'stp':725437,'address':'725437, EGL - SOWETO, DENNIS TSEPE, SILVER FALCON SPUR (NTO), SHOP 402 MAPONYA MALL OLD POTCH ROA, KLIPSPRUIT EXT 5, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25864,'lng':27.90214}},
{'stp':745192,'address':'745192, EGL - SOWETO, DENNIS TSEPE, SAFARI INN (NTO), CNR GORDON ROAD AND OLD VEREENIGING, KIBLER PARK, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.30086,'lng':27.99426}},
{'stp':753496,'address':'753496, EGL - SOWETO, DENNIS TSEPE, PHIRI SHOPPING CENTRE LIQUOR STORE, STAND NO, ERF 220 TSEKO STREET PHIRI SOWETO, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26920,'lng':27.85940}},
{'stp':765009,'address':'765009, EGL - SOWETO, DENNIS TSEPE, PULENG PLACE, 18150 STAND NO, BRAAMFISHERVILLE EXT 12 & 13, #, BRAAMFISCHERVILLE/TH, South Africa','geo':{'lat':-26.20034,'lng':27.82071}},
{'stp':775597,'address':'775597, EGL - SOWETO, DENNIS TSEPE, FALCON ARROW SPUR (NTO), U204C SOUTHGATE S/C, SOUTHGATE, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.26605,'lng':27.98177}},
{'stp':787639,'address':'787639, EGL - SOWETO, DENNIS TSEPE, PLANTATION, SOFASONKE STREET, ERF 1434 ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23645,'lng':27.92901}},
{'stp':318289,'address':'318289, EGL - SOWETO, DENNIS TSEPE, JABULILIS SHEBEEN, 93A STAND NUMBER, XABA DRIVE, WHITE CITY, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24933,'lng':27.88111}},
{'stp':388426,'address':'388426, EGL - SOWETO, DENNIS TSEPE, STEVE BLUE BOTTLE LIQOUR EXPRESS, 95 STAND NO, MAY STREET, #, MOFOLO, South Africa','geo':{'lat':-26.23545,'lng':27.88095}},
{'stp':389781,'address':'389781, EGL - SOWETO, DENNIS TSEPE, MNGEMANIS INN, 351 STAND NO, A ROODEPOORT ROAD WHITE CITY, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24186,'lng':27.87906}},
{'stp':392519,'address':'392519, EGL - SOWETO, DENNIS TSEPE, FREE AT LAST, 611 B NTOMBELA STREET, #, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24611,'lng':27.87806}},
{'stp':393045,'address':'393045, EGL - SOWETO, DENNIS TSEPE, DE JENGAS RESTAURANT, 6 MLANGENI STREET, CNR TSHABALALA STREET ERF 3210, JABAVU, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25717,'lng':27.87792}},
{'stp':460755,'address':'460755, EGL - SOWETO, DENNIS TSEPE, MOSES LIQUOR STORE, STAND NO, ERF 594 THULARE STREET, MOFOLO SOUTH, SOWETO, MOFOLO, South Africa','geo':{'lat':-26.25450,'lng':27.88820}},
{'stp':462548,'address':'462548, EGL - SOWETO, DENNIS TSEPE, MNZA LIQUOR STORE, STAND NO, ERF 646 KININI STREET, MOFOLO CENTRAL, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.22425,'lng':27.89434}},
{'stp':462621,'address':'462621, EGL - SOWETO, DENNIS TSEPE, ONICCA\'S PLACE, 1099 STAND NO, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.25657,'lng':27.88566}},
{'stp':465793,'address':'465793, EGL - SOWETO, DENNIS TSEPE, ALINAS PLACE, 2768 TUNZI STREET, #, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25897,'lng':27.87964}},
{'stp':468580,'address':'468580, EGL - SOWETO, DENNIS TSEPE, THABOS PLACE, 166B WHITE CITY, ERF 6556, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24524,'lng':27.87377}},
{'stp':468924,'address':'468924, EGL - SOWETO, DENNIS TSEPE, MANGALISOS INN, 206 C STAND NO, 5 DHLAMINI DRIVE EXT 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25295,'lng':27.88203}},
{'stp':472241,'address':'472241, EGL - SOWETO, DENNIS TSEPE, HLEKOS LIQUOR STORE, ERF 536, 62 MAHLOMULA STREET, JABAVU CENTRAL WESTERN, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24315,'lng':27.87207}},
{'stp':477423,'address':'477423, EGL - SOWETO, DENNIS TSEPE, VUKASIZWE LIQUOR STORE, 9094 STAND NO, JABAVU EXTENSION 3, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25265,'lng':27.88681}},
{'stp':485989,'address':'485989, EGL - SOWETO, DENNIS TSEPE, CHOMIS PLACE, 318 STAND NO, KGAMA STREET, ZONE 2, MEADOWLANDS, South Africa','geo':{'lat':-26.25294,'lng':27.88204}},
{'stp':490896,'address':'490896, EGL - SOWETO, DENNIS TSEPE, SIPHOS PLACE, 241A STAND NO, WHITE CITY, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25064,'lng':27.88127}},
{'stp':550079,'address':'550079, EGL - SOWETO, DENNIS TSEPE, MORE S PLACE, 1442 MOFOKENG STREET, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.24720,'lng':27.88600}},
{'stp':550093,'address':'550093, EGL - SOWETO, DENNIS TSEPE, RADEBE LIQUOR STORE, 6144 TSEHLANE STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25010,'lng':27.87300}},
{'stp':550733,'address':'550733, EGL - SOWETO, DENNIS TSEPE, EMANTIMENI BOTTLE STORE, 3119/2 TSHABANGU STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25337,'lng':27.87238}},
{'stp':552661,'address':'552661, EGL - SOWETO, DENNIS TSEPE, WILLIAM S PLACE, 775B MLANGENI STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25400,'lng':27.87760}},
{'stp':552686,'address':'552686, EGL - SOWETO, DENNIS TSEPE, MABESANA, ERF 252, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23590,'lng':27.87440}},
{'stp':553517,'address':'553517, EGL - SOWETO, DENNIS TSEPE, KWALENNY\'S TAVERN, 3744 ERF 36, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23380,'lng':27.87500}},
{'stp':553822,'address':'553822, EGL - SOWETO, DENNIS TSEPE, JABUS INN PERMIT, 1460A ROODEPOORT RD, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.25050,'lng':27.88450}},
{'stp':560222,'address':'560222, EGL - SOWETO, DENNIS TSEPE, SARAHS INN PERMIT, 559 PITSO STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24160,'lng':27.87200}},
{'stp':561101,'address':'561101, EGL - SOWETO, DENNIS TSEPE, STELLAS INN, 5399 STAND NO, JABAVU  EXT 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24740,'lng':27.87330}},
{'stp':580351,'address':'580351, EGL - SOWETO, DENNIS TSEPE, BRENDAS PLACE, 1175 MACHABA DRIVE, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.24200,'lng':27.88570}},
{'stp':580961,'address':'580961, EGL - SOWETO, DENNIS TSEPE, FOS PLACE PERMIT, 626 POTO STR, MOFOLO SOUTH, #, MOFOLO, South Africa','geo':{'lat':-26.25500,'lng':27.88830}},
{'stp':580962,'address':'580962, EGL - SOWETO, DENNIS TSEPE, MOSS JAZZ CABIN, 1534C MAILULU STREET, WHITE CITY, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24630,'lng':27.87450}},
{'stp':603659,'address':'603659, EGL - SOWETO, DENNIS TSEPE, SANDILES PLACE, 84 KHESWA STR, MOFOLO SOUTH, #, MOFOLO, South Africa','geo':{'lat':-26.25140,'lng':27.88930}},
{'stp':603681,'address':'603681, EGL - SOWETO, DENNIS TSEPE, SPINOS PLACE, 1644 NANCEFIELD ROAD, MOFOLO VILLAGE, #, MOFOLO, South Africa','geo':{'lat':-26.25110,'lng':27.88570}},
{'stp':603682,'address':'603682, EGL - SOWETO, DENNIS TSEPE, HLONGWANES PLACE, 310 LEKOROPO STR, MOFOLO SOUTH, #, MOFOLO, South Africa','geo':{'lat':-26.25280,'lng':27.88820}},
{'stp':604423,'address':'604423, EGL - SOWETO, DENNIS TSEPE, THIZA TAVERN, 661MBANDZENI STR, MOFOLO SOUTH, #, MOFOLO, South Africa','geo':{'lat':-26.25550,'lng':27.88830}},
{'stp':605428,'address':'605428, EGL - SOWETO, DENNIS TSEPE, JUSTICES TAVERN, 684B MLANGENI STR, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25110,'lng':27.87770}},
{'stp':606101,'address':'606101, EGL - SOWETO, DENNIS TSEPE, VAKELE COLLINS SIKOTANE PERMIT, 79 BRAMAITE STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23054,'lng':27.86271}},
{'stp':606905,'address':'606905, EGL - SOWETO, DENNIS TSEPE, EMZINI WESINTSIZWA, 915C MLANGENI STR, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25120,'lng':27.87780}},
{'stp':607317,'address':'607317, EGL - SOWETO, DENNIS TSEPE, GAS INN, 1319 C MASINA STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24916,'lng':27.87490}},
{'stp':608523,'address':'608523, EGL - SOWETO, DENNIS TSEPE, BIZA (NTO), 995A MALUNGA STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25307,'lng':27.87593}},
{'stp':625091,'address':'625091, EGL - SOWETO, DENNIS TSEPE, MERITING, 458A JABAVU, WHITE CITY, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24870,'lng':27.88190}},
{'stp':625105,'address':'625105, EGL - SOWETO, DENNIS TSEPE, KALFIES PLACE, 619 MOTSUMI STR, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24130,'lng':27.87360}},
{'stp':625115,'address':'625115, EGL - SOWETO, DENNIS TSEPE, SEYAS PLACE, 551 PITSO STR, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24250,'lng':27.87170}},
{'stp':625936,'address':'625936, EGL - SOWETO, DENNIS TSEPE, NKULULEKOS TAVERN PERMIT, 316 MNGUNI STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23590,'lng':27.87520}},
{'stp':627187,'address':'627187, EGL - SOWETO, DENNIS TSEPE, NKELES INN PERMIT, 1407B KLAAS STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25040,'lng':27.86910}},
{'stp':628737,'address':'628737, EGL - SOWETO, DENNIS TSEPE, THATAS PLACE PERMIT, 837C CRUSTE STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24400,'lng':27.88040}},
{'stp':631108,'address':'631108, EGL - SOWETO, DENNIS TSEPE, THUKIS PLACE, 102 A SKHAKANE STREET, WHITE CITY JABVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25370,'lng':27.88070}},
{'stp':632122,'address':'632122, EGL - SOWETO, DENNIS TSEPE, TO MAMAS, 378 CRUISE STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24550,'lng':27.88110}},
{'stp':632146,'address':'632146, EGL - SOWETO, DENNIS TSEPE, ZIPHORAS INN, 669 C NJIKELANE STR, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25000,'lng':27.87850}},
{'stp':632363,'address':'632363, EGL - SOWETO, DENNIS TSEPE, MEME\'S TAVERN, 331A ROODEPOORT ROAD, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24850,'lng':27.88330}},
{'stp':632869,'address':'632869, EGL - SOWETO, DENNIS TSEPE, ARCHIES PLACE, 1075 KUNENE STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25980,'lng':27.87860}},
{'stp':633339,'address':'633339, EGL - SOWETO, DENNIS TSEPE, NOKUTHULAS PLACE, 1032 RHUBHUSHA STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26150,'lng':27.88130}},
{'stp':635114,'address':'635114, EGL - SOWETO, DENNIS TSEPE, PRISCILLAS INN, 631 MOTSUMI STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24290,'lng':27.87320}},
{'stp':636331,'address':'636331, EGL - SOWETO, DENNIS TSEPE, TILLY\'S PLACE, 177A MODISE STREET E XABA, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25390,'lng':27.88090}},
{'stp':638013,'address':'638013, EGL - SOWETO, DENNIS TSEPE, PINKY\'S TAVERN, 2739 MKHIZE STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25910,'lng':27.87880}},
{'stp':638500,'address':'638500, EGL - SOWETO, DENNIS TSEPE, GABS INN, 674 EMHLANGENI STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24180,'lng':27.87410}},
{'stp':638503,'address':'638503, EGL - SOWETO, DENNIS TSEPE, VILAS JAZZ CORNER, 970 LIMAKATSO STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24520,'lng':27.87080}},
{'stp':638626,'address':'638626, EGL - SOWETO, DENNIS TSEPE, DLAMINI ENTERPRISE, 1604 MOROBE STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23560,'lng':27.87130}},
{'stp':638768,'address':'638768, EGL - SOWETO, DENNIS TSEPE, SCOTCHS PLACE, 940 B MALUNGA STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24840,'lng':27.87750}},
{'stp':639156,'address':'639156, EGL - SOWETO, DENNIS TSEPE, ZODWAS PLACE, 526 KHUMALO STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24930,'lng':27.88010}},
{'stp':639157,'address':'639157, EGL - SOWETO, DENNIS TSEPE, MAPULES PLACE, 589 B NTOMBELA STREET, WHITE CITY JABAVU EXT 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25014,'lng':27.87993}},
{'stp':639339,'address':'639339, EGL - SOWETO, DENNIS TSEPE, MASOOANES PLACE, 456 PITSO STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24140,'lng':27.87200}},
{'stp':639662,'address':'639662, EGL - SOWETO, DENNIS TSEPE, STELLA\'S INN, 1635 A MHLANGA STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24750,'lng':27.87470}},
{'stp':640129,'address':'640129, EGL - SOWETO, DENNIS TSEPE, SIBONGILES PLACE, 1522 BOKOLO STREET, ZONDI 1, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23720,'lng':27.86950}},
{'stp':640659,'address':'640659, EGL - SOWETO, DENNIS TSEPE, MANTWA\'S INN, 1051B MALUNGA STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25660,'lng':27.87670}},
{'stp':641016,'address':'641016, EGL - SOWETO, DENNIS TSEPE, TOMBO\'S INN, 304A CRUSTE STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25040,'lng':27.88340}},
{'stp':641343,'address':'641343, EGL - SOWETO, DENNIS TSEPE, TUIS MADALA\'S PLACE, 406C MANNE STREET, WHITE CITY JABAVU EXT 2, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24670,'lng':27.87980}},
{'stp':641422,'address':'641422, EGL - SOWETO, DENNIS TSEPE, MEISIES PLACE, 349A FORBES ROAD, MEADOWLANDS ZONE 6, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22160,'lng':27.88950}},
{'stp':642010,'address':'642010, EGL - SOWETO, DENNIS TSEPE, JEFF\'S PLACE, 976, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25566,'lng':27.87477}},
{'stp':642257,'address':'642257, EGL - SOWETO, DENNIS TSEPE, MANDELA\'S INN, 291 KHUMALO STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25100,'lng':27.88270}},
{'stp':642267,'address':'642267, EGL - SOWETO, DENNIS TSEPE, SHEILA\'S PLACE, 541A KHUMALO STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24920,'lng':27.87980}},
{'stp':642308,'address':'642308, EGL - SOWETO, DENNIS TSEPE, JOHANNA\'S PLACE, 557A TWALA STREET, WHITE CITY, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24770,'lng':27.87840}},
{'stp':646896,'address':'646896, EGL - SOWETO, DENNIS TSEPE, 112 TAVERN, 112 PHERA STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25240,'lng':27.88050}},
{'stp':647110,'address':'647110, EGL - SOWETO, DENNIS TSEPE, ZIPHO\'S INN, 988 MKHIZE STREET, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26140,'lng':27.88230}},
{'stp':647773,'address':'647773, EGL - SOWETO, DENNIS TSEPE, MAVIS PLACE, 1282C MALIZA DRIVE, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25290,'lng':27.87240}},
{'stp':648755,'address':'648755, EGL - SOWETO, DENNIS TSEPE, JACKSONS PLACE, 1653C MHLANGA STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24820,'lng':27.87340}},
{'stp':649089,'address':'649089, EGL - SOWETO, DENNIS TSEPE, ESPAZA SHOP, 78 KESWA STREET, MOFOLO SOUTH, #, MOFOLO, South Africa','geo':{'lat':-26.25200,'lng':27.88830}},
{'stp':649492,'address':'649492, EGL - SOWETO, DENNIS TSEPE, SOFT TOWN, 1443A MOFOKENG STREET, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.24719,'lng':27.88600}},
{'stp':650184,'address':'650184, EGL - SOWETO, DENNIS TSEPE, SIBONGILE SHEBEEN, 29B ROODEPOORT ROAD, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25386,'lng':27.88509}},
{'stp':652567,'address':'652567, EGL - SOWETO, DENNIS TSEPE, JANES PLACE, 1151 MACHABA DRIVE, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.24190,'lng':27.88580}},
{'stp':653717,'address':'653717, EGL - SOWETO, DENNIS TSEPE, MOSHES PLACE, 259A XABA STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25020,'lng':27.88060}},
{'stp':653818,'address':'653818, EGL - SOWETO, DENNIS TSEPE, GUNIS TAVERN, 1670C NKOSI STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24710,'lng':27.87420}},
{'stp':660932,'address':'660932, EGL - SOWETO, DENNIS TSEPE, LESOTHO, 243A XABA DRIVE, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25130,'lng':27.88040}},
{'stp':663034,'address':'663034, EGL - SOWETO, DENNIS TSEPE, SOPHIE\'S PLACE (NTO), 3471 MEKGWE STREET, DOBSONVILLE, #, DOBSONVILLE, South Africa','geo':{'lat':-26.24254,'lng':27.87172}},
{'stp':664164,'address':'664164, EGL - SOWETO, DENNIS TSEPE, MANDISE\'S PLACE (NTO), 1593 B MHLANGA STR, WHITE CITY, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23645,'lng':27.86696}},
{'stp':666714,'address':'666714, EGL - SOWETO, DENNIS TSEPE, MONDIS BEER GARDEN, 40/5 ELIAS MOTSWALEDI ROAD, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22930,'lng':27.87270}},
{'stp':667892,'address':'667892, EGL - SOWETO, DENNIS TSEPE, MOLEFIS PLACE, 1169 MACHABA DRIVE, MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.24130,'lng':27.88580}},
{'stp':668861,'address':'668861, EGL - SOWETO, DENNIS TSEPE, REX, 369 PHATELI STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24200,'lng':27.87000}},
{'stp':681908,'address':'681908, EGL - SOWETO, DENNIS TSEPE, PULIS TAVERN, 439A KRUTSE STREET, WHITE CITY, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24720,'lng':27.88190}},
{'stp':683830,'address':'683830, EGL - SOWETO, DENNIS TSEPE, LOVER TUCK SHOP, 436, MOFOLO SOUTH, #, MOFOLO, South Africa','geo':{'lat':-26.25280,'lng':27.88680}},
{'stp':685922,'address':'685922, EGL - SOWETO, DENNIS TSEPE, SIBONGILES PLACE, 1324B MABOKO STREET (ERF 6177), WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25000,'lng':27.87460}},
{'stp':689268,'address':'689268, EGL - SOWETO, DENNIS TSEPE, SISBEE, 258 NTSIBANDE STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23640,'lng':27.87400}},
{'stp':723690,'address':'723690, EGL - SOWETO, DENNIS TSEPE, FRANS TAVERN, 4166 TUMAHOLE STREET, JABAVU EXT 3, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25520,'lng':27.87350}},
{'stp':724682,'address':'724682, EGL - SOWETO, DENNIS TSEPE, HA AUSI PINKY, 405C TSABANE STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24600,'lng':27.87960}},
{'stp':740622,'address':'740622, EGL - SOWETO, DENNIS TSEPE, CHILLAS TAVERN, 743B TSHABALALA STREET, #, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25690,'lng':27.87820}},
{'stp':750083,'address':'750083, EGL - SOWETO, DENNIS TSEPE, CASH INN, 1327 STREET NO, B MAMATHELA STREET MOFOLO CENTRAL, #, MOFOLO, South Africa','geo':{'lat':-26.24390,'lng':27.88210}},
{'stp':772686,'address':'772686, EGL - SOWETO, DENNIS TSEPE, MZWAKHES PLACE, 106 STAND NO, MOFOLO NORTH, #, MOFOLO, South Africa','geo':{'lat':-26.22953,'lng':27.87537}},
{'stp':776401,'address':'776401, EGL - SOWETO, DENNIS TSEPE, THEMBIS PLACE, 82 STAND NO, A SIKHAKANE STR WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25430,'lng':27.88240}},
{'stp':792732,'address':'792732, EGL - SOWETO, DENNIS TSEPE, TSHIDIS PLACE, 216 A STAND NO, 31 DHLAMINIDRIVE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25256,'lng':27.88185}},
{'stp':792736,'address':'792736, EGL - SOWETO, DENNIS TSEPE, TRNS PLACE, KHESWA STREET, ERF 102, #, MOFOLO, South Africa','geo':{'lat':-26.25138,'lng':27.88947}},
{'stp':792797,'address':'792797, EGL - SOWETO, DENNIS TSEPE, LINDIS PLACE, 176 A RAMAITE STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25431,'lng':27.88094}},
{'stp':793970,'address':'793970, EGL - SOWETO, DENNIS TSEPE, KAGISO, 1454B MAVI STREET, ERF 5900 WHITE CITY, JABAVU, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24929,'lng':27.87304}},
{'stp':797090,'address':'797090, EGL - SOWETO, DENNIS TSEPE, KHUTSO LIQUOR DISTIBUTION, 1379 MELATO STREET, ERF 6136, JABAVU EXTENSION 1, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25068,'lng':27.87328}},
{'stp':389859,'address':'389859, EGL - SOWETO, DENNIS TSEPE, LM LIQUOR, 7477 IMMINK DRIVE, ZONE 3, DIEPKLOOF TOWNSHIP, DIEPKLOOF, South Africa','geo':{'lat':-26.24468,'lng':27.94900}},
{'stp':393229,'address':'393229, EGL - SOWETO, DENNIS TSEPE, MIKA LIQUOR STORE, 1140 STAND NO, MASIANE STREET, MOLAPO, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25956,'lng':27.85815}},
{'stp':460072,'address':'460072, EGL - SOWETO, DENNIS TSEPE, REGGIE 2 LIQUOR STORE, STAND NO, 92 PERCY STREET, ENNERDALE EXT 11, ENNERDALE, South Africa','geo':{'lat':-26.42219,'lng':27.84195}},
{'stp':468403,'address':'468403, EGL - SOWETO, DENNIS TSEPE, IGUGULETHU LIQUOR STORE, 98 STAND NO, SHOP 99/100, MATSOBANE STREET, MOFOLO, South Africa','geo':{'lat':-26.25118,'lng':27.88995}},
{'stp':472001,'address':'472001, EGL - SOWETO, DENNIS TSEPE, JAZZ IN LOUNGE, 4175 STAND NO, ZONE 3 DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23607,'lng':27.94833}},
{'stp':474291,'address':'474291, EGL - SOWETO, DENNIS TSEPE, SEJESONG, 6856 MOHALE STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24116,'lng':27.95824}},
{'stp':476387,'address':'476387, EGL - SOWETO, DENNIS TSEPE, T L LIQUOR STORE, 1708 STAND NO, NKADIMENG, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.25626,'lng':27.82361}},
{'stp':477420,'address':'477420, EGL - SOWETO, DENNIS TSEPE, MASH LIQUOR STORE, 1385 STAND NUMBER, SOFASONKETI STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24250,'lng':27.92418}},
{'stp':477666,'address':'477666, EGL - SOWETO, DENNIS TSEPE, MIKES LIQUOR STORE, BEN NAUDE DRIVE, DIEPKLOOF ZONE 5, ERF 5582F, DIEPKLOOF, South Africa','geo':{'lat':-26.24863,'lng':27.94911}},
{'stp':478723,'address':'478723, EGL - SOWETO, DENNIS TSEPE, LIQUOR CITY HYPER, 9828 STAND NO, LENASIA EXT 11, LENASIA, LENASIA, South Africa','geo':{'lat':-26.33165,'lng':27.85604}},
{'stp':481068,'address':'481068, EGL - SOWETO, DENNIS TSEPE, DONGAS TAVERN, 1023 STAND NO, HOSPITAL HILL, LENASIA SOUTH, LENASIA SOUTH, South Africa','geo':{'lat':-26.38283,'lng':27.85753}},
{'stp':484563,'address':'484563, EGL - SOWETO, DENNIS TSEPE, SBO S PLACE, 6178 STAND NO, EBEN CYLER ROAD, ZONE 5, DIEPKLOOF, South Africa','geo':{'lat':-26.24334,'lng':27.95417}},
{'stp':486431,'address':'486431, EGL - SOWETO, DENNIS TSEPE, COME DUZE LIQUOR STORE, 1405 STAND NUMBER, 11 AZALIA STREET, LEHAE, ELDORADO PARK, South Africa','geo':{'lat':-26.34720,'lng':27.89040}},
{'stp':486684,'address':'486684, EGL - SOWETO, DENNIS TSEPE, EAST SIDE, STAND NO, ERF 3582 NO 50 KAMGA STREET, ELDORADO PARK, ELDORADO PARK, South Africa','geo':{'lat':-26.29069,'lng':27.92002}},
{'stp':487141,'address':'487141, EGL - SOWETO, DENNIS TSEPE, COME DUZE, 0011 STAND NO, AZALIA STREET, LEHAE EXTENSION 1, ELDORADO PARK, South Africa','geo':{'lat':-26.28502,'lng':27.87751}},
{'stp':545739,'address':'545739, EGL - SOWETO, DENNIS TSEPE, KOMANE LIQOUR STORE, ERF 11665, QUSHEKA STREET, ORLANDO WEST, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.21889,'lng':27.90951}},
{'stp':550186,'address':'550186, EGL - SOWETO, DENNIS TSEPE, ACE BOTTLE STORE, 32 WIDGEON STR, LENASIA EXT 1, #, LENASIA, South Africa','geo':{'lat':-26.31230,'lng':27.83270}},
{'stp':550331,'address':'550331, EGL - SOWETO, DENNIS TSEPE, HAMBA UZOBUYA TAVERN, NO 100 DUBE HOSTEL, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23490,'lng':27.89820}},
{'stp':550712,'address':'550712, EGL - SOWETO, DENNIS TSEPE, MANTOLIN LIQUOR STORE, SHOP 4 DIEPKLOOF COMPLEX OLD POTCH, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25980,'lng':27.94550}},
{'stp':551136,'address':'551136, EGL - SOWETO, DENNIS TSEPE, JUBEES CASH AND CARRY, 297-301 CNR MEKHARIPE & DICHABE STR, NALEDI, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24970,'lng':27.82870}},
{'stp':551210,'address':'551210, EGL - SOWETO, DENNIS TSEPE, IVANS TAVERN, LOT 575 GREENWICH STR, LENASIA SOUTH EXT 11, #, LENASIA, South Africa','geo':{'lat':-26.39550,'lng':27.85600}},
{'stp':551326,'address':'551326, EGL - SOWETO, DENNIS TSEPE, DOMINIQUES PARADISE, 3021 CAPRICORN STREET, ENNERDALE, #, ENNERDALE, South Africa','geo':{'lat':-26.40920,'lng':27.84110}},
{'stp':551496,'address':'551496, EGL - SOWETO, DENNIS TSEPE, VHADAU LIQUOR MARKET, 952 STAND NO, PROTEA GLEN SOUR PLUM STREET, #, PROTEA, South Africa','geo':{'lat':-26.26865,'lng':27.81400}},
{'stp':551513,'address':'551513, EGL - SOWETO, DENNIS TSEPE, JOES CABIN INN, 4110 MBATA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24200,'lng':27.91640}},
{'stp':551760,'address':'551760, EGL - SOWETO, DENNIS TSEPE, MBEDWENI TAVERN, 1283 UMKONDO STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27530,'lng':27.86580}},
{'stp':552158,'address':'552158, EGL - SOWETO, DENNIS TSEPE, MOSS TAVERN, 1535 LETSATSI ROAD, PROTEA NORTH, #, PROTEA, South Africa','geo':{'lat':-26.26780,'lng':27.82860}},
{'stp':552689,'address':'552689, EGL - SOWETO, DENNIS TSEPE, STARLING LIQUOR STORE, ERF 2860, LENASIA SOUTH EXT 2, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.39440,'lng':27.84860}},
{'stp':552846,'address':'552846, EGL - SOWETO, DENNIS TSEPE, BK BLUES INN, 1192 TWO ROOMS, KLIPSPRUIT, #, ELDORADO PARK, South Africa','geo':{'lat':-26.25210,'lng':27.91190}},
{'stp':552926,'address':'552926, EGL - SOWETO, DENNIS TSEPE, MEAL SPOT, 869 MPHUTI STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24530,'lng':27.87380}},
{'stp':552938,'address':'552938, EGL - SOWETO, DENNIS TSEPE, NEW WEST LIQUOR STORE, ERF 9116 HENESSEY ST, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22489,'lng':27.90918}},
{'stp':553529,'address':'553529, EGL - SOWETO, DENNIS TSEPE, SIPELA PLACE, 4893 IMMINK ROAD, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23780,'lng':27.95120}},
{'stp':553757,'address':'553757, EGL - SOWETO, DENNIS TSEPE, LENNYS TAVERN, 24 ALABAMA AVENUE, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28480,'lng':27.89190}},
{'stp':553835,'address':'553835, EGL - SOWETO, DENNIS TSEPE, ROTSOS INN, 8144A RIKHOSTO STREET, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25930,'lng':27.94450}},
{'stp':553959,'address':'553959, EGL - SOWETO, DENNIS TSEPE, PATS INN, 3059 EXT 2, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.27830,'lng':27.82280}},
{'stp':560207,'address':'560207, EGL - SOWETO, DENNIS TSEPE, CINDYS PLACE, 8457, PIMVILLE ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.26840,'lng':27.91440}},
{'stp':560522,'address':'560522, EGL - SOWETO, DENNIS TSEPE, REUBENS PLACE, 2712 STAND NO, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25060,'lng':27.93910}},
{'stp':561180,'address':'561180, EGL - SOWETO, DENNIS TSEPE, JOZIS PLACE, 1145 STAND NO, 142 NGCULU STREET, ORLANDO WEST SOWETO, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23366,'lng':27.92976}},
{'stp':580944,'address':'580944, EGL - SOWETO, DENNIS TSEPE, LAKE VICTORIA LIQOUR SUPPLY, 5579 BEN NAUDE DRIVE, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24890,'lng':27.94960}},
{'stp':581120,'address':'581120, EGL - SOWETO, DENNIS TSEPE, KILLER TAVERN, 7513 MPHATLALATSANE STR, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23500,'lng':27.94940}},
{'stp':600499,'address':'600499, EGL - SOWETO, DENNIS TSEPE, THANDIS PLACE, STAND 2048, POORTJIE, #, LAWLEY, South Africa','geo':{'lat':-26.45840,'lng':27.77150}},
{'stp':605357,'address':'605357, EGL - SOWETO, DENNIS TSEPE, TOTOS PLACE, 6206A IMMINK STR, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23640,'lng':27.95170}},
{'stp':605414,'address':'605414, EGL - SOWETO, DENNIS TSEPE, C W J BOTTLE STORE, 150/1 DUMA STR, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.23620,'lng':27.87600}},
{'stp':605506,'address':'605506, EGL - SOWETO, DENNIS TSEPE, BUZAS PLACE, 279 MAKHUDU STR, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25390,'lng':27.86440}},
{'stp':605731,'address':'605731, EGL - SOWETO, DENNIS TSEPE, MAURICE TAVERN, 5522 MNQANDANI STREET, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27460,'lng':27.90670}},
{'stp':607184,'address':'607184, EGL - SOWETO, DENNIS TSEPE, JOYCE ELIZABETH MAKGALEMELE, 8110A, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25910,'lng':27.94040}},
{'stp':628047,'address':'628047, EGL - SOWETO, DENNIS TSEPE, MAMATLALES PLACE PERMIT, 106 MOTLHO STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21200,'lng':27.90090}},
{'stp':628299,'address':'628299, EGL - SOWETO, DENNIS TSEPE, FANIE S PLACE, 4762 STAND NO, SIMELANE STREET, LAWLEY 2, LAWLEY, South Africa','geo':{'lat':-26.38850,'lng':27.79448}},
{'stp':628582,'address':'628582, EGL - SOWETO, DENNIS TSEPE, PINKYS TAVERN PERMIT, 897B DUMISA STREET, EMDENI SOUTH, #, ZOLA/EMDENI/NALEDI, South Africa','geo':{'lat':-26.24810,'lng':27.82940}},
{'stp':629257,'address':'629257, EGL - SOWETO, DENNIS TSEPE, TINTI POOL CLUB, 155 CNR MAIN & FLORA COMPTONVILLE S, NATURENA, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.27650,'lng':27.96150}},
{'stp':630992,'address':'630992, EGL - SOWETO, DENNIS TSEPE, CINDERELA GENERAL TRADING, 965 THEMBELIHLE EXT 1, VLAKFONTEIN, #, LENASIA SOUTH, South Africa','geo':{'lat':-26.37090,'lng':27.88860}},
{'stp':631683,'address':'631683, EGL - SOWETO, DENNIS TSEPE, POPI\'S SHEBEEN, 80 LAMMAS STREET, ELDORADO PARK EXT 9, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30100,'lng':27.89080}},
{'stp':631848,'address':'631848, EGL - SOWETO, DENNIS TSEPE, CHARB CHICAGO TAVERN, 354/355 CATFISH CRESCENT, LAWLEY EXT 1, #, LAWLEY, South Africa','geo':{'lat':-26.38980,'lng':27.81540}},
{'stp':633404,'address':'633404, EGL - SOWETO, DENNIS TSEPE, STEVE\'S PLACE, 644A STAND NO, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23070,'lng':27.89880}},
{'stp':634204,'address':'634204, EGL - SOWETO, DENNIS TSEPE, PASIES TAVERN, 1672, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24500,'lng':27.94100}},
{'stp':634909,'address':'634909, EGL - SOWETO, DENNIS TSEPE, DIMPHO\'S PLACE, 1496 THORNPEAR STREET, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.27370,'lng':27.81830}},
{'stp':636018,'address':'636018, EGL - SOWETO, DENNIS TSEPE, MANDIWANA TAVERN, 221 SLOVO PARK, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30690,'lng':27.89520}},
{'stp':636794,'address':'636794, EGL - SOWETO, DENNIS TSEPE, DAVID\'S INN, 1463 SAND STREET, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29800,'lng':27.89720}},
{'stp':638026,'address':'638026, EGL - SOWETO, DENNIS TSEPE, NORMAN\'S TAVERN, 812D, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25870,'lng':27.94190}},
{'stp':639291,'address':'639291, EGL - SOWETO, DENNIS TSEPE, BIG HOUSE, 396 TAKALANI STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27890,'lng':27.86920}},
{'stp':639935,'address':'639935, EGL - SOWETO, DENNIS TSEPE, MBE\'S PLACE, 176B THABANG STREET, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21800,'lng':27.90530}},
{'stp':642080,'address':'642080, EGL - SOWETO, DENNIS TSEPE, MBUELO\'S SHEEBEN, 4131 MOJAJI STREET, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27510,'lng':27.89460}},
{'stp':642263,'address':'642263, EGL - SOWETO, DENNIS TSEPE, JULIA\'S SHEBEEN, 5658, FREEDOM PARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.28550,'lng':27.94480}},
{'stp':642635,'address':'642635, EGL - SOWETO, DENNIS TSEPE, KGWALE\'S PLACE, 8107B, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25910,'lng':27.94010}},
{'stp':646486,'address':'646486, EGL - SOWETO, DENNIS TSEPE, J AND Z LIQUOR STORE, 764 PILANE STREET, MAPETLA SOWETO, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26324,'lng':27.85063}},
{'stp':647861,'address':'647861, EGL - SOWETO, DENNIS TSEPE, COSTA\'S INN, 5401 BEN NAUDE STREET, DIEPKLOOF ZONE  5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24990,'lng':27.95340}},
{'stp':648227,'address':'648227, EGL - SOWETO, DENNIS TSEPE, TINTIS RESTAURANT, SHOP 7 PALM S/C CNR MAIN AND FLORA, NATURENA, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.27640,'lng':27.96160}},
{'stp':648771,'address':'648771, EGL - SOWETO, DENNIS TSEPE, DENNIS\' PLACE, 3678 RED CURRENT STR, PROTEA GLEN EXT 2, #, PROTEA, South Africa','geo':{'lat':-26.28030,'lng':27.81910}},
{'stp':648891,'address':'648891, EGL - SOWETO, DENNIS TSEPE, PRINCE PLACE, 8040A, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25730,'lng':27.93840}},
{'stp':650239,'address':'650239, EGL - SOWETO, DENNIS TSEPE, VHADAU LIQUOR MARKET, ERF 1609A EXTENSION, P O KWA XUMA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26268,'lng':27.85959}},
{'stp':651004,'address':'651004, EGL - SOWETO, DENNIS TSEPE, EMALAHLENI, 7328 ANGOLA STREET, GRASMERE, #, ENNERDALE, South Africa','geo':{'lat':-26.43027,'lng':27.86136}},
{'stp':651199,'address':'651199, EGL - SOWETO, DENNIS TSEPE, TEEZA S PLACE, ERF 57 12 SAMUEL STREET, ENNERDALE EXT 9, #, ENNERDALE, South Africa','geo':{'lat':-26.40330,'lng':27.82360}},
{'stp':661278,'address':'661278, EGL - SOWETO, DENNIS TSEPE, SPARKS PLACE, 8085B MKHIPHAMPETHU, ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.26620,'lng':27.91360}},
{'stp':667420,'address':'667420, EGL - SOWETO, DENNIS TSEPE, PATTY\'S INN, 750 STAND NO,  ODENDAAL ROAD MEADOWLANDS TOWNSHIP, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21390,'lng':27.90200}},
{'stp':669081,'address':'669081, EGL - SOWETO, DENNIS TSEPE, MATABANE INN, 1658B, MOLAPO EXT, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26300,'lng':27.86290}},
{'stp':673699,'address':'673699, EGL - SOWETO, DENNIS TSEPE, MOLAPO BOTTLE STORE, 3 ERF MOLELE STREET, MOLAPO TOWNSHIP, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25040,'lng':27.86410}},
{'stp':675508,'address':'675508, EGL - SOWETO, DENNIS TSEPE, THE TRUE BROTHERS IN PROGRESS, 10000 ERF 12277 MPHANANA STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22390,'lng':27.92220}},
{'stp':689295,'address':'689295, EGL - SOWETO, DENNIS TSEPE, MOGALES PLACE, STAND NO 257, KLIPSRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25790,'lng':27.90740}},
{'stp':695860,'address':'695860, EGL - SOWETO, DENNIS TSEPE, TOP DECK, 6441 IMMINK DRIVE, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23440,'lng':27.95270}},
{'stp':716435,'address':'716435, EGL - SOWETO, DENNIS TSEPE, MAJAHA LIQUOR STORE, ERF 943 RAMASHILA STREET, CHIAWELO, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.27983,'lng':27.87219}},
{'stp':720226,'address':'720226, EGL - SOWETO, DENNIS TSEPE, HAJHIRA LIQUOR STORE, ERF 381 MASILWANE STREET, TLADI, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.24869,'lng':27.84501}},
{'stp':733293,'address':'733293, EGL - SOWETO, DENNIS TSEPE, KENNETH LIQUOR STORE, 3145 52 MOUTHI STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24510,'lng':27.87810}},
{'stp':753598,'address':'753598, EGL - SOWETO, DENNIS TSEPE, DUMAS PLACE, 4830 STAND NO, PILISO STREET ORLANDO EAST SOWETO, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24710,'lng':27.92460}},
{'stp':773631,'address':'773631, EGL - SOWETO, DENNIS TSEPE, JOYCE S PLACE, 1432 C STAND NO, RADEBE STREET, WHITE CITY, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24881,'lng':27.87143}},
{'stp':779650,'address':'779650, EGL - SOWETO, DENNIS TSEPE, MUSHWANA LIQUOR STORE, 2654 RAPULANE STREET, #, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25178,'lng':27.93882}},
{'stp':388656,'address':'388656, EGL - SOWETO, DENNIS TSEPE, GLADY S PLACE, 862 STAND NO, LETUMA STREET, MOLAPO TOWNSHIP, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25793,'lng':27.86420}},
{'stp':389857,'address':'389857, EGL - SOWETO, DENNIS TSEPE, RIB S INN, 395 STAND NO, MSUNDUSI STREET, SENOANE, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26927,'lng':27.86316}},
{'stp':392080,'address':'392080, EGL - SOWETO, DENNIS TSEPE, WHITE HOUSE TAKE AWAY, 0402 STAND,, 19 KOLISANG STREET, MOLAPO, SOWETO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':392138,'address':'392138, EGL - SOWETO, DENNIS TSEPE, ROCKTOWN DELI, 3487 STAND NO, VUNDLA STREET, MOROKA, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25906,'lng':27.86743}},
{'stp':392577,'address':'392577, EGL - SOWETO, DENNIS TSEPE, MABELE, 2515 LEFATOLA STREET, #, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25890,'lng':27.86920}},
{'stp':461842,'address':'461842, EGL - SOWETO, DENNIS TSEPE, MSIBI LIQUOR STORE, STAND NO, ERF 577, MAZIBUKO STREET, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25956,'lng':27.87532}},
{'stp':462595,'address':'462595, EGL - SOWETO, DENNIS TSEPE, LINDIWES PLACE, 2643 MOROKA, #, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26287,'lng':27.87363}},
{'stp':468048,'address':'468048, EGL - SOWETO, DENNIS TSEPE, TSHIDIS PLACE, 2198 STAND NO, MAKUPSO STREET, ROCKVILLE, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26681,'lng':27.86938}},
{'stp':468393,'address':'468393, EGL - SOWETO, DENNIS TSEPE, NGAMLAS LIQUOR, 730 STAND NO,  IMPI STREET  PHIRI SOWETO, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27182,'lng':27.85289}},
{'stp':468624,'address':'468624, EGL - SOWETO, DENNIS TSEPE, SKONTIRI, 941 STAND NO, LESEDI STREET, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27470,'lng':27.85470}},
{'stp':473498,'address':'473498, EGL - SOWETO, DENNIS TSEPE, JEFFS LIQUOR STORE, 191 STAND NO, LEFU STREET, PHIRIR, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26570,'lng':27.88020}},
{'stp':474908,'address':'474908, EGL - SOWETO, DENNIS TSEPE, BENITO, 447B STAND NO,  MABALANE STREET PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26921,'lng':27.85286}},
{'stp':476985,'address':'476985, EGL - SOWETO, DENNIS TSEPE, GA RAMARUMO, 424 STAND NO, SEKWIRI STREET, ZONE 8, MEADOWLANDS, South Africa','geo':{'lat':-26.26032,'lng':27.87022}},
{'stp':478393,'address':'478393, EGL - SOWETO, DENNIS TSEPE, ZANDILE S PLACE, 2639 STAND NO, SOBEZO STREET, MOROKA, PIMVILLE, South Africa','geo':{'lat':-26.25893,'lng':27.87599}},
{'stp':490352,'address':'490352, EGL - SOWETO, DENNIS TSEPE, TARRAZ S TAVERN, STAND NO, 0977A PHIRI, SOWETO, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27056,'lng':27.85621}},
{'stp':550287,'address':'550287, EGL - SOWETO, DENNIS TSEPE, ELLENS INN, 2352 KEDIMOTSE STR, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26140,'lng':27.87380}},
{'stp':550920,'address':'550920, EGL - SOWETO, DENNIS TSEPE, PEGGY S PLACE, 2475, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26110,'lng':27.86850}},
{'stp':551041,'address':'551041, EGL - SOWETO, DENNIS TSEPE, MITTAS PLACE, 22 MAKAPANE STREET, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25600,'lng':27.86540}},
{'stp':552589,'address':'552589, EGL - SOWETO, DENNIS TSEPE, JOYCE INN, 284 MPHETHE STREET, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25874,'lng':27.86882}},
{'stp':553176,'address':'553176, EGL - SOWETO, DENNIS TSEPE, EBUMNANDINI TAVERN, 117 B NHLAPO STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26920,'lng':27.86030}},
{'stp':553390,'address':'553390, EGL - SOWETO, DENNIS TSEPE, JULLS INN, 1310 MOLALO STREET, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26100,'lng':27.86490}},
{'stp':553430,'address':'553430, EGL - SOWETO, DENNIS TSEPE, THE INN CROWD, 2054 MAMPONA STREET, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26740,'lng':27.87460}},
{'stp':553744,'address':'553744, EGL - SOWETO, DENNIS TSEPE, T J TAVERN, 866 SHEZI STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27180,'lng':27.86890}},
{'stp':553849,'address':'553849, EGL - SOWETO, DENNIS TSEPE, TK ZEE PERMIT, 573D PHIRI STR CNR MABALANE, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26970,'lng':27.85260}},
{'stp':560321,'address':'560321, EGL - SOWETO, DENNIS TSEPE, MOROKA DAM INN PERMIT, 1814 BHUDAZA STR, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26290,'lng':27.87710}},
{'stp':560475,'address':'560475, EGL - SOWETO, DENNIS TSEPE, LOVERS CORNER, 222 MOFOKENG STR, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25490,'lng':27.86880}},
{'stp':561157,'address':'561157, EGL - SOWETO, DENNIS TSEPE, TAWANA TUCK SHOP, 2775 THULANE STREET,  EXT 1, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27700,'lng':27.84840}},
{'stp':561165,'address':'561165, EGL - SOWETO, DENNIS TSEPE, COOL TAVERN, 756B KHUMALO STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27220,'lng':27.85320}},
{'stp':561232,'address':'561232, EGL - SOWETO, DENNIS TSEPE, MONTOAS INN, 248 MATHATSIE STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25890,'lng':27.86830}},
{'stp':561234,'address':'561234, EGL - SOWETO, DENNIS TSEPE, LUCKYS INN, 150 STREET NO, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26680,'lng':27.86440}},
{'stp':580500,'address':'580500, EGL - SOWETO, DENNIS TSEPE, MIRRIAM RAFEDILE, 328 LEPAGUA STR, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25650,'lng':27.86860}},
{'stp':580981,'address':'580981, EGL - SOWETO, DENNIS TSEPE, MAGGIES PLACE, 82B MOKHELE STR, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26556,'lng':27.85741}},
{'stp':581134,'address':'581134, EGL - SOWETO, DENNIS TSEPE, RONNIES PLACE, 2544 LANGA STR, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26060,'lng':27.86990}},
{'stp':603148,'address':'603148, EGL - SOWETO, DENNIS TSEPE, MPONENG INN, 97A NHLAPO STR, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26680,'lng':27.85690}},
{'stp':603164,'address':'603164, EGL - SOWETO, DENNIS TSEPE, OSIANNAS INN, 2428 LANGA STR, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26030,'lng':27.87010}},
{'stp':604068,'address':'604068, EGL - SOWETO, DENNIS TSEPE, SMALL INN, 113 A MOKHELE STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26860,'lng':27.85990}},
{'stp':604120,'address':'604120, EGL - SOWETO, DENNIS TSEPE, PINKIES PLACE, 1683A MOLELE STR, MOLAPO EXT, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26330,'lng':27.86020}},
{'stp':604249,'address':'604249, EGL - SOWETO, DENNIS TSEPE, MAWILIES INN, 2442 LANGA STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26130,'lng':27.86890}},
{'stp':604478,'address':'604478, EGL - SOWETO, DENNIS TSEPE, SUSANS INN, 1061 MOEFI STR, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26820,'lng':27.85170}},
{'stp':604548,'address':'604548, EGL - SOWETO, DENNIS TSEPE, NCANES JOINT, 1729 PHEWA STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26510,'lng':27.87670}},
{'stp':605502,'address':'605502, EGL - SOWETO, DENNIS TSEPE, RAMAISELAS PLACE, 24 TSOTETSI STREET, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25270,'lng':27.86830}},
{'stp':606536,'address':'606536, EGL - SOWETO, DENNIS TSEPE, NTHABISENG INN, 671 C LELANI STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27090,'lng':27.85120}},
{'stp':606804,'address':'606804, EGL - SOWETO, DENNIS TSEPE, BUSY CORNER, 1886 OLD POTCHEFSTROOM RD, DLAMINI, #, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.26550,'lng':27.87920}},
{'stp':606806,'address':'606806, EGL - SOWETO, DENNIS TSEPE, VUYELWAS PLACE, 105B MOKHELE STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26780,'lng':27.85820}},
{'stp':625069,'address':'625069, EGL - SOWETO, DENNIS TSEPE, PARLIAMENT, 1045 VUNDLA DRV, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25860,'lng':27.86300}},
{'stp':625112,'address':'625112, EGL - SOWETO, DENNIS TSEPE, MIYOS PLACE PERMIT, 1350 LEFATOLA STR, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26260,'lng':27.86860}},
{'stp':626748,'address':'626748, EGL - SOWETO, DENNIS TSEPE, WINNIES PLACE PERMIT, 591GUMEDE STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26070,'lng':27.87480}},
{'stp':626899,'address':'626899, EGL - SOWETO, DENNIS TSEPE, BUSY MANS PLACE, 986 CNR PUSO & MOKGATLA STREETS, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26700,'lng':27.85160}},
{'stp':627877,'address':'627877, EGL - SOWETO, DENNIS TSEPE, CLEMZAS INN PERMIT, 348 UMLAZI STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27210,'lng':27.86230}},
{'stp':628720,'address':'628720, EGL - SOWETO, DENNIS TSEPE, GS PLACE PERMIT, 2591 INGANGXOSI STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27360,'lng':27.85520}},
{'stp':630157,'address':'630157, EGL - SOWETO, DENNIS TSEPE, BONGANIS PLACE, 602 IMBAZE STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26960,'lng':27.86600}},
{'stp':631039,'address':'631039, EGL - SOWETO, DENNIS TSEPE, TRIPLE M, 449 TSOTETSI STREET, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25230,'lng':27.86670}},
{'stp':631117,'address':'631117, EGL - SOWETO, DENNIS TSEPE, MAPULES PLACE, 2283 KELEMOTSE STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26770,'lng':27.87080}},
{'stp':631609,'address':'631609, EGL - SOWETO, DENNIS TSEPE, THUSANANG BASOTHO, 1580 DIPAPANA, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26250,'lng':27.86320}},
{'stp':631850,'address':'631850, EGL - SOWETO, DENNIS TSEPE, MPATIS PLACE, 2 KOMA ROAD, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26780,'lng':27.86080}},
{'stp':632301,'address':'632301, EGL - SOWETO, DENNIS TSEPE, PEPE\'S INN, 594 GUMEDE STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26080,'lng':27.87470}},
{'stp':632334,'address':'632334, EGL - SOWETO, DENNIS TSEPE, JIMMY\'S PLACE, 863A MNGWEVU STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27280,'lng':27.85410}},
{'stp':632570,'address':'632570, EGL - SOWETO, DENNIS TSEPE, OUMA\'S INN, 1003 MOKGATLA STREET, MAPETLA, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26680,'lng':27.85250}},
{'stp':633352,'address':'633352, EGL - SOWETO, DENNIS TSEPE, POPPIE S INN, 358 B MASIONE STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26720,'lng':27.85550}},
{'stp':633537,'address':'633537, EGL - SOWETO, DENNIS TSEPE, THABO S PLACE, 1750 KHOTSO STREET, MOLAPO EXT, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26480,'lng':27.85930}},
{'stp':633577,'address':'633577, EGL - SOWETO, DENNIS TSEPE, MOM AND SON, 1664B MASAKHANE STREET, MOLAPO EXT, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26380,'lng':27.86280}},
{'stp':633645,'address':'633645, EGL - SOWETO, DENNIS TSEPE, GEORGES BOTTLE STORE, 3278 VUNDLA DRIVE, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26500,'lng':27.87150}},
{'stp':634165,'address':'634165, EGL - SOWETO, DENNIS TSEPE, THAMI\'S INN, 260 IMFOLOZI STREET, SENAONE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27070,'lng':27.86150}},
{'stp':635140,'address':'635140, EGL - SOWETO, DENNIS TSEPE, BOYS PLACE, 518A MABALANE STREET, PHIRI TOWNSHIP, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27280,'lng':27.85970}},
{'stp':635826,'address':'635826, EGL - SOWETO, DENNIS TSEPE, KATES PLACE, 419A MATILILI STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26870,'lng':27.85290}},
{'stp':637359,'address':'637359, EGL - SOWETO, DENNIS TSEPE, LINDA\'S PLACE, 118B NHLAPO STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26910,'lng':27.85960}},
{'stp':639238,'address':'639238, EGL - SOWETO, DENNIS TSEPE, SIBONGILES PLACE, 1345C KLAAS STREET, WHITE CITY JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25018,'lng':27.87159}},
{'stp':640238,'address':'640238, EGL - SOWETO, DENNIS TSEPE, KIRKS PLACE, 293B MANOTSHE STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26720,'lng':27.85350}},
{'stp':640312,'address':'640312, EGL - SOWETO, DENNIS TSEPE, BIG MAMA\'S PLACE, 885B MVUNDLA STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27250,'lng':27.85540}},
{'stp':642483,'address':'642483, EGL - SOWETO, DENNIS TSEPE, OUMA S INN, 356 TEKELA STREET, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25710,'lng':27.86850}},
{'stp':646288,'address':'646288, EGL - SOWETO, DENNIS TSEPE, MAGGIES PLACE, 231 MOFOKENG STREET, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25432,'lng':27.86601}},
{'stp':646423,'address':'646423, EGL - SOWETO, DENNIS TSEPE, PINKY\'S PLACE, 220 MOFOKENG STREET, MOROKA NORTH, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25470,'lng':27.86850}},
{'stp':646448,'address':'646448, EGL - SOWETO, DENNIS TSEPE, CLEMENTS PLACE, 824 KHATELLO STREET, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25830,'lng':27.86360}},
{'stp':646514,'address':'646514, EGL - SOWETO, DENNIS TSEPE, WINNIE\'S INN, 1122 L.ALI STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26010,'lng':27.87710}},
{'stp':646635,'address':'646635, EGL - SOWETO, DENNIS TSEPE, SHIELAS PLACE, 203 VUNDLA DRIVE, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25960,'lng':27.86600}},
{'stp':646808,'address':'646808, EGL - SOWETO, DENNIS TSEPE, IVYS PLACE, 1859 NUSE STREET, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26370,'lng':27.87460}},
{'stp':646858,'address':'646858, EGL - SOWETO, DENNIS TSEPE, LIZOBUYA LIQUOR STORE, 2336 CNR MABALANE & MVUNDLA STR, SHOP NO 876, PHIRI LOCATION, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27150,'lng':27.85610}},
{'stp':648121,'address':'648121, EGL - SOWETO, DENNIS TSEPE, MMAPULA PLACE, 1398C MAVI STREET, CENTRAL WESTERN JABAVU, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.24980,'lng':27.86820}},
{'stp':648481,'address':'648481, EGL - SOWETO, DENNIS TSEPE, TANKISO TAVERN, 1297 MONNA HALA STREET, MOLAPO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25950,'lng':27.86220}},
{'stp':650434,'address':'650434, EGL - SOWETO, DENNIS TSEPE, BIG GS WORLD, 2533 TSHIYVANE STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27430,'lng':27.85650}},
{'stp':651773,'address':'651773, EGL - SOWETO, DENNIS TSEPE, SENAOANE LIQUOR STORE, 1933 MABALANE STREET, SENAOANE, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27280,'lng':27.86380}},
{'stp':653899,'address':'653899, EGL - SOWETO, DENNIS TSEPE, TLETLE, 596 GUMEDE STREET, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26090,'lng':27.87400}},
{'stp':659879,'address':'659879, EGL - SOWETO, DENNIS TSEPE, NGONYAMAS PLACE, 2616 TELESCOPE STR FREEDOM PARK, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.29990,'lng':27.93380}},
{'stp':663643,'address':'663643, EGL - SOWETO, DENNIS TSEPE, TUMA S PLACE, 299 A ERF NO, MANOTSHE STREET, PHIRI TOWNSHIP, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26620,'lng':27.85460}},
{'stp':663828,'address':'663828, EGL - SOWETO, DENNIS TSEPE, MUSA\'S PLACE (NTO), 1398 LEGODI STREET, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26390,'lng':27.86610}},
{'stp':685886,'address':'685886, EGL - SOWETO, DENNIS TSEPE, BRENDAS PLACE, 306 BOJANG STREET, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25900,'lng':27.86840}},
{'stp':690242,'address':'690242, EGL - SOWETO, DENNIS TSEPE, SIPHO\'S PLACE (NTO), 502 BOGOGO STR, ROCKVILLE, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25920,'lng':27.87440}},
{'stp':698934,'address':'698934, EGL - SOWETO, DENNIS TSEPE, LESOTHO INN, 549C MANOTSHE STREET, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.26900,'lng':27.85180}},
{'stp':712246,'address':'712246, EGL - SOWETO, DENNIS TSEPE, OR S TAVERN, 1241B WHITE CITY, JABAVU EXT 3, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25600,'lng':27.87220}},
{'stp':722235,'address':'722235, EGL - SOWETO, DENNIS TSEPE, LUKAS LIQUOR DISTRIBUTION, 858 CNR MAKAPANE AND VUNDLA DRIVE, MOLAPO SOWETO, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25890,'lng':27.86460}},
{'stp':738102,'address':'738102, EGL - SOWETO, DENNIS TSEPE, SWEET WATERS, 02127-000 LEKHOOA STREET, MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26600,'lng':27.86910}},
{'stp':749558,'address':'749558, EGL - SOWETO, DENNIS TSEPE, LE MELON LIQUOR  STORE, STREET NO, ERF 3200 SHOP B VUNDLA DRIVE  MOROKA, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26050,'lng':27.86680}},
{'stp':761635,'address':'761635, EGL - SOWETO, DENNIS TSEPE, MEET AND PHOLA, 245 A STAND NO, PHIRI, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27000,'lng':27.85970}},
{'stp':776895,'address':'776895, EGL - SOWETO, DENNIS TSEPE, BOSS PLACE, 835 STAND NO, KHATELLO STREET, MOLAPO, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.25810,'lng':27.86310}},
{'stp':782807,'address':'782807, EGL - SOWETO, DENNIS TSEPE, BOYKIES TAVERN, STAND NO, ERF 1445 M PHAELANE STREET MOROKA 1818, #, CENTRAL WESTERN JABA, South Africa','geo':{'lat':-26.26462,'lng':27.86584}},
{'stp':785794,'address':'785794, EGL - SOWETO, DENNIS TSEPE, EVAHS INN, 547A STAND NO, MANOTSHE STREET, PHIRI, CHIAWELO/DHLAMINI, South Africa','geo':{'lat':-26.26920,'lng':27.85156}},
{'stp':661316,'address':'661316, EGL - SOWETO, DENNIS TSEPE, VERDOS PLACE (NTO), 2526 NGALELA STREET, MAPETLA EXT, #, SENAOANE/PHIRI/MAPET, South Africa','geo':{'lat':-26.27382,'lng':27.85032}},
{'stp':687529,'address':'687529, EGL - SOWETO, DENNIS TSEPE, MABORITE TRAINING CONFERENCE AND AC, PORTION 18 OF 322, FREEDOMPARK, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.47200,'lng':27.97342}},
{'stp':707629,'address':'707629, EGL - SOWETO, DENNIS TSEPE, NATIONAL STADIUM, CITY & STADIUM AVE & NASREC RD, NASREC, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23354,'lng':27.98145}},
{'stp':821003,'address':'821003, EGL - SOWETO, DENNIS TSEPE, BARAGWANATH STAKEHOLDER, BARAGWANATH DEPOT, BARAGWANATH EXT 1, #, SOUTH GATE/MONDEOR/K, South Africa','geo':{'lat':-26.26560,'lng':27.97450}},
{'stp':388289,'address':'388289, EGL - SOWETO, MPHO MOEMA, RORI S CORNER, 5636 STAND NO, TSHABALALA STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24719,'lng':27.93006}},
{'stp':389970,'address':'389970, EGL - SOWETO, MPHO MOEMA, NSIKA YE THUSI, 6406 STAND NO, #, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24854,'lng':27.93031}},
{'stp':391800,'address':'391800, EGL - SOWETO, MPHO MOEMA, RALPH S PLACE, 5344A INKONJANE STREET, #, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25005,'lng':27.95223}},
{'stp':462594,'address':'462594, EGL - SOWETO, MPHO MOEMA, NOMVUYOS TAVERN, 23406 ERF, STAND 8096 DIELKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25542,'lng':27.94023}},
{'stp':466984,'address':'466984, EGL - SOWETO, MPHO MOEMA, DEBS PLACE, STAND NO, 448 ZONE 7, PIMVILLE TOWNSHIP, PIMVILLE, South Africa','geo':{'lat':-26.27090,'lng':27.90107}},
{'stp':468029,'address':'468029, EGL - SOWETO, MPHO MOEMA, PEACE INN, 5900A STAND NO, MARTHINUS SMUTS DR, ZONE 5, DIEPKLOOF, South Africa','geo':{'lat':-26.24674,'lng':27.93835}},
{'stp':478558,'address':'478558, EGL - SOWETO, MPHO MOEMA, THOMOS PLACE, 8097A STAND NO, ZONE 6, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.24790,'lng':27.94180}},
{'stp':489584,'address':'489584, EGL - SOWETO, MPHO MOEMA, MOTLALEPULE S TAVERN, 6034 STAND NO, MORAILANE STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24957,'lng':27.93254}},
{'stp':490161,'address':'490161, EGL - SOWETO, MPHO MOEMA, BAB S TAVERN, 7731B STAND NO, SEGOTSANE STREET, ZONE 2, DIEPKLOOF, South Africa','geo':{'lat':-26.20662,'lng':27.88775}},
{'stp':490902,'address':'490902, EGL - SOWETO, MPHO MOEMA, JABULILE TAVERN, 7718A STAND NO,  ZONE 2 DIEPKLOOF , SOWETO, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24788,'lng':27.94193}},
{'stp':542578,'address':'542578, EGL - SOWETO, MPHO MOEMA, TOPS PLACE, STAND NO, 2425 ZONE2 SONO ROAD, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25143,'lng':27.94518}},
{'stp':547106,'address':'547106, EGL - SOWETO, MPHO MOEMA, JOYCE TAVERN, 5069 A STAND NO, #, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25266,'lng':27.94858}},
{'stp':548297,'address':'548297, EGL - SOWETO, MPHO MOEMA, SCOUT, 2501 MAGABE STREET, ZONE 2, PIMVILLE TOWNSHIP, PIMVILLE, South Africa','geo':{'lat':-26.26278,'lng':27.90269}},
{'stp':548784,'address':'548784, EGL - SOWETO, MPHO MOEMA, MARIAS PLACE, STAND NO, ERF 10190 1949 A, LUVHOLA STREET ZONE 2 DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.24953,'lng':27.94037}},
{'stp':550175,'address':'550175, EGL - SOWETO, MPHO MOEMA, PHILLIP\'S TAVERN, 2553 INGWE STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25010,'lng':27.94680}},
{'stp':550392,'address':'550392, EGL - SOWETO, MPHO MOEMA, SOLLYS JOINT, 7775, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25100,'lng':27.93540}},
{'stp':550989,'address':'550989, EGL - SOWETO, MPHO MOEMA, AGGIES INN, 5432 INGWE STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25140,'lng':27.94970}},
{'stp':552539,'address':'552539, EGL - SOWETO, MPHO MOEMA, BOICES PLACE, 7105 EBEN CUYLER STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24560,'lng':27.96000}},
{'stp':553102,'address':'553102, EGL - SOWETO, MPHO MOEMA, DIESEL PLANE, LUMANI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24950,'lng':27.93070}},
{'stp':553347,'address':'553347, EGL - SOWETO, MPHO MOEMA, BETTY S TAVERN, 7854 KOKWANI STREET, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25550,'lng':27.94000}},
{'stp':560204,'address':'560204, EGL - SOWETO, MPHO MOEMA, MZALAS PLACE PERMIT, 997 ZULWANE STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24380,'lng':27.94650}},
{'stp':560448,'address':'560448, EGL - SOWETO, MPHO MOEMA, VENDA SUN, 1676 MSWAZI STR, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24520,'lng':27.94130}},
{'stp':560548,'address':'560548, EGL - SOWETO, MPHO MOEMA, THIZAS PLACE, 2420 MBILA STR, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25110,'lng':27.94530}},
{'stp':561106,'address':'561106, EGL - SOWETO, MPHO MOEMA, THIBIS PLACE, 766 MAKONDE STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24140,'lng':27.94130}},
{'stp':561158,'address':'561158, EGL - SOWETO, MPHO MOEMA, KWA NORMAN, 7634 A NJAKANJAKA STR, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24460,'lng':27.93880}},
{'stp':561233,'address':'561233, EGL - SOWETO, MPHO MOEMA, VUSIS INN, 2752B SONO ROAD, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24935,'lng':27.93743}},
{'stp':561270,'address':'561270, EGL - SOWETO, MPHO MOEMA, PATS INN, 612A MINGA STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23980,'lng':27.94320}},
{'stp':581121,'address':'581121, EGL - SOWETO, MPHO MOEMA, JABU\'S PLACE, 532A LUANAME STR, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24160,'lng':27.94560}},
{'stp':581278,'address':'581278, EGL - SOWETO, MPHO MOEMA, DLAMS PLACE, 929 JACK KLIPIN AVE, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24290,'lng':27.94420}},
{'stp':603635,'address':'603635, EGL - SOWETO, MPHO MOEMA, DI MAMZA, 24692 KOKOANA STR, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25540,'lng':27.94140}},
{'stp':605322,'address':'605322, EGL - SOWETO, MPHO MOEMA, MOBES PLACE, 5536 DADA STR, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24940,'lng':27.94860}},
{'stp':605323,'address':'605323, EGL - SOWETO, MPHO MOEMA, MOTLALEPULES PLACE, 5684 NKUNDLENI STR, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25080,'lng':27.95200}},
{'stp':605350,'address':'605350, EGL - SOWETO, MPHO MOEMA, SCANE, 5308A NOQHAZA STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25130,'lng':27.95180}},
{'stp':605416,'address':'605416, EGL - SOWETO, MPHO MOEMA, LELES INN, 6311 HLABAKU STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.25080,'lng':27.92780}},
{'stp':605489,'address':'605489, EGL - SOWETO, MPHO MOEMA, KWA EUGY, 1090 STAND NO, ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24470,'lng':27.94210}},
{'stp':605500,'address':'605500, EGL - SOWETO, MPHO MOEMA, GALELAS TAVERN, 1138 HLOPHEKHULU STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24440,'lng':27.94540}},
{'stp':607158,'address':'607158, EGL - SOWETO, MPHO MOEMA, MAMPES PLACE, 5306 NOQHAZA STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25120,'lng':27.95190}},
{'stp':624947,'address':'624947, EGL - SOWETO, MPHO MOEMA, DAN\'S INN (NTO), 1296, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24060,'lng':27.94100}},
{'stp':627297,'address':'627297, EGL - SOWETO, MPHO MOEMA, ZICOS PLACE PERMIT, 2348 NYANGA STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25200,'lng':27.94510}},
{'stp':628716,'address':'628716, EGL - SOWETO, MPHO MOEMA, MIKES PLACE PERMIT, 1931 LIVHUVU STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24880,'lng':27.94140}},
{'stp':629936,'address':'629936, EGL - SOWETO, MPHO MOEMA, DAKYS PLACE PERMIT, 4925 UMFOLOZI STR, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25200,'lng':27.94740}},
{'stp':630358,'address':'630358, EGL - SOWETO, MPHO MOEMA, KASI FLAVOUR, 5219A NONGOMA STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25260,'lng':27.95210}},
{'stp':630994,'address':'630994, EGL - SOWETO, MPHO MOEMA, NOKUTHULAS PLACE, 5922 TLOME STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24630,'lng':27.93160}},
{'stp':631041,'address':'631041, EGL - SOWETO, MPHO MOEMA, RONAS INN, 686 SHIRULUNI STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24150,'lng':27.94340}},
{'stp':631238,'address':'631238, EGL - SOWETO, MPHO MOEMA, IKES PLACE, 2813 LUVHULA STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24810,'lng':27.93890}},
{'stp':631282,'address':'631282, EGL - SOWETO, MPHO MOEMA, CHINA, 3157 KHOLOFELO STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24510,'lng':27.93680}},
{'stp':631326,'address':'631326, EGL - SOWETO, MPHO MOEMA, TIZZAH, 5522B HLUNGULO STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25060,'lng':27.94960}},
{'stp':631896,'address':'631896, EGL - SOWETO, MPHO MOEMA, RHEE\'S PLACE, 5393B, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25070,'lng':27.95360}},
{'stp':632300,'address':'632300, EGL - SOWETO, MPHO MOEMA, THE PLACE, 1024 ZWELITSHA STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24350,'lng':27.94460}},
{'stp':632336,'address':'632336, EGL - SOWETO, MPHO MOEMA, SARAH MOHESU, 2030 FUNDZUZU STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25070,'lng':27.94080}},
{'stp':632424,'address':'632424, EGL - SOWETO, MPHO MOEMA, MOKOBANE S TUCKSHOP, 6210 FOBO STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.25100,'lng':27.92980}},
{'stp':632750,'address':'632750, EGL - SOWETO, MPHO MOEMA, NICO\'S PLACE, 6202 FOBO STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24990,'lng':27.93000}},
{'stp':632752,'address':'632752, EGL - SOWETO, MPHO MOEMA, MAGWELA\'S ENTERPRISE, 869A, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24300,'lng':27.94240}},
{'stp':633533,'address':'633533, EGL - SOWETO, MPHO MOEMA, HLOZINOS PLACE, 578A KUTHWAMO STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24110,'lng':27.94520}},
{'stp':633655,'address':'633655, EGL - SOWETO, MPHO MOEMA, TOY\'S PLACE, 5201 NYAMMEKO STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25390,'lng':27.95110}},
{'stp':633940,'address':'633940, EGL - SOWETO, MPHO MOEMA, PORTIAS PLACE, 1202 DUMILE STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24480,'lng':27.94550}},
{'stp':634291,'address':'634291, EGL - SOWETO, MPHO MOEMA, LEE\'S PLACE, 5850 TAUKOBONG STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24420,'lng':27.93410}},
{'stp':634533,'address':'634533, EGL - SOWETO, MPHO MOEMA, LOLOS PLACE, 700 STAND NO, LEBONA STREET DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24190,'lng':27.94270}},
{'stp':634632,'address':'634632, EGL - SOWETO, MPHO MOEMA, GOGO\'S PLACE, 5150 B NYAMEKO STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25430,'lng':27.95210}},
{'stp':634744,'address':'634744, EGL - SOWETO, MPHO MOEMA, CLUB C, 7707B, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24880,'lng':27.93520}},
{'stp':635211,'address':'635211, EGL - SOWETO, MPHO MOEMA, EVA\'S SHEBEEN, 3370 KHETSI STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24740,'lng':27.93640}},
{'stp':635954,'address':'635954, EGL - SOWETO, MPHO MOEMA, TONKO\'S TAVERN, 1479 HLOPHEKHULU STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24420,'lng':27.94770}},
{'stp':636637,'address':'636637, EGL - SOWETO, MPHO MOEMA, MAMAILA\'S PLACE, 2011 B SONO ROAD, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25010,'lng':27.94030}},
{'stp':637357,'address':'637357, EGL - SOWETO, MPHO MOEMA, MAPULE\'S PLACE, 703 LUONDE STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24200,'lng':27.94270}},
{'stp':637654,'address':'637654, EGL - SOWETO, MPHO MOEMA, KWAMALUME\'S PLACE, 2603 KREBE STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24890,'lng':27.94570}},
{'stp':639433,'address':'639433, EGL - SOWETO, MPHO MOEMA, BOYS PLACE, 895 ZWELISHA STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24380,'lng':27.94180}},
{'stp':639737,'address':'639737, EGL - SOWETO, MPHO MOEMA, MABONGISI\'S PLACE, 6179 LUMANI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.25105,'lng':27.93022}},
{'stp':640731,'address':'640731, EGL - SOWETO, MPHO MOEMA, CONNIES PLACE, 1381 BEN NAUDE DRIVE, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24780,'lng':27.94680}},
{'stp':640749,'address':'640749, EGL - SOWETO, MPHO MOEMA, BUSI\'S PLACE, 1733 HASHI STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24860,'lng':27.94770}},
{'stp':640780,'address':'640780, EGL - SOWETO, MPHO MOEMA, JABU\'S TAVERN, 5616A BARAGWANATH ROAD, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24890,'lng':27.94800}},
{'stp':640984,'address':'640984, EGL - SOWETO, MPHO MOEMA, MABUGS PLACE, 1819, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24780,'lng':27.94180}},
{'stp':641012,'address':'641012, EGL - SOWETO, MPHO MOEMA, MARRIAM\'S PLACE, 2033 SONO STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25020,'lng':27.94110}},
{'stp':642211,'address':'642211, EGL - SOWETO, MPHO MOEMA, RR S INN, 5789 LEBEKO STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24620,'lng':27.93360}},
{'stp':642554,'address':'642554, EGL - SOWETO, MPHO MOEMA, MEGGIE\'S PLACE, 7624 DIPPENAAR DRIVE, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24460,'lng':27.93820}},
{'stp':642558,'address':'642558, EGL - SOWETO, MPHO MOEMA, MARTHA\'S PLACE, 3104 NWAMTWA STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24530,'lng':27.93830}},
{'stp':654375,'address':'654375, EGL - SOWETO, MPHO MOEMA, FLORAS PLACE, 2984 HARSH DRIVE STREET, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24630,'lng':27.94090}},
{'stp':659583,'address':'659583, EGL - SOWETO, MPHO MOEMA, NELLY, 6115 MARASHA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.25120,'lng':27.93100}},
{'stp':660568,'address':'660568, EGL - SOWETO, MPHO MOEMA, NANIS PLACE, 3478 MAHUBE STREET, DIEPKLOOF ZONE 3, SOWETO, DIEPKLOOF, South Africa','geo':{'lat':-26.23993,'lng':27.94333}},
{'stp':661567,'address':'661567, EGL - SOWETO, MPHO MOEMA, BONGANIS PLACE, 1597 EMSHWATI STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24580,'lng':27.94230}},
{'stp':664950,'address':'664950, EGL - SOWETO, MPHO MOEMA, NOMVULAS INN (NTO), 5942 ZONE 5, DIEPKLOOF EXT, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25100,'lng':27.94830}},
{'stp':681807,'address':'681807, EGL - SOWETO, MPHO MOEMA, NTANGAS INN, 5368 HLOSI STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25110,'lng':27.95290}},
{'stp':716805,'address':'716805, EGL - SOWETO, MPHO MOEMA, GA MOSHIDIS PLACE, 579B MINGA STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24120,'lng':27.94450}},
{'stp':725379,'address':'725379, EGL - SOWETO, MPHO MOEMA, SGUBU, 6092 MOLORA STREET, ORLANDO EAST TOWNSHIP, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.25093,'lng':27.93129}},
{'stp':727780,'address':'727780, EGL - SOWETO, MPHO MOEMA, QUEEN S JUICE TAVERN, 817 LUANAME STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24240,'lng':27.94370}},
{'stp':729715,'address':'729715, EGL - SOWETO, MPHO MOEMA, GODFREYS TUCKSHOP, 2747 B, DIEPKLOOF ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24990,'lng':27.93730}},
{'stp':734518,'address':'734518, EGL - SOWETO, MPHO MOEMA, BERRIES LIQUORS, 6279 LINGANE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.25080,'lng':27.92850}},
{'stp':757355,'address':'757355, EGL - SOWETO, MPHO MOEMA, ERICS PLACE, STAND NO, ERF 14295, 1371 ZONE 1 DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.24710,'lng':27.94640}},
{'stp':758351,'address':'758351, EGL - SOWETO, MPHO MOEMA, VUSI S JOINT, 1630 EMBO STREET, ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24500,'lng':27.94350}},
{'stp':758378,'address':'758378, EGL - SOWETO, MPHO MOEMA, WHITEY S PLACE, 4912 STAND NO, LMMIK DRIVE DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25270,'lng':27.94670}},
{'stp':767071,'address':'767071, EGL - SOWETO, MPHO MOEMA, KOMZAS PLACE, STAND NO, ERF 11355 7713A SONO RD ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24930,'lng':27.93460}},
{'stp':772695,'address':'772695, EGL - SOWETO, MPHO MOEMA, FIRST QUENCHER, 7272 STAND NO, ZONE 4, DIEKPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.25140,'lng':27.94970}},
{'stp':776219,'address':'776219, EGL - SOWETO, MPHO MOEMA, GOGO S PLACE, 188 STAND NO, DIEPKLOOFPHASE 1, EXT 11864, DIEPKLOOF, South Africa','geo':{'lat':-26.24170,'lng':27.94270}},
{'stp':776381,'address':'776381, EGL - SOWETO, MPHO MOEMA, KAYBEES, 4959B BARAGWANATH ROAD, ERF 22584 DIEPKLOOF ZONE 5, SOWETO, DIEPKLOOF, South Africa','geo':{'lat':-26.25428,'lng':27.94834}},
{'stp':777694,'address':'777694, EGL - SOWETO, MPHO MOEMA, BERNARDS PLACE, 5665 TLOOME STREET, #, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24680,'lng':27.93120}},
{'stp':779911,'address':'779911, EGL - SOWETO, MPHO MOEMA, RED BISHOP, 480 A MOROBANAKO STREET, ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.24855,'lng':27.94900}},
{'stp':781201,'address':'781201, EGL - SOWETO, MPHO MOEMA, FLORS INN, 1314 STAND NO, 33 ST MARK STREET EXT 2, SHOP 6, PIMVILLE, South Africa','geo':{'lat':-26.29970,'lng':27.87790}},
{'stp':787038,'address':'787038, EGL - SOWETO, MPHO MOEMA, MADIBUSENGS PLACE, ERF 3112, ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24565,'lng':27.93749}},
{'stp':790623,'address':'790623, EGL - SOWETO, MPHO MOEMA, JNF TUCKSHOP, 7665 STAND NO, ZONE 2, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24802,'lng':27.93493}},
{'stp':793999,'address':'793999, EGL - SOWETO, MPHO MOEMA, THE VIBE INN, 2150 STAND NO, #, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25156,'lng':27.94189}},
{'stp':797507,'address':'797507, EGL - SOWETO, MPHO MOEMA, BAB\'S LIQUOR STORE, STAND NO, ERF 13774 ZONE 1 DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24890,'lng':27.94565}},
{'stp':388223,'address':'388223, EGL - SOWETO, MPHO MOEMA, MABOTLOLONG, 8789A STAND NUMBER, MDA STREET, ORLANDO WEST, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22465,'lng':27.90833}},
{'stp':388660,'address':'388660, EGL - SOWETO, MPHO MOEMA, BIG O TRADING, 21 STAND NO, JUMP STREET, 74 SLOVO PARK, ELDORADO PARK, South Africa','geo':{'lat':-26.30407,'lng':27.89901}},
{'stp':461940,'address':'461940, EGL - SOWETO, MPHO MOEMA, SARAHS PLACE, 19 ABRAM ADREWS ROAD, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29100,'lng':27.89690}},
{'stp':472125,'address':'472125, EGL - SOWETO, MPHO MOEMA, LEROYS TAVERN, 419 ALBERT DICKENSON AVENUE, #, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28882,'lng':27.89462}},
{'stp':478224,'address':'478224, EGL - SOWETO, MPHO MOEMA, THELA LIQUOR STORE, 8664 STAND NO, NGIBA ORLANDO, WEST KILLERNEY, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22571,'lng':27.90673}},
{'stp':490162,'address':'490162, EGL - SOWETO, MPHO MOEMA, DAWNS TAVERN, 1022 STAND NO, SNEEUBERG STREET, EXT 2, ELDORADO PARK, South Africa','geo':{'lat':-26.29201,'lng':27.92040}},
{'stp':490165,'address':'490165, EGL - SOWETO, MPHO MOEMA, MARYS TAVERN, 5811 STAND NO, MZIKI STREET, ZONE 5 PIMVILLE, PIMVILLE, South Africa','geo':{'lat':-26.27477,'lng':27.90959}},
{'stp':490903,'address':'490903, EGL - SOWETO, MPHO MOEMA, AUNTIES TAVERN, 133A STAND NUMBER, ZONE 9, JOE SLOVO PARK CINFILL, ELDORADO PARK, South Africa','geo':{'lat':-26.27870,'lng':27.89137}},
{'stp':542618,'address':'542618, EGL - SOWETO, MPHO MOEMA, CHEAPLINE, 8755B LESOLANS STREET, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22323,'lng':27.90806}},
{'stp':545738,'address':'545738, EGL - SOWETO, MPHO MOEMA, STIX HOOPERS, 4 STAND NO, GULLAMENT EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28410,'lng':27.89700}},
{'stp':549168,'address':'549168, EGL - SOWETO, MPHO MOEMA, TKS PLACE, 3990 STAND NO, MMUTLA STREET, ZONE 4, PIMVILLE, South Africa','geo':{'lat':-26.27304,'lng':27.89200}},
{'stp':550804,'address':'550804, EGL - SOWETO, MPHO MOEMA, MANZINIS TAVERN, 3982A, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27320,'lng':27.89270}},
{'stp':550850,'address':'550850, EGL - SOWETO, MPHO MOEMA, NORMANS CORNER, 7588 IMMINK ROAD, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23430,'lng':27.95230}},
{'stp':551626,'address':'551626, EGL - SOWETO, MPHO MOEMA, MONDES PLACE, 4220, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27370,'lng':27.89860}},
{'stp':551734,'address':'551734, EGL - SOWETO, MPHO MOEMA, RAE AND RACHEL S TAVERN, ERF 8732, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27260,'lng':27.89900}},
{'stp':552487,'address':'552487, EGL - SOWETO, MPHO MOEMA, T AND T TAVERN, 8228B, PIMVILLE ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.27080,'lng':27.91200}},
{'stp':553889,'address':'553889, EGL - SOWETO, MPHO MOEMA, 910 PLACE, 910 BAKWENA STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21280,'lng':27.91020}},
{'stp':553938,'address':'553938, EGL - SOWETO, MPHO MOEMA, ELIZAS PLACE PERMIT, 10 MAIN AVENUE, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28590,'lng':27.89650}},
{'stp':553952,'address':'553952, EGL - SOWETO, MPHO MOEMA, CLUB 16 PERMIT, 5873, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27260,'lng':27.90670}},
{'stp':560195,'address':'560195, EGL - SOWETO, MPHO MOEMA, NOMVULAS JAZZ JOINT PERMIT, 37 MOROBADILEPE STREET, PIMVILLE ZONE 7, #, PIMVILLE, South Africa','geo':{'lat':-26.27210,'lng':27.90730}},
{'stp':560205,'address':'560205, EGL - SOWETO, MPHO MOEMA, MASINDIS PLACE PERMIT, 3811 B MKATA STREET, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27050,'lng':27.89670}},
{'stp':560206,'address':'560206, EGL - SOWETO, MPHO MOEMA, NTSIKIS PLACE PERMIT, 78 MNEPULE STREET, PIMVILLE ZONE 7, #, PIMVILLE, South Africa','geo':{'lat':-26.27150,'lng':27.90830}},
{'stp':561152,'address':'561152, EGL - SOWETO, MPHO MOEMA, MATODZIS PLACE, 3786 A  MNGA STREET, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27080,'lng':27.89410}},
{'stp':580289,'address':'580289, EGL - SOWETO, MPHO MOEMA, BUGS INN, 25 NEW ROAD, KLIPTOWN, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28060,'lng':27.88830}},
{'stp':580359,'address':'580359, EGL - SOWETO, MPHO MOEMA, WALTER\'S ENTERPRISE, 2 SANDPIPER FLATS, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.27345,'lng':27.81628}},
{'stp':580964,'address':'580964, EGL - SOWETO, MPHO MOEMA, M AND MS TAVERN, 6521/8 KOOS HUMAN STR, ELDORADO PARK EXT 6, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28420,'lng':27.90100}},
{'stp':603343,'address':'603343, EGL - SOWETO, MPHO MOEMA, KOTIS PLACE, 8568 SEGAPO STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22950,'lng':27.90570}},
{'stp':604118,'address':'604118, EGL - SOWETO, MPHO MOEMA, MZALA\'S TAVERN, 1692 MTANGAI STRREET, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26410,'lng':27.91430}},
{'stp':604536,'address':'604536, EGL - SOWETO, MPHO MOEMA, NGASEMLANJENI, 11859 MTIPA STR, ORLANDO WEST EXT 1, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24280,'lng':27.90940}},
{'stp':604710,'address':'604710, EGL - SOWETO, MPHO MOEMA, RIKKIS INN, 2085 STAND NUMBER, 12TH STUKA AVENUE, ELDORADO PARK EXT 1, ELDORADO PARK, South Africa','geo':{'lat':-26.29900,'lng':27.90540}},
{'stp':605397,'address':'605397, EGL - SOWETO, MPHO MOEMA, SALEMS PLACE, 27 TEXOS AVENUE, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28886,'lng':27.89955}},
{'stp':605901,'address':'605901, EGL - SOWETO, MPHO MOEMA, SAMMYS TAVERN, 74 SLOVO PARK, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30500,'lng':27.89870}},
{'stp':606492,'address':'606492, EGL - SOWETO, MPHO MOEMA, MUM DUKES, 5240 KWENA STREET, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27490,'lng':27.90250}},
{'stp':606507,'address':'606507, EGL - SOWETO, MPHO MOEMA, FNB, 5156 MQUMA STR, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27370,'lng':27.90130}},
{'stp':606515,'address':'606515, EGL - SOWETO, MPHO MOEMA, TSHIDIS JOINT, 8959A MPITSO STR, ORLANDO WEST, SOWETO, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22330,'lng':27.91020}},
{'stp':606519,'address':'606519, EGL - SOWETO, MPHO MOEMA, MONDIS PLACE, 8670B NGIBA STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22405,'lng':27.90739}},
{'stp':607189,'address':'607189, EGL - SOWETO, MPHO MOEMA, TRENZ TA TRENZ COCKTAIL LOUNGE, SHOP U28A TRA ROU MA CN NIRV & CAPE, LENASIA EXT 9, #, LENASIA, South Africa','geo':{'lat':-26.32541,'lng':27.86677}},
{'stp':607431,'address':'607431, EGL - SOWETO, MPHO MOEMA, BUCANNEAR TAVERN, 578 JOE SLOVO PARK, NANCEFIELD INDUSTRIAL, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30830,'lng':27.90070}},
{'stp':616480,'address':'616480, EGL - SOWETO, MPHO MOEMA, LIKS PLACE (NTO), 8125 IMMINK DRIVE, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.25840,'lng':27.94300}},
{'stp':625066,'address':'625066, EGL - SOWETO, MPHO MOEMA, MASAKENG PUB, 946 KININI STREET, MOFOLO, #, MOFOLO, South Africa','geo':{'lat':-26.24550,'lng':27.88740}},
{'stp':626432,'address':'626432, EGL - SOWETO, MPHO MOEMA, BONGOS PLACE PERMIT, 5472 MHLAKHOTHI STREET, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27370,'lng':27.90550}},
{'stp':626855,'address':'626855, EGL - SOWETO, MPHO MOEMA, DEE DEES INN PERMIT, 21 NEW ROAD KLIPRIVIERSOOG, KLIPTOWN WEST, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28100,'lng':27.88880}},
{'stp':627085,'address':'627085, EGL - SOWETO, MPHO MOEMA, ELIZABETH INN PERMIT, 5 INDIANA AVENUE, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28230,'lng':27.89550}},
{'stp':628151,'address':'628151, EGL - SOWETO, MPHO MOEMA, TONYS TAVERN PERMIT, 44 NIEKEL CRESCENT, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29330,'lng':27.89830}},
{'stp':629532,'address':'629532, EGL - SOWETO, MPHO MOEMA, NORMANS TAVERN PERMIT, 4013 A TSHUKHUDU STR, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27390,'lng':27.89310}},
{'stp':629967,'address':'629967, EGL - SOWETO, MPHO MOEMA, THANDEKAS PLACE, 51 BERRL STREET, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29450,'lng':27.89540}},
{'stp':630323,'address':'630323, EGL - SOWETO, MPHO MOEMA, RUSTENBURG SHOP, 8972 MPITSO STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22050,'lng':27.91020}},
{'stp':630779,'address':'630779, EGL - SOWETO, MPHO MOEMA, DEEVAN AND SHANNONS PLACE, 22 BAYTREE CRESCENT, ELDORADO PARK EXT 3, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29710,'lng':27.91190}},
{'stp':630817,'address':'630817, EGL - SOWETO, MPHO MOEMA, EXACT PLACE, 23 ORANJEZICHTT STREET, ELDORADO PARK EXT 4, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29250,'lng':27.91040}},
{'stp':630843,'address':'630843, EGL - SOWETO, MPHO MOEMA, ROCCO\'S PLACE, 33 ALABAMA AVENUE, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28460,'lng':27.89200}},
{'stp':630942,'address':'630942, EGL - SOWETO, MPHO MOEMA, MAGOGO\'S TAVERN, 58C CUMMING ROAD, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29940,'lng':27.89120}},
{'stp':631842,'address':'631842, EGL - SOWETO, MPHO MOEMA, IDAH NGOBENI PERMIT, 4669 STAND NO, 3915 B, 25 NTIMBANE STREET ZONE 4 PIMVILLE, PIMVILLE, South Africa','geo':{'lat':-26.27190,'lng':27.89360}},
{'stp':631849,'address':'631849, EGL - SOWETO, MPHO MOEMA, IZAK\'S PLACE, 18 HARVARD ROAD, ELDORADO PARK EXT 1, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29520,'lng':27.90580}},
{'stp':631963,'address':'631963, EGL - SOWETO, MPHO MOEMA, ANN\'S INN, 8 VEEN STR, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29530,'lng':27.89859}},
{'stp':632117,'address':'632117, EGL - SOWETO, MPHO MOEMA, MAUREEN\'S INN, 15 REDDERBERG STREET, ELDORADO PARK EXT 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28800,'lng':27.92320}},
{'stp':632267,'address':'632267, EGL - SOWETO, MPHO MOEMA, BIBI\'S PLACE, 570 570 KREMETART STREET, ELDORADO PARK EXT 3, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30130,'lng':27.91400}},
{'stp':632364,'address':'632364, EGL - SOWETO, MPHO MOEMA, THEMBA\'S PLACE, 8984A, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.21830,'lng':27.91030}},
{'stp':632374,'address':'632374, EGL - SOWETO, MPHO MOEMA, NORA\'S PLACE, 19B CUMING ROAD, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29280,'lng':27.89180}},
{'stp':633054,'address':'633054, EGL - SOWETO, MPHO MOEMA, LOUIS TAVERN, 43A CUMMING ROAD, ELDARADO PARK EXT 9, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29730,'lng':27.89140}},
{'stp':633322,'address':'633322, EGL - SOWETO, MPHO MOEMA, JOE\'S JAZZ CONER, 25 FLORIDA AVENUE, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28170,'lng':27.89340}},
{'stp':633326,'address':'633326, EGL - SOWETO, MPHO MOEMA, NTSOAKI\'S INN, 11760B MAMPURU STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23930,'lng':27.91140}},
{'stp':633634,'address':'633634, EGL - SOWETO, MPHO MOEMA, JENNY S PLACE, 4066B NKENTSHANE STREET, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27420,'lng':27.89600}},
{'stp':633664,'address':'633664, EGL - SOWETO, MPHO MOEMA, PATS TAVERN, ERF 5545 109 MAHONIE SINGLE, ELDORADO PARK EXT 4, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29500,'lng':27.91860}},
{'stp':633732,'address':'633732, EGL - SOWETO, MPHO MOEMA, GRACES INN, 660A SEROKOLO STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21160,'lng':27.90330}},
{'stp':633738,'address':'633738, EGL - SOWETO, MPHO MOEMA, SHAUNS TAVERN, 3130-6 WITMOSBERG STREET, ELDORADO PARK EXT 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28540,'lng':27.92090}},
{'stp':634018,'address':'634018, EGL - SOWETO, MPHO MOEMA, SEWENDE LAAN TAVERN, 13C CUMMING ROAD, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29230,'lng':27.89180}},
{'stp':634169,'address':'634169, EGL - SOWETO, MPHO MOEMA, SHEILAS PLACE, 50A CUMMINGS ROAD, OLD ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29800,'lng':27.89130}},
{'stp':634295,'address':'634295, EGL - SOWETO, MPHO MOEMA, SIPHO\'S CAFE, 5335  NGWENYAMA  STREET, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27530,'lng':27.90270}},
{'stp':634505,'address':'634505, EGL - SOWETO, MPHO MOEMA, WINNIE\'S INN, 683 INKANDLA STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22904,'lng':27.89825}},
{'stp':634535,'address':'634535, EGL - SOWETO, MPHO MOEMA, BETTIES INN, 41 KAMASSIE STREET, ELDORADO PARK EXT 3, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29660,'lng':27.91740}},
{'stp':634635,'address':'634635, EGL - SOWETO, MPHO MOEMA, RIPAS TAVERN, 338 MOLOI STREET, MOLETSANE, #, TLADI/MOLETSANE/JABU, South Africa','geo':{'lat':-26.25340,'lng':27.85110}},
{'stp':635314,'address':'635314, EGL - SOWETO, MPHO MOEMA, BABY\'S INN, 20 BERIL STREET, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29450,'lng':27.89390}},
{'stp':636019,'address':'636019, EGL - SOWETO, MPHO MOEMA, TOPA NAMA MASHABELA, 695 MASHABELA STR, JOE SLOVO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30780,'lng':27.90050}},
{'stp':636059,'address':'636059, EGL - SOWETO, MPHO MOEMA, D\'S TAVERN, 8739B LESOLANG STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22630,'lng':27.90710}},
{'stp':636622,'address':'636622, EGL - SOWETO, MPHO MOEMA, LITTLE ROSE, 849B MONGAGANA STREET, MEADOWLANDS ZONE 1, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21420,'lng':27.90520}},
{'stp':637100,'address':'637100, EGL - SOWETO, MPHO MOEMA, SANZAS PLACE, 578 WISTARIA STREET, ELDORADO PARK EXT 3, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30120,'lng':27.91290}},
{'stp':637230,'address':'637230, EGL - SOWETO, MPHO MOEMA, JEFF\'S TAVERN, 31 CUMING ROAD, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30230,'lng':27.89090}},
{'stp':637872,'address':'637872, EGL - SOWETO, MPHO MOEMA, YVONNE\'S PLACE, 77 3RD STREET, KLIPTOWN, #, ELDORADO PARK, South Africa','geo':{'lat':-26.27950,'lng':27.88590}},
{'stp':639661,'address':'639661, EGL - SOWETO, MPHO MOEMA, BENNETT\'S PLACE, 2990 NUM NUM STREET, ELDORADO PARK EXT 3, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30050,'lng':27.91790}},
{'stp':640561,'address':'640561, EGL - SOWETO, MPHO MOEMA, NOZASI\'S INN, 8105A THIVOTHI STREET, PIMVILLE ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.26550,'lng':27.91360}},
{'stp':641414,'address':'641414, EGL - SOWETO, MPHO MOEMA, SHEIRIDA\'S PLACE, 13926 TWALA STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23120,'lng':27.90650}},
{'stp':647514,'address':'647514, EGL - SOWETO, MPHO MOEMA, THOPE\'S PLACE, 8697 LESOLANG STREET, ORLANDO WEST 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22380,'lng':27.90780}},
{'stp':648427,'address':'648427, EGL - SOWETO, MPHO MOEMA, J C H LIQUOR STORE, 77 KREMATART STR (STAND 6715), ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29660,'lng':27.91360}},
{'stp':650699,'address':'650699, EGL - SOWETO, MPHO MOEMA, NAMBITHA, 6877 VILAKAZI STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23800,'lng':27.90400}},
{'stp':651328,'address':'651328, EGL - SOWETO, MPHO MOEMA, GAB TAVERN, 3328, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26700,'lng':27.89510}},
{'stp':655015,'address':'655015, EGL - SOWETO, MPHO MOEMA, PRISCILLAS LIQUOR STORE, 22 INDIANA AVENUE, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28240,'lng':27.89640}},
{'stp':660596,'address':'660596, EGL - SOWETO, MPHO MOEMA, PEGGYS INN, 1715 SILVER STREET, #, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30100,'lng':27.89860}},
{'stp':663117,'address':'663117, EGL - SOWETO, MPHO MOEMA, LALA VUKA (NTO), 8621 MABITSELA STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22380,'lng':27.90699}},
{'stp':688864,'address':'688864, EGL - SOWETO, MPHO MOEMA, THORNBIRDS (NTO), PLOT 152 KLIPRIVIER RD, EIKENHOF, #, WALKERVILLE/WEILERS, South Africa','geo':{'lat':-26.32708,'lng':27.99374}},
{'stp':695608,'address':'695608, EGL - SOWETO, MPHO MOEMA, SLOVO PARK TAVERN, ERF 497 50 CAVENDISH ROAD, NANCEFIELD, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30760,'lng':27.90310}},
{'stp':707505,'address':'707505, EGL - SOWETO, MPHO MOEMA, EDWARDS PLACE, 668D LESOTHO STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22810,'lng':27.89880}},
{'stp':710127,'address':'710127, EGL - SOWETO, MPHO MOEMA, NAOMIS TAVERN, 26 FAURE ROAD, ELDORADO PARK EXT 4, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29190,'lng':27.91210}},
{'stp':722318,'address':'722318, EGL - SOWETO, MPHO MOEMA, SONIAS PLACE, 8835 MDA STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22180,'lng':27.90840}},
{'stp':750319,'address':'750319, EGL - SOWETO, MPHO MOEMA, GOGO S PLACE, 958 MOLETSANE STRETT, SLOVO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30660,'lng':27.90280}},
{'stp':752804,'address':'752804, EGL - SOWETO, MPHO MOEMA, MONI S PLACE, 6009 STAND NO, ELANDSBERG DRIVE, ELDORADO PARKEXT 2, ELDORADO PARK, South Africa','geo':{'lat':-26.29900,'lng':27.92010}},
{'stp':758263,'address':'758263, EGL - SOWETO, MPHO MOEMA, PIMTOWN, 5341B MOKOKA AND SIKHANDE STREETS, ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27620,'lng':27.90240}},
{'stp':762098,'address':'762098, EGL - SOWETO, MPHO MOEMA, ZAKHO S PLACE, 9046 STAND NO A, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.21985,'lng':27.91137}},
{'stp':769849,'address':'769849, EGL - SOWETO, MPHO MOEMA, M S TAVERN, 15 ALGOA PLACE, ELDORADO PARK, EXTENSION 6, ELDORADO PARK, South Africa','geo':{'lat':-26.28280,'lng':27.90090}},
{'stp':773408,'address':'773408, EGL - SOWETO, MPHO MOEMA, P K PLACE, 8790B STAND NO, MDA STREET, ORLANDO WEST, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22448,'lng':27.90833}},
{'stp':779115,'address':'779115, EGL - SOWETO, MPHO MOEMA, MA INKA S, 41 DAKOTA ROAD, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29889,'lng':27.90611}},
{'stp':312774,'address':'312774, EGL - SOWETO, MPHO MOEMA, HAPPY BROTHERS INN, 327 STAND NO, #, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22715,'lng':27.93372}},
{'stp':315736,'address':'315736, EGL - SOWETO, MPHO MOEMA, DHLAMO\'S BEER GARDENS, 336 STAND NO, 38 BALLENDEN STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24035,'lng':27.92102}},
{'stp':377503,'address':'377503, EGL - SOWETO, MPHO MOEMA, NO1 KNOXMAN, 74H STAND NO, CARR STREET, ZONE 11, MEADOWLANDS, South Africa','geo':{'lat':-26.22210,'lng':27.93393}},
{'stp':389597,'address':'389597, EGL - SOWETO, MPHO MOEMA, KWA  SABI, 9207A STAND NO, MBHELE STREET, ORLANDO WEST, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22646,'lng':27.91009}},
{'stp':392120,'address':'392120, EGL - SOWETO, MPHO MOEMA, ESIHLAHLENI, 906 STAND NO, PHIELA STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23089,'lng':27.92582}},
{'stp':392770,'address':'392770, EGL - SOWETO, MPHO MOEMA, ROADSIDE, 844 STAND NO, ELEPHANT STREET, PENNYVILLE EXTENSION 1, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':482324,'address':'482324, EGL - SOWETO, MPHO MOEMA, LEX RONNIE, 976 STAND NO, PHIELA STREET ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23514,'lng':27.92767}},
{'stp':543889,'address':'543889, EGL - SOWETO, MPHO MOEMA, MALINDANE LIQUOR STORE, 1417/18 STAND NO, SOFASONE STREET, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23829,'lng':27.92688}},
{'stp':545154,'address':'545154, EGL - SOWETO, MPHO MOEMA, BACKO POZIT, 10388A MALIWA STREET, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22011,'lng':27.92007}},
{'stp':548695,'address':'548695, EGL - SOWETO, MPHO MOEMA, LIFIKA LIQOUR STORE, ERF 352, ELAND STREET, PENNYVILLE EXTENSION 1, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.21807,'lng':27.93838}},
{'stp':550658,'address':'550658, EGL - SOWETO, MPHO MOEMA, TINAS JOINT PERMIT, 9943A MOKALE STREET, ORLANDO WEST 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22590,'lng':27.92050}},
{'stp':550756,'address':'550756, EGL - SOWETO, MPHO MOEMA, BOY MASAKA S TAVERN, 10836B RAMUSHU STREET, ORLANDO WEST 2, SOWETO, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22420,'lng':27.91500}},
{'stp':551063,'address':'551063, EGL - SOWETO, MPHO MOEMA, ORLANDO EAST BOTTLE STORE, SHOP 4 6978 SOFASONKE STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22820,'lng':27.93150}},
{'stp':551428,'address':'551428, EGL - SOWETO, MPHO MOEMA, QUERENCIA TAVERN, ERF 358 PORTION 47 POPS PLACE, KLIPSPRUIT WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.28700,'lng':27.87770}},
{'stp':553249,'address':'553249, EGL - SOWETO, MPHO MOEMA, STEEL S INN, 161 WINTER STREET, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22550,'lng':27.93530}},
{'stp':553329,'address':'553329, EGL - SOWETO, MPHO MOEMA, LIBERTYS INN PERMIT, 88 FUSCHIA ROAD, KLIPSPRUIT WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.28380,'lng':27.88010}},
{'stp':553697,'address':'553697, EGL - SOWETO, MPHO MOEMA, NOMSA S TAVERN, 43 MOOKI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23800,'lng':27.92080}},
{'stp':553749,'address':'553749, EGL - SOWETO, MPHO MOEMA, SJR LIQUOR STORE, 10387 ELLIOT STREET, MZIMHLOPHE ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22170,'lng':27.92150}},
{'stp':553797,'address':'553797, EGL - SOWETO, MPHO MOEMA, THANDIS INN PERMIT, 3289 LETSATSI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23030,'lng':27.92990}},
{'stp':553810,'address':'553810, EGL - SOWETO, MPHO MOEMA, TOPSYS TAVERN PERMIT, 5099 SIKHOSANA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24400,'lng':27.91470}},
{'stp':553829,'address':'553829, EGL - SOWETO, MPHO MOEMA, THEMBAS PLACE PERMIT, 1080 NGCULU STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23547,'lng':27.92841}},
{'stp':553904,'address':'553904, EGL - SOWETO, MPHO MOEMA, PHUNGASHES PLACE, 572 LETSATSI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23480,'lng':27.92580}},
{'stp':560499,'address':'560499, EGL - SOWETO, MPHO MOEMA, LETUBE\'S PLACE, 3601 MPANE STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23210,'lng':27.93470}},
{'stp':580368,'address':'580368, EGL - SOWETO, MPHO MOEMA, SIBONGILES INN, STAND NO 3890, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23450,'lng':27.93630}},
{'stp':580391,'address':'580391, EGL - SOWETO, MPHO MOEMA, A M T, 2204  MUFUTSANYANA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23610,'lng':27.93300}},
{'stp':581167,'address':'581167, EGL - SOWETO, MPHO MOEMA, DINAH TAABS, 3033 BALLANDEN STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22560,'lng':27.92960}},
{'stp':581242,'address':'581242, EGL - SOWETO, MPHO MOEMA, SBONGILES INN, 10891A MACINGWANE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22240,'lng':27.91600}},
{'stp':603059,'address':'603059, EGL - SOWETO, MPHO MOEMA, MAMNTSHANGASESS PLACE, 4264 MACFADEN STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24300,'lng':27.91860}},
{'stp':604336,'address':'604336, EGL - SOWETO, MPHO MOEMA, RAKGADI, 9096B MVUBU STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22210,'lng':27.91200}},
{'stp':605354,'address':'605354, EGL - SOWETO, MPHO MOEMA, THOMAS PLACE, 2664 TLOOME STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24100,'lng':27.93500}},
{'stp':605356,'address':'605356, EGL - SOWETO, MPHO MOEMA, PHEPHIS PLACE, 901 PHIELA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23670,'lng':27.92580}},
{'stp':605384,'address':'605384, EGL - SOWETO, MPHO MOEMA, THABOS PLACE, 9925 B MOKALE STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22530,'lng':27.92320}},
{'stp':605417,'address':'605417, EGL - SOWETO, MPHO MOEMA, CHARLIES PLACE, 3044 BALLANDEN STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22690,'lng':27.92850}},
{'stp':605419,'address':'605419, EGL - SOWETO, MPHO MOEMA, LIFIS PLACE, 2524 SKOTA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24270,'lng':27.93240}},
{'stp':605420,'address':'605420, EGL - SOWETO, MPHO MOEMA, MTHUKS PLACE PERMIT, 1616 MOLAHLOE STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23540,'lng':27.93050}},
{'stp':605421,'address':'605421, EGL - SOWETO, MPHO MOEMA, MONWABISIS INN, 2658 STAND NO, TLOOME STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24156,'lng':27.93474}},
{'stp':605422,'address':'605422, EGL - SOWETO, MPHO MOEMA, DUDUZILES BEVERAGES, 3244 HERMINUS STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22696,'lng':27.93076}},
{'stp':605423,'address':'605423, EGL - SOWETO, MPHO MOEMA, THABISOS INN, 1648 MOLAHLOE STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23450,'lng':27.93100}},
{'stp':605430,'address':'605430, EGL - SOWETO, MPHO MOEMA, MANJAS PLACE, 10368B MALEWA STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.21990,'lng':27.91980}},
{'stp':605639,'address':'605639, EGL - SOWETO, MPHO MOEMA, WALAZAS INN, 10686B NOVEMBER STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22000,'lng':27.91570}},
{'stp':605709,'address':'605709, EGL - SOWETO, MPHO MOEMA, BIZZYS INN, 112 BALLENDED STREET, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22460,'lng':27.93480}},
{'stp':605895,'address':'605895, EGL - SOWETO, MPHO MOEMA, SIBONGILES PLACE, 9907A MPHIRIMA STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22640,'lng':27.92100}},
{'stp':606517,'address':'606517, EGL - SOWETO, MPHO MOEMA, TURFONTEIN PLACE, 9155B MOLEFI STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22610,'lng':27.91190}},
{'stp':606528,'address':'606528, EGL - SOWETO, MPHO MOEMA, MANTIMANDES TAVERN, 9863A INKUNGU STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22700,'lng':27.92080}},
{'stp':606541,'address':'606541, EGL - SOWETO, MPHO MOEMA, SMALLY, 3166 NJONGWE STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22860,'lng':27.93020}},
{'stp':606793,'address':'606793, EGL - SOWETO, MPHO MOEMA, BOY, 5851 TAUKOBONG STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24430,'lng':27.93420}},
{'stp':625068,'address':'625068, EGL - SOWETO, MPHO MOEMA, MADODAS PLACE, 9403 CARR STR, ORLANDO  WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22780,'lng':27.91850}},
{'stp':625084,'address':'625084, EGL - SOWETO, MPHO MOEMA, NTSIMBIS PLACE, 9873B NKUNGU STREET, ORLANDO  WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22670,'lng':27.92240}},
{'stp':625874,'address':'625874, EGL - SOWETO, MPHO MOEMA, FIHLAS PLACE, 2661 TLOOME STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24282,'lng':27.93309}},
{'stp':626464,'address':'626464, EGL - SOWETO, MPHO MOEMA, LUBUSI PERMIT, 3290 LETSATSI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23010,'lng':27.93000}},
{'stp':627072,'address':'627072, EGL - SOWETO, MPHO MOEMA, PRETTYS PLACE PERMIT, 10525A CARR  & MOHALE STREET, ORLANDO WEST 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22060,'lng':27.91860}},
{'stp':627315,'address':'627315, EGL - SOWETO, MPHO MOEMA, ABEL CHUBBY PLACE, 51 SAN SALVADOR STREET, KLIPSPRUIT WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.29140,'lng':27.87290}},
{'stp':627547,'address':'627547, EGL - SOWETO, MPHO MOEMA, MIKES TAVERN PERMIT, 847 DISA STREET, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23250,'lng':27.94060}},
{'stp':627879,'address':'627879, EGL - SOWETO, MPHO MOEMA, VETTIES PLACE PERMIT, 105 FLORENCE STREET, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22560,'lng':27.93180}},
{'stp':629725,'address':'629725, EGL - SOWETO, MPHO MOEMA, ANGIES INN, 684 LETSATSI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24140,'lng':27.92160}},
{'stp':630449,'address':'630449, EGL - SOWETO, MPHO MOEMA, RAMANYATSES GENERAL DEALERS, 3785 NKOMOYAHLABA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23650,'lng':27.93400}},
{'stp':630882,'address':'630882, EGL - SOWETO, MPHO MOEMA, FANYANES PLACE, 10714 B MPONTSO STREET, ORLANDO WEST MZIMHLOPHE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22140,'lng':27.91560}},
{'stp':632118,'address':'632118, EGL - SOWETO, MPHO MOEMA, MACHOKE\'S TAVERN, 10494 NOBANDA STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22200,'lng':27.91850}},
{'stp':632602,'address':'632602, EGL - SOWETO, MPHO MOEMA, J J\'S INN, 391 NORTH STREET, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22630,'lng':27.93710}},
{'stp':632610,'address':'632610, EGL - SOWETO, MPHO MOEMA, MALINDI\'S PLACE, 10261 POKA STREET, MZIMHLOPHE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22320,'lng':27.91990}},
{'stp':633134,'address':'633134, EGL - SOWETO, MPHO MOEMA, VALI\'S PLACE, 1528 SOFASONKE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23025,'lng':27.92984}},
{'stp':633670,'address':'633670, EGL - SOWETO, MPHO MOEMA, KINGS AND QUEENS, 53 ADAMS STREET, KLIPSPRUIT WEST EXT 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.29480,'lng':27.87550}},
{'stp':633794,'address':'633794, EGL - SOWETO, MPHO MOEMA, V E S PLACE, 9924A MOKALE STREET, ORLANDO WEST EXT 1, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22550,'lng':27.92350}},
{'stp':634292,'address':'634292, EGL - SOWETO, MPHO MOEMA, BOOYSENS INN, 11 MAJOR STREET, ERF 660, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22970,'lng':27.93620}},
{'stp':635957,'address':'635957, EGL - SOWETO, MPHO MOEMA, CHUPZA\'S INN, 4223 CHOECOE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24201,'lng':27.91621}},
{'stp':636921,'address':'636921, EGL - SOWETO, MPHO MOEMA, BUCIBOS TAVERN, 3435 MANANA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22980,'lng':27.93340}},
{'stp':637264,'address':'637264, EGL - SOWETO, MPHO MOEMA, TITS PLACE, 2284 JOLOBE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24141,'lng':27.93049}},
{'stp':638025,'address':'638025, EGL - SOWETO, MPHO MOEMA, LAPOLOGA\'S TAVERN, 3657 DHLAMINI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23230,'lng':27.93550}},
{'stp':638849,'address':'638849, EGL - SOWETO, MPHO MOEMA, SBONGILES TAVERN, 3027 BALLANDIN STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22930,'lng':27.92910}},
{'stp':639166,'address':'639166, EGL - SOWETO, MPHO MOEMA, HENRYS PLACE, 3298 MOGOYE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22960,'lng':27.93110}},
{'stp':639227,'address':'639227, EGL - SOWETO, MPHO MOEMA, PHASHES PLACE, 1159 NGULU STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23530,'lng':27.92840}},
{'stp':639417,'address':'639417, EGL - SOWETO, MPHO MOEMA, THEMBAS INN, 13093 ERF, 10661B ORLANDO WEST2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22070,'lng':27.91670}},
{'stp':639680,'address':'639680, EGL - SOWETO, MPHO MOEMA, SIBONGILE\'S PLACE, 1165 NGCULU STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23200,'lng':27.93080}},
{'stp':640228,'address':'640228, EGL - SOWETO, MPHO MOEMA, LULLABY, 10297B ELLIOT STREET, ORLANDO WEST 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22170,'lng':27.92170}},
{'stp':640232,'address':'640232, EGL - SOWETO, MPHO MOEMA, NANA\'S PLACE, 10452A MOHALE STREET, ORLANDO WEST 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22210,'lng':27.92050}},
{'stp':640311,'address':'640311, EGL - SOWETO, MPHO MOEMA, NDO\'S PLACE, 10360A NDABEZITHA STREET, ORLANDO WEST 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.21980,'lng':27.92050}},
{'stp':640313,'address':'640313, EGL - SOWETO, MPHO MOEMA, MOKGO\'S PLACE, 10429B HAPEBE STREET, ORLANDO WEST 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22110,'lng':27.92010}},
{'stp':640681,'address':'640681, EGL - SOWETO, MPHO MOEMA, VICTOR\'S PLACE, 475 HLATSWAYO STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23580,'lng':27.92440}},
{'stp':641186,'address':'641186, EGL - SOWETO, MPHO MOEMA, SALI\'S INN, 3284 LETSATSI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23030,'lng':27.92990}},
{'stp':641526,'address':'641526, EGL - SOWETO, MPHO MOEMA, NORMA\'S INN, 884 WITSERING STREET, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23320,'lng':27.94060}},
{'stp':641691,'address':'641691, EGL - SOWETO, MPHO MOEMA, THREE DLADLA INN, 10127 POOE STREET, ORLANDO WEST 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22400,'lng':27.92090}},
{'stp':642254,'address':'642254, EGL - SOWETO, MPHO MOEMA, THERESA\'S PLACE, 3619 MPANE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23430,'lng':27.93360}},
{'stp':646863,'address':'646863, EGL - SOWETO, MPHO MOEMA, OUPA\'S CORNER, 1674 MOLAHLOE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23860,'lng':27.92900}},
{'stp':647449,'address':'647449, EGL - SOWETO, MPHO MOEMA, CLAUDINES INN, 774 BERGROOS STREET, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23100,'lng':27.94120}},
{'stp':648602,'address':'648602, EGL - SOWETO, MPHO MOEMA, TA VUSI, 2040 MOTA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24000,'lng':27.93020}},
{'stp':648611,'address':'648611, EGL - SOWETO, MPHO MOEMA, NIP\'S INN, 623 STADIUM ROAD, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22950,'lng':27.93900}},
{'stp':648687,'address':'648687, EGL - SOWETO, MPHO MOEMA, PHALUKA\'S PLACE, 305 MAJOR STREET, NOORDGESIG, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22920,'lng':27.93570}},
{'stp':650495,'address':'650495, EGL - SOWETO, MPHO MOEMA, MAWASHAS PLACE, ERF 10617B MOLEFE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22070,'lng':27.91700}},
{'stp':651329,'address':'651329, EGL - SOWETO, MPHO MOEMA, PEPSIS INN, 8717 LESOLANG STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22590,'lng':27.90720}},
{'stp':660477,'address':'660477, EGL - SOWETO, MPHO MOEMA, LOUISAS PLACE, 828 B SEKELDAS STREET, ERF 965, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23280,'lng':27.94010}},
{'stp':660989,'address':'660989, EGL - SOWETO, MPHO MOEMA, WHITE HOUSE, 10158A STAND NO,  ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22175,'lng':27.92156}},
{'stp':666327,'address':'666327, EGL - SOWETO, MPHO MOEMA, RELOADS PLACE, 14 PHLOX STREET, KLIPSPRUIT WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.28870,'lng':27.88200}},
{'stp':684359,'address':'684359, EGL - SOWETO, MPHO MOEMA, MOTLAHELAS PLACE, 551 MANDELA SQUARE, BEACON ROAD, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.28710,'lng':27.88810}},
{'stp':703786,'address':'703786, EGL - SOWETO, MPHO MOEMA, DUDUS PLACE, 10467B QUPE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22170,'lng':27.91920}},
{'stp':707712,'address':'707712, EGL - SOWETO, MPHO MOEMA, CONNY S PLACE, 1812 MSIMANG STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23306,'lng':27.93070}},
{'stp':733280,'address':'733280, EGL - SOWETO, MPHO MOEMA, MAWEZA S PLACE, STAND NO 03606/7, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23580,'lng':27.92870}},
{'stp':752554,'address':'752554, EGL - SOWETO, MPHO MOEMA, MINGAS PLACE, 3998 LECHUBA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23630,'lng':27.93710}},
{'stp':762849,'address':'762849, EGL - SOWETO, MPHO MOEMA, DORAH MABULE S PLACE, 1281 STAND NO,  MAKOENA STREET ORLANDOP EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23580,'lng':27.92870}},
{'stp':765572,'address':'765572, EGL - SOWETO, MPHO MOEMA, WOLFIES PLACE PERMIT, 135 STAND NO, STATION ROAD NOORDGESIS, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22650,'lng':27.93180}},
{'stp':770336,'address':'770336, EGL - SOWETO, MPHO MOEMA, ANNECIA S PLACE, 1310 STAND NO, MOKOENA STREET ORLANDO EAST SOWETO, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23310,'lng':27.93060}},
{'stp':774820,'address':'774820, EGL - SOWETO, MPHO MOEMA, VUKA ZENZELE, 8821B STAND NO, MUNDA STREET ORLANDO WEST, MANKW MAKHDU, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22546,'lng':27.90854}},
{'stp':778119,'address':'778119, EGL - SOWETO, MPHO MOEMA, TINYS PLACE, 1324 MOKOENA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23490,'lng':27.92956}},
{'stp':781126,'address':'781126, EGL - SOWETO, MPHO MOEMA, LANGAS TAVERN, 0656 CAS STREET, ERVEN 28613-28619 SHOP 1, MEADOWLANDS ZONE 11, MEADOWLANDS, South Africa','geo':{'lat':-26.23363,'lng':27.92957}},
{'stp':790982,'address':'790982, EGL - SOWETO, MPHO MOEMA, BAZABAZA LIQUOR STORE, STAND NO, ERF 1510 SOFASONKE STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23828,'lng':27.92844}},
{'stp':792994,'address':'792994, EGL - SOWETO, MPHO MOEMA, MOIPONE PLACE, 9955A STAND NO, ORLANDO WEST, JOHANNESBURG, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22602,'lng':27.91967}},
{'stp':799605,'address':'799605, EGL - SOWETO, MPHO MOEMA, MEISIES PLACE, 2472 THIBEDI STREET, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24238,'lng':27.93194}},
{'stp':377648,'address':'377648, EGL - SOWETO, MPHO MOEMA, LEMI LIQUOR STORE, 9198 STAND NUMBER, SISULU STREET, ORLANDO WEST, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23067,'lng':27.90739}},
{'stp':381569,'address':'381569, EGL - SOWETO, MPHO MOEMA, TOUCH FAST FOOD, 202C STAND NUMBER, SECHELE STREET, ZONE 2, MEADOWLANDS, South Africa','geo':{'lat':-26.21401,'lng':27.89540}},
{'stp':383418,'address':'383418, EGL - SOWETO, MPHO MOEMA, VOVIVOS PLACE, 7417 STAND NUMBER, KHUMALO STREET, ORLANDO WEST, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23334,'lng':27.90847}},
{'stp':388277,'address':'388277, EGL - SOWETO, MPHO MOEMA, GIGINI S PLACE, 11576 STAND NO, NKADIMENG STREET, ORLANDO WEST,, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24224,'lng':27.90572}},
{'stp':388596,'address':'388596, EGL - SOWETO, MPHO MOEMA, NDOFAYA SOCIAL VIBE, 285B STAND NO, MOTSEPE STREET, MEADOWLANDS, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21489,'lng':27.89797}},
{'stp':389198,'address':'389198, EGL - SOWETO, MPHO MOEMA, POLAS PLACE, 4871 STAND NO, NICHOLA STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24701,'lng':27.92520}},
{'stp':390053,'address':'390053, EGL - SOWETO, MPHO MOEMA, MDLALOSE S PLACE, 373 STAND NO, #, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24901,'lng':27.92040}},
{'stp':390994,'address':'390994, EGL - SOWETO, MPHO MOEMA, TUMO S TAVERN, 2196A STAND NO, #, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21820,'lng':27.88892}},
{'stp':391174,'address':'391174, EGL - SOWETO, MPHO MOEMA, CHEAP CHEAP TUCK SHOP, 317A STAND NO, #, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22346,'lng':27.88695}},
{'stp':393422,'address':'393422, EGL - SOWETO, MPHO MOEMA, SELECT PRODUCT, 6521 ERF, ORLANDO EAST SOWETO, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23745,'lng':27.91953}},
{'stp':468582,'address':'468582, EGL - SOWETO, MPHO MOEMA, MAPULES PLACE, 224 D STAND NO, PORTION 23619, 8 RAPULWA STREET,MEADOWLANDS ZONE 2, MEADOWLANDS, South Africa','geo':{'lat':-26.21810,'lng':27.89820}},
{'stp':482253,'address':'482253, EGL - SOWETO, MPHO MOEMA, CORNER SNACK BAR, 11080 STAND NUMBER, ORLANDO WEST, ORLANDO WEST, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24128,'lng':27.90411}},
{'stp':482254,'address':'482254, EGL - SOWETO, MPHO MOEMA, JANET PLACE, 711A STAND NUMBER, FORBES ROAD, ZONE 6, MEADOWLANDS, South Africa','geo':{'lat':-26.22162,'lng':27.88957}},
{'stp':486427,'address':'486427, EGL - SOWETO, MPHO MOEMA, BOBOS PLACE, 7161 STAND NO, PHIRI STREET, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23682,'lng':27.90307}},
{'stp':487381,'address':'487381, EGL - SOWETO, MPHO MOEMA, NDODAS PLACE, 11481 STAND NO, #, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24403,'lng':27.90218}},
{'stp':490164,'address':'490164, EGL - SOWETO, MPHO MOEMA, PHINDIS TAVERN, 2611 STAND NO, TSHABALALA  STREET, ORLANDO STREET, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23990,'lng':27.93467}},
{'stp':545073,'address':'545073, EGL - SOWETO, MPHO MOEMA, TODDS INN, 4360 ORLANDO EAST, #, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23995,'lng':27.92046}},
{'stp':551037,'address':'551037, EGL - SOWETO, MPHO MOEMA, KIDDOS PLACE PERMIT, 7719 LEMBEDE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22970,'lng':27.91061}},
{'stp':553881,'address':'553881, EGL - SOWETO, MPHO MOEMA, SIBONGILES PLACE, 11358B TAMBO STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24550,'lng':27.90440}},
{'stp':553893,'address':'553893, EGL - SOWETO, MPHO MOEMA, MVULAS PLACE PERMIT, 11602A MAKABENI STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24210,'lng':27.90440}},
{'stp':560310,'address':'560310, EGL - SOWETO, MPHO MOEMA, MAVIS PLACE PERMIT, 55 ADAM STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23700,'lng':27.92210}},
{'stp':561073,'address':'561073, EGL - SOWETO, MPHO MOEMA, SEMASES INN, 4286 MAKAPELA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24400,'lng':27.92010}},
{'stp':561082,'address':'561082, EGL - SOWETO, MPHO MOEMA, COUNTS PLACE PERMIT, 4810 SEREPE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24880,'lng':27.92430}},
{'stp':561104,'address':'561104, EGL - SOWETO, MPHO MOEMA, IMBALIS TAVERN, 291 F EAST, MEADOWLANDS ZONE 11, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21750,'lng':27.91590}},
{'stp':561230,'address':'561230, EGL - SOWETO, MPHO MOEMA, LUCKY\'S PLACE, 5255 MOFUTSANYANA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22776,'lng':27.91133}},
{'stp':580358,'address':'580358, EGL - SOWETO, MPHO MOEMA, WHITY\'S TAVERN, 6367 MZINYATHI STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24870,'lng':27.92690}},
{'stp':580367,'address':'580367, EGL - SOWETO, MPHO MOEMA, TSHIDIS PLACE, 4135 MATHABA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24320,'lng':27.91800}},
{'stp':580370,'address':'580370, EGL - SOWETO, MPHO MOEMA, MARKET AND MOOI, 8194 LEMBEDE STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23550,'lng':27.91110}},
{'stp':580380,'address':'580380, EGL - SOWETO, MPHO MOEMA, DIMAMAS TAVERN, 1606 MOLAHLOE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.22423,'lng':27.89245}},
{'stp':580395,'address':'580395, EGL - SOWETO, MPHO MOEMA, HEITA AFRICA TAVERN, 4795 SILGEE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.27163,'lng':27.84205}},
{'stp':580630,'address':'580630, EGL - SOWETO, MPHO MOEMA, DELLYS PLACE, 721 HERBY MDINGI STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23720,'lng':27.92470}},
{'stp':580940,'address':'580940, EGL - SOWETO, MPHO MOEMA, CHOCS TAVERN, 1654 CHALKER AVE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24388,'lng':27.89861}},
{'stp':580943,'address':'580943, EGL - SOWETO, MPHO MOEMA, WILLIES DISTRIBUTOR, 5316 GAOBENTLEWE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24420,'lng':27.92840}},
{'stp':580969,'address':'580969, EGL - SOWETO, MPHO MOEMA, ANTHIAS PLACE, 520 LETSATSI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24160,'lng':27.92140}},
{'stp':603058,'address':'603058, EGL - SOWETO, MPHO MOEMA, NOMBULELOS PALCE, 4991 SKHOSANA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24490,'lng':27.91750}},
{'stp':603127,'address':'603127, EGL - SOWETO, MPHO MOEMA, NEW SPOT, 5171 MPANE STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24400,'lng':27.92630}},
{'stp':603690,'address':'603690, EGL - SOWETO, MPHO MOEMA, T P JAZZ CLUB, 6424 MORAILANE STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24930,'lng':27.92790}},
{'stp':604116,'address':'604116, EGL - SOWETO, MPHO MOEMA, MFELANG GENERAL DEALER, 5148 MSIMNANG STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24400,'lng':27.92570}},
{'stp':605425,'address':'605425, EGL - SOWETO, MPHO MOEMA, TAUS PLACE, 2142 MOTA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24310,'lng':27.92720}},
{'stp':605707,'address':'605707, EGL - SOWETO, MPHO MOEMA, BONGIS INN, 1555 1555 MOLAHLOE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24290,'lng':27.92510}},
{'stp':606102,'address':'606102, EGL - SOWETO, MPHO MOEMA, MARTINS PLACE, 11789 MTIPA STR, ORLANDO WEST EXT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23990,'lng':27.91073}},
{'stp':606540,'address':'606540, EGL - SOWETO, MPHO MOEMA, SIBONGILES PLACE, 7857 PITSONYANE STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23270,'lng':27.91270}},
{'stp':606542,'address':'606542, EGL - SOWETO, MPHO MOEMA, JAMES INN, 8051 BACELA STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23770,'lng':27.91130}},
{'stp':607408,'address':'607408, EGL - SOWETO, MPHO MOEMA, MAMAS INN, 11416  MTIPA  STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24480,'lng':27.90410}},
{'stp':611613,'address':'611613, EGL - SOWETO, MPHO MOEMA, SHIMMY MOFOKENG, STAND NO, ERF 2267 MOFUTSANYANA, STREET ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24325,'lng':27.92793}},
{'stp':625207,'address':'625207, EGL - SOWETO, MPHO MOEMA, HOLLA PERMIT, 7617 MAKHETHA STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23380,'lng':27.91020}},
{'stp':625210,'address':'625210, EGL - SOWETO, MPHO MOEMA, SELS PLACE PERMIT, 5201 MOTHA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24520,'lng':27.92630}},
{'stp':625221,'address':'625221, EGL - SOWETO, MPHO MOEMA, REFILOES PLACE, 11446A JEMLANA STR, ORLANDO  WEST EXT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24440,'lng':27.90350}},
{'stp':625907,'address':'625907, EGL - SOWETO, MPHO MOEMA, MAKIS PLACE PERMIT, 347 HLATSHWAYO STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24018,'lng':27.92164}},
{'stp':627543,'address':'627543, EGL - SOWETO, MPHO MOEMA, INCH PLACE PERMIT, 2459 DE BRUIN STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24150,'lng':27.93190}},
{'stp':627544,'address':'627544, EGL - SOWETO, MPHO MOEMA, FIKILES INN PERMIT, 2036 MOTHA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24050,'lng':27.92980}},
{'stp':627776,'address':'627776, EGL - SOWETO, MPHO MOEMA, PINKIES TAVERN PERMIT, 7843 XORILE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23070,'lng':27.91180}},
{'stp':627891,'address':'627891, EGL - SOWETO, MPHO MOEMA, THAMI AND SONS PERMIT, 7297 PHIRI STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23670,'lng':27.90600}},
{'stp':629276,'address':'629276, EGL - SOWETO, MPHO MOEMA, LINDIS PLACE PERMIT, 4368 ADAM STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24170,'lng':27.91890}},
{'stp':629490,'address':'629490, EGL - SOWETO, MPHO MOEMA, PINKYS PLACE, 8179 LEMBEDE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23760,'lng':27.90860}},
{'stp':630412,'address':'630412, EGL - SOWETO, MPHO MOEMA, TLADIS PLACE, 5208 MOTA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24420,'lng':27.92690}},
{'stp':631280,'address':'631280, EGL - SOWETO, MPHO MOEMA, NUNUS PLACE, 173 BALLENDEN STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24090,'lng':27.92060}},
{'stp':631809,'address':'631809, EGL - SOWETO, MPHO MOEMA, TMT\'S INN, 1606 CHALKER AVENUE, DUBE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24360,'lng':27.89830}},
{'stp':631901,'address':'631901, EGL - SOWETO, MPHO MOEMA, SISTER T\'S PLACE, 5341 STAND NO, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24680,'lng':27.92660}},
{'stp':632102,'address':'632102, EGL - SOWETO, MPHO MOEMA, FOKE\'S PLACE, 1570 MOLAHLOE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24120,'lng':27.92630}},
{'stp':632283,'address':'632283, EGL - SOWETO, MPHO MOEMA, KENNY\'S INN, 1995 MPANE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24110,'lng':27.92790}},
{'stp':632527,'address':'632527, EGL - SOWETO, MPHO MOEMA, MANTOA \'S INN, 333 BALLENDEN STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24010,'lng':27.92120}},
{'stp':632546,'address':'632546, EGL - SOWETO, MPHO MOEMA, MARGARET\'S PLACE, 287B MOTSEPE STREET, MEADOWLANDS ZONE 2, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21870,'lng':27.89900}},
{'stp':633327,'address':'633327, EGL - SOWETO, MPHO MOEMA, AZIZIS PLACE, 8069 BAQWA STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23860,'lng':27.90900}},
{'stp':633637,'address':'633637, EGL - SOWETO, MPHO MOEMA, DUMI\'S PLACE, 7500 LITABE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23200,'lng':27.90870}},
{'stp':636764,'address':'636764, EGL - SOWETO, MPHO MOEMA, SASA\'S TUCKSHOP, 6423 MORAILANE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24930,'lng':27.92810}},
{'stp':638833,'address':'638833, EGL - SOWETO, MPHO MOEMA, SINA\'S PLACE, 4267 MACFAYDEN STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24350,'lng':27.91870}},
{'stp':638840,'address':'638840, EGL - SOWETO, MPHO MOEMA, FIKILES PLACE, 1582 MOLAHLOE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23970,'lng':27.92740}},
{'stp':638852,'address':'638852, EGL - SOWETO, MPHO MOEMA, BENNYS PLACE, 4766 SIKHANDO STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24800,'lng':27.92340}},
{'stp':639145,'address':'639145, EGL - SOWETO, MPHO MOEMA, BUSIS PLACE, 11346 TAMBO STREET, ORLANDO WEST EXT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24450,'lng':27.90660}},
{'stp':639710,'address':'639710, EGL - SOWETO, MPHO MOEMA, RIB\'S PLACE, 716 HERBY MDINGI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23750,'lng':27.92450}},
{'stp':639878,'address':'639878, EGL - SOWETO, MPHO MOEMA, POPO\'S PLACE, 4291 MAKAPELA STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24330,'lng':27.91960}},
{'stp':640680,'address':'640680, EGL - SOWETO, MPHO MOEMA, MATLADI\'S SPORT, 129 ADAM STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.92419,'lng':27.23334}},
{'stp':640736,'address':'640736, EGL - SOWETO, MPHO MOEMA, SURGERY, 8450 OAK CRESCENT, ORLANDO GARDENS, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23130,'lng':27.91620}},
{'stp':640982,'address':'640982, EGL - SOWETO, MPHO MOEMA, MTHIS PLACE, 5498 HLISO STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24470,'lng':27.93010}},
{'stp':641332,'address':'641332, EGL - SOWETO, MPHO MOEMA, UNCLE S PLACE, 11554B NKADIMENG STREET, ORLANDO WEST EXT 1, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24175,'lng':27.90504}},
{'stp':641495,'address':'641495, EGL - SOWETO, MPHO MOEMA, MA F\'S PLACE, 11598A MAKABENI STREET, ORLANDO WEST EXT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24220,'lng':27.90500}},
{'stp':641681,'address':'641681, EGL - SOWETO, MPHO MOEMA, MLABA\'S PLACE, 11354B TAMBO STREET, ORLANDO WEST EXT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24510,'lng':27.90500}},
{'stp':641699,'address':'641699, EGL - SOWETO, MPHO MOEMA, MORUANES PLACE, 400A STAND NO, KENANG STREET, MEADOWLANDS ZONE 3, MEADOWLANDS, South Africa','geo':{'lat':-26.22420,'lng':27.90270}},
{'stp':641927,'address':'641927, EGL - SOWETO, MPHO MOEMA, SIPHIWES PLACE, 454 STAND NO, 135 AMANDLA BOULEVARD, BRAMFISCHERVILLE PHASE1, MEADOWLANDS, South Africa','geo':{'lat':-26.20826,'lng':27.85831}},
{'stp':641995,'address':'641995, EGL - SOWETO, MPHO MOEMA, MAMANE\'S TAVERN, 7872 PITSONYANE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23470,'lng':27.91310}},
{'stp':642293,'address':'642293, EGL - SOWETO, MPHO MOEMA, NOCWAKAS PLACE, 7469 LITABE STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23310,'lng':27.90880}},
{'stp':646958,'address':'646958, EGL - SOWETO, MPHO MOEMA, CHICKEN JIVE BUY AND BRAAI, 28542, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22320,'lng':27.90620}},
{'stp':647408,'address':'647408, EGL - SOWETO, MPHO MOEMA, GABIS PLACE, 11071 MAKABENI STREET, ORLANDO WEST EXT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24130,'lng':27.90300}},
{'stp':648680,'address':'648680, EGL - SOWETO, MPHO MOEMA, KA MAGRI, 28 MOOKI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23580,'lng':27.92220}},
{'stp':648847,'address':'648847, EGL - SOWETO, MPHO MOEMA, NHLEK\'S PLACE, 11714 PHOFOLLO STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24172,'lng':27.90803}},
{'stp':649116,'address':'649116, EGL - SOWETO, MPHO MOEMA, GOGO\'S TAVERN, 4419 HERBY MDINGI STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23818,'lng':27.90421}},
{'stp':659688,'address':'659688, EGL - SOWETO, MPHO MOEMA, TINY S INN, 4370 ADAM STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24170,'lng':27.91890}},
{'stp':659690,'address':'659690, EGL - SOWETO, MPHO MOEMA, TOWAS INN (NTO), 11205 MUTI STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23382,'lng':27.92417}},
{'stp':661174,'address':'661174, EGL - SOWETO, MPHO MOEMA, J C P SAAMSUKKEL, 306 JOE SLOVO PARK, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30580,'lng':27.89740}},
{'stp':663014,'address':'663014, EGL - SOWETO, MPHO MOEMA, MEISIE\'S PLACE (NTO), 376 ITENDELE STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21172,'lng':27.89418}},
{'stp':670265,'address':'670265, EGL - SOWETO, MPHO MOEMA, THEMBA\'S PLACE (NTO), 9254A MKWAYI STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.24701,'lng':27.92485}},
{'stp':684553,'address':'684553, EGL - SOWETO, MPHO MOEMA, GOGOS PLACE, 8345 OAK CRESCENT ORLANDO GARDENS, ORLANDO WEST EXT 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23130,'lng':27.91620}},
{'stp':684760,'address':'684760, EGL - SOWETO, MPHO MOEMA, LOLOS INN, 9365B MOPHIRING STREET, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.22615,'lng':27.91517}},
{'stp':699332,'address':'699332, EGL - SOWETO, MPHO MOEMA, TSITSI DISTRIBUTOR, 678 LEGAKABE STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20750,'lng':27.89540}},
{'stp':701781,'address':'701781, EGL - SOWETO, MPHO MOEMA, SIBONGILES INN, 7471 LETABE STR, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23330,'lng':27.90890}},
{'stp':726025,'address':'726025, EGL - SOWETO, MPHO MOEMA, LE MELON LIQUOR STORE, ERF 9220 MOLOI STREET, ORLANDO EAST, SOWETO, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24320,'lng':27.92050}},
{'stp':729285,'address':'729285, EGL - SOWETO, MPHO MOEMA, MIKES INN, 662A LEGAKABE STREET, MEADOWLANDS ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.20850,'lng':27.89490}},
{'stp':745593,'address':'745593, EGL - SOWETO, MPHO MOEMA, BAKWENA BOTTLE STORE, TLOU STREET, ERF 25836, ZONE 3, MEADOWLANDS, South Africa','geo':{'lat':-26.22600,'lng':27.90450}},
{'stp':749993,'address':'749993, EGL - SOWETO, MPHO MOEMA, JABU MALL, 7139 STREET NO, ORLANDO WEST, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23928,'lng':27.90373}},
{'stp':753282,'address':'753282, EGL - SOWETO, MPHO MOEMA, MPAKIS PLACE, 5686 TAUKOBONG STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24381,'lng':27.92761}},
{'stp':755951,'address':'755951, EGL - SOWETO, MPHO MOEMA, MANDOZA S INN, 803 STAND NO, MPOLOKENG STREET, MEADOWLANDS ZONE 3, MEADOWLANDS, South Africa','geo':{'lat':-26.21900,'lng':27.90560}},
{'stp':755977,'address':'755977, EGL - SOWETO, MPHO MOEMA, MUSHIS TAVERN, MALIE STREET, ERF 7137, ORLANDO WEST, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23847,'lng':27.90422}},
{'stp':755988,'address':'755988, EGL - SOWETO, MPHO MOEMA, NTSWAKIS PLACE, 6421 MOREILANE STREET, #, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24920,'lng':27.92820}},
{'stp':756171,'address':'756171, EGL - SOWETO, MPHO MOEMA, SHOSHOLOZA GUESTHOUSE AND RESTAURAN, 62 INDWA STREET, ERF 14329 ZONE 7, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21410,'lng':27.89140}},
{'stp':764915,'address':'764915, EGL - SOWETO, MPHO MOEMA, UNCLE S PLACE, 515 E STAND NO, PORTION 22212, 12 NYATHITREET, MEADOWLANDS, South Africa','geo':{'lat':-26.22530,'lng':27.89670}},
{'stp':766921,'address':'766921, EGL - SOWETO, MPHO MOEMA, KHOZIKAZI S PLACE, 2286 JALORE STREET, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.25386,'lng':27.92604}},
{'stp':766923,'address':'766923, EGL - SOWETO, MPHO MOEMA, MASUKU\'S SHEBEEN, STAND NO, UNIT 330, ROOM C ZONE 11, MEADOWLANDS, South Africa','geo':{'lat':-26.21530,'lng':27.91340}},
{'stp':771186,'address':'771186, EGL - SOWETO, MPHO MOEMA, TSEKO S PLACE, 5312 STAND NO, #, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24480,'lng':27.92800}},
{'stp':772703,'address':'772703, EGL - SOWETO, MPHO MOEMA, BOYZA S CORNER, 5126 STAND NO, KUSI STREET ORLANDO EAST ORLANDO, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24380,'lng':27.92510}},
{'stp':775094,'address':'775094, EGL - SOWETO, MPHO MOEMA, JABUS PLACE, STAND NO, ERF 1254 MOKENA STREET, ORLANDO EAST, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.23940,'lng':27.92600}},
{'stp':777924,'address':'777924, EGL - SOWETO, MPHO MOEMA, SNOWYS PLACE (NTO), 1220 MOKOENA STR, ORLANDO EAST, #, ORLANDO EAST/NOORDGE, South Africa','geo':{'lat':-26.24239,'lng':27.92359}},
{'stp':781024,'address':'781024, EGL - SOWETO, MPHO MOEMA, BUCCANEERS, 50 ELLIAS MOTSAOLEDI STREET, ORLANDO, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25432,'lng':27.92749}},
{'stp':315587,'address':'315587, EGL - SOWETO, MPHO MOEMA, EMAZENKENI TARVEN, 1704 STAND NO, MARTINS STREET, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.24710,'lng':27.92529}},
{'stp':318415,'address':'318415, EGL - SOWETO, MPHO MOEMA, MAMGO\'S PLACE, 11904 STAND NO, PIMVILLE, #, PIMVILLE, South Africa','geo':{'lat':-26.25347,'lng':27.90854}},
{'stp':377433,'address':'377433, EGL - SOWETO, MPHO MOEMA, 40 DRAGONS, 0400 STAND NO, 26 DRAGON DRIVE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25627,'lng':27.89286}},
{'stp':389058,'address':'389058, EGL - SOWETO, MPHO MOEMA, PHOMANO S PLACE, 3045 STAND NO, MATHITHIBALA STREET, ZONE 3, PIMVILLE, South Africa','geo':{'lat':-26.26400,'lng':27.89243}},
{'stp':391208,'address':'391208, EGL - SOWETO, MPHO MOEMA, NGOBENI S TAVERN, 3955 STAND NO, NTENESHI STREET PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27267,'lng':27.89262}},
{'stp':392500,'address':'392500, EGL - SOWETO, MPHO MOEMA, EUNICE\'S TAVERN, 47 RACE COURSE, #, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28031,'lng':27.90110}},
{'stp':482344,'address':'482344, EGL - SOWETO, MPHO MOEMA, BELLAS PLACE, 836 MAHLAKULA, #, #, PIMVILLE, South Africa','geo':{'lat':-26.25569,'lng':27.91357}},
{'stp':544893,'address':'544893, EGL - SOWETO, MPHO MOEMA, B SECTION, STAND NO, PORTION  1 OF ERF SHOP NO 3 EXT 2, SHOPPING COMPLEX NO 21 SNEEBURG STREET, ELDORADO PARK, South Africa','geo':{'lat':-26.29250,'lng':27.91040}},
{'stp':550299,'address':'550299, EGL - SOWETO, MPHO MOEMA, SUCCEEDS INN, 259 KLOKO STREET, PIMVILLE ZONE 7, #, PIMVILLE, South Africa','geo':{'lat':-26.27030,'lng':27.90460}},
{'stp':550649,'address':'550649, EGL - SOWETO, MPHO MOEMA, MINI TAVERN, 3297 A NGEMBE STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26760,'lng':27.89900}},
{'stp':551360,'address':'551360, EGL - SOWETO, MPHO MOEMA, LOCKS TAVERN, 20 GOOD HOPE PLACE, ELDORADO PARK EXT 6, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28340,'lng':27.90650}},
{'stp':552327,'address':'552327, EGL - SOWETO, MPHO MOEMA, P K TAVERN, STAND NO, ERF 1114, ZONE 01, PIMVILLE, SOWETO, PIMVILLE, South Africa','geo':{'lat':-26.26050,'lng':27.90820}},
{'stp':552510,'address':'552510, EGL - SOWETO, MPHO MOEMA, SESA INN, 400 B LEKHARI STREET, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25620,'lng':27.90850}},
{'stp':552611,'address':'552611, EGL - SOWETO, MPHO MOEMA, UPRISING SPORTS BAR, 9 BRUTUS STREET, ELDORADO PARK EXT 9, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29830,'lng':27.88840}},
{'stp':553162,'address':'553162, EGL - SOWETO, MPHO MOEMA, WINDSOR PLACE, 5192 NAARE STREET, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27430,'lng':27.90160}},
{'stp':553324,'address':'553324, EGL - SOWETO, MPHO MOEMA, CLUB T JUNCTION, 926 MOSOLODI STREET, KLIPSPRUIT, #, ELDORADO PARK, South Africa','geo':{'lat':-26.25620,'lng':27.91480}},
{'stp':553825,'address':'553825, EGL - SOWETO, MPHO MOEMA, CLUB 95 PERMIT, 95 MATSIE STREET, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25790,'lng':27.90640}},
{'stp':553847,'address':'553847, EGL - SOWETO, MPHO MOEMA, IRIS PLACE PERMIT, 14 TERENCE STREET, ELDORADO PARK EXT 9, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30030,'lng':27.88480}},
{'stp':560385,'address':'560385, EGL - SOWETO, MPHO MOEMA, SHADIS TAVERN PERMIT, 3315B SINGALOBA STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26700,'lng':27.89760}},
{'stp':560452,'address':'560452, EGL - SOWETO, MPHO MOEMA, ANNYS PLACE, 1347 MBAMBISA STREET, KLIPSPRUIT EXT 4, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25360,'lng':27.90990}},
{'stp':560496,'address':'560496, EGL - SOWETO, MPHO MOEMA, TA RICHIES PLACE, 1176 ELIAS MOTSOALEDI, DEVLAND, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.26580,'lng':27.93220}},
{'stp':560545,'address':'560545, EGL - SOWETO, MPHO MOEMA, DORI S TAVERN, 450 FUSCHIA ROAD, KLIPSPRUIT WEST, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29000,'lng':27.88080}},
{'stp':560552,'address':'560552, EGL - SOWETO, MPHO MOEMA, ROEKIE\'S TAVERN, 57 AZALIA ROAD, KLIPSPRUIT WEST, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28460,'lng':27.87780}},
{'stp':561071,'address':'561071, EGL - SOWETO, MPHO MOEMA, M G S PLACE, 2264 MOHLATSWA STREET, PIMVILLE ZONE 2, #, PIMVILLE, South Africa','geo':{'lat':-26.26140,'lng':27.90400}},
{'stp':561146,'address':'561146, EGL - SOWETO, MPHO MOEMA, M G L, 2547, PIMVILLE ZONE 2, #, PIMVILLE, South Africa','geo':{'lat':-26.26270,'lng':27.90240}},
{'stp':580711,'address':'580711, EGL - SOWETO, MPHO MOEMA, TAIMBOS PLACE, 1157 DUBE STREET, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26030,'lng':27.91130}},
{'stp':580771,'address':'580771, EGL - SOWETO, MPHO MOEMA, KA NURSE, 74 ELIAS MOTSOALEDI  STREET, DIEPKLOOF ZONE 6, #, DIEPKLOOF, South Africa','geo':{'lat':-26.26100,'lng':27.93080}},
{'stp':580966,'address':'580966, EGL - SOWETO, MPHO MOEMA, THREE SOUND, 4121 B MJANTJI STR, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27530,'lng':27.89670}},
{'stp':581123,'address':'581123, EGL - SOWETO, MPHO MOEMA, BASEMENT, 119 EAGLE CRESCENT STR, KLIPSPRUIT EXT 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.25960,'lng':27.89250}},
{'stp':581132,'address':'581132, EGL - SOWETO, MPHO MOEMA, MABALENGS PLACE, 4126 A MJANTJI STR, PIMVILLE ZONE 4, #, PIMVILLE, South Africa','geo':{'lat':-26.27500,'lng':27.89470}},
{'stp':603128,'address':'603128, EGL - SOWETO, MPHO MOEMA, LOUISAS INN, 431 STAND NO, KILSPRUIT, WEST EXTENSION, ELDORADO PARK, South Africa','geo':{'lat':-26.30250,'lng':27.87690}},
{'stp':603155,'address':'603155, EGL - SOWETO, MPHO MOEMA, RICHIES INN, 41 SLAGBERG STR, ELDORADO PARK EXT 4, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29320,'lng':27.91830}},
{'stp':603637,'address':'603637, EGL - SOWETO, MPHO MOEMA, MASBOS INN, 1164 STAND NO, BAMBISANA STREET, KLIPSPRUIT, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25144,'lng':27.91189}},
{'stp':603639,'address':'603639, EGL - SOWETO, MPHO MOEMA, SMAS PLACE, 8020 THSOLO STREET, PIMVILLE ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.26700,'lng':27.91270}},
{'stp':603699,'address':'603699, EGL - SOWETO, MPHO MOEMA, ALL NATIONS TAVERN, 824 JOE SLOVO PARK, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30810,'lng':27.90250}},
{'stp':604544,'address':'604544, EGL - SOWETO, MPHO MOEMA, NDONDOS PLACE, 3090 NDABA STR, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26770,'lng':27.89560}},
{'stp':606103,'address':'606103, EGL - SOWETO, MPHO MOEMA, MAMAS INN, 1275 MBAMBISA STR, KLIPSPRUIT EXT 14, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25242,'lng':27.91087}},
{'stp':606797,'address':'606797, EGL - SOWETO, MPHO MOEMA, MZANTSI SHEBEEN, 3516 MBONGISA STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26530,'lng':27.89970}},
{'stp':606808,'address':'606808, EGL - SOWETO, MPHO MOEMA, LITTLE WAVE, 3639 MHLOMO STR, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26520,'lng':27.90120}},
{'stp':626635,'address':'626635, EGL - SOWETO, MPHO MOEMA, POOR BOYS PERMIT, 3340 MSILINGA STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26570,'lng':27.89590}},
{'stp':626821,'address':'626821, EGL - SOWETO, MPHO MOEMA, PITSOS PLACE PERMIT, 2696A MOHLOKA STREET, PIMVILLE ZONE 2, #, PIMVILLE, South Africa','geo':{'lat':-26.26530,'lng':27.90360}},
{'stp':626822,'address':'626822, EGL - SOWETO, MPHO MOEMA, GABIS PLACE PERMIT, 359 MANHATTAN ST, KLIPSPRUIT EXT 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25870,'lng':27.89340}},
{'stp':626824,'address':'626824, EGL - SOWETO, MPHO MOEMA, KWA JEFF PERMIT, 8339A NONGAWE STREET, PIMVILLE ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.27470,'lng':27.91180}},
{'stp':627091,'address':'627091, EGL - SOWETO, MPHO MOEMA, MANGALISOS TAVERN PERMIT (ExFR), 1445 MTANGAYI STREET, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26100,'lng':27.90830}},
{'stp':627093,'address':'627093, EGL - SOWETO, MPHO MOEMA, BELIEVE IT OR NOT PERMIT, 7 DANGOR GROVE CNR FUSCHIA RD, KLIPSPRUIT WEST, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29060,'lng':27.87980}},
{'stp':627526,'address':'627526, EGL - SOWETO, MPHO MOEMA, VEROS TAVERN PERMIT, STAND 5302, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27590,'lng':27.89920}},
{'stp':629303,'address':'629303, EGL - SOWETO, MPHO MOEMA, LAPOLOGA REST, 111 MPONDONDE STREET, PIMVILLE ZONE 7, #, PIMVILLE, South Africa','geo':{'lat':-26.27020,'lng':27.90890}},
{'stp':629660,'address':'629660, EGL - SOWETO, MPHO MOEMA, ISIDINGO TAVERN PERMIT, 5 ASTER STREET, KLIPSPRUIT WEST, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28930,'lng':27.88020}},
{'stp':630161,'address':'630161, EGL - SOWETO, MPHO MOEMA, PORAS PLACE PERMIT, 62 KULSUM MORGAN STREET, KLIPTOWN EXT 10, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28320,'lng':27.88580}},
{'stp':630164,'address':'630164, EGL - SOWETO, MPHO MOEMA, TSAROS PLACE PERMIT, 2714A PLOT 11249, PIMVILLE ZONE 2, #, PIMVILLE, South Africa','geo':{'lat':-26.26600,'lng':27.90380}},
{'stp':630844,'address':'630844, EGL - SOWETO, MPHO MOEMA, UNCLE RONS INN, 18 ST JOSE STREET, KLIPSPRUIT WEST EXT 1, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29040,'lng':27.87700}},
{'stp':631191,'address':'631191, EGL - SOWETO, MPHO MOEMA, MABES SHEBEEN, STAND NO, ERF 1201 A PIMVILLE ZONE 1, SOWETO, PIMVILLE, South Africa','geo':{'lat':-26.25970,'lng':27.91460}},
{'stp':631237,'address':'631237, EGL - SOWETO, MPHO MOEMA, SKHUMBUZO\'S PLACE, 8128A THIPAMPETHU STREET, PIMVILLE ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.26700,'lng':27.91380}},
{'stp':631696,'address':'631696, EGL - SOWETO, MPHO MOEMA, MONICA\'S SHEBEEN, 464 TWALA STR, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25520,'lng':27.90910}},
{'stp':631778,'address':'631778, EGL - SOWETO, MPHO MOEMA, YVONNES INN, 31 MARYLAND STR, ELDORADO PARK EXT 7, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28470,'lng':27.89430}},
{'stp':631779,'address':'631779, EGL - SOWETO, MPHO MOEMA, D TRAIN, 1051 MOKHAHLANE STREET, KLIPSPRUIT, #, ELDORADO PARK, South Africa','geo':{'lat':-26.25870,'lng':27.91310}},
{'stp':632162,'address':'632162, EGL - SOWETO, MPHO MOEMA, ROSELINE\'S TAVERN, 44 BRANDBERG CRESCENT, ELDORADO PARK EXT 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28660,'lng':27.92120}},
{'stp':632268,'address':'632268, EGL - SOWETO, MPHO MOEMA, MALILA S PLACE, 1515 BHAKA STREET, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26360,'lng':27.90970}},
{'stp':632302,'address':'632302, EGL - SOWETO, MPHO MOEMA, DRY HOOK, 448A MOFOKENG STREET, KLIPSPRUIT, #, ELDORADO PARK, South Africa','geo':{'lat':-26.25800,'lng':27.91060}},
{'stp':632596,'address':'632596, EGL - SOWETO, MPHO MOEMA, BETTY S TAVERN, 3314A  SIYANGOLOBA STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26700,'lng':27.89750}},
{'stp':632884,'address':'632884, EGL - SOWETO, MPHO MOEMA, BONTANAS PLACE, 3055 JOSEPH STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26780,'lng':27.89500}},
{'stp':633195,'address':'633195, EGL - SOWETO, MPHO MOEMA, DLAMINI NATION GATE, 282 JOE SLOVO PARK, JOE SLOVO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30620,'lng':27.89580}},
{'stp':633479,'address':'633479, EGL - SOWETO, MPHO MOEMA, ZAMAS INN, 617 SLOVO PARK, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30800,'lng':27.90020}},
{'stp':633572,'address':'633572, EGL - SOWETO, MPHO MOEMA, ZIPHATHE\'S TAVERN, 2 MOTSOALEDI, DEVLAND, #, DEVLAND/FREEDOM PARK, South Africa','geo':{'lat':-26.25980,'lng':27.93050}},
{'stp':633674,'address':'633674, EGL - SOWETO, MPHO MOEMA, JOE\'S TAVERN, 11 COOK PLACE, KLIPSPRUIT EXT 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30010,'lng':27.87550}},
{'stp':634036,'address':'634036, EGL - SOWETO, MPHO MOEMA, VIRGINIA TAVERN, 85 SAN SALVADOR STREET, KLIPSPRUIT WEST EXT 1, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29160,'lng':27.87250}},
{'stp':634871,'address':'634871, EGL - SOWETO, MPHO MOEMA, HB TAVERN, 1253 MBABIZA STREET, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25270,'lng':27.91250}},
{'stp':634880,'address':'634880, EGL - SOWETO, MPHO MOEMA, UNTOUCHABLES INN TAVERN, 28 ABERTYN STREET, KLIPTOWN EXT 10, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28460,'lng':27.88630}},
{'stp':634920,'address':'634920, EGL - SOWETO, MPHO MOEMA, SIZA\'S PLACE, 1081B KOMA STREET, KLIPSPRUIT, #, ELDORADO PARK, South Africa','geo':{'lat':-26.25910,'lng':27.91410}},
{'stp':635067,'address':'635067, EGL - SOWETO, MPHO MOEMA, KETUMILE INN, 13 AKWAMARYN STREET, ELDORADO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29380,'lng':27.89330}},
{'stp':635578,'address':'635578, EGL - SOWETO, MPHO MOEMA, PHUKA\'S PLACE, 766 MAKHUBU STREET, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25500,'lng':27.91360}},
{'stp':636064,'address':'636064, EGL - SOWETO, MPHO MOEMA, SHEILAS PLACE, 138 TOSMAN FRIESLIEN AVE, ELDORADO PARK EXT 8, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29080,'lng':27.89480}},
{'stp':636435,'address':'636435, EGL - SOWETO, MPHO MOEMA, MANHIQUE\'S PLACE, 513 JOE SLOVO PARK, JOE SLOVO PARK, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30800,'lng':27.89770}},
{'stp':637871,'address':'637871, EGL - SOWETO, MPHO MOEMA, MIMMIE\'S PLACE, 47 BEACON ROAD, KLIPTOWN, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28570,'lng':27.88840}},
{'stp':638196,'address':'638196, EGL - SOWETO, MPHO MOEMA, KINGS LIQUOR STORE, 832 MCUBE DRIVE, DUBE VILLAGE, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.23790,'lng':27.89410}},
{'stp':638218,'address':'638218, EGL - SOWETO, MPHO MOEMA, SIMANGELE\'S PLACE, 260 NIGTHEENGALE STREET, KLIPSPRUIT EXT 2, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25670,'lng':27.89390}},
{'stp':639879,'address':'639879, EGL - SOWETO, MPHO MOEMA, MICHAELS PLACE, 908 MIRIME STREET, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25569,'lng':27.91267}},
{'stp':640560,'address':'640560, EGL - SOWETO, MPHO MOEMA, EMAZENKENI, ERF 4098, THUTLWA STREET, PIMVILLE ZONE 4, PIMVILLE, South Africa','geo':{'lat':-26.27390,'lng':27.89613}},
{'stp':640686,'address':'640686, EGL - SOWETO, MPHO MOEMA, RIDERS SHEBEEN, 3247B MOTSHWARAE STR, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26810,'lng':27.90070}},
{'stp':640690,'address':'640690, EGL - SOWETO, MPHO MOEMA, OUSI\'S PLACE, 2401 MOKWEREKWERE STREET, PIMVILLE  ZONE 2, #, PIMVILLE, South Africa','geo':{'lat':-26.26480,'lng':27.90500}},
{'stp':641339,'address':'641339, EGL - SOWETO, MPHO MOEMA, OUSIE\'S PLACE, 2617 MILLO STREET, PIMVILLE TOWNSHIP, #, PIMVILLE, South Africa','geo':{'lat':-26.26150,'lng':27.89990}},
{'stp':641371,'address':'641371, EGL - SOWETO, MPHO MOEMA, ZITHA\'S PLACE, 1186 DUBE STREET, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26020,'lng':27.91340}},
{'stp':641683,'address':'641683, EGL - SOWETO, MPHO MOEMA, LE BATIE INN, 63 DAFFODIL ROAD, KLIPSPRUIT WEST, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28680,'lng':27.88250}},
{'stp':641988,'address':'641988, EGL - SOWETO, MPHO MOEMA, NOMSA\'S PLACE, 8152B 8152B MHLAMBAMANZI STREET, PIMVILLE ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.26630,'lng':27.91440}},
{'stp':642321,'address':'642321, EGL - SOWETO, MPHO MOEMA, MAMA DORAS PLACE, 3278B SIGQALOBA STREET, PIMVILLE ZONE 3, #, PIMVILLE, South Africa','geo':{'lat':-26.26800,'lng':27.89850}},
{'stp':646389,'address':'646389, EGL - SOWETO, MPHO MOEMA, KWAJAMANI, 635 ELIAS MOTSOALEDI, DEVLAND, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.26450,'lng':27.92990}},
{'stp':655313,'address':'655313, EGL - SOWETO, MPHO MOEMA, MOSIRES PLACE, 7920 SANQI STREET, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26480,'lng':27.91040}},
{'stp':659857,'address':'659857, EGL - SOWETO, MPHO MOEMA, EILEENS PLACE, 26 PHILLIPI STREET, ELDORADO PARK EXT 4, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29240,'lng':27.91790}},
{'stp':661236,'address':'661236, EGL - SOWETO, MPHO MOEMA, GISELLE TAVERN INN, 38 APPLEGREEN COURT, ELDORADO PARK EXT 10, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28380,'lng':27.88520}},
{'stp':663265,'address':'663265, EGL - SOWETO, MPHO MOEMA, FATTIS PLACE, 2530 MOKGALO STREET, PIMVILLE ZONE 2, #, PIMVILLE, South Africa','geo':{'lat':-26.26330,'lng':27.90160}},
{'stp':664629,'address':'664629, EGL - SOWETO, MPHO MOEMA, CHU CHU, 8063A MAGHELEBHELE STREET, PIMVILLE ZONE 6, #, PIMVILLE, South Africa','geo':{'lat':-26.26770,'lng':27.91320}},
{'stp':671226,'address':'671226, EGL - SOWETO, MPHO MOEMA, WINSTON INN, 28 LEIKLIP STREET MAHALA PARK, ELDORADO PARK EXT INFILL, #, ELDORADO PARK, South Africa','geo':{'lat':-26.30440,'lng':27.89240}},
{'stp':682588,'address':'682588, EGL - SOWETO, MPHO MOEMA, STELLAS INN, 68 SEPTEMBER AVENUE, KLIPSPRUIT WEST 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29940,'lng':27.87490}},
{'stp':687277,'address':'687277, EGL - SOWETO, MPHO MOEMA, PINKYS PLACE, 5731 NEEMA STREET, PIMVILLE ZONE 5, #, PIMVILLE, South Africa','geo':{'lat':-26.27640,'lng':27.90860}},
{'stp':697837,'address':'697837, EGL - SOWETO, MPHO MOEMA, LINDIS PLACE, 1117, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26030,'lng':27.90870}},
{'stp':699762,'address':'699762, EGL - SOWETO, MPHO MOEMA, MALOME, 377 MOKOENA STREET, KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25770,'lng':27.90890}},
{'stp':705745,'address':'705745, EGL - SOWETO, MPHO MOEMA, MAMMATY PLACE, 8391MHLWATHI STREET, PIMVILLE ZONE 1, #, PIMVILLE, South Africa','geo':{'lat':-26.26590,'lng':27.91130}},
{'stp':719347,'address':'719347, EGL - SOWETO, MPHO MOEMA, ALINA S PLACE, 2727B SINGAWE STREET, #, #, PIMVILLE, South Africa','geo':{'lat':-26.26570,'lng':27.90460}},
{'stp':736908,'address':'736908, EGL - SOWETO, MPHO MOEMA, BIKINYAS PLACE, 313 MANDELA SQUARE, KLIPTOWN, #, ELDORADO PARK, South Africa','geo':{'lat':-26.28710,'lng':27.88750}},
{'stp':745355,'address':'745355, EGL - SOWETO, MPHO MOEMA, NOE S LIQUOR STORE, 3 SNEEUBERG STREET, PORTION 1 OF ERF 3592, EXT 2 SHOPPING COMPLEX 21, ELDORADO PARK, South Africa','geo':{'lat':-26.29396,'lng':27.92163}},
{'stp':746404,'address':'746404, EGL - SOWETO, MPHO MOEMA, KENNYS PALCE, 26 STAND NO, #, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29237,'lng':27.89184}},
{'stp':746802,'address':'746802, EGL - SOWETO, MPHO MOEMA, WHITE HOUSE, 464 STAND NO, EXT2, #, PIMVILLE, South Africa','geo':{'lat':-26.25700,'lng':27.89390}},
{'stp':753692,'address':'753692, EGL - SOWETO, MPHO MOEMA, THOMAS INN, 200 STAND NO, MARUMO STREET KLIPSPRUIT, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25940,'lng':27.90620}},
{'stp':762010,'address':'762010, EGL - SOWETO, MPHO MOEMA, CATHY S INN, 15 STAND NO, SAN SALVADOR EXT 1 KLIPSPRUITWEST, #, ELDORADO PARK, South Africa','geo':{'lat':-26.29710,'lng':27.87209}},
{'stp':765533,'address':'765533, EGL - SOWETO, MPHO MOEMA, SAMS LIQUOR STORE, STAND NO, ERF 119 BY 120, KLIPSRUIT, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.25890,'lng':27.90640}},
{'stp':769426,'address':'769426, EGL - SOWETO, MPHO MOEMA, ROCKET LIQUOR STORE, 5 UNIT, ERF 1178SHOP NO 4, CORNER OF MBAMBISA & MOFEKENG STREETS, ELDORADO PARK, South Africa','geo':{'lat':-26.25450,'lng':27.90850}},
{'stp':781006,'address':'781006, EGL - SOWETO, MPHO MOEMA, NTSHOLENG CORNER, 447 ZULU STREET, JOE SLOVO PARK, #, ORLANDO WEST/DUBE/KL, South Africa','geo':{'lat':-26.29660,'lng':27.91740}},
{'stp':781123,'address':'781123, EGL - SOWETO, MPHO MOEMA, SHADS LIQUOR STORE, 0033 UNION ROAD, ERF 1622 KLIPSPRUIT WEST, #, ELDORADO PARK, South Africa','geo':{'lat':-26.27900,'lng':27.89104}},
{'stp':783848,'address':'783848, EGL - SOWETO, MPHO MOEMA, CORNER DLADLA, 0922 STAND NO, KLIPSPRUIT, #, ELDORADO PARK, South Africa','geo':{'lat':-26.25490,'lng':27.91650}},
{'stp':794428,'address':'794428, EGL - SOWETO, MPHO MOEMA, TOGETHERNESS, 251 STAND NO, EXT 2, #, ELDORADO PARK, South Africa','geo':{'lat':-26.25624,'lng':27.89328}},
{'stp':311896,'address':'311896, EGL - SOWETO, MPHO MOEMA, BOER S PLACE, 00267 STAND NO, DIEPENAAR DRIVE  ZONE 1, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.23845,'lng':27.94015}},
{'stp':312629,'address':'312629, EGL - SOWETO, MPHO MOEMA, TNE\'S PLACE, 4861 STAND NO, B MARTHINUS DRIVE, 4 DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.24978,'lng':27.95391}},
{'stp':381425,'address':'381425, EGL - SOWETO, MPHO MOEMA, CHIKZAS PLACE, 495B STAND NUMBER, MABITSELA STREET, ZONE 5, MEADOWLANDS, South Africa','geo':{'lat':-26.23182,'lng':27.90391}},
{'stp':383559,'address':'383559, EGL - SOWETO, MPHO MOEMA, LEPILE S LIQUOR, 4139G STAND NO, #, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23587,'lng':27.94674}},
{'stp':383560,'address':'383560, EGL - SOWETO, MPHO MOEMA, JIMMY S PLACE, 4646B STAND NO, ERF NO 18679, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23954,'lng':27.95647}},
{'stp':386203,'address':'386203, EGL - SOWETO, MPHO MOEMA, KWA XABA, 226A STAND NO, MKHASIBE STREET, ZONE 5, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.22725,'lng':27.90118}},
{'stp':390417,'address':'390417, EGL - SOWETO, MPHO MOEMA, EMBIZWENI, 5658 ERF NO,  BENAUDE STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24955,'lng':27.95861}},
{'stp':390993,'address':'390993, EGL - SOWETO, MPHO MOEMA, T MAN S PLACE, 4237 MONNAFELA MOTHA STREET, #, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23318,'lng':27.94773}},
{'stp':391195,'address':'391195, EGL - SOWETO, MPHO MOEMA, THOBI S BLUES INN, 6659 STAND, ZONE 4 EXTENSION, DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.29498,'lng':27.93116}},
{'stp':477319,'address':'477319, EGL - SOWETO, MPHO MOEMA, KWA MASIYA S PLACE, 5742B STAND NO, ZONE 5, MBULELO STREET, DIEPKLOOF, South Africa','geo':{'lat':-26.24643,'lng':27.95049}},
{'stp':479110,'address':'479110, EGL - SOWETO, MPHO MOEMA, THAMIS PLACE, 5021 B OBOMBO STREET, DIEPKLOOF, ZONE 5, DIEPKLOOF, South Africa','geo':{'lat':-26.23723,'lng':27.95003}},
{'stp':481398,'address':'481398, EGL - SOWETO, MPHO MOEMA, MIMIS PLACE, 4402 STAND NO, MOKETSE STREET, ZONE 4 DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.24203,'lng':27.95198}},
{'stp':483954,'address':'483954, EGL - SOWETO, MPHO MOEMA, TSAKIS PLACE, 6992 B, MABIESKRAAL STREET, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.24560,'lng':27.95995}},
{'stp':491615,'address':'491615, EGL - SOWETO, MPHO MOEMA, HOUSE 48, 4843A STAND NUMBER, A MARTINUS SMUTS DRIVE, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.23811,'lng':27.95300}},
{'stp':549388,'address':'549388, EGL - SOWETO, MPHO MOEMA, MAJANZA SPORTS BAR, 1327 STAND NO, PHASE 3, DIEPKLOOF EXTENSION, DIEPKLOOF, South Africa','geo':{'lat':-26.23448,'lng':27.95274}},
{'stp':550282,'address':'550282, EGL - SOWETO, MPHO MOEMA, MAN S TAVERN, 4282A, DIEPKLOOF  ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23440,'lng':27.94730}},
{'stp':550394,'address':'550394, EGL - SOWETO, MPHO MOEMA, RHINO PLACE TAVERN, 7120 MODISA, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24421,'lng':27.95955}},
{'stp':550767,'address':'550767, EGL - SOWETO, MPHO MOEMA, KEKANA LIQUOR MARKET, STAND NO, ERF 3459 ZONE 3, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.23260,'lng':27.94680}},
{'stp':551979,'address':'551979, EGL - SOWETO, MPHO MOEMA, KWA STADIC TAVERN, 6643 POLOKELO STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23820,'lng':27.95893}},
{'stp':553169,'address':'553169, EGL - SOWETO, MPHO MOEMA, FLORINA\'S TAVERN, 4378A NTSU STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24220,'lng':27.95070}},
{'stp':553949,'address':'553949, EGL - SOWETO, MPHO MOEMA, IDAHS PLACE PERMIT, 4599A MOLETSI STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24010,'lng':27.95430}},
{'stp':553955,'address':'553955, EGL - SOWETO, MPHO MOEMA, MATHULAS PLACE-PERMIT, 3782 MARTINUS DRIVE, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23780,'lng':27.94470}},
{'stp':560381,'address':'560381, EGL - SOWETO, MPHO MOEMA, WILLIAMS PLACE PERMIT, 7255 FENYANG STR, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24470,'lng':27.96160}},
{'stp':560395,'address':'560395, EGL - SOWETO, MPHO MOEMA, SMITHS PLACE, 4874 LEEBA STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23990,'lng':27.95100}},
{'stp':560410,'address':'560410, EGL - SOWETO, MPHO MOEMA, PAPAS PLACE PERMIT, 4246 MONNAFELO MOTHA STREET, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23600,'lng':27.94680}},
{'stp':560497,'address':'560497, EGL - SOWETO, MPHO MOEMA, JCS PLACE, 3559 GEREAGOPOLA STREET, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23280,'lng':27.94850}},
{'stp':560498,'address':'560498, EGL - SOWETO, MPHO MOEMA, FRANKS CABIN, 189 EBEN CUYLER, DIEPKLOOF EXT PHASE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24680,'lng':27.96200}},
{'stp':561109,'address':'561109, EGL - SOWETO, MPHO MOEMA, NKGAILELES PLACE, 3738 MAKITIKITI STREET, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23690,'lng':27.94410}},
{'stp':561134,'address':'561134, EGL - SOWETO, MPHO MOEMA, ANDRIESS PLACE, 3726 MAKITIKITI STREET, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23680,'lng':27.94430}},
{'stp':561151,'address':'561151, EGL - SOWETO, MPHO MOEMA, PALESAS PLACE, 7178 MOROKWENG STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24470,'lng':27.96010}},
{'stp':580594,'address':'580594, EGL - SOWETO, MPHO MOEMA, BERTHA LETLEPO, 3869 THWANE STR, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23970,'lng':27.94630}},
{'stp':580633,'address':'580633, EGL - SOWETO, MPHO MOEMA, SULELOS TAVERN, 39A  ITUMELENG STR, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21520,'lng':27.90410}},
{'stp':580712,'address':'580712, EGL - SOWETO, MPHO MOEMA, OUPA\'S TAVERN, 6483  MAKHURA STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23640,'lng':27.95740}},
{'stp':580965,'address':'580965, EGL - SOWETO, MPHO MOEMA, DOLLY\'S PLACE, 3828 LEKANYANE STR, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23860,'lng':27.94530}},
{'stp':603126,'address':'603126, EGL - SOWETO, MPHO MOEMA, STANELY DLAMINI, 6037 NONGOMA STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24690,'lng':27.95320}},
{'stp':603636,'address':'603636, EGL - SOWETO, MPHO MOEMA, MAX PLACE, 334C KUBU STR, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22170,'lng':27.90160}},
{'stp':604278,'address':'604278, EGL - SOWETO, MPHO MOEMA, THABOS PLACE, 471A MOSETLHWA STR, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21701,'lng':27.91233}},
{'stp':605338,'address':'605338, EGL - SOWETO, MPHO MOEMA, STOKIS PLACE, 6671 MODIMOLLE STR, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23820,'lng':27.95510}},
{'stp':605488,'address':'605488, EGL - SOWETO, MPHO MOEMA, SPHIES PLACE, 4329B IMMINK DRIVE, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24160,'lng':27.95000}},
{'stp':605509,'address':'605509, EGL - SOWETO, MPHO MOEMA, KHULUS PLACE, 869A NDLOVU STR, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22248,'lng':27.90185}},
{'stp':605518,'address':'605518, EGL - SOWETO, MPHO MOEMA, K S INN, 212A SIBEKO STR, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22860,'lng':27.90100}},
{'stp':605902,'address':'605902, EGL - SOWETO, MPHO MOEMA, MAGGIES PLACE, 3678 BENYA-BENYA STR, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23510,'lng':27.94600}},
{'stp':625876,'address':'625876, EGL - SOWETO, MPHO MOEMA, NTSIZWAS PLACE-PERMIT, 6958 TAUNG STR, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23955,'lng':27.95926}},
{'stp':625917,'address':'625917, EGL - SOWETO, MPHO MOEMA, MAPULES INN PERMIT, 4696 IMMINK & RED SHAW DRV, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24030,'lng':27.95030}},
{'stp':625924,'address':'625924, EGL - SOWETO, MPHO MOEMA, HLOKS INN PERMIT, 6271A KOOS MOTHA STR, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23620,'lng':27.95310}},
{'stp':625937,'address':'625937, EGL - SOWETO, MPHO MOEMA, LICKS JAZZ INN PERMIT, 145A ITUMELENG STR, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21690,'lng':27.90560}},
{'stp':626460,'address':'626460, EGL - SOWETO, MPHO MOEMA, PHILLIPINES INN PERMIT, 6438 IMMINK DRIVE, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23450,'lng':27.95270}},
{'stp':626750,'address':'626750, EGL - SOWETO, MPHO MOEMA, THEMBAS PLACE PERMIT, 6165A MARTINUS STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24510,'lng':27.95510}},
{'stp':626751,'address':'626751, EGL - SOWETO, MPHO MOEMA, BOSE TAVERN PERMIT, 5779 MPENDULO STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24530,'lng':27.95020}},
{'stp':628717,'address':'628717, EGL - SOWETO, MPHO MOEMA, KO SNOOKENG PERMIT, 517B TSHIDZUMBA STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23120,'lng':27.90360}},
{'stp':629279,'address':'629279, EGL - SOWETO, MPHO MOEMA, BROWNS INN PERMIT, 719A ODENDAAL ROAD, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22790,'lng':27.89720}},
{'stp':629945,'address':'629945, EGL - SOWETO, MPHO MOEMA, MBAZO\'S PLACE, 4133 MARTINUS SMUTS DRIVE, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24324,'lng':27.95312}},
{'stp':631342,'address':'631342, EGL - SOWETO, MPHO MOEMA, MSOJAS PLACE, 4606 MOLETJIE STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24120,'lng':27.95490}},
{'stp':631620,'address':'631620, EGL - SOWETO, MPHO MOEMA, FREDDIE\'S INN, 5987 THOKOZILE STR, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24750,'lng':27.95340}},
{'stp':631959,'address':'631959, EGL - SOWETO, MPHO MOEMA, SWAZI\'S INN, 4455A MATONOSI STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24340,'lng':27.95310}},
{'stp':632331,'address':'632331, EGL - SOWETO, MPHO MOEMA, SYLVIA S INN, 1055 ZWELITSHE STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23495,'lng':27.94939}},
{'stp':632539,'address':'632539, EGL - SOWETO, MPHO MOEMA, DALLAS INN, 3891 STAND NUMBER, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24020,'lng':27.94740}},
{'stp':634164,'address':'634164, EGL - SOWETO, MPHO MOEMA, LAURETTA S PLACE, 4097 NARE STREET, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23730,'lng':27.94980}},
{'stp':634205,'address':'634205, EGL - SOWETO, MPHO MOEMA, MABETHAMAS PLACE, 4049 B MAITSE STREET, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23820,'lng':27.94980}},
{'stp':635304,'address':'635304, EGL - SOWETO, MPHO MOEMA, B S PLACE, 295 RETLILE STREET, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22180,'lng':27.90130}},
{'stp':636640,'address':'636640, EGL - SOWETO, MPHO MOEMA, JOE BUDDY\'S PLACE, 4439 MONTWEDI STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24170,'lng':27.95240}},
{'stp':637042,'address':'637042, EGL - SOWETO, MPHO MOEMA, PEOPLE\'S LIQUOR STORE, 12401/2/3 SHOP 2 PEOPLE\'S DOMESTIC, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23520,'lng':27.93910}},
{'stp':637115,'address':'637115, EGL - SOWETO, MPHO MOEMA, VILLAGE ANN TAVERN, 3984B MOSUPATSELA STREET, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23940,'lng':27.94900}},
{'stp':640804,'address':'640804, EGL - SOWETO, MPHO MOEMA, SHOLO\'S PLACE, 6711 MASHEHANE STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24030,'lng':27.95580}},
{'stp':641399,'address':'641399, EGL - SOWETO, MPHO MOEMA, MATHANDI\'S PLACE, 428 KOBA STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22840,'lng':27.90390}},
{'stp':641408,'address':'641408, EGL - SOWETO, MPHO MOEMA, BRANICKS PLACE, 4650B RUSTENBURG ROAD, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24080,'lng':27.95360}},
{'stp':641425,'address':'641425, EGL - SOWETO, MPHO MOEMA, MISTRES\'S PLACE, 657B SCHRADER STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.23000,'lng':27.89920}},
{'stp':641430,'address':'641430, EGL - SOWETO, MPHO MOEMA, SELINA\'S PLACE, 6971 B MAKHURA DRIVE, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24040,'lng':27.96040}},
{'stp':641668,'address':'641668, EGL - SOWETO, MPHO MOEMA, TWO SEVEN INN, 27 MOTSWAOSELLE STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23460,'lng':27.93870}},
{'stp':641791,'address':'641791, EGL - SOWETO, MPHO MOEMA, NTSHEGENG\'S PLACE, 651NAKEDI STREET, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22450,'lng':27.90340}},
{'stp':641842,'address':'641842, EGL - SOWETO, MPHO MOEMA, JEFF\'S PLACE, 630B KENANG STREET, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22440,'lng':27.90620}},
{'stp':642215,'address':'642215, EGL - SOWETO, MPHO MOEMA, TSHIDI\'S PLACE, 4327 IMMINK DRIVE, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24190,'lng':27.95000}},
{'stp':642665,'address':'642665, EGL - SOWETO, MPHO MOEMA, DORIS TUCKSHOP, 6314 BOITSOKA STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23610,'lng':27.95480}},
{'stp':642671,'address':'642671, EGL - SOWETO, MPHO MOEMA, CAPTAIN\'S INN, 4776 WITSIESHOEK STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23860,'lng':27.95220}},
{'stp':646517,'address':'646517, EGL - SOWETO, MPHO MOEMA, REHABS PLACE, 7367 MOLEFE STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24380,'lng':27.95610}},
{'stp':646522,'address':'646522, EGL - SOWETO, MPHO MOEMA, DITIKE SNACK BAR, 1458 IMMINK DRIVE, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24410,'lng':27.94930}},
{'stp':646556,'address':'646556, EGL - SOWETO, MPHO MOEMA, JONE\'S PLACE, 4779 WITERSHOEK STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23791,'lng':27.95312}},
{'stp':647659,'address':'647659, EGL - SOWETO, MPHO MOEMA, ADMIRAL TAVERN, 4576 RUSTENBURG STREET,  ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24137,'lng':27.95499}},
{'stp':650264,'address':'650264, EGL - SOWETO, MPHO MOEMA, NGANES PLACE, 5993 THOKOZILE STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24840,'lng':27.95310}},
{'stp':650580,'address':'650580, EGL - SOWETO, MPHO MOEMA, THEMBIS INN, 5968B JABULANE STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24890,'lng':27.95240}},
{'stp':652369,'address':'652369, EGL - SOWETO, MPHO MOEMA, THUSIS PLACE, 5934 B LUNGILE STREET, DIEPKLOOF ZONE 5, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24930,'lng':27.95320}},
{'stp':658687,'address':'658687, EGL - SOWETO, MPHO MOEMA, MABUS PLACE, 7218 LIBA STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24430,'lng':27.96080}},
{'stp':660617,'address':'660617, EGL - SOWETO, MPHO MOEMA, RIDERS PLACE, 6722  MASHESHANE STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23900,'lng':27.95490}},
{'stp':660666,'address':'660666, EGL - SOWETO, MPHO MOEMA, BIG BIG JOE, 186B RAMPA STREET, DIEPKLOOF ZONE 1, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23808,'lng':27.94103}},
{'stp':666320,'address':'666320, EGL - SOWETO, MPHO MOEMA, CHIPPAS PLACE, 4562A MEHLOLO STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24991,'lng':27.95339}},
{'stp':695321,'address':'695321, EGL - SOWETO, MPHO MOEMA, LIZZYS MINERALS, 176A MNGUNI STREET, MEADOWLANDS ZONE 5, #, MEADOWLANDS, South Africa','geo':{'lat':-26.22830,'lng':27.90010}},
{'stp':704891,'address':'704891, EGL - SOWETO, MPHO MOEMA, BARCELONA, 4321 MAKHUTSO DRIVE, DIEPKLOOF ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23419,'lng':27.94678}},
{'stp':705499,'address':'705499, EGL - SOWETO, MPHO MOEMA, RAINBOW TAVERN, 4455B MATONOTSI STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24320,'lng':27.95310}},
{'stp':715041,'address':'715041, EGL - SOWETO, MPHO MOEMA, KENNYS PLACE, 51A LEROTHO STREET, MEADOWLANDS ZONE 3, #, MEADOWLANDS, South Africa','geo':{'lat':-26.21660,'lng':27.90330}},
{'stp':725037,'address':'725037, EGL - SOWETO, MPHO MOEMA, PANYAZAS PLACE, 6183 MARTHUIS DRIVE, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23820,'lng':27.95340}},
{'stp':728660,'address':'728660, EGL - SOWETO, MPHO MOEMA, MOTANAS INN, 7221B LIBA STREET, DIEPKLOOF ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24440,'lng':27.96130}},
{'stp':731925,'address':'731925, EGL - SOWETO, MPHO MOEMA, FUZE AND FUZE LIQUOR STORE, 19151 MARSHA ROAD, MEADOWLANDS ZONE 3, SOWETO, MEADOWLANDS, South Africa','geo':{'lat':-26.22460,'lng':27.90240}},
{'stp':747088,'address':'747088, EGL - SOWETO, MPHO MOEMA, WESE PLACE, STREET NO, 432B SANDERS ROAD ZONE 3, MEADOWLANDS, MEADOWLANDS, South Africa','geo':{'lat':-26.21900,'lng':27.90400}},
{'stp':748384,'address':'748384, EGL - SOWETO, MPHO MOEMA, UNICE PLACE, STREET NO, 4383 NTSU STREET ZONE 4 DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24230,'lng':27.95160}},
{'stp':763470,'address':'763470, EGL - SOWETO, MPHO MOEMA, SYLVIA S PLACE, 6528 STAND NO, TUKISO STREET ZONE 4, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.23720,'lng':27.95760}},
{'stp':764124,'address':'764124, EGL - SOWETO, MPHO MOEMA, ALINA SHEBEEN, 6848 A STAND NO, LETABA STREET ZONE 4 DIEPKLOOF, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24120,'lng':27.95730}},
{'stp':764356,'address':'764356, EGL - SOWETO, MPHO MOEMA, SMAN S SHABANGU LIQUOR, 5734 B MBOLELO STREE, ZONE 5, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.24740,'lng':27.95050}},
{'stp':767093,'address':'767093, EGL - SOWETO, MPHO MOEMA, LAWRENCE PLACE, 6461A MAKHURA STREET, ZONE 4, DIEPKLOOF, DIEPKLOOF, South Africa','geo':{'lat':-26.23505,'lng':27.95559}},
{'stp':783354,'address':'783354, EGL - SOWETO, MPHO MOEMA, LALLOS PLACE, 6926 WESSEL MOTA STREET, ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24149,'lng':27.95615}},
{'stp':784436,'address':'784436, EGL - SOWETO, MPHO MOEMA, MAMOGOLOS INN, 3831B STAND NO, LEKANYAE STREET ZONE 3, #, DIEPKLOOF, South Africa','geo':{'lat':-26.24641,'lng':27.95048}},
{'stp':790962,'address':'790962, EGL - SOWETO, MPHO MOEMA, MOBES PLACE, 6842A STAND NO, MOLOI STREET ZONE 4, #, DIEPKLOOF, South Africa','geo':{'lat':-26.23759,'lng':27.95822}},
{'stp':318410,'address':'318410, EGL - WEST, WEST HYBRID, CHECKERS LIQUOR SHOP KYALAMI LC 092, 1 STAND NUMBER, MAC GREGOR ROAD, CN MAIN ROAD, KYALAMI, South Africa','geo':{'lat':-25.98444,'lng':28.07436}},
{'stp':383647,'address':'383647, EGL - WEST, WEST HYBRID, CHECKERS LIQUORSHOP SUNNINGHILL LS-, 1A STAND NO, NAIVASHA ROAD, CNR LEEUWKOP,SUNNINGHILL, #, SUNNINGHILL, South Africa','geo':{'lat':-26.03273,'lng':28.06377}},
{'stp':384537,'address':'384537, EGL - WEST, WEST HYBRID, THE BARON KYALAMI (NTO), 117 KYALAMI CORNER SHOPPING CENTRE, CNR MAIN ROAD(M17) AND PITTS AVENUE(R55), MIDRAND, KYALAMI, South Africa','geo':{'lat':-25.98587,'lng':28.07424}},
{'stp':384684,'address':'384684, EGL - WEST, WEST HYBRID, NORMAN GOODFELLOWS PTY LTD, 2, 2A SHOP NO, KYALAMI CORNER SHOPPING CENTRE, KYALAMI EXTENSION 3 HOLDING 5,6,7,8,9,10, KYALAMI, South Africa','geo':{'lat':-25.98364,'lng':28.07422}},
{'stp':386173,'address':'386173, EGL - WEST, WEST HYBRID, SALSA MEXICAN GRILL KYALAMI (NTO), SHOP 47 KYALAMI CORNER SHOPPING CEN, BOUNDED BY PITTS AVE, BEGONIA RD M71, MAIN ROAD, KYALAMI, KYALAMI, South Africa','geo':{'lat':-25.98418,'lng':28.07479}},
{'stp':386929,'address':'386929, EGL - WEST, WEST HYBRID, LIPPY S, STAND NO, CNR SILVERSTONE CRESCENT AND ZINNIA RD, KYALAMI PARK BEING ERF 165, KYALAMI, South Africa','geo':{'lat':-25.99156,'lng':28.06834}},
{'stp':388032,'address':'388032, EGL - WEST, WEST HYBRID, HUSSAR GRILL (NTO), ERF:1794, SHOP NO:5 WATERFALL CORNE, CORNER WOODMEAD & MAXWELL DRIVES, JUKSKEI VIEW EXT 51, WATERFALL, MIDRAND, South Africa','geo':{'lat':-26.02125,'lng':28.08792}},
{'stp':389091,'address':'389091, EGL - WEST, WEST HYBRID, PIZA E VINO (CAMBRIDGE CROSSING) (N, L23 CAMBRIDGE CROSSING CENTRE, WITKOPPEN ROAD, PAULSHOF, SUNNINGHILL, South Africa','geo':{'lat':-26.03577,'lng':28.05150}},
{'stp':389237,'address':'389237, EGL - WEST, WEST HYBRID, THE DINER (NTO), SHOP 78, 78A AND 61, KYALAMI SHOPPING CENTRE,CNR MAIN AND, KYALAMI ROADS, KYALAMI EXT. 3 , KYALAMI, KYALAMI, South Africa','geo':{'lat':-25.98352,'lng':28.07446}},
{'stp':389619,'address':'389619, EGL - WEST, WEST HYBRID, PIZA E\' VINO (NTO), ERF:3 KYALAMI RIDGE EXT:3, KYALAMI, CNR OF MAIN RD(M71) & PITTS AVE(R55) RDS, ENTRANCE FROM MAIN RD,KYALAMI RIDGE EXT3, KYALAMI, South Africa','geo':{'lat':-25.98342,'lng':28.07523}},
{'stp':461092,'address':'461092, EGL - WEST, WEST HYBRID, Ballbreakers Sunninghill (NTO), Shop 13B, The square, Cnr Leeukop & Naivasha Road, Sunninghill, SUNNINGHILL, South Africa','geo':{'lat':-26.03275,'lng':28.06370}},
{'stp':462587,'address':'462587, EGL - WEST, WEST HYBRID, PICK N PAY GC76 KYALAMI ON MAIN, STAND NO, ERF 0019 CNR OF FORSSMAN CLOSE & DYTCHLE, KYALAMI, KYALAMI, South Africa','geo':{'lat':-26.00190,'lng':28.07524}},
{'stp':463291,'address':'463291, EGL - WEST, WEST HYBRID, ROCOMAMAS KYALAMI (NTO), KYALAMI MAIN ON SHOPPING CENTRE, CNR DUTCHLEY & MAIN ROAD, KYALAMI, KYALAMI, South Africa','geo':{'lat':-26.00294,'lng':28.07445}},
{'stp':466741,'address':'466741, EGL - WEST, WEST HYBRID, SHANNEE SPUR SUNNINGHILL (NTO), Shop 6, Chilli on Top Shopping Cent, Cnr Rivonia & Leeukop Road, Sunninghill, SUNNINGHILL, South Africa','geo':{'lat':-26.03054,'lng':28.06081}},
{'stp':472061,'address':'472061, EGL - WEST, WEST HYBRID, LIQUOR CITY CHILLI ON TOP, SHOP NO 08, CHILLI ON TOP CENTRE, CNR LEEUKOP DRIVE AND RIVONIA ROAD, SUNNINGHILL, South Africa','geo':{'lat':-26.03010,'lng':28.06167}},
{'stp':482411,'address':'482411, EGL - WEST, WEST HYBRID, SMILE CAFE WOODMEAD (NTO), CNR WOODMEAD & MAXWELL DRIVE, #, #, MIDRAND, South Africa','geo':{'lat':-26.04299,'lng':28.09328}},
{'stp':482416,'address':'482416, EGL - WEST, WEST HYBRID, NISOMO WATERFALL (NTO), 54 MAXWELL DRIVE, JUKSKEI VIEW, #, MIDRAND, South Africa','geo':{'lat':-26.04405,'lng':28.09247}},
{'stp':482417,'address':'482417, EGL - WEST, WEST HYBRID, TURN N TENDER WATERFALL (NTO), 54 MAXWELL DRIVE, JUKSKEI VIEW, #, MIDRAND, South Africa','geo':{'lat':-26.01985,'lng':28.08969}},
{'stp':488293,'address':'488293, EGL - WEST, WEST HYBRID, CHECKERS LIQUORSHOP WATERFALL CNR 3, 0001 STAND NO, CORNER MAXWELL AND WOODMEAT DRIVES, JUKSKEI VIEW EXT 51, MIDRAND, South Africa','geo':{'lat':-26.02169,'lng':28.08878}},
{'stp':540974,'address':'540974, EGL - WEST, WEST HYBRID, THE LOFT (NTO), KYALAMI MAIN ON SHOPPING CENTRE, CNR DUTCHLEY & MAIN ROAD, KYALAMI, KYALAMI, South Africa','geo':{'lat':-26.00294,'lng':28.07445}},
{'stp':550174,'address':'550174, EGL - WEST, WEST HYBRID, KEG AND FILLY RESTAURANT, 4 TAMBACH ROAD PAULSHOF X 50, SUNNINGHILL, RANDBURG, SUNNINGHILL, South Africa','geo':{'lat':-26.03530,'lng':28.06040}},
{'stp':550509,'address':'550509, EGL - WEST, WEST HYBRID, LOCO LIQ CROWTHORNE, SHOP 12 CROWTHORNE S/CENTRE, C/O PITT (MAIN) & ARTHUR ROAD, CROWTHORNE A/H MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.97464,'lng':28.08058}},
{'stp':550720,'address':'550720, EGL - WEST, WEST HYBRID, KYALAMI COUNTRY LIQUOR STORE, ERF 10 BOTHASFONTEIN 408, CRN KIPLING & BOVENEYN ST, BARGEQUE AGRICULTURAL HOLDINGS, KYALAMI, South Africa','geo':{'lat':-26.00720,'lng':28.07890}},
{'stp':550808,'address':'550808, EGL - WEST, WEST HYBRID, ARLECHINO COUNTRY CAFE, CNR CEDAR & MAIN ROADS, KYALAMI, #, KYALAMI, South Africa','geo':{'lat':-25.98600,'lng':28.06550}},
{'stp':551140,'address':'551140, EGL - WEST, WEST HYBRID, LEEUWKOP GOLF CLUB, LEEUWKOP ROAD, SUNNINGHILL, 2157, SUNNINGHILL, South Africa','geo':{'lat':-26.00945,'lng':28.06828}},
{'stp':551842,'address':'551842, EGL - WEST, WEST HYBRID, KYALAMI COUNTRY CLUB, MAPLE ROAD, PORTION 109 CALLED BEULIEU OF, FARM WITPOORT NO 409, KYALAMI, South Africa','geo':{'lat':-25.97831,'lng':28.05451}},
{'stp':551908,'address':'551908, EGL - WEST, WEST HYBRID, THE CROC INN, BOX 5897  (blue hill farm nursery), RIVONIA  (main rd r 55 bluehill kyalami), #, KYALAMI, South Africa','geo':{'lat':-25.96660,'lng':28.07950}},
{'stp':552118,'address':'552118, EGL - WEST, WEST HYBRID, DA VINCENZO RESTAURANT, 29 MONTROSE AVENUE, BARBEQUE DOWNS, KYALAMI, KYALAMI, South Africa','geo':{'lat':-26.02543,'lng':28.06669}},
{'stp':552652,'address':'552652, EGL - WEST, WEST HYBRID, RIVONIA RECREATION CLUB, ASHER ROAD, PAULSHOF, SANDTON, SUNNINGHILL, South Africa','geo':{'lat':-26.03012,'lng':28.05844}},
{'stp':553541,'address':'553541, EGL - WEST, WEST HYBRID, TRATTORIA DEL SANTIS, SHOP LG1 THE SQUARE SHOPPING C, CNR LEEUWKOP & NAIVASHA RDS, SUNNIGHILL, SUNNINGHILL, South Africa','geo':{'lat':-26.03210,'lng':28.06310}},
{'stp':604307,'address':'604307, EGL - WEST, WEST HYBRID, 7 ELEVEN LIQUOR MARKET SUNNINGHILL, SEVEN ELEVEN, THE SQUARE CORNER LEEUKOP AND NAVAISHA, ROADS, SUNNINGHILL, South Africa','geo':{'lat':-26.03253,'lng':28.06300}},
{'stp':635059,'address':'635059, EGL - WEST, WEST HYBRID, PRIMI PIATTI DESIGN QUARTER, SHOP 68 DESIGN QUARTER, WILLIAM NICHOL STREET/leslie road, SANTON, FOURWAYS, South Africa','geo':{'lat':-26.03270,'lng':28.01080}},
{'stp':639478,'address':'639478, EGL - WEST, WEST HYBRID, SUNNINGHILL TOPS, SUNNINGHILL SHOPPING CENTRE, STAND 97 SUNNINGHILL SHOP CENTRE, SANDTON, SUNNINGHILL, South Africa','geo':{'lat':-26.03700,'lng':28.07870}},
{'stp':640151,'address':'640151, EGL - WEST, WEST HYBRID, PICK N PAY CHILLI LANE, ERVEN 974+975, SUNNINGHILL EXT35, SUNNINGHILL RETAIL CENTRE, CHILLI LANE SUNNINGHILL, RANDBURG, SUNNINGHILL, South Africa','geo':{'lat':-26.02820,'lng':28.05980}},
{'stp':640268,'address':'640268, EGL - WEST, WEST HYBRID, SUNNINGHILL LIQUORS, SHOP 18 AND 19 SUNNINGHILL, SHOPPING CENTRE CNR TANA & NAIVASHA RD, SUNNINGHILL, SUNNINGHILL, South Africa','geo':{'lat':-26.02550,'lng':28.06670}},
{'stp':642611,'address':'642611, EGL - WEST, WEST HYBRID, MOVIDA, 01 SHOP LNGE, RIVONIA CROSSING, WITKOPPEN ROAD, RIVONIA & ACHTER ROADS, PAULSHOF X28, SUNNINGHILL, South Africa','geo':{'lat':-26.03650,'lng':28.05900}},
{'stp':646903,'address':'646903, EGL - WEST, WEST HYBRID, KYALAMI LIQUORS, 12 SHOP  NUM 12, KYALAMI DOWNS SHOPPING CENTRE, KHAYALAMI BOULEVARD, KYALAMI, South Africa','geo':{'lat':-25.99080,'lng':28.07600}},
{'stp':647468,'address':'647468, EGL - WEST, WEST HYBRID, BEACH BLANKET BOHEMIA, SUNNINGHILL VILLAGE SHOPPING, CENTRE, SHOP NO 1, 97 EDISON CRESCENT, #, SUNNINGHILL, South Africa','geo':{'lat':-26.03780,'lng':28.07960}},
{'stp':647631,'address':'647631, EGL - WEST, WEST HYBRID, PAULSHOF LIQUORS, SHOP 19, CNR WITKOPPEN & STONEHAVEN STREET, CAMBRIDGE CROSSING, SUNNINGHILL, South Africa','geo':{'lat':-26.03570,'lng':28.05130}},
{'stp':651611,'address':'651611, EGL - WEST, WEST HYBRID, DOPPIO ZERO SUNNINGHILL, CNR LEEUWKOP RD AND RIVONIA RD, SHOP 42 CHILLI LANE SHOPPING CENTRE, SUNNINGHILL EXT 143, SUNNINGHILL, South Africa','geo':{'lat':-26.02850,'lng':28.06100}},
{'stp':664197,'address':'664197, EGL - WEST, WEST HYBRID, BOSCO RESTAURANT (NTO), SUNNINGHILL CENTRE, CNR TANA & NAVISHA RD, CNR TANA & NAVISHA RD, SUNNINGHILL, South Africa','geo':{'lat':-26.02557,'lng':28.06682}},
{'stp':665009,'address':'665009, EGL - WEST, WEST HYBRID, LUCA\'S (NTO), RIVONIA CROSSING, ACHTER RD CNR WITKOPPEN, ACHTER RD CNR WITKOPPEN, SUNNINGHILL, South Africa','geo':{'lat':-26.03600,'lng':28.05900}},
{'stp':665011,'address':'665011, EGL - WEST, WEST HYBRID, GRAZAL INDIAN RESTAURANT (NTO), THE SQUARE, CNR LEEWKOP AND NAVISHA RD, CNR LEEWKOP AND NAVISHA RD, SUNNINGHILL, South Africa','geo':{'lat':-26.03251,'lng':28.06310}},
{'stp':665235,'address':'665235, EGL - WEST, WEST HYBRID, TITALINO\'S (NTO), KYALAMI DOWN SHOPPING CENTRE, CNR KYALAMI BOULEVARD & MAIN RD, CNR KYALAMI BOULEVARD & MAIN RD, KYALAMI, South Africa','geo':{'lat':-25.99059,'lng':28.07603}},
{'stp':665244,'address':'665244, EGL - WEST, WEST HYBRID, CEDER BETTING SPORTS BAR & GRILL (N, KYALAMI DOWN SHOPPING CENTRE, CNR KYALAMI BOULEVARD & MAIN RD, CNR KYALAMI BOULEVARD & MAIN RD, KYALAMI, South Africa','geo':{'lat':-25.99059,'lng':28.07603}},
{'stp':665273,'address':'665273, EGL - WEST, WEST HYBRID, THEATRE ON THE TRACK (NTO), MONZA PLACE KYALAMI PARK, MONZA PLACE KYALAMI PARK, MONZA PLACE KYALAMI PARK, KYALAMI, South Africa','geo':{'lat':-25.98897,'lng':28.07442}},
{'stp':685027,'address':'685027, EGL - WEST, WEST HYBRID, KITCHEN BAR, OFF WILLIAM NICOL AND LESLIE AVE, SHOP 5 AND 6 THE DESIGN QUARTER SHOPPING, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.03120,'lng':28.01160}},
{'stp':691475,'address':'691475, EGL - WEST, WEST HYBRID, THE RAJ INDIAN RESTAURANT, CROWNTHORNE SHOPPING CENTRE, CNR NEW ARTHUR AND MAIN STREET, SHOP NO 23 PORTION 377 AND 378, MIDRAND, South Africa','geo':{'lat':-25.97400,'lng':28.07960}},
{'stp':702089,'address':'702089, EGL - WEST, WEST HYBRID, RISTORANTE LA TRINITA, SHOP NO G1A, KYALAMI DOWNS SHOPPING CENTRE, MAIN ROAD KAYLAMI, KYALAMI, South Africa','geo':{'lat':-25.99290,'lng':28.07570}},
{'stp':711139,'address':'711139, EGL - WEST, WEST HYBRID, THE PRIVATE ROOM (NTO), KYALAMI DOWN SHOPPING CENTRE, CNR KYALAMI BOULEVARD & MAIN RD, #, KYALAMI, South Africa','geo':{'lat':-25.99059,'lng':28.07603}},
{'stp':727131,'address':'727131, EGL - WEST, WEST HYBRID, BARNYARD RIVONIA (NTO), RIVONIA CROSSING, WITKOPPEN ROAD, RIVONIA & ACHTER ROADS, PAULSHOF, SUNNINGHILL, South Africa','geo':{'lat':-26.03630,'lng':28.05900}},
{'stp':728584,'address':'728584, EGL - WEST, WEST HYBRID, THE GERMAN CLUB PAULSHOF (NTO), 131 HOLKAM ROAD, PAULSHOF, PAULSHOF, SUNNINGHILL, South Africa','geo':{'lat':-26.02937,'lng':28.03951}},
{'stp':745590,'address':'745590, EGL - WEST, WEST HYBRID, THE VU (NTO), SHOP 49, KYALAMI DOWN, KYALAMI, KYALAMI, South Africa','geo':{'lat':-25.99290,'lng':28.07570}},
{'stp':765195,'address':'765195, EGL - WEST, WEST HYBRID, ADEGA RIVONIA (NTO), RIVONIA CROSSING, #, #, SUNNINGHILL, South Africa','geo':{'lat':-26.03707,'lng':28.05947}},
{'stp':774717,'address':'774717, EGL - WEST, WEST HYBRID, OFF THE GRID (NTO), ERF 969 PORTION 1, CAMBRIDGE EXT 45, PAULSHOF, SUNNINGHILL, South Africa','geo':{'lat':-26.03570,'lng':28.05130}},
{'stp':790372,'address':'790372, EGL - WEST, WEST HYBRID, REMOS MAXIMILLIANO (NTO), WATERFALL CORNER, CNR MAXWELL & WOODMEAD DR, WOODMEAD, MIDRAND, South Africa','geo':{'lat':-26.02169,'lng':28.08997}},
{'stp':790373,'address':'790373, EGL - WEST, WEST HYBRID, LIFE GRAND CAFE (NTO), WATERFALL CORNER, CNR MAXWELL & WOODMEAD DR, WOODMEAD, MIDRAND, South Africa','geo':{'lat':-26.02206,'lng':28.08929}},
{'stp':379866,'address':'379866, EGL - WEST, WEST HYBRID, MOLLY MALONES, 14 SHOP NO, DESIGN AND DECOR CENTRE FORESTR RD, COR SUNSET AVE PINESLOPES FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02021,'lng':28.01440}},
{'stp':390879,'address':'390879, EGL - WEST, WEST HYBRID, CASA BELLA - MONTECASINO (NTO), SHOP NO 58 MONTE CASINO, CORNER WITKOPPEN & WILLIAM NICOL DRIVE, #, FOURWAYS, South Africa','geo':{'lat':-26.02359,'lng':28.01182}},
{'stp':466402,'address':'466402, EGL - WEST, WEST HYBRID, MYTHOS MONTE CASINO (NTO), MONTECASINO BLVD, CNR WILLIAM NICOL AND WITKOPPEN, MIDRAND, FOURWAYS, South Africa','geo':{'lat':-26.01036,'lng':28.00480}},
{'stp':480988,'address':'480988, EGL - WEST, WEST HYBRID, MON PETIT THROBB (NTO), SHOP 2, LONG POINT OFFICE PARK, BRANDFIELD DRIVE, #, FOURWAYS, South Africa','geo':{'lat':-26.02529,'lng':28.01672}},
{'stp':481125,'address':'481125, EGL - WEST, WEST HYBRID, SALSA MEXICAN GRILL (NTO), PINESLOPES CENTRE, CNR WITKOPPEN & STRAIGHT ROADS, #, FOURWAYS, South Africa','geo':{'lat':-26.02324,'lng':28.01621}},
{'stp':545153,'address':'545153, EGL - WEST, WEST HYBRID, EAGLE FALLS SPUR (NTO), Shop U7/12/20, top floor, CNR Fourways BLVD and William Nicol, Fourways Crossing, FOURWAYS, South Africa','geo':{'lat':-26.01600,'lng':28.01000}},
{'stp':550226,'address':'550226, EGL - WEST, WEST HYBRID, BILLY THE BUMS FOURWAYS, ERVEN 1167 & 1167 SHOP 15 LONE, PINESLOPES CONVENIENCE CENTRE, CNR WITKOPPEN RD & THE STRAIGH, FOURWAYS, South Africa','geo':{'lat':-26.02270,'lng':28.01580}},
{'stp':550259,'address':'550259, EGL - WEST, WEST HYBRID, DONATELLAS PIZZA CAFE, SHOP NO 5 MONTE CASINO, WILLIAM NICOL DRIVE & WITKOPPE, RANDBURG, FOURWAYS, South Africa','geo':{'lat':-26.02510,'lng':28.01310}},
{'stp':550980,'address':'550980, EGL - WEST, WEST HYBRID, MONTE CASINO, MONTE CASINO STAND 399 X 3, ON SOUTH EASTERN CORNER WITHKO, PPEN RD & WILLIAM NICOL DRIVE, FOURWAYS, South Africa','geo':{'lat':-26.02485,'lng':28.01386}},
{'stp':551339,'address':'551339, EGL - WEST, WEST HYBRID, IL PAESANO CONTINENTAL CAFE, PORTION 11200, LONEHILL EXT 47, CANTRY WITKOPPEN DEVELOPMENT, FOURWAYS, South Africa','geo':{'lat':-26.02297,'lng':28.01770}},
{'stp':551773,'address':'551773, EGL - WEST, WEST HYBRID, MONTEXO SPUR STEAK RANCH, SHOP 37 MONTE CASINO BUILDING, WILLIAM NICHOL DRIVE, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02510,'lng':28.01310}},
{'stp':551886,'address':'551886, EGL - WEST, WEST HYBRID, THE PALAZZO, Must deliver before 16h00 MONTE, CNR WITKOPPEN & WILLIAM NICOL, RANDBURG, FOURWAYS, South Africa','geo':{'lat':-26.02506,'lng':28.01503}},
{'stp':552085,'address':'552085, EGL - WEST, WEST HYBRID, BOOTLEGGERS FOURWAYS, SHOP L1 & L2 FOURWAYS CROSSING, SHOPPING CENTRE RANDBURG, WITKOPPEN ROAD FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.01720,'lng':28.01060}},
{'stp':552629,'address':'552629, EGL - WEST, WEST HYBRID, LIQUOR CITY LONEHILL, ERF 651, LONEHILL EXT 9, SHOP 102 LONEHILL SHOP CENTRE, FOURWAYS, South Africa','geo':{'lat':-26.01371,'lng':28.02436}},
{'stp':553686,'address':'553686, EGL - WEST, WEST HYBRID, VERDICCHIO REST, SHOP 9 MONTE CASINO GROUND, LEVEL & BASEMENT STAND 399, MAGALIESIG X37 WITKOPEN & WILL, FOURWAYS, South Africa','geo':{'lat':-26.01036,'lng':28.00480}},
{'stp':553693,'address':'553693, EGL - WEST, WEST HYBRID, NU METRO CONTINENTAL LOUNG, GROUND FLOOR,MONTE CASINO, STAND 399, MAGALISIG EXT 37, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.01036,'lng':28.00480}},
{'stp':610014,'address':'610014, EGL - WEST, WEST HYBRID, BEIRA ALTA PORTUGUESE RESTAURANT (N, SHOP 63 MONTE CASINO, #, Johannesburg, FOURWAYS, South Africa','geo':{'lat':-26.01036,'lng':28.00480}},
{'stp':617277,'address':'617277, EGL - WEST, WEST HYBRID, MEAT CO (NTO), MONTE CASINO, C/O WILLIAM NICOL & W, FOURWAYS, Johannesburg, FOURWAYS, South Africa','geo':{'lat':-26.01036,'lng':28.00480}},
{'stp':620482,'address':'620482, EGL - WEST, WEST HYBRID, OCEAN BASKET (MONTE) (NTO), MONTE CASINO, C/O WILLIAM NICOL & W, FOURWAYS, Johannesburg, FOURWAYS, South Africa','geo':{'lat':-26.02540,'lng':28.01410}},
{'stp':635557,'address':'635557, EGL - WEST, WEST HYBRID, DINOS TRATTORIA, ERF NO651 LONEHILL SHOPPING CENTRE, SHOP NO2 BOULEVARD, 22 LONEHILL BOULEVARD,SANDTON, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':637767,'address':'637767, EGL - WEST, WEST HYBRID, BRAZEN HEAD FOURWAYS, SHOP NO 7, LEAPING FROG SHOPPING CENTRE, CNR WILLIAM NICOL DRIVE AND MULBARTON, FOURWAYS, South Africa','geo':{'lat':-26.00981,'lng':28.01240}},
{'stp':639726,'address':'639726, EGL - WEST, WEST HYBRID, MYTHOS (NTO), WILLAIM NICOL ROAD - DESIGN QUARTER, #, #, FOURWAYS, South Africa','geo':{'lat':-26.03273,'lng':28.01000}},
{'stp':641148,'address':'641148, EGL - WEST, WEST HYBRID, TOPS AT PINESLOPES, PINESLOPES TOPS, CNR WITKOPPEN & THE STRAIGHT, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02196,'lng':28.01523}},
{'stp':654576,'address':'654576, EGL - WEST, WEST HYBRID, SUNSQUARE MONTECASINO, MONTECASINO LIFESTYLE CENTRE, BOUNDED BY MONTECASION BOULEVARD, WITKOPPEN ROAD AND WILLIAM NICOL DRIVE, FOURWAYS, South Africa','geo':{'lat':-26.02458,'lng':28.01611}},
{'stp':664186,'address':'664186, EGL - WEST, WEST HYBRID, PARKERS COMEDY CLUB (NTO), SHOP NO:24 NEW LIFESTYLE CENTRE, MONTECASINO BLVD,WITKOPPEN RD & WILLIAM, NICOL DR, ERF:474&475,MAGALIESSIG EXT:37, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':664189,'address':'664189, EGL - WEST, WEST HYBRID, RAJ (NTO), MONTECASINO BOULEVARD, CNR WILLIAM NICOL & WITKOPPEN RD, CNR WILLIAM NICOL & WITKOPPEN RD, FOURWAYS, South Africa','geo':{'lat':-26.01036,'lng':28.00480}},
{'stp':664204,'address':'664204, EGL - WEST, WEST HYBRID, MUGG & BEAN LONEHILL (NTO), LONEHILL VILLAGE, LONEHILL VILLAGE, LONEHILL VILLAGE, FOURWAYS, South Africa','geo':{'lat':-26.01380,'lng':28.02560}},
{'stp':665007,'address':'665007, EGL - WEST, WEST HYBRID, CANTARE (NTO), MONTECASINO BLVD, CNR WILLIAM NICOL AND WITKOPPEN, CNR WILLIAM NICOL AND WITKOPPEN, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':668286,'address':'668286, EGL - WEST, WEST HYBRID, KAI TAI FOURWAYS (NTO), MONTECASINO BOULEVARD, CNR WILLIAM NICOL AND WITKOPPEN ROAD, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02425,'lng':28.01195}},
{'stp':674698,'address':'674698, EGL - WEST, WEST HYBRID, BALLBREAKERS (NTO), CNR SUNSET BLVD AND FOREST ROAD, FOURWAYS VALUE MART, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02029,'lng':28.01439}},
{'stp':685051,'address':'685051, EGL - WEST, WEST HYBRID, BILLY JEAN\'S (NTO), STAND 399 X 3 MONTE CASINO, WITKOPPEN & WILLIAM NICOL DRIVE, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02500,'lng':28.01400}},
{'stp':693580,'address':'693580, EGL - WEST, WEST HYBRID, SOUTHERN SUN MONTE CASINO, CNR WITKOPPEN AND WILLIAM NICOL DR, MONTECASINO COMPLEX, MONTECASINO BOULEVARD, FOURWAYS, South Africa','geo':{'lat':-26.02509,'lng':28.01384}},
{'stp':699760,'address':'699760, EGL - WEST, WEST HYBRID, CITY LODGE FOURWAYS, ERF NO 484, MAGALIESSIG EXTENSION 42, CNR MONTY CASINO BOULEVARD AND, FOURWAYS, South Africa','geo':{'lat':-26.02482,'lng':28.01797}},
{'stp':700927,'address':'700927, EGL - WEST, WEST HYBRID, STONES FOURWAYS (NTO), O SUNSET BOULEVARD EXT, CASINO VIWE RETAIL CENTRE, LONEHILL EXT 61 AND 67 FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02140,'lng':28.01380}},
{'stp':744987,'address':'744987, EGL - WEST, WEST HYBRID, COL\'CACCHIO MONTE CASINO (NTO), MONTE CASINO, ON SOUTH EASTERN CORNER WITHKO, PPEN RD & WILLIAM NICOL DRIVE, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':744989,'address':'744989, EGL - WEST, WEST HYBRID, MIO COL\'CACCHIO MONTE CASINO (NTO), MONTE CASINO PALAZZO/SQUARE, ON SOUTH EASTERN CORNER WITHKO, PPEN RD & WILLIAM NICOL DRIVE, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':748105,'address':'748105, EGL - WEST, WEST HYBRID, LET\'S MEAT (NTO), SHOP LL 43, LONEHILL SHOPPING CENTRE, CNR LONE CLOSE AND LONEHILL BOULEVARD, FOURWAYS, South Africa','geo':{'lat':-26.02054,'lng':28.01901}},
{'stp':751094,'address':'751094, EGL - WEST, WEST HYBRID, 3 SIXTY (NTO), MONTE CASINO BOULEVARD, #, #, FOURWAYS, South Africa','geo':{'lat':-26.02367,'lng':28.01220}},
{'stp':755631,'address':'755631, EGL - WEST, WEST HYBRID, DOPPIO ZERO FOURWAYS (NTO), PINESLOPES SHOPPING CENTRE, #, #, FOURWAYS, South Africa','geo':{'lat':-26.01580,'lng':28.02270}},
{'stp':769021,'address':'769021, EGL - WEST, WEST HYBRID, SHINGARA (NTO), SHANGANI ESTATE, #, #, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':791181,'address':'791181, EGL - WEST, WEST HYBRID, SOCIETY (NTO), CNR WITKOPPEN & MERROW RDS, FOURWAYS, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02734,'lng':28.02116}},
{'stp':791183,'address':'791183, EGL - WEST, WEST HYBRID, BEERHOUSE (NTO), SHOP NO:GF34, PINESLOPES SHOPPING C, CORNER THE STRAIGHT & WITKOPPEN ROADS, LONEHILL, FOURWAYS, South Africa','geo':{'lat':-26.02270,'lng':28.02270}},
{'stp':795871,'address':'795871, EGL - WEST, WEST HYBRID, ROCOMAMAS (NTO), PINESLOPES CENTRE, FOURWAYS, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02270,'lng':28.02270}},
{'stp':795872,'address':'795872, EGL - WEST, WEST HYBRID, Country Meat Butchery (NTO), CNR SUNSET BOULEVARD & FOREST DR, FOURWAYS, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02029,'lng':28.01439}},
{'stp':388355,'address':'388355, EGL - WEST, WEST NEAR, MAKRO RIVERSANDS M27, 4227 ERF NO, RIVERSIDE VIEW EXT 80, NO 7 INCUBATION BOULEVARD, DIEPSLOOT, South Africa','geo':{'lat':-25.96786,'lng':28.02080}},
{'stp':393411,'address':'393411, EGL - WEST, WEST DEF MGR, TA PAULS PLACE, 8334 STAND NO, EXT 5, #, NOT ASSIGNED, South Africa','geo':{'lat':-26.21541,'lng':27.73530}},
{'stp':393430,'address':'393430, EGL - WEST, WEST DEF MGR, OWEN S PUB, 100 PLOT, BEYERS NAUDE DRIVE, RIEFONTEIN HONEYDEW, NOT ASSIGNED, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':316441,'address':'316441, EGL - WEST, WEST HYBRID, STEPPING STONES RESTAURANT T/A OLIV, OLIEVENHOUTPOORT 196 IQ, NO.283 WEST ROAD, HONEYDEW, HONEYDEW, South Africa','geo':{'lat':-26.07150,'lng':27.93495}},
{'stp':388176,'address':'388176, EGL - WEST, WEST HYBRID, RUSTY HOOK, 22 STAND NUMBER, WILGE STREET, HONEYDEW, HONEYDEW, South Africa','geo':{'lat':-26.08011,'lng':27.90510}},
{'stp':390476,'address':'390476, EGL - WEST, WEST HYBRID, BROOKLYN BROTHERS (NTO), SASOL ROODEKRUIN,NO.31 C R SWART RD, CORNER OU KLIP DRIVE, WILRO PARK, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.11644,'lng':27.85090}},
{'stp':473485,'address':'473485, EGL - WEST, WEST HYBRID, PICK N PAY LOCAL CONSTANTIA KLOOF G, JIM FOUCHE STREET, CONSTANTIA KLOOF, #, ROODEPOORT, South Africa','geo':{'lat':-26.13730,'lng':27.90970}},
{'stp':477664,'address':'477664, EGL - WEST, WEST HYBRID, TURN N TENDER THE FALLS (NTO), ERF 100 BROMHOF EXT 54 SHOP NO:S001, BOSKRUIN VILLAGE SHOPPING CENTRE, CNR PRESIDENT FOUCHE & HAWKEN ROADS, ROODEPOORT, South Africa','geo':{'lat':-26.10950,'lng':27.88630}},
{'stp':477793,'address':'477793, EGL - WEST, WEST HYBRID, PIG AND WIGGLE (NTO), 95 RANDRIDGE ROAD, 95 RANDRIDGE ROAD, 95 RANDRIDGE ROAD, RANDBURG, South Africa','geo':{'lat':-26.10230,'lng':27.96130}},
{'stp':477794,'address':'477794, EGL - WEST, WEST HYBRID, SOUL CAF# (NTO), THE RIDGE SHOPPING CENTRE, CNR MOZART & PAUL KRUGER RDS, SHOP 23, HONEYDEW EXT 12, ROODEPOORT, South Africa','geo':{'lat':-26.10060,'lng':27.90620}},
{'stp':489782,'address':'489782, EGL - WEST, WEST HYBRID, PIZZA DEL FORNO HONEYDEW (NTO), SHOP 19&20 THE RIDGE SHOPPING CENTR, CNR PAUL KRUGER & MOZART STREETS, HONEYDEW, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.10023,'lng':27.90645}},
{'stp':490324,'address':'490324, EGL - WEST, WEST HYBRID, Pralina Caf# Grill TA Pizza Del For, Cnr Hendrik Potgieter & Featherbroo,  Peter Rd, #, ROODEPOORT, South Africa','geo':{'lat':-26.09120,'lng':27.87046}},
{'stp':548616,'address':'548616, EGL - WEST, WEST HYBRID, KYA SPORTS BAR, STAND NO, ERF 515 KYA SAND SHOPPING CENTRE, CORNER ELSECAR & BERNIE ROADS, RANDBURG, South Africa','geo':{'lat':-26.02497,'lng':27.94927}},
{'stp':550137,'address':'550137, EGL - WEST, WEST HYBRID, KYA JUNCTION LIQUOR STORE, SHOP 10 KYA JUNCTION SHOP CENTRE, C/O BERNIE & ELSECAR STR, KYA SANDS EXT 42, COSMOS CITY, South Africa','geo':{'lat':-26.02460,'lng':27.94980}},
{'stp':550148,'address':'550148, EGL - WEST, WEST HYBRID, TOPS PALM COURT, PALM COURT SHOPPING CENTRE, C/O J G STRYDOM & CORNELIUS, WELTEVREDEN PARK, RANDBURG, South Africa','geo':{'lat':-26.12602,'lng':27.93177}},
{'stp':550772,'address':'550772, EGL - WEST, WEST HYBRID, MI VAMI HILLFOX RESTAURANT, SHOP 35 LOWER LEVEL, HILLFOXPOWER CENTRE, WELTEVREDENPARK EXT 44, ROODEPOORT, South Africa','geo':{'lat':-26.14000,'lng':27.92050}},
{'stp':550876,'address':'550876, EGL - WEST, WEST HYBRID, LIQUOR CITY HONEY JUNCTION, RADIOKOP L/S STAND 2-3, HONEY JUNCTION SHOPPING CENTRE, JOHN VORSTER RD RADIOKOP, RANDBURG, South Africa','geo':{'lat':-26.10330,'lng':27.92430}},
{'stp':551152,'address':'551152, EGL - WEST, WEST HYBRID, CIRO UMBERTO RESTAURANT, SHOP 14 GROUD FLOOR, GLEN NERINE SHOPPING CENTRE, CNR WITKOPPEN RD AND NERINE DRIVE, RANDBURG, South Africa','geo':{'lat':-26.02480,'lng':27.98960}},
{'stp':551221,'address':'551221, EGL - WEST, WEST HYBRID, LIQUOR CELLAR WILROPARK, 31 CR SWART DRIVE, SHOP 2 WILROPARK SHOPPING CENT, WILROPARK, ROODEPOORT, South Africa','geo':{'lat':-26.11910,'lng':27.85020}},
{'stp':551329,'address':'551329, EGL - WEST, WEST HYBRID, DOSOL LODGE, C/O MULDERSDRIFT & ZWEISS RDS, HONEYDEW, #, ROODEPOORT, South Africa','geo':{'lat':-26.07470,'lng':27.91990}},
{'stp':551578,'address':'551578, EGL - WEST, WEST HYBRID, NATCHEZ SPUR, SHOP NO 23 CONSTANTIA PARK, C/O 14TH AVE & HENDRIK POTGIET, WELTEVREDEN PARK, ROODEPOORT, South Africa','geo':{'lat':-26.14920,'lng':27.92460}},
{'stp':551638,'address':'551638, EGL - WEST, WEST HYBRID, MAKRO ROODEPOORT, PORTION 137 & 138, C/O CHRISTIAAN DE WET & POTGIETER, STRUBENS VALLEY, ROODEPOORT, South Africa','geo':{'lat':-26.12360,'lng':27.90770}},
{'stp':552018,'address':'552018, EGL - WEST, WEST HYBRID, TOPS AT RUIMSIG LIQUOR BOUTIQUE, SHOP NO 20 21 AND 22, CO DOREEN R AND HENDRIK POTGIETER, RUIMSIG, ROODEPOORT, South Africa','geo':{'lat':-26.09420,'lng':27.86900}},
{'stp':552471,'address':'552471, EGL - WEST, WEST HYBRID, BACCHUS LIQUOR BOUTIQUE, PICK AND PAY CENTRE, CNR MCGRATH AND DIRKIE UYS AVENUE, WITPOORTJIE, ROODEPOORT, South Africa','geo':{'lat':-26.14080,'lng':27.83190}},
{'stp':552702,'address':'552702, EGL - WEST, WEST HYBRID, PANORAMA SPORTS CLUB, PAUL KRUGER STREET REMAINDER O, PORTION 2, FARM PANORAMA, ROODEPOORT, South Africa','geo':{'lat':-26.10160,'lng':27.91590}},
{'stp':552771,'address':'552771, EGL - WEST, WEST HYBRID, NEWS CAFE CONSTANTIA, SHOP NO 1, SHOP NO 1, GROUND FLOOR TOWN SQUARE, ROODEPOORT, South Africa','geo':{'lat':-26.13720,'lng':27.91740}},
{'stp':552802,'address':'552802, EGL - WEST, WEST HYBRID, NEW DOGGYS RESTAURANT, 29 WILGE RD, ALSEF, HONEYDEW, MULDERSDRIFT, South Africa','geo':{'lat':-26.07960,'lng':27.90980}},
{'stp':553018,'address':'553018, EGL - WEST, WEST HYBRID, HONEYDEW DISCOUNT LIQUOR STORE, HONEYDEW SHOPPING CENTRE, C/O BLUEBERRY & D F MALAN ROAD, HONEYDEW, RANDBURG, South Africa','geo':{'lat':-26.07900,'lng':27.92600}},
{'stp':553132,'address':'553132, EGL - WEST, WEST HYBRID, ROODEPOORT BUITEKLUB, CNR HENDRIK POTGIETER & PETER, RUIMSIG, ROODEPOORT, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08200,'lng':27.86640}},
{'stp':553736,'address':'553736, EGL - WEST, WEST HYBRID, UNCLE JIMS, C/O JIM FOUCHE & ANTELOPE CRES, CONSTANTIA KLOOF X 12, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.13730,'lng':27.90970}},
{'stp':605766,'address':'605766, EGL - WEST, WEST HYBRID, LIQUORLAND PETER ROAD, SHOP 6, PETER RD RETAIL CENTRE, CNR BEYERS NAUDE DR AND PETER RD, ERF 164 AND 164 TRS JOLLIE EXT 15, RANDBURG, South Africa','geo':{'lat':-26.06270,'lng':27.90210}},
{'stp':624910,'address':'624910, EGL - WEST, WEST HYBRID, PIZZA DEL FORNO (NTO), TOWN SQUARE, C/O HENDRIK POTGIETER, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.09053,'lng':27.86928}},
{'stp':634742,'address':'634742, EGL - WEST, WEST HYBRID, TOPS AT RADIOKOP, CNR PAUL KRUGER AND MOZART STREET, HONEYDEW RIDGE, ROODEPOORT, RANDBURG, South Africa','geo':{'lat':-26.10060,'lng':27.90620}},
{'stp':636039,'address':'636039, EGL - WEST, WEST HYBRID, TOPS AT STRUBENSVALLEY, 331 ERF NO, SHOP  NO 8 & 9,STRUBENSVALLEY, SHOPPING CENTRE, ROODEPOORT, South Africa','geo':{'lat':-26.12330,'lng':27.89920}},
{'stp':641205,'address':'641205, EGL - WEST, WEST HYBRID, CATTLE BARON LITTLE FALLS, ERF 1392, SHOP 31, WEST RAND, RETAIL CROSSING, CORNER HENDRIK, POTGIETER RD & NIC DIEDERICHS BOULEYARD, ROODEPOORT, South Africa','geo':{'lat':-26.11310,'lng':27.89710}},
{'stp':641533,'address':'641533, EGL - WEST, WEST HYBRID, CHECKERS ROODEPOORT 43987, CHECKERS HYPER CENTRE, HENDRIK POTGIETER & GOLF CLUB TERRACE, CONSTANTIA KLOOF, ROODEPOORT, South Africa','geo':{'lat':-26.14710,'lng':27.92230}},
{'stp':646350,'address':'646350, EGL - WEST, WEST HYBRID, EAGLE CANYON COUNTRY CLUB, HONEYDEW MANOR ERF 979, BLUEBERRY AVENUE, HONEYDEW, MULDERSDRIFT, South Africa','geo':{'lat':-26.08310,'lng':27.91060}},
{'stp':646841,'address':'646841, EGL - WEST, WEST HYBRID, WILD EAGLE STEAKRANCH, 474 HOLE IN ONE ROAD, PORTION 160 OF THE FARM RUIMSIG, RUIMSIG, ROODEPOORT, South Africa','geo':{'lat':-26.07740,'lng':27.85850}},
{'stp':647511,'address':'647511, EGL - WEST, WEST HYBRID, JIM AND JERRY DRIVING RANGE, PTN 77 FARM ROODEKRANS 183 10, HOLE IN ONE DRIVE, RUIMSIG, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.07250,'lng':27.85740}},
{'stp':648643,'address':'648643, EGL - WEST, WEST HYBRID, MUGS INN (NTO), CNR 2ND & GOLDMEN STR, FLORIDA, #, ROODEPOORT, South Africa','geo':{'lat':-26.14730,'lng':27.86330}},
{'stp':648688,'address':'648688, EGL - WEST, WEST HYBRID, RUMOURS (NTO), PALM SQUARE SHOPPING CENTRE, CO STRYDOM AND CORNELIUS STR, #, RANDBURG, South Africa','geo':{'lat':-26.12507,'lng':27.93225}},
{'stp':648782,'address':'648782, EGL - WEST, WEST HYBRID, THE IRISH ROCK, ERF 343,BOUNDED BY JOHN VOSTER, CHRISTIAAN DE WET AND OPERA ROADS, RADIOKOP EXT 3, ROODEPOORT, South Africa','geo':{'lat':-26.10500,'lng':27.92360}},
{'stp':651717,'address':'651717, EGL - WEST, WEST HYBRID, LIQUOR CITY HILLFOX, SHOP 2 THE HILLFOX POWER CENTRE, ERF 4278 & 4279 HENDRIK POTGIETER STREET, CNR ALBERT & RHINOCEROS STREETS, ROODEPOORT, South Africa','geo':{'lat':-26.14100,'lng':27.92140}},
{'stp':652847,'address':'652847, EGL - WEST, WEST HYBRID, THE KEG AND EAGLE, SHOP 34, BLOCK B, CNR NIC DIEDERICHS & HENDRIK POTGIETER, RETAIL CROSSING WILGEHEUWEL, ROODEPOORT, South Africa','geo':{'lat':-26.11320,'lng':27.89680}},
{'stp':653710,'address':'653710, EGL - WEST, WEST HYBRID, CLUB 40, 34 GEOFFREY STREET, PORTION 1855, #, ROODEPOORT, South Africa','geo':{'lat':-26.16680,'lng':27.87310}},
{'stp':658790,'address':'658790, EGL - WEST, WEST HYBRID, SUNSET ROCK (NTO), 212 DORREEN STR, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.09532,'lng':27.86828}},
{'stp':666310,'address':'666310, EGL - WEST, WEST HYBRID, CHILLY TRENCH SHELLHOLEMOTH, COR DIEPERINK AND MEYER STREET, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.15824,'lng':27.86721}},
{'stp':668270,'address':'668270, EGL - WEST, WEST HYBRID, FRIENDLY LIQUOR, 34 LOFTUS WEG NOORDHEUWEL, CNR JIM FOUCHE AND PHIL ALLEN STREET, VALLEY CENTRE WELTEVREDEN ERF 966, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.13150,'lng':27.91630}},
{'stp':668868,'address':'668868, EGL - WEST, WEST HYBRID, Jackal Creek Golf Estate (NTO), Boundry Road, Northworld, Randburg, RANDBURG, South Africa','geo':{'lat':-26.05191,'lng':27.92869}},
{'stp':669400,'address':'669400, EGL - WEST, WEST HYBRID, COTTEN CLUB RESTAURANT AND BAR (NTO, 189 RIETFONTEIN, BEYERS NAUDE STREET, HONEYDEW, RANDBURG, South Africa','geo':{'lat':-26.05557,'lng':27.89602}},
{'stp':669402,'address':'669402, EGL - WEST, WEST HYBRID, RUSTY HOOK FLY AND ART LURE CLUB (N, 22 WILGE STREET, HONEYDEW, #, MULDERSDRIFT, South Africa','geo':{'lat':-26.08041,'lng':27.90601}},
{'stp':669466,'address':'669466, EGL - WEST, WEST HYBRID, HONEYDEW BOWLING CLUB (NTO), MULDERSDRIFT RAOD, RANDBURG, #, RANDBURG, South Africa','geo':{'lat':-26.08010,'lng':27.93140}},
{'stp':669467,'address':'669467, EGL - WEST, WEST HYBRID, BERLIN PUB (NTO), HOLDING 40 PLOT 40, VILJOENS STREET, MULDERSDRIFT, MULDERSDRIFT, South Africa','geo':{'lat':-26.06780,'lng':27.84280}},
{'stp':669470,'address':'669470, EGL - WEST, WEST HYBRID, JB\'S CORNER (NTO), SHOP NO LM 073, GROUND FLOOR, CLEARWATER MALL, ROODEPOORT, South Africa','geo':{'lat':-26.12740,'lng':27.90360}},
{'stp':684290,'address':'684290, EGL - WEST, WEST HYBRID, Frosties Pub (NTO), 95 Randridge Rd, Randpark Ridge, Randurg, RANDBURG, South Africa','geo':{'lat':-26.10230,'lng':27.96130}},
{'stp':684379,'address':'684379, EGL - WEST, WEST HYBRID, Ball and All driving range (NTO), corner R512 and R114, Nooitgedacht, Krugersdorp, RANDBURG, South Africa','geo':{'lat':-25.99881,'lng':27.91955}},
{'stp':686398,'address':'686398, EGL - WEST, WEST HYBRID, ACTION SPORTS ROODEPOORT, HILLFOX POWER CENTRE, SHOP NUMBER 336 STAND NUMBER REF 4938, WELTEVREDEN PARK EXTENSION 44, ROODEPOORT, South Africa','geo':{'lat':-26.13840,'lng':27.91990}},
{'stp':694804,'address':'694804, EGL - WEST, WEST HYBRID, RIVER DECK (NTO), NO 35 PETER ROAD, TRES JOLIE, RUIMSIG, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.07890,'lng':27.88130}},
{'stp':700682,'address':'700682, EGL - WEST, WEST HYBRID, DUCK & DIVE (NTO), 21 RIVERBEND ROAD, NORTHRIDING, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.02450,'lng':27.94980}},
{'stp':701115,'address':'701115, EGL - WEST, WEST HYBRID, Chilling Thyme Caf# (NTO), Cnr. Christiaan de Wet &, Dolfyn Road Randpark Ridge, Randburg, RANDBURG, South Africa','geo':{'lat':-26.08970,'lng':27.92871}},
{'stp':702293,'address':'702293, EGL - WEST, WEST HYBRID, FARM CITY LIQUORS, FARM CITY SHOPPING COMPLEX, HENDRIK POTGIETER ROAD AND, CORNER VILJOEN STREET, ROODEPOORT, South Africa','geo':{'lat':-26.06783,'lng':27.85004}},
{'stp':702519,'address':'702519, EGL - WEST, WEST HYBRID, HILLFOX SPORTS BAR, HILLFOX VALUE CENTRE, HENDRIK POTGIETER DRIVE, WELTERVREDEN PARK, ROODEPOORT, South Africa','geo':{'lat':-26.13840,'lng':27.91990}},
{'stp':704918,'address':'704918, EGL - WEST, WEST HYBRID, OCEAN BASKET FEATHERBROOKE (NTO), CORNER OF HENDRIK POTGIETER, & JOHAN ROAD FEATHERBROOKE VILLAGE, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.09150,'lng':27.87180}},
{'stp':704920,'address':'704920, EGL - WEST, WEST HYBRID, ADEGA LITTLE FALLS (NTO), ERF 1392, SHOP 31, WEST RAND, RETAIL CROSSING, CORNER HENDRIK, POTGIETER RD & NIC DIEDERICHS BOULEYARD, ROODEPOORT, South Africa','geo':{'lat':-26.11310,'lng':27.89710}},
{'stp':705029,'address':'705029, EGL - WEST, WEST HYBRID, CHECKERS ALLENS NEK 63357, SHOP 17 ALLENS NEK VALUE MART, CORNER JIM FOUCHE ROAD AND ELAND AVE, BEING ERF 681 ALLENS NEK EXTENSION 19, ROODEPOORT, South Africa','geo':{'lat':-26.13500,'lng':27.91210}},
{'stp':705609,'address':'705609, EGL - WEST, WEST HYBRID, DIE WAENHUIS (NTO), C/O JIM FOUCHE AND, ANTELOPE CRESENT, CONSTANTIA KLOOF, ROODEPOORT, South Africa','geo':{'lat':-26.13730,'lng':27.90970}},
{'stp':718425,'address':'718425, EGL - WEST, WEST HYBRID, SEEMANN\'S LIFESTYLE CROSSING (NTO), CORNER OF HENDRIK POTGIETER, AND NICK DIEDERICS, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.11480,'lng':27.89330}},
{'stp':741215,'address':'741215, EGL - WEST, WEST HYBRID, MAVERICKS SPORTS PUB (NTO), CORNER OF EXNER STREET, & GROBLER STREET, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.15680,'lng':27.86295}},
{'stp':748067,'address':'748067, EGL - WEST, WEST HYBRID, RUIMSIG BOWLING CLUB, CNR HENDRIK POTGIETER &, PETER ROAD, RUIMSIG, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08200,'lng':27.86640}},
{'stp':762547,'address':'762547, EGL - WEST, WEST HYBRID, EAGLES FARE (NTO), WITWATERSRAND BOTANICAL GARDENS, OF MALCOLM ROAD, PORTVIEW, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.08716,'lng':27.84464}},
{'stp':762550,'address':'762550, EGL - WEST, WEST HYBRID, FOOD LOVERS MARKET HILLFOX, HILLFOX POWER CENTRE, ERF 4278 & 4279 HENDRIK POTGIETER, CNR ALBERT AND RHINOCEROS STREET, ROODEPOORT, South Africa','geo':{'lat':-26.14100,'lng':27.92140}},
{'stp':763720,'address':'763720, EGL - WEST, WEST HYBRID, TRES JOLIE, 22 PETER RD, RUIMSIG, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.06855,'lng':27.89419}},
{'stp':772046,'address':'772046, EGL - WEST, WEST HYBRID, KYA SANDS LIQUOR CITY, STAND NO, #, #, RANDBURG, South Africa','geo':{'lat':-26.02830,'lng':27.95100}},
{'stp':772118,'address':'772118, EGL - WEST, WEST HYBRID, CHEERLEADERS (NTO), GLEN NERINE SHOPPING CENTRE, WITKOPPEN AND NERINE DRIVE, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.02480,'lng':27.98960}},
{'stp':774917,'address':'774917, EGL - WEST, WEST HYBRID, NANDOS EAGLES LANDING (NTO), SHOP 16 EAGLES LANDING SHOPPING CEN, CNR CHRISTIAAN DE WET AND SCOTT AVENUE, RADIOKOP, RANDBURG, South Africa','geo':{'lat':-26.08310,'lng':27.91060}},
{'stp':778324,'address':'778324, EGL - WEST, WEST HYBRID, TOPS AT RETAIL CROSSING, 0355 HENDRIK POTGIETER, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.11507,'lng':27.89743}},
{'stp':785004,'address':'785004, EGL - WEST, WEST HYBRID, BALL BREAKERS WELTEVREDEN (NTO), CNR Rubgy RD and Ninth RD, WELTEVREDEN PARK, #, RANDBURG, South Africa','geo':{'lat':-26.13650,'lng':27.93340}},
{'stp':785827,'address':'785827, EGL - WEST, WEST HYBRID, CHECKERS LIQUORSHOP WILROGATE 88705, STAND NO, SHOP 15 WILROGATE SHOPPING CENTRE, CNR SWART AND ONTDEKKERS ROAD, ROODEPOORT, South Africa','geo':{'lat':-26.12583,'lng':27.84847}},
{'stp':797193,'address':'797193, EGL - WEST, WEST HYBRID, LAZER PARK TAVERN, 1010 STAND  NO, LINER AVENUE HONEYDEW, IMPACT INDUSTRIAL, ROODEPOORT, South Africa','geo':{'lat':-26.07924,'lng':27.91312}},
{'stp':797359,'address':'797359, EGL - WEST, WEST HYBRID, HOOTERS ROODEPOORT (NTO), 46 DOREEN ROAD, WILGESPRUIT NO 190, AMAROSA, ROODEPOORT, South Africa','geo':{'lat':-26.09480,'lng':27.86850}},
{'stp':799474,'address':'799474, EGL - WEST, WEST HYBRID, LIQUOR CITY EXPRESS, RUGBY STREET, ERF 4569 SHOP 6 DEVON VALLEY SHOPPING CE, CORNER 9TH AVENUE, ROODEPOORT, South Africa','geo':{'lat':-26.13670,'lng':27.93388}},
{'stp':799663,'address':'799663, EGL - WEST, WEST HYBRID, ROCOMAMAS RUIMSIG (NTO), VILLAGE CENTRE, CNR HENDRIK POTGIETER, RUIMSIG, ROODEPOORT, South Africa','geo':{'lat':-26.09084,'lng':27.87120}},
{'stp':312132,'address':'312132, EGL - WEST, WEST HYBRID, FOOD LOVERS MARKET LIQUOR STORE WES, 120 STAND NUMBER, WESTAGE SHOPPING CENTRE, ONTDEKKERS ROAD, ROODEPOORT, South Africa','geo':{'lat':-26.13920,'lng':27.86270}},
{'stp':314825,'address':'314825, EGL - WEST, WEST HYBRID, PICK N PAY GH05-PICK N PAY LIQUOR S, 117 STAND NO, KILBURN SHOPPING CENTRE, KILBUSN STREET, ROODEPOORT, South Africa','geo':{'lat':-26.15667,'lng':27.88561}},
{'stp':320511,'address':'320511, EGL - WEST, WEST HYBRID, UNIFIED PUB, 1417 STAND NO, 132 GOLDMANS STREET, #, ROODEPOORT, South Africa','geo':{'lat':-26.17810,'lng':27.92959}},
{'stp':385031,'address':'385031, EGL - WEST, WEST HYBRID, ROCOMAMAS - HORIZON VIEW (NTO), HORIZON VIEW SHOPPING CENTRE, CNR SONOP STREET & HOSSACK AVENUE, HORIZON VIEW, ROODEPORT, ROODEPOORT, South Africa','geo':{'lat':-26.14275,'lng':27.86933}},
{'stp':388368,'address':'388368, EGL - WEST, WEST HYBRID, D FORUM RESTAURANT, 3B SHOP NO, NO 27 GOLDMAN STREET, #, ROODEPOORT, South Africa','geo':{'lat':-26.17194,'lng':27.91050}},
{'stp':389872,'address':'389872, EGL - WEST, WEST HYBRID, RADICALS POOL CLUB (NTO), SHOP NO:8, 93 MIMOSA STREET, ROODEPOORT, CBD, South Africa','geo':{'lat':-26.13330,'lng':27.85930}},
{'stp':389924,'address':'389924, EGL - WEST, WEST HYBRID, LIONS DEN, 173 STAND NO, ONTDEKKERS ROAD, HORISON PARK, ROODEPOORT, South Africa','geo':{'lat':-26.14135,'lng':27.87169}},
{'stp':550168,'address':'550168, EGL - WEST, WEST HYBRID, ALPHA LIQUOR STORE, PORTION 1 OF ERF NO : 6, CORNER MAIN REEF ROAD AND WILLEM VAN, REEKUM STREET, ROODEPOORT, South Africa','geo':{'lat':-26.14730,'lng':27.86350}},
{'stp':550438,'address':'550438, EGL - WEST, WEST HYBRID, PORTERHOUSE WESTGATE, ONTDEKKERS,CROUS,PHEASANTRD, WESTGATE SHOPPING CENTRE, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.14000,'lng':27.86050}},
{'stp':550510,'address':'550510, EGL - WEST, WEST HYBRID, BIG TIME LIQ STORE, 54 GOLF CLUB TERRACE, CORNER PIONEER STREET, DISCOVERY, ROODEPOORT, South Africa','geo':{'lat':-26.15930,'lng':27.90470}},
{'stp':550581,'address':'550581, EGL - WEST, WEST HYBRID, LUCKY RESTAURANT, 16 GLENLEA SHOPPING CENTRE, 16 GRANVILLE AVENUE, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.19160,'lng':27.91600}},
{'stp':550669,'address':'550669, EGL - WEST, WEST HYBRID, WESTGATE HYPER LIQUOR, SHOP L46 WESTGATE SHOPPING, ONTDEKKERS ROAD, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.13670,'lng':27.85980}},
{'stp':550763,'address':'550763, EGL - WEST, WEST HYBRID, ROODEPOORT LIQUORLAND, 18a VAN WYK STREET, ROODEPOORT, 1725, ROODEPOORT, South Africa','geo':{'lat':-26.16150,'lng':27.86420}},
{'stp':550905,'address':'550905, EGL - WEST, WEST HYBRID, THE JOKER, SHOP 25 WILROGATE SHOPPING CEN, C/O ONTDEKKERS RD AND C R SWART, WILROGATE, ROODEPOORT, South Africa','geo':{'lat':-26.12680,'lng':27.84700}},
{'stp':550929,'address':'550929, EGL - WEST, WEST HYBRID, TOPS AT FLORIDA JUNCTION, 1212 ERF NO, SHOP NO 9 FLORIDA JUNCTION SHOPPING CENT, BOUNDED BY CHRISTIAAN DE WET, ROODEPOORT, South Africa','geo':{'lat':-26.14860,'lng':27.90170}},
{'stp':551122,'address':'551122, EGL - WEST, WEST HYBRID, PARADISE LIQUOR STORE, 201 MAIN REEF ROAD, LEWISHAM, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.12050,'lng':27.81010}},
{'stp':551168,'address':'551168, EGL - WEST, WEST HYBRID, WITPOORTJIE BOWLING CLUB,  CNR ORANJE AND KOEBERG STREET, WITPOORTJIE EXT 5, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.15430,'lng':27.82480}},
{'stp':551650,'address':'551650, EGL - WEST, WEST HYBRID, UNIFLOR HOTEL, COR SHAMROCK AND MAIN AVENUE, FLORIDA, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.17700,'lng':27.93030}},
{'stp':551680,'address':'551680, EGL - WEST, WEST HYBRID, WITPOORTJIE LIQUOR STORE ARISONA, 1474 CNR TREZONA & PROOT STREET, WITPOORTJIE, #, ROODEPOORT, South Africa','geo':{'lat':-26.15890,'lng':27.81750}},
{'stp':551681,'address':'551681, EGL - WEST, WEST HYBRID, WEST END LIQUOR MARKET, 181 MAIN REEF RD, LUIPAARDSVLEI, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.12070,'lng':27.81190}},
{'stp':552007,'address':'552007, EGL - WEST, WEST HYBRID, ROODEPOORT BOWLING CLUB, VIVIAN RORKE PARK NO 6, GEORGINIA, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.16680,'lng':27.87950}},
{'stp':552060,'address':'552060, EGL - WEST, WEST HYBRID, MARAISBURG BOWLING CLUB, DICK CORLETT PARK, HILWARD AVE, MARAISBURG, ROODEPOORT, South Africa','geo':{'lat':-26.18040,'lng':27.94070}},
{'stp':552132,'address':'552132, EGL - WEST, WEST HYBRID, DISCOVERY ROLBALCLUB, FRED MITCHELLE PARK, TRICHARDTLAAN 4, DISCOVERY, ROODEPOORT, South Africa','geo':{'lat':-26.15510,'lng':27.89030}},
{'stp':552220,'address':'552220, EGL - WEST, WEST HYBRID, LION PARK LIQUOR STORE, STAND NO, FARMNIETGEDACT 533 CO PRETORIA 1, #, DIEPSLOOT, South Africa','geo':{'lat':-25.97440,'lng':27.94920}},
{'stp':552405,'address':'552405, EGL - WEST, WEST HYBRID, MOTOR SPORT LIQUOR, 37 ERF 749 SHOP, DISCOVERY SHOPPING COMPLEX, DISCOVERY, ROODEPOORT, South Africa','geo':{'lat':-26.15640,'lng':27.89480}},
{'stp':552496,'address':'552496, EGL - WEST, WEST HYBRID, THE LIQUOR LOCATION, SHOP NO 4, C/O HEBBARD & KATROL STREETS, ROBERTVILLE EXT 10, ROODEPOORT, South Africa','geo':{'lat':-26.18570,'lng':27.91120}},
{'stp':552514,'address':'552514, EGL - WEST, WEST HYBRID, JIMMY S LIQUOR FLORIDA, 6 STAND NO, LEGAL WISE BUILDING, CNR GOLDMAN STR AND 2ND AVE, ROODEPOORT, South Africa','geo':{'lat':-26.17170,'lng':27.90810}},
{'stp':552623,'address':'552623, EGL - WEST, WEST HYBRID, FOUNDERS RESTAURANT FLORIDA, 4 GORDON ROAD, FLORIDA NORTH, FLORIDA, ROODEPOORT, South Africa','geo':{'lat':-26.16860,'lng':27.93150}},
{'stp':552763,'address':'552763, EGL - WEST, WEST HYBRID, CONSTANTIA LIQUOR STORE, 17 KATHLEEN STREET, FLORIDA LAKE, #, ROODEPOORT, South Africa','geo':{'lat':-26.18200,'lng':27.91140}},
{'stp':553039,'address':'553039, EGL - WEST, WEST HYBRID, DISCOUNT LIQUOR STORE, 1692 ERF 1689, SHOP- NO 01 18, CNR VAN WYK AND HANDEL  STREETS, ROODEPOORT, South Africa','geo':{'lat':-26.16100,'lng':27.86260}},
{'stp':553043,'address':'553043, EGL - WEST, WEST HYBRID, MONUMENT LIQUOR WAREHOUSE, cnr SHANNON / VOORTREKKER RDS, MONUMENT, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.11150,'lng':27.80440}},
{'stp':553081,'address':'553081, EGL - WEST, WEST HYBRID, SHOOTERS POOL CLUB, 139 ONTDEKKERS ROAD, JOHAN VAN ROOYEN HYPERMART, HELDERKRUIN EXT 9, ROODEPOORT, South Africa','geo':{'lat':-26.13865,'lng':27.86671}},
{'stp':553130,'address':'553130, EGL - WEST, WEST HYBRID, FLORIDA NOORD ROLBALKLUB, PARK LANE AND ALEXANDER STREET, FLORIDA NORTH, #, ROODEPOORT, South Africa','geo':{'lat':-26.17100,'lng':27.93060}},
{'stp':553417,'address':'553417, EGL - WEST, WEST HYBRID, LIQUOR MAN LIQUOR STORE, 5TH AVENUE, FLORIDA LAKE, #, ROODEPOORT, South Africa','geo':{'lat':-26.17610,'lng':27.91260}},
{'stp':553429,'address':'553429, EGL - WEST, WEST HYBRID, LINDHAVEN LIQUOR STORE, 635 ERF NO, SHOP 1 LINDHAVEN CENTRE, 91 PROGRESS ROAD, ROODEPOORT, South Africa','geo':{'lat':-26.14450,'lng':27.85490}},
{'stp':553546,'address':'553546, EGL - WEST, WEST HYBRID, PITSTOP LIQUORS, 202 ERF NO, SHOP  NO 01 JOFRAN CENTRAL NO 83, HAMBERG ROAD CNR WANDEL STREET, ROODEPOORT, South Africa','geo':{'lat':-26.17350,'lng':27.88850}},
{'stp':553578,'address':'553578, EGL - WEST, WEST HYBRID, THE DANCE PALACE, ERF 1863 NO 336 ONTDREKKERS ROAD, DISCOVERY EXTENSION 3, #, ROODEPOORT, South Africa','geo':{'lat':-26.14770,'lng':27.89360}},
{'stp':560327,'address':'560327, EGL - WEST, WEST HYBRID, SHOPRITE LIQUORSHOP FLORIDA 94394, STAND NO, 22 THIRD AVENUE, FLORIDA, ROODEPOORT, South Africa','geo':{'lat':-26.17301,'lng':27.90948}},
{'stp':561119,'address':'561119, EGL - WEST, WEST HYBRID, WESTERN RACKET AND WORKOUT CENTRE, 1334 DE WET STREET, EXTENSION 6, DISCOVERY, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.15440,'lng':27.88600}},
{'stp':580863,'address':'580863, EGL - WEST, WEST HYBRID, PICK N PAY PRINCESS CROSSING, ERWE 33/34/35 PRINCESS Ext 35, ONDEKKERS ROAD, BOUNDED BY HELDERPRINS, ROODEPOORT, South Africa','geo':{'lat':-26.13000,'lng':27.84810}},
{'stp':620544,'address':'620544, EGL - WEST, WEST HYBRID, FAT BOYS 2 (NTO), MIMOSA STREET, HELDERKRUIN, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.13323,'lng':27.85937}},
{'stp':630022,'address':'630022, EGL - WEST, WEST HYBRID, WITBEECK LIQUORS, 30 STUMKE ST CNR VREDENHOEK ST, WITPOORTJIE, #, ROODEPOORT, South Africa','geo':{'lat':-26.14740,'lng':27.82880}},
{'stp':630123,'address':'630123, EGL - WEST, WEST HYBRID, OOM SE PLAAS, 24 FIFTH STREET, MARAISBURG, FLORIDA, ROODEPOORT, South Africa','geo':{'lat':-26.17778,'lng':27.93963}},
{'stp':637363,'address':'637363, EGL - WEST, WEST HYBRID, TOPS AT FLORIDA, CNR GOLDMAN  & 9TH AVENUE, FLORIDA EXT, FLORIDA, ROODEPOORT, South Africa','geo':{'lat':-26.17710,'lng':27.92370}},
{'stp':637370,'address':'637370, EGL - WEST, WEST HYBRID, INTERNATIONAL LIQUORS, 11 KITE STREET, HORISON, #, ROODEPOORT, South Africa','geo':{'lat':-26.14136,'lng':27.87985}},
{'stp':639404,'address':'639404, EGL - WEST, WEST HYBRID, SPARKLE RESTURANT, 18A VAN WYK STREET, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.16160,'lng':27.86420}},
{'stp':639845,'address':'639845, EGL - WEST, WEST HYBRID, KENMARE BOTTLE STORE, SHOP NO 11 TERENURE AVENUE, KENMARE, MOGALE, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.11290,'lng':27.81450}},
{'stp':646894,'address':'646894, EGL - WEST, WEST HYBRID, WEST PEAK SPUR, 120 OUTDEKKERS RD, WESTGATE SHOPPING CENTRE, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.13920,'lng':27.86270}},
{'stp':648642,'address':'648642, EGL - WEST, WEST HYBRID, TRUMPS RESTAURANT WESTGATE (NTO), SHOP NO 66, wESTGATE SHOPPING CENTRE, ONTDEKKERS, ROODEPOORT, South Africa','geo':{'lat':-26.14730,'lng':27.86330}},
{'stp':648644,'address':'648644, EGL - WEST, WEST HYBRID, LIONS DEN (NTO), 173 ONTDEKKERS, HORISON PARK, #, ROODEPOORT, South Africa','geo':{'lat':-26.14133,'lng':27.87145}},
{'stp':648710,'address':'648710, EGL - WEST, WEST HYBRID, DIE HUGENOTE (NTO), FLORA CENTRE, CNR ONTDEKKERS AND CONRAD STR, FLORIDA HILLS, ROODEPOORT, South Africa','geo':{'lat':-26.16665,'lng':27.92714}},
{'stp':648952,'address':'648952, EGL - WEST, WEST HYBRID, FALCONS NEST RESTAURANT, ERF 1296 THORA COURT SHOPPING, CENTRE CNR KITE AND NERON STREET, HORISON EXT 1, ROODEPOORT, South Africa','geo':{'lat':-26.14080,'lng':27.88010}},
{'stp':649957,'address':'649957, EGL - WEST, WEST HYBRID, WITPOORTJIE LIQUOR STORE, 40 WITPOORTJIE CENTRE ERF 218, CNR GENERAL PIENAAR AVENUE, AND GREGOR STREET, ROODEPOORT, South Africa','geo':{'lat':-26.13300,'lng':27.83450}},
{'stp':652028,'address':'652028, EGL - WEST, WEST HYBRID, MALAS SPORTS BAR, ERF 742, 13 GREY STREET, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.12090,'lng':27.81170}},
{'stp':653783,'address':'653783, EGL - WEST, WEST HYBRID, TOTO\'S SPORTS BAR (NTO), SHOP 16 BEACON ISLE SHOPPING CENTRE, CNR ONTDEKKERS & BEACON ROAD, FLORIDA, ROODEPOORT, South Africa','geo':{'lat':-26.16886,'lng':27.93007}},
{'stp':657728,'address':'657728, EGL - WEST, WEST HYBRID, LIQUOR CITY ONTDEKKERS, CNR ONTDEKKERS AND GORDON RD, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.17222,'lng':27.90857}},
{'stp':659057,'address':'659057, EGL - WEST, WEST HYBRID, MEAT AND EAT (NTO), CORNER OF ONDEKKERS AND, CRANE STREET HORISON, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.16150,'lng':27.86420}},
{'stp':666094,'address':'666094, EGL - WEST, WEST HYBRID, KEG AND LEGEND, SHOP 57, THE VILLAGE AT HORIZON SHOPPING CENTRE, BOUNDED BY SONOP HOSSACK AND VAN, ROODEPOORT, South Africa','geo':{'lat':-26.14330,'lng':27.86990}},
{'stp':666213,'address':'666213, EGL - WEST, WEST HYBRID, THE BURG (NTO), SHOP 1 DEKKERS OAK CENTRE, ONDEKKERSPARK, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.14810,'lng':27.89163}},
{'stp':666370,'address':'666370, EGL - WEST, WEST HYBRID, MPUMA KOLONI WS PTY LTD TA SUNSHINE, CNR ELECTRON AND MAIN REEF ROAD, ERF 111 112 113 114 AND 115 NELICOE CENT, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.15660,'lng':27.86870}},
{'stp':666438,'address':'666438, EGL - WEST, WEST HYBRID, PIONEER LIQUOR STORE, 00480 shop no, #, PIONEER COURT ERF NO 15, ROODEPOORT, South Africa','geo':{'lat':-26.15487,'lng':27.86163}},
{'stp':666709,'address':'666709, EGL - WEST, WEST HYBRID, JAKES LIQUOR MASTERS, 56 HENDRIK POTGIETER ROAD, #, FLORIDA NORTH, ROODEPOORT, South Africa','geo':{'lat':-26.16720,'lng':27.93770}},
{'stp':669426,'address':'669426, EGL - WEST, WEST HYBRID, CMR GOLF CLUB (NTO), MARAISBURG ROAD, MARAISBURG, #, ROODEPOORT, South Africa','geo':{'lat':-26.18967,'lng':27.93774}},
{'stp':669430,'address':'669430, EGL - WEST, WEST HYBRID, SMURFYS PUB AND GRILL (NTO), SHOP NO 3, KLOOF CENTRE, C/O KLOOF / ONTDEKKERS ROAD, PRINCESS, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.12620,'lng':27.83020}},
{'stp':669465,'address':'669465, EGL - WEST, WEST HYBRID, FAT BOYS INN (NTO), C/O RUBEN & GEFFEN STR, MINDALORE, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.12772,'lng':27.82823}},
{'stp':676137,'address':'676137, EGL - WEST, WEST HYBRID, CHECKERS HORIZON VIEW 57146, 36 CNR SONOP STREET, VAN SANTEN DRIVE AND HOSSACK AVENUE, HORIZON VIEW HORIZON VIEW SHOPPING CENTR, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.14380,'lng':27.86770}},
{'stp':686763,'address':'686763, EGL - WEST, WEST HYBRID, TOWN LODGE ROODEPOORT, CONSTANTIA BOULEVARD, ERF 984, CONSTANTIA KLOOF EXT 25, ROODEPOORT, South Africa','geo':{'lat':-26.15320,'lng':27.92290}},
{'stp':691633,'address':'691633, EGL - WEST, WEST HYBRID, UNIFIED LIQUOR STORE, 132 GOLDMAN STREET, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.17780,'lng':27.92940}},
{'stp':694014,'address':'694014, EGL - WEST, WEST HYBRID, PIZZA DEL FORNO (NTO), SHOP 4+5, FLORA CENTRE SHOPPING CEN, CNR ONDEKKERS & CONRAD ROADS, FLORIDA NORTH, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.14730,'lng':27.86330}},
{'stp':702124,'address':'702124, EGL - WEST, WEST HYBRID, PICK N PAY FLORIDA, SHOP 4-15 FOR A CENTRE, ERF/STAND 319 FLORIDA NORTHON THE, CORNER OF ONTDEKKERS ROAD AND CONRAD, ROODEPOORT, South Africa','geo':{'lat':-26.16800,'lng':27.92770}},
{'stp':716177,'address':'716177, EGL - WEST, WEST HYBRID, APPLEBITE ROADHOUSE (NTO), 22 KATHLEEN STREET, FLORIDA, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.17200,'lng':27.91140}},
{'stp':721284,'address':'721284, EGL - WEST, WEST HYBRID, FAT CATS RESTAURANT (NTO), C/O BARRAT & COPPINGER STREET, FACTORIA, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.11815,'lng':27.80853}},
{'stp':729602,'address':'729602, EGL - WEST, WEST HYBRID, CITY LIQUORS STORE (NTO), NO 10 MOUTON STREET, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.14990,'lng':27.87850}},
{'stp':739475,'address':'739475, EGL - WEST, WEST HYBRID, FLORIDA LAKE CANOE CLUB (NTO), EISTEDDFOD STREET, FLORIDA LAKE, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.17998,'lng':27.90291}},
{'stp':749341,'address':'749341, EGL - WEST, WEST HYBRID, PICK N PAY GF69 PICK N PAY LS GOLDM, GOLD MAN STREET, NUMBER 12, ERF 47 48 66 67  68, ROODEPOORT, South Africa','geo':{'lat':-26.17030,'lng':27.90800}},
{'stp':753022,'address':'753022, EGL - WEST, WEST HYBRID, The Liquor Connoisseur (NTO), Cnr Barnard St & Cockroft Rd, Honey Hills, Roodepoort, ROODEPOORT, South Africa','geo':{'lat':-26.14305,'lng':27.89210}},
{'stp':758999,'address':'758999, EGL - WEST, WEST HYBRID, 4 TH STREET BOTTLE STORE, CORNER HULL STREET AND FOURTH AVENU, PORTION 5 OF ERF 187, FLORIDA, ROODEPOORT, South Africa','geo':{'lat':-26.17550,'lng':27.91120}},
{'stp':764099,'address':'764099, EGL - WEST, WEST HYBRID, THE PORTERHOUSE FLORIDA JUNCTION, 1212 STAND, SHOP 20 AND 21, FLORIDA JUNCTION SHOPPING CENTRE, ROODEPOORT, South Africa','geo':{'lat':-26.14880,'lng':27.90180}},
{'stp':768289,'address':'768289, EGL - WEST, WEST HYBRID, SUNSHINE LIQUOR PLAZA, 70 HOOFD STREET, BEING ERF 1945, #, ROODEPOORT, South Africa','geo':{'lat':-26.16247,'lng':27.87191}},
{'stp':774939,'address':'774939, EGL - WEST, WEST HYBRID, NANDOS HORIZON VIEW (NTO), VILLAGE AT HORIZON VIEW SHOPPING CE, SONOP STREET, HORIZON VIEW, ROODEPOORT, South Africa','geo':{'lat':-26.16150,'lng':27.86420}},
{'stp':777773,'address':'777773, EGL - WEST, WEST HYBRID, CMR DRIVING RANGE (NTO), PORTION 167. NO:149 OF THE FARM PAA, SPENCER ROAD,, MARAISBURG, ROODEPOORT, South Africa','geo':{'lat':-26.18967,'lng':27.93774}},
{'stp':778046,'address':'778046, EGL - WEST, WEST HYBRID, PRINCE OF THIEVES, ERF 1117 PORTION 01, UNIT 9 - 10 FLORIDA PARK SHOPPING CENTRE, CORNER OF D F MALAN AND JAN SMUTS DRIVE, ROODEPOORT, South Africa','geo':{'lat':-26.16380,'lng':27.91720}},
{'stp':785526,'address':'785526, EGL - WEST, WEST HYBRID, CLIVES LIQUOR, 25 ADOLPHUS STREET, ROODEPOORT, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.16068,'lng':27.86876}},
{'stp':789133,'address':'789133, EGL - WEST, WEST HYBRID, THE LOCATION PUB, KATRO STREET, ERF 339 SHOP 3 ROBERTVILLE CENTRE, CORNER HEBBARD EXTENSION 10, ROODEPOORT, South Africa','geo':{'lat':-26.18564,'lng':27.91110}},
{'stp':798191,'address':'798191, EGL - WEST, WEST HYBRID, TJ S LIQUOR STORE, STAND NO, ERF 1094 NO 1 LOUIS STREET, #, ROODEPOORT, South Africa','geo':{'lat':-26.14836,'lng':27.89217}},
{'stp':308789,'address':'308789, EGL - WEST, WEST HYBRID, TOPS AT ROBERT BROOM, STAND NUMBER, SHOP NUMBER 16, VALLEY SHOPPING CENTRE, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08229,'lng':27.80502}},
{'stp':383563,'address':'383563, EGL - WEST, WEST HYBRID, PICCOLO FALILI KITCHEN (NTO), SHOP 24 CORNER BOOM DRIVE & VAN OOR, NOORHEUWEL EXT 17, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08266,'lng':27.80464}},
{'stp':385846,'address':'385846, EGL - WEST, WEST HYBRID, FEATHERBROOKE ESTATE CLUBHOUSE & RE, PORTION 35 OF ERF 965, FEATHERBROOK ESTATE,NO 35 EAGLE ROAD, FEATHERBROOK ESTATE, EXT 1 KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.07604,'lng':27.83994}},
{'stp':388544,'address':'388544, EGL - WEST, WEST HYBRID, GEAR DOWN (NTO), GATE NO: 9, LANSERIA INTL. AIRPORT, LANSERIA AIRPORT, EXT 1, LANSERIA ERF: 182 & 183, MULDERSDRIFT, South Africa','geo':{'lat':-25.93780,'lng':27.92640}},
{'stp':389092,'address':'389092, EGL - WEST, WEST HYBRID, FOGERTYS RESTAURANT AND MUSIC BAR (, 9 TERENURE STREET, KENMARE 1739 SHOP 1, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.11283,'lng':27.81436}},
{'stp':390936,'address':'390936, EGL - WEST, WEST HYBRID, AARYA SILVERSTAR (NTO), SHOP 5 SILVERSTAR CASINO COMPLEX, PTN 39 AND 302 OF THE FARM ROODEKRAN 183, R28 KRUGERSDORP/PRETORIA ROAD, MOGALE CI, MULDERSDRIFT, South Africa','geo':{'lat':-26.06778,'lng':27.82771}},
{'stp':392341,'address':'392341, EGL - WEST, WEST HYBRID, THE ROCKCOD COUNTRY RESTAURANT (NTO, 560 HOLE IN ONE ROAD, BEING PORTION 189 OF FARM RUIMSIG 265 IQ, RUIMSIG, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08723,'lng':27.86781}},
{'stp':392526,'address':'392526, EGL - WEST, WEST HYBRID, CHARLIE\'S CLUBHOUSE (NTO), ERF:7, SHOP NO:2, NO:9 MASTROOSBERG, NOORDHEUWEL, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08718,'lng':27.78738}},
{'stp':466093,'address':'466093, EGL - WEST, WEST HYBRID, Dulce Caf# Featherbrook Estate (NTO, Featherbrook Country Estate, Muldersdrift, Krugersdorp, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.07604,'lng':27.83993}},
{'stp':466873,'address':'466873, EGL - WEST, WEST HYBRID, Col#cacchio Silverstar Casino (NTO), Erf 642 Homes Haven, Off the R28 Pretoria Road, Muldersdrift, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06740,'lng':27.82640}},
{'stp':550099,'address':'550099, EGL - WEST, WEST HYBRID, ROMALDO ITALIAN RESTAURANT, PLOT 41 BOLAND STREET, CNR BEYERS NAUDE STREET, SONNENDAL, RANDBURG, South Africa','geo':{'lat':-26.05594,'lng':27.89806}},
{'stp':550254,'address':'550254, EGL - WEST, WEST HYBRID, CEDAR COUNTRY GUEST HOUSE, HOLDING 20 BUSH ROAD, C/O R28 HIGHWAY, CHANCLIFF, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.07490,'lng':27.80130}},
{'stp':550273,'address':'550273, EGL - WEST, WEST HYBRID, HEROES PUB AND GRUB, ERF 33, 130 CAROL SILVERFIELDS, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.11870,'lng':27.81800}},
{'stp':550306,'address':'550306, EGL - WEST, WEST HYBRID, GOLDEN WEST LIQUORS, 181 COMMISSIONER STR, BURGERSHOOP, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09780,'lng':27.75880}},
{'stp':550347,'address':'550347, EGL - WEST, WEST HYBRID, RENOSTERSPRUIT DRANKWINKEL, RIVERSIDE ESTATES, HOLDINGS 59 - PELINDABA ROAD, LANSERIA, MULDERSDRIFT, South Africa','geo':{'lat':-25.89870,'lng':27.88870}},
{'stp':550917,'address':'550917, EGL - WEST, WEST HYBRID, NOORD HEWEL COUNTRY CLUB, PORTION 183 OF FARM, PAARDEKRAAL, KRGUERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08070,'lng':27.79120}},
{'stp':551111,'address':'551111, EGL - WEST, WEST HYBRID, SHUMBA VALLEY LODGE AND CONFERENCE, R 512 PELINDABA ROAD, LANSERIA, #, MULDERSDRIFT, South Africa','geo':{'lat':-25.95870,'lng':27.91940}},
{'stp':551191,'address':'551191, EGL - WEST, WEST HYBRID, ALSIDA LIQUOR STORE, PORTION 73 NOOITGEDACHT FARM, OLD PRETORIA /KRUGERSDORP RD, KRUGERSDORP ROAD, MULDERSDRIFT, South Africa','geo':{'lat':-26.00130,'lng':27.91940}},
{'stp':551245,'address':'551245, EGL - WEST, WEST HYBRID, KHOSA SPORTS CLUB, STAN FIEDMAN SPORTS GROUNDS, MONUMENT EXT 1, FIEDMAN COOPER STR KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10610,'lng':27.80330}},
{'stp':551527,'address':'551527, EGL - WEST, WEST HYBRID, DIRT RYDERS ADVENTURE PARK, NO 185 KATHERINE STREET, NORTHLANDS, LANSERIA, MULDERSDRIFT, South Africa','geo':{'lat':-25.95640,'lng':27.90880}},
{'stp':551615,'address':'551615, EGL - WEST, WEST HYBRID, BARN OWL RESTAURANT, HOEWE 3, SUNRELLA LANDBOUHOEWES, KRUGERSDORP, MULDERSDRIFT, South Africa','geo':{'lat':-25.94500,'lng':27.93140}},
{'stp':551771,'address':'551771, EGL - WEST, WEST HYBRID, LIQUOR CITY WEST KRUGERSDORP, NO 79 RUSTENBURG ROAD, WEST KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09810,'lng':27.74520}},
{'stp':551865,'address':'551865, EGL - WEST, WEST HYBRID, LIQUOR CITY RUIMSIG, 83 SHOP 24 ERF, WILLOW BROOK EXTENSION 12, RUIMSIG, ROODEPOORT, South Africa','geo':{'lat':-26.09140,'lng':27.87190}},
{'stp':551904,'address':'551904, EGL - WEST, WEST HYBRID, ROBERT BROOM BLUE BOTTLE STORE, SHOP NO 1&2 NOORDHEUWEL MALL, 110 VAN OORDT STREET, NOORDHEUWEL X11, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08270,'lng':27.80210}},
{'stp':552507,'address':'552507, EGL - WEST, WEST HYBRID, RAND en DAL TOPS, 44 RANT EN DAL WINKELSENTRUM, CNR CECIL KNIGHT AND NIGHTINALE, RANT EN DAL, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.07973,'lng':27.77988}},
{'stp':552586,'address':'552586, EGL - WEST, WEST HYBRID, KRUGERSDORP BOWLING CLUB, DOWN STREET, KENMARE, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10990,'lng':27.80700}},
{'stp':552899,'address':'552899, EGL - WEST, WEST HYBRID, TOPS AT LANSERIA SPAR, SHOP NO 06 AND 07, HERTFORD JUNCTION PELINDABA RD, PORTION 212 OF THE FARM NOOITGEDACHT, MULDERSDRIFT, South Africa','geo':{'lat':-25.97510,'lng':27.92300}},
{'stp':552925,'address':'552925, EGL - WEST, WEST HYBRID, WESTWOOD ARMS, SHOP 02, MONUMENT MALL, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10920,'lng':27.80400}},
{'stp':552981,'address':'552981, EGL - WEST, WEST HYBRID, WEST RAND UNION CLUB, 8 OCKERSE STREET, KRUGERSDORP, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10387,'lng':27.77336}},
{'stp':553120,'address':'553120, EGL - WEST, WEST HYBRID, REMBRANDT LIQUOR STORE, 11 RUSTENBURG RD, KRUGERSDORP WEST, 1739, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09870,'lng':27.75210}},
{'stp':553154,'address':'553154, EGL - WEST, WEST HYBRID, IDOL PUB AND GRILL, C/O TREZONA AND VAN LILL, MINDALORE, WITPOORTJIE, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.14190,'lng':27.82590}},
{'stp':553159,'address':'553159, EGL - WEST, WEST HYBRID, OUKLIP LIQUOR STORE, SHOP NO 2, C/O OUKLIP & C R SWART DRIVE, ROODEKRANS, ROODEPOORT, South Africa','geo':{'lat':-26.10840,'lng':27.85500}},
{'stp':553317,'address':'553317, EGL - WEST, WEST HYBRID, CASALINGA RESTAURANT, GEDEELTE 113 VAN DIE PLAAS, RIETFONTEIN 189 IQ, KRUGERSDORP, MULDERSDRIFT, South Africa','geo':{'lat':-26.04440,'lng':27.87210}},
{'stp':553344,'address':'553344, EGL - WEST, WEST HYBRID, KRUGERSDORP GOLF CLUB, PORTION 13 OF PTN OF THE SOUTH, WESTERN PTN OF FARM PAARDEKRAA, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08100,'lng':27.78480}},
{'stp':553465,'address':'553465, EGL - WEST, WEST HYBRID, LIQUOR CELLAR, STAND 1003, 202 OUKLIP ROAD, HELDERKRUIN, ROODEPOORT, South Africa','geo':{'lat':-26.12370,'lng':27.87520}},
{'stp':553582,'address':'553582, EGL - WEST, WEST HYBRID, HERBERG HOTEL, NO 39 COMMISSIONER STRAAT, KRUGERSDORP, 1740, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10220,'lng':27.77290}},
{'stp':553730,'address':'553730, EGL - WEST, WEST HYBRID, RIETFONTEIN LIQUOR STORE, PORTION 141 OF PORTION 139, 189 RIETFONTEIN, KRUGERSDORP, MULDERSDRIFT, South Africa','geo':{'lat':-26.03230,'lng':27.87680}},
{'stp':561258,'address':'561258, EGL - WEST, WEST HYBRID, NYANAS INN, PLOT 92, GREATER LANSERIA, MOGALE CITY, MULDERSDRIFT, South Africa','geo':{'lat':-25.92680,'lng':27.92240}},
{'stp':606886,'address':'606886, EGL - WEST, WEST HYBRID, LIQUORLAND ZANDSPRUIT, SHOP NO 17, ZANDSPRUIT VALUE CENTRE, C/O BEYERS NAUDE DRIVE AND PETER ROAD, MULDERSDRIFT, South Africa','geo':{'lat':-26.05830,'lng':27.90150}},
{'stp':616256,'address':'616256, EGL - WEST, WEST HYBRID, DIE ANKER (NTO), KRUGERSDORP WEST, #, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.09248,'lng':27.76941}},
{'stp':624545,'address':'624545, EGL - WEST, WEST HYBRID, KRUGERSDORP CLUB (NTO), 66 OCKERTSE STREET, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10108,'lng':27.77118}},
{'stp':632714,'address':'632714, EGL - WEST, WEST HYBRID, DROS KRUGERSDORP, SHOP 5 & 5A HERITAGE SQUARE, C/R CECIL KNIGHT & VILJOEN STREETS, KRUGERSDORP NORTH, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08470,'lng':27.77960}},
{'stp':632728,'address':'632728, EGL - WEST, WEST HYBRID, HELDERKRUIN OVERLAND LIQUORS, 1010 STAND NO, 15 WESTWAY SHOPPING CENTRE, 200 OUKLIP DRIVE, ROODEPOORT, South Africa','geo':{'lat':-26.12380,'lng':27.87430}},
{'stp':636710,'address':'636710, EGL - WEST, WEST HYBRID, LIQUOR CITY HERITAGE SQUARE LIQUOR, 232 CNR VILJOEN & CECIL KNIGHT STR, KRUGERSDORP NORTH, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08500,'lng':27.77960}},
{'stp':638870,'address':'638870, EGL - WEST, WEST HYBRID, KRUGERSDORP GAME RESERVE, KRUGERSDORP GAME RESERVE, FARM WATERVAL 174, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10620,'lng':27.72410}},
{'stp':639611,'address':'639611, EGL - WEST, WEST HYBRID, IDLE WINDS, 68 DOORNRANDJIE, ON R511, #, MULDERSDRIFT, South Africa','geo':{'lat':-25.87190,'lng':28.01370}},
{'stp':640750,'address':'640750, EGL - WEST, WEST HYBRID, SILVER STAR CASINO, ERF 642 HOMES HAVEN,, OFF THE R28 PRETORIA ROAD, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06858,'lng':27.82903}},
{'stp':648683,'address':'648683, EGL - WEST, WEST HYBRID, TONYS SPAGETTI GRILL KRUGERSDORP (N, HERRITAGE SQUARE, CNR CECIL KNIGHT AND VILJOEN STR, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08810,'lng':27.78140}},
{'stp':648684,'address':'648684, EGL - WEST, WEST HYBRID, STEAK SILVERSTAR CASINO (NTO), CNR 28 AND HENDRIK POTGIETER, MULDERSDRIFT, #, MULDERSDRIFT, South Africa','geo':{'lat':-26.05840,'lng':27.90170}},
{'stp':649762,'address':'649762, EGL - WEST, WEST HYBRID, THE PYRAMID POOL AND SNOOKER LOUNGE, CASCADE SHOPP CENTRE, SHOP NO 17 CNR VICTORIA & CASCADE RD, LITTLEFALLS EXT 1, ROODEPOORT, South Africa','geo':{'lat':-26.11777,'lng':27.88848}},
{'stp':659213,'address':'659213, EGL - WEST, WEST HYBRID, BLAIR ATHOLL (NTO), LANSERIA ROAD, #, #, MULDERSDRIFT, South Africa','geo':{'lat':-25.90631,'lng':27.92080}},
{'stp':660806,'address':'660806, EGL - WEST, WEST HYBRID, COCO PALMS (NTO), RANGE VIEW SHOPPING CENTRE, ROBERT BROOM DRIVE, and NOORDEN, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.09377,'lng':27.82714}},
{'stp':664605,'address':'664605, EGL - WEST, WEST HYBRID, WILDERNESS PONTA DOSOL 2 BOTTLE STO, PORTION 14, FARM LINLEY 528 JQ, NEAR LANSERIA AIRPORT, MULDERSDRIFT, South Africa','geo':{'lat':-25.93720,'lng':27.90270}},
{'stp':667293,'address':'667293, EGL - WEST, WEST HYBRID, TWENTY 10 PUB AND DINER, SHOP NIMBER 151, MAIN REEF ROAD, LEWISHAM, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.12100,'lng':27.81440}},
{'stp':669432,'address':'669432, EGL - WEST, WEST HYBRID, MILKWOOD PLACE RESTAURANT (NTO), STAND NO 64, 101 ROODE STREET, BURGERSHOOP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10150,'lng':27.75680}},
{'stp':669434,'address':'669434, EGL - WEST, WEST HYBRID, TREFF RESTAURANT (NTO), RISSOCK SHOPPING CENTRE, RISSOCK SHOPPING CENTRE, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10384,'lng':27.77619}},
{'stp':669435,'address':'669435, EGL - WEST, WEST HYBRID, CAMELOT (NTO), 71 OCKERSE STREET, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10394,'lng':27.77316}},
{'stp':672726,'address':'672726, EGL - WEST, WEST HYBRID, TOPS OUKLIP, 110 OUKLIP ROAD  SHOP NO 16, WILROKRANS SHOPPING CENTRE, CORNER OF GRAPHITE ROAD ERF 1023, ROODEPOORT, South Africa','geo':{'lat':-26.11250,'lng':27.86070}},
{'stp':685105,'address':'685105, EGL - WEST, WEST HYBRID, CHECKERS LIQUORSHOP NOORDHEUWEL 925, CNR ROBERT BROOM AND LUD HERCH STR, SHOP G8 AND G9 NOORDHEUWEL SHOPPING CENT, NOORDHEUWEL, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08990,'lng':27.81760}},
{'stp':700720,'address':'700720, EGL - WEST, WEST HYBRID, THE HERTFORD COUNTRY HOUSE (NTO), PLOT 9, CORNER OF MALIIBONGWE AND, ELANDSDRIFT ROAD LANSERIA, RANDBURG, South Africa','geo':{'lat':-25.97276,'lng':27.92110}},
{'stp':707307,'address':'707307, EGL - WEST, WEST HYBRID, AMBROZIO (NTO), SHOP 1& 2 ANTHOS CENTRE, CNR VOORTREKKER & VAN OCTROT STREET, MONUMENT -KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10060,'lng':27.79710}},
{'stp':707308,'address':'707308, EGL - WEST, WEST HYBRID, TEASPOON & TANKARD (NTO), Plot 122, Rietfontein, C/O Beyers Naude & Diepsloot Road,, Muldersdrift, 1747, MULDERSDRIFT, South Africa','geo':{'lat':-26.02698,'lng':27.86815}},
{'stp':710122,'address':'710122, EGL - WEST, WEST HYBRID, WIESENHOF LANSERIA, FIRST FLOOR MAIN TERMINAL, BUILDINH LANSERIA INTERNATIONAL AIRPORT, SITUATED ON THE FARM SWARTKOP 530, MULDERSDRIFT, South Africa','geo':{'lat':-25.93390,'lng':27.92600}},
{'stp':729657,'address':'729657, EGL - WEST, WEST HYBRID, Jenny\'s Pub (NTO), Plot 66, C/O Hendrik Potgieter and R28, Muldersdrift, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06860,'lng':27.82900}},
{'stp':739473,'address':'739473, EGL - WEST, WEST HYBRID, ELEPHANT & FRIENDS HELDERKRUIN (NTO, C/O OUKLIP ROAD, AND MIRAGE DRIVE HELDERKRUIN, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.12234,'lng':27.87400}},
{'stp':747583,'address':'747583, EGL - WEST, WEST HYBRID, LIME LITE, 60 RUSTENBURG ROAD, KRUGERSDORP WEST, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09820,'lng':27.74500}},
{'stp':747837,'address':'747837, EGL - WEST, WEST HYBRID, PICK N PAY LIQUORSTORE THE FALLS GC, CNR OF HENDRIK POTGIETER AND JOHAN, WILGEHEUWEL, EXTENSION 57, ROODEPOORT, South Africa','geo':{'lat':-26.10950,'lng':27.88630}},
{'stp':749312,'address':'749312, EGL - WEST, WEST HYBRID, LIQUOR AT CASCADES, 10 SHOP NO, CASCADES AND WATERFALLS STREET, WATERFALLS CASCADES ROADS, ROODEPOORT, South Africa','geo':{'lat':-26.11780,'lng':27.88820}},
{'stp':760047,'address':'760047, EGL - WEST, WEST HYBRID, LANSERIA AIRPORT (NTO), MAIN TERMINAL, LANSERIA AIRPORT, SITUATED ON THE FARM SWARTKOP 530, MULDERSDRIFT, South Africa','geo':{'lat':-25.93370,'lng':27.92610}},
{'stp':763708,'address':'763708, EGL - WEST, WEST HYBRID, FEATHER FALLS SQUARE TOPS LIQUOR ST,  STAND NO, ERF 778 SHOP 2, FEATHER FALLS SQUARE SHOPPING CENTRE, MULDERSDRIFT, South Africa','geo':{'lat':-26.06730,'lng':27.83460}},
{'stp':769664,'address':'769664, EGL - WEST, WEST HYBRID, THE GRILL SHOP NOORDHEUWEL (NTO), CNR ROBERT BROOM AND LUD HERCH STRE, #, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.08990,'lng':27.81760}},
{'stp':775356,'address':'775356, EGL - WEST, WEST HYBRID, Billy G Silverstar (NTO), ERF 642 HOMES HAVEN, OFF THE R28 PRETORIA ROAD, MULDERSDRIFT, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06740,'lng':27.82640}},
{'stp':777013,'address':'777013, EGL - WEST, WEST HYBRID, LIZZIE S PLACE, 0022 FURROW ROAD PLOT, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.06613,'lng':27.83690}},
{'stp':791891,'address':'791891, EGL - WEST, WEST HYBRID, PUBLICITY SILVERSTAR (NTO), PORTION 302 OF FARM ROODEKRANS, SILVERSTAR CASINO COMPLEX OFF R28, KRUGERSDORP/PRETORIA ROAD, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06740,'lng':27.82640}},
{'stp':791893,'address':'791893, EGL - WEST, WEST HYBRID, SPUR SILVER ARROW (NTO), SHOP NO 4 SILVERSTAR CASINO, ERF6-42 HOMES HAVEN ENTRANCE OFF THE R28, PRETORIA ROAD, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06740,'lng':27.82640}},
{'stp':791895,'address':'791895, EGL - WEST, WEST HYBRID, PARKERS SILVERSTAR (NTO), ERF 642 HOMES HAVEN,, OFF THE R28 PRETORIA ROAD, MULDERSDRIFT, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06740,'lng':27.82640}},
{'stp':794587,'address':'794587, EGL - WEST, WEST HYBRID, MOSCOW LIQUOR MARKET, STAND NO, SHOP NO 1 COMBODIA CRESCENT 2, #, RANDBURG, South Africa','geo':{'lat':-26.03817,'lng':27.92306}},
{'stp':794706,'address':'794706, EGL - WEST, WEST HYBRID, AMAJAK LIQUOR, 60 STAND NO, BEYERS NAUDE DRIVER, #, HONEYDEW, South Africa','geo':{'lat':-26.06335,'lng':27.90617}},
{'stp':797024,'address':'797024, EGL - WEST, WEST HYBRID, CHATTERS (NTO), RANT EN DAL SHOPPING CENTRE, CECIL KNIGHT STREET, RANT EN DAL, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07876,'lng':27.77973}},
{'stp':797025,'address':'797025, EGL - WEST, WEST HYBRID, THE DINER (NTO), SHOP 1& 2 ANTHOS CENTRE, CNR VOORTREKKER & VAN OCTROT STREET, MONUMENT -KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10060,'lng':27.79710}},
{'stp':798974,'address':'798974, EGL - WEST, WEST HYBRID, CAMBRIDGE COSMO CITY, 214 ERF, COSMO CITY CNR SOUTH AFRICA DRIVE, UNITED STATES OF AMERICA AVENUE, COSMOS CITY, South Africa','geo':{'lat':-26.01785,'lng':27.93722}},
{'stp':388727,'address':'388727, EGL - WEST, WEST HYBRID, SUNNINGHILL PUB (NTO), 25 TANA STREET, SUNNINGHILL SHOPPING CENTRE, ERF 971, SUNNINGHILL EXTENTION, SUNNINGHILL, South Africa','geo':{'lat':-26.02563,'lng':28.06632}},
{'stp':390228,'address':'390228, EGL - WEST, WEST HYBRID, SAVANNAH SANDS CLUB (NTO), THE CLUBHOUSE SAVANNAH SANDS, ERF; GALLOWAY AVENUE, CORNBILL DRIVE, DOUGLASDALE EXT 114, DOUGLASDALE, FOURWAYS, South Africa','geo':{'lat':-26.04474,'lng':27.99126}},
{'stp':391611,'address':'391611, EGL - WEST, WEST HYBRID, ROSTO - KYALAMI (NTO), SHOP 47.1, KYALAMI, CNR SHOPPING, CENTRE, CNR MAIN & PITTS ROAD, KYALAMI EXT 3, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.98425,'lng':28.07530}},
{'stp':392168,'address':'392168, EGL - WEST, WEST HYBRID, MIO COLCACCHIO PIZZERIA - WARERFALL, NO:1 WATERFALL CORNER SHOPPING CENT, SHOP NO:9 CORNER MAXWELL, JUKSKEI VIEW, EXTENTION 51, BEING PORTION 737 OF ERF 1, SUNNINGHILL, South Africa','geo':{'lat':-26.02239,'lng':28.08872}},
{'stp':483029,'address':'483029, EGL - WEST, WEST HYBRID, RIBS AND STEAK (NTO), DOUGLASDALE SHOPPING CENTRE, CNR DOUGLAS AND LESLIE, CNR DOUGLAS AND LESLIE, FOURWAYS, South Africa','geo':{'lat':-26.03960,'lng':27.99340}},
{'stp':491380,'address':'491380, EGL - WEST, WEST HYBRID, BOYWORKS DRINKS AND EVENTS, 267 STAND NO, BATTLESHIP JOHANN AND PRINTECH STREET, ROODEPOORT, HONEYDEW, South Africa','geo':{'lat':-26.07342,'lng':27.91378}},
{'stp':546903,'address':'546903, EGL - WEST, WEST HYBRID, Free Range (NTO), 42 WITKOPPEN STREET, RUSTIC TIMBER CENTRE, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.02450,'lng':27.94980}},
{'stp':550002,'address':'550002, EGL - WEST, WEST HYBRID, THE DOME AT NORTHGATE, NORTHUMBERLAND AND OLIEVENHOUT, NORTHGATE EXTENSION 18, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.06270,'lng':27.94350}},
{'stp':550291,'address':'550291, EGL - WEST, WEST HYBRID, GATHERING O THE CLANS, SHOP 1 /2 DOUGLASDALE SHOPPING, DOUGLASDALE EXT 85, SANDTON, RANDBURG, South Africa','geo':{'lat':-26.03960,'lng':27.99340}},
{'stp':551587,'address':'551587, EGL - WEST, WEST HYBRID, GOLD REEF TOPS, KILBURN SHOPPING CENTRE, NO 117 KILBURN STREET, DISCOVERY, ROODEPOORT, South Africa','geo':{'lat':-26.15650,'lng':27.88600}},
{'stp':552609,'address':'552609, EGL - WEST, WEST HYBRID, THE CLUB HOUSE, RIVERGLADES ESTATE, JUWEEL STREET, JUKSKEIPARK, RANDBURG, South Africa','geo':{'lat':-26.02940,'lng':27.98300}},
{'stp':552872,'address':'552872, EGL - WEST, WEST HYBRID, SUBWAY 8, SHOP 13, DEVON VALLEY SHOPPING CENTRE, WELTEVREDENPARK, ROODEPOORT, South Africa','geo':{'lat':-26.13650,'lng':27.93340}},
{'stp':639286,'address':'639286, EGL - WEST, WEST HYBRID, ACTION SPORTS FOURWAYS, UNIT 8, NORTHLANDS, PRODUCTION PARK, EPSOM AVENUE, NORTH RIDING, RANDBURG, South Africa','geo':{'lat':-26.03450,'lng':27.95090}},
{'stp':648551,'address':'648551, EGL - WEST, WEST HYBRID, PICK N PAY DOUGLASDALE, ERF 1328,DOUGLASDALE EXT 85, SHOP 11A,DOUGLASDALE SHOPPING CENTRE, CNR DOUGLASDALE DRIVE & LESLEY AVENUE, RANDBURG, South Africa','geo':{'lat':-26.03830,'lng':27.99390}},
{'stp':655452,'address':'655452, EGL - WEST, WEST HYBRID, COL\'CACCHIO NORTHGATE (NTO), WITKOPPEN ROAD, FOURWAYS, #, RANDBURG, South Africa','geo':{'lat':-26.06310,'lng':27.94330}},
{'stp':674666,'address':'674666, EGL - WEST, WEST HYBRID, HOP SCHOTCH AND BARREL, SHOP NO 23 HONEYRIDGE, HONEYRIDGE SHOPPING CENTRE, #, RANDBURG, South Africa','geo':{'lat':-26.09397,'lng':27.94088}},
{'stp':676704,'address':'676704, EGL - WEST, WEST HYBRID, TOPS AT KINGFISHER, SHOP NO 2 FOURWAYS, SHOPPING CENTRE KINGFISHER DRIVE, #, RANDBURG, South Africa','geo':{'lat':-26.02877,'lng':28.00220}},
{'stp':684201,'address':'684201, EGL - WEST, WEST HYBRID, BARON WITKOPPEN, PLOT 81, WITKOPPEN NO 194, REG DIV, RANDBURG, South Africa','geo':{'lat':-26.02790,'lng':27.97820}},
{'stp':684395,'address':'684395, EGL - WEST, WEST HYBRID, Chobe Sands Clubhouse (NTO), Chobe Sands Complex, C/o Douglas and Alexander roads, Douglasdale, RANDBURG, South Africa','geo':{'lat':-26.03960,'lng':27.99340}},
{'stp':695137,'address':'695137, EGL - WEST, WEST HYBRID, Dakota Spur Northgate (NTO), NORTHGATE SHOPPING CENTRE, NORTHUMBERLAND ROAD, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.06010,'lng':27.94740}},
{'stp':709679,'address':'709679, EGL - WEST, WEST HYBRID, HOGSHEAD DOUGLASDALE - DO NOT CALL, ERF 2077 SHOP 29, DOUGLASDALE VILLAGE CORNER LESILE, AVE EXT 131 REGION 3 DOUGLASDALE, RANDBURG, South Africa','geo':{'lat':-26.04010,'lng':27.99440}},
{'stp':710166,'address':'710166, EGL - WEST, WEST HYBRID, THROBBING STRAWBERRY (NTO), SHOP 1 DOUGLASDALE VILLAGE, CORNER OF LESLEY AND DOUGLASDALE, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.03840,'lng':27.99390}},
{'stp':717455,'address':'717455, EGL - WEST, WEST HYBRID, OCEAN BASKET NORTHGATE (NTO), SHOP 273, NORTHGATE SHOPPING CENTRE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.06010,'lng':27.94740}},
{'stp':724712,'address':'724712, EGL - WEST, WEST HYBRID, INYATI SANDS CLUBHOUSE (NTO), INYATI STAND NO 45, LESLIE ROAD, DOUGLASDALE, RANDBURG, South Africa','geo':{'lat':-26.03980,'lng':27.99340}},
{'stp':729605,'address':'729605, EGL - WEST, WEST HYBRID, LIQUOR DISCOUNT STORE (NTO), HOOFDESTRAAT 70, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.16240,'lng':27.87180}},
{'stp':744985,'address':'744985, EGL - WEST, WEST HYBRID, MIGUELS CAF# (NTO), CNR LEEUWKOP & KIKUYU RD, THE CORE SHOPPING CENTRE, SUNNINGHILL, SUNNINGHILL, South Africa','geo':{'lat':-26.01694,'lng':28.06490}},
{'stp':754366,'address':'754366, EGL - WEST, WEST HYBRID, THE GRILL SHOP, 396 STAND NO, SHOP 16B NORTHLANDS DECO PARK, CORNER NEW MARKET AND WITKOCORNER NEW MA, RANDBURG, South Africa','geo':{'lat':-26.04050,'lng':27.95760}},
{'stp':765538,'address':'765538, EGL - WEST, WEST HYBRID, THE WAKEFORD, STAND NO, ERF 1616 AND 1617 SHOP 18 THE CORE SC, CNR LEEUWKOP AND KIKUY RD, SUNNINGHILL, South Africa','geo':{'lat':-26.02150,'lng':28.03899}},
{'stp':794519,'address':'794519, EGL - WEST, WEST HYBRID, PICOLINOS, 42 WITKOPPEN STREET, RUSTIC TIMBER CENTRE, #, RANDBURG, South Africa','geo':{'lat':-26.02292,'lng':27.99506}},
{'stp':311751,'address':'311751, EGL - WEST, WEST HYBRID, PRIME GRILL STAKEHOUSE (NTO), CORNER TULBACH & TAMBOTIE STREET, CULLEMBORG PARK, RANDFONTEIN, #, RANDFONTEIN, South Africa','geo':{'lat':-26.01737,'lng':28.00030}},
{'stp':312497,'address':'312497, EGL - WEST, WEST HYBRID, OCEAN BASKET DAINFERN (NTO), CNR WILLIAM NICOL DRIVE AND, BROADACRES AVENUE, DAINFERN, #, FOURWAYS, South Africa','geo':{'lat':-25.99298,'lng':28.01412}},
{'stp':358003,'address':'358003, EGL - WEST, WEST HYBRID, PEDRO PORTIA CIGAR AND CHAMPANGNE L, CNR CEDAR ROAD AND WILLOW AVENUE, ERF NUMBER 000862, FOURWAYS, #, FOURWAYS, South Africa','geo':{'lat':-26.01798,'lng':28.00079}},
{'stp':389877,'address':'389877, EGL - WEST, WEST HYBRID, GAZAL - SUNNINGHILL (NTO), SHOP NO:13, GROUND FLOOR, SQUARE SHOPPING CENTRE NAISHA ROAD,, LEEKOP ROAD,ERF:1219 EXT:75, SUNNINGHILL, SUNNINGHILL, South Africa','geo':{'lat':-26.03253,'lng':28.06324}},
{'stp':390086,'address':'390086, EGL - WEST, WEST HYBRID, BLACK TONGUE (NTO), ERF:1789, SHOP NO:3, BROADACRES DRI, FOURWAYS EXTENSION 33, DAINFERN VALLEY SHOPPING CENTRE, FOURWAYS, South Africa','geo':{'lat':-25.99155,'lng':28.00980}},
{'stp':390103,'address':'390103, EGL - WEST, WEST HYBRID, OCEAN BASKET- CEDAR SQUARE (NTO), SHOP NO:U13.14, UPPER LEVEL,CEDAR S, CORNER CEDAR WOAD & WILLOW AVENUE,, WITKOPPEN EXT:58, FOURWAYS, RANDBURG, FOURWAYS, South Africa','geo':{'lat':-26.01725,'lng':27.99846}},
{'stp':390168,'address':'390168, EGL - WEST, WEST HYBRID, COPPERLAKE-BROADACRES (NTO),  ERF 1, SHOPNO.2&3 PHASE 2 BROADACR, CORNER CEDAR  (R552) & VALLEYROADS, NEEDWOOD, BROADACRES, FOURWAYS, South Africa','geo':{'lat':-26.00230,'lng':27.98510}},
{'stp':390202,'address':'390202, EGL - WEST, WEST HYBRID, PRIME GRILL WINE CLUB (NTO), SHOP U3.13 & U3.13A, CEDAR SHOPPING CENTRE,CNR CEDAR RD &, WILLOW AVE, WITKOPPEN EXT:8, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.01830,'lng':28.00040}},
{'stp':390203,'address':'390203, EGL - WEST, WEST HYBRID, KONG ROAST (NTO), WITKOPPEN EXT:58 & ERF:918 & 919 WI, SHOP NO:U3,4,U3,6B,CEDAR SQUARE SHOPPING, CENTRE,CNR CEDAR RD&WILLOW AVE,FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.01722,'lng':28.00031}},
{'stp':390215,'address':'390215, EGL - WEST, WEST HYBRID, DROS LONE HILL, LL43 SHOP NO, LONE HILL SHOPPING CENTRE LONE CLOSE, 22 22 LONE HILL BOULEVARD LONE HILL, FOURWAYS, South Africa','geo':{'lat':-26.01351,'lng':28.02487}},
{'stp':390287,'address':'390287, EGL - WEST, WEST HYBRID, FISHMONGER-DOUGLASDALE (NTO), SHOP NO.13 THE BUZZ SHOPPING CENTRE, CORNER WITKOPPEN ROAD AND NERINE PLACE, WITKOPPEN, FOURWAYS, South Africa','geo':{'lat':-26.02370,'lng':27.99150}},
{'stp':390436,'address':'390436, EGL - WEST, WEST HYBRID, ESCONDIDO (NTO), SHOP NO.U5 UPPER LEVEL, CEDAR SHOPPING CENTRE,CNR CEDAR RD &, WILLOW AVE, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.01725,'lng':27.99846}},
{'stp':390439,'address':'390439, EGL - WEST, WEST HYBRID, RIM & RUBBER (NTO), SHOP 7,8A AND 8B, THE BUZZ SHOPPING, CORNER OF WITKOPPEN AND DOUGLAS STREETS, FOURWAYS, SANDTON, FOURWAYS, South Africa','geo':{'lat':-26.02370,'lng':27.99150}},
{'stp':391046,'address':'391046, EGL - WEST, WEST HYBRID, CHICAGOS PIANO BAR FOURWAYS, STAND NO, WATERFORD CENTRE, CORNER WITKOPPEN AND DOUGLAS ROAD, FOURWAYS, South Africa','geo':{'lat':-26.02475,'lng':27.98882}},
{'stp':392097,'address':'392097, EGL - WEST, WEST HYBRID, PEARL HARBOUR (NTO), ERF 651, SHOP NO LL46, LONE HILL SHOPPING CENTRE, EXTENSION 9, #, FOURWAYS, South Africa','geo':{'lat':-26.01402,'lng':28.02631}},
{'stp':392826,'address':'392826, EGL - WEST, WEST HYBRID, V\'S BISTRO FOURWAYS MALL (NTO), CORNER WITKOPPEN ROAD AND WILLIAM, NICOL DRIVE, FOURWAYS, #, FOURWAYS, South Africa','geo':{'lat':-26.01845,'lng':28.00772}},
{'stp':392848,'address':'392848, EGL - WEST, WEST HYBRID, HUMBLE HARRY\'S CAFE (NTO), SHOP NO: 48 THE LEAPING FROG SHOPPI, CNR WILLIAM NICOL DRIVE & MILBARTON RD, SANDTON, FOURWAYS, South Africa','geo':{'lat':-26.01018,'lng':28.01229}},
{'stp':393321,'address':'393321, EGL - WEST, WEST HYBRID, CHIMICHURRI RESTAURANT (NTO), ERF 1168, SHOP NO. GF21, CORNER THE, RETAIL SECTION, PINESLOPES SHOPPING CEN, LONEHILL EXT 54, FOURWAYS, South Africa','geo':{'lat':-26.02155,'lng':28.01505}},
{'stp':393437,'address':'393437, EGL - WEST, WEST HYBRID, NIKOS COALGRILL GREEK (NTO), NO:51 WITKOPPEN & STRAIGHT, ERF 1167 & 1265, #, FOURWAYS, South Africa','geo':{'lat':-26.02161,'lng':28.01497}},
{'stp':461087,'address':'461087, EGL - WEST, WEST HYBRID, ADRENALINE CAF# (NTO), Cnr Sunset Boulevard & Forest Rd, Shop 21 Fourways value mart, Fourways, FOURWAYS, South Africa','geo':{'lat':-26.02030,'lng':28.01440}},
{'stp':471122,'address':'471122, EGL - WEST, WEST HYBRID, PICK  N PAY DAINFERNDQUARE GC79, STAND NO, CNR OF BROADCRES & WILLIAM NICOLE DRIVE, ERF 2609 INTERSECTIO, RANDBURG, South Africa','geo':{'lat':-25.99267,'lng':28.01525}},
{'stp':471130,'address':'471130, EGL - WEST, WEST HYBRID, AMICI THE WILLIAM (NTO), THE WILLIAM COMPLEX, CNR OF WILLAIM NICOL AND BROADACRES DRIV, DAINFERN, FOURWAYS, South Africa','geo':{'lat':-25.99620,'lng':28.01651}},
{'stp':471980,'address':'471980, EGL - WEST, WEST HYBRID, Pizza Hut Cedar (NTO), CEDAR SQUARE SHOP CENTRE, CNR CEDAR & WILLOW ROAD, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.01830,'lng':28.00040}},
{'stp':477712,'address':'477712, EGL - WEST, WEST HYBRID, PAPACHINOS BROADACES (NTO), BROADACRES SHOPPING CENTRE, BROADACRES SHOPPING CENTRE, BROADACRES SHOPPING CENTRE, FOURWAYS, South Africa','geo':{'lat':-26.00040,'lng':27.98390}},
{'stp':477725,'address':'477725, EGL - WEST, WEST HYBRID, TURN N TENDER DAINFERN (NTO), SHOP 70 DAINFERN SQUARE SHOPPING CE, CNR OF BROADACRES & WILLIAM NICOL DRIVE, DAINFERN, FOURWAYS STAND 2609 EXT 6, FOURWAYS, South Africa','geo':{'lat':-25.99320,'lng':28.01390}},
{'stp':477791,'address':'477791, EGL - WEST, WEST HYBRID, COL\'CACCHIO DAINFERN (NTO), CORNER OF BROADACRES DRIVE, AND WILLIAM NICOL DRIVE, CORNER OF BROADACRES DRIVE AND WILLIAM N, FOURWAYS, South Africa','geo':{'lat':-25.99320,'lng':28.01390}},
{'stp':483028,'address':'483028, EGL - WEST, WEST HYBRID, MASH (NTO), BUZZ SHOPPING CENTRE, CNR WITKOPPEN AND DOUGLAS, CNR WITKOPPEN AND DOUGLAS, FOURWAYS, South Africa','geo':{'lat':-26.02370,'lng':27.99150}},
{'stp':483062,'address':'483062, EGL - WEST, WEST HYBRID, NYC (NTO), GLEN NERINE SHOPPING CENTRE, WITKOPPEN AND NERINE DRIVE, WITKOPPEN AND NERINE DRIVE, FOURWAYS, South Africa','geo':{'lat':-26.02480,'lng':27.98960}},
{'stp':487431,'address':'487431, EGL - WEST, WEST HYBRID, THE BARON DAINFERN SQUARE (NTO), SHOP 50 DAINFERN SQUARE SHOPPING, CNR OF BROADACRES & WILLIAM NICOL DRIVE, DAINFERN, FOURWAYS ERF 2609 EXT 5, FOURWAYS, South Africa','geo':{'lat':-25.99298,'lng':28.01412}},
{'stp':546866,'address':'546866, EGL - WEST, WEST HYBRID, Steyn City Club House (NTO), 1 Club House Lane, Cedar Road, Steyn City, DAINFERN, RANDBURG, South Africa','geo':{'lat':-25.98276,'lng':27.98335}},
{'stp':550996,'address':'550996, EGL - WEST, WEST HYBRID, THE SANDTON INDABA HOTEL, PTN OF FARM ZEVENFONTEIN 40, WILLIAM NICOL DRIVE, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.00160,'lng':28.01270}},
{'stp':551248,'address':'551248, EGL - WEST, WEST HYBRID, LA TOSCANA, STAND 399,SHOP62, MONTE CASINO, CNR WITKOPPEN & WILLIAM NICOL, FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02510,'lng':28.01310}},
{'stp':551795,'address':'551795, EGL - WEST, WEST HYBRID, LAPAMANZI RESTAURANT, FOURWAYS GARDENS CENTRE, CNR URANIUM & BUSH WILLOW AVE., FOURWAY GARDENS., RANDBURG, South Africa','geo':{'lat':-26.00820,'lng':28.00280}},
{'stp':552390,'address':'552390, EGL - WEST, WEST HYBRID, LIQUOR CITY BROADACRES, BROADACRES SHOP/CENTRE, CNR VALLEY & CEDAR ROADS, BROADACRES RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.00047,'lng':27.98299}},
{'stp':552395,'address':'552395, EGL - WEST, WEST HYBRID, LIQUOR CITY GLEN NERINE, SHOP 1A GLEN NERINE SHOPPING C, C/P NERINE & WITKOPPEN ROADS, DOUGLASDALE, RANDBURG, South Africa','geo':{'lat':-26.02420,'lng':27.98930}},
{'stp':552706,'address':'552706, EGL - WEST, WEST HYBRID, MAESTRO, SHOP 58, MONTE CASINO, CNR WITKOPPEN RD, RANDBURG, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':552721,'address':'552721, EGL - WEST, WEST HYBRID, GREY WOLF SPUR, BROADACRES SHOPPING CENTRE, CO CEDAR AND VALLEY RAODS, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.00230,'lng':27.98510}},
{'stp':613717,'address':'613717, EGL - WEST, WEST HYBRID, LIMA TAPAS BAR - MONTE CASINO (NTO), SHOP NO:58A MONTE CASINO, WILLIAM NICOL DRIVE, CORNER MONTE CASINO, BOULEVARD AND WITKOPPEN ROAD, SANDTON, FOURWAYS, South Africa','geo':{'lat':-26.02540,'lng':28.01410}},
{'stp':629729,'address':'629729, EGL - WEST, WEST HYBRID, KNOCK KNOCK GINGER TA LA NOUVA, CNR BUSHWILLOW & URANIUM RDS, FOURWAYS GARDENS, #, RANDBURG, South Africa','geo':{'lat':-26.00800,'lng':28.00290}},
{'stp':635694,'address':'635694, EGL - WEST, WEST HYBRID, PICK N PAY FOURWAYS, ERF 1612 WITKOPPEN EXT 116, SHOP 1A THE BUZZ SHOPPING CENTRE, CNR WITKOPPEN ROAD & NERINE STREET, RANDBURG, South Africa','geo':{'lat':-26.02310,'lng':27.99020}},
{'stp':636146,'address':'636146, EGL - WEST, WEST HYBRID, CIAO BABY CEDAR SQUARE, SHOP U6. 1 TO U6. 7 UPPER GROUN FLR, CEDAR RD SHOPP CENTRE, WITTOPPEN, RANDBURG, South Africa','geo':{'lat':-26.01640,'lng':27.99990}},
{'stp':638402,'address':'638402, EGL - WEST, WEST HYBRID, CIAO BABY MONTECASINO, SHOP NO2  NEW LIFESTYLE CENTRE, CNR WILLIAM NICOL, MONTECASINO  BOULEVARD SANDTON, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':639727,'address':'639727, EGL - WEST, WEST HYBRID, METROPOLIS BAR AND GRILL (NTO), SHOP E5, MONTE CASINO BLVRD, #, #, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':639728,'address':'639728, EGL - WEST, WEST HYBRID, MILK & HONEY (NTO), MONTECASINO LIFESTYLE CENTRE, BOUNDED BY MONTECASINO BVD WITKOPPEN RD, WILLIAM NICOL DRIVE, MAGALIESSIG EXT 37, FOURWAYS, South Africa','geo':{'lat':-26.02419,'lng':28.01402}},
{'stp':640146,'address':'640146, EGL - WEST, WEST HYBRID, PICK N PAY CEDAR, ERF 862, 863 & 864, CEDAR SQUARE, CNR CEDAR AVE & WILLOW RD, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.01680,'lng':27.99820}},
{'stp':640676,'address':'640676, EGL - WEST, WEST HYBRID, OCEAN BASKET FOURWAYS MALL (NTO), WITKOPPEN ROAD, FOURWAYS MALL, #, RANDBURG, South Africa','geo':{'lat':-26.07820,'lng':27.96270}},
{'stp':642402,'address':'642402, EGL - WEST, WEST HYBRID, FTV CEDAR SQUARE (NTO), CEDAR SQUARE SHOPPING CENTRE, FOURWAYS, #, RANDBURG, South Africa','geo':{'lat':-26.01730,'lng':28.00050}},
{'stp':642507,'address':'642507, EGL - WEST, WEST HYBRID, LONGMEADOWS (NTO), CNR MONTE CASINO BOULAVADE, #, #, FOURWAYS, South Africa','geo':{'lat':-26.02220,'lng':28.01130}},
{'stp':646962,'address':'646962, EGL - WEST, WEST HYBRID, COFI CEDAR SQUARE, CEDAR SQUARE SHOP CENTRE, CNR CEDAR & WILLOW ROAD, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.01830,'lng':28.00040}},
{'stp':649309,'address':'649309, EGL - WEST, WEST HYBRID, CUBANA FOURWAYS, SHOP NO G3 7, GROUND FLOOR, RD SHOPP CENTRE, CEDAR RD & WILLOW RD, WITKOPPEN X8, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.01860,'lng':28.00080}},
{'stp':653075,'address':'653075, EGL - WEST, WEST HYBRID, FERNRIDGE LIQUOR STORE, SHOP 4, CNR BRAODACRES & WILLIAM NICOL, THE VALLEY SHOPPING CENTRE, RANDBURG, South Africa','geo':{'lat':-25.99200,'lng':28.00960}},
{'stp':653397,'address':'653397, EGL - WEST, WEST HYBRID, EAGLE FALLS SPUR, ERVEN 1698,1699,1700,1701,1714,1715, CNR WILLIAM NICOL DRIVE & WITKOPPEN ROAD, SHOP L25 LOWER LEVEL, FOURWAYS MALL, RANDBURG, South Africa','geo':{'lat':-26.01860,'lng':28.00680}},
{'stp':657056,'address':'657056, EGL - WEST, WEST HYBRID, JONNY\'S (NTO), SHOP NO 1 & A1 THE VALLEY SHOPPING, CORNER BROADACRES AND WILLIAM NICOL ROAD, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-25.99160,'lng':28.00970}},
{'stp':660805,'address':'660805, EGL - WEST, WEST HYBRID, MOLLY MALONES (NTO), Design & Decor Centre,  Design & Decor Centre, Forest Road Sunset blvd Pineslopes, FOURWAYS, South Africa','geo':{'lat':-26.02029,'lng':28.01439}},
{'stp':664187,'address':'664187, EGL - WEST, WEST HYBRID, GOURMET GARAGE (NTO), MONTECASINO BOULEVARD, CNR WILLIAM NICOL & WITKOPPEN RD, CNR WILLIAM NICOL & WITKOPPEN RD, FOURWAYS, South Africa','geo':{'lat':-26.02473,'lng':28.01342}},
{'stp':664188,'address':'664188, EGL - WEST, WEST HYBRID, HUSSAR GRILL - MONTECASINO (NTO), MONTECASINO BOULEVARD, CNR WILLIAM NICOL & WITKOPPEN RD, CNR WILLIAM NICOL & WITKOPPEN RD, FOURWAYS, South Africa','geo':{'lat':-26.02540,'lng':28.01410}},
{'stp':669312,'address':'669312, EGL - WEST, WEST HYBRID, PANAROTTI\'S FOURWAYS (NTO), ERF 1698,1700,1701,17, SHOP L002, CNR WILLIAM NICOL BOUNDE BY WITKOPPEN RO, & PERCY STREET FOURWAYS BOULEVARD EXT 14, FOURWAYS, South Africa','geo':{'lat':-26.01845,'lng':28.00772}},
{'stp':669472,'address':'669472, EGL - WEST, WEST HYBRID, DAINFERN COUNTRY CLUB (NTO), ERF: 633, DAINFERN TOWNSHIP REG. DI, J.R. DAINFERN, DAINFERN, RANDBURG, South Africa','geo':{'lat':-25.98759,'lng':27.99731}},
{'stp':670107,'address':'670107, EGL - WEST, WEST HYBRID, TOPS AT FOURWAYS GARDENS, SHOP 11A, FOURWAYS GARDENS CENTRE,CORNER, URANIUM STREET AND BUSHWILLOW AVENUE, RANDBURG, South Africa','geo':{'lat':-26.00770,'lng':28.00290}},
{'stp':675790,'address':'675790, EGL - WEST, WEST HYBRID, PIATTO DAINFERN (NTO), The valley shopping centre, Broadacres drive, Dainfern Valley, RANDBURG, South Africa','geo':{'lat':-25.99160,'lng':28.00970}},
{'stp':686111,'address':'686111, EGL - WEST, WEST HYBRID, HOOTERS ON THE BUZZ, CNR WITKOPPEN RD AND NERINE PLACE,  HOP 7A 9,10,11, THE BUZZ SHOPPING CENTRE, RANDBURG, South Africa','geo':{'lat':-26.02370,'lng':27.99150}},
{'stp':688234,'address':'688234, EGL - WEST, WEST HYBRID, GREEN AND GOLD SPORTS BAR AND PUB, SHOP NO 21 AND 22, CNR 14TH AVENUE AND HENDRIK POTGIETER ST, CONSTANIA OFFICE PARK RETAIL CENTRE, ROODEPOORT, South Africa','geo':{'lat':-26.15040,'lng':27.92530}},
{'stp':698198,'address':'698198, EGL - WEST, WEST HYBRID, CEDAR LAKES CLUBHOUSE (NTO), CEDAR LAKES ESTATE, CEDAR AVENUE, BROADACRES, RANDBURG, South Africa','geo':{'lat':-26.00210,'lng':27.98520}},
{'stp':702785,'address':'702785, EGL - WEST, WEST HYBRID, THE CHEF AND THE FAT MAN (NTO), PLOT 4 WITKOPPEN ROAD, NORTHRIDING, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.02330,'lng':27.99010}},
{'stp':706622,'address':'706622, EGL - WEST, WEST HYBRID, FOURWAYS GARDEN CLUBHOUSE (NTO), 69 CAMDEBOO ROAD, FOURWAYS GARDEN ESTATE, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.00780,'lng':28.00631}},
{'stp':719103,'address':'719103, EGL - WEST, WEST HYBRID, SHOPRITE FOURWAYS 30164, FOURWAYS MALL, FOURWAYS CHECKERS HYPER CENTRE, CNR WITKOPPEN AND WILLIMA NICOL DRIVE, RANDBURG, South Africa','geo':{'lat':-26.02050,'lng':28.00780}},
{'stp':719196,'address':'719196, EGL - WEST, WEST HYBRID, CELESTINOS (NTO), SHOP 16 THE FERN SHOPPING, CENTER CORNER CEDAR, AND GATESIDE AVENUE, RANDBURG, South Africa','geo':{'lat':-25.98779,'lng':27.98525}},
{'stp':729933,'address':'729933, EGL - WEST, WEST HYBRID, CAPPELLO FOURWAYS (NTO), cnr Witkop Shop No.2 Buzz Shopping, #, #, RANDBURG, South Africa','geo':{'lat':-26.02370,'lng':27.99150}},
{'stp':762573,'address':'762573, EGL - WEST, WEST HYBRID, LIQUOR CITY LEAPING FROG, G006 LEAPING FROG SHOPPING CENTRE, CNR WILLIAM NICOLE DRIVE N MULBARTON RD, FOURWAYS ERVEN 937 N 938, FOURWAYS, South Africa','geo':{'lat':-26.00790,'lng':28.01249}},
{'stp':791896,'address':'791896, EGL - WEST, WEST HYBRID, SMILE CAFE (NTO), Fourways Cedar Square Shopping Cent, Cnr of Willow and Cedar Road, #, RANDBURG, South Africa','geo':{'lat':-26.01733,'lng':27.99898}},
{'stp':316796,'address':'316796, EGL - WEST, WEST HYBRID, JOHN DORY\'S CRADLESTONE (NTO), CRADLESTONE MALL , SHOP NO F07, CNR HENDRIK POTGIETER AND FURROW ROADS, MULDERSDRIFT, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06073,'lng':27.83649}},
{'stp':387460,'address':'387460, EGL - WEST, WEST HYBRID, PANAROTTI\'S CRADLESTONE MALL (NTO), SHOP F08, CRADLESTONE MALL, CNR HENDRIK POTGIETER & FURROW ROAD, ROODEKRANS, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06073,'lng':27.83649}},
{'stp':389969,'address':'389969, EGL - WEST, WEST HYBRID, DA QUEENS CLUB, 13 14 AND 15 FAIRVIEW ESTATE, MAIN REEF ROAD, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.20602,'lng':27.69584}},
{'stp':461480,'address':'461480, EGL - WEST, WEST HYBRID, NUTBUSH BOMA LODGE (NTO), Off the T1, Magaliesburg, #, MAGALIESBURG, South Africa','geo':{'lat':-25.91480,'lng':27.61430}},
{'stp':480391,'address':'480391, EGL - WEST, WEST HYBRID, MOGALE LIQUORS, STAND NO, ERF 117, UNIT 41 DAVIS STREET, CHAMDOR, KAGISO, South Africa','geo':{'lat':-26.15909,'lng':27.79917}},
{'stp':482540,'address':'482540, EGL - WEST, WEST HYBRID, COUNTRY LIQUOR STORE, STAND NO, PORTION 125(A PORTION OF PORTION 68), FARM WATERVAL 174 IQ CNR RUSTENBURG, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10299,'lng':27.69196}},
{'stp':482984,'address':'482984, EGL - WEST, WEST HYBRID, THE PIT STOP & AUTO CRADLE ESTATE (, HEKPOORT, HEKPOORT, HEKPOORT, MAGALIESBURG, South Africa','geo':{'lat':-25.96080,'lng':27.68074}},
{'stp':485676,'address':'485676, EGL - WEST, WEST HYBRID, TARLTON TAVERN (NTO), PLOT 6 RUSTENBURG ROAD, #, #, MULDERSDRIFT, South Africa','geo':{'lat':-26.07913,'lng':27.65063}},
{'stp':544487,'address':'544487, EGL - WEST, WEST HYBRID, MYKA TRADE LIQUORS, STAND NO, PORTION 75, A PORTION OF PORTION 8 OF THE FARM, MULDERSDRIFT, South Africa','geo':{'lat':-26.03937,'lng':27.84513}},
{'stp':548024,'address':'548024, EGL - WEST, WEST HYBRID, CRADLE LIQUOR STORE, STAND NO, PORTION 35 OF THE FARM STERKFONTEIN, 173 IQ PLOT 68 HEKPOORT ROAD R563, MAGALIESBURG, South Africa','geo':{'lat':-26.02194,'lng':27.72195}},
{'stp':550257,'address':'550257, EGL - WEST, WEST HYBRID, HEIA SAFARI RANCH, 525 GEDEELTE 59 DIE PLAAS SWARTKOP, SWARTKOPS, #, MULDERSDRIFT, South Africa','geo':{'lat':-25.96240,'lng':27.85850}},
{'stp':550396,'address':'550396, EGL - WEST, WEST HYBRID, VICTORIA LIQUORLAND, 12 MARKET STREET, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10670,'lng':27.77220}},
{'stp':550779,'address':'550779, EGL - WEST, WEST HYBRID, MISSOURRI SPUR, SHOP 92 KEY WEST SHOPPING CENT, C/O PAARDEKRAAL & VILJOEN STRE, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08790,'lng':27.78010}},
{'stp':550790,'address':'550790, EGL - WEST, WEST HYBRID, SUNLIGHT LIQUOR STORE, 22 FOURTH & BLOMMENSTEIN, KRUGERSDORP NORTH, 1741, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09270,'lng':27.76930}},
{'stp':551118,'address':'551118, EGL - WEST, WEST HYBRID, SMALL TIME LIQUOR STORE, 0041 STAND NO, RUSTENBURG ROAD, MAGALIESBURG, MAGALIESBURG, South Africa','geo':{'lat':-26.00070,'lng':27.54640}},
{'stp':551207,'address':'551207, EGL - WEST, WEST HYBRID, FIGURE EIGHT LIQUOR STORE, 73 5TH STREET, DAN PIENAARVILLE, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08470,'lng':27.76480}},
{'stp':551349,'address':'551349, EGL - WEST, WEST HYBRID, GREENSLEEVES RESTAURANT, PORTION 12 OF STERKFONTEIN 173, REG DIV IQ TVL, KRUGERSDORP, MULDERSDRIFT, South Africa','geo':{'lat':-26.03373,'lng':27.71733}},
{'stp':551436,'address':'551436, EGL - WEST, WEST HYBRID, OCEAN BASKET KRUGERSDORP, OCEAN BASKET KRUGERSDORP, KEY WEST SHOPPING CENTRE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08780,'lng':27.77980}},
{'stp':551580,'address':'551580, EGL - WEST, WEST HYBRID, VALLEY LODGE, 13 JENNING STEET, MAGALIESBURG, #, MAGALIESBURG, South Africa','geo':{'lat':-26.00090,'lng':27.55480}},
{'stp':551591,'address':'551591, EGL - WEST, WEST HYBRID, AVIANTO CONFERENCE CENTRE, PORTION 69 & 73, FARM DRIEFONTEIN 179, DRIEFONTEIN, MULDERSDRIFT, South Africa','geo':{'lat':-26.02560,'lng':27.83770}},
{'stp':552236,'address':'552236, EGL - WEST, WEST HYBRID, KGOMOTSO LIQUOR STORE, PLOT 37, MARABETH AGRICULTURAL HOLDINGS, TARLTON, MAGALIESBURG, South Africa','geo':{'lat':-26.07030,'lng':27.67640}},
{'stp':552237,'address':'552237, EGL - WEST, WEST HYBRID, MAJESTIC LIQUOR STORE, VON BRANDIS STREETS, PORTION OF GROUND FLOOR RETAIL, CORNER KRUGER, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10460,'lng':27.77090}},
{'stp':552485,'address':'552485, EGL - WEST, WEST HYBRID, STUBBS LIQUOR STORE, 1442 STAND NO, NO 2 POTGIETER STREET, WESTERGLOOR, RANDFONTEIN, South Africa','geo':{'lat':-26.19403,'lng':27.70038}},
{'stp':552902,'address':'552902, EGL - WEST, WEST HYBRID, DRIFT COUNTRY INN, OLD PRETORIA ROAD, FARM RIETVALLEI 180, MULDERSDRIFT, MULDERSDRIFT, South Africa','geo':{'lat':-26.03640,'lng':27.84820}},
{'stp':553153,'address':'553153, EGL - WEST, WEST HYBRID, SWANNIES LIQUOR STORE, 57 PRESIDENT STREET, CRN VAN BREDA STREET, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09900,'lng':27.77130}},
{'stp':553363,'address':'553363, EGL - WEST, WEST HYBRID, BIG TIME LIQ MAGALIESBURG, SHOP 2 MAGALIESBURG MALL, 11 RUSTENBURG ROAD, MAGALIESBURG, MAGALIESBURG, South Africa','geo':{'lat':-26.00060,'lng':27.54670}},
{'stp':553547,'address':'553547, EGL - WEST, WEST HYBRID, CAPTAIN LIQUORS, PORTION 207 FELDER STREET, RANDFONTEIN, #, RANDFONTEIN, South Africa','geo':{'lat':-26.19110,'lng':27.69340}},
{'stp':553551,'address':'553551, EGL - WEST, WEST HYBRID, MILLENIUM LIQUOR CITY, 41 KRUGER STREET, KRUGERSDORP CBD, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10040,'lng':27.77110}},
{'stp':553585,'address':'553585, EGL - WEST, WEST HYBRID, CARNIVORE JOHANNESBURG, MULDERSDRIFT ESTATE, OLD PRETORIA / KRUGERSDORP RD, MULDERSDRIFT, MULDERSDRIFT, South Africa','geo':{'lat':-26.02840,'lng':27.85480}},
{'stp':553788,'address':'553788, EGL - WEST, WEST HYBRID, DRIFT INN LIQUOR STORE, Drift boulevard, PORTIION 4 FARM RIETVALLEI, MULDERSDRIFT, MULDERSDRIFT, South Africa','geo':{'lat':-26.03660,'lng':27.84850}},
{'stp':561069,'address':'561069, EGL - WEST, WEST HYBRID, KAGISO LIQUOR STORE, 3041 ERF 3041, SEBENZISA ROAD, KAGISO EXTENSION 2  KAGISO CENTRAL, KAGISO, South Africa','geo':{'lat':-26.15980,'lng':27.78570}},
{'stp':620759,'address':'620759, EGL - WEST, WEST HYBRID, MISTY HILLS (NTO), MULDERSDRIFT ESTATE PLOT 69, MULDERSDRIFT ROAD, MULDERSDRIFT, MULDERSDRIFT, South Africa','geo':{'lat':-26.02816,'lng':27.85532}},
{'stp':626563,'address':'626563, EGL - WEST, WEST HYBRID, THE WAREHOUSE COFFEE SHOP, PORTION 72 FARM RIETVALLEI, DRIFT BOULEVARD, KRUGERSDORP, MULDERSDRIFT, South Africa','geo':{'lat':-26.03820,'lng':27.84650}},
{'stp':628634,'address':'628634, EGL - WEST, WEST HYBRID, KLOOFZICHT LODGE, KLOOFZICHT LODGE, cnr DRIEFONTEIN / KROMDRAAI RD, SWARTKOPS, MULDERSDRIFT, South Africa','geo':{'lat':-25.97320,'lng':27.81950}},
{'stp':638707,'address':'638707, EGL - WEST, WEST HYBRID, GLENBURN LODGE, KROMDRAAI ROAD, MULDERSDRIFT, #, MULDERSDRIFT, South Africa','geo':{'lat':-25.97750,'lng':27.84150}},
{'stp':639225,'address':'639225, EGL - WEST, WEST HYBRID, MOUNT GRACE COUNTRY HOUSE AND SPA, MOUNT GRACE HOTEL, MAGALIESBURG, RUSTENBURG, MAGALIESBURG, South Africa','geo':{'lat':-25.98370,'lng':27.55260}},
{'stp':639818,'address':'639818, EGL - WEST, WEST HYBRID, PLANTATION LIQUOR TA BLUE BOTTLE LI, 30 STAND NO, PRESIDENT STREET, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09971,'lng':27.77397}},
{'stp':640517,'address':'640517, EGL - WEST, WEST HYBRID, TOPS AT BONANZA, 27- 4TH FOURTH STREET, KRUGERSDORP NORTH, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.09210,'lng':27.76820}},
{'stp':647333,'address':'647333, EGL - WEST, WEST HYBRID, WESTGROVE, 291 PORTION, BEYERS NAUDE DRIVE, #, MULDERSDRIFT, South Africa','geo':{'lat':-26.02310,'lng':27.86500}},
{'stp':652236,'address':'652236, EGL - WEST, WEST HYBRID, WEST LIQUOR MARKET, PRESIDENT SQUARE, CNR PRETORIA AND MARKET, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09760,'lng':27.77680}},
{'stp':653947,'address':'653947, EGL - WEST, WEST HYBRID, CAPTAIN LIQUOR EXPRESS, STAND NO 3814, RALERATA STREET, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.21790,'lng':27.69610}},
{'stp':659054,'address':'659054, EGL - WEST, WEST HYBRID, VALVERDE COUNTRY HOTEL (NTO), CNR BEYER NAUDE AND ELANDSDRIFT, #, #, MULDERSDRIFT, South Africa','geo':{'lat':-25.95451,'lng':27.87618}},
{'stp':659061,'address':'659061, EGL - WEST, WEST HYBRID, THATCHWOOD GOLD DRIVING RANGE (NTO), MAGALIESBURG, #, #, MAGALIESBURG, South Africa','geo':{'lat':-26.00070,'lng':27.54640}},
{'stp':664562,'address':'664562, EGL - WEST, WEST HYBRID, HEKPOORT LIQUORS, STAND NO, FARM HEKPOORT 504, #, MAGALIESBURG, South Africa','geo':{'lat':-25.89380,'lng':27.60180}},
{'stp':667758,'address':'667758, EGL - WEST, WEST HYBRID, LIQUOR CITY  NOORDHEUWEL, 1175 VOORTREKKER ROAD, SHOP NO 31A, ANTHOS SHOPPING CENTRE MONUMENT TOWNSHIP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10121,'lng':27.79792}},
{'stp':669441,'address':'669441, EGL - WEST, WEST HYBRID, THE THATCHERY COUNTRY RESTAURANT (N, 26 VAN ZYL ROAD, MULDERSDRIFT, #, MULDERSDRIFT, South Africa','geo':{'lat':-26.04770,'lng':27.81900}},
{'stp':670211,'address':'670211, EGL - WEST, WEST HYBRID, MINDALORE LIQUOR STORE, ERF 1449 ON THE CORNERS OF, TREZONA AVENUE,VAN LILL STREET AND, LIPTON STREET,MINDALORE EXTENSION 01, ROODEPOORT, South Africa','geo':{'lat':-26.14180,'lng':27.82600}},
{'stp':684227,'address':'684227, EGL - WEST, WEST HYBRID, TARLTON LIQUOR CELLARS, 160 OF THE FARM VLAKPLAATS, PORTION 6 OF PORTION 20, DIV IQ, MAGALIESBURG, South Africa','geo':{'lat':-26.07910,'lng':27.65060}},
{'stp':687795,'address':'687795, EGL - WEST, WEST HYBRID, HARTLEYS/ELEPHANT BAR (NTO), VAALBANK 512 PORTION 12, MAGALIESBURG, #, MAGALIESBURG, South Africa','geo':{'lat':-25.98606,'lng':27.53953}},
{'stp':696043,'address':'696043, EGL - WEST, WEST HYBRID, CASTLE INN (NTO), PLOT NO. 23, MAGALIESBURG, #, MAGALIESBURG, South Africa','geo':{'lat':-26.06946,'lng':27.67739}},
{'stp':701784,'address':'701784, EGL - WEST, WEST HYBRID, MOTORWEST BIKKERS PUB, 52 MARKET STREET, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10060,'lng':27.77410}},
{'stp':707164,'address':'707164, EGL - WEST, WEST HYBRID, PICK N PAY KRUGERSDORP, PICK N PAY SHOPPING CENTRE BELL, DRIVE SCHANNON AND NUPEN ROAD, NOORDEHEUWEL, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.09630,'lng':27.80300}},
{'stp':723337,'address':'723337, EGL - WEST, WEST HYBRID, PICK N PAY KEY WEST, KEY WEST SHOPPING MALL, ON ERF 1 KRUGERSDORP NORTH, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08800,'lng':27.78270}},
{'stp':725750,'address':'725750, EGL - WEST, WEST HYBRID, BAOBAB LIQUOR STORE, 4 SHOP NO, HOLDING 33, HELDERBLOM AGRICULTURAL HOLDING, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10094,'lng':27.69118}},
{'stp':729217,'address':'729217, EGL - WEST, WEST HYBRID, MAGALIES LIQUOR WHOLESALER, 41 RUSTERNURG ROAD, BEING PORTION 1 OF ERF 41, MAGALIESBURG KRUGERSDORP, MAGALIESBURG, South Africa','geo':{'lat':-26.00070,'lng':27.54640}},
{'stp':747582,'address':'747582, EGL - WEST, WEST HYBRID, PANAROTTIS  KEY WEST (NTO), SHOP 91 KEY WEST SHOPPING CENTRE, CORNER PAARDEKRAAL & VILJOEN STREET, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08920,'lng':27.78080}},
{'stp':752065,'address':'752065, EGL - WEST, WEST HYBRID, LOVERS ROCK, RUSTENBURG AVENUE, ON THE R24 TO RUSTENBURG, MAGALIESBURG, MAGALIESBURG, South Africa','geo':{'lat':-25.99506,'lng':27.54383}},
{'stp':754128,'address':'754128, EGL - WEST, WEST HYBRID, DUDE S PUB AND GRILL, STAND NO, PLOT 48 RUSTENBURG WEG ELJEESEE TARLTON, #, MAGALIESBURG, South Africa','geo':{'lat':-26.09130,'lng':27.67460}},
{'stp':763786,'address':'763786, EGL - WEST, WEST HYBRID, MOZAMBIQUE KRUGERSDORP, STAND NO, SHOP 206 KEY WEST SHOPPING CETRE, C O BEGIN AND KEY WEST STREETS, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08730,'lng':27.78050}},
{'stp':774086,'address':'774086, EGL - WEST, WEST HYBRID, DOPPIO ZERO CRADLESTONE (NTO), BUILDING 1-6, DISWLMAR AH SHOP:U91, CRADDLESTONE MALL SHOPPING CENTRE, CNR HENDRIK POTGIETER RD & R28/N14 HIGHW, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06025,'lng':27.85600}},
{'stp':774087,'address':'774087, EGL - WEST, WEST HYBRID, RHINO & LION PARK (NTO), PORTION OF PORTION AND THE, REMAINING PORTION 2 OF THE FARM, RIETFONTEIN 520 JQ, KROMDRAAI ROAD, KRUGERSDORP NORTH, South Africa','geo':{'lat':-25.97130,'lng':27.78910}},
{'stp':774461,'address':'774461, EGL - WEST, WEST HYBRID, PICK N PAY CRADLESTONE MALL GC73, 1 FURROW ROAD, ERF 16 SHOP 34, CRADLESTONE MALL, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06045,'lng':27.83877}},
{'stp':774550,'address':'774550, EGL - WEST, WEST HYBRID, GAME LIQUORMART CARDELSTONE 262, STAND NO, ERVEN 15 AND 16 HIGHWAY AND HENDRIK AND, HENDRIK POTGIETER ROAD, MULDERSDRIFT, South Africa','geo':{'lat':-26.06065,'lng':27.83910}},
{'stp':775661,'address':'775661, EGL - WEST, WEST HYBRID, CHECKERS LIQSHOP CRADLESTONE 69858, STAND NO, ERF 15 SHOP U11 CRADLESTONE MALL, SHOPPING CENTRE HENDRIK POTGIETER ROAD, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06025,'lng':27.83600}},
{'stp':777729,'address':'777729, EGL - WEST, WEST HYBRID, Mythos (NTO), ROAD CNR HENDRIK POTGIETER AND FURR, #, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06025,'lng':27.85600}},
{'stp':777771,'address':'777771, EGL - WEST, WEST HYBRID, OCEAN BASKET CRADLESTONE (NTO), ROAD CNR HENDRIK POTGIETER AND FURR, CRADLESTONE MALL, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06045,'lng':27.83877}},
{'stp':782200,'address':'782200, EGL - WEST, WEST HYBRID, RIVERSTONE LODGE, R114 CORNER DRIEFONTEIN ROAD, PORTION 116 OF THE FARM VAN WYKS RESTANT, 182 IQ DRIFT ROAD N14 OFF RAMP, MULDERSDRIFT, South Africa','geo':{'lat':-26.03569,'lng':27.84320}},
{'stp':785096,'address':'785096, EGL - WEST, WEST HYBRID, SPUR CRADLESTONE MALL, STAND NO, FURROW ROAD AND HENDRICK POTGIETER, SHOP 493, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.06025,'lng':27.85600}},
{'stp':795119,'address':'795119, EGL - WEST, WEST HYBRID, DIE BOOMHUISIE (NTO), 75 VILOEN STREET, KRUGERSDORP NORTH, KRUGERSDORP, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.07490,'lng':27.80130}},
{'stp':799309,'address':'799309, EGL - WEST, WEST HYBRID, BUY RITE LIQUOR STORE, 959 LUIPAARD STREET, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10873,'lng':27.78579}},
{'stp':384679,'address':'384679, EGL - WEST, WEST HYBRID, SCROOGE ROCK COTTAGE (NTO), SHOP NO 2, ROCK COTTAGE CENTRE, RANDBURG, #, RANDBURG, South Africa','geo':{'lat':-26.10422,'lng':27.92604}},
{'stp':386063,'address':'386063, EGL - WEST, WEST HYBRID, BLANDFORD MANOR (NTO), HOLDING 106, NORTHRIDING, HYPERION DRIVE,CORNER BLANDFORD ROAD, NORTHRIDING, RANDBURG, South Africa','geo':{'lat':-26.04241,'lng':27.96264}},
{'stp':387924,'address':'387924, EGL - WEST, WEST HYBRID, THE FLYING DUTCHMAN (NTO), SHOP 1,2,3 RIVERVIEW SHOPPING CENTR, CORNER KRUGERRAND & FLORIN RD,ERF 1418, WILGEHEUWEL & ERF684 STRUBENSVALLEY EXT3, ROODEPOORT, South Africa','geo':{'lat':-26.11672,'lng':27.90381}},
{'stp':388570,'address':'388570, EGL - WEST, WEST HYBRID, DOPPIO ZERO CLEARWATER MALL (NTO), SHOP NO: LM 133, CLEARWATER MALL, CNR CHRISTIAAN DEWET & HENDRIK POTGIETER, RDS, STRUBENS VALLEI, STAND 929, EXT 12, ROODEPOORT, South Africa','geo':{'lat':-26.12810,'lng':27.90260}},
{'stp':388815,'address':'388815, EGL - WEST, WEST HYBRID, GAME AND GAME LIQUOR CLEARWATER 312, UM105A SHOP NO, CLEARWATER MALL ERVEN 929 AND 938, STRUBENS VALLEY X12 CNR HENDRIK, ROODEPOORT, South Africa','geo':{'lat':-26.12936,'lng':27.90423}},
{'stp':388910,'address':'388910, EGL - WEST, WEST HYBRID, FRESHOUSE LIQUORS OLIVEDALE, 10 AND 11 SHOP NO, OLIVEDALE SHOPPING CENTRE, STAND 931 PRESIDENT FOUCHE DRIVE, RANDBURG, South Africa','geo':{'lat':-26.06287,'lng':27.97000}},
{'stp':389022,'address':'389022, EGL - WEST, WEST HYBRID, CHECKERS LIQUORSHOP NORTHGATE 39605, 318 SHOP NO, NORTHGATE SHOPPING CENTRE, #, RANDBURG, South Africa','geo':{'lat':-26.05745,'lng':27.94741}},
{'stp':390570,'address':'390570, EGL - WEST, WEST HYBRID, RARE STEAKHOUSE (NTO), SHOP NO: LM132A,GROUND FLOOR LEVEL,, CLEARWATER MALL, CNR CHRISTIAAN DE WET &, POTGIETER RDS, ERVEN: 929 & 938 ROODEPOO, ROODEPOORT, South Africa','geo':{'lat':-26.12845,'lng':27.90238}},
{'stp':391081,'address':'391081, EGL - WEST, WEST HYBRID, NIKOS RANDPARKRIDGE (NTO), 13 HONEYCREST CENTRE, BEYERS NAUDE DR, RANDPARK RIDGE, RANDBURG ERF 4209 EXT, RANDBURG, South Africa','geo':{'lat':-26.09568,'lng':27.94096}},
{'stp':391271,'address':'391271, EGL - WEST, WEST HYBRID, TUC N TAP (NTO), SHOP NO. 17 & 18 SUMMERFIELD SHOPPI, NUMBER 20, KOWIE ROAD, ERF 676, BOSKRUIN, RANDBURG, South Africa','geo':{'lat':-26.08393,'lng':27.95004}},
{'stp':392110,'address':'392110, EGL - WEST, WEST HYBRID, REAL BEER CO, 00 CNR PRES FOUCHE DRIVE AND, JACARANDA AVE, MEADOW BROOK ESTATE, OLIVEDALE EXTENSION 17, RANDBURG, South Africa','geo':{'lat':-26.05937,'lng':27.97532}},
{'stp':461447,'address':'461447, EGL - WEST, WEST HYBRID, ROCOMAMAS BELLAIRS (NTO), ERF:2112 AND 2113, BEL AIR MALL, SHOP NO.27A GROUND LEVEL, CNR MALIBONGWE, DR. & BELLAIRS DR, NORTHRIDING EXT.57, RANDBURG, South Africa','geo':{'lat':-26.05510,'lng':27.96520}},
{'stp':461448,'address':'461448, EGL - WEST, WEST HYBRID, ROCOMAMAS RAND PARK RIDGE (NTO), BUSH HILL RETAIL CENTRE, BEYERS NAUDE DRIVE, CNR DUIKER AVENUE, RANDPARK RIDGE EXT 1 ERF: 3627, RANDBURG, South Africa','geo':{'lat':-26.09379,'lng':27.94082}},
{'stp':470529,'address':'470529, EGL - WEST, WEST HYBRID, MATEYS PUB, 5/1789 STAND NO, ERF 21481, RANDPARK RIDGE, RANDBURG, South Africa','geo':{'lat':-26.11410,'lng':27.94780}},
{'stp':471049,'address':'471049, EGL - WEST, WEST HYBRID, TOPS AT EAGLE CANYON LIQUOR STORE, EAGLE CANYON TOPS LIQUOR STORE, CNR CHRISTIAAN DE WET ROAD AND SCOTT, STREET, RANDBURG, South Africa','geo':{'lat':-26.09496,'lng':27.92869}},
{'stp':478275,'address':'478275, EGL - WEST, WEST HYBRID, PICK N PAY FAMILY BOSKRUIN GH 02, THE SUMMERFIELD SHOPPING CENTRE, BETWEEN BALETA, KOWIE AND KELLY STREET, ROODEPOORT, South Africa','geo':{'lat':-26.08505,'lng':27.94764}},
{'stp':483912,'address':'483912, EGL - WEST, WEST HYBRID, THE LIQUOR SHOP, 24 STAND NUMBER, RIDGE TERRACE, SHOPPING CENTRE, RANDBURG, South Africa','geo':{'lat':-26.11424,'lng':27.94778}},
{'stp':494218,'address':'494218, EGL - WEST, WEST HYBRID, PIZZA DEL FORNO BROMHOF (NTO), SHOP 26 BROMHOF VILLAGE SHOPPING CE, CNR SWART AVE AND TIN ROAD, BROMHOH EXT 19, RANDBURG, HONEYDEW, South Africa','geo':{'lat':-26.07836,'lng':27.96231}},
{'stp':494617,'address':'494617, EGL - WEST, WEST HYBRID, CAFE ROSSINI T/A PIZZA E VINO EAGLE, 15  EAGLES LANDING SHOPPING CENTRE, CORNER CHRISTIAAN DE WET ROAD & SCOTT ST,  RANDPARK RIDGE, RANDBURG, South Africa','geo':{'lat':-26.09617,'lng':27.92736}},
{'stp':541919,'address':'541919, EGL - WEST, WEST HYBRID, NINETEEN 69, 001 SHOP, SHOP 001 BOSKRUIN VILLAGE SHOPPING CENTR, PRESIDENT FOUCHE AND HAWKEN ROAD, RANDBURG, South Africa','geo':{'lat':-26.08671,'lng':27.95817}},
{'stp':544415,'address':'544415, EGL - WEST, WEST HYBRID, NORTHRIDING LIQUORS, BANBURY CROSS PLAZA, ERF 767 SHOP D5, CORNER OLIVENHOUT N MALLBONGWE AVE, RANDBURG, South Africa','geo':{'lat':-26.06197,'lng':27.95368}},
{'stp':550010,'address':'550010, EGL - WEST, WEST HYBRID, FROGGIES PUB, SHOP 18 & 19, RIVERVIEW SHOPPING CENTRE, C/O KRUGERRAND & FLORIN ROADS, MULDERSDRIFT, South Africa','geo':{'lat':-26.11672,'lng':27.90381}},
{'stp':550573,'address':'550573, EGL - WEST, WEST HYBRID, TOPS AT SPAR NORTHWOLD, C/O DRYSDALE & ELNITA AVE, NORTHWOLD EXT 8, NORTHWOLD, RANDBURG, South Africa','geo':{'lat':-26.07780,'lng':27.94560}},
{'stp':550642,'address':'550642, EGL - WEST, WEST HYBRID, ANGELOS PIZZERIA AND GRILL, SHOP 5 & 6, RANDPARK RIDGE SHOPPING CENTR, RANDPARK RIDGE EXT 1, RANDBURG, South Africa','geo':{'lat':-26.10220,'lng':27.96140}},
{'stp':551192,'address':'551192, EGL - WEST, WEST HYBRID, TONYS SPAGHETTI GRILL, TONYS SPAGHETTI GRILL/TOP CROP, C/O D F MALAN & DUIKER AVE, RANDPARK RIDGE, RANDBURG, South Africa','geo':{'lat':-26.09418,'lng':27.94015}},
{'stp':551237,'address':'551237, EGL - WEST, WEST HYBRID, TULBACH LIQUOR CELLARS, STAND NO, SHOP A1 HONEYDEW RAOD, #, RANDBURG, South Africa','geo':{'lat':-26.06060,'lng':27.95330}},
{'stp':551275,'address':'551275, EGL - WEST, WEST HYBRID, TOPS LIQUOR MASTERS, SHOP 23 OLIVEDALE SHOP CENTRE, WINDSOR WAY STAND 1741-4, NORTHRIDING EXT 53 RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.05370,'lng':27.97160}},
{'stp':551520,'address':'551520, EGL - WEST, WEST HYBRID, POTTERS, STAND 121 SHOP 1, C/O ELNITA & DRYSDALE STREETS, NORTHWOLD - RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.07770,'lng':27.94560}},
{'stp':551661,'address':'551661, EGL - WEST, WEST HYBRID, RANDBURG BOWLS CLUB, BOSKRUIN RECREATION CLUB, KELLYAVENUE, BOSKRUIN, RANDBURG, South Africa','geo':{'lat':-26.08860,'lng':27.94510}},
{'stp':552108,'address':'552108, EGL - WEST, WEST HYBRID, PICK N PAY CLEARWATER, SHOP NO LMO49A,CLEARWATER MALL, CNR C DE WET & H POTGIETER RDS, STRUBENSVALLEI, ROODEPOORT, South Africa','geo':{'lat':-26.12820,'lng':27.90290}},
{'stp':552719,'address':'552719, EGL - WEST, WEST HYBRID, SPUR FALCON FALLS, SHOP 166 CLEARWATER MALL, CNR CHRISTIAAN DE WET AND HENDRI, STRUBENS VALLEY, ROODEPOORT, South Africa','geo':{'lat':-26.12770,'lng':27.90450}},
{'stp':553251,'address':'553251, EGL - WEST, WEST HYBRID, CHICAGOS PIANO BAR RANDPARK RIDGE, STAND NO, ERF 4209, BEYERS NAUDE DRIVE RANDPARK RIDGE EXT 62, RANDBURG, South Africa','geo':{'lat':-26.09430,'lng':27.94050}},
{'stp':553414,'address':'553414, EGL - WEST, WEST HYBRID, LA ROSA MEXICAN GRILL N TEQUILA BAR,  30 SHOP NO, HONEYDEW VILLAGE CORNER, C/O JOHN VOSTER & CHRISTIAAN DE WET, RANDBURG, South Africa','geo':{'lat':-26.10515,'lng':27.92510}},
{'stp':560583,'address':'560583, EGL - WEST, WEST HYBRID, EAGLES LIQUOR, 8 SHOP NO, EAGLES LANDING SHOPPING CENTRE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09591,'lng':27.92724}},
{'stp':580950,'address':'580950, EGL - WEST, WEST HYBRID, GATSBYS BAR AND GRILL HOUSE, SUMMERFIELD SHOPPING CENTRE, CNR  KELLY AND KOWIE STS, #, ROODEPOORT, South Africa','geo':{'lat':-26.08610,'lng':27.94720}},
{'stp':605553,'address':'605553, EGL - WEST, WEST HYBRID, SPOT ON LIQUORS, 29 STAND NO, CURIE ROAD, JOHANNESBURG, RANDBURG, South Africa','geo':{'lat':-26.10530,'lng':27.92550}},
{'stp':626564,'address':'626564, EGL - WEST, WEST HYBRID, AJ S PUB, 1154 RINYANI STREET, SHOP 7 WELTEVREDEN PARK SHOPPING CENTRE, WELEVREDEN PARK, ROODEPOORT, South Africa','geo':{'lat':-26.11720,'lng':27.92370}},
{'stp':630473,'address':'630473, EGL - WEST, WEST HYBRID, DROS NORTHRIDING, NORTHRIDING SQUARE S/CENTRE, CNR BLANFORD AND BELLAIRS DRIVE, NORTHRIDING, RANDBURG, South Africa','geo':{'lat':-26.05500,'lng':27.96490}},
{'stp':631810,'address':'631810, EGL - WEST, WEST HYBRID, SUNDOWNER RESTAURANT, SHOP NO.12 SUNDOWER PLAZA EXT 4, RANDBURG, #, RANDBURG, South Africa','geo':{'lat':-26.07363,'lng':27.93962}},
{'stp':634840,'address':'634840, EGL - WEST, WEST HYBRID, POCOCK ACTION BEACH VOLLEYBALL, 292 NORTHLANDS DECO PARK, NEW MARKET STREET, NORTHLANDS, RANDBURG, South Africa','geo':{'lat':-26.03380,'lng':27.95620}},
{'stp':636124,'address':'636124, EGL - WEST, WEST HYBRID, TOPS AT BEL AIR MALL, SHOP NUMBER GL04, CNR BELLAIRS & HANS STRYDOM DRIVE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.06060,'lng':27.95820}},
{'stp':636213,'address':'636213, EGL - WEST, WEST HYBRID, LIQUOR CITY NORTHRIDING SQUARE LIQU, SHOP NO 18 NORTHRIDING SQUARE, CNR BELLAIR & BLANDFORD ROADS, NORTHRIDING, RANDBURG, South Africa','geo':{'lat':-26.05610,'lng':27.96530}},
{'stp':636728,'address':'636728, EGL - WEST, WEST HYBRID, PRIMI PIATTI LIFESTYLE, CNR BEYERS NAUDE DRIVE, AND YSTERHOUT AVENUE, RANDPARK RIDGE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09500,'lng':27.94320}},
{'stp':637788,'address':'637788, EGL - WEST, WEST HYBRID, TOPS AT WELTEVREDEN PARK, CNR LOBELIA AVE + JG STRYDOM, WELTEVREDEN PARK, #, RANDBURG, South Africa','geo':{'lat':-26.11230,'lng':27.93160}},
{'stp':639513,'address':'639513, EGL - WEST, WEST HYBRID, OMEGA LIQUOR STORE, ERF 134 OF PORTION 1, 90 CHURCH STREET, JOHANNESBURG NORTH, RANDBURG, South Africa','geo':{'lat':-26.04580,'lng':27.97610}},
{'stp':639516,'address':'639516, EGL - WEST, WEST HYBRID, LIQUOR CITY RANDRIDGE, CNR KAYBURNE AVE & JOHN VORSTER RD, RANDPARK RIDGE, EXT 8, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.10040,'lng':27.94070}},
{'stp':639866,'address':'639866, EGL - WEST, WEST HYBRID, PICK N PAY NORTHGATE, SHOP 69AA NORTHG S/C C NORTHUMBERLA, DOUGLAS CRES & OLIVIENHOUT AVENUE,, EXT 4 NORTHGATE, RANDBURG, South Africa','geo':{'lat':-26.06210,'lng':27.94840}},
{'stp':640317,'address':'640317, EGL - WEST, WEST HYBRID, NEWS CAFE NORTHRIDING, NORTHERN SHOPING CENTRE, UNIT B2 CNR HANS STRYDOM  OLIEVEHOUT RD, NORTHRIDING, RANDBURG, South Africa','geo':{'lat':-26.06080,'lng':27.95480}},
{'stp':647102,'address':'647102, EGL - WEST, WEST HYBRID, TOPS AT RANDPARK RIDGE, 93 SHOP NO 7  RANDPARK RIDGE SHOPPI, 93 RANDPARK DRIVE, RANDPARK RIDGE, RANDBURG, South Africa','geo':{'lat':-26.10240,'lng':27.96210}},
{'stp':647541,'address':'647541, EGL - WEST, WEST HYBRID, OVERLAND LIQUOR BOSKRUIN, 8035 UNIT NO, BOSKRUIN VILLAGE SHOPPING CENTRE, CNR HAWKEN AVENUE AND, RANDBURG, South Africa','geo':{'lat':-26.08749,'lng':27.95949}},
{'stp':647685,'address':'647685, EGL - WEST, WEST HYBRID, TOPS AT SPAR BROMHOF, 26 SHOP NO, BROMHOF VILLAGE SHOPPING CENTRE, CNE SWART DRV A ND TIN RD, RANDBURG, South Africa','geo':{'lat':-26.07830,'lng':27.96260}},
{'stp':648827,'address':'648827, EGL - WEST, WEST HYBRID, HOGSHEAD ROCK COTTAGE - DO NOT CALL, CNR CHRISTIAAN DE WET AND JOHN VORS, ROCK COTTAGE CENTRE, #, ROODEPOORT, South Africa','geo':{'lat':-26.10380,'lng':27.92630}},
{'stp':650347,'address':'650347, EGL - WEST, WEST HYBRID, MATEYS PUB (NTO), SHOP 10 RIDGE TERRACE, CNR WITHOUT & JIM FOUCHE, WELTEVREDENPARK, ROODEPOORT, South Africa','geo':{'lat':-26.11413,'lng':27.94752}},
{'stp':650534,'address':'650534, EGL - WEST, WEST HYBRID, QUARTZ POOL LOUNGE AND PUB, SHOP NO 15&16 BROMHOF CENTRE, CNR OSTRICH STREET & HAWKEN AVENUE, BOSKRUIN, RANDBURG, South Africa','geo':{'lat':-26.08790,'lng':27.96080}},
{'stp':654037,'address':'654037, EGL - WEST, WEST HYBRID, LONE WOLF SPUR, EASTWOOD AVENUE, CNR KNOPPIESDORING STREET, RANDRIDGE EXTENSION 82 ERF 4301, RANDBURG, South Africa','geo':{'lat':-26.09300,'lng':27.93770}},
{'stp':654873,'address':'654873, EGL - WEST, WEST HYBRID, SMUGGLERS (NTO), RIVERBEND SHOPPING CENTRE, CNR WITKOPPEN & RIVERBND RD, #, RANDBURG, South Africa','geo':{'lat':-26.02450,'lng':27.94980}},
{'stp':665100,'address':'665100, EGL - WEST, WEST HYBRID, Brazen Head Florida Glen (NTO), C/o Hendrik Potgieter and, Gordon Road Florida, Roodepoort, ROODEPOORT, South Africa','geo':{'lat':-26.16748,'lng':27.93780}},
{'stp':669175,'address':'669175, EGL - WEST, WEST HYBRID, PANAROTTI\'S CLEARWATER (NTO), CINEMA LEVEL CLEARWATER MALL, CNR CHRISTIAAN DE WET & HENDRIK POTGIETE, ROAD EXT. 12, ROODEPOORT, South Africa','geo':{'lat':-26.12740,'lng':27.90360}},
{'stp':669176,'address':'669176, EGL - WEST, WEST HYBRID, Ocean Basket Clearwater (NTO), Shop 77 Clear Water Mall, Cnr Hendrik Potgieter & Christiaan de We, Strubensvalley, ROODEPOORT, South Africa','geo':{'lat':-26.12740,'lng':27.90360}},
{'stp':669438,'address':'669438, EGL - WEST, WEST HYBRID, MARCO POLO (NTO), 483 BOUNDARY RD, NORTHRIDING, #, RANDBURG, South Africa','geo':{'lat':-26.03340,'lng':27.94120}},
{'stp':669440,'address':'669440, EGL - WEST, WEST HYBRID, CORNER BISTRO (NTO), 9 BOUNDARY CORNER, BOUNDARY RD AND HANS STRIJDOM, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.03370,'lng':27.94640}},
{'stp':669474,'address':'669474, EGL - WEST, WEST HYBRID, DOPPIO ZERO NORTHRIDING (NTO), ERF:2112 AND 2113, SHOP NO:LL25, BEL AIR MALL, CNR OF BELL AIR DR. & HANS, STRYDOM DR., NORTHRIDING EXT.57, RANDBURG, South Africa','geo':{'lat':-26.05510,'lng':27.96520}},
{'stp':697933,'address':'697933, EGL - WEST, WEST HYBRID, MO-ZAM-BIK (NTO), HOP R1 TRINITY VILLAGE, 36 KNOPPIESDORING STR, STANDS 4873 &4874, RANDPARK RIDGE EXT 121, RANDBURG, South Africa','geo':{'lat':-26.09300,'lng':27.93790}},
{'stp':698541,'address':'698541, EGL - WEST, WEST HYBRID, OVERLAND COTTAGE CELLAR, STAND NO, SHOP 20 B ROCK COTTAGE C/0 CHRISTIAAN DE, WET AND JOHN VOSTER STR HONETDEW, ROODEPOORT, South Africa','geo':{'lat':-26.10490,'lng':27.92720}},
{'stp':704043,'address':'704043, EGL - WEST, WEST HYBRID, PAPACHINOS CLEARWATER (NTO), ERVEN 527 & 528 VILLA ROSSI CENTRE, CNR CHRISTIAAN DE WET & ERASMUS ROAD, RADIOKOP EXTENTION 49, ROODEPOORT, South Africa','geo':{'lat':-26.11356,'lng':27.91887}},
{'stp':704184,'address':'704184, EGL - WEST, WEST HYBRID, TRINITY BLUE BOTTLE LIQUORS, ERF NO 4873 AND 4874, RANDPARK RIDGE EXTENSION 121 TRINITY, VILLAGE SHOPPING CENTRE BOUNDED BY, RANDBURG, South Africa','geo':{'lat':-26.09150,'lng':27.93730}},
{'stp':706893,'address':'706893, EGL - WEST, WEST HYBRID, FRIENDS BLUE BOTTLE LIQUORS, SHOP 7 EXT 6, WELTEVREDEN PARK SHOPPING CENTRE, #, ROODEPOORT, South Africa','geo':{'lat':-26.11730,'lng':27.92380}},
{'stp':710436,'address':'710436, EGL - WEST, WEST HYBRID, LUIGIS PIZZERIA (NTO), SHOP 22 ALL SAINTS CENTER, WINDSOR WAY, OLIVEDALE, RANDBURG, South Africa','geo':{'lat':-26.03960,'lng':27.99330}},
{'stp':712427,'address':'712427, EGL - WEST, WEST HYBRID, All Stars Sports Bar (NTO), 4299 KNOPPIESDORING STREET, ERF 4299 RANDPARKDRIFT EXT 92, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09320,'lng':27.93780}},
{'stp':732596,'address':'732596, EGL - WEST, WEST HYBRID, Luigis Pizzeria Boskruin (NTO), Boskruin Village Shopping Centre, C/O President Fouche and Hawken, Boskruin, RANDBURG, South Africa','geo':{'lat':-26.08733,'lng':27.95883}},
{'stp':770738,'address':'770738, EGL - WEST, WEST HYBRID, PICK N PAY NORTHLANDS CORNER GC67, ERF 396, NORTHLANDS CORNER RETAIL SHOPPING CENTRE, CORNER  WILKOPPEN STREET AND NEW MARKET, RANDBURG, South Africa','geo':{'lat':-26.03962,'lng':27.95640}},
{'stp':774916,'address':'774916, EGL - WEST, WEST HYBRID, NANDOS BANBURRY CROSS CENTRE (NTO), SHOP B1 NORTHVIEW SHOPPING, CENTRE, OLIEVENHOUT ROAD, CNR OF, MALIBONGWE DR, ERF.721 NORTHWORLD EXT:16, RANDBURG, South Africa','geo':{'lat':-26.06230,'lng':27.95520}},
{'stp':780211,'address':'780211, EGL - WEST, WEST HYBRID, MURPHY\'S LAW (NTO), Malibongwe, Northriding, Randburg, RANDBURG, South Africa','geo':{'lat':-26.05418,'lng':27.95561}},
{'stp':646868,'address':'646868, EGL - WEST, D&DOUTLET WITH ASSET, CENTRAL GRILL COCKTAIL HOUSE, CEDAR SQUARE SHOPPING CENTRE, CNR CEDAR ROAD & WILLOW AVENUE, FOURWAYS, RANDBURG, South Africa','geo':{'lat':-26.01750,'lng':28.00070}},
{'stp':705608,'address':'705608, EGL - WEST, D&DOUTLET WITH ASSET, CAPPELLO TOWN SQUARE (NTO), CORNER OF HENDRIK, POTGIETER AND ALBERT STREET, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.13720,'lng':27.91843}},
{'stp':386529,'address':'386529, EGL - WEST, WEST FAR, BHEKI SHEBEEN, 7177 STAND NUMBER, LEMEKO STREET, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.24165,'lng':27.69611}},
{'stp':389477,'address':'389477, EGL - WEST, WEST FAR, SPECHO RESTAURANT, 001399 ERF, 1399 LESHOMO, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.22317,'lng':27.69885}},
{'stp':390920,'address':'390920, EGL - WEST, WEST FAR, GZ DISTRIBUTORS, 3233 ERF, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.19074,'lng':27.80599}},
{'stp':392153,'address':'392153, EGL - WEST, WEST FAR, ANZA LIQUORS, 5053 TLHAPANE CRECENT, MOHLAKENG, #, #, MOHLAKENG, South Africa','geo':{'lat':-26.23687,'lng':27.68962}},
{'stp':392534,'address':'392534, EGL - WEST, WEST FAR, JACKS CONVENIENT LIQUOR STORE, 6932 ERF, PROTEA GLEN EXTENSION 11, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.27300,'lng':27.80410}},
{'stp':393343,'address':'393343, EGL - WEST, WEST FAR, EXT LIQUORS, 22 STAND NO, NUTMEG STREET, PROTEA GLEN EXTENSION, ROODEPOORT, South Africa','geo':{'lat':-26.28428,'lng':27.76810}},
{'stp':467703,'address':'467703, EGL - WEST, WEST FAR, KO NTOMBIS INN, 549 NHLAPO STREET, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.22874,'lng':27.69315}},
{'stp':470811,'address':'470811, EGL - WEST, WEST FAR, KO WESO, STAND NO, HUDSON NTSANWISI STREET, ERF 05733, RANDFONTEIN, South Africa','geo':{'lat':-26.22448,'lng':27.68944}},
{'stp':477416,'address':'477416, EGL - WEST, WEST FAR, PICK N PAY GF87 PROTEA BOULEVARD, 8698 NO, CNR IMPALA AND PROTEA BOULEVARD, PROTEA GLEN MALL, PROTEA, South Africa','geo':{'lat':-26.27722,'lng':27.81155}},
{'stp':480325,'address':'480325, EGL - WEST, WEST FAR, LEBOGANG S PLACE, 6551 STAND NO, SEBONE STREET, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.23856,'lng':27.69539}},
{'stp':481563,'address':'481563, EGL - WEST, WEST FAR, SHOPRITE LIQUORSHOP MOHLAKENG, 13 SHOP NO, MOHLAKENG MALL 2483 NTULI STREET, CORNER KGWELE STREET, MOHLAKENG, South Africa','geo':{'lat':-26.23331,'lng':27.69251}},
{'stp':488698,'address':'488698, EGL - WEST, WEST FAR, HLAKES TAVERN, 5321 STAND NO, RAMASIA STREET, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.23168,'lng':27.68645}},
{'stp':550918,'address':'550918, EGL - WEST, WEST FAR, MASHKHO LIQUOR STORE, 645 NANDI STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22700,'lng':27.69600}},
{'stp':551119,'address':'551119, EGL - WEST, WEST FAR, NORTHGATE TAVERN, 3502 NGONYELA STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22160,'lng':27.70140}},
{'stp':551322,'address':'551322, EGL - WEST, WEST FAR, CASBANS LIQUOR STORE 2, 8250  WILD CHESTNUT RD, EXTENTION 11, PROTEA GLEN, ROODEPOORT, South Africa','geo':{'lat':-26.27350,'lng':27.80370}},
{'stp':551415,'address':'551415, EGL - WEST, WEST FAR, EYETHU LIQUOR STORE, STAND MO 11245, NHLAPO STREET, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.22860,'lng':27.69900}},
{'stp':551950,'address':'551950, EGL - WEST, WEST FAR, NDIS TAVERN, NO 851 RALERATA STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22650,'lng':27.69720}},
{'stp':552075,'address':'552075, EGL - WEST, WEST FAR, CASBANS LIQUOR STORE, STAND 3007, PROTEA GLEN EXT 2, SOWETO, ROODEPOORT, South Africa','geo':{'lat':-26.27727,'lng':27.82220}},
{'stp':552347,'address':'552347, EGL - WEST, WEST FAR, G AND Z INN TA G AND Z LIQUOR TRADE, ERF 3765, TSHEPISONG, DOORNKOP, KAGISO, South Africa','geo':{'lat':-26.19480,'lng':27.80460}},
{'stp':552588,'address':'552588, EGL - WEST, WEST FAR, MOTIMALENYORA LIQUOR STORE, 2195 THENENARE STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23030,'lng':27.69694}},
{'stp':553313,'address':'553313, EGL - WEST, WEST FAR, MOSES LIQUOR STORE, PLOT 12 R558, VLAKFONTEIN, ROODEPOORT, KAGISO, South Africa','geo':{'lat':-26.20603,'lng':27.80494}},
{'stp':580860,'address':'580860, EGL - WEST, WEST FAR, JACKS SPORTS PUB, 6932 ERF, PROTEA GLEN EXT 11, JOHANNESBURG, ROODEPOORT, South Africa','geo':{'lat':-26.27300,'lng':27.80410}},
{'stp':603076,'address':'603076, EGL - WEST, WEST FAR, RASSIES TAVERN, 1102 ROSE STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20770,'lng':27.71440}},
{'stp':605464,'address':'605464, EGL - WEST, WEST FAR, ALASKA INN, 4916 MOSIANE CRESCENT, MOHLAKENG, RANDFONTEIN, MOHLAKENG, South Africa','geo':{'lat':-26.23520,'lng':27.69540}},
{'stp':606138,'address':'606138, EGL - WEST, WEST FAR, LATTINO HOUSE, 4855 THEBENARE STREET, MOHLAKENG, EXTENSION 3, MOHLAKENG, South Africa','geo':{'lat':-26.23434,'lng':27.69851}},
{'stp':606672,'address':'606672, EGL - WEST, WEST FAR, ALFREDS PLACE, 5377 RAMASIA ST, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23480,'lng':27.68610}},
{'stp':606684,'address':'606684, EGL - WEST, WEST FAR, CHARLES PLACE, 2669 NTULI STREET, MOHLAKENG, RANDFONTEIN, MOHLAKENG, South Africa','geo':{'lat':-26.23320,'lng':27.69250}},
{'stp':607420,'address':'607420, EGL - WEST, WEST FAR, LUCY\'S TAVERN, 2306 PINEAPPLE ST, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.21090,'lng':27.71510}},
{'stp':624417,'address':'624417, EGL - WEST, WEST FAR, CLUB 19 (NTO), 19 MZOLO STREET, MOHLAKENG, Randfontein, MOHLAKENG, South Africa','geo':{'lat':-26.22424,'lng':27.69499}},
{'stp':626074,'address':'626074, EGL - WEST, WEST FAR, PONASES PLACE, 11945 SELOPI THEMA STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22806,'lng':27.70833}},
{'stp':626087,'address':'626087, EGL - WEST, WEST FAR, MAJOVAS PLACE, 3042 STAND NO, HLAZANA STREET, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.22952,'lng':27.69209}},
{'stp':626100,'address':'626100, EGL - WEST, WEST FAR, KILLERS PLACE, 208 MOSHOESHOE STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22396,'lng':27.69201}},
{'stp':626161,'address':'626161, EGL - WEST, WEST FAR, MONGES PLACE, 7469 NONAME STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22381,'lng':27.70019}},
{'stp':626740,'address':'626740, EGL - WEST, WEST FAR, BEEGEES TAVERN, 913 DIAMOND STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20420,'lng':27.71450}},
{'stp':626741,'address':'626741, EGL - WEST, WEST FAR, SIBONGILES PLACE, 12 VAN RENSBURG STREET, WESTERGLOOR, #, RANDFONTEIN, South Africa','geo':{'lat':-26.19860,'lng':27.70040}},
{'stp':627046,'address':'627046, EGL - WEST, WEST FAR, MANDLAS PLACE, 7158 LEMEKO ST, MOHLAKENG X 4, #, MOHLAKENG, South Africa','geo':{'lat':-26.24100,'lng':27.69540}},
{'stp':627139,'address':'627139, EGL - WEST, WEST FAR, PAPPIES PLACE, STAND 2688 MAHUMA ST, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.23140,'lng':27.69400}},
{'stp':627173,'address':'627173, EGL - WEST, WEST FAR, SIPHOS PLACE, 1303 DIALE DRIVE, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.22154,'lng':27.69742}},
{'stp':627843,'address':'627843, EGL - WEST, WEST FAR, VILLA CRUISE TAVERN, 982 MADELIEFIE ST, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20530,'lng':27.71400}},
{'stp':629152,'address':'629152, EGL - WEST, WEST FAR, XIMBAS LIQUOR PLACE, 1124 NOKWE ST, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22540,'lng':27.70090}},
{'stp':630628,'address':'630628, EGL - WEST, WEST FAR, SLENDERS PALACE, 367 EAST EXT 1, SEEME STREET, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.22950,'lng':27.70170}},
{'stp':633959,'address':'633959, EGL - WEST, WEST FAR, NAKS INN, 12216 DRAGON STREET, EXTENSION 7, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.22720,'lng':27.71260}},
{'stp':634138,'address':'634138, EGL - WEST, WEST FAR, LEFTY S PLACE, 1445 PHUKWILE STREET, MOHLAKENG, RANDFONTEIN, MOHLAKENG, South Africa','geo':{'lat':-26.22248,'lng':27.69976}},
{'stp':634366,'address':'634366, EGL - WEST, WEST FAR, ZETTA S PLACE, ERF 2419 BANANA STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.21110,'lng':27.71013}},
{'stp':634728,'address':'634728, EGL - WEST, WEST FAR, NYATHI\'S TAVERN, 1687 LOOD STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20970,'lng':27.70410}},
{'stp':634729,'address':'634729, EGL - WEST, WEST FAR, JACOBS TAVERN, 1119 MADELIEFIE STREET, TOEKOMSRUS, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.20790,'lng':27.71160}},
{'stp':635181,'address':'635181, EGL - WEST, WEST FAR, SAMBOS PLACE, 12100 JUVENTUR STREET, MOHLAKENG, EXT 7, MOHLAKENG, South Africa','geo':{'lat':-26.22920,'lng':27.71140}},
{'stp':636952,'address':'636952, EGL - WEST, WEST FAR, DONNY\'S TAVERN, 72 RUBY STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20280,'lng':27.71690}},
{'stp':637071,'address':'637071, EGL - WEST, WEST FAR, LORETTA\'S TAVERN, 17 STRAWBERRRY STREET, STAND 1593, TOEKOMSRUS, RANDFONTEIN, South Africa','geo':{'lat':-26.20570,'lng':27.71190}},
{'stp':637933,'address':'637933, EGL - WEST, WEST FAR, JAYCEE\'S TAVERN, 4956 KENT MASIRE STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23599,'lng':27.69365}},
{'stp':638711,'address':'638711, EGL - WEST, WEST FAR, TOEKOMSRUS BOTTLE STORE, STANDS 1545 & 1546, CNR DIAMOND & GOLD STREET, TOEKOMSRUS, RANDFONTEIN, South Africa','geo':{'lat':-26.20460,'lng':27.70460}},
{'stp':641542,'address':'641542, EGL - WEST, WEST FAR, STRESS FREE, 11930 ARSENAL STREET, Ext 7, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22755,'lng':27.70931}},
{'stp':641770,'address':'641770, EGL - WEST, WEST FAR, SAGE, 1497 PHUKLULE STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22161,'lng':27.69993}},
{'stp':642397,'address':'642397, EGL - WEST, WEST FAR, DANIEL\'S TAVERN, 709 PEER STREET, TOEKOMSRUS, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.20410,'lng':27.70970}},
{'stp':642403,'address':'642403, EGL - WEST, WEST FAR, PHOLAS, 3437 NHLAPO STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22860,'lng':27.70140}},
{'stp':642411,'address':'642411, EGL - WEST, WEST FAR, MAMODISE\'S PLACE, 1853 MABOE STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22014,'lng':27.70260}},
{'stp':642420,'address':'642420, EGL - WEST, WEST FAR, BROWN PLACE, 565 NHLAPO STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22937,'lng':27.69514}},
{'stp':642424,'address':'642424, EGL - WEST, WEST FAR, KHOMA S PLACE, 2597 KGAMA STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23292,'lng':27.69342}},
{'stp':642669,'address':'642669, EGL - WEST, WEST FAR, SWEETIE\'S PLACE, 1948 STORMRIVIER STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.21085,'lng':27.70962}},
{'stp':642678,'address':'642678, EGL - WEST, WEST FAR, ANDILES PLACE, 2023 NONKWE STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23438,'lng':27.69864}},
{'stp':642683,'address':'642683, EGL - WEST, WEST FAR, CROWS SHEBEEN, 3382 NTULI STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23382,'lng':27.69311}},
{'stp':642690,'address':'642690, EGL - WEST, WEST FAR, BELLAS PLACE, 467 DIAMOND STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20457,'lng':27.71541}},
{'stp':646388,'address':'646388, EGL - WEST, WEST FAR, EMILYS PLACE, 3699 NDABAZABANTU STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.21940,'lng':27.69430}},
{'stp':646477,'address':'646477, EGL - WEST, WEST FAR, MAITISONG TAVERN, 5411 RAMOSIA STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23680,'lng':27.68580}},
{'stp':646571,'address':'646571, EGL - WEST, WEST FAR, REGGIE\'S PLACE, 1463 MAMABOLO STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22423,'lng':27.69887}},
{'stp':646706,'address':'646706, EGL - WEST, WEST FAR, MASAKENG, 5974 NDOGENI STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.24262,'lng':27.69761}},
{'stp':646870,'address':'646870, EGL - WEST, WEST FAR, TSHIDIS PLACE, 6053 NGONGENI STREET, EXTENTION 4, #, RANDFONTEIN, South Africa','geo':{'lat':-26.24299,'lng':27.69818}},
{'stp':647750,'address':'647750, EGL - WEST, WEST FAR, MARGARET PEACE INN, 2004 KRAAIRIVIER STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20994,'lng':27.71803}},
{'stp':648401,'address':'648401, EGL - WEST, WEST FAR, CHEAP INN, 1134 MADELIFIE STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20753,'lng':27.71344}},
{'stp':648404,'address':'648404, EGL - WEST, WEST FAR, WHY ME, 159 LIMPOPO STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20321,'lng':27.71520}},
{'stp':648675,'address':'648675, EGL - WEST, WEST FAR, RANTJIE\'S PLACE, 1324 KATJIEPIERING STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20836,'lng':27.70976}},
{'stp':649329,'address':'649329, EGL - WEST, WEST FAR, RAINIE\'S PLACE, 1242 PLAKKIES STREET, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20825,'lng':27.71084}},
{'stp':650566,'address':'650566, EGL - WEST, WEST FAR, BIEDIES, 420 DIAMOND STREET, #, TOEKOMSRUS, RANDFONTEIN, South Africa','geo':{'lat':-26.20345,'lng':27.71327}},
{'stp':651137,'address':'651137, EGL - WEST, WEST FAR, JEANETTES PLACE, 4234 MAHLANGU CRESCENT, EXTENTION 3, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.21835,'lng':27.69230}},
{'stp':651666,'address':'651666, EGL - WEST, WEST FAR, GOING UP TUCK AND TAVERN, 1678 SEME STREET, MOHLAKENG 1759, #, MOHLAKENG, South Africa','geo':{'lat':-26.22220,'lng':27.70289}},
{'stp':652886,'address':'652886, EGL - WEST, WEST FAR, SHOPRITE TOEKOMSRUS 54635, 1517 CLINIC STREET, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20430,'lng':27.70560}},
{'stp':652980,'address':'652980, EGL - WEST, WEST FAR, THOKOS SHEBEEN, 861 RALERATA STREET, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.22540,'lng':27.69695}},
{'stp':653284,'address':'653284, EGL - WEST, WEST FAR, EARLY BIRD TUCKSHOP, 354 VIGRO STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20216,'lng':27.71173}},
{'stp':653335,'address':'653335, EGL - WEST, WEST FAR, PETERS TUCKSHOP, 7365 SEME STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.21999,'lng':27.70329}},
{'stp':655386,'address':'655386, EGL - WEST, WEST FAR, PHOMOLONG TAVERN, 0 7775 KHUZE STREET, EXTENSION 5, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.23740,'lng':27.69270}},
{'stp':666121,'address':'666121, EGL - WEST, WEST FAR, STRESS FREE TOEKOMSRUS, 1325 KATJIEPIERING STREET, #, TOEKOMSRUS, RANDFONTEIN, South Africa','geo':{'lat':-26.20833,'lng':27.70987}},
{'stp':666434,'address':'666434, EGL - WEST, WEST FAR, CHOCOLETE PLACE, 811 STAND NO, XUMA STREET MOHLAKENG RANDFONTEIN, #, MOHLAKENG, South Africa','geo':{'lat':-26.22829,'lng':27.69698}},
{'stp':668882,'address':'668882, EGL - WEST, WEST FAR, Toekomsrus Tavern (NTO), Stand 1545 Cnr Diamond, and Goud Street, Toekomrus Randfontein, RANDFONTEIN, South Africa','geo':{'lat':-26.20471,'lng':27.70457}},
{'stp':745457,'address':'745457, EGL - WEST, WEST FAR, PRO GLEN LIQUOR STORE, SHOP NUMBER 2, PROTEA GLEN SHOPPING MALL, CORNER R558, EXTENSION 11-12, KAGISO, South Africa','geo':{'lat':-26.27440,'lng':27.81220}},
{'stp':757690,'address':'757690, EGL - WEST, WEST FAR, MORARA S INN, 115 STAND NO, DLAMINI STRAAT MOHLAKENG RANDFONTEIN, #, MOHLAKENG, South Africa','geo':{'lat':-26.22320,'lng':27.69316}},
{'stp':760186,'address':'760186, EGL - WEST, WEST FAR, LIQUOR CITY KAGISO, STAND NO, PORTION 5 OF ERF 15048, SHOP NO SH32, KAGISO, South Africa','geo':{'lat':-26.16660,'lng':27.78060}},
{'stp':762244,'address':'762244, EGL - WEST, WEST FAR, B AND B SHEBEEN, STAND NO 14119, MOHLAKENG EXT 11, RANDFOTNEIN, MOHLAKENG, South Africa','geo':{'lat':-26.22980,'lng':27.68305}},
{'stp':764172,'address':'764172, EGL - WEST, WEST FAR, SULALA S PLACE, 1094 STAND NO, ROSE STREET  TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20682,'lng':27.71497}},
{'stp':764764,'address':'764764, EGL - WEST, WEST FAR, DAVID S PLACE, 2244 MOLETSANE STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23241,'lng':27.69882}},
{'stp':768308,'address':'768308, EGL - WEST, WEST FAR, SHOPRITE GLENRIDGE MALL 32873, STAND NO, SHOP 9 GLEN RIDGE CENTRE, CNR R558 &. SUNSET DRIVE PROTEA GLEN, PROTEA, South Africa','geo':{'lat':-26.26920,'lng':27.78980}},
{'stp':777612,'address':'777612, EGL - WEST, WEST FAR, KO JEFF, 1227 NOKWE STREET, #, #, MOHLAKENG, South Africa','geo':{'lat':-26.22741,'lng':27.70142}},
{'stp':779527,'address':'779527, EGL - WEST, WEST FAR, ALL IN ONE TAVERN, 198 STAND NO, DORIUS MHLANGO STR EXT 7 MOHLAKENG, RANDFONTEIN, MOHLAKENG, South Africa','geo':{'lat':-26.21730,'lng':27.70671}},
{'stp':782458,'address':'782458, EGL - WEST, WEST FAR, JOHNNY S INN, 3097 STAND NO, LEMBEDE STREET MOHLAKENG, RANDFONTEIN, MOHLAKENG, South Africa','geo':{'lat':-26.22975,'lng':27.69139}},
{'stp':785200,'address':'785200, EGL - WEST, WEST FAR, MARIKANA PUB AND GRILL, 3675 STAND NUMBER, SECHOARO STREET, RANDFONTEIN, MOHLAKENG, South Africa','geo':{'lat':-26.21944,'lng':27.69481}},
{'stp':786050,'address':'786050, EGL - WEST, WEST FAR, PHUMELELE S TAVERN, 14066 STAND NO, EXT 11, #, MOHLAKENG, South Africa','geo':{'lat':-26.22942,'lng':27.68209}},
{'stp':318483,'address':'318483, EGL - WEST, WEST FAR, CHECKERS LIQUOR SHOP TAMBOTIE MALL, STAND NUMBER, 1 TAMBOTIE STREET CNR, MAIN STREET, RANDFONTEIN, South Africa','geo':{'lat':-26.17216,'lng':27.69104}},
{'stp':383238,'address':'383238, EGL - WEST, WEST FAR, PICK N PAY GM10 GREENHILLS RANDFONT, STAND NUMBER, ERF 2211 2212, CORNER NORTHWAY, RANDFONTEIN, South Africa','geo':{'lat':-26.15521,'lng':27.69892}},
{'stp':388543,'address':'388543, EGL - WEST, WEST FAR, SHOOTERS PUB 2 (NTO), 67 ERF NO: 4002, EDWARDS AVENUE, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31880,'lng':27.65030}},
{'stp':392559,'address':'392559, EGL - WEST, WEST FAR, OCEAN BASKET - RANDFONTEIN (NTO), 4 HOMESTEAD AVENUE, HOMELAKE, #, RANDFONTEIN, South Africa','geo':{'lat':-26.17299,'lng':27.69078}},
{'stp':460012,'address':'460012, EGL - WEST, WEST FAR, TOPS AT SPAR GREENHILLS, STAND NO, ERF 2201, NO 7 SOUTHERN CIRCLE, GRENHILLS, RANDFONTEIN, South Africa','geo':{'lat':-26.16660,'lng':27.69878}},
{'stp':550016,'address':'550016, EGL - WEST, WEST FAR, GREENHILLS LIQUOR STORE, 108 STAND NUMBER, SHOP NUMBER 37, NASHET BUILDING, RANDFONTEIN, South Africa','geo':{'lat':-26.16560,'lng':27.69810}},
{'stp':550033,'address':'550033, EGL - WEST, WEST FAR, ORIBI PUB AND COUNTRY RESTAURANT, NO 2 CONVENT STREET, GREENHILLS, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.16630,'lng':27.70450}},
{'stp':550911,'address':'550911, EGL - WEST, WEST FAR, Piet se Gat, C/O KINGFISHER AVE & KORHAAN, HELIKON PARK, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.18000,'lng':27.68380}},
{'stp':551046,'address':'551046, EGL - WEST, WEST FAR, UNCLE HARRYS RESTAURANT, FARM UITVALFONTEIN 244, CNR R28&R41 REEF ROADS, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.15610,'lng':27.71960}},
{'stp':551450,'address':'551450, EGL - WEST, WEST FAR, D J LIQUOR STORE, NO 27 UNION STREET, BEING STAND NO 641, RANDGATE, RANDFONTEIN, South Africa','geo':{'lat':-26.17010,'lng':27.68500}},
{'stp':551486,'address':'551486, EGL - WEST, WEST FAR, PILLAYS LIQUOR STORE, NO 9 KAMEEL STREET, GREENHILLS, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.15980,'lng':27.69230}},
{'stp':552384,'address':'552384, EGL - WEST, WEST FAR, RANDFONTEIN RUGBY KLUB, HF VERWOERD STADION, NOORD AND HOMSTEAD STRS, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.16380,'lng':27.70490}},
{'stp':552503,'address':'552503, EGL - WEST, WEST FAR, LEOPARD CRAWL, STAND NO 943, SHINGALANA CENTRE, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.17370,'lng':27.70830}},
{'stp':552761,'address':'552761, EGL - WEST, WEST FAR, SAN PEDRO SPUR STEAK HOUSE, CNR HOMESTEAD & KENNETH AVE, RANDFONTEIN, #, RANDFONTEIN, South Africa','geo':{'lat':-26.17030,'lng':27.69940}},
{'stp':553510,'address':'553510, EGL - WEST, WEST FAR, DWARSKLOOF BOTTLE STORE, 26 MARKGRAAF STR & FLIP vd WESTHUIZ, DWARSKLOOF, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.16600,'lng':27.62220}},
{'stp':560605,'address':'560605, EGL - WEST, WEST FAR, ROUND THE CORNER LIQUOR, GATEWAY SHOPPING CENTRE, SHOP 142 MAIN REEF ROAD, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.16580,'lng':27.71190}},
{'stp':629775,'address':'629775, EGL - WEST, WEST FAR, CULEMBORG LIQUOR CITY EXPRESS, 54 STAND NO, TULBACH AVENUE, #, RANDFONTEIN, South Africa','geo':{'lat':-26.17637,'lng':27.69309}},
{'stp':634569,'address':'634569, EGL - WEST, WEST FAR, CAPTAIN LIQUOR DISTRIBUTORS, 83 FEDLER STREET, RANDFONTIEN, #, RANDFONTEIN, South Africa','geo':{'lat':-26.19090,'lng':27.69340}},
{'stp':637885,'address':'637885, EGL - WEST, WEST FAR, JOHNNYS BOTTLE STORE, 31 PLOT NO, BARNDVLEI R47 ROAD, BRANDVLEI, RANDFONTEIN, South Africa','geo':{'lat':-26.13381,'lng':27.59157}},
{'stp':640677,'address':'640677, EGL - WEST, WEST FAR, GOODFELLAS CARLTONVILLE (NTO), VAN ZYL SMITSTREET, OBERHOLZER, #, CARLTONVILLE, South Africa','geo':{'lat':-26.40217,'lng':27.38483}},
{'stp':652271,'address':'652271, EGL - WEST, WEST FAR, TOPS AT RANDGATE, CNR UNION AND SMUTS STREETS, RANDGATE, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.17220,'lng':27.68470}},
{'stp':659051,'address':'659051, EGL - WEST, WEST FAR, WATERPAN GOLF CLUB (NTO), EZULIWENI FIRST MINE, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31978,'lng':27.65119}},
{'stp':663148,'address':'663148, EGL - WEST, WEST FAR, Johannesburg Skydiving Club (NTO), Farm 12 Portion 2, Carltonville, #, CARLTONVILLE, South Africa','geo':{'lat':-26.41804,'lng':27.72137}},
{'stp':668956,'address':'668956, EGL - WEST, WEST FAR, Webo\'s (NTO), 27 Keurboom straat, Fochville, #, CARLTONVILLE, South Africa','geo':{'lat':-26.47310,'lng':27.49338}},
{'stp':669445,'address':'669445, EGL - WEST, WEST FAR, CROCODILIAN RESTAURANT (NTO), FARM WELTEVREDEN 357, CARLETONVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.48423,'lng':27.53876}},
{'stp':681980,'address':'681980, EGL - WEST, WEST FAR, Rant en Dal Sports Bar (NTO), Shop 8 Rant en Dal Centre, Cnr Cecil Knight & Nightingale, Krugersdorp, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07876,'lng':27.77973}},
{'stp':690100,'address':'690100, EGL - WEST, WEST FAR, HELIKON PARK BLUE BOTTLE LIQUORS, 4 SHOP, SELECT SHOPPING CENTRE, CARMORONT STREET HELIKON PARK, RANDFONTEIN, South Africa','geo':{'lat':-26.18010,'lng':27.68460}},
{'stp':696911,'address':'696911, EGL - WEST, WEST FAR, BOULEVARD RESTAURANT, KAMEEL STREET, SHOP 7 AND 9, #, RANDFONTEIN, South Africa','geo':{'lat':-26.16070,'lng':27.69260}},
{'stp':703254,'address':'703254, EGL - WEST, WEST FAR, OU VANS PUB AND GRILL, SHOP NO 1 AND 2, PALM CENTRE  SOUTH CIRCLE, GREENHILLS, RANDFONTEIN, South Africa','geo':{'lat':-26.16650,'lng':27.69800}},
{'stp':716176,'address':'716176, EGL - WEST, WEST FAR, SPIDERS WED (NTO), OLD POST OFFICE BUILDING, DRIFT BOULEVARD, MULDERSDRIFT, MULDERSDRIFT, South Africa','geo':{'lat':-26.03650,'lng':27.84830}},
{'stp':728255,'address':'728255, EGL - WEST, WEST FAR, WARRIOR\'S ARM (NTO), RESTUARANT 182, PORTION 12 VAN WYK FARM, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.39000,'lng':27.50180}},
{'stp':738769,'address':'738769, EGL - WEST, WEST FAR, CROCODILE INN, 512 FARM VAALBANK, PORTION 71, REG DIVISION JQ, MAGALIESBURG, South Africa','geo':{'lat':-26.00038,'lng':27.49137}},
{'stp':752639,'address':'752639, EGL - WEST, WEST FAR, RANDFONTEIN BOWLING CLUB (NTO), RANDFONTEIN GOLF COURSE, HOMESTEAD LAAN, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.15620,'lng':27.71410}},
{'stp':760749,'address':'760749, EGL - WEST, WEST FAR, DROOGHEUWEL VENUE, STAND NO, PORTION 6 OF THE FARM DROOUGHEUWEL, #, RANDFONTEIN, South Africa','geo':{'lat':-26.19100,'lng':27.65410}},
{'stp':761662,'address':'761662, EGL - WEST, WEST FAR, SWING AND PUTTER (NTO), STAND NO:1041, 7 CHURCH STREET, FOCHVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49265,'lng':27.50156}},
{'stp':761663,'address':'761663, EGL - WEST, WEST FAR, RED CAP RANCH (NTO), R500 BETWEEN CARLTONVILLE, AND WESTONARIA, CARLTONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.50690,'lng':27.47480}},
{'stp':391894,'address':'391894, EGL - WEST, WEST FAR, TSHEPOS PLACE, 11861 STAND NO, COLTS STREET EXT 7, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.22616,'lng':27.71042}},
{'stp':553415,'address':'553415, EGL - WEST, WEST FAR, LAPA LOGA TAVERN, PLOT 93 BOTHA SMALL HOLDINGS, DWARSKLOOF, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.15758,'lng':27.67247}},
{'stp':560280,'address':'560280, EGL - WEST, WEST FAR, GRACES INN, 1913 MERCURY  AND ASTEROID STREET, TOEKOMRUS, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.21110,'lng':27.70820}},
{'stp':622795,'address':'622795, EGL - WEST, WEST FAR, DOLPHUS (NTO), 2546 GODLO STR, BEKKERSDAL, Westonaria, WESTONARIA, South Africa','geo':{'lat':-26.28667,'lng':27.70611}},
{'stp':626679,'address':'626679, EGL - WEST, WEST FAR, CJ\'S TAVERN, 449 HARTZ STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20490,'lng':27.71630}},
{'stp':642526,'address':'642526, EGL - WEST, WEST FAR, KOOS PUBLIC BAR, 566 APPLE STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20320,'lng':27.71080}},
{'stp':642682,'address':'642682, EGL - WEST, WEST FAR, MARTHIEN\'S PLACE, 1877 STORMRIVIER STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.21118,'lng':27.71080}},
{'stp':646744,'address':'646744, EGL - WEST, WEST FAR, SIDIKIS PLACE, 6449 KABELO STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.24610,'lng':27.69666}},
{'stp':647607,'address':'647607, EGL - WEST, WEST FAR, MAKI\'S INN, 6613 SEBONE STREET, EXTENSION 4, #, RANDFONTEIN, South Africa','geo':{'lat':-26.23751,'lng':27.69779}},
{'stp':648412,'address':'648412, EGL - WEST, WEST FAR, BACK TO BACK, 6266 EXTENSION 4, GANYANE STREET, #, RANDFONTEIN, South Africa','geo':{'lat':-26.24528,'lng':27.69567}},
{'stp':648650,'address':'648650, EGL - WEST, WEST FAR, NANDO\'S PLACE, 6444 KABELO STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.24583,'lng':27.69498}},
{'stp':648880,'address':'648880, EGL - WEST, WEST FAR, MANANA\'S INN, 6610 SEBONE STREET, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.23764,'lng':27.69717}},
{'stp':649015,'address':'649015, EGL - WEST, WEST FAR, SHELA\'S PLACE, 6412 MOSINYI STREET, MOHLAKENG, EXT 4, MOHLAKENG, South Africa','geo':{'lat':-26.24529,'lng':27.69665}},
{'stp':649782,'address':'649782, EGL - WEST, WEST FAR, MAPULES INN, 557 APPLE STREET, TOEKOMRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20359,'lng':27.71193}},
{'stp':650120,'address':'650120, EGL - WEST, WEST FAR, MONATE TUCK SHOP, HANI STREET 546, ZENZELE, #, RANDFONTEIN, South Africa','geo':{'lat':-26.26087,'lng':27.69391}},
{'stp':651146,'address':'651146, EGL - WEST, WEST FAR, GIDEONS PLACE, 6498 RAMETSANE STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.23735,'lng':27.69771}},
{'stp':652971,'address':'652971, EGL - WEST, WEST FAR, SEMOKAS PLACE, 323 VENUS STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20155,'lng':27.71213}},
{'stp':652973,'address':'652973, EGL - WEST, WEST FAR, JOAES INN, 514 DIAMOND STREET, TOEKOMSRUS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20223,'lng':27.70963}},
{'stp':653331,'address':'653331, EGL - WEST, WEST FAR, VERONICAS INN, 6570 SEBONI CIRCLE, EXTENSION 4, #, RANDFONTEIN, South Africa','geo':{'lat':-26.23121,'lng':27.70088}},
{'stp':668985,'address':'668985, EGL - WEST, WEST FAR, Mnqarhwane (NTO), No 78 Bekkersdal Hostel, Westonaria, #, WESTONARIA, South Africa','geo':{'lat':-26.28453,'lng':27.70755}},
{'stp':677258,'address':'677258, EGL - WEST, WEST FAR, GAUTENG RESTAURANT (NTO), NO 5 ROAD, HILLSIDE, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.21919,'lng':27.63207}},
{'stp':763080,'address':'763080, EGL - WEST, WEST FAR, VIOLET S PLACE, 6982 RAMAPHALA STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.24070,'lng':27.69830}},
{'stp':786083,'address':'786083, EGL - WEST, WEST FAR, NORAHS INN, 681 STAND NO, PEACH STREET, TOEKOMSRUS, RANDFONTEIN, South Africa','geo':{'lat':-26.20439,'lng':27.71201}},
{'stp':794802,'address':'794802, EGL - WEST, WEST FAR, MR B INN, 103 STAND NO, RIETRIVIER STREET, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20343,'lng':27.71708}},
{'stp':389436,'address':'389436, EGL - WEST, WEST FAR, ROOF TOP JUNXION, 8588 STAND NO, EXTENSION 5, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.24251,'lng':27.69326}},
{'stp':390997,'address':'390997, EGL - WEST, WEST FAR, LEJAVAH S BAR AND GRILL, 141 ERF NO, DLAMINI STREET MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22485,'lng':27.69389}},
{'stp':391220,'address':'391220, EGL - WEST, WEST FAR, KARIBA PUB, 2195 STAND NO, THEBENARE STREET, #, MOHLAKENG, South Africa','geo':{'lat':-26.23030,'lng':27.69694}},
{'stp':392549,'address':'392549, EGL - WEST, WEST FAR, BRANDYS BAR, 51 PLOT NO, VENTERSDORP ROAD, WHEATLANDS, RANDFONTEIN, South Africa','geo':{'lat':-26.17380,'lng':27.64281}},
{'stp':550441,'address':'550441, EGL - WEST, WEST FAR, MANNYS LIQUOR STORE, VENTERSDORP ROAD, PLOT 51, WHEATLANDS, RANDFONTEIN, South Africa','geo':{'lat':-26.17380,'lng':27.64260}},
{'stp':553743,'address':'553743, EGL - WEST, WEST FAR, LACOSTES JOINT, STAND 386, DINGAAN STREET, MOHLAKENG, MOHLAKENG, South Africa','geo':{'lat':-26.24251,'lng':27.69326}},
{'stp':603100,'address':'603100, EGL - WEST, WEST FAR, MNTUNGWA\'S PLACE, 10957, PROTEA GLEN EXT 12, #, ROODEPOORT, South Africa','geo':{'lat':-26.28337,'lng':27.80485}},
{'stp':639981,'address':'639981, EGL - WEST, WEST FAR, THE V PUB, PORTION 2, RALERATA STREET, MOHLAKENG, RANDFONTAIN, MOHLAKENG, South Africa','geo':{'lat':-26.21780,'lng':27.69620}},
{'stp':640925,'address':'640925, EGL - WEST, WEST FAR, 6 FOR 9 RESTURANT, NO 649 NANDI STREET, MOHLAKENG, #, MOHLAKENG, South Africa','geo':{'lat':-26.22690,'lng':27.69640}},
{'stp':658221,'address':'658221, EGL - WEST, WEST FAR, KARIBA (NTO), 2196 THEBENARE STREET, MOHLAKENG, RANDFONTEIN, #, MOHLAKENG, South Africa','geo':{'lat':-26.23028,'lng':27.69693}},
{'stp':677395,'address':'677395, EGL - WEST, WEST FAR, JANES POOL CLUB, PROTEA GLEN EXT 11, ERF 11434, JOHANNESBURG, ROODEPOORT, South Africa','geo':{'lat':-26.27350,'lng':27.80400}},
{'stp':779228,'address':'779228, EGL - WEST, WEST FAR, SILINDILES PUB, STAND NO, ERF 3007, PROTEA GLEN EXT 2, ROODEPOORT, South Africa','geo':{'lat':-26.27698,'lng':27.82215}},
{'stp':616412,'address':'616412, EGL - WEST, WEST FAR, NGOS PLACE (NTO), 909 XHOSA SEC, KHUTSONG, Carletonville, CARLTONVILLE, South Africa','geo':{'lat':-26.32755,'lng':27.31904}},
{'stp':309197,'address':'309197, EGL - WEST, WEST FAR, MARIA SHEBEEN, 8698 STAND NO, MNANDINI, TSHEPISONG WEST, ROODEPOORT, South Africa','geo':{'lat':-26.19019,'lng':27.79360}},
{'stp':318491,'address':'318491, EGL - WEST, WEST FAR, RG SQUARE, 740 ERF NO, SLOVOVILLE, SEDIBENG, KAGISO, South Africa','geo':{'lat':-26.21727,'lng':27.77978}},
{'stp':320233,'address':'320233, EGL - WEST, WEST FAR, LETHABONG PUB, 2864 STAND NO, PHASE 2, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.19524,'lng':27.79833}},
{'stp':385803,'address':'385803, EGL - WEST, WEST FAR, LOUIS TUCKSHOP, 2569 ERF NO, TSHEPISONG PHASE 4, ROODEPOORT, KAGISO, South Africa','geo':{'lat':-26.18947,'lng':27.80646}},
{'stp':391807,'address':'391807, EGL - WEST, WEST FAR, SBUTHAS PLACE, 487 STAND NO, TSHEPISONG PAHSE 5, #, ROODEPOORT, South Africa','geo':{'lat':-26.18532,'lng':27.79556}},
{'stp':392856,'address':'392856, EGL - WEST, WEST FAR, NINIS PLACE, 166 STAND NO, CHRIS HANI STREET, #, ROODEPOORT, South Africa','geo':{'lat':-26.21420,'lng':27.78290}},
{'stp':475157,'address':'475157, EGL - WEST, WEST FAR, KWADAISE PLACE, 3738 STAND NO, TANA RIVER STREET, TSHEPISONG PHASE, ROODEPOORT, South Africa','geo':{'lat':-26.19606,'lng':27.80657}},
{'stp':481543,'address':'481543, EGL - WEST, WEST FAR, VENDA TAVERN, 2568 STAND NO, MOMBASA STREET, TSHEPISONG, RANDFONTEIN, South Africa','geo':{'lat':-26.18934,'lng':27.80641}},
{'stp':482280,'address':'482280, EGL - WEST, WEST FAR, TA COSAS PLACE, 3149 STAND NUMBER, PHASE 3, TSHEPISONG, KAGISO, South Africa','geo':{'lat':-26.19130,'lng':27.80385}},
{'stp':483226,'address':'483226, EGL - WEST, WEST FAR, T M TAVERN, 4253 STAND NO, TSHEPISONG, #, KAGISO, South Africa','geo':{'lat':-26.19738,'lng':27.80153}},
{'stp':484898,'address':'484898, EGL - WEST, WEST FAR, EBUMNANDINI TAVERN, 6337 STAND NO, TSHEPISONG, BLOCK 3, KAGISO, South Africa','geo':{'lat':-26.18644,'lng':27.78464}},
{'stp':488987,'address':'488987, EGL - WEST, WEST FAR, MATHENJWA BOTTLE STORE, 21214 ERF NO, PROTEA GLEN, #, PROTEA, South Africa','geo':{'lat':-26.27277,'lng':27.77480}},
{'stp':491622,'address':'491622, EGL - WEST, WEST FAR, MTABELAS TAVERN, 21430 ERF, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.23702,'lng':27.80627}},
{'stp':540392,'address':'540392, EGL - WEST, WEST FAR, ZONDIS PLACE, 879 STAND NO, GABORANE STREET, PHASE 5, ROODEPOORT, South Africa','geo':{'lat':-26.18622,'lng':27.80031}},
{'stp':540641,'address':'540641, EGL - WEST, WEST FAR, JBS LIQUOR STORE, 4708 PHASE 1, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.19933,'lng':27.80744}},
{'stp':542592,'address':'542592, EGL - WEST, WEST FAR, CHILL IN OUT MALUME LOUNGE, 48 STAND NO, THAKADU STREET, PORTION 48 OF ERF 8489 PROTEA GLEN, PROTEA, South Africa','geo':{'lat':-26.26520,'lng':27.80154}},
{'stp':550982,'address':'550982, EGL - WEST, WEST FAR, T AND T TAVERN, 4015 RUTH FIRST STREET, TSEPISONG, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.19790,'lng':27.79750}},
{'stp':552950,'address':'552950, EGL - WEST, WEST FAR, MAGULES PLACE, 46 SKOOMPLAAS, SLOVOVILLE, #, ROODEPOORT, South Africa','geo':{'lat':-26.21920,'lng':27.76520}},
{'stp':560524,'address':'560524, EGL - WEST, WEST FAR, MAITISONG, STAND 10376, CHANDELIER PLANT CRESCENT, PROTEA GLEN, ROODEPOORT, South Africa','geo':{'lat':-26.28340,'lng':27.80940}},
{'stp':561129,'address':'561129, EGL - WEST, WEST FAR, MTHES PLACE, 9245 EXTENSION 12, PROTEA GLEN, #, ROODEPOORT, South Africa','geo':{'lat':-26.27790,'lng':27.79880}},
{'stp':561242,'address':'561242, EGL - WEST, WEST FAR, BENS TAVERN, STAND 4726, TSHEPISONG PHASE 1, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.19860,'lng':27.80680}},
{'stp':561282,'address':'561282, EGL - WEST, WEST FAR, NTUTHUKO PLACE, 571 TSHEPISONG PHASE 5, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.18894,'lng':27.79820}},
{'stp':580453,'address':'580453, EGL - WEST, WEST FAR, BOYSIES PLACE, 2704 TSHEPISONG PHASE 2, ROODEPOORT, #, KAGISO, South Africa','geo':{'lat':-26.19520,'lng':27.79810}},
{'stp':581180,'address':'581180, EGL - WEST, WEST FAR, MAVI PLACE, 7046 PROTEA GLEN, EXT 11, #, ROODEPOORT, South Africa','geo':{'lat':-26.26798,'lng':27.79627}},
{'stp':603581,'address':'603581, EGL - WEST, WEST FAR, SABELOS TAVERN, 8991/89 PROTEA GLEN, EXT 11, CHIAWELO, ROODEPOORT, South Africa','geo':{'lat':-26.27220,'lng':27.80910}},
{'stp':603905,'address':'603905, EGL - WEST, WEST FAR, TSHEPISONG WESTS TAVERN, 1523 TSHEPISONG WEST, TSHEPISONG WEST, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.19370,'lng':27.79040}},
{'stp':603953,'address':'603953, EGL - WEST, WEST FAR, LUNGIS, 655 PHASE 5, TSHEPISONG, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.18800,'lng':27.80020}},
{'stp':604570,'address':'604570, EGL - WEST, WEST FAR, OUPAS INN, 5890 TSHEPISONG, PHASE 7, #, ROODEPOORT, South Africa','geo':{'lat':-26.19850,'lng':27.81270}},
{'stp':604863,'address':'604863, EGL - WEST, WEST FAR, NATHI S PLACE, 10023 STAND NO, PROTEA GLEN EXT 12, #, KAGISO, South Africa','geo':{'lat':-26.28060,'lng':27.79960}},
{'stp':606062,'address':'606062, EGL - WEST, WEST FAR, CORNER HOUSE SHOP, ERF 332/333/31 PHASE 6, TSHEPISONG, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.18340,'lng':27.79870}},
{'stp':607151,'address':'607151, EGL - WEST, WEST FAR, DLOMO S, 1837 PHASE 5, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.18834,'lng':27.80188}},
{'stp':626182,'address':'626182, EGL - WEST, WEST FAR, NTSOKS PLACE, 10225 STAND NO, EXTENTION 12, PROTEA, ROODEPOORT, South Africa','geo':{'lat':-26.27820,'lng':27.80810}},
{'stp':626972,'address':'626972, EGL - WEST, WEST FAR, NDLOVU TAVERN, 4121/18 PHASE 1, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.19690,'lng':27.79950}},
{'stp':626988,'address':'626988, EGL - WEST, WEST FAR, JABUS TAVERN, 2870 PHASE 2, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.19450,'lng':27.79840}},
{'stp':627110,'address':'627110, EGL - WEST, WEST FAR, KHOROMBIS TAVERN, 9131 STAND, PROTEA GLEN, EXT 12, ROODEPOORT, South Africa','geo':{'lat':-26.27850,'lng':27.80632}},
{'stp':627201,'address':'627201, EGL - WEST, WEST FAR, LUNAN TAVERN, 541 SLOVOVILLE, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.21810,'lng':27.77940}},
{'stp':627718,'address':'627718, EGL - WEST, WEST FAR, JACKSON SHEBEEN, 1754 TSHEPISONG WEST, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.19400,'lng':27.79200}},
{'stp':627796,'address':'627796, EGL - WEST, WEST FAR, KOKWANES TAVERN, 1242 PHASE 6, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.18270,'lng':27.80160}},
{'stp':628053,'address':'628053, EGL - WEST, WEST FAR, ROSE TUCKSHOP, 340 PHASE 6, TSHEPISONG TOWNSHIP, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.18310,'lng':27.79800}},
{'stp':630124,'address':'630124, EGL - WEST, WEST FAR, GIDJAS PLACE, 8887 PROTEA GLEN, EXTENSION 11, JOHANNESBURG, ROODEPOORT, South Africa','geo':{'lat':-26.27010,'lng':27.80790}},
{'stp':630218,'address':'630218, EGL - WEST, WEST FAR, TSHABALALA TUCK SHOP, 104 SLOVOVILLE, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.21540,'lng':27.78040}},
{'stp':630254,'address':'630254, EGL - WEST, WEST FAR, YVONNES PLACE, 210 TSHEPISONG PHASE 6, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.18410,'lng':27.79540}},
{'stp':631897,'address':'631897, EGL - WEST, WEST FAR, LIVHU\'S CORNER, PORTION 55 OF ERF 10392, PROTEA GLEN EXT 12, JOHANNESBURG, ROODEPOORT, South Africa','geo':{'lat':-26.28080,'lng':27.80760}},
{'stp':632411,'address':'632411, EGL - WEST, WEST FAR, MORITING, 8996/160 PROTEA GLEN, EXTENSION 11, JOHANNESBURG, ROODEPOORT, South Africa','geo':{'lat':-26.27340,'lng':27.80670}},
{'stp':632520,'address':'632520, EGL - WEST, WEST FAR, VEJA\'S INN, 5503 PHASE TSEPISONG, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.18860,'lng':27.81310}},
{'stp':632848,'address':'632848, EGL - WEST, WEST FAR, SOXER TAVERN, 4974 PHASE 7, TSEPISONG EAST, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.18850,'lng':27.81220}},
{'stp':632850,'address':'632850, EGL - WEST, WEST FAR, ZUMA\'S PLACE, 5835 TSHEPISONG PHASE 7, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.19290,'lng':27.81360}},
{'stp':633124,'address':'633124, EGL - WEST, WEST FAR, NTHABI\'S CORNER, 8550 CNR NEHI & RATLADI STREETS, PROTEA GLEN EXTENSION 11, JOHANNESBURG, ROODEPOORT, South Africa','geo':{'lat':-26.26890,'lng':27.80460}},
{'stp':633355,'address':'633355, EGL - WEST, WEST FAR, PAMELA\'S, 738 PHASE 5, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.18804,'lng':27.79671}},
{'stp':633433,'address':'633433, EGL - WEST, WEST FAR, NOKO\'S PLACE, 8187 NUNGU STREET, EXT 11 PROTEA GLEN, #, ROODEPOORT, South Africa','geo':{'lat':-26.27410,'lng':27.80350}},
{'stp':633903,'address':'633903, EGL - WEST, WEST FAR, KADJA KADJA PLACE, 7638 EBUMNANDINI STREET, TSEPISONG WEST, JOHANNESBURG, ROODEPOORT, South Africa','geo':{'lat':-26.19560,'lng':27.79460}},
{'stp':633924,'address':'633924, EGL - WEST, WEST FAR, ROSE PLACE, 7130 NDLOVU STREET, PROTEA GLEN,EXTENSION 11, JOHANNESBURG, ROODEPOORT, South Africa','geo':{'lat':-26.26580,'lng':27.79770}},
{'stp':634032,'address':'634032, EGL - WEST, WEST FAR, MAMNCANES PLACE, 162 STAND NO, MOYE STREET, LUFHERENG, KAGISO, South Africa','geo':{'lat':-26.23704,'lng':27.79802}},
{'stp':634190,'address':'634190, EGL - WEST, WEST FAR, ZWELIBONZI PLACE, 9756 STAND NO, 1 MULBERRY STREET, PROTEA GLEN EXTENSION 12, ROODEPOORT, South Africa','geo':{'lat':-26.27931,'lng':27.80092}},
{'stp':635051,'address':'635051, EGL - WEST, WEST FAR, FRANK\'S PLACE, 8466 INYALA STREET, EXTENSION 11, PROTEA GLEN, ROODEPOORT, South Africa','geo':{'lat':-26.27750,'lng':27.80640}},
{'stp':635225,'address':'635225, EGL - WEST, WEST FAR, SLATER\'S PLACE, 2541 PHASE 4, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.18800,'lng':27.80850}},
{'stp':639377,'address':'639377, EGL - WEST, WEST FAR, CONIES CORNER, 7907 TSHEPISONG  WEST, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.19400,'lng':27.79050}},
{'stp':640279,'address':'640279, EGL - WEST, WEST FAR, VUSI S SHEBEEN, ERF 167 SLOVOVILLE, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.21420,'lng':27.78290}},
{'stp':640362,'address':'640362, EGL - WEST, WEST FAR, ELS CORNER, 1071 TSHEPISONG PHASE 5, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.18620,'lng':27.79910}},
{'stp':642090,'address':'642090, EGL - WEST, WEST FAR, LORATO\'S PLACE, 10603 EXT 12, PROTEA GLEN, #, ROODEPOORT, South Africa','geo':{'lat':-26.28110,'lng':27.81010}},
{'stp':648122,'address':'648122, EGL - WEST, WEST FAR, EMMANUELS TAVERN, 15243 AMOZAN STREET, PROTEA GLEN EXT 16, #, KAGISO, South Africa','geo':{'lat':-26.26880,'lng':27.78650}},
{'stp':648918,'address':'648918, EGL - WEST, WEST FAR, STRESS FREE PLACE, 2283 PHASE 6, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.18430,'lng':27.80540}},
{'stp':650000,'address':'650000, EGL - WEST, WEST FAR, NTSIKIS PLACE, 2242 TSHEPISONG, PHASE 6, #, ROODEPOORT, South Africa','geo':{'lat':-26.18380,'lng':27.80700}},
{'stp':650212,'address':'650212, EGL - WEST, WEST FAR, NKELES INN, 2791 TSHEPOSONG PHASE 2, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.19420,'lng':27.80040}},
{'stp':650840,'address':'650840, EGL - WEST, WEST FAR, WOZA WEEKEND, 702 BULAWAYO STREET, PHASE 5, #, ROODEPOORT, South Africa','geo':{'lat':-26.18704,'lng':27.79666}},
{'stp':652586,'address':'652586, EGL - WEST, WEST FAR, DIMAMZOS HOUSE, 15408 STAND NO, ARUNDAL STREET 43 ALUMINIUM CRESCENT, GLENRIDGE EXT 16 PROTEA GLEN, PROTEA, South Africa','geo':{'lat':-26.27017,'lng':27.78321}},
{'stp':652811,'address':'652811, EGL - WEST, WEST FAR, MAFIKAS INN, 6881 PROTEA GLEN, EXT 11, #, ROODEPOORT, South Africa','geo':{'lat':-26.27290,'lng':27.79760}},
{'stp':653778,'address':'653778, EGL - WEST, WEST FAR, M LIFE SAVER, 11191 PROTEA GLEN, EXTENSION 12, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.28370,'lng':27.80960}},
{'stp':653833,'address':'653833, EGL - WEST, WEST FAR, ZAZAS PLACE, 1315 ALBERT LETHULI STREET, TSEPISONG, #, KAGISO, South Africa','geo':{'lat':-26.18320,'lng':27.80030}},
{'stp':653835,'address':'653835, EGL - WEST, WEST FAR, MLAMLI GENERAL DEALER, 1519 PHASE 6, #, #, KAGISO, South Africa','geo':{'lat':-26.18470,'lng':27.80340}},
{'stp':653836,'address':'653836, EGL - WEST, WEST FAR, TS TUCKSHOP, 8408 INYOLA STREET, EXTENSION 11, #, ROODEPOORT, South Africa','geo':{'lat':-26.27590,'lng':27.80500}},
{'stp':660544,'address':'660544, EGL - WEST, WEST FAR, OKUNANDI RESTAURANT (NTO), 10206 BOULEVARD STREET, PROTEA GLEN, #, KAGISO, South Africa','geo':{'lat':-26.27653,'lng':27.80797}},
{'stp':663115,'address':'663115, EGL - WEST, WEST FAR, KWA B (NTO), EXT 2 15372, GLENRIDGE, #, KAGISO, South Africa','geo':{'lat':-26.27161,'lng':27.78441}},
{'stp':676138,'address':'676138, EGL - WEST, WEST FAR, PAULAS TAVERN, 2298 PHASE 6, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.18360,'lng':27.80650}},
{'stp':681904,'address':'681904, EGL - WEST, WEST FAR, NYELETI TAVERN, STAND NO 5877, TSHEPISONG PHASE 7, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.19620,'lng':27.81290}},
{'stp':687571,'address':'687571, EGL - WEST, WEST FAR, VUSIS INN, OUPA MPETHA STREET, STAND NO 2048, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.18260,'lng':27.80470}},
{'stp':693178,'address':'693178, EGL - WEST, WEST FAR, BIG MAMAS INN, ERF NO 10756, #, PROTEA GLEN TOWNSHIP, ROODEPOORT, South Africa','geo':{'lat':-26.28300,'lng':27.80670}},
{'stp':700804,'address':'700804, EGL - WEST, WEST FAR, LINDIZWES PLACE, 323 LERATONG VILLAGE, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.17730,'lng':27.81560}},
{'stp':729337,'address':'729337, EGL - WEST, WEST FAR, MONGANO TAVERN, 1432 MNANDINI, TSHEPISONG, #, ROODEPOORT, South Africa','geo':{'lat':-26.19320,'lng':27.78800}},
{'stp':764410,'address':'764410, EGL - WEST, WEST FAR, CISCO\'S TAVERN, 1926 stand no, TSHEDZA STREET, #, ROODEPOORT, South Africa','geo':{'lat':-26.24290,'lng':27.80750}},
{'stp':767231,'address':'767231, EGL - WEST, WEST FAR, RAKAUS PLACE, STAND NO, ERF 845, TSHEPISONG TOWNSHIP, ROODEPOORT, South Africa','geo':{'lat':-26.18611,'lng':27.80024}},
{'stp':771293,'address':'771293, EGL - WEST, WEST FAR, NOMSAS PLACE, STAND NO, PROTEA GLEN, EXT 11, ROODEPOORT, South Africa','geo':{'lat':-26.27180,'lng':27.80437}},
{'stp':772158,'address':'772158, EGL - WEST, WEST FAR, PONTSHO PLACE, 7320 KUKAMA STREET, PROTEA GLEN EXT 11, #, KAGISO, South Africa','geo':{'lat':-26.27178,'lng':27.79762}},
{'stp':772794,'address':'772794, EGL - WEST, WEST FAR, DINKELES AND SMANHA TAVERN, 658 STAND NO, OKAPI STREET, SLOVOVILLE, ROODEPOORT, South Africa','geo':{'lat':-26.21763,'lng':27.77903}},
{'stp':773031,'address':'773031, EGL - WEST, WEST FAR, HANGWIS PLACE, 1901 STAND NO, MOYE STREET, #, ROODEPOORT, South Africa','geo':{'lat':-26.23359,'lng':27.79883}},
{'stp':774630,'address':'774630, EGL - WEST, WEST FAR, LERATONG VILLAGE, 47D STAND NO, LERATONG VILLAGE KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.17704,'lng':27.81219}},
{'stp':390150,'address':'390150, EGL - WEST, WEST NEAR, THE JOINT LOUNGE, 15049 ERF, PORTION 45, KAGISO EXTENTSION6, KAGISO, South Africa','geo':{'lat':-26.16534,'lng':27.77890}},
{'stp':461744,'address':'461744, EGL - WEST, WEST NEAR, HOMOS TAVERN, 832 STAND NO, MOSS STREET, #, KAGISO, South Africa','geo':{'lat':-26.19649,'lng':27.76854}},
{'stp':470835,'address':'470835, EGL - WEST, WEST NEAR, JEFF MAXS TAVERN, 2792 STAND NO, KAGISO EXT 2, #, KAGISO, South Africa','geo':{'lat':-26.16002,'lng':27.78884}},
{'stp':489161,'address':'489161, EGL - WEST, WEST NEAR, ZAMA OKWAKHE TAVERN, 3219 ERF NO, KAGISO UITBR 2, #, KAGISO, South Africa','geo':{'lat':-26.16092,'lng':27.78689}},
{'stp':541190,'address':'541190, EGL - WEST, WEST NEAR, MADAM X TAVERN, 1201 STAND NO, SLOVOVILLE SOUTH, #, ROODEPOORT, South Africa','geo':{'lat':-26.21800,'lng':27.77656}},
{'stp':550090,'address':'550090, EGL - WEST, WEST NEAR, LADYS INN, 11451 HOSPITAL VIEW, KAGISO EXT 6, #, KAGISO, South Africa','geo':{'lat':-26.16910,'lng':27.78930}},
{'stp':550141,'address':'550141, EGL - WEST, WEST NEAR, MEISIES INN, 476 SANDPIPER STREET, EXTENTION 1, #, KAGISO, South Africa','geo':{'lat':-26.19330,'lng':27.77010}},
{'stp':550230,'address':'550230, EGL - WEST, WEST NEAR, PUNAS, 19590 PHUMLA STREET, EXT 9, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17080,'lng':27.78130}},
{'stp':550302,'address':'550302, EGL - WEST, WEST NEAR, ISIPOSENKOSI TAVERN, 1951 NIGER STREET, SWANEVILLE, #, KAGISO, South Africa','geo':{'lat':-26.20140,'lng':27.76150}},
{'stp':550304,'address':'550304, EGL - WEST, WEST NEAR, GERTYS INN, STAND 0891 LUSAKA EXT 1, SWANIEVILLE, #, KAGISO, South Africa','geo':{'lat':-26.19440,'lng':27.76770}},
{'stp':550694,'address':'550694, EGL - WEST, WEST NEAR, THABOS PLACE, 16343/22 LIONS HEAD STREET, EXT 12, #, KAGISO, South Africa','geo':{'lat':-26.17280,'lng':27.79430}},
{'stp':551131,'address':'551131, EGL - WEST, WEST NEAR, STOMPIS PLACE, 4975 MANYANE STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15900,'lng':27.77070}},
{'stp':551497,'address':'551497, EGL - WEST, WEST NEAR, CAPE FLATS PLACE, STAND 5012, MOTSWENI STREET, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15990,'lng':27.77010}},
{'stp':551637,'address':'551637, EGL - WEST, WEST NEAR, OUMAS INN, 6340 SOBHUZA STREET, EAST PARK, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15850,'lng':27.79030}},
{'stp':552131,'address':'552131, EGL - WEST, WEST NEAR, TSOTSI JAZZ HOUSE, 5091 MEOGONG STREET, #, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15930,'lng':27.76760}},
{'stp':552182,'address':'552182, EGL - WEST, WEST NEAR, MISSIPPI INN, STAND 11186, EXT 2 KAGISO, KRUGERSDORP, KAGISO, South Africa','geo':{'lat':-26.16360,'lng':27.77540}},
{'stp':552417,'address':'552417, EGL - WEST, WEST NEAR, CAMRY PLACE, 4881 MEAGONG STREET, #, #, KAGISO, South Africa','geo':{'lat':-26.15930,'lng':27.76760}},
{'stp':552563,'address':'552563, EGL - WEST, WEST NEAR, MOONLIGHT INN, STAND 3062, SIPHO DRIVE, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.16084,'lng':27.78696}},
{'stp':552646,'address':'552646, EGL - WEST, WEST NEAR, KWA MAGOGO, STAND 2031, SWANEVILLE, KAGISO, KAGISO, South Africa','geo':{'lat':-26.20012,'lng':27.76298}},
{'stp':553007,'address':'553007, EGL - WEST, WEST NEAR, LESOTHO SUN TAVERN, 4821 STAND NO, BOIKETLO STREET  EXT 2 KAGISO, KRUGERSDORP, KAGISO, South Africa','geo':{'lat':-26.15990,'lng':27.76800}},
{'stp':553017,'address':'553017, EGL - WEST, WEST NEAR, TSHOLOFELO LIQUOR STORE, SHOP NO 5 MOKALE SHOPPING CENT, CNR TEMBA DRIVE & HOY STREETS, KAGISO - EXT 2, KAGISO, South Africa','geo':{'lat':-26.16415,'lng':27.79080}},
{'stp':553134,'address':'553134, EGL - WEST, WEST NEAR, KWASHISANYAMA, 2784 EKUTHULENI STREET, KAGISO 2, KRUGERSDORP, KAGISO, South Africa','geo':{'lat':-26.15940,'lng':27.78880}},
{'stp':553208,'address':'553208, EGL - WEST, WEST NEAR, EMPRESS INN TAVERN, STAND 2001 LUSAKA CITY EXT 1, MURRY LOOP STREET, LUSAKA CITY, KAGISO, South Africa','geo':{'lat':-26.20010,'lng':27.76110}},
{'stp':553217,'address':'553217, EGL - WEST, WEST NEAR, MOBBS PLACE, 4859 MEAGONG STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15980,'lng':27.76980}},
{'stp':553389,'address':'553389, EGL - WEST, WEST NEAR, B ENTERTAINMENT CENTRE, STAND 31 HERON STREET, SWANIEVILLE, #, KAGISO, South Africa','geo':{'lat':-26.18930,'lng':27.76520}},
{'stp':553772,'address':'553772, EGL - WEST, WEST NEAR, TARAS PLACE, 1869 KAIGISO AVENUE, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16280,'lng':27.78040}},
{'stp':560301,'address':'560301, EGL - WEST, WEST NEAR, SOLLYS PLACE, 20/3363 MEJE STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16120,'lng':27.78380}},
{'stp':560421,'address':'560421, EGL - WEST, WEST NEAR, TUIS CORNER, 11235 UMOLOZI CRESCENT, KAGISO EXT 6, #, KAGISO, South Africa','geo':{'lat':-26.16440,'lng':27.77490}},
{'stp':560436,'address':'560436, EGL - WEST, WEST NEAR, THOTHIS PLACE, 6339 SENZANGAKHONE STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15840,'lng':27.79060}},
{'stp':560531,'address':'560531, EGL - WEST, WEST NEAR, VENUS LIQUORS, STAND 3394, KAGISO AVENUE, KAGISO, KAGISO, South Africa','geo':{'lat':-26.16285,'lng':27.78048}},
{'stp':561287,'address':'561287, EGL - WEST, WEST NEAR, MALAMBAS PLACE, 6709 MEHLOMAKHULU ST, EAST PARK, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16520,'lng':27.78970}},
{'stp':580791,'address':'580791, EGL - WEST, WEST NEAR, MEISIS PLACE, STAND 4639, MOGOROSI ST, #, KAGISO, South Africa','geo':{'lat':-26.15920,'lng':27.77410}},
{'stp':580800,'address':'580800, EGL - WEST, WEST NEAR, MAEBENG 16 SHEBEEN, STAND 4344, SETLOLAMATHE STREET, #, KAGISO, South Africa','geo':{'lat':-26.16050,'lng':27.77600}},
{'stp':581104,'address':'581104, EGL - WEST, WEST NEAR, NKELES PLACE, 10896 EXT 6, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16360,'lng':27.77100}},
{'stp':581225,'address':'581225, EGL - WEST, WEST NEAR, MASOKHELAS SMALL TAVERN, 2315 ONDLA STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15590,'lng':27.78830}},
{'stp':581271,'address':'581271, EGL - WEST, WEST NEAR, TMANS PLACE, STAND 2510, DLAMINI STREET, KAGISO, KAGISO, South Africa','geo':{'lat':-26.15700,'lng':27.78840}},
{'stp':603311,'address':'603311, EGL - WEST, WEST NEAR, PATTYS PLACE, 2478 CETSHWAYO  ST, KAGISO 2, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.21460,'lng':27.78020}},
{'stp':603703,'address':'603703, EGL - WEST, WEST NEAR, MAVO S INN, 4059 STAND NO, SETLOLAMATHE STREET KAGISO CENTRAL, #, KAGISO, South Africa','geo':{'lat':-26.15850,'lng':27.77660}},
{'stp':606889,'address':'606889, EGL - WEST, WEST NEAR, BUCKS PLACE, 1905 KAMAGORO ST, KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.16190,'lng':27.78030}},
{'stp':625941,'address':'625941, EGL - WEST, WEST NEAR, NCAMS PUB AND RESTAURANT, 2835 SILA STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15920,'lng':27.78710}},
{'stp':626713,'address':'626713, EGL - WEST, WEST NEAR, GENERATIONS, 2654 ACOMOTSHE STREET, KAGISO2, #, KAGISO, South Africa','geo':{'lat':-26.15770,'lng':27.78430}},
{'stp':626714,'address':'626714, EGL - WEST, WEST NEAR, MJOZI, 2670 ACOMOTSHE STREET, KAGISO2, #, KAGISO, South Africa','geo':{'lat':-26.15820,'lng':27.78380}},
{'stp':626731,'address':'626731, EGL - WEST, WEST NEAR, KHETHIS INN, 2966 KAGISO AVE, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16027,'lng':27.78209}},
{'stp':627057,'address':'627057, EGL - WEST, WEST NEAR, CONNYS INN, 2274 ONDLHA DRIVE, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15590,'lng':27.78820}},
{'stp':627058,'address':'627058, EGL - WEST, WEST NEAR, STERAS PLACE, 2409 BHUNGANE STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15600,'lng':27.78710}},
{'stp':627059,'address':'627059, EGL - WEST, WEST NEAR, RODS SHEBEEN, 2492 DUBULAMANZI STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15690,'lng':27.78780}},
{'stp':627111,'address':'627111, EGL - WEST, WEST NEAR, KK PUB, ERF 814, SLOVOVILLE, ROODEPOORT SOUTH, ROODEPOORT, South Africa','geo':{'lat':-26.21710,'lng':27.77570}},
{'stp':627566,'address':'627566, EGL - WEST, WEST NEAR, MOLAPOS SHEBEEN, 4561 MANAMALADI ST, KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.16020,'lng':27.77550}},
{'stp':628314,'address':'628314, EGL - WEST, WEST NEAR, LUDONGA TAVERN, BLOCK 4 RIETVALLEI, INGRID ST 691, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.19370,'lng':27.77150}},
{'stp':629632,'address':'629632, EGL - WEST, WEST NEAR, WONAI PUB, 6468 MZIMANDE STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16099,'lng':27.79172}},
{'stp':630121,'address':'630121, EGL - WEST, WEST NEAR, OUMAKIS INN, 6837 MPINGA ST, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16540,'lng':27.79290}},
{'stp':630179,'address':'630179, EGL - WEST, WEST NEAR, CONNYS TAVERN, 1292 STAND 12, LUSAKA SWANEVILLE, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.19530,'lng':27.76550}},
{'stp':630492,'address':'630492, EGL - WEST, WEST NEAR, MAJOZIS PLACE, 5222 MABALANE ST, KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.15720,'lng':27.77180}},
{'stp':631111,'address':'631111, EGL - WEST, WEST NEAR, FIESTALAND TAVERN, 2684 BHEKIZULU STREET, MOGALE CITY, KAGISO, KAGISO, South Africa','geo':{'lat':-26.15720,'lng':27.78510}},
{'stp':631135,'address':'631135, EGL - WEST, WEST NEAR, NTSAMAENGS PLACE, 852 SWANEVILLE, KAGISO LOCATION, #, KAGISO, South Africa','geo':{'lat':-26.19460,'lng':27.76860}},
{'stp':631551,'address':'631551, EGL - WEST, WEST NEAR, AMOS TAVERN, 2404 BHEKIZULU STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15640,'lng':27.78576}},
{'stp':631607,'address':'631607, EGL - WEST, WEST NEAR, NOMSAS PLACE, 2593 ACONOTSHER STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15670,'lng':27.78480}},
{'stp':632542,'address':'632542, EGL - WEST, WEST NEAR, THEMBA\'S TAVERN, 2392 YUKON STREET, SWANEVILLE, #, KAGISO, South Africa','geo':{'lat':-26.19800,'lng':27.76640}},
{'stp':633144,'address':'633144, EGL - WEST, WEST NEAR, SANZAS INN, 1750 MANTHATA STREET, KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.15960,'lng':27.77910}},
{'stp':634038,'address':'634038, EGL - WEST, WEST NEAR, NDHLOVU\'S INN, 1238 SWANNIEVILLE, MOGALE CITY, #, KAGISO, South Africa','geo':{'lat':-26.19480,'lng':27.76570}},
{'stp':634056,'address':'634056, EGL - WEST, WEST NEAR, MAIN LINE INN TAVERN, ERF 5014 MOTSWENENI STREET, KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.15930,'lng':27.76940}},
{'stp':635175,'address':'635175, EGL - WEST, WEST NEAR, LEMI\'S INN, 1063 WEAVERLOOP STREET, SWANEVILLE, #, KAGISO, South Africa','geo':{'lat':-26.19540,'lng':27.76700}},
{'stp':635230,'address':'635230, EGL - WEST, WEST NEAR, BIG MAMMA, STAND NO 3241 NOBELUNGU STR, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16200,'lng':27.78790}},
{'stp':637819,'address':'637819, EGL - WEST, WEST NEAR, THE CAVE INN, 235 STAND NO, SWAN STREET, RIETVALLEI, KAGISO, South Africa','geo':{'lat':-26.19050,'lng':27.76770}},
{'stp':637949,'address':'637949, EGL - WEST, WEST NEAR, MASOBAS PLACE, 4556 BAAGI STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16000,'lng':27.77500}},
{'stp':638708,'address':'638708, EGL - WEST, WEST NEAR, JOE\'S TAVERN, 1580 KAMAGORO STREET, KAGISO 2, KRUGERSDORP, KAGISO, South Africa','geo':{'lat':-26.15890,'lng':27.78200}},
{'stp':640500,'address':'640500, EGL - WEST, WEST NEAR, LAPA LAKA, 2365 SANDPEPER STREET, SWANEVILLE, RIETVALLEI, KAGISO, South Africa','geo':{'lat':-26.19760,'lng':27.76520}},
{'stp':641702,'address':'641702, EGL - WEST, WEST NEAR, KERILENG PLACE, 4527 MEAGONG STREET, #, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.16050,'lng':27.77240}},
{'stp':641703,'address':'641703, EGL - WEST, WEST NEAR, MPHOS PLACE, 1892 UTLHANONG STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16290,'lng':27.77870}},
{'stp':641735,'address':'641735, EGL - WEST, WEST NEAR, MAMSIS INN, 3031 SEBENZISA STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16000,'lng':27.78590}},
{'stp':641761,'address':'641761, EGL - WEST, WEST NEAR, LD TAVERN, 51 SWALLOW STREET, RIETVALLEI, #, KAGISO, South Africa','geo':{'lat':-26.18930,'lng':27.76450}},
{'stp':642133,'address':'642133, EGL - WEST, WEST NEAR, SHEILAS INN, 19555 PHEHLA STREET, EXT 9, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17060,'lng':27.78080}},
{'stp':642347,'address':'642347, EGL - WEST, WEST NEAR, NCANES TAVERN, 11477 VIOLET CRESCENT, EXTENSION 6, KAGISO, KAGISO, South Africa','geo':{'lat':-26.16920,'lng':27.78940}},
{'stp':642668,'address':'642668, EGL - WEST, WEST NEAR, CHAUCHAUS PLACE, 10817 EXT 6, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16260,'lng':27.77230}},
{'stp':647673,'address':'647673, EGL - WEST, WEST NEAR, RAKGADI\'S PLACE, 4202 KUNUPI STREET, KAGISO 2, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.15790,'lng':27.77420}},
{'stp':647695,'address':'647695, EGL - WEST, WEST NEAR, MALESHANE\'S PLACE, 4435 MODIMOSANE STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16090,'lng':27.77210}},
{'stp':648470,'address':'648470, EGL - WEST, WEST NEAR, MAMA CHA, 2720 DHALAMINI STREET, KAGISO 2, KRUGERSDORP, KAGISO, South Africa','geo':{'lat':-26.15890,'lng':27.78780}},
{'stp':649029,'address':'649029, EGL - WEST, WEST NEAR, SIS D\'S PLACE, 7169 MBABANE STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15920,'lng':27.79490}},
{'stp':649035,'address':'649035, EGL - WEST, WEST NEAR, MORONGOAS INN, 6287 SEBENZISA STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16130,'lng':27.78930}},
{'stp':649127,'address':'649127, EGL - WEST, WEST NEAR, JOMO\'S, 2394 BERUZULU STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15570,'lng':27.78600}},
{'stp':649274,'address':'649274, EGL - WEST, WEST NEAR, MAKHEHLA\'S PLACE, 6858 MAYIBUYE EXT 5, SWANEVILLE, #, KAGISO, South Africa','geo':{'lat':-26.20230,'lng':27.77140}},
{'stp':649281,'address':'649281, EGL - WEST, WEST NEAR, MALOVER\'S INN, 6235 HINZA STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15726,'lng':27.79034}},
{'stp':650438,'address':'650438, EGL - WEST, WEST NEAR, KHAYALAM, 2801 KAGISO 2, #, #, KAGISO, South Africa','geo':{'lat':-26.15820,'lng':27.78510}},
{'stp':651491,'address':'651491, EGL - WEST, WEST NEAR, ROSYS PLACE, 4049 SETLOLAMATHE STREET, #, #, KAGISO, South Africa','geo':{'lat':-26.15780,'lng':27.77710}},
{'stp':651673,'address':'651673, EGL - WEST, WEST NEAR, SHIMIS TAVERN, 7254 EMADLELWENI STREET, RIETVALLEI, #, KAGISO, South Africa','geo':{'lat':-26.20210,'lng':27.76890}},
{'stp':651975,'address':'651975, EGL - WEST, WEST NEAR, NTSWAKIS INN, STAND 1932, LEFURUTSHE STREET, #, KAGISO, South Africa','geo':{'lat':-26.16090,'lng':27.77990}},
{'stp':652450,'address':'652450, EGL - WEST, WEST NEAR, DAVID\'S PLACE, 4084 BOKOBALO STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15680,'lng':27.77630}},
{'stp':652456,'address':'652456, EGL - WEST, WEST NEAR, ANNAHS INN, 408 BLOCK 2, SWANEVILLE, #, KAGISO, South Africa','geo':{'lat':-26.19080,'lng':27.77010}},
{'stp':652819,'address':'652819, EGL - WEST, WEST NEAR, SINAS PLACE, 2656 MEKONG STREET, RIETVALLEI, #, KAGISO, South Africa','geo':{'lat':-26.19990,'lng':27.76930}},
{'stp':653953,'address':'653953, EGL - WEST, WEST NEAR, ERNES PLACE, 6231 HINTSA STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15700,'lng':27.79060}},
{'stp':658262,'address':'658262, EGL - WEST, WEST NEAR, MAHLOMOLA LIQUOR STORE, 1168 HERON STREET, ERF NO 2328, RIETVALLEI, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.19926,'lng':27.76482}},
{'stp':687983,'address':'687983, EGL - WEST, WEST NEAR, BOGGERT PUB AND RESTAURANT, 4600 MOROKA STREET, #, KAGISO 02, KAGISO, South Africa','geo':{'lat':-26.16050,'lng':27.77430}},
{'stp':707588,'address':'707588, EGL - WEST, WEST NEAR, MOHLOMOLA LIQUOR STORE (NTO), 1168 HERON STREET, #, #, KAGISO, South Africa','geo':{'lat':-26.19940,'lng':27.76500}},
{'stp':725546,'address':'725546, EGL - WEST, WEST NEAR, MAHOBE TAVERN, 4568 BAAGI STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16060,'lng':27.77480}},
{'stp':727611,'address':'727611, EGL - WEST, WEST NEAR, TSO S PUB, 6400 RADEBE STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15780,'lng':27.79130}},
{'stp':745545,'address':'745545, EGL - WEST, WEST NEAR, SESIBUYILE TAVERN, 1214 LUSAKA, SWANEVILLE, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.19540,'lng':27.76480}},
{'stp':751484,'address':'751484, EGL - WEST, WEST NEAR, TWIST PLACE, 2154 TSIGLALA STREET, KAGISO 2 CENTRAL, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.16192,'lng':27.77661}},
{'stp':756882,'address':'756882, EGL - WEST, WEST NEAR, GOLO\'S INN (NTO), 2192 UTHLANONG STREET, KAGISO CENTRAL, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.16233,'lng':27.77674}},
{'stp':770388,'address':'770388, EGL - WEST, WEST NEAR, JAPIES INN, 2412 BAMBATHA STREET, KAGISO , #, KAGISO, South Africa','geo':{'lat':-26.15720,'lng':27.78570}},
{'stp':785792,'address':'785792, EGL - WEST, WEST NEAR, PHASAS INN, 4729 STAND NO, MALENGENA STREET, KAGISO 02 CENTRAL, KAGISO, South Africa','geo':{'lat':-26.16049,'lng':27.77251}},
{'stp':795945,'address':'795945, EGL - WEST, WEST NEAR, ROCKA MKHULU SHEBEEN, 2490 STAND NO, ORINOCCO STREET, #, KAGISO, South Africa','geo':{'lat':-26.19962,'lng':27.76645}},
{'stp':388782,'address':'388782, EGL - WEST, WEST NEAR, PICK N PAY GS10 PHENYO MARKET, 966 ERF NO, KAGISO AVENUE, KAGISO, KAGISO, South Africa','geo':{'lat':-26.15486,'lng':27.78460}},
{'stp':389763,'address':'389763, EGL - WEST, WEST NEAR, KOKI S TAVERN, 2604 STAND NO, SOLPLATJIE, #, ROODEPOORT, South Africa','geo':{'lat':-26.18304,'lng':27.85510}},
{'stp':390978,'address':'390978, EGL - WEST, WEST NEAR, THAMIS INN, 5473 STAND NO, #, #, KAGISO, South Africa','geo':{'lat':-26.15529,'lng':27.76873}},
{'stp':460900,'address':'460900, EGL - WEST, WEST NEAR, LAMPHOZA INN, 7166 STAND NO, OTLEGA DRIVE, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15943,'lng':27.79456}},
{'stp':461024,'address':'461024, EGL - WEST, WEST NEAR, BOITO INN, 1898 STAND NO, SOLOMON STREET, SOLPLAATJIE, ROODEPOORT, South Africa','geo':{'lat':-26.18395,'lng':27.85025}},
{'stp':462576,'address':'462576, EGL - WEST, WEST NEAR, ROOTS AND CULTURE HOUSE, 6 STAND NO, PROGRESS ROAD, #, ROODEPOORT, South Africa','geo':{'lat':-26.15197,'lng':27.83587}},
{'stp':464295,'address':'464295, EGL - WEST, WEST NEAR, EVAS PLACE, 7234 DUTYWA STREET, #, #, KAGISO, South Africa','geo':{'lat':-26.16136,'lng':27.79915}},
{'stp':550215,'address':'550215, EGL - WEST, WEST NEAR, DAVID\'S GENERAL DEALER, 940 PHATUDI STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15340,'lng':27.78210}},
{'stp':550235,'address':'550235, EGL - WEST, WEST NEAR, SUNSHINE LIQUOR STORE, SHOP 7 ROODEPOORT PLAZA, OFF VAN WYK STREET, ROODEPOORT CBD, ROODEPOORT, South Africa','geo':{'lat':-26.16310,'lng':27.86940}},
{'stp':550303,'address':'550303, EGL - WEST, WEST NEAR, DO ITS PLACE, 1295 KHUTSA STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15650,'lng':27.78132}},
{'stp':550335,'address':'550335, EGL - WEST, WEST NEAR, GREEN HOUSE, STAND 6909, NHWAVUMA STREET, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.16370,'lng':27.79550}},
{'stp':550499,'address':'550499, EGL - WEST, WEST NEAR, EMILY\'S PLACE, 7191 MBABANE STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15940,'lng':27.79680}},
{'stp':550527,'address':'550527, EGL - WEST, WEST NEAR, JOSEPHS PLACE, 15488/24 JOSEPH MOLATLWA STR, EXT 12 KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.17240,'lng':27.78870}},
{'stp':550659,'address':'550659, EGL - WEST, WEST NEAR, TLHANKI\'S LOUNGE, 6949 STAND NO, THEMBA DRIVE, EAST PARK, KAGISO, South Africa','geo':{'lat':-26.16407,'lng':27.79657}},
{'stp':550788,'address':'550788, EGL - WEST, WEST NEAR, MAGREKA LIQUOR STORE, C/O ASHENBERG AND JACOBS STREETS, CHAMDOR, KRUGERSDORP, KAGISO, South Africa','geo':{'lat':-26.15698,'lng':27.79642}},
{'stp':550923,'address':'550923, EGL - WEST, WEST NEAR, THANDIS PLACE, 20/13768 ALBERT LUTHULI STREET, EXTENTION 8, #, KAGISO, South Africa','geo':{'lat':-26.15170,'lng':27.77880}},
{'stp':550944,'address':'550944, EGL - WEST, WEST NEAR, LERATONG LIQUOR STORE, STAND 7187, MVEVE STREET, KAGISO, KAGISO, South Africa','geo':{'lat':-26.15970,'lng':27.79610}},
{'stp':550990,'address':'550990, EGL - WEST, WEST NEAR, ROBS TAVERN,  3 SHOP NO, STATION STREET, ROODEPOORT ERF NUMBER 1776, ROODEPOORT, South Africa','geo':{'lat':-26.15960,'lng':27.86940}},
{'stp':551086,'address':'551086, EGL - WEST, WEST NEAR, GABYS SPORTS BAR, 34 MAIN STREET, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.16040,'lng':27.86890}},
{'stp':551189,'address':'551189, EGL - WEST, WEST NEAR, TEBOGO S PUB AND RETAURANT, 13691 SOL PLAATJIES CRESCENT, EXT 8, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15230,'lng':27.77980}},
{'stp':551231,'address':'551231, EGL - WEST, WEST NEAR, CULEMBEECK LIQUOR STORE, PORTION 16 OF WITPOORTJIE, NO 645 I Q, CULEMBEECK, ROODEPOORT, South Africa','geo':{'lat':-26.17180,'lng':27.82400}},
{'stp':551532,'address':'551532, EGL - WEST, WEST NEAR, SHIMIS INN, ERF 6901, NGAVAVUMA, KAGISO, KAGISO, South Africa','geo':{'lat':-26.16368,'lng':27.79450}},
{'stp':551671,'address':'551671, EGL - WEST, WEST NEAR, ROODEPOORT HOTEL, PRESIDENTPLEIN STRAAT 17, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.16040,'lng':27.86280}},
{'stp':551834,'address':'551834, EGL - WEST, WEST NEAR, STATION HOTEL ROODEPOORT, 24 STATION STREET, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.15950,'lng':27.86970}},
{'stp':551899,'address':'551899, EGL - WEST, WEST NEAR, MZETS PLACE, 7138 GEBA STREET, EAST PARK, KAGISO, KAGISO, South Africa','geo':{'lat':-26.15810,'lng':27.79540}},
{'stp':552341,'address':'552341, EGL - WEST, WEST NEAR, MOTSOMIS PLACE, 4946 AMOS MAGODIELA STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16675,'lng':27.79968}},
{'stp':552511,'address':'552511, EGL - WEST, WEST NEAR, TRIPPLE S, 13452 OTLEGA STREET, EXT 8, KAGISO, KAGISO, South Africa','geo':{'lat':-26.14930,'lng':27.77370}},
{'stp':552785,'address':'552785, EGL - WEST, WEST NEAR, OUPAS PLACE, 410 NYAMAKAZI STREET, KAGISO 1, #, KAGISO, South Africa','geo':{'lat':-26.14210,'lng':27.79460}},
{'stp':552814,'address':'552814, EGL - WEST, WEST NEAR, TIGERS PLACE, 5480 THULANI STREET, RIVERSIDE, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15590,'lng':27.76830}},
{'stp':552875,'address':'552875, EGL - WEST, WEST NEAR, MZI\'S PLACE, ERF 16779, KAGISO EXT 12, #, KAGISO, South Africa','geo':{'lat':-26.17600,'lng':27.79180}},
{'stp':553009,'address':'553009, EGL - WEST, WEST NEAR, JUMBOS PLACE, 5691 MAIMANE STREET, RIVERSIDE, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15480,'lng':27.76857}},
{'stp':553122,'address':'553122, EGL - WEST, WEST NEAR, LINDIS PLACE, 7271 DUTWYA STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16120,'lng':27.79900}},
{'stp':553279,'address':'553279, EGL - WEST, WEST NEAR, BMX TAVERN, STAND 9610, EASTPARK, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.16160,'lng':27.79770}},
{'stp':553408,'address':'553408, EGL - WEST, WEST NEAR, ALL STAR POOL CLUB, 3825 KUTLWANONG STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15660,'lng':27.77830}},
{'stp':553593,'address':'553593, EGL - WEST, WEST NEAR, NONOS PLACE, 12205 OLIVER CRESCENT, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16880,'lng':27.79560}},
{'stp':553595,'address':'553595, EGL - WEST, WEST NEAR, MOKALE LIQUOR STORE, 14611b MASEDI STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15440,'lng':27.77290}},
{'stp':553673,'address':'553673, EGL - WEST, WEST NEAR, BACCHUS HOTEL, NO 8 BURGER STREET, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.16080,'lng':27.86670}},
{'stp':560213,'address':'560213, EGL - WEST, WEST NEAR, MASABATAS PLACE, 8634 EMDENI STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15420,'lng':27.79060}},
{'stp':560232,'address':'560232, EGL - WEST, WEST NEAR, AFRICAN DIASPORA, STAND 3820, SETLOLAMATHE STREET, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15760,'lng':27.77910}},
{'stp':560480,'address':'560480, EGL - WEST, WEST NEAR, MABONGIS PLACE, 193 ELLIAS MADIA, EXT 11, KAGISO, KAGISO, South Africa','geo':{'lat':-26.16630,'lng':27.79640}},
{'stp':602885,'address':'602885, EGL - WEST, WEST NEAR, MPHOS SOL TAVERN, 280 CNR BUTI & MOTLOUNG ST, KAGISO 1, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.14190,'lng':27.79597}},
{'stp':603034,'address':'603034, EGL - WEST, WEST NEAR, SENYANE RINAH MOTSHEGWA, STAND 6089, RIVERSIDE, #, KAGISO, South Africa','geo':{'lat':-26.15200,'lng':27.76640}},
{'stp':603135,'address':'603135, EGL - WEST, WEST NEAR, KGATELOPELE, 5501 MOILOA STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15595,'lng':27.76666}},
{'stp':603773,'address':'603773, EGL - WEST, WEST NEAR, CORNER POCKET, 351 HELIOS ST, DAVIDSONVILLE, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.15570,'lng':27.84730}},
{'stp':604152,'address':'604152, EGL - WEST, WEST NEAR, CHAFFPOZZY, 14883 EXT 11, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.16700,'lng':27.80060}},
{'stp':604675,'address':'604675, EGL - WEST, WEST NEAR, MATLAKALAS INN, 1129 BATHOENG ST, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15460,'lng':27.78230}},
{'stp':604676,'address':'604676, EGL - WEST, WEST NEAR, MPHATIS INN, 282A POSWAYO ST, KAGISO 2, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.14100,'lng':27.79610}},
{'stp':605375,'address':'605375, EGL - WEST, WEST NEAR, SAMS PLACE, 13311 J. SOFASONKE ST, EXT 8 KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.14870,'lng':27.77280}},
{'stp':605861,'address':'605861, EGL - WEST, WEST NEAR, TSWEKZA TRADERS, NO 20 MAIN STREET, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.15890,'lng':27.86850}},
{'stp':606184,'address':'606184, EGL - WEST, WEST NEAR, SHAKARAIM INN, 5182 MABALANE ST, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15510,'lng':27.77250}},
{'stp':606955,'address':'606955, EGL - WEST, WEST NEAR, BONANA SHEBEEN, 1297 PHILLIP RAMAKOBYA ST, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15630,'lng':27.78120}},
{'stp':625006,'address':'625006, EGL - WEST, WEST NEAR, NEXT TO NOTHING, 4665 EXT 2, RIETVALLEI, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.18350,'lng':27.75580}},
{'stp':626317,'address':'626317, EGL - WEST, WEST NEAR, CUTTERS INN, PLOT 61 ROOM656, MAINREEF ROAD, #, ROODEPOORT, South Africa','geo':{'lat':-26.13470,'lng':27.85260}},
{'stp':626647,'address':'626647, EGL - WEST, WEST NEAR, DUDLEYS SPORTS TAVERN, 356 HOMER STREET, DAVIDSONVILLE, #, ROODEPOORT, South Africa','geo':{'lat':-26.15490,'lng':27.84760}},
{'stp':627310,'address':'627310, EGL - WEST, WEST NEAR, DINGAANS PLACE, 5364 BAFOKENG ST, RIVERSIDE, #, KAGISO, South Africa','geo':{'lat':-26.15700,'lng':27.77070}},
{'stp':627743,'address':'627743, EGL - WEST, WEST NEAR, MAMA T TAVERN, 5704 MOILWA STREET, KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.15550,'lng':27.76780}},
{'stp':629267,'address':'629267, EGL - WEST, WEST NEAR, CHICARGO LIQUORS, 24 STATION STREET, ROODEPOORT, #, ROODEPOORT, South Africa','geo':{'lat':-26.15960,'lng':27.86970}},
{'stp':630285,'address':'630285, EGL - WEST, WEST NEAR, MGODIS PLACE, 7197 STAND NO, MBABANE DRIVE, #, KAGISO, South Africa','geo':{'lat':-26.15973,'lng':27.79701}},
{'stp':630610,'address':'630610, EGL - WEST, WEST NEAR, SIS B PLACE, 7028 MZILIKAZI ST, KAGISO EAST, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.16290,'lng':27.80010}},
{'stp':631063,'address':'631063, EGL - WEST, WEST NEAR, PATIENCE PLACE, 4710 EXT 2 & 3 KAGISO, MOGALE  CITY, #, KAGISO, South Africa','geo':{'lat':-26.18280,'lng':27.75290}},
{'stp':631400,'address':'631400, EGL - WEST, WEST NEAR, DIBOMBOS PLACE, 1145 BAPOO STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15420,'lng':27.78300}},
{'stp':632597,'address':'632597, EGL - WEST, WEST NEAR, LINDI\'S SHEBEEN, 1540 SENOAMORE STREET, KAGISO 2, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.15680,'lng':27.77850}},
{'stp':632719,'address':'632719, EGL - WEST, WEST NEAR, MACHAVA TAVERN, 366 MATHOLEVILLE, EXTENTION 4, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.17070,'lng':27.85030}},
{'stp':633556,'address':'633556, EGL - WEST, WEST NEAR, MAKWABA TAVERN, 13119 GOVEN MBEKI STREET, KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.14500,'lng':27.77130}},
{'stp':633608,'address':'633608, EGL - WEST, WEST NEAR, PIETAS PLACE, 13350 SOFASONKE STREET, EXTENSION 8, KAGISO, KAGISO, South Africa','geo':{'lat':-26.14840,'lng':27.77300}},
{'stp':633904,'address':'633904, EGL - WEST, WEST NEAR, MBATHA TAVERN, 37 5TH STREET, ROODEPOORT NORTH, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.15870,'lng':27.87260}},
{'stp':634410,'address':'634410, EGL - WEST, WEST NEAR, ZWAKALA HEER RESTAURANT, 195 MAIN REEF ROAD, LEWISHAM, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.12030,'lng':27.81120}},
{'stp':636247,'address':'636247, EGL - WEST, WEST NEAR, AFTERPARTY SPORTS BAR AND NIGHT CLU, HOTSHOTS SPORTSBAR & NIGHT CLUB, ERF 537 1A VAN WYK STREET, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.16060,'lng':27.86400}},
{'stp':636497,'address':'636497, EGL - WEST, WEST NEAR, BRA TSA\'S INN, 11732 FAIR COURT STREET, HOSPITAL VIEW, KAGISO, KAGISO, South Africa','geo':{'lat':-26.16768,'lng':27.79682}},
{'stp':637694,'address':'637694, EGL - WEST, WEST NEAR, TRY INN, 7117 NOMANDI DRIVE, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15960,'lng':27.79880}},
{'stp':639246,'address':'639246, EGL - WEST, WEST NEAR, CHEERS HYPER LIQUOR STORE, 149  MAIN REEF ROAD, LEWISHAM, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.12140,'lng':27.81490}},
{'stp':639331,'address':'639331, EGL - WEST, WEST NEAR, CONNIES TAVERN, STAND NO 318/8, NO 22 SCHRIKKER STREET, DAVIDSONVILLE  ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.16030,'lng':27.85340}},
{'stp':640533,'address':'640533, EGL - WEST, WEST NEAR, AMELIAS PLACE, 908 PHATUDI STREET, KAGISO 2, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.15420,'lng':27.78380}},
{'stp':640926,'address':'640926, EGL - WEST, WEST NEAR, GREEN CENTRE, 309 PLOT 61, PRINCESS, #, ROODEPOORT, South Africa','geo':{'lat':-26.13533,'lng':27.85173}},
{'stp':641225,'address':'641225, EGL - WEST, WEST NEAR, SETLHARENG INN, 9128 KAGISO EXT 2, KUTLOANONG STREET, FATHER GERALD MARTIN, KAGISO, South Africa','geo':{'lat':-26.15030,'lng':27.78300}},
{'stp':641304,'address':'641304, EGL - WEST, WEST NEAR, SHEIKES PLACE, 865 OTLEGA DRIVE, KAGISO 2, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.15230,'lng':27.78210}},
{'stp':641596,'address':'641596, EGL - WEST, WEST NEAR, MOHATLANES PLACE, 5729 BOMENO STREET, RIVERSIDE, #, KAGISO, South Africa','geo':{'lat':-26.15590,'lng':27.76610}},
{'stp':641694,'address':'641694, EGL - WEST, WEST NEAR, COCO\'S PLACE, 19078 STAND NO, KAGISO, EXTENSION 14, KAGISO, South Africa','geo':{'lat':-26.17720,'lng':27.78470}},
{'stp':642045,'address':'642045, EGL - WEST, WEST NEAR, MAITISONG SHORT LEFT, 13512 EXT 8, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.14950,'lng':27.77260}},
{'stp':642371,'address':'642371, EGL - WEST, WEST NEAR, BASIMANE TAVERN, 1191 KABOGANKA STREET, EXTENTION 2, KAGISO, KAGISO, South Africa','geo':{'lat':-26.15430,'lng':27.78120}},
{'stp':649009,'address':'649009, EGL - WEST, WEST NEAR, SEIPUBIS PLACE, 7061 GEBA STREET, EAST PARK, #, KAGISO, South Africa','geo':{'lat':-26.15750,'lng':27.79590}},
{'stp':649010,'address':'649010, EGL - WEST, WEST NEAR, GERTIES INN, 8796 CETSHWAYO STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.15420,'lng':27.78880}},
{'stp':649034,'address':'649034, EGL - WEST, WEST NEAR, MILLYS INN, 9363 SIBANYOBI STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.16180,'lng':27.79560}},
{'stp':649055,'address':'649055, EGL - WEST, WEST NEAR, KHAYA LOUNGE PUB AND RESTAURANT, 7187 MBABANE STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15940,'lng':27.79610}},
{'stp':649232,'address':'649232, EGL - WEST, WEST NEAR, EIGHT DOUBLE ONE, 811 OTLEGA STREET, KAGISO 2, #, KAGISO, South Africa','geo':{'lat':-26.15380,'lng':27.78520}},
{'stp':650841,'address':'650841, EGL - WEST, WEST NEAR, SKS TAVERN, 16881 KAREEBERG STREET, KAGISO EXTENSION 12, #, KAGISO, South Africa','geo':{'lat':-26.17710,'lng':27.79270}},
{'stp':651495,'address':'651495, EGL - WEST, WEST NEAR, MALISHI PLACE, 5775 MOSHOESHOE STREET, KAGISO 2, RIVERSIDE, KAGISO, South Africa','geo':{'lat':-26.15420,'lng':27.76840}},
{'stp':651607,'address':'651607, EGL - WEST, WEST NEAR, EASY PACK LIQUOR STORE, 36 JACOBS STREET, SHOP 7 ERF 1622 AND 34 AND 36, CHAMDOR, KAGISO, South Africa','geo':{'lat':-26.15675,'lng':27.79587}},
{'stp':651948,'address':'651948, EGL - WEST, WEST NEAR, SUSANS PLACE, ERF 717, 19 AMELIA STREET, #, ROODEPOORT, South Africa','geo':{'lat':-26.15860,'lng':27.86440}},
{'stp':654568,'address':'654568, EGL - WEST, WEST NEAR, TUKA SE FEES, STAND NO 7190, OTLEGA DRIVE, KAGISO 2, KAGISO, South Africa','geo':{'lat':-26.15960,'lng':27.79620}},
{'stp':657145,'address':'657145, EGL - WEST, WEST NEAR, ON THE ROCK (NTO), CNR 2ND & GOLDMEN STR, FLORIDA, #, ROODEPOORT, South Africa','geo':{'lat':-26.17126,'lng':27.90857}},
{'stp':658161,'address':'658161, EGL - WEST, WEST NEAR, AMIGOS SPORTS BAR (NTO), SHOP NO 17D, BLECHMAN BUILDING, KATHLEEM STREET,,  ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.16150,'lng':27.86420}},
{'stp':666067,'address':'666067, EGL - WEST, WEST NEAR, EMZINI WEZINSIZWA TAVERN, STAND 12124, FIGTREE STREET, EXTENSION 6, KAGISO, South Africa','geo':{'lat':-26.17110,'lng':27.79780}},
{'stp':666795,'address':'666795, EGL - WEST, WEST NEAR, RAMAFAMBAS PLACE, BLOCK 61 PRINCESS NO 112, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.13530,'lng':27.85050}},
{'stp':666987,'address':'666987, EGL - WEST, WEST NEAR, Y 2 J TAVERN, STAND 13444 OTLEGA STREET, #, EXTENSION 08 KAGISO TOWNSHIP, KAGISO, South Africa','geo':{'lat':-26.14890,'lng':27.77240}},
{'stp':686868,'address':'686868, EGL - WEST, WEST NEAR, CAFE T O, ROODEPOORT NORTH, ERF 549 AND 550, #, ROODEPOORT, South Africa','geo':{'lat':-26.15940,'lng':27.87150}},
{'stp':687767,'address':'687767, EGL - WEST, WEST NEAR, JACKPOT INN, VAN WYK STREET, 1ST FLOOR SHOP NO 4, STAND NO 341, ROODEPOORT, South Africa','geo':{'lat':-26.16160,'lng':27.86120}},
{'stp':689369,'address':'689369, EGL - WEST, WEST NEAR, TSITSIS PUB, 9201 LEKGOTLA CRESENT, #, #, KAGISO, South Africa','geo':{'lat':-26.15290,'lng':27.78460}},
{'stp':698325,'address':'698325, EGL - WEST, WEST NEAR, GRACES TAVERN, 6914 IXOPO STREET, #, #, KAGISO, South Africa','geo':{'lat':-26.16350,'lng':27.79610}},
{'stp':701812,'address':'701812, EGL - WEST, WEST NEAR, BLAFAS INN, 7120 MHCABUMZIMA STREET, ERF 7120, KAGISO, KAGISO, South Africa','geo':{'lat':-26.16000,'lng':27.79850}},
{'stp':709266,'address':'709266, EGL - WEST, WEST NEAR, SEDIBENG BOTTLE STORE, 1384 KAGISO, #, #, KAGISO, South Africa','geo':{'lat':-26.15710,'lng':27.78120}},
{'stp':763003,'address':'763003, EGL - WEST, WEST NEAR, EAT WELLS AND PUB, 11532 STAND NO, SNAPDRAGON CRESENT, KAGISO, KAGISO, South Africa','geo':{'lat':-26.16865,'lng':27.79557}},
{'stp':770065,'address':'770065, EGL - WEST, WEST NEAR, BACKYARD ART GALLERY PUB AND RESTAU, 2340 STAND NO, KAGISO AVE, KAGISO EXT 2, KAGISO, South Africa','geo':{'lat':-26.14422,'lng':27.78267}},
{'stp':785127,'address':'785127, EGL - WEST, WEST NEAR, FRESH CUT PUB, ERF 2161, 019 VAN WYK STREET, ROODEPOORT WEST, ROODEPOORT, South Africa','geo':{'lat':-26.16180,'lng':27.86015}},
{'stp':785772,'address':'785772, EGL - WEST, WEST NEAR, RIVERSIDE LIQUORS, MAIMANE STREET, ERF 5681 SHOP NO 6, KAGISO, KAGISO, South Africa','geo':{'lat':-26.15459,'lng':27.76959}},
{'stp':791074,'address':'791074, EGL - WEST, WEST NEAR, LUGOGO, SHOP 1 A , BATAVIA RETAIL PARK, CORNER QUELLERIE AND RANDFONTEIN ROAD, #, ROODEPOORT, South Africa','geo':{'lat':-26.17074,'lng':27.82383}},
{'stp':312754,'address':'312754, EGL - WEST, WEST NEAR, MAHUMANI TUCKSHOP, 810 STAND NO, DIEPSLOOT EXT 2, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92371,'lng':28.01430}},
{'stp':385851,'address':'385851, EGL - WEST, WEST NEAR, MEGA SEVEN 7, stand number, 2ND AND GOLDMAN AVENUE, GOLDMAN AND 2 ND AVENUE, ROODEPOORT, South Africa','geo':{'lat':-26.17235,'lng':27.90824}},
{'stp':386826,'address':'386826, EGL - WEST, WEST NEAR, MEHLARENG BEER PLACE, 3136 STAND NO, SAPPHIARE STREET, EXT 6 DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93347,'lng':28.01430}},
{'stp':388275,'address':'388275, EGL - WEST, WEST NEAR, E LIQUOR COMO JUNCTION, 207 STAND NUMBER, SHOP GF 2, COSMO JUNCTION, COSMOS CITY, South Africa','geo':{'lat':-26.01955,'lng':27.93348}},
{'stp':388518,'address':'388518, EGL - WEST, WEST NEAR, COSMO VIEW SPORTS BAR, 207 STAND NUMBER, SHOP FFH7 C/O SOUTH AFRICA, TENESSE AVE, FOURWAYS, South Africa','geo':{'lat':-26.01897,'lng':27.93250}},
{'stp':389045,'address':'389045, EGL - WEST, WEST NEAR, SHOPRITE  LIQUORSHOP DIEPSLOOT WEST, 97 STAND NO, BAMBANANI SHOPPING CENTRE, CNR R114 ROAD &APPLE STREET, DIEPSLOOT, South Africa','geo':{'lat':-25.93211,'lng':27.99386}},
{'stp':467159,'address':'467159, EGL - WEST, WEST NEAR, BOXER SUPERSTORE DIEPSLOOT, STAND NO, CNR AFRICA & PEACH ROADS, DIEPSLOOT WEST ERF 164, DIEPSLOOT, South Africa','geo':{'lat':-25.93493,'lng':28.01514}},
{'stp':480088,'address':'480088, EGL - WEST, WEST NEAR, PNP FAMILY COSMO CITY GH07, 15069 CNR OF SOUTH AFRICA AND AFRIC, #, #, RANDBURG, South Africa','geo':{'lat':-26.02174,'lng':27.92780}},
{'stp':550229,'address':'550229, EGL - WEST, WEST NEAR, TESAMEKOMS TAVERN, PORTION 71, FARM VAALBANK 512 J Q, MAGALIESBURG, MAGALIESBURG, South Africa','geo':{'lat':-26.00050,'lng':27.49170}},
{'stp':550413,'address':'550413, EGL - WEST, WEST NEAR, SAVOY HOTEL, SAVOY HOTEL, 9 BURGER STREET, #, ROODEPOORT, South Africa','geo':{'lat':-26.16050,'lng':27.86580}},
{'stp':550588,'address':'550588, EGL - WEST, WEST NEAR, NDOSI INN, STAND NO 882, DIEPSLOOT WEST, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92460,'lng':28.02010}},
{'stp':550634,'address':'550634, EGL - WEST, WEST NEAR, SELEPES PLACE, STAND NO 1440, EXTENTION 4, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92590,'lng':28.00650}},
{'stp':550726,'address':'550726, EGL - WEST, WEST NEAR, PHUTHADITJABA TAVERN, SHOP NO 1, NEW SHOPPING CENTRE COMPLEX, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92975,'lng':28.00409}},
{'stp':551405,'address':'551405, EGL - WEST, WEST NEAR, PLATINUM LOUNGE ZANDSPRUIT, STAND NO, PORTION 122 A PORTION OF PORTION 63 OF, THE FARM ZANDSPRUIT 191 IQ, RANDBURG, South Africa','geo':{'lat':-26.01805,'lng':27.94147}},
{'stp':551573,'address':'551573, EGL - WEST, WEST NEAR, PIRATE LIQUOR STORE, R511 HARTBEESPOORT ROAD, LAEZONIA SHOPPING CENTRE, #, DIEPSLOOT, South Africa','geo':{'lat':-25.90486,'lng':28.02770}},
{'stp':551850,'address':'551850, EGL - WEST, WEST NEAR, HOLLANDIA LIQUOR STORE, NO 56 SIVERIGHT STREET, KRUGERSDORP, #, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.10810,'lng':27.77940}},
{'stp':552006,'address':'552006, EGL - WEST, WEST NEAR, MAFAHLA LIQUORS, STAND 1478 EXTENTION 1, DIEPSLOOT WEST, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92740,'lng':28.00660}},
{'stp':552069,'address':'552069, EGL - WEST, WEST NEAR, SHIMANGE LIQUOR STORE, SHOP NO 5 ZANDSPRUIT S/ CENTRE, DF MALAN DRIVE EXTENSION, PORTION 22 FARM ZANDSPRUIT 191, RANDBURG, South Africa','geo':{'lat':-26.06890,'lng':27.91120}},
{'stp':552193,'address':'552193, EGL - WEST, WEST NEAR, JAY EM PUB AND RESTAURANT, ERF 1398, 30 A HUMAN STREET, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10360,'lng':27.77340}},
{'stp':552435,'address':'552435, EGL - WEST, WEST NEAR, DANNYS TAVERN, STAND 20 LTIRELENG STREET, #, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93440,'lng':28.01510}},
{'stp':552688,'address':'552688, EGL - WEST, WEST NEAR, PARADISE TAVERN, SITE 54A ZANDSPRUIT, HONEYDEW, RANDBURG, MULDERSDRIFT, South Africa','geo':{'lat':-26.05710,'lng':27.90340}},
{'stp':560458,'address':'560458, EGL - WEST, WEST NEAR, FEMIS CORNER, STAND NO 877 EX 2, CNR OWL AND BUFFALO STR, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92400,'lng':28.02020}},
{'stp':580701,'address':'580701, EGL - WEST, WEST NEAR, ALETTA SHEBEEN, 2428 MBABANE STREET, COSMOCITY, #, COSMOS CITY, South Africa','geo':{'lat':-26.01520,'lng':27.92490}},
{'stp':580709,'address':'580709, EGL - WEST, WEST NEAR, LOUIS TAVERN, 1992  ASMARA STREET, EXTENTION 2, COSMO CITY, COSMOS CITY, South Africa','geo':{'lat':-26.02270,'lng':27.92310}},
{'stp':580828,'address':'580828, EGL - WEST, WEST NEAR, KOFIFI TAVERN, STAND 4558 EXT 5, MAKHULONG STREET, #, DIEPSLOOT, South Africa','geo':{'lat':-25.94350,'lng':28.00930}},
{'stp':580862,'address':'580862, EGL - WEST, WEST NEAR, FAMILY MINI TAVERN, 3690 EXTENTION 6, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93730,'lng':28.01080}},
{'stp':603313,'address':'603313, EGL - WEST, WEST NEAR, SD MZAMANI TAVERN, 1048 ERNEST WOLF, ERF 45 ZANDSPRUIT, HONEYDEW, RANDBURG, South Africa','geo':{'lat':-26.06090,'lng':27.90360}},
{'stp':603396,'address':'603396, EGL - WEST, WEST NEAR, LETABONE TAVERN, 635 EXTENTION 1, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92920,'lng':28.01250}},
{'stp':603404,'address':'603404, EGL - WEST, WEST NEAR, KWENDAS TAVERN, STAND 269, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92910,'lng':28.01260}},
{'stp':603845,'address':'603845, EGL - WEST, WEST NEAR, VHANDANI TAVERN, 96B ZANDSPRUIT, HONEYDEW, #, RANDBURG, South Africa','geo':{'lat':-26.05600,'lng':27.90430}},
{'stp':605126,'address':'605126, EGL - WEST, WEST NEAR, WILLOW VALE GUEST HOUSE, PLOT 79 ANDRIES STREET, MULDERSDRIFT, #, MULDERSDRIFT, South Africa','geo':{'lat':-26.04710,'lng':27.84850}},
{'stp':606163,'address':'606163, EGL - WEST, WEST NEAR, POOR MAN RICH MAN INN, 2091 ZANDSPRUIT, DIRECTLY OPPOSITE HONEYDEW TOYOTA, IN BEYERS NAUDE ZANDSPRUIT, RANDBURG, South Africa','geo':{'lat':-26.06450,'lng':27.90600}},
{'stp':626651,'address':'626651, EGL - WEST, WEST NEAR, ENTOKOZWENI INN, 889 EXTENTION 2, CNR OWL AND BUFFALO STR, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92460,'lng':28.02070}},
{'stp':626767,'address':'626767, EGL - WEST, WEST NEAR, VESAUBUNTU JULIES TAVERN, 529 EXTENTION 2, DIEPSLOOT WEST, #, DIEPSLOOT, South Africa','geo':{'lat':-25.91930,'lng':28.01890}},
{'stp':627169,'address':'627169, EGL - WEST, WEST NEAR, RATA BATHO, PLOT 2 HENRY RD, MULDERSDRIFT, #, MUNZIEVILLE, South Africa','geo':{'lat':-26.05620,'lng':27.82570}},
{'stp':627172,'address':'627172, EGL - WEST, WEST NEAR, SPLASH TAVERN, STAND 836 EXTENTION 7, INGWENYAMA ROAD, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93700,'lng':28.00380}},
{'stp':627193,'address':'627193, EGL - WEST, WEST NEAR, THABO SUN, 2831 LUARND ST, COSMO CITY, KYA SANDS, COSMOS CITY, South Africa','geo':{'lat':-26.01680,'lng':27.92890}},
{'stp':627530,'address':'627530, EGL - WEST, WEST NEAR, LANGALIBALELE TAVERN, ERF 613, DIEPSLOOT WES TOWNSHIP, JOHANNESBURG, DIEPSLOOT, South Africa','geo':{'lat':-25.91810,'lng':28.02250}},
{'stp':629411,'address':'629411, EGL - WEST, WEST NEAR, RAMODIPA LE MORWA SHEBEEN, 1261, COSMO CITY, #, COSMOS CITY, South Africa','geo':{'lat':-26.02060,'lng':27.92030}},
{'stp':629599,'address':'629599, EGL - WEST, WEST NEAR, SLENDERS TAVERN, 763 DIEPSLOOT WEST, DIEPSLOOT WEST, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92200,'lng':28.01980}},
{'stp':629989,'address':'629989, EGL - WEST, WEST NEAR, DEWA TAVERN, 1514 EGYPT ST, COSMO CITY, ROODEPOORT, COSMOS CITY, South Africa','geo':{'lat':-26.01700,'lng':27.92540}},
{'stp':630686,'address':'630686, EGL - WEST, WEST NEAR, MZAMO TAVERN, 1524 THANZANIYA STREET, COSMO CITY EXT 2, #, COSMOS CITY, South Africa','geo':{'lat':-26.01760,'lng':27.92590}},
{'stp':630922,'address':'630922, EGL - WEST, WEST NEAR, MOLEFES CORNER, 4658  EXTENTION 5, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.94530,'lng':28.01090}},
{'stp':631408,'address':'631408, EGL - WEST, WEST NEAR, VIVIV\'S SPAZA SHOP, 2304 CNR LUANDA & ANGELA STR, COSMO CITY, #, COSMOS CITY, South Africa','geo':{'lat':-26.01780,'lng':27.92810}},
{'stp':633194,'address':'633194, EGL - WEST, WEST NEAR, BETTYS PLACE, 4184 EXTENSION 5, DIPALO STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.94190,'lng':28.00670}},
{'stp':633240,'address':'633240, EGL - WEST, WEST NEAR, NEO\'S TAVERN, STAND 7252 EXTENSION 10, MAKHUDUTHAMAGA ZONKIZIZWE STR, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.94200,'lng':28.01860}},
{'stp':635923,'address':'635923, EGL - WEST, WEST NEAR, LINDAS TAVERN, 6023 SOUTH AFRICA ROAD, CNR SERIAL RION AND CAMEROON, COSMO CITY, COSMOS CITY, South Africa','geo':{'lat':-26.02980,'lng':27.92510}},
{'stp':636785,'address':'636785, EGL - WEST, WEST NEAR, MOSS\'S BEER, STAND 894, BUFFALO STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92450,'lng':28.02080}},
{'stp':639743,'address':'639743, EGL - WEST, WEST NEAR, DANERA INN, PLOT 38 PLOVER ROAD, HILLS & DALES, LANSERIA, DIEPSLOOT, South Africa','geo':{'lat':-25.93580,'lng':27.99070}},
{'stp':642050,'address':'642050, EGL - WEST, WEST NEAR, MOMES TAVERN, EXTENTION 11, DIEPSLOOT WEST, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93080,'lng':28.01990}},
{'stp':642398,'address':'642398, EGL - WEST, WEST NEAR, CORNER HOUSE TUCK SHOP, 2915 EXTENTION 6, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93490,'lng':28.01030}},
{'stp':648713,'address':'648713, EGL - WEST, WEST NEAR, ERICS PLACE, 301 DIEPSLOOT EXT 7, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93420,'lng':28.00320}},
{'stp':648747,'address':'648747, EGL - WEST, WEST NEAR, KWAMUVENDA TAVERN, 721 DIEPSLOOT WEST TOWNSHIP, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92130,'lng':28.02090}},
{'stp':649827,'address':'649827, EGL - WEST, WEST NEAR, MOMES PLACE, STAND NO 2614 EXT 4, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93210,'lng':28.00750}},
{'stp':653416,'address':'653416, EGL - WEST, WEST NEAR, MAFAHLA PLACE, STAND NO 1478A, DIEPSLOOT WEST EXTENSION 1, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92760,'lng':28.00670}},
{'stp':654821,'address':'654821, EGL - WEST, WEST NEAR, PATRICKS TAVERN, 2838 STAND NO, EXTENSION 6, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93440,'lng':28.01450}},
{'stp':661100,'address':'661100, EGL - WEST, WEST NEAR, MANDLAS PLACE, 362 EXTENSION 10, ZANDSPRUIT, HONEYDEW, RANDBURG, South Africa','geo':{'lat':-26.05077,'lng':27.91113}},
{'stp':682463,'address':'682463, EGL - WEST, WEST NEAR, THEMBA LIQUOR STORE, 3167 1ST ORANGE STREET, EXT 6, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93670,'lng':28.01390}},
{'stp':691068,'address':'691068, EGL - WEST, WEST NEAR, EASY BY NIGHT, 6436 MIKEHAMELE STREET, STAND EXT 9, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93370,'lng':27.99980}},
{'stp':701389,'address':'701389, EGL - WEST, WEST NEAR, SHOPRITE COSMO CITY 59114, SHOP 1 COSMO CITY  JUNCTION, CORNER SOUTH AFRICA DIVE AND UNITED, STATES OF AMERICA AVENUE COSMO CITY, COSMOS CITY, South Africa','geo':{'lat':-26.01840,'lng':27.93860}},
{'stp':702936,'address':'702936, EGL - WEST, WEST NEAR, SAVOY DISCOUNT LIQUOR STORE, 9 BURGER STREET, ROODEPOORT, ROODEPOORT, ROODEPOORT, South Africa','geo':{'lat':-26.16030,'lng':27.86620}},
{'stp':723964,'address':'723964, EGL - WEST, WEST NEAR, SHINANINGS, 375 THAB O MBEKI, INFORMAL SETTLEMENT, #, RANDBURG, South Africa','geo':{'lat':-25.98800,'lng':27.93020}},
{'stp':736477,'address':'736477, EGL - WEST, WEST NEAR, MOTSWAKO POT RESTAURANT, ZANDSPRUIT TOWNSHIP, ERF 24 UMCAKA STREET, #, RANDBURG, South Africa','geo':{'lat':-26.05380,'lng':27.90570}},
{'stp':764541,'address':'764541, EGL - WEST, WEST NEAR, PELA KE PELE, 5159 STAND NO 5159, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92668,'lng':28.01184}},
{'stp':773027,'address':'773027, EGL - WEST, WEST NEAR, WINNIES TAVERN, 6945 STAND NO, JOHN MALATJIE STREET DIEPSLOOT EXT 10, SECTION MAREETSANE, DIEPSLOOT, South Africa','geo':{'lat':-25.93963,'lng':28.01727}},
{'stp':774523,'address':'774523, EGL - WEST, WEST NEAR, EMAHLANGENI PLACE, 81 HOUSE NO, PLOT 52 PEWU STREET ZANDSPRUIT, #, RANDBURG, South Africa','geo':{'lat':-26.05180,'lng':27.90650}},
{'stp':774646,'address':'774646, EGL - WEST, WEST NEAR, LIQUOR CITY DIEPSLOOT MALL, 7159 ERF, SHOP 33 DIEPSLOOT MALL, WILLIAM NICOLDRIVE AND PLUM STREET, DIEPSLOOT, South Africa','geo':{'lat':-25.94138,'lng':28.01980}},
{'stp':775253,'address':'775253, EGL - WEST, WEST NEAR, THABBYS PLACE, 5277 STAND NO, INGONYAMA STREET, EXT 7, DIEPSLOOT, South Africa','geo':{'lat':-25.93062,'lng':28.00177}},
{'stp':775756,'address':'775756, EGL - WEST, WEST NEAR, LION PARK TAVERN, 669 STAND NO, THABO MBEKI STREET, THABO MBEKI TOWNSHIP, DIEPSLOOT, South Africa','geo':{'lat':-25.99002,'lng':27.92997}},
{'stp':777051,'address':'777051, EGL - WEST, WEST NEAR, MAKIES PLACE, 1102 STAND NO, HONEYDEW ROAD, #, RANDBURG, South Africa','geo':{'lat':-26.05632,'lng':27.90460}},
{'stp':779541,'address':'779541, EGL - WEST, WEST NEAR, LUVHENGO SHEBEEN, 271 STAND NO, #, #, HONEYDEW, South Africa','geo':{'lat':-26.05171,'lng':27.90889}},
{'stp':785184,'address':'785184, EGL - WEST, WEST NEAR, BUSHYS PLACE, 220 STAND NO, ZANDSPRUIT, HONEYDEW, ZANDSPRUIT, HONEYDEW, HONEYDEW, South Africa','geo':{'lat':-26.05157,'lng':27.90867}},
{'stp':795938,'address':'795938, EGL - WEST, WEST NEAR, SELIMATHUNZI CORNER, STAND NO, ERF 19981 SHOP 53, HONEYDEW, HONEYDEW, South Africa','geo':{'lat':-26.05090,'lng':27.91117}},
{'stp':799851,'address':'799851, EGL - WEST, WEST NEAR, PICK N PAY GC81 COSMO MALL, DAWN ROADS, ERF 672 COSMO CITY MALL, CORNER MALIBONGE KYS SANDS, COSMOS CITY, South Africa','geo':{'lat':-26.01285,'lng':27.93941}},
{'stp':821004,'address':'821004, EGL - WEST, WEST NEAR, Chamdor Stakeholder, Chamdor Depot, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.15510,'lng':27.79540}},
{'stp':392857,'address':'392857, EGL - WEST, WEST NEAR, KHATHUS PLACE, 785 STAND NO, EXT 2, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92275,'lng':28.01426}},
{'stp':462058,'address':'462058, EGL - WEST, WEST NEAR, CONNY\'S TAVERN, 6504 STAND NO, MSAKAZO STREET, DIEPSLOOT EXT 9, DIEPSLOOT, South Africa','geo':{'lat':-25.93436,'lng':27.99840}},
{'stp':550474,'address':'550474, EGL - WEST, WEST NEAR, MAGONGWAS TAVERN, 913 DIEPSLOOT WEST, PRETORIA, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92412,'lng':28.01490}},
{'stp':550537,'address':'550537, EGL - WEST, WEST NEAR, MAFAHLA INN, SITE 100, DIEPSLOOT NUMBER 2, DIEPSLOOT WEST, DIEPSLOOT, South Africa','geo':{'lat':-25.91815,'lng':28.01939}},
{'stp':551682,'address':'551682, EGL - WEST, WEST NEAR, AMINGOS INN, 1095 STAND NO, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92280,'lng':28.01720}},
{'stp':552184,'address':'552184, EGL - WEST, WEST NEAR, JIMMYS TAVERN DIEPSLOOT, 406 DIEPSLOOT EXT 2, CHAMDOR, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92140,'lng':28.01780}},
{'stp':552416,'address':'552416, EGL - WEST, WEST NEAR, SIZABANTU LIQUOR STORE, STAND 443 DIEPSLOOT WEST, NORTH AVENUE, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92000,'lng':28.01800}},
{'stp':560260,'address':'560260, EGL - WEST, WEST NEAR, SIMINDLOS TAVERN, ERF 223 EXT 2, UKHAHLAMBA STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92230,'lng':28.01330}},
{'stp':560535,'address':'560535, EGL - WEST, WEST NEAR, NEMUFULWIS PLACE, STAND 1112 EXT 2, TLOU STREET, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92420,'lng':28.01970}},
{'stp':580698,'address':'580698, EGL - WEST, WEST NEAR, BAFOKENG SHEBEEN, FREE TOWN STREET, ERF 7587 EXT 6, #, RANDBURG, South Africa','geo':{'lat':-26.03223,'lng':27.92674}},
{'stp':580705,'address':'580705, EGL - WEST, WEST NEAR, ELSIE\'S TAVERN, 2556 TANZANIA STREET, COSMO CITY, #, COSMOS CITY, South Africa','geo':{'lat':-26.01570,'lng':27.92700}},
{'stp':580715,'address':'580715, EGL - WEST, WEST NEAR, BOITHABISO TUCKSHOP, DIEPSLOOT 896, EXT 12, HARTWILLE, DIEPSLOOT, South Africa','geo':{'lat':-26.02303,'lng':27.92630}},
{'stp':580827,'address':'580827, EGL - WEST, WEST NEAR, MATOME\'S PLACE, STAND 4877 EXT 5, MAPETLA STREET, DIEPSLOOT WEST, DIEPSLOOT, South Africa','geo':{'lat':-25.94750,'lng':28.01240}},
{'stp':580861,'address':'580861, EGL - WEST, WEST NEAR, KONE S TAVERN, STAND NO 2639, 49 DR ALFRED XUMA STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92920,'lng':28.00610}},
{'stp':580869,'address':'580869, EGL - WEST, WEST NEAR, BAFOKENG FLY BY NIGHT, STAND NO 3663, EXTENSION 6, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93930,'lng':28.01110}},
{'stp':580897,'address':'580897, EGL - WEST, WEST NEAR, MOKGOLO GENERAL DEALER, 172 ERF,  DIEPSLOOT EXTENSION 2 TOWNSHIP, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92100,'lng':28.01450}},
{'stp':580911,'address':'580911, EGL - WEST, WEST NEAR, VHANARINI TRADING ENTERPRISE, STAND 4777, EXTENSION 5, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.94400,'lng':28.01100}},
{'stp':580930,'address':'580930, EGL - WEST, WEST NEAR, KHAYELISHA TAVERN, ERF 263 EXT 2, CNR BUFFALO AND KANE STREET, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92180,'lng':28.01530}},
{'stp':603136,'address':'603136, EGL - WEST, WEST NEAR, THANDABATHU, 4781 EXTENSION 5, #, MAKHULWANA STR, DIEPSLOOT, South Africa','geo':{'lat':-25.94410,'lng':28.01210}},
{'stp':603274,'address':'603274, EGL - WEST, WEST NEAR, MESHAZK TAVERN, ERF 502 EXT 2, DUKUZA STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.91880,'lng':28.01890}},
{'stp':603310,'address':'603310, EGL - WEST, WEST NEAR, JULIAHAS TAVERN, 1106 EXTENTION 2, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92350,'lng':28.01970}},
{'stp':603373,'address':'603373, EGL - WEST, WEST NEAR, SAMS TAVERN, STAND 3203, DIEPSLOOT WEST, PRETORIA, DIEPSLOOT, South Africa','geo':{'lat':-25.93640,'lng':28.01130}},
{'stp':603389,'address':'603389, EGL - WEST, WEST NEAR, MABOAS TAVERN, 869 DIEPKLOOF, EXT 2, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92500,'lng':28.01810}},
{'stp':604265,'address':'604265, EGL - WEST, WEST NEAR, MOTHAPO M TAVERN, ERF 4219, EXTENSION 5 DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.94190,'lng':28.00680}},
{'stp':605116,'address':'605116, EGL - WEST, WEST NEAR, CALVIN MOKGOLA TUCKSHOP, STAND 304 EXT 2, UKHAHLAMBA STREET, DIEPSLOOT WEST, DIEPSLOOT, South Africa','geo':{'lat':-25.91990,'lng':28.01610}},
{'stp':605125,'address':'605125, EGL - WEST, WEST NEAR, BHEKE ZAKHO, ERF 4782  JULIAS NYERERE STREET, EXT 5, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.94420,'lng':28.01210}},
{'stp':605957,'address':'605957, EGL - WEST, WEST NEAR, SUCCESSES TAVERN, 2158 EXT 4, STABILITY STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92740,'lng':28.01130}},
{'stp':610553,'address':'610553, EGL - WEST, WEST NEAR, CHICARGO GENERAL DEALER (NTO), 358 EXT 2, DIEPSLOOT, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92050,'lng':28.01705}},
{'stp':624933,'address':'624933, EGL - WEST, WEST NEAR, MAHOMANE T/S (NTO), EXT 1, DIEPSLOOT, RANDBURG, DIEPSLOOT, South Africa','geo':{'lat':-25.92893,'lng':28.00543}},
{'stp':625958,'address':'625958, EGL - WEST, WEST NEAR, MATSEA TAVERN, STAND 5186 EXT3, KHETHAKWAKHO STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92460,'lng':28.01190}},
{'stp':626650,'address':'626650, EGL - WEST, WEST NEAR, LENGAKAS TAVERN, 6580 STAND NO, DIEPSLOOT, EXTENSION 11, DIEPSLOOT, South Africa','geo':{'lat':-25.93153,'lng':28.01785}},
{'stp':626995,'address':'626995, EGL - WEST, WEST NEAR, VHA VENDANI SHEBEEN, STAND 532 CA, EXTENTION 1, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92910,'lng':28.02060}},
{'stp':627001,'address':'627001, EGL - WEST, WEST NEAR, PHILLIPS TUCK SHOP, STAND 1126, MGABABA STREET, DIEPSLOOT WEST, DIEPSLOOT, South Africa','geo':{'lat':-25.92390,'lng':28.01910}},
{'stp':627022,'address':'627022, EGL - WEST, WEST NEAR, OUPAS 791 SHEBEEN, ERF 791 EXTENTION 2, MABOKO STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92290,'lng':28.01410}},
{'stp':627170,'address':'627170, EGL - WEST, WEST NEAR, TSAMAHANSI, STAND 4431 EXT 5, JULY NKOSI STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.94280,'lng':28.01100}},
{'stp':627605,'address':'627605, EGL - WEST, WEST NEAR, LEBOGANG TAVERN, 854 EXTENTION 2, PITSI STREET, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92560,'lng':28.01710}},
{'stp':629146,'address':'629146, EGL - WEST, WEST NEAR, SOLLYS TAVERN, STAND  510, DUKUZA STREET, DIEPSLOOT WEST, DIEPSLOOT, South Africa','geo':{'lat':-25.91912,'lng':28.01990}},
{'stp':629148,'address':'629148, EGL - WEST, WEST NEAR, PHUMULANI RANA TAVERN, STAND 564 EXT 2, DIEPSLOOT, RANDBURG, DIEPSLOOT, South Africa','geo':{'lat':-25.91920,'lng':28.02040}},
{'stp':629446,'address':'629446, EGL - WEST, WEST NEAR, SHADYS TAVERN, STAND 654, FUNDUBZI STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93140,'lng':28.01650}},
{'stp':629709,'address':'629709, EGL - WEST, WEST NEAR, BATAU TAVERN, 2818 EXTENTION 6, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93510,'lng':28.01280}},
{'stp':629988,'address':'629988, EGL - WEST, WEST NEAR, WISANI TAVERN, 1746 EXTENTION 2, COSMO CITY, #, COSMOS CITY, South Africa','geo':{'lat':-26.01990,'lng':27.92440}},
{'stp':630634,'address':'630634, EGL - WEST, WEST NEAR, BRA VICTORS TAVERN, STAND 6765 EXT 6, SABELO PHEMA STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93249,'lng':28.01977}},
{'stp':630711,'address':'630711, EGL - WEST, WEST NEAR, TSOQA O EKEL SETSE, 2401 LESOTHO ST, COSMO CITY EXT 2, RANDBURG, COSMOS CITY, South Africa','geo':{'lat':-26.01440,'lng':27.92640}},
{'stp':630731,'address':'630731, EGL - WEST, WEST NEAR, LUFUNO TAVERN, 2481 MASERU ST, COSMO CITY, RANDBURG, COSMOS CITY, South Africa','geo':{'lat':-26.01390,'lng':27.92550}},
{'stp':631192,'address':'631192, EGL - WEST, WEST NEAR, TOMS TAVERN, 1520 EXTENTION 4, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92670,'lng':28.00370}},
{'stp':631245,'address':'631245, EGL - WEST, WEST NEAR, ANNAS TAVERN, STAND 6589 EXT 11, ZEPH MOTHOTENG STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93220,'lng':28.01720}},
{'stp':631507,'address':'631507, EGL - WEST, WEST NEAR, BALOYI TAVERN, 1518  EXTENTION 4, INYONI STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92671,'lng':28.00362}},
{'stp':632278,'address':'632278, EGL - WEST, WEST NEAR, SELOANE TAVERN, 5105 EXTENSION 8, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92480,'lng':28.01170}},
{'stp':632568,'address':'632568, EGL - WEST, WEST NEAR, RAMPEDI TAVERN, 1215 EXTENSION 4, INYONI STREET, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92550,'lng':28.00570}},
{'stp':633165,'address':'633165, EGL - WEST, WEST NEAR, OBBY S PLACE, 4280 EXTENSION 5, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.94170,'lng':28.01100}},
{'stp':633263,'address':'633263, EGL - WEST, WEST NEAR, MTHIBANE SHEBEEN, STAND 313 EXT2, UKHAHLAMBA STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.91945,'lng':28.01707}},
{'stp':633914,'address':'633914, EGL - WEST, WEST NEAR, MAMAJO, 26 EXTENSION 7, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93667,'lng':28.00357}},
{'stp':634185,'address':'634185, EGL - WEST, WEST NEAR, MAYIBUYE TAVERN, ERF 5667 EXT 5, PARKS MANKAHLANE STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93440,'lng':28.00460}},
{'stp':635066,'address':'635066, EGL - WEST, WEST NEAR, VENDA BOY TAVERN, 1421 TANZANIA STREET, COSMO CITY, JOHANNESBURG, COSMOS CITY, South Africa','geo':{'lat':-26.01800,'lng':27.92400}},
{'stp':635162,'address':'635162, EGL - WEST, WEST NEAR, THABANG TUCKSHOP, STAND NO 984, EXTENSION 2, DIEPSLOOT WEST, DIEPSLOOT, South Africa','geo':{'lat':-25.92400,'lng':28.01550}},
{'stp':635547,'address':'635547, EGL - WEST, WEST NEAR, NO 8 TAVERN, 5069 EXTENSION 3, DIEPSLOOT, RANDBURG, DIEPSLOOT, South Africa','geo':{'lat':-25.92380,'lng':28.01120}},
{'stp':635960,'address':'635960, EGL - WEST, WEST NEAR, THOKOZANI TUCK SHOP, 246 EXTENSION 2, DIEPSLOOT, RANDBURG, DIEPSLOOT, South Africa','geo':{'lat':-25.92160,'lng':28.01320}},
{'stp':636141,'address':'636141, EGL - WEST, WEST NEAR, L DLAMINI, ERF 744, MAKHANDO STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92150,'lng':28.02020}},
{'stp':636338,'address':'636338, EGL - WEST, WEST NEAR, ITC TAVERN, 6126 EXTENSION 9, PHENDUKA STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93100,'lng':27.99900}},
{'stp':636719,'address':'636719, EGL - WEST, WEST NEAR, MAY SPAZA, 2138 CASABLANCA STREET, COSMO CITY, RANDBURG, COSMOS CITY, South Africa','geo':{'lat':-26.01830,'lng':27.92490}},
{'stp':636813,'address':'636813, EGL - WEST, WEST NEAR, DRIVE IN TAVERN, 5939 DIEPSLOOT, EXTENTION 7, RANDBURG, DIEPSLOOT, South Africa','geo':{'lat':-25.93670,'lng':28.00360}},
{'stp':637007,'address':'637007, EGL - WEST, WEST NEAR, JAVAS, 4889 EXT 5, BARDIRI STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.94630,'lng':28.01280}},
{'stp':637024,'address':'637024, EGL - WEST, WEST NEAR, SMALL HAVEN, 4515, makhulong street, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.94360,'lng':28.01140}},
{'stp':637536,'address':'637536, EGL - WEST, WEST NEAR, GREEN HOUSE, 1863 BALEKA STREET, EXTENSION 7, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93050,'lng':28.00160}},
{'stp':637915,'address':'637915, EGL - WEST, WEST NEAR, SEVHA TAVERN, BALEKA STREET, 2073 EXT 7, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93250,'lng':28.00130}},
{'stp':639023,'address':'639023, EGL - WEST, WEST NEAR, SEDZAZWAWO, 1683 EXTENTION 4, DR ALFRED XUMA STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92800,'lng':28.00430}},
{'stp':639315,'address':'639315, EGL - WEST, WEST NEAR, MOLESENG SHEBEEN, 3828 DIEPSLOOT WEST EXT 6, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93750,'lng':28.01280}},
{'stp':639570,'address':'639570, EGL - WEST, WEST NEAR, SELIMANTHUNZI INN MINI SHOP, ERF 535, REPANG CLOSE STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.91990,'lng':28.01930}},
{'stp':639614,'address':'639614, EGL - WEST, WEST NEAR, DT MATHEBULA TAVERN AND TUCKSHOP CC, D509 EXT 1, DIEPSLOOT, RANDBURG, DIEPSLOOT, South Africa','geo':{'lat':-25.92820,'lng':28.01720}},
{'stp':640251,'address':'640251, EGL - WEST, WEST NEAR, ITHUMELE TUCK SHOP, E788 DIEPSLOOT EXT 1, RANDBURG, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92960,'lng':28.01520}},
{'stp':640739,'address':'640739, EGL - WEST, WEST NEAR, HAPPY\'S INN, 258 EXTENSION 2, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92180,'lng':28.01490}},
{'stp':640745,'address':'640745, EGL - WEST, WEST NEAR, BOETA S SHEBEEN, 2867 STAND NO, DIEPSLOOT WEST, EXTENSION 6, DIEPSLOOT, South Africa','geo':{'lat':-25.93311,'lng':28.01020}},
{'stp':640929,'address':'640929, EGL - WEST, WEST NEAR, LUCYS PLACE OF JOY, 271 THABO MBEKI, INFORMAL SETTLEMENTS, MOGALE CITY, RANDBURG, South Africa','geo':{'lat':-25.98620,'lng':27.92930}},
{'stp':641087,'address':'641087, EGL - WEST, WEST NEAR, WALALA WASALA, 379 THABO MBEKI INFORMAL SETTLEMENT, MOGALE CITY, #, RANDBURG, South Africa','geo':{'lat':-25.98740,'lng':27.93010}},
{'stp':641202,'address':'641202, EGL - WEST, WEST NEAR, KWA NKUNA, 631 STAND NO, ETIANINE STREET, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92850,'lng':28.02080}},
{'stp':641203,'address':'641203, EGL - WEST, WEST NEAR, UNCLE\'S PLACE, 5093 EXTENTION 8, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92410,'lng':28.01130}},
{'stp':641269,'address':'641269, EGL - WEST, WEST NEAR, KHIPI NDLALA TAVERN, 1114 EXT 2, DIEPSLOOT, RANDBURG, DIEPSLOOT, South Africa','geo':{'lat':-25.92430,'lng':28.01960}},
{'stp':641287,'address':'641287, EGL - WEST, WEST NEAR, PPAPA\'S TAVERN, 3237 EXTENTION 6, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93620,'lng':28.00910}},
{'stp':641590,'address':'641590, EGL - WEST, WEST NEAR, RINAS TAVERN, 4794  EXTENTION 5, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.94540,'lng':28.01200}},
{'stp':641599,'address':'641599, EGL - WEST, WEST NEAR, MOGOU\'S TAVERN, 6401 DIEPSLOOT, DIEPSLOOT, EXT 7, DIEPSLOOT, South Africa','geo':{'lat':-25.93650,'lng':28.00590}},
{'stp':642073,'address':'642073, EGL - WEST, WEST NEAR, BEERHALL GARDEN, 2011 BALEKA STREET, EXTENTION 7, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93130,'lng':28.00270}},
{'stp':642137,'address':'642137, EGL - WEST, WEST NEAR, GWELE\'S PLACE, ERF 344, BUFFALO STREET, EXT 2, DIEPSLOOT, South Africa','geo':{'lat':-25.92150,'lng':28.01610}},
{'stp':642279,'address':'642279, EGL - WEST, WEST NEAR, BUSY CORNER, 1325 EXTENSION 4, SIZANANI STREET, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92570,'lng':28.00680}},
{'stp':642281,'address':'642281, EGL - WEST, WEST NEAR, COME AGAIN T SHOP, 161b  EXTENTION 1, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92930,'lng':28.01270}},
{'stp':642655,'address':'642655, EGL - WEST, WEST NEAR, MATSENA INN, 160 MANGWA STREET, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92060,'lng':28.01480}},
{'stp':642685,'address':'642685, EGL - WEST, WEST NEAR, K W TAVERN, 4218 EXTENTION 5, BOSELELE STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.94210,'lng':28.00690}},
{'stp':642691,'address':'642691, EGL - WEST, WEST NEAR, MOTHEPA S TUCK SHOP, 2107 EXTENTION 7, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93170,'lng':28.00390}},
{'stp':646495,'address':'646495, EGL - WEST, WEST NEAR, MODIBA\'S TAVERN, 5313 EXTENSION 1, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92933,'lng':28.01461}},
{'stp':646969,'address':'646969, EGL - WEST, WEST NEAR, ROSTAS TAVERN, 6457 EXTENTION 9, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93420,'lng':28.00120}},
{'stp':647522,'address':'647522, EGL - WEST, WEST NEAR, KWA ZULU, 479 479 EXTENTION 2, DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.91940,'lng':28.01940}},
{'stp':649146,'address':'649146, EGL - WEST, WEST NEAR, THANDABANTU\'S TAVERN, 822 EXT 2, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92350,'lng':28.01310}},
{'stp':649198,'address':'649198, EGL - WEST, WEST NEAR, SIZABANTU\'S PLACE, 728 EXTENSION 2 DR M MAKHABO STR, DIEPSLOOT WEST, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92090,'lng':28.02060}},
{'stp':649571,'address':'649571, EGL - WEST, WEST NEAR, HADEBES TUCKSHOP, 3928 Ext 6, Liberty Street, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93806,'lng':28.01382}},
{'stp':650078,'address':'650078, EGL - WEST, WEST NEAR, DZAMBA TWO, 1314 DIEPSLOOT WEST, EXTENSION 4, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92510,'lng':28.00840}},
{'stp':650261,'address':'650261, EGL - WEST, WEST NEAR, MABOTANE TAVERN, 1480 EXT 4, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92710,'lng':28.00600}},
{'stp':650262,'address':'650262, EGL - WEST, WEST NEAR, JULIAS TAVERN, 84 DIEPSLOOT WEST EXT 2, KING DINIZULU STREET, #, DIEPSLOOT, South Africa','geo':{'lat':-25.91740,'lng':28.02220}},
{'stp':653625,'address':'653625, EGL - WEST, WEST NEAR, MZIMBA TAVERN, 3783 EXT 6, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93860,'lng':28.01220}},
{'stp':655419,'address':'655419, EGL - WEST, WEST NEAR, ELICIAS TAVERN, 557 NO F557 EXTENSION 1, NDIMATSHELONI STREET, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.92940,'lng':28.01310}},
{'stp':656117,'address':'656117, EGL - WEST, WEST NEAR, DARK CITY LIQUORS, 0 STAND NO 157,  UKHAHLAMBA STREET EXT 2, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92050,'lng':28.01510}},
{'stp':656118,'address':'656118, EGL - WEST, WEST NEAR, EKHONENI, 0 4816 MAPETLA STREET, #, EXTENSION 5, DIEPSLOOT, South Africa','geo':{'lat':-25.94480,'lng':28.01310}},
{'stp':656461,'address':'656461, EGL - WEST, WEST NEAR, CHECCOS SHEBEEN, 8101 COSMO CITY, EXT 6, #, COSMOS CITY, South Africa','geo':{'lat':-26.03350,'lng':27.92770}},
{'stp':661034,'address':'661034, EGL - WEST, WEST NEAR, SCARA S SHOP, 6546 MAIN STREET, EXTENSION 11, NEXT TO TAXI  RANK, DIEPSLOOT, South Africa','geo':{'lat':-25.93085,'lng':28.01693}},
{'stp':661165,'address':'661165, EGL - WEST, WEST NEAR, BANARENG CAFE AND RESTAURANT, 0 833 RAVELE STREET, #, EXTENSION 2, DIEPSLOOT, South Africa','geo':{'lat':-25.92410,'lng':28.01380}},
{'stp':663132,'address':'663132, EGL - WEST, WEST NEAR, MARIAS TAVERN, 0 I845 OYSTER STREET, EXTENSION 1, DIEPSLOOT, DIEPSLOOT, South Africa','geo':{'lat':-25.93220,'lng':28.01467}},
{'stp':706230,'address':'706230, EGL - WEST, WEST NEAR, THABO MBEKI, STAND 117 THABO MBEKI, MOGALE CITY, #, RANDBURG, South Africa','geo':{'lat':-25.98830,'lng':27.93210}},
{'stp':763943,'address':'763943, EGL - WEST, WEST NEAR, RAMAS TAVERN, 1776 STAND NO, IMGWEYAMA STREET, DIEPSLOOT EXTENSION 4, RANDBURG, South Africa','geo':{'lat':-25.92959,'lng':28.00511}},
{'stp':774609,'address':'774609, EGL - WEST, WEST NEAR, TSUVHULA S INN, 6552 STAND NO, EXT 1  DIEPSLOOT, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93114,'lng':28.01623}},
{'stp':776251,'address':'776251, EGL - WEST, WEST NEAR, MABUKES PLACE, 6552 ZEPH MOTHOPENG STREET, EXT 11, #, DIEPSLOOT, South Africa','geo':{'lat':-25.92150,'lng':28.01655}},
{'stp':781471,'address':'781471, EGL - WEST, WEST NEAR, ERIC TUCKSHOP, 5315 STAND NO, CHIEF LANGALIBALELE STREET, EXT 7, DIEPSLOOT, South Africa','geo':{'lat':-25.93356,'lng':28.01585}},
{'stp':796820,'address':'796820, EGL - WEST, WEST NEAR, T N TAVERN, 6305 MIKE HARMEL STREET, #, #, DIEPSLOOT, South Africa','geo':{'lat':-25.93410,'lng':27.99932}},
{'stp':388323,'address':'388323, EGL - WEST, WEST NEAR, MKHABELA TAVERN, 57353 STAND NO, 3RD AVENUE LUSAKA, RIETVALLEI, RANDFONTEIN, South Africa','geo':{'lat':-26.19342,'lng':27.74438}},
{'stp':388738,'address':'388738, EGL - WEST, WEST NEAR, LIQUOR LEGENDS LIQUOR STORE, 777 ERF NO, NO 2 SUTHERLAND AVENUE, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.18241,'lng':27.69892}},
{'stp':392133,'address':'392133, EGL - WEST, WEST NEAR, XOLI PLACE, 16095 THABANTSU STREET EXT 12 KASIG, #, #, KAGISO, South Africa','geo':{'lat':-26.17792,'lng':27.78867}},
{'stp':460074,'address':'460074, EGL - WEST, WEST NEAR, VENDAS INN, 17463 STAND NO, ROGGERVELD STREET, KAGISO EXTENSION 12, KAGISO, South Africa','geo':{'lat':-26.18078,'lng':27.79072}},
{'stp':473381,'address':'473381, EGL - WEST, WEST NEAR, MATSOANELO TAVERN, 3133 MADIBA STREET, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.19039,'lng':27.74818}},
{'stp':478800,'address':'478800, EGL - WEST, WEST NEAR, MASIZAS PLACE, 18598 STAND NO, LETHABONG STREET, #, KAGISO, South Africa','geo':{'lat':-26.17417,'lng':27.78115}},
{'stp':550177,'address':'550177, EGL - WEST, WEST NEAR, TLHOKE S PLACE, 16347 STAND NO,  SUURBERG STREET  EXT 12  KAGISO 2, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17288,'lng':27.79327}},
{'stp':550762,'address':'550762, EGL - WEST, WEST NEAR, KGAMATSO INN, STAND 16944 ELANDSBERG STREET, EXTENTION 12, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17945,'lng':27.79137}},
{'stp':550904,'address':'550904, EGL - WEST, WEST NEAR, GREAVES PLACE, 15488/37 EXTENTION 12, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.17250,'lng':27.78690}},
{'stp':550992,'address':'550992, EGL - WEST, WEST NEAR, ALMAS, ERF 0149, FREDALAAN 34 A, HOMELAKE, RANDFONTEIN, South Africa','geo':{'lat':-26.17000,'lng':27.70560}},
{'stp':551293,'address':'551293, EGL - WEST, WEST NEAR, RANDFONTEIN ESTATES SPORTS CLUB, RANDFONTEIN GOLF COURSE, HOMESTEAD LAAN, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.15620,'lng':27.71410}},
{'stp':551595,'address':'551595, EGL - WEST, WEST NEAR, JIMMYS LIQUOR STORE, 106 A MAIN REEF RD, RANDFONTEIN, #, RANDFONTEIN, South Africa','geo':{'lat':-26.17350,'lng':27.70860}},
{'stp':552314,'address':'552314, EGL - WEST, WEST NEAR, SOLLYS KITCHEN, 16318 LION HEAD STREET, EXTENTION 12, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17410,'lng':27.79220}},
{'stp':553393,'address':'553393, EGL - WEST, WEST NEAR, LIQUOR SPOT LIQUOR STORE, NO 16 STATION STREET, RANDFONTEIN, #, RANDFONTEIN, South Africa','geo':{'lat':-26.18270,'lng':27.69790}},
{'stp':553484,'address':'553484, EGL - WEST, WEST NEAR, MMS PLACE, 4647 RIETVLEI, EXTENTION 2, #, KAGISO, South Africa','geo':{'lat':-26.18330,'lng':27.75820}},
{'stp':553552,'address':'553552, EGL - WEST, WEST NEAR, BAVUMILE, STAND 3567 EXT 2 & 3, RIETVALLEI, #, KAGISO, South Africa','geo':{'lat':-26.19290,'lng':27.74750}},
{'stp':553557,'address':'553557, EGL - WEST, WEST NEAR, MARIA\'S TAVERN, 16311 LION HEAD STREET, EXT 12, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17450,'lng':27.79210}},
{'stp':560365,'address':'560365, EGL - WEST, WEST NEAR, THE ONE BIG FAMILY, 4494 RIETVALLEI,  MOGALE CITY, #, KAGISO, South Africa','geo':{'lat':-26.18320,'lng':27.75930}},
{'stp':560462,'address':'560462, EGL - WEST, WEST NEAR, MKIKANAS, 17095 MOGALE STREET, EXT 12 KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.18030,'lng':27.79150}},
{'stp':560501,'address':'560501, EGL - WEST, WEST NEAR, MATUS INN, STAND 3572, EXT 2, #, KAGISO, South Africa','geo':{'lat':-26.19250,'lng':27.74790}},
{'stp':560558,'address':'560558, EGL - WEST, WEST NEAR, LUCKY S PLACE, STAND 15488, MOLATIHOA STREET 15, EXT 12 KAGISO, KAGISO, South Africa','geo':{'lat':-26.17240,'lng':27.78990}},
{'stp':581058,'address':'581058, EGL - WEST, WEST NEAR, CHIS PLACE, 15845 THABANCHU ST, EXT 12, #, KAGISO, South Africa','geo':{'lat':-26.17590,'lng':27.78810}},
{'stp':603607,'address':'603607, EGL - WEST, WEST NEAR, KIT KAT TAVERN AND SPORTS BAR, STAND 769 SHOP 2, MUNSEY RD, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.18320,'lng':27.69820}},
{'stp':603774,'address':'603774, EGL - WEST, WEST NEAR, BETHUELS TAVERN, 5870 EXT 3, LUSAKA, #, KAGISO, South Africa','geo':{'lat':-26.19750,'lng':27.74280}},
{'stp':604154,'address':'604154, EGL - WEST, WEST NEAR, MOAGIS TAVERN, 4814 EXT 2 AND 3, RIETVALLEI, #, KAGISO, South Africa','geo':{'lat':-26.18380,'lng':27.75480}},
{'stp':604170,'address':'604170, EGL - WEST, WEST NEAR, KHOZAS PLACE, 4599 LUSAKA, EXT 2 RIETVALLEI, #, KAGISO, South Africa','geo':{'lat':-26.18230,'lng':27.75690}},
{'stp':604677,'address':'604677, EGL - WEST, WEST NEAR, JACARANS, 17533 CHAPMAN STREET, EXTENTION 12, KAGISO, KAGISO, South Africa','geo':{'lat':-26.18150,'lng':27.79310}},
{'stp':605105,'address':'605105, EGL - WEST, WEST NEAR, JOHNSON ENTERTAINMENT CENTRE, 4404 LUSAKA EXT 2, RIETVALLEI, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.18340,'lng':27.75180}},
{'stp':605971,'address':'605971, EGL - WEST, WEST NEAR, PROGRESS, 16703 MAHLABA ST, KAGISO EXT 12, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.17475,'lng':27.79473}},
{'stp':606127,'address':'606127, EGL - WEST, WEST NEAR, TIPFUXENI TAVERN, 15898 THABABOSELO ST, EXT 12, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17520,'lng':27.78700}},
{'stp':607081,'address':'607081, EGL - WEST, WEST NEAR, CYNTHIAS PLACE, 5796 LUSAKA EXT 3, RIETVALLEI, KAGISO MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.19890,'lng':27.74220}},
{'stp':626761,'address':'626761, EGL - WEST, WEST NEAR, SIYAZENZELA, 212 TSHEPISONG WEST, ROODEPOORT, #, KAGISO, South Africa','geo':{'lat':-26.18350,'lng':27.78650}},
{'stp':627498,'address':'627498, EGL - WEST, WEST NEAR, RUSTENBURG IN, 16205 GEBA STREET, EXTENTION 12, #, KAGISO, South Africa','geo':{'lat':-26.17850,'lng':27.78730}},
{'stp':627646,'address':'627646, EGL - WEST, WEST NEAR, MZONDIS INN, 5983 EXT 2/3, RIETVALLEI, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.19950,'lng':27.74370}},
{'stp':627791,'address':'627791, EGL - WEST, WEST NEAR, POLA INN, 11893 EXT 6 PEACOCK STREET, HOSPITAL VIEW, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17250,'lng':27.79860}},
{'stp':627944,'address':'627944, EGL - WEST, WEST NEAR, ROBBIES PALACE, 5819 RIETVALLEI, EXT 3 KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.20000,'lng':27.74200}},
{'stp':628039,'address':'628039, EGL - WEST, WEST NEAR, BOYS II MAN, 3545 EXT 2, RIETVALLEI, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.19040,'lng':27.74930}},
{'stp':629266,'address':'629266, EGL - WEST, WEST NEAR, MNGUNI ZITHA TRADING AND PROJECTS, STAND 3993, RIETVLEI, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.18750,'lng':27.75160}},
{'stp':629385,'address':'629385, EGL - WEST, WEST NEAR, WET WELL JAZZ CLUB, 4990 RIETVALLEI EXT 2, KRUGERSDORP, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.18430,'lng':27.75600}},
{'stp':630055,'address':'630055, EGL - WEST, WEST NEAR, BOITUMELOS INN, 20110/1 STAND NO, CEDABURG STREET, KAGISO EXT 13, KAGISO, South Africa','geo':{'lat':-26.17678,'lng':27.79401}},
{'stp':630292,'address':'630292, EGL - WEST, WEST NEAR, LADUMA TAVERN, 4289 EXT 2 LUSAKA, MOGALE CITY, #, KAGISO, South Africa','geo':{'lat':-26.18330,'lng':27.75106}},
{'stp':630479,'address':'630479, EGL - WEST, WEST NEAR, TSHIKWENI INN, 17029 WATERBERG ST, KAGISO EXT 12, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.17950,'lng':27.79380}},
{'stp':631843,'address':'631843, EGL - WEST, WEST NEAR, STAR POINT PLACE, 4028 EXT 2/3, RIETVALLEI, #, KAGISO, South Africa','geo':{'lat':-26.18950,'lng':27.74990}},
{'stp':632007,'address':'632007, EGL - WEST, WEST NEAR, GALIES PLACE, 18664 EXT 14 MOUNT EVEREST STR, KAGISO, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.17640,'lng':27.78170}},
{'stp':632598,'address':'632598, EGL - WEST, WEST NEAR, LONGSBASE, 16260 GEBA STREET, KAGISO EXT 12, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.17830,'lng':27.78820}},
{'stp':634944,'address':'634944, EGL - WEST, WEST NEAR, KAGISO BUSI\'S PLACE, 16639 GILBES TSITA STREET, EXTENSION 12, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17810,'lng':27.79080}},
{'stp':635344,'address':'635344, EGL - WEST, WEST NEAR, MADUMO PLACE, 4016 RIETVALLEI EXT 2, KAGISO 2, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.18700,'lng':27.75120}},
{'stp':638242,'address':'638242, EGL - WEST, WEST NEAR, MACHAVA INN, 4721  5 TH STREET, EXTENTION 2, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.18340,'lng':27.75800}},
{'stp':638954,'address':'638954, EGL - WEST, WEST NEAR, SHOPRITE RANDFONTEIN 51475, DUNCAN STREET, CNR MAIN REEF RD, SAN REMOS STR, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.17920,'lng':27.70550}},
{'stp':639789,'address':'639789, EGL - WEST, WEST NEAR, ITOSENG BOTTLE STORE, 11694 GEBA STREET, KAGISO 2, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.16700,'lng':27.79370}},
{'stp':639931,'address':'639931, EGL - WEST, WEST NEAR, BELLA VISTA RESTAURANT, 37 VILLAGE STREET, STAND NO 368, RANDFOTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.18100,'lng':27.70330}},
{'stp':641197,'address':'641197, EGL - WEST, WEST NEAR, MANDLA\'S PLACE, 3144 EXT 2, RIETVALLEI, MOGALE CITY, KAGISO, South Africa','geo':{'lat':-26.18970,'lng':27.74690}},
{'stp':641706,'address':'641706, EGL - WEST, WEST NEAR, BANYAMME TUCKSHOP, 19034 MOUNT EVEREST STREET, EXT 14, #, KAGISO, South Africa','geo':{'lat':-26.17740,'lng':27.78480}},
{'stp':641760,'address':'641760, EGL - WEST, WEST NEAR, PATTYS PLACE, 16236 GEBA STREET, KAGISO EXT 12, #, KAGISO, South Africa','geo':{'lat':-26.17780,'lng':27.79020}},
{'stp':641807,'address':'641807, EGL - WEST, WEST NEAR, TSIKITSIKIS INN, 3296 EXTENSION 2, LUSAKA, #, KAGISO, South Africa','geo':{'lat':-26.19113,'lng':27.74753}},
{'stp':642457,'address':'642457, EGL - WEST, WEST NEAR, BRA DARKIE, 18561 LITHABANG CRESENT, EXT 14, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17410,'lng':27.78050}},
{'stp':646521,'address':'646521, EGL - WEST, WEST NEAR, BONIS PLACE, 17216 EXTENSION 1, MANGALIESBERG STREET EXT 12, #, KAGISO, South Africa','geo':{'lat':-26.18130,'lng':27.78900}},
{'stp':646710,'address':'646710, EGL - WEST, WEST NEAR, ANASHI, 17606 CHAPMAN DRIVE, EXTENSION 12, KAGISO, KAGISO, South Africa','geo':{'lat':-26.18160,'lng':27.78780}},
{'stp':646726,'address':'646726, EGL - WEST, WEST NEAR, MIKE\'S PLACE, 17175 GEBA STREET, EXTENSION 12, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17890,'lng':27.78770}},
{'stp':646741,'address':'646741, EGL - WEST, WEST NEAR, NTOMBI PLACE, 19145 MOUNT EVEREST STREET, EXTENTION 14, KAGISO, KAGISO, South Africa','geo':{'lat':-26.17730,'lng':27.78470}},
{'stp':648094,'address':'648094, EGL - WEST, WEST NEAR, BULI\'S PLACE, STAND 18661 MOUNT EVEREST, EXTENSION 14, #, KAGISO, South Africa','geo':{'lat':-26.17640,'lng':27.78140}},
{'stp':649726,'address':'649726, EGL - WEST, WEST NEAR, BHONGWENI CLUB, 244 FARM UITVALFONTEIN, AND FARM RIETVALLEI, #, RANDFONTEIN, South Africa','geo':{'lat':-26.16715,'lng':27.72683}},
{'stp':651145,'address':'651145, EGL - WEST, WEST NEAR, THANDEKAS PLACE, 16807 EXT 12, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.17730,'lng':27.79340}},
{'stp':651492,'address':'651492, EGL - WEST, WEST NEAR, SIMUNYE TUCKSHOP, 4093 LUSAKA EXT 2, #, #, KAGISO, South Africa','geo':{'lat':-26.19050,'lng':27.75170}},
{'stp':651520,'address':'651520, EGL - WEST, WEST NEAR, PHUMLANI TAVERN, 4534 7 TH AVENUE,  RIETVALLEI, KAGISO EXTENSION 2, #, KAGISO, South Africa','geo':{'lat':-26.18230,'lng':27.75680}},
{'stp':651577,'address':'651577, EGL - WEST, WEST NEAR, THABELO\'S PLACE, 16789 DRAKENSBERG STREET, EXT 12 KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.18130,'lng':27.78903}},
{'stp':651968,'address':'651968, EGL - WEST, WEST NEAR, MOLAPO TUCKSHOP, 16585 STORMBERG STREET, KAGISO EXTENTION 12, #, KAGISO, South Africa','geo':{'lat':-26.17460,'lng':27.79510}},
{'stp':652058,'address':'652058, EGL - WEST, WEST NEAR, ANDELANE TUCKSHOP, LUSAKA 4901, EXTENSION 2, #, KAGISO, South Africa','geo':{'lat':-26.18420,'lng':27.75790}},
{'stp':652820,'address':'652820, EGL - WEST, WEST NEAR, SAMS TAVERN, 8 ANARKAZI STREET, AZAADVILLE, #, KAGISO, South Africa','geo':{'lat':-26.16380,'lng':27.75920}},
{'stp':653395,'address':'653395, EGL - WEST, WEST NEAR, LOLOS PLACE, 15889 PILANE STREET, KAGISO EXTENSION 12, #, KAGISO, South Africa','geo':{'lat':-26.17500,'lng':27.78860}},
{'stp':657315,'address':'657315, EGL - WEST, WEST NEAR, SOLLYS PLACE, 6922  IXOPO DRIVE, #, KAGISO, KAGISO, South Africa','geo':{'lat':-26.18150,'lng':27.70340}},
{'stp':684351,'address':'684351, EGL - WEST, WEST NEAR, NEW STATION TEAROOM, 01 STATION STREET, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.18130,'lng':27.69900}},
{'stp':691392,'address':'691392, EGL - WEST, WEST NEAR, OVERLAND LIQUOR STORE, CNR SUTHERLAND AND STUBB STR, WRDM BUILDING, #, RANDFONTEIN, South Africa','geo':{'lat':-26.18170,'lng':27.70080}},
{'stp':697121,'address':'697121, EGL - WEST, WEST NEAR, FUNKY LIQUOR MARKET, 3043 STAND NO, SHOP 02, CNR SEBENZISA AND DINGAAN STREETS, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.16010,'lng':27.78600}},
{'stp':698330,'address':'698330, EGL - WEST, WEST NEAR, JABULANI TAVERN, 142 MAIN ROAD, ERF 30, #, RANDFONTEIN, South Africa','geo':{'lat':-26.16630,'lng':27.71190}},
{'stp':707586,'address':'707586, EGL - WEST, WEST NEAR, CONSOLIDATED MEAT INDUSTRIES (NTO), PORTION OF THE REM EXT, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.15980,'lng':27.72160}},
{'stp':715745,'address':'715745, EGL - WEST, WEST NEAR, LEASURE AND PLEASURE TAVERN, 3424 LUSAKA, RIEVALLEY, #, KAGISO, South Africa','geo':{'lat':-26.19320,'lng':27.74600}},
{'stp':740922,'address':'740922, EGL - WEST, WEST NEAR, TOPS AT RANDFONTEIN, 32 MAIN REEF ROAD, BETWEEN 3RD AND SUTHERLAND STREETS, ERVRN 407 AND 784, RANDFONTEIN, South Africa','geo':{'lat':-26.18260,'lng':27.70300}},
{'stp':761471,'address':'761471, EGL - WEST, WEST NEAR, RANDFONTEIN CLUB (NTO), 54 VILLAGE STREET, RANDFONTEIN, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.17881,'lng':27.70364}},
{'stp':761661,'address':'761661, EGL - WEST, WEST NEAR, LOVER SPOT, 772 STAND NO, STATIONS STREET RANDFONTEIN, GREEN HILLS, RANDFONTEIN, South Africa','geo':{'lat':-26.18100,'lng':27.69760}},
{'stp':769168,'address':'769168, EGL - WEST, WEST NEAR, ADAMS PLACE, 99 STAND NO, TSHEPISONG WEST, #, ROODEPOORT, South Africa','geo':{'lat':-26.15313,'lng':27.78600}},
{'stp':769870,'address':'769870, EGL - WEST, WEST NEAR, CHECKERS LIQ RANDFONTEIN 64989, ERF 940 SHOP NO 4, VILLAGE SQUARE SHOPPING CENTRE, 72 MAIN REEF ROAD, RANDFONTEIN, South Africa','geo':{'lat':-26.17809,'lng':27.70395}},
{'stp':797672,'address':'797672, EGL - WEST, WEST NEAR, TLALES PLACE, 17561 STAND NO, CHAPMAN STREET, EXT 12, KAGISO, South Africa','geo':{'lat':-26.18180,'lng':27.79078}},
{'stp':388354,'address':'388354, EGL - WEST, WEST NEAR, MALELE TAVERN, 1211 STAND NO, BALOYI STREET, SINQOILE, KAGISO, South Africa','geo':{'lat':-26.14278,'lng':27.78435}},
{'stp':390261,'address':'390261, EGL - WEST, WEST NEAR, BUKIES PUB AND GRILL, STAND NO, U181 TRACEY ROAD WEST VILLAGE, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.11783,'lng':27.75069}},
{'stp':390681,'address':'390681, EGL - WEST, WEST NEAR, STATION TAVERN, 16 STAND NO, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10839,'lng':27.77098}},
{'stp':463387,'address':'463387, EGL - WEST, WEST NEAR, SIZAMA IMPILO, 802 STAND NO, PLOT NO 6,  MATSHELAPATA STREET, MAGALIESBURG, South Africa','geo':{'lat':-26.07634,'lng':27.65790}},
{'stp':467944,'address':'467944, EGL - WEST, WEST NEAR, SINAHS PLACE, 1038 STAND NO, KAGOSI NORTH, SINQOBILE, KAGISO, South Africa','geo':{'lat':-26.14381,'lng':27.78315}},
{'stp':468775,'address':'468775, EGL - WEST, WEST NEAR, DINEO\'S PLACE, 82 STAND NO, ORIENT HILLS, KRUGERSDORP, MAGALIESBURG, South Africa','geo':{'lat':-26.03359,'lng':27.60302}},
{'stp':468861,'address':'468861, EGL - WEST, WEST NEAR, DIPUOS PLACE, 426 STAND NO, BAGALE VILLAGE, TARLTON, MAGALIESBURG, South Africa','geo':{'lat':-26.07694,'lng':27.65545}},
{'stp':471214,'address':'471214, EGL - WEST, WEST NEAR, MAKWAKWA TAVERN, PLOT 06, BAGALE VILLAGE, TARLTON, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07897,'lng':27.65446}},
{'stp':480042,'address':'480042, EGL - WEST, WEST NEAR, MOSES INN, 17399 59 BASHEE STREET  ERF, #, #, MUNZIEVILLE, South Africa','geo':{'lat':-26.07392,'lng':27.75686}},
{'stp':547258,'address':'547258, EGL - WEST, WEST NEAR, SBUDAS INN, 1456 STAND NO, MOILOANYANE STREET, #, KAGISO, South Africa','geo':{'lat':-26.13770,'lng':27.78553}},
{'stp':550140,'address':'550140, EGL - WEST, WEST NEAR, MOSES HOUSE, 12509 JOHANNES MOGOJOA STREET, #, #, KAGISO, South Africa','geo':{'lat':-26.13970,'lng':27.79820}},
{'stp':550322,'address':'550322, EGL - WEST, WEST NEAR, CUMACK TAVERN, STAND 350 MACQOKI STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07470,'lng':27.75400}},
{'stp':550468,'address':'550468, EGL - WEST, WEST NEAR, LITTLE ROSE TAVERN, STAND NO 22, HONEYDEW, ROODEPOORT, MULDERSDRIFT, South Africa','geo':{'lat':-26.07777,'lng':27.92858}},
{'stp':550498,'address':'550498, EGL - WEST, WEST NEAR, STEWIE\'S PLACE, 600 STAND, KALATI STREET, KAGISO 1, KAGISO, South Africa','geo':{'lat':-26.14460,'lng':27.79830}},
{'stp':550506,'address':'550506, EGL - WEST, WEST NEAR, STAR TAVERN, 56 4 STAND 56/4 KRUGERSDORP NORTH, 19 A 4TH STREET, KRUGERSDORP NORTH, KRUGERSDORP NORTH, South Africa','geo':{'lat':-26.09323,'lng':27.76889}},
{'stp':550603,'address':'550603, EGL - WEST, WEST NEAR, NOZIPHOS INN, 1452 NGIDI STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07380,'lng':27.75350}},
{'stp':551288,'address':'551288, EGL - WEST, WEST NEAR, NOBELA LIQUOR STORE, 805A DALIYEBO STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08000,'lng':27.75670}},
{'stp':551412,'address':'551412, EGL - WEST, WEST NEAR, ZWANES PLACE, STAND 13950, EXT 10, KAGISO, KAGISO, South Africa','geo':{'lat':-26.14270,'lng':27.78880}},
{'stp':551781,'address':'551781, EGL - WEST, WEST NEAR, VINNYS BOTTLE STORE, 29 STAND NO, ERF 1608, OCKERSE STREET, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10296,'lng':27.77359}},
{'stp':551870,'address':'551870, EGL - WEST, WEST NEAR, ACE CAFE BAKERY AND RESTAURANT, 47 MCLEANSTREET, CHAMDOR, KRUGERSDORP, KAGISO, South Africa','geo':{'lat':-26.14480,'lng':27.80740}},
{'stp':552199,'address':'552199, EGL - WEST, WEST NEAR, TESAMEKOMS LIQUOR STORE, PORTION 71 OF FARM VAALBANK, MAIN RD MAGALIESBURG TO KOSTER, MAGALIESBURG, MAGALIESBURG, South Africa','geo':{'lat':-25.99990,'lng':27.49210}},
{'stp':552308,'address':'552308, EGL - WEST, WEST NEAR, MAJESTIC HOTEL, COMMISSIONER STREET, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10170,'lng':27.77110}},
{'stp':552345,'address':'552345, EGL - WEST, WEST NEAR, TINNYS PLACE, 298 RAMOKOTJO STREET, EXTENTION 1, #, KAGISO, South Africa','geo':{'lat':-26.14040,'lng':27.79650}},
{'stp':552635,'address':'552635, EGL - WEST, WEST NEAR, BIZOS INN TAVERN, ERF 182 MAGOKO STREET, KAGISO, #, KAGISO, South Africa','geo':{'lat':-26.14237,'lng':27.79629}},
{'stp':552703,'address':'552703, EGL - WEST, WEST NEAR, CHOC AND HONEY PUB AND DINER, PORTION 40 STAND NO 01, FARM LUIPAARDSVLEI 246, MARKET STREET KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10640,'lng':27.77180}},
{'stp':552800,'address':'552800, EGL - WEST, WEST NEAR, REFLECTIONS RESTAURANT, NO 12 RISSIK STREET, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10450,'lng':27.77400}},
{'stp':552840,'address':'552840, EGL - WEST, WEST NEAR, MABUTLES TAVERN, ERF 887, MUNSIEVILLE, 1739, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08040,'lng':27.75660}},
{'stp':552951,'address':'552951, EGL - WEST, WEST NEAR, PALIS PLACE, 149 BUTI STREET, #, #, KAGISO, South Africa','geo':{'lat':-26.14180,'lng':27.79690}},
{'stp':553457,'address':'553457, EGL - WEST, WEST NEAR, WEST VILLAGE BOTTLE STORE, STANDS 47 & 48, TOM MULLER STREET, WEST VILLAGE, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.11420,'lng':27.75400}},
{'stp':553514,'address':'553514, EGL - WEST, WEST NEAR, SIMUNYE TAVERN, NO 163 COMMISIONER STREET, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09840,'lng':27.76060}},
{'stp':553519,'address':'553519, EGL - WEST, WEST NEAR, P N TAVERN, 490 KAOKETSA STREET, KAGISO X1, KRUGERSDORP, KAGISO, South Africa','geo':{'lat':-26.14290,'lng':27.80100}},
{'stp':560339,'address':'560339, EGL - WEST, WEST NEAR, BONYANAS PLACE, ERF NO 407, 10  STEVEN MOTINGOA STREET, SINQOBILE TOWENSHIP, KAGISO, South Africa','geo':{'lat':-26.13737,'lng':27.78833}},
{'stp':560526,'address':'560526, EGL - WEST, WEST NEAR, TITOS INN, 362 MEJOANE STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07520,'lng':27.75350}},
{'stp':560567,'address':'560567, EGL - WEST, WEST NEAR, PARADISE TOO, 69 ELOFF ST, MOGALE CITY, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09790,'lng':27.77020}},
{'stp':560575,'address':'560575, EGL - WEST, WEST NEAR, KWA SJIM, 860 DALINYEBO STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08090,'lng':27.75740}},
{'stp':560585,'address':'560585, EGL - WEST, WEST NEAR, LULUS PLACE, STAND 271, BOLTONIA EXT 2, KRUGERSDORP WEST, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10600,'lng':27.74250}},
{'stp':580571,'address':'580571, EGL - WEST, WEST NEAR, KOOS PLACE, STAND 12502, MAKGOJOA STREET, KAGISO EXT 3, KAGISO, South Africa','geo':{'lat':-26.13950,'lng':27.79780}},
{'stp':603033,'address':'603033, EGL - WEST, WEST NEAR, SHONGWENI LIQUOR STORE, SHONGWENI LIQUOR, CLORY ROAD NEXT TO CHICKEN FARM, #, MULDERSDRIFT, South Africa','geo':{'lat':-26.01400,'lng':27.90240}},
{'stp':605130,'address':'605130, EGL - WEST, WEST NEAR, CEDAR PALACE RESTAURANT, 236 RIETFONTEIN, #, #, MULDERSDRIFT, South Africa','geo':{'lat':-27.92631,'lng':27.92631}},
{'stp':605162,'address':'605162, EGL - WEST, WEST NEAR, TSUNAMI TAVERN, ERF 1140  GOPANE ST, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07890,'lng':27.75300}},
{'stp':605539,'address':'605539, EGL - WEST, WEST NEAR, MATHOMUS SHEBEEN, 191 PELENDABA RD, ZANDSPRUIT, JOHANNESBURG, RANDBURG, South Africa','geo':{'lat':-26.01760,'lng':27.94150}},
{'stp':606007,'address':'606007, EGL - WEST, WEST NEAR, SISTERS TAVERN, 92 SIVEWRIGHT ST, LUIPAARDSVLEI, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10710,'lng':27.77620}},
{'stp':607418,'address':'607418, EGL - WEST, WEST NEAR, OBIS PLACE, STAND 533, LEEUW STREET, KAGISO, KAGISO, South Africa','geo':{'lat':-26.14420,'lng':27.80040}},
{'stp':612838,'address':'612838, EGL - WEST, WEST NEAR, KOJAK`S (NTO), 140 RUELE STREET, MUNSIENVILLE, Krugersdrop, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08578,'lng':27.77732}},
{'stp':625770,'address':'625770, EGL - WEST, WEST NEAR, TEN YEARS, 1220 BASHEE ST, MUNSIEVILLE, MOGALE CITY, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07200,'lng':27.75410}},
{'stp':626305,'address':'626305, EGL - WEST, WEST NEAR, THANDOS PLACE, 1821 MOGOAI STREET, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07010,'lng':27.75760}},
{'stp':626516,'address':'626516, EGL - WEST, WEST NEAR, BATSIS TAVERN, 1955 JABULANI DRIVE, MUNSIEVILLE EXT2, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07630,'lng':27.74820}},
{'stp':629375,'address':'629375, EGL - WEST, WEST NEAR, MALEKUTUS DISCOUNT CENTRE, 6987 MATROOS ST, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07870,'lng':27.75840}},
{'stp':629650,'address':'629650, EGL - WEST, WEST NEAR, KHOLOS PLACE, 1436 SKHOSANA STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08053,'lng':27.75653}},
{'stp':629731,'address':'629731, EGL - WEST, WEST NEAR, ANNAHS SHEBEEN, 120 SIVEWRIGHT ST, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10700,'lng':27.77400}},
{'stp':629886,'address':'629886, EGL - WEST, WEST NEAR, JD TAVERN, 1940 JABULANI DRIVE, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07450,'lng':27.74810}},
{'stp':631074,'address':'631074, EGL - WEST, WEST NEAR, BUENA VISTA, 3457 NICHOLAS STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.06530,'lng':27.75080}},
{'stp':631179,'address':'631179, EGL - WEST, WEST NEAR, MIAMI GARDENS, 1461 MAHUMA STREET, MUNSIEVIILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07430,'lng':27.75560}},
{'stp':631678,'address':'631678, EGL - WEST, WEST NEAR, MONATE BY D, 3745 MAYIBUYE, EXTENTION 4, MUNSIEVILLE, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.06792,'lng':27.75192}},
{'stp':633213,'address':'633213, EGL - WEST, WEST NEAR, VHAVENDA TAVERN, 3301 HALIM STREET, EXTENSION 4, MUNSIEVILLE, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.06310,'lng':27.74980}},
{'stp':634230,'address':'634230, EGL - WEST, WEST NEAR, STAN\'S PLACE, 583 DALINYEBO AVENUE, MUNSIEVILLE, MOGALE CITY, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07770,'lng':27.75780}},
{'stp':634903,'address':'634903, EGL - WEST, WEST NEAR, GET DOWN, 504 MOTSHEPE STREET, KAGISO 1, #, KAGISO, South Africa','geo':{'lat':-26.14360,'lng':27.80080}},
{'stp':635145,'address':'635145, EGL - WEST, WEST NEAR, BINO\'S TAVERN, 497 MAROPI STREET, GA-MOHALE, MOGALE CITY, MAGALIESBURG, MAGALIESBURG, South Africa','geo':{'lat':-25.99380,'lng':27.53580}},
{'stp':636821,'address':'636821, EGL - WEST, WEST NEAR, LESEGO INN, PORTION 385 JQ, MAIN RD MAGALIESBURG TO KOSTER, MAGALIESBURG, MAGALIESBURG, South Africa','geo':{'lat':-25.99530,'lng':27.43800}},
{'stp':637025,'address':'637025, EGL - WEST, WEST NEAR, DESMOND\'S SHEBEEN, 258 GA MOGALE, MAGALIESBURG, MOGALE CITY, MAGALIESBURG, South Africa','geo':{'lat':-25.99490,'lng':27.53110}},
{'stp':637569,'address':'637569, EGL - WEST, WEST NEAR, KGWESA NGWANA, 3590 MOTHWA EXT 4, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.06490,'lng':27.75200}},
{'stp':639701,'address':'639701, EGL - WEST, WEST NEAR, OOMPIES INN, 2118 PHOLA STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07500,'lng':27.75080}},
{'stp':639787,'address':'639787, EGL - WEST, WEST NEAR, JACKPOT TAVERN, 48 ELOFF STREET, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.09850,'lng':27.77250}},
{'stp':641326,'address':'641326, EGL - WEST, WEST NEAR, EAT WELL RESTAURANT, 30 FOUNTAIN STREET, KRUGERSDORP, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10130,'lng':27.76880}},
{'stp':642031,'address':'642031, EGL - WEST, WEST NEAR, BRA COMA\'S PLACE, 642 KHAYA STREET, GAMOHALE MAGALIESBURG, MOGALE CITY, MAGALIESBURG, South Africa','geo':{'lat':-25.99550,'lng':27.53630}},
{'stp':642072,'address':'642072, EGL - WEST, WEST NEAR, LESS TSHOP, 2482 JABULANE STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08060,'lng':27.74960}},
{'stp':642096,'address':'642096, EGL - WEST, WEST NEAR, STAR LAW, 771 CORONATION SQUARE, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.08010,'lng':27.75810}},
{'stp':642134,'address':'642134, EGL - WEST, WEST NEAR, PULES PLACE, 1219 GXOYIYA STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07200,'lng':27.75380}},
{'stp':642277,'address':'642277, EGL - WEST, WEST NEAR, EMAZULWINI TAVERN, 3350 MAJENGE STREET, MUNSIEVILLE EXTENSION 4, MAYIBUYE, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.06530,'lng':27.74850}},
{'stp':642596,'address':'642596, EGL - WEST, WEST NEAR, ABIES PLACE, STAND NO 3666, EXTENTION 4, MUNZIEVILLE, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.06760,'lng':27.75210}},
{'stp':642687,'address':'642687, EGL - WEST, WEST NEAR, S AND B PUB AND GRILL, SHOP NO 12  ADCOCK BLDG, ELOFF STREET, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.15350,'lng':27.79497}},
{'stp':647535,'address':'647535, EGL - WEST, WEST NEAR, KWA MOSS, 681 MATROSS STREET 681, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07870,'lng':27.75840}},
{'stp':647565,'address':'647565, EGL - WEST, WEST NEAR, KOKI\'S INN, 30 MORARE DRIVE 30, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07260,'lng':27.75580}},
{'stp':648197,'address':'648197, EGL - WEST, WEST NEAR, EMBIZENI TAVERN, ABUJA STREET 7326, EXTENSION 6, COSMO CITY, COSMOS CITY, South Africa','geo':{'lat':-26.02230,'lng':27.93050}},
{'stp':652433,'address':'652433, EGL - WEST, WEST NEAR, MONDES INN, 607 MABE DRIVE, MUNSIEVILLE, #, MUNZIEVILLE, South Africa','geo':{'lat':-26.07850,'lng':27.75710}},
{'stp':652455,'address':'652455, EGL - WEST, WEST NEAR, VUKUZENZELE TAVERN, 1224 MONALA STREET, MUNSIEVILLE, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07130,'lng':27.75440}},
{'stp':658164,'address':'658164, EGL - WEST, WEST NEAR, BASSIE (NTO), 1488 MONGOPE STREET, MUNSIEVILLE, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07990,'lng':27.75700}},
{'stp':658247,'address':'658247, EGL - WEST, WEST NEAR, MAKGHETHA T/S (NTO), B374, MUNSIENVILLE, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07115,'lng':27.91740}},
{'stp':667438,'address':'667438, EGL - WEST, WEST NEAR, CLUB 54 (NTO), 54 Human street, Krugersdorp, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10282,'lng':27.77133}},
{'stp':669397,'address':'669397, EGL - WEST, WEST NEAR, OBSESSIONS (NTO), STAND NO 1979, 68 HUMAN STREET, KRUGERSDORP, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10278,'lng':27.77029}},
{'stp':684378,'address':'684378, EGL - WEST, WEST NEAR, DIRT BRONCO (NTO), 88 Windsor road, Krugersdorp, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.11740,'lng':27.76626}},
{'stp':688585,'address':'688585, EGL - WEST, WEST NEAR, STATION LIQUOR STORE, 19 MARKET STREET, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.10830,'lng':27.77110}},
{'stp':704070,'address':'704070, EGL - WEST, WEST NEAR, LUKAS PLACE, 445 STAND NO, SEDINGWE STREET, GA MOGALE LOCATION, MAGALIESBURG, South Africa','geo':{'lat':-25.99380,'lng':27.53580}},
{'stp':709422,'address':'709422, EGL - WEST, WEST NEAR, BIG TIME PUB AND RESTAURANT, 2294 NKULULEKO STREET, #, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.07830,'lng':27.74860}},
{'stp':735648,'address':'735648, EGL - WEST, WEST NEAR, LAJAVA GUEST LODGE, ERF 652,67 BURGER STREET, #, KRUGERSDORP, ROODEPOORT, South Africa','geo':{'lat':-26.10540,'lng':27.77610}},
{'stp':747989,'address':'747989, EGL - WEST, WEST NEAR, BAFURUTSHE TAVERN, 633 STAND NO, PHALADI STREET, KAGISO, KAGISO, South Africa','geo':{'lat':-26.14450,'lng':27.79750}},
{'stp':748160,'address':'748160, EGL - WEST, WEST NEAR, NTIMANDE TAVERN, STAND NO 1006, TSOTETSI STREET, KAGISO NORTH, KAGISO, South Africa','geo':{'lat':-26.14420,'lng':27.78270}},
{'stp':773832,'address':'773832, EGL - WEST, WEST NEAR, NEXT DOOR PUB AND RESTAURANT, 2381 STAND NO, SIBONGILE STREET, #, MUNZIEVILLE, South Africa','geo':{'lat':-26.07792,'lng':27.74940}},
{'stp':779883,'address':'779883, EGL - WEST, WEST NEAR, MAGOGO INN, 2155 KATLEGO STREET, #, #, MUNZIEVILLE, South Africa','geo':{'lat':-26.07500,'lng':27.75144}},
{'stp':785394,'address':'785394, EGL - WEST, WEST NEAR, CHESA NYAMA KRUGERSDORP (NTO), CORNER HUMAN STREET, MONUMENT ROAD, #, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.10332,'lng':27.77168}},
{'stp':787241,'address':'787241, EGL - WEST, WEST NEAR, THE BIG O, 215 STAND NO, GAMOGALE TOWNSHIP, MAGALIEBURG, MAGALIESBURG, South Africa','geo':{'lat':-25.99476,'lng':27.53132}},
{'stp':794734,'address':'794734, EGL - WEST, WEST NEAR, MAGOLI TAVERN, 363 STAND NO, ELIJAH STREET, SINQOBILE, KRUGERSDORP WEST, South Africa','geo':{'lat':-26.14232,'lng':27.78596}},
{'stp':796938,'address':'796938, EGL - WEST, WEST NEAR, LIQUOR CITY MUNSIEVILLE, MONALE STREET, ERF 1596, #, MUNZIEVILLE, South Africa','geo':{'lat':-26.07228,'lng':27.76053}},
{'stp':473827,'address':'473827, EGL - WEST, WEST HYBRID, OLLIES LIQUOR STORE, 955 MAIN AVENUE, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.38790,'lng':27.27471}},
{'stp':551052,'address':'551052, EGL - WEST, WEST HYBRID, LIQUOR LAPA, VAN ZYL SMITSTRAAT 58, OBERHOLZER, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35460,'lng':27.39900}},
{'stp':626538,'address':'626538, EGL - WEST, WEST HYBRID, WESTONARIA BEER DISTRIBUTORS, CNR PULLINGER AND FERRUS STREET, INDUSTRIAL AREA, #, WESTONARIA, South Africa','geo':{'lat':-26.31080,'lng':27.67330}},
{'stp':309595,'address':'309595, EGL - WEST, WEST FAR, EL TORO LOCO LIQUORS, 125 EDWARD AVENUE, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31528,'lng':27.65862}},
{'stp':391440,'address':'391440, EGL - WEST, WEST FAR, BRADFORD PALM (PTY) LTD, 356 PORTION 28 LEEUWPOORT, #, #, CARLTONVILLE, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':391616,'address':'391616, EGL - WEST, WEST FAR, SAXONWORLD, PLOT 11 MAIN ROAD TEN ACRES, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.23457,'lng':27.72042}},
{'stp':473828,'address':'473828, EGL - WEST, WEST FAR, OLLIES PUB AND GRILL, 266 MAIN AVENUE, WELVERDIEND, #, CARLTONVILLE, South Africa','geo':{'lat':-26.38789,'lng':27.27465}},
{'stp':477137,'address':'477137, EGL - WEST, WEST FAR, PICK N PAY LIQUOR STORE - GOLD REEF, 7613 STATION STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35300,'lng':27.36096}},
{'stp':550345,'address':'550345, EGL - WEST, WEST FAR, THE WORKSHOP PUB AND  GRILL, 43 NO 43 BOTHA STREET, MERAFONG ,OBERHOLZER, #, CARLTONVILLE, South Africa','geo':{'lat':-26.34660,'lng':27.37510}},
{'stp':550354,'address':'550354, EGL - WEST, WEST FAR, QUEENS KNIGHT, 1 DOORNFONTEIN GOLF CLUB, CARLETONVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.37676,'lng':27.39514}},
{'stp':550358,'address':'550358, EGL - WEST, WEST FAR, WESTONARIA LIQUOR STORE, 21 ALLENSTR, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.31970,'lng':27.65120}},
{'stp':550697,'address':'550697, EGL - WEST, WEST FAR, THE CATS, PORTION 11, FARM LEEUWPOORT 356, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.41380,'lng':27.51535}},
{'stp':550742,'address':'550742, EGL - WEST, WEST FAR, CHEERS DRANKWINKEL, C/O MARK & LOSBERGSTRAAT, FOCHVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49210,'lng':27.49520}},
{'stp':550832,'address':'550832, EGL - WEST, WEST FAR, OVERLAND LIQUOR STORE - WESTONARIA, 77 77 EDWARDS STREET, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.31710,'lng':27.64940}},
{'stp':550895,'address':'550895, EGL - WEST, WEST FAR, THATENG LIQUOR STORE, STAND NO 5, KOKOSI, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.49470,'lng':27.46750}},
{'stp':551053,'address':'551053, EGL - WEST, WEST FAR, HYPER CELLARS OBERHOLZER, H/V ADORP & VAN ZYL SMITHSTR, OBERHOLZER, 2505, CARLTONVILLE, South Africa','geo':{'lat':-26.34580,'lng':27.38970}},
{'stp':551144,'address':'551144, EGL - WEST, WEST FAR, GOOD LIFE RESTAURANT, ERF 283, NO 4 STATION STREET, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.34480,'lng':27.38890}},
{'stp':551194,'address':'551194, EGL - WEST, WEST FAR, CARLETONVILLE HYPER LIQUOR STORE, 2976 C/O ONYX & PAUL KRUGERSTR, CARLTONVILLE EXT 8, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.35810,'lng':27.38310}},
{'stp':551410,'address':'551410, EGL - WEST, WEST FAR, FOCHVILLE ROLBALKLUB, ERF 320, 8 STE STRAAT, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.48707,'lng':27.49124}},
{'stp':551470,'address':'551470, EGL - WEST, WEST FAR, KLOOF RECREATION CLUB, PORTION 21 OF FARM RIETFONTEIN, NO 349, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.38610,'lng':27.61760}},
{'stp':551621,'address':'551621, EGL - WEST, WEST FAR, BILTONG TAVERN WESTONARIA, ERF 3221 , SHOP 2 , GC & Bldg, NEPTUNE STREET, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.33050,'lng':27.65430}},
{'stp':551653,'address':'551653, EGL - WEST, WEST FAR, FIDLERS ELBOW PUB AND BISTRO, NO 82 VAN ZYL SMITH STREET, MADPOWER HOUSE, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.35090,'lng':27.38990}},
{'stp':551702,'address':'551702, EGL - WEST, WEST FAR, CASABLANCA RESTAURANT, GOLDSTRAAT 20, CARLETONVILLE, 2500, CARLTONVILLE, South Africa','geo':{'lat':-26.35770,'lng':27.39900}},
{'stp':551823,'address':'551823, EGL - WEST, WEST FAR, CORNER POCKET LOUNGE AND POOL BAR, ERVEN 406 & 407, C/O VAN ZYL SMIT & ORANJE STR, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.34820,'lng':27.38950}},
{'stp':551832,'address':'551832, EGL - WEST, WEST FAR, WIMPY RESTAURANT CARLETONVILLE, PROTEA BUILDING, NO 6 PALLADIOM STREET, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.35840,'lng':27.39920}},
{'stp':551863,'address':'551863, EGL - WEST, WEST FAR, PRESIDENT LIQUOR (FOCHVILLE), SHOP NO 1 PRESIDENT SQUARE, CNR CHURCH STR & LOSBERG AVENUE, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.49190,'lng':27.49474}},
{'stp':552051,'address':'552051, EGL - WEST, WEST FAR, BLYVOORUITZICHT GOLF CLUB, THE FARM BLYVOORUITZICHT, OBERHOLZER, 2502, CARLTONVILLE, South Africa','geo':{'lat':-26.40217,'lng':27.38483}},
{'stp':552057,'address':'552057, EGL - WEST, WEST FAR, LIBANON RECREATION CLUB, LIBANON MINE PROPERTY, FARM LIBANON NO 283, RIGISTRATION DIVISION I Q LIBA, WESTONARIA, South Africa','geo':{'lat':-26.35172,'lng':27.61164}},
{'stp':552145,'address':'552145, EGL - WEST, WEST FAR, GOLDFIELDS WEST GOLF CLUB, FARM DRIEFONTEIN NO 118, OBERHOLZER, 2502, CARLTONVILLE, South Africa','geo':{'lat':-26.39120,'lng':27.47300}},
{'stp':552255,'address':'552255, EGL - WEST, WEST FAR, WDL RECREATION C ANGLOGOLD ASHANTI, COR PROTEA & CRESCENT DRIVE, VILLAGE OF WESTERN DEEP LEVELS, FARM BLYVOORUITZICHT, CARLTONVILLE, South Africa','geo':{'lat':-26.42521,'lng':27.41151}},
{'stp':552336,'address':'552336, EGL - WEST, WEST FAR, WESGOUD CC TA WELVERDIEND LS, 123 10 TH AVENUE, WELVERDIEND, #, CARLTONVILLE, South Africa','geo':{'lat':-26.37690,'lng':27.28530}},
{'stp':552698,'address':'552698, EGL - WEST, WEST FAR, CAPELO BOTTLE STORE, SHOP 6, EAST DRIEFONTEIN SHOPPING CENTRE, EAST CRESCENT, EAST VILLAGE, CARLTONVILLE, South Africa','geo':{'lat':-26.38826,'lng':27.51484}},
{'stp':552747,'address':'552747, EGL - WEST, WEST FAR, KASTEEL RESTAURANT AND KROEG, PALM COURT, VAN ZYL SMITSTREET, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.34530,'lng':27.39040}},
{'stp':552819,'address':'552819, EGL - WEST, WEST FAR, SHOOTERS PUB AND  RESTUARANT, ERF 328 SHOP 10, FEDEK CENTRE, 71 BRIGGS STREET, WESTONARIA, South Africa','geo':{'lat':-26.31880,'lng':27.65030}},
{'stp':552830,'address':'552830, EGL - WEST, WEST FAR, LOSBERG KELDERS, LOOPSPRUIT AVE, FOCHVILLE, 2515, CARLTONVILLE, South Africa','geo':{'lat':-26.49390,'lng':27.49580}},
{'stp':553023,'address':'553023, EGL - WEST, WEST FAR, YELLOWSTONE SPUR STEAK C VILLE, SHOP NO 1, TERENURE CENTRE, KAOLIN STREET, CARLTONVILLE, South Africa','geo':{'lat':-26.35946,'lng':27.39740}},
{'stp':553041,'address':'553041, EGL - WEST, WEST FAR, CARLETONVILLE BOWLING CLUB, STAND 603, C/O LIGNITE & IONITE STREETS, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.35970,'lng':27.40760}},
{'stp':553234,'address':'553234, EGL - WEST, WEST FAR, MIDDELVLEI REST TA BUNDU INN, PLAAS 255, MIDDELVLEI, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.20386,'lng':27.68560}},
{'stp':553236,'address':'553236, EGL - WEST, WEST FAR, BLYVOOR LIQUOR STORE, PORTION 47 OF PORTION 1, FARM BLYVOORUITZICHT 116, REGISTRATION DIVISION IQ, CARLTONVILLE, South Africa','geo':{'lat':-26.42190,'lng':27.37370}},
{'stp':553250,'address':'553250, EGL - WEST, WEST FAR, KRAALKOP HOTEL, CONSOLIDATED PORT 40 OF THE, FARM KRAALKOP NO 147, POTCHEFSTROOM, CARLTONVILLE, South Africa','geo':{'lat':-26.43588,'lng':27.50770}},
{'stp':553325,'address':'553325, EGL - WEST, WEST FAR, FALLOW DEER INN, HOLDING NO 9, WAGERSKOP, GLENHARVIE, WESTONARIA, South Africa','geo':{'lat':-26.38405,'lng':27.60309}},
{'stp':553349,'address':'553349, EGL - WEST, WEST FAR, FRANCOIS RESTAURANT AND GUEST LODGE, ERF 1199 CARLTONVILLE EXT 1, 105 KAOLIN STREET, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.36050,'lng':27.39990}},
{'stp':553683,'address':'553683, EGL - WEST, WEST FAR, VAN TEES TAVERN, PLOT 251, DEVILLIERS ROAD, HILLSIDE, RANDFONTEIN, South Africa','geo':{'lat':-26.21590,'lng':27.63650}},
{'stp':553763,'address':'553763, EGL - WEST, WEST FAR, SPRINGBOK LIQUOR STORE, STAND 698, SHOP NO 1 BRIDGES AVENUE, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.32220,'lng':27.64060}},
{'stp':607339,'address':'607339, EGL - WEST, WEST FAR, HENICO LIQUOR STORE, 127 KERK STREET, FOCHVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49280,'lng':27.48580}},
{'stp':626950,'address':'626950, EGL - WEST, WEST FAR, LIQUOR COM, SHOP NO 7, SHOPRITE CENTRE, CNR PRESIDENT & STEYN STREET, WESTONARIA, South Africa','geo':{'lat':-26.32210,'lng':27.65220}},
{'stp':646398,'address':'646398, EGL - WEST, WEST FAR, BUFFALO RANGE LIQUORS, HOLDING 2 PELZVALE, AGRICULTURE HOLDINGS, #, RANDFONTEIN, South Africa','geo':{'lat':-26.25500,'lng':27.67090}},
{'stp':647497,'address':'647497, EGL - WEST, WEST FAR, LIQOUR THINGS, 39 PRESIDENT STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49370,'lng':27.49210}},
{'stp':650483,'address':'650483, EGL - WEST, WEST FAR, LIDO, SECTION 5, JACHTFONTEIN, #, #, WESTONARIA, South Africa','geo':{'lat':-26.41810,'lng':27.72144}},
{'stp':650904,'address':'650904, EGL - WEST, WEST FAR, LIQUOR CITY WESTONARIA, 16 CNR MACGREGOR FORBES & BRIGGS, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31860,'lng':27.65450}},
{'stp':651342,'address':'651342, EGL - WEST, WEST FAR, ULTRA EXPRESS FOCHVILLE, CO JAKARANDA & LOSBERG LAAN, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.47130,'lng':27.49330}},
{'stp':668048,'address':'668048, EGL - WEST, WEST FAR, COUNTRY PUB WESTONARIA, PORTION 44 FARM ELANDSFONTEIN IQ364, #, #, WESTONARIA, South Africa','geo':{'lat':-26.39790,'lng':27.64320}},
{'stp':675406,'address':'675406, EGL - WEST, WEST FAR, TIMES CAFE SPORTS BAR AND POOL HALL, CNR RADIUM & KALOIN STREET, SHOP 6, BUYS SHOPPING CENTRE, CARLTONVILLE, South Africa','geo':{'lat':-26.35860,'lng':27.39520}},
{'stp':688623,'address':'688623, EGL - WEST, WEST FAR, LIQUOR CITY OBERHOLZER, 293 & 294 CNR VAN ZYL SMITH & STATI, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35240,'lng':27.38490}},
{'stp':710667,'address':'710667, EGL - WEST, WEST FAR, MANDALAY, 65 EDWARDS AVENUE, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31882,'lng':27.65030}},
{'stp':777923,'address':'777923, EGL - WEST, WEST FAR, MARCELO\'S CAFE, 1276 SHOP 10, ELLERINES BUILDING, 17 EMERALD STREET, CARLTONVILLE, South Africa','geo':{'lat':-26.35614,'lng':27.39837}},
{'stp':391365,'address':'391365, EGL - WEST, WEST FAR, CLUB WITBANK, ERF 875 KHOMOEHLABA STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28354,'lng':27.70621}},
{'stp':392562,'address':'392562, EGL - WEST, WEST FAR, WHITE HOUSE LIQUOR, 167 UKK, TAMBO SECTION, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.28539,'lng':27.69295}},
{'stp':392739,'address':'392739, EGL - WEST, WEST FAR, MKL LIQUOR STORE, 2555 PANYA PANYA STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28727,'lng':27.70390}},
{'stp':392881,'address':'392881, EGL - WEST, WEST FAR, MASOJA MARASI, Y957 SILVERCITY, #, #, WESTONARIA, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':392882,'address':'392882, EGL - WEST, WEST FAR, NKANE\'S CIRCLE PUB AND CAR WASH, 1527 NELSON MANDELA STREET, BORWA PHASE 1, #, WESTONARIA, South Africa','geo':{'lat':-26.33811,'lng':27.65915}},
{'stp':469976,'address':'469976, EGL - WEST, WEST FAR, ELIQUOR BOTTLE STORE, 1190 SHOP 1 &2, CODRINGTON STREET, #, WESTONARIA, South Africa','geo':{'lat':-26.31704,'lng':27.64941}},
{'stp':474353,'address':'474353, EGL - WEST, WEST FAR, C-POZ, 1574 KETHLAETSE STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.27897,'lng':27.70370}},
{'stp':477999,'address':'477999, EGL - WEST, WEST FAR, MARULA PUB AND GRILL, 76 BRIGGS STREET, SHOP NO 3, #, WESTONARIA, South Africa','geo':{'lat':-26.31940,'lng':27.64429}},
{'stp':550638,'address':'550638, EGL - WEST, WEST FAR, MAGGIE SPORTS BAR, 2491 MOSIDI STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28730,'lng':27.70930}},
{'stp':550823,'address':'550823, EGL - WEST, WEST FAR, B AND B DRANKWINKEL, PLOT 250, TWEEDESTRAAT, ZUURBEKOM, WESTONARIA, South Africa','geo':{'lat':-26.29904,'lng':27.77397}},
{'stp':550866,'address':'550866, EGL - WEST, WEST FAR, PALM TREE HOTEL, 1 NO 1 CODRINGTON STREET, WESTONRIA, #, WESTONARIA, South Africa','geo':{'lat':-26.31470,'lng':27.65170}},
{'stp':551090,'address':'551090, EGL - WEST, WEST FAR, MINGLES RESTAURANT, 2 DOVETON STREET, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.31380,'lng':27.63880}},
{'stp':551095,'address':'551095, EGL - WEST, WEST FAR, DOORNKOP HOSTEL TAVERN LTD, PORTION 130 & 131, FARM DOORNKOP 239 REG DIV IQ, ROODEPOORT, WESTONARIA, South Africa','geo':{'lat':-26.21890,'lng':27.79550}},
{'stp':551283,'address':'551283, EGL - WEST, WEST FAR, BRA TIZA\'S TAVERN, 770 REAIKAGA STREET, SIMUNYE, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.31900,'lng':27.68140}},
{'stp':551328,'address':'551328, EGL - WEST, WEST FAR, MAINSHAFT HOTEL, C/O ALLEN & BOTHA STREETS, WESTONARIA, 1779, WESTONARIA, South Africa','geo':{'lat':-26.32130,'lng':27.65090}},
{'stp':551923,'address':'551923, EGL - WEST, WEST FAR, ITS A PLEASURE, STAND 2106 & 2107, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28080,'lng':27.70310}},
{'stp':551960,'address':'551960, EGL - WEST, WEST FAR, VIOLETS RESTAURANT, SITE NO 1646 & 1647, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.27940,'lng':27.70190}},
{'stp':553446,'address':'553446, EGL - WEST, WEST FAR, TIVOLI RESTAURANT WEST, ERF 879, BRIGGS STR, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.31900,'lng':27.65190}},
{'stp':561147,'address':'561147, EGL - WEST, WEST FAR, MOSES LIQUOR STORE, 2666 JACKSON NKUNA AVENUE, SIMUNYE EXT 1, #, WESTONARIA, South Africa','geo':{'lat':-26.31927,'lng':27.68953}},
{'stp':603323,'address':'603323, EGL - WEST, WEST FAR, LAGOS INN, 883 SELOPE THEMA STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28440,'lng':27.70710}},
{'stp':604556,'address':'604556, EGL - WEST, WEST FAR, MAS\'BONGILE\'S PLACE, 117 MASEKO STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28190,'lng':27.70490}},
{'stp':605046,'address':'605046, EGL - WEST, WEST FAR, MOKOME BOTTLE STORE, 376 INKULULEKO STREET, INKULULEKO STREET, SIMUNYE, WESTONARIA, South Africa','geo':{'lat':-26.32520,'lng':27.69330}},
{'stp':605462,'address':'605462, EGL - WEST, WEST FAR, FRANK\'S, 2383 EXT 1, SIMUNYE, #, WESTONARIA, South Africa','geo':{'lat':-26.31600,'lng':27.69270}},
{'stp':605636,'address':'605636, EGL - WEST, WEST FAR, NDUNA\'S INN, STAND 3550 KGOMOYAMAHLABA STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28470,'lng':27.70060}},
{'stp':607215,'address':'607215, EGL - WEST, WEST FAR, ROBBIE\'S PLACE, 1943 JACKSON NKUNA STREET, SIMUNYE EXT1, #, WESTONARIA, South Africa','geo':{'lat':-26.32445,'lng':27.68948}},
{'stp':607322,'address':'607322, EGL - WEST, WEST FAR, GENESIS INN, 4546 THUPISO STREET, SIMUNYE EXT 2, #, WESTONARIA, South Africa','geo':{'lat':-26.32940,'lng':27.69830}},
{'stp':607578,'address':'607578, EGL - WEST, WEST FAR, MODISAOTSELE LIQUOR STORE, ERF 1312, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.32033,'lng':27.67742}},
{'stp':626803,'address':'626803, EGL - WEST, WEST FAR, NESI\'S PLACE, 3118 LERATO STREET, SIMUNYE EXT 2, #, WESTONARIA, South Africa','geo':{'lat':-26.31735,'lng':27.69466}},
{'stp':627595,'address':'627595, EGL - WEST, WEST FAR, SONTY\'S INN, 636 GODLO STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28690,'lng':27.70720}},
{'stp':627596,'address':'627596, EGL - WEST, WEST FAR, MAMKHULU, 650 HULLET STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28635,'lng':27.70712}},
{'stp':627752,'address':'627752, EGL - WEST, WEST FAR, MAPUTO NIGHT CLUB, 387 XUMA STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28490,'lng':27.71611}},
{'stp':628893,'address':'628893, EGL - WEST, WEST FAR, KWA NDLOVU, J13 SIVERCITY, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28390,'lng':27.69060}},
{'stp':630862,'address':'630862, EGL - WEST, WEST FAR, MOKAU SHEBEEN, 503 BOITUMELO STREET, SIMUNYE EXT 5, #, WESTONARIA, South Africa','geo':{'lat':-26.32460,'lng':27.67720}},
{'stp':633062,'address':'633062, EGL - WEST, WEST FAR, PINK HOUSE, 27 FOWER STREET, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.32146,'lng':27.64140}},
{'stp':634222,'address':'634222, EGL - WEST, WEST FAR, FISH, 332 KOPANANG STREET, SIMUNYE, #, WESTONARIA, South Africa','geo':{'lat':-26.32266,'lng':27.67651}},
{'stp':634817,'address':'634817, EGL - WEST, WEST FAR, MAFLOZA\'S INN, 630 HALLET STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28634,'lng':27.70401}},
{'stp':635125,'address':'635125, EGL - WEST, WEST FAR, MOONLIGHT, 3990 TUMELO STREET, EXT 4, SIMUNYE, WESTONARIA, South Africa','geo':{'lat':-26.32721,'lng':27.69693}},
{'stp':636724,'address':'636724, EGL - WEST, WEST FAR, KATLEHO, 2868 EXT 2, SIMUNYE, #, WESTONARIA, South Africa','geo':{'lat':-26.31477,'lng':27.69726}},
{'stp':637078,'address':'637078, EGL - WEST, WEST FAR, PHINDI\'S SHEBEEN, IKK 27 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28690,'lng':27.68850}},
{'stp':637079,'address':'637079, EGL - WEST, WEST FAR, GUZI\'S SHEBEEN, DKK 43 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28590,'lng':27.69110}},
{'stp':637084,'address':'637084, EGL - WEST, WEST FAR, MOLABJA, SKK 250 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28690,'lng':27.69110}},
{'stp':637095,'address':'637095, EGL - WEST, WEST FAR, KWANOXOLO, KK 388 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28290,'lng':27.69070}},
{'stp':637116,'address':'637116, EGL - WEST, WEST FAR, KWA NTULI, KKR TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28670,'lng':27.69490}},
{'stp':637247,'address':'637247, EGL - WEST, WEST FAR, JUSTINA\'S SHEBEEN, JKK 1 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28880,'lng':27.68820}},
{'stp':637260,'address':'637260, EGL - WEST, WEST FAR, JAKALASE, VKK 114 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.29071,'lng':27.68547}},
{'stp':637344,'address':'637344, EGL - WEST, WEST FAR, KWA THOZAMA, KK 321 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28670,'lng':27.69490}},
{'stp':637979,'address':'637979, EGL - WEST, WEST FAR, NONTSAPHO, KK 424 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28740,'lng':27.69260}},
{'stp':638198,'address':'638198, EGL - WEST, WEST FAR, MBUKU\'S SHEBEEN, KKR 77 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28620,'lng':27.69660}},
{'stp':638199,'address':'638199, EGL - WEST, WEST FAR, BOPHELO KE MATLA, 1329 EKUTHULENI STREET, SIMUNYE, #, WESTONARIA, South Africa','geo':{'lat':-26.32370,'lng':27.68490}},
{'stp':638369,'address':'638369, EGL - WEST, WEST FAR, OUMA\'S INN, 3497 MAJA STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28610,'lng':27.71680}},
{'stp':638425,'address':'638425, EGL - WEST, WEST FAR, SLENDER\'S INN, 996 TEBALONG STREET, SIMUNYE, #, WESTONARIA, South Africa','geo':{'lat':-26.31780,'lng':27.68360}},
{'stp':638815,'address':'638815, EGL - WEST, WEST FAR, AGNES SHEBEEN, 4430 EXT 4, SIMUNYE, #, WESTONARIA, South Africa','geo':{'lat':-26.32839,'lng':27.69836}},
{'stp':639383,'address':'639383, EGL - WEST, WEST FAR, ROBBIE\'S PLACE TRADING (PTY)ltd, JJ 153 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28710,'lng':27.69640}},
{'stp':639467,'address':'639467, EGL - WEST, WEST FAR, OTOS SHEBEEN, 2083 SENZANGAKHONA STREET, EXT 1, SIMUNYE, WESTONARIA, South Africa','geo':{'lat':-26.31319,'lng':27.69244}},
{'stp':639852,'address':'639852, EGL - WEST, WEST FAR, ZAKHELE, VKK 216 KHOZA STREET, TAMBO SECTION, BEKKERSDAL, WESTONARIA, South Africa','geo':{'lat':-26.28040,'lng':27.70420}},
{'stp':640029,'address':'640029, EGL - WEST, WEST FAR, FK TAVERN, 3648 KGOHELELA DRIVE, SIMUNYE EXT 3, #, WESTONARIA, South Africa','geo':{'lat':-26.32940,'lng':27.69090}},
{'stp':640276,'address':'640276, EGL - WEST, WEST FAR, DLOMO\'S PLACE, AA 19 Mandela Section, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28120,'lng':27.69970}},
{'stp':640305,'address':'640305, EGL - WEST, WEST FAR, MARI\'S INN, 17 PANYAPANYA STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28172,'lng':27.70374}},
{'stp':640507,'address':'640507, EGL - WEST, WEST FAR, BUZY BEE, 3538 Kgomo Ea Hlabo Street, Bekkersdal, #, WESTONARIA, South Africa','geo':{'lat':-26.28530,'lng':27.69860}},
{'stp':640789,'address':'640789, EGL - WEST, WEST FAR, PORTIA\'S PLACE, AA 4 MANDELA SECTION, Bekkersdal, #, WESTONARIA, South Africa','geo':{'lat':-26.28205,'lng':27.70058}},
{'stp':640825,'address':'640825, EGL - WEST, WEST FAR, MPULOMPULA, KK229 TAMBO, Bekkersdal, #, WESTONARIA, South Africa','geo':{'lat':-26.28680,'lng':27.68870}},
{'stp':640831,'address':'640831, EGL - WEST, WEST FAR, ZOLANI\'S SHEBEEN, KK 182 TAMBO SECTION, Bekkersdal, #, WESTONARIA, South Africa','geo':{'lat':-26.28557,'lng':27.69400}},
{'stp':641073,'address':'641073, EGL - WEST, WEST FAR, BASOTHONG, WKK 22 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.29050,'lng':27.68440}},
{'stp':641715,'address':'641715, EGL - WEST, WEST FAR, JOE\'S INN, 1301 LETSWERE STREET, Bekkersdal, #, WESTONARIA, South Africa','geo':{'lat':-26.28400,'lng':27.70070}},
{'stp':642217,'address':'642217, EGL - WEST, WEST FAR, THUSANANG SHEBEEN, E 20 THUSANANG, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.41475,'lng':27.70419}},
{'stp':642261,'address':'642261, EGL - WEST, WEST FAR, KWA DAVID, CC 109 MANDELA SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28620,'lng':27.70280}},
{'stp':642524,'address':'642524, EGL - WEST, WEST FAR, LEBZA, JJ 96 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28610,'lng':27.68780}},
{'stp':646335,'address':'646335, EGL - WEST, WEST FAR, TISHA\'S PLACE, 14 PANYA PANYA STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28120,'lng':27.69880}},
{'stp':647264,'address':'647264, EGL - WEST, WEST FAR, GAMA\'S TAVERN, ERF 381 KGOTSO STREET, SIMUNYE, #, WESTONARIA, South Africa','geo':{'lat':-26.32110,'lng':27.67720}},
{'stp':647373,'address':'647373, EGL - WEST, WEST FAR, BUTI INN, KK 282 TAMBO SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.17184,'lng':27.41606}},
{'stp':648253,'address':'648253, EGL - WEST, WEST FAR, NKOMOZI TAVERN, UKK 169 TAMBO SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.17420,'lng':27.41208}},
{'stp':648655,'address':'648655, EGL - WEST, WEST FAR, EMFENENI, A 44 THUSANANG SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.41440,'lng':27.70020}},
{'stp':648656,'address':'648656, EGL - WEST, WEST FAR, CHOPPIE\'S SHEBEEN, ERF 656 ZAMA STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28515,'lng':27.70932}},
{'stp':648869,'address':'648869, EGL - WEST, WEST FAR, CONGIE\'S INN, 634 GODLO STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28680,'lng':27.70680}},
{'stp':649709,'address':'649709, EGL - WEST, WEST FAR, MNQARHWANA, TKK 140 TAMBO SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28860,'lng':27.68880}},
{'stp':649710,'address':'649710, EGL - WEST, WEST FAR, JOHN SHEBEEN, 845 TV 845 HOLOMISA SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28002,'lng':27.69046}},
{'stp':650975,'address':'650975, EGL - WEST, WEST FAR, ZISIZE TUCKSHOP, XX 20 XUMA STREET, BEKKERSDAL, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.28310,'lng':27.71380}},
{'stp':650977,'address':'650977, EGL - WEST, WEST FAR, ZANELE\'S TAVERN, X 113 XHUMA STREET, BEKKERSDAL, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.28227,'lng':27.71233}},
{'stp':651075,'address':'651075, EGL - WEST, WEST FAR, KWA MACHAVA, JJ 77 TAMBO SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28558,'lng':27.69574}},
{'stp':651079,'address':'651079, EGL - WEST, WEST FAR, KWA ARONE, AA  101  MANDELA SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.27964,'lng':27.69568}},
{'stp':651486,'address':'651486, EGL - WEST, WEST FAR, SITHOLE\'S TUCKSHOP, Y1121 PANYA PANYA STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.27958,'lng':27.70369}},
{'stp':651487,'address':'651487, EGL - WEST, WEST FAR, TLADI\'S SHEBEEN, 2815 CNR JOHNSON & NYENYE STR, BEKKERSDAL, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.28349,'lng':27.70890}},
{'stp':652164,'address':'652164, EGL - WEST, WEST FAR, SITHOLE\'S TUCKSHOP, Y1121 PANYA PANYA STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.27980,'lng':27.70370}},
{'stp':652983,'address':'652983, EGL - WEST, WEST FAR, Y2K TUCKSHOP, KK 465 TAMBO SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.17171,'lng':27.41535}},
{'stp':653180,'address':'653180, EGL - WEST, WEST FAR, DEPHNEY\'S SHEBEEN, 2760 THETHELETSA STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28420,'lng':27.70960}},
{'stp':653392,'address':'653392, EGL - WEST, WEST FAR, SALI\'S INN, S39 SPOOKTOWN, #, #, WESTONARIA, South Africa','geo':{'lat':-26.27763,'lng':27.69801}},
{'stp':653484,'address':'653484, EGL - WEST, WEST FAR, IMBIZO TAVERN, 801 STATION STREET, KOCKSOORD, #, RANDFONTEIN, South Africa','geo':{'lat':-26.22070,'lng':27.64700}},
{'stp':653499,'address':'653499, EGL - WEST, WEST FAR, BEKKERSDAL TUCKSHOP, FF2 FF2 MANDELA SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28320,'lng':27.69990}},
{'stp':653537,'address':'653537, EGL - WEST, WEST FAR, MATSEKO\'S PLACE, K 38 SPOOKTOWN, #, #, WESTONARIA, South Africa','geo':{'lat':-26.27820,'lng':27.69840}},
{'stp':653538,'address':'653538, EGL - WEST, WEST FAR, JAFIDA\'S PLACE, L 25 SPOOKTOWN, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28500,'lng':27.69750}},
{'stp':653696,'address':'653696, EGL - WEST, WEST FAR, SIS LUISA, TV 765 HOLOMISA SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28049,'lng':27.69043}},
{'stp':653714,'address':'653714, EGL - WEST, WEST FAR, FANYANA\'S PLACE, 922 TV 922 HOLOMISA SECTION, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28114,'lng':27.68933}},
{'stp':653743,'address':'653743, EGL - WEST, WEST FAR, KHENENE\'S SHEBEEN, BB51 MANDELA SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28310,'lng':27.70000}},
{'stp':670483,'address':'670483, EGL - WEST, WEST FAR, SAM\'S INN, 2436 GODLO STREET, BEKKERSDAL, #, WESTONARIA, South Africa','geo':{'lat':-26.28690,'lng':27.71360}},
{'stp':688648,'address':'688648, EGL - WEST, WEST FAR, MDUDUZI\'S INN, GG23 GG23 MANDELA SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28260,'lng':27.69720}},
{'stp':695933,'address':'695933, EGL - WEST, WEST FAR, MAKO\'S SHEBEEN, 2593 ERF 2593, SIMUNYE EXT 1, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31770,'lng':27.68910}},
{'stp':699631,'address':'699631, EGL - WEST, WEST FAR, A1 RESTURANT, 17 ALLEN STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31860,'lng':27.65070}},
{'stp':703969,'address':'703969, EGL - WEST, WEST FAR, GEORGE\'S CLUB, 3566 HOLOMISA SECTION, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28259,'lng':27.69223}},
{'stp':706246,'address':'706246, EGL - WEST, WEST FAR, HAMILTON TAVERN, 578 HALLET STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28400,'lng':27.71260}},
{'stp':718339,'address':'718339, EGL - WEST, WEST FAR, M DICKSON GENERAL DEALER, 204 EKK, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28432,'lng':27.69073}},
{'stp':720377,'address':'720377, EGL - WEST, WEST FAR, TOWER CITY INN, 3528 ERF 3528, MANDELA SECTION, WAR, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28454,'lng':27.69907}},
{'stp':729355,'address':'729355, EGL - WEST, WEST FAR, HAVANNA INN TAVERN, 25 HOLDINGS 25, WATERPAN, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31940,'lng':27.64430}},
{'stp':746016,'address':'746016, EGL - WEST, WEST FAR, MEISIE\'S SHEBEEN, TSEBISO STREET 3215, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.32038,'lng':27.69870}},
{'stp':773655,'address':'773655, EGL - WEST, WEST FAR, OLIVIA\'S PLACE, 2490 GODLO, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28718,'lng':27.70927}},
{'stp':780218,'address':'780218, EGL - WEST, WEST FAR, TA RAPS INN, 719 MAAKSKOON STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.28627,'lng':27.71150}},
{'stp':786230,'address':'786230, EGL - WEST, WEST FAR, THE GREAT SIMUNYE TAVERN, 2268 JACKSON NKUNA, SIMUNYE, EXT 1, WESTONARIA, South Africa','geo':{'lat':-26.31527,'lng':27.69113}},
{'stp':786670,'address':'786670, EGL - WEST, WEST FAR, PAULINA\'S INN, 4600 THUPISO STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.33123,'lng':27.69986}},
{'stp':389140,'address':'389140, EGL - WEST, WEST FAR, BLUE CRANE CORNER LIQUUORS, 1 PORTION 1 OF HOLDING 44 , TENACRE, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.22146,'lng':27.63515}},
{'stp':390717,'address':'390717, EGL - WEST, WEST FAR, CAMBRIDGE LIQUOR - CARLETONVILLE, 3 EMERALD STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35770,'lng':27.39420}},
{'stp':391279,'address':'391279, EGL - WEST, WEST FAR, Mr Liquor, ERF 1306, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35763,'lng':27.39913}},
{'stp':391549,'address':'391549, EGL - WEST, WEST FAR, MIRIS TAVERN, 21 FLINT STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35671,'lng':27.39902}},
{'stp':391663,'address':'391663, EGL - WEST, WEST FAR, POOL WARRIORS, 5363 SHOP NO 2A COREFACT CENTRE 1A, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35204,'lng':27.39753}},
{'stp':392409,'address':'392409, EGL - WEST, WEST FAR, WEDELA LIQUOR STORE, 1588 KILIMANJARO DRIVE, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46232,'lng':27.38987}},
{'stp':392411,'address':'392411, EGL - WEST, WEST FAR, ANNAN\'S LIQUOR STORE, 1424 ANNAN ROAD, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35209,'lng':27.39789}},
{'stp':479376,'address':'479376, EGL - WEST, WEST FAR, SHOPRITE LIQUOR (GATEWAY MALL) 0344, 215 4420106777, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35423,'lng':27.39709}},
{'stp':482115,'address':'482115, EGL - WEST, WEST FAR, GOOD SPOT SPORTS BAR, 116JQ PORTION 3, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.42470,'lng':27.40180}},
{'stp':491327,'address':'491327, EGL - WEST, WEST FAR, CARLETONVILLE BLUE BOTTLE LIQUORS, 147 DOLOMITE STR , CNR ONYX DRIVE, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35666,'lng':27.39055}},
{'stp':543290,'address':'543290, EGL - WEST, WEST FAR, CENTRE PARK PUB, 04 SMOUSPOORT, SHOP NO 4, GLENHARVIE, WESTONARIA, South Africa','geo':{'lat':-26.38737,'lng':27.62566}},
{'stp':550146,'address':'550146, EGL - WEST, WEST FAR, TSEPONG LIQUOR OUTLET (NO 2), 113 MAIN HOSTEL GOLDFIELDS MINE, FARM DRIEFONTEIN NO 113, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.38709,'lng':27.42411}},
{'stp':550415,'address':'550415, EGL - WEST, WEST FAR, NUMBAWANI TAVERN (NO1 SHAFT), 115 PORTION 5 OF THE FARM, ELANDSFONTEIN, 115 I Q, CARLTONVILLE, South Africa','geo':{'lat':-26.44550,'lng':27.42310}},
{'stp':550536,'address':'550536, EGL - WEST, WEST FAR, CHESA BY NIGHT, ERF 1257 - ANNAN STREET, CARLETONVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35400,'lng':27.39840}},
{'stp':550636,'address':'550636, EGL - WEST, WEST FAR, IKHWEZI HOST BAR 2 LTD, PANVLAKTE NO 291 IQ ADJACENT, TO THE BEKKERSDAL ROAD, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.30284,'lng':27.71851}},
{'stp':550960,'address':'550960, EGL - WEST, WEST FAR, JUMBO DRANKWINKEL, 1245 ERF 1245, WINKEL 3, ONYXWEG 3, CARLETONVILLE EXT 2, CARLTONVILLE, South Africa','geo':{'lat':-26.35230,'lng':27.39610}},
{'stp':551120,'address':'551120, EGL - WEST, WEST FAR, BLUE BELL LIQUOR STORE, 2 PLATINUM ROAD, CARLETONVILLE, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.35890,'lng':27.41050}},
{'stp':551407,'address':'551407, EGL - WEST, WEST FAR, TRIO S RESTAURANT, SHAFT NO 2, EAST DRIEFONTEIN GOLD MINE, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.38950,'lng':27.50130}},
{'stp':551783,'address':'551783, EGL - WEST, WEST FAR, TOPS CARLETON, SHOPS 2 & 3, CARLETONVILLE KWIKSPAR, NO 2 ANNAN RD, CARLTONVILLE, South Africa','geo':{'lat':-26.37340,'lng':27.40670}},
{'stp':551828,'address':'551828, EGL - WEST, WEST FAR, GLENHARVIE PUB AND GRILL, 349 PORTION 82 RIETFONTEIN, IQ MAIN ROAD, 1787, WESTONARIA, South Africa','geo':{'lat':-26.39840,'lng':27.59070}},
{'stp':552105,'address':'552105, EGL - WEST, WEST FAR, MOTEBONG HOSTEL ANGLOGOLD ASHANTI, PORTION 62 OF THE FARM, BLYVOORUITZICHT NO 116 IQ, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.41660,'lng':27.42370}},
{'stp':552161,'address':'552161, EGL - WEST, WEST FAR, SOUTH DEEP GOLD MINE, PTN 42 OF FARM MODDERFONTEIN, NO 345 IQ, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.40558,'lng':27.68111}},
{'stp':552285,'address':'552285, EGL - WEST, WEST FAR, JUMBO LIQUOR STORE GLENHARVIE, SHOP NO 5, ERF 702, GLENHARVIE, WESTONARIA, South Africa','geo':{'lat':-26.38690,'lng':27.62650}},
{'stp':552323,'address':'552323, EGL - WEST, WEST FAR, FAMILY SAVE LIQUOR STORE, SHOP NO 1 CONSOLIDATED ERF 804, C/O STEYN & VAN DER BERG STREET, KOCKSOORD, RANDFONTEIN, South Africa','geo':{'lat':-26.21960,'lng':27.64800}},
{'stp':552482,'address':'552482, EGL - WEST, WEST FAR, HILLSHAVEN BOTTLE STORE, 01 SHOP 1, BEVERLEY DRIVE, HILLSHAVEN, WESTONARIA, South Africa','geo':{'lat':-26.36850,'lng':27.66460}},
{'stp':552538,'address':'552538, EGL - WEST, WEST FAR, MARCELO\'S PUB, VANMALI BUILDING, 3 OSMIUM STREET, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.35400,'lng':27.39830}},
{'stp':552543,'address':'552543, EGL - WEST, WEST FAR, PHOFUNG RECREATION CLUB, PTN 60 (PTN OF PTN 9) OF, BUFFELSDOORN 143 IQ, POTCHEFSTROOM, CARLTONVILLE, South Africa','geo':{'lat':-26.45275,'lng':27.36716}},
{'stp':552643,'address':'552643, EGL - WEST, WEST FAR, XHUNGU BROTHERS ENTERTAINMENT PARK, KHUTSONG SOUTH PORTION 101, WELVERDIEND ROAD, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.34890,'lng':27.32220}},
{'stp':552701,'address':'552701, EGL - WEST, WEST FAR, EKUTHULENI LIQUOR STORE (NO 4), 113 REMAINING EXTENT OF PORTION 2, OF FARM DRIEFINTEIN 113 IQ, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.37000,'lng':27.46820}},
{'stp':552739,'address':'552739, EGL - WEST, WEST FAR, KLOOF DRANKWINKEL, 3 MATHABO SENTRUM, BAINSKLOOF STRAAT 3, GLENHARVIE, WESTONARIA, South Africa','geo':{'lat':-26.38640,'lng':27.60390}},
{'stp':552777,'address':'552777, EGL - WEST, WEST FAR, MAC WEST LIQUOR OUTLET LTD, DOORNFONTEIN HOSTEL 3, FARM DOORNFONTEIN 118, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.46171,'lng':27.38441}},
{'stp':552864,'address':'552864, EGL - WEST, WEST FAR, CIVIC BEER HALL, 7 AMETHYST STREET, CARLETONVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35420,'lng':27.39650}},
{'stp':552916,'address':'552916, EGL - WEST, WEST FAR, KOPANANG LIQUOR OUTLET, 350 FARM DOORNKLOOF 350, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.40640,'lng':27.59180}},
{'stp':553177,'address':'553177, EGL - WEST, WEST FAR, ANNANS LIQUOR DISTRIBUTORS, 2 ERF 5381, ONYX DRIVE, CARLETONVILLE EXT 3,OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.35160,'lng':27.39780}},
{'stp':553207,'address':'553207, EGL - WEST, WEST FAR, NKULULEKO, 28 FARM LIBANON 28, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.34610,'lng':27.60620}},
{'stp':553270,'address':'553270, EGL - WEST, WEST FAR, MASIZAKHELE LIQUOR STORE, EAST DRIEFONTEIN GOLD MINE, MAIN HOSTEL, FARM DRIEFONTEIN, CARLTONVILLE, South Africa','geo':{'lat':-26.38980,'lng':27.49660}},
{'stp':553314,'address':'553314, EGL - WEST, WEST FAR, HIGHWAY LIQUOR STORE, PLOT 9, TEN ACRES, OLD CARLETONVILLE ROAD, RANDFONTEIN, South Africa','geo':{'lat':-26.23620,'lng':27.61080}},
{'stp':553630,'address':'553630, EGL - WEST, WEST FAR, RED CAP LIQUORS, 18 AMENTHYS STREET, CARLETONVILLE, EXT 2, CARLTONVILLE, South Africa','geo':{'lat':-26.35300,'lng':27.39560}},
{'stp':553710,'address':'553710, EGL - WEST, WEST FAR, LESEDING LIQUOR OUTLET, 351 FARM LEEUDOORN NO 351, WESTONARIA, #, WESTONARIA, South Africa','geo':{'lat':-26.44710,'lng':27.57020}},
{'stp':553790,'address':'553790, EGL - WEST, WEST FAR, DRIVE INN LIQUOR STORE, ERF 4539, SHOP NO 2 CORNER, REINECKE & DOLOMITE DRIVERS, CARLETONVILLE EXT 1, CARLTONVILLE, South Africa','geo':{'lat':-26.36820,'lng':27.39410}},
{'stp':561160,'address':'561160, EGL - WEST, WEST FAR, S AND S TAVERN, 2 LEBOMBO ST, FINSBURY, RANDFONTEIN, RANDFONTEIN, South Africa','geo':{'lat':-26.20390,'lng':27.65150}},
{'stp':561259,'address':'561259, EGL - WEST, WEST FAR, MOONLIGHT INN RESTAURANT, 32 SKOOL STREET, FOCHVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49410,'lng':27.49330}},
{'stp':581197,'address':'581197, EGL - WEST, WEST FAR, WHITE HOUSE, 1153 EXT 4, KHUTSONG SOUTH, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35301,'lng':27.33335}},
{'stp':603257,'address':'603257, EGL - WEST, WEST FAR, MOTSHIDISI\'S INN, 1032 TUMELO STREET, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35380,'lng':27.33640}},
{'stp':607616,'address':'607616, EGL - WEST, WEST FAR, LOVE AND PEACE TAVERN, 14 OUTENIQUA STREET, FINSBURY, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20971,'lng':27.65348}},
{'stp':629893,'address':'629893, EGL - WEST, WEST FAR, HILLSHAVEN LIQUOR MERCHANT, 629 BEVERLY DRIVE, HILLSHAVEN, #, WESTONARIA, South Africa','geo':{'lat':-26.36570,'lng':27.65710}},
{'stp':633681,'address':'633681, EGL - WEST, WEST FAR, KWA MAVIS, 835 TUMELO STREET, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33120,'lng':27.31800}},
{'stp':634578,'address':'634578, EGL - WEST, WEST FAR, MACINGWANE, 1987 EXT 4, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35780,'lng':27.32470}},
{'stp':635910,'address':'635910, EGL - WEST, WEST FAR, STALWARDS MOTEL AND LEGENDS, ERF 1243 SHOP NO 6, 1ST FLOOR, UPBEAT PROPS CENTRE, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.35230,'lng':27.39600}},
{'stp':640280,'address':'640280, EGL - WEST, WEST FAR, CHICAGO BAR  RESTAURANT, SHOP 2 EMERALD STREET, OBERHOLZER, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35590,'lng':27.39830}},
{'stp':646396,'address':'646396, EGL - WEST, WEST FAR, MACSELS SUPERSAVE, CNR SILICA & COPPER STREETS, ELANDS RIDGE, ELANDSRAND, CARLTONVILLE, South Africa','geo':{'lat':-26.44480,'lng':27.37100}},
{'stp':647873,'address':'647873, EGL - WEST, WEST FAR, RED CAP BAR  and RESTAURANT, 14 AMATHYST STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35370,'lng':27.39620}},
{'stp':649417,'address':'649417, EGL - WEST, WEST FAR, HOOFWEG KELDERS, PTN 7, 4 OF THE FARM HARTEBEESFONTE, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.23590,'lng':27.61020}},
{'stp':649977,'address':'649977, EGL - WEST, WEST FAR, MNCEDI\'S INN, 2137 EXT 4, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35380,'lng':27.32620}},
{'stp':649987,'address':'649987, EGL - WEST, WEST FAR, MATHEMBE\'S TAVERN, 1241 EXT, 4, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35090,'lng':27.32450}},
{'stp':650691,'address':'650691, EGL - WEST, WEST FAR, MARIA\'S PLACE, 2220 KHUTSONG SOUTH, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35500,'lng':27.32460}},
{'stp':650990,'address':'650990, EGL - WEST, WEST FAR, SILVAS RESTAURANT and BEERGARDEN, PTN OF PTN 4 FARM DOORNKLOOF, #, #, WESTONARIA, South Africa','geo':{'lat':-26.41550,'lng':27.59830}},
{'stp':651414,'address':'651414, EGL - WEST, WEST FAR, KLOOF RESTAURANT, PORTION 29 OF PORTION 4, FARM DOORN, #, #, WESTONARIA, South Africa','geo':{'lat':-26.41020,'lng':27.59360}},
{'stp':652177,'address':'652177, EGL - WEST, WEST FAR, AARON\'S PLACE, 172 DE VILLIERS STREET, HILLSIDE, #, #, RANDFONTEIN, South Africa','geo':{'lat':-26.22180,'lng':27.62580}},
{'stp':652879,'address':'652879, EGL - WEST, WEST FAR, MA\'VIS, 1589, EXT 4, KHUTSONG SOUTH, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35200,'lng':27.32590}},
{'stp':653074,'address':'653074, EGL - WEST, WEST FAR, NOBELA\'S TAVERN, 577 NOBELA STREET, KHUTSONG SOUTH, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35289,'lng':27.32761}},
{'stp':653267,'address':'653267, EGL - WEST, WEST FAR, ESTHER\'S SHEBEEN, 1576 EXT 4, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35113,'lng':27.32455}},
{'stp':653275,'address':'653275, EGL - WEST, WEST FAR, MAMBHELE, 916, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35150,'lng':27.33470}},
{'stp':654740,'address':'654740, EGL - WEST, WEST FAR, KOCKSROOD LIQUOR MARKET, NO 2 STATION STREET, SHOP NO 2, KOCKRSOOD, RANDFONTEIN, South Africa','geo':{'lat':-26.22210,'lng':27.64390}},
{'stp':657663,'address':'657663, EGL - WEST, WEST FAR, FINSBURY LIQUOR STORE, 864 FINSBURY CNR, FINSBURRY, #, RANDFONTEIN, South Africa','geo':{'lat':-26.20980,'lng':27.65520}},
{'stp':668067,'address':'668067, EGL - WEST, WEST FAR, SLAHLA PUB, 2302 ERF 2302, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35460,'lng':27.32730}},
{'stp':675763,'address':'675763, EGL - WEST, WEST FAR, NKELE\'S TAVERN, 124 ERF 124 EXT 4, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.34870,'lng':27.33340}},
{'stp':686986,'address':'686986, EGL - WEST, WEST FAR, PRESIDENT LIQUOR STORE, 91 ANNAN ROAD, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35370,'lng':27.39840}},
{'stp':691387,'address':'691387, EGL - WEST, WEST FAR, PHUTHADITCHABA HOSTEL BAR, 113 FARM DRIEFONTEIN, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.38100,'lng':27.45420}},
{'stp':698452,'address':'698452, EGL - WEST, WEST FAR, RICHARD LIQUOR CENTRE INN, 872 SHOP 01, REA IKAGA STREET, RICHARDS CENTRE, SIMUNYE, WESTONARIA, South Africa','geo':{'lat':-26.31749,'lng':27.68247}},
{'stp':699069,'address':'699069, EGL - WEST, WEST FAR, RICHARD\'S LIQUOR STORE, 872 SHOP 02, RICHARDS CENTRE, REA IKAGA STREET, SIMUNYE, WESTONARIA, South Africa','geo':{'lat':-26.31920,'lng':27.68150}},
{'stp':699293,'address':'699293, EGL - WEST, WEST FAR, PICK \'N PAY CARLETONVILLE, 1335 CNR OSMIUM AND RADIUM STREETS, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35770,'lng':27.39420}},
{'stp':709490,'address':'709490, EGL - WEST, WEST FAR, PIECE HAVEN, STAND NO 33, SELUKWE PEAK, FINSBURY, RANDFONTEIN, South Africa','geo':{'lat':-26.20070,'lng':27.65815}},
{'stp':771855,'address':'771855, EGL - WEST, WEST FAR, LEBANON RESTAURANT, 283 LEBANON SHOPPING CENTRE, #, #, WESTONARIA, South Africa','geo':{'lat':-26.34930,'lng':27.60406}},
{'stp':783221,'address':'783221, EGL - WEST, WEST FAR, Gientshisa pty ltd,  4 SMOUSEPOORT STREET, Glenharvie, Westonaria, WESTONARIA, South Africa','geo':{'lat':-26.38789,'lng':27.62556}},
{'stp':792858,'address':'792858, EGL - WEST, WEST FAR, SIMUNYE BOTTLE STORE, 2665 HORNBILL STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.34019,'lng':27.47191}},
{'stp':309597,'address':'309597, EGL - WEST, WEST FAR, SIYATHUTHUKA SHEBEEN, 251 MANDELA SECTION, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31765,'lng':27.32823}},
{'stp':388225,'address':'388225, EGL - WEST, WEST FAR, PILLARS PUB and  GRILL, 3301 ERF 3301 , TAU STREET , WEDELA, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46440,'lng':27.38943}},
{'stp':389077,'address':'389077, EGL - WEST, WEST FAR, JOE SHEBEEN, 15 WARD 15, MPHAHLINA VILLAGE, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.40004,'lng':27.50966}},
{'stp':389096,'address':'389096, EGL - WEST, WEST FAR, KWA MASELWANE SHISANYAMA, ERF:03952 SHOP No.3 , No. 3952 MOFU, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32388,'lng':27.32532}},
{'stp':391676,'address':'391676, EGL - WEST, WEST FAR, MPINGA\'S PLACE, 75 CH 75 B CHRIS HANI SECTION, #, #, ROODEPOORT, South Africa','geo':{'lat':-26.33273,'lng':27.30947}},
{'stp':392334,'address':'392334, EGL - WEST, WEST FAR, UNCLE SAM PLACE, 2172 TSHOLO STREET, KHUTSONG EXT 4, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35522,'lng':27.32496}},
{'stp':460337,'address':'460337, EGL - WEST, WEST FAR, LAZY INN, 7558 NKOSANA STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33080,'lng':27.31757}},
{'stp':473539,'address':'473539, EGL - WEST, WEST FAR, SALMON TAVERN, 4565 MANDELA STREET, EXTENSION 5, KOKOSI, CARLTONVILLE, South Africa','geo':{'lat':-26.50840,'lng':27.47562}},
{'stp':474762,'address':'474762, EGL - WEST, WEST FAR, SIYANGQOBA TAVERN, 6693 MANO STREET, KHUTSONG EXT 3, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33668,'lng':27.30933}},
{'stp':485593,'address':'485593, EGL - WEST, WEST FAR, KEY VEE\'S PLACE, 4585 EXT 3, WEDELA, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46846,'lng':27.37750}},
{'stp':487443,'address':'487443, EGL - WEST, WEST FAR, MANZO RESTAURANT, 3481 SPRINGBOK STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46530,'lng':27.39002}},
{'stp':550208,'address':'550208, EGL - WEST, WEST FAR, BAKWENA LIQUORS GS09, 3952 ERF 3952, MOFUBE ROAD, KHUTSONG, CARLTONVILLE, South Africa','geo':{'lat':-26.32388,'lng':27.32532}},
{'stp':550232,'address':'550232, EGL - WEST, WEST FAR, CROCODILE INN, STAND 3703, EXT 4 KOKOSI, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.51110,'lng':27.46540}},
{'stp':550242,'address':'550242, EGL - WEST, WEST FAR, EEM - DEE INN, NO 1149 ZAMBEZI DRIVE, WEDELA, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.46400,'lng':27.38487}},
{'stp':550507,'address':'550507, EGL - WEST, WEST FAR, FLAT INN, 3525 MUTLWA STREET, WEDELA, OBERHOLZER, CARLTONVILLE, South Africa','geo':{'lat':-26.46150,'lng':27.39080}},
{'stp':550521,'address':'550521, EGL - WEST, WEST FAR, CARLTON INN, ERF 455, KHUTSONG, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.32660,'lng':27.32070}},
{'stp':550770,'address':'550770, EGL - WEST, WEST FAR, BAKWENA INN, STAND 3627, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32308,'lng':27.32057}},
{'stp':551069,'address':'551069, EGL - WEST, WEST FAR, FREE WAY TAVERN, STAND 2151, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32060,'lng':27.32300}},
{'stp':551332,'address':'551332, EGL - WEST, WEST FAR, LAS VEGAS RESTAURANT, 28 SCHOOL STREET, FOCHVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49407,'lng':27.49332}},
{'stp':551346,'address':'551346, EGL - WEST, WEST FAR, MAKING FRIENDS TAVERN, ERF 365, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32650,'lng':27.32280}},
{'stp':551501,'address':'551501, EGL - WEST, WEST FAR, VITALIS INN, ERF 2769, KHUTSONG, 2505, CARLTONVILLE, South Africa','geo':{'lat':-26.31420,'lng':27.32510}},
{'stp':551798,'address':'551798, EGL - WEST, WEST FAR, GREENSPARK RESTAURANT, FISANTSTRAAT 102 / 7, GREENSPARK, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.49440,'lng':27.51550}},
{'stp':551940,'address':'551940, EGL - WEST, WEST FAR, HEAVY D, STAND 439, KOKOSI, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.49330,'lng':27.46110}},
{'stp':552218,'address':'552218, EGL - WEST, WEST FAR, MAFUNDAS TAVERN, STAND 2011 LEMBEDE STREET, KOKOSI, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.50270,'lng':27.46840}},
{'stp':552266,'address':'552266, EGL - WEST, WEST FAR, LOVERS INN, NO 47 GOOSE STREET, GREENSPARK, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.49420,'lng':27.51500}},
{'stp':552560,'address':'552560, EGL - WEST, WEST FAR, LOS ANGELES LIQUOR STORE, 3 / 102 FISANT STREET, GREENSPARK, FOCHVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.49490,'lng':27.51230}},
{'stp':552724,'address':'552724, EGL - WEST, WEST FAR, LEES PLACE, ERF 1182 LIMPOPO DRIVE, MSHENGU SECTION, WEDELA, CARLTONVILLE, South Africa','geo':{'lat':-26.46476,'lng':27.38268}},
{'stp':552809,'address':'552809, EGL - WEST, WEST FAR, IKETLENG SPROG TAVERN, ERF 1689, KHUTSONG, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.32040,'lng':27.32560}},
{'stp':552870,'address':'552870, EGL - WEST, WEST FAR, SPESHAS INN, ERF 3760, KHUTSONG, 2499, CARLTONVILLE, South Africa','geo':{'lat':-26.32360,'lng':27.32290}},
{'stp':552888,'address':'552888, EGL - WEST, WEST FAR, CLUB HLANGANANI, STAND 4343, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33070,'lng':27.31630}},
{'stp':553197,'address':'553197, EGL - WEST, WEST FAR, CLUB SEDIBENG, STAND 7772, EXT 1, KHUTSONG, CARLTONVILLE, South Africa','geo':{'lat':-26.32950,'lng':27.32470}},
{'stp':560606,'address':'560606, EGL - WEST, WEST FAR, FUNKY\'S PLACE, 6148 EXT 3, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.34005,'lng':27.30254}},
{'stp':561046,'address':'561046, EGL - WEST, WEST FAR, MSIPHOLA\'S PLACE, ERF 253, KHUTSONG, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.33000,'lng':27.31930}},
{'stp':580543,'address':'580543, EGL - WEST, WEST FAR, FUZI\'S SPORTS BAR, 5844 EXT 3, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33860,'lng':27.30180}},
{'stp':581070,'address':'581070, EGL - WEST, WEST FAR, GEORGE\'S PLACE, 480 KHUTSONG SOUTH, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.34850,'lng':27.33220}},
{'stp':581214,'address':'581214, EGL - WEST, WEST FAR, EMPOLWENI INN, 4856 MOKABA STREET, WEDELA, #, CARLTONVILLE, South Africa','geo':{'lat':-26.47210,'lng':27.37710}},
{'stp':603644,'address':'603644, EGL - WEST, WEST FAR, LEBOGANG\'S INN, 4916 EXT 2, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32170,'lng':27.31800}},
{'stp':604519,'address':'604519, EGL - WEST, WEST FAR, PARADISE INN, STAND 5020 KHUTSONG, CARLETONVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33850,'lng':27.32300}},
{'stp':604595,'address':'604595, EGL - WEST, WEST FAR, KUBU\'S PLACE, STAND 5429 EXT 2, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33420,'lng':27.32450}},
{'stp':605027,'address':'605027, EGL - WEST, WEST FAR, SHUKUSHUKUMA, 7002 EXT 3, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33400,'lng':27.31020}},
{'stp':605694,'address':'605694, EGL - WEST, WEST FAR, LUCKY LEAVES TAVERN, STAND 4391, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33208,'lng':27.31588}},
{'stp':607048,'address':'607048, EGL - WEST, WEST FAR, STOFUNG INN, 1717 THABABOSIU STREET, WEDELA, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46100,'lng':27.39070}},
{'stp':625476,'address':'625476, EGL - WEST, WEST FAR, SOUL SOUND, 728 LETWABA STREET, KOKOSI, #, CARLTONVILLE, South Africa','geo':{'lat':-26.50700,'lng':27.45980}},
{'stp':626853,'address':'626853, EGL - WEST, WEST FAR, ISAAC\'S PLACE, ERF 6030, KHUTSONG EXT 3, #, CARLTONVILLE, South Africa','geo':{'lat':-26.34200,'lng':27.30170}},
{'stp':627063,'address':'627063, EGL - WEST, WEST FAR, MANINI\'S INN, 2662 BATSWANA SECTION, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31583,'lng':27.32261}},
{'stp':627076,'address':'627076, EGL - WEST, WEST FAR, NONGO\'S PLACE, 2784 BATSWANA SECTION, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31420,'lng':27.32410}},
{'stp':627269,'address':'627269, EGL - WEST, WEST FAR, TSALANANG, 2223 SOMPANE ROAD, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31670,'lng':27.32120}},
{'stp':627305,'address':'627305, EGL - WEST, WEST FAR, MAMSY\'S PLACE, 4803 EXT 2, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33530,'lng':27.32010}},
{'stp':627393,'address':'627393, EGL - WEST, WEST FAR, DAKS WHOLESALER, 2802 MATLADI DRIVE, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31460,'lng':27.32570}},
{'stp':627923,'address':'627923, EGL - WEST, WEST FAR, MSHENGU INN, 563 XHOSA SECTION, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33690,'lng':27.30600}},
{'stp':628211,'address':'628211, EGL - WEST, WEST FAR, THE GIRL\'S INN, 2855 KHUTSONG, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31610,'lng':27.32280}},
{'stp':628212,'address':'628212, EGL - WEST, WEST FAR, 2 FOR JOY, 2845, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31580,'lng':27.32320}},
{'stp':631376,'address':'631376, EGL - WEST, WEST FAR, SAMMY\'S HAPPY HOUR, 5410 EXT 2, KHUTSONG SOUTH, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33350,'lng':27.31390}},
{'stp':631377,'address':'631377, EGL - WEST, WEST FAR, MATHEBULA INN, 66 CHRIS HANI SECTION, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35790,'lng':27.32480}},
{'stp':633472,'address':'633472, EGL - WEST, WEST FAR, BLESS JOINT, 3314 SEBEKO STREET, KOKOSI, #, CARLTONVILLE, South Africa','geo':{'lat':-26.50800,'lng':27.47120}},
{'stp':636536,'address':'636536, EGL - WEST, WEST FAR, EMM DEE INN, 1150 TUGELA DRIVE, WEDELA, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46380,'lng':27.38460}},
{'stp':637878,'address':'637878, EGL - WEST, WEST FAR, KWA MADALA, 6053 EXT 3, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.34240,'lng':27.30190}},
{'stp':639739,'address':'639739, EGL - WEST, WEST FAR, J RAMAFOKO DRANKWINKEL, STANDPLAAS 2560, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31900,'lng':27.32170}},
{'stp':640333,'address':'640333, EGL - WEST, WEST FAR, PATT\' S PLACE, 2565 KHUTSONG LOCATION, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32080,'lng':27.32610}},
{'stp':640877,'address':'640877, EGL - WEST, WEST FAR, AGNES MOKHATLA, 728 CHRIS HANI SECTION, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33550,'lng':27.30580}},
{'stp':640878,'address':'640878, EGL - WEST, WEST FAR, NKANA\'S INN, 485 MOTINYANE STREET, KOKOSI, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49350,'lng':27.46470}},
{'stp':642610,'address':'642610, EGL - WEST, WEST FAR, SKHABELA\'S PLACE, 02 SH 02, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.31750,'lng':27.31760}},
{'stp':646397,'address':'646397, EGL - WEST, WEST FAR, RAINBOW TAVERN, 3163 BIKO STREET, KOKOSI, #, CARLTONVILLE, South Africa','geo':{'lat':-26.50590,'lng':27.47280}},
{'stp':647570,'address':'647570, EGL - WEST, WEST FAR, THANDA BANTU TAVERN, 4129 EXT 4, KOKOSI, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.50910,'lng':27.46220}},
{'stp':647761,'address':'647761, EGL - WEST, WEST FAR, MAZENKENG, 1968 BASOTHO SECTION, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32080,'lng':27.32360}},
{'stp':649568,'address':'649568, EGL - WEST, WEST FAR, DINA\'S INN, 5652 KHUTSONG, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33640,'lng':27.32560}},
{'stp':649569,'address':'649569, EGL - WEST, WEST FAR, CHRISTINA\'S TUCKSHOP, 4302 KHAYALETHU STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33000,'lng':27.31610}},
{'stp':649660,'address':'649660, EGL - WEST, WEST FAR, THIBO\'S TAVERN, 721 KHAYALETHU STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32540,'lng':27.31550}},
{'stp':649955,'address':'649955, EGL - WEST, WEST FAR, GRANNY\'S PLACE, 5029, EXT 2, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33790,'lng':27.32350}},
{'stp':649989,'address':'649989, EGL - WEST, WEST FAR, GOODMAN\'S TAVERN, 1475, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35250,'lng':27.32017}},
{'stp':650693,'address':'650693, EGL - WEST, WEST FAR, MARGARET\'S INN, CH 10 CHRIS HANI SECTION, KHUTSONG, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33030,'lng':27.31160}},
{'stp':650696,'address':'650696, EGL - WEST, WEST FAR, ZUNGU\'S INN, CH 58 CHRIS HANI SECTION, KHUTSONG, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33190,'lng':27.31050}},
{'stp':653269,'address':'653269, EGL - WEST, WEST FAR, CARA\'S INN, 7819 EXT 1, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33190,'lng':27.32460}},
{'stp':653675,'address':'653675, EGL - WEST, WEST FAR, PHOMOLONG TAVERN, ERF 3754, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32320,'lng':27.32260}},
{'stp':654111,'address':'654111, EGL - WEST, WEST FAR, SP SHEBEEN, 1667 EXT 4, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.35201,'lng':27.32597}},
{'stp':656735,'address':'656735, EGL - WEST, WEST FAR, SEARCH\'S PLACE, 2154 MATLADI DRIVE, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32020,'lng':27.32290}},
{'stp':664118,'address':'664118, EGL - WEST, WEST FAR, DORA\'S TAVERN, 4713 4713 EXT 3, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46952,'lng':27.37640}},
{'stp':699279,'address':'699279, EGL - WEST, WEST FAR, GUGULETHU TAVERN, 5862 ERF 5862, EXT 3, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.34013,'lng':27.30085}},
{'stp':710106,'address':'710106, EGL - WEST, WEST FAR, ZAZISE TAVERN, 2615-1 DOVE STREET, BLYBANK, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33910,'lng':27.47815}},
{'stp':710468,'address':'710468, EGL - WEST, WEST FAR, FOCHVILLE PITT STOP HOTEL, PRESIDENT STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49413,'lng':27.49272}},
{'stp':725972,'address':'725972, EGL - WEST, WEST FAR, KWA MAGUMEDE, 5353 EXT 2, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46624,'lng':27.38702}},
{'stp':737911,'address':'737911, EGL - WEST, WEST FAR, MADLAMINI\'S TAVERN, 5353/44 EXT 2, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.46624,'lng':27.38701}},
{'stp':743428,'address':'743428, EGL - WEST, WEST FAR, VERKIES INN TAVERN, STAND 2034, CARLETONVILLE, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32179,'lng':27.32420}},
{'stp':747606,'address':'747606, EGL - WEST, WEST FAR, TSHANGISA\'S TAVERN, DUIKER STREET 3504, WEDELA, CARLTONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.46640,'lng':27.39110}},
{'stp':750877,'address':'750877, EGL - WEST, WEST FAR, GOOD LUCK PUB AND GRILL, PORTION 2 1243, CORNER ONXY & ANNAN STREET, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.35117,'lng':27.39782}},
{'stp':751828,'address':'751828, EGL - WEST, WEST FAR, KWA BONI, 489 NXUMALO STREET 489, THOZAMA SECTION, KHUTSONG, CARLTONVILLE, South Africa','geo':{'lat':-26.32594,'lng':27.32908}},
{'stp':754552,'address':'754552, EGL - WEST, WEST FAR, STELLENBOSCH CITY TAVERN, TSWANA SECTION 2858, CARLETONVILLE, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.31596,'lng':27.32273}},
{'stp':754553,'address':'754553, EGL - WEST, WEST FAR, POPSUE PLACE, JULIANA STREET 58, OBERHOLZER, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.35023,'lng':27.39107}},
{'stp':761669,'address':'761669, EGL - WEST, WEST FAR, GREEN HOUSE TAVERN, 10 ZIRCON STREET, CARLETONVILLE, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.36210,'lng':27.39767}},
{'stp':763932,'address':'763932, EGL - WEST, WEST FAR, STRONGBASE SHEBEEN, 1090 MOSHOESHOE STREET, EXT 4, KHUTSONG SOUTH, CARLTONVILLE, South Africa','geo':{'lat':-26.35361,'lng':27.33222}},
{'stp':763933,'address':'763933, EGL - WEST, WEST FAR, HASSAN SHEBEEN, MPHAHLWA VILLAGE A1, DRIEFONTEIN EAST, CARLETONVILLE, CARLTONVILLE, South Africa','geo':{'lat':-26.39958,'lng':27.50997}},
{'stp':768168,'address':'768168, EGL - WEST, WEST FAR, JOMO\' S INN, 218 STREET, KHUTSONG, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33190,'lng':27.31050}},
{'stp':776005,'address':'776005, EGL - WEST, WEST FAR, MAZO, 6294 EXT 3, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.33624,'lng':27.30754}},
{'stp':786291,'address':'786291, EGL - WEST, WEST FAR, B-ZEE CORNER PUB, 2328 MOLEBATSI STREET, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.32103,'lng':27.32195}},
{'stp':786668,'address':'786668, EGL - WEST, WEST FAR, KOPANANG TAVERN, 1736 KOPANANG STREET, TSWANA SECTION, EXT 9, KHUTSONG, South Africa','geo':{'lat':-26.31953,'lng':27.31719}},
{'stp':831065,'address':'831065, EGL - WEST, WEST FAR, ANGIE\'S PLACE, 68 ERF : 68 , PATRAISE, #, #, CARLTONVILLE, South Africa','geo':{'lat':-26.49456,'lng':27.51126}},
{'stp':553085,'address':'553085, EGL - WEST, WEST FAR, GOLDFIELDS EATING HOUSE, FARM UITVAL 280, LIBANON, WESTONARIA, WESTONARIA, South Africa','geo':{'lat':-26.35170,'lng':27.59790}},
{'stp':627958,'address':'627958, EGL - WEST, WEST FAR, MAUREEN \'S OASIS, PLOT 127, 4TH STREET 3RD AVENUE, ZUURBEKOM, WESTONARIA, South Africa','geo':{'lat':-26.29470,'lng':27.76480}},
{'stp':628618,'address':'628618, EGL - WEST, WEST FAR, KHONGWENI\'S LIQUOR MART, PLOT 186 RAILWAY STREET, ZUURBEKOM, #, WESTONARIA, South Africa','geo':{'lat':-26.30610,'lng':27.77130}},
{'stp':708771,'address':'708771, EGL - WEST, WEST FAR, GRADA\'S PLACE, 48 FIRST STREET, PLOT 48, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31074,'lng':27.75893}},
{'stp':728452,'address':'728452, EGL - WEST, WEST FAR, TSM TAVERN, 52 FIRST STREET, #, #, WESTONARIA, South Africa','geo':{'lat':-26.31015,'lng':27.76081}},
{'stp':769086,'address':'769086, EGL - WEST, WEST FAR, MOKOENA SHEBEEN, 103 W, WATERWORKS, ZUURBEKOM, WESTONARIA, South Africa','geo':{'lat':-26.29715,'lng':27.81372}},
{'stp':781877,'address':'781877, EGL - WEST, WEST FAR, SOLOMON\'S PLACE, 392 SANTO\'S AVENUE, ZUURBEKOM, #, WESTONARIA, South Africa','geo':{'lat':-26.30158,'lng':27.74879}},
{'stp':393191,'address':'393191, EGL - ISANDO, ISANDO EAST MAIN, AMHO GUESTHOUSE AND CONFERENCE CENT, 1 PLOT NO, HEIDELBERG ROAD, SPAAR WATER, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.45090,'lng':28.34840}},
{'stp':550162,'address':'550162, EGL - ISANDO, ISANDO EAST MAIN, THULANI BAR LOUNGE, 193 ERF, NGEMA SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33330,'lng':28.14895}},
{'stp':687027,'address':'687027, EGL - ISANDO, ISANDO EAST MAIN, JJS SPORTS BAR, PIET FICK STREET, ERF 49, ALRODE, ALBERTON, South Africa','geo':{'lat':-26.33945,'lng':28.16266}},
{'stp':687526,'address':'687526, EGL - ISANDO, ISANDO EAST MAIN, MOF AVENUE LIQUOR STORE AND LIQUOR, 272 MOSEU STREET, HLAHATSI SECTION, KATLEHONG, KATLEHONG, South Africa','geo':{'lat':-26.33543,'lng':28.15451}},
{'stp':765757,'address':'765757, EGL - ISANDO, ISANDO EAST MAIN, DRINKS GENERAL LIQUOR STORE, STAND NO, ERF 164 CORNER KLIPSRINGER AVENUE AND, PHILIP ROAD ROODEKOP, GERMISTON, South Africa','geo':{'lat':-26.36288,'lng':28.16485}},
{'stp':315403,'address':'315403, EGL - ISANDO, ISANDO EAST MAIN, MKHWANAZI TAVERN, 15 STAND NO, LETHEMBA STREET, ROODEKOP, SPRUITVIEW, South Africa','geo':{'lat':-26.31510,'lng':28.20486}},
{'stp':388804,'address':'388804, EGL - ISANDO, ISANDO EAST MAIN, MA SWEET TAVERN, 3765 STAND NO, LITHEMBA STREET, #, SPRUITVIEW, South Africa','geo':{'lat':-26.31479,'lng':28.20328}},
{'stp':392325,'address':'392325, EGL - ISANDO, ISANDO EAST MAIN, CHINUA MIZIK SOUL LOUNGE, 165,212 ERF KLIPSPRINGER, #, #, SPRUITVIEW, South Africa','geo':{'lat':-26.30847,'lng':28.19565}},
{'stp':475677,'address':'475677, EGL - ISANDO, ISANDO EAST MAIN, XABAS INN, 1512 ACACIA STREET, PHUMULA, #, BOKSBURG, South Africa','geo':{'lat':-26.32240,'lng':28.20470}},
{'stp':550477,'address':'550477, EGL - ISANDO, ISANDO EAST MAIN, OVERLAND LIQUORS, CNR LEONDALE & LUTHANDO STR, ROODEKOP, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31478,'lng':28.19693}},
{'stp':550652,'address':'550652, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR CITY LEONDALE, CNR CURRY RD AND BLAUWBOK AVE, LEONDALE, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.30330,'lng':28.17920}},
{'stp':551104,'address':'551104, EGL - ISANDO, ISANDO EAST MAIN, TOPS LEONDALE, SPAR CENTRE, BOUNDED BY GOUSBLOM, CNR WILDEBEEST AND BLESKOK AVENUE, SPRUITVIEW, South Africa','geo':{'lat':-26.30740,'lng':28.18770}},
{'stp':552196,'address':'552196, EGL - ISANDO, ISANDO EAST MAIN, HIGHWAY TAVERN AND RESTAURANT, PLOT 127 VLAKPLAAS, VLAKPLAAS, VOSLOORUS, VOSLOORUS, South Africa','geo':{'lat':-26.33600,'lng':28.23470}},
{'stp':561115,'address':'561115, EGL - ISANDO, ISANDO EAST MAIN, BRIANS TAVERN, STAND 4334 PHUMULA, EXT 21, ROODEKOP, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31770,'lng':28.20020}},
{'stp':580510,'address':'580510, EGL - ISANDO, ISANDO EAST MAIN, NDOSIS TAVERN, 26 BONTEBOK STREET, LEONDALE, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.30997,'lng':28.19085}},
{'stp':605077,'address':'605077, EGL - ISANDO, ISANDO EAST MAIN, MORGANS TAVERN, 4308 PHUMULA EXT 21, ROODEKOP, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31733,'lng':28.19927}},
{'stp':629965,'address':'629965, EGL - ISANDO, ISANDO EAST MAIN, MASECHABA GUEST HOUSE PERMIT, 5 ANTELOPE STREET, LEONDALE, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.30750,'lng':28.18060}},
{'stp':639184,'address':'639184, EGL - ISANDO, ISANDO EAST MAIN, KASDUDLAS PLACE, 5633/66 ROODEKOP, GERMISTON EXT 21, GERMISTON, SPRUITVIEW, South Africa','geo':{'lat':-26.32150,'lng':28.20050}},
{'stp':647551,'address':'647551, EGL - ISANDO, ISANDO EAST MAIN, MDLALOSE TAVERN, ERF 4925 PHUMULA, EXT 22, ROODEKOP, SPRUITVIEW, South Africa','geo':{'lat':-26.31170,'lng':28.19550}},
{'stp':653723,'address':'653723, EGL - ISANDO, ISANDO EAST MAIN, PALESAS INN, 369 BUHLE PARK, KLIPPOORTJIE, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.29608,'lng':28.19430}},
{'stp':677718,'address':'677718, EGL - ISANDO, ISANDO EAST MAIN, MHLEKAZI TAVERN, 1542 LUTHANDO STREET, EXT 10, MAPLETON, BOKSBURG, South Africa','geo':{'lat':-26.32520,'lng':28.20590}},
{'stp':686583,'address':'686583, EGL - ISANDO, ISANDO EAST MAIN, ALL IN ONE TUCKSHOP, 251 LUTHANDO STREET, MAPLETON EXT 10, RONDEBULT, BOKSBURG, South Africa','geo':{'lat':-26.32524,'lng':28.20957}},
{'stp':705497,'address':'705497, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR CITY RONDEBULT, CORNER HEIDELBERG AND VAN DYK, KLIPPOORTJIE SITUATED ON LOT 162, KLIPPOORTJIE RONDEBULT, SPRUITVIEW, South Africa','geo':{'lat':-26.29092,'lng':28.22913}},
{'stp':735509,'address':'735509, EGL - ISANDO, ISANDO EAST MAIN, KUDLI ATCHA DISTRIBUTION, 2371 PENNYWORTH STREET, EXT 2 RONDEBULT, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.30970,'lng':28.20810}},
{'stp':767892,'address':'767892, EGL - ISANDO, ISANDO EAST MAIN, JR MEAT AND BRAAI, 4746 LUTHANDO STREET, PHUMULA GARDEN, #, VOSLOORUS, South Africa','geo':{'lat':-26.31711,'lng':28.19560}},
{'stp':773720,'address':'773720, EGL - ISANDO, ISANDO EAST MAIN, LERATO SHEBEEN, GRANADINA STREET, ERF 363, MAPLETON EXTENSION 10 PHUMULA, BOKSBURG, South Africa','geo':{'lat':-26.32505,'lng':28.20833}},
{'stp':778829,'address':'778829, EGL - ISANDO, ISANDO EAST MAIN, KHOROS PLACE, 5661 PHELINDABA STREET, ROODEKOP EXTENSION 23, #, BOKSBURG, South Africa','geo':{'lat':-26.31296,'lng':28.20756}},
{'stp':793286,'address':'793286, EGL - ISANDO, ISANDO EAST MAIN, SAMS LIQUOR STORE RONDEBULT, STAND NO, ERF 2423 SHOP NO 3 SELEKA BUILDING, GARLIC STREET EXT 2, SPRUITVIEW, South Africa','geo':{'lat':-26.31245,'lng':28.21121}},
{'stp':311305,'address':'311305, EGL - ISANDO, ISANDO EAST MAIN, KABASIA BOTTLE STORE, 0/983 ERF NO, MANDELA, BLUEGUMVIEW, DUDUZA, South Africa','geo':{'lat':-26.38110,'lng':28.38950}},
{'stp':312884,'address':'312884, EGL - ISANDO, ISANDO EAST MAIN, LINDIWE\'S PLACE, 6869 STAND NO, LUCKY MOGODI STREET, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38190,'lng':28.41150}},
{'stp':388218,'address':'388218, EGL - ISANDO, ISANDO EAST MAIN, JEFREY S PLACE, 3860 STAND NO, MASETJHABA VIEW EXT 3, DUDUZA NIGEL, DUDUZA, South Africa','geo':{'lat':-26.39600,'lng':28.40270}},
{'stp':391114,'address':'391114, EGL - ISANDO, ISANDO EAST MAIN, ZUNGUZA TAVERN, 7137 MATSEGO STREET, #, #, DUDUZA, South Africa','geo':{'lat':-26.37334,'lng':28.39554}},
{'stp':391409,'address':'391409, EGL - ISANDO, ISANDO EAST MAIN, EBUHLATHINI S TAVERN, 646 STAND NO, MANDELA DRIVE EXTENSION 1, #, DUDUZA, South Africa','geo':{'lat':-26.38635,'lng':28.40247}},
{'stp':391476,'address':'391476, EGL - ISANDO, ISANDO EAST MAIN, VILLAGE RESTAURANT AND TAKE AWAYS, 130 STAND NO, SHOP A AND B BALFOUR ROAD, #, ALRA PARK, South Africa','geo':{'lat':-26.44010,'lng':28.51970}},
{'stp':392636,'address':'392636, EGL - ISANDO, ISANDO EAST MAIN, DIALE S TAVERN, 59 ERF  BLUEGUM VIEW DUDUZA, #, #, DUDUZA, South Africa','geo':{'lat':-26.38316,'lng':28.40504}},
{'stp':462423,'address':'462423, EGL - ISANDO, ISANDO EAST MAIN, HEROES INN, 3695 STAND NO, MASECHABA VIEW, EXT 3 DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39521,'lng':28.39366}},
{'stp':464578,'address':'464578, EGL - ISANDO, ISANDO EAST MAIN, NKOSI\'S INN, 572524 STAND NO, MASECHABA STREET, DUDUZA NIGEL, DUDUZA, South Africa','geo':{'lat':-26.39263,'lng':28.39197}},
{'stp':465385,'address':'465385, EGL - ISANDO, ISANDO EAST MAIN, JULI\'S TAVERN, 5362 ERF, KAU STREET, #, DUDUZA, South Africa','geo':{'lat':-26.37839,'lng':28.39855}},
{'stp':478632,'address':'478632, EGL - ISANDO, ISANDO EAST MAIN, ZAKES TAVERN, 6874 POOA STREET, DUDUZA, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.38180,'lng':28.40920}},
{'stp':487525,'address':'487525, EGL - ISANDO, ISANDO EAST MAIN, CAPITAL D, STAND NO, ERVEN 5488, NALA STREET, NIGEL, South Africa','geo':{'lat':-26.37480,'lng':28.40880}},
{'stp':488641,'address':'488641, EGL - ISANDO, ISANDO EAST MAIN, NOT YET UHURU LOUNGE, 12 STAND NO, KLIEPRIVIER, ALRAPARK EXT 2 ERF 01914, ALRA PARK, South Africa','geo':{'lat':-26.44607,'lng':28.50767}},
{'stp':551101,'address':'551101, EGL - ISANDO, ISANDO EAST MAIN, JULIAS TAVERN, 5362 KAY STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37780,'lng':28.39790}},
{'stp':551430,'address':'551430, EGL - ISANDO, ISANDO EAST MAIN, BACK OF THE MOON,  215 SIBEKO STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37230,'lng':28.41110}},
{'stp':551716,'address':'551716, EGL - ISANDO, ISANDO EAST MAIN, CHAZIS TAVERN, 1796 NKOMO STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37370,'lng':28.40520}},
{'stp':552226,'address':'552226, EGL - ISANDO, ISANDO EAST MAIN, M J INN, 1652 MNCUBE STREET, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39300,'lng':28.39470}},
{'stp':552398,'address':'552398, EGL - ISANDO, ISANDO EAST MAIN, MANHATTAN TAVERN, 1978 NKOMO STREET, DUDUZA TOWNSHIP, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37060,'lng':28.40050}},
{'stp':553723,'address':'553723, EGL - ISANDO, ISANDO EAST MAIN, IF NOT WHY NOT TAVERN, 555 FUBU STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.36890,'lng':28.40880}},
{'stp':561074,'address':'561074, EGL - ISANDO, ISANDO EAST MAIN, HLUPHIS TAVERN, 424 DONTZA  STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.36730,'lng':28.40920}},
{'stp':561141,'address':'561141, EGL - ISANDO, ISANDO EAST MAIN, JIGA\'S TAVERN, 3746 TSAGANE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38250,'lng':28.40840}},
{'stp':580877,'address':'580877, EGL - ISANDO, ISANDO EAST MAIN, MASKHOZI\'S TAVERN, 8554 MLANGENI STREET, ROSEVIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37260,'lng':28.41500}},
{'stp':581044,'address':'581044, EGL - ISANDO, ISANDO EAST MAIN, KGOKGO TAVERN, 3675 LOPENG STREET, DUDUZA, SOTHO SECTION, DUDUZA, South Africa','geo':{'lat':-26.38180,'lng':28.40920}},
{'stp':581061,'address':'581061, EGL - ISANDO, ISANDO EAST MAIN, NOMSAS PLACE, 1876 MAVUSO STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.36860,'lng':28.40380}},
{'stp':581103,'address':'581103, EGL - ISANDO, ISANDO EAST MAIN, MOLATIS TAVERN, 2224 SOBOKWE STREET, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39200,'lng':28.39680}},
{'stp':603110,'address':'603110, EGL - ISANDO, ISANDO EAST MAIN, ALFRED MAHLANGU, 43 GAZZELL DRIVE, ALRA PARK, NIGEL, ALRA PARK, South Africa','geo':{'lat':-26.43540,'lng':28.51100}},
{'stp':605341,'address':'605341, EGL - ISANDO, ISANDO EAST MAIN, MASAKHANE, 35 ZAMBESI ROAD, CERUNTIVILLE, ALRA PARK, ALRA PARK, NIGEL, South Africa','geo':{'lat':-26.44303,'lng':28.50755}},
{'stp':605482,'address':'605482, EGL - ISANDO, ISANDO EAST MAIN, MASUNGUBALAS AND SON, 3036 THOBELA STREET, EXT 3, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38390,'lng':28.39400}},
{'stp':606364,'address':'606364, EGL - ISANDO, ISANDO EAST MAIN, NOMSAS TAVERN, 6864 LUCKY MOKGUDI STREET, EXTENSION 2, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38190,'lng':28.41150}},
{'stp':607364,'address':'607364, EGL - ISANDO, ISANDO EAST MAIN, BUHLEBAKHE SHEBEEN, 1577 MASECHABA VIEW, MAYEKISO EXT 1, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39000,'lng':28.39080}},
{'stp':608758,'address':'608758, EGL - ISANDO, ISANDO EAST MAIN, SIZA BANTU (NTO), 5061 MPHAHLE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38143,'lng':28.40480}},
{'stp':625551,'address':'625551, EGL - ISANDO, ISANDO EAST MAIN, SAMO LIQUOR STORE, 889 GAZEL DRIVE, ALRA PARK, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.43810,'lng':28.51370}},
{'stp':627141,'address':'627141, EGL - ISANDO, ISANDO EAST MAIN, FANOS TAVERN, 3111 MOHAPI STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37600,'lng':28.41290}},
{'stp':627802,'address':'627802, EGL - ISANDO, ISANDO EAST MAIN, NORMANS PLACE PERMIT, 4633 MASECHABA VIEW EXT 2, DUDUZA, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.39310,'lng':28.38770}},
{'stp':627982,'address':'627982, EGL - ISANDO, ISANDO EAST MAIN, MADALAS PLACE PERMIT, 577 BLUEGUM VIEW, EXT 1, BLUE GUM VIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38380,'lng':28.39720}},
{'stp':628400,'address':'628400, EGL - ISANDO, ISANDO EAST MAIN, BACK STAGE TAVERN PERMIT, 1606 MAYIHLOME STREET, BLUE GUM VIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38010,'lng':28.39340}},
{'stp':630579,'address':'630579, EGL - ISANDO, ISANDO EAST MAIN, KHATHIDAS TAVERN, 1264 MKWAYI STREET, EXT 2, BLUE GUM VIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38110,'lng':28.38950}},
{'stp':630710,'address':'630710, EGL - ISANDO, ISANDO EAST MAIN, MATLALAS TAVERN, 3548 LETSAPA STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38000,'lng':28.41010}},
{'stp':630712,'address':'630712, EGL - ISANDO, ISANDO EAST MAIN, JACOBS PLACE, 8 NYALA  AVENUE, ALRA PARK, NIGEL, ALRA PARK, South Africa','geo':{'lat':-26.43450,'lng':28.50850}},
{'stp':635975,'address':'635975, EGL - ISANDO, ISANDO EAST MAIN, POPPIES PLACE, 2684 STAND NO, THAMBO STREET, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38480,'lng':28.39010}},
{'stp':636726,'address':'636726, EGL - ISANDO, ISANDO EAST MAIN, BONGANI\'S INN, 240 NONJEKWA STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37202,'lng':28.41206}},
{'stp':637101,'address':'637101, EGL - ISANDO, ISANDO EAST MAIN, SEVEN TO SEVEN TAVERN, 1833 SOBUKWE STREET, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39020,'lng':28.39500}},
{'stp':641440,'address':'641440, EGL - ISANDO, ISANDO EAST MAIN, ZANZIBAR, 1299 MOLOI STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39050,'lng':28.39470}},
{'stp':642626,'address':'642626, EGL - ISANDO, ISANDO EAST MAIN, TITI\'S PLACE, 1375 RADEBE STREET, DUDUZA, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.36560,'lng':28.40800}},
{'stp':642698,'address':'642698, EGL - ISANDO, ISANDO EAST MAIN, RICHARD\'S INN, 325 KINGFISHER, EXT 1 DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38380,'lng':28.40190}},
{'stp':646295,'address':'646295, EGL - ISANDO, ISANDO EAST MAIN, JANE\'S INN, 75 PANVIEW ROAD, ALRA PARK, NIGEL, ALRA PARK, South Africa','geo':{'lat':-26.44080,'lng':28.51050}},
{'stp':647661,'address':'647661, EGL - ISANDO, ISANDO EAST MAIN, MATEWU\'S TAVERN, 4396 MPHUTHI STREET, DUDUZA, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.37560,'lng':28.40720}},
{'stp':648278,'address':'648278, EGL - ISANDO, ISANDO EAST MAIN, TITI POTE BOTTLE STORE, 5490 LEKOPE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37920,'lng':28.40820}},
{'stp':648524,'address':'648524, EGL - ISANDO, ISANDO EAST MAIN, PINKY\'S PLACE, 8632 NYEMBE STREET, DUDUZA, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.37070,'lng':28.41370}},
{'stp':648550,'address':'648550, EGL - ISANDO, ISANDO EAST MAIN, MIKES TAVERN, 1984 COMRATA STREET EXT 3, BLUEGUM VIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38200,'lng':28.39470}},
{'stp':648554,'address':'648554, EGL - ISANDO, ISANDO EAST MAIN, OUMA\'S PLACE, 1514 NGUBANE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37220,'lng':28.40700}},
{'stp':648581,'address':'648581, EGL - ISANDO, ISANDO EAST MAIN, THE CORNER HOUSE, 289 MAYABA STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37100,'lng':28.41140}},
{'stp':648724,'address':'648724, EGL - ISANDO, ISANDO EAST MAIN, NOTOYI AND SONS, 3642 MAKHALEMELE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38130,'lng':28.40930}},
{'stp':648725,'address':'648725, EGL - ISANDO, ISANDO EAST MAIN, NOMAGISI\'S PLACE, 5027 RAMAPHOSA STREET, ZAMANE SECTION, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38255,'lng':28.41679}},
{'stp':648732,'address':'648732, EGL - ISANDO, ISANDO EAST MAIN, FANIS TAVERN, 3526 JOHN DUBE STREET, ZAMANI SECTION, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37220,'lng':28.40470}},
{'stp':648753,'address':'648753, EGL - ISANDO, ISANDO EAST MAIN, MATLAKALA\'S INN, 3913 THOBEJANE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37990,'lng':28.40730}},
{'stp':648781,'address':'648781, EGL - ISANDO, ISANDO EAST MAIN, MPHAHLELE\'S PLACE, 3960 LOVE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39600,'lng':28.40270}},
{'stp':648970,'address':'648970, EGL - ISANDO, ISANDO EAST MAIN, SINENE GOGO\'S TRADING, 2385 HINTSA STREET, BLUE GUM, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38820,'lng':28.40020}},
{'stp':649151,'address':'649151, EGL - ISANDO, ISANDO EAST MAIN, NOZENJA\'S TAVERN, 2977 NAMIBIA STREET, BLUEGUM VIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38608,'lng':28.39101}},
{'stp':649482,'address':'649482, EGL - ISANDO, ISANDO EAST MAIN, SOLLY\'S TAVERN, 1622 MAYEKISO STREET, MASETJABA VIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39240,'lng':28.39340}},
{'stp':653104,'address':'653104, EGL - ISANDO, ISANDO EAST MAIN, THAMIS PLACE, 654 NTOMELA STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37150,'lng':28.41000}},
{'stp':653216,'address':'653216, EGL - ISANDO, ISANDO EAST MAIN, HAMBAUZOBUYA TAVERN, 3 KAI STREET, EXT 2, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.44450,'lng':28.50680}},
{'stp':653231,'address':'653231, EGL - ISANDO, ISANDO EAST MAIN, BOBS TAVERN 2, 3046 MOTAUNG STREET, SOTHO SECTION, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37680,'lng':28.41330}},
{'stp':653263,'address':'653263, EGL - ISANDO, ISANDO EAST MAIN, ZWELABELUNGUS TAVERN, 22 FIG AVENUE, ALRA, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.43510,'lng':28.51390}},
{'stp':654253,'address':'654253, EGL - ISANDO, ISANDO EAST MAIN, BAFANAS INN, 3334 MASECHABA VIEW, EXTENSION 3, DUBAZANA, DUDUZA, South Africa','geo':{'lat':-26.39320,'lng':28.39840}},
{'stp':654842,'address':'654842, EGL - ISANDO, ISANDO EAST MAIN, FIKILES TAVERN, 1480 NQUBANE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.36880,'lng':28.40450}},
{'stp':657517,'address':'657517, EGL - ISANDO, ISANDO EAST MAIN, TAMARA ZONDO, 185 ASTER STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38223,'lng':28.40282}},
{'stp':658864,'address':'658864, EGL - ISANDO, ISANDO EAST MAIN, THANDIS INN, ERF 3312 BLUGGUMVIEW, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38730,'lng':28.39850}},
{'stp':685626,'address':'685626, EGL - ISANDO, ISANDO EAST MAIN, REAL GODFATHER, 643 GWALA STREET, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38980,'lng':28.39900}},
{'stp':687312,'address':'687312, EGL - ISANDO, ISANDO EAST MAIN, DAVES PLACE, 09 LUTHULI STREET, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38770,'lng':28.39000}},
{'stp':692749,'address':'692749, EGL - ISANDO, ISANDO EAST MAIN, MONTE CARLO, 225 NANJEKWA STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37190,'lng':28.41240}},
{'stp':694618,'address':'694618, EGL - ISANDO, ISANDO EAST MAIN, CHISA, 3283 ANGOLA STREET, NIGEL, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.38610,'lng':28.39920}},
{'stp':756532,'address':'756532, EGL - ISANDO, ISANDO EAST MAIN, WHITE HOUSE PUB, 2172 STAND NO, NALA STREET DUDUZA, #, DUDUZA, South Africa','geo':{'lat':-26.37310,'lng':28.40170}},
{'stp':763465,'address':'763465, EGL - ISANDO, ISANDO EAST MAIN, PAULINAH S PALACE, 3857 STAND NO, MASECHABA EXT 03 DUDUZA NIGEL, #, DUDUZA, South Africa','geo':{'lat':-26.39767,'lng':28.39873}},
{'stp':765114,'address':'765114, EGL - ISANDO, ISANDO EAST MAIN, NKOSI S TAVERN, JOE SLOVO STREET, ERF 2734, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.44450,'lng':28.50680}},
{'stp':768412,'address':'768412, EGL - ISANDO, ISANDO EAST MAIN, MAVIMBELA LAS VEGAS, VAAL RIVER STREET, ERF 2421, ALRA PARK EXTENSION 3, ALRA PARK, South Africa','geo':{'lat':-26.44803,'lng':28.51107}},
{'stp':769496,'address':'769496, EGL - ISANDO, ISANDO EAST MAIN, MTHEMBUS INN, 184 STAND NO, EXTENSION 1, BLUEGUM VIEW, DUDUZA, South Africa','geo':{'lat':-26.38245,'lng':28.40282}},
{'stp':769577,'address':'769577, EGL - ISANDO, ISANDO EAST MAIN, GIFTS INN, 3837 STAND NO, MAYEKISO STREET, ZAMANI SECTION, DUDUZA, South Africa','geo':{'lat':-26.38390,'lng':28.41230}},
{'stp':774361,'address':'774361, EGL - ISANDO, ISANDO EAST MAIN, GROOTBOOM TAVERN, 30 STAND NO, BOSBOK AVENUE, ALRAPARK, ALRA PARK, South Africa','geo':{'lat':-26.43740,'lng':28.50880}},
{'stp':795675,'address':'795675, EGL - ISANDO, ISANDO EAST MAIN, LEGACY LOUNGE, LEKOPE STREET, ERF 654 BLUEGUMVIEW EXT 1, #, DUDUZA, South Africa','geo':{'lat':-26.38635,'lng':28.40247}},
{'stp':796116,'address':'796116, EGL - ISANDO, ISANDO EAST MAIN, NONHLANHLAS TAVERN, STAND NO, ERF 5596 SEREMA STREET, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37927,'lng':28.40330}},
{'stp':755002,'address':'755002, EGL - ISANDO, ISANDO EAST HYBRID, THULI S BAR (MCHACHO\'S), 9069 MASAKHANE STREET, TSHONGNENI, #, KATLEHONG, South Africa','geo':{'lat':-26.34560,'lng':28.15127}},
{'stp':393002,'address':'393002, EGL - ISANDO, ISANDO EAST HYBRID, TERENCE S PLACE, 2309 STAND NO, MAPLETON EXT 12 BOKSBURG, #, BOKSBURG, South Africa','geo':{'lat':-26.32928,'lng':28.20971}},
{'stp':551614,'address':'551614, EGL - ISANDO, ISANDO EAST HYBRID, BOMA ALBERTON, CNR RING ROAD WES AND FORE STR, NEW REDRUTH, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26370,'lng':28.11710}},
{'stp':552937,'address':'552937, EGL - ISANDO, ISANDO EAST HYBRID, MIKE\'S KITCHEN ALBERTON,  P O BOX 10102, VERWOERD PARK, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.27170,'lng':28.12190}},
{'stp':315402,'address':'315402, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR LEGENDS BALFOUR, 101 STAND NO, PERTUNIA STREET, #, BALFOUR, South Africa','geo':{'lat':-26.66160,'lng':28.58647}},
{'stp':388493,'address':'388493, EGL - ISANDO, ISANDO EAST HYBRID, MVELASE TAVERN, 5032 ERF NO, EXT 5, #, BALFOUR, South Africa','geo':{'lat':-26.65373,'lng':28.60752}},
{'stp':390579,'address':'390579, EGL - ISANDO, ISANDO EAST HYBRID, SALT ROCK TAVERN AND RESTAURANT, 29 STAND NO, KUBHEKA STREET NTHORWANE, DIPALISENG GERT SIBANDE, BALFOUR, South Africa','geo':{'lat':-26.64569,'lng':28.60429}},
{'stp':392606,'address':'392606, EGL - ISANDO, ISANDO EAST HYBRID, EBUHLENI AND PROJECTS PTY LTD, 92 CHARLES STREET STAND, BALFOUR DIPALISENG, #, BALFOUR, South Africa','geo':{'lat':-26.64592,'lng':28.60139}},
{'stp':489146,'address':'489146, EGL - ISANDO, ISANDO EAST HYBRID, LADIES NIGHT, 21 STAND NO, KUBEKA STREET, GREYLINGSTAD, BALFOUR, South Africa','geo':{'lat':-26.76152,'lng':28.77123}},
{'stp':550290,'address':'550290, EGL - ISANDO, ISANDO EAST HYBRID, PICK N LIQUOR, STUARTSTRAAT 12, BALFOUR, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.65930,'lng':28.58500}},
{'stp':550639,'address':'550639, EGL - ISANDO, ISANDO EAST HYBRID, BELINA S TAVERN, 273 SIYATHEMBA, BALFOUR, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.65343,'lng':28.61229}},
{'stp':550806,'address':'550806, EGL - ISANDO, ISANDO EAST HYBRID, KULULEKA TAVERN, 781 SITHEBE STREET, SIYATHEMBA, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.64770,'lng':28.61640}},
{'stp':550892,'address':'550892, EGL - ISANDO, ISANDO EAST HYBRID, M AND J BOTTLING, ERF 629 MAIN STREET, GREYLINGSTAD, GREYLINGSTAD, BALFOUR, South Africa','geo':{'lat':-26.74730,'lng':28.75090}},
{'stp':552941,'address':'552941, EGL - ISANDO, ISANDO EAST HYBRID, THANDA BANTU DRANKWINKEL, STANDPLAAS 623 ZWANE STREET, SIYATHEMBA, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.64920,'lng':28.61129}},
{'stp':553374,'address':'553374, EGL - ISANDO, ISANDO EAST HYBRID, GREYLINGSTAD LIQUOR STORE, 508 & 606 PORTIN 1 OF ERF, MAIN ROAD WILLEMSDAL, GREYLINGSTAD, BALFOUR, South Africa','geo':{'lat':-26.74580,'lng':28.74700}},
{'stp':553456,'address':'553456, EGL - ISANDO, ISANDO EAST HYBRID, GROOTVLEI DRANKWINKEL, SPRINGFIELD ROAD, GROOTVLEI, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.80102,'lng':28.49890}},
{'stp':649460,'address':'649460, EGL - ISANDO, ISANDO EAST HYBRID, TRUMP BOTTLE STORE, ERF 1738, 168 JOUBERT STREET, BALFOUR, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.67350,'lng':28.58530}},
{'stp':662677,'address':'662677, EGL - ISANDO, ISANDO EAST HYBRID, LIONS PUB, 508 MAIN STREET, ERF 508 SHOP NO 2, GREYLINGSTAD, GREYLINGSTAD, South Africa','geo':{'lat':-26.56151,'lng':28.39245}},
{'stp':683500,'address':'683500, EGL - ISANDO, ISANDO EAST HYBRID, ZAMAZA TAVERN, NKONKONI STREET, STAND NO 2659, SIYATHEMBA LOCATION, BALFOUR, South Africa','geo':{'lat':-26.65735,'lng':28.61377}},
{'stp':701514,'address':'701514, EGL - ISANDO, ISANDO EAST HYBRID, ZAMOKHULE TAVERN, STAND NO 931, RATANANG STREET, GREYLINGSTAD, GREYLINGSTAD, South Africa','geo':{'lat':-26.75760,'lng':28.76730}},
{'stp':749185,'address':'749185, EGL - ISANDO, ISANDO EAST HYBRID, MAZIYA LIQUOR STORE, 5613B STAND NO, SIYATHEMBA EXT 3, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.64372,'lng':28.62007}},
{'stp':768403,'address':'768403, EGL - ISANDO, ISANDO EAST HYBRID, SONDELA TAVERN, 3141 STAND NO, MANDELA STREET, #, BALFOUR, South Africa','geo':{'lat':-26.66077,'lng':28.60805}},
{'stp':781854,'address':'781854, EGL - ISANDO, ISANDO EAST HYBRID, BABAWAMI PUB AND GRILL, STAND NO, ERF NO 2970 EXT 2 BALFOUR SIYATHEMBA, LOCATION, BALFOUR, South Africa','geo':{'lat':-26.66049,'lng':28.60883}},
{'stp':785437,'address':'785437, EGL - ISANDO, ISANDO EAST HYBRID, QUICK TOP TAVERN, STAND NO, PORTION 8 FARM STEYNKRAAL, 399 IR, BALFOUR, South Africa','geo':{'lat':-26.60651,'lng':28.49182}},
{'stp':391869,'address':'391869, EGL - ISANDO, ISANDO EAST HYBRID, FIVE SEASONS, 139 SHOP NO 1, ERF, HENDRICK VERWOERD STREET, #, NIGEL, South Africa','geo':{'lat':-26.42461,'lng':28.48234}},
{'stp':393052,'address':'393052, EGL - ISANDO, ISANDO EAST HYBRID, ZULU TAVERN, 280 ERF, MDA STREET, JOHN DUBE VILLAGE, NIGEL, South Africa','geo':{'lat':-26.36097,'lng':28.42198}},
{'stp':464753,'address':'464753, EGL - ISANDO, ISANDO EAST HYBRID, STHENJWA\'S TAVERN, 1963 STAND NO, ZIMU STREET, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.36939,'lng':28.40142}},
{'stp':466183,'address':'466183, EGL - ISANDO, ISANDO EAST HYBRID, SMALL KTICHEN PUB, 5531 STAND NO, NDUDULA STREET, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.36976,'lng':28.40669}},
{'stp':475850,'address':'475850, EGL - ISANDO, ISANDO EAST HYBRID, THEATRE PUB AND GRILL, 1473 ERF, NO 42 HENDRICK, VERWOERD STREET, NIGEL, South Africa','geo':{'lat':-26.41860,'lng':28.47000}},
{'stp':488630,'address':'488630, EGL - ISANDO, ISANDO EAST HYBRID, BLUE SKY BOTTLE STORE, 3533 STAND NUMBER, MONTWEDI STREET, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37920,'lng':28.40820}},
{'stp':550574,'address':'550574, EGL - ISANDO, ISANDO EAST HYBRID, FRIENDLY LIQUOR STORE, 65 RHODES AVE, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.42240,'lng':28.46120}},
{'stp':551694,'address':'551694, EGL - ISANDO, ISANDO EAST HYBRID, KLEIN BEGIN TAVERN, PLOT 34 PORTION 9 OF FARM HOLGATFON, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.43070,'lng':28.54650}},
{'stp':551802,'address':'551802, EGL - ISANDO, ISANDO EAST HYBRID, HALLGATE RESTAURANT, VLAKFONTEIN ROAD, PLOT 41, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.42350,'lng':28.52900}},
{'stp':552542,'address':'552542, EGL - ISANDO, ISANDO EAST HYBRID, NIGEL LIQOUR STORE, 35 BREYTENBACH STREET, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.41910,'lng':28.46910}},
{'stp':552653,'address':'552653, EGL - ISANDO, ISANDO EAST HYBRID, DUNNOTAR BOTTLE STORE, CNR 80  A BIRKENRUTH AND NIGEL AVE, DUNNOTTAR, DUNNOTTAR, NIGEL, South Africa','geo':{'lat':-26.34980,'lng':28.43490}},
{'stp':552825,'address':'552825, EGL - ISANDO, ISANDO EAST HYBRID, KING MAFUTA RESTAURANT, 21 NORTHERN STREET, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.41530,'lng':28.46960}},
{'stp':553544,'address':'553544, EGL - ISANDO, ISANDO EAST HYBRID, MANNYS RESTAURANT AND PUB, NORTHERN ROAD, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.41610,'lng':28.46990}},
{'stp':560215,'address':'560215, EGL - ISANDO, ISANDO EAST HYBRID, DIONISSOS LIQUOR STORE, 13 EEUFEES AVENUE, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.42110,'lng':28.47930}},
{'stp':581114,'address':'581114, EGL - ISANDO, ISANDO EAST HYBRID, BOB 40 TAVERN, 1017 KHUMALO STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37000,'lng':28.40590}},
{'stp':621438,'address':'621438, EGL - ISANDO, ISANDO EAST HYBRID, L.C SPORTS BAR (NTO), 3634 MAGALEMELE STR, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37832,'lng':28.40742}},
{'stp':627534,'address':'627534, EGL - ISANDO, ISANDO EAST HYBRID, JAMESON PARK BOTTLE STORE, 154 McLENNAN DRIVE, JAMESON PARK, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.45340,'lng':28.41910}},
{'stp':659159,'address':'659159, EGL - ISANDO, ISANDO EAST HYBRID, TOPS AT THE ANGELO, CNR RHODES & HEIDELBURG RDS, NOYCEDALE, NIGEL, NIGEL, South Africa','geo':{'lat':-26.42330,'lng':28.45870}},
{'stp':659195,'address':'659195, EGL - ISANDO, ISANDO EAST HYBRID, TOPS AT DUNOTTAR, CNR AGNEW AND NIGEL ROAD, PORTION 3 OF ERF 540, DUNNOTTAR, NIGEL, South Africa','geo':{'lat':-26.34810,'lng':28.43440}},
{'stp':691544,'address':'691544, EGL - ISANDO, ISANDO EAST HYBRID, VOCO\'S (NTO), UNIT 31, 1ST FLOOR, PG CENTRE, 52 VOORTREKKER STREET, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.49480,'lng':28.35320}},
{'stp':756668,'address':'756668, EGL - ISANDO, ISANDO EAST HYBRID, JUDGES PUB AND GRILL, STAND NO, 17 PORTER ROAD, DUNNOTTAR, DUNNOTTAR, South Africa','geo':{'lat':-26.35194,'lng':28.43977}},
{'stp':779849,'address':'779849, EGL - ISANDO, ISANDO EAST HYBRID, FERRYVALE TOPS, BEVERLEY STREET, FERRYVALE INKOOPSENTRUM, #, NIGEL, South Africa','geo':{'lat':-26.43060,'lng':28.47950}},
{'stp':308324,'address':'308324, EGL - ISANDO, ISANDO EAST MAIN, T 2 M LIQUORS, 1487 STAND NO, NDABEZITHA STREET, TZAKANE, TSAKANE, South Africa','geo':{'lat':-26.35476,'lng':28.38667}},
{'stp':318187,'address':'318187, EGL - ISANDO, ISANDO EAST MAIN, PLAZINI TAVERN, 2185 STAND NO, MATUBATUBA STREET, CNR LMIBALA BOULEVARD LANGVILLE, TSAKANE, South Africa','geo':{'lat':-26.32155,'lng':28.38644}},
{'stp':379617,'address':'379617, EGL - ISANDO, ISANDO EAST MAIN, THIZA S PLACE, 7180 STAND NO, LANGAVILLE EXT 5, #, TSAKANE, South Africa','geo':{'lat':-26.33352,'lng':28.39261}},
{'stp':390823,'address':'390823, EGL - ISANDO, ISANDO EAST MAIN, TATI S TAVERN, 2489 STAND NO, #, #, GELUKSDAL, South Africa','geo':{'lat':-26.35184,'lng':28.35349}},
{'stp':391790,'address':'391790, EGL - ISANDO, ISANDO EAST MAIN, THEMBA N SONS, 19767 STAND NO, MSIZA STREET, KWA THEMA XET 7, KWATHEMA, South Africa','geo':{'lat':-26.31652,'lng':28.39241}},
{'stp':392125,'address':'392125, EGL - ISANDO, ISANDO EAST MAIN, YFM TAVERN, 1996 ERF, IZILWANE STREET, LANGAVILLE, TSAKANE, South Africa','geo':{'lat':-26.33685,'lng':28.38999}},
{'stp':462615,'address':'462615, EGL - ISANDO, ISANDO EAST MAIN, MADISENKE LIQUOR, STAND NO, ERF 2331 MASIYANE STREET, TSAKANE BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.34500,'lng':28.39000}},
{'stp':470535,'address':'470535, EGL - ISANDO, ISANDO EAST MAIN, TJAOS TAVERN, 8677 CALATA STREET, LANGAVILLE EXT 6, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.33717,'lng':28.39563}},
{'stp':480446,'address':'480446, EGL - ISANDO, ISANDO EAST MAIN, JOHANNA S TAVERN, 6757 STAND NO, EXTENSION 5 LANGAVILLE, #, TSAKANE, South Africa','geo':{'lat':-26.29099,'lng':28.41293}},
{'stp':480871,'address':'480871, EGL - ISANDO, ISANDO EAST MAIN, MD SHEBEEN, 19744 STAND NO, CORNWELL STREET, #, KWATHEMA, South Africa','geo':{'lat':-26.31614,'lng':28.39119}},
{'stp':485933,'address':'485933, EGL - ISANDO, ISANDO EAST MAIN, N J SILUALE S TAVERN, STAND NO, ERF 1839 GRAPEFRUITCRESCENT, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.32342,'lng':28.37003}},
{'stp':550233,'address':'550233, EGL - ISANDO, ISANDO EAST MAIN, REKISA TAVERN, 4821 LANGAVILLE, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34744,'lng':28.38601}},
{'stp':552880,'address':'552880, EGL - ISANDO, ISANDO EAST MAIN, NUNUS TAVERN, 4225 SHINGA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34860,'lng':28.37840}},
{'stp':553243,'address':'553243, EGL - ISANDO, ISANDO EAST MAIN, GELUKSDAL BOTTLE STORE, 725 UITTOG STREET, GELUKSDAL, GELUKSDAL, TSAKANE, South Africa','geo':{'lat':-26.34150,'lng':28.36260}},
{'stp':553378,'address':'553378, EGL - ISANDO, ISANDO EAST MAIN, TRIPLE STAR INN, 29227 STAND NO, THULANI STREET, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36420,'lng':28.35440}},
{'stp':560557,'address':'560557, EGL - ISANDO, ISANDO EAST MAIN, JAKE\'S TAVERN, 3429 RUTH FIRST AVENUE, EXTENSION 2, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.34760,'lng':28.36330}},
{'stp':561060,'address':'561060, EGL - ISANDO, ISANDO EAST MAIN, SAURDES TAVERN, 1539 KENNETH WILLIAMS DRIVE, GELUKSDAL, GELUKSDAL, TSAKANE, South Africa','geo':{'lat':-26.34674,'lng':28.35757}},
{'stp':574710,'address':'574710, EGL - ISANDO, ISANDO EAST MAIN, PIETERSBURG TAVERN, 5328 NDABANE STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.30870,'lng':28.38490}},
{'stp':580169,'address':'580169, EGL - ISANDO, ISANDO EAST MAIN, S W TAVERN, 3973 MAGWAZA STREET, EXT 4 LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32170,'lng':28.39710}},
{'stp':580558,'address':'580558, EGL - ISANDO, ISANDO EAST MAIN, LOBISA S PLACE, 808 KHAHLWANE STREET, EXT 3 LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33010,'lng':28.38000}},
{'stp':581045,'address':'581045, EGL - ISANDO, ISANDO EAST MAIN, MONTY CARLO TAVERN, 1275 NKAY STREET,  EXT 03, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33200,'lng':28.38960}},
{'stp':581049,'address':'581049, EGL - ISANDO, ISANDO EAST MAIN, SELLOS PLACE, 2685 IRIS STREET, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.35250,'lng':28.35130}},
{'stp':581063,'address':'581063, EGL - ISANDO, ISANDO EAST MAIN, ELLIES TAVERN, 204 ROUXHANSON STREET, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.34110,'lng':28.35500}},
{'stp':581064,'address':'581064, EGL - ISANDO, ISANDO EAST MAIN, OUMAS PLACE, 1256 MARGARET STREET, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.34130,'lng':28.36860}},
{'stp':581077,'address':'581077, EGL - ISANDO, ISANDO EAST MAIN, NKUTAS TAVERN, 473 LANGAVILLE, EXT 2, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33220,'lng':28.38140}},
{'stp':602889,'address':'602889, EGL - ISANDO, ISANDO EAST MAIN, SEKWATIS PLACE, 819 HEATHI BOULEVARD, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33000,'lng':28.37860}},
{'stp':602907,'address':'602907, EGL - ISANDO, ISANDO EAST MAIN, ESTHERS PLACE, 1263 NHULAMITHI STREET, EXT 3, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33190,'lng':28.38950}},
{'stp':602948,'address':'602948, EGL - ISANDO, ISANDO EAST MAIN, MNODIS PLACE, 1078 SKOSANA STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32920,'lng':28.38690}},
{'stp':602950,'address':'602950, EGL - ISANDO, ISANDO EAST MAIN, ALL NATION TAVERN, 4759 MNISI STREET EXT 8, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.31390,'lng':28.37940}},
{'stp':602954,'address':'602954, EGL - ISANDO, ISANDO EAST MAIN, DA SUNSET TAVERN, 2893 MALANDELA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34090,'lng':28.38370}},
{'stp':603017,'address':'603017, EGL - ISANDO, ISANDO EAST MAIN, WONDERS TAVERN, 782 LANGAVILLE ROAD, IZIHLAHLA BOUL, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32980,'lng':28.38080}},
{'stp':603351,'address':'603351, EGL - ISANDO, ISANDO EAST MAIN, OLLIES TAVERN, 376 RHAUMANELA DRIVE, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.34341,'lng':28.35787}},
{'stp':603770,'address':'603770, EGL - ISANDO, ISANDO EAST MAIN, SOEKIES TAVERN, 1456 UITTOG AVENUE, GELUKSDAL, TSAKANE, GELUKSDAL, South Africa','geo':{'lat':-26.34670,'lng':28.36140}},
{'stp':603844,'address':'603844, EGL - ISANDO, ISANDO EAST MAIN, MAKOFANES TAVERN, 8551 LANGAVILLE EXT 6, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33920,'lng':28.39553}},
{'stp':603886,'address':'603886, EGL - ISANDO, ISANDO EAST MAIN, BHAKA BHAKA TAVERN, 5552 MPONGENI STR, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.30643,'lng':28.38167}},
{'stp':604975,'address':'604975, EGL - ISANDO, ISANDO EAST MAIN, JOHN\'S PLACE, 27424 AVENUE, TOKYO 30TH, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33780,'lng':28.37380}},
{'stp':605570,'address':'605570, EGL - ISANDO, ISANDO EAST MAIN, TJ TAVERN, 27097 ROLIHLALA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33960,'lng':28.37230}},
{'stp':606018,'address':'606018, EGL - ISANDO, ISANDO EAST MAIN, HALANES TAVERN, 552 MADIBA STREET, EXT 02, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33410,'lng':28.38220}},
{'stp':607117,'address':'607117, EGL - ISANDO, ISANDO EAST MAIN, MAPULES TAVERN, 417 KRISANT STREET, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.34290,'lng':28.35800}},
{'stp':607275,'address':'607275, EGL - ISANDO, ISANDO EAST MAIN, DIPEERES TUCK SHOP, 813 EXT 2 PHUMLANI STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33040,'lng':28.37960}},
{'stp':607283,'address':'607283, EGL - ISANDO, ISANDO EAST MAIN, SPACE TAVERN, 4848 FREESTATE STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.30650,'lng':28.38250}},
{'stp':625829,'address':'625829, EGL - ISANDO, ISANDO EAST MAIN, MASIBAMBANE TAVERN PERMIT, 8547 MALANDELA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34060,'lng':28.39920}},
{'stp':626105,'address':'626105, EGL - ISANDO, ISANDO EAST MAIN, MAMPURU TAVERN PERMIT, 7890 CORNWELL STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32850,'lng':28.40120}},
{'stp':627610,'address':'627610, EGL - ISANDO, ISANDO EAST MAIN, ABBYS TAVERN PERMIT, 19532 BLACK ROAD EXT 7, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.31400,'lng':28.38880}},
{'stp':627749,'address':'627749, EGL - ISANDO, ISANDO EAST MAIN, VELIS SHEBEEN PERMIT, 2441 RUTH FIRST AVENUE, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.35120,'lng':28.35060}},
{'stp':627820,'address':'627820, EGL - ISANDO, ISANDO EAST MAIN, KWAGGA INN, 11234 MADONSELA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34996,'lng':28.39036}},
{'stp':627823,'address':'627823, EGL - ISANDO, ISANDO EAST MAIN, SPHIWE TAVERN PERMIT, 2697 VIOLINA STREET, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.35210,'lng':28.35040}},
{'stp':627899,'address':'627899, EGL - ISANDO, ISANDO EAST MAIN, LUDIDIS TAVERN PERMIT, 4768 SHEZI STREET, EXT 8, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34681,'lng':28.38506}},
{'stp':628125,'address':'628125, EGL - ISANDO, ISANDO EAST MAIN, VUKUZENZELE TAVERN PERMIT, 1290 SONTWENI STREET, TSAKANE EXTENSION 8, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37491,'lng':28.36135}},
{'stp':628184,'address':'628184, EGL - ISANDO, ISANDO EAST MAIN, KHUTSONG TAVERN PERMIT, 3042 MPHAHLELE STREET EXT 3, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32290,'lng':28.38860}},
{'stp':628493,'address':'628493, EGL - ISANDO, ISANDO EAST MAIN, D SOUL FOOD LOUNGE, 482 KAPPERTJIE STREET, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.34295,'lng':28.35667}},
{'stp':628826,'address':'628826, EGL - ISANDO, ISANDO EAST MAIN, SMOKERS AND DRINKERS, 7701 MALANDELA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36452,'lng':28.34528}},
{'stp':628846,'address':'628846, EGL - ISANDO, ISANDO EAST MAIN, LINDYS INN PERMIT, 3110 MFOLOZI STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32510,'lng':28.38760}},
{'stp':629288,'address':'629288, EGL - ISANDO, ISANDO EAST MAIN, ZAKES TAVERN PERMIT, 6810 CORNWELL STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32510,'lng':28.40540}},
{'stp':629311,'address':'629311, EGL - ISANDO, ISANDO EAST MAIN, DESPERADO TAVERN PERMIT, 7160 CORNWELL STREET EXT 5,  LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32430,'lng':28.39920}},
{'stp':629617,'address':'629617, EGL - ISANDO, ISANDO EAST MAIN, T MANS PLACE PERMIT, 6391 ECHIBINI STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.31537,'lng':28.37988}},
{'stp':630165,'address':'630165, EGL - ISANDO, ISANDO EAST MAIN, PHILLYS SPORTS BAR PERMIT, 1062 LANGAVILLE, EXT 3, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32980,'lng':28.38950}},
{'stp':630166,'address':'630166, EGL - ISANDO, ISANDO EAST MAIN, EMMAS CORNER PERMIT, 569 LANGAVILLE EXT 2, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33460,'lng':28.38330}},
{'stp':630458,'address':'630458, EGL - ISANDO, ISANDO EAST MAIN, THANDIS PLACE, 20328 CORNWELL STREET, LANGAVILLE EXT 7, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31620,'lng':28.39400}},
{'stp':630505,'address':'630505, EGL - ISANDO, ISANDO EAST MAIN, TIS TAVERN PERMIT, 6249 LANGAVILLE X8 MADADENI ST, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.31732,'lng':28.38522}},
{'stp':630538,'address':'630538, EGL - ISANDO, ISANDO EAST MAIN, MARTHA HOUSE SHOP, 15951 SAMUTULI STREET, TSAKANE EXT 5, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.35930,'lng':28.35950}},
{'stp':630577,'address':'630577, EGL - ISANDO, ISANDO EAST MAIN, VUKUZENZELE PERMIT, 3362 MPHAHLELE STREET, EXT 3 LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32560,'lng':28.38910}},
{'stp':635495,'address':'635495, EGL - ISANDO, ISANDO EAST MAIN, MARYS TAVERN, 2322 MASINYANE STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34470,'lng':28.38960}},
{'stp':635903,'address':'635903, EGL - ISANDO, ISANDO EAST MAIN, NOMPUMELELO TAVERN, 2700 TOKYO STREET, EXT 1 LANGEVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32476,'lng':28.38386}},
{'stp':636932,'address':'636932, EGL - ISANDO, ISANDO EAST MAIN, DOLEZAS TAVERN, 2670 MHLOPE STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32383,'lng':28.38312}},
{'stp':638571,'address':'638571, EGL - ISANDO, ISANDO EAST MAIN, MASHAS PLACE, 5236 EASTERN CAPE STREET, EXT 8 LANGAVILLE, TSAKANE, KWATHEMA, South Africa','geo':{'lat':-26.30990,'lng':28.38590}},
{'stp':638573,'address':'638573, EGL - ISANDO, ISANDO EAST MAIN, STEVES PLACE, 614 LUNGILE STREET, EXT 1 TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.34330,'lng':28.37860}},
{'stp':642653,'address':'642653, EGL - ISANDO, ISANDO EAST MAIN, TORCH PLACE, 706 SPRANKEL CRESCENT, GELUKSDAL, BRAKPAN, GELUKSDAL, South Africa','geo':{'lat':-26.34108,'lng':28.35895}},
{'stp':642699,'address':'642699, EGL - ISANDO, ISANDO EAST MAIN, JOE\'S TAVERN, 9258 CONWELL STREET, LANGAVILLE EXT 6, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34430,'lng':28.39915}},
{'stp':646313,'address':'646313, EGL - ISANDO, ISANDO EAST MAIN, MOHLALAS INN, 3682 MVOTI STREET, LANGAVILLE EXTENSION 4, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.32910,'lng':28.39290}},
{'stp':646430,'address':'646430, EGL - ISANDO, ISANDO EAST MAIN, KGOPANES INN, 5852 NHLAPO STREET, LANGAVILLE, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.31260,'lng':28.38360}},
{'stp':647167,'address':'647167, EGL - ISANDO, ISANDO EAST MAIN, MATSATSELA, 2569 MTHUBI STREET EXT 1, LANGAVILLE, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.32400,'lng':28.38190}},
{'stp':647595,'address':'647595, EGL - ISANDO, ISANDO EAST MAIN, PICREB KWA THEMA, CNR VLAKFONTEIN & NDABEZITHA RD, KWA THEMA SHOPPING CENTRE, KWA THEMA, KWATHEMA, South Africa','geo':{'lat':-26.31270,'lng':28.38950}},
{'stp':648286,'address':'648286, EGL - ISANDO, ISANDO EAST MAIN, PINKY\'S PUB, 611 MODISA STREET, EXTENSION 1, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34280,'lng':28.37920}},
{'stp':648607,'address':'648607, EGL - ISANDO, ISANDO EAST MAIN, MALINGA S, 27491 ROLIHLAHA AVENUE, EXT 12A, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.33710,'lng':28.37880}},
{'stp':649380,'address':'649380, EGL - ISANDO, ISANDO EAST MAIN, MPUMELELO TAVERN, 6101 DUDUDU STREET, LANGAVILLE PROPER EXT 8, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.31560,'lng':28.38400}},
{'stp':649558,'address':'649558, EGL - ISANDO, ISANDO EAST MAIN, MM TUCKSHOP, 5873 MOTHIBEDI STR, EXT 8, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.31390,'lng':28.38380}},
{'stp':650082,'address':'650082, EGL - ISANDO, ISANDO EAST MAIN, DAVIDS PLACE, 3152 SHOSHOLOZA STREET, GELUKSDAL EXTENSION 2, BRAKPAN, GELUKSDAL, South Africa','geo':{'lat':-26.35040,'lng':28.35600}},
{'stp':650083,'address':'650083, EGL - ISANDO, ISANDO EAST MAIN, NORMANS PLACE, 342 SPRANKLE SINGLE, GELUKSDAL, BRAKPAN, GELUKSDAL, South Africa','geo':{'lat':-26.34110,'lng':28.35900}},
{'stp':652979,'address':'652979, EGL - ISANDO, ISANDO EAST MAIN, DALLAS INN, 1688 MTHINYA STREET, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.35489,'lng':28.38601}},
{'stp':654155,'address':'654155, EGL - ISANDO, ISANDO EAST MAIN, SDUMO TAVERN, 1565 NKOSANA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35290,'lng':28.38510}},
{'stp':655534,'address':'655534, EGL - ISANDO, ISANDO EAST MAIN, BABINANOKO TAVERN, ERF NO J01 0050016029, EXTENSION 5, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35970,'lng':28.36020}},
{'stp':660583,'address':'660583, EGL - ISANDO, ISANDO EAST MAIN, PENNYS PLACE, 864 REENBUI STREET, GELUKSDAL, BRAKPAN, GELUKSDAL, South Africa','geo':{'lat':-26.34260,'lng':28.36550}},
{'stp':687859,'address':'687859, EGL - ISANDO, ISANDO EAST MAIN, BOLEKE TAVERN, 1364 SILANGWE STREET, EXT 3, LANGAVILLE, TSAKANE, South Africa','geo':{'lat':-26.33390,'lng':28.38950}},
{'stp':720490,'address':'720490, EGL - ISANDO, ISANDO EAST MAIN, VHAVENDANI TAVERN, 4042 EXT 4 KWA MAGWAZA, LANGAVILLE, #, TSAKANE, South Africa','geo':{'lat':-26.32230,'lng':28.39730}},
{'stp':723209,'address':'723209, EGL - ISANDO, ISANDO EAST MAIN, COSY CORNER DISKOTEEK, WINKELS 2 AND 3, GEDEELTE 6 VAN ERF 725, #, GELUKSDAL, South Africa','geo':{'lat':-26.34210,'lng':28.36269}},
{'stp':728771,'address':'728771, EGL - ISANDO, ISANDO EAST MAIN, MHLANGA JOSEPHINA MATSILISI, C 4444 EXT 6, LANGAVILLE SECTION, #, TSAKANE, South Africa','geo':{'lat':-26.34460,'lng':28.39490}},
{'stp':734996,'address':'734996, EGL - ISANDO, ISANDO EAST MAIN, BAFANA BOXOLO TAVERN AND LIQUOR DIS, 1660 POGOGO STREET, LANGAVILLE, EXT 3, TSAKANE, South Africa','geo':{'lat':-26.33510,'lng':28.39170}},
{'stp':757023,'address':'757023, EGL - ISANDO, ISANDO EAST MAIN, B AND J TAVERN, STAND NO, ERF 5086 ITSHEHLOPE STREET, LANGAVILLE EXT 8, TSAKANE, South Africa','geo':{'lat':-26.30990,'lng':28.38240}},
{'stp':757998,'address':'757998, EGL - ISANDO, ISANDO EAST MAIN, JANE TAVERN, 531 STAND NO, HLAHLAWU STREET LANGAVILLE TSAKANE, #, TSAKANE, South Africa','geo':{'lat':-26.33348,'lng':28.38213}},
{'stp':765763,'address':'765763, EGL - ISANDO, ISANDO EAST MAIN, EKUTHULENI TAVERN, 7484 NDLOVU STREET, TSAKANE, #, TSAKANE, South Africa','geo':{'lat':-26.35024,'lng':28.38590}},
{'stp':769138,'address':'769138, EGL - ISANDO, ISANDO EAST MAIN, ISIYALU BEVERAGES, STAND NO, ERF 27250 CNR  NKOSAWA & CENGIMBO, STREET LANGAVILLE TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.31840,'lng':28.39350}},
{'stp':778025,'address':'778025, EGL - ISANDO, ISANDO EAST MAIN, PNR TAVERN, 2434 HLAZA STREET EXT 1, #, #, TSAKANE, South Africa','geo':{'lat':-26.32250,'lng':28.38135}},
{'stp':782773,'address':'782773, EGL - ISANDO, ISANDO EAST MAIN, RUBYS TAVERN, STAND NO, ERF 27264 SECOND AVENUE, TSAKANE EXT 12, TSAKANE, South Africa','geo':{'lat':-26.33968,'lng':28.37230}},
{'stp':784527,'address':'784527, EGL - ISANDO, ISANDO EAST MAIN, MPHOS TAVERN, 4936 STAND NO, MHLONGO STREET  EXT 8, #, TSAKANE, South Africa','geo':{'lat':-26.30782,'lng':28.38198}},
{'stp':785834,'address':'785834, EGL - ISANDO, ISANDO EAST MAIN, NOVELAS TAVERN, MASHININI STREET, ERF 1586 EXT 8, #, TSAKANE, South Africa','geo':{'lat':-26.37700,'lng':28.35700}},
{'stp':315564,'address':'315564, EGL - ISANDO, ISANDO EAST MAIN, MABALENG INN, 1194 STAND NO, PADI AND RAMPETE STREET, #, WATTVILLE, South Africa','geo':{'lat':-26.22531,'lng':28.29778}},
{'stp':316150,'address':'316150, EGL - ISANDO, ISANDO EAST MAIN, KGABS BOTTLE STORE, 12 STAND NO, LIBYA, SLOVO PARK, KWATHEMA, South Africa','geo':{'lat':-26.20986,'lng':28.48591}},
{'stp':388428,'address':'388428, EGL - ISANDO, ISANDO EAST MAIN, SHAKU S PLACE, 3262 ERF NO, MASETLOA STREET, WATVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21925,'lng':28.31082}},
{'stp':388776,'address':'388776, EGL - ISANDO, ISANDO EAST MAIN, NCUBES TAVERN, 555 ERF NO, NHLANHLA STREET, #, DAVEYTON, South Africa','geo':{'lat':-26.18784,'lng':28.39322}},
{'stp':391472,'address':'391472, EGL - ISANDO, ISANDO EAST MAIN, MSAWAWA S TAVERN, 422 STAND NO, BOSHIELO WATTVILE, #, WATTVILLE, South Africa','geo':{'lat':-26.23006,'lng':28.30453}},
{'stp':393048,'address':'393048, EGL - ISANDO, ISANDO EAST MAIN, E LIQUOR EXPRESS SPRINGS, 529 ERF, SHOP 3A 22 FOURTH AVENUE, #, SPRINGS, South Africa','geo':{'lat':-26.25292,'lng':28.44062}},
{'stp':460518,'address':'460518, EGL - ISANDO, ISANDO EAST MAIN, THULIES PLACE, 867 ERF, MIKE STREET TABBOVILLE EXT 2, #, WATTVILLE, South Africa','geo':{'lat':-26.22829,'lng':28.29767}},
{'stp':464579,'address':'464579, EGL - ISANDO, ISANDO EAST MAIN, VINTAGE BOTTLE STORE, 1941 STAND NO, MVULANI STREET, WATTVILE, WATTVILLE, South Africa','geo':{'lat':-26.22824,'lng':28.30259}},
{'stp':468047,'address':'468047, EGL - ISANDO, ISANDO EAST MAIN, NORRIS TAVERN, 1839 STAND NO, DURBAN DRIVE, SLOVO PARK, SPRINGS, South Africa','geo':{'lat':-26.21285,'lng':28.48950}},
{'stp':471066,'address':'471066, EGL - ISANDO, ISANDO EAST MAIN, JAPHTAS TAVERN, ABIE NYALUNA STREET, TABMOVILLE, ERF 284 WATVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23179,'lng':28.30236}},
{'stp':482128,'address':'482128, EGL - ISANDO, ISANDO EAST MAIN, SMALL TAVERN, 2669 STAND NO, NTSANE STREET, WATVTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22811,'lng':28.30246}},
{'stp':486339,'address':'486339, EGL - ISANDO, ISANDO EAST MAIN, NGWANE LIQUOR STORE, 925 ERF, NKOSI STREET, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22202,'lng':28.29692}},
{'stp':488076,'address':'488076, EGL - ISANDO, ISANDO EAST MAIN, BEFORE SUNSET TRADING AND PROJECTS, 2542 STAND NO 000 , ERF, JANNGU STREET, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22739,'lng':28.30219}},
{'stp':550221,'address':'550221, EGL - ISANDO, ISANDO EAST MAIN, TSHWALA LIQUOR STORE, CNR SINGH & LONTON STREET, ACTONVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21008,'lng':28.30136}},
{'stp':550533,'address':'550533, EGL - ISANDO, ISANDO EAST MAIN, SNACK BAR RESTAURANT, 218 VAN DYK STREET, BOKSBURG, BOKSBURG, WATTVILLE, South Africa','geo':{'lat':-26.21330,'lng':28.28650}},
{'stp':550604,'address':'550604, EGL - ISANDO, ISANDO EAST MAIN, WATTVILLE RESTAURANT, 91 READING RD, WATTVILLE BEER HALL, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21060,'lng':28.30010}},
{'stp':550919,'address':'550919, EGL - ISANDO, ISANDO EAST MAIN, MASHILE LIQUOR STORE, 2454B RADEBE STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22230,'lng':28.31010}},
{'stp':552052,'address':'552052, EGL - ISANDO, ISANDO EAST MAIN, OASIS RESTAURANT AND PUB, 519 MAYET DRIVE, ACTONVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21718,'lng':28.29973}},
{'stp':552325,'address':'552325, EGL - ISANDO, ISANDO EAST MAIN, SISONKE LIQUOR STORE, 727 1 KAHN CRESCENT CORNER SOMA, ACTONVILLE, #, WATTVILLE, South Africa','geo':{'lat':-26.21340,'lng':28.30120}},
{'stp':552700,'address':'552700, EGL - ISANDO, ISANDO EAST MAIN, JAZZIE INN, 499 MDAKA STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21750,'lng':28.29330}},
{'stp':553170,'address':'553170, EGL - ISANDO, ISANDO EAST MAIN, ACTONVILLE BAR AND BOTTLE STORE, 441 BIRMINGHAM ROAD, ACTONVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.20990,'lng':28.30800}},
{'stp':553358,'address':'553358, EGL - ISANDO, ISANDO EAST MAIN, JULY S RESTAURANT AND TAVERN, 533 JIVAN STREET, ACTONVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21800,'lng':28.29970}},
{'stp':553746,'address':'553746, EGL - ISANDO, ISANDO EAST MAIN, ZOZO TAVERN, 345 DLOMO STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21680,'lng':28.29280}},
{'stp':553812,'address':'553812, EGL - ISANDO, ISANDO EAST MAIN, FULL MOON, 253 ERF NO, MDAKA AVENUE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21690,'lng':28.29480}},
{'stp':553873,'address':'553873, EGL - ISANDO, ISANDO EAST MAIN, LAPENG TAVERN, 2653 MAMKELE STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22930,'lng':28.30180}},
{'stp':560471,'address':'560471, EGL - ISANDO, ISANDO EAST MAIN, WATSON DOY SELEKA, 359 DLHOMO STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21610,'lng':28.29220}},
{'stp':561300,'address':'561300, EGL - ISANDO, ISANDO EAST MAIN, SKUTUKUTU, 346 ABIE NYALUNGA STREET, TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23290,'lng':28.30530}},
{'stp':581127,'address':'581127, EGL - ISANDO, ISANDO EAST MAIN, METRO LIQUOR OUTLET, 115 READING ROAD, ACTONVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21263,'lng':28.29706}},
{'stp':602856,'address':'602856, EGL - ISANDO, ISANDO EAST MAIN, NCUBE TAVERN, 555 INHLANHLA STREET, KINGSWAY, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.18700,'lng':28.39300}},
{'stp':602859,'address':'602859, EGL - ISANDO, ISANDO EAST MAIN, S A PLACE PERMIT, 544 WCRC STREET, TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23210,'lng':28.30410}},
{'stp':603376,'address':'603376, EGL - ISANDO, ISANDO EAST MAIN, TWO FOR JOY, 1925 BEKE STREET, KINGSWAY, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.19120,'lng':28.39630}},
{'stp':603848,'address':'603848, EGL - ISANDO, ISANDO EAST MAIN, LOVE CORNER, STAND1991 WELGADACHT, EXT 1 SLOVO PARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.21600,'lng':28.48930}},
{'stp':603872,'address':'603872, EGL - ISANDO, ISANDO EAST MAIN, UNCLE EDDIES PLACE, 457 MNADI STREET, KINGSWAY, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.18760,'lng':28.39120}},
{'stp':604818,'address':'604818, EGL - ISANDO, ISANDO EAST MAIN, JIKA JOE, BLOCK B 156 LINDELANI, BRAKPAN, BRAKPAN, DAVEYTON, South Africa','geo':{'lat':-26.18767,'lng':28.40741}},
{'stp':606467,'address':'606467, EGL - ISANDO, ISANDO EAST MAIN, MATSWIRITSWIRI, 972 BENXA STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22180,'lng':28.29520}},
{'stp':626643,'address':'626643, EGL - ISANDO, ISANDO EAST MAIN, SAMS SPAZA SHOP PERMIT, 1895 BHEKA STREET, KINGSWAY, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.19030,'lng':28.39270}},
{'stp':628324,'address':'628324, EGL - ISANDO, ISANDO EAST MAIN, VIVIANS PLACE PERMIT, 174 KEKANE STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21490,'lng':28.29420}},
{'stp':628507,'address':'628507, EGL - ISANDO, ISANDO EAST MAIN, ZODWAS PLACE PERMIT, 3181/2 XUMA STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22090,'lng':28.30390}},
{'stp':629277,'address':'629277, EGL - ISANDO, ISANDO EAST MAIN, BETTYS TAVERN PERMIT, 414 KATRADA STREET, TAMBOVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.23220,'lng':28.30590}},
{'stp':629287,'address':'629287, EGL - ISANDO, ISANDO EAST MAIN, EUNICE SHEBEEN PERMIT, 882 MIKE STREET, EXT 2,  TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22930,'lng':28.29730}},
{'stp':629366,'address':'629366, EGL - ISANDO, ISANDO EAST MAIN, JORDAN TAVERN PERMIT, 548 VICTOR MKWAMBA STREET, TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23110,'lng':28.30230}},
{'stp':629564,'address':'629564, EGL - ISANDO, ISANDO EAST MAIN, PRETORIA INN PERMIT, 1369 ZWELITHINI ROAD, PAYNEVILLE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.23150,'lng':28.46220}},
{'stp':629585,'address':'629585, EGL - ISANDO, ISANDO EAST MAIN, WONDERS INN PERMIT, 1264 RAMPHETHE STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22450,'lng':28.29810}},
{'stp':629889,'address':'629889, EGL - ISANDO, ISANDO EAST MAIN, GOLDEN BRAAI TUCKSHOP PERMIT, 6 CHETWAYO STREET, PAYNEVILLE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.22770,'lng':28.46310}},
{'stp':630713,'address':'630713, EGL - ISANDO, ISANDO EAST MAIN, MAMAS, 1535 VILAKAZI STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22530,'lng':28.30150}},
{'stp':630828,'address':'630828, EGL - ISANDO, ISANDO EAST MAIN, TSALANANG, 2201 INYOKA STREET, KINGSWAY, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.19160,'lng':28.39600}},
{'stp':631048,'address':'631048, EGL - ISANDO, ISANDO EAST MAIN, EVENTS, 39  MAGODUSHU ROAD, SLOVOPARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.20751,'lng':28.48384}},
{'stp':631061,'address':'631061, EGL - ISANDO, ISANDO EAST MAIN, BIG BROTHERS PLACE, 3635 MOSALA STR, TAMBOVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.23010,'lng':28.30080}},
{'stp':631214,'address':'631214, EGL - ISANDO, ISANDO EAST MAIN, SLAHLA TAVERN, 201 LOUISE STREET, TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23409,'lng':28.30647}},
{'stp':631220,'address':'631220, EGL - ISANDO, ISANDO EAST MAIN, CHINAS PLACE, 155 SEBOTSANE STREET, TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23360,'lng':28.30700}},
{'stp':635669,'address':'635669, EGL - ISANDO, ISANDO EAST MAIN, LUKS STORES,  157 SEBOTSANA STR, TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23337,'lng':28.30695}},
{'stp':635828,'address':'635828, EGL - ISANDO, ISANDO EAST MAIN, MAKIES TAVERN, 232 TOSS NGILIMA STREET, TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23360,'lng':28.30560}},
{'stp':636183,'address':'636183, EGL - ISANDO, ISANDO EAST MAIN, MKHWEBANE\'S TAVERN, 1269 RAKELE STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22405,'lng':28.29867}},
{'stp':636547,'address':'636547, EGL - ISANDO, ISANDO EAST MAIN, NUMBER 2, 3003 ERF NO, MAMKELE STREET, WATTVILLE TOWNSHIP, WATTVILLE, South Africa','geo':{'lat':-26.22940,'lng':28.30460}},
{'stp':636583,'address':'636583, EGL - ISANDO, ISANDO EAST MAIN, RUTHS PLACE, 437 BALOYI STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21641,'lng':28.29070}},
{'stp':636933,'address':'636933, EGL - ISANDO, ISANDO EAST MAIN, ZAMA ZAMA TUCKSHOP, NO 7 HARARE STREET SLOVO PARK, WELGEDAGHT, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.20890,'lng':28.48260}},
{'stp':636987,'address':'636987, EGL - ISANDO, ISANDO EAST MAIN, LALAS TAVERN, 1726 LONDON AVE, EXT 1 WELGEDACHT, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.21250,'lng':28.48650}},
{'stp':637408,'address':'637408, EGL - ISANDO, ISANDO EAST MAIN, OUPAS SHEBEEN, 1630 ZINA STREET, WATTVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.22440,'lng':28.30168}},
{'stp':637681,'address':'637681, EGL - ISANDO, ISANDO EAST MAIN, NCHAPIS INN, 112 KATHRIDAR STREET, TAMBOVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23110,'lng':28.30600}},
{'stp':639304,'address':'639304, EGL - ISANDO, ISANDO EAST MAIN, BUDAS TAVERN, G149 LINDELANI, KINGSWAY, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.19090,'lng':28.40160}},
{'stp':639937,'address':'639937, EGL - ISANDO, ISANDO EAST MAIN, MORABAS TAVERN, 102 BOIKHUTSO STREET, WATTVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.21510,'lng':28.29070}},
{'stp':640955,'address':'640955, EGL - ISANDO, ISANDO EAST MAIN, NELLINAS TAVERN, 769 HLAHLE STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22386,'lng':28.30089}},
{'stp':641607,'address':'641607, EGL - ISANDO, ISANDO EAST MAIN, MASAKHANE SHEBEEN, 97 ISIBUSISO STREET, KINGSWAY, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.18660,'lng':28.39610}},
{'stp':642602,'address':'642602, EGL - ISANDO, ISANDO EAST MAIN, SISIS PLACE, 565 DLAMINI STREET, WATTVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.22060,'lng':28.29490}},
{'stp':648220,'address':'648220, EGL - ISANDO, ISANDO EAST MAIN, JOANA\'S SHEBEEN, 3736 LAKEVIEW, WATTVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.22430,'lng':28.30980}},
{'stp':648772,'address':'648772, EGL - ISANDO, ISANDO EAST MAIN, WALTER\'S INN, 1877 TULELA STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22290,'lng':28.30128}},
{'stp':648774,'address':'648774, EGL - ISANDO, ISANDO EAST MAIN, SANCHO\'S INN, 229 GOLLA STREET, WATTVILLE TOWNSHIP, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21606,'lng':28.29432}},
{'stp':648845,'address':'648845, EGL - ISANDO, ISANDO EAST MAIN, MARTHA S TAVERN, 21 KEKANE STREET, WATTVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.21370,'lng':28.29280}},
{'stp':649085,'address':'649085, EGL - ISANDO, ISANDO EAST MAIN, ZEROS PLACE, 2705 NTSANE STREET, WATTVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.22830,'lng':28.30260}},
{'stp':649250,'address':'649250, EGL - ISANDO, ISANDO EAST MAIN, MALINDI\'S PLACE, 3215 KHUMALO STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22050,'lng':28.30550}},
{'stp':649348,'address':'649348, EGL - ISANDO, ISANDO EAST MAIN, DOROTHY S PLACE, 1444 TLADI STREET, WATTVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.22510,'lng':28.30070}},
{'stp':649401,'address':'649401, EGL - ISANDO, ISANDO EAST MAIN, STOPIE\'S INN, 1387 THAKEDI STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22593,'lng':28.30054}},
{'stp':649550,'address':'649550, EGL - ISANDO, ISANDO EAST MAIN, MATU\'S PLACE, 1466 VILAKAZI STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22610,'lng':28.30170}},
{'stp':649556,'address':'649556, EGL - ISANDO, ISANDO EAST MAIN, MAJOZI\'S PLACE, 1662 DUBE STREET 1662, WATTVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.22340,'lng':28.30120}},
{'stp':649767,'address':'649767, EGL - ISANDO, ISANDO EAST MAIN, MAKIS INN, 1497 WEYI STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22544,'lng':28.30414}},
{'stp':651764,'address':'651764, EGL - ISANDO, ISANDO EAST MAIN, MAGUGESTARS PLACE, 1128 AMABOKOBOKO ROAD, PAYNEVILLE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.22726,'lng':28.46239}},
{'stp':656689,'address':'656689, EGL - ISANDO, ISANDO EAST MAIN, SUNSHINE TAVERN (NTO), 564 FAZEL STREET, ACTONVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21293,'lng':28.29777}},
{'stp':659048,'address':'659048, EGL - ISANDO, ISANDO EAST MAIN, ELIA S TUCKSHOP, 141 SIBISI STREET, TAMBOVILLE, BENONI, WATTVILLE, South Africa','geo':{'lat':-26.23300,'lng':28.30740}},
{'stp':659345,'address':'659345, EGL - ISANDO, ISANDO EAST MAIN, LULU\'S PLACE, 20 MARY MOODLEY STREET, ERF 221, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23050,'lng':28.30724}},
{'stp':659755,'address':'659755, EGL - ISANDO, ISANDO EAST MAIN, TELAS PLACE, 2980 MATLAISANE STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22850,'lng':28.30650}},
{'stp':661099,'address':'661099, EGL - ISANDO, ISANDO EAST MAIN, SIMELANES TUCKSHOP, 3050 SIGA STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22605,'lng':28.30241}},
{'stp':662553,'address':'662553, EGL - ISANDO, ISANDO EAST MAIN, THEMBIS TAVERN BIGHOUSE, 532 MASOLENG AVENUE, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21910,'lng':28.29300}},
{'stp':663680,'address':'663680, EGL - ISANDO, ISANDO EAST MAIN, ANNAS TAVERN, 0 749 JABAVU STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22033,'lng':28.29837}},
{'stp':682580,'address':'682580, EGL - ISANDO, ISANDO EAST MAIN, GEYERS STOP AND SHOP, ERF 491, ACTONVILLE EXT 2, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21711,'lng':28.29843}},
{'stp':684764,'address':'684764, EGL - ISANDO, ISANDO EAST MAIN, MAZET SHEBEEN, 3567 BENCE STREET, WATTVILLE, #, WATTVILLE, South Africa','geo':{'lat':-26.22240,'lng':28.29560}},
{'stp':685022,'address':'685022, EGL - ISANDO, ISANDO EAST MAIN, PANICS PLACE, 2393 SEREMA STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22210,'lng':28.31000}},
{'stp':691347,'address':'691347, EGL - ISANDO, ISANDO EAST MAIN, PATS SHEBEEN, 557 DLAMINI STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22030,'lng':28.29450}},
{'stp':699963,'address':'699963, EGL - ISANDO, ISANDO EAST MAIN, TSHEPOS TAVERN, 3312 STAND NO, EXT 1, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.21930,'lng':28.31000}},
{'stp':718828,'address':'718828, EGL - ISANDO, ISANDO EAST MAIN, MAKOLANE PLACE, 3496 P P MAKHUBU STREET, WATTVILLE, #, WATTVILLE, South Africa','geo':{'lat':-26.22150,'lng':28.30430}},
{'stp':744857,'address':'744857, EGL - ISANDO, ISANDO EAST MAIN, DINNER TAVERN, 1896 THULELA STREET, WATTVILLE, DAVEYTON, WATTVILLE, South Africa','geo':{'lat':-26.22230,'lng':28.30150}},
{'stp':747314,'address':'747314, EGL - ISANDO, ISANDO EAST MAIN, KWA NUNUS PLACE, 3577 BENXA STREET, WATTVILLE, #, WATTVILLE, South Africa','geo':{'lat':-26.22200,'lng':28.29500}},
{'stp':747766,'address':'747766, EGL - ISANDO, ISANDO EAST MAIN, BANDIDOS CAFE TAVERN, 2359 SEREMA STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22230,'lng':28.30970}},
{'stp':752634,'address':'752634, EGL - ISANDO, ISANDO EAST MAIN, MTHANDENI TAVERN, 268 STAND NO, ABIE NYALUNGU STRET TAMBOVILLE WATTVILLE, #, WATTVILLE, South Africa','geo':{'lat':-26.23230,'lng':28.30370}},
{'stp':757038,'address':'757038, EGL - ISANDO, ISANDO EAST MAIN, THIKEZA JAZZ INN, 1696 INDLOVU STREET, KINGS WAY, #, DAVEYTON, South Africa','geo':{'lat':-26.19110,'lng':28.38890}},
{'stp':757987,'address':'757987, EGL - ISANDO, ISANDO EAST MAIN, JOES SHEBEEN, 724 GEORGE MAPHOSA STREET, TAMBOVILLE EXT 1, #, WATTVILLE, South Africa','geo':{'lat':-26.22994,'lng':28.29938}},
{'stp':759016,'address':'759016, EGL - ISANDO, ISANDO EAST MAIN, SKOPIS TAVERN, GEOERGE MAPHOSA STREET, ERF 731 EXTENSION 1, TAMBOVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22995,'lng':28.29943}},
{'stp':770367,'address':'770367, EGL - ISANDO, ISANDO EAST MAIN, HETTY S TAVERN, 209 GOLLA STREET,  WATTVILLE, #, WATTVILLE, South Africa','geo':{'lat':-26.21655,'lng':28.29539}},
{'stp':782872,'address':'782872, EGL - ISANDO, ISANDO EAST MAIN, SOLLY S TAVERN, 388 STAND NO, UKUTHULA STREET KINGSWAY DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.18852,'lng':28.39001}},
{'stp':784329,'address':'784329, EGL - ISANDO, ISANDO EAST MAIN, SDN TAVERN, 2252 LEKWAPA STREET, WATTVILLE, #, WATTVILLE, South Africa','geo':{'lat':-26.22372,'lng':28.30113}},
{'stp':787112,'address':'787112, EGL - ISANDO, ISANDO EAST MAIN, SINDIS PLACE, STAND NO, ERF 118 NOMSA STREET, KINGSWAY TOWNSHIP, DAVEYTON, South Africa','geo':{'lat':-26.18151,'lng':28.39590}},
{'stp':793373,'address':'793373, EGL - ISANDO, ISANDO EAST MAIN, TITANIC CLUB, 2269 ERF, MAKGATHO STREET, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22160,'lng':28.30825}},
{'stp':793728,'address':'793728, EGL - ISANDO, ISANDO EAST MAIN, TZOZO S TAVERN, NHLAPO STREET, PORTION 29 OF ERF 3551, #, WATTVILLE, South Africa','geo':{'lat':-26.22269,'lng':28.30405}},
{'stp':793801,'address':'793801, EGL - ISANDO, ISANDO EAST MAIN, COXY CORNER, SECOND STREET, ERF 1373, #, SPRINGS, South Africa','geo':{'lat':-26.22253,'lng':28.46778}},
{'stp':793998,'address':'793998, EGL - ISANDO, ISANDO EAST MAIN, SLEDGES PLACE, 686 STAND NO, MAKATINI AVENUE, WATEVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22391,'lng':28.30285}},
{'stp':797270,'address':'797270, EGL - ISANDO, ISANDO EAST MAIN, VINTAGE PUB AND RESTAURANT, STAND NO, ERF 1941 MVULANE STREET WATTVILLE, #, WATTVILLE, South Africa','geo':{'lat':-26.22308,'lng':28.30344}},
{'stp':389664,'address':'389664, EGL - ISANDO, ISANDO EAST MAIN, INKUNZI BEER HALL, 9909 STAND NO, RAMPELA STREET KWA THEMA, #, KWATHEMA, South Africa','geo':{'lat':-26.31035,'lng':28.40306}},
{'stp':390702,'address':'390702, EGL - ISANDO, ISANDO EAST MAIN, MA T TAVERN, ERF NO 3 NTSANI STREET, #, #, KWATHEMA, South Africa','geo':{'lat':-26.29252,'lng':28.38973}},
{'stp':392226,'address':'392226, EGL - ISANDO, ISANDO EAST MAIN, BLOMANA T, 8922 ERF NO,26 MARULE STREET,KWA TH, #, #, KWATHEMA, South Africa','geo':{'lat':-26.30592,'lng':28.40286}},
{'stp':392719,'address':'392719, EGL - ISANDO, ISANDO EAST MAIN, SAVOYI LIQUOR STORE, 31 NO., DLADLA STREET, KWA THEMA, KWATHEMA, South Africa','geo':{'lat':-26.28623,'lng':28.39615}},
{'stp':463066,'address':'463066, EGL - ISANDO, ISANDO EAST MAIN, PERCY MIKE LIQUORS, 1 MONYANE STREET, MASIMENI SECTION, KWA THEMA, KWATHEMA, South Africa','geo':{'lat':-26.30153,'lng':28.39775}},
{'stp':472944,'address':'472944, EGL - ISANDO, ISANDO EAST MAIN, ALFAS TAVERN, 9336 STAND NO,  20 RAMPELA STREET, REST IN PEACE SECTION, KWATHEMA, South Africa','geo':{'lat':-26.31046,'lng':28.40293}},
{'stp':482348,'address':'482348, EGL - ISANDO, ISANDO EAST MAIN, BAKWENA S TAVERN, 14054 STAND NO, MOREKU, STREET, KWATHEMA, South Africa','geo':{'lat':-26.31186,'lng':28.41695}},
{'stp':549389,'address':'549389, EGL - ISANDO, ISANDO EAST MAIN, NKWEZI TAVERN, STAND NO, 42 LEGODI STREET, KWATHEMBA, KWATHEMA, South Africa','geo':{'lat':-26.30683,'lng':28.39333}},
{'stp':550330,'address':'550330, EGL - ISANDO, ISANDO EAST MAIN, MAROTHIS TAVERN, 68 RAMOTHIBE STREET, KWATHEMA, SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.29880,'lng':28.39840}},
{'stp':550816,'address':'550816, EGL - ISANDO, ISANDO EAST MAIN, JOSEPHINES TAVERN PERMIT, 38 MOTSEGOA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30140,'lng':28.38600}},
{'stp':551287,'address':'551287, EGL - ISANDO, ISANDO EAST MAIN, CARO S TAVERN, 6 TSHEFU STREET, KWATHEMA, SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.30148,'lng':28.39216}},
{'stp':551698,'address':'551698, EGL - ISANDO, ISANDO EAST MAIN, LADY J TAVERN, 31 MAPHANYA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29886,'lng':28.38641}},
{'stp':551818,'address':'551818, EGL - ISANDO, ISANDO EAST MAIN, CLIFFMARG TAVERN, 14475 SETHUSE STREET EXT 2, KWATHEMA, SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.31500,'lng':28.41630}},
{'stp':551867,'address':'551867, EGL - ISANDO, ISANDO EAST MAIN, SHEPERD S LIQUOR STORE, 13101 MUKENTA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31277,'lng':28.41056}},
{'stp':552479,'address':'552479, EGL - ISANDO, ISANDO EAST MAIN, REST IN PEACE BOTTLE STORE, 13130 RAMPHELE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31043,'lng':28.40349}},
{'stp':552502,'address':'552502, EGL - ISANDO, ISANDO EAST MAIN, MAKSAL TAVERN PERMIT, 39 TSUTSUBE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29300,'lng':28.39680}},
{'stp':552704,'address':'552704, EGL - ISANDO, ISANDO EAST MAIN, BEN MARE TAVERN, 62 NYAWENI STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29942,'lng':28.40010}},
{'stp':552889,'address':'552889, EGL - ISANDO, ISANDO EAST MAIN, BAVARIA TAVERN, 10 MOTSEPE STREET, KWATHEMA, SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.28980,'lng':28.38640}},
{'stp':553029,'address':'553029, EGL - ISANDO, ISANDO EAST MAIN, LIONS DENS TAVERN, 402 INTERLAND, EXT 1, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29780,'lng':28.40800}},
{'stp':553617,'address':'553617, EGL - ISANDO, ISANDO EAST MAIN, SHIRLEY SCOTT TAVERN, 11 MTETWA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31000,'lng':28.39670}},
{'stp':553662,'address':'553662, EGL - ISANDO, ISANDO EAST MAIN, MIMIS TAVERN PERMIT, 51 KODISANG STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29563,'lng':28.39619}},
{'stp':553839,'address':'553839, EGL - ISANDO, ISANDO EAST MAIN, CONNIES CORNER - PERMIT, 12200 PHAKATHI STREET EXT 4, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28440,'lng':28.40530}},
{'stp':560256,'address':'560256, EGL - ISANDO, ISANDO EAST MAIN, SOWETO TAVERN - PERMIT, 10143 MONARENG STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29170,'lng':28.40010}},
{'stp':560352,'address':'560352, EGL - ISANDO, ISANDO EAST MAIN, MC QUEENS TAVERN, 12037 NDUNGOANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28480,'lng':28.40810}},
{'stp':561064,'address':'561064, EGL - ISANDO, ISANDO EAST MAIN, KULIS TAVERN, 12315 MFELANG STREET, EXTENSION 4, PHASE 2, KWATHEMA, South Africa','geo':{'lat':-26.29400,'lng':28.41260}},
{'stp':561128,'address':'561128, EGL - ISANDO, ISANDO EAST MAIN, MA OLA TAVERN, 21839 KOPE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31970,'lng':28.40680}},
{'stp':580886,'address':'580886, EGL - ISANDO, ISANDO EAST MAIN, BACK OF THE MOON PERMIT, 7009 RAMPELA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30990,'lng':28.40330}},
{'stp':580888,'address':'580888, EGL - ISANDO, ISANDO EAST MAIN, SAKIES TAVERN, 19388 MOAGI STREET, EXT 6, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31320,'lng':28.39570}},
{'stp':580890,'address':'580890, EGL - ISANDO, ISANDO EAST MAIN, SOMDYE PLACE, 29 MORE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28980,'lng':28.38870}},
{'stp':580891,'address':'580891, EGL - ISANDO, ISANDO EAST MAIN, MANZO TAVERN, 10A MMUSANE STREET, WHITE CITY, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30490,'lng':28.38850}},
{'stp':580894,'address':'580894, EGL - ISANDO, ISANDO EAST MAIN, HAZYVIE TAVERN, 38 PHUTGEAGAE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30860,'lng':28.39830}},
{'stp':580896,'address':'580896, EGL - ISANDO, ISANDO EAST MAIN, MASEKO TAVERN, 25732 BARWA STREET, EXT 2, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31560,'lng':28.41790}},
{'stp':580992,'address':'580992, EGL - ISANDO, ISANDO EAST MAIN, PETERS GAZZ HOUSE, 227 SAM NGEMA DRIVE, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29480,'lng':28.41160}},
{'stp':580993,'address':'580993, EGL - ISANDO, ISANDO EAST MAIN, BUSHYS TAVERN, 527 NKAMBULE SREET, KWATHEMA, #, KWATHEMA, South Africa','geo':{'lat':-26.29933,'lng':28.40777}},
{'stp':580994,'address':'580994, EGL - ISANDO, ISANDO EAST MAIN, NGONGO TAVERN, 5489 MAKENTA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30200,'lng':28.38590}},
{'stp':581075,'address':'581075, EGL - ISANDO, ISANDO EAST MAIN, JAZI BOOTLEGGERS, CNR KGASWANE & THAGE STR, GUGULETHU, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30320,'lng':28.40260}},
{'stp':581076,'address':'581076, EGL - ISANDO, ISANDO EAST MAIN, FUNANIS PLACE, 76 MOTSUGI STREET, ERF 5964, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30380,'lng':28.38980}},
{'stp':581100,'address':'581100, EGL - ISANDO, ISANDO EAST MAIN, GIYANI TAVERN, 6755 MAFILIKA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30230,'lng':28.39700}},
{'stp':602863,'address':'602863, EGL - ISANDO, ISANDO EAST MAIN, ESIHLAHLENI TAVERN, 1216 MAKUA STREET EXT 01, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29080,'lng':28.40830}},
{'stp':602997,'address':'602997, EGL - ISANDO, ISANDO EAST MAIN, NONOZI MAHLANGU TAVERN, 39 STAND NO, NKOSI STREET, KWA THEMA SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.29770,'lng':28.38840}},
{'stp':603009,'address':'603009, EGL - ISANDO, ISANDO EAST MAIN, DENNIS THEJANE, 4 SIBEKO STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30480,'lng':28.39440}},
{'stp':603185,'address':'603185, EGL - ISANDO, ISANDO EAST MAIN, EVERGREEN CABIN, 32 KUZWAYO STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30090,'lng':28.39220}},
{'stp':603187,'address':'603187, EGL - ISANDO, ISANDO EAST MAIN, LEYMONDS INN, 54 KHELETSANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29780,'lng':28.39390}},
{'stp':603383,'address':'603383, EGL - ISANDO, ISANDO EAST MAIN, MUZIS TAVERN, 4 DITLE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29560,'lng':28.38480}},
{'stp':603391,'address':'603391, EGL - ISANDO, ISANDO EAST MAIN, MASEHLAS TAVERN, 25181 MNCUBE STREET, EXT 3, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31190,'lng':28.42280}},
{'stp':604410,'address':'604410, EGL - ISANDO, ISANDO EAST MAIN, QUEENS PLACE PERMIT, 9659 NGUBENI STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31170,'lng':28.40700}},
{'stp':604929,'address':'604929, EGL - ISANDO, ISANDO EAST MAIN, KGABS TAVERN, 22435 MAJOLA STREET  EXT 6, KWATHEMA, SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.31090,'lng':28.39120}},
{'stp':605164,'address':'605164, EGL - ISANDO, ISANDO EAST MAIN, LIFE CORNER, 25045 LEKUBU STREET, EXTENSION 3, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30960,'lng':28.42280}},
{'stp':606128,'address':'606128, EGL - ISANDO, ISANDO EAST MAIN, ZODWA S TAVERN, 60 MORE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29050,'lng':28.39050}},
{'stp':606129,'address':'606129, EGL - ISANDO, ISANDO EAST MAIN, JOYCE\'S TAVERN, 12036 NDUNGOANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28471,'lng':28.40819}},
{'stp':606252,'address':'606252, EGL - ISANDO, ISANDO EAST MAIN, MABENAS TAVERN, 26 RAMOTHIBE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30030,'lng':28.40010}},
{'stp':606466,'address':'606466, EGL - ISANDO, ISANDO EAST MAIN, NOMIAS TAVERN, STAND NO, ERF 24532 METSING STREET, KWA THEMA EXT 3, KWATHEMA, South Africa','geo':{'lat':-26.30717,'lng':28.42030}},
{'stp':606593,'address':'606593, EGL - ISANDO, ISANDO EAST MAIN, TSHIDI\'S PLACE, 11988 XABA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28400,'lng':28.40690}},
{'stp':607067,'address':'607067, EGL - ISANDO, ISANDO EAST MAIN, SEDIBENG TAVERN, 2 MABASO STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30050,'lng':28.39340}},
{'stp':625022,'address':'625022, EGL - ISANDO, ISANDO EAST MAIN, RIBS TAVERN, 3A NGAKANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30250,'lng':28.38640}},
{'stp':626094,'address':'626094, EGL - ISANDO, ISANDO EAST MAIN, OUPA ENTERPRICE PERMIT, 79 MEHLOMAKHULU STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30300,'lng':28.39130}},
{'stp':626661,'address':'626661, EGL - ISANDO, ISANDO EAST MAIN, PATUS TAVERN PERMIT, 4B MMUSANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30510,'lng':28.38830}},
{'stp':626811,'address':'626811, EGL - ISANDO, ISANDO EAST MAIN, SONTOS TAVERN PERMIT, 26115 KEKETSO STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31850,'lng':28.41650}},
{'stp':627420,'address':'627420, EGL - ISANDO, ISANDO EAST MAIN, KHAYAS TAVERN PERMIT, 12299 MOLEFE STREET, EXT 04, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29290,'lng':28.41270}},
{'stp':627860,'address':'627860, EGL - ISANDO, ISANDO EAST MAIN, CHANNEL 702 PERMIT, 38 MOLAPO STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30240,'lng':28.38850}},
{'stp':628157,'address':'628157, EGL - ISANDO, ISANDO EAST MAIN, DISBO TAVERN, 21815 STAND NO, RABORIFE STREET  EXT 2, #, KWATHEMA, South Africa','geo':{'lat':-26.32065,'lng':28.40544}},
{'stp':629390,'address':'629390, EGL - ISANDO, ISANDO EAST MAIN, WHITE HOUSE TAVERN, 63 MOHLALA STREET, KWATHEMA, #, KWATHEMA, South Africa','geo':{'lat':-26.29110,'lng':28.39050}},
{'stp':629473,'address':'629473, EGL - ISANDO, ISANDO EAST MAIN, NYUKUS TAVERN PERMIT, 33 MOTHOA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28840,'lng':28.39120}},
{'stp':629502,'address':'629502, EGL - ISANDO, ISANDO EAST MAIN, SANZA S TAVERN, 33 ERF 5317, 33 NKOSI STREET, KWA THEMA, KWATHEMA, South Africa','geo':{'lat':-26.29700,'lng':28.38850}},
{'stp':629529,'address':'629529, EGL - ISANDO, ISANDO EAST MAIN, SIZABANTU PERMIT, 129 NYAWENI STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29287,'lng':28.39707}},
{'stp':629588,'address':'629588, EGL - ISANDO, ISANDO EAST MAIN, ROSE SHEBEEN PERMIT, 19 THIPE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29260,'lng':28.39450}},
{'stp':630168,'address':'630168, EGL - ISANDO, ISANDO EAST MAIN, WALKERS TAVERN PERMIT, 67 TSHEHLO STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31050,'lng':28.39640}},
{'stp':630219,'address':'630219, EGL - ISANDO, ISANDO EAST MAIN, STAN ENTERPRISE PERMIT, 36 RAMOTHIBE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29960,'lng':28.40000}},
{'stp':630290,'address':'630290, EGL - ISANDO, ISANDO EAST MAIN, OTLALEMANG INN PERMIT, 19500 MOAGI STREET, extension 06, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31350,'lng':28.39710}},
{'stp':630372,'address':'630372, EGL - ISANDO, ISANDO EAST MAIN, LPK TAVERN PERMIT, 24840 GUGUSHE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31150,'lng':28.41900}},
{'stp':630374,'address':'630374, EGL - ISANDO, ISANDO EAST MAIN, EMASOFENI, 6 MMUSANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30480,'lng':28.38850}},
{'stp':630376,'address':'630376, EGL - ISANDO, ISANDO EAST MAIN, THOKOS TAVERN, 27 ZONDI STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29170,'lng':28.39640}},
{'stp':630438,'address':'630438, EGL - ISANDO, ISANDO EAST MAIN, PHANDORAS TAVERN PERMIT, 57 LUTHUMBU STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28250,'lng':28.39510}},
{'stp':630457,'address':'630457, EGL - ISANDO, ISANDO EAST MAIN, SIMPLE THE BEST ENTREPRICE, 14309 MASINA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31610,'lng':28.40930}},
{'stp':630459,'address':'630459, EGL - ISANDO, ISANDO EAST MAIN, LENDYS SHEBEEN, 13323 STAND NO, MASANABO STREET, KWA THEMA EXT 2, KWATHEMA, South Africa','geo':{'lat':-26.30883,'lng':28.41797}},
{'stp':630493,'address':'630493, EGL - ISANDO, ISANDO EAST MAIN, FEZMO TAVERN, 38 MMEKOA STREET, WHITE CITY, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30090,'lng':28.38620}},
{'stp':630507,'address':'630507, EGL - ISANDO, ISANDO EAST MAIN, LEAHS INN, 10848 MOTLAPING STREET, #, #, KWATHEMA, South Africa','geo':{'lat':-26.30627,'lng':28.40588}},
{'stp':630565,'address':'630565, EGL - ISANDO, ISANDO EAST MAIN, 7 UP, 4 GAZA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28754,'lng':28.39977}},
{'stp':630574,'address':'630574, EGL - ISANDO, ISANDO EAST MAIN, THEMBAS PLACE, 25492 MATSIPA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31140,'lng':28.42480}},
{'stp':630596,'address':'630596, EGL - ISANDO, ISANDO EAST MAIN, BEER GARDEN PERMIT, 24676 MADIKIZELA STREET EXT 3, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31410,'lng':28.41920}},
{'stp':630643,'address':'630643, EGL - ISANDO, ISANDO EAST MAIN, BIG MAMA PERMIT, 22208 SHILWANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.32006,'lng':28.40905}},
{'stp':633982,'address':'633982, EGL - ISANDO, ISANDO EAST MAIN, MOLLYS TUCKSHOP, 24 ZWANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30650,'lng':28.39650}},
{'stp':635515,'address':'635515, EGL - ISANDO, ISANDO EAST MAIN, PITSENG TAVERN, 1A JAMES SKASAONA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.27990,'lng':28.39590}},
{'stp':635735,'address':'635735, EGL - ISANDO, ISANDO EAST MAIN, MAGGIE\'S INN, 14522 MDUDUZI STR, EXT 2 KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31500,'lng':28.41320}},
{'stp':635833,'address':'635833, EGL - ISANDO, ISANDO EAST MAIN, MASABATA, 16 KHUZWAYO STR, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30010,'lng':28.39260}},
{'stp':635845,'address':'635845, EGL - ISANDO, ISANDO EAST MAIN, SQUIRE, 92 RAMOTHIBE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29720,'lng':28.39730}},
{'stp':636336,'address':'636336, EGL - ISANDO, ISANDO EAST MAIN, COFFEE SHOP, 22310 MSHELEDI STREET EXT 2, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31947,'lng':28.40675}},
{'stp':636541,'address':'636541, EGL - ISANDO, ISANDO EAST MAIN, CHARLIES INN, 19494 MOAGI STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31360,'lng':28.39630}},
{'stp':636853,'address':'636853, EGL - ISANDO, ISANDO EAST MAIN, BUSY BEES PLACE, 25 MAKGABUTLANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28310,'lng':28.39180}},
{'stp':637193,'address':'637193, EGL - ISANDO, ISANDO EAST MAIN, MAKUA\'S TAVERN, 888 MOBOTJA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29200,'lng':28.40240}},
{'stp':638227,'address':'638227, EGL - ISANDO, ISANDO EAST MAIN, NKABINDES PLACE, 11616 MAKHONJWA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30100,'lng':28.40630}},
{'stp':638564,'address':'638564, EGL - ISANDO, ISANDO EAST MAIN, ROSE\'S PLACE, 12325 MDEBELE STREET, EXT 4 KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28508,'lng':28.40857}},
{'stp':641431,'address':'641431, EGL - ISANDO, ISANDO EAST MAIN, J T S TAVERN, 25868 EXT 3, KWATHEMA, SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.31711,'lng':28.41727}},
{'stp':642650,'address':'642650, EGL - ISANDO, ISANDO EAST MAIN, EMLANJENI, 19B MMUSANE STREET, KWA-THEMA, KWA-THEMA, KWATHEMA, South Africa','geo':{'lat':-26.30570,'lng':28.38770}},
{'stp':648039,'address':'648039, EGL - ISANDO, ISANDO EAST MAIN, KISS ME TWICE, 62 TSUTSUBANE STREET, KWATHEMA, SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.29290,'lng':28.39710}},
{'stp':648051,'address':'648051, EGL - ISANDO, ISANDO EAST MAIN, TOLL GATE, 60 THOLWENI SECTION, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31090,'lng':28.39580}},
{'stp':648095,'address':'648095, EGL - ISANDO, ISANDO EAST MAIN, BANDIANE TAVERN, 48 TSUTSUBE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29310,'lng':28.39660}},
{'stp':648104,'address':'648104, EGL - ISANDO, ISANDO EAST MAIN, KHAMBIS, 25951 NHLABATHI STREET EXT 3, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31420,'lng':28.41860}},
{'stp':648135,'address':'648135, EGL - ISANDO, ISANDO EAST MAIN, VITAMIN M, 13485 NKONOANE STREET, EXT 2,KWATHEMA, SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.30860,'lng':28.41530}},
{'stp':648156,'address':'648156, EGL - ISANDO, ISANDO EAST MAIN, KWA MABENA, 30 MAHLANGU STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30301,'lng':28.39124}},
{'stp':648664,'address':'648664, EGL - ISANDO, ISANDO EAST MAIN, MTHANJENI INN, 149 SAM-NGEMA DRIVE, ERF 21238, KWATHEMA EXTENSION 1, KWATHEMA, South Africa','geo':{'lat':-26.29810,'lng':28.41230}},
{'stp':648720,'address':'648720, EGL - ISANDO, ISANDO EAST MAIN, EBHAVINI TAVERN, 297 BERLIN STREET, #, #, KWATHEMA, South Africa','geo':{'lat':-26.26760,'lng':28.39370}},
{'stp':649074,'address':'649074, EGL - ISANDO, ISANDO EAST MAIN, MPUMALANGA TAVERN, 10 MKHABELA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29960,'lng':28.39620}},
{'stp':653920,'address':'653920, EGL - ISANDO, ISANDO EAST MAIN, TWO SISTERS TAVERN, 15715 JACK PHALA STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28110,'lng':28.39730}},
{'stp':654357,'address':'654357, EGL - ISANDO, ISANDO EAST MAIN, NGWENYA DEPOT, 1111 MOREKU STREET, KWA THEMA, #, KWATHEMA, South Africa','geo':{'lat':-26.29190,'lng':28.40930}},
{'stp':654488,'address':'654488, EGL - ISANDO, ISANDO EAST MAIN, FANTSHOS TAVERN, 75 KOTANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31372,'lng':28.40131}},
{'stp':656530,'address':'656530, EGL - ISANDO, ISANDO EAST MAIN, NHLANHLAS PLACE, 98 MORE STREET, KWA THEMA, KWA THEMA, KWATHEMA, South Africa','geo':{'lat':-26.28990,'lng':28.39270}},
{'stp':705706,'address':'705706, EGL - ISANDO, ISANDO EAST MAIN, MOTAUNG TAVERN, 7 MOKHITLI STREET, KWA THEMA, #, KWATHEMA, South Africa','geo':{'lat':-26.28700,'lng':28.38810}},
{'stp':705968,'address':'705968, EGL - ISANDO, ISANDO EAST MAIN, SISTER V, 26724 MADIBA STREET, EXTENSION 3, KWA THEMA, KWATHEMA, South Africa','geo':{'lat':-26.31860,'lng':28.41820}},
{'stp':746311,'address':'746311, EGL - ISANDO, ISANDO EAST MAIN, OKHAMBENI INN, 12459 MAYO STREET, #, #, KWATHEMA, South Africa','geo':{'lat':-26.29000,'lng':28.40990}},
{'stp':746746,'address':'746746, EGL - ISANDO, ISANDO EAST MAIN, TOLOS PLACE, 26 MOHLALA STREET, KWA THEMA, #, KWATHEMA, South Africa','geo':{'lat':-26.28980,'lng':28.38670}},
{'stp':753659,'address':'753659, EGL - ISANDO, ISANDO EAST MAIN, NTANDOS TAVERN, 19095 MAJOLA STREET, EXT 6, #, KWATHEMA, South Africa','geo':{'lat':-26.31050,'lng':28.39110}},
{'stp':759862,'address':'759862, EGL - ISANDO, ISANDO EAST MAIN, KOKOS SHEBEEN, 47 MEHLOMAKHULU STREET, KWA-THEMA, #, KWATHEMA, South Africa','geo':{'lat':-26.30130,'lng':28.39030}},
{'stp':772888,'address':'772888, EGL - ISANDO, ISANDO EAST MAIN, MR KEN S PLACE, 0043 JOB MASEKO STREET, #, #, KWATHEMA, South Africa','geo':{'lat':-26.29350,'lng':28.38878}},
{'stp':311948,'address':'311948, EGL - ISANDO, ISANDO EAST MAIN, SA LIQUORS, 718 STAND NO, GINSTEIN STREET JUNCTION HILL, EXT 7, GERMISTON, South Africa','geo':{'lat':-26.29261,'lng':28.16280}},
{'stp':312076,'address':'312076, EGL - ISANDO, ISANDO EAST MAIN, RASTA TAVERN, 46A STAND NO, PAMPASGRAS CRESENT KLIPPORTJIE, #, SPRUITVIEW, South Africa','geo':{'lat':-26.28239,'lng':28.20485}},
{'stp':315386,'address':'315386, EGL - ISANDO, ISANDO EAST MAIN, CONER JEMMEY PLACE, STAND NO, ERF NUMBER 319/118, KLIPPOORTJIE AGRICULTURAL LOTS, SPRUITVIEW, South Africa','geo':{'lat':-26.28858,'lng':28.19882}},
{'stp':318839,'address':'318839, EGL - ISANDO, ISANDO EAST MAIN, CHOOSE \'N PAY TOPS, STAND NO, TASSO CENTRE CNR ONYX PLACE, HATFIELD AVENUE ALBERMARLE X1 ERF 498, GERMISTON, South Africa','geo':{'lat':-26.26456,'lng':28.15359}},
{'stp':392253,'address':'392253, EGL - ISANDO, ISANDO EAST MAIN, SHOPRITE LIQUORSHOP THE REEF ALBERM, 19 SHOP NO, CNR BLACK REEF RD AND CHRIS STR., BEING ERF 427 (PORTION 648 OF FARM, GERMISTON, South Africa','geo':{'lat':-26.25589,'lng':28.15839}},
{'stp':393127,'address':'393127, EGL - ISANDO, ISANDO EAST MAIN, THE YATCH PUB AND GRILL, ERF 11 SHOP 9 , 54, TUNNY STREET, GROENEWEIDE TOWNSHIP BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.27876,'lng':28.23049}},
{'stp':393438,'address':'393438, EGL - ISANDO, ISANDO EAST MAIN, TSHEPO S TAVERN, 236 ERF NO, PORTION 110, KLIPPOOTJIE AGRICULTURAL LOTS, GERMISTON, South Africa','geo':{'lat':-26.29121,'lng':28.18959}},
{'stp':463025,'address':'463025, EGL - ISANDO, ISANDO EAST MAIN, THULAS TAVERN, STAND NO, SHOP NO 387, BUHLE PARK PHASE 5A, SPRUITVIEW, South Africa','geo':{'lat':-26.28994,'lng':28.20426}},
{'stp':468402,'address':'468402, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR CITY DAWN PARK MALL, GALAHAD STREET, SHOP 23 DAWN PARK, ERF 1724, BOKSBURG, South Africa','geo':{'lat':-26.31280,'lng':28.25123}},
{'stp':480457,'address':'480457, EGL - ISANDO, ISANDO EAST MAIN, TWO SISTERS TAVERN, 2346 STAND NUMBER, GARLIC STREET, EXTENSION 02, BOKSBURG, South Africa','geo':{'lat':-26.31001,'lng':28.20766}},
{'stp':480874,'address':'480874, EGL - ISANDO, ISANDO EAST MAIN, MUNOS TAVERN, 69 STAND NO, MADEIRA STREET, EXTENSION 10, BOKSBURG, South Africa','geo':{'lat':-26.29366,'lng':28.26484}},
{'stp':487524,'address':'487524, EGL - ISANDO, ISANDO EAST MAIN, ZINHLE TAVERN, 233 ERF NO, 971 CORNER PRIMA FACIA AND RATIO STREET, PHASE 2 BHULE PARK, GERMISTON, South Africa','geo':{'lat':-26.29098,'lng':28.20256}},
{'stp':488757,'address':'488757, EGL - ISANDO, ISANDO EAST MAIN, MAVUSANE TAVERN, 0372 STAND NO, NITROGEN STREET, BUHLE PARK PHASE 5, GERMISTON, South Africa','geo':{'lat':-26.28823,'lng':28.20355}},
{'stp':550388,'address':'550388, EGL - ISANDO, ISANDO EAST MAIN, STILL WATER 61, 61 BUHLE PARK PHASE 3, KLIPPOORTJIE, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.28810,'lng':28.19730}},
{'stp':550471,'address':'550471, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR CITY LAMBTON, 145 CNR WEBBER ROAD & PIERCY AVE, LAMBTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.23830,'lng':28.17600}},
{'stp':550495,'address':'550495, EGL - ISANDO, ISANDO EAST MAIN, THE HARVARD CAFE LE PISTON, RAND AIRPORT ROAD, 8 TERMINAL BUILDING GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.23959,'lng':28.15251}},
{'stp':551247,'address':'551247, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR CITY DAWN PARK, CNR HASSINK HIGHWAY & GALAHAD ROAD, DAWN PARK, DAWN PARK, BOKSBURG, South Africa','geo':{'lat':-26.31540,'lng':28.24809}},
{'stp':551257,'address':'551257, EGL - ISANDO, ISANDO EAST MAIN, SANNY S INN PERMIT, 0489 ERF NO, 489/233 CRESCENT STREET,BUHLE PARK, KLIPPOORTJE AGRICULTURAL LOTS, SPRUITVIEW, South Africa','geo':{'lat':-26.29470,'lng':28.19860}},
{'stp':551689,'address':'551689, EGL - ISANDO, ISANDO EAST MAIN, CROWDED HOUSE TA GEM BAR, CNR WEBBER RD & THIRD AVE, LAMBTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.24160,'lng':28.17660}},
{'stp':551708,'address':'551708, EGL - ISANDO, ISANDO EAST MAIN, MURPHY S PUB AND GRILL, CNR WEBBER RD AND OLIVIER STREET, ESTERA, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.25180,'lng':28.18650}},
{'stp':552642,'address':'552642, EGL - ISANDO, ISANDO EAST MAIN, SAAME SPORT CLUB, CNR CHEAM CRESCENT & KINGSTON ROAD, #, DINWIDDIE, GERMISTON, South Africa','geo':{'lat':-26.27055,'lng':28.15787}},
{'stp':552663,'address':'552663, EGL - ISANDO, ISANDO EAST MAIN, MOTAU BOTTLE STORE, 1264 EXT 2 SOUTH BOUNDARY, RONDEBULT, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31177,'lng':28.21848}},
{'stp':552773,'address':'552773, EGL - ISANDO, ISANDO EAST MAIN, GERMISTON GOLF CLUB, GED 2 VAN GED VAN PLAAS, ELANDSFONT, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.23320,'lng':28.15260}},
{'stp':553033,'address':'553033, EGL - ISANDO, ISANDO EAST MAIN, COPPER CANYON SPUR, CNR ANGUS AND WEBBER STREETS, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.25120,'lng':28.18620}},
{'stp':553714,'address':'553714, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR LAND, SHP 8 TASSO CNTR ONYX & HATFIELD, ALBERMARLE, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26407,'lng':28.15417}},
{'stp':561077,'address':'561077, EGL - ISANDO, ISANDO EAST MAIN, GOLDEN LIQUOR STORE, CNR KINGSTON ROAD & 36 DEWLISH AVE, DINWIDDIE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.27130,'lng':28.15330}},
{'stp':604955,'address':'604955, EGL - ISANDO, ISANDO EAST MAIN, ELSIES TAVERN, 352 DELPHINIUM STREET, BUHLE PARK, BUHLE PARK, SPRUITVIEW, South Africa','geo':{'lat':-26.29230,'lng':28.19530}},
{'stp':606254,'address':'606254, EGL - ISANDO, ISANDO EAST MAIN, ELSPARK GOLF DRIVING RANGE, 45 SAPELE STREET, ERF 1278 EXT 3, SAPELE STR CNR MOPANI DRIVE, GERMISTON, South Africa','geo':{'lat':-26.27214,'lng':28.23169}},
{'stp':606987,'address':'606987, EGL - ISANDO, ISANDO EAST MAIN, LAST STREET, 383 BUHLE  PARK, BUHLE PARK, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.29560,'lng':28.19330}},
{'stp':607506,'address':'607506, EGL - ISANDO, ISANDO EAST MAIN, LAKESIDE LIQUOR STORE CC, CNR CHAPMAN & RUSSELL ROAD, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.23710,'lng':28.16300}},
{'stp':626976,'address':'626976, EGL - ISANDO, ISANDO EAST MAIN, TWO SISTERS PERMIT, 610 CRESCENT STREET, BUHLE PARK PHASE 3, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.29540,'lng':28.19960}},
{'stp':630887,'address':'630887, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR CITY HAZELPARK, CNR WEBBER AND DOAK STREET, LAMBTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.24770,'lng':28.17980}},
{'stp':632180,'address':'632180, EGL - ISANDO, ISANDO EAST MAIN, NHLANHLAS TAVERN, CNR3008 RONDUBULT RD & BEANS STR, RONDEBULT, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31150,'lng':28.22250}},
{'stp':632741,'address':'632741, EGL - ISANDO, ISANDO EAST MAIN, THOBELA TAVERN, 850 LEX STREET, BUHLE PARK, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.29240,'lng':28.20150}},
{'stp':636513,'address':'636513, EGL - ISANDO, ISANDO EAST MAIN, MOREMA TAVERN, 3026 LULUMBA STREET, RONDEBULT EXT 3, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.31200,'lng':28.22220}},
{'stp':642630,'address':'642630, EGL - ISANDO, ISANDO EAST MAIN, SIZIWES TAVERN, 737 737 BOIKHUTSO STREET, RONDEBUT EXT 2, GERMISTON, SPRUITVIEW, South Africa','geo':{'lat':-26.31070,'lng':28.21960}},
{'stp':642689,'address':'642689, EGL - ISANDO, ISANDO EAST MAIN, THAKANES TAVERN, 197 197 BUHLE PARK, PHASE 5A, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.28730,'lng':28.20170}},
{'stp':647170,'address':'647170, EGL - ISANDO, ISANDO EAST MAIN, K B TUCKSHOP AND INN, 113 ULANA STREET, DELMORE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.26850,'lng':28.20162}},
{'stp':649529,'address':'649529, EGL - ISANDO, ISANDO EAST MAIN, SMILING PLACE INN, RONDEBULT  593, EXTENSION 2, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.30820,'lng':28.21840}},
{'stp':652280,'address':'652280, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR LEGENDS CASTLEVIEW, SHOP 6,STONE ARCH SQUARE, CNR SUNSTONE AND BROOKHILL ROADS, PORTION 1 OF ERF 216 CASTLEVIEW, GERMISTON, South Africa','geo':{'lat':-26.25610,'lng':28.16850}},
{'stp':655567,'address':'655567, EGL - ISANDO, ISANDO EAST MAIN, MARRYS INN, 178 110 BUHLE PARK, KLIPPOORTJIE, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.28610,'lng':28.20530}},
{'stp':655586,'address':'655586, EGL - ISANDO, ISANDO EAST MAIN, NOZIS PLACE, 295 PHASE 3, BUHLE PARK, SPRUITVIEW, SPRUITVIEW, South Africa','geo':{'lat':-26.29380,'lng':28.19832}},
{'stp':670160,'address':'670160, EGL - ISANDO, ISANDO EAST MAIN, RONDEBULT RESTAURANT, CNR MALUTI AND DRAKENSBURG RDS, RONDEBULT CENTRE, RONDEBULT, SPRUITVIEW, South Africa','geo':{'lat':-26.30920,'lng':28.23250}},
{'stp':682637,'address':'682637, EGL - ISANDO, ISANDO EAST MAIN, GREEN VALLEY LIQUOR STORE, GREENVALLEY SHOPPING CENTRE, KLIPPOORTJIE, GERMISTON, SPRUITVIEW, South Africa','geo':{'lat':-26.28770,'lng':28.20600}},
{'stp':705988,'address':'705988, EGL - ISANDO, ISANDO EAST MAIN, MIKES KITCHEN LAMBTON, LAMBTON COURT SHOPPING CENTRE, SHO NO 16 CORNER WEBBER AND BEACON, STREET LAMBTON, GERMISTON, South Africa','geo':{'lat':-26.24620,'lng':28.17880}},
{'stp':755316,'address':'755316, EGL - ISANDO, ISANDO EAST MAIN, BOYKI TAVERN, 154 STAND NO, NYALA STREET PHASWE 03 BUHLE PARK, #, SPRUITVIEW, South Africa','geo':{'lat':-26.28892,'lng':28.19865}},
{'stp':768140,'address':'768140, EGL - ISANDO, ISANDO EAST MAIN, FLYBOYS, STAND NO, RAND AIRPORT, #, GERMISTON, South Africa','geo':{'lat':-26.24170,'lng':28.15830}},
{'stp':313244,'address':'313244, EGL - ISANDO, ISANDO EAST MAIN, WAGON WHEEL TAVERN, 298 STAND NO, COSMOS STREET, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23286,'lng':28.22918}},
{'stp':389020,'address':'389020, EGL - ISANDO, ISANDO EAST MAIN, PICK N PAY GS11  MARIO S, 1/380 ERF, REIGERPARK,    SHOPPING CENTRE.,    9 LEON FERRIERA DRIVE, BOKSBURG, South Africa','geo':{'lat':-26.23361,'lng':28.22609}},
{'stp':389884,'address':'389884, EGL - ISANDO, ISANDO EAST MAIN, WHITE HOUSE BOTTLE STORE, 7 STAND NO, HOSPITAL ROAD, PLANTATION, BOKSBURG, South Africa','geo':{'lat':-26.21734,'lng':28.24352}},
{'stp':391509,'address':'391509, EGL - ISANDO, ISANDO EAST MAIN, OZOIKE LIQUOR STORE, 8 STAND NO, 54 TUNNY STREET, #, BOKSBURG, South Africa','geo':{'lat':-26.27876,'lng':28.23049}},
{'stp':392622,'address':'392622, EGL - ISANDO, ISANDO EAST MAIN, BOSOMA TAVERN, 2379 NO, INSINZINI STREET, RAMAPHOSA, BOKSBURG, South Africa','geo':{'lat':-26.22781,'lng':28.20939}},
{'stp':461212,'address':'461212, EGL - ISANDO, ISANDO EAST MAIN, WILLIAM\'S TAVERN, STAND NO, ERF 1639 ILOWE STREET, REIGER PARK EXT 5, BOKSBURG, South Africa','geo':{'lat':-26.23288,'lng':28.20736}},
{'stp':462266,'address':'462266, EGL - ISANDO, ISANDO EAST MAIN, GESH LIQUOR STORE, 695 STAND NO, EAST CENTRAL ROAD, WINDMILL PARK EXT 8, DAWN PARK, South Africa','geo':{'lat':-26.29076,'lng':28.26101}},
{'stp':470921,'address':'470921, EGL - ISANDO, ISANDO EAST MAIN, SUSANES TAVERN, 8 STAND NO, QUEENS AVENUE, DELMORE PARK, BOKSBURG, South Africa','geo':{'lat':-26.20745,'lng':28.19790}},
{'stp':476744,'address':'476744, EGL - ISANDO, ISANDO EAST MAIN, LORRAINE S PLACE, 1113 STAND NO, WILLIAM\'S ROAD, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23220,'lng':28.21950}},
{'stp':483661,'address':'483661, EGL - ISANDO, ISANDO EAST MAIN, VUKA UZENEZELE TAVERN, 2432 STAND NO, MUSTARD STREET, EXTENSION 2, DAWN PARK, South Africa','geo':{'lat':-26.32178,'lng':28.26039}},
{'stp':486878,'address':'486878, EGL - ISANDO, ISANDO EAST MAIN, BBJ S PLACE, 2464 STAND NO, MBENGA STREET, EXT, DUKATHOLE, GERMISTON, South Africa','geo':{'lat':-26.21851,'lng':28.19515}},
{'stp':544288,'address':'544288, EGL - ISANDO, ISANDO EAST MAIN, M J MOTLOUNG WHOLESALERS, 1665 ERF, 3607 EAST CENTRAL WINDMILL PARK, #, BOKSBURG, South Africa','geo':{'lat':-26.29000,'lng':28.26236}},
{'stp':550407,'address':'550407, EGL - ISANDO, ISANDO EAST MAIN, MONATI TAVERN, ERF 328 SHOP NO 4, FERREIRA CENTRE, CORNER VICTOR AND COMMISSIONER STREETS, BOKSBURG, South Africa','geo':{'lat':-26.21810,'lng':28.27500}},
{'stp':550460,'address':'550460, EGL - ISANDO, ISANDO EAST MAIN, ROB S TAVERN, 228 GOEDEHOOP AVENUE, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22910,'lng':28.23330}},
{'stp':550761,'address':'550761, EGL - ISANDO, ISANDO EAST MAIN, ATLANTIS ENTERTAINMENT CENTRE, 9 HOSPITAL ROAD, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22080,'lng':28.24290}},
{'stp':550912,'address':'550912, EGL - ISANDO, ISANDO EAST MAIN, JUNCTION JAZZ, 52 LEEUWPOORT STREET, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22380,'lng':28.25010}},
{'stp':551022,'address':'551022, EGL - ISANDO, ISANDO EAST MAIN, STABLE INN CONFERENCE CENTRE, VLEI ROAD, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25910,'lng':28.48380}},
{'stp':551344,'address':'551344, EGL - ISANDO, ISANDO EAST MAIN, KHAYALETHU, 170 LEEUWPOORT STREET, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22420,'lng':28.26120}},
{'stp':551872,'address':'551872, EGL - ISANDO, ISANDO EAST MAIN, J S BOTTLE STORE, LEON FERREIRA DRIVE, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22810,'lng':28.22660}},
{'stp':552170,'address':'552170, EGL - ISANDO, ISANDO EAST MAIN, LIQUOR MASTER, CNR STERKBOOM & ANDY SOLOMONS ST, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23040,'lng':28.21490}},
{'stp':552176,'address':'552176, EGL - ISANDO, ISANDO EAST MAIN, CENTRAL HOTEL BOKSBURG, 270 COMMISSIONER STREET, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22330,'lng':28.25130}},
{'stp':552211,'address':'552211, EGL - ISANDO, ISANDO EAST MAIN, MR LIQUOR, LEON FERREIRA STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23030,'lng':28.22630}},
{'stp':552853,'address':'552853, EGL - ISANDO, ISANDO EAST MAIN, R DS TAVERN PERMIT, 231 BUTTER CUP STREET, REIGER PARK, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23150,'lng':28.23170}},
{'stp':552904,'address':'552904, EGL - ISANDO, ISANDO EAST MAIN, DANS TAVERN, 1663 ZITA STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22390,'lng':28.30140}},
{'stp':553559,'address':'553559, EGL - ISANDO, ISANDO EAST MAIN, MAC BY NIGHT TAVERN, 78 A JOHN COLLINS STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23055,'lng':28.23068}},
{'stp':553853,'address':'553853, EGL - ISANDO, ISANDO EAST MAIN, MIMIS TAVERN PERMIT, 118 GELDERBLOM DRIVE, WINDMILL PARK, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.28760,'lng':28.25730}},
{'stp':553896,'address':'553896, EGL - ISANDO, ISANDO EAST MAIN, ZEENOS, 1234 OXFORD STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23160,'lng':28.23040}},
{'stp':560251,'address':'560251, EGL - ISANDO, ISANDO EAST MAIN, PHATHLELE TAVERN, 1861 STAND NO, FISH EAGLE STREET EXTENSION 05, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.22710,'lng':28.20810}},
{'stp':561301,'address':'561301, EGL - ISANDO, ISANDO EAST MAIN, ISESE TAVERN, 1855 FISH EAGLE STREET, EXT 5, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.22770,'lng':28.20800}},
{'stp':580406,'address':'580406, EGL - ISANDO, ISANDO EAST MAIN, BJ S INN, 12 TUNA STREET EXT 2, DELMORE PARK, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21130,'lng':28.20930}},
{'stp':581047,'address':'581047, EGL - ISANDO, ISANDO EAST MAIN, ZAKS TAVERN, 80A JOHN COLLINS ROAD, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23043,'lng':28.23108}},
{'stp':581314,'address':'581314, EGL - ISANDO, ISANDO EAST MAIN, INDA CAPE TAVERN, 218 DE WAAL STREET, WINDMILL PARK, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.28500,'lng':28.25770}},
{'stp':604716,'address':'604716, EGL - ISANDO, ISANDO EAST MAIN, THOHOYANDOU, 53 GERANIUM STREET, VILLA LIZA, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.32888,'lng':28.25380}},
{'stp':605196,'address':'605196, EGL - ISANDO, ISANDO EAST MAIN, PHINDILES TAVERN, 2431 MUSTARD STREET, EXT 2, VILLA LIZA, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.32178,'lng':28.26039}},
{'stp':605544,'address':'605544, EGL - ISANDO, ISANDO EAST MAIN, CLEOS TAVERN, 4291 EAST CENTRAL RD EXT 9, WINDMILL PARK, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.29300,'lng':28.27340}},
{'stp':605857,'address':'605857, EGL - ISANDO, ISANDO EAST MAIN, DIPILING TAVERN, 2097 UTHEKWANE STR, RAMAPHOSA, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23120,'lng':28.20550}},
{'stp':605982,'address':'605982, EGL - ISANDO, ISANDO EAST MAIN, CLIVE SHEBEEN, 1195 CLARENCE SEPTEMBER STR, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23380,'lng':28.21750}},
{'stp':607540,'address':'607540, EGL - ISANDO, ISANDO EAST MAIN, ELEPHANT CAFE, 69 St.ANTHONY STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22450,'lng':28.22560}},
{'stp':625007,'address':'625007, EGL - ISANDO, ISANDO EAST MAIN, FALLING LEAVES, 128 JOHNNY ARENDSE STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23510,'lng':28.21820}},
{'stp':625159,'address':'625159, EGL - ISANDO, ISANDO EAST MAIN, BALANS JOINT, 16 DAFFODIL STREET, VILLA LIZA, DAWN PARK, BOKSBURG, South Africa','geo':{'lat':-26.32920,'lng':28.25160}},
{'stp':625729,'address':'625729, EGL - ISANDO, ISANDO EAST MAIN, SPUTLAS, STAND NO, ERF 2281 UMKHOLONJANE, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23200,'lng':28.20691}},
{'stp':626220,'address':'626220, EGL - ISANDO, ISANDO EAST MAIN, TSHABALALAS PLACE, 330A DAISY STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23330,'lng':28.22820}},
{'stp':626926,'address':'626926, EGL - ISANDO, ISANDO EAST MAIN, JABUS TAVERN PERMIT, 14 LEHEA STREET, WINDMILL PARK, EXTENSION 9, DAWN PARK, South Africa','geo':{'lat':-26.28840,'lng':28.27210}},
{'stp':627143,'address':'627143, EGL - ISANDO, ISANDO EAST MAIN, KHULANE TAVERN PERMIT, 4734 SIPHUMELELE STREET, WINDMILL PARK, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.29660,'lng':28.27160}},
{'stp':627195,'address':'627195, EGL - ISANDO, ISANDO EAST MAIN, DEPORAS INN PERMIT, 33 GELDERBLOM STREET, WINDMILL PARK, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.28630,'lng':28.26380}},
{'stp':627221,'address':'627221, EGL - ISANDO, ISANDO EAST MAIN, FLORAS PLACE PERMIT, 60 SQUIRREL STREET, VILLA LIZA, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.32690,'lng':28.25250}},
{'stp':627576,'address':'627576, EGL - ISANDO, ISANDO EAST MAIN, 196233MAKIES TAVERN PERMIT, 18 LEON FERREIRA DRIVE, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23310,'lng':28.22630}},
{'stp':627826,'address':'627826, EGL - ISANDO, ISANDO EAST MAIN, CINDYS TAVERN PERMIT, 77 PRIMROSE STREET, VILLA LIZA, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.33070,'lng':28.24750}},
{'stp':627832,'address':'627832, EGL - ISANDO, ISANDO EAST MAIN, MORETHETHONG PUB AND GRILL, 12 ANTELOPE STREET, VILLA LIZA, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.32320,'lng':28.25480}},
{'stp':628731,'address':'628731, EGL - ISANDO, ISANDO EAST MAIN, HOT DELI PUB AND RESTAURANT, 327 COMMISSIONER STREET, ERF 563 AND 1384, #, BOKSBURG, South Africa','geo':{'lat':-26.22336,'lng':28.25987}},
{'stp':628771,'address':'628771, EGL - ISANDO, ISANDO EAST MAIN, WINDMILL PARK JAZZ PALACE PERMIT, 971 EAST CENTRAL STREET, WINDMILL PARK, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.28893,'lng':28.26570}},
{'stp':628951,'address':'628951, EGL - ISANDO, ISANDO EAST MAIN, QUEENES INN PERMIT, 9 PALING STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23440,'lng':28.21830}},
{'stp':629890,'address':'629890, EGL - ISANDO, ISANDO EAST MAIN, DAN THREE, 2750 POO STREET, WATTVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22392,'lng':28.30165}},
{'stp':629891,'address':'629891, EGL - ISANDO, ISANDO EAST MAIN, DAN ONE, 4867 SERAPA STREET, WINDMILL PARK, DAWN PARK, BOKSBURG, South Africa','geo':{'lat':-26.29834,'lng':28.27027}},
{'stp':630379,'address':'630379, EGL - ISANDO, ISANDO EAST MAIN, DAN TWO, 557 INHLANHLA STREET, KINGSWAY, DAVEYTON, SPRINGS, South Africa','geo':{'lat':-26.29566,'lng':28.26762}},
{'stp':630795,'address':'630795, EGL - ISANDO, ISANDO EAST MAIN, AUBA, 647 SIMON MENTOR STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23540,'lng':28.22210}},
{'stp':631073,'address':'631073, EGL - ISANDO, ISANDO EAST MAIN, BAFOKENG TAVERN, 284 NALA STREET, VILLA LIZA, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.33010,'lng':28.26130}},
{'stp':632737,'address':'632737, EGL - ISANDO, ISANDO EAST MAIN, FUZZYS TAVERN, 279A LEEUW BEKKIE STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23306,'lng':28.23223}},
{'stp':636197,'address':'636197, EGL - ISANDO, ISANDO EAST MAIN, LIGHT HOUSE, 10 ANNETTE STREET, DAWN PARK, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.30290,'lng':28.24100}},
{'stp':636234,'address':'636234, EGL - ISANDO, ISANDO EAST MAIN, KINGSWAY LIQUOR STORE, CNR CHURCH AND PLEIN STS, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22066,'lng':28.24212}},
{'stp':636345,'address':'636345, EGL - ISANDO, ISANDO EAST MAIN, KUKUS TAVERN, 1387 AMAKHULU STREET, REIGER PARK, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23740,'lng':28.20890}},
{'stp':636545,'address':'636545, EGL - ISANDO, ISANDO EAST MAIN, TSAKANE TAVERN, 4828 MONOKOTSWAI STREET, WINDMILL PARK EXT 9, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.29710,'lng':28.27120}},
{'stp':636546,'address':'636546, EGL - ISANDO, ISANDO EAST MAIN, PEKAS TAVERN, 310A COSMOS STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23290,'lng':28.22980}},
{'stp':637056,'address':'637056, EGL - ISANDO, ISANDO EAST MAIN, LEBELO TAVERN, 8 MOLALA STREET, WINDMILL PARK EXT 9, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.29100,'lng':28.26910}},
{'stp':638132,'address':'638132, EGL - ISANDO, ISANDO EAST MAIN, GAFANE\'S TAVERN, A49 LINDELANE STREET HOLOMISA, WINDMILL PARK, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.29683,'lng':28.26482}},
{'stp':638229,'address':'638229, EGL - ISANDO, ISANDO EAST MAIN, JONAS TAVERN, 1990 ROOIBOS STREET, VILLA LISA EXT2, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.32950,'lng':28.25920}},
{'stp':640052,'address':'640052, EGL - ISANDO, ISANDO EAST MAIN, ZEE TUCKSHOP, 07 BLESBOK STREET, DAWN PARK, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.29622,'lng':28.24715}},
{'stp':641283,'address':'641283, EGL - ISANDO, ISANDO EAST MAIN, NANDOS PLACE, 1884 GREEN STREET, VILL LIZA, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.32793,'lng':28.25814}},
{'stp':641653,'address':'641653, EGL - ISANDO, ISANDO EAST MAIN, CHARLES PLACE, 1355 KRANSDUIF STREET, WINDMILL PARK, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.30000,'lng':28.26000}},
{'stp':646304,'address':'646304, EGL - ISANDO, ISANDO EAST MAIN, VUKUZENZELE TAVERN, 2819 IGOLOMO STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23850,'lng':28.20860}},
{'stp':646978,'address':'646978, EGL - ISANDO, ISANDO EAST MAIN, O J WHOLESALERS, 462 ERF EXT 1, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22878,'lng':28.22627}},
{'stp':647330,'address':'647330, EGL - ISANDO, ISANDO EAST MAIN, AFTER TEARS, 121A ZINNIA STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23250,'lng':28.22700}},
{'stp':649224,'address':'649224, EGL - ISANDO, ISANDO EAST MAIN, STALLION TAVERN, 1693 KITE STREET, RIEGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23080,'lng':28.20930}},
{'stp':649275,'address':'649275, EGL - ISANDO, ISANDO EAST MAIN, BAPENDING TAVERN, 2032 WINTER SAVORY STREET, VILLA LISA, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.32510,'lng':28.26030}},
{'stp':649783,'address':'649783, EGL - ISANDO, ISANDO EAST MAIN, BS JOINT, 27 ELKINGTON STREET, WINDMILL PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.28600,'lng':28.25650}},
{'stp':649802,'address':'649802, EGL - ISANDO, ISANDO EAST MAIN, IRENES PLACE, 889 VINK STREET, WINDMILL PARK EXT 8, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.29270,'lng':28.26350}},
{'stp':649833,'address':'649833, EGL - ISANDO, ISANDO EAST MAIN, FIENAS TAVERN, 1208 CLARENCE SEPTEMBER, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23380,'lng':28.21940}},
{'stp':649837,'address':'649837, EGL - ISANDO, ISANDO EAST MAIN, BELLAS LOUNGE, 1311 UGWIDI STREET, KAMAPHOSA, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23540,'lng':28.20700}},
{'stp':649873,'address':'649873, EGL - ISANDO, ISANDO EAST MAIN, BISHOPS PLACE, 88 GELDER BLOM STREET, WINDMILL PARK, WINDMILL PARK, DAWN PARK, South Africa','geo':{'lat':-26.28740,'lng':28.26060}},
{'stp':651722,'address':'651722, EGL - ISANDO, ISANDO EAST MAIN, SITHOLES TAVERN, 2179 RAMAPHOSA STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23460,'lng':28.20560}},
{'stp':651723,'address':'651723, EGL - ISANDO, ISANDO EAST MAIN, BMG, 1120 1120 BOKMEKEREL STREET, WINDMILL PARK EXTENSION 8, BOKSBURG, DAWN PARK, South Africa','geo':{'lat':-26.29080,'lng':28.26560}},
{'stp':652371,'address':'652371, EGL - ISANDO, ISANDO EAST MAIN, ATLANTIS LIQUOR STORE, CNR HOSPITAL & CHURCH STS, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22080,'lng':28.24280}},
{'stp':653032,'address':'653032, EGL - ISANDO, ISANDO EAST MAIN, MAZIBUKO TAVERN, 3656 JULUKA STREET, EXTENSION 9, WINDMILL PARK, DAWN PARK, South Africa','geo':{'lat':-26.29100,'lng':28.27360}},
{'stp':653830,'address':'653830, EGL - ISANDO, ISANDO EAST MAIN, BANIS ENTERTAINMENT INN, 2469 IGXIYA STREET, RAMAPHOSA, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23000,'lng':28.20990}},
{'stp':653916,'address':'653916, EGL - ISANDO, ISANDO EAST MAIN, YORICKS PLACE, 902 BUTCH JANTJIES DRIVE, REIGER PARK, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.22880,'lng':28.21540}},
{'stp':654139,'address':'654139, EGL - ISANDO, ISANDO EAST MAIN, ISSYS TAVERN, 73 SCHACHAT CRESCENT, EXTENSION 25 DAWN PARK, DAWNPARK, DAWN PARK, South Africa','geo':{'lat':-26.31086,'lng':28.25638}},
{'stp':654146,'address':'654146, EGL - ISANDO, ISANDO EAST MAIN, IVYS PLACE, 3651 JULUJA STREET, WINDMILL PARK, DAWNPARK, DAWN PARK, South Africa','geo':{'lat':-26.29130,'lng':28.27290}},
{'stp':659915,'address':'659915, EGL - ISANDO, ISANDO EAST MAIN, MELONY S PLACE, 41 KANGAROO STREET, VILLA LISA, WINDMILL PARK, DAWN PARK, South Africa','geo':{'lat':-26.32650,'lng':28.25480}},
{'stp':660166,'address':'660166, EGL - ISANDO, ISANDO EAST MAIN, MATHYE TAVERN, 1270 JH SELANE STREET, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22850,'lng':28.19250}},
{'stp':664823,'address':'664823, EGL - ISANDO, ISANDO EAST MAIN, JACKIE PLACE, 20 GUNARD AVENUE, EXTENSION 02, DELMORE PARK, GERMISTON, South Africa','geo':{'lat':-26.21192,'lng':28.20754}},
{'stp':666064,'address':'666064, EGL - ISANDO, ISANDO EAST MAIN, CHAKA CHILLAS TUCK SHOP, 155 NATALIE STREET EXT 37, EXT 37, DAWNPARK, DAWN PARK, South Africa','geo':{'lat':-26.30790,'lng':28.23990}},
{'stp':669200,'address':'669200, EGL - ISANDO, ISANDO EAST MAIN, BALEFE TAVERN, 3535 LEHA STREET, EXT 5, WINDMILL PARK, DAWN PARK, South Africa','geo':{'lat':-26.28840,'lng':28.27250}},
{'stp':669541,'address':'669541, EGL - ISANDO, ISANDO EAST MAIN, ANDRIESS PLACE, 1952 FISH EAGLE STREET, RAMAPHOSA, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23128,'lng':28.20563}},
{'stp':674463,'address':'674463, EGL - ISANDO, ISANDO EAST MAIN, BOKOBA, 75 PARROT STREET, VILLA LIZA, DAVEYTON, DAWN PARK, South Africa','geo':{'lat':-26.32160,'lng':28.25571}},
{'stp':700263,'address':'700263, EGL - ISANDO, ISANDO EAST MAIN, BE GOOD POOL PUB, 54 TUNNY ROAD, GROENEWEIDE, #, BOKSBURG, South Africa','geo':{'lat':-26.23066,'lng':28.27869}},
{'stp':716656,'address':'716656, EGL - ISANDO, ISANDO EAST MAIN, FISH EAGLE RESTAURANT, 1712 REIGER PARK, EXT 5 (KNOWN FINGER PARK), #, BOKSBURG, South Africa','geo':{'lat':-26.23100,'lng':28.20770}},
{'stp':720411,'address':'720411, EGL - ISANDO, ISANDO EAST MAIN, KUTAMA ENTERTAINMENT INN, 2189 RAMAPHOSA SECTION EXT 5, REIGER PARK, #, BOKSBURG, South Africa','geo':{'lat':-26.23200,'lng':28.20690}},
{'stp':746585,'address':'746585, EGL - ISANDO, ISANDO EAST MAIN, LIZZIE SHEBEEN, 406 STOCK STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23460,'lng':28.22080}},
{'stp':756763,'address':'756763, EGL - ISANDO, ISANDO EAST MAIN, STALLION LIQUARS GS12, FISHEAGLE STREET, ERF 1712, EXT 5 REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23120,'lng':28.20820}},
{'stp':759115,'address':'759115, EGL - ISANDO, ISANDO EAST MAIN, AUBREY S BOTTLE STORE, STAND NO, ERF 75 20 MALCOM AVE DELMORE PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.20723,'lng':28.19940}},
{'stp':760194,'address':'760194, EGL - ISANDO, ISANDO EAST MAIN, SHIRELYS TAVERN, DAVID FRANSCH STREET, ERF 308, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.23330,'lng':28.22470}},
{'stp':761203,'address':'761203, EGL - ISANDO, ISANDO EAST MAIN, I K S TORETTO\'S LIQUOR STORE, STAND NO, PORTION 4 OF ERF 207, BOKSBURG EAST, BOKSBURG, South Africa','geo':{'lat':-26.23332,'lng':28.22450}},
{'stp':766772,'address':'766772, EGL - ISANDO, ISANDO EAST MAIN, MOLOBI\'S TAVERN, 42 FLOUNDER STREET, ERF 121 DELMORE PARK, EXT 2, GERMISTON, South Africa','geo':{'lat':-26.21079,'lng':28.21160}},
{'stp':768887,'address':'768887, EGL - ISANDO, ISANDO EAST MAIN, MNAKA\'S CORNER, 2377  ISINZINZI STREET, EXT 5, REIGER PARK, BOKSBURG, South Africa','geo':{'lat':-26.22785,'lng':28.20980}},
{'stp':863000,'address':'863000, EGL - ISANDO, ISANDO EAST MAIN, CORRECTIONAL SERVICES BOKSBURG REC, BARRY MARAIS ROAD, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.27550,'lng':28.28640}},
{'stp':602978,'address':'602978, EGL - ISANDO, ISANDO EAST MAIN, FUNIS POZI, 8359 HLANGA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36370,'lng':28.37560}},
{'stp':626975,'address':'626975, EGL - ISANDO, ISANDO EAST MAIN, LUCKYS INN PERMIT, 29185 THULANE STREET, EXT 11, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36440,'lng':28.35260}},
{'stp':627202,'address':'627202, EGL - ISANDO, ISANDO EAST MAIN, MARIAS PLACE PERMIT, 6750 TLOKWA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35700,'lng':28.37940}},
{'stp':630377,'address':'630377, EGL - ISANDO, ISANDO EAST MAIN, T RE TAVERN, 4792 CORNWELL STREET, LANGAVILLE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.31790,'lng':28.38540}},
{'stp':636923,'address':'636923, EGL - ISANDO, ISANDO EAST MAIN, JB TAVERN, 30316 CHAUKE STREET, EXT 15 TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.37120,'lng':28.37400}},
{'stp':648291,'address':'648291, EGL - ISANDO, ISANDO EAST MAIN, JABULANI, 41335 MADIBA STREET, TSAKANE EXTENSION 19, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.36510,'lng':28.40360}},
{'stp':649559,'address':'649559, EGL - ISANDO, ISANDO EAST MAIN, SUN CITY TAVERN, 2821 HLOPE STREET, LANGAVILLE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.32230,'lng':28.38350}},
{'stp':380139,'address':'380139, EGL - ISANDO, ISANDO EAST MAIN, JOZI S TAVERN, 29349 ERF, #, #, TSAKANE, South Africa','geo':{'lat':-26.36145,'lng':28.35880}},
{'stp':385752,'address':'385752, EGL - ISANDO, ISANDO EAST MAIN, VUSI TAVERN, 436 STAND NO, KHUMALO STREET, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34967,'lng':28.38828}},
{'stp':390604,'address':'390604, EGL - ISANDO, ISANDO EAST MAIN, EMNANDINI INN, 1291 STAND NO, SOTWENI STREET, TSAKANE EXT 8, TSAKANE, South Africa','geo':{'lat':-26.37739,'lng':28.35441}},
{'stp':392380,'address':'392380, EGL - ISANDO, ISANDO EAST MAIN, CHILLAS LOUNGE, 43993 ERF, NO.816 CORNER, NZIMA AND NDABEZITHA STREET, #, TSAKANE, South Africa','geo':{'lat':-26.34304,'lng':28.38390}},
{'stp':392832,'address':'392832, EGL - ISANDO, ISANDO EAST MAIN, KOYI S TAVERN, 20878 NO, SHAKWANA STREET, #, TSAKANE, South Africa','geo':{'lat':-26.35693,'lng':28.35404}},
{'stp':463871,'address':'463871, EGL - ISANDO, ISANDO EAST MAIN, NCANE TAVERN, 1139 HLATYWAKO STREET, #, #, TSAKANE, South Africa','geo':{'lat':-26.35655,'lng':28.38791}},
{'stp':470942,'address':'470942, EGL - ISANDO, ISANDO EAST MAIN, SHOPRITE LIQUORSHOP 68373 TSAKANE C, 1 JABULANI STREET, CORNER TSAKANE CENTRE, TSAKANE EXT 11 ERF NO 21538, TSAKANE, South Africa','geo':{'lat':-26.35835,'lng':28.35441}},
{'stp':471800,'address':'471800, EGL - ISANDO, ISANDO EAST MAIN, GUGUS PLACE, CNR ZIKILAZI AND SENZENI STREETS, TSAKANE EXT 19, ERF 42247, TSAKANE, South Africa','geo':{'lat':-26.36362,'lng':28.39606}},
{'stp':478356,'address':'478356, EGL - ISANDO, ISANDO EAST MAIN, NKANDLA INN, 30890 NO, IMBALI STREET EXT 15, #, TSAKANE, South Africa','geo':{'lat':-26.37252,'lng':28.37876}},
{'stp':482345,'address':'482345, EGL - ISANDO, ISANDO EAST MAIN, THULA S TAVERN, 36526 STAND NUMBER, TSAKANE STREET, TYDELIKE NAAM STREET, TSAKANE, South Africa','geo':{'lat':-26.36848,'lng':28.38887}},
{'stp':483018,'address':'483018, EGL - ISANDO, ISANDO EAST MAIN, SIPHO S PLACE, 6386 STAND NUMBER, MPHAHLE STREET, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36464,'lng':28.37916}},
{'stp':484567,'address':'484567, EGL - ISANDO, ISANDO EAST MAIN, SONTOS INN, 3065 STAND NO, SHENGE STREET, TSAKANE BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.34649,'lng':28.38388}},
{'stp':484827,'address':'484827, EGL - ISANDO, ISANDO EAST MAIN, SAMSON LIQUOR DISTRIBUTOR, STAND NO, 3221, SABELO TSAKANE,, TSAKANE, South Africa','geo':{'lat':-26.36830,'lng':28.36497}},
{'stp':486138,'address':'486138, EGL - ISANDO, ISANDO EAST MAIN, MAHOLAS TAVERN, 21751 ERF NO, MSIPHA STREET, #, TSAKANE, South Africa','geo':{'lat':-26.35926,'lng':28.35895}},
{'stp':486274,'address':'486274, EGL - ISANDO, ISANDO EAST MAIN, SBONGULWAZI PALACE, 29798 ERF NO, TSOGANG STREET, EXTENTION 11, TSAKANE, South Africa','geo':{'lat':-26.36550,'lng':28.35271}},
{'stp':541406,'address':'541406, EGL - ISANDO, ISANDO EAST MAIN, SEVEN DAYS LIQUOR STORE, 43919 SIPHUMELELO STR, EXT 21, #, TSAKANE, South Africa','geo':{'lat':-26.36201,'lng':28.39279}},
{'stp':541489,'address':'541489, EGL - ISANDO, ISANDO EAST MAIN, SBONELO TAVERN, 28524 XHOSA STREET, EXTENSION 12B, #, TSAKANE, South Africa','geo':{'lat':-26.35884,'lng':28.38912}},
{'stp':542849,'address':'542849, EGL - ISANDO, ISANDO EAST MAIN, TSAKANE LIQUOR DISTRIBUTOR, 10347 ERF, NDABEZITHA STREET, #, TSAKANE, South Africa','geo':{'lat':-26.36934,'lng':28.36939}},
{'stp':545585,'address':'545585, EGL - ISANDO, ISANDO EAST MAIN, THA THA TAVERN, TOYI TOYI STREET, ERF 28515 CNR SOTHO AND, CNR SOTHO AND TOYI TOYI STREET, TSAKANE, South Africa','geo':{'lat':-26.36273,'lng':28.38523}},
{'stp':550312,'address':'550312, EGL - ISANDO, ISANDO EAST MAIN, KHOC\'S TAVERN,  CNR NKOWANKOWA & BAYETHE STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35400,'lng':28.36530}},
{'stp':551608,'address':'551608, EGL - ISANDO, ISANDO EAST MAIN, THETHE S PLACE, 15736 THANOTZ STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35420,'lng':28.36470}},
{'stp':551639,'address':'551639, EGL - ISANDO, ISANDO EAST MAIN, SPIDERS ROCK INN, 9964 NOGA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37279,'lng':28.36768}},
{'stp':552271,'address':'552271, EGL - ISANDO, ISANDO EAST MAIN, KWATSADUZA BOTTLE STORE, CNR LERATO & NDABEZITHA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34261,'lng':28.38352}},
{'stp':553486,'address':'553486, EGL - ISANDO, ISANDO EAST MAIN, SIYABONGA LIQUOR STORE, 9755 PHALANE STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37120,'lng':28.36940}},
{'stp':553859,'address':'553859, EGL - ISANDO, ISANDO EAST MAIN, JAZZ GLO PERMIT, 4471 GAIKA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35310,'lng':28.37820}},
{'stp':553866,'address':'553866, EGL - ISANDO, ISANDO EAST MAIN, JOHN\'S PLACE, 6673 HLAKWANA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36100,'lng':28.37930}},
{'stp':580404,'address':'580404, EGL - ISANDO, ISANDO EAST MAIN, MANYEKA INN, 2303 EXTENSION 10, TSAKANE  SECTION, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.38670,'lng':28.37630}},
{'stp':580809,'address':'580809, EGL - ISANDO, ISANDO EAST MAIN, TILLYS INN, 6403 KGARI STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36350,'lng':28.37770}},
{'stp':581046,'address':'581046, EGL - ISANDO, ISANDO EAST MAIN, MJ MASHELE WHOLESALER,  44 MASHELE STREET, EXT 8, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36799,'lng':28.35075}},
{'stp':581125,'address':'581125, EGL - ISANDO, ISANDO EAST MAIN, PAULINAS TAVERN, 32519 RAMAPHOSA STREET, EXT 12, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36140,'lng':28.36790}},
{'stp':602965,'address':'602965, EGL - ISANDO, ISANDO EAST MAIN, MOSES MOROLO, 7370 PHUTHI STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36160,'lng':28.37580}},
{'stp':602967,'address':'602967, EGL - ISANDO, ISANDO EAST MAIN, SIMANGA NDHLOVU, 15341 BANANA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35480,'lng':28.36330}},
{'stp':602968,'address':'602968, EGL - ISANDO, ISANDO EAST MAIN, EMMA MATHABATHA, 4357 XHOSA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35300,'lng':28.38160}},
{'stp':602969,'address':'602969, EGL - ISANDO, ISANDO EAST MAIN, MONTGOMERY SIBIYA, 30281 MTHUNZI STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36645,'lng':28.35914}},
{'stp':602979,'address':'602979, EGL - ISANDO, ISANDO EAST MAIN, G P S INN, 8893 ROKA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36060,'lng':28.36980}},
{'stp':602988,'address':'602988, EGL - ISANDO, ISANDO EAST MAIN, PULES PLACE, 10398 BUTHELEZI STREET, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.36560,'lng':28.37260}},
{'stp':602999,'address':'602999, EGL - ISANDO, ISANDO EAST MAIN, BHEKI DLAMINI, 18566  EXTENSION 8, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36770,'lng':28.36150}},
{'stp':603005,'address':'603005, EGL - ISANDO, ISANDO EAST MAIN, SIZAS PLACE, 29244 SIZANANI STREET, TSAKANE EXT 11, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36270,'lng':28.35700}},
{'stp':603007,'address':'603007, EGL - ISANDO, ISANDO EAST MAIN, BUSISIWE A BLOSE, 29664 SABEO STREET EXT 11, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36380,'lng':28.35750}},
{'stp':603026,'address':'603026, EGL - ISANDO, ISANDO EAST MAIN, MTENGO MHLONGO, 13356 EXTENSION 9, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.38086,'lng':28.37965}},
{'stp':603188,'address':'603188, EGL - ISANDO, ISANDO EAST MAIN, BECCAS INN, 6834 KGAGA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35773,'lng':28.37862}},
{'stp':603355,'address':'603355, EGL - ISANDO, ISANDO EAST MAIN, MAMIKIES INN, 32684 PHOLA STREET  EXT 12, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36491,'lng':28.36791}},
{'stp':603675,'address':'603675, EGL - ISANDO, ISANDO EAST MAIN, BUSIS TAVERN, 8342 HLANGA STR, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36504,'lng':28.37398}},
{'stp':604011,'address':'604011, EGL - ISANDO, ISANDO EAST MAIN, PHUMIS PLACE, 13459 MAJA STREET, EXT 9, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.38110,'lng':28.38080}},
{'stp':604526,'address':'604526, EGL - ISANDO, ISANDO EAST MAIN, CHISANPAMA, 11557 GAMA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34190,'lng':28.38530}},
{'stp':605078,'address':'605078, EGL - ISANDO, ISANDO EAST MAIN, PHUTHASONS PLACE, 9569  KUBU STR, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36940,'lng':28.37180}},
{'stp':605140,'address':'605140, EGL - ISANDO, ISANDO EAST MAIN, LINDI PLACE, 6260 LWAMONDO STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36240,'lng':28.38010}},
{'stp':606044,'address':'606044, EGL - ISANDO, ISANDO EAST MAIN, MABENAS INN,  CNR30975 INGANYAMA & GUGULESIZWE, TSAKANE, Extension 15, TSAKANE, South Africa','geo':{'lat':-26.37870,'lng':28.38400}},
{'stp':606150,'address':'606150, EGL - ISANDO, ISANDO EAST MAIN, KODISANG INN, 8015 KGAGA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35860,'lng':28.37230}},
{'stp':606725,'address':'606725, EGL - ISANDO, ISANDO EAST MAIN, KUBHAYI SPAZA, 8020 KGAGA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35850,'lng':28.37160}},
{'stp':607239,'address':'607239, EGL - ISANDO, ISANDO EAST MAIN, KHANYILE TAVERN, 35404 EXT 13, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36680,'lng':28.38020}},
{'stp':607278,'address':'607278, EGL - ISANDO, ISANDO EAST MAIN, LOLYS INN, 17181 MODIBA STREET, EXT 8, THEMBELISHA, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37550,'lng':28.34800}},
{'stp':607351,'address':'607351, EGL - ISANDO, ISANDO EAST MAIN, ALFREDS PLACE, 8745 MODJADJI STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35935,'lng':28.36980}},
{'stp':607472,'address':'607472, EGL - ISANDO, ISANDO EAST MAIN, BATSHELENI INN, 16442 MASHININI STREET EXT 5 B, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35730,'lng':28.36420}},
{'stp':607498,'address':'607498, EGL - ISANDO, ISANDO EAST MAIN, MHLONGOS TAVERN, 19115 KHUZWAYO STREET, EXTENSION 8, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37040,'lng':28.35500}},
{'stp':626611,'address':'626611, EGL - ISANDO, ISANDO EAST MAIN, RAMAHUDUS INN PERMIT, 13669 XABA STREET, EXT 9, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.38064,'lng':28.38213}},
{'stp':626727,'address':'626727, EGL - ISANDO, ISANDO EAST MAIN, MAFAS PLACE PERMIT, 21284 PILO STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35850,'lng':28.35120}},
{'stp':627176,'address':'627176, EGL - ISANDO, ISANDO EAST MAIN, THEMBIS PLACE PERMIT, 775 MABUNDA STREET, EXT 8, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37090,'lng':28.35890}},
{'stp':627850,'address':'627850, EGL - ISANDO, ISANDO EAST MAIN, THREE SISTERS PLACE PERMIT, 16500 ROBERT SOBUKWE STREET, EXT 5, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36452,'lng':28.34528}},
{'stp':628703,'address':'628703, EGL - ISANDO, ISANDO EAST MAIN, PEOPLES TAVERN PERMIT, 7616 PEDI STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35670,'lng':28.37430}},
{'stp':628812,'address':'628812, EGL - ISANDO, ISANDO EAST MAIN, NOMSESIS TAVERN PERMIT, 11296 MNISI STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34820,'lng':28.38890}},
{'stp':628837,'address':'628837, EGL - ISANDO, ISANDO EAST MAIN, SHWIS INN PERMIT, 29869 TSOGANG STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36560,'lng':28.35270}},
{'stp':628838,'address':'628838, EGL - ISANDO, ISANDO EAST MAIN, DECOS PLACE PERMIT, 7989 PEDI STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35530,'lng':28.37390}},
{'stp':628839,'address':'628839, EGL - ISANDO, ISANDO EAST MAIN, MASG, 11653 NZIMA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34230,'lng':28.38670}},
{'stp':629551,'address':'629551, EGL - ISANDO, ISANDO EAST MAIN, SENZAS CHOICE PERMIT, 18406 EXT 8, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37280,'lng':28.36180}},
{'stp':630437,'address':'630437, EGL - ISANDO, ISANDO EAST MAIN, ZITHOBENI INN, 825 NTSHANGASE STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34960,'lng':28.38420}},
{'stp':630540,'address':'630540, EGL - ISANDO, ISANDO EAST MAIN, MOTSERERE TAVERN PERMIT, 2990 SHEZI STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34660,'lng':28.38500}},
{'stp':630542,'address':'630542, EGL - ISANDO, ISANDO EAST MAIN, JOHNS TAVERN, 35169 SIBIYA STREET, EXT 13, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36420,'lng':28.38110}},
{'stp':630544,'address':'630544, EGL - ISANDO, ISANDO EAST MAIN, MALUMES TAVERN, 6676 HLAKOANA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36060,'lng':28.37890}},
{'stp':632171,'address':'632171, EGL - ISANDO, ISANDO EAST MAIN, GIRLIES INN, 18737 NHLANGULA STR, EXT 8, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37080,'lng':28.36090}},
{'stp':635750,'address':'635750, EGL - ISANDO, ISANDO EAST MAIN, DUDU TAVERN, 17061 EXTENTION 13, THEMBELISHA, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37450,'lng':28.34530}},
{'stp':636100,'address':'636100, EGL - ISANDO, ISANDO EAST MAIN, MAMAZOZOS TAVERN, 177 MAPHUNGULA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36980,'lng':28.35320}},
{'stp':636121,'address':'636121, EGL - ISANDO, ISANDO EAST MAIN, PHALAFALA TAVERN, 781 MABUNZA STREET, TSAKANE EXTENSION 8, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37173,'lng':28.35876}},
{'stp':636551,'address':'636551, EGL - ISANDO, ISANDO EAST MAIN, BULIS TAVERN, 31524 MOKOENA STREET, EXT 15 TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37403,'lng':28.37963}},
{'stp':636578,'address':'636578, EGL - ISANDO, ISANDO EAST MAIN, PAT MATSHIKIZA TAVERN, 16749 GOVERN MBEKI STR, EXT 5 TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36130,'lng':28.36220}},
{'stp':637648,'address':'637648, EGL - ISANDO, ISANDO EAST MAIN, KHAYAS TAVERN, 13046 EKUTHULENI STREET, EXT  9 TASKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37790,'lng':28.37640}},
{'stp':638563,'address':'638563, EGL - ISANDO, ISANDO EAST MAIN, LYDIAS INN, 1914 NGCOBO STREET, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.35660,'lng':28.38390}},
{'stp':641372,'address':'641372, EGL - ISANDO, ISANDO EAST MAIN, RONALDO TAVERN, 36104 ZULU STREET, TSAKANE EXT 16, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36950,'lng':28.38150}},
{'stp':642584,'address':'642584, EGL - ISANDO, ISANDO EAST MAIN, BOBO PLACE, 18101 MAMABOLO STREET, EXT 8, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.37035,'lng':28.35153}},
{'stp':642723,'address':'642723, EGL - ISANDO, ISANDO EAST MAIN, SIZWE\'S TAVERN, 30809 NDALA  STREET, TSAKANE EXT 15, TSAKANE EXT 15, TSAKANE, South Africa','geo':{'lat':-26.37374,'lng':28.37743}},
{'stp':646876,'address':'646876, EGL - ISANDO, ISANDO EAST MAIN, ZWIE TAVERN,  35281 35281 TSOTETSI STREET, EXT 13 TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.36487,'lng':28.38151}},
{'stp':647283,'address':'647283, EGL - ISANDO, ISANDO EAST MAIN, TINA\'S TAVERN, 13085 LEHOLA STREET EXTENSION 9, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.37890,'lng':28.37640}},
{'stp':647371,'address':'647371, EGL - ISANDO, ISANDO EAST MAIN, NTOMBI\'S TAVERN, 484    MOTSOAGAE STREET, EXT 8    TSAKANA, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.37160,'lng':28.35060}},
{'stp':648045,'address':'648045, EGL - ISANDO, ISANDO EAST MAIN, CHILLAS PUB, CHIBAS CAR WASH & PUB, 813/814  NZIMA STREET, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.34337,'lng':28.38383}},
{'stp':648138,'address':'648138, EGL - ISANDO, ISANDO EAST MAIN, BUTIZA\'S TAVERN, 13479 XABA STREET, EXTENSION 9, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.38280,'lng':28.37870}},
{'stp':648252,'address':'648252, EGL - ISANDO, ISANDO EAST MAIN, KWA BUTHELEZI, 120 PHELANDABA STREET, EXTENSION 8, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36760,'lng':28.35390}},
{'stp':648262,'address':'648262, EGL - ISANDO, ISANDO EAST MAIN, KA NELLY, 8954 KGOADI STREET, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.36290,'lng':28.37210}},
{'stp':648314,'address':'648314, EGL - ISANDO, ISANDO EAST MAIN, LETLHABILE BOTTLE STORE, 109 LETSWALO STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36250,'lng':28.37280}},
{'stp':648617,'address':'648617, EGL - ISANDO, ISANDO EAST MAIN, VOLSOO, 15653 THWASANA STREET, EXT 05, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35160,'lng':28.36540}},
{'stp':648727,'address':'648727, EGL - ISANDO, ISANDO EAST MAIN, DANI\'S PLACE, 16362 EXT 05C, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36430,'lng':28.36180}},
{'stp':648950,'address':'648950, EGL - ISANDO, ISANDO EAST MAIN, THANDI\'S TAVERN, 30699 NGWENYA STREET, EXT 15, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37450,'lng':28.37700}},
{'stp':649096,'address':'649096, EGL - ISANDO, ISANDO EAST MAIN, MAILE\'S CORNER, 30479 ED VAN DER HEERDEN STREET, EXT 15, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37320,'lng':28.37350}},
{'stp':649312,'address':'649312, EGL - ISANDO, ISANDO EAST MAIN, GAZI TAVERN, 28448 EXT 12B, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35720,'lng':28.38870}},
{'stp':649565,'address':'649565, EGL - ISANDO, ISANDO EAST MAIN, JOYCE\'S INN, 36619 EXT 17, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36610,'lng':28.38440}},
{'stp':649575,'address':'649575, EGL - ISANDO, ISANDO EAST MAIN, TSALANANG, 8407 NARE STREET, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.36230,'lng':28.37400}},
{'stp':653472,'address':'653472, EGL - ISANDO, ISANDO EAST MAIN, SHUKUSHUKUMA TAVERN, ERF 32729, TSAKANE EXTENSION 12, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.36550,'lng':28.36590}},
{'stp':654322,'address':'654322, EGL - ISANDO, ISANDO EAST MAIN, MNAKE TAVERN, 1281A SOLOMON STREET, EXTENSION 10, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.38465,'lng':28.37495}},
{'stp':654486,'address':'654486, EGL - ISANDO, ISANDO EAST MAIN, PHOKENG INN, 27647 NAMU STREET, TSAKANE, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.34964,'lng':28.39309}},
{'stp':656820,'address':'656820, EGL - ISANDO, ISANDO EAST MAIN, GIVANA ENTERPRISES (NTO), 922 MATHIBA STREET, DEVON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.21047,'lng':28.46174}},
{'stp':674462,'address':'674462, EGL - ISANDO, ISANDO EAST MAIN, BUSY CORNER, JUMBA STREET, ERF 6259D, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36220,'lng':28.37967}},
{'stp':693387,'address':'693387, EGL - ISANDO, ISANDO EAST MAIN, PAPI, 10696  NZIMA STREET, #, #, TSAKANE, South Africa','geo':{'lat':-26.34590,'lng':28.37750}},
{'stp':697703,'address':'697703, EGL - ISANDO, ISANDO EAST MAIN, THUJANE TRADING WHOLESALERS, 1265 SONTWENI STREET, TSAKANE EXT 8, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.37780,'lng':28.35190}},
{'stp':705153,'address':'705153, EGL - ISANDO, ISANDO EAST MAIN, SAMPHI S PLACE, 13272 LUTHULI STREET, #, #, TSAKANE, South Africa','geo':{'lat':-26.38200,'lng':28.37740}},
{'stp':706497,'address':'706497, EGL - ISANDO, ISANDO EAST MAIN, MALUME TAVERN, 8295 PEDI STREET, TSAKANE, #, TSAKANE, South Africa','geo':{'lat':-26.36138,'lng':28.37490}},
{'stp':706833,'address':'706833, EGL - ISANDO, ISANDO EAST MAIN, JAMZARS HAPPY VALLEY, 10296A BHALA AND FINGO STREETS, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35400,'lng':28.38000}},
{'stp':717952,'address':'717952, EGL - ISANDO, ISANDO EAST MAIN, ETSHANINI TAVERN, 38530 GWEBU STREET, #, #, TSAKANE, South Africa','geo':{'lat':-26.34080,'lng':28.38830}},
{'stp':718009,'address':'718009, EGL - ISANDO, ISANDO EAST MAIN, GUGU S TAVERN, ERF 27686 MOLOPO AVENUE, EXT 12, #, TSAKANE, South Africa','geo':{'lat':-26.34750,'lng':28.39290}},
{'stp':719842,'address':'719842, EGL - ISANDO, ISANDO EAST MAIN, MLOTSHWAS TAVERN, ERF 42769, DIMAKATSO STREET, TSAKANE EXTENSION 20, TSAKANE, South Africa','geo':{'lat':-26.36905,'lng':28.39302}},
{'stp':732081,'address':'732081, EGL - ISANDO, ISANDO EAST MAIN, DORAHS TAVERN, ERF 42043, TSAKANE EXTENSION 19, #, TSAKANE, South Africa','geo':{'lat':-26.36160,'lng':28.39920}},
{'stp':735323,'address':'735323, EGL - ISANDO, ISANDO EAST MAIN, ALPHUIS TAVERN, ERF 36698, EXT 17, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36521,'lng':28.38567}},
{'stp':760094,'address':'760094, EGL - ISANDO, ISANDO EAST MAIN, NTOMBIFUTHIS PLACE, HLANGANANI STREET, ERF 32194, TSAKANE EXTENSION 12, TSAKANE, South Africa','geo':{'lat':-26.36625,'lng':28.36532}},
{'stp':776213,'address':'776213, EGL - ISANDO, ISANDO EAST MAIN, AUISI S TAVERN, 21743 MSIPHA STREET, EXT 11, #, TSAKANE, South Africa','geo':{'lat':-26.35927,'lng':28.35663}},
{'stp':776301,'address':'776301, EGL - ISANDO, ISANDO EAST MAIN, MALINDI S TAVERN, 37192 ERF, CORNWELL STREET, EXT 17, TSAKANE, South Africa','geo':{'lat':-26.36848,'lng':28.38893}},
{'stp':778416,'address':'778416, EGL - ISANDO, ISANDO EAST MAIN, TLAKA S PLACE, TOYI TOYI STREET, ERF 28504, #, TSAKANE, South Africa','geo':{'lat':-26.36181,'lng':28.38606}},
{'stp':783734,'address':'783734, EGL - ISANDO, ISANDO EAST MAIN, THEMBAS PLACE, KHUMALO STREET, ERF 402, #, TSAKANE, South Africa','geo':{'lat':-26.34970,'lng':28.38829}},
{'stp':785411,'address':'785411, EGL - ISANDO, ISANDO EAST MAIN, MVEMVE TAVERN, 35307 SIBIYA STREET, #, #, TSAKANE, South Africa','geo':{'lat':-26.36688,'lng':28.37871}},
{'stp':786977,'address':'786977, EGL - ISANDO, ISANDO EAST MAIN, SHOPRITE LIQSHOP TSAKANE CNR 87200, 41234 STAND NO, CNR MADIBA AND FEZELA STREET, TSAKANE EXT 19, TSAKANE, South Africa','geo':{'lat':-26.36476,'lng':28.40019}},
{'stp':792376,'address':'792376, EGL - ISANDO, ISANDO EAST MAIN, TEBZA TAVERN, 41511 STAND NO, FEZELA STREET TSAKANE, #, TSAKANE, South Africa','geo':{'lat':-26.36678,'lng':28.39925}},
{'stp':312712,'address':'312712, EGL - ISANDO, ISANDO EAST HYBRID, PNP GC 84- PNP LIQUOR STORE SPRINGS, stand no, wit and jan smuts roads, CASSELDALE EXT 4, SPRINGS, South Africa','geo':{'lat':-26.27405,'lng':28.45125}},
{'stp':315785,'address':'315785, EGL - ISANDO, ISANDO EAST HYBRID, CHECKERS LIQUOR SHOP- SPRING MALL 9, 1257 ERF NO, 1 WIT ROAD, CORNER N17, SPRINGS, South Africa','geo':{'lat':-26.27651,'lng':28.45375}},
{'stp':318485,'address':'318485, EGL - ISANDO, ISANDO EAST HYBRID, VAN CI LIQUOR STORE, 200 STAND NO, NIGEL ROAD SELCOURT, X110000001524, SPRINGS, South Africa','geo':{'lat':-26.30333,'lng':28.44672}},
{'stp':319894,'address':'319894, EGL - ISANDO, ISANDO EAST HYBRID, TUSCALOOSA SPUR (NTO), SPRINGS MALL, SH10L053, JAN SMUTS R, #, #, SPRINGS, South Africa','geo':{'lat':-26.27557,'lng':28.45443}},
{'stp':381438,'address':'381438, EGL - ISANDO, ISANDO EAST HYBRID, WSJ LIQUORS STORE, 16 STAND NO, MEYER DRIVE, EKURHULENI, SPRINGS, South Africa','geo':{'lat':-26.26283,'lng':28.40026}},
{'stp':384686,'address':'384686, EGL - ISANDO, ISANDO EAST HYBRID, SELCOURT TAVERN, 200 STAND NUMBER, 200-202, NIGEL ROAD ERF 305 AND ERF 1524, SPRINGS, South Africa','geo':{'lat':-26.30334,'lng':28.44670}},
{'stp':388018,'address':'388018, EGL - ISANDO, ISANDO EAST HYBRID, THE VINYARD, STADN NUMBER, ERF 9 NUMBER 24, SPRINGS WEST ROAD, SPRINGS, South Africa','geo':{'lat':-26.26475,'lng':28.43519}},
{'stp':388205,'address':'388205, EGL - ISANDO, ISANDO EAST HYBRID, LUCKY SAFARI, 57D STAND NO, OPPENHEIMER CIRCLE, SELECTION PARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.28932,'lng':28.44420}},
{'stp':388877,'address':'388877, EGL - ISANDO, ISANDO EAST HYBRID, PICK N PAY LIQURO STORE SHARON PARK, STAND NO, SHARON PARK S/CENTRE CNR MAITLAND, #, SPRINGS, South Africa','geo':{'lat':-26.32540,'lng':28.44560}},
{'stp':388891,'address':'388891, EGL - ISANDO, ISANDO EAST HYBRID, BERKETON BOTTLE STORE, 24 STAND NO, 1ST AVENUE, ERF 1162, #, SPRINGS, South Africa','geo':{'lat':-26.22487,'lng':28.46770}},
{'stp':389073,'address':'389073, EGL - ISANDO, ISANDO EAST HYBRID, THE RANCH ROAD HOUSE, 47 STAND NO, STOFBERG ROAD, PERSIDA, SPRINGS, South Africa','geo':{'lat':-26.18977,'lng':28.51616}},
{'stp':389079,'address':'389079, EGL - ISANDO, ISANDO EAST HYBRID, JOHN DORY\'S SPRINGS MALL (NTO), ERF 1257: SHOP L54, SPRINGS MALL, NORTH OF N17, CNR WIT & JAN SMUTS, CASSELDALE EXT 4, SPRINGS, South Africa','geo':{'lat':-26.27639,'lng':28.45353}},
{'stp':389613,'address':'389613, EGL - ISANDO, ISANDO EAST HYBRID, TOP CENTRE CLUB, 54 STAND NO, ERF NO 237, SECOUND STREET SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25504,'lng':28.43867}},
{'stp':390810,'address':'390810, EGL - ISANDO, ISANDO EAST HYBRID, LEMONA LIQUOR STORE, 02 SHOP NO, LEMONA COURT NO 35, IRVING STETN CNR, SPRINGS, South Africa','geo':{'lat':-26.26592,'lng':28.46609}},
{'stp':393003,'address':'393003, EGL - ISANDO, ISANDO EAST HYBRID, UHURU CLUB 1941, 24 STAND NO, 2ND AVENUE WELGEDACHT, #, SPRINGS, South Africa','geo':{'lat':-26.20584,'lng':28.49434}},
{'stp':393090,'address':'393090, EGL - ISANDO, ISANDO EAST HYBRID, FLORA HILL (NTO), ERVEN 891, NO. 16 & 18 COWLESS STRE, ROWHILL, #, SPRINGS, South Africa','geo':{'lat':-26.22547,'lng':28.42607}},
{'stp':393461,'address':'393461, EGL - ISANDO, ISANDO EAST HYBRID, OVERLAND EXPRESS AVENUE, 38 SHOP NO, 6TH AVENUE THE AVENUES SHOPPING CENTRE, SPRINGS CBD, SPRINGS, South Africa','geo':{'lat':-26.25547,'lng':28.44178}},
{'stp':466464,'address':'466464, EGL - ISANDO, ISANDO EAST HYBRID, LEGACY LIQUOR STORE, 3 STAND NO, RIVERFIELD SHOPPING CENTRE ERF 101, VAAL DRIVE CORNER CROCODILE STREET, SPRINGS, South Africa','geo':{'lat':-26.23396,'lng':28.44085}},
{'stp':473826,'address':'473826, EGL - ISANDO, ISANDO EAST HYBRID, SELECTION PARK LIQUOR STORE, 92 NIGEL ROAD, ERF 316, SHOP 01, SELECTION PARK, SPRINGS, South Africa','geo':{'lat':-26.28030,'lng':28.44313}},
{'stp':477852,'address':'477852, EGL - ISANDO, ISANDO EAST HYBRID, QUENCH CORNER, 01 SHOP NO, 189 B NIGEL ROAD SELCOURT, #, SPRINGS, South Africa','geo':{'lat':-26.30329,'lng':28.44717}},
{'stp':547798,'address':'547798, EGL - ISANDO, ISANDO EAST HYBRID, FRANCAS TAVERN, 154 STAND NO, 31 AVENUE, SPRINGS EXTENSION, SPRINGS, South Africa','geo':{'lat':-26.25820,'lng':28.43580}},
{'stp':550036,'address':'550036, EGL - ISANDO, ISANDO EAST HYBRID, BAKERTON LIQUOR STORE, 1749 FIRST STREET EXT 4, BAKERTON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.22328,'lng':28.46746}},
{'stp':550528,'address':'550528, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR LEGENDS SPRINGSGATE, 0001 STAND NO, CORNER PAUL KRUGER, AND CONNAUGHT, SPRINGS, South Africa','geo':{'lat':-26.23530,'lng':28.42270}},
{'stp':550703,'address':'550703, EGL - ISANDO, ISANDO EAST HYBRID, SELECTION LODGE AND CONFERENCE CENT, 36 ERMELO ROAD, CASSELDALE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25671,'lng':28.46645}},
{'stp':550952,'address':'550952, EGL - ISANDO, ISANDO EAST HYBRID, PARK LIQUOR STORE, CNR OPPENHEIMER & BRINK STREET, SELECTION PARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.28930,'lng':28.44610}},
{'stp':551128,'address':'551128, EGL - ISANDO, ISANDO EAST HYBRID, LOGG INN RESTAURANT, CNR KRAANVOEL & HAMMERSKOP RD, DAGGAFONTEIN, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.28510,'lng':28.47810}},
{'stp':551333,'address':'551333, EGL - ISANDO, ISANDO EAST HYBRID, JOAOS BOTTLE STORE, 198 CHARTERLAND AVE, ERF 321 SELCOURT, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.30390,'lng':28.44730}},
{'stp':551375,'address':'551375, EGL - ISANDO, ISANDO EAST HYBRID, STRUBENVALE BOTTLE STORE, 3 LARGO WEG, STRUBENVALE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25750,'lng':28.46890}},
{'stp':551498,'address':'551498, EGL - ISANDO, ISANDO EAST HYBRID, POLLAK PARK GOLF CLUB, PTN 120 RIETFONTEIN 128, POLLAK PARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.26940,'lng':28.42677}},
{'stp':551575,'address':'551575, EGL - ISANDO, ISANDO EAST HYBRID, STARDUST HOTEL, 114 3RD AVENUE, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25825,'lng':28.43940}},
{'stp':551753,'address':'551753, EGL - ISANDO, ISANDO EAST HYBRID, FOUR SISTER LIQUOR STORE, 839 STAND NO, 130 CNR ERMELO, CNR  FRYER ROAD, SPRINGS, South Africa','geo':{'lat':-26.26734,'lng':28.47263}},
{'stp':551812,'address':'551812, EGL - ISANDO, ISANDO EAST HYBRID, DJ S RESTAURANT, 541 ERF NO, 27 4th STREET, #, SPRINGS, South Africa','geo':{'lat':-26.25370,'lng':28.44010}},
{'stp':552072,'address':'552072, EGL - ISANDO, ISANDO EAST HYBRID, PARK HOTEL SPRINGS, 124 NIGEL AVENUE, SELECTION PARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.28880,'lng':28.44470}},
{'stp':552195,'address':'552195, EGL - ISANDO, ISANDO EAST HYBRID, HYPER LIQUOR MARKET, 4 1ST AVENUE, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.20542,'lng':28.49592}},
{'stp':552243,'address':'552243, EGL - ISANDO, ISANDO EAST HYBRID, THE POINT RESTAURANT AND COCKTAIL B, 157 SECOND STREET, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.26150,'lng':28.43870}},
{'stp':552329,'address':'552329, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR CITY GEDULD, CNR FOURTH AVENUE AND SIXTH STR, GEDULD, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24360,'lng':28.42910}},
{'stp':552693,'address':'552693, EGL - ISANDO, ISANDO EAST HYBRID, HORSE AND HOUND, 2 COUNCILS RESIDENCE, PRESIDENT PARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.21520,'lng':28.41360}},
{'stp':552713,'address':'552713, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGBOK LIQUOR WAREHOUSE, 7 ERMELO ROAD, STRUBENVALE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25430,'lng':28.45940}},
{'stp':552823,'address':'552823, EGL - ISANDO, ISANDO EAST HYBRID, JOSE S RESTAURANT AND TAKE AWAY, 96 BUTLER STREET, NUFFIELD, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.28850,'lng':28.45990}},
{'stp':552836,'address':'552836, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGWOOD BOWLING CLUB, GILLESPIE ROAD, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.26730,'lng':28.43510}},
{'stp':552906,'address':'552906, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGS RUGBY CLUB, VYFDE LAAN, OLYMPIA PARK STADIUN, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24720,'lng':28.43070}},
{'stp':552972,'address':'552972, EGL - ISANDO, ISANDO EAST HYBRID, KRUIN DRANKWINKEL, 95 STAND NUMBER, 4TH AVENUE, GEDULD, SPRINGS, South Africa','geo':{'lat':-26.24330,'lng':28.42730}},
{'stp':553361,'address':'553361, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGS GOLF AND SPORTS FACILITY, STAND NO, PORTION 1 OF PORTION K THE FARM, RIETFONTEIN, SPRINGS, South Africa','geo':{'lat':-26.26480,'lng':28.44250}},
{'stp':553468,'address':'553468, EGL - ISANDO, ISANDO EAST HYBRID, PRAIRE HAWK SPUR, PAUL KRUGER HIGHWAY, GEDULD, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.23420,'lng':28.42180}},
{'stp':553754,'address':'553754, EGL - ISANDO, ISANDO EAST HYBRID, SELCOURT TOPS, 197 NIGEL ROAD, SELCOURT, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.30500,'lng':28.44730}},
{'stp':553903,'address':'553903, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR EXPRESS, 729 STAND NUMBER, SHOP NUMBER 211, QUITENIZUA STREET, BRAKPAN, South Africa','geo':{'lat':-26.18510,'lng':28.43540}},
{'stp':561303,'address':'561303, EGL - ISANDO, ISANDO EAST HYBRID, THE SPORTS BAR, 133 C CLYDESDALE ROAD, CASSELDALE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.27230,'lng':28.46520}},
{'stp':605877,'address':'605877, EGL - ISANDO, ISANDO EAST HYBRID, VOLUME 2 THE CLUB, 30 FOURTH STREET, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25320,'lng':28.44040}},
{'stp':606051,'address':'606051, EGL - ISANDO, ISANDO EAST HYBRID, J TS DINER, 127 A CLYDESDALE ROAD, CASSELDALE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.27230,'lng':28.46520}},
{'stp':630797,'address':'630797, EGL - ISANDO, ISANDO EAST HYBRID, KAPS LIQUOR STORE, 1741 SECOND STREET, BAKERTON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.22328,'lng':28.46746}},
{'stp':635089,'address':'635089, EGL - ISANDO, ISANDO EAST HYBRID, HEADQUATERS PUB AND GRILL, 39 7TH STREET, #, #, SPRINGS, South Africa','geo':{'lat':-26.25430,'lng':28.44270}},
{'stp':636805,'address':'636805, EGL - ISANDO, ISANDO EAST HYBRID, PETERSFIELD LIQUOR STORE, 51 KRUGER STREET, PETERSFIELD, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.23370,'lng':28.43060}},
{'stp':637203,'address':'637203, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGS CASH AND CARRY, ERF  186, 1 VLAKFONTEIN ROAD, #, SPRINGS, South Africa','geo':{'lat':-26.26968,'lng':28.40443}},
{'stp':637280,'address':'637280, EGL - ISANDO, ISANDO EAST HYBRID, THE LIQUOR FELLAS, 9 SHOP NO, 7 HEWITT STREET, CORNER COATON, SPRINGS, South Africa','geo':{'lat':-26.29370,'lng':28.45130}},
{'stp':637566,'address':'637566, EGL - ISANDO, ISANDO EAST HYBRID, VOLUME TWO LIQUOR STORE,  28 FOURTH ST, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25330,'lng':28.44040}},
{'stp':639699,'address':'639699, EGL - ISANDO, ISANDO EAST HYBRID, POPULAR PUB (NTO), 3RD STREET, GEDULD, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24205,'lng':28.43369}},
{'stp':639700,'address':'639700, EGL - ISANDO, ISANDO EAST HYBRID, THE DECK PUB (NTO), CNR PHOENIX & COATEN STS, SELPARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.29551,'lng':28.43531}},
{'stp':641024,'address':'641024, EGL - ISANDO, ISANDO EAST HYBRID, EDELWEISS LIQUORSTORE, 798 STAND NO, 9 PROTEA AVENUE, EDELWEISS, SPRINGS, South Africa','geo':{'lat':-26.28540,'lng':28.47020}},
{'stp':641639,'address':'641639, EGL - ISANDO, ISANDO EAST HYBRID, TOPS AT CASSELDALE, 131 CLYDESDALE ROAD, CASSELDALE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.27220,'lng':28.46540}},
{'stp':651586,'address':'651586, EGL - ISANDO, ISANDO EAST HYBRID, CARIBBEAN LIQUORS, CNR GRANT STREET & MAIN REEF ROAD, NEW STATE AREA, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.23880,'lng':28.40580}},
{'stp':652248,'address':'652248, EGL - ISANDO, ISANDO EAST HYBRID, ACTION SPORTS SPRINGS, EAST WING OF SPRINGS INDOOR SPORTS, CNR SOUTH MAIN REEF ROAD AND ANGELL STR, PORTION OF ERF 1853,EXT 6 AND ERF 1678, SPRINGS, South Africa','geo':{'lat':-26.25460,'lng':28.43550}},
{'stp':656605,'address':'656605, EGL - ISANDO, ISANDO EAST HYBRID, CHEMONIQUES PUB (NTO), SOUTH MAIN REEF ROAD, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25560,'lng':28.41987}},
{'stp':657432,'address':'657432, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR RAMA, CNR 2ND STREET AND 9TH AVENUE, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.26230,'lng':28.43900}},
{'stp':667628,'address':'667628, EGL - ISANDO, ISANDO EAST HYBRID, GOLDEN NUGGET RESTAURANT (NTO), RAND CENTRE, FOURTH STREET, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25603,'lng':28.44012}},
{'stp':668036,'address':'668036, EGL - ISANDO, ISANDO EAST HYBRID, CASA NOSSA FAMILY RESTAURANT, 11 NIGEL ROAD, ERF NO 1540, SELECTION PARK, SPRINGS, South Africa','geo':{'lat':-26.26560,'lng':28.44040}},
{'stp':668838,'address':'668838, EGL - ISANDO, ISANDO EAST HYBRID, SUNDOWNERS PUB AND GRILL, 13 TINKTINKIE CRESCENT, ERF 9, RESIDENTS DAM EXT 1, SPRINGS, South Africa','geo':{'lat':-26.21420,'lng':28.41490}},
{'stp':669457,'address':'669457, EGL - ISANDO, ISANDO EAST HYBRID, THE PALACE (NTO), 36 TWELVE STREET, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25421,'lng':28.44687}},
{'stp':669665,'address':'669665, EGL - ISANDO, ISANDO EAST HYBRID, BUSHWACKERS PUB (NTO), NIGEL ROAD, SELECTION PARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.27839,'lng':28.44291}},
{'stp':674478,'address':'674478, EGL - ISANDO, ISANDO EAST HYBRID, THE OFFICE PUB AND GRILL, 9A PROTEA ROAD, EDELWEISS CENTRE, EDELWEISS EXT 1, SPRINGS, South Africa','geo':{'lat':-26.28570,'lng':28.47020}},
{'stp':682064,'address':'682064, EGL - ISANDO, ISANDO EAST HYBRID, COFFIN SHOPPE, 22 NORTH PHOENIX ROAD, ERF 4 GOLDEN SPRINGS TRUST, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.29210,'lng':28.43500}},
{'stp':684648,'address':'684648, EGL - ISANDO, ISANDO EAST HYBRID, MIKE\'S KITCHEN SPRINGS, ERF 2134 GEDULD EXTENSION 05, SHOP NO 01 SPRINGSGATE SHOPPING CENTRE, CORNER PAUL KRUGER,CONNAUGHT AND, SPRINGS, South Africa','geo':{'lat':-26.23530,'lng':28.42350}},
{'stp':687473,'address':'687473, EGL - ISANDO, ISANDO EAST HYBRID, CHECKERS LIQSHOP 69549 SELCOURT, CNR HEWITT AVENUE, AN EXTENSION OF BUTLER ROAD AND, RAMONA RD AN EXT OF PRIDGEON AVE SOUTH, SPRINGS, South Africa','geo':{'lat':-26.29300,'lng':28.45310}},
{'stp':694395,'address':'694395, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGS GOLF HALFWAY (NTO), NIGEL ROAD, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.26526,'lng':28.44317}},
{'stp':694737,'address':'694737, EGL - ISANDO, ISANDO EAST HYBRID, DA LIQUOR LIP, STAND NO, ERF NO 19376, GEDULD ROAD, SPRINGS, South Africa','geo':{'lat':-26.23230,'lng':28.42210}},
{'stp':701114,'address':'701114, EGL - ISANDO, ISANDO EAST HYBRID, LADY LUCK PUB, ERMELO ROAD ERF PORTION 33, GROOTVALY SH, #, SPRINGS, South Africa','geo':{'lat':-26.26348,'lng':28.48519}},
{'stp':703094,'address':'703094, EGL - ISANDO, ISANDO EAST HYBRID, SCOTTIES PUB AND BISTRO, 7 HEWITT STREET, PORTION 2 OF ERF 1549, SELECTION PARK, SPRINGS, South Africa','geo':{'lat':-26.29318,'lng':28.45171}},
{'stp':708952,'address':'708952, EGL - ISANDO, ISANDO EAST HYBRID, AZURE PUB AND CRILL, ERF 329, SHARON PARK SHOPPING CENTRE, SHOP NO 23, SPRINGS, South Africa','geo':{'lat':-26.32540,'lng':28.44560}},
{'stp':728389,'address':'728389, EGL - ISANDO, ISANDO EAST HYBRID, LE STOEP PUB AND PIZZA (NTO), RIVERFIELD SHOPPING CENTRE, VAAL DRIVE, PETERSFIELD EXT, SPRINGS, South Africa','geo':{'lat':-26.23447,'lng':28.43985}},
{'stp':737120,'address':'737120, EGL - ISANDO, ISANDO EAST HYBRID, YANKY\'S BURGERS RESTAURANT (NTO), YANKY\'S COMPLEX, OLYMPIA RD, DAL FOUCHE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25375,'lng':28.40257}},
{'stp':757781,'address':'757781, EGL - ISANDO, ISANDO EAST HYBRID, DERSLEY LIQUOR STORE, 442 STAND NO, NO 8 CLOVERFIELD STREET DERSELY SPRINGS, #, SPRINGS, South Africa','geo':{'lat':-26.20420,'lng':28.41400}},
{'stp':760138,'address':'760138, EGL - ISANDO, ISANDO EAST HYBRID, GERONIMO S, 3 STAND NO, ERF 797 PUMIC STREET DERSLEY, #, SPRINGS, South Africa','geo':{'lat':-26.19603,'lng':28.42186}},
{'stp':781423,'address':'781423, EGL - ISANDO, ISANDO EAST HYBRID, PITSTOP RESTAURANT, STAND, 5TH AVENUE, CORNER 10TH STREET, SPRINGS, South Africa','geo':{'lat':-26.25473,'lng':28.44526}},
{'stp':314515,'address':'314515, EGL - ISANDO, ISANDO EAST HYBRID, DOWN TOWN BAR, 5 SHOP NO, IKHAYA LAMI BUILDING, CORNER JOUBERT AND HUDSON STREET, GERMISTON, South Africa','geo':{'lat':-26.21160,'lng':28.16468}},
{'stp':317828,'address':'317828, EGL - ISANDO, ISANDO EAST HYBRID, E AND J BOTTLE STORE, 154 STAND NO, PRESIDENT, SOUTH GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22155,'lng':28.17009}},
{'stp':379802,'address':'379802, EGL - ISANDO, ISANDO EAST HYBRID, CLUB 10 AND GRAND GAMING SLOTS, 56 STAND NO, #, #, GERMISTON, South Africa','geo':{'lat':-26.20886,'lng':28.15857}},
{'stp':388380,'address':'388380, EGL - ISANDO, ISANDO EAST HYBRID, PLATINUM LIQUOR STORE, 5 SHOP NO, STAND NO 1, DEKEMA ROAD PLOT 82, KLIPPOORTJIE AGRICULTURAL LOTS, GERMISTON, South Africa','geo':{'lat':-26.26176,'lng':28.20644}},
{'stp':389114,'address':'389114, EGL - ISANDO, ISANDO EAST HYBRID, GERMISTON RESIDENTIAL HOTEL, 7-11 STAND NO, KING STREET, PORTION 1 OF ERF 189, GERMISTON, South Africa','geo':{'lat':-26.22449,'lng':28.16741}},
{'stp':390292,'address':'390292, EGL - ISANDO, ISANDO EAST HYBRID, ECLIPSE PUB AND GRILL, 545 ERF NO, NO 31 FH ONDENDAAL ROAD AND, MEYER STREET, GERMISTON, South Africa','geo':{'lat':-26.21369,'lng':28.16160}},
{'stp':391323,'address':'391323, EGL - ISANDO, ISANDO EAST HYBRID, IRON MAN, STAND NO. 1523 , EXTENSION 8, #, #, GERMISTON, South Africa','geo':{'lat':-26.25566,'lng':28.21135}},
{'stp':392722,'address':'392722, EGL - ISANDO, ISANDO EAST HYBRID, QUEENS PUB AND RESTAURANT PTY LTD, 34 CORNER KNOX AND SIMPSON ROAD, #, #, GERMISTON, South Africa','geo':{'lat':-26.21176,'lng':28.16308}},
{'stp':468046,'address':'468046, EGL - ISANDO, ISANDO EAST HYBRID, DRIEHOEK LIQUOR STORE, 56 MEYER STREET, ERF NO 0000000000000056, #, GERMISTON, South Africa','geo':{'lat':-26.20879,'lng':28.15839}},
{'stp':469204,'address':'469204, EGL - ISANDO, ISANDO EAST HYBRID, MNANDINI QABULANI BOTTLE STORE, ERF 149, 126 VICTORIA STREET, #, GERMISTON, South Africa','geo':{'lat':-26.21165,'lng':28.16173}},
{'stp':478049,'address':'478049, EGL - ISANDO, ISANDO EAST HYBRID, ROYAL CHEF LIQUOR STORE, SHOP NO AA, 114 VICTORIA STREET, CNR SIMMER STREET, GERMISTON, South Africa','geo':{'lat':-26.21177,'lng':28.16182}},
{'stp':479769,'address':'479769, EGL - ISANDO, ISANDO EAST HYBRID, ZAMA ZAMA LIQUORS, STAND NO, ERF 2793 TONGAZI STREET, EXTENSION 9 , SOUTH, GERMISTON, South Africa','geo':{'lat':-26.22913,'lng':28.19647}},
{'stp':483663,'address':'483663, EGL - ISANDO, ISANDO EAST HYBRID, LAGOS TAVERN, 0020 STAND NO, DUNKIRK ROAD, DELVILLE GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22768,'lng':28.18275}},
{'stp':483999,'address':'483999, EGL - ISANDO, ISANDO EAST HYBRID, PJ ENTERTAINMENT CENTRE, 6 WEBBER SOUTH, GERMISTON ERF 01335, #, GERMISTON, South Africa','geo':{'lat':-26.21944,'lng':28.16733}},
{'stp':488755,'address':'488755, EGL - ISANDO, ISANDO EAST HYBRID, LA BRAZA TAVERN, 25 STAND NO, SAPELIE AVENUE LEACHVILLE, #, BRAKPAN, South Africa','geo':{'lat':-26.22802,'lng':28.32574}},
{'stp':548540,'address':'548540, EGL - ISANDO, ISANDO EAST HYBRID, CHILAS PLACE, 2354 SIZELA STREET, EXT 9, #, GERMISTON, South Africa','geo':{'lat':-26.21658,'lng':28.19161}},
{'stp':550051,'address':'550051, EGL - ISANDO, ISANDO EAST HYBRID, KHATIFPLACE, 44 RAILWAY STREET, GEORGETOWN, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21144,'lng':28.16791}},
{'stp':550105,'address':'550105, EGL - ISANDO, ISANDO EAST HYBRID, SPORTSMAN II, RAILWAY STREET, GEORGETOWN, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21100,'lng':28.16830}},
{'stp':550377,'address':'550377, EGL - ISANDO, ISANDO EAST HYBRID, SANDOLANI RESTAURANT, 1 NASMITH AVENUE, DRIEHOEK, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21659,'lng':28.14371}},
{'stp':550404,'address':'550404, EGL - ISANDO, ISANDO EAST HYBRID, GERMISTON HOTEL, 1 SIMMER STREET, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21160,'lng':28.16030}},
{'stp':550425,'address':'550425, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGBOK RESTAURANT, 4 TREFFEY PLACE  CNR SIMMER AND, MEYER STREET, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21145,'lng':28.16054}},
{'stp':550470,'address':'550470, EGL - ISANDO, ISANDO EAST HYBRID, D ORIENTAL TAKEAWAYS AND RESTAURANT, 1 SHOP NUMBER, 31 LARNTERN ROAD, WADEVILLE, GERMISTON, South Africa','geo':{'lat':-26.26972,'lng':28.18495}},
{'stp':550557,'address':'550557, EGL - ISANDO, ISANDO EAST HYBRID, JOHNNY S RESTAURANT, CNR RENDELL & OSBORNE ROADS, WADEVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.25730,'lng':28.18950}},
{'stp':550974,'address':'550974, EGL - ISANDO, ISANDO EAST HYBRID, CHEETAHS ACTION BAR, 163 CNR HIGH AND PLANTATION STREET, GEORGETOWN, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21240,'lng':28.16860}},
{'stp':551067,'address':'551067, EGL - ISANDO, ISANDO EAST HYBRID, CITY HALL RESTAURANT, 269 PRESIDENT STREET, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21580,'lng':28.16410}},
{'stp':551461,'address':'551461, EGL - ISANDO, ISANDO EAST HYBRID, MANAGO RESTAURANT, CNR PETRUS & DEKEMA RD, WADEVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.27452,'lng':28.17121}},
{'stp':551537,'address':'551537, EGL - ISANDO, ISANDO EAST HYBRID, CIVIC LIQUORS, CNR JOUBERT AND OOSTHUIZEN STR, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21930,'lng':28.16570}},
{'stp':551697,'address':'551697, EGL - ISANDO, ISANDO EAST HYBRID, WADEVILLE LIQUORS, CNR RENDELL & OSBORNE AVE, WADEVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.25710,'lng':28.18990}},
{'stp':551699,'address':'551699, EGL - ISANDO, ISANDO EAST HYBRID, KNIGHTS RESTAURANT, 87 MAIN REEF ROAD, KNIGHTS, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.19590,'lng':28.17310}},
{'stp':551861,'address':'551861, EGL - ISANDO, ISANDO EAST HYBRID, SUPERSTAR PUB, 234D CNR  RAILWAY & GALWAY STREETS, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22148,'lng':28.17826}},
{'stp':552041,'address':'552041, EGL - ISANDO, ISANDO EAST HYBRID, SPORTSMANS BAR AND REST, INSTITUTE ROAD, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21186,'lng':28.16863}},
{'stp':552529,'address':'552529, EGL - ISANDO, ISANDO EAST HYBRID, AMIGO S RESTAURANT, 219 PRESIDENT STREET, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21440,'lng':28.16240}},
{'stp':552633,'address':'552633, EGL - ISANDO, ISANDO EAST HYBRID, ZAMA ZAMA RESTAURANT, 75 STAND  NO, OSTEND ROAD, GERMISTON SOUTH EXT 7, GERMISTON, South Africa','geo':{'lat':-26.22920,'lng':28.19650}},
{'stp':552759,'address':'552759, EGL - ISANDO, ISANDO EAST HYBRID, HIGHLANDS RESTAURANT, CNR 6 VICTORIA  & EXODUS STREET, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.20600,'lng':28.15790}},
{'stp':552782,'address':'552782, EGL - ISANDO, ISANDO EAST HYBRID, STATION BAZAAR LIQUOR STORE, STATION BAZAAR SHOPPING CENTRE, SHOP NO 64 STAND NO 804, 771 AND 772 RAILWAY STREET, GERMISTON, South Africa','geo':{'lat':-26.21200,'lng':28.16920}},
{'stp':552844,'address':'552844, EGL - ISANDO, ISANDO EAST HYBRID, A AND A CAFE AND TAVERN, 1-2 REFINERY ROAD, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22180,'lng':28.15800}},
{'stp':552974,'address':'552974, EGL - ISANDO, ISANDO EAST HYBRID, ROYAL ZAMALEK, 84 RAILWAY STREET, GEORGETOWN, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21297,'lng':28.16939}},
{'stp':553045,'address':'553045, EGL - ISANDO, ISANDO EAST HYBRID, STATION LIQ STORE GEORGETOWN, 44 RAILWAY STREET, GEORGETOWN, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21160,'lng':28.16800}},
{'stp':553212,'address':'553212, EGL - ISANDO, ISANDO EAST HYBRID, ABC PUB AND GRUB, 66 ERF, ABC COURT 267, VICTORIA STREET CNR, GERMISTON, South Africa','geo':{'lat':-26.21690,'lng':28.16760}},
{'stp':560463,'address':'560463, EGL - ISANDO, ISANDO EAST HYBRID, WHITE HOUSE LIQUOR STORE, 17 SOUTH RAND ROAD, BEING ERVEN 204 AND 205, GEORGETOWN, GERMISTON, South Africa','geo':{'lat':-26.21098,'lng':28.16528}},
{'stp':561076,'address':'561076, EGL - ISANDO, ISANDO EAST HYBRID, NEXTACY SPORTS BAR, CNR 93 PRESIDENT & CHURCH STREET, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.20979,'lng':28.15841}},
{'stp':603354,'address':'603354, EGL - ISANDO, ISANDO EAST HYBRID, GIMS TAVERN, 1125 SELANE DRIVE, EXT 8, DIKATHOLE, GERMISTON, South Africa','geo':{'lat':-26.22530,'lng':28.19160}},
{'stp':605575,'address':'605575, EGL - ISANDO, ISANDO EAST HYBRID, LANAS JOINT, 14 MALGAAN STR, DIKATHOLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22383,'lng':28.19264}},
{'stp':606450,'address':'606450, EGL - ISANDO, ISANDO EAST HYBRID, HOKAAI SUPERMARKET AND CAFE, 27 LANTERN ROAD, WADEVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.26770,'lng':28.18350}},
{'stp':606848,'address':'606848, EGL - ISANDO, ISANDO EAST HYBRID, MALESELAS PLACE, 28 WALNUT AVENUE, LEACHVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.22500,'lng':28.32310}},
{'stp':607572,'address':'607572, EGL - ISANDO, ISANDO EAST HYBRID, 777 LIQUOR STORE, WEBBER ROAD MANSION, SHOP 4 WEBBER STREET BETWEEN KING, #, GERMISTON, South Africa','geo':{'lat':-26.22430,'lng':28.17020}},
{'stp':608013,'address':'608013, EGL - ISANDO, ISANDO EAST HYBRID, MASHOBA\'S PLACE (NTO), 83 DAFODIL STREET, VILLA LIZA, DAWN PARK, DAWN PARK, South Africa','geo':{'lat':-26.19507,'lng':28.17414}},
{'stp':626860,'address':'626860, EGL - ISANDO, ISANDO EAST HYBRID, CALEDONIAN HOTEL, CNR MAIN REEF AND JOHN RIISSIK STR, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.20125,'lng':28.14396}},
{'stp':627632,'address':'627632, EGL - ISANDO, ISANDO EAST HYBRID, NO COMPROMISE JAZZ CLUB PERMIT, 8 SWEET WATER PLACE, GRACELAND, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.25900,'lng':28.21600}},
{'stp':629155,'address':'629155, EGL - ISANDO, ISANDO EAST HYBRID, MIYAS PLACE PERMIT, 3 BLOUBERG DRIVE, GRACELAND, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.26030,'lng':28.21700}},
{'stp':629779,'address':'629779, EGL - ISANDO, ISANDO EAST HYBRID, ILITHA TAVERN, 2599 MFULA BOULEVARD, EXT 9, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22050,'lng':28.19530}},
{'stp':630789,'address':'630789, EGL - ISANDO, ISANDO EAST HYBRID, BIZAS OFF SALES, 29 VAN LINGEN STREET, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22790,'lng':28.19140}},
{'stp':631070,'address':'631070, EGL - ISANDO, ISANDO EAST HYBRID, ARRIES TAVERN, 44 JACARADA  AVE, LEACHVILLE, WATTVILLE, WATTVILLE, South Africa','geo':{'lat':-26.23040,'lng':28.32550}},
{'stp':635025,'address':'635025, EGL - ISANDO, ISANDO EAST HYBRID, WADEFIN PUB, RF 302, CNR PRESIDENT & POWER ST, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22160,'lng':28.17070}},
{'stp':636101,'address':'636101, EGL - ISANDO, ISANDO EAST HYBRID, MR JD TAVERN, 21 VAN LINGEN STREET EXT 8, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22794,'lng':28.19102}},
{'stp':636339,'address':'636339, EGL - ISANDO, ISANDO EAST HYBRID, MOGALE SPAZA, 13 KWARTER STREET, PIROWVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21310,'lng':28.17470}},
{'stp':636863,'address':'636863, EGL - ISANDO, ISANDO EAST HYBRID, MOLWENI TAVERN, 15 BOHEMIA AVENUE, EXT 3 LEACHVILLE, WATTVILLE, BRAKPAN, South Africa','geo':{'lat':-26.22850,'lng':28.32500}},
{'stp':637686,'address':'637686, EGL - ISANDO, ISANDO EAST HYBRID, THE SPORTS PALACE, SHOP NO 74 JOUBERT ST, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21570,'lng':28.16400}},
{'stp':639270,'address':'639270, EGL - ISANDO, ISANDO EAST HYBRID, DANNY S SPORTS TAVERN, ERF 11 COR OSBORNE AND RENDELL STR, WADEVILLE, WADEVILLE, GERMISTON, South Africa','geo':{'lat':-26.25700,'lng':28.18990}},
{'stp':649094,'address':'649094, EGL - ISANDO, ISANDO EAST HYBRID, GREEN HOUSE, 15 SABI STREET, LEACHVILLE, WATTVILLE, BRAKPAN, South Africa','geo':{'lat':-26.22590,'lng':28.32660}},
{'stp':649109,'address':'649109, EGL - ISANDO, ISANDO EAST HYBRID, POPPI S PLACE, 71 JACARANDA AVENUE, LEACHVILLE EXT 8, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22940,'lng':28.32540}},
{'stp':650106,'address':'650106, EGL - ISANDO, ISANDO EAST HYBRID, CARIBBEAN COOL SPORTS BAR, ERF 386, 57 KNOX STREET, SHOP NO 03, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21135,'lng':28.16340}},
{'stp':651019,'address':'651019, EGL - ISANDO, ISANDO EAST HYBRID, GOLDEN GRILL, 65 PLANTATION ROAD, STAND NO 192 SHO P NO 5, GEORGETOWN, GERMISTON, South Africa','geo':{'lat':-26.21250,'lng':28.16830}},
{'stp':653130,'address':'653130, EGL - ISANDO, ISANDO EAST HYBRID, FISOKHULE TAVERN, C361 BENJAMIN STREET, DIKATHOLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22920,'lng':28.19220}},
{'stp':666102,'address':'666102, EGL - ISANDO, ISANDO EAST HYBRID, SIPHO TUCKSHOP, 2188 MFOLOZI AND KING WAZA STREET, EXT 9, DIKATHOLE, GERMISTON, South Africa','geo':{'lat':-26.21495,'lng':28.18903}},
{'stp':671310,'address':'671310, EGL - ISANDO, ISANDO EAST HYBRID, MMAPEDI RESTAURANT, LANTANA AND BEZUIDENHOUT STREETS, ERF 540 EXT 12, WADEVILLE, GERMISTON, South Africa','geo':{'lat':-26.26080,'lng':28.18250}},
{'stp':683959,'address':'683959, EGL - ISANDO, ISANDO EAST HYBRID, CIVIC LIQUORS TAVERN, SHOP 2, FRENCH CENTRE, JOUBERT STREET, GERMISTON, South Africa','geo':{'lat':-26.21940,'lng':28.16570}},
{'stp':685512,'address':'685512, EGL - ISANDO, ISANDO EAST HYBRID, M AND M THE REEDS PUB, SHOP NO 3, 27 POWER ROAD, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22158,'lng':28.16972}},
{'stp':685958,'address':'685958, EGL - ISANDO, ISANDO EAST HYBRID, SEBENZA LIQUOR STORE, DRIEHOEK AND JUNCTION STREETS, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21080,'lng':28.15450}},
{'stp':688732,'address':'688732, EGL - ISANDO, ISANDO EAST HYBRID, VUSIS TAVERN, 2841 KWATATE STREET, EXT 9, DIKATHOLE, GERMISTON, South Africa','geo':{'lat':-26.21830,'lng':28.19260}},
{'stp':699655,'address':'699655, EGL - ISANDO, ISANDO EAST HYBRID, LAGOS BOTTLE STORE AND DISTRIBUTION, 20 DUNKIRK, DELVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22562,'lng':28.18177}},
{'stp':702397,'address':'702397, EGL - ISANDO, ISANDO EAST HYBRID, DEKEMA LIQUORS, 303 DEKEMA ROAD, WADEVILLE, #, GERMISTON, South Africa','geo':{'lat':-26.27200,'lng':28.17760}},
{'stp':726546,'address':'726546, EGL - ISANDO, ISANDO EAST HYBRID, JJ PUB AND RESTAURANT, STAND NO 571,, 166 - 168 MEYER STREET, #, GERMISTON, South Africa','geo':{'lat':-26.21251,'lng':28.16178}},
{'stp':752292,'address':'752292, EGL - ISANDO, ISANDO EAST HYBRID, MASHIYA TAVERN, MAFULA STREET, ERF 2644 SOUTH GERMISTON, EXT 9, DAWN PARK, South Africa','geo':{'lat':-26.21820,'lng':28.19360}},
{'stp':757295,'address':'757295, EGL - ISANDO, ISANDO EAST HYBRID, PHOKENG TAVERN, MHLATI CRESCENT, ERF 2625, SOUTH GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21785,'lng':28.19425}},
{'stp':759838,'address':'759838, EGL - ISANDO, ISANDO EAST HYBRID, JOHN TAVERN, 2521 MVOTY STREET, EXTENSION 9, #, GERMISTON, South Africa','geo':{'lat':-26.22147,'lng':28.19684}},
{'stp':768960,'address':'768960, EGL - ISANDO, ISANDO EAST HYBRID, KAY KAY\'S TAVERN, CNR STUDLAND & CHEAM STREET, ERF 1036 NO 1 STUDLAND AVE, #, GERMISTON, South Africa','geo':{'lat':-26.27024,'lng':28.16241}},
{'stp':769458,'address':'769458, EGL - ISANDO, ISANDO EAST HYBRID, KOJOE LIQUOR STORE, 1 STAND NO, ERF 534 DEKEMA ROAD, CORNER STEENBRAS, STREET WADEVILLE EXT 2, GERMISTON, South Africa','geo':{'lat':-26.26702,'lng':28.18636}},
{'stp':770977,'address':'770977, EGL - ISANDO, ISANDO EAST HYBRID, SOLLY RESTAURANT AND BAR, ARGYL STREET, ERF 245, GERMISTON SOUTH, GERMISTON, South Africa','geo':{'lat':-26.22266,'lng':28.16950}},
{'stp':772867,'address':'772867, EGL - ISANDO, ISANDO EAST HYBRID, MTSHIDO S TAVERN, 1378 STAND NO, GRACELAND, ELSPARK EXTENSION 7, KATLEHONG, South Africa','geo':{'lat':-26.25815,'lng':28.21181}},
{'stp':774292,'address':'774292, EGL - ISANDO, ISANDO EAST HYBRID, FISH POINT, STAND NO, ERF 37 MALGAAN ROAD 1010 MINNIE STREET, DUKATHOLE TOWENSHIP GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22373,'lng':28.19075}},
{'stp':779840,'address':'779840, EGL - ISANDO, ISANDO EAST HYBRID, SISONKE TAVERN, STAND NO, CNR MAIN REEF ROAD, FIELD STREET LILIANTON, BOKSBURG, South Africa','geo':{'lat':-26.19795,'lng':28.21577}},
{'stp':782005,'address':'782005, EGL - ISANDO, ISANDO EAST HYBRID, CARLITOS TAVERN AND TAKE AWAY, STREET NO, ERF 541 MARY LAUS BUILDING CORNER, LANTANA AND BUIZENHOUT STR, GERMISTON, South Africa','geo':{'lat':-26.26022,'lng':28.18272}},
{'stp':796536,'address':'796536, EGL - ISANDO, ISANDO EAST HYBRID, STEP UP TAVERN, 940 PETERSON STREET, DIKATHOLE, SOUTH GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22285,'lng':28.19012}},
{'stp':797351,'address':'797351, EGL - ISANDO, ISANDO EAST HYBRID, ENNYS TAVERN, 1271 SELANI STREET, DUKATHOLE EXT 8, #, GERMISTON, South Africa','geo':{'lat':-26.22839,'lng':28.19252}},
{'stp':311604,'address':'311604, EGL - ISANDO, ISANDO EAST HYBRID, OWL\'S LANDING BOKSBURG RUGBY CLUB, 113 STAND NO, LEEUW POORT 113, IR PRINCE GEORGE PARK, BOKSBURG, South Africa','geo':{'lat':-26.22484,'lng':28.25049}},
{'stp':311767,'address':'311767, EGL - ISANDO, ISANDO EAST HYBRID, CHECKERS LIQUORSHOP BOKSBURG 67741, 652 ERF NO, NO 1 LEEUWPOORT STREET, CNR RONDEBUILT ROAD, BOKSBURG, South Africa','geo':{'lat':-26.22761,'lng':28.24678}},
{'stp':387967,'address':'387967, EGL - ISANDO, ISANDO EAST HYBRID, PEOPLE LIKE US, 3558 ERF NO, SHOP NO 2, SUNWARD PALMS SHOPPING CENTRE, BOKSBURG, South Africa','geo':{'lat':-26.25909,'lng':28.26224}},
{'stp':390486,'address':'390486, EGL - ISANDO, ISANDO EAST HYBRID, ELSBURG LIQUOR STORE, 21 STAND NO, #, #, GERMISTON, South Africa','geo':{'lat':-26.24725,'lng':28.20065}},
{'stp':390555,'address':'390555, EGL - ISANDO, ISANDO EAST HYBRID, LA ROCA LOUNGE, STAND NO, STAND 113, MIDDLE STREET, BOKSBURG, South Africa','geo':{'lat':-26.23855,'lng':28.23855}},
{'stp':391239,'address':'391239, EGL - ISANDO, ISANDO EAST HYBRID, CLUB FANTASY PUB AND LOUNGE, 1665 ERF NO, 1ST FLOOR  160 COMMISSIONER STREET, #, BOKSBURG, South Africa','geo':{'lat':-26.22164,'lng':28.24241}},
{'stp':391277,'address':'391277, EGL - ISANDO, ISANDO EAST HYBRID, VERDE MINHO RESTAURANT (NTO), ERF 3558, SHOP NO:15 SUNWARD PALMS, SHOPPING CENTRE, KINGFISHER AVENUE, SUNWARD PARK EXTENSION 8, BOKSBURG, South Africa','geo':{'lat':-26.25752,'lng':28.27826}},
{'stp':392745,'address':'392745, EGL - ISANDO, ISANDO EAST HYBRID, VALENTINO\'S PIZZA OVEN RESTAURANT (, SHOP NO:2A & 2B CINDERELLA SHOPPING, ERVEN 211, NO:51 KILLIAN, MUNNIK AND JOHNSTON STREETS, CINDERELLA, BOKSBURG, South Africa','geo':{'lat':-26.24239,'lng':28.25659}},
{'stp':393068,'address':'393068, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR CENTREE, ERF 1985 SHOP 2, SECOND STREET MALL, CORNER FIRST AVENUE AND FIRST STREET, SPRINGS, South Africa','geo':{'lat':-26.24930,'lng':28.43919}},
{'stp':393120,'address':'393120, EGL - ISANDO, ISANDO EAST HYBRID, E LIQUOR GOLDEN WALK, 94A SHOP NO, 141 VICTORIA STREET, GOLDEN WALK SHOPPING CENTRE, SPRINGS, South Africa','geo':{'lat':-26.21372,'lng':28.16585}},
{'stp':471500,'address':'471500, EGL - ISANDO, ISANDO EAST HYBRID, BOKSBURG TOPS, ERF 1735, BOKSBURG SPAR CENTRE, 222 CORNER KRUGER AND, BOKSBURG, South Africa','geo':{'lat':-26.22404,'lng':28.26332}},
{'stp':472003,'address':'472003, EGL - ISANDO, ISANDO EAST HYBRID, PICKET LIQUOR SHOP, 1 STAND NO, 271 PRESIDENT STREET, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21327,'lng':28.16853}},
{'stp':478922,'address':'478922, EGL - ISANDO, ISANDO EAST HYBRID, NEW YORK CITY PUB AND RESTAURANT, 193 ERF, 55 RAILWAY STREET, #, GERMISTON, South Africa','geo':{'lat':-26.21180,'lng':28.16826}},
{'stp':488616,'address':'488616, EGL - ISANDO, ISANDO EAST HYBRID, BOXER LIQUOR BOKSBURG, ERF 1763, MAIN UNIT 3, BANK BUILDING, BOKSBURG, South Africa','geo':{'lat':-26.22413,'lng':28.25313}},
{'stp':489587,'address':'489587, EGL - ISANDO, ISANDO EAST HYBRID, PIZZA DEL FORNO - SUNWARD (NTO), PORTION 127 OF THE FARM LEEUPORT, SHOP NO 24 SUNWARD LIFESTYLE CENTRE, CNR KINGFISHER AND TRICHARD RD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.25720,'lng':28.26940}},
{'stp':490353,'address':'490353, EGL - ISANDO, ISANDO EAST HYBRID, TARRAZ S TAVERN, STAND NO, SIMMERPAN CNR POWER AND LAKE STREET, BEING SITUATED ON PORTION 1223, GERMISTON, South Africa','geo':{'lat':-26.23363,'lng':28.16426}},
{'stp':550126,'address':'550126, EGL - ISANDO, ISANDO EAST HYBRID, MIMOSA PARK LIQUOR STORE, CNR KAREN AND MACKAY ROADS, SHOP 3 ELTORRA SHOPPING CENTRE, LAMTON GARDENS MIMOSA PARK, GERMISTON, South Africa','geo':{'lat':-26.25300,'lng':28.18580}},
{'stp':550362,'address':'550362, EGL - ISANDO, ISANDO EAST HYBRID, KINGS HOTEL, 18-20 CNR CHURCH & PLEIN STREETS, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22057,'lng':28.24287}},
{'stp':550478,'address':'550478, EGL - ISANDO, ISANDO EAST HYBRID, GOOD LUCK TAVERN, 1776 1ST STREET  PLATFORM 2, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24970,'lng':28.43830}},
{'stp':550817,'address':'550817, EGL - ISANDO, ISANDO EAST HYBRID, WITWATERSRAND DISCOUNT L STORE, CNR EGERT AND KINGFISHER STREETS, TEDSTONEVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.23750,'lng':28.19630}},
{'stp':551271,'address':'551271, EGL - ISANDO, ISANDO EAST HYBRID, BOKSBURG LIQUOR DISCOUNTERS, CNR LEEUWPOORT & PRETORIA STR, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22390,'lng':28.25180}},
{'stp':551398,'address':'551398, EGL - ISANDO, ISANDO EAST HYBRID, LA CAMPANA RESTAURANT, 261 KINGFISHER AVENUE, SUNWARD PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.25840,'lng':28.26220}},
{'stp':551416,'address':'551416, EGL - ISANDO, ISANDO EAST HYBRID, MASONIC LIQUOR STORE BOKSBURG, CNR KRUGER & LEEUPOORT STREET, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22430,'lng':28.26380}},
{'stp':551570,'address':'551570, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGS HOTEL, CNR 1ST AND 2ND AVENUE, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25070,'lng':28.43880}},
{'stp':551733,'address':'551733, EGL - ISANDO, ISANDO EAST HYBRID, NILE FALLS WHOLESALERS CC, 345 STAND NO, DRIEFONTEIN 85 IR, MAINREEF ROAD, BOKSBURG, South Africa','geo':{'lat':-26.20210,'lng':28.22560}},
{'stp':551982,'address':'551982, EGL - ISANDO, ISANDO EAST HYBRID, ERPM REC CLUB, COMETWEG, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.20710,'lng':28.23640}},
{'stp':552004,'address':'552004, EGL - ISANDO, ISANDO EAST HYBRID, NEWYORK CITY, 56 RAILWAY ROAD, GEORGETOWN, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21190,'lng':28.16830}},
{'stp':552275,'address':'552275, EGL - ISANDO, ISANDO EAST HYBRID, DELVILLE LIQUOR MART, ERF 621 102 ELSBURG ROAD, DELVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.23460,'lng':28.18910}},
{'stp':552566,'address':'552566, EGL - ISANDO, ISANDO EAST HYBRID, PICREB GERMISTON, CNR MEYER & OOSTHUIZEN STREET, GEORGETOWN, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21790,'lng':28.16680}},
{'stp':552607,'address':'552607, EGL - ISANDO, ISANDO EAST HYBRID, VICTORIA LAKE CLUB, NORTHEAST SIDE, GERMISTON LAKE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22620,'lng':28.16290}},
{'stp':552767,'address':'552767, EGL - ISANDO, ISANDO EAST HYBRID, CINDERELLA LIQUOR STORE, 111 KILLIAN STREET, SHOP 3 CINDRELLA SHOPPING CENTRE, #, BOKSBURG, South Africa','geo':{'lat':-26.24250,'lng':28.25660}},
{'stp':552847,'address':'552847, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR CITY CLEOS, CNR KINGFISHER & GANNET STREET, ELSPARK, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.26852,'lng':28.22510}},
{'stp':553016,'address':'553016, EGL - ISANDO, ISANDO EAST HYBRID, CHEEKY TIGERS, 10 PARK STREET, GEORGETOWN, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21075,'lng':28.16662}},
{'stp':553052,'address':'553052, EGL - ISANDO, ISANDO EAST HYBRID, GEDULD TOWNSHIP BOTTLE STORE, 36 4TH AVENUE, GEDULD, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24510,'lng':28.43470}},
{'stp':553119,'address':'553119, EGL - ISANDO, ISANDO EAST HYBRID, THE MOON BAR, STAND NO 132, 163 MEYER STREET, CBD GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.27147,'lng':28.17237}},
{'stp':553262,'address':'553262, EGL - ISANDO, ISANDO EAST HYBRID, GERMISTON CITY SPORTS CLUB, WEBBER RD, DELVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22824,'lng':28.16885}},
{'stp':553293,'address':'553293, EGL - ISANDO, ISANDO EAST HYBRID, THE DOG BOX LIQUOR STORE, 9 VOORTREKKER STREET, ELSBURG, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.24920,'lng':28.20130}},
{'stp':553462,'address':'553462, EGL - ISANDO, ISANDO EAST HYBRID, TOWER LIQUOR STORE, CNR RAVEN AND KINGFISHER AVENUE, ERF 1152 ELSPARK, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.26300,'lng':28.23270}},
{'stp':553601,'address':'553601, EGL - ISANDO, ISANDO EAST HYBRID, DELVILLE BOWLING CLUB, DELVILLE PARK 764, DELVILLE, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22851,'lng':28.17719}},
{'stp':560406,'address':'560406, EGL - ISANDO, ISANDO EAST HYBRID, ANGELO HOTEL, STAND 343 & 353 FARM DRIEFONTEIN, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.20170,'lng':28.22621}},
{'stp':580848,'address':'580848, EGL - ISANDO, ISANDO EAST HYBRID, MCLARENS PUB AND GRILL, CNR TRICHARDT & HEIDELBERG STREET, PARKDENE, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23506,'lng':28.25932}},
{'stp':602888,'address':'602888, EGL - ISANDO, ISANDO EAST HYBRID, MINI HARDROCK BOTTLE STORE, 402 MAIN REEF, ANGELO, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.20250,'lng':28.22600}},
{'stp':621702,'address':'621702, EGL - ISANDO, ISANDO EAST HYBRID, CASA DO CAMPO (NTO), 230 RONDEBULT RD, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.24498,'lng':28.24588}},
{'stp':624990,'address':'624990, EGL - ISANDO, ISANDO EAST HYBRID, SOUTHERN TOPS, CNR WEBBER AND ANGUS STREET, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.22042,'lng':28.16907}},
{'stp':631193,'address':'631193, EGL - ISANDO, ISANDO EAST HYBRID, GOODWILL LIQUOR STORE, 406 ERF NO, NO 25 4TH AVENUE, GEDULD SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24540,'lng':28.43470}},
{'stp':635130,'address':'635130, EGL - ISANDO, ISANDO EAST HYBRID, THE BRAZEN HEAD BOKSBURG, STAND NO ., SHOP 1 PARKRAND PIAZZA CENTRE 44, VAN WYK LOUW DRIVE PARKRAND, BOKSBURG, South Africa','geo':{'lat':-26.24730,'lng':28.27782}},
{'stp':637914,'address':'637914, EGL - ISANDO, ISANDO EAST HYBRID, POPS JAZZ TAVERN, 1130 LAVANDEL STREET, REIGER PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23150,'lng':28.21390}},
{'stp':640315,'address':'640315, EGL - ISANDO, ISANDO EAST HYBRID, OGWINI PLACE, 107 HIGH ROAD, 107 HIGH ROAD BETWEEN, PLANTATION AND PRELLER STREET, GERMISTON, South Africa','geo':{'lat':-26.21320,'lng':28.16860}},
{'stp':641652,'address':'641652, EGL - ISANDO, ISANDO EAST HYBRID, PARKRAND SUNSET LIQUORS, 180 STAND NO, PARKRAND SHOPPING CENTRE, SHOP NO 1 TRICHARDT STREET, BOKSBURG, South Africa','geo':{'lat':-26.24550,'lng':28.26367}},
{'stp':642393,'address':'642393, EGL - ISANDO, ISANDO EAST HYBRID, JABULA TAVERN EATING HOUSE, 4 1ST STREET, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24720,'lng':28.43820}},
{'stp':646982,'address':'646982, EGL - ISANDO, ISANDO EAST HYBRID, TOPS AT LAMBTON, 2 SHOP NO LAMBTON MALL 78 DOAK ST, KLIPPOORTJIE LAMBTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.24710,'lng':28.17790}},
{'stp':647498,'address':'647498, EGL - ISANDO, ISANDO EAST HYBRID, EPINTOMBI RESTAURANT, CNR 1ST ST AND  FIRST AVE, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24760,'lng':28.43860}},
{'stp':647538,'address':'647538, EGL - ISANDO, ISANDO EAST HYBRID, TOWNSHIP DISCOUNT LIQUOR STORE, STAND NO 977, GEDULT EAST 1st AVENUE, BOKSBURG, SPRINGS, South Africa','geo':{'lat':-26.24860,'lng':28.43940}},
{'stp':650287,'address':'650287, EGL - ISANDO, ISANDO EAST HYBRID, TOPS AT SUNWARD PARK, KINGFISH AV, ROOIBEK & AQUARIUS STS, ERF 325 SUNWARD PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.26070,'lng':28.25340}},
{'stp':650312,'address':'650312, EGL - ISANDO, ISANDO EAST HYBRID, PARKRAND TOPS LIQUOR STORE, 02 SHOP NO, PARKRAND CITY SHOPPING CENTRE, 44 VAN WYK LOUW DRIVE REMAINDER OF ERF, BOKSBURG, South Africa','geo':{'lat':-26.24585,'lng':28.27884}},
{'stp':651187,'address':'651187, EGL - ISANDO, ISANDO EAST HYBRID, THE COCONUT BAR AND RESTAURANT, 1665 ERF, 160 COMMISSIONER STREET, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22150,'lng':28.24230}},
{'stp':653007,'address':'653007, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR FOUNTAINS WHOLESALERS, ERF 221 CASON, CNR TRICHARDTS ROAD & KRYNAUW STREET, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.21040,'lng':28.25290}},
{'stp':661033,'address':'661033, EGL - ISANDO, ISANDO EAST HYBRID, GEORGE BOTTLE STORE, CNR 1ST STR EAST AND 1ST AVE, SHOP 9 GEDULD, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.24850,'lng':28.43870}},
{'stp':662558,'address':'662558, EGL - ISANDO, ISANDO EAST HYBRID, PUBLIC HOTEL (NTO), 38 VAN RIEBEECK, ELSBURG, GERMISTOM, GERMISTON, South Africa','geo':{'lat':-26.24758,'lng':28.20043}},
{'stp':664875,'address':'664875, EGL - ISANDO, ISANDO EAST HYBRID, OCEAN BASKET SUNWARD PARK (NTO), SHOP 28 KINGFISHER CNR, SUNWARD PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.25930,'lng':28.25809}},
{'stp':664880,'address':'664880, EGL - ISANDO, ISANDO EAST HYBRID, MANALITO\'S (NTO), 301A COMMISSIONER ROAD, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22281,'lng':28.25781}},
{'stp':664882,'address':'664882, EGL - ISANDO, ISANDO EAST HYBRID, BOKSBURG BOWLING CLUB (NTO), CNR PLEIN & CHURCH STREET, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22086,'lng':28.23996}},
{'stp':664895,'address':'664895, EGL - ISANDO, ISANDO EAST HYBRID, BOKSBURG FOOTBALL CLUB (NTO), JUBILEE ROAD, BOKSBURG STADIUM, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23575,'lng':28.27734}},
{'stp':665750,'address':'665750, EGL - ISANDO, ISANDO EAST HYBRID, TOPS AT ELSBURG SPAR, 17 VOORTREKKER STREET, SHOP 13 AND 15, ELSBURG, GERMISTON, South Africa','geo':{'lat':-26.24910,'lng':28.20250}},
{'stp':665770,'address':'665770, EGL - ISANDO, ISANDO EAST HYBRID, CP PUB (NTO), 1 ST FLOOR ESTERA CENTRE, BAKER STREET, ELSBURG, GERMISTON, South Africa','geo':{'lat':-26.25375,'lng':28.19506}},
{'stp':674430,'address':'674430, EGL - ISANDO, ISANDO EAST HYBRID, BOKSBURG ATHLETICS CLUB (NTO), BOKSBURG STADIUM, JUBILEE ROAD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.23575,'lng':28.27734}},
{'stp':674670,'address':'674670, EGL - ISANDO, ISANDO EAST HYBRID, SAVANAH SPUR, STAND NO, SHOP 43 127 CORNER TRICHARDT KINGFISHER, SUNWARD PARK CENTRE PORTION OF 127, BOKSBURG, South Africa','geo':{'lat':-26.25956,'lng':28.26993}},
{'stp':690704,'address':'690704, EGL - ISANDO, ISANDO EAST HYBRID, TRYLINE RESTAURANT, HERMAN IMMELMAN STADIUM, CNR WEBER ROAD AND DELVILLE, NORTH STREET DELVILLE COMMON RE/765, GERMISTON, South Africa','geo':{'lat':-26.22840,'lng':28.17380}},
{'stp':702376,'address':'702376, EGL - ISANDO, ISANDO EAST HYBRID, NICKS LIQUOR STORE, 119 COMMISSIONER STREET ERF 1679, BOKSBURG, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.22020,'lng':28.23910}},
{'stp':704019,'address':'704019, EGL - ISANDO, ISANDO EAST HYBRID, DROS SUNWARD PARK, CNR KINGFISHER AVE AND NICHOLOS STR, SHOP  29 KINGFISHER SHOPPING CENTRE, SUNWARD PARK, BOKSBURG, South Africa','geo':{'lat':-26.25938,'lng':28.26986}},
{'stp':728301,'address':'728301, EGL - ISANDO, ISANDO EAST HYBRID, CLAREDON HOTEL (NTO), 72 WEBBER ROAD, GERMISTON, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.23161,'lng':28.17350}},
{'stp':731932,'address':'731932, EGL - ISANDO, ISANDO EAST HYBRID, EAGLES NEST PUB (NTO), CNR HEIDELBERG RD & EGRET ST, ELSBURG, GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.26146,'lng':28.22510}},
{'stp':739746,'address':'739746, EGL - ISANDO, ISANDO EAST HYBRID, JOIN INN DINER, 56 CACHET ROAD, ERF 144, PARKHILL GARDENS, GERMISTON, South Africa','geo':{'lat':-26.23830,'lng':28.17600}},
{'stp':740224,'address':'740224, EGL - ISANDO, ISANDO EAST HYBRID, GOLDEN WALK LIQUOR LAND, SHOP 16 GOLDENWALK SHOPPING CENTRE, 141 VICORIA STREET BEING ERF 488, #, GERMISTON, South Africa','geo':{'lat':-26.21110,'lng':28.16320}},
{'stp':753713,'address':'753713, EGL - ISANDO, ISANDO EAST HYBRID, DE CALABASH RESTAURANT AND SPORT, 309 PRESIDENT STREET, ERF 23 CNR VOORTREKKER STREET, GEORGETOWN, GERMISTON, South Africa','geo':{'lat':-26.21730,'lng':28.16570}},
{'stp':759871,'address':'759871, EGL - ISANDO, ISANDO EAST HYBRID, CIRCLE PUB AND GRILL, 3 STAND NO, ERF 400 MENIN ROAD DELVILLE, #, GERMISTON, South Africa','geo':{'lat':-26.23100,'lng':28.17510}},
{'stp':771355,'address':'771355, EGL - ISANDO, ISANDO EAST HYBRID, GENESIS BOTTLE STORE, STAND NO, PORTION 1,ERF 96 SHOP 17 STIRLING COURT, 177 PRESIDENT STEET GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21263,'lng':28.16093}},
{'stp':772626,'address':'772626, EGL - ISANDO, ISANDO EAST HYBRID, BLACK BOTTLE LIQUORS, STAND NO, PORTION 2 OF ERF 531 GREATERMANS, BUILDING 62 PRESIDENT STREET GERMISTON, GERMISTON, South Africa','geo':{'lat':-26.21228,'lng':28.16010}},
{'stp':776177,'address':'776177, EGL - ISANDO, ISANDO EAST HYBRID, SUNWARD PARK LIQUORS, STAND NO, SHOP NO 2 KINGFISHER AVE AN TRICHARTS RD, SUNWARD PARK EXT 22, BOKSBURG, South Africa','geo':{'lat':-26.25753,'lng':28.27010}},
{'stp':781763,'address':'781763, EGL - ISANDO, ISANDO EAST HYBRID, ALL IN ONE LIQUOR STORE, 813 ERF, SHOP 2 RAILWAY STREET 212, CNR RAILWAY AND LINTON STR, GERMISTON, South Africa','geo':{'lat':-26.21790,'lng':28.17442}},
{'stp':782037,'address':'782037, EGL - ISANDO, ISANDO EAST HYBRID, RAINBOW BOTTLE STORE, STAND NO, ERF 1440 SHOP 1, RUTLING PLACE PRESIDENT STREET, GERMISTON, South Africa','geo':{'lat':-26.21383,'lng':28.16182}},
{'stp':799863,'address':'799863, EGL - ISANDO, ISANDO EAST HYBRID, PICK N PAY SUNWARD PARK GC10, 322 ERF, ERF 322 326 AND 327 SUNWARD P ARK CENTRE,       CNR DUIKER ROAD & KINGFISHER AVENU, BOKSBURG, South Africa','geo':{'lat':-26.26080,'lng':28.25511}},
{'stp':386312,'address':'386312, EGL - ISANDO, ISANDO EAST HYBRID, PICK N PAY GD 07 GROCER STORE LEAND, 17 STAND NO, NORDA STREET, STAND NO 2265, LEANDRA, South Africa','geo':{'lat':-26.36998,'lng':28.92016}},
{'stp':461685,'address':'461685, EGL - ISANDO, ISANDO EAST HYBRID, MALOME INN, 106 VULAMEHLO STREET, ERF 1016, IMPUMELELO EXTENSION 1, LEANDRA, South Africa','geo':{'lat':-26.34283,'lng':28.77191}},
{'stp':461686,'address':'461686, EGL - ISANDO, ISANDO EAST HYBRID, SAM\'S PLACE, STAND NO, ERF 922 JIKELEZA STREET, IMPUMELELO EXT 1 ,DEVON, LEANDRA, South Africa','geo':{'lat':-26.35080,'lng':28.76957}},
{'stp':550019,'address':'550019, EGL - ISANDO, ISANDO EAST HYBRID, HOT ACE DISTR AND BEER GARDEN, STAND 1577, LEBOHANG, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.38010,'lng':28.92130}},
{'stp':550151,'address':'550151, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR CITY CHEERS DOP SHOP, 29 NORDA STREET, LEANDRA, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.36880,'lng':28.92280}},
{'stp':550195,'address':'550195, EGL - ISANDO, ISANDO EAST HYBRID, LUIS LIQUOR STORE, 165 ADRIANA STREET, EENDRAG, LEANDRA, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.38250,'lng':28.88870}},
{'stp':550606,'address':'550606, EGL - ISANDO, ISANDO EAST HYBRID, ROTUNDUS OORD, ROTUNDUS LEISURE RESORT, BETHAL AVE, ENDICOTT, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.29293,'lng':28.59533}},
{'stp':550628,'address':'550628, EGL - ISANDO, ISANDO EAST HYBRID, DELI S TAVERN, STAND 674, LEBOHANG, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.38390,'lng':28.91770}},
{'stp':551077,'address':'551077, EGL - ISANDO, ISANDO EAST HYBRID, CLUB EMANGWANENI, ERF 6, LEANDRA, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.37690,'lng':28.91570}},
{'stp':552198,'address':'552198, EGL - ISANDO, ISANDO EAST HYBRID, MONRIKA BOTTLE STORE, 31 DERWIG STREET, DEVON, SPRINGS, LEANDRA, South Africa','geo':{'lat':-26.35510,'lng':28.78270}},
{'stp':552244,'address':'552244, EGL - ISANDO, ISANDO EAST HYBRID, CLUB 3 5I TAVERN, STAND NO 7 EXT 12, LEBOHANG, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.38020,'lng':28.91090}},
{'stp':553299,'address':'553299, EGL - ISANDO, ISANDO EAST HYBRID, JABULANI LIQUOR STORE, EFR 214, IMPUMELELO, DEVON, SPRINGS, LEANDRA, South Africa','geo':{'lat':-26.34847,'lng':28.76908}},
{'stp':560231,'address':'560231, EGL - ISANDO, ISANDO EAST HYBRID, MEDIADE, 669 DUBE STREET, IMPUMELELO, DEVON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.34826,'lng':28.76843}},
{'stp':560405,'address':'560405, EGL - ISANDO, ISANDO EAST HYBRID, BRONX TAVERN, 3043 STAND NO, LEBOHANG, #, LEANDRA, South Africa','geo':{'lat':-26.37710,'lng':28.91410}},
{'stp':603102,'address':'603102, EGL - ISANDO, ISANDO EAST HYBRID, THOKOZAN AND SONS, 678 DUBE STREET, IMPUMELELO EXT 1, DEVON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.34721,'lng':28.76807}},
{'stp':605804,'address':'605804, EGL - ISANDO, ISANDO EAST HYBRID, SHISANYAMA TAVERN, 455 EXT 2 LESLIE, HIGHVELD RIDGE, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.37280,'lng':28.91940}},
{'stp':606005,'address':'606005, EGL - ISANDO, ISANDO EAST HYBRID, TAVERN 85, 3436 EXT 10, LEBOHANG, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.37624,'lng':28.92047}},
{'stp':606006,'address':'606006, EGL - ISANDO, ISANDO EAST HYBRID, LUCKY TAVERN 85, 3819 LEANDRA, LEANDRA, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.37960,'lng':28.91910}},
{'stp':610009,'address':'610009, EGL - ISANDO, ISANDO EAST HYBRID, DEVON HOTEL (NTO), DERWIG STREET, DEVON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.35612,'lng':28.78386}},
{'stp':622504,'address':'622504, EGL - ISANDO, ISANDO EAST HYBRID, SHARP CONER (NTO), 780 IMPUMELELO, DEVON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.35127,'lng':28.76854}},
{'stp':622933,'address':'622933, EGL - ISANDO, ISANDO EAST HYBRID, ESTHER S TAVERN, 8539 LEBOHANG TOWNSHIP, EXT 21 LESLIE MPUMALANGA, HIGHVELD RIDGE, LEANDRA, South Africa','geo':{'lat':-26.38218,'lng':28.93712}},
{'stp':625158,'address':'625158, EGL - ISANDO, ISANDO EAST HYBRID, IMPUMELELO MASILELA BEER HALL, 215 USIZOLWETHU STREET, DEVON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.34850,'lng':28.76930}},
{'stp':625828,'address':'625828, EGL - ISANDO, ISANDO EAST HYBRID, BACK STAGE TAVERN PERMIT, 446 PHOLA STREET, DEVON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.34520,'lng':28.77050}},
{'stp':632244,'address':'632244, EGL - ISANDO, ISANDO EAST HYBRID, DEVONSHIRE INN, 74 KINGSWAY AVENUE, DEVON, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.35938,'lng':28.77927}},
{'stp':633753,'address':'633753, EGL - ISANDO, ISANDO EAST HYBRID, DUBES DRANKWINKEL, STANDPLAAS 15, LEBOHANG, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.37930,'lng':28.92080}},
{'stp':635987,'address':'635987, EGL - ISANDO, ISANDO EAST HYBRID, ALGERIA BOTTLE STORE, 302 ERMELO ROAD, SHORT ROAD LESEDI, #, SPRINGS, South Africa','geo':{'lat':-26.29800,'lng':28.60530}},
{'stp':636580,'address':'636580, EGL - ISANDO, ISANDO EAST HYBRID, EKUTHULENI TAVERN, 3595 EXTENSION 10, LEBOHANG, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.37560,'lng':28.91780}},
{'stp':647786,'address':'647786, EGL - ISANDO, ISANDO EAST HYBRID, CELEBRATION LIQUOR WORLD, 850 NORDA STREET, LEANDRA, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.37140,'lng':28.91980}},
{'stp':657548,'address':'657548, EGL - ISANDO, ISANDO EAST HYBRID, ZISIZE BOTTLE STORE, STAND NO 361, LEBOHANG, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.37440,'lng':28.92060}},
{'stp':700026,'address':'700026, EGL - ISANDO, ISANDO EAST HYBRID, BACKSTAGE TAVERN, ERF NO 2194, EXTENSION 12 LEBOHANG LESLIE, #, LEANDRA, South Africa','geo':{'lat':-26.38080,'lng':28.91230}},
{'stp':702871,'address':'702871, EGL - ISANDO, ISANDO EAST HYBRID, MASOMBUKA INN, STAND NO 3845, LEBOHANG TOWNSHIP EXTENSION 10 LESLIE, #, LEANDRA, South Africa','geo':{'lat':-26.37960,'lng':28.91750}},
{'stp':709218,'address':'709218, EGL - ISANDO, ISANDO EAST HYBRID, KH TAVERN, ERF NO 7629, EXTENSION 19 LEBOGANG LESLIE, #, LEBOHANG, South Africa','geo':{'lat':-26.38043,'lng':28.93369}},
{'stp':709246,'address':'709246, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR WORLD RESTAURANT, SHOP NO3, MAVUNGA BUILDING PORTION , ERF 260, EXTENSION 4GE, LEANDRA, South Africa','geo':{'lat':-26.38271,'lng':28.91767}},
{'stp':709324,'address':'709324, EGL - ISANDO, ISANDO EAST HYBRID, TOPS AT SPAR LEANDRA, SHOP NO 2 OF BUILDING LOCATED, ON ERF 946 NO 2 NORDA STREET, #, LEANDRA, South Africa','geo':{'lat':-26.37130,'lng':28.92020}},
{'stp':712089,'address':'712089, EGL - ISANDO, ISANDO EAST HYBRID, NALSIO TAVERN, 8590 EXT 21, LEBOHANG, #, LEANDRA, South Africa','geo':{'lat':-26.38200,'lng':28.93760}},
{'stp':750788,'address':'750788, EGL - ISANDO, ISANDO EAST HYBRID, CLUB 18 PUB, 6818 STREET NO, EX 17 LEBOHANG LESLIE, #, LEANDRA, South Africa','geo':{'lat':-26.38172,'lng':28.92487}},
{'stp':751629,'address':'751629, EGL - ISANDO, ISANDO EAST HYBRID, GAZI TAVERN, 1902 EXT 12, LESLIE, LEANDRA, LEANDRA, South Africa','geo':{'lat':-26.38130,'lng':28.91080}},
{'stp':752305,'address':'752305, EGL - ISANDO, ISANDO EAST HYBRID, UBUNTU TAVERN, 5512 STAND NO, EXT 16 LEBOHANG LESLIE, #, LEANDRA, South Africa','geo':{'lat':-26.37954,'lng':28.94569}},
{'stp':758461,'address':'758461, EGL - ISANDO, ISANDO EAST HYBRID, EKUJABULENI TAVERN, STAND NO, ERF 183, KWAZENZELE COMMUNITY TRUST, SPRINGS, South Africa','geo':{'lat':-26.28886,'lng':28.56954}},
{'stp':766969,'address':'766969, EGL - ISANDO, ISANDO EAST HYBRID, MZANSI TAVERN, 9463 STAND NO, EXT 16 PHASE 2,  LEBOHANG LOC, LEBOHANG, South Africa','geo':{'lat':-26.38276,'lng':28.94455}},
{'stp':771188,'address':'771188, EGL - ISANDO, ISANDO EAST HYBRID, L A S LIQUOR STORE, 7461 STAND NO, LEBOHANG TOWNSHIP EXT 18, #, LEBOHANG, South Africa','geo':{'lat':-26.38422,'lng':28.93696}},
{'stp':776569,'address':'776569, EGL - ISANDO, ISANDO EAST HYBRID, 2 BROTHERS TAVERN, 141 ERF NO, KWAZENZELE, ENDICOTT, NIGEL, South Africa','geo':{'lat':-26.28753,'lng':28.56744}},
{'stp':778636,'address':'778636, EGL - ISANDO, ISANDO EAST HYBRID, SIPHO S PLACE, STAND NO, ERF 1947 IMPUMELELO EXT 2 DEVON, HEIDELBERG, LEANDRA, South Africa','geo':{'lat':-26.34931,'lng':28.77408}},
{'stp':781454,'address':'781454, EGL - ISANDO, ISANDO EAST HYBRID, THE BEST TAVERN, 2058 STAND NO, EXT 12 LESLIE, #, LEANDRA, South Africa','geo':{'lat':-26.37930,'lng':28.90807}},
{'stp':781846,'address':'781846, EGL - ISANDO, ISANDO EAST HYBRID, VULTURES TAVERN, 475 STAND NO, EXT 6 REGISTRATION DIVISION IR, #, LEANDRA, South Africa','geo':{'lat':-26.38507,'lng':28.91495}},
{'stp':786808,'address':'786808, EGL - ISANDO, ISANDO EAST HYBRID, MWELASE TAVERN, STANDN NO, ERF 3561 LEBOGANG TOWNSHIP, #, LEANDRA, South Africa','geo':{'lat':-26.37690,'lng':28.91719}},
{'stp':787081,'address':'787081, EGL - ISANDO, ISANDO EAST HYBRID, MAKAMA TAVERN, 1436 STAND NO, LEBOHANG TOWNHSIP EXT 9, #, LEBOHANG, South Africa','geo':{'lat':-26.37949,'lng':28.92083}},
{'stp':311392,'address':'311392, EGL - ISANDO, ISANDO EAST HYBRID, SPUR CARNIVAL (NTO), CARNIVAL CITY CASINO, HEIDELBERG ELSBURG & CENTURY ROADS, DALPARK, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25752,'lng':28.31665}},
{'stp':389832,'address':'389832, EGL - ISANDO, ISANDO EAST HYBRID, ROCOMAMAS - CARNIVAL CITY (NTO), SHOP 1,2,3&4 CARNIVAL CITY CASINO C, BOUNDED BY N17 HIGHWAY & HEIDELBERG ROAD, ELS ROAD & CENTURY ROAD, BRAKPAN, South Africa','geo':{'lat':-26.25752,'lng':28.31665}},
{'stp':390686,'address':'390686, EGL - ISANDO, ISANDO EAST HYBRID, DKS PUTT PUB AND DINNERS, 16 STAND NO, #, #, BRAKPAN, South Africa','geo':{'lat':-26.27540,'lng':28.34071}},
{'stp':391017,'address':'391017, EGL - ISANDO, ISANDO EAST HYBRID, CAPELLO CARNIVAL (NTO), 0000 HIGHWAY, MALL @ CARNIVAL, CNR HEIDELBERG & AIRPORT ROADS, DALPARK, BRAKPAN, South Africa','geo':{'lat':-26.25135,'lng':28.31966}},
{'stp':392612,'address':'392612, EGL - ISANDO, ISANDO EAST HYBRID, MBABANE LIQUOR WHOLESALERS, 153 STAND NO, WENDEN AVENUE, EKURHULENI, BRAKPAN, South Africa','geo':{'lat':-26.24075,'lng':28.36233}},
{'stp':461014,'address':'461014, EGL - ISANDO, ISANDO EAST HYBRID, ENFUSION LOUNGE, 135 KITZINGER AVENUE, ERF 1421, BRAKPAN CBD, BRAKPAN, South Africa','geo':{'lat':-26.23910,'lng':28.36443}},
{'stp':473575,'address':'473575, EGL - ISANDO, ISANDO EAST HYBRID, PANAROTIS CARNIVAL CITY, STAND NO, N17 HIGHWAY DALPARK  PORTION 91, DALPARK, BRAKPAN, South Africa','geo':{'lat':-26.25752,'lng':28.31665}},
{'stp':478830,'address':'478830, EGL - ISANDO, ISANDO EAST HYBRID, MAKRO CARNIVAL CITY M26, 3086 ERF, DALPARK EXT 19BEING CNR RANGEVIEW STREET, HEIDELBER STREET, BRAKPAN, South Africa','geo':{'lat':-26.23761,'lng':28.32469}},
{'stp':484800,'address':'484800, EGL - ISANDO, ISANDO EAST HYBRID, ROCOMAMA S MALL AT CARNIVAL, SHOP NO 148A GROUND FLOOR, THE MALL AT CARNIVAL BOUNDED BY N17, HIGHWAY HEIDELBERG ROAD, BRAKPAN, South Africa','geo':{'lat':-26.25251,'lng':28.31738}},
{'stp':496017,'address':'496017, EGL - ISANDO, ISANDO EAST HYBRID, OCEAN BASKET - CARNIVAL MALL (NTO), SHOP 60 THE MALL @ CARNIVAL, CNR AIRPORT & HEIDELBERG ROADS, BRAKPAN, #, BRAKPAN, South Africa','geo':{'lat':-26.25251,'lng':28.31738}},
{'stp':550115,'address':'550115, EGL - ISANDO, ISANDO EAST HYBRID, GOOD LUCK LIQUOR STORE, 766D VOORTREKKER RD,, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24020,'lng':28.35620}},
{'stp':550193,'address':'550193, EGL - ISANDO, ISANDO EAST HYBRID, BRENTHURST LIQUOR STORE, 2 ROOK STREET, BRENTHURST, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25401,'lng':28.37786}},
{'stp':550261,'address':'550261, EGL - ISANDO, ISANDO EAST HYBRID, RIEBEECK LIQUOR STORE, CNR WEST SPRING & INDUSTRY ROADS, POLLAK PARK, #, SPRINGS, South Africa','geo':{'lat':-26.26600,'lng':28.41680}},
{'stp':550265,'address':'550265, EGL - ISANDO, ISANDO EAST HYBRID, BRAKPAN LIQUOR WAREHOUSE, 23A & B PRINCE GEORGE AVE, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22770,'lng':28.36000}},
{'stp':550380,'address':'550380, EGL - ISANDO, ISANDO EAST HYBRID, SHERWOOD LIQUORS, CNR CRAVEN AND HOSPITAL STREETS, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22570,'lng':28.38630}},
{'stp':550486,'address':'550486, EGL - ISANDO, ISANDO EAST HYBRID, SPRINGS HIGH SCHOOL OLD BOYS, STAND 199, POLLAK PARK, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.27080,'lng':28.41530}},
{'stp':550532,'address':'550532, EGL - ISANDO, ISANDO EAST HYBRID, TAKE FIVE, 148 WENDEN AVENUE, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24040,'lng':28.36320}},
{'stp':550711,'address':'550711, EGL - ISANDO, ISANDO EAST HYBRID, AMERICAN FAST FOODS, 46A GOODS ROAD, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24620,'lng':28.36250}},
{'stp':550736,'address':'550736, EGL - ISANDO, ISANDO EAST HYBRID, SHOSHOLOZA INN, 1272 STAND NO, 62 STATION ROAD, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24002,'lng':28.36171}},
{'stp':551097,'address':'551097, EGL - ISANDO, ISANDO EAST HYBRID, ROYAL OAK GOLF CLUB, PRINCE GEORGE AVENUE, ANZAC, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22630,'lng':28.35930}},
{'stp':551300,'address':'551300, EGL - ISANDO, ISANDO EAST HYBRID, CRUISE INN, 73 DENNE RD, WITPOORT ESTATES, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25893,'lng':28.36220}},
{'stp':551314,'address':'551314, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR CITY VC, 3 CRAIGHOLM STREET, SHOP 3, VC CENTRE, #, BRAKPAN, South Africa','geo':{'lat':-26.25000,'lng':28.35080}},
{'stp':551658,'address':'551658, EGL - ISANDO, ISANDO EAST HYBRID, MARIEVLEI LIQUOR STORE, ERF : 64 MARIEVLEI SHOPPING, CENTRE, CNR LEMMER & ERGO, ROADS, VULCANIA, BRAKPAN, South Africa','geo':{'lat':-26.26656,'lng':28.37844}},
{'stp':551706,'address':'551706, EGL - ISANDO, ISANDO EAST HYBRID, BONANZA LIQUORS, 20 DENNE ROAD, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.26211,'lng':28.36010}},
{'stp':551977,'address':'551977, EGL - ISANDO, ISANDO EAST HYBRID, SPEEDY CHICKEN RESTAURANT, STAND NO 362, 61 ELLIS STREET, WRIGHT PARK, SPRINGS, South Africa','geo':{'lat':-26.26563,'lng':28.41589}},
{'stp':552146,'address':'552146, EGL - ISANDO, ISANDO EAST HYBRID, MINNEBRON LIQUOR STORE, 4 ERIC HOLDTMAN STREET, MINNEBRON, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.27950,'lng':28.34600}},
{'stp':552540,'address':'552540, EGL - ISANDO, ISANDO EAST HYBRID, BRAKPAN MINES BOWLING CLUB, FARM WELTEVREDE NO 188, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22130,'lng':28.35900}},
{'stp':552564,'address':'552564, EGL - ISANDO, ISANDO EAST HYBRID, BRAKPAN SPORTS CLUB, STAND 3345, ABERCON AVE, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22820,'lng':28.36220}},
{'stp':552608,'address':'552608, EGL - ISANDO, ISANDO EAST HYBRID, HOUSE OF LIQUOR, 223 ERF NO, BLOCK A SHOP 1 DALPARK SHOPPING CENTRE, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25260,'lng':28.33360}},
{'stp':552615,'address':'552615, EGL - ISANDO, ISANDO EAST HYBRID, MEGA LIQUORS BRAKPAN, 0004 STAND NO, MAIN REEF ROAD, ANZAC, BRAKPAN, South Africa','geo':{'lat':-26.22610,'lng':28.35960}},
{'stp':552863,'address':'552863, EGL - ISANDO, ISANDO EAST HYBRID, CENTRAL BOTTLE STORE, 23 GOODS ROAD, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24699,'lng':28.36339}},
{'stp':552897,'address':'552897, EGL - ISANDO, ISANDO EAST HYBRID, STAR LIQUOR, ERF 1557,SHOP 2 WENDEN AVENUE, CORNER STATION ROAD, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24100,'lng':28.36220}},
{'stp':552940,'address':'552940, EGL - ISANDO, ISANDO EAST HYBRID, SI CARNIVAL CITY, 177 RIETFONTEIN FARM  N17 TOLL, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25752,'lng':28.31665}},
{'stp':553032,'address':'553032, EGL - ISANDO, ISANDO EAST HYBRID, MC LIQUOR STORE, 505 PRINCE GEORGE AVENUE, CORNER STOFFEBERG AVENUE, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24340,'lng':28.36740}},
{'stp':553086,'address':'553086, EGL - ISANDO, ISANDO EAST HYBRID, STATE MINES COUNTRY CLUB, 30 HEWITT STREET, BRAKPAN NORTH, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.20940,'lng':28.37530}},
{'stp':553157,'address':'553157, EGL - ISANDO, ISANDO EAST HYBRID, GLAMORGAN LIQUOR STORE, 458 GLAMORGAN STREET SHELBURN CEN, DALVIEW, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25330,'lng':28.34490}},
{'stp':553590,'address':'553590, EGL - ISANDO, ISANDO EAST HYBRID, POST OFFICE TOPS AT SPAR, STAND NO, ERF 3479 NO 668, VOORTREKKER ROAD CNR HIGH STREET, BRAKPAN, South Africa','geo':{'lat':-26.23732,'lng':28.36286}},
{'stp':553658,'address':'553658, EGL - ISANDO, ISANDO EAST HYBRID, STATE MINES LIQUOR STORE, 307 VOORTREKKER ROAD, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23266,'lng':28.37448}},
{'stp':553719,'address':'553719, EGL - ISANDO, ISANDO EAST HYBRID, KINGSWAY CLUB, KINGSWAYLAAN 108, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23627,'lng':28.36740}},
{'stp':603600,'address':'603600, EGL - ISANDO, ISANDO EAST HYBRID, SILVER WOLF SPUR, CNR HEIDELBERG AND  AIRPORT ROAD, DALPARK, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25251,'lng':28.31738}},
{'stp':606550,'address':'606550, EGL - ISANDO, ISANDO EAST HYBRID, PICK N PAY CARNIVAL MALL, HEIDELBERG RANGEVIER&AIRPORT RDS, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25251,'lng':28.31738}},
{'stp':606997,'address':'606997, EGL - ISANDO, ISANDO EAST HYBRID, CAROLINES LIQUOR STORE, CNR VOORTREKKER & KERRY STREET, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23100,'lng':28.37780}},
{'stp':607629,'address':'607629, EGL - ISANDO, ISANDO EAST HYBRID, FEDICS (NTO), ELSBURG ROAD, CARNIVAL CITY, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25752,'lng':28.31665}},
{'stp':614873,'address':'614873, EGL - ISANDO, ISANDO EAST HYBRID, SUPERSPORT BAR (NTO), ELSBURG ROAD, CARNIVAL CITY, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25752,'lng':28.31665}},
{'stp':616651,'address':'616651, EGL - ISANDO, ISANDO EAST HYBRID, MASONIC HOTEL (NTO), 48 VOORTREKKER STREET, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22898,'lng':28.38221}},
{'stp':618924,'address':'618924, EGL - ISANDO, ISANDO EAST HYBRID, CARNIVAL BAR (NTO), CARNIVAL CITY, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25752,'lng':28.31665}},
{'stp':621896,'address':'621896, EGL - ISANDO, ISANDO EAST HYBRID, TROLLEY BAR (NTO), ELSBURG ROAD, CARNIVAL CITY, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25752,'lng':28.31665}},
{'stp':634682,'address':'634682, EGL - ISANDO, ISANDO EAST HYBRID, TONYS INN RESTAURANT, 124 VICTORIA AVENUE, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23930,'lng':28.36210}},
{'stp':637575,'address':'637575, EGL - ISANDO, ISANDO EAST HYBRID, DALVIEW LIQUORS, 8 ST HENDRIK POTGIETER STREET, DALVIEW, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24620,'lng':28.34710}},
{'stp':642609,'address':'642609, EGL - ISANDO, ISANDO EAST HYBRID, MANHATTAN HOTEL AND ACTION BAR, 333 PRINCE GEORGELAAN, PRINCE GEORGELAAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23830,'lng':28.36520}},
{'stp':646087,'address':'646087, EGL - ISANDO, ISANDO EAST HYBRID, ZITHABISENI TAVERN, 93B ERF NO, HOY AVENUE, DALVIEW, BRAKPAN, South Africa','geo':{'lat':-26.24630,'lng':28.36240}},
{'stp':647281,'address':'647281, EGL - ISANDO, ISANDO EAST HYBRID, RAINBOW TAVERN, 1408 ERF, 150 WENDEN AVENUE, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24040,'lng':28.36320}},
{'stp':647382,'address':'647382, EGL - ISANDO, ISANDO EAST HYBRID, SHOPRITE BRAKPAN 44048, 3391 Cnr VICTORIA & BEDFORD STREETS, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23712,'lng':28.36801}},
{'stp':648223,'address':'648223, EGL - ISANDO, ISANDO EAST HYBRID, BRENTHURST RESTAURANT, 1 ROOK STREET, BRENTHURST, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25420,'lng':28.37750}},
{'stp':654442,'address':'654442, EGL - ISANDO, ISANDO EAST HYBRID, COSY CORNER PUB AND GRILL, NO 12 ZEEMAN STREET, ERF 1334 BRENTHURST, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25040,'lng':28.37730}},
{'stp':658521,'address':'658521, EGL - ISANDO, ISANDO EAST HYBRID, SAFARI LIQUORS, 2595 ERF NO, CNR PRINCE GEORGE AND PORTER AVENUE, ST ANDREWS BUILDING, BRAKPAN, South Africa','geo':{'lat':-26.24560,'lng':28.36870}},
{'stp':664876,'address':'664876, EGL - ISANDO, ISANDO EAST HYBRID, OCEAN BASKET CARNIVAL CITY (NTO), C/O ELSBERG & CENTURY RD, CARNIVAL CITY, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.26054,'lng':28.31446}},
{'stp':664879,'address':'664879, EGL - ISANDO, ISANDO EAST HYBRID, ROSE TULEE (NTO), 189 FARQUHUSAN STREET, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25288,'lng':28.35072}},
{'stp':665355,'address':'665355, EGL - ISANDO, ISANDO EAST HYBRID, MISSISSIPI (NTO), ESCOUMBE STREET, BRAKPAN LAKE, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22877,'lng':28.36227}},
{'stp':669324,'address':'669324, EGL - ISANDO, ISANDO EAST HYBRID, BAFANA BAFANA SPORTS BAR (NTO), 52B GOODS ROAD, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24825,'lng':28.36192}},
{'stp':670448,'address':'670448, EGL - ISANDO, ISANDO EAST HYBRID, CHANRI RESTAURANT (NTO), 755 VORRTREKKER  ROAD, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22990,'lng':28.38038}},
{'stp':684312,'address':'684312, EGL - ISANDO, ISANDO EAST HYBRID, AFRICAN SPORTS BAR, 96 KINGSWAY STREET, ERF 3451, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23530,'lng':28.36940}},
{'stp':688127,'address':'688127, EGL - ISANDO, ISANDO EAST HYBRID, THE BEST SPORTS PUB, 84 STATION RAOD, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24107,'lng':28.36233}},
{'stp':688649,'address':'688649, EGL - ISANDO, ISANDO EAST HYBRID, MBABANE RESTAURANT, 152B WENDEN AVENUE, ERF R1406, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24070,'lng':28.36240}},
{'stp':694396,'address':'694396, EGL - ISANDO, ISANDO EAST HYBRID, HIPPO\'S (NTO), 40 ELLIOT STREET, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.22738,'lng':28.36443}},
{'stp':696022,'address':'696022, EGL - ISANDO, ISANDO EAST HYBRID, CAFE ATELIER (NTO), 57 REITZ AVENUE, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24990,'lng':28.36920}},
{'stp':702327,'address':'702327, EGL - ISANDO, ISANDO EAST HYBRID, LA PETITE COFFEE CAFE, 293 PRINCE GEORGE STREET, #, #, BRAKPAN, South Africa','geo':{'lat':-26.23610,'lng':28.36400}},
{'stp':715941,'address':'715941, EGL - ISANDO, ISANDO EAST HYBRID, SHOPRITE CARNIVAL MALL 31136, CNR WILLIAM DABBS AND PAARL RDS, BRACKENFELL, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25251,'lng':28.31738}},
{'stp':722483,'address':'722483, EGL - ISANDO, ISANDO EAST HYBRID, TIRISANO PUB, ERF 1421, 135B KITZINGER AVENUE, #, BRAKPAN, South Africa','geo':{'lat':-26.23890,'lng':28.36480}},
{'stp':722754,'address':'722754, EGL - ISANDO, ISANDO EAST HYBRID, PANTHERS LIQUOR STORE, 1296 KITZINGER AVE SHOP 1, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23880,'lng':28.36540}},
{'stp':735173,'address':'735173, EGL - ISANDO, ISANDO EAST HYBRID, PANAROTTIS @ CARNIVAL MALL (NTO), CNR HEIDELBERG & AIRPORT RDS, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25871,'lng':28.31504}},
{'stp':737058,'address':'737058, EGL - ISANDO, ISANDO EAST HYBRID, JOHN DORY S CAPPUCCINO S, SHOPS 108 AND 109 GROUND FLOOR, THE MALL AT CARNIVAL PHASE 2 ERF 3080, #, BRAKPAN, South Africa','geo':{'lat':-26.25251,'lng':28.31738}},
{'stp':738105,'address':'738105, EGL - ISANDO, ISANDO EAST HYBRID, PINK PANTHER LIQUOR STORE, WINKEL 121 BRAKPAN PLAZA, H/V PRINCE GEORGE AND VOORTREKKER STR, #, BRAKPAN, South Africa','geo':{'lat':-26.23700,'lng':28.36480}},
{'stp':746131,'address':'746131, EGL - ISANDO, ISANDO EAST HYBRID, LAPENG RESTAURANT AND PUB, 63A HIGH STREET ERF 1414, BRAKPAN, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23980,'lng':28.36400}},
{'stp':749335,'address':'749335, EGL - ISANDO, ISANDO EAST HYBRID, GAME LIQUORMART CARNIVAL 213, STREET NO, PORTION 1 OF ERF 2063 ANDD ERF 3057 SHOP, MALL CARNIVAL PHASE 2 CORNER AIRPORT AND, BRAKPAN, South Africa','geo':{'lat':-26.25251,'lng':28.31738}},
{'stp':763076,'address':'763076, EGL - ISANDO, ISANDO EAST HYBRID, MAKOLA SHEBEEN, 241 DENNE ROAD, WITPOORT ESTATES, #, BRAKPAN, South Africa','geo':{'lat':-26.28094,'lng':28.35289}},
{'stp':768883,'address':'768883, EGL - ISANDO, ISANDO EAST HYBRID, PARK HOTEL, STAND NO, CNR BEDFORD STREET, QUEENS AVENUE BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.23444,'lng':28.36784}},
{'stp':783634,'address':'783634, EGL - ISANDO, ISANDO EAST HYBRID, HILLMANS PUB, 1570 STAND NO, 146 HIGH STREET CORNER, NORTHDENE ROAD BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.24056,'lng':28.36426}},
{'stp':314343,'address':'314343, EGL - ISANDO, ISANDO EAST HYBRID, LEGEND VILLAGE PUB, 9916 STAND NUMBER, MONAMETSI STREET, KWA THEMA SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.28796,'lng':28.38873}},
{'stp':318064,'address':'318064, EGL - ISANDO, ISANDO EAST HYBRID, THE VENUE PUB, 7086 STAND NO, THE VENUE SHOPPING CENTRE, TSHAKA STREET, KWATHEMA, South Africa','geo':{'lat':-26.29429,'lng':28.39146}},
{'stp':388803,'address':'388803, EGL - ISANDO, ISANDO EAST HYBRID, MOTHER AND SON DRILL PUB, 6983 STAND NO, MASOLE STREET, #, KWATHEMA, South Africa','geo':{'lat':-26.28168,'lng':28.39154}},
{'stp':389882,'address':'389882, EGL - ISANDO, ISANDO EAST HYBRID, THE BIG 5 PUB AND RESTAURANT, 688 ERF 1133, STAND NO, HOSKING STREET, CORNER VAN ZYL STREET, BRAKPAN, South Africa','geo':{'lat':-26.25609,'lng':28.39146}},
{'stp':389953,'address':'389953, EGL - ISANDO, ISANDO EAST HYBRID, SHOPRITE LS KWA THEMA SQUARE 82959, 42 SHOP NO, KWA THEMBA CORNER SHOPPIMG CENTRE, GREEN ROAD CORNER, KWATHEMA, South Africa','geo':{'lat':-26.28184,'lng':28.38882}},
{'stp':390145,'address':'390145, EGL - ISANDO, ISANDO EAST HYBRID, YANKY S BURGERS RESTAURANT, 362 ERF NO, #, #, SPRINGS, South Africa','geo':{'lat':-26.25474,'lng':28.39855}},
{'stp':390613,'address':'390613, EGL - ISANDO, ISANDO EAST HYBRID, THE CRIB 1575, 20686 ERF  NO, PORTION 1 MOREKU STREET, KWA THEMBA, KWATHEMA, South Africa','geo':{'lat':-26.29096,'lng':28.40707}},
{'stp':391873,'address':'391873, EGL - ISANDO, ISANDO EAST HYBRID, E HOSTEL CHESA NYAMA, 9888 STAND NO, KWA THEMA HOSTEL, HADEBE STREET, KWATHEMA, South Africa','geo':{'lat':-26.28487,'lng':28.40277}},
{'stp':392854,'address':'392854, EGL - ISANDO, ISANDO EAST HYBRID, S L KWA-THEMA JUNCTION-LS94572, 21 SHOP NO, JUNCTION @ KWA-THEMA CNR TONK METER, DRIVE AND SOUTH ROAD/ RHOHANA ROAD, KWATHEMA, South Africa','geo':{'lat':-26.31166,'lng':28.42855}},
{'stp':471119,'address':'471119, EGL - ISANDO, ISANDO EAST HYBRID, AIRFIELD LIQUORS, SHOP NO 3, DENNE AVENNUE, DAL FOUCHE ERF 373, SPRINGS, South Africa','geo':{'lat':-26.25393,'lng':28.40266}},
{'stp':472123,'address':'472123, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR CITY TSAKANE, 16034 CNR MBAZIMA STREET, AND TSAKANE ROAD, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35829,'lng':28.36251}},
{'stp':489826,'address':'489826, EGL - ISANDO, ISANDO EAST HYBRID, DYNASTY LOUNGE WES, 11147 STAND NO, MORRISON STREET, KWATHEMA SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.30422,'lng':28.40693}},
{'stp':489965,'address':'489965, EGL - ISANDO, ISANDO EAST HYBRID, SHOPRITE LIQUORSHOP WHITECITY 39655, 13102 STAND NUMBER, MARKENTA STREET, KWA TEMA SPRINGS, KWATHEMA, South Africa','geo':{'lat':-26.30366,'lng':28.38847}},
{'stp':550011,'address':'550011, EGL - ISANDO, ISANDO EAST HYBRID, OLYMPIC TAKE AWAY AND TAVERN, 14 SECOND AVENUE, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25090,'lng':28.44120}},
{'stp':550026,'address':'550026, EGL - ISANDO, ISANDO EAST HYBRID, PROTEA HOTEL, DERDE LAAN, SPRINGS, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25117,'lng':28.44213}},
{'stp':550824,'address':'550824, EGL - ISANDO, ISANDO EAST HYBRID, GWINYA TAVERN PERMIT, 18715 MASHIQILA STREET, EXT 6, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.30372,'lng':28.38368}},
{'stp':552304,'address':'552304, EGL - ISANDO, ISANDO EAST HYBRID, MOAMGA BOTTLE STORE, 6980 MASOLE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28159,'lng':28.39136}},
{'stp':552386,'address':'552386, EGL - ISANDO, ISANDO EAST HYBRID, BRAKPAN LIQUOR MARKET, CNR VAN ZYL & OLYMPIA RDS, BRENTHURST, BRAKPAN, BRAKPAN, South Africa','geo':{'lat':-26.25670,'lng':28.39200}},
{'stp':552827,'address':'552827, EGL - ISANDO, ISANDO EAST HYBRID, COOKIES TAVERN, 991 SQUAREHILL CRESCENT, GELUKSDAL, GELUKSDAL, GELUKSDAL, South Africa','geo':{'lat':-26.33759,'lng':28.36416}},
{'stp':552936,'address':'552936, EGL - ISANDO, ISANDO EAST HYBRID, SECRET GARDENS TAVERN, 13793 SESHEMANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.31280,'lng':28.41060}},
{'stp':561297,'address':'561297, EGL - ISANDO, ISANDO EAST HYBRID, WELCOMES PLACE, 7388 NDABEZITHA STREET, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36320,'lng':28.37710}},
{'stp':581027,'address':'581027, EGL - ISANDO, ISANDO EAST HYBRID, EKASI PUB AND GRILL, 7063 MOSHOESHOE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29550,'lng':28.39120}},
{'stp':603671,'address':'603671, EGL - ISANDO, ISANDO EAST HYBRID, VILANES PLACE, 15535 KOKWANA STREET, EXT5, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35235,'lng':28.36092}},
{'stp':604694,'address':'604694, EGL - ISANDO, ISANDO EAST HYBRID, TWO BROTHERS TAVERN, 150 PRAGUE LANE, REEDVILLE, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.26670,'lng':28.39470}},
{'stp':605044,'address':'605044, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR CITY BRAKPAN, CNR 12TH & ERGO STREET, MARYVLEI, BRAKPAN, KWATHEMA, South Africa','geo':{'lat':-26.28450,'lng':28.38600}},
{'stp':607295,'address':'607295, EGL - ISANDO, ISANDO EAST HYBRID, UNCLES PLACE, 41/505 EXT 19, TSAKANE, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.36620,'lng':28.39943}},
{'stp':607386,'address':'607386, EGL - ISANDO, ISANDO EAST HYBRID, NOGES RESTAURANT, 6983 MASOLE SREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28170,'lng':28.39130}},
{'stp':612409,'address':'612409, EGL - ISANDO, ISANDO EAST HYBRID, ENGINEERS GOLF CLUB (NTO), MARIEVALE ARMY BASE, DUNNOTTAR, DUNNOTTAR, DUNNOTTAR, South Africa','geo':{'lat':-26.34498,'lng':28.48259}},
{'stp':625827,'address':'625827, EGL - ISANDO, ISANDO EAST HYBRID, THE RANGE, 19 HELEN JOSEPH CRESCENT, VLAKFONTE, BRAKPAN, BRAKPAN, DUDUZA, South Africa','geo':{'lat':-26.34974,'lng':28.40682}},
{'stp':627861,'address':'627861, EGL - ISANDO, ISANDO EAST HYBRID, MPUMIS PLACE PERMIT, 4 MDAKANE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.28105,'lng':28.39730}},
{'stp':630575,'address':'630575, EGL - ISANDO, ISANDO EAST HYBRID, B F T BOTTLE STORE, 6963 SHABANGU STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29795,'lng':28.40235}},
{'stp':635678,'address':'635678, EGL - ISANDO, ISANDO EAST HYBRID, TOHOS PLACE, 598 OTTOWA LANE, REEDVILLE, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.26521,'lng':28.39133}},
{'stp':635848,'address':'635848, EGL - ISANDO, ISANDO EAST HYBRID, DISBOS PLACE, 731 SANTO DOMINGO LANE, REEDVILLE, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.26380,'lng':28.39110}},
{'stp':640941,'address':'640941, EGL - ISANDO, ISANDO EAST HYBRID, RIEBEECK HOTEL, 20 SECOND AVENUE, SPRINGS CBD, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25113,'lng':28.44205}},
{'stp':648290,'address':'648290, EGL - ISANDO, ISANDO EAST HYBRID, AFRO SQUARE PUB AND GRILL, 15207 BANANA STREET, TSAKANE EXTENSION 5, BRAKPAN, TSAKANE, South Africa','geo':{'lat':-26.35560,'lng':28.36330}},
{'stp':650321,'address':'650321, EGL - ISANDO, ISANDO EAST HYBRID, CORNER RESTAURANT, ERF 1317, 10C 2ND AVENUE, CNR 4TH & 2ND AVENUE, SPRINGS, SPRINGS, South Africa','geo':{'lat':-26.25090,'lng':28.44080}},
{'stp':658457,'address':'658457, EGL - ISANDO, ISANDO EAST HYBRID, TOPS AT TSAKANE, 58 CNR MODJADJI AND MALANDELA STR, TSAKANE MALL, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35160,'lng':28.37260}},
{'stp':688408,'address':'688408, EGL - ISANDO, ISANDO EAST HYBRID, THE VENUE (NTO), 1078 MOSHOESHOE STREET, KWATHEMA, KWATHEMA, KWATHEMA, South Africa','geo':{'lat':-26.29350,'lng':28.39020}},
{'stp':694394,'address':'694394, EGL - ISANDO, ISANDO EAST HYBRID, NIGEL GOLF PRO SHOP (NTO), SPRINGS ROAD, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.39493,'lng':28.45587}},
{'stp':696341,'address':'696341, EGL - ISANDO, ISANDO EAST HYBRID, THEATRE CAFE\' (NTO), 27 HENDRIK VERWOERD STREET, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.39489,'lng':28.45575}},
{'stp':718668,'address':'718668, EGL - ISANDO, ISANDO EAST HYBRID, THE VENUE BOTTLE STORE, ERF 7084, KWA THEMA, #, KWATHEMA, South Africa','geo':{'lat':-26.29480,'lng':28.39160}},
{'stp':799207,'address':'799207, EGL - ISANDO, ISANDO EAST HYBRID, ISHUDU PUB AND GRILL, BHALA STREET, ERF 115, TSAKANE, TSAKANE, South Africa','geo':{'lat':-26.35440,'lng':28.38036}},
{'stp':311176,'address':'311176, EGL - ISANDO, ISANDO MAIN ON 1, SHESHISA TAVERN, 7730 STAND NO, ZONE 1, WINNIE MANDELA, IVORY PARK, South Africa','geo':{'lat':-25.98894,'lng':28.23645}},
{'stp':318747,'address':'318747, EGL - ISANDO, ISANDO MAIN ON 1, TLADI\'S PLACE, 54 STAND NO, KOPANONG, #, TEMBISA, South Africa','geo':{'lat':-26.00331,'lng':28.22693}},
{'stp':382515,'address':'382515, EGL - ISANDO, ISANDO MAIN ON 1, HOME  OF DEEP, 3806 STAND NO, OAKMOOR, EXTENTION 7, TEMBISA, South Africa','geo':{'lat':-25.99707,'lng':28.23571}},
{'stp':387801,'address':'387801, EGL - ISANDO, ISANDO MAIN ON 1, SIMINYA TAVERN, 36 STAND NO, EROS STREET, TSEPO, TEMBISA, South Africa','geo':{'lat':-25.99749,'lng':28.22900}},
{'stp':388448,'address':'388448, EGL - ISANDO, ISANDO MAIN ON 1, MAJOZI S PLACE, 10464 ERF, WINNIE MANDELA PARK, TEMBIZA EXT 24, TEMBISA, South Africa','geo':{'lat':-25.98887,'lng':28.22233}},
{'stp':391567,'address':'391567, EGL - ISANDO, ISANDO MAIN ON 1, PULLHOUSE PUB, 4162 STAND NO, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.04053,'lng':28.19756}},
{'stp':391739,'address':'391739, EGL - ISANDO, ISANDO MAIN ON 1, THE LEGACY NIGHT CLUB N RESTAURANT, 631 STAND NO, BERGRIVIER DRIVE, CHLOORKOP EXT 46, EDENVALE, South Africa','geo':{'lat':-26.07296,'lng':28.19321}},
{'stp':393028,'address':'393028, EGL - ISANDO, ISANDO MAIN ON 1, LEBO MOROPA, 331 MAOKENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99540,'lng':28.22780}},
{'stp':393082,'address':'393082, EGL - ISANDO, ISANDO MAIN ON 1, KWENZA S PLACE, 44 TWENTY, SEVEN APRIL STREET, EXTENTION 7, TEMBISA, South Africa','geo':{'lat':-25.99902,'lng':28.24530}},
{'stp':460339,'address':'460339, EGL - ISANDO, ISANDO MAIN ON 1, SKS LIQUOR, 314 STAND NO, MERCURY STREER, KOPANONG, TEMBISA, South Africa','geo':{'lat':-26.00345,'lng':28.23101}},
{'stp':464431,'address':'464431, EGL - ISANDO, ISANDO MAIN ON 1, KWA BRA DANS PLACE, LUCINA STREET, ERF 291, MAKHULONG SECTION, TEMBISA, South Africa','geo':{'lat':-25.99729,'lng':28.23184}},
{'stp':476724,'address':'476724, EGL - ISANDO, ISANDO MAIN ON 1, BRA GAV S PLACE, 52 STAND NO, CLIO STREET, 196 TSEPO SECTION, TEMBISA, South Africa','geo':{'lat':-25.99602,'lng':28.22807}},
{'stp':481215,'address':'481215, EGL - ISANDO, ISANDO MAIN ON 1, TJATJI S PLACE, 83 STAND NUMBER, CALLISTO STR, MASIMONG, TEMBISA, South Africa','geo':{'lat':-26.00107,'lng':28.23451}},
{'stp':545600,'address':'545600, EGL - ISANDO, ISANDO MAIN ON 1, YELLOW ZONE 2, 4194 STAND  NO, 11 KING MOSHOESHOE DRIVE, EXT 6, TEMBISA, South Africa','geo':{'lat':-26.02438,'lng':28.17900}},
{'stp':550263,'address':'550263, EGL - ISANDO, ISANDO MAIN ON 1, DUDUS PLACE, 15 GAMTOOS DRIVE, NORKEM PARK_EX 4, #, KEMPTON PARK, South Africa','geo':{'lat':-26.04311,'lng':28.21507}},
{'stp':550693,'address':'550693, EGL - ISANDO, ISANDO MAIN ON 1, WONDERS TAVERN, STAND 201, MORITHING SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99980,'lng':28.23930}},
{'stp':550994,'address':'550994, EGL - ISANDO, ISANDO MAIN ON 1, MAMPA NS, 362 TSEPO SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99570,'lng':28.22890}},
{'stp':551015,'address':'551015, EGL - ISANDO, ISANDO MAIN ON 1, THARI LIQ OUTLET, 865 MASHIMONG SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99810,'lng':28.23590}},
{'stp':551399,'address':'551399, EGL - ISANDO, ISANDO MAIN ON 1, HAMBA UZOBUYA, 122 SETHOKGA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99940,'lng':28.24240}},
{'stp':552123,'address':'552123, EGL - ISANDO, ISANDO MAIN ON 1, ABIES BOTTLE STORE, STAND 180, TLAMA TLAMA SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99935,'lng':28.22619}},
{'stp':552313,'address':'552313, EGL - ISANDO, ISANDO MAIN ON 1, TWIN BROTHERS TAVERN, 9932 WINNIE MANDELA PARK, ZONE 4, #, TEMBISA, South Africa','geo':{'lat':-25.98670,'lng':28.22420}},
{'stp':552371,'address':'552371, EGL - ISANDO, ISANDO MAIN ON 1, MPHAHLELE MH, 268 TSHENOLONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99990,'lng':28.21750}},
{'stp':552654,'address':'552654, EGL - ISANDO, ISANDO MAIN ON 1, MANEWA RR, 7120 ZONE 2, WINNIE MANDELA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.98420,'lng':28.22690}},
{'stp':553111,'address':'553111, EGL - ISANDO, ISANDO MAIN ON 1, BRIAN TAVERN, 956 HOSPITAL VEW EXTN 4, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99550,'lng':28.23970}},
{'stp':553312,'address':'553312, EGL - ISANDO, ISANDO MAIN ON 1, SUMMORE BOTTLE STORE, 430 TLAMATLAMA SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99500,'lng':28.22330}},
{'stp':553343,'address':'553343, EGL - ISANDO, ISANDO MAIN ON 1, S AND E LIQUOR STORE, 467 MAKHULONG SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99710,'lng':28.23550}},
{'stp':553781,'address':'553781, EGL - ISANDO, ISANDO MAIN ON 1, STEVES TAVERN, 171 TLAMAILAMA SECTION, TEMBISA, 1628, TEMBISA, South Africa','geo':{'lat':-25.99620,'lng':28.22580}},
{'stp':553963,'address':'553963, EGL - ISANDO, ISANDO MAIN ON 1, MOTHIBA MC, 505 TLAMATLAMA SECTION, TEMBISA (White house), #, TEMBISA, South Africa','geo':{'lat':-25.99380,'lng':28.22720}},
{'stp':560240,'address':'560240, EGL - ISANDO, ISANDO MAIN ON 1, MASUKU SJ, 62 JIYANE SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02750,'lng':28.21480}},
{'stp':560241,'address':'560241, EGL - ISANDO, ISANDO MAIN ON 1, SETLHABANE EM, 5376 HOSPITAL GARDENS, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.98770,'lng':28.24480}},
{'stp':560374,'address':'560374, EGL - ISANDO, ISANDO MAIN ON 1, MAZIYA WA, 7947 WINNIE MANDELA, PHASE 1, #, TEMBISA, South Africa','geo':{'lat':-25.99180,'lng':28.23710}},
{'stp':560434,'address':'560434, EGL - ISANDO, ISANDO MAIN ON 1, TEN TENS PLACE, 8068 WINNIE MANDELA PARK  ZONE 1, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99290,'lng':28.23790}},
{'stp':560492,'address':'560492, EGL - ISANDO, ISANDO MAIN ON 1, SUZMAN RECHARGE, 803 MOSHIMON SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99880,'lng':28.23543}},
{'stp':560511,'address':'560511, EGL - ISANDO, ISANDO MAIN ON 1, MSIBI ML, 639 TLAMATLAMA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99400,'lng':28.22520}},
{'stp':561138,'address':'561138, EGL - ISANDO, ISANDO MAIN ON 1, MOSADI SE, 5333 HOSPITAL GARDENS, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99180,'lng':28.24600}},
{'stp':561200,'address':'561200, EGL - ISANDO, ISANDO MAIN ON 1, MUCUME F, 124 TSWELAPELE SECTION, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.96810,'lng':28.21250}},
{'stp':580410,'address':'580410, EGL - ISANDO, ISANDO MAIN ON 1, MAVENDENG AN TEE TEES PALACE, 3958 BIRCH ACRES, EXT 23  (Umhohlo street), #, TEMBISA, South Africa','geo':{'lat':-26.03670,'lng':28.19460}},
{'stp':580411,'address':'580411, EGL - ISANDO, ISANDO MAIN ON 1, MARULE ML, 80 TAMOEKIE STREET, BIRCH ACRES(80Piet my vrou Cnr tambokie, #, TEMBISA, South Africa','geo':{'lat':-26.05175,'lng':28.19655}},
{'stp':580612,'address':'580612, EGL - ISANDO, ISANDO MAIN ON 1, HLONGO FM, 713 MAKHULONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99440,'lng':28.23300}},
{'stp':580814,'address':'580814, EGL - ISANDO, ISANDO MAIN ON 1, NALEDI TAVERN, 6249 WINNIE MANDELA, ZONE 5, #, TEMBISA, South Africa','geo':{'lat':-25.98230,'lng':28.22880}},
{'stp':581080,'address':'581080, EGL - ISANDO, ISANDO MAIN ON 1, MOKGOPA MS, 5484 HOSPITAL GARDENS, #, #, TEMBISA, South Africa','geo':{'lat':-25.98950,'lng':28.24510}},
{'stp':581159,'address':'581159, EGL - ISANDO, ISANDO MAIN ON 1, MAMA V S PLACE, 102 HANDEL STREET, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.08590,'lng':28.19980}},
{'stp':581237,'address':'581237, EGL - ISANDO, ISANDO MAIN ON 1, CHOMA MK, 13623 NOKENG STREET, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.98550,'lng':28.19800}},
{'stp':602871,'address':'602871, EGL - ISANDO, ISANDO MAIN ON 1, NGOBENI TE, 53 MINERVA STREET, ERF 248, MAKHULONG SECTION, TEMBISA, South Africa','geo':{'lat':-25.99800,'lng':28.23320}},
{'stp':603967,'address':'603967, EGL - ISANDO, ISANDO MAIN ON 1, THOBOKI MS, 60 VENUS STREET, KOPANONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.00400,'lng':28.22670}},
{'stp':603968,'address':'603968, EGL - ISANDO, ISANDO MAIN ON 1, MAKGATHO P, 625 MASHEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00630,'lng':28.23140}},
{'stp':603994,'address':'603994, EGL - ISANDO, ISANDO MAIN ON 1, SITHOLE SA 1, 3503 TSWELOPELE SECTION, EXT 6, #, IVORY PARK, South Africa','geo':{'lat':-25.96410,'lng':28.20860}},
{'stp':604156,'address':'604156, EGL - ISANDO, ISANDO MAIN ON 1, JOMOS TAVERN, 321 MASHEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00080,'lng':28.23470}},
{'stp':604378,'address':'604378, EGL - ISANDO, ISANDO MAIN ON 1, BAKWENA TAVERN, 6889 WINNIE MANDELA, TEMBISA (zone 2), #, TEMBISA, South Africa','geo':{'lat':-25.98600,'lng':28.22990}},
{'stp':604417,'address':'604417, EGL - ISANDO, ISANDO MAIN ON 1, PEDING INN, 419 MASHEMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99990,'lng':28.23460}},
{'stp':604809,'address':'604809, EGL - ISANDO, ISANDO MAIN ON 1, SY SPORTS BAR, SY SPORT\'S BAR, 274 MAKHULONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-25.99700,'lng':28.22970}},
{'stp':605791,'address':'605791, EGL - ISANDO, ISANDO MAIN ON 1, LERATONG TAVERN, 65 TLAMATLAMA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99910,'lng':28.22450}},
{'stp':606121,'address':'606121, EGL - ISANDO, ISANDO MAIN ON 1, TWINS INN, 274 TLAMATLAMA SECTION, RDJ NAMANE STREET, #, TEMBISA, South Africa','geo':{'lat':-25.99520,'lng':28.22650}},
{'stp':606125,'address':'606125, EGL - ISANDO, ISANDO MAIN ON 1, CONNIES PLACE, 19 MASHIMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00330,'lng':28.23560}},
{'stp':606179,'address':'606179, EGL - ISANDO, ISANDO MAIN ON 1, LETLERE NA, 628 MASHIMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00650,'lng':28.23120}},
{'stp':606190,'address':'606190, EGL - ISANDO, ISANDO MAIN ON 1, ZULU S, 993 MAOKENG EXT, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99340,'lng':28.21973}},
{'stp':606390,'address':'606390, EGL - ISANDO, ISANDO MAIN ON 1, RADZILANI TS, 6528 ZONE 2, WINNIE MANDELA EXT 23, #, TEMBISA, South Africa','geo':{'lat':-25.98630,'lng':28.23050}},
{'stp':625011,'address':'625011, EGL - ISANDO, ISANDO MAIN ON 1, BOPAPE SM, 137 KOPANONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00400,'lng':28.22910}},
{'stp':626384,'address':'626384, EGL - ISANDO, ISANDO MAIN ON 1, SKY LAB TAVERN, 504 TLAMATLA EXT, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99326,'lng':28.22736}},
{'stp':628060,'address':'628060, EGL - ISANDO, ISANDO MAIN ON 1, TURDOS PLACE, 21 UITSIGHELLING STREET, KEMPTON PARK WEST, #, KEMPTON PARK, South Africa','geo':{'lat':-26.08677,'lng':28.18532}},
{'stp':628064,'address':'628064, EGL - ISANDO, ISANDO MAIN ON 1, JOSEPH PLACE, 3346 TSWELOPELE, #, #, IVORY PARK, South Africa','geo':{'lat':-25.96340,'lng':28.20790}},
{'stp':628068,'address':'628068, EGL - ISANDO, ISANDO MAIN ON 1, MREX INN, 7938 WINNIE MANDELA Zone 1, #, #, TEMBISA, South Africa','geo':{'lat':-25.99140,'lng':28.23780}},
{'stp':628409,'address':'628409, EGL - ISANDO, ISANDO MAIN ON 1, MAIMELA GEN DEAL, 191 191 KOPANONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00440,'lng':28.23010}},
{'stp':628422,'address':'628422, EGL - ISANDO, ISANDO MAIN ON 1, RAMPURU, 5223/24 HOSPITAL HILL, EXTENTION 12 (Samora Machel street), #, TEMBISA, South Africa','geo':{'lat':-25.98660,'lng':28.24489}},
{'stp':629352,'address':'629352, EGL - ISANDO, ISANDO MAIN ON 1, KHAZAMULA TAVERN, 8064 WINNIE MANDELA, TEMBISA  (zone 1), #, TEMBISA, South Africa','geo':{'lat':-25.99280,'lng':28.23730}},
{'stp':629353,'address':'629353, EGL - ISANDO, ISANDO MAIN ON 1, TLADIS PLACE, 6336 ZONE 5, WINNIE MANDELA, #, TEMBISA, South Africa','geo':{'lat':-25.98180,'lng':28.22960}},
{'stp':629462,'address':'629462, EGL - ISANDO, ISANDO MAIN ON 1, PASS OFFICE, 289 MASHEMONG SECTION, TEMBISA (Callisto street), #, TEMBISA, South Africa','geo':{'lat':-26.00420,'lng':28.23230}},
{'stp':629463,'address':'629463, EGL - ISANDO, ISANDO MAIN ON 1, JB TAVERN, 454 AMELTHEA STREET, MASHEMONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00340,'lng':28.23210}},
{'stp':629464,'address':'629464, EGL - ISANDO, ISANDO MAIN ON 1, BRAZIL TAVERN, 768 MASHEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00060,'lng':28.23350}},
{'stp':629465,'address':'629465, EGL - ISANDO, ISANDO MAIN ON 1, LETHABONG PALACE, 234 TSHEPO SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99780,'lng':28.22760}},
{'stp':629466,'address':'629466, EGL - ISANDO, ISANDO MAIN ON 1, TIBASKO PALACE, 341 TSHEPO SECTION, TEMBISA- dont deliver to lethabong place, #, TEMBISA, South Africa','geo':{'lat':-25.99802,'lng':28.22653}},
{'stp':630043,'address':'630043, EGL - ISANDO, ISANDO MAIN ON 1, AUGUSTO\'S PLACE, 7697 IVORY PARK, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-26.00570,'lng':28.19610}},
{'stp':630093,'address':'630093, EGL - ISANDO, ISANDO MAIN ON 1, JERRY\'S PLACE, 3054 TSWELOPELE SECTION, TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.96120,'lng':28.20790}},
{'stp':631176,'address':'631176, EGL - ISANDO, ISANDO MAIN ON 1, MAPINKIES JAZZ BAR, 657 SOWETO STREET, TEMBISA KEMPTON PARK(Meriting section), #, TEMBISA, South Africa','geo':{'lat':-25.99239,'lng':28.24355}},
{'stp':631276,'address':'631276, EGL - ISANDO, ISANDO MAIN ON 1, MMAKWENAS PLACE, 773 MASHEMONG SECTION, MERCURY STREET, #, TEMBISA, South Africa','geo':{'lat':-26.00010,'lng':28.23380}},
{'stp':631803,'address':'631803, EGL - ISANDO, ISANDO MAIN ON 1, LIMPOPO RESTAURANT, 15320  EXT 13, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.97880,'lng':28.20180}},
{'stp':632307,'address':'632307, EGL - ISANDO, ISANDO MAIN ON 1, IKHAYA LO MUSIC, 533 TLAMATLAMA SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99275,'lng':28.22215}},
{'stp':633100,'address':'633100, EGL - ISANDO, ISANDO MAIN ON 1, OUPAS PLACE, 217 TSHEPO SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99860,'lng':28.22760}},
{'stp':633104,'address':'633104, EGL - ISANDO, ISANDO MAIN ON 1, ROSINAS PLACE TSWELAPELE, 394 TSWELAPELE, EXTENSION 1, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97139,'lng':28.21150}},
{'stp':633632,'address':'633632, EGL - ISANDO, ISANDO MAIN ON 1, FIKS SPORTS LEISURE, 24 EMBREN STREET, BIRCHLIEGH NORTH, #, KEMPTON PARK, South Africa','geo':{'lat':-26.03650,'lng':28.22980}},
{'stp':633998,'address':'633998, EGL - ISANDO, ISANDO MAIN ON 1, MOTSE MARIA INN TRADING PTY LTD, 73 TSWELOPELE STREET, EXTENSION 1, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.96770,'lng':28.21440}},
{'stp':634313,'address':'634313, EGL - ISANDO, ISANDO MAIN ON 1, MMABATHO TAVERN, 7534 JABU MDUNGE STREET, ZONE 2 WINNIE MANDELA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99130,'lng':28.22820}},
{'stp':635011,'address':'635011, EGL - ISANDO, ISANDO MAIN ON 1, THEMBISILES PLACE, 391 MAKNULONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99730,'lng':28.23440}},
{'stp':635690,'address':'635690, EGL - ISANDO, ISANDO MAIN ON 1, MA ANDREW, 290 MASHMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00400,'lng':28.23240}},
{'stp':637132,'address':'637132, EGL - ISANDO, ISANDO MAIN ON 1, MOSHIMEDIS PLACE, 7911 WINNIE MANDELA, ZONE 1, T, TEMBISA, South Africa','geo':{'lat':-25.99110,'lng':28.23610}},
{'stp':637142,'address':'637142, EGL - ISANDO, ISANDO MAIN ON 1, LINDA\'S TAVERN, 430 TSWELOPELE, EXTENSION 1, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97140,'lng':28.21090}},
{'stp':637157,'address':'637157, EGL - ISANDO, ISANDO MAIN ON 1, POPPY\'S TAVERN, 504 TSWELOPELE EXT 1, TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.97310,'lng':28.21230}},
{'stp':637171,'address':'637171, EGL - ISANDO, ISANDO MAIN ON 1, SIZA\'S PLACE, 156 TLAMA TLAMA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99600,'lng':28.22530}},
{'stp':637197,'address':'637197, EGL - ISANDO, ISANDO MAIN ON 1, MARYS PLACE, 74 SHAMBAMBONGOLOLO, EMKHATHINI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00980,'lng':28.21590}},
{'stp':637421,'address':'637421, EGL - ISANDO, ISANDO MAIN ON 1, THANDIS PLACE, 696 MASHIMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00510,'lng':28.23100}},
{'stp':637542,'address':'637542, EGL - ISANDO, ISANDO MAIN ON 1, ZIMBAS PLACE, 98 TLAMATLAMA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99860,'lng':28.22470}},
{'stp':637650,'address':'637650, EGL - ISANDO, ISANDO MAIN ON 1, MAGGIES PLACE, 675 MASHEMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00660,'lng':28.23050}},
{'stp':639186,'address':'639186, EGL - ISANDO, ISANDO MAIN ON 1, ZAMA ZAMA JERRY, 40 SETHOKGOA, THEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99950,'lng':28.24180}},
{'stp':639289,'address':'639289, EGL - ISANDO, ISANDO MAIN ON 1, EMMA\'S PLACE, 6957 IVORY PARK, IVORY PARK EXT 3, IVORY PARK, TEMBISA, South Africa','geo':{'lat':-25.99540,'lng':28.20410}},
{'stp':639557,'address':'639557, EGL - ISANDO, ISANDO MAIN ON 1, LEBOS TAVERN, 1199 HOSPITAL HILL EXT 4, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99140,'lng':28.23940}},
{'stp':640785,'address':'640785, EGL - ISANDO, ISANDO MAIN ON 1, EYETHU TAVERN, 3983 TSWELOPELE, EXTENSION 6, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.96570,'lng':28.21230}},
{'stp':640961,'address':'640961, EGL - ISANDO, ISANDO MAIN ON 1, MADIDIMALO TAVERN, 115 SETHOKGA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00000,'lng':28.24250}},
{'stp':641199,'address':'641199, EGL - ISANDO, ISANDO MAIN ON 1, CORNER JAZZ TAVERN, ERF 8089 WINNIE MANDELA, ZONE 1, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.98990,'lng':28.22982}},
{'stp':641762,'address':'641762, EGL - ISANDO, ISANDO MAIN ON 1, POLLY\'S INN, 1112 IVORY PARK, IVORY PARK EXT 2, #, IVORY PARK, South Africa','geo':{'lat':-26.00760,'lng':28.18010}},
{'stp':641808,'address':'641808, EGL - ISANDO, ISANDO MAIN ON 1, PHILLIPS PLACE, 309 MORITINO SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99900,'lng':28.24110}},
{'stp':641895,'address':'641895, EGL - ISANDO, ISANDO MAIN ON 1, MAX PLACE, 129 MASHIMONG SECTION num 129, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00420,'lng':28.23350}},
{'stp':646582,'address':'646582, EGL - ISANDO, ISANDO MAIN ON 1, THE BIRD IS CALLING, 16 ERF NO, 235 BRIAN MAZIBUKO EAST DRIVE, MERITING SECTION, TEMBISA, South Africa','geo':{'lat':-26.00207,'lng':28.24167}},
{'stp':646698,'address':'646698, EGL - ISANDO, ISANDO MAIN ON 1, SHANDU\'S PLACE, 136 MKHATHINI SECTION  num 136, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01118,'lng':28.21622}},
{'stp':647201,'address':'647201, EGL - ISANDO, ISANDO MAIN ON 1, MZINGILI TRADING, 894/ 22 MASHIMONG SECTION 894/22, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.00740,'lng':28.23060}},
{'stp':647866,'address':'647866, EGL - ISANDO, ISANDO MAIN ON 1, FIGO\'S PLACE, 13851 IVORY PARK, EXT 12, #, TEMBISA, South Africa','geo':{'lat':-25.98380,'lng':28.20550}},
{'stp':649394,'address':'649394, EGL - ISANDO, ISANDO MAIN ON 1, FLO\'S PLACE, 2671 KAALFONTEIN EXT 5, #, #, IVORY PARK, South Africa','geo':{'lat':-25.98620,'lng':28.17420}},
{'stp':649409,'address':'649409, EGL - ISANDO, ISANDO MAIN ON 1, GP TAVERN, 1319/25 EXT 2, #, #, IVORY PARK, South Africa','geo':{'lat':-26.00820,'lng':28.18000}},
{'stp':649848,'address':'649848, EGL - ISANDO, ISANDO MAIN ON 1, MAGRETS PLACE, 60 WINNIE MANDELA ZONE 3, #, #, TEMBISA, South Africa','geo':{'lat':-25.99160,'lng':28.22760}},
{'stp':655712,'address':'655712, EGL - ISANDO, ISANDO MAIN ON 1, GUZZLERS LIQUOR STORE, 1019 ERF NO, CNR PONGOLA AND BREERIVIER STREET, NORKEN PARK EXTENSION 2, KEMPTON PARK, South Africa','geo':{'lat':-26.04550,'lng':28.21870}},
{'stp':667543,'address':'667543, EGL - ISANDO, ISANDO MAIN ON 1, MORUTIS LIQUOR STORE, 5761 MORITING SECTION, #, EXTENSION 9, TEMBISA, South Africa','geo':{'lat':-25.99700,'lng':28.24460}},
{'stp':677247,'address':'677247, EGL - ISANDO, ISANDO MAIN ON 1, BLAZA PALACE, 0 391 TSEPO SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99532,'lng':28.22753}},
{'stp':685916,'address':'685916, EGL - ISANDO, ISANDO MAIN ON 1, VMS PLACE, 5603 HOSPITAL GARDEN, #, EXT 12, TEMBISA, South Africa','geo':{'lat':-25.99270,'lng':28.24550}},
{'stp':706590,'address':'706590, EGL - ISANDO, ISANDO MAIN ON 1, MISSISSIPPI TAVERN, 1800 TSWELOPELE SECTION, EXT 5 WINNIE MANDELA ZONE 8, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97570,'lng':28.21850}},
{'stp':725052,'address':'725052, EGL - ISANDO, ISANDO MAIN ON 1, GAV LIQUOR DISTRIBUTORS, 86 EMKHATHINI SECTION, #, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01013,'lng':28.21580}},
{'stp':736529,'address':'736529, EGL - ISANDO, ISANDO MAIN ON 1, CHILLAS PLACE, ERF 704 SKYLAB STREET, MAKHULONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-25.99410,'lng':28.23206}},
{'stp':770302,'address':'770302, EGL - ISANDO, ISANDO MAIN ON 1, PELA AL TRADING TRANSPORT, STAND NO, 3904 IVORY PARK 2, #, IVORY PARK, South Africa','geo':{'lat':-26.00263,'lng':28.19238}},
{'stp':771105,'address':'771105, EGL - ISANDO, ISANDO MAIN ON 1, MALAPA TAVERN, ERF 3034, FOWL STR HOSPITAL HILL, EXT 6, TEMBISA, South Africa','geo':{'lat':-25.98788,'lng':28.23510}},
{'stp':774623,'address':'774623, EGL - ISANDO, ISANDO MAIN ON 1, MAKOLAS PLACE, 735 STAND NO, SEDIBENG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.00140,'lng':28.23950}},
{'stp':774633,'address':'774633, EGL - ISANDO, ISANDO MAIN ON 1, GEZANI DOG, 1214 STAND NO, DUDUZA IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.01128,'lng':28.18190}},
{'stp':798041,'address':'798041, EGL - ISANDO, ISANDO MAIN ON 1, NKWITSIDI TAVERN, STAND NO, ERF 6384 EXT 23 KEMPTON PARK, #, TEMBISA, South Africa','geo':{'lat':-25.98236,'lng':28.23146}},
{'stp':387978,'address':'387978, EGL - ISANDO, ISANDO MAIN ON 1, DIANAS TAVERN, 21 STAND NO, MOTSU STREET, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00735,'lng':28.20133}},
{'stp':390773,'address':'390773, EGL - ISANDO, ISANDO MAIN ON 1, RICHS PLACE, 378 STAND NO, MUTSO SECTION TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00854,'lng':28.20243}},
{'stp':392029,'address':'392029, EGL - ISANDO, ISANDO MAIN ON 1, S CONNER TAVERN, 13662 THAKALO STREET, IVORY PARK EXT 12, #, IVORY PARK, South Africa','geo':{'lat':-25.99139,'lng':28.20066}},
{'stp':462163,'address':'462163, EGL - ISANDO, ISANDO MAIN ON 1, MAKHALAS PLACE, 4126 NDLUKULU STREET, IVORY PARK, EXT 6, TEMBISA, South Africa','geo':{'lat':-26.00802,'lng':28.19158}},
{'stp':466858,'address':'466858, EGL - ISANDO, ISANDO MAIN ON 1, CEILA THABALANG, 16112 STAND NO, IVORY PARK EXT 2, #, IVORY PARK, South Africa','geo':{'lat':-26.01677,'lng':28.17938}},
{'stp':470722,'address':'470722, EGL - ISANDO, ISANDO MAIN ON 1, SA MATHEBULA S, 3057 STAND NO, IVORY PARK EXT 5, #, TEMBISA, South Africa','geo':{'lat':-26.01328,'lng':28.18339}},
{'stp':476119,'address':'476119, EGL - ISANDO, ISANDO MAIN ON 1, PROS PLACE, 8250 STAND NO, IVORY PARK, EXT 8, TEMBISA, South Africa','geo':{'lat':-25.99201,'lng':28.19436}},
{'stp':477506,'address':'477506, EGL - ISANDO, ISANDO MAIN ON 1, MVUBU S PLACE, 6049 STAND NUMBER, HIKHENSILE CRES, IVORY PARK, TEMBISA, South Africa','geo':{'lat':-26.00378,'lng':28.18854}},
{'stp':478358,'address':'478358, EGL - ISANDO, ISANDO MAIN ON 1, BELLA VISTA TAVERN, 2591 ICEPHE STREET, IVORY PARK EXT 2, JOHANNESBURG, IVORY PARK, South Africa','geo':{'lat':-26.01499,'lng':28.18002}},
{'stp':479092,'address':'479092, EGL - ISANDO, ISANDO MAIN ON 1, MZALA S LIQUORS, 18520 STAND NO, GOVAN MBEKI STREET, EXT 2, TEMBISA, South Africa','geo':{'lat':-26.01201,'lng':28.17754}},
{'stp':481105,'address':'481105, EGL - ISANDO, ISANDO MAIN ON 1, SEDANIS PLACE, 2329 ERF, 135B THERESA ROAD COMMERCIAL,  EXTENSION 34, TEMBISA, South Africa','geo':{'lat':-26.03371,'lng':28.16997}},
{'stp':550339,'address':'550339, EGL - ISANDO, ISANDO MAIN ON 1, GOBENE MV, 17526  EXT 3, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.98647,'lng':28.20353}},
{'stp':550410,'address':'550410, EGL - ISANDO, ISANDO MAIN ON 1, CHIYA MI, 6629 MORAPEDI STREET, IVORY PARK, MIDRAND, TEMBISA, South Africa','geo':{'lat':-25.99770,'lng':28.20090}},
{'stp':550504,'address':'550504, EGL - ISANDO, ISANDO MAIN ON 1, MAEKO NC, 10984 IVORY PARK, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.99115,'lng':28.19671}},
{'stp':550812,'address':'550812, EGL - ISANDO, ISANDO MAIN ON 1, MUSUSUMELI T, 1702 IVORY PARK, EXT 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.01040,'lng':28.18110}},
{'stp':550922,'address':'550922, EGL - ISANDO, ISANDO MAIN ON 1, MAMARABU S TAVERN, ERF 1298 MEHLALE STREET, EXT 2, IVORY PARK, IVORY PARK, South Africa','geo':{'lat':-26.01040,'lng':28.17780}},
{'stp':551465,'address':'551465, EGL - ISANDO, ISANDO MAIN ON 1, BOSHOMANE MD, 224 LEKANENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01750,'lng':28.20500}},
{'stp':551504,'address':'551504, EGL - ISANDO, ISANDO MAIN ON 1, KHOZA S TAVERN, 4481 IVORY PARK, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.00870,'lng':28.19420}},
{'stp':551546,'address':'551546, EGL - ISANDO, ISANDO MAIN ON 1, MAMISE TR, 14124 IVORY PARK, EXT 12, #, TEMBISA, South Africa','geo':{'lat':-25.98760,'lng':28.21000}},
{'stp':551624,'address':'551624, EGL - ISANDO, ISANDO MAIN ON 1, IVORY PARK TAVERN, STAND 1846, IVORY PARK, EXT 2, IVORY PARK, South Africa','geo':{'lat':-26.01100,'lng':28.18210}},
{'stp':552671,'address':'552671, EGL - ISANDO, ISANDO MAIN ON 1, JAZZ HOUSE TAVERN, 6215 MNGUNI STREET,  EXTENSION 8, #, TEMBISA, South Africa','geo':{'lat':-25.99710,'lng':28.20470}},
{'stp':552673,'address':'552673, EGL - ISANDO, ISANDO MAIN ON 1, MOTLOGELWA BOTTLE STORE, 751 SEOTLWANA SECTION, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00780,'lng':28.20528}},
{'stp':552992,'address':'552992, EGL - ISANDO, ISANDO MAIN ON 1, LADY JOY TAVERN, 1820 TEANONG SECTION, TEMBISA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00097,'lng':28.20777}},
{'stp':553143,'address':'553143, EGL - ISANDO, ISANDO MAIN ON 1, PEA INN TAVERN, ERF 8937 IVORY PARK, EXTENSION 9, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99270,'lng':28.20060}},
{'stp':553155,'address':'553155, EGL - ISANDO, ISANDO MAIN ON 1, CHOKOE MF, 13923 IVORY PARK, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-25.98566,'lng':28.20295}},
{'stp':553308,'address':'553308, EGL - ISANDO, ISANDO MAIN ON 1, ABBEYS ENTERPRISES, 82 NTOZONKE STREET, SETHOKGA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00380,'lng':28.24210}},
{'stp':553473,'address':'553473, EGL - ISANDO, ISANDO MAIN ON 1, CAPRIVI BOTTLE STORE, ERF 750, SEOTLOANA TOWNSHIP, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.00770,'lng':28.20580}},
{'stp':560368,'address':'560368, EGL - ISANDO, ISANDO MAIN ON 1, SIBIYA P, 9087 IVORY PARK, Ivory Park, #, TEMBISA, South Africa','geo':{'lat':-25.98988,'lng':28.20627}},
{'stp':560369,'address':'560369, EGL - ISANDO, ISANDO MAIN ON 1, KEKANA LJ, ERF 12006 IVORY PARK, IVORY PARK, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.99080,'lng':28.19760}},
{'stp':560418,'address':'560418, EGL - ISANDO, ISANDO MAIN ON 1, JAZZ INN, 10964 IVORY PARK, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.99170,'lng':28.19660}},
{'stp':560502,'address':'560502, EGL - ISANDO, ISANDO MAIN ON 1, TSHABALALA P, 7257 IVORY PARK, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-26.00310,'lng':28.20180}},
{'stp':561055,'address':'561055, EGL - ISANDO, ISANDO MAIN ON 1, MAPULE DORCAS RABEDZWANA, 677 MASHIMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00680,'lng':28.23000}},
{'stp':561059,'address':'561059, EGL - ISANDO, ISANDO MAIN ON 1, MATHEBULA SP, 8342 IVORY PARK, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.99940,'lng':28.20210}},
{'stp':561114,'address':'561114, EGL - ISANDO, ISANDO MAIN ON 1, MPUSE\'S PLACE, 411 TEANONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00220,'lng':28.20910}},
{'stp':561205,'address':'561205, EGL - ISANDO, ISANDO MAIN ON 1, MNCUBE TR, 3451 IVORY PARK, #, #, TEMBISA, South Africa','geo':{'lat':-26.01920,'lng':28.18140}},
{'stp':580389,'address':'580389, EGL - ISANDO, ISANDO MAIN ON 1, MOGALE AS, 8930 IVORY PARK EXT 3, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.99270,'lng':28.20160}},
{'stp':580521,'address':'580521, EGL - ISANDO, ISANDO MAIN ON 1, MBATHA MR, 4528 IVORY PARK, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-26.00900,'lng':28.19140}},
{'stp':581069,'address':'581069, EGL - ISANDO, ISANDO MAIN ON 1, ACES PLACE, 9518 IVORY PARK, EXTENSION 9, #, TEMBISA, South Africa','geo':{'lat':-25.99129,'lng':28.21749}},
{'stp':603788,'address':'603788, EGL - ISANDO, ISANDO MAIN ON 1, PHUMIS TAVERN, 8325 IVORY PARK, EXT 8, #, TEMBISA, South Africa','geo':{'lat':-26.00015,'lng':28.20086}},
{'stp':604622,'address':'604622, EGL - ISANDO, ISANDO MAIN ON 1, MALONI NA, 5683 IVORY PARK, EXT  7, #, IVORY PARK, South Africa','geo':{'lat':-26.00880,'lng':28.18570}},
{'stp':605448,'address':'605448, EGL - ISANDO, ISANDO MAIN ON 1, MAVHERENGIS PLACE, MAVHERENGI\'S PLACE, 6847 IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.99795,'lng':28.20433}},
{'stp':607388,'address':'607388, EGL - ISANDO, ISANDO MAIN ON 1, TIMZAS PLACE, 1859 TEANONG SECTION, EXT 5, #, TEMBISA, South Africa','geo':{'lat':-26.00090,'lng':28.20770}},
{'stp':607432,'address':'607432, EGL - ISANDO, ISANDO MAIN ON 1, SERVICE TAVERN, 2730 EXT 2, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.01330,'lng':28.18540}},
{'stp':625016,'address':'625016, EGL - ISANDO, ISANDO MAIN ON 1, NELLYS TAVERN, 10033 IVORY PARK, EXT 9, #, TEMBISA, South Africa','geo':{'lat':-25.99560,'lng':28.21150}},
{'stp':627037,'address':'627037, EGL - ISANDO, ISANDO MAIN ON 1, BELLAS PLACE, 1527 LEBOENG, EXTENTION 05, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00050,'lng':28.20820}},
{'stp':627328,'address':'627328, EGL - ISANDO, ISANDO MAIN ON 1, SARAHS PLACE, 09867-000 IVORY PARK(mashemi st), TEMBISA (Roni slasla section), #, TEMBISA, South Africa','geo':{'lat':-25.99373,'lng':28.21272}},
{'stp':627331,'address':'627331, EGL - ISANDO, ISANDO MAIN ON 1, NOMVELIS TAVERN, 2385 ESKOM STREET, EXT 2 IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.01260,'lng':28.18100}},
{'stp':627333,'address':'627333, EGL - ISANDO, ISANDO MAIN ON 1, MAKGATAS PLACE, 1887 EXT 2, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.01120,'lng':28.18190}},
{'stp':627348,'address':'627348, EGL - ISANDO, ISANDO MAIN ON 1, WALTER INN, 2338 POLAR PARK  UMKHONTO ST, IVORY PARK EXT 2, #, IVORY PARK, South Africa','geo':{'lat':-26.01650,'lng':28.18060}},
{'stp':627356,'address':'627356, EGL - ISANDO, ISANDO MAIN ON 1, JOSIAS PLACE, 8688 THULA STREET, (CNR OF MADIGA & UZIMMKHLU STREET, EXT 9, TEMBISA, South Africa','geo':{'lat':-25.98960,'lng':28.20810}},
{'stp':627738,'address':'627738, EGL - ISANDO, ISANDO MAIN ON 1, JOHANNES\'S PLACE, 3293 NKABINDE STREET, IVORY PARK EXT 5, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01460,'lng':28.18550}},
{'stp':627739,'address':'627739, EGL - ISANDO, ISANDO MAIN ON 1, ELVIS, 152 MOSES SIKOSANA, IVORY PARK (Extention 7), TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00211,'lng':28.19783}},
{'stp':627740,'address':'627740, EGL - ISANDO, ISANDO MAIN ON 1, MASULEKA, 6532 DLAMINI (ext 6 ivory park), HALFWAY HOUSE(near mqele school ), #, TEMBISA, South Africa','geo':{'lat':-25.99590,'lng':28.20030}},
{'stp':627741,'address':'627741, EGL - ISANDO, ISANDO MAIN ON 1, LINDI\'S PLACE, 3279 IVORY PARK EXT 5, IVORY PARK, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01930,'lng':28.18060}},
{'stp':628069,'address':'628069, EGL - ISANDO, ISANDO MAIN ON 1, JUKES PLACE, 10478 EXTENTION 9, EXTENTION 9, #, TEMBISA, South Africa','geo':{'lat':-25.99451,'lng':28.20752}},
{'stp':628943,'address':'628943, EGL - ISANDO, ISANDO MAIN ON 1, BOYKIE\'S PLACE, 1838 KHOZA STREET, EXTENTION 51 (Phomolong section), CHLOORKOP, TEMBISA, South Africa','geo':{'lat':-26.04250,'lng':28.18020}},
{'stp':629096,'address':'629096, EGL - ISANDO, ISANDO MAIN ON 1, JOYCE PLACE, 12120 EXTENSION10, SONHEUWEL, IVORY PARK, IVORY PARK, South Africa','geo':{'lat':-25.99185,'lng':28.19942}},
{'stp':629097,'address':'629097, EGL - ISANDO, ISANDO MAIN ON 1, MTHIMKHULU\'S TAVERN, 10361 BLUE HILL, EXTENTION 9, #, TEMBISA, South Africa','geo':{'lat':-25.99680,'lng':28.20780}},
{'stp':629098,'address':'629098, EGL - ISANDO, ISANDO MAIN ON 1, KABELO\'S TAVERN, 34 GONIWE STREET, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.98790,'lng':28.20290}},
{'stp':629110,'address':'629110, EGL - ISANDO, ISANDO MAIN ON 1, VERONICA\'S PLACE, 2390 ESKOM STREET, EXTENTION 2, #, IVORY PARK, South Africa','geo':{'lat':-26.01270,'lng':28.18150}},
{'stp':630416,'address':'630416, EGL - ISANDO, ISANDO MAIN ON 1, ISAAC GENERAL DEALER, 10612 SPRINGBOK STREET, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.99362,'lng':28.20598}},
{'stp':630872,'address':'630872, EGL - ISANDO, ISANDO MAIN ON 1, BENS PLACE, 4064 IVORY PARK EXT 6, RANDBURG, #, TEMBISA, South Africa','geo':{'lat':-26.00600,'lng':28.19350}},
{'stp':631328,'address':'631328, EGL - ISANDO, ISANDO MAIN ON 1, LILLIANS TAVERN, 9288 EXTENSION 2, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.99060,'lng':28.20900}},
{'stp':631774,'address':'631774, EGL - ISANDO, ISANDO MAIN ON 1, MONACCO TUCKSHOP, 7957 EXT 7, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-26.00400,'lng':28.19580}},
{'stp':631993,'address':'631993, EGL - ISANDO, ISANDO MAIN ON 1, ZAMA ZAMA, 4509 EXTENSION 6, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-26.01052,'lng':28.19196}},
{'stp':631996,'address':'631996, EGL - ISANDO, ISANDO MAIN ON 1, ESIKHALENI, 8414 EXTENSION 3, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-26.00010,'lng':28.20300}},
{'stp':632024,'address':'632024, EGL - ISANDO, ISANDO MAIN ON 1, NGOZISI INN, 8120 IVORY PARK, JOHANNESBURG, #, TEMBISA, South Africa','geo':{'lat':-25.99930,'lng':28.19640}},
{'stp':632332,'address':'632332, EGL - ISANDO, ISANDO MAIN ON 1, MATHEBULA, 4644 IVORY PARK, EXTENSION 6, #, TEMBISA, South Africa','geo':{'lat':-26.01060,'lng':28.18960}},
{'stp':632700,'address':'632700, EGL - ISANDO, ISANDO MAIN ON 1, MHLABAS PLACE, 622 SEOTHLANE SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01060,'lng':28.20350}},
{'stp':633084,'address':'633084, EGL - ISANDO, ISANDO MAIN ON 1, NTANGA\'S PLACE, 2363 IVORY PARK EXT 2, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-26.01450,'lng':28.18040}},
{'stp':633103,'address':'633103, EGL - ISANDO, ISANDO MAIN ON 1, MAFUNKWANE TAVERN, 6722 IVORY PARK, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-25.99620,'lng':28.20140}},
{'stp':633466,'address':'633466, EGL - ISANDO, ISANDO MAIN ON 1, NTHABISENG, 5738 IVORY PARK, EXTENSION 7, IVORY PARK, IVORY PARK, South Africa','geo':{'lat':-26.00920,'lng':28.18640}},
{'stp':633599,'address':'633599, EGL - ISANDO, ISANDO MAIN ON 1, SAFARI PLACE, 2298 IVORY PARK, INGWE STR EXT 2, #, IVORY PARK, South Africa','geo':{'lat':-26.01430,'lng':28.17720}},
{'stp':633635,'address':'633635, EGL - ISANDO, ISANDO MAIN ON 1, PHILLIP\'S PLACE, 752 SEOTLWANA SECTION, TEMBISA, KEMPTONPARK, TEMBISA, South Africa','geo':{'lat':-26.00810,'lng':28.20560}},
{'stp':634481,'address':'634481, EGL - ISANDO, ISANDO MAIN ON 1, SALLY\'S TUCK SHOP, 4358 IVORY PARK, EXTENTION 6, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.00630,'lng':28.19330}},
{'stp':634515,'address':'634515, EGL - ISANDO, ISANDO MAIN ON 1, MOJELAS TAVERN, 14276 LETHABONG SECTION, IVORY, #, TEMBISA, South Africa','geo':{'lat':-25.98550,'lng':28.21060}},
{'stp':634561,'address':'634561, EGL - ISANDO, ISANDO MAIN ON 1, DON CARLOS, 8311  IVORY PARK, MIDRAND, JOHANNESBURG, TEMBISA, South Africa','geo':{'lat':-26.00004,'lng':28.20136}},
{'stp':634750,'address':'634750, EGL - ISANDO, ISANDO MAIN ON 1, A SIWELANI, 7556 IVORY PARK, EXTENSION 8, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.00420,'lng':28.19800}},
{'stp':635744,'address':'635744, EGL - ISANDO, ISANDO MAIN ON 1, NAKOS PLACE, 578, KLIPFONTEIN, #, TEMBISA, South Africa','geo':{'lat':-26.04460,'lng':28.15790}},
{'stp':635778,'address':'635778, EGL - ISANDO, ISANDO MAIN ON 1, MATOBIS PLACE, 107 UMFUYANENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01730,'lng':28.19810}},
{'stp':635829,'address':'635829, EGL - ISANDO, ISANDO MAIN ON 1, GUMEDE\'S TUCKSHOP, 6511, IVORYPARK EXT 8, #, TEMBISA, South Africa','geo':{'lat':-25.99526,'lng':28.20002}},
{'stp':635921,'address':'635921, EGL - ISANDO, ISANDO MAIN ON 1, ALINAHS PLACE, 604 SEOTLWANA SECTION, JOHANNESBURG Tembisa, #, TEMBISA, South Africa','geo':{'lat':-26.01120,'lng':28.20370}},
{'stp':637125,'address':'637125, EGL - ISANDO, ISANDO MAIN ON 1, VANGI\'S PLACE, 4856 IVORY PARK EXT 2, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01124,'lng':28.19078}},
{'stp':637491,'address':'637491, EGL - ISANDO, ISANDO MAIN ON 1, MEISIES TAVERN, 647 SEOTLANA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01000,'lng':28.20310}},
{'stp':637492,'address':'637492, EGL - ISANDO, ISANDO MAIN ON 1, FIKILE INN,  671 SEOTLOANA STREET, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01000,'lng':28.20340}},
{'stp':638454,'address':'638454, EGL - ISANDO, ISANDO MAIN ON 1, NANAS PLACE, 722 EMANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01108,'lng':28.21422}},
{'stp':638831,'address':'638831, EGL - ISANDO, ISANDO MAIN ON 1, SIBONGILES PLACE, 4125 IVORY PARK, EXT 7, #, TEMBISA, South Africa','geo':{'lat':-26.00770,'lng':28.19190}},
{'stp':638892,'address':'638892, EGL - ISANDO, ISANDO MAIN ON 1, SITHOLES PLACE, 1493 IVORY PARK, EXT 2, HALFWAY HOUSE, #, IVORY PARK, South Africa','geo':{'lat':-26.01130,'lng':28.17730}},
{'stp':639136,'address':'639136, EGL - ISANDO, ISANDO MAIN ON 1, MGIBA\'S PLACE, 14229 EXT 12, IVORY PARK, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98650,'lng':28.21200}},
{'stp':639196,'address':'639196, EGL - ISANDO, ISANDO MAIN ON 1, BKS PLACE, 2152 EXT 34, MAYIBUYE SECTION, THEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03170,'lng':28.17100}},
{'stp':639625,'address':'639625, EGL - ISANDO, ISANDO MAIN ON 1, FRANS PLACE, 7062 EXT 8, IVORY PARK, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00008,'lng':28.20413}},
{'stp':640450,'address':'640450, EGL - ISANDO, ISANDO MAIN ON 1, MTWANANIS PLACE, 9003 EXT 9, IVORY PARK, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99110,'lng':28.20490}},
{'stp':640597,'address':'640597, EGL - ISANDO, ISANDO MAIN ON 1, MANDLISA, 6989 MAGWAGWA STREET, EXT 8, IIVORY PARK, TEMBISA, South Africa','geo':{'lat':-26.00143,'lng':28.20448}},
{'stp':640752,'address':'640752, EGL - ISANDO, ISANDO MAIN ON 1, PATRAO, 7384 IVORY PARK EXT 7, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.00517,'lng':28.19899}},
{'stp':641244,'address':'641244, EGL - ISANDO, ISANDO MAIN ON 1, MAVUSO\'S PLACE, 7505 IVORY PARK, Thembisa, #, TEMBISA, South Africa','geo':{'lat':-26.00357,'lng':28.19849}},
{'stp':641631,'address':'641631, EGL - ISANDO, ISANDO MAIN ON 1, MORRIS PLACE, 525 ECALENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01340,'lng':28.24460}},
{'stp':641840,'address':'641840, EGL - ISANDO, ISANDO MAIN ON 1, EMILY\'S PALACE, 7063 num 7063 kingdom street, EXT 8, ivory park, TEMBISA, South Africa','geo':{'lat':-26.00000,'lng':28.20410}},
{'stp':642428,'address':'642428, EGL - ISANDO, ISANDO MAIN ON 1, TSHIMANGADZO\'S PLACE, 3393 IVORY PARK EXT 2 3393, IVORY PARK EXT 2, IVORY PARK, TEMBISA, South Africa','geo':{'lat':-26.01670,'lng':28.18460}},
{'stp':642445,'address':'642445, EGL - ISANDO, ISANDO MAIN ON 1, KIDIBONE\'S PLACE, 5367 KAALFONTEIN, EXTENSION 22, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98593,'lng':28.19047}},
{'stp':642612,'address':'642612, EGL - ISANDO, ISANDO MAIN ON 1, TEMBA\'S PLACE, 0630/107 TEMONG SECTION num  0630/1, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00420,'lng':28.21720}},
{'stp':646485,'address':'646485, EGL - ISANDO, ISANDO MAIN ON 1, MAKHOSI PLACE, 4737 STAND NO, 30 BASAMBILU CIRCLE, IVORY PARK EXTENSIO 6, TEMBISA, South Africa','geo':{'lat':-26.01148,'lng':28.19005}},
{'stp':646603,'address':'646603, EGL - ISANDO, ISANDO MAIN ON 1, MASIBINI INN, 10734 IVORY PARK  num 10734, IVORY PARK EXT 10, MIDRAND, TEMBISA, South Africa','geo':{'lat':-25.99513,'lng':28.20450}},
{'stp':646925,'address':'646925, EGL - ISANDO, ISANDO MAIN ON 1, NGWENYA TAVERN, 6770 IVORY PARK  num 6770, EXTENSION 8, IVORY PARK, TEMBISA, South Africa','geo':{'lat':-25.99730,'lng':28.20280}},
{'stp':646938,'address':'646938, EGL - ISANDO, ISANDO MAIN ON 1, MOSIA\'S PLACE, 1200 IVORY PARK 1200, EXT 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.00960,'lng':28.17750}},
{'stp':647887,'address':'647887, EGL - ISANDO, ISANDO MAIN ON 1, MZANZALE, 9078 IVORY PARK, EXT 9, #, TEMBISA, South Africa','geo':{'lat':-25.99000,'lng':28.20520}},
{'stp':647955,'address':'647955, EGL - ISANDO, ISANDO MAIN ON 1, HAPPINE\'S PLACE, 55 ENHLANZENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.03280,'lng':28.20300}},
{'stp':647983,'address':'647983, EGL - ISANDO, ISANDO MAIN ON 1, SIPHO\'S TAVERN, 134 TEANENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00590,'lng':28.20619}},
{'stp':648022,'address':'648022, EGL - ISANDO, ISANDO MAIN ON 1, MAM RUDI, 7652 IVORY PARK, EXTENSION 8, #, TEMBISA, South Africa','geo':{'lat':-26.00590,'lng':28.19720}},
{'stp':649352,'address':'649352, EGL - ISANDO, ISANDO MAIN ON 1, JAGLO INN, 325 DIFATENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01360,'lng':28.21140}},
{'stp':649787,'address':'649787, EGL - ISANDO, ISANDO MAIN ON 1, TSONGAS TAVERN, 6823 IVORY PARK, #, #, TEMBISA, South Africa','geo':{'lat':-25.99755,'lng':28.20319}},
{'stp':654128,'address':'654128, EGL - ISANDO, ISANDO MAIN ON 1, CAPRIVI BAR (NTO), 750 SEOTLOANASECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01101,'lng':28.20207}},
{'stp':655889,'address':'655889, EGL - ISANDO, ISANDO MAIN ON 1, ANNETJIE JACOBS (NTO), 350 RABIE RIDGE, RABIE RIDGE, Midrand, IVORY PARK, South Africa','geo':{'lat':-26.01607,'lng':28.17269}},
{'stp':656676,'address':'656676, EGL - ISANDO, ISANDO MAIN ON 1, JOYCE NEMADZIVHODI, HOUSE NO 2321, IVORY Park, #, IVORY PARK, South Africa','geo':{'lat':-26.01370,'lng':28.17970}},
{'stp':658388,'address':'658388, EGL - ISANDO, ISANDO MAIN ON 1, MOROPAS PLACE, 6325 IVORY PARK, EXTENSION 6, #, TEMBISA, South Africa','geo':{'lat':-25.99310,'lng':28.19990}},
{'stp':666061,'address':'666061, EGL - ISANDO, ISANDO MAIN ON 1, MALINDIS PLACE, 106 ESIQONGWENI, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02250,'lng':28.18640}},
{'stp':671195,'address':'671195, EGL - ISANDO, ISANDO MAIN ON 1, MAPULANENG PLACE, 7584 IVORY PARK, #, EXT 3, TEMBISA, South Africa','geo':{'lat':-26.00413,'lng':28.19791}},
{'stp':683187,'address':'683187, EGL - ISANDO, ISANDO MAIN ON 1, W D TAVERN, 3900 UMHLOBOWENENE STREET, IVORY PARK,  EXT 6, TEMBISA, South Africa','geo':{'lat':-26.00290,'lng':28.19250}},
{'stp':686982,'address':'686982, EGL - ISANDO, ISANDO MAIN ON 1, ZAMA ZAMA TAVERN, 3367 IVORY PARK EXT 5, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.01630,'lng':28.18440}},
{'stp':691615,'address':'691615, EGL - ISANDO, ISANDO MAIN ON 1, SIZANANI LIQUOR DISTRIBUTOR, STAND NO 1397, IVORY PARK EXTENSION 2, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-26.01120,'lng':28.17630}},
{'stp':730081,'address':'730081, EGL - ISANDO, ISANDO MAIN ON 1, NAGILA RESTAURANT, 2 FARRADY STREET, #, #, TEMBISA, South Africa','geo':{'lat':-26.05954,'lng':28.17709}},
{'stp':756093,'address':'756093, EGL - ISANDO, ISANDO MAIN ON 1, SARAHS PLACE, IVORY PARK, ERF 2919, EXTENSION 2, TEMBISA, South Africa','geo':{'lat':-26.01480,'lng':28.18640}},
{'stp':769332,'address':'769332, EGL - ISANDO, ISANDO MAIN ON 1, MOTHUKI TRADING AND SOUND HIRE 2, 6206 STAND NO, IVORY PARK MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-25.99773,'lng':28.20525}},
{'stp':769665,'address':'769665, EGL - ISANDO, ISANDO MAIN ON 1, S N TAVERN, 11722 STAND NO, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-26.00766,'lng':28.19168}},
{'stp':774575,'address':'774575, EGL - ISANDO, ISANDO MAIN ON 1, DELIAS PLACE, 1263 STAND NO, THEKA STREET PHOMOLONG TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.04205,'lng':28.17793}},
{'stp':778505,'address':'778505, EGL - ISANDO, ISANDO MAIN ON 1, SIZAS PLACE, STAND NO, ERF 259 MOTSU SECT, #, TEMBISA, South Africa','geo':{'lat':-26.00816,'lng':28.20236}},
{'stp':784307,'address':'784307, EGL - ISANDO, ISANDO MAIN ON 1, REDHORN LIQUOR (NTO), 178 MOCHASIS ST, ENDULWINI SECTION, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-26.01508,'lng':28.22318}},
{'stp':791804,'address':'791804, EGL - ISANDO, ISANDO MAIN ON 1, MAGGIES INN, 1217 STAND NO, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00946,'lng':28.17687}},
{'stp':794491,'address':'794491, EGL - ISANDO, ISANDO MAIN ON 1, MAHLANGUS PLACE, 391 STAND NO, MORITING SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02156,'lng':28.24861}},
{'stp':799754,'address':'799754, EGL - ISANDO, ISANDO MAIN ON 1, MOSHES PLACE, MAGGIE BALOI STREET, 2756 IVORY PARK EXTENSION 5, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.01233,'lng':28.18581}},
{'stp':312075,'address':'312075, EGL - ISANDO, ISANDO MAIN ON 1, MBHOMBHI TAVERN, 381 STAND NO, TEANONG SECTION, 82 KAKONCO STREET, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01106,'lng':28.22641}},
{'stp':381737,'address':'381737, EGL - ISANDO, ISANDO MAIN ON 1, SUTHO TAVERN, 290 STAND NUMBER, TEMONG SECT, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00463,'lng':28.21536}},
{'stp':389008,'address':'389008, EGL - ISANDO, ISANDO MAIN ON 1, KHENSANI S PLACE, 642 STAND NO, ISITHAMA SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.00671,'lng':28.22282}},
{'stp':393228,'address':'393228, EGL - ISANDO, ISANDO MAIN ON 1, PHINDI S TAVERN, 25384 ERF, TLADI STREET, EXTENSION 31, BENONI, South Africa','geo':{'lat':-26.11215,'lng':28.45978}},
{'stp':393230,'address':'393230, EGL - ISANDO, ISANDO MAIN ON 1, DOWNTOWN DUTY FREE (CALL AND COLLEC, 149294 STAND NO, STREET NO 2 SOUTH ARM ROAD, PORT OF CAPE TOWN, BENONI, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':393443,'address':'393443, EGL - ISANDO, ISANDO MAIN ON 1, SA LIQUORS BOKSBURG, 33 ERF, EXTENSION 6, ANDERBOLT, NOT ASSIGNED, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':460265,'address':'460265, EGL - ISANDO, ISANDO MAIN ON 1, MOTSOELI BOTTLE STORE, ERF 2 TEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00331,'lng':28.21119}},
{'stp':471273,'address':'471273, EGL - ISANDO, ISANDO MAIN ON 1, PICK N PAY GG11 BOPHELONG, 6921 STAND NO, 02 OCTOBER AND MARINDA SQUARE, IVORY PARK, TEMBISA, South Africa','geo':{'lat':-26.00010,'lng':28.20628}},
{'stp':550117,'address':'550117, EGL - ISANDO, ISANDO MAIN ON 1, THE VIEW LIQUOR STORE, ERF 1257 SHOP 2, MIDTEBA SHOPPING CENTRE, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99120,'lng':28.24040}},
{'stp':550370,'address':'550370, EGL - ISANDO, ISANDO MAIN ON 1, LANGAS TAVERN, 579 ISTHAME SECTION, TEMBISA, 1628, TEMBISA, South Africa','geo':{'lat':-26.00740,'lng':28.22250}},
{'stp':550559,'address':'550559, EGL - ISANDO, ISANDO MAIN ON 1, MABUZA S, 131 TEANONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00610,'lng':28.20560}},
{'stp':550713,'address':'550713, EGL - ISANDO, ISANDO MAIN ON 1, MARIA\'S PLACE, 143 MOAKENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99780,'lng':28.21970}},
{'stp':551080,'address':'551080, EGL - ISANDO, ISANDO MAIN ON 1, MOHALE LE, 126 MOTHEONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01350,'lng':28.20660}},
{'stp':551213,'address':'551213, EGL - ISANDO, ISANDO MAIN ON 1, PHOSA\'S TAVERN, ERF 1621 LEBEONG EXT 5, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99860,'lng':28.21110}},
{'stp':551239,'address':'551239, EGL - ISANDO, ISANDO MAIN ON 1, MOGASHOA S TAVERN, 337 MOTEONG SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01120,'lng':28.20570}},
{'stp':551330,'address':'551330, EGL - ISANDO, ISANDO MAIN ON 1, YOU WELCOME INN, 341 MOTEONG SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01180,'lng':28.20560}},
{'stp':551707,'address':'551707, EGL - ISANDO, ISANDO MAIN ON 1, SIPHO S TAVERN, ERF 210 ECALENI SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01120,'lng':28.24680}},
{'stp':551916,'address':'551916, EGL - ISANDO, ISANDO MAIN ON 1, RABIE RIDGE LIQUOR STORE, STAND NO 131, RABIE RIDGE EXT 4, HALFWAY HOUSE, TEMBISA, South Africa','geo':{'lat':-26.02651,'lng':28.17875}},
{'stp':552486,'address':'552486, EGL - ISANDO, ISANDO MAIN ON 1, SHOBA WHOLESALE BOTTLE STORE, ERF 788 MQANTSA SECTION, LIGHT INDUSTRIAL, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01490,'lng':28.23830}},
{'stp':552612,'address':'552612, EGL - ISANDO, ISANDO MAIN ON 1, MARIDILI LIQUOR STORE, 409 LEKANENG SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01760,'lng':28.20350}},
{'stp':552962,'address':'552962, EGL - ISANDO, ISANDO MAIN ON 1, TYAMS TAVERN, 2177 TSWELAPELE EXT 5, WINNIE MANDELA, #, IVORY PARK, South Africa','geo':{'lat':-25.97400,'lng':28.21820}},
{'stp':553149,'address':'553149, EGL - ISANDO, ISANDO MAIN ON 1, BAILY S INN, 799 PELICAN AVE, RABIE RIDGE, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.02240,'lng':28.17570}},
{'stp':553300,'address':'553300, EGL - ISANDO, ISANDO MAIN ON 1, NEW ORLEANS JAZZ CLUB, NO 423 MOAKENG EXTENSION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99656,'lng':28.21913}},
{'stp':553767,'address':'553767, EGL - ISANDO, ISANDO MAIN ON 1, PHUKI S PLACE, STAND 289, LEBOENG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99950,'lng':28.21130}},
{'stp':560210,'address':'560210, EGL - ISANDO, ISANDO MAIN ON 1, SIBIYA NM, 500 MOTHEONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01206,'lng':28.20445}},
{'stp':560211,'address':'560211, EGL - ISANDO, ISANDO MAIN ON 1, RAMOTHIBE TI, 242 MOTEONG SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.00930,'lng':28.20770}},
{'stp':560223,'address':'560223, EGL - ISANDO, ISANDO MAIN ON 1, MANAIOA OP, 157 MOTEONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01045,'lng':28.20783}},
{'stp':560299,'address':'560299, EGL - ISANDO, ISANDO MAIN ON 1, NGONYAMA MR, 472 DIFATENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01017,'lng':28.21050}},
{'stp':560373,'address':'560373, EGL - ISANDO, ISANDO MAIN ON 1, KHOZA SD, 1736 SIYABUSA STREET, Ivory Park(Kanana, rabie ridge), #, TEMBISA, South Africa','geo':{'lat':-26.02591,'lng':28.17832}},
{'stp':560455,'address':'560455, EGL - ISANDO, ISANDO MAIN ON 1, LIQUOR CITY TEMBISA, 583 ESANGWENI SECTION, PORTION 2AND 3, #, TEMBISA, South Africa','geo':{'lat':-26.02740,'lng':28.19580}},
{'stp':560457,'address':'560457, EGL - ISANDO, ISANDO MAIN ON 1, KHOZA MM, 567 TEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00150,'lng':28.21750}},
{'stp':561057,'address':'561057, EGL - ISANDO, ISANDO MAIN ON 1, SHAKU LP, 13/5 DIFATENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01550,'lng':28.21320}},
{'stp':561136,'address':'561136, EGL - ISANDO, ISANDO MAIN ON 1, MAGOSA TIM MAKOLA, 630234 TEMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00560,'lng':28.21480}},
{'stp':561207,'address':'561207, EGL - ISANDO, ISANDO MAIN ON 1, RAMASHAPA SL, 80 EMANUEL MABJE STREET, THITENG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02038,'lng':28.20296}},
{'stp':561249,'address':'561249, EGL - ISANDO, ISANDO MAIN ON 1, MASHILO S, 327 MOTEONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01000,'lng':28.20630}},
{'stp':580778,'address':'580778, EGL - ISANDO, ISANDO MAIN ON 1, CHUEU MB, 188 MOTEONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01170,'lng':28.20670}},
{'stp':580813,'address':'580813, EGL - ISANDO, ISANDO MAIN ON 1, MALEBATI SJ, 1461 RABIE RIDGE, EXT 4, #, TEMBISA, South Africa','geo':{'lat':-26.02760,'lng':28.17940}},
{'stp':581068,'address':'581068, EGL - ISANDO, ISANDO MAIN ON 1, CHAPLIN LP, 891 HERON SINGLE, RABIE RIDGE, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.02310,'lng':28.17330}},
{'stp':581081,'address':'581081, EGL - ISANDO, ISANDO MAIN ON 1, THATCH HOUSE, 555 MAOKENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99120,'lng':28.21750}},
{'stp':581162,'address':'581162, EGL - ISANDO, ISANDO MAIN ON 1, RAMOTHIBE MT, 412 LIFATENG SECTION, EXT 06, #, TEMBISA, South Africa','geo':{'lat':-26.01410,'lng':28.21120}},
{'stp':602916,'address':'602916, EGL - ISANDO, ISANDO MAIN ON 1, NDLOVU SB, 686 SITHAMA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00630,'lng':28.22260}},
{'stp':603556,'address':'603556, EGL - ISANDO, ISANDO MAIN ON 1, MATSETELA MP, 461 LEBOENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00120,'lng':28.21370}},
{'stp':603822,'address':'603822, EGL - ISANDO, ISANDO MAIN ON 1, Haritabeng Tavern, 282 LIFATENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00840,'lng':28.21280}},
{'stp':605017,'address':'605017, EGL - ISANDO, ISANDO MAIN ON 1, MABASA LM, 4674 PHOMOLONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.04180,'lng':28.18760}},
{'stp':605317,'address':'605317, EGL - ISANDO, ISANDO MAIN ON 1, MAKWELA MM, 333 MOTEONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02663,'lng':28.19546}},
{'stp':605449,'address':'605449, EGL - ISANDO, ISANDO MAIN ON 1, GROOVE INN, GROOVE INN, 603 MAOKENG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99171,'lng':28.21798}},
{'stp':605968,'address':'605968, EGL - ISANDO, ISANDO MAIN ON 1, UNCLE JOES, UNCLE JOE\'S, 89 LEBOENG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.00210,'lng':28.21090}},
{'stp':606435,'address':'606435, EGL - ISANDO, ISANDO MAIN ON 1, MANEWA DM, 20 ZONE,2 WINNIE MANDELA, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.98920,'lng':28.22820}},
{'stp':606436,'address':'606436, EGL - ISANDO, ISANDO MAIN ON 1, MANEWA MD, 6311 ZONE 05 WINNIE MANDELA, EXT 23 TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.98330,'lng':28.22940}},
{'stp':607526,'address':'607526, EGL - ISANDO, ISANDO MAIN ON 1, BACK YARD SPORTS BAR, 1043 MAOKENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99250,'lng':28.22190}},
{'stp':625014,'address':'625014, EGL - ISANDO, ISANDO MAIN ON 1, STEVES PLACE, 419 TEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00130,'lng':28.21520}},
{'stp':626299,'address':'626299, EGL - ISANDO, ISANDO MAIN ON 1, MASHIANES PLACE, STAND NO, ERF 521 REVEREND RTJ NAMANE DRIVE, 07 TEMONG SECT, TEMBISA, South Africa','geo':{'lat':-26.00309,'lng':28.21149}},
{'stp':627330,'address':'627330, EGL - ISANDO, ISANDO MAIN ON 1, CHARLES PLACE, 169 KIDARE STREET, TEMONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.00660,'lng':28.21500}},
{'stp':627359,'address':'627359, EGL - ISANDO, ISANDO MAIN ON 1, MAGOGO, 14443 (SPARROW ST), IVORY PARK EXT 13    THOLANE SECT, #, IVORY PARK, South Africa','geo':{'lat':-25.97740,'lng':28.20520}},
{'stp':628420,'address':'628420, EGL - ISANDO, ISANDO MAIN ON 1, TINYS PLACE, 139 STAND NO, DIFATENG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01030,'lng':28.21230}},
{'stp':629344,'address':'629344, EGL - ISANDO, ISANDO MAIN ON 1, TSHIDIS INN, 220 LIFATENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01400,'lng':28.21240}},
{'stp':629475,'address':'629475, EGL - ISANDO, ISANDO MAIN ON 1, MOHALE, 1896 RABIE RIDGE, EXTENSION 4, #, TEMBISA, South Africa','geo':{'lat':-26.02439,'lng':28.17901}},
{'stp':629925,'address':'629925, EGL - ISANDO, ISANDO MAIN ON 1, FANIS PLACE, 161 LUBA STREET, TEMONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00440,'lng':28.21230}},
{'stp':630092,'address':'630092, EGL - ISANDO, ISANDO MAIN ON 1, KGABUS PLACE, 630/34 TEMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00250,'lng':28.21890}},
{'stp':631555,'address':'631555, EGL - ISANDO, ISANDO MAIN ON 1, SELLOS PLACE, 617 UMTHAMBEKA, TEMBISA, KEMPTONPARK, TEMBISA, South Africa','geo':{'lat':-26.02380,'lng':28.18960}},
{'stp':632009,'address':'632009, EGL - ISANDO, ISANDO MAIN ON 1, 14 CLIPPER, 474 TEMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00086,'lng':28.21539}},
{'stp':632488,'address':'632488, EGL - ISANDO, ISANDO MAIN ON 1, SIKOSHULE\'S PLACE, 208 MAOKENG SECTION, TEMBISA, KEMPTONPARK, TEMBISA, South Africa','geo':{'lat':-25.99870,'lng':28.22200}},
{'stp':633995,'address':'633995, EGL - ISANDO, ISANDO MAIN ON 1, PHINDI\'S TUCKSHOP, 185 ILANDA STREET, MOTEONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01140,'lng':28.20722}},
{'stp':633996,'address':'633996, EGL - ISANDO, ISANDO MAIN ON 1, MOGOLE TAVERN, 590 TEMONG SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.00110,'lng':28.21720}},
{'stp':634339,'address':'634339, EGL - ISANDO, ISANDO MAIN ON 1, NKWANA BUTCHERY, 2192 RABIE RIDGE, EXTENSION 4, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.02450,'lng':28.18110}},
{'stp':637434,'address':'637434, EGL - ISANDO, ISANDO MAIN ON 1, MAPOLANKAS PLACE, 715 ISITHAMA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00530,'lng':28.22220}},
{'stp':637448,'address':'637448, EGL - ISANDO, ISANDO MAIN ON 1, GO OMUNATI, 16 TEMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00231,'lng':28.21232}},
{'stp':637662,'address':'637662, EGL - ISANDO, ISANDO MAIN ON 1, NYATHI TUCKSHOP, 1799 KANANA SECTION, RABIE RIDGE, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02430,'lng':28.17730}},
{'stp':637755,'address':'637755, EGL - ISANDO, ISANDO MAIN ON 1, MME ROSES PLACE, 190 LUNGA STREET, TEMONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00450,'lng':28.21300}},
{'stp':637766,'address':'637766, EGL - ISANDO, ISANDO MAIN ON 1, MARTINA\'S TAVERN, 10088 WINNIE MANDELA, WINNIE MANDELA ZONE 3, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.98770,'lng':28.22590}},
{'stp':638041,'address':'638041, EGL - ISANDO, ISANDO MAIN ON 1, TLHAKOS TAVERN, 661 TSHENOLONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99550,'lng':28.21710}},
{'stp':639439,'address':'639439, EGL - ISANDO, ISANDO MAIN ON 1, KOENAS PLACE, 772 MAOKENG EXT, THEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99140,'lng':28.22150}},
{'stp':639473,'address':'639473, EGL - ISANDO, ISANDO MAIN ON 1, MATHOPAS TAVERN, 2061 KANANA SECTION, RABIE RIDGE, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02600,'lng':28.18200}},
{'stp':639544,'address':'639544, EGL - ISANDO, ISANDO MAIN ON 1, ALIS PLACE, 226 TSHENOLONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99954,'lng':28.22115}},
{'stp':639561,'address':'639561, EGL - ISANDO, ISANDO MAIN ON 1, LYDIAS TAVERN, 111 TSHENOLONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00093,'lng':28.22118}},
{'stp':639674,'address':'639674, EGL - ISANDO, ISANDO MAIN ON 1, MIKIE S PUB, 246 TEMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00400,'lng':28.21330}},
{'stp':640024,'address':'640024, EGL - ISANDO, ISANDO MAIN ON 1, MALOBA\'S INN, 1615 LEBOENG EXT 5, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00250,'lng':28.21227}},
{'stp':640445,'address':'640445, EGL - ISANDO, ISANDO MAIN ON 1, PULENG\'S PLACE, 241 LEBOENG SECTIION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00181,'lng':28.21135}},
{'stp':640449,'address':'640449, EGL - ISANDO, ISANDO MAIN ON 1, LETHABO ENTERPRISES, 88 MOTEONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01000,'lng':28.20870}},
{'stp':641028,'address':'641028, EGL - ISANDO, ISANDO MAIN ON 1, JABUS PLACE, 9/20 LIFATENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01040,'lng':28.20940}},
{'stp':641297,'address':'641297, EGL - ISANDO, ISANDO MAIN ON 1, LEDWABAS PLACE, 294 MAOKENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99620,'lng':28.22220}},
{'stp':642239,'address':'642239, EGL - ISANDO, ISANDO MAIN ON 1, DNE TUCKSHOP, 2755 KAALFONTEIN  num 2755, EXT 5, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98430,'lng':28.17580}},
{'stp':646330,'address':'646330, EGL - ISANDO, ISANDO MAIN ON 1, ROADSIDE FOOD AND DRINKS, 1151 MAOKENG SECTION, EXTENSION 1, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-25.99290,'lng':28.22180}},
{'stp':646433,'address':'646433, EGL - ISANDO, ISANDO MAIN ON 1, AMO LIQUOR RESTAURANT, 2325 STAND NO, RABIE RIDGE EXT 4, RABIE RIDGE, TEMBISA, South Africa','geo':{'lat':-26.03070,'lng':28.18590}},
{'stp':646663,'address':'646663, EGL - ISANDO, ISANDO MAIN ON 1, MOIPONE S PLACE, LEBOENG SECTION 370, #, #, TEMBISA, South Africa','geo':{'lat':-25.99790,'lng':28.21310}},
{'stp':646676,'address':'646676, EGL - ISANDO, ISANDO MAIN ON 1, MACHABE\'S PLACE, 1141 MAOKENG SECTION  num 1141, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99430,'lng':28.22060}},
{'stp':646791,'address':'646791, EGL - ISANDO, ISANDO MAIN ON 1, NEW JOINT, 344 TSHENOLONG  SECTION  num 344, TEMBISA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99770,'lng':28.21930}},
{'stp':646921,'address':'646921, EGL - ISANDO, ISANDO MAIN ON 1, PAUL\'S QUENCHING PLACE, 630 ESIPHETHWENI SECTION  num 630, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02830,'lng':28.21850}},
{'stp':646941,'address':'646941, EGL - ISANDO, ISANDO MAIN ON 1, FANIWELA SOFT DRINK, 308 308 MOTEONG SECTION  num 308, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01020,'lng':28.20670}},
{'stp':646950,'address':'646950, EGL - ISANDO, ISANDO MAIN ON 1, MAKITLA\'S TAVERN, 427 LEBOENG SECTION   num 427, TEMBISA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99930,'lng':28.21610}},
{'stp':647045,'address':'647045, EGL - ISANDO, ISANDO MAIN ON 1, TWALA\'S PLACE, 479 ESIPHETHWENI SECTION  num 479, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03230,'lng':28.21740}},
{'stp':647306,'address':'647306, EGL - ISANDO, ISANDO MAIN ON 1, RORISONG\'S PLACE, 486 MOTEONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01031,'lng':28.20479}},
{'stp':648463,'address':'648463, EGL - ISANDO, ISANDO MAIN ON 1, ZWAKHALA INN, 237 DIFATENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01206,'lng':28.21125}},
{'stp':649234,'address':'649234, EGL - ISANDO, ISANDO MAIN ON 1, EKUTHULENI TAVERN, 390 TEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00483,'lng':28.22178}},
{'stp':650753,'address':'650753, EGL - ISANDO, ISANDO MAIN ON 1, MAHLATSES PLACE, 462 TSENELONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99892,'lng':28.21583}},
{'stp':664125,'address':'664125, EGL - ISANDO, ISANDO MAIN ON 1, PULA MOGAPI, 2562 STAND NO, RABIE RIDGE EXTION 5, #, TEMBISA, South Africa','geo':{'lat':-26.03277,'lng':28.18569}},
{'stp':664928,'address':'664928, EGL - ISANDO, ISANDO MAIN ON 1, ZACKS TUCKSHOP, 651 ESIPHETHWENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02930,'lng':28.21970}},
{'stp':666047,'address':'666047, EGL - ISANDO, ISANDO MAIN ON 1, NARES CORNER, 473/398 DIFATENG SECTION, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00950,'lng':28.21040}},
{'stp':672207,'address':'672207, EGL - ISANDO, ISANDO MAIN ON 1, LETTIES TAVERN, 502 TSENOLONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99700,'lng':28.21590}},
{'stp':683929,'address':'683929, EGL - ISANDO, ISANDO MAIN ON 1, MAFAS INN, 4449 MADUNA STREET, #, #, TEMBISA, South Africa','geo':{'lat':-26.04460,'lng':28.18530}},
{'stp':696620,'address':'696620, EGL - ISANDO, ISANDO MAIN ON 1, STEP BY STEP, 506 DIFATENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01432,'lng':28.21115}},
{'stp':701040,'address':'701040, EGL - ISANDO, ISANDO MAIN ON 1, 1936 ENDAWENI AND LIQUOR DISTRIBUTO, 1481 PARLIAMENT STREET, RABBIE RIDGE EXTENSION 4, IVORY PARK MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.02660,'lng':28.17990}},
{'stp':706772,'address':'706772, EGL - ISANDO, ISANDO MAIN ON 1, P N LIQUOR MARKET, 381 JOHNNY MAKHATHINI STREET, UMTHAMBEKA SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02660,'lng':28.18900}},
{'stp':710831,'address':'710831, EGL - ISANDO, ISANDO MAIN ON 1, MUTHUKIS TAVERN, 2604 EXTENSION 5, RABIE RIDGE, #, TEMBISA, South Africa','geo':{'lat':-26.03280,'lng':28.18560}},
{'stp':745920,'address':'745920, EGL - ISANDO, ISANDO MAIN ON 1, LAS VEGAS BOTTLE STORE, ERF 4165 CAMEROEN STREET, TSWELOPELE EXT 6, #, IVORY PARK, South Africa','geo':{'lat':-25.96724,'lng':28.21131}},
{'stp':752142,'address':'752142, EGL - ISANDO, ISANDO MAIN ON 1, B B M PALACE, STAND NO 350, TEMONG SECT TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.22424,'lng':28.21365}},
{'stp':784049,'address':'784049, EGL - ISANDO, ISANDO MAIN ON 1, OVER-SPORO TAVERN (NTO), 38 DIFATENG SECTION, TEMBISA 1632, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-26.02316,'lng':28.21666}},
{'stp':786574,'address':'786574, EGL - ISANDO, ISANDO MAIN ON 1, PETERS PLACE, KGANO STREET, ERF 473/248 DIFATENG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.00816,'lng':28.20919}},
{'stp':791738,'address':'791738, EGL - ISANDO, ISANDO MAIN ON 1, LEBINA OFF SALES, STAND NO, 1139 LIFATENG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01234,'lng':28.21415}},
{'stp':794502,'address':'794502, EGL - ISANDO, ISANDO MAIN ON 1, CEDRICKS PLACE, 462 TEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00202,'lng':28.21652}},
{'stp':303001,'address':'303001, EGL - ISANDO, ISANDO MAIN ON 1, WIJO\'S CORNER, 140/189 STAND NO, NYONI CIRCLE, ECALENI SECTION, TEMBISA, South Africa','geo':{'lat':-26.00940,'lng':28.24470}},
{'stp':311399,'address':'311399, EGL - ISANDO, ISANDO MAIN ON 1, AT 365 CAFE, 365 STAND NUMBER, MKHWANAZI STR, EMANGWENI SECTION, TEMBISA, South Africa','geo':{'lat':-26.01818,'lng':28.23755}},
{'stp':392738,'address':'392738, EGL - ISANDO, ISANDO MAIN ON 1, NTOMBIS PLACE, 619 EMMANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01980,'lng':28.23220}},
{'stp':470926,'address':'470926, EGL - ISANDO, ISANDO MAIN ON 1, V H LIQUOR STORE, 125 STAND NO, EMFIHLWENI SECTION, ERF 000125 TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02642,'lng':28.21982}},
{'stp':484562,'address':'484562, EGL - ISANDO, ISANDO MAIN ON 1, REGGIES LIQUOR STORE, STAND NO, ERF 1195 HOSPITAL VIEW, EXTENSION 4, TEMBISA, South Africa','geo':{'lat':-25.99154,'lng':28.23879}},
{'stp':486703,'address':'486703, EGL - ISANDO, ISANDO MAIN ON 1, MEMBERS TAVERN, 2467/02 STAND NUMBER, ESSELEN PARK, EXT 3, TEMBISA, South Africa','geo':{'lat':-26.03548,'lng':28.22165}},
{'stp':491233,'address':'491233, EGL - ISANDO, ISANDO MAIN ON 1, WELASE BROTHERS WHOLESALE BOTTLE ST, 788 STAND NUMBER, MQANTA SECT TEMBISA, MQANTA SECT TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01500,'lng':28.23845}},
{'stp':550156,'address':'550156, EGL - ISANDO, ISANDO MAIN ON 1, MAKGAE MC, 248 HOSPITAL VIEW, #, #, TEMBISA, South Africa','geo':{'lat':-25.98620,'lng':28.24270}},
{'stp':550200,'address':'550200, EGL - ISANDO, ISANDO MAIN ON 1, VHADAU CONNER TAVERN, 906/41 ISITHAME SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00810,'lng':28.22400}},
{'stp':550321,'address':'550321, EGL - ISANDO, ISANDO MAIN ON 1, MGABABA TAVERN, ERF 670 ECALENI SECTION, TEMBISA, 1628, TEMBISA, South Africa','geo':{'lat':-26.01277,'lng':28.24652}},
{'stp':550538,'address':'550538, EGL - ISANDO, ISANDO MAIN ON 1, GOOD LUCK AND SONS LIQUOR STORE, STAND 354 EMOYENI SECTION, TEMBISA, 1628, TEMBISA, South Africa','geo':{'lat':-26.01270,'lng':28.23290}},
{'stp':551238,'address':'551238, EGL - ISANDO, ISANDO MAIN ON 1, KGORONG WATERING HOLE, 57 ALUTA CRESECENT, HOSPITAL VIEW, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99344,'lng':28.24512}},
{'stp':552429,'address':'552429, EGL - ISANDO, ISANDO MAIN ON 1, MAVUSANAS TAVERN, 225 EMMANGWENI SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01830,'lng':28.24020}},
{'stp':552625,'address':'552625, EGL - ISANDO, ISANDO MAIN ON 1, ITUMELENG TAVERN, 299 SEDIBENG SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.00560,'lng':28.23820}},
{'stp':553094,'address':'553094, EGL - ISANDO, ISANDO MAIN ON 1, MATHOTHES PLACE, 13515 IVORY PARK, #, #, IVORY PARK, South Africa','geo':{'lat':-25.98480,'lng':28.20270}},
{'stp':553193,'address':'553193, EGL - ISANDO, ISANDO MAIN ON 1, JOS TAVERN, 380 MQANTSA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01100,'lng':28.23600}},
{'stp':553339,'address':'553339, EGL - ISANDO, ISANDO MAIN ON 1, VIEW POINT TAVERN, 214 ERF NO, HOSPITAL VIEW, #, TEMBISA, South Africa','geo':{'lat':-25.98670,'lng':28.24280}},
{'stp':553467,'address':'553467, EGL - ISANDO, ISANDO MAIN ON 1, KAIZERS TAVERN, 279 KOPANONG SECTION, TEMBISA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00320,'lng':28.22950}},
{'stp':553729,'address':'553729, EGL - ISANDO, ISANDO MAIN ON 1, GETTIES TAVERN, 490 ECALENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01170,'lng':28.24420}},
{'stp':553884,'address':'553884, EGL - ISANDO, ISANDO MAIN ON 1, MALAWI INN TAVERN, STAND 615, MORITING SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99770,'lng':28.24220}},
{'stp':560416,'address':'560416, EGL - ISANDO, ISANDO MAIN ON 1, CHAUKE M, 8069 IVORY PARK EXT 8, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.99800,'lng':28.19880}},
{'stp':560431,'address':'560431, EGL - ISANDO, ISANDO MAIN ON 1, MABENA MA, 55 MQANTSA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00930,'lng':28.23570}},
{'stp':561056,'address':'561056, EGL - ISANDO, ISANDO MAIN ON 1, YVONNE SIZANE CHAUKE, 873/42 MQANTSA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00940,'lng':28.23320}},
{'stp':561085,'address':'561085, EGL - ISANDO, ISANDO MAIN ON 1, VBK GENERAL, ERF 536 EMFIHLWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02668,'lng':28.22186}},
{'stp':561096,'address':'561096, EGL - ISANDO, ISANDO MAIN ON 1, ZOLILE CAROLINE GUMA, 976 HOSPITAL VIEW, EXTENSION 4, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99510,'lng':28.23910}},
{'stp':561186,'address':'561186, EGL - ISANDO, ISANDO MAIN ON 1, SESSI MDHLULI DISTRIBUTORS, 458 MORITING SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99610,'lng':28.23950}},
{'stp':561189,'address':'561189, EGL - ISANDO, ISANDO MAIN ON 1, NDIMANDE KS, 154 EMANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01720,'lng':28.23510}},
{'stp':561208,'address':'561208, EGL - ISANDO, ISANDO MAIN ON 1, YAKA TK, 710 EMANGWENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01860,'lng':28.23290}},
{'stp':561264,'address':'561264, EGL - ISANDO, ISANDO MAIN ON 1, REX S PLACE, HARRY GWALA STREET, HOSPITAL VIEW, ERF 1372, TEMBISA, South Africa','geo':{'lat':-25.98760,'lng':28.23780}},
{'stp':580722,'address':'580722, EGL - ISANDO, ISANDO MAIN ON 1, MTILI NM, 94 EMOYENI SECTION, TEMBISA (Nomathembas Place), #, TEMBISA, South Africa','geo':{'lat':-26.01280,'lng':28.23040}},
{'stp':580818,'address':'580818, EGL - ISANDO, ISANDO MAIN ON 1, HIPPOS PLACE, 367 KOPANONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00120,'lng':28.22980}},
{'stp':581158,'address':'581158, EGL - ISANDO, ISANDO MAIN ON 1, MATHEKGE MM, 5857/64 LILIBA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02040,'lng':28.23120}},
{'stp':603224,'address':'603224, EGL - ISANDO, ISANDO MAIN ON 1, MAZIBUKO T, 3742 NARI STREET, OAKMOOR EXT 7, #, TEMBISA, South Africa','geo':{'lat':-26.01200,'lng':28.24840}},
{'stp':603990,'address':'603990, EGL - ISANDO, ISANDO MAIN ON 1, MSIYA MJ, 66 ECALENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00940,'lng':28.24320}},
{'stp':604416,'address':'604416, EGL - ISANDO, ISANDO MAIN ON 1, SIPHOS CABIN, 274 EMANGWENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01738,'lng':28.23848}},
{'stp':604808,'address':'604808, EGL - ISANDO, ISANDO MAIN ON 1, SKOSANA RE, 206 ECALENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01043,'lng':28.24677}},
{'stp':605146,'address':'605146, EGL - ISANDO, ISANDO MAIN ON 1, SELETISHA LJ, 5398 HOSPITAL VIEW, EXT 12, #, TEMBISA, South Africa','geo':{'lat':-25.98881,'lng':28.24503}},
{'stp':605318,'address':'605318, EGL - ISANDO, ISANDO MAIN ON 1, MALAZA MM, 295 EMFIHLWENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01617,'lng':28.21950}},
{'stp':609695,'address':'609695, EGL - ISANDO, ISANDO MAIN ON 1, NTSHINGO INN, 6 MQANSA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00874,'lng':28.23488}},
{'stp':627323,'address':'627323, EGL - ISANDO, ISANDO MAIN ON 1, LINDIWES PLACE, 1313 ESSELEN PARK, #, #, TEMBISA, South Africa','geo':{'lat':-26.02520,'lng':28.23740}},
{'stp':627332,'address':'627332, EGL - ISANDO, ISANDO MAIN ON 1, KHOZA S PLACE, 7250 IVORY PARK, EXT 8, #, TEMBISA, South Africa','geo':{'lat':-26.00234,'lng':28.20212}},
{'stp':627344,'address':'627344, EGL - ISANDO, ISANDO MAIN ON 1, NOMUSAS PLACE, 3803 IVORY PARK, EXT 6, #, TEMBISA, South Africa','geo':{'lat':-25.98646,'lng':28.20357}},
{'stp':627994,'address':'627994, EGL - ISANDO, ISANDO MAIN ON 1, KELEBOGILE TAVERN, 6483 DLAMINI STREET, EXTENTION 8, IVORY PARK, TEMBISA, South Africa','geo':{'lat':-25.98097,'lng':28.20492}},
{'stp':629251,'address':'629251, EGL - ISANDO, ISANDO MAIN ON 1, T T TAVERN, 339 XUBENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00700,'lng':28.24510}},
{'stp':629354,'address':'629354, EGL - ISANDO, ISANDO MAIN ON 1, FAKUDES PLACE, 147 BAANS STREET, EMMANGWENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01700,'lng':28.23620}},
{'stp':629538,'address':'629538, EGL - ISANDO, ISANDO MAIN ON 1, CELYS PLACE, 833 EXTENSION01, HOSPITAL VIEW, #, TEMBISA, South Africa','geo':{'lat':-25.99357,'lng':28.24497}},
{'stp':629691,'address':'629691, EGL - ISANDO, ISANDO MAIN ON 1, THE VIEW GUEST HOUSE, STAND 17, HOSPITAL VIEW, PHASE 1, TEMBISA, South Africa','geo':{'lat':-25.98676,'lng':28.23810}},
{'stp':629743,'address':'629743, EGL - ISANDO, ISANDO MAIN ON 1, KOMA  YA BANNA, 1178 ESSELEN PARK, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02580,'lng':28.23720}},
{'stp':630066,'address':'630066, EGL - ISANDO, ISANDO MAIN ON 1, MASHAMPLANI, 390 MQANTS SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01210,'lng':28.23810}},
{'stp':631251,'address':'631251, EGL - ISANDO, ISANDO MAIN ON 1, MTHEMBUS PLACE, 697 EMFIHLWENI SEC, TEMBISA, JOHANNESBURG, TEMBISA, South Africa','geo':{'lat':-26.02940,'lng':28.22250}},
{'stp':633105,'address':'633105, EGL - ISANDO, ISANDO MAIN ON 1, EMAZEKENI TAVERN, 1001 ESSELEN PARK ,NINETENTH STREET, EXTENSION 2, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.02390,'lng':28.23770}},
{'stp':633278,'address':'633278, EGL - ISANDO, ISANDO MAIN ON 1, MFANAKANJANI PLACE, 322 EMOYENI SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01600,'lng':28.23290}},
{'stp':635050,'address':'635050, EGL - ISANDO, ISANDO MAIN ON 1, NENES PLACE, 1470 ESSELEN PARK, EXTENSION 2, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02570,'lng':28.23910}},
{'stp':635182,'address':'635182, EGL - ISANDO, ISANDO MAIN ON 1, MABUZA\'S PLACE, 9694 ROLIHLAHLA SECTION, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.99270,'lng':28.21010}},
{'stp':635203,'address':'635203, EGL - ISANDO, ISANDO MAIN ON 1, NTOMBI\'S PLACE, 199 FOURTH STREET, ESSELEN PARK, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02160,'lng':28.23980}},
{'stp':635781,'address':'635781, EGL - ISANDO, ISANDO MAIN ON 1, MADISHA\'S TAVERN, 51 ESSELEN PARK, THEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02210,'lng':28.24040}},
{'stp':635823,'address':'635823, EGL - ISANDO, ISANDO MAIN ON 1, MAPULES PLACE, 407 ESSELEN PARK, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02370,'lng':28.24270}},
{'stp':636841,'address':'636841, EGL - ISANDO, ISANDO MAIN ON 1, PHILL\'S TAVERN, 1348 ESSELEN  PARK, PHASE 2, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.02510,'lng':28.24050}},
{'stp':637097,'address':'637097, EGL - ISANDO, ISANDO MAIN ON 1, BOZZAS TAVERN, 165 IGQAGQA SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01110,'lng':28.22810}},
{'stp':637131,'address':'637131, EGL - ISANDO, ISANDO MAIN ON 1, CHINA TAVERN, 233 ECALENI SECTION, THE HIGH STREET, #, TEMBISA, South Africa','geo':{'lat':-26.01279,'lng':28.24661}},
{'stp':637135,'address':'637135, EGL - ISANDO, ISANDO MAIN ON 1, MIRRIAM\'S JAZZ CLUB, 584 EMQANTSA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01110,'lng':28.23980}},
{'stp':637200,'address':'637200, EGL - ISANDO, ISANDO MAIN ON 1, LOLOS PLACE, 108 ECALENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01450,'lng':28.24300}},
{'stp':637352,'address':'637352, EGL - ISANDO, ISANDO MAIN ON 1, PHINDIS PLACE, 425 MANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01980,'lng':28.23590}},
{'stp':637876,'address':'637876, EGL - ISANDO, ISANDO MAIN ON 1, MXOLISI\'S PLACE, 961 IVORY PARK, EXTENSION 2, IVORY PARK, IVORY PARK, South Africa','geo':{'lat':-25.99650,'lng':28.19400}},
{'stp':638842,'address':'638842, EGL - ISANDO, ISANDO MAIN ON 1, KHOZAS PLACE, 3401 IVORY PARK, EXT 2, #, TEMBISA, South Africa','geo':{'lat':-26.00176,'lng':28.17864}},
{'stp':639135,'address':'639135, EGL - ISANDO, ISANDO MAIN ON 1, VUKUZENZELE TAVERN, 157 ECALENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01180,'lng':28.24250}},
{'stp':639154,'address':'639154, EGL - ISANDO, ISANDO MAIN ON 1, SHABALALA BUY AND BRAAI, 54 EMOYENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01630,'lng':28.23300}},
{'stp':640020,'address':'640020, EGL - ISANDO, ISANDO MAIN ON 1, VUSIS TAVERN, 545 EMANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02040,'lng':28.23605}},
{'stp':640441,'address':'640441, EGL - ISANDO, ISANDO MAIN ON 1, ZANELES PLACE, 435 ECALENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01450,'lng':28.24420}},
{'stp':640972,'address':'640972, EGL - ISANDO, ISANDO MAIN ON 1, MOLUPE S PLACE, 933 HOSPITAL VIEW, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99460,'lng':28.23780}},
{'stp':641253,'address':'641253, EGL - ISANDO, ISANDO MAIN ON 1, STELLAS PLACE, 564 HOSPITAL VIEW, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99029,'lng':28.24308}},
{'stp':642101,'address':'642101, EGL - ISANDO, ISANDO MAIN ON 1, MAPHINDI\'S PLACE, 186 MHLAMBI 186, EMFIHLWENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02080,'lng':28.22590}},
{'stp':642549,'address':'642549, EGL - ISANDO, ISANDO MAIN ON 1, THEMBA KHABOS PLACE, 1397 HOSPITAL VIEW num 1397, EXT 4, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.98631,'lng':28.23711}},
{'stp':642724,'address':'642724, EGL - ISANDO, ISANDO MAIN ON 1, MAGOGO\'S PLACE, 1456 ESSELEN PARK 1456, EXT 2, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02590,'lng':28.24080}},
{'stp':646305,'address':'646305, EGL - ISANDO, ISANDO MAIN ON 1, SWINKIES TAVERN, 256 MORITING SECTION num 256, MORITING SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99890,'lng':28.23980}},
{'stp':646309,'address':'646309, EGL - ISANDO, ISANDO MAIN ON 1, GIBA\'S PLACE, 411 EMOYENI SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01340,'lng':28.23340}},
{'stp':646515,'address':'646515, EGL - ISANDO, ISANDO MAIN ON 1, BUSISIWE\'S PLACE, EMANGWENI SECTION  615, EMANGWENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01990,'lng':28.23170}},
{'stp':646580,'address':'646580, EGL - ISANDO, ISANDO MAIN ON 1, DLAMINIS TAVERN, 198 EMANGWENI SECTION, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01730,'lng':28.23700}},
{'stp':647385,'address':'647385, EGL - ISANDO, ISANDO MAIN ON 1, PHATHA PHATHA\'S PLACE, 220 XUBENI SECTION 220, #, #, TEMBISA, South Africa','geo':{'lat':-26.00750,'lng':28.24360}},
{'stp':647899,'address':'647899, EGL - ISANDO, ISANDO MAIN ON 1, SIMPHIWE INN, 367 EMFIHLWENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02444,'lng':28.22283}},
{'stp':648434,'address':'648434, EGL - ISANDO, ISANDO MAIN ON 1, MARY\'S INN, 358 MOLEFE STREET, EMFIHLWENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02370,'lng':28.22360}},
{'stp':665796,'address':'665796, EGL - ISANDO, ISANDO MAIN ON 1, NANAS TAVERN, 188 EMFIHLWENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02100,'lng':28.22560}},
{'stp':673329,'address':'673329, EGL - ISANDO, ISANDO MAIN ON 1, ROBERTS INN, 631 ISITHAMA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00610,'lng':28.22140}},
{'stp':690297,'address':'690297, EGL - ISANDO, ISANDO MAIN ON 1, SIBEKO PEOPLES VILLAGE, 281 EMANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01730,'lng':28.23680}},
{'stp':697399,'address':'697399, EGL - ISANDO, ISANDO MAIN ON 1, Nyide\'s tavern (NTO), 5004 MASHALA STR EXT 2, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00372,'lng':28.18320}},
{'stp':711927,'address':'711927, EGL - ISANDO, ISANDO MAIN ON 1, SIHLANGU TAVERN, 376 MQANTSA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01390,'lng':28.23790}},
{'stp':718664,'address':'718664, EGL - ISANDO, ISANDO MAIN ON 1, KILOS DISTRIBUTION, 1019 ESSELEN PARK, EXTENSION 2 TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02490,'lng':28.23640}},
{'stp':724312,'address':'724312, EGL - ISANDO, ISANDO MAIN ON 1, JOYCES PLACE, 2027 KANANA EXTENION 4, RABIE RIDGE, #, TEMBISA, South Africa','geo':{'lat':-26.02460,'lng':28.18120}},
{'stp':734907,'address':'734907, EGL - ISANDO, ISANDO MAIN ON 1, HLOPHE LIQUOR DISTRIBUTORS, 462 EMANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01870,'lng':28.23770}},
{'stp':755195,'address':'755195, EGL - ISANDO, ISANDO MAIN ON 1, NOAH ARK  TAVERN, ESSELEN PARK, ERF 878, EXT 2, TEMBISA, South Africa','geo':{'lat':-26.02720,'lng':28.23410}},
{'stp':759153,'address':'759153, EGL - ISANDO, ISANDO MAIN ON 1, MAGOGO S PLACE, 212 STAND NO, 4TH STREET ESSELEN PARK EXT 1, #, TEMBISA, South Africa','geo':{'lat':-26.02100,'lng':28.24070}},
{'stp':776024,'address':'776024, EGL - ISANDO, ISANDO MAIN ON 1, DA SQUARE TAVERN, 562 STAND NO, MONJANI STR EMANGWENI SECT TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02092,'lng':28.23520}},
{'stp':784299,'address':'784299, EGL - ISANDO, ISANDO MAIN ON 1, LUKHWE LIQUOR STORE, KANGAROO STREET, ERF 751 MQANTSA SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01202,'lng':28.24208}},
{'stp':795015,'address':'795015, EGL - ISANDO, ISANDO MAIN ON 1, TEMBISA BEACH PARTY, STAND NO, RTJ NAMANE DRIVE, MEHLARENG STADIUM, TEMBISA, South Africa','geo':{'lat':-26.00631,'lng':28.21000}},
{'stp':311238,'address':'311238, EGL - ISANDO, ISANDO MAIN ON 1, LEBO\'S PLACE, 1723 STAND NO, MAYIBUYE EXT 34, #, TEMBISA, South Africa','geo':{'lat':-26.03130,'lng':28.16607}},
{'stp':315563,'address':'315563, EGL - ISANDO, ISANDO MAIN ON 1, MAHLANGU\'S PLACE, 1788 STAND NO, RIVONIA, CHLOORKOP X51, KEMPTON PARK, South Africa','geo':{'lat':-26.04219,'lng':28.18023}},
{'stp':388624,'address':'388624, EGL - ISANDO, ISANDO MAIN ON 1, JIMMY S TAVERN, 1953 STAND NO, #, #, TEMBISA, South Africa','geo':{'lat':-26.04564,'lng':28.17579}},
{'stp':390540,'address':'390540, EGL - ISANDO, ISANDO MAIN ON 1, AMIGO LIQUOR STORE, 1 STAND NO, WATT STREET, #, TEMBISA, South Africa','geo':{'lat':-26.05670,'lng':28.17701}},
{'stp':392140,'address':'392140, EGL - ISANDO, ISANDO MAIN ON 1, GOODLUCK BUY AND BRAAI, 1609 STAND NO, CHLOORKOP EXT 51, PHOMOLONG, TEMBISA, South Africa','geo':{'lat':-26.04086,'lng':28.17984}},
{'stp':463210,'address':'463210, EGL - ISANDO, ISANDO MAIN ON 1, SITHOLE LIQUOR STORE, 3356 STAND NO, SONGO STREET, PHOMOLONG SECTION, TEMBISA, South Africa','geo':{'lat':-26.03858,'lng':28.18165}},
{'stp':471880,'address':'471880, EGL - ISANDO, ISANDO MAIN ON 1, LAPENG PLACE, 2233 STAND NO, SERETSE KHAMA STREET, KLIPFONTEIN VIEW, TEMBISA, South Africa','geo':{'lat':-26.04736,'lng':28.15566}},
{'stp':481210,'address':'481210, EGL - ISANDO, ISANDO MAIN ON 1, EUROPA TAVERN, 863 STAND NO, MAYIBUYE, COMMERCIA EXT 34, TEMBISA, South Africa','geo':{'lat':-26.03159,'lng':28.15978}},
{'stp':482365,'address':'482365, EGL - ISANDO, ISANDO MAIN ON 1, BEATRICE S PLACE, 02655 STAND NO, ITIRELENG STREET, CHLOORKOP, TEMBISA, South Africa','geo':{'lat':-26.05275,'lng':28.17901}},
{'stp':486219,'address':'486219, EGL - ISANDO, ISANDO MAIN ON 1, BEAU MONDES PLACE, 2340 STAND NO, COMMERCIA EXTIONSION 34, TOWNSHIP, TEMBISA, South Africa','geo':{'lat':-26.02944,'lng':28.18890}},
{'stp':488307,'address':'488307, EGL - ISANDO, ISANDO MAIN ON 1, MNISI S PLACE, 0025 STAND NO, 2579 PHOMOLONG, CHLOORKOP, TEMBISA, South Africa','geo':{'lat':-26.04979,'lng':28.17698}},
{'stp':489462,'address':'489462, EGL - ISANDO, ISANDO MAIN ON 1, BEER EXPRESS, 0349 STAND NO, SHILWAYUSIKU, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.00603,'lng':28.22015}},
{'stp':551569,'address':'551569, EGL - ISANDO, ISANDO MAIN ON 1, DUMIS TAVERN, 1511 KEKAE STREET, #, PHOMOLONG, TEMBISA, South Africa','geo':{'lat':-26.04340,'lng':28.17730}},
{'stp':551777,'address':'551777, EGL - ISANDO, ISANDO MAIN ON 1, MBOSHANE T, 126 MANTSU SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00730,'lng':28.20150}},
{'stp':552125,'address':'552125, EGL - ISANDO, ISANDO MAIN ON 1, MTHOMBENI PJ, 4434 MABENA STREET, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.04300,'lng':28.18550}},
{'stp':552219,'address':'552219, EGL - ISANDO, ISANDO MAIN ON 1, GAMEDZE T, 1530 MKHABELA STREET, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.04340,'lng':28.17670}},
{'stp':553201,'address':'553201, EGL - ISANDO, ISANDO MAIN ON 1, MD TAVERN, STAND 237, NO 237 SKELETON CAOST STREET, MOAKENG SEC TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99730,'lng':28.22140}},
{'stp':560194,'address':'560194, EGL - ISANDO, ISANDO MAIN ON 1, BOGALE NJ, 4471 EDWARD STREET, PHOMOLONG, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.04440,'lng':28.18260}},
{'stp':560487,'address':'560487, EGL - ISANDO, ISANDO MAIN ON 1, NDLOVU J, 1311 HLONGWANE STREET, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.04380,'lng':28.17620}},
{'stp':560529,'address':'560529, EGL - ISANDO, ISANDO MAIN ON 1, LOGODI J, 2801 SEBOPA STREET, LEKOKO SECTION, PHOMOLONG, TEMBISA, South Africa','geo':{'lat':-26.05040,'lng':28.18340}},
{'stp':560561,'address':'560561, EGL - ISANDO, ISANDO MAIN ON 1, SUMMER PLACE, 1182 MKANZI STREET, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.04060,'lng':28.17660}},
{'stp':560601,'address':'560601, EGL - ISANDO, ISANDO MAIN ON 1, MASELELA SJ, 2189 NGOBENI STREET, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.05220,'lng':28.17920}},
{'stp':560616,'address':'560616, EGL - ISANDO, ISANDO MAIN ON 1, AMOS MAZUZULE MACHAVE, 2914 PHOMOLONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.04760,'lng':28.18160}},
{'stp':561095,'address':'561095, EGL - ISANDO, ISANDO MAIN ON 1, NTHWASE LOVELANCE CHILOANE, 2689 MASEKO STREET, PHOMOLONG, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.05351,'lng':28.18208}},
{'stp':581161,'address':'581161, EGL - ISANDO, ISANDO MAIN ON 1, MGIBA NM, 1591 MAYIBUYE, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.03130,'lng':28.16300}},
{'stp':603778,'address':'603778, EGL - ISANDO, ISANDO MAIN ON 1, MALULEKA D, 4309 TRY AGAIN STREET, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.04130,'lng':28.18600}},
{'stp':603820,'address':'603820, EGL - ISANDO, ISANDO MAIN ON 1, KHOZA A, 1214 LETSOALO STREET, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.04097,'lng':28.17650}},
{'stp':604157,'address':'604157, EGL - ISANDO, ISANDO MAIN ON 1, NHLAPO K, 2050 NKUNA STREET, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.04862,'lng':28.17674}},
{'stp':604790,'address':'604790, EGL - ISANDO, ISANDO MAIN ON 1, TSININI LG, ERF 1485 COMMERCIA, EXT 34, #, TEMBISA, South Africa','geo':{'lat':-26.02921,'lng':28.16912}},
{'stp':604907,'address':'604907, EGL - ISANDO, ISANDO MAIN ON 1, CHAUKE T, 379 IGQAGQA SECTION, EXT 01, #, TEMBISA, South Africa','geo':{'lat':-26.00970,'lng':28.22220}},
{'stp':605015,'address':'605015, EGL - ISANDO, ISANDO MAIN ON 1, NDHLOVU YJ, 3986 PHOMOLONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.03900,'lng':28.18330}},
{'stp':606388,'address':'606388, EGL - ISANDO, ISANDO MAIN ON 1, NKOANA V, 4343 PHOMOLONG, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.04320,'lng':28.18310}},
{'stp':606399,'address':'606399, EGL - ISANDO, ISANDO MAIN ON 1, TJAO EC, 1980 VUMA STREET, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.04680,'lng':28.17670}},
{'stp':606625,'address':'606625, EGL - ISANDO, ISANDO MAIN ON 1, MPHEKEGOFE, 2719 MOKONE STREET, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.05230,'lng':28.18240}},
{'stp':606680,'address':'606680, EGL - ISANDO, ISANDO MAIN ON 1, MANAMELA EC SHEBEEN, 1362 MKHANZA STREET, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.03950,'lng':28.17620}},
{'stp':606715,'address':'606715, EGL - ISANDO, ISANDO MAIN ON 1, SHONGWE DD, 85 EMOYENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01280,'lng':28.22990}},
{'stp':606829,'address':'606829, EGL - ISANDO, ISANDO MAIN ON 1, SIPHO\'S TAVERN, 1869 HLONGWANE STREET, POMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.04340,'lng':28.17870}},
{'stp':606958,'address':'606958, EGL - ISANDO, ISANDO MAIN ON 1, NKOSI MS, 92 TEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00310,'lng':28.21230}},
{'stp':607019,'address':'607019, EGL - ISANDO, ISANDO MAIN ON 1, MGIBA LS, 1439 MAYIBUYE, EXT 34, #, IVORY PARK, South Africa','geo':{'lat':-26.03037,'lng':28.16320}},
{'stp':607437,'address':'607437, EGL - ISANDO, ISANDO MAIN ON 1, JUSTICE\'S PLACE, 1231 MAYIBUYE, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.03630,'lng':28.16050}},
{'stp':626298,'address':'626298, EGL - ISANDO, ISANDO MAIN ON 1, SWAZIS PLACE, 2143 MTHIMKHULU STREET, PHOMOLONG, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.05243,'lng':28.17819}},
{'stp':626762,'address':'626762, EGL - ISANDO, ISANDO MAIN ON 1, BIZAS TAVERN, 2988 CIRA LAAN, LEKOKO SECTION, PHOMOLONG, TEMBISA, South Africa','geo':{'lat':-26.04460,'lng':28.17970}},
{'stp':627061,'address':'627061, EGL - ISANDO, ISANDO MAIN ON 1, LAURAS PLACE, 2698 MASEKO STREET, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.05360,'lng':28.18090}},
{'stp':627357,'address':'627357, EGL - ISANDO, ISANDO MAIN ON 1, MAX PLACE, 2067 MAYIBUYE SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02990,'lng':28.17040}},
{'stp':627737,'address':'627737, EGL - ISANDO, ISANDO MAIN ON 1, PULANES TAVERN, 5134/14 MAJEKE STREET, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.04240,'lng':28.18540}},
{'stp':627905,'address':'627905, EGL - ISANDO, ISANDO MAIN ON 1, DALAS TAVERN, ERF NO 4179 PHOMOLONG, PHOMOLONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.04130,'lng':28.18470}},
{'stp':628084,'address':'628084, EGL - ISANDO, ISANDO MAIN ON 1, LUCYS PLACE, 4457 CIRA AVENUE, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.04452,'lng':28.18397}},
{'stp':628421,'address':'628421, EGL - ISANDO, ISANDO MAIN ON 1, MASEMA TAVERN, 3305 PHOMOLONG, LEKOKO SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.05190,'lng':28.18030}},
{'stp':629022,'address':'629022, EGL - ISANDO, ISANDO MAIN ON 1, SHIELA\'S PLACE, 2295 EXTENSION 34, MAYIBUYE, #, TEMBISA, South Africa','geo':{'lat':-26.03310,'lng':28.16860}},
{'stp':629031,'address':'629031, EGL - ISANDO, ISANDO MAIN ON 1, ENOCHS PLACE, 808 NGWENYA, PHOMOLONG, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03750,'lng':28.17600}},
{'stp':629079,'address':'629079, EGL - ISANDO, ISANDO MAIN ON 1, EXT3 CONER, 1390 KLIPFONTEIN VIEW, EXTENTION 3, #, TEMBISA, South Africa','geo':{'lat':-26.04371,'lng':28.15972}},
{'stp':629105,'address':'629105, EGL - ISANDO, ISANDO MAIN ON 1, VICTOR\'S PLACE, 2106 EXTENSION2, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.01260,'lng':28.17800}},
{'stp':629343,'address':'629343, EGL - ISANDO, ISANDO MAIN ON 1, KHUTSO S PLACE, 1478 PHOMOLONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.04290,'lng':28.17790}},
{'stp':629469,'address':'629469, EGL - ISANDO, ISANDO MAIN ON 1, WILLIES PLACE, 2052 EXTENSION 5 (Dosantos street), TEMBISA (Next to ivory park), #, TEMBISA, South Africa','geo':{'lat':-25.98970,'lng':28.21540}},
{'stp':629742,'address':'629742, EGL - ISANDO, ISANDO MAIN ON 1, PAISON PUB, 34 CASAS STREET, UMFUYANENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01630,'lng':28.19700}},
{'stp':630042,'address':'630042, EGL - ISANDO, ISANDO MAIN ON 1, MFUNDISIS TAVERN, 2512 MAYIBUYE, EXTENSION 34, #, TEMBISA, South Africa','geo':{'lat':-26.03440,'lng':28.17230}},
{'stp':630044,'address':'630044, EGL - ISANDO, ISANDO MAIN ON 1, MATLALA S PLACE, 1661 MAYIBUE, COMMERCIA, EXTENSION 34 IVORY PARK, TEMBISA, South Africa','geo':{'lat':-26.03150,'lng':28.16510}},
{'stp':630045,'address':'630045, EGL - ISANDO, ISANDO MAIN ON 1, NOMSAS PLACE, 1750 KHOZA STREET, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.04130,'lng':28.17820}},
{'stp':630824,'address':'630824, EGL - ISANDO, ISANDO MAIN ON 1, MAAKES PLACE, 1544 HLONGWANE STREET, PHOMOLONG, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.04410,'lng':28.17770}},
{'stp':630892,'address':'630892, EGL - ISANDO, ISANDO MAIN ON 1, ESTHERS PLACE, 2181 MAYIBUYE EXT 34, MIDRAND, JOHANNESBURG, TEMBISA, South Africa','geo':{'lat':-26.03295,'lng':28.16613}},
{'stp':631171,'address':'631171, EGL - ISANDO, ISANDO MAIN ON 1, JBS PLACE, 129 ENXIWENI STREET, TEMBISA, KEMPTONPARK, TEMBISA, South Africa','geo':{'lat':-26.02250,'lng':28.21760}},
{'stp':631562,'address':'631562, EGL - ISANDO, ISANDO MAIN ON 1, KWIK SAVE, 2123 MTHIMKHULU STR, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.05110,'lng':28.17850}},
{'stp':632703,'address':'632703, EGL - ISANDO, ISANDO MAIN ON 1, NTANDOS TAVERN, 296 IGQAQA SECTION, TEMBISA, KEMPTONPARK, TEMBISA, South Africa','geo':{'lat':-26.00970,'lng':28.22420}},
{'stp':633600,'address':'633600, EGL - ISANDO, ISANDO MAIN ON 1, MANGWANES PLACE, 779 NGWENYA PHOMOLONG, EXTENSION 51, #, TEMBISA, South Africa','geo':{'lat':-26.03600,'lng':28.17650}},
{'stp':633707,'address':'633707, EGL - ISANDO, ISANDO MAIN ON 1, LIFE IS GREAT TARVEN, 1539 IVORY PARK, EXTENSION 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.00880,'lng':28.18210}},
{'stp':633806,'address':'633806, EGL - ISANDO, ISANDO MAIN ON 1, MTHEMBI\'S PLACE, 2292 MAYIBUYE, EXTENSION 34, #, TEMBISA, South Africa','geo':{'lat':-26.03303,'lng':28.16807}},
{'stp':633971,'address':'633971, EGL - ISANDO, ISANDO MAIN ON 1, CHILLER\'S INN, 4475 PJ MAHLANGU STREET, PHOMOLONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.04400,'lng':28.18290}},
{'stp':635187,'address':'635187, EGL - ISANDO, ISANDO MAIN ON 1, MAPULANENG TAVERN, 3203 RABIE RIDGE, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.03530,'lng':28.18000}},
{'stp':635209,'address':'635209, EGL - ISANDO, ISANDO MAIN ON 1, MILLION GENERAL DEALER, 20 TANZANIA STREET, KLIPFONTEIN, #, TEMBISA, South Africa','geo':{'lat':-26.04100,'lng':28.15750}},
{'stp':636048,'address':'636048, EGL - ISANDO, ISANDO MAIN ON 1, MUNO CORNER SHOP, 550 KLIPFONTEIN, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.04737,'lng':28.15927}},
{'stp':636697,'address':'636697, EGL - ISANDO, ISANDO MAIN ON 1, JAZZ INN 2, 3157 MTHUNZI DRIVE, LEKOTA SECTION, PHOMOLONG, TEMBISA, South Africa','geo':{'lat':-26.04610,'lng':28.17820}},
{'stp':637096,'address':'637096, EGL - ISANDO, ISANDO MAIN ON 1, MOLAUTSI S TAVERN, 4432 ERF NO, DEMOCRACY STREET, #, TEMBISA, South Africa','geo':{'lat':-26.04300,'lng':28.18550}},
{'stp':637159,'address':'637159, EGL - ISANDO, ISANDO MAIN ON 1, CHACKS PLACE, 2047 NKUNA STREET, PHOMOLONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.04870,'lng':28.17670}},
{'stp':637334,'address':'637334, EGL - ISANDO, ISANDO MAIN ON 1, SILENDA TAVERN, 1151 MAYIBUYE EXT 31, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.03480,'lng':28.15850}},
{'stp':637440,'address':'637440, EGL - ISANDO, ISANDO MAIN ON 1, M J MOHALE TAVERN, 1964 VUMA STREET, PHOMOLONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.04635,'lng':28.17633}},
{'stp':638051,'address':'638051, EGL - ISANDO, ISANDO MAIN ON 1, SHAKA\'S PLACE, 138 ESSELEN PARK, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02320,'lng':28.23850}},
{'stp':639114,'address':'639114, EGL - ISANDO, ISANDO MAIN ON 1, JANE\'S PLACE MAYIBUYE, 1501 COMMERCIA STREET EXT 34, MAYIBUYE, RABIE RIDGE, TEMBISA, South Africa','geo':{'lat':-26.02900,'lng':28.17080}},
{'stp':639224,'address':'639224, EGL - ISANDO, ISANDO MAIN ON 1, FRANCINAS PALACE, 906/62 ISITHAMA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00820,'lng':28.22420}},
{'stp':639334,'address':'639334, EGL - ISANDO, ISANDO MAIN ON 1, SUN SAVE TAVERN, 3799 PHOMOLONG SECTION, THEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.04810,'lng':28.18520}},
{'stp':639459,'address':'639459, EGL - ISANDO, ISANDO MAIN ON 1, MNET INN, 4951 MELIA STREET, PHOMOLONG, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.04580,'lng':28.18370}},
{'stp':639622,'address':'639622, EGL - ISANDO, ISANDO MAIN ON 1, THANDI\'S PLACE, 1000 MAYIBUYE SECTION, EXT34, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.03357,'lng':28.15780}},
{'stp':639628,'address':'639628, EGL - ISANDO, ISANDO MAIN ON 1, BOIKHUTSOS PLACE, 1074 KLIPFONTEIN EXT 1, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.05110,'lng':28.16310}},
{'stp':639654,'address':'639654, EGL - ISANDO, ISANDO MAIN ON 1, ALINAHS PLACE, 1177 KLIPFONTEIN, EXT 1, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.05320,'lng':28.16330}},
{'stp':639673,'address':'639673, EGL - ISANDO, ISANDO MAIN ON 1, SHORTYS PLACE, 3943 TRY AGAIN STREET, TEMBISA Phumolong section, #, TEMBISA, South Africa','geo':{'lat':-26.04020,'lng':28.18640}},
{'stp':639980,'address':'639980, EGL - ISANDO, ISANDO MAIN ON 1, BELLAS PLACE, 4641 PHOMOLONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.04210,'lng':28.18630}},
{'stp':640339,'address':'640339, EGL - ISANDO, ISANDO MAIN ON 1, MAKHALA\'S PLACE, 4428 IVORY PARK, EXTENSION 2, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.00810,'lng':28.19180}},
{'stp':640341,'address':'640341, EGL - ISANDO, ISANDO MAIN ON 1, BIZAS PLACE, 2010 MAYIBUYE, RABIE RIDGE, #, TEMBISA, South Africa','geo':{'lat':-26.02890,'lng':28.17040}},
{'stp':640617,'address':'640617, EGL - ISANDO, ISANDO MAIN ON 1, HOLENI\'S PLACE, 1351 NONQA STREET, PHOHOLONG, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.04020,'lng':28.17660}},
{'stp':641056,'address':'641056, EGL - ISANDO, ISANDO MAIN ON 1, NGWAKOS PLACE, 1332 HLONGWANE STREET, PHOMOLONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.04460,'lng':28.17740}},
{'stp':641689,'address':'641689, EGL - ISANDO, ISANDO MAIN ON 1, THANDIS PLACE, 487 ETHAFENI SECTION, -, #, TEMBISA, South Africa','geo':{'lat':-26.02800,'lng':28.21190}},
{'stp':641964,'address':'641964, EGL - ISANDO, ISANDO MAIN ON 1, MOELANES PLACE, 227 MOTSU SECTION, TEMBISA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00820,'lng':28.20130}},
{'stp':642092,'address':'642092, EGL - ISANDO, ISANDO MAIN ON 1, MASOKAS TAVERN, 855 855 MASHIMONG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99910,'lng':28.23670}},
{'stp':642103,'address':'642103, EGL - ISANDO, ISANDO MAIN ON 1, IGGY\'S PLACE, 45 EMAKATINI SECTION 45, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01142,'lng':28.21637}},
{'stp':646405,'address':'646405, EGL - ISANDO, ISANDO MAIN ON 1, SIPHIWE\'S PLACE, 3569   PHOMOLONG SECTION, PHOMOLONG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03814,'lng':28.18304}},
{'stp':647351,'address':'647351, EGL - ISANDO, ISANDO MAIN ON 1, MNTUMHUM\'S PLACE, 912 ERF, COMMERCIA EXT 34, #, TEMBISA, South Africa','geo':{'lat':-26.03370,'lng':28.15855}},
{'stp':647556,'address':'647556, EGL - ISANDO, ISANDO MAIN ON 1, MORENA\'S PLACE, 1803 JOE SLOVO STREET, KLIPFONTEIN VIEW EXT 2, #, TEMBISA, South Africa','geo':{'lat':-26.04736,'lng':28.15575}},
{'stp':647642,'address':'647642, EGL - ISANDO, ISANDO MAIN ON 1, PATRICK\'S PLACE, 2915 JOE SLOVO STREET, INYONI PARK, RABIE RIDGE, IVORY PARK, South Africa','geo':{'lat':-26.02040,'lng':28.17210}},
{'stp':647719,'address':'647719, EGL - ISANDO, ISANDO MAIN ON 1, LAPOLOGA TAVERN, 2014 TLHONG STREET MAYIBUYE, #, #, TEMBISA, South Africa','geo':{'lat':-26.02921,'lng':28.17076}},
{'stp':647890,'address':'647890, EGL - ISANDO, ISANDO MAIN ON 1, MAMA JOY S  PLACE, 3441 DLAMINI STREET, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.03710,'lng':28.18440}},
{'stp':647914,'address':'647914, EGL - ISANDO, ISANDO MAIN ON 1, CONNY\'S PLACE, 1707 MAYIBUYE, #, #, TEMBISA, South Africa','geo':{'lat':-26.03060,'lng':28.16487}},
{'stp':648723,'address':'648723, EGL - ISANDO, ISANDO MAIN ON 1, GSB PUB, 273 TLAMATLAMA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-25.99530,'lng':28.22660}},
{'stp':648763,'address':'648763, EGL - ISANDO, ISANDO MAIN ON 1, MATITIMELA TAVERN, 1179 CORMECIA EXT 34, MAYIBUYE, RABIE RIDGE, TEMBISA, South Africa','geo':{'lat':-26.03640,'lng':28.15920}},
{'stp':649257,'address':'649257, EGL - ISANDO, ISANDO MAIN ON 1, TSEPISO TAVERN, 2330 MAYIBUYE COMMERCIAL EXT 34, #, #, TEMBISA, South Africa','geo':{'lat':-26.03360,'lng':28.16980}},
{'stp':650375,'address':'650375, EGL - ISANDO, ISANDO MAIN ON 1, SIBIYA E B, 2935 MOJAPELO STREET, #, #, TEMBISA, South Africa','geo':{'lat':-26.04730,'lng':28.18000}},
{'stp':656342,'address':'656342, EGL - ISANDO, ISANDO MAIN ON 1, LEBO MOROPA (NTO), 331 MAOKENG SECTION, MAOKENG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.99612,'lng':28.22096}},
{'stp':657611,'address':'657611, EGL - ISANDO, ISANDO MAIN ON 1, NTSHIMANE INN, 1094 MAYIBUYE, #, #, TEMBISA, South Africa','geo':{'lat':-26.03110,'lng':28.16150}},
{'stp':661629,'address':'661629, EGL - ISANDO, ISANDO MAIN ON 1, RM MADIKABJE, 0 628 TEMONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00030,'lng':28.21640}},
{'stp':664791,'address':'664791, EGL - ISANDO, ISANDO MAIN ON 1, MRAMBAS PLACE, 2713 PHOMOLONG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.05320,'lng':28.18070}},
{'stp':665161,'address':'665161, EGL - ISANDO, ISANDO MAIN ON 1, TSHABALALA TAVERN, 2109 LUANDA STREET KLIPFONTEIN, KLIPFONTEIN, #, TEMBISA, South Africa','geo':{'lat':-26.04600,'lng':28.15340}},
{'stp':665164,'address':'665164, EGL - ISANDO, ISANDO MAIN ON 1, MKWANI CORNER, 648 SIBASA STR, KLIPFONTEIN VIEW, EXT 1, TEMBISA, South Africa','geo':{'lat':-26.04742,'lng':28.15958}},
{'stp':665729,'address':'665729, EGL - ISANDO, ISANDO MAIN ON 1, SIYAKULA TUCKSHOP, 279 GABERONE STREET, KLIPFONTEINVIEW, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.04408,'lng':28.15809}},
{'stp':668726,'address':'668726, EGL - ISANDO, ISANDO MAIN ON 1, KLIPFONTEIN TAVERN, 449 KLIPFONTEIN, #, #, TEMBISA, South Africa','geo':{'lat':-26.04398,'lng':28.15687}},
{'stp':668733,'address':'668733, EGL - ISANDO, ISANDO MAIN ON 1, MIKES PLACE, 1062 MDAKA STREET, #, PHOMOLONG, TEMBISA, South Africa','geo':{'lat':-26.03870,'lng':28.17810}},
{'stp':668929,'address':'668929, EGL - ISANDO, ISANDO MAIN ON 1, GODFATHERS INN, 532 ESIPHETHWENI SECTION, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03080,'lng':28.21860}},
{'stp':684120,'address':'684120, EGL - ISANDO, ISANDO MAIN ON 1, SIVADZE AND MONATE, 984 KLIPFONTEIN, #, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.04900,'lng':28.16000}},
{'stp':687401,'address':'687401, EGL - ISANDO, ISANDO MAIN ON 1, YENDES PLACE, 74 MOYENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01460,'lng':28.23060}},
{'stp':688621,'address':'688621, EGL - ISANDO, ISANDO MAIN ON 1, LAPENG CHISA NYAMA, 4242 MDAKA STREET, PHOMOLONG, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03940,'lng':28.18710}},
{'stp':701446,'address':'701446, EGL - ISANDO, ISANDO MAIN ON 1, PHILLYS TAVERN, 122 MOEDI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01430,'lng':28.19530}},
{'stp':704980,'address':'704980, EGL - ISANDO, ISANDO MAIN ON 1, CARAVALES RESTAURANT AND PUB, ERF NO 723, ET MDAKA STREET, PHOMOLONG TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03860,'lng':28.18100}},
{'stp':762164,'address':'762164, EGL - ISANDO, ISANDO MAIN ON 1, DUDUS PLACE, 1721 COMMERCIA, EXT 34, MAYIBUYE, TEMBISA, South Africa','geo':{'lat':-26.02999,'lng':28.16655}},
{'stp':762872,'address':'762872, EGL - ISANDO, ISANDO MAIN ON 1, MATA S PLACE, 1731 AZANIA STREET, PHOMOLONG, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.04160,'lng':28.17920}},
{'stp':774345,'address':'774345, EGL - ISANDO, ISANDO MAIN ON 1, SELEPE AND SON, STAND NO, MOTEONG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.03552,'lng':28.18097}},
{'stp':797714,'address':'797714, EGL - ISANDO, ISANDO MAIN ON 1, MASINGIS\'S PLACE, MAHLANGU STREET, ERF 4381 PHOMOLONG SECT, CLOORKOP EXTR 53, IVORY PARK, South Africa','geo':{'lat':-26.04393,'lng':28.18347}},
{'stp':552546,'address':'552546, EGL - ISANDO, ISANDO MAIN ON 1, ISANDO RESTAURANT, 14 ISANDO ROAD, ISANDO, KEMPTON PARK, ISANDO, South Africa','geo':{'lat':-26.14390,'lng':28.20220}},
{'stp':640688,'address':'640688, EGL - ISANDO, ISANDO MAIN ON 1, SHISA NYAMA, 6 ALEXANDRA AVENUE, MIDRAND, #, MIDRAND, South Africa','geo':{'lat':-25.99410,'lng':28.12550}},
{'stp':700456,'address':'700456, EGL - ISANDO, ISANDO MAIN ON 1, J CAFE PLACE, 221 JIYANE SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02800,'lng':28.21380}},
{'stp':580492,'address':'580492, EGL - ISANDO, ISANDO MAIN ON 1, THABA DG, 9489 MALULEKA STREET, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.99020,'lng':28.21190}},
{'stp':627340,'address':'627340, EGL - ISANDO, ISANDO MAIN ON 1, FRANS TAVERN, 3089 KANANA EXT 5, LERATO STR, RABIE RIDGE, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.03520,'lng':28.18220}},
{'stp':630875,'address':'630875, EGL - ISANDO, ISANDO MAIN ON 1, EBONY\'S SPORTS BAR, 1642 HLOKOHOLOKO CRESCENT, EBONY PARK EXT 3, #, IVORY PARK, South Africa','geo':{'lat':-26.00060,'lng':28.18280}},
{'stp':632022,'address':'632022, EGL - ISANDO, ISANDO MAIN ON 1, NTOMBI\'S PLACE, 2228 MAYIBUYE, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.03254,'lng':28.16918}},
{'stp':632469,'address':'632469, EGL - ISANDO, ISANDO MAIN ON 1, BIG MOSS, 11144 IVORY PARK, EXTENSION 10, #, IVORY PARK, South Africa','geo':{'lat':-25.99390,'lng':28.19470}},
{'stp':633981,'address':'633981, EGL - ISANDO, ISANDO MAIN ON 1, MATONA\'S PLACE, 3524 VORY PARK, EXTENSION 5, RANDBURG, TEMBISA, South Africa','geo':{'lat':-26.01770,'lng':28.18230}},
{'stp':634849,'address':'634849, EGL - ISANDO, ISANDO MAIN ON 1, JACK TO JACK JAZZ CLUB, 611 KAALFONTEIN, EXTENSION 1, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.99718,'lng':28.22003}},
{'stp':639165,'address':'639165, EGL - ISANDO, ISANDO MAIN ON 1, GRACES PLACE, 2154 COMMERCIA STREET, EXT 34, MAYIBUYE, RABIE RIDGE, TEMBISA, South Africa','geo':{'lat':-26.02670,'lng':28.17600}},
{'stp':640751,'address':'640751, EGL - ISANDO, ISANDO MAIN ON 1, KIDS PLACE, 316 UMTHAMBEKA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02750,'lng':28.18890}},
{'stp':641045,'address':'641045, EGL - ISANDO, ISANDO MAIN ON 1, SELLOS PLACE, 1025 COMMMERCIAL, 34 MAYIBUYE SECTION, MIDRAND, TEMBISA, South Africa','geo':{'lat':-26.03215,'lng':28.15803}},
{'stp':642648,'address':'642648, EGL - ISANDO, ISANDO MAIN ON 1, MARIA\'S PLACE, 6474 DUSHANE STREET  num 6474, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13730,'lng':28.42370}},
{'stp':646343,'address':'646343, EGL - ISANDO, ISANDO MAIN ON 1, KHAYA RESTAURANT, 2673/4 CAMILIA STREET 2673/4, EBONY PARK EXT 6, HALFWAY HOUSE, IVORY PARK, South Africa','geo':{'lat':-26.00210,'lng':28.17130}},
{'stp':646948,'address':'646948, EGL - ISANDO, ISANDO MAIN ON 1, BOIKETLONG INN, 3602 RUBBERLIP STREET   num 3602, KAALFONTEIN EXT 7, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.99560,'lng':28.17580}},
{'stp':647194,'address':'647194, EGL - ISANDO, ISANDO MAIN ON 1, SKM INN, 1983 IVORY PARK  num 1983, EXT 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.01470,'lng':28.17670}},
{'stp':648612,'address':'648612, EGL - ISANDO, ISANDO MAIN ON 1, SIHLANGU\'S PLACE, 14230 IVORY PARK, EXT 12, #, TEMBISA, South Africa','geo':{'lat':-25.98660,'lng':28.21210}},
{'stp':663180,'address':'663180, EGL - ISANDO, ISANDO MAIN ON 1, RAISIBE MOKIBELO SPORTS BAR, 2490 RABIE RIDGE, #, KANANA EXT 5, TEMBISA, South Africa','geo':{'lat':-26.03287,'lng':28.18670}},
{'stp':667071,'address':'667071, EGL - ISANDO, ISANDO MAIN ON 1, DOREENS INN, 1469 KANANA, #, RABIE RIDGE EXT 8 EXT 4, TEMBISA, South Africa','geo':{'lat':-26.02710,'lng':28.17840}},
{'stp':704969,'address':'704969, EGL - ISANDO, ISANDO MAIN ON 1, THUALRE SPORTS BAR (NTO), 69 MOEDI SECTION, TEMBISA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01533,'lng':28.19911}},
{'stp':783792,'address':'783792, EGL - ISANDO, ISANDO MAIN ON 1, DELLAH\'S TAVERN (NTO), 203 ENDAYENI SECTION, TEMBISA 1632, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01780,'lng':28.19220}},
{'stp':308448,'address':'308448, EGL - ISANDO, ISANDO MAIN ON 1, THEMBA S BEER GARDEN, 2674 STAND NO, LAVENDER STREET, EBONY PARK, IVORY PARK, South Africa','geo':{'lat':-26.00201,'lng':28.17135}},
{'stp':309685,'address':'309685, EGL - ISANDO, ISANDO MAIN ON 1, LIFESTYLE TAVERN, 254 STAND NO, ACACIA STREET, EBONY PARK, EXT 4, IVORY PARK, South Africa','geo':{'lat':-26.00089,'lng':28.17764}},
{'stp':312404,'address':'312404, EGL - ISANDO, ISANDO MAIN ON 1, SIS MAY TAVERN, 2240 STAND NO, KOPANONG STREET, EXTENSION 4, TEMBISA, South Africa','geo':{'lat':-26.02954,'lng':28.18381}},
{'stp':386750,'address':'386750, EGL - ISANDO, ISANDO MAIN ON 1, BOTTOMS UP WINE N LIQUOR, 2 STAND NO, ERF 3117, KAALFONTEIN MALL,, ARCHERFISH DRIVE, ANGELFISH, IVORY PARK, South Africa','geo':{'lat':-25.98931,'lng':28.17514}},
{'stp':388683,'address':'388683, EGL - ISANDO, ISANDO MAIN ON 1, ROB AND BUSI S TAVERN, 271 STAND NO, TARNTAAL STREET, RABIE RIDGE, TEMBISA, South Africa','geo':{'lat':-26.01765,'lng':28.17547}},
{'stp':388963,'address':'388963, EGL - ISANDO, ISANDO MAIN ON 1, EMELY S PLACE, 1662 STAND NO, GAMA STREET, #, IVORY PARK, South Africa','geo':{'lat':-25.99930,'lng':28.18261}},
{'stp':388979,'address':'388979, EGL - ISANDO, ISANDO MAIN ON 1, PKN LIQUOR STORE, 1807 STAND NO, 199 BOUGANVILLA STREET, #, IVORY PARK, South Africa','geo':{'lat':-25.99962,'lng':28.17502}},
{'stp':389049,'address':'389049, EGL - ISANDO, ISANDO MAIN ON 1, SHOPRITE  LIQUORSHOP KAALFONTEIN 80, 10 SHOP NO,  CNR MAIN ROAD (K111), ANGELFISH STREET, IVORY PARK, South Africa','geo':{'lat':-25.98824,'lng':28.19031}},
{'stp':392406,'address':'392406, EGL - ISANDO, ISANDO MAIN ON 1, THEMBAS GUEST LOUNGE, 4795 STAND NO, KAALFONTEIN, EXTENSION 17, IVORY PARK, South Africa','geo':{'lat':-25.98944,'lng':28.17368}},
{'stp':392800,'address':'392800, EGL - ISANDO, ISANDO MAIN ON 1, MOTSWAKO LOUNGE, ARCHERFISH DRIVE KAALFONTEIN, SITUATED ON PORTION 57 OF FARMM, KAALFONTEIN 13 IR, TEMBISA, South Africa','geo':{'lat':-25.98462,'lng':28.17004}},
{'stp':481132,'address':'481132, EGL - ISANDO, ISANDO MAIN ON 1, LIQUOR LEGENDS IVORY PARK, 18162 STAND NO, SHOP NO 11 AND 12, KOPANONG SHOP CENTRE 8 MALATSI STR, IVORY PARK, South Africa','geo':{'lat':-25.98051,'lng':28.20549}},
{'stp':488393,'address':'488393, EGL - ISANDO, ISANDO MAIN ON 1, EVERGREEN S TAVERN, 1342 STAND NO, 12 FALCON RABIE, RISGE, IVORY PARK, South Africa','geo':{'lat':-26.01618,'lng':28.16862}},
{'stp':543961,'address':'543961, EGL - ISANDO, ISANDO MAIN ON 1, MOTSWAKO LOUNGE, STAND NO, PORTION 57 OF THE FARM KAALFONTEIN, 131 R CNR DALE AND ARCHERFISHER ROADS, IVORY PARK, South Africa','geo':{'lat':-25.98463,'lng':28.17053}},
{'stp':550258,'address':'550258, EGL - ISANDO, ISANDO MAIN ON 1, VELI S TAVERN, STAND 309, SWIFT STREET, RABIE RIDGE, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.01660,'lng':28.17390}},
{'stp':551083,'address':'551083, EGL - ISANDO, ISANDO MAIN ON 1, LOS LIQUOR STORE, PORTION 10 OF ERF 1074, 1074 RIETDUIKER STREET, RABIERIDGE, IVORY PARK, South Africa','geo':{'lat':-26.02100,'lng':28.17270}},
{'stp':551879,'address':'551879, EGL - ISANDO, ISANDO MAIN ON 1, ALLANDALE LIQUOR CELLARS, PLOT 63, MODDERFONTEIN ROAD, PRESIDENT PARK, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.99311,'lng':28.15368}},
{'stp':551946,'address':'551946, EGL - ISANDO, ISANDO MAIN ON 1, CANNON S PLACE, 2374 EXT 5, EBONEY PARK, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.99850,'lng':28.17290}},
{'stp':552140,'address':'552140, EGL - ISANDO, ISANDO MAIN ON 1, MAROTA TH, 1241 KAALFONTEIN EX 2, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.98792,'lng':28.18706}},
{'stp':552797,'address':'552797, EGL - ISANDO, ISANDO MAIN ON 1, TIMOTHYS PLACE, 536 EBONY PARK EXT 2, #, #, IVORY PARK, South Africa','geo':{'lat':-26.00460,'lng':28.17490}},
{'stp':553316,'address':'553316, EGL - ISANDO, ISANDO MAIN ON 1, MANELI NV, 1086 FOX FACE FISH, KAALFONTEIN EXT 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98748,'lng':28.18387}},
{'stp':553402,'address':'553402, EGL - ISANDO, ISANDO MAIN ON 1, HLATSHAWAYO MM, 12731 IVORY PARK, HALFWAYHOUSE, #, IVORY PARK, South Africa','geo':{'lat':-25.99620,'lng':28.19190}},
{'stp':560259,'address':'560259, EGL - ISANDO, ISANDO MAIN ON 1, LATOYAS PLACE, STAND NO 731 KAALFONTEIN EXT 1, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.99360,'lng':28.18720}},
{'stp':560298,'address':'560298, EGL - ISANDO, ISANDO MAIN ON 1, MALOBA MJ, 768 RAY FISH STREET, KAALFONTEIN EXT 1, #, IVORY PARK, South Africa','geo':{'lat':-25.99300,'lng':28.18810}},
{'stp':560372,'address':'560372, EGL - ISANDO, ISANDO MAIN ON 1, MSIMANGU MP, 3113 KAALFONTEIN, EXT 5, #, IVORY PARK, South Africa','geo':{'lat':-25.98700,'lng':28.18060}},
{'stp':560378,'address':'560378, EGL - ISANDO, ISANDO MAIN ON 1, HUDSON D, 22 BISHOP STREET, RABIE RIDGE EXT 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.01480,'lng':28.16630}},
{'stp':560415,'address':'560415, EGL - ISANDO, ISANDO MAIN ON 1, NKANYANA J, 1084 KAALFONTEIN, TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.98743,'lng':28.18372}},
{'stp':560454,'address':'560454, EGL - ISANDO, ISANDO MAIN ON 1,  KJ S PLACE, STAND NO 5047, EXT 7, IVORY PARK, IVORY PARK, South Africa','geo':{'lat':-26.00400,'lng':28.18220}},
{'stp':580430,'address':'580430, EGL - ISANDO, ISANDO MAIN ON 1, MOKOENA MD, 332 EBONY PARK, #, #, IVORY PARK, South Africa','geo':{'lat':-25.99910,'lng':28.17730}},
{'stp':580431,'address':'580431, EGL - ISANDO, ISANDO MAIN ON 1, ELIAS S TAVERN, 573 SITUATED AT NO 573 FLAT, CROWN STREET EBONY PARK, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.00400,'lng':28.17400}},
{'stp':602931,'address':'602931, EGL - ISANDO, ISANDO MAIN ON 1, XOLANI G, 4595 MOOR STREET, KAALFONTEIN, #, IVORY PARK, South Africa','geo':{'lat':-25.99120,'lng':28.16980}},
{'stp':605261,'address':'605261, EGL - ISANDO, ISANDO MAIN ON 1, TOPS AT EBONY PARK, AMABELE SPAR, CRN BELGUIM & ACACIA STREETS, ERF 1606 EBONY PARK, IVORY PARK, South Africa','geo':{'lat':-26.00220,'lng':28.17790}},
{'stp':606595,'address':'606595, EGL - ISANDO, ISANDO MAIN ON 1, LANGA A, 1187 EBONY PARK, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-26.00010,'lng':28.18000}},
{'stp':606732,'address':'606732, EGL - ISANDO, ISANDO MAIN ON 1, MANDISI DEMA, 601 KAALFONTEINEXT 1, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.99363,'lng':28.18276}},
{'stp':607253,'address':'607253, EGL - ISANDO, ISANDO MAIN ON 1, MBATHA T (ExFR), 454 BLUECUM STREET, EBONY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00270,'lng':28.17780}},
{'stp':607389,'address':'607389, EGL - ISANDO, ISANDO MAIN ON 1, SHIKWANES TAVERN, 2904 KANANA EXT 5, RABIE RIDGE, #, TEMBISA, South Africa','geo':{'lat':-26.03508,'lng':28.18067}},
{'stp':607430,'address':'607430, EGL - ISANDO, ISANDO MAIN ON 1, MAGWENJU MM, 1640 PARLIAMENT STREET, KANANA EXT 4, #, TEMBISA, South Africa','geo':{'lat':-26.02620,'lng':28.17650}},
{'stp':607436,'address':'607436, EGL - ISANDO, ISANDO MAIN ON 1, NKHUNAS TAVERN, 2093 KANANA EXT 4, midrand, #, TEMBISA, South Africa','geo':{'lat':-26.02480,'lng':28.18000}},
{'stp':612113,'address':'612113, EGL - ISANDO, ISANDO MAIN ON 1, CARPET INN, 350 ERF FALCON STREET, RABBIE RIDGE, #, TEMBISA, South Africa','geo':{'lat':-26.01600,'lng':28.17250}},
{'stp':620115,'address':'620115, EGL - ISANDO, ISANDO MAIN ON 1, LEGODI TUCKSHOP, 21 STAND NO, MPHATA STR EBONY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.99783,'lng':28.17557}},
{'stp':626176,'address':'626176, EGL - ISANDO, ISANDO MAIN ON 1, NGOAKO\'S, 2092 MOHLAKO STREET, EBONY PARK EXT 4, #, IVORY PARK, South Africa','geo':{'lat':-25.99720,'lng':28.17690}},
{'stp':627301,'address':'627301, EGL - ISANDO, ISANDO MAIN ON 1, EBONY PARK SPAZA, 1898 PEKWA STREET, EBONY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.99540,'lng':28.18480}},
{'stp':627355,'address':'627355, EGL - ISANDO, ISANDO MAIN ON 1, FANCYS TAVERN, 1165 IVORY PARK ECT 2, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00750,'lng':28.17800}},
{'stp':627404,'address':'627404, EGL - ISANDO, ISANDO MAIN ON 1, BOPHELONG HOTEL AND RESTAURANT, 3461 KAALFONTEIN, EXT 7, #, IVORY PARK, South Africa','geo':{'lat':-25.99690,'lng':28.17210}},
{'stp':627733,'address':'627733, EGL - ISANDO, ISANDO MAIN ON 1, MARIA\'S PLACE, 5305 IVORY PARK EXT 7, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00240,'lng':28.18660}},
{'stp':627736,'address':'627736, EGL - ISANDO, ISANDO MAIN ON 1, MANAMELA, 6121 IVORY PARK, EXT 2, HALFWAY HOUSE, #, IVORY PARK, South Africa','geo':{'lat':-26.00350,'lng':28.18940}},
{'stp':627742,'address':'627742, EGL - ISANDO, ISANDO MAIN ON 1, SPHOLA S PLACE, 7228 MADUNA STREET, HALFWAY HOUSE, #, TEMBISA, South Africa','geo':{'lat':-26.00230,'lng':28.20170}},
{'stp':627930,'address':'627930, EGL - ISANDO, ISANDO MAIN ON 1, LANGA TAVERN, 1461 CNR DAMSEL FISH AND TANG F1, EXTENTION 2, #, IVORY PARK, South Africa','geo':{'lat':-25.98820,'lng':28.18250}},
{'stp':628414,'address':'628414, EGL - ISANDO, ISANDO MAIN ON 1, HONEY BUNCH, 1792  BLUEGUM ROAD, EXTENTION 3, EBONYPARK,, IVORY PARK, South Africa','geo':{'lat':-25.99774,'lng':28.18268}},
{'stp':629032,'address':'629032, EGL - ISANDO, ISANDO MAIN ON 1, SEEPE\'S PLACE, 2955 KAALFONTEIN, EXTENTION 5, #, IVORY PARK, South Africa','geo':{'lat':-25.98610,'lng':28.17650}},
{'stp':629114,'address':'629114, EGL - ISANDO, ISANDO MAIN ON 1, FRIDAY INN, 5495 MKHWATSWA STREET, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00682,'lng':28.18611}},
{'stp':629117,'address':'629117, EGL - ISANDO, ISANDO MAIN ON 1, CAIRO\'S TAVERN, 5958 TOKYO, EXTENTION 2, IVORY PARK, IVORY PARK, South Africa','geo':{'lat':-26.00730,'lng':28.18750}},
{'stp':630068,'address':'630068, EGL - ISANDO, ISANDO MAIN ON 1, TS INN, 475 BABOA CLOSE, EBONY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00415,'lng':28.18206}},
{'stp':630070,'address':'630070, EGL - ISANDO, ISANDO MAIN ON 1, JOE\'S PLACE, 137/1854 EXTENSION4, KANANA, #, TEMBISA, South Africa','geo':{'lat':-26.02380,'lng':28.17910}},
{'stp':632011,'address':'632011, EGL - ISANDO, ISANDO MAIN ON 1, MARUMO S PLACE, 5222 IVORY PARK, EXTENSION 07, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.00630,'lng':28.18300}},
{'stp':633598,'address':'633598, EGL - ISANDO, ISANDO MAIN ON 1, SARAH S PLACE, 2006 ERF NO, RED ROSE STREET KANANA, RABIE RIDGE EXT 4, TEMBISA, South Africa','geo':{'lat':-26.02630,'lng':28.18220}},
{'stp':633790,'address':'633790, EGL - ISANDO, ISANDO MAIN ON 1, MARYS TAVERN, 12827 MOROKOROKO STREET, LINDIKUHLE SECTION , EXTENSION 10, IVORY PARK, IVORY PARK, South Africa','geo':{'lat':-25.99742,'lng':28.19208}},
{'stp':634331,'address':'634331, EGL - ISANDO, ISANDO MAIN ON 1, MASHIANE TRADING, 1082 LOVE BIRD (house no 146), RABIE RIDGE, #, IVORY PARK, South Africa','geo':{'lat':-26.01290,'lng':28.17260}},
{'stp':634490,'address':'634490, EGL - ISANDO, ISANDO MAIN ON 1, TIMOTE\'S PLACE, 2622 EXTENSION 5, KAALFONTEIN, #, IVORY PARK, South Africa','geo':{'lat':-25.98703,'lng':28.17550}},
{'stp':634491,'address':'634491, EGL - ISANDO, ISANDO MAIN ON 1, SITOE\'S PLACE, 1700 KAALFONTEIN, #, #, IVORY PARK, South Africa','geo':{'lat':-25.98820,'lng':28.18050}},
{'stp':635006,'address':'635006, EGL - ISANDO, ISANDO MAIN ON 1, JOYCE\'S PLACE, 5583 IVORY PARK, EXTENSION 7, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.00370,'lng':28.18990}},
{'stp':636049,'address':'636049, EGL - ISANDO, ISANDO MAIN ON 1, SAM\'S TAVERN, 1625 PLOVER STR, EBONY PARK EXT 3, #, IVORY PARK, South Africa','geo':{'lat':-25.99935,'lng':28.18138}},
{'stp':637128,'address':'637128, EGL - ISANDO, ISANDO MAIN ON 1, NTSWAKI\'S PLACE, 2216 GULFFONTEIN, MIDRAND EXT 4, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.99207,'lng':28.18068}},
{'stp':637136,'address':'637136, EGL - ISANDO, ISANDO MAIN ON 1, MIRRIAMS JAZZ CLUB 2, 2961 EXT 6 EBONY PARK, TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-26.00214,'lng':28.17075}},
{'stp':637353,'address':'637353, EGL - ISANDO, ISANDO MAIN ON 1, MIZO\'S PLACE, 540 KILLFISH STREET, EXT 1, KAALFONTEIN - TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.99490,'lng':28.18550}},
{'stp':637441,'address':'637441, EGL - ISANDO, ISANDO MAIN ON 1, ELSIE\'S PLACE, 3146 POMPON, KAALFONTEIN EXT 7, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.99040,'lng':28.17270}},
{'stp':637757,'address':'637757, EGL - ISANDO, ISANDO MAIN ON 1, PASEKA\'S PLACE, 305 EXTENSION 2, RABIE RIGDE, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-26.01520,'lng':28.16770}},
{'stp':638558,'address':'638558, EGL - ISANDO, ISANDO MAIN ON 1, DIVAS PLACE, 12727 IVORY PARK, EXT 10, RANDBURG, IVORY PARK, South Africa','geo':{'lat':-25.99600,'lng':28.19190}},
{'stp':639098,'address':'639098, EGL - ISANDO, ISANDO MAIN ON 1, MTHEMBU\'S PLACE, 4575 MOOR STREET, KAALFONTEIN EXT 5, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.99100,'lng':28.17040}},
{'stp':639169,'address':'639169, EGL - ISANDO, ISANDO MAIN ON 1, ALEXS PLACE,  1307 JUBA STREET, EBONY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.99630,'lng':28.17940}},
{'stp':639274,'address':'639274, EGL - ISANDO, ISANDO MAIN ON 1, MANYONI\'S PLACE, 4552 ANGELFISH, KAALFONTEIN, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.99220,'lng':28.17010}},
{'stp':639285,'address':'639285, EGL - ISANDO, ISANDO MAIN ON 1, GUGUS TAVERN, 1220 BLUE TANGFISH, KAALFONTEIN, #, IVORY PARK, South Africa','geo':{'lat':-25.98758,'lng':28.18623}},
{'stp':639356,'address':'639356, EGL - ISANDO, ISANDO MAIN ON 1, MASSINGUE S TRADING, 2090 MUDHOPPER STREET, KAALFONTEIN EXT 4, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.99120,'lng':28.18250}},
{'stp':640720,'address':'640720, EGL - ISANDO, ISANDO MAIN ON 1, MORAKE INN, 124 COCKATOO CLOSE 124, RABIE RIDGE, #, IVORY PARK, South Africa','geo':{'lat':-26.01600,'lng':28.17570}},
{'stp':641602,'address':'641602, EGL - ISANDO, ISANDO MAIN ON 1, JOE S PLACE, IVORY PARK NUM 4566, EXTENSION 6, #, TEMBISA, South Africa','geo':{'lat':-26.00900,'lng':28.19300}},
{'stp':641829,'address':'641829, EGL - ISANDO, ISANDO MAIN ON 1, THATO\'S TAVERN, 2211 KAALFONTEIN num 2211, EXTENSION 4, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.98660,'lng':28.18530}},
{'stp':642195,'address':'642195, EGL - ISANDO, ISANDO MAIN ON 1, NKWANYANA TUCKSHOP, 5044 IVORY PARK  num 5044, EXT 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.00410,'lng':28.18200}},
{'stp':642597,'address':'642597, EGL - ISANDO, ISANDO MAIN ON 1, MABLONDY TAVERN, 2611 KAALFONTEIN EXT 5  num 2611, MIDRAND, RANDBURG, IVORY PARK, South Africa','geo':{'lat':-25.98750,'lng':28.17580}},
{'stp':646308,'address':'646308, EGL - ISANDO, ISANDO MAIN ON 1, LINDI\'S PLACE, 968 KAALFONTEIN  num 968, KAALFONTEIN EXT 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98460,'lng':28.18070}},
{'stp':646513,'address':'646513, EGL - ISANDO, ISANDO MAIN ON 1, PETER\'S BUTCHERY, 603 IVORY PARK   num 603, EXTENSION 2, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-26.00810,'lng':28.17395}},
{'stp':649082,'address':'649082, EGL - ISANDO, ISANDO MAIN ON 1, MOKOELE\'S PLACE, 801 KAALFONTEIN EXT 1, #, #, IVORY PARK, South Africa','geo':{'lat':-25.99240,'lng':28.18700}},
{'stp':649087,'address':'649087, EGL - ISANDO, ISANDO MAIN ON 1, NGEMA\'S PLACE, 2449 KAALFONTEIN EXT 5, #, #, IVORY PARK, South Africa','geo':{'lat':-25.98810,'lng':28.17350}},
{'stp':649091,'address':'649091, EGL - ISANDO, ISANDO MAIN ON 1, KEDUMETSI INN, 391 EBONY PARK, #, #, IVORY PARK, South Africa','geo':{'lat':-25.99850,'lng':28.17760}},
{'stp':649290,'address':'649290, EGL - ISANDO, ISANDO MAIN ON 1, KEKANA INN, 3038 RABIE RIDGE EXT 5, #, #, TEMBISA, South Africa','geo':{'lat':-26.02632,'lng':28.21373}},
{'stp':656680,'address':'656680, EGL - ISANDO, ISANDO MAIN ON 1, CHARLES PLACE, 729 STAND NO, 10 HLAKANIPHA AVENUE, EXTENSION 2, IVORY PARK, South Africa','geo':{'lat':-26.00608,'lng':28.17680}},
{'stp':661645,'address':'661645, EGL - ISANDO, ISANDO MAIN ON 1, NKOSIS PLACE, 1099 KAALFONTEIN EXT 2, #, #, IVORY PARK, South Africa','geo':{'lat':-25.98640,'lng':28.18530}},
{'stp':663935,'address':'663935, EGL - ISANDO, ISANDO MAIN ON 1, LEGODI\'S TUCKSHOP (NTO), 2061 EBONY PARK EXT 4, EBONY PARK EXT 4 2061, EBONY PARK EXT 4 2061, IVORY PARK, South Africa','geo':{'lat':-25.99775,'lng':28.17561}},
{'stp':666309,'address':'666309, EGL - ISANDO, ISANDO MAIN ON 1, SHOPRITE PHUMULANI 54601, 0 CNR INDUSTRY AND ANDREW MAPETO DR, SHOP 2 PHUMULANI MALL ALONG R562 ROUTE, CLAYVILLE EXTENSION 22, IVORY PARK, South Africa','geo':{'lat':-25.97480,'lng':28.22850}},
{'stp':667445,'address':'667445, EGL - ISANDO, ISANDO MAIN ON 1, NEOS TAVERN, 1652 NTOKOZWENI STREET, #, RABIE RIDGE EXTENSION 4, TEMBISA, South Africa','geo':{'lat':-26.02510,'lng':28.17590}},
{'stp':671695,'address':'671695, EGL - ISANDO, ISANDO MAIN ON 1, SHOPRITE KAALFONTEIN 53702, CNR ARCHERFISH AND ANGELFISH STR, ERF 3115 SHOP 2 YA RONE CENTRE, KAALFONTEIN EXT 6, IVORY PARK, South Africa','geo':{'lat':-25.98890,'lng':28.17250}},
{'stp':693911,'address':'693911, EGL - ISANDO, ISANDO MAIN ON 1, MOTJATJI PLACE, 2919 EXTENSION, KAALFONTEIN MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.98648,'lng':28.17521}},
{'stp':699276,'address':'699276, EGL - ISANDO, ISANDO MAIN ON 1, OUR FREEWAY TAVERN, 4806 LADYFISH STREET, KAALFONTEIN, #, IVORY PARK, South Africa','geo':{'lat':-25.98640,'lng':28.17160}},
{'stp':706983,'address':'706983, EGL - ISANDO, ISANDO MAIN ON 1, BHIZZA MEETING PLACE AND PUB, 1130 ALBATROS, RABIE RIDGE, #, IVORY PARK, South Africa','geo':{'lat':-26.01370,'lng':28.17550}},
{'stp':765965,'address':'765965, EGL - ISANDO, ISANDO MAIN ON 1, BIZ EF RESTAURANT, 31 JAMES CRESCENT, PORTION 1 OF ERF 403, HALFWAY HOUSE EXTENSION 44, MIDRAND, South Africa','geo':{'lat':-26.00526,'lng':28.18030}},
{'stp':766917,'address':'766917, EGL - ISANDO, ISANDO MAIN ON 1, MOTHIBI S TAVERN, 1/68 STAND NO, MODDERFONTEIN ROAD PRESIDENT PARK, #, MIDRAND, South Africa','geo':{'lat':-25.99183,'lng':28.15456}},
{'stp':785137,'address':'785137, EGL - ISANDO, ISANDO MAIN ON 1, PICK AND PAY TEMBISA GC77, STAND NO, TEMBISA MEGMART SHOPPING CENTRE, CORNER OLIFANTSFONTIEN AND ALGERIA ROADS, IVORY PARK, South Africa','geo':{'lat':-25.96530,'lng':28.21150}},
{'stp':786866,'address':'786866, EGL - ISANDO, ISANDO MAIN ON 1, BUNDAS TAVERN, 4952 STAND NO, EXT 2, #, IVORY PARK, South Africa','geo':{'lat':-26.00695,'lng':28.18262}},
{'stp':787839,'address':'787839, EGL - ISANDO, ISANDO MAIN ON 1, NDABASES PLACE, QUATRO STREET, 5004 IVORY PARK EXT 7, #, MIDRAND, South Africa','geo':{'lat':-26.00106,'lng':28.18193}},
{'stp':793960,'address':'793960, EGL - ISANDO, ISANDO MAIN ON 1, KHLULULEKA SD SHEBEEN, STAND NO, 1341 EXT 2, #, IVORY PARK, South Africa','geo':{'lat':-26.01508,'lng':28.17317}},
{'stp':311461,'address':'311461, EGL - ISANDO, ISANDO MAIN ON 1, RAIDERS PLACE, 190 STAND NO, MPHO SECTION, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01697,'lng':28.21142}},
{'stp':318555,'address':'318555, EGL - ISANDO, ISANDO MAIN ON 1, THIWES PLACE, 288 STAND NO, MOSES KOTANE ROAD, ENTSHONALANGA, TEMBISA, South Africa','geo':{'lat':-26.02031,'lng':28.18614}},
{'stp':384747,'address':'384747, EGL - ISANDO, ISANDO MAIN ON 1, SIFISO PLACE, 266 STAND NO, UMTHAMBEKA SECTRION, #, TEMBISA, South Africa','geo':{'lat':-26.02571,'lng':28.18938}},
{'stp':388286,'address':'388286, EGL - ISANDO, ISANDO MAIN ON 1, BROS PLACE, 107 STAND NUMBER, THITENG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02121,'lng':28.20273}},
{'stp':388361,'address':'388361, EGL - ISANDO, ISANDO MAIN ON 1, PHINEAS PLACE, 246 STAND NO, BENJAMIN NTLHANE DRIVE, ENDAYINI, TEMBISA, South Africa','geo':{'lat':-26.02054,'lng':28.19999}},
{'stp':388485,'address':'388485, EGL - ISANDO, ISANDO MAIN ON 1, SIPHOS PLACE, 766 STAND NO, MTHAMBEKA SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02684,'lng':28.19588}},
{'stp':388954,'address':'388954, EGL - ISANDO, ISANDO MAIN ON 1, THABITHAS S PLACE, 346 STAND NO, MOSES KOTANE STR, ENTSHONALANGA SECTION, KEMPTON PARK,, TEMBISA, South Africa','geo':{'lat':-26.02040,'lng':28.18616}},
{'stp':390927,'address':'390927, EGL - ISANDO, ISANDO MAIN ON 1, THEMBA S PLACE, STAND NO. 52, #, #, TEMBISA, South Africa','geo':{'lat':-26.03066,'lng':28.20442}},
{'stp':392604,'address':'392604, EGL - ISANDO, ISANDO MAIN ON 1, HYPER LIQUOR TAVERN, 691 EMFUYANENG SECTION, MZILIKAZI STREET, #, TEMBISA, South Africa','geo':{'lat':-26.01943,'lng':28.19637}},
{'stp':461937,'address':'461937, EGL - ISANDO, ISANDO MAIN ON 1, MATSEMELA S D TAVERN, 41 MNGOMEZULU STREET, EERF 37 THITENG SECT, #, TEMBISA, South Africa','geo':{'lat':-26.02042,'lng':28.20064}},
{'stp':463756,'address':'463756, EGL - ISANDO, ISANDO MAIN ON 1, NEVER GIVE UP, 3748/80 STAND NO, PHOMOLONG, #, TEMBISA, South Africa','geo':{'lat':-26.03728,'lng':28.18883}},
{'stp':476723,'address':'476723, EGL - ISANDO, ISANDO MAIN ON 1, WHAT IS BLACK, 42 STAND NO, BENJAMIN NTLHANE DRIVE, UMNONJANENI, TEMBISA, South Africa','geo':{'lat':-26.01411,'lng':28.19199}},
{'stp':478463,'address':'478463, EGL - ISANDO, ISANDO MAIN ON 1, EDLOZINI PLACE, 530 STAND NO, ARCHIE GUMEDE RD, ROAD, ENTSHONALANGA, TEMBISA, South Africa','geo':{'lat':-26.02304,'lng':28.18421}},
{'stp':484560,'address':'484560, EGL - ISANDO, ISANDO MAIN ON 1, MABUZAS PLACE, 53 STAND NUMBER, SOLOMON  MAHLANGU, CRES 2822, TEMBISA, South Africa','geo':{'lat':-26.01210,'lng':28.18733}},
{'stp':491146,'address':'491146, EGL - ISANDO, ISANDO MAIN ON 1, DOT COM INN, 3939 STAND NO, FIDEL CASTRO CRESENT, EXT 11 ENTSHONALANGA, TEMBISA, South Africa','geo':{'lat':-26.02221,'lng':28.18236}},
{'stp':543325,'address':'543325, EGL - ISANDO, ISANDO MAIN ON 1, MAZIYA LIQUORS AND LEISURE, STAND NO, ERF 904, 905 ANDREW MAPHETO ROAD, TEMBISA, South Africa','geo':{'lat':-26.01719,'lng':28.21335}},
{'stp':550979,'address':'550979, EGL - ISANDO, ISANDO MAIN ON 1, MZETS TAVERN, 515 OUPA MOETI ROAD, UMFUYANENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02100,'lng':28.19680}},
{'stp':551099,'address':'551099, EGL - ISANDO, ISANDO MAIN ON 1, FEMER JACOB SITHEBE, 584 VUSIMUZI, #, #, TEMBISA, South Africa','geo':{'lat':-26.03260,'lng':28.20330}},
{'stp':551138,'address':'551138, EGL - ISANDO, ISANDO MAIN ON 1, PLANTINAS PLACE, 62 MPHO SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01680,'lng':28.20980}},
{'stp':551150,'address':'551150, EGL - ISANDO, ISANDO MAIN ON 1, NOKENG BOTTLE STORE, 347 KGATLAMPING, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01230,'lng':28.19940}},
{'stp':551723,'address':'551723, EGL - ISANDO, ISANDO MAIN ON 1, PHISTOS PLACE, 794 MTHAMBEKA SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.02250,'lng':28.19100}},
{'stp':551837,'address':'551837, EGL - ISANDO, ISANDO MAIN ON 1, LINDA S TAVERN, ERF NO 697, UMTHAMBEKA SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02300,'lng':28.18990}},
{'stp':552406,'address':'552406, EGL - ISANDO, ISANDO MAIN ON 1, PEACE TAVERN, STAND 108 MOEDI SECTION, TEMBISA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01520,'lng':28.19690}},
{'stp':552409,'address':'552409, EGL - ISANDO, ISANDO MAIN ON 1, LESIBA PALACE, 201 UMNOMJANENI_SECTION, TEMBISA, 1628, TEMBISA, South Africa','geo':{'lat':-26.01510,'lng':28.19280}},
{'stp':552617,'address':'552617, EGL - ISANDO, ISANDO MAIN ON 1, KORASI LIQUOR STORE, ERF 172 UMNONJANENI SECT, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01470,'lng':28.19390}},
{'stp':553448,'address':'553448, EGL - ISANDO, ISANDO MAIN ON 1, SIX TO SIX, 610 NAIROBI STREET, EHLANZENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03110,'lng':28.20500}},
{'stp':553596,'address':'553596, EGL - ISANDO, ISANDO MAIN ON 1, DAPHNES PALACE, STAND 320, ESANGWENI, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02120,'lng':28.20080}},
{'stp':560216,'address':'560216, EGL - ISANDO, ISANDO MAIN ON 1, MATHIBELAS PLACE, STAND NO 01082 -016, BARN OWL ST, RABIE RIDGE EXT 2, IVORY PARK, South Africa','geo':{'lat':-26.01130,'lng':28.17250}},
{'stp':560239,'address':'560239, EGL - ISANDO, ISANDO MAIN ON 1, THEMA TJ, 22 MOSWE AVE (354 Moedi Sec), TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01410,'lng':28.19720}},
{'stp':560426,'address':'560426, EGL - ISANDO, ISANDO MAIN ON 1, RADEBE NN, 1098 SAM NUJOMA STREET, UMTHAMBEKA SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02260,'lng':28.19170}},
{'stp':560432,'address':'560432, EGL - ISANDO, ISANDO MAIN ON 1, NKABASA ENTERTAINMENT AND PROMOTION, 430 UMNONJANENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01840,'lng':28.18950}},
{'stp':560539,'address':'560539, EGL - ISANDO, ISANDO MAIN ON 1, YENDE NA, 110 MTHAMBEKA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.03020,'lng':28.18810}},
{'stp':561195,'address':'561195, EGL - ISANDO, ISANDO MAIN ON 1, NGWENYA MC, 51 VUSI MNGOMEZULU STREET, ESANGWENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02360,'lng':28.19900}},
{'stp':561197,'address':'561197, EGL - ISANDO, ISANDO MAIN ON 1, MZIMBA M, 835 EBUHLENI STREET, UMTAMBEKA SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02624,'lng':28.19258}},
{'stp':580388,'address':'580388, EGL - ISANDO, ISANDO MAIN ON 1, MOLOKOMME GN, 85 UMTAMBEKA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02830,'lng':28.19110}},
{'stp':580490,'address':'580490, EGL - ISANDO, ISANDO MAIN ON 1, MZOLO DAVID VUYANI, 129 ESANGWENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02450,'lng':28.19910}},
{'stp':580723,'address':'580723, EGL - ISANDO, ISANDO MAIN ON 1, VELAPHI LIQUOR DISTRIBUTORS, 185 ESANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02260,'lng':28.20050}},
{'stp':581066,'address':'581066, EGL - ISANDO, ISANDO MAIN ON 1, SKOSANAS TAVERN, ERF 2244 ROBERT MATHEKGA DRIVE, TEMBISA EXT 5 EDENVALE, #, TEMBISA, South Africa','geo':{'lat':-26.02050,'lng':28.18310}},
{'stp':602869,'address':'602869, EGL - ISANDO, ISANDO MAIN ON 1, MPISI LT, 571 UMFUNYANENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02150,'lng':28.19719}},
{'stp':602872,'address':'602872, EGL - ISANDO, ISANDO MAIN ON 1, GUMEDE MW, 307 UMTHAMBEKA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02830,'lng':28.18940}},
{'stp':603779,'address':'603779, EGL - ISANDO, ISANDO MAIN ON 1, RAMOHAPI PT, 485 UMFUYANENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02270,'lng':28.19600}},
{'stp':603821,'address':'603821, EGL - ISANDO, ISANDO MAIN ON 1, MATHONSI SA, 117 IBAXA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02030,'lng':28.21400}},
{'stp':603992,'address':'603992, EGL - ISANDO, ISANDO MAIN ON 1, BADANA PF, 99 ISIVANA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01800,'lng':28.21250}},
{'stp':603993,'address':'603993, EGL - ISANDO, ISANDO MAIN ON 1, SITHOLE SA 2, 848 ENHLANZENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.03380,'lng':28.20990}},
{'stp':604330,'address':'604330, EGL - ISANDO, ISANDO MAIN ON 1, SAMS PLACE, PORTION 550 EHLANZENI, #, #, TEMBISA, South Africa','geo':{'lat':-26.03257,'lng':28.20247}},
{'stp':604331,'address':'604331, EGL - ISANDO, ISANDO MAIN ON 1, KGATLA MLF, 73 KGATLAMPING SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01180,'lng':28.19640}},
{'stp':604524,'address':'604524, EGL - ISANDO, ISANDO MAIN ON 1, MOSWANI TUCKSHOP, 516 ENHLANZENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.03125,'lng':28.20259}},
{'stp':604624,'address':'604624, EGL - ISANDO, ISANDO MAIN ON 1, REUBENS PLACE, QUAL STREET, 393 LEKANENG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01960,'lng':28.20690}},
{'stp':604680,'address':'604680, EGL - ISANDO, ISANDO MAIN ON 1, MOHLOUAO L, 550 UMFUYANENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02400,'lng':28.19560}},
{'stp':604763,'address':'604763, EGL - ISANDO, ISANDO MAIN ON 1, MTHEMBU TG, 148 EHLANZENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02970,'lng':28.20170}},
{'stp':604806,'address':'604806, EGL - ISANDO, ISANDO MAIN ON 1, MEHALA AS, 432 UMFUYANENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02280,'lng':28.19520}},
{'stp':628058,'address':'628058, EGL - ISANDO, ISANDO MAIN ON 1, JULIUS PLACE, 547 UMFUYANENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02420,'lng':28.19540}},
{'stp':628059,'address':'628059, EGL - ISANDO, ISANDO MAIN ON 1, LUTHANDO, 15918 AUGUST STREET, GREEN SECTION, #, IVORY PARK, South Africa','geo':{'lat':-26.00770,'lng':28.18270}},
{'stp':628080,'address':'628080, EGL - ISANDO, ISANDO MAIN ON 1, SHIRLEYS TAVERN, 5936 EXTENTION (2), EXTENTION (2), #, IVORY PARK, South Africa','geo':{'lat':-26.00710,'lng':28.18630}},
{'stp':628081,'address':'628081, EGL - ISANDO, ISANDO MAIN ON 1, MERIS JAZZ INN, 60 NONYANA STREET, MPHO SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01680,'lng':28.21010}},
{'stp':628423,'address':'628423, EGL - ISANDO, ISANDO MAIN ON 1, NTOMBIS INN, 3535 STAND NO, JULY EXT 5, #, IVORY PARK, South Africa','geo':{'lat':-26.01337,'lng':28.18542}},
{'stp':629608,'address':'629608, EGL - ISANDO, ISANDO MAIN ON 1, SUZANS PLACE, 315 NZWAI PILLSO STREET, MOEDI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01460,'lng':28.19680}},
{'stp':629994,'address':'629994, EGL - ISANDO, ISANDO MAIN ON 1, REUBENS TAVERN, 934 EXTENSION 2, #, #, IVORY PARK, South Africa','geo':{'lat':-26.00841,'lng':28.17461}},
{'stp':630041,'address':'630041, EGL - ISANDO, ISANDO MAIN ON 1, THOMAS TUCKSHOP, 2267 EXTENSION 4, RABIE RIDGE, #, TEMBISA, South Africa','geo':{'lat':-26.02940,'lng':28.18510}},
{'stp':630062,'address':'630062, EGL - ISANDO, ISANDO MAIN ON 1, JEAN BABYS PLACE, 431 MNONJANENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01850,'lng':28.18940}},
{'stp':630063,'address':'630063, EGL - ISANDO, ISANDO MAIN ON 1, LUCAS S PLACE, 388 UMTHAMBEKA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02570,'lng':28.18830}},
{'stp':631798,'address':'631798, EGL - ISANDO, ISANDO MAIN ON 1, JANETTS PLACE, 271 ETHAFENI SECTION, TEMBISA, JOHANNESBURG, TEMBISA, South Africa','geo':{'lat':-26.02549,'lng':28.21034}},
{'stp':632304,'address':'632304, EGL - ISANDO, ISANDO MAIN ON 1, KEX TAVERN, 443/21UMNONJANENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01900,'lng':28.19100}},
{'stp':632478,'address':'632478, EGL - ISANDO, ISANDO MAIN ON 1, MAKGETA, 16 MUTARE CRESCENT, ESANGWENI, #, TEMBISA, South Africa','geo':{'lat':-26.02489,'lng':28.20184}},
{'stp':636402,'address':'636402, EGL - ISANDO, ISANDO MAIN ON 1, CONTIS PLACE, 98 ENDAYENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01880,'lng':28.19320}},
{'stp':636511,'address':'636511, EGL - ISANDO, ISANDO MAIN ON 1, S9 SHOP, 1632 ENHLANZENI HOSTEL, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.03030,'lng':28.19860}},
{'stp':637133,'address':'637133, EGL - ISANDO, ISANDO MAIN ON 1, PAPAS PLACE, 700 UMFUYANENI SECTION, MZILIKAZI STREET, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01940,'lng':28.19610}},
{'stp':637140,'address':'637140, EGL - ISANDO, ISANDO MAIN ON 1, DALAS PLACE, 998 MTHAMBEKA, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02390,'lng':28.19350}},
{'stp':637154,'address':'637154, EGL - ISANDO, ISANDO MAIN ON 1, LILLIES PLACE, 34 ENDAYENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01890,'lng':28.19140}},
{'stp':637155,'address':'637155, EGL - ISANDO, ISANDO MAIN ON 1, KATJIES PLACE, 137 ESANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02380,'lng':28.19950}},
{'stp':637409,'address':'637409, EGL - ISANDO, ISANDO MAIN ON 1, JABUS PLACE, 281 ESANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02250,'lng':28.20160}},
{'stp':637416,'address':'637416, EGL - ISANDO, ISANDO MAIN ON 1, DAVID TUCKSHOP, 137 ISIVANE SECT, #, #, TEMBISA, South Africa','geo':{'lat':-26.01860,'lng':28.21220}},
{'stp':637429,'address':'637429, EGL - ISANDO, ISANDO MAIN ON 1, MOSHOESHOE\'S PLACE, 238 MOEDI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01330,'lng':28.19450}},
{'stp':637477,'address':'637477, EGL - ISANDO, ISANDO MAIN ON 1, PHIRIS TAVERN, 151 VUSIMUZI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02830,'lng':28.20600}},
{'stp':637646,'address':'637646, EGL - ISANDO, ISANDO MAIN ON 1, CASBA, 76 MOEDI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01550,'lng':28.19860}},
{'stp':637651,'address':'637651, EGL - ISANDO, ISANDO MAIN ON 1, MMANTSELENGS PLACE, 118 ISIVANA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01870,'lng':28.21460}},
{'stp':639471,'address':'639471, EGL - ISANDO, ISANDO MAIN ON 1, MOLATELOS PLACE, 279 LEKANENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01710,'lng':28.20600}},
{'stp':639812,'address':'639812, EGL - ISANDO, ISANDO MAIN ON 1, MADUHAS PLACE, 4215 UMTHAMBEKA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02880,'lng':28.18620}},
{'stp':639830,'address':'639830, EGL - ISANDO, ISANDO MAIN ON 1, SOKHELA INN, W9 ELANZENI, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.03010,'lng':28.19840}},
{'stp':640973,'address':'640973, EGL - ISANDO, ISANDO MAIN ON 1, MASEKOS PLACE, 369 ENTSHONALANGA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01860,'lng':28.18840}},
{'stp':641600,'address':'641600, EGL - ISANDO, ISANDO MAIN ON 1, MATHEKGA S PLACE, HOUSE 23 ROBERT MATSHEKGA DRIVE, KGATLAMPING, #, TEMBISA, South Africa','geo':{'lat':-26.01105,'lng':28.19569}},
{'stp':642143,'address':'642143, EGL - ISANDO, ISANDO MAIN ON 1, ZAMIEMPILO, 16 VUSIMUZI SECTION  16, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02550,'lng':28.20400}},
{'stp':642175,'address':'642175, EGL - ISANDO, ISANDO MAIN ON 1, MOREKOS POOL BAR, 108 IMPANGELE STREET  108, MPHO SECTION, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-26.01351,'lng':28.21593}},
{'stp':646341,'address':'646341, EGL - ISANDO, ISANDO MAIN ON 1, MADUNA\'S TAVERN, 1595 VUSUMUZI SECTION  num 1595, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03384,'lng':28.20768}},
{'stp':646596,'address':'646596, EGL - ISANDO, ISANDO MAIN ON 1, THULI\'S PLACE, 164 UMNONJANENI SECTION, UMNONJANENI SECTION, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01460,'lng':28.19270}},
{'stp':646716,'address':'646716, EGL - ISANDO, ISANDO MAIN ON 1, THANDI\'S TAVERN, 170 KGATLAMPING SECTION, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01330,'lng':28.19780}},
{'stp':646864,'address':'646864, EGL - ISANDO, ISANDO MAIN ON 1, ZIYWA TAVERN, 664 VUSUMUZI SECTION, VUSUMUZI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03270,'lng':28.20280}},
{'stp':646883,'address':'646883, EGL - ISANDO, ISANDO MAIN ON 1, MAPHANGA SUPERMARKET, 737 ENHLANZENI SECTION  num 737, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.03349,'lng':28.20672}},
{'stp':647085,'address':'647085, EGL - ISANDO, ISANDO MAIN ON 1, VINNIE\'S PLACE, 71 EHLANZENI SECTION  num 71, EHLANZENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02670,'lng':28.20160}},
{'stp':647263,'address':'647263, EGL - ISANDO, ISANDO MAIN ON 1, BOBBY\'S TAVERN, 177, EMNONJANENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01480,'lng':28.19429}},
{'stp':647604,'address':'647604, EGL - ISANDO, ISANDO MAIN ON 1, SIPHO\'S PLACE, UMTHAMBEKA SECTION  130, #, #, TEMBISA, South Africa','geo':{'lat':-26.02780,'lng':28.18650}},
{'stp':647879,'address':'647879, EGL - ISANDO, ISANDO MAIN ON 1, MASHABANE INN, 15019 ENHLANZENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.03290,'lng':28.20000}},
{'stp':648024,'address':'648024, EGL - ISANDO, ISANDO MAIN ON 1, REDIRILE LIQUOR STORE, A484 VUSUMUZI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.03360,'lng':28.20780}},
{'stp':649768,'address':'649768, EGL - ISANDO, ISANDO MAIN ON 1, MOCHA O CHELE, 292 EHLANZENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02980,'lng':28.20290}},
{'stp':649938,'address':'649938, EGL - ISANDO, ISANDO MAIN ON 1, EATING HOUSE, STAND NO, PORTION 208 ERF 124 BLOCK B3, EHLANGENI HOSTEL EHLANGENI SECTION, TEMBISA, South Africa','geo':{'lat':-26.02920,'lng':28.19870}},
{'stp':650697,'address':'650697, EGL - ISANDO, ISANDO MAIN ON 1, JOHNNYS PLACE, 849 EXT 2, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00730,'lng':28.17580}},
{'stp':658596,'address':'658596, EGL - ISANDO, ISANDO MAIN ON 1, MC TAVERN, 480 EHLANZENI SECTION, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03020,'lng':28.20390}},
{'stp':658977,'address':'658977, EGL - ISANDO, ISANDO MAIN ON 1, SABELA\'S TUCKSHOP (NTO), 773 UMFUYANENI SECTION, UMFUYANENIN SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02115,'lng':28.19250}},
{'stp':659318,'address':'659318, EGL - ISANDO, ISANDO MAIN ON 1, MPHATLATSANE, 144 ISIVANA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01940,'lng':28.21140}},
{'stp':660736,'address':'660736, EGL - ISANDO, ISANDO MAIN ON 1, KHOLOS TAVERN, 0 1022 VUSIMUZI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.03160,'lng':28.20960}},
{'stp':664738,'address':'664738, EGL - ISANDO, ISANDO MAIN ON 1, BUSIS TUCKSHOP, 523 VUSUMUZI SECTION, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03120,'lng':28.20330}},
{'stp':665731,'address':'665731, EGL - ISANDO, ISANDO MAIN ON 1, THUTHUKA INN, U5 EHLANZENI, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.03017,'lng':28.19840}},
{'stp':666405,'address':'666405, EGL - ISANDO, ISANDO MAIN ON 1, KEDOS SHEBEEN, 115 UMPUYANENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01822,'lng':28.19857}},
{'stp':672209,'address':'672209, EGL - ISANDO, ISANDO MAIN ON 1, BAFOZOS TUCKSHOP, 221 UMFUYANENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02260,'lng':28.19380}},
{'stp':683772,'address':'683772, EGL - ISANDO, ISANDO MAIN ON 1, BUCS SPORTS BAR, 145 LEKANENG SECTION, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01753,'lng':28.20711}},
{'stp':686918,'address':'686918, EGL - ISANDO, ISANDO MAIN ON 1, THWALAS PLACE, 131 VUSUMUZI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02550,'lng':28.20610}},
{'stp':687872,'address':'687872, EGL - ISANDO, ISANDO MAIN ON 1, ZNIKO LOUNGE BAR, STAND NO 28, ENHLANZENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02500,'lng':28.20380}},
{'stp':701037,'address':'701037, EGL - ISANDO, ISANDO MAIN ON 1, HYPER LIQUOR, 693 EMPUNYANENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01960,'lng':28.19650}},
{'stp':704045,'address':'704045, EGL - ISANDO, ISANDO MAIN ON 1, ENHLANZENI TUCKSHOP AND INN, 10 WARD, EHLANZENI, #, TEMBISA, South Africa','geo':{'lat':-26.03019,'lng':28.19841}},
{'stp':726179,'address':'726179, EGL - ISANDO, ISANDO MAIN ON 1, FUNEKA TUCKSHOP, K9 EHLANZENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02981,'lng':28.19943}},
{'stp':771190,'address':'771190, EGL - ISANDO, ISANDO MAIN ON 1, THANA INN, STAND NO, 198 KGATLAMPING SECT, #, TEMBISA, South Africa','geo':{'lat':-26.01414,'lng':28.19856}},
{'stp':774311,'address':'774311, EGL - ISANDO, ISANDO MAIN ON 1, MPHIKELEI S PLACE, 44 STAND NO, VUSI MNGOMEZULU ROAD, ESANGWENI SECTION TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02278,'lng':28.19921}},
{'stp':775867,'address':'775867, EGL - ISANDO, ISANDO MAIN ON 1, NOMVULA S TAVERN, 107 STAND NO, EHLANZANI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.03087,'lng':28.20120}},
{'stp':781600,'address':'781600, EGL - ISANDO, ISANDO MAIN ON 1, SABELAS ENTERTAINMENT, STAND NO, 773 UMFUYANENI EXT, #, TEMBISA, South Africa','geo':{'lat':-26.02122,'lng':28.19243}},
{'stp':785125,'address':'785125, EGL - ISANDO, ISANDO MAIN ON 1, CHAKLAS PUB, 189 STAND NO, ISISVANA SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01709,'lng':28.21065}},
{'stp':792231,'address':'792231, EGL - ISANDO, ISANDO MAIN ON 1, ESANGWENI CAFE, MPILO STREET, ERF 317 ESANGWENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02125,'lng':28.20027}},
{'stp':794441,'address':'794441, EGL - ISANDO, ISANDO MAIN ON 1, BANDA PLACE, 2343 UNITY STR, #, #, TEMBISA, South Africa','geo':{'lat':-26.03257,'lng':28.18406}},
{'stp':794504,'address':'794504, EGL - ISANDO, ISANDO MAIN ON 1, ZM PLACE, 675 STAND NO, UMTHAMBEKA SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02520,'lng':28.19060}},
{'stp':798996,'address':'798996, EGL - ISANDO, ISANDO MAIN ON 1, MOEKETSI AND WONDER PLACE, 516 STAND NO, UMTHAMBEKA SECT, #, TEMBISA, South Africa','geo':{'lat':-26.02642,'lng':28.19012}},
{'stp':315400,'address':'315400, EGL - ISANDO, ISANDO MAIN ON 1, SELEPES PLACE, 1133 STAND NUMBER, ESSELLEN PARK, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02781,'lng':28.23889}},
{'stp':382492,'address':'382492, EGL - ISANDO, ISANDO MAIN ON 1, GG S PLACE, 35 STAND NUMBER, MVELASI ESIZIBA, TOWNSHIP, TEMBISA, South Africa','geo':{'lat':-26.01862,'lng':28.22220}},
{'stp':389012,'address':'389012, EGL - ISANDO, ISANDO MAIN ON 1, MASERU INN 2, 287 STAND NO, IBAXA SECT, #, TEMBISA, South Africa','geo':{'lat':-26.02210,'lng':28.21565}},
{'stp':389017,'address':'389017, EGL - ISANDO, ISANDO MAIN ON 1, MASERU INN 3, 564 STAND NO, #, #, TEMBISA, South Africa','geo':{'lat':-26.00575,'lng':28.24058}},
{'stp':389038,'address':'389038, EGL - ISANDO, ISANDO MAIN ON 1, MASERU INN 1, 707 STAND NO, ISIPHETWENI SECT, #, TEMBISA, South Africa','geo':{'lat':-26.03089,'lng':28.21611}},
{'stp':389067,'address':'389067, EGL - ISANDO, ISANDO MAIN ON 1, MASERU INN 5, 23 SHOP NO, #, #, TEMBISA, South Africa','geo':{'lat':-26.01644,'lng':28.23233}},
{'stp':390875,'address':'390875, EGL - ISANDO, ISANDO MAIN ON 1, DINANA S PLACE, 483 ERF NO, #, #, TEMBISA, South Africa','geo':{'lat':-26.00943,'lng':28.22730}},
{'stp':468606,'address':'468606, EGL - ISANDO, ISANDO MAIN ON 1, TSEPO\'S TAVERN, STAND NO, ESIZIBA SECT, ERF 341, TEMBISA, South Africa','geo':{'lat':-26.01986,'lng':28.21981}},
{'stp':470785,'address':'470785, EGL - ISANDO, ISANDO MAIN ON 1, PHAMBILIS PLACE, STAND NO, 121 EJIYANE SECTION, TEMBISA ERF 121, TEMBISA, South Africa','geo':{'lat':-26.02759,'lng':28.21456}},
{'stp':476098,'address':'476098, EGL - ISANDO, ISANDO MAIN ON 1, COME TOGETHER PLACE, 532 STAND NO, ISITHAME SECTION, TEMBISA ERF 532, TEMBISA, South Africa','geo':{'lat':-26.00620,'lng':28.22379}},
{'stp':478465,'address':'478465, EGL - ISANDO, ISANDO MAIN ON 1, GEORGE M UBISI LIQUOR DISTRIBUTION, 188 STAND NO, NGUNGUNYANE STREET, ISITHAME SECTION, TEMBISA, South Africa','geo':{'lat':-26.00804,'lng':28.22607}},
{'stp':484123,'address':'484123, EGL - ISANDO, ISANDO MAIN ON 1, MCEMBULA LIQUOR WHOLESALE, 197 STAND NO, ENDULWENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01513,'lng':28.22329}},
{'stp':485658,'address':'485658, EGL - ISANDO, ISANDO MAIN ON 1, MATHOKGOSELA, 130 ERF NO, 130 SEDIBENG, #, TEMBISA, South Africa','geo':{'lat':-26.00709,'lng':28.23563}},
{'stp':486770,'address':'486770, EGL - ISANDO, ISANDO MAIN ON 1, MARKIANA TAVERN, 0296 STAND NO, ECLAENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01172,'lng':28.24317}},
{'stp':490071,'address':'490071, EGL - ISANDO, ISANDO MAIN ON 1, RED HORN BOTTLE STORE, 198 STAND NO, ENDULWINI SECT, #, TEMBISA, South Africa','geo':{'lat':-26.01512,'lng':28.22330}},
{'stp':550071,'address':'550071, EGL - ISANDO, ISANDO MAIN ON 1, W N NKOSI TAVERN, 469 ENDULWINI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01410,'lng':28.22120}},
{'stp':550111,'address':'550111, EGL - ISANDO, ISANDO MAIN ON 1, LESIBAS TAVERN, 686 ENDULWINI SECTION,  (Next to Jam Session), #, TEMBISA, South Africa','geo':{'lat':-26.01358,'lng':28.22562}},
{'stp':550166,'address':'550166, EGL - ISANDO, ISANDO MAIN ON 1, MKHIZE V, 55 ENDULWINI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01195,'lng':28.22854}},
{'stp':550458,'address':'550458, EGL - ISANDO, ISANDO MAIN ON 1, MASHABANE TE, 3625 EXT 07, OOKMORE, #, TEMBISA, South Africa','geo':{'lat':-26.00610,'lng':28.24810}},
{'stp':550976,'address':'550976, EGL - ISANDO, ISANDO MAIN ON 1, MAITES TAVERN, 245 ENDULWENI SECTION, TEMBISA, KEMPTON, TEMBISA, South Africa','geo':{'lat':-26.01702,'lng':28.22211}},
{'stp':551114,'address':'551114, EGL - ISANDO, ISANDO MAIN ON 1, NABE NH, 383_ ENDULWINI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01620,'lng':28.22080}},
{'stp':551371,'address':'551371, EGL - ISANDO, ISANDO MAIN ON 1, MKIZE MA, 46 ELIDINGA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01190,'lng':28.22860}},
{'stp':551893,'address':'551893, EGL - ISANDO, ISANDO MAIN ON 1, MAHLALELA MA, 3580 EXT 07, OOKMORE TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00705,'lng':28.24741}},
{'stp':552163,'address':'552163, EGL - ISANDO, ISANDO MAIN ON 1, MASHAVA ME, 237 XUBENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00590,'lng':28.24300}},
{'stp':552480,'address':'552480, EGL - ISANDO, ISANDO MAIN ON 1, JAM SESSION PLACE, STAND 110, SECTION 886, ISIZIBA SEKSIE, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01720,'lng':28.22300}},
{'stp':552801,'address':'552801, EGL - ISANDO, ISANDO MAIN ON 1, MZINYANE LM, 86 NDULWINI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01583,'lng':28.22389}},
{'stp':552913,'address':'552913, EGL - ISANDO, ISANDO MAIN ON 1, CONCORD TAVERN, 344 JIYANE SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02895,'lng':28.21320}},
{'stp':553409,'address':'553409, EGL - ISANDO, ISANDO MAIN ON 1, MAKAMA LH, 131 GQAGPA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01290,'lng':28.22631}},
{'stp':560198,'address':'560198, EGL - ISANDO, ISANDO MAIN ON 1, KEMENI FA, 85 WELAMLAMBO SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02390,'lng':28.20790}},
{'stp':560209,'address':'560209, EGL - ISANDO, ISANDO MAIN ON 1, MOHALE TK, 859 SEDIBENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00480,'lng':28.23820}},
{'stp':560490,'address':'560490, EGL - ISANDO, ISANDO MAIN ON 1, SOXA\'S  PLACE, 30 IBAZELO SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00480,'lng':28.21840}},
{'stp':561137,'address':'561137, EGL - ISANDO, ISANDO MAIN ON 1, DAVID VELAPI VUMA, 19 XUBENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00650,'lng':28.24190}},
{'stp':580491,'address':'580491, EGL - ISANDO, ISANDO MAIN ON 1, SIMELANE TAVERN, 3419 OAKMOOR, EXT 7, #, TEMBISA, South Africa','geo':{'lat':-26.00440,'lng':28.24700}},
{'stp':580726,'address':'580726, EGL - ISANDO, ISANDO MAIN ON 1, BILA P, 212 IBAXA SECTION, TEMBISA, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.02091,'lng':28.21587}},
{'stp':581155,'address':'581155, EGL - ISANDO, ISANDO MAIN ON 1, MOKOENA NL, 137 LIDINGA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01437,'lng':28.22745}},
{'stp':581239,'address':'581239, EGL - ISANDO, ISANDO MAIN ON 1, LANGLEY RN, 663 ENDULWENI STREET, #, #, TEMBISA, South Africa','geo':{'lat':-26.01297,'lng':28.22347}},
{'stp':602876,'address':'602876, EGL - ISANDO, ISANDO MAIN ON 1, SIFUNDA MJJ (ExFR), 63 MOYENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01620,'lng':28.23150}},
{'stp':602894,'address':'602894, EGL - ISANDO, ISANDO MAIN ON 1, MOGALE MJ, 130 ETHAFENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02528,'lng':28.21277}},
{'stp':602941,'address':'602941, EGL - ISANDO, ISANDO MAIN ON 1, DLAMINI PH, 6000 OAKMOOR, EXTENSION 7, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00540,'lng':28.24770}},
{'stp':603969,'address':'603969, EGL - ISANDO, ISANDO MAIN ON 1, MNGUNI MA, 326 WELAMLAMBO, #, #, TEMBISA, South Africa','geo':{'lat':-26.02240,'lng':28.21110}},
{'stp':604053,'address':'604053, EGL - ISANDO, ISANDO MAIN ON 1, MUKHAWA S, 95 IBAXA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02020,'lng':28.21690}},
{'stp':604525,'address':'604525, EGL - ISANDO, ISANDO MAIN ON 1, MNGUNI TR, 740 NDULWINI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01020,'lng':28.21950}},
{'stp':604735,'address':'604735, EGL - ISANDO, ISANDO MAIN ON 1, LANGA MM, 217 IBAXA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02140,'lng':28.21600}},
{'stp':605467,'address':'605467, EGL - ISANDO, ISANDO MAIN ON 1, MANANA ME, 639 EMQANTSA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01220,'lng':28.24060}},
{'stp':605880,'address':'605880, EGL - ISANDO, ISANDO MAIN ON 1, KWA NONTIBEKO, NAMIBIA STREET, 460/28 INXIWENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02360,'lng':28.21680}},
{'stp':606022,'address':'606022, EGL - ISANDO, ISANDO MAIN ON 1, MOLEFE SA, 83 SEDIBENG SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00860,'lng':28.23490}},
{'stp':606915,'address':'606915, EGL - ISANDO, ISANDO MAIN ON 1, MAHLANGU KK, 708 ENDULWINI SECTION, KEMPTON PARK, #, TEMBISA, South Africa','geo':{'lat':-26.01220,'lng':28.22320}},
{'stp':611997,'address':'611997, EGL - ISANDO, ISANDO MAIN ON 1, TEMBA\'S SHEBEEN (NTO), 204 ECALENI SECTION, TEMBISA, Johannesburg, TEMBISA, South Africa','geo':{'lat':-26.00760,'lng':28.14563}},
{'stp':624223,'address':'624223, EGL - ISANDO, ISANDO MAIN ON 1, RYDER (NTO), 190 SEAGULL STR, MPHO, TEMBISA, Johannesburg, TEMBISA, South Africa','geo':{'lat':-26.01616,'lng':28.21301}},
{'stp':626837,'address':'626837, EGL - ISANDO, ISANDO MAIN ON 1, DLAMINI JL, 140 BAXA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02100,'lng':28.21680}},
{'stp':628063,'address':'628063, EGL - ISANDO, ISANDO MAIN ON 1, JAKES PLACE, 3485 CHIKUDU STREET, OOMOUR EXTENTION 7, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00788,'lng':28.24676}},
{'stp':628408,'address':'628408, EGL - ISANDO, ISANDO MAIN ON 1, LIZZYS PLACE, 459 IGQAGQA  SECTION, EXTENTION 1, #, TEMBISA, South Africa','geo':{'lat':-26.01007,'lng':28.22633}},
{'stp':628706,'address':'628706, EGL - ISANDO, ISANDO MAIN ON 1, VICTORS TAVERN, 600 BRIAN MAZIBUKO STREET, SEDIBENG SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.00580,'lng':28.24190}},
{'stp':628950,'address':'628950, EGL - ISANDO, ISANDO MAIN ON 1, VICTORS PLACE, 65 SERI LEON, ETHAFENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02370,'lng':28.21310}},
{'stp':629099,'address':'629099, EGL - ISANDO, ISANDO MAIN ON 1, JACKIES PLACE, 154 IBAXA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02090,'lng':28.21520}},
{'stp':629451,'address':'629451, EGL - ISANDO, ISANDO MAIN ON 1, DA JUKAS PLACE, 207 INXIWENI SECTION, MBELE STREET, #, TEMBISA, South Africa','geo':{'lat':-26.02370,'lng':28.22040}},
{'stp':629607,'address':'629607, EGL - ISANDO, ISANDO MAIN ON 1, MAGOGOS PLACE, 154 JIYANA STREET, #, #, TEMBISA, South Africa','geo':{'lat':-26.02770,'lng':28.21650}},
{'stp':631233,'address':'631233, EGL - ISANDO, ISANDO MAIN ON 1, THABETHE ZIYAWA INN TAVERN, 423 XUBENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00489,'lng':28.24423}},
{'stp':631737,'address':'631737, EGL - ISANDO, ISANDO MAIN ON 1, MKHULUS INN, 159 ENXIWENI SECTION, TEMBISA, KEMPTONPARK, TEMBISA, South Africa','geo':{'lat':-26.02500,'lng':28.21890}},
{'stp':631997,'address':'631997, EGL - ISANDO, ISANDO MAIN ON 1, HARRYS PLACE, 3433 EXTENSION 07, OAKMORE, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00430,'lng':28.24690}},
{'stp':634518,'address':'634518, EGL - ISANDO, ISANDO MAIN ON 1, FIRES PLACE, 187 ELIDINGA SECTION, KEMPTON PARK, JOHANNESBURG, TEMBISA, South Africa','geo':{'lat':-26.01370,'lng':28.22950}},
{'stp':634846,'address':'634846, EGL - ISANDO, ISANDO MAIN ON 1, MNB TUCKSHOP, 760 ENDULWINI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01000,'lng':28.21860}},
{'stp':634915,'address':'634915, EGL - ISANDO, ISANDO MAIN ON 1, SUNNYBOYS PLACE, 297 EMGANSTA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00789,'lng':28.23284}},
{'stp':635683,'address':'635683, EGL - ISANDO, ISANDO MAIN ON 1, MAPHUTHA, 99 ETHAFENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02500,'lng':28.21300}},
{'stp':635766,'address':'635766, EGL - ISANDO, ISANDO MAIN ON 1, STATUS, 30 ENXUBENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00850,'lng':28.24350}},
{'stp':636720,'address':'636720, EGL - ISANDO, ISANDO MAIN ON 1, WINTER\'S INN, 133 JIYANE SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02740,'lng':28.21290}},
{'stp':637174,'address':'637174, EGL - ISANDO, ISANDO MAIN ON 1, SUCCESS TAVERN, 846 ISITHANE SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00460,'lng':28.22400}},
{'stp':637195,'address':'637195, EGL - ISANDO, ISANDO MAIN ON 1, THULANI\'S PLACE, 36 WELAMLAMBO SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02320,'lng':28.20650}},
{'stp':637595,'address':'637595, EGL - ISANDO, ISANDO MAIN ON 1, LYDIA\'S PLACE, 286 IGQAGA SECTION, EXT 1, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00890,'lng':28.22350}},
{'stp':638125,'address':'638125, EGL - ISANDO, ISANDO MAIN ON 1, B B Q BOTTLE STORE, 0009 STAND NO, BRIAN MAZIBUKO DRIVE, ECALENI SECTION TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01026,'lng':28.24215}},
{'stp':638158,'address':'638158, EGL - ISANDO, ISANDO MAIN ON 1, SIZABANTU TUCKSHOP INN, 820 MADELAKUFA 2, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01668,'lng':28.22572}},
{'stp':638287,'address':'638287, EGL - ISANDO, ISANDO MAIN ON 1, MASIZE INN, 343/53 ISIZIBA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01821,'lng':28.22017}},
{'stp':638294,'address':'638294, EGL - ISANDO, ISANDO MAIN ON 1, FEZEKAS PLACE, 343/55 ISIZIBENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01790,'lng':28.22050}},
{'stp':638549,'address':'638549, EGL - ISANDO, ISANDO MAIN ON 1, SABELOS PLACE, 465 ETHAFENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02600,'lng':28.20990}},
{'stp':638554,'address':'638554, EGL - ISANDO, ISANDO MAIN ON 1, HLEHLES PLACE, 343/95 ISIZIBA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01920,'lng':28.22040}},
{'stp':638709,'address':'638709, EGL - ISANDO, ISANDO MAIN ON 1, STHESHS PLACE, 157 WELAMLAMBO SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02220,'lng':28.20720}},
{'stp':638864,'address':'638864, EGL - ISANDO, ISANDO MAIN ON 1, ZACKS BAR, 294 JIYANI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02730,'lng':28.21830}},
{'stp':639170,'address':'639170, EGL - ISANDO, ISANDO MAIN ON 1, DICEMBERS PLACE, 343/101 ISIZIBA, SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01930,'lng':28.22090}},
{'stp':639744,'address':'639744, EGL - ISANDO, ISANDO MAIN ON 1, ZAMALEK INN, 433 IQAQA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01151,'lng':28.22197}},
{'stp':640296,'address':'640296, EGL - ISANDO, ISANDO MAIN ON 1, LINDIS PLACE, 597 ENDULWINI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01619,'lng':28.21963}},
{'stp':640816,'address':'640816, EGL - ISANDO, ISANDO MAIN ON 1, WAY INN, 219 SEDEBENG SECTION, KEMPTON PARK, #, TEMBISA, South Africa','geo':{'lat':-26.00510,'lng':28.23730}},
{'stp':640858,'address':'640858, EGL - ISANDO, ISANDO MAIN ON 1, SADIS TAVERN, 273 SEDIBENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00580,'lng':28.23720}},
{'stp':641137,'address':'641137, EGL - ISANDO, ISANDO MAIN ON 1, UBISIS PLACE, 13 XUBENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00660,'lng':28.24190}},
{'stp':641541,'address':'641541, EGL - ISANDO, ISANDO MAIN ON 1, PODESTAS PLACE, 75 STAND NO, EMIFIHLWENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.02150,'lng':28.22440}},
{'stp':642012,'address':'642012, EGL - ISANDO, ISANDO MAIN ON 1, LUCKY\'S PLACE, 76 EXUBENI SECTION num 76, TEMBISA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00760,'lng':28.24280}},
{'stp':642381,'address':'642381, EGL - ISANDO, ISANDO MAIN ON 1, LUCKYS PLACE, 472 SEDIBENG SECTION  num 472, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00780,'lng':28.24050}},
{'stp':642382,'address':'642382, EGL - ISANDO, ISANDO MAIN ON 1, SEKGOBELA\'S TUCKSHOP,  505 IGQAGQA SECTION  num 505, -, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00980,'lng':28.22420}},
{'stp':646932,'address':'646932, EGL - ISANDO, ISANDO MAIN ON 1, YARONA BUY AND BRAAI, 340 ENXIWENI SECTION  num 340, ENXIWENI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02150,'lng':28.22350}},
{'stp':647653,'address':'647653, EGL - ISANDO, ISANDO MAIN ON 1, ZITHABISENI INN, 874   SEAPARANKWE STREET, ISITHAMA SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.00430,'lng':28.22470}},
{'stp':650639,'address':'650639, EGL - ISANDO, ISANDO MAIN ON 1, BROTHERS, 224 IBAXA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02180,'lng':28.21700}},
{'stp':661647,'address':'661647, EGL - ISANDO, ISANDO MAIN ON 1, RADEBE H T, 300 JIYANE SECTION, #, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02797,'lng':28.21762}},
{'stp':664824,'address':'664824, EGL - ISANDO, ISANDO MAIN ON 1, CORNER PLACE, 272 EXUBENI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.00750,'lng':28.24500}},
{'stp':667298,'address':'667298, EGL - ISANDO, ISANDO MAIN ON 1, FUNIS TUCKSHOP AND INN, 616 ISITHAMA SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00670,'lng':28.22140}},
{'stp':684546,'address':'684546, EGL - ISANDO, ISANDO MAIN ON 1, MULAMBO TUCKSHOP, 191IGQAGQA, #, #, TEMBISA, South Africa','geo':{'lat':-26.01381,'lng':28.22648}},
{'stp':687850,'address':'687850, EGL - ISANDO, ISANDO MAIN ON 1, SPANZAS TAVERN, 384 ENXIWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02176,'lng':28.22246}},
{'stp':689436,'address':'689436, EGL - ISANDO, ISANDO MAIN ON 1, DUMISANI FIRST TRADING ENTERPRISE, 158 EXUBENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00540,'lng':28.24660}},
{'stp':729273,'address':'729273, EGL - ISANDO, ISANDO MAIN ON 1, 18 TAVERN WIPETANI, 18 ISITHAME SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01146,'lng':28.22835}},
{'stp':738104,'address':'738104, EGL - ISANDO, ISANDO MAIN ON 1, LIQUOR CITY TEMBI MALL, UNIT 2 TEMBISA MALL, CNR ANDREW MAPHETO AND GEORGE N STR, #, TEMBISA, South Africa','geo':{'lat':-26.00980,'lng':28.22050}},
{'stp':754833,'address':'754833, EGL - ISANDO, ISANDO MAIN ON 1, LOVERS INN, STAND NO, ERF 137, JIYANE SECTION, TEMBISA, South Africa','geo':{'lat':-26.02790,'lng':28.21360}},
{'stp':756168,'address':'756168, EGL - ISANDO, ISANDO MAIN ON 1, HOLLYWOOD TAVERN, 143 STAND NO, ENDULWINI SECTION TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01556,'lng':28.22339}},
{'stp':762448,'address':'762448, EGL - ISANDO, ISANDO MAIN ON 1, MTHETWAS PLACE, STAND NO, ERF 195, IQAQA SECTION, TEMBISA, South Africa','geo':{'lat':-26.01370,'lng':28.22710}},
{'stp':762635,'address':'762635, EGL - ISANDO, ISANDO MAIN ON 1, SEDIBENG WHOLESALERS, SEDIBENG SECTION, ERF 99, #, TEMBISA, South Africa','geo':{'lat':-26.00800,'lng':28.23260}},
{'stp':766133,'address':'766133, EGL - ISANDO, ISANDO MAIN ON 1, PARK AND BRAAI BUTCHERY, STAND NO, ERF 629 SHOP 35, MQANTSA SECTION, TEMBISA, South Africa','geo':{'lat':-26.01220,'lng':28.24060}},
{'stp':778260,'address':'778260, EGL - ISANDO, ISANDO MAIN ON 1, LILIBA TAVERN, 4910 STAND NO, LILIBA SECTION, EXTENSION 10, TEMBISA, South Africa','geo':{'lat':-26.02216,'lng':28.23107}},
{'stp':783829,'address':'783829, EGL - ISANDO, ISANDO MAIN ON 1, EMASOFENI INN (NTO), 289 ZIKALALA ST ENDULWINI SECTION, TEMBISA 1632, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01456,'lng':28.22239}},
{'stp':794444,'address':'794444, EGL - ISANDO, ISANDO MAIN ON 1, MAGDELINES PLACE, 495 STAND NO, #, #, TEMBISA, South Africa','geo':{'lat':-26.01345,'lng':28.22153}},
{'stp':798169,'address':'798169, EGL - ISANDO, ISANDO MAIN ON 1, EPITITI, 467 STAND NO, ECALENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01065,'lng':28.24354}},
{'stp':311900,'address':'311900, EGL - ISANDO, ISANDO MAIN ON 1, PHUTI S PLACE, 1729 STAND NO, 16 JUNE STREET, IVORY PARK EXT 02, IVORY PARK, South Africa','geo':{'lat':-26.00955,'lng':28.18262}},
{'stp':312230,'address':'312230, EGL - ISANDO, ISANDO MAIN ON 1, RAMETSI TAVERN, 8 ZONE, WINNIE MANDELA SECT, #, IVORY PARK, South Africa','geo':{'lat':-25.97932,'lng':28.21711}},
{'stp':317159,'address':'317159, EGL - ISANDO, ISANDO MAIN ON 1, M AND M PALACE, 279 STAND NO, STREENBOK STREET, #, IVORY PARK, South Africa','geo':{'lat':-25.96971,'lng':28.21240}},
{'stp':389082,'address':'389082, EGL - ISANDO, ISANDO MAIN ON 1, PRETTYS PLACE, 9342 STAND, EXT 24, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.98193,'lng':28.22754}},
{'stp':389847,'address':'389847, EGL - ISANDO, ISANDO MAIN ON 1, MADODA TAVERN, 8750 STAND NO, #, #, TEMBISA, South Africa','geo':{'lat':-25.97733,'lng':28.21936}},
{'stp':392175,'address':'392175, EGL - ISANDO, ISANDO MAIN ON 1, MABOTENG PLACE, 12807 STAND NO, LINDOKUHLE, IVORY PARK, TEMBISA, South Africa','geo':{'lat':-25.99711,'lng':28.19238}},
{'stp':464762,'address':'464762, EGL - ISANDO, ISANDO MAIN ON 1, THEMBAS INN, 6098 STREET NUMBER, WINNIE MANDELA ZONE 5, ERF 06098, IVORY PARK, South Africa','geo':{'lat':-25.97990,'lng':28.22832}},
{'stp':470641,'address':'470641, EGL - ISANDO, ISANDO MAIN ON 1, BIG JONI\'S PLACE, STAND NO, EXT  24 TEMBISA, ERF 00000008458, IVORY PARK, South Africa','geo':{'lat':-25.97808,'lng':28.21776}},
{'stp':471133,'address':'471133, EGL - ISANDO, ISANDO MAIN ON 1, MPHELAS PLACE, 1711 TWELAPELE STREET, EXT 05, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97336,'lng':28.21859}},
{'stp':471297,'address':'471297, EGL - ISANDO, ISANDO MAIN ON 1, ROOTS GRILL AND RESTAURANT TEMBISA, STAND NO, ERF 3877 AND 3878 CNR OLIFANTSFONTEIN, ALGERIA ROAD, TSHWELEPELE, IVORY PARK, South Africa','geo':{'lat':-25.96427,'lng':28.21263}},
{'stp':471766,'address':'471766, EGL - ISANDO, ISANDO MAIN ON 1, MOJELAS TAVERN, 2007 STAND NO, TSHWELOPELE EXT 5, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97291,'lng':28.21590}},
{'stp':474037,'address':'474037, EGL - ISANDO, ISANDO MAIN ON 1, KAALFONTEIN LIQUOR STORE, 3116 STAND NO, SHOP B1 ARCHERFISH DRIVE KAALFONTEIN, EXT 6 EBONY PARK, IVORY PARK, South Africa','geo':{'lat':-25.98864,'lng':28.17410}},
{'stp':481812,'address':'481812, EGL - ISANDO, ISANDO MAIN ON 1, ELIAS TAVERN, 2712 STAND NUMBER, TSWELAPELA, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97518,'lng':28.22474}},
{'stp':485209,'address':'485209, EGL - ISANDO, ISANDO MAIN ON 1, STRESS FREE, 9880 STAND NO, WINNIE MANDELA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.98611,'lng':28.22141}},
{'stp':487313,'address':'487313, EGL - ISANDO, ISANDO MAIN ON 1, HR TAVERN, 9733 STAND NUMBER, EXT 24, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.98625,'lng':28.21982}},
{'stp':487478,'address':'487478, EGL - ISANDO, ISANDO MAIN ON 1, DIPHORORO TAVERN, 10450 ERF, WINNE, MANDELA SECTION,ZONE 4, #, IVORY PARK, South Africa','geo':{'lat':-25.99057,'lng':28.22378}},
{'stp':488333,'address':'488333, EGL - ISANDO, ISANDO MAIN ON 1, MKHABELAS PLACE, 10692 STAND NO, EXT 24, #, IVORY PARK, South Africa','geo':{'lat':-25.98193,'lng':28.22308}},
{'stp':494842,'address':'494842, EGL - ISANDO, ISANDO MAIN ON 1, BANANA TAVERN, 13361 STAND NO, 41 RUNGULANI STREET, LVORY PARK EXT. 12, IVORY PARK, South Africa','geo':{'lat':-25.98325,'lng':28.20058}},
{'stp':543316,'address':'543316, EGL - ISANDO, ISANDO MAIN ON 1, CROWN PALACE TAVERN, 206 ERF, TSWELOPELE SECT, #, IVORY PARK, South Africa','geo':{'lat':-25.96872,'lng':28.21264}},
{'stp':550133,'address':'550133, EGL - ISANDO, ISANDO MAIN ON 1, BMS TAVERN, 1275 ZONE 10 WINNIE MANDELA, TEMBISA (next to zone 11), #, IVORY PARK, South Africa','geo':{'lat':-25.97060,'lng':28.22020}},
{'stp':550689,'address':'550689, EGL - ISANDO, ISANDO MAIN ON 1, SIKHETHAN THOMAS MATHEBULA, 9240 WINNIE MANDELA, ZONE 11, #, TEMBISA, South Africa','geo':{'lat':-25.98577,'lng':28.21450}},
{'stp':550853,'address':'550853, EGL - ISANDO, ISANDO MAIN ON 1, NGOEPE MS, 14625 IVORY PARK, EXT 13, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.97980,'lng':28.21040}},
{'stp':550880,'address':'550880, EGL - ISANDO, ISANDO MAIN ON 1, MKHONTO\'S TAVERN, STAND 241, ISITHAME SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.00900,'lng':28.22660}},
{'stp':551839,'address':'551839, EGL - ISANDO, ISANDO MAIN ON 1, IMBIZO SHISANYAMA, ERF 2975 (ERFVEN 1033,1034 & 1035), THEKWANE CRESCENT, EBONY PARK EXT 1, IVORY PARK, South Africa','geo':{'lat':-26.00310,'lng':28.17880}},
{'stp':553599,'address':'553599, EGL - ISANDO, ISANDO MAIN ON 1, ZET\'S TAVERN, 13184 JIKELEZA STREET, IVORY PARK, RANDBURG, IVORY PARK, South Africa','geo':{'lat':-25.98226,'lng':28.20314}},
{'stp':560258,'address':'560258, EGL - ISANDO, ISANDO MAIN ON 1, NOBINJANE Z, 15152 IVORY PARK, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.98080,'lng':28.21050}},
{'stp':560510,'address':'560510, EGL - ISANDO, ISANDO MAIN ON 1, TSHIPAPE K, 8866 ZONE 8, WINNIE MANDELA, #, IVORY PARK, South Africa','geo':{'lat':-25.97820,'lng':28.22080}},
{'stp':560581,'address':'560581, EGL - ISANDO, ISANDO MAIN ON 1, PAULS TAVERN, NO 655 ZONE 12, WINNIE MANDELA, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.96798,'lng':28.21799}},
{'stp':561078,'address':'561078, EGL - ISANDO, ISANDO MAIN ON 1, NGOLI ELIZABETH KHANYILE, 331 MOSES KOTANE ROAD, UMNONJANENI SECTION, KEMPTON PARK, TEMBISA, South Africa','geo':{'lat':-26.01700,'lng':28.19040}},
{'stp':561187,'address':'561187, EGL - ISANDO, ISANDO MAIN ON 1, MMA TSHEPO PLACE, 15125 IVORY PARK, 29 PELEPELE STREET, EXTENSION 13, IVORY PARK, South Africa','geo':{'lat':-25.97970,'lng':28.21070}},
{'stp':561260,'address':'561260, EGL - ISANDO, ISANDO MAIN ON 1, CHABANGU MB, 1926 ZONE 09, WINNIE MANDELA, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97330,'lng':28.21551}},
{'stp':580520,'address':'580520, EGL - ISANDO, ISANDO MAIN ON 1, MOYANA R, 14466 IVORY PARK, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.97940,'lng':28.20570}},
{'stp':580621,'address':'580621, EGL - ISANDO, ISANDO MAIN ON 1, MOTAU RD, 778 MOAKENG SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.99170,'lng':28.22250}},
{'stp':602878,'address':'602878, EGL - ISANDO, ISANDO MAIN ON 1, DIBAKWANE MS, 8533 ZONE 8 WINNIE MANDELA, TEMBIA, #, IVORY PARK, South Africa','geo':{'lat':-25.97960,'lng':28.21730}},
{'stp':603419,'address':'603419, EGL - ISANDO, ISANDO MAIN ON 1, KLAAS TS, 9062 ZONE 11 WINNIE MANDELA, #, #, TEMBISA, South Africa','geo':{'lat':-25.98258,'lng':28.21272}},
{'stp':604447,'address':'604447, EGL - ISANDO, ISANDO MAIN ON 1, MOJELAS TAVERN, 15244 IVORY PARK EXT 2, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.98100,'lng':28.20850}},
{'stp':605491,'address':'605491, EGL - ISANDO, ISANDO MAIN ON 1, SIBANDE MM, THANDANANI TAVERN, 15239 IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.98140,'lng':28.20850}},
{'stp':606024,'address':'606024, EGL - ISANDO, ISANDO MAIN ON 1, TOMYS PLACE, NGCOBO STREET, 220 EMOYENI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.01270,'lng':28.23140}},
{'stp':607056,'address':'607056, EGL - ISANDO, ISANDO MAIN ON 1, MNISI NG, 11443 THOLANE, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.98782,'lng':28.20046}},
{'stp':625012,'address':'625012, EGL - ISANDO, ISANDO MAIN ON 1, MODIBEDI TUCKSHOP, 15508 IVORY PARK, EXT 13, #, IVORY PARK, South Africa','geo':{'lat':-25.98010,'lng':28.19904}},
{'stp':625013,'address':'625013, EGL - ISANDO, ISANDO MAIN ON 1, BONNYS TAVERN, 15310 IVORY PARK EXT 3,  TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.97890,'lng':28.20060}},
{'stp':626603,'address':'626603, EGL - ISANDO, ISANDO MAIN ON 1, MANYIKE\'S LIQ WHOLESALERS, 14547 IVORY PARK, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.97910,'lng':28.20640}},
{'stp':627318,'address':'627318, EGL - ISANDO, ISANDO MAIN ON 1, MONTHOS PLACE, 14447 IVORY PARK, IVORY PARK EXT 13, #, IVORY PARK, South Africa','geo':{'lat':-25.97804,'lng':28.20502}},
{'stp':627327,'address':'627327, EGL - ISANDO, ISANDO MAIN ON 1, DENARE, 2159 NDLOVU STREET, IVORY PARK, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-26.01340,'lng':28.17790}},
{'stp':627352,'address':'627352, EGL - ISANDO, ISANDO MAIN ON 1, SUTHUS PLACE, 14793 THULANE SECTION, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.97670,'lng':28.20110}},
{'stp':627732,'address':'627732, EGL - ISANDO, ISANDO MAIN ON 1, ARONE\'S PLACE, 11817 MOPEDI STREET, IVORY PARK, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.98898,'lng':28.19892}},
{'stp':628061,'address':'628061, EGL - ISANDO, ISANDO MAIN ON 1, MA AFRICA, 3 15225 EXTENTION, #, #, IVORY PARK, South Africa','geo':{'lat':-25.98120,'lng':28.21000}},
{'stp':628065,'address':'628065, EGL - ISANDO, ISANDO MAIN ON 1, MASHASHANES TAVERN, 1762 WINNIE MANDELA, ZONE 12, #, IVORY PARK, South Africa','geo':{'lat':-25.97330,'lng':28.21470}},
{'stp':628066,'address':'628066, EGL - ISANDO, ISANDO MAIN ON 1, SECHABA G, 8599 WINNIE MANDELA, EXTENTION 24, ZONE 8, IVORY PARK, South Africa','geo':{'lat':-25.97940,'lng':28.21550}},
{'stp':628067,'address':'628067, EGL - ISANDO, ISANDO MAIN ON 1, THIBO\'S TAVERN, 5310 MAFELA  DAUNYE, EXTENTION 2, #, IVORY PARK, South Africa','geo':{'lat':-26.01170,'lng':28.18380}},
{'stp':628071,'address':'628071, EGL - ISANDO, ISANDO MAIN ON 1, THOKOS PLACE, 3076 STAND NO, BLOCK D, DUDUZA, IVORY PARK, South Africa','geo':{'lat':-25.97840,'lng':28.20948}},
{'stp':628072,'address':'628072, EGL - ISANDO, ISANDO MAIN ON 1, SIZANANI TAVERN, 14922 MVELASE, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.97780,'lng':28.20260}},
{'stp':628074,'address':'628074, EGL - ISANDO, ISANDO MAIN ON 1, SHIKOBELA TAVERN, 1436 WINNIE MANDELA, ZONE 10, TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.97233,'lng':28.22343}},
{'stp':628410,'address':'628410, EGL - ISANDO, ISANDO MAIN ON 1, THULIS PLACE, 2620 JACKSON STREET, EXTENTION 2, #, IVORY PARK, South Africa','geo':{'lat':-26.01560,'lng':28.18040}},
{'stp':628411,'address':'628411, EGL - ISANDO, ISANDO MAIN ON 1, NYAMA KING, 14606 EXTENTION 13, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.98105,'lng':28.21137}},
{'stp':628413,'address':'628413, EGL - ISANDO, ISANDO MAIN ON 1, GENTS PLACE, 2279 STAND NO, IVORY PARK, EXTENTION 2, IVORY PARK, South Africa','geo':{'lat':-26.01350,'lng':28.17800}},
{'stp':629078,'address':'629078, EGL - ISANDO, ISANDO MAIN ON 1, MOTSEO\'S PLACE, 13162 EXTENSION 12, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.98295,'lng':28.20360}},
{'stp':629250,'address':'629250, EGL - ISANDO, ISANDO MAIN ON 1, TM TAVERN, 8203 WINNIE MANDELA, TEMBISA ZONE 8, #, IVORY PARK, South Africa','geo':{'lat':-25.97750,'lng':28.21530}},
{'stp':629537,'address':'629537, EGL - ISANDO, ISANDO MAIN ON 1, THEMBAS PLACE, 14824 EXTENSION13, #, #, IVORY PARK, South Africa','geo':{'lat':-25.97840,'lng':28.20070}},
{'stp':630111,'address':'630111, EGL - ISANDO, ISANDO MAIN ON 1, JAMES PLACE, 1342 ZONE 4, EXTENSION 23 WINNIE MANDELA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.98330,'lng':28.22230}},
{'stp':630115,'address':'630115, EGL - ISANDO, ISANDO MAIN ON 1, MAM SEXY PLACE, 2730 ZONE 10, WINNIE MANDELA, #, IVORY PARK, South Africa','geo':{'lat':-25.97320,'lng':28.22460}},
{'stp':630441,'address':'630441, EGL - ISANDO, ISANDO MAIN ON 1, NTSIKIS PLACE, 5706 EXTENSION 21, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.00865,'lng':28.18573}},
{'stp':631170,'address':'631170, EGL - ISANDO, ISANDO MAIN ON 1, NEOS PLACE, 273 WINNIE MANDELA  (zone 6), TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.97830,'lng':28.22200}},
{'stp':632030,'address':'632030, EGL - ISANDO, ISANDO MAIN ON 1, WILD WATERS, 15465 ERF NO, 19 LIMPOPO DRIVE, IVORY PARK EXTENSION 13, IVORY PARK, South Africa','geo':{'lat':-25.98094,'lng':28.19842}},
{'stp':632295,'address':'632295, EGL - ISANDO, ISANDO MAIN ON 1, FLORAHS PLACE, 2167 JAKALAS STREET, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-26.01350,'lng':28.17680}},
{'stp':634753,'address':'634753, EGL - ISANDO, ISANDO MAIN ON 1, MABUZA\'S INN, 11532 MOLEPI STREET, IVORY PARK, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98827,'lng':28.20048}},
{'stp':636845,'address':'636845, EGL - ISANDO, ISANDO MAIN ON 1, RIDER\'S TAVERN, 1224 WINNIE MANDELA, ZONE 10, #, IVORY PARK, South Africa','geo':{'lat':-25.97140,'lng':28.21930}},
{'stp':637160,'address':'637160, EGL - ISANDO, ISANDO MAIN ON 1, MZAKE\'S PLACE, 15121 IVORY PARK, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.97930,'lng':28.21050}},
{'stp':637161,'address':'637161, EGL - ISANDO, ISANDO MAIN ON 1, ROSES SHEBEEN, 8490 ZONE 8, WINNIE MANDELA PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.97870,'lng':28.21760}},
{'stp':637333,'address':'637333, EGL - ISANDO, ISANDO MAIN ON 1, MHLONGOS TAVERN, 1098 WINNIE MANDELA, TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.97100,'lng':28.21740}},
{'stp':637549,'address':'637549, EGL - ISANDO, ISANDO MAIN ON 1, NTOMBIS PLACE, 1394 WINNIE MANDELA, ZONE 10, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97180,'lng':28.22130}},
{'stp':637685,'address':'637685, EGL - ISANDO, ISANDO MAIN ON 1, ANNA\'S SHEBEEN, 939 WINNIE MANDELA, ZONE 10, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.96980,'lng':28.21980}},
{'stp':637968,'address':'637968, EGL - ISANDO, ISANDO MAIN ON 1, ANDILE\'S PLACE, 15277 IVORY PARK, EXTENSION 3, IVORY PARK, IVORY PARK, South Africa','geo':{'lat':-25.97910,'lng':28.20340}},
{'stp':638049,'address':'638049, EGL - ISANDO, ISANDO MAIN ON 1, B AND J TAVERN, 1714 EXT 4, KANANA SECTION, RABIE RIDGE, TEMBISA, South Africa','geo':{'lat':-26.02530,'lng':28.17690}},
{'stp':638830,'address':'638830, EGL - ISANDO, ISANDO MAIN ON 1, VHAVHA VUKUZENZELE, 13307 IVORY PARK, EXT 12, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98399,'lng':28.19845}},
{'stp':639055,'address':'639055, EGL - ISANDO, ISANDO MAIN ON 1, RACHEL\'S TAVERN, 11122 IVORY PARK, TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.99210,'lng':28.19440}},
{'stp':639180,'address':'639180, EGL - ISANDO, ISANDO MAIN ON 1, KWA HLOKZA, 328 ESANGWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.02200,'lng':28.20180}},
{'stp':639272,'address':'639272, EGL - ISANDO, ISANDO MAIN ON 1, NYABANGA\'S PLACE, 15200 IVORY PARK, IVORY PARK EXT 3, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98061,'lng':28.20876}},
{'stp':640427,'address':'640427, EGL - ISANDO, ISANDO MAIN ON 1, GUY DOOYS TAVERN, 9733 WINNIE MANDELA ZONE 4, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-25.98620,'lng':28.21980}},
{'stp':641690,'address':'641690, EGL - ISANDO, ISANDO MAIN ON 1, THABISENG PLACE, 10937 STAND NO, IVORY PARK, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.98860,'lng':28.19790}},
{'stp':641793,'address':'641793, EGL - ISANDO, ISANDO MAIN ON 1, BETTY\'S TAVERN, 15167 EXT 3, IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.98018,'lng':28.20921}},
{'stp':646425,'address':'646425, EGL - ISANDO, ISANDO MAIN ON 1, WILLYS PLACE, 9172 ZONE 11  num 9172, WINNIE MANDELA, TEMBISA, TEMBISA, South Africa','geo':{'lat':-25.98760,'lng':28.21400}},
{'stp':646672,'address':'646672, EGL - ISANDO, ISANDO MAIN ON 1, VICTORS PLACE, 8387 WINNIE MANDELA ZONE 8  num 838, WINNIE MANDELA ZONE 8, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97840,'lng':28.21490}},
{'stp':646946,'address':'646946, EGL - ISANDO, ISANDO MAIN ON 1, Y2K INN, 14567 IVORY PARK EXT 3   num 14567, IVORY PARK, MIDRAND, IVORY PARK, South Africa','geo':{'lat':-25.97780,'lng':28.20670}},
{'stp':646952,'address':'646952, EGL - ISANDO, ISANDO MAIN ON 1, MILLA\'S PLACE, ERF 2602 WINNIE MANDELA, ZONE 10, TSWELOPELE EXTENSION 5, IVORY PARK, South Africa','geo':{'lat':-25.97370,'lng':28.22630}},
{'stp':647352,'address':'647352, EGL - ISANDO, ISANDO MAIN ON 1, SIFRONIA\'S PLACE, 542 BLOCK D, DUDUZA SECTION, #, IVORY PARK, South Africa','geo':{'lat':-25.97610,'lng':28.21140}},
{'stp':647906,'address':'647906, EGL - ISANDO, ISANDO MAIN ON 1, VBK GENERAL DEALER, 13803 KARIMBA STREET, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.98320,'lng':28.20620}},
{'stp':648464,'address':'648464, EGL - ISANDO, ISANDO MAIN ON 1, WEST SIDE CORNER, 12263 IVORY PARK, EXT 10, #, IVORY PARK, South Africa','geo':{'lat':-25.99380,'lng':28.19490}},
{'stp':649115,'address':'649115, EGL - ISANDO, ISANDO MAIN ON 1, MAJOZI TAVERN, 9799 WINNIE MANDELA ZONE 4, #, #, TEMBISA, South Africa','geo':{'lat':-25.98650,'lng':28.22050}},
{'stp':649351,'address':'649351, EGL - ISANDO, ISANDO MAIN ON 1, ALFRED\'S PLACE, 12449 IVORY PARK, #, #, IVORY PARK, South Africa','geo':{'lat':-25.99700,'lng':28.19750}},
{'stp':650373,'address':'650373, EGL - ISANDO, ISANDO MAIN ON 1, TAKALANI, 2679 BERRILIUM STREET, CLAYVILLE HEIGHTS EXT 21, #, IVORY PARK, South Africa','geo':{'lat':-25.95031,'lng':28.21214}},
{'stp':651188,'address':'651188, EGL - ISANDO, ISANDO MAIN ON 1, ALINAS PLACE, ERF 15039, EXTENSION 13, #, IVORY PARK, South Africa','geo':{'lat':-25.97873,'lng':28.20435}},
{'stp':653364,'address':'653364, EGL - ISANDO, ISANDO MAIN ON 1, JAKES TAVERN, 2824 ANGOLA STREET, TSWELOPELE, #, IVORY PARK, South Africa','geo':{'lat':-25.96116,'lng':28.20716}},
{'stp':659240,'address':'659240, EGL - ISANDO, ISANDO MAIN ON 1, NKOVANI TAVERN, 8278 WINNIE MANDELA, #, ZONE 08, IVORY PARK, South Africa','geo':{'lat':-25.97790,'lng':28.21680}},
{'stp':665094,'address':'665094, EGL - ISANDO, ISANDO MAIN ON 1, AMOS TUCKSHOP, 15485 STAND NO, 3 KHUNDU STR IVORY PARK EXT 13 MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.98062,'lng':28.19863}},
{'stp':682452,'address':'682452, EGL - ISANDO, ISANDO MAIN ON 1, JOHANNES TAVERN, ERF 11297 IVORY PARK, EXTENSION 10 TOWNSHIP, #, IVORY PARK, South Africa','geo':{'lat':-25.98680,'lng':28.19720}},
{'stp':682573,'address':'682573, EGL - ISANDO, ISANDO MAIN ON 1, OTHANDWENI TUCKSHOP, 13049 IVORY PARK EXT 12, TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.98520,'lng':28.19770}},
{'stp':687191,'address':'687191, EGL - ISANDO, ISANDO MAIN ON 1, SITHOLE TAVERN, 630 KANANA EXT 5, MIDRAND, #, TEMBISA, South Africa','geo':{'lat':-26.03330,'lng':28.18550}},
{'stp':755193,'address':'755193, EGL - ISANDO, ISANDO MAIN ON 1, SEBAMBA TAVERN, STAND NO 5907, ZONE 5, WINNIE MANDELA, IVORY PARK, South Africa','geo':{'lat':-25.97860,'lng':28.22950}},
{'stp':770948,'address':'770948, EGL - ISANDO, ISANDO MAIN ON 1, PARADISE, STAND NO, ERF 5829 APRIL STR IVORY PARK EXT 7, #, IVORY PARK, South Africa','geo':{'lat':-26.01065,'lng':28.18740}},
{'stp':771374,'address':'771374, EGL - ISANDO, ISANDO MAIN ON 1, THABISOS PLACE, 11480 MASHUSHA STR, EXT 10 IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.98783,'lng':28.20098}},
{'stp':771395,'address':'771395, EGL - ISANDO, ISANDO MAIN ON 1, BEN S PLACE, STAND NO, ERF 10691 WINNIE MANDELA ZONE 4, TEMBISA EXTENSION 24, TEMBISA, South Africa','geo':{'lat':-25.98978,'lng':28.22025}},
{'stp':772622,'address':'772622, EGL - ISANDO, ISANDO MAIN ON 1, FLOMINGO SHOP, 13574 STAND NO, IVORY PARK EXT 12  MIDRAND IVORY PARK, #, IVORY PARK, South Africa','geo':{'lat':-25.98571,'lng':28.20173}},
{'stp':775951,'address':'775951, EGL - ISANDO, ISANDO MAIN ON 1, J M TUCKSHOP, 14584 STAND NO, MIDRAND, #, IVORY PARK, South Africa','geo':{'lat':-25.97852,'lng':28.20867}},
{'stp':775952,'address':'775952, EGL - ISANDO, ISANDO MAIN ON 1, AYAKANG, STAND NO, 8384 WINNIE MANDEAL, EXT 24, IVORY PARK, South Africa','geo':{'lat':-25.97882,'lng':28.21517}},
{'stp':781118,'address':'781118, EGL - ISANDO, ISANDO MAIN ON 1, LETHULAS TAVERN, STAND NO, ERF 630 TSWELOPELE EXT 5, #, IVORY PARK, South Africa','geo':{'lat':-25.96765,'lng':28.21638}},
{'stp':781921,'address':'781921, EGL - ISANDO, ISANDO MAIN ON 1, MPHUTHA DICHABA TAVERN, 1234 STAND NO, MOKHADO STR TSWELAPELE EXT 6 TEMBISA, #, IVORY PARK, South Africa','geo':{'lat':-25.97229,'lng':28.21943}},
{'stp':782902,'address':'782902, EGL - ISANDO, ISANDO MAIN ON 1, FEELING GOOD TAVERN, 3749 STAND NO, TSWELOPELE SECTION, #, IVORY PARK, South Africa','geo':{'lat':-25.96561,'lng':28.20855}},
{'stp':799654,'address':'799654, EGL - ISANDO, ISANDO MAIN ON 1, KUDUS INN, 819 ERF, TSWELAPELE EXT 5, WINNIE MANDELA ZONE 12, IVORY PARK, South Africa','geo':{'lat':-25.97109,'lng':28.21576}},
{'stp':307059,'address':'307059, EGL - ISANDO, ISANDO HYBRID TEAM, LUCY S TAVERN, 10398 STAND NO, INGWEYA, MAYFIELD EXT 11, DAVEYTON, South Africa','geo':{'lat':-26.13770,'lng':28.40942}},
{'stp':308729,'address':'308729, EGL - ISANDO, ISANDO HYBRID TEAM, LIQUOR LEGENDS, 52 SHOP NO, DAVEYTON MALL,, ERF 20075, DAVEYTON, South Africa','geo':{'lat':-26.15453,'lng':28.41737}},
{'stp':315415,'address':'315415, EGL - ISANDO, ISANDO HYBRID TEAM, FREEWAY CAFE, 9/70-IR ERF NO, KLIPFONTEIN 70-IR, 9 HURUTHSE , DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.15249,'lng':28.42141}},
{'stp':388050,'address':'388050, EGL - ISANDO, ISANDO HYBRID TEAM, PICK N PAY GC78 MAYFIELD SQUARE S C, 61 SHOP NO, ERF NO 10471&10472 NEW MAYFIELD SQUARE, SHOOPING CENTRENO 268 LURIE ROAD, DAVEYTON, South Africa','geo':{'lat':-26.13532,'lng':28.41008}},
{'stp':393166,'address':'393166, EGL - ISANDO, ISANDO HYBRID TEAM, GRETA S TAVERN, 152 ERF, VIVIENE DRIVE, CHRIS HANI TOWNSHIP DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14956,'lng':28.42925}},
{'stp':464497,'address':'464497, EGL - ISANDO, ISANDO HYBRID TEAM, TWO DOOR TAVERN, 10183 STAND NO, BARWA, #, DAVEYTON, South Africa','geo':{'lat':-26.14454,'lng':28.42364}},
{'stp':476130,'address':'476130, EGL - ISANDO, ISANDO HYBRID TEAM, IGWE TAVERN, 3790 THEMBU STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14793,'lng':28.40934}},
{'stp':476301,'address':'476301, EGL - ISANDO, ISANDO HYBRID TEAM, SHOPRITE LIQUORSHOP 69971 MAYFIELD, ERF 10471 AND 10472 SHOP 48A, MAYFIELD SQUARE, 1 LURIE ROAD MAYFIELD EXTENSION 30, DAVEYTON, South Africa','geo':{'lat':-26.13564,'lng':28.41057}},
{'stp':477232,'address':'477232, EGL - ISANDO, ISANDO HYBRID TEAM, FRANK CORNER, 10641 KXATLA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14942,'lng':28.42126}},
{'stp':480015,'address':'480015, EGL - ISANDO, ISANDO HYBRID TEAM, NTSIKI CENTRAL CITY, 4739/40 STAND NO, KHANYILE, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14004,'lng':28.42243}},
{'stp':484561,'address':'484561, EGL - ISANDO, ISANDO HYBRID TEAM, LASTOWN BRAAI HOUSE, STAND NO, 10224 HLAKWANA, DAVETON, DAVEYTON, South Africa','geo':{'lat':-26.15510,'lng':28.42394}},
{'stp':488441,'address':'488441, EGL - ISANDO, ISANDO HYBRID TEAM, BOXER LIQUOR DAVEYTON, 20060 STAND NUMBER, DAVEYTON SHOPPING CENTRE, CORNER ENSLIN AND HLAKWANA STREETS, DAVEYTON, South Africa','geo':{'lat':-26.15160,'lng':28.41770}},
{'stp':491418,'address':'491418, EGL - ISANDO, ISANDO HYBRID TEAM, EKASI LIQUOR STORE, 4293 ERF NO, GUMEDE STREET, DAVEYTON, ERF NUMBER 4293/00, DAVEYTON, South Africa','geo':{'lat':-26.14410,'lng':28.41790}},
{'stp':546999,'address':'546999, EGL - ISANDO, ISANDO HYBRID TEAM, CHILLOUT TAVERN, STAND NO, ERF 1736 CELE STREET, #, DAVEYTON, South Africa','geo':{'lat':-26.15140,'lng':28.40730}},
{'stp':550022,'address':'550022, EGL - ISANDO, ISANDO HYBRID TEAM, LUCY S TAVERN, 1496 KHAKHU STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.14792,'lng':28.42030}},
{'stp':550433,'address':'550433, EGL - ISANDO, ISANDO HYBRID TEAM, CLUB TEN 4 NINE TAVERN, 10449 HURUTSHE STREET, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.15360,'lng':28.42320}},
{'stp':550608,'address':'550608, EGL - ISANDO, ISANDO HYBRID TEAM, CHILOANE SD, 11498 SIA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14920,'lng':28.42730}},
{'stp':551043,'address':'551043, EGL - ISANDO, ISANDO HYBRID TEAM, CLUB SAHARA TAVERN, STAND10735 LOBEDU STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15040,'lng':28.42540}},
{'stp':551174,'address':'551174, EGL - ISANDO, ISANDO HYBRID TEAM, GEMINI\'S INN, 15108 MASONDO STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14106,'lng':28.41145}},
{'stp':551200,'address':'551200, EGL - ISANDO, ISANDO HYBRID TEAM, MVELASES TAVERN, STAND 3541 DUNGENI STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14950,'lng':28.41180}},
{'stp':551292,'address':'551292, EGL - ISANDO, ISANDO HYBRID TEAM, KHUNOU RP, 11616 SELEKA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14700,'lng':28.42630}},
{'stp':551318,'address':'551318, EGL - ISANDO, ISANDO HYBRID TEAM, NTOMBIS INN, 1322 GONONO STREET, DAVEYTON, 1507, DAVEYTON, South Africa','geo':{'lat':-26.15190,'lng':28.40870}},
{'stp':552033,'address':'552033, EGL - ISANDO, ISANDO HYBRID TEAM, MTSHALI MG, 10689 KOLOBE STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14770,'lng':28.42090}},
{'stp':552037,'address':'552037, EGL - ISANDO, ISANDO HYBRID TEAM, JAYIYA LIQUOR STORE, 3740 TOLO STREET, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14610,'lng':28.40970}},
{'stp':552804,'address':'552804, EGL - ISANDO, ISANDO HYBRID TEAM, FREDDIE AND SON DISTRIBUTION, 11688 PULANA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14880,'lng':28.42830}},
{'stp':552843,'address':'552843, EGL - ISANDO, ISANDO HYBRID TEAM, MANHATTEN INN DELMAS, 4588 MOTHOPENG AVENUE, BOTLENG, EXT 7 DELMAS, DELMAS, South Africa','geo':{'lat':-26.10376,'lng':28.70372}},
{'stp':552995,'address':'552995, EGL - ISANDO, ISANDO HYBRID TEAM, LIDO LIQUOR WHOLESALERS, ERF 20010, CORNER BACA & DUMISA STREETS, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14720,'lng':28.41660}},
{'stp':553320,'address':'553320, EGL - ISANDO, ISANDO HYBRID TEAM, EVA S TAVERN, 11466 SIA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14820,'lng':28.42510}},
{'stp':553332,'address':'553332, EGL - ISANDO, ISANDO HYBRID TEAM, DANIELS PLACE, 10284 HLAKWANA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15130,'lng':28.41750}},
{'stp':553470,'address':'553470, EGL - ISANDO, ISANDO HYBRID TEAM, FUNKYS INN DAVEYTON, 4978 MOCKE STR, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.14470,'lng':28.41670}},
{'stp':553579,'address':'553579, EGL - ISANDO, ISANDO HYBRID TEAM, DAVIDS TAVERN, 10366 MOLETLANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15360,'lng':28.42140}},
{'stp':553600,'address':'553600, EGL - ISANDO, ISANDO HYBRID TEAM, BANDA FANYANA JOSEPH, 3607 SHONGWE STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14910,'lng':28.41220}},
{'stp':553775,'address':'553775, EGL - ISANDO, ISANDO HYBRID TEAM, JAMZARS LIQUOR, 5547 CNR MOCKE & TURTON ST, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.14152,'lng':28.42437}},
{'stp':560190,'address':'560190, EGL - ISANDO, ISANDO HYBRID TEAM, MGCINA SJ, 1653 DUNGENI STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14904,'lng':28.40470}},
{'stp':560247,'address':'560247, EGL - ISANDO, ISANDO HYBRID TEAM, LIQUOR LEGENDS DAVEYTON, CORNER ALLIANCE AND R22 NATIONAL, ROADS PORTION 7 OF FARM KLIPFO, NTEIN) DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.15839,'lng':28.41938}},
{'stp':560486,'address':'560486, EGL - ISANDO, ISANDO HYBRID TEAM, NGWENYA MI, 20035 SIBIYA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13060,'lng':28.43800}},
{'stp':560536,'address':'560536, EGL - ISANDO, ISANDO HYBRID TEAM, SHOBA BH, 7892 SPARROW STREET, EXT 9 EMAPHUPHENI, #, DAVEYTON, South Africa','geo':{'lat':-26.11270,'lng':28.47710}},
{'stp':561222,'address':'561222, EGL - ISANDO, ISANDO HYBRID TEAM, DLUDLU SA, 2688 ETWATWA EAST, #, #, DAVEYTON, South Africa','geo':{'lat':-26.13078,'lng':28.47572}},
{'stp':581096,'address':'581096, EGL - ISANDO, ISANDO HYBRID TEAM, ZWANE MD, 381 SIKINDU STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15800,'lng':28.40550}},
{'stp':581098,'address':'581098, EGL - ISANDO, ISANDO HYBRID TEAM, MACAMO S PLACE, STAND NO, ERF 1309 GONONO STR DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15240,'lng':28.40930}},
{'stp':581099,'address':'581099, EGL - ISANDO, ISANDO HYBRID TEAM, NKABINDE WB, 15044 SYDNEY MAKWALO STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14280,'lng':28.40950}},
{'stp':581105,'address':'581105, EGL - ISANDO, ISANDO HYBRID TEAM, MPYANE MM, 11869 TLHAKO STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14530,'lng':28.42690}},
{'stp':603708,'address':'603708, EGL - ISANDO, ISANDO HYBRID TEAM, RATALE SAC, 10762 LOBEDU STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14880,'lng':28.42300}},
{'stp':604627,'address':'604627, EGL - ISANDO, ISANDO HYBRID TEAM, SARAHS PLACE, 10912 MATLALA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14690,'lng':28.42410}},
{'stp':604900,'address':'604900, EGL - ISANDO, ISANDO HYBRID TEAM, MADOTO SVM, 10469 HURUTHSE STREET, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.15251,'lng':28.42053}},
{'stp':604906,'address':'604906, EGL - ISANDO, ISANDO HYBRID TEAM, MATSEKE SW, 11071 TURTON STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14921,'lng':28.42375}},
{'stp':605312,'address':'605312, EGL - ISANDO, ISANDO HYBRID TEAM, SEKWATE M, 10988 FOKENG STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14690,'lng':28.42440}},
{'stp':606520,'address':'606520, EGL - ISANDO, ISANDO HYBRID TEAM, MBANZE EB, 1479 JAFUTA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15070,'lng':28.41230}},
{'stp':606830,'address':'606830, EGL - ISANDO, ISANDO HYBRID TEAM, RAMUKHUVHATHI OLD MAKHOSAZANA MJ, 1596 KHAKHU STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14990,'lng':28.40260}},
{'stp':606970,'address':'606970, EGL - ISANDO, ISANDO HYBRID TEAM, LUKHELE DP, 15318 SWAZI STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14273,'lng':28.41393}},
{'stp':606971,'address':'606971, EGL - ISANDO, ISANDO HYBRID TEAM, MMULE S TAVERN (NYATHELA AM), 11208 NARENG STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14630,'lng':28.42610}},
{'stp':614369,'address':'614369, EGL - ISANDO, ISANDO HYBRID TEAM, LAPENG SPORTS & JAZZ JOINT (NTO), 11631 PULANE STREET, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.15304,'lng':28.42487}},
{'stp':625079,'address':'625079, EGL - ISANDO, ISANDO HYBRID TEAM, MUNDISA BAR LOUNGE, PERSEEL C11, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15280,'lng':28.41910}},
{'stp':625893,'address':'625893, EGL - ISANDO, ISANDO HYBRID TEAM, MOTHIBE C, 11411 SOTHO STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.15006,'lng':28.42594}},
{'stp':627324,'address':'627324, EGL - ISANDO, ISANDO HYBRID TEAM, MBUYIS PLACE, 3898 TSHEZI STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14700,'lng':28.41240}},
{'stp':627345,'address':'627345, EGL - ISANDO, ISANDO HYBRID TEAM, JABUS PLACE, 1896 BIYELA STREET,  DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15150,'lng':28.40480}},
{'stp':627350,'address':'627350, EGL - ISANDO, ISANDO HYBRID TEAM, LERATO, 3215 L ERATO STREET, KANANA EXT 5 (rabie ridge), #, TEMBISA, South Africa','geo':{'lat':-26.03550,'lng':28.18110}},
{'stp':628087,'address':'628087, EGL - ISANDO, ISANDO HYBRID TEAM, NICOS PLACE, 10051 EISELEN STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14560,'lng':28.42130}},
{'stp':629019,'address':'629019, EGL - ISANDO, ISANDO HYBRID TEAM, SUSAS PLACE, 11710 ROLONG STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15400,'lng':28.42560}},
{'stp':629453,'address':'629453, EGL - ISANDO, ISANDO HYBRID TEAM, WEBSTER INN, 4230 MPONDOMISE STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14270,'lng':28.41500}},
{'stp':629540,'address':'629540, EGL - ISANDO, ISANDO HYBRID TEAM, EZENKENI 2, 1147 VENDA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15210,'lng':28.41290}},
{'stp':629541,'address':'629541, EGL - ISANDO, ISANDO HYBRID TEAM, DIRAS PLACE, 3144 YENDE STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14760,'lng':28.40490}},
{'stp':629576,'address':'629576, EGL - ISANDO, ISANDO HYBRID TEAM, JACQUELINES PLACE, 288 PHASWANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15600,'lng':28.40420}},
{'stp':629596,'address':'629596, EGL - ISANDO, ISANDO HYBRID TEAM, ZANOVA S, 259 PHASWANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.16010,'lng':28.40550}},
{'stp':629597,'address':'629597, EGL - ISANDO, ISANDO HYBRID TEAM, THULANES PLACE, 5281 GCALEKA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14524,'lng':28.41807}},
{'stp':629598,'address':'629598, EGL - ISANDO, ISANDO HYBRID TEAM, MCEDISIS PLACE, 3904 TSHEZI STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14650,'lng':28.41170}},
{'stp':629869,'address':'629869, EGL - ISANDO, ISANDO HYBRID TEAM, GLADS PLACE, 5495 EISELEN STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.15030,'lng':28.41680}},
{'stp':630017,'address':'630017, EGL - ISANDO, ISANDO HYBRID TEAM, SPIDERS PLACE, MOLETLANE STREET, ERF 10368 BASOTHO SECTION, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.15360,'lng':28.42140}},
{'stp':631556,'address':'631556, EGL - ISANDO, ISANDO HYBRID TEAM, CYNTHIA\'S TAVERN, 10534 KWENA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15110,'lng':28.42030}},
{'stp':632701,'address':'632701, EGL - ISANDO, ISANDO HYBRID TEAM, SHADRACK NTAPI NTOKE, 10575 KWENA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14990,'lng':28.41900}},
{'stp':634335,'address':'634335, EGL - ISANDO, ISANDO HYBRID TEAM, EVELINAHS TAVERN, 5287 HLUBE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14466,'lng':28.41981}},
{'stp':634558,'address':'634558, EGL - ISANDO, ISANDO HYBRID TEAM, ABRAMS PLACE, 10378 MKWAKETSE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15420,'lng':28.42160}},
{'stp':634859,'address':'634859, EGL - ISANDO, ISANDO HYBRID TEAM, LINDIWES PLACE, 10667 KOLOBE STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.14857,'lng':28.42099}},
{'stp':635441,'address':'635441, EGL - ISANDO, ISANDO HYBRID TEAM, MISSISSIPPI, MAHLOBO STREET, ERF 3966, #, DAVEYTON, South Africa','geo':{'lat':-26.14440,'lng':28.40880}},
{'stp':636228,'address':'636228, EGL - ISANDO, ISANDO HYBRID TEAM, HARRIETS PLACE, 1185 DENGA STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.15350,'lng':28.41090}},
{'stp':638019,'address':'638019, EGL - ISANDO, ISANDO HYBRID TEAM, TSIBIS PLACE, 11706 ROLENG STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15470,'lng':28.42520}},
{'stp':639226,'address':'639226, EGL - ISANDO, ISANDO HYBRID TEAM, ZODWAS TAVERN, 4798 KHESWA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14461,'lng':28.42377}},
{'stp':639346,'address':'639346, EGL - ISANDO, ISANDO HYBRID TEAM, TIZAS PLACE, 5400 BOMVANA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14980,'lng':28.41650}},
{'stp':639376,'address':'639376, EGL - ISANDO, ISANDO HYBRID TEAM, KWA MAGOGO, 3298 RAMZA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14460,'lng':28.40990}},
{'stp':639400,'address':'639400, EGL - ISANDO, ISANDO HYBRID TEAM, THEMBIS INN, 5001 MOCKE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14320,'lng':28.41830}},
{'stp':639403,'address':'639403, EGL - ISANDO, ISANDO HYBRID TEAM, XOLILES PLACE, 4953 MOCKE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14540,'lng':28.41570}},
{'stp':640652,'address':'640652, EGL - ISANDO, ISANDO HYBRID TEAM, ONICAS PLACE, 1000 LEMBA STREE, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15420,'lng':28.41050}},
{'stp':640903,'address':'640903, EGL - ISANDO, ISANDO HYBRID TEAM, TSHABALALA\'S PLACE, 9055 (3358) RAMZA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14260,'lng':28.41390}},
{'stp':641401,'address':'641401, EGL - ISANDO, ISANDO HYBRID TEAM, TSHIDIS PLACE, 10779 LOBEDU STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14790,'lng':28.42180}},
{'stp':642208,'address':'642208, EGL - ISANDO, ISANDO HYBRID TEAM, SIXTY\'S PLACE, 3625 SHONGWE STREET  3625, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14880,'lng':28.41110}},
{'stp':642646,'address':'642646, EGL - ISANDO, ISANDO HYBRID TEAM, PRICILA MDLALOSE, 5024 MOCKE STREET  num 5024, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14221,'lng':28.41981}},
{'stp':642717,'address':'642717, EGL - ISANDO, ISANDO HYBRID TEAM, ZEENEBO BOTTLE STORE, 114 VAN DYK ROAD  num 114, DUNSWART, BENONI, BENONI, South Africa','geo':{'lat':-26.23250,'lng':28.29500}},
{'stp':646470,'address':'646470, EGL - ISANDO, ISANDO HYBRID TEAM, MPHATHI\'S PLACE, 3986 NTAMBO STREET  num 3986, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.14620,'lng':28.41330}},
{'stp':646622,'address':'646622, EGL - ISANDO, ISANDO HYBRID TEAM, MAMGO S PLACE AND GRAB, 3810 VEZI STREET  num 3810, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.14770,'lng':28.41160}},
{'stp':646631,'address':'646631, EGL - ISANDO, ISANDO HYBRID TEAM, FIKILE \'S PLACE, 4061 QAYI STREET  num 4061, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14470,'lng':28.41230}},
{'stp':646679,'address':'646679, EGL - ISANDO, ISANDO HYBRID TEAM, LANGA\'S PLACE, 4222 MPONDOMISE STREET  num 4222, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13564,'lng':28.43604}},
{'stp':649583,'address':'649583, EGL - ISANDO, ISANDO HYBRID TEAM, FOX\'S TAVERN, 5268 GCALEKA STREET 5268, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14540,'lng':28.41830}},
{'stp':653547,'address':'653547, EGL - ISANDO, ISANDO HYBRID TEAM, BAPSFONTEIN DRANKWINKEL, PORTION 10 FARM TWEENFONTEIN, 413 REGISTRATION DIVISION J R, #, KEMPTON PARK, South Africa','geo':{'lat':-26.00380,'lng':28.42480}},
{'stp':664262,'address':'664262, EGL - ISANDO, ISANDO HYBRID TEAM, DECEMBERS PLACE, 4445 LUSHABA STREET, #, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14210,'lng':28.41800}},
{'stp':664314,'address':'664314, EGL - ISANDO, ISANDO HYBRID TEAM, DIPUO S PLACE, 3004 ZIBISINI STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14880,'lng':28.40250}},
{'stp':674856,'address':'674856, EGL - ISANDO, ISANDO HYBRID TEAM, IN THE HOUSE SPORTS BAR, 11618 SELEKA STREET, #, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14700,'lng':28.42630}},
{'stp':685157,'address':'685157, EGL - ISANDO, ISANDO HYBRID TEAM, KEEP IT COOL LIQUORS, 120 STAND NO, PUTFONTEIN, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12960,'lng':28.40150}},
{'stp':687108,'address':'687108, EGL - ISANDO, ISANDO HYBRID TEAM, CHRISTOS GENERAL DEALER, 17 OLIFANTSFONTEIN 196 JR, #, #, MIDRAND, South Africa','geo':{'lat':-26.09640,'lng':28.61210}},
{'stp':710328,'address':'710328, EGL - ISANDO, ISANDO HYBRID TEAM, MAKES TAVERN, STANDPLAAS 11870, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14530,'lng':28.42690}},
{'stp':735514,'address':'735514, EGL - ISANDO, ISANDO HYBRID TEAM, TWO TONE PUB, ERF 11016, FOKENG STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14440,'lng':28.42500}},
{'stp':741376,'address':'741376, EGL - ISANDO, ISANDO HYBRID TEAM, KGORONG YA MARUMA, 9032 EMBUSWENI STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15210,'lng':28.41870}},
{'stp':774278,'address':'774278, EGL - ISANDO, ISANDO HYBRID TEAM, SHIRLEY S PLACE, 1634 DUNGENI STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14932,'lng':28.40783}},
{'stp':775666,'address':'775666, EGL - ISANDO, ISANDO HYBRID TEAM, LEBOS PLACE, 4006 NTAMBO STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14448,'lng':28.41326}},
{'stp':793389,'address':'793389, EGL - ISANDO, ISANDO HYBRID TEAM, THANDIS PLACE, 11253 PHUTHI STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.15335,'lng':28.42380}},
{'stp':316139,'address':'316139, EGL - ISANDO, ISANDO HYBRID TEAM, JC LIQUOR, 10 SHOT NO, MARNOL COURT, NO 211, RIETFONTEIN ROAD, PRIMROSE, PRIMROSE, South Africa','geo':{'lat':-26.18494,'lng':28.17051}},
{'stp':319333,'address':'319333, EGL - ISANDO, ISANDO HYBRID TEAM, TILT BEACH BAR - JANSENPARK (NTO), ERF 235, GROUND FLOOR, EASTLANDS OFFICE PARK, CNR RONDEBULT AND NORTHRANDS ROAD, BOKSBURG, South Africa','geo':{'lat':-26.17959,'lng':28.24325}},
{'stp':384954,'address':'384954, EGL - ISANDO, ISANDO HYBRID TEAM, CLYDE LIQUOR STORE, 227 ERF NO, 1 EMDEN STREET, CORNER WATERLOO STREET GERDVIEW, PRIMROSE, South Africa','geo':{'lat':-26.17429,'lng':28.18160}},
{'stp':388530,'address':'388530, EGL - ISANDO, ISANDO HYBRID TEAM, FERREIRAS SPORTS BAR AND RESTAURANT, 312 N 313 ERF NO, JANSEN PARK EXTENSION 26, CORNER RIETFONTEIN ROAD AND, BOKSBURG, South Africa','geo':{'lat':-26.19143,'lng':28.24152}},
{'stp':388778,'address':'388778, EGL - ISANDO, ISANDO HYBRID TEAM, NEW HEAVEN LIQUOR STORE, 94 STAND NO, RIETFONTEIN ROAD, PRIMROSE ERF NO810, PRIMROSE, South Africa','geo':{'lat':-26.18996,'lng':28.15912}},
{'stp':389201,'address':'389201, EGL - ISANDO, ISANDO HYBRID TEAM, BLUE MARLIN SEAFOOD (NTO), 194 MERCURY ROAD, ALPHA, BENONI, BOKSBURG, South Africa','geo':{'lat':-26.16595,'lng':28.27823}},
{'stp':389474,'address':'389474, EGL - ISANDO, ISANDO HYBRID TEAM, TILT BEACH BAR, 235 ERF, GROUND FLOOR EASTLANDS OFFICE PARK, CORNER RONDEBULT AND NORTHRAND ROADS, BOKSBURG, South Africa','geo':{'lat':-26.18038,'lng':28.24353}},
{'stp':390679,'address':'390679, EGL - ISANDO, ISANDO HYBRID TEAM, THE SOUL SHACK, 191 STAND NO, PORTION 191 OF THE FARM KLIPFONTEIN 83 I, NO191 TRICHARDT ROAD RAVENSWOOD, BOKSBURG, South Africa','geo':{'lat':-26.19237,'lng':28.25328}},
{'stp':392184,'address':'392184, EGL - ISANDO, ISANDO HYBRID TEAM, ZIR COFFEE & CAR WASH TA NIGHT OWL, 260 UYS STREET, RYNFIELD, #, BENONI, South Africa','geo':{'lat':-26.13353,'lng':28.33749}},
{'stp':392447,'address':'392447, EGL - ISANDO, ISANDO HYBRID TEAM, DINGO S PUB AND GRILL, SHOP NO 1 & 2 LAKEDENE CENTRE, NO :108 LAKEFIELD AVENUE, BEING ERF: 684, BENONI, South Africa','geo':{'lat':-26.17933,'lng':28.28585}},
{'stp':393009,'address':'393009, EGL - ISANDO, ISANDO HYBRID TEAM, JOHN DORY\'S - EAST RAND MALL (NTO), SHOP .94 BENTEL AVENUE, BOKSBURG NO, BEING ERVEN 189 JANSEN PARK EXTENSION 4, JANSEN PARK EXTENSION 7, EAST RAND MALL, ISANDO, South Africa','geo':{'lat':-26.18322,'lng':28.23971}},
{'stp':461582,'address':'461582, EGL - ISANDO, ISANDO HYBRID TEAM, KICK OFF (NTO), WITFIELD CENTRE, SHOP NO 06, 58 MAIN STREET, WITFIELD, BOKSBURG, South Africa','geo':{'lat':-26.18382,'lng':28.20638}},
{'stp':468642,'address':'468642, EGL - ISANDO, ISANDO HYBRID TEAM, TSUNAMI BEACH BAR (NTO), 5 MERCURY STREET, FARRARMERE, BENONI, BENONI, South Africa','geo':{'lat':-26.15035,'lng':28.29898}},
{'stp':473082,'address':'473082, EGL - ISANDO, ISANDO HYBRID TEAM, MCCHICKEN MCPIZZA, ERF 316, SHOP 6B BIRCH ACRES, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.06064,'lng':28.21127}},
{'stp':473255,'address':'473255, EGL - ISANDO, ISANDO HYBRID TEAM, RAINBOW LIQUOR STORE, 32 STAND NO, PRIMULA ROAD, PRIMROSE, ERF 806, PRIMROSE, South Africa','geo':{'lat':-26.18964,'lng':28.15949}},
{'stp':475200,'address':'475200, EGL - ISANDO, ISANDO HYBRID TEAM, ROCOMAMA\'S BOKSBURG (NTO), 136-137 NORTH RAND ROAD, THE TOWERS SHOPPING CENTRE, JANSEN PARK, BOKSBURG, South Africa','geo':{'lat':-26.17950,'lng':28.24243}},
{'stp':477626,'address':'477626, EGL - ISANDO, ISANDO HYBRID TEAM, ST BENEDICT RUGBY FESTIVAL 2016, STAND NO, ST BEEDICT\'S COLLEGE, BOUNDED BY HURCUS ROAD & CONCORD ROAD, BEDFORDVIEW, South Africa','geo':{'lat':-26.17317,'lng':28.13557}},
{'stp':478477,'address':'478477, EGL - ISANDO, ISANDO HYBRID TEAM, HOT SPOT SPORTS AND COCTAIL BAR, 5657 STAND NUMBER, 74 GOUSBLOM STREET, CRONER MIMOSA STREET, BOKSBURG, South Africa','geo':{'lat':-26.16926,'lng':28.30007}},
{'stp':482860,'address':'482860, EGL - ISANDO, ISANDO HYBRID TEAM, DIVINE LIQUORS, 0026 STAND NO, VOORTREKKER RD, ERF 1236 BENONI, BENONI, South Africa','geo':{'lat':-26.18946,'lng':28.32123}},
{'stp':484078,'address':'484078, EGL - ISANDO, ISANDO HYBRID TEAM, NANDO\'S GLEN ACRES (NTO), CNR DANN & MONUMENT ROAD, STAND ALONE DRIVE THROUGH, GLEN ACRES SHOPPING CENTRE, KEMPTON PARK, South Africa','geo':{'lat':-26.07837,'lng':28.24850}},
{'stp':485581,'address':'485581, EGL - ISANDO, ISANDO HYBRID TEAM, COOL RUNINGS PUB AND GRILL (NTO), 49 HIGH ROAD, BRENTWOOD PARK, #, #, BENONI, South Africa','geo':{'lat':-26.11788,'lng':28.29413}},
{'stp':489885,'address':'489885, EGL - ISANDO, ISANDO HYBRID TEAM, BROOKLYN BROTHERS (NTO), Erf 177, Shop No\'s L14 & L14A, EAST POINT SHOPPING MALL, CNR NORTRAND & RIETFONTEIN ROADS, BOKSBURG, South Africa','geo':{'lat':-26.17870,'lng':28.23490}},
{'stp':490357,'address':'490357, EGL - ISANDO, ISANDO HYBRID TEAM, ROCOMAMAS RYNFIELD (NTO), SHOP 1 RYNFIELD SQUARE SHOPPING CEN, CNR PETORIA AND RICKARD ROADS, RYNFIELD, BENONI, BENONI, South Africa','geo':{'lat':-26.14751,'lng':28.34277}},
{'stp':499434,'address':'499434, EGL - ISANDO, ISANDO HYBRID TEAM, SPIRIT AND BUBBLES WHOLESALE, 3617 STAND NO, POMONA RD, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.09699,'lng':28.25779}},
{'stp':540459,'address':'540459, EGL - ISANDO, ISANDO HYBRID TEAM, CIELLO @ THE LAKES HOTEL (NTO), ERF 7913 EXT 45, 1 COUNTRY LANE LAKEFIELD, BENONI, BENONI, South Africa','geo':{'lat':-26.17979,'lng':28.28878}},
{'stp':542616,'address':'542616, EGL - ISANDO, ISANDO HYBRID TEAM, G-STRING (NTO), PLOT 193, RAVENSWOOD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19255,'lng':28.25319}},
{'stp':550110,'address':'550110, EGL - ISANDO, ISANDO HYBRID TEAM, SUMMER PLACE RESTAURANT, LEITH ROAD, PLOT 80, #, BOKSBURG, South Africa','geo':{'lat':-26.17051,'lng':28.27187}},
{'stp':550152,'address':'550152, EGL - ISANDO, ISANDO HYBRID TEAM, IL VILLAGGIO ALFONSO, 12 KINGS ROAD, BEDFORDVIEW, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.17350,'lng':28.13380}},
{'stp':550432,'address':'550432, EGL - ISANDO, ISANDO HYBRID TEAM, LITTLE NEROS, 137 NORTH RAND ROAD, KLIPFONTEIN, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17920,'lng':28.24190}},
{'stp':550452,'address':'550452, EGL - ISANDO, ISANDO HYBRID TEAM, BALMORAL LIQUOR STORE, ERF 480, SHOPS D SHOP D & E, 43 MAIN REEF ROAD, LILIANTON EXT1, BOKSBURG, South Africa','geo':{'lat':-26.19619,'lng':28.20897}},
{'stp':550541,'address':'550541, EGL - ISANDO, ISANDO HYBRID TEAM, MIKE\'S KITCHEN BOKSBURG, CNR.PHILLIPS & KITCHENER, BEYERS PARK, BOKSBURG., BOKSBURG, South Africa','geo':{'lat':-26.18460,'lng':28.27600}},
{'stp':550544,'address':'550544, EGL - ISANDO, ISANDO HYBRID TEAM, SANTA RIO SPUR, SHOP E25 & M28, LAKESIDE MALL, BENONI, BENONI, South Africa','geo':{'lat':-26.18600,'lng':28.31340}},
{'stp':550571,'address':'550571, EGL - ISANDO, ISANDO HYBRID TEAM, JET PARK LIQUORS, SHOP 1 ERF 138, MAIN ROAD, WITFIELD BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17645,'lng':28.21030}},
{'stp':550747,'address':'550747, EGL - ISANDO, ISANDO HYBRID TEAM, SHOOTERS AVIATION, NORTHMEAD SQUARE, CNRO REILLY MERRY STREET, 14 AVE NORTHMEAD, BENONI, South Africa','geo':{'lat':-26.16221,'lng':28.32338}},
{'stp':550867,'address':'550867, EGL - ISANDO, ISANDO HYBRID TEAM, BLUE MARLIN, ERF 2/62 BOKSBURG WES, RIETFONTEINWEG 63, BOKSBURG WEST, BOKSBURG, South Africa','geo':{'lat':-26.17970,'lng':28.22841}},
{'stp':550951,'address':'550951, EGL - ISANDO, ISANDO HYBRID TEAM, DAYTONA SPORTS BAR, PLOT 192, KLIPFONTEIN, RAVENSWOOD, BOKSBURG, South Africa','geo':{'lat':-26.19262,'lng':28.25319}},
{'stp':551066,'address':'551066, EGL - ISANDO, ISANDO HYBRID TEAM, SILVER PEAK SPUR, SHOP 90 A EAST RAND MALL, BENTEL ROAD, BOKSBURG NOORD, BOKSBURG, South Africa','geo':{'lat':-26.18209,'lng':28.24097}},
{'stp':551148,'address':'551148, EGL - ISANDO, ISANDO HYBRID TEAM, SAINTS, 0 FINCH ROAD, SHOP 2 SUMMERFIELDS SHOPPING CENTRE, ATLASVILLE EXT 5, BENONI, South Africa','geo':{'lat':-26.16080,'lng':28.27990}},
{'stp':551175,'address':'551175, EGL - ISANDO, ISANDO HYBRID TEAM, E R P M GOLF CLUB, FARM DRIEFONTEIN NO 1, NEAR RIETFONTEIN RD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19930,'lng':28.23350}},
{'stp':551183,'address':'551183, EGL - ISANDO, ISANDO HYBRID TEAM, BIG DOG PUB, SHOP E9M LAKESIDE MALL,  ERVEN 8346,8350 &8351TOM JONES AVENUE, BENONI, BENONI, South Africa','geo':{'lat':-26.18555,'lng':28.31376}},
{'stp':551273,'address':'551273, EGL - ISANDO, ISANDO HYBRID TEAM, KEG AND EAGLE, 86 FIELD STREET, C/O PRETORIA ROAD, WITFIELD X 7, BOKSBURG, South Africa','geo':{'lat':-26.18490,'lng':28.21870}},
{'stp':551389,'address':'551389, EGL - ISANDO, ISANDO HYBRID TEAM, HI FLYERZ AVIATION THEME BAR, ERF 676120NORTH RAND RD, BARDENE EXT 39, BARTLETT, BOKSBURG, South Africa','geo':{'lat':-26.17490,'lng':28.24850}},
{'stp':551390,'address':'551390, EGL - ISANDO, ISANDO HYBRID TEAM, MARY ANNE S PUB AND DINER, SHOP NO 13 MONA LISA SHOPPING, CENTRE 6TH AVENUE, NORTHMEAD BENONI, BENONI, South Africa','geo':{'lat':-26.17350,'lng':28.33050}},
{'stp':551654,'address':'551654, EGL - ISANDO, ISANDO HYBRID TEAM, GALAXY BINGO BOKSBURG, SHOP M2 K90 CENTRE, NORTH RAND ROAD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.18120,'lng':28.24570}},
{'stp':551701,'address':'551701, EGL - ISANDO, ISANDO HYBRID TEAM, BENONI LAKE CLUB, LAKEFIELDLAA, BENONI, #, BENONI, South Africa','geo':{'lat':-26.18350,'lng':28.29790}},
{'stp':551724,'address':'551724, EGL - ISANDO, ISANDO HYBRID TEAM, WOODYS PUB AND GRILL, 32 STAND NO, 36437 ESPERANTI CENTER, SHOP 32 M GREAT NORTH ROAD, BENONI, South Africa','geo':{'lat':-26.14530,'lng':28.29870}},
{'stp':551768,'address':'551768, EGL - ISANDO, ISANDO HYBRID TEAM, BIRCHWOOD SPUR, BIRCHWOOD SPUR, 20 NORTHRAND ROAD, BARDENE, BOKSBURG, South Africa','geo':{'lat':-26.17880,'lng':28.24870}},
{'stp':551989,'address':'551989, EGL - ISANDO, ISANDO HYBRID TEAM, THE SCALLYWAG PUB AND RESTAURANT, 54 CHARL CILLIERS STREET, BOKSBURG NORTH, #, BOKSBURG, South Africa','geo':{'lat':-26.19853,'lng':28.24417}},
{'stp':552421,'address':'552421, EGL - ISANDO, ISANDO HYBRID TEAM, TRADERS SPORTS BAR, UNIT 8 EAST RAND TRADERS SQUAR, CYNTHIA ROAD, BARDENE EXTN 21, BOKSBURG, South Africa','geo':{'lat':-26.17670,'lng':28.23880}},
{'stp':552517,'address':'552517, EGL - ISANDO, ISANDO HYBRID TEAM, RIO LIQUOR STORE, 85 STAND NO, PORTION 12 MAIN REEF ROAD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19880,'lng':28.21720}},
{'stp':552533,'address':'552533, EGL - ISANDO, ISANDO HYBRID TEAM, LIQUOR MART, 4 11TH AVE, NORTHMEAD, #, BENONI, South Africa','geo':{'lat':-26.16890,'lng':28.32780}},
{'stp':552990,'address':'552990, EGL - ISANDO, ISANDO HYBRID TEAM,  PRESLEYS BOKSBURG, CNR NORTHRAND & GIBB RD, DRIEFONTEIN, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17900,'lng':28.23130}},
{'stp':553042,'address':'553042, EGL - ISANDO, ISANDO HYBRID TEAM, NORTHMEAD BOWLING CLUB, 8TH AVENUE, NORTHMEAD PARK, NORTHMEAD, BENONI, South Africa','geo':{'lat':-26.17370,'lng':28.32290}},
{'stp':553163,'address':'553163, EGL - ISANDO, ISANDO HYBRID TEAM, TROTTERS PUB AND GRILL, BONAERO PARK EDMS COMPLEX, DOKOTA WEG, KEMPTON PARK, BOKSBURG, South Africa','geo':{'lat':-26.15560,'lng':28.25960}},
{'stp':553184,'address':'553184, EGL - ISANDO, ISANDO HYBRID TEAM, PANAROTTIS LAKESIDE MALL, SHOP E10, LAKESIDE MALL, BENONI, BENONI, South Africa','geo':{'lat':-26.18590,'lng':28.31340}},
{'stp':553211,'address':'553211, EGL - ISANDO, ISANDO HYBRID TEAM, BENONI BOWLING CLUB, CNR KIMBOLTON STR & NEWLANDS, BENONI, 1500, BENONI, South Africa','geo':{'lat':-26.18770,'lng':28.31040}},
{'stp':553225,'address':'553225, EGL - ISANDO, ISANDO HYBRID TEAM, PICREB BENONI, SHOP 5 NORTHMEAD SQUARE, 14 TH AVE NORTHMEAD, BENONI, BENONI, South Africa','geo':{'lat':-26.16240,'lng':28.32390}},
{'stp':553242,'address':'553242, EGL - ISANDO, ISANDO HYBRID TEAM, BRIGADOON S PUB AND REST EASY, ERF 5221 BENONI EXT 14, 16 BAYLEY COURT BAYLEY STREET, FARRARMERE, BENONI, South Africa','geo':{'lat':-26.17450,'lng':28.30140}},
{'stp':553280,'address':'553280, EGL - ISANDO, ISANDO HYBRID TEAM, BENONI COUNTRY CLUB, VLAKFONTEIN NO 7(GEDEELTE 27 ), BENONI (Morehill), #, BENONI, South Africa','geo':{'lat':-26.17210,'lng':28.34140}},
{'stp':553348,'address':'553348, EGL - ISANDO, ISANDO HYBRID TEAM, HOMESTEAD LIQUORS, 21 LUCY STREET, CRN WATTLE HOMESTEAD, PRIMROSE, PRIMROSE, South Africa','geo':{'lat':-26.17710,'lng':28.18850}},
{'stp':553396,'address':'553396, EGL - ISANDO, ISANDO HYBRID TEAM, KOPANONG PROTEA HOTEL, 243 GLEN GORY ROAD, BEING CONS PORTION 243 FARM, VLAKFONTEIN 30 IR, BENONI, South Africa','geo':{'lat':-26.10640,'lng':28.32040}},
{'stp':553401,'address':'553401, EGL - ISANDO, ISANDO HYBRID TEAM, KEG AND KINGFISHER, LAKEDENE CENTRE, LAKEFIELD AVENUE, BENONI, BENONI, South Africa','geo':{'lat':-26.17933,'lng':28.28585}},
{'stp':553480,'address':'553480, EGL - ISANDO, ISANDO HYBRID TEAM, ADVENTURE COUNTRY INN, ADVENTURE CARAVAN PARK, 119 MADELEY ROAD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.18680,'lng':28.22630}},
{'stp':553725,'address':'553725, EGL - ISANDO, ISANDO HYBRID TEAM, DIE WATERGAT, 24 ESSON RD, EFR 236 LILIANTON, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19250,'lng':28.21290}},
{'stp':553787,'address':'553787, EGL - ISANDO, ISANDO HYBRID TEAM, SUTRA, JAN SMUTS AVENUE, BARDENE, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17350,'lng':28.24260}},
{'stp':580777,'address':'580777, EGL - ISANDO, ISANDO HYBRID TEAM, MONGOS PORTUGUESE RESTAURANT, ERF 114 SUNNYROCK EXT 2 TOWNSHIP, NO 2 SUNROCK CLOSE SUNNYROCK EXT 2, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.16490,'lng':28.17020}},
{'stp':605967,'address':'605967, EGL - ISANDO, ISANDO HYBRID TEAM, JIMMYS KILLER PRAWNS KEY LARGO, SHOP NO 18 UPPER LEVEL KEY LARGO, SHOPPING CENTRE NORTH RAND RD CRN, TRICHARDTS RD ERF 1094 BARDENE EXT 1, BOKSBURG, South Africa','geo':{'lat':-26.17850,'lng':28.25520}},
{'stp':607365,'address':'607365, EGL - ISANDO, ISANDO HYBRID TEAM, NANDO\'S CHICKENLAND EASTRAND MALL, CNR RIETFONTEIN & BENTEL AVE, JANSEN PARK EXT 9, #, BOKSBURG, South Africa','geo':{'lat':-26.18220,'lng':28.24200}},
{'stp':607490,'address':'607490, EGL - ISANDO, ISANDO HYBRID TEAM, NANDO\'S CHICKENLAND NORTMEAD, MONA LISA SHOPPING CENTRE, CNR 6TH AVE & 1ST STREET, #, BENONI, South Africa','geo':{'lat':-26.17320,'lng':28.33120}},
{'stp':609454,'address':'609454, EGL - ISANDO, ISANDO HYBRID TEAM, OCEAN BASKET (LAKE SIDE MALL) (NTO), LAKESIDE MALL, TOM JONES STREET, BENONI, Johannesburg, BENONI, South Africa','geo':{'lat':-26.18448,'lng':28.31597}},
{'stp':610916,'address':'610916, EGL - ISANDO, ISANDO HYBRID TEAM, KNIGHT & DRAGON (NTO), TRICHARDT AVENUE, RAVENSWOOD, BOKSBURG, Johannesburg, BOKSBURG, South Africa','geo':{'lat':-26.17891,'lng':28.25431}},
{'stp':620627,'address':'620627, EGL - ISANDO, ISANDO HYBRID TEAM, BOA VIDA (NTO), 159 ELIZABETH ROAD, BARTLETT, BOKSBURG, Johannesburg, BOKSBURG, South Africa','geo':{'lat':-26.17535,'lng':28.27072}},
{'stp':625894,'address':'625894, EGL - ISANDO, ISANDO HYBRID TEAM, MIKE\'S KITCHEN BENONI, SHOP 28 RYNFIELD TERRACE, PORTIONS 161/162 OF FARM VLAKFONTEIN 69, IR CRN VLEI & PRETORIA RD RYNFIELD, BENONI, South Africa','geo':{'lat':-26.13550,'lng':28.34950}},
{'stp':627395,'address':'627395, EGL - ISANDO, ISANDO HYBRID TEAM, GLYNWOOD LIQUOR STORE, GLYNNWOOD CENTRE, 130 AMPTHILL AVE SHOP 4 ERF 1654, #, BENONI, South Africa','geo':{'lat':-26.19600,'lng':28.30900}},
{'stp':628937,'address':'628937, EGL - ISANDO, ISANDO HYBRID TEAM, MARLANDS LIQUOR STORE, SHOP 7A EREENE SHOPPING CENTRE, CRN 2ND & 3RD AVE STANDS 24-26, #, PRIMROSE, South Africa','geo':{'lat':-26.18135,'lng':28.19332}},
{'stp':636918,'address':'636918, EGL - ISANDO, ISANDO HYBRID TEAM,  COOLZ CRAFTY CORNER, 684 LAKEDENE SHOPPING CENTRE, LAKEFIELD AVENUE, LAKEFIELD EXT 39, BENONI, BENONI, South Africa','geo':{'lat':-26.17860,'lng':28.28560}},
{'stp':639231,'address':'639231, EGL - ISANDO, ISANDO HYBRID TEAM, CONSTANTIA PUB AND GRILL, HOTEL CONSTANTIA 53, SHAMROK ROAD PRIMROSE, GERMISTON, PRIMROSE, South Africa','geo':{'lat':-26.19150,'lng':28.16040}},
{'stp':639731,'address':'639731, EGL - ISANDO, ISANDO HYBRID TEAM, GIG TYD (NTO), C/O NORTH RAND RD & RONDEBULT, #, #, BOKSBURG, South Africa','geo':{'lat':-26.17880,'lng':28.24362}},
{'stp':640823,'address':'640823, EGL - ISANDO, ISANDO HYBRID TEAM, NORKEM CELLARS, SHOP1 NORKEM MALL, NORKEM PARK, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.06280,'lng':28.22050}},
{'stp':642054,'address':'642054, EGL - ISANDO, ISANDO HYBRID TEAM, LILLIANTON LIQUOR STORE, 503 ERF NO 503, SHOP NO 2, MAIN REEF ROAD, LILLIANTON, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19820,'lng':28.21550}},
{'stp':646488,'address':'646488, EGL - ISANDO, ISANDO HYBRID TEAM, BUBBA JS SPORTS AND COMEDY CAFE, FINCH ROAD SUMMERFIELD CENTRE, #, #, BENONI, South Africa','geo':{'lat':-26.16047,'lng':28.28059}},
{'stp':647231,'address':'647231, EGL - ISANDO, ISANDO HYBRID TEAM, QUARTER DECK PUT AND GRILL, 1799 STAND NO, 201 ELSTON AVENUE, CORNER RUSSEL, BENONI, BENONI, South Africa','geo':{'lat':-26.19320,'lng':28.30400}},
{'stp':648808,'address':'648808, EGL - ISANDO, ISANDO HYBRID TEAM, CONTINENTAL CAFE, ERF 201,80 PRETORIA ROAD, RYNFIELD, #, BENONI, South Africa','geo':{'lat':-26.15886,'lng':28.33287}},
{'stp':651874,'address':'651874, EGL - ISANDO, ISANDO HYBRID TEAM, O TIAGOS PORTUGESE RESTAURANT, WITFIELD CENTRE, SHOP NO 6, NO 58 MAIN STREET, WITFIELD, BOKSBURG, South Africa','geo':{'lat':-26.18404,'lng':28.20633}},
{'stp':661391,'address':'661391, EGL - ISANDO, ISANDO HYBRID TEAM, OAK AVENUE LIQUOR STORE, 99A OAKLAAN, #, PRIMROSE-OOS, PRIMROSE, South Africa','geo':{'lat':-26.18430,'lng':28.18690}},
{'stp':665025,'address':'665025, EGL - ISANDO, ISANDO HYBRID TEAM, ORO BELLA (NTO), CNR 11TH AVE & CHARLES CILLIERS ST, CNR 11TH AVE & CHARLES CILLIERS ST, CNR 11TH AVE & CHARLES CILLIERS ST, BOKSBURG, South Africa','geo':{'lat':-26.20312,'lng':28.25978}},
{'stp':665029,'address':'665029, EGL - ISANDO, ISANDO HYBRID TEAM, PERRYS PUB AND GRILL (NTO), 218 MONUMENT ROAD, GLEN MARAIS, GLEN MARAIS, KEMPTON PARK, South Africa','geo':{'lat':-26.07608,'lng':28.25539}},
{'stp':665268,'address':'665268, EGL - ISANDO, ISANDO HYBRID TEAM, MUGG AND BEAN EASTRAND MALL (NTO), EASTRAND MALL, CNR NORTH RAND RD & BENTEL AVE, CNR NORTH RAND RD & BENTEL AVE, BOKSBURG, South Africa','geo':{'lat':-26.18107,'lng':28.23933}},
{'stp':665269,'address':'665269, EGL - ISANDO, ISANDO HYBRID TEAM, PANAROTTI\'S EASTRAND MALL (NTO), EASTRAND MALL, CNR NORTH RAND RD & BENTEL AVE, CNR NORTH RAND RD & BENTEL AVE, BOKSBURG, South Africa','geo':{'lat':-26.18123,'lng':28.23997}},
{'stp':665270,'address':'665270, EGL - ISANDO, ISANDO HYBRID TEAM, CAPPUCCINO\'S EASTRAND MALL (NTO), EASTRAND MALL, CNR NORTH RAND RD & BENTEL AVE, CNR NORTH RAND RD & BENTEL AVE, BOKSBURG, South Africa','geo':{'lat':-26.18123,'lng':28.23997}},
{'stp':668022,'address':'668022, EGL - ISANDO, ISANDO HYBRID TEAM, GRAPES PUB AND RESTAURANT, 17 STAND NO, RUSSELL STREET WESTERN EXTENSION, #, BENONI, South Africa','geo':{'lat':-26.19150,'lng':28.30330}},
{'stp':668264,'address':'668264, EGL - ISANDO, ISANDO HYBRID TEAM, OCEAN BASKET BOKSBURG (NTO), EASTRAND MALL, CNR NORTH RAND ROAD AND BENTEL AVE, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17965,'lng':28.24459}},
{'stp':668288,'address':'668288, EGL - ISANDO, ISANDO HYBRID TEAM, MUGG AND BEAN BENONI (NTO), LAKESIDE MALL, LAKESIDE MALL, BENONI, BENONI, South Africa','geo':{'lat':-26.18610,'lng':28.31350}},
{'stp':669291,'address':'669291, EGL - ISANDO, ISANDO HYBRID TEAM, EBOTSE GOLF AND COUNTRY ESTATE, ERF 3371 AND 3372, #, RYNFIELD EXTENSION 55, BENONI, South Africa','geo':{'lat':-26.14690,'lng':28.35220}},
{'stp':671524,'address':'671524, EGL - ISANDO, ISANDO HYBRID TEAM, BIRCHWOOD HOTEL (NTO), VIEWPOINT STREET, BARTLETT, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17551,'lng':28.25051}},
{'stp':671885,'address':'671885, EGL - ISANDO, ISANDO HYBRID TEAM, LIQUOR NET, 0 CNR SYDNEY AND NORTH RAND ROADS, SHOP NO 1, EVERLEIGH, BOKSBURG, South Africa','geo':{'lat':-26.18127,'lng':28.24703}},
{'stp':673472,'address':'673472, EGL - ISANDO, ISANDO HYBRID TEAM, ADEGA BOKSBURG (NTO), SHOP 1 WESTWOOD VILLAGE, SHOP 1 WESTWOOD VILLAGE, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.18480,'lng':28.28080}},
{'stp':673474,'address':'673474, EGL - ISANDO, ISANDO HYBRID TEAM, LEMON GRASS (NTO), CNR HOWARD AND RUSSEL ROAD, CNR HOWARD AND RUSSEL ROAD, BENONI, BENONI, South Africa','geo':{'lat':-26.19372,'lng':28.30482}},
{'stp':686899,'address':'686899, EGL - ISANDO, ISANDO HYBRID TEAM, LUSO AFRICA (NTO), HATTINGH PARK, WALNUT ROAD, PRIMROSE, PRIMROSE, South Africa','geo':{'lat':-26.17866,'lng':28.23478}},
{'stp':689555,'address':'689555, EGL - ISANDO, ISANDO HYBRID TEAM, GALITOS TASCA NORTHMEAD, SHOP NO 18 19 AND 20, MONA LISA CENTRE CORNER 1st STREET AND, 6th AVENUE NORTHMEAD BEING SITUATED ON, BENONI, South Africa','geo':{'lat':-26.17320,'lng':28.33100}},
{'stp':696442,'address':'696442, EGL - ISANDO, ISANDO HYBRID TEAM, WESTERN EXTENTON LIQUOR STORE, URIZIMA 58 CC, T/A WESTERN EXTENTON LIQUOR STORE, CNR RUSSEL STREET AND ELSTON AVENUE, BENONI, South Africa','geo':{'lat':-26.19304,'lng':28.30375}},
{'stp':696466,'address':'696466, EGL - ISANDO, ISANDO HYBRID TEAM, DAMIO RESTAURANT (NTO), 144 RIDGE ROAD, BARLETT, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17923,'lng':28.25672}},
{'stp':696529,'address':'696529, EGL - ISANDO, ISANDO HYBRID TEAM, FERREIRA\'S SPORTS BAR (NTO), 78 SETT STREET, JANSEN PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19153,'lng':28.24133}},
{'stp':696966,'address':'696966, EGL - ISANDO, ISANDO HYBRID TEAM, BIG E\'S PUB AND GRILL (NTO), 76A OAK AVENUE, GERMISTON, #, PRIMROSE, South Africa','geo':{'lat':-26.18471,'lng':28.17264}},
{'stp':696973,'address':'696973, EGL - ISANDO, ISANDO HYBRID TEAM, EAST RAND ITALIAN CLUB (NTO), 74 MIDDLE ROAD, BOKSBURG, #, BOKSBURG, South Africa','geo':{'lat':-26.22284,'lng':28.25528}},
{'stp':696978,'address':'696978, EGL - ISANDO, ISANDO HYBRID TEAM, MIMMOS BENONI (NTO), 9 6TH AVENUE, NORTHMEAD, BENONI, BENONI, South Africa','geo':{'lat':-26.17388,'lng':28.32938}},
{'stp':698302,'address':'698302, EGL - ISANDO, ISANDO HYBRID TEAM, AZZURRI PIZZA CAFE FRANCISING CC, SHOP NO 02, EL RIDGE CNR, CNR ELIZABETH ROAD AND RIDGE ROAD, BOKSBURG, South Africa','geo':{'lat':-26.16800,'lng':28.27360}},
{'stp':700922,'address':'700922, EGL - ISANDO, ISANDO HYBRID TEAM, CYDONIA LIQUOR STORE, ERF NO 406, PRIMROSE HILL MAGNOLIA COURT NO 123, CYDONIA ROAD CNR MAGNOLIA STREET, PRIMROSE, South Africa','geo':{'lat':-26.19465,'lng':28.13888}},
{'stp':701450,'address':'701450, EGL - ISANDO, ISANDO HYBRID TEAM, CURRY LEAF RESTAURANT, 9 SIXTH AVENUE, ERF 2195 SHOP 1A, #, BENONI, South Africa','geo':{'lat':-26.17370,'lng':28.32900}},
{'stp':701673,'address':'701673, EGL - ISANDO, ISANDO HYBRID TEAM, BEYERSPARK LIQUOR STORE, ERF NO 404, BEYERSPARK CENTRE CNR BARTLET AND, NORTH STREETS BEYERS PARK, BOKSBURG, South Africa','geo':{'lat':-26.19020,'lng':28.26370}},
{'stp':708786,'address':'708786, EGL - ISANDO, ISANDO HYBRID TEAM, THE HOUSE OF RIBS FAMILY RESTAURANT, PTN 137 OF THE FARM KLIPFONTEIN, 83 IR THE TOWERS SHOPPING CENTRE, CNR NORTH RAND AND BENTEL STREET, BOKSBURG, South Africa','geo':{'lat':-26.17890,'lng':28.24250}},
{'stp':709861,'address':'709861, EGL - ISANDO, ISANDO HYBRID TEAM, PADDY S SPORTS BAR, DAYAN PARK SHOPPING CENTRE, SHOP 6 AND 7 DAYAN STREET, #, BOKSBURG, South Africa','geo':{'lat':-26.18911,'lng':28.22877}},
{'stp':710187,'address':'710187, EGL - ISANDO, ISANDO HYBRID TEAM, LOCAL GRILL (NTO), 120 NORTH RAND ROAD, BOKSBURG, #, BOKSBURG, South Africa','geo':{'lat':-26.10485,'lng':28.15025}},
{'stp':711465,'address':'711465, EGL - ISANDO, ISANDO HYBRID TEAM, NELSONS HIDEAWAY PUB (NTO), SHOP 2, BEYERS PARK SHOPPING CENTRE, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19039,'lng':28.26390}},
{'stp':712656,'address':'712656, EGL - ISANDO, ISANDO HYBRID TEAM, MAHLALELA LIQUOR DISTRIBUTORS, PORTION 9 OF ERF 487, #, #, ISANDO, South Africa','geo':{'lat':-26.15820,'lng':28.20650}},
{'stp':717891,'address':'717891, EGL - ISANDO, ISANDO HYBRID TEAM, OLD BENS SPORTS CLUB (NTO), CNR PRESIDENT BOSHOFF ROAD AND, PRESIDENT BRAND STREET, RYNEVELD, BENONI, South Africa','geo':{'lat':-26.14186,'lng':28.32104}},
{'stp':718995,'address':'718995, EGL - ISANDO, ISANDO HYBRID TEAM, LIZARDS PUB AND GRILL (NTO), 191 TRICHARD ROAD, RAVENSWOOD, #, BOKSBURG, South Africa','geo':{'lat':-26.19282,'lng':28.25332}},
{'stp':722368,'address':'722368, EGL - ISANDO, ISANDO HYBRID TEAM, KLEINFONTEIN BOWLING CLUB (NTO), WOODPECKER STREET, MACKENZIE PARK, BENONI, BENONI, South Africa','geo':{'lat':-26.19636,'lng':28.33340}},
{'stp':728128,'address':'728128, EGL - ISANDO, ISANDO HYBRID TEAM, DAYANGLEN LIQUORS, STAND NO, DAYAN RD PORTION 1 OF ERF 2, SHOP 6 GREENLEAVES VILALGE DAYANGLEN, BOKSBURG, South Africa','geo':{'lat':-26.18926,'lng':28.22864}},
{'stp':740842,'address':'740842, EGL - ISANDO, ISANDO HYBRID TEAM, PAPA BOA (NTO), LAKEFIELD ROAD, ERF 787 LAKEFIELD SQUARE, LAKEFIELD EXT 26, BENONI, South Africa','geo':{'lat':-26.17900,'lng':28.28720}},
{'stp':742633,'address':'742633, EGL - ISANDO, ISANDO HYBRID TEAM, TOP SHOTS (NTO), TRICHARDT AVENUE, RAVENSWOOD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17891,'lng':28.25431}},
{'stp':743511,'address':'743511, EGL - ISANDO, ISANDO HYBRID TEAM, SWELEBE TRADING PROJECTS, 33  LINCOLN ROAD, R14448, #, BENONI, South Africa','geo':{'lat':-26.18650,'lng':28.30640}},
{'stp':745588,'address':'745588, EGL - ISANDO, ISANDO HYBRID TEAM, ALASKA SPUR (NTO), CNR FRENCH AND ATLAS RD, BOKSBURG, #, BENONI, South Africa','geo':{'lat':-26.15917,'lng':28.28176}},
{'stp':750134,'address':'750134, EGL - ISANDO, ISANDO HYBRID TEAM, RAZZLE@556 (NTO), 556 TRICHARDT RD, BOKSBURG, #, BOKSBURG, South Africa','geo':{'lat':-26.19900,'lng':28.25387}},
{'stp':754759,'address':'754759, EGL - ISANDO, ISANDO HYBRID TEAM, THE ZONE (NTO), 3/273 POMONA ROAD KEMPTON PARK, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.09274,'lng':28.26424}},
{'stp':775598,'address':'775598, EGL - ISANDO, ISANDO HYBRID TEAM, PARROTS EASTGATE MALL (NTO), SHOP 90 B EAST RAND MALL, BENTEL ROAD, BOKSBURG NORTH, BOKSBURG, South Africa','geo':{'lat':-26.18250,'lng':28.24150}},
{'stp':778071,'address':'778071, EGL - ISANDO, ISANDO HYBRID TEAM, TILT (NTO), CNR CELIA NESTADT & STOKROOS STREET, NORHTVILLA, BENONI, BENONI, South Africa','geo':{'lat':-26.13828,'lng':28.30127}},
{'stp':788307,'address':'788307, EGL - ISANDO, ISANDO HYBRID TEAM, TURN N TENDER BOKSBURG (NTO), SHOP 5 K90 CENTRE, CNR RONDEBULT & NORTHRAND RDS, JANSEN PARK, BOKSBURG, South Africa','geo':{'lat':-26.18030,'lng':28.24478}},
{'stp':793701,'address':'793701, EGL - ISANDO, ISANDO HYBRID TEAM, DIE HOUTHUIS, 33 MAIN STREET, ERF NO 01 OF 55, WITFIELD, BOKSBURG, South Africa','geo':{'lat':-26.20036,'lng':28.25404}},
{'stp':795579,'address':'795579, EGL - ISANDO, ISANDO HYBRID TEAM, PABLO LOPEZ\'S MEXICAN CANTINA (NTO), MONA LISA CENTER, CNR 6TH AVE AND 2ND STREET, NORTHMEAD, BENONI, South Africa','geo':{'lat':-26.17369,'lng':28.32952}},
{'stp':314539,'address':'314539, EGL - ISANDO, ISANDO HYBRID TEAM, QUANTUM TAVERN, 6146 STAND NO, KHETHIWE STREET, ETWATWA EXTENSION 03, DAVEYTON, South Africa','geo':{'lat':-26.11955,'lng':28.45684}},
{'stp':386729,'address':'386729, EGL - ISANDO, ISANDO HYBRID TEAM, NONTSUKU TAVERN, 1198 STAND NUMBER, AMON STREET, CHRIS HANI TOWNSHIP, DAVEYTON, South Africa','geo':{'lat':-26.13365,'lng':28.43902}},
{'stp':391248,'address':'391248, EGL - ISANDO, ISANDO HYBRID TEAM, NONDUMISO GP TAVERN, 3538 STAND NO, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12236,'lng':28.42880}},
{'stp':391597,'address':'391597, EGL - ISANDO, ISANDO HYBRID TEAM, MOEKETSI S PLACE, 5 1759 MAYFIELD EXTENSION, #, #, DAVEYTON, South Africa','geo':{'lat':-26.11997,'lng':28.43257}},
{'stp':391627,'address':'391627, EGL - ISANDO, ISANDO HYBRID TEAM, GA JOHNNY PUB JAZZ INN, 5621 STAND NO, EISELEN STREET, #, DAVEYTON, South Africa','geo':{'lat':-26.13419,'lng':28.43228}},
{'stp':392043,'address':'392043, EGL - ISANDO, ISANDO HYBRID TEAM, TNR BOTTLE STORE, 12352 STAND NO, KGWADUDA STREET, #, DAVEYTON, South Africa','geo':{'lat':-26.14297,'lng':28.42911}},
{'stp':392573,'address':'392573, EGL - ISANDO, ISANDO HYBRID TEAM, LA MATTS INN, 6767 JUMBA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13343,'lng':28.43098}},
{'stp':392581,'address':'392581, EGL - ISANDO, ISANDO HYBRID TEAM, FANAFUTHIS PLACE, 442 CHRIS HANI, EXT 1 BENONI, #, DAVEYTON, South Africa','geo':{'lat':-26.10866,'lng':28.47920}},
{'stp':466054,'address':'466054, EGL - ISANDO, ISANDO HYBRID TEAM, BHEKIS PLACE, 601 STAND NO, VIVIAN DRIVE, CHRIS HANI SECTION, DAVEYTON, South Africa','geo':{'lat':-26.14186,'lng':28.43373}},
{'stp':468645,'address':'468645, EGL - ISANDO, ISANDO HYBRID TEAM, MUZETE LIQUOR STORE, MAYFIELD STREET, ERF 3856, MAYFIELD EXT 6 DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12643,'lng':28.42851}},
{'stp':481134,'address':'481134, EGL - ISANDO, ISANDO HYBRID TEAM, ZANOVAS TAVERN, 22134 STAND NO, MADIBA STREET, ETWATWA EXT 32, DAVEYTON, South Africa','geo':{'lat':-26.10858,'lng':28.44760}},
{'stp':485211,'address':'485211, EGL - ISANDO, ISANDO HYBRID TEAM, MACARENA PLACE, 13214 STAND NUMBER, MTSALI STR, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12828,'lng':28.44528}},
{'stp':550308,'address':'550308, EGL - ISANDO, ISANDO HYBRID TEAM, MOJO\'S TAVERN, 6456 DUSHANA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13830,'lng':28.42410}},
{'stp':550356,'address':'550356, EGL - ISANDO, ISANDO HYBRID TEAM, MATSHA M, 5903 TURTON STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13871,'lng':28.42288}},
{'stp':550870,'address':'550870, EGL - ISANDO, ISANDO HYBRID TEAM, LEKGOLOKWE TAVERN, 12735 PAI STREET, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13720,'lng':28.43200}},
{'stp':550934,'address':'550934, EGL - ISANDO, ISANDO HYBRID TEAM, BHEKISIZWE BOTTLE STORE, erf 23404 CRN EMPILWENI STREET &, KOPPIE MOTEBANG AVE NORTH ETWATWA, EXT 32, DAVEYTON, South Africa','geo':{'lat':-26.11290,'lng':28.45240}},
{'stp':551418,'address':'551418, EGL - ISANDO, ISANDO HYBRID TEAM, BOBO S TAVERN, 6181 MOCKE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13960,'lng':28.42470}},
{'stp':551618,'address':'551618, EGL - ISANDO, ISANDO HYBRID TEAM, THEMBALITHE REST, STAND 7061, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13060,'lng':28.43330}},
{'stp':551878,'address':'551878, EGL - ISANDO, ISANDO HYBRID TEAM, LOVE CORNER, 7534 MANGOSUTHU STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12780,'lng':28.44360}},
{'stp':552522,'address':'552522, EGL - ISANDO, ISANDO HYBRID TEAM, JOBE BOTTLE STORE, ERF 5662 C/O EISELEN STR, MADUNA STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13180,'lng':28.43500}},
{'stp':552681,'address':'552681, EGL - ISANDO, ISANDO HYBRID TEAM, TSHABALALA MB, 1483 BESTER HOMES, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.11760,'lng':28.46140}},
{'stp':552910,'address':'552910, EGL - ISANDO, ISANDO HYBRID TEAM, HILLS TAVERN, 13284 LEKENO STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12900,'lng':28.44610}},
{'stp':552949,'address':'552949, EGL - ISANDO, ISANDO HYBRID TEAM, LANGA M, 1366 IKAGENG STREET, TWATWA, EXT 2 DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11600,'lng':28.45800}},
{'stp':553352,'address':'553352, EGL - ISANDO, ISANDO HYBRID TEAM, MAJOROS SQUARE INN, 13420 MOLOKO STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13020,'lng':28.44540}},
{'stp':553724,'address':'553724, EGL - ISANDO, ISANDO HYBRID TEAM, QUPE RM, 1952 SIBOYANA STREET, THULANI VILLAGE, ETWATWA DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11147,'lng':28.44817}},
{'stp':557076,'address':'557076, EGL - ISANDO, ISANDO HYBRID TEAM, MOREMI S CABIN, 15589 TURTON CABIN EXT 3, #, #, DAVEYTON, South Africa','geo':{'lat':-26.13734,'lng':28.42079}},
{'stp':560181,'address':'560181, EGL - ISANDO, ISANDO HYBRID TEAM, DLUDLU LS, 6492 DUSHANE STREET, DAVEYTON,  (Ninos Place), DAVEYTON, South Africa','geo':{'lat':-26.13680,'lng':28.42390}},
{'stp':560185,'address':'560185, EGL - ISANDO, ISANDO HYBRID TEAM, PELEPELE S PLACE, 3720 KHAYA STREET, MINA NAWE, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12530,'lng':28.42640}},
{'stp':560188,'address':'560188, EGL - ISANDO, ISANDO HYBRID TEAM, MBATHA SEM, 3353 MAYFIELD, EXT 5, #, DAVEYTON, South Africa','geo':{'lat':-26.11990,'lng':28.43150}},
{'stp':560296,'address':'560296, EGL - ISANDO, ISANDO HYBRID TEAM, NHLAPO LD, 6405 DIDI STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.13720,'lng':28.42250}},
{'stp':560375,'address':'560375, EGL - ISANDO, ISANDO HYBRID TEAM, PONTSO PLACE, 7774 MATSOBANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14500,'lng':28.42990}},
{'stp':560435,'address':'560435, EGL - ISANDO, ISANDO HYBRID TEAM, MAKOLANE KA, 1748 TSHABALALA STREET, ZENZELE, #, DAVEYTON, South Africa','geo':{'lat':-26.12760,'lng':28.42030}},
{'stp':560485,'address':'560485, EGL - ISANDO, ISANDO HYBRID TEAM, NDLOVU J, 24764 BARCELONA EXT 5, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.11470,'lng':28.45490}},
{'stp':561226,'address':'561226, EGL - ISANDO, ISANDO HYBRID TEAM, MBATHA R, 1378 REGGIE STREET, DAVEYTON (Chris Hani), #, DAVEYTON, South Africa','geo':{'lat':-26.13210,'lng':28.43890}},
{'stp':561266,'address':'561266, EGL - ISANDO, ISANDO HYBRID TEAM, MBATA KI, 3285 MAYFIELD, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.11950,'lng':28.43360}},
{'stp':580991,'address':'580991, EGL - ISANDO, ISANDO HYBRID TEAM, MASINA NC, 23571 BARCELONA EXT 32, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.11040,'lng':28.45070}},
{'stp':581106,'address':'581106, EGL - ISANDO, ISANDO HYBRID TEAM, LONGWALK SPORTS BAR KWAKHUMALO, 6199 HAMILTON STREET, C 199, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13280,'lng':28.41980}},
{'stp':602868,'address':'602868, EGL - ISANDO, ISANDO HYBRID TEAM, ARNOS PETRUS MAKOFANE, 1040 DLAMINI CRESCENT, CHRIS HANI, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12940,'lng':28.44130}},
{'stp':603555,'address':'603555, EGL - ISANDO, ISANDO HYBRID TEAM, ZITHA MD, 7708 MONTANYANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14510,'lng':28.42930}},
{'stp':603557,'address':'603557, EGL - ISANDO, ISANDO HYBRID TEAM, MOESI KE, 3591 KHAYA STREET, MINA NAWE, #, DAVEYTON, South Africa','geo':{'lat':-26.12350,'lng':28.42620}},
{'stp':603980,'address':'603980, EGL - ISANDO, ISANDO HYBRID TEAM, LONG WALK SPORTS BAR, 3617 MINA NAWE, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12430,'lng':28.42570}},
{'stp':604905,'address':'604905, EGL - ISANDO, ISANDO HYBRID TEAM, NOBADULA KP, 7216 TAHLE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12603,'lng':28.43957}},
{'stp':605836,'address':'605836, EGL - ISANDO, ISANDO HYBRID TEAM, MOIKANGOE KP, 60861 THOBEJANE CRESCENT, EXT 3, #, DAVEYTON, South Africa','geo':{'lat':-26.13300,'lng':28.42430}},
{'stp':606341,'address':'606341, EGL - ISANDO, ISANDO HYBRID TEAM, MASEKO A, 1523 NOFANESILE SECTION, BESTER ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.11870,'lng':28.45940}},
{'stp':606813,'address':'606813, EGL - ISANDO, ISANDO HYBRID TEAM, MAELANE KM, 2049 CHRIS HANI EXT 1, #, #, DAVEYTON, South Africa','geo':{'lat':-26.13360,'lng':28.44410}},
{'stp':606887,'address':'606887, EGL - ISANDO, ISANDO HYBRID TEAM, HLOPHE T, 1260 SABATHA STREET, CHRIS HANI EXT 03, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13438,'lng':28.43905}},
{'stp':606888,'address':'606888, EGL - ISANDO, ISANDO HYBRID TEAM, MAHLANGU W, 5923 TURTON STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13805,'lng':28.42203}},
{'stp':613089,'address':'613089, EGL - ISANDO, ISANDO HYBRID TEAM, DAISY (NTO), 4645 KHUMALO STR, DAVEYTON, Johannesburg, DAVEYTON, South Africa','geo':{'lat':-26.13957,'lng':28.42088}},
{'stp':625889,'address':'625889, EGL - ISANDO, ISANDO HYBRID TEAM, UYOZE AWUCHITHE, 1145 REGGIE STREET, CHRIS HANI, #, DAVEYTON, South Africa','geo':{'lat':-26.13180,'lng':28.44050}},
{'stp':627034,'address':'627034, EGL - ISANDO, ISANDO HYBRID TEAM, TSIDI\'S PLACE, 31075 MAWELA STREET, ZENZELE, #, DAVEYTON, South Africa','geo':{'lat':-26.12900,'lng':28.43230}},
{'stp':627909,'address':'627909, EGL - ISANDO, ISANDO HYBRID TEAM, DAVEYTON GOLF CLUB, EISELAN AND SIBEKO STR, 73IR, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12768,'lng':28.44263}},
{'stp':628043,'address':'628043, EGL - ISANDO, ISANDO HYBRID TEAM, EMAZULWINI, 5678 MADUNA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14209,'lng':28.42541}},
{'stp':628044,'address':'628044, EGL - ISANDO, ISANDO HYBRID TEAM, OUPAS PLACE, 1732 STAND NO, BARLELOMA DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.11338,'lng':28.44592}},
{'stp':628056,'address':'628056, EGL - ISANDO, ISANDO HYBRID TEAM, LANGAS PLACE, 2274 SEISO STREET(Chris Hanni str), EXTENTION 1, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13550,'lng':28.43610}},
{'stp':628057,'address':'628057, EGL - ISANDO, ISANDO HYBRID TEAM, MAVUSANAS PLACE, 606 NTOKOZO STREET, ZENZELE, #, DAVEYTON, South Africa','geo':{'lat':-26.13120,'lng':28.42000}},
{'stp':628692,'address':'628692, EGL - ISANDO, ISANDO HYBRID TEAM, MAJOZI TAVERN, 21592 BARCELONA, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.13660,'lng':28.24110}},
{'stp':628698,'address':'628698, EGL - ISANDO, ISANDO HYBRID TEAM, PHAKS PLACE, STAND NO, ERF 3035 PALLO JORDAN, MAYFIELD EXTENSION 5, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.11782,'lng':28.43590}},
{'stp':629252,'address':'629252, EGL - ISANDO, ISANDO HYBRID TEAM, FLO\'S PLACE, 6204 MOCKE STREET, DAVEYTON, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13824,'lng':28.42617}},
{'stp':629253,'address':'629253, EGL - ISANDO, ISANDO HYBRID TEAM, SIBIYAS PLACE, 2098 CHRIS HANI STREET, EXTENTION 4, #, DAVEYTON, South Africa','geo':{'lat':-26.13430,'lng':28.44240}},
{'stp':629545,'address':'629545, EGL - ISANDO, ISANDO HYBRID TEAM, MOLOTOS PLACE, 6342 KHESWA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.13580,'lng':28.43060}},
{'stp':630001,'address':'630001, EGL - ISANDO, ISANDO HYBRID TEAM, NOMABHUNUS PLACE, 7111 KHOLWA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13190,'lng':28.43130}},
{'stp':630002,'address':'630002, EGL - ISANDO, ISANDO HYBRID TEAM, MARIAS PLACE, 7739 MOTANYANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14680,'lng':28.43020}},
{'stp':630415,'address':'630415, EGL - ISANDO, ISANDO HYBRID TEAM, MATI YO TENGA, 2824 MAYFIELD, EXTENSION 5, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11862,'lng':28.43290}},
{'stp':630525,'address':'630525, EGL - ISANDO, ISANDO HYBRID TEAM, SOMBUS PLACE, 22316 BARCELONA, EXTENSION 32, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10830,'lng':28.45040}},
{'stp':630528,'address':'630528, EGL - ISANDO, ISANDO HYBRID TEAM, EMELYS PLACE, 23487 BARCELONA (house num 23), EXTENSION 32, ETWATWA DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11059,'lng':28.44824}},
{'stp':630831,'address':'630831, EGL - ISANDO, ISANDO HYBRID TEAM, DOCTOR LUBISI, 1482 BARCELONA EXT 23 (21768), DAVEYTON BENONI, JOHANNESBURG, DAVEYTON, South Africa','geo':{'lat':-26.10930,'lng':28.44570}},
{'stp':631327,'address':'631327, EGL - ISANDO, ISANDO HYBRID TEAM, RANGERS INN, 24489 EMANDLENI STREET, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.11570,'lng':28.45100}},
{'stp':631801,'address':'631801, EGL - ISANDO, ISANDO HYBRID TEAM, BARCELONA TAVERN, LONDON CITY ROAD HOUSE NO 22286, ETWA TWA EXT 22 BUTCHERY BARCELONA, #, DAVEYTON, South Africa','geo':{'lat':-26.10930,'lng':28.45200}},
{'stp':632006,'address':'632006, EGL - ISANDO, ISANDO HYBRID TEAM, CORNER SS, 12045 BARWA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14240,'lng':28.42720}},
{'stp':632365,'address':'632365, EGL - ISANDO, ISANDO HYBRID TEAM, PRINCESS PLACE, 1154 REGGIE STREET, EXT 1 CHRIS HANI, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13420,'lng':28.43690}},
{'stp':633597,'address':'633597, EGL - ISANDO, ISANDO HYBRID TEAM, EBHARENI PLACE, 1105 NOLO STREET, ZENZELE STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13250,'lng':28.41380}},
{'stp':634517,'address':'634517, EGL - ISANDO, ISANDO HYBRID TEAM, GLADS PLACE, 13157 MADELA STR, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12760,'lng':28.44530}},
{'stp':635442,'address':'635442, EGL - ISANDO, ISANDO HYBRID TEAM, EDDYS PLACE, 23719 BARCELONA, EXTENSION 23, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11030,'lng':28.45120}},
{'stp':637178,'address':'637178, EGL - ISANDO, ISANDO HYBRID TEAM, SELINAS SHEBEEN, 6123 STAND NO, MABASO STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14070,'lng':28.42370}},
{'stp':637963,'address':'637963, EGL - ISANDO, ISANDO HYBRID TEAM, NONDZABAS PLACE, 31221 DUKUZA STREET, ZENZELE SECTION, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12750,'lng':28.43350}},
{'stp':637972,'address':'637972, EGL - ISANDO, ISANDO HYBRID TEAM, CHINA INN, 13460 MAISELA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13140,'lng':28.44330}},
{'stp':638642,'address':'638642, EGL - ISANDO, ISANDO HYBRID TEAM, NOMVULA PLACE, 1060 DLAMINI 56  CHRIS HANI, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13058,'lng':28.44201}},
{'stp':639396,'address':'639396, EGL - ISANDO, ISANDO HYBRID TEAM, KANCANE, 6026 MAZIBUKO STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13960,'lng':28.42770}},
{'stp':639631,'address':'639631, EGL - ISANDO, ISANDO HYBRID TEAM, MASAKANE, 6892 STAND NO, MAYFIELD EXT 8, #, DAVEYTON, South Africa','geo':{'lat':-26.11610,'lng':28.42030}},
{'stp':640111,'address':'640111, EGL - ISANDO, ISANDO HYBRID TEAM, DA MO, 7499 JUMBA STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.12720,'lng':28.43720}},
{'stp':640577,'address':'640577, EGL - ISANDO, ISANDO HYBRID TEAM, DODO\'S PLACE, 8080 SAME STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.14127,'lng':28.42649}},
{'stp':641060,'address':'641060, EGL - ISANDO, ISANDO HYBRID TEAM, VUSIS PLACE, 3566 MAYFIELD,MINA NAWE, EXT 05, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12460,'lng':28.42740}},
{'stp':641231,'address':'641231, EGL - ISANDO, ISANDO HYBRID TEAM, WINNIE\'S PLACE, 4871 KHESWA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.14010,'lng':28.42280}},
{'stp':642464,'address':'642464, EGL - ISANDO, ISANDO HYBRID TEAM, KAREL\'S PLACE, 6914 MDLULI STREET num 6914, MDLULI STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13320,'lng':28.43140}},
{'stp':642640,'address':'642640, EGL - ISANDO, ISANDO HYBRID TEAM, PRISCILLA MOKOENA, 7247 KWALO STREET  num 7247, KWALO STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12962,'lng':28.43357}},
{'stp':646469,'address':'646469, EGL - ISANDO, ISANDO HYBRID TEAM, SEDIBANE\'S PLACE, 5647 EISELEN STREET  num 5647, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13240,'lng':28.43420}},
{'stp':646512,'address':'646512, EGL - ISANDO, ISANDO HYBRID TEAM, KHOZA JM, 259 FREEDOM STREET   num 259, EXTENSION 1, MAYFIELD, DAVEYTON, South Africa','geo':{'lat':-26.13380,'lng':28.41050}},
{'stp':646587,'address':'646587, EGL - ISANDO, ISANDO HYBRID TEAM, RIA\'S PLACE, 6447 DIDI STREET  num 6447, -, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13810,'lng':28.42360}},
{'stp':646606,'address':'646606, EGL - ISANDO, ISANDO HYBRID TEAM, TOLO\'S PLACE, 7522 JUMBA STREET  num 7522, -, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12610,'lng':28.43940}},
{'stp':646657,'address':'646657, EGL - ISANDO, ISANDO HYBRID TEAM, RAGGA\'S PLACE, 7231 KWALO STREET, -, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12903,'lng':28.43479}},
{'stp':646717,'address':'646717, EGL - ISANDO, ISANDO HYBRID TEAM, MATHABO\'S PLACE, 12125 BARWA STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13530,'lng':28.43240}},
{'stp':646722,'address':'646722, EGL - ISANDO, ISANDO HYBRID TEAM, MNISI\'S PLACE, 6594 GAZINI STREET, GAZINI STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13710,'lng':28.42050}},
{'stp':646724,'address':'646724, EGL - ISANDO, ISANDO HYBRID TEAM, MARIA NKOSI LIQUOR STORE, 5609 MOEKE STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13660,'lng':28.43060}},
{'stp':646934,'address':'646934, EGL - ISANDO, ISANDO HYBRID TEAM, EMMAS PLACE, 7683 MOKWAPANE SECTION   num 7683, MOKWAPANE SECTION, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.14470,'lng':28.42880}},
{'stp':647133,'address':'647133, EGL - ISANDO, ISANDO HYBRID TEAM, AMOS TAVERN, 699 MOCKE STREET C num 699, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13880,'lng':28.44150}},
{'stp':649852,'address':'649852, EGL - ISANDO, ISANDO HYBRID TEAM, PINKIES PLACE, 21639 STAND NO, BARCELONA SECTION, ETWATWA EXT 32 DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10778,'lng':28.44672}},
{'stp':651738,'address':'651738, EGL - ISANDO, ISANDO HYBRID TEAM, LOVE CORNER, 3040 MINA NAWE, MAYFIELD, #, DAVEYTON, South Africa','geo':{'lat':-26.11830,'lng':28.43590}},
{'stp':652407,'address':'652407, EGL - ISANDO, ISANDO HYBRID TEAM, LUCKY 7, 24274 KOPI MOTIBANG, ETWATWA EXT 32, #, DAVEYTON, South Africa','geo':{'lat':-26.11317,'lng':28.44754}},
{'stp':653333,'address':'653333, EGL - ISANDO, ISANDO HYBRID TEAM, SKOTIPHOLA LIQUOR STORE, ERF 13356 CHIAWELO STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12990,'lng':28.44560}},
{'stp':662080,'address':'662080, EGL - ISANDO, ISANDO HYBRID TEAM, BOBOS PLACE, 2361 MAYFIELD, EXTENSION NO 5, #, DAVEYTON, South Africa','geo':{'lat':-26.12100,'lng':28.42850}},
{'stp':662161,'address':'662161, EGL - ISANDO, ISANDO HYBRID TEAM, ANICENT FIRE, 12281 12281 MAKAPANE STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14761,'lng':28.43022}},
{'stp':662581,'address':'662581, EGL - ISANDO, ISANDO HYBRID TEAM, PULES PLACE, 6142 Cnr Mocke and Mabaso, Cnr Mocke and Mabaso6142, Cnr Mocke and Mabaso6142, DAVEYTON, South Africa','geo':{'lat':-26.13926,'lng':28.42564}},
{'stp':663922,'address':'663922, EGL - ISANDO, ISANDO HYBRID TEAM, THEMBI S TAVERN, 6244 MOCKE STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.13670,'lng':28.42861}},
{'stp':667109,'address':'667109, EGL - ISANDO, ISANDO HYBRID TEAM, LOTISOS PLACE, 1725 CHRIS HANI, #, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13260,'lng':28.44910}},
{'stp':674016,'address':'674016, EGL - ISANDO, ISANDO HYBRID TEAM, KINGBATSI BOTTLE STORE, STAND NO, ERF 12091, BARWA STREET, DAVEYTON, South Africa','geo':{'lat':-26.13920,'lng':28.43120}},
{'stp':676428,'address':'676428, EGL - ISANDO, ISANDO HYBRID TEAM, GRASSLAND DRIVING RANGE, 0 65 GLEN GORY AVENUE, #, VLAKFONTEIN, BENONI, South Africa','geo':{'lat':-26.12000,'lng':28.25813}},
{'stp':701479,'address':'701479, EGL - ISANDO, ISANDO HYBRID TEAM, LADY D TAVERN, 3741 MAYFIELD, EXT5, #, DAVEYTON, South Africa','geo':{'lat':-26.12620,'lng':28.42470}},
{'stp':718462,'address':'718462, EGL - ISANDO, ISANDO HYBRID TEAM, MATSHATA LIQUOR STORE, ERF 7645 EISELEN STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12730,'lng':28.44440}},
{'stp':726076,'address':'726076, EGL - ISANDO, ISANDO HYBRID TEAM, BAFANAS PLACE, 6564 GASELE STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.13700,'lng':28.42550}},
{'stp':735180,'address':'735180, EGL - ISANDO, ISANDO HYBRID TEAM, BRICK S PLACE, 8175 TUMBA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12980,'lng':28.43200}},
{'stp':757090,'address':'757090, EGL - ISANDO, ISANDO HYBRID TEAM, THABO S PLACE, 2279 STAND NO, THUTHUKANI STREET MAYFIELD, EXT 5, DAVEYTON, South Africa','geo':{'lat':-26.12040,'lng':28.42590}},
{'stp':762169,'address':'762169, EGL - ISANDO, ISANDO HYBRID TEAM, PRINCE PLACE, STAND NO, ERF 3311 MAYFIELD EXT 5  DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12035,'lng':28.43219}},
{'stp':762202,'address':'762202, EGL - ISANDO, ISANDO HYBRID TEAM, FUNKYS INN, STAND NO, 1588 SENZO CRESENT, ETWATWA EXT 2, DAVEYTON, South Africa','geo':{'lat':-26.11683,'lng':28.45815}},
{'stp':781158,'address':'781158, EGL - ISANDO, ISANDO HYBRID TEAM, LAPOLOGA, MOCKE STREET, ERF 12741 DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13723,'lng':28.43394}},
{'stp':781645,'address':'781645, EGL - ISANDO, ISANDO HYBRID TEAM, EHOF TAVERN, ZULU STREET, ERF 3122, #, DAVEYTON, South Africa','geo':{'lat':-26.14825,'lng':28.40433}},
{'stp':792196,'address':'792196, EGL - ISANDO, ISANDO HYBRID TEAM, JB TAVERN, 2631 LINDOKUHLE STR, MAYFIELD EXT 5, #, DAVEYTON, South Africa','geo':{'lat':-26.11750,'lng':28.43010}},
{'stp':793402,'address':'793402, EGL - ISANDO, ISANDO HYBRID TEAM, LUNGILES PLACE, 31149 DILIZA STREET, EXT 12, #, DAVEYTON, South Africa','geo':{'lat':-26.12947,'lng':28.42887}},
{'stp':314564,'address':'314564, EGL - ISANDO, ISANDO HYBRID TEAM, PRO VIBE LOUNGE, 19161 STAND NUMBER, NDAMBI STREET, ETWATWA EXTENSION 24, DAVEYTON, South Africa','geo':{'lat':-26.12101,'lng':28.48038}},
{'stp':383791,'address':'383791, EGL - ISANDO, ISANDO HYBRID TEAM, BRA STEVE S LIQUORS AND MEAR MARKET, 4040 STAND NUMBER, CORNER 23 AND 24, ETWATWA EST DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12213,'lng':28.47288}},
{'stp':391735,'address':'391735, EGL - ISANDO, ISANDO HYBRID TEAM, MAMPHO S TAVERN, 0008194 STAND NO, FINCH, #, BENONI, South Africa','geo':{'lat':-26.11273,'lng':28.47807}},
{'stp':392259,'address':'392259, EGL - ISANDO, ISANDO HYBRID TEAM, MA O LADYS PLACE, 21935 STAND NO, SAZAKHELA STREET, ETWATWA EXT 32, DAVEYTON, South Africa','geo':{'lat':-26.11031,'lng':28.45282}},
{'stp':392630,'address':'392630, EGL - ISANDO, ISANDO HYBRID TEAM, IFLORD LIQUOR STORE, 20301 STAND NO, CORNER MAIN STREET AND MBENEGE STREET, ETWATWA EXTENSION 24, DAVEYTON, South Africa','geo':{'lat':-26.12515,'lng':28.47732}},
{'stp':392723,'address':'392723, EGL - ISANDO, ISANDO HYBRID TEAM, VICTOR S RESTAURANT, 28028 NO, TSAVOLOOP STREET, ETWATWA EXTENSION 37, DAVEYTON, South Africa','geo':{'lat':-26.08978,'lng':28.44224}},
{'stp':460601,'address':'460601, EGL - ISANDO, ISANDO HYBRID TEAM, SELEPES TAVERN, 7297 STAND NO, BISHOP STREET, EMAPHUPHENI, TWATWA EXT 9, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11067,'lng':28.48462}},
{'stp':462756,'address':'462756, EGL - ISANDO, ISANDO HYBRID TEAM, JERRYS PLACE, ULOLIWE STREET, ERF 15962, ETWATWA EXTENSION 8, DAVEYTON, South Africa','geo':{'lat':-26.12909,'lng':28.48197}},
{'stp':463206,'address':'463206, EGL - ISANDO, ISANDO HYBRID TEAM, BUKHALIS BOTTLE STORE, 2716 STAND NO, ETWAWA EAST, EAST DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12781,'lng':28.47613}},
{'stp':467173,'address':'467173, EGL - ISANDO, ISANDO HYBRID TEAM, CAPE BUTCHERY INN, 18958 STAND NO, SOKWEBE STREET ETWATWA, EXT 24, DAVEYTON, South Africa','geo':{'lat':-26.12018,'lng':28.47959}},
{'stp':468489,'address':'468489, EGL - ISANDO, ISANDO HYBRID TEAM, FARAZAS PLACE, 34139 STAND NO, BARCELONA SECTION, EXTENSION 34, DAVEYTON, South Africa','geo':{'lat':-26.10260,'lng':28.43929}},
{'stp':468646,'address':'468646, EGL - ISANDO, ISANDO HYBRID TEAM, BIZZAR\'S PLACE, 29651 ERF, TSAVO LOOP STREET, MOGOBA VILLAGE, DAVEYTON, South Africa','geo':{'lat':-26.09209,'lng':28.44667}},
{'stp':470762,'address':'470762, EGL - ISANDO, ISANDO HYBRID TEAM, DUDUS PLACE, 8347 STAND NO, BEE EATER STR, ETWATWA EXT 9, DAVEYTON, South Africa','geo':{'lat':-26.11551,'lng':28.47678}},
{'stp':471123,'address':'471123, EGL - ISANDO, ISANDO HYBRID TEAM, BHEKIS PLACE, STAND NO, EMAPHUPHENI, #, BENONI, South Africa','geo':{'lat':-26.10559,'lng':28.47235}},
{'stp':476025,'address':'476025, EGL - ISANDO, ISANDO HYBRID TEAM, SISTERS PLACE, 12625 STAND NO, MPUMALANGA STREET, EXTENSION 10 ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.10697,'lng':28.47475}},
{'stp':477706,'address':'477706, EGL - ISANDO, ISANDO HYBRID TEAM, DABEZITHA PLACE, 0404 STAND NO, THEMBELIHLE EMAPHUPHENI, SECTION, DAVEYTON, South Africa','geo':{'lat':-26.11374,'lng':28.48652}},
{'stp':484240,'address':'484240, EGL - ISANDO, ISANDO HYBRID TEAM, ABSALOMS PLACE, 3434 STAND NUMBER, NANKURU STREET, BARCELONA SECTION, DAVEYTON, South Africa','geo':{'lat':-26.10341,'lng':28.44800}},
{'stp':485610,'address':'485610, EGL - ISANDO, ISANDO HYBRID TEAM, THE SUN INN, 21007 STAND NO, EXT 30, CHRIS HANI DRIVE, BENONI, South Africa','geo':{'lat':-26.11274,'lng':28.47586}},
{'stp':488001,'address':'488001, EGL - ISANDO, ISANDO HYBRID TEAM, TIZAMELENI TUCK SHOP, 19764 STAND NO, MANDELA EXTENSION 24, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12231,'lng':28.48258}},
{'stp':489287,'address':'489287, EGL - ISANDO, ISANDO HYBRID TEAM, DUBES TAVERN, 22851 STAND NO, SETSENG, ETWATWA EXT 32 TOWNSHIP, DAVEYTON, South Africa','geo':{'lat':-26.10672,'lng':28.46062}},
{'stp':547087,'address':'547087, EGL - ISANDO, ISANDO HYBRID TEAM, MVULANE LIQUOR STORE, STAND NO, ERF 8169 DOVE STR ECT 9 ETWATWA, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11113,'lng':28.47821}},
{'stp':550409,'address':'550409, EGL - ISANDO, ISANDO HYBRID TEAM, SOLLY S TAVERN, STAND 24019, ETWATWA EAST, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11990,'lng':28.47680}},
{'stp':551072,'address':'551072, EGL - ISANDO, ISANDO HYBRID TEAM, MOLOTO A, 29648 LESEDI STREET, ETWATWA CITYCON, DAVETON, DAVEYTON, South Africa','geo':{'lat':-26.11700,'lng':28.47420}},
{'stp':551181,'address':'551181, EGL - ISANDO, ISANDO HYBRID TEAM, KENNYS PLACE, 691 BARCELONA, EXTENTION 32, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10770,'lng':28.45400}},
{'stp':551476,'address':'551476, EGL - ISANDO, ISANDO HYBRID TEAM, MKHWANAZI EM RASTA S PLACE, 20656 EXT 19  (emaphupheni 14), ETWATWA, BENONI   (etwatwa)  1521, DAVEYTON, South Africa','geo':{'lat':-26.11230,'lng':28.48780}},
{'stp':552120,'address':'552120, EGL - ISANDO, ISANDO HYBRID TEAM, ESIZWENI LIQUOR STORE, SHOP 3 ESIZWENI COMPLEX, 20224 MSANI STREET, MANDELA EXT 24, DAVEYTON, South Africa','geo':{'lat':-26.12795,'lng':28.48066}},
{'stp':552796,'address':'552796, EGL - ISANDO, ISANDO HYBRID TEAM, NGUBENI RLJ, 8171 DOVE STREET, EMAPHUPHENI, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11197,'lng':28.47827}},
{'stp':553857,'address':'553857, EGL - ISANDO, ISANDO HYBRID TEAM, ZONDO TG, 599  MADIKIZELA STREET, EMAPHUMPHENI, EXT 9 DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11623,'lng':28.48494}},
{'stp':560176,'address':'560176, EGL - ISANDO, ISANDO HYBRID TEAM, MANKAYI JD, 15942 MANDELA EXT 8, ETWATWA, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13030,'lng':28.48100}},
{'stp':560189,'address':'560189, EGL - ISANDO, ISANDO HYBRID TEAM, NTULI AS, 2697 BARCELONA, EXT 25, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10106,'lng':28.45502}},
{'stp':560613,'address':'560613, EGL - ISANDO, ISANDO HYBRID TEAM, SIBONGILE REGINA MARABE, 21490 LUCKY GARDEN, EXT 33, #, DAVEYTON (Next to cemetary, DAVEYTON, South Africa','geo':{'lat':-26.13380,'lng':28.47410}},
{'stp':561065,'address':'561065, EGL - ISANDO, ISANDO HYBRID TEAM, DINI DANIEL NHLAPO, 21069 RUSSIA STREET, EXT 30, ALBETINA, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11400,'lng':28.47530}},
{'stp':580356,'address':'580356, EGL - ISANDO, ISANDO HYBRID TEAM, NHLABATHI MM, 21604 BLACK STREET, BARCELONA EXT 27, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.10728,'lng':28.44610}},
{'stp':580420,'address':'580420, EGL - ISANDO, ISANDO HYBRID TEAM, MNYAKENI LA, 9485 STAND NO, EXTENSION 15, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.11606,'lng':28.47099}},
{'stp':580423,'address':'580423, EGL - ISANDO, ISANDO HYBRID TEAM, FIKILES INN, 9575 KHUTSONG STREET, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.11615,'lng':28.47289}},
{'stp':580725,'address':'580725, EGL - ISANDO, ISANDO HYBRID TEAM, MABASA WQ, 15408 MANDELA EXT 21, NDABA STR, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.12810,'lng':28.48680}},
{'stp':581107,'address':'581107, EGL - ISANDO, ISANDO HYBRID TEAM, NEMAKONDE LN, 3888 ETWATWA EAST, EXT 13, #, DAVEYTON, South Africa','geo':{'lat':-26.11940,'lng':28.47530}},
{'stp':581156,'address':'581156, EGL - ISANDO, ISANDO HYBRID TEAM, MBUYISE SS, ERF 3741 ETWATWA EXT 13, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.11915,'lng':28.47808}},
{'stp':602880,'address':'602880, EGL - ISANDO, ISANDO HYBRID TEAM, MBALANI DE, 15978 ITEKSI STREET,  EXT 08, #, DAVEYTON, South Africa','geo':{'lat':-26.12950,'lng':28.48090}},
{'stp':603965,'address':'603965, EGL - ISANDO, ISANDO HYBRID TEAM, MOHLALA DE, 12344 TSHIPINNOTO STREET, ETWATWA EXT 10, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.10559,'lng':28.47235}},
{'stp':604138,'address':'604138, EGL - ISANDO, ISANDO HYBRID TEAM, LUBISIS PLACE, 20412 EXT 19, ETWATWA(Maphupeni Next to Rasta), DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11080,'lng':28.48707}},
{'stp':604379,'address':'604379, EGL - ISANDO, ISANDO HYBRID TEAM, RONNIES T SHOP, 23231 BARCELONA EXT 23, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.11120,'lng':28.45360}},
{'stp':604792,'address':'604792, EGL - ISANDO, ISANDO HYBRID TEAM, MAROBANE A, 3638 TWENTY FOURTH STREET, ETWATWA EXT 13, #, DAVEYTON, South Africa','geo':{'lat':-26.11480,'lng':28.47270}},
{'stp':607161,'address':'607161, EGL - ISANDO, ISANDO HYBRID TEAM, NXUMALO D, 21119 CUBA STREET, EXT 30, #, DAVEYTON, South Africa','geo':{'lat':-26.11440,'lng':28.47500}},
{'stp':625891,'address':'625891, EGL - ISANDO, ISANDO HYBRID TEAM, JABULANI TAVERN, 19271 DALAS STREET, MANDELA SECTION EXT 24, #, DAVEYTON, South Africa','geo':{'lat':-26.12340,'lng':28.47960}},
{'stp':626116,'address':'626116, EGL - ISANDO, ISANDO HYBRID TEAM, MGUYOS PLACE, 19697 MANDELA PARK, EXT 24 ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.12143,'lng':28.48227}},
{'stp':626297,'address':'626297, EGL - ISANDO, ISANDO HYBRID TEAM, SHAMASE TAVERN, 10 SHABANGU STREET, EMAPHUPHENI, #, DAVEYTON, South Africa','geo':{'lat':-26.10710,'lng':28.48520}},
{'stp':627154,'address':'627154, EGL - ISANDO, ISANDO HYBRID TEAM, MAJOLA SHUKUSHUKUMA, 19027 MANDELA EXT 24, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12093,'lng':28.47993}},
{'stp':627156,'address':'627156, EGL - ISANDO, ISANDO HYBRID TEAM, MAHLANGUS PLACE, 3146 ETWATWA, #, #, DAVEYTON, South Africa','geo':{'lat':-26.13090,'lng':28.47570}},
{'stp':627351,'address':'627351, EGL - ISANDO, ISANDO HYBRID TEAM, DANS PLACE, 3530   TWENTY FOUR STREET, ETWATWA EXT 13, #, DAVEYTON, South Africa','geo':{'lat':-26.12348,'lng':28.47952}},
{'stp':627908,'address':'627908, EGL - ISANDO, ISANDO HYBRID TEAM, EBUHLENI, 7688 BATELEUR STREET, EXTENTION 9(Emaphuphemi), ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.11160,'lng':28.47950}},
{'stp':628691,'address':'628691, EGL - ISANDO, ISANDO HYBRID TEAM, MOHLALAS SHOP, 14905 NDABA STREET, EXTENTION 5 (ext 21 not 5), ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.12610,'lng':28.48460}},
{'stp':628693,'address':'628693, EGL - ISANDO, ISANDO HYBRID TEAM, JUNIOR SPORTS BAR, 9178 ETWATWA, EXTENTION 15, #, DAVEYTON, South Africa','geo':{'lat':-26.11690,'lng':28.47070}},
{'stp':628697,'address':'628697, EGL - ISANDO, ISANDO HYBRID TEAM, MASUPAS PLACE, 12022 MOTHA DRIVE, EMAPHUPHENA SECTION, EXT 10, #, DAVEYTON, South Africa','geo':{'lat':-26.10230,'lng':28.47840}},
{'stp':629018,'address':'629018, EGL - ISANDO, ISANDO HYBRID TEAM, KHUMALOS TAVERN, 23148 SECOND STREET, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.13100,'lng':28.47560}},
{'stp':629113,'address':'629113, EGL - ISANDO, ISANDO HYBRID TEAM, P AND L BOTTLE STORE, 7811 FLY CATCHER STREET, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.11060,'lng':28.47690}},
{'stp':629118,'address':'629118, EGL - ISANDO, ISANDO HYBRID TEAM, MARYS PLACE, 7937 FLY CATCHER STREET, EXTENTION 9, #, DAVEYTON, South Africa','geo':{'lat':-26.11210,'lng':28.47630}},
{'stp':629577,'address':'629577, EGL - ISANDO, ISANDO HYBRID TEAM, TABOLAS PLACE, 8696 EMAPHUPHENI SECTION, EXTENTION 9, #, DAVEYTON, South Africa','geo':{'lat':-26.11620,'lng':28.48130}},
{'stp':629927,'address':'629927, EGL - ISANDO, ISANDO HYBRID TEAM, FIKZAS PLACE, 282 BARCELONA, EXTENSION 26, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10010,'lng':28.45380}},
{'stp':629928,'address':'629928, EGL - ISANDO, ISANDO HYBRID TEAM, GEORGES PLACE, 567 BARCELONA, EXTENSION 26, #, DAVEYTON, South Africa','geo':{'lat':-26.09770,'lng':28.45370}},
{'stp':629929,'address':'629929, EGL - ISANDO, ISANDO HYBRID TEAM, SILENCES PLACE, 11857 MAPHUPHENI, EXTENSION 10, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.10258,'lng':28.48249}},
{'stp':630526,'address':'630526, EGL - ISANDO, ISANDO HYBRID TEAM, ZERO 2 ONE, 14629 NDABA STREET, ETWATWA EXT 21, #, DAVEYTON, South Africa','geo':{'lat':-26.12554,'lng':28.48939}},
{'stp':630527,'address':'630527, EGL - ISANDO, ISANDO HYBRID TEAM, RAYS PLACE, 252 BARCELONA, EXTENSION 23, #, DAVEYTON, South Africa','geo':{'lat':-26.11030,'lng':28.45700}},
{'stp':630529,'address':'630529, EGL - ISANDO, ISANDO HYBRID TEAM, MOLOIS PLACE, 1403 BARCELONA, EXTENSION 25, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.10374,'lng':28.45243}},
{'stp':630530,'address':'630530, EGL - ISANDO, ISANDO HYBRID TEAM, MAGOGOS PLACE, 8817 BUL-BUL STREET, EMAPHUPHENI EXT 9, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11490,'lng':28.48200}},
{'stp':630531,'address':'630531, EGL - ISANDO, ISANDO HYBRID TEAM, MADIBAS PLACE, 19553 NGWENYA STREET, ETWATWA EXT 24, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12200,'lng':28.48840}},
{'stp':630801,'address':'630801, EGL - ISANDO, ISANDO HYBRID TEAM, J J TAVERN, 11782 MAPHIPHENI EXT10, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.10210,'lng':28.48330}},
{'stp':630944,'address':'630944, EGL - ISANDO, ISANDO HYBRID TEAM, MATJIE GENERAL DEALER, 11436 MTHOMBENI STR, ETWATWA EXT 10, #, DAVEYTON, South Africa','geo':{'lat':-26.10270,'lng':28.48140}},
{'stp':631252,'address':'631252, EGL - ISANDO, ISANDO HYBRID TEAM, TEVIN\'S TUCKSHOP, 13212 Sithole drive, Emaphupheni, Behind Phandimfundo High School, Etwatwa Ext 10 Daveyton, DAVEYTON, South Africa','geo':{'lat':-26.10570,'lng':28.48110}},
{'stp':632297,'address':'632297, EGL - ISANDO, ISANDO HYBRID TEAM, MNGUNI GENERAL DEALER, 11701 UMVUKAZI STREET, ETWATWA, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10681,'lng':28.47100}},
{'stp':632344,'address':'632344, EGL - ISANDO, ISANDO HYBRID TEAM, SANELES PALACE, 19098 NTOSAKHE STREET, ETWATWA EXTENSION 24, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.12100,'lng':28.48180}},
{'stp':632706,'address':'632706, EGL - ISANDO, ISANDO HYBRID TEAM, KHUMALO DISTRIBUTORS, 3724 ETWATWA EAST, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.11891,'lng':28.47239}},
{'stp':633617,'address':'633617, EGL - ISANDO, ISANDO HYBRID TEAM, PINTO\'S TAVERN, 13220 EXTENSION 10, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.10620,'lng':28.47940}},
{'stp':634332,'address':'634332, EGL - ISANDO, ISANDO HYBRID TEAM, EMASOFENI, 12461 MOERANE STREET, EXT 10 ETWATWA, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10750,'lng':28.47140}},
{'stp':634911,'address':'634911, EGL - ISANDO, ISANDO HYBRID TEAM, MIRAS PLACE, 10598 SIMUNYE STREET, EXTENSION 19, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.11620,'lng':28.48544}},
{'stp':635021,'address':'635021, EGL - ISANDO, ISANDO HYBRID TEAM, EAST ROCK, 8821 BUL BUL, EXTENTION 09, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.11500,'lng':28.48260}},
{'stp':636152,'address':'636152, EGL - ISANDO, ISANDO HYBRID TEAM, GREEN HOUSE, 21060 KALAHARI STREET, ETWATWA EXT 30, #, DAVEYTON, South Africa','geo':{'lat':-26.11340,'lng':28.47480}},
{'stp':640388,'address':'640388, EGL - ISANDO, ISANDO HYBRID TEAM, HIGH CLASS TAVERN, 8950 BULBUL STREET, ETWATWA EXTENSION 9, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11670,'lng':28.48340}},
{'stp':640410,'address':'640410, EGL - ISANDO, ISANDO HYBRID TEAM, CHRISTINAS PLACE, 10453 EMABHUHENI,EXT 19 ETWATWA, EXTENSION 9, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11520,'lng':28.48520}},
{'stp':640416,'address':'640416, EGL - ISANDO, ISANDO HYBRID TEAM, VICTORIA\'S TUCKSHOP, 25630 BARCELONA EXT 31, ETWATWA, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10887,'lng':28.46219}},
{'stp':640422,'address':'640422, EGL - ISANDO, ISANDO HYBRID TEAM, RHULANI TAVERN, 8469 LOURIE STREET, ETWATWA EXTENSION 9, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11900,'lng':28.48210}},
{'stp':640603,'address':'640603, EGL - ISANDO, ISANDO HYBRID TEAM, QEDA UKOMA, 21420 LUCKY GARDEN, ETWATWA EAST, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13140,'lng':28.47300}},
{'stp':640774,'address':'640774, EGL - ISANDO, ISANDO HYBRID TEAM, NTUTHUKO PLACE, 25511 BARCELONA, ETWATWA EXT 1, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11070,'lng':28.46200}},
{'stp':641230,'address':'641230, EGL - ISANDO, ISANDO HYBRID TEAM, CHINA TOWN PLACE, 2586 FIRST STREET, ETWATWA  EXT 4, P BAG 1838, DAVEYTON, South Africa','geo':{'lat':-26.12318,'lng':28.47413}},
{'stp':641284,'address':'641284, EGL - ISANDO, ISANDO HYBRID TEAM, MNCUBES PLACE, 9484 KHOTSONG STREET, CITI CORN, ETWATWA EXTENSION 15, DAVEYTON, South Africa','geo':{'lat':-26.11573,'lng':28.47428}},
{'stp':641311,'address':'641311, EGL - ISANDO, ISANDO HYBRID TEAM, TSHEPOS PLACE, ISIGADLA STREET, ERF 15971 ETWATWA EXT 8, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13020,'lng':28.48113}},
{'stp':641374,'address':'641374, EGL - ISANDO, ISANDO HYBRID TEAM, MZONDIS PLACE, 3080 SECOND STREET, ETWATWA EAST, #, DAVEYTON, South Africa','geo':{'lat':-26.12980,'lng':28.47550}},
{'stp':642613,'address':'642613, EGL - ISANDO, ISANDO HYBRID TEAM, OUPA\'S PLACE, 13158 REUBEN STREET  num 13158, ETWATWA EXTENSION 10, #, DAVEYTON, South Africa','geo':{'lat':-26.10459,'lng':28.47892}},
{'stp':642642,'address':'642642, EGL - ISANDO, ISANDO HYBRID TEAM, NORAHS PLACE, 1016 MAPHUPHENI  num 1016, EXTENSION 19, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.11947,'lng':28.48670}},
{'stp':642645,'address':'642645, EGL - ISANDO, ISANDO HYBRID TEAM, S\'KARAVANE, 8581 GUINEA-FOWL STREET  num 8581, ETWATWA EXTENSION 9, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.11390,'lng':28.48460}},
{'stp':646334,'address':'646334, EGL - ISANDO, ISANDO HYBRID TEAM, MATHOUSAND TAVERN, 19833 BARCELONA EXT 27  num 19833, ETWATWA, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.10128,'lng':28.44241}},
{'stp':646616,'address':'646616, EGL - ISANDO, ISANDO HYBRID TEAM, DLUDLUS PLACE, 3830 TWENTY THIRD STREET num 3830, ETWATWA  EXTENSION 13, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.12030,'lng':28.47260}},
{'stp':646739,'address':'646739, EGL - ISANDO, ISANDO HYBRID TEAM, JOSEPH\'S PLACE, 8838 BULBUL STREET  NUM 8838, EMAPHUPHENI, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11620,'lng':28.48340}},
{'stp':647814,'address':'647814, EGL - ISANDO, ISANDO HYBRID TEAM, PINK HOUSE, 9473 MASAKHANE STREET, EXTENSION 15, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.11571,'lng':28.47448}},
{'stp':648639,'address':'648639, EGL - ISANDO, ISANDO HYBRID TEAM, THAPEDI\'S TAVERN, 22584 BARCELONA, #, #, DAVEYTON, South Africa','geo':{'lat':-26.10890,'lng':28.45370}},
{'stp':649655,'address':'649655, EGL - ISANDO, ISANDO HYBRID TEAM, MAPAPU\'S PLACE, 9570 SITHOLE STREET, CITICON, #, DAVEYTON, South Africa','geo':{'lat':-26.11708,'lng':28.47177}},
{'stp':650341,'address':'650341, EGL - ISANDO, ISANDO HYBRID TEAM, LIZIS PLACE, 9568 HADIDA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.11710,'lng':28.47195}},
{'stp':650545,'address':'650545, EGL - ISANDO, ISANDO HYBRID TEAM, NOMVULAS PLACE, 2798 ETWATWA EAST, EXTENSION 4, #, DAVEYTON, South Africa','geo':{'lat':-26.12455,'lng':28.47352}},
{'stp':655249,'address':'655249, EGL - ISANDO, ISANDO HYBRID TEAM, MTSWALES PLACE, 20562 MAPHONOLO STREET, ETWATWA EXTENSION 19, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11170,'lng':28.48830}},
{'stp':697117,'address':'697117, EGL - ISANDO, ISANDO HYBRID TEAM, NHLANHLA PLACE, 14999 EXTENSION 21, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12781,'lng':28.48429}},
{'stp':726392,'address':'726392, EGL - ISANDO, ISANDO HYBRID TEAM, MM SEDIBANE LIQUOR DISTRIBUTORS, 21928 ERF, PHUMELELA STREET, BARCELONA EXTENSION 32 ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.10670,'lng':28.45140}},
{'stp':729813,'address':'729813, EGL - ISANDO, ISANDO HYBRID TEAM, ZWANE TAVERN, ERF 25681,MOLATO STREET, EXT 31, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.10600,'lng':28.46430}},
{'stp':742823,'address':'742823, EGL - ISANDO, ISANDO HYBRID TEAM, LEGANCE PLACE, 9293 HADIDA STREET, EXTENSION 15, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.11410,'lng':28.47220}},
{'stp':744816,'address':'744816, EGL - ISANDO, ISANDO HYBRID TEAM, MAPHUPHENI LIQOUR STORE, 7267 CORNER KINGFISHER AND LOVEBIRD, ETWATWA EXTENSION 9, #, DAVEYTON, South Africa','geo':{'lat':-26.10940,'lng':28.48270}},
{'stp':745994,'address':'745994, EGL - ISANDO, ISANDO HYBRID TEAM, XABAS TAVERN, 1956 NENE STREET, MANDELA EXTENSION 24, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.12200,'lng':28.48800}},
{'stp':759619,'address':'759619, EGL - ISANDO, ISANDO HYBRID TEAM, MOHLAKWANAS PLACE, 2271 THOBELA STREET, ERF C 13032, BARCELONA SECTION, DAVEYTON, South Africa','geo':{'lat':-26.11963,'lng':28.46685}},
{'stp':765575,'address':'765575, EGL - ISANDO, ISANDO HYBRID TEAM, BETTY S PLACE, STAND NO, ERF 19026, MBENENGE STR ETWATWA EXT 24 DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12460,'lng':28.47760}},
{'stp':765648,'address':'765648, EGL - ISANDO, ISANDO HYBRID TEAM, NCEBA S, STAND NO, ERF 13763 MAIMELA STREET EMAPHUPHENI EXT, 10 ETWATWA EAST BENONI, DAVEYTON, South Africa','geo':{'lat':-26.10885,'lng':28.47687}},
{'stp':772639,'address':'772639, EGL - ISANDO, ISANDO HYBRID TEAM, JAMES S PLACE, 26788 STAND NO, THEMBISILE STR, ETWATWA EXT 36, DAVEYTON, South Africa','geo':{'lat':-26.10487,'lng':28.48548}},
{'stp':774326,'address':'774326, EGL - ISANDO, ISANDO HYBRID TEAM, LOVE  ONE AND LOVE ALL, 28568 STAND NO, MOGABA VILLAGE EXT 37, PUTFONTEIN, DAVEYTON, South Africa','geo':{'lat':-26.09360,'lng':28.44860}},
{'stp':775865,'address':'775865, EGL - ISANDO, ISANDO HYBRID TEAM, MAPHOSA TAVERN, 13433 SIKELE STREET, ETWATWA EXT 10, #, DAVEYTON, South Africa','geo':{'lat':-26.10692,'lng':28.48383}},
{'stp':775959,'address':'775959, EGL - ISANDO, ISANDO HYBRID TEAM, NTOMBI S PLACE, 25425 MOLATA STREET, ETWATWA, EXT 31, #, DAVEYTON, South Africa','geo':{'lat':-26.10741,'lng':28.46265}},
{'stp':778481,'address':'778481, EGL - ISANDO, ISANDO HYBRID TEAM, SIYABONGA TAVERN, 26217 STAND NO, DHUNGWANE STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.10922,'lng':28.48672}},
{'stp':778525,'address':'778525, EGL - ISANDO, ISANDO HYBRID TEAM, ANADINI TAVERN, 20003 STAND NO, GABA STREET ETWATWA  X24, #, DAVEYTON, South Africa','geo':{'lat':-26.12467,'lng':28.48136}},
{'stp':780144,'address':'780144, EGL - ISANDO, ISANDO HYBRID TEAM, GLADY S PLACE, 8394 WEAVER STREET, ETWATWA EXT 9, #, DAVEYTON, South Africa','geo':{'lat':-26.11550,'lng':28.47830}},
{'stp':782734,'address':'782734, EGL - ISANDO, ISANDO HYBRID TEAM, MLAMBOS PLACE, 26527 BHEKA STR, ETWATWA EXT 3, #, DAVEYTON, South Africa','geo':{'lat':-26.10454,'lng':28.48536}},
{'stp':799009,'address':'799009, EGL - ISANDO, ISANDO HYBRID TEAM, MMINASHORO TAVERN, 730 STAND NO, BARCELONA EXT 23, #, DAVEYTON, South Africa','geo':{'lat':-26.10890,'lng':28.45370}},
{'stp':550348,'address':'550348, EGL - ISANDO, ISANDO HYBRID TEAM, LONGHORN HARMELIA, BOUNDED BY HARMON, KRUIS &, AVONDGLOED ROADS, HARMELIA, ISANDO, South Africa','geo':{'lat':-26.14120,'lng':28.19140}},
{'stp':550516,'address':'550516, EGL - ISANDO, ISANDO HYBRID TEAM, BOMA BOKSBURG, ERF 807, NORTHRAND ROAD, BEYERSPARK EXT 17, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.18000,'lng':28.25650}},
{'stp':551794,'address':'551794, EGL - ISANDO, ISANDO HYBRID TEAM, O HAGANS ELMA PARK, ELMA PARK CENTRE, CRN BOEING STREET & EAST FIRST, AVENUE ELMA PARK EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.15980,'lng':28.15730}},
{'stp':552156,'address':'552156, EGL - ISANDO, ISANDO HYBRID TEAM, CHEFS IN MOTION, 212 CHEFS IN MOTION SHOPS 157 AND, 158 LONEHILL SHOPPING CENTRE, LONEHILL EXT 9 22 LONEHILL BLV, FOURWAYS, South Africa','geo':{'lat':-26.01380,'lng':28.02570}},
{'stp':581086,'address':'581086, EGL - ISANDO, ISANDO HYBRID TEAM, THE GOBLET, ERF 2787, CAMELOT COMPLEX, PRETORIA ROAD (K104 RD) CRN HACKEA RD, PRIMROSE, PRIMROSE, South Africa','geo':{'lat':-26.18820,'lng':28.16890}},
{'stp':604246,'address':'604246, EGL - ISANDO, ISANDO HYBRID TEAM, STONES EDENVALE, SHOP NO 40 EDENMEADOW S/CENTRE, CNR MODDERFONTEIN RD & VAN RIEBEECK AVE, #, EDENVALE, South Africa','geo':{'lat':-26.12020,'lng':28.13830}},
{'stp':605068,'address':'605068, EGL - ISANDO, ISANDO HYBRID TEAM, OGILVYS POSTINO, PORTION 47 OF ERF 30, 128, TONETTI STREET HALFWAY HOUSE, #, MIDRAND, South Africa','geo':{'lat':-25.99680,'lng':28.12530}},
{'stp':610077,'address':'610077, EGL - ISANDO, ISANDO HYBRID TEAM, GROOVE CAFE (NTO), Terrace Road Shopping Centre, Edenvale, Johannesburg, EDENVALE, South Africa','geo':{'lat':-26.08219,'lng':28.20954}},
{'stp':621383,'address':'621383, EGL - ISANDO, ISANDO HYBRID TEAM, MCGINTY\'S JHB INT DOMESTIC DEP (NTO, JOHANNESBURG INTERNATIONAL AIRPORT, INTERNATIONAL DEPARTURES, Johannesburg, KEMPTON PARK, South Africa','geo':{'lat':-26.13040,'lng':28.23120}},
{'stp':671522,'address':'671522, EGL - ISANDO, ISANDO HYBRID TEAM, MO-ZAM-BIK (NTO), LAKEFIELD AVE, LAKEFIELD AVENUE, BENONI, BENONI, South Africa','geo':{'lat':-26.17860,'lng':28.28580}},
{'stp':683752,'address':'683752, EGL - ISANDO, ISANDO HYBRID TEAM, KEG AND MINSTREL, 0 SUNSET BOULEVARD EXT, CASINO VIWE RETAIL CENTRE, LONEHILL EXT 61 AND 67 FOURWAYS, FOURWAYS, South Africa','geo':{'lat':-26.02110,'lng':28.01340}},
{'stp':684794,'address':'684794, EGL - ISANDO, ISANDO HYBRID TEAM, FUSION SPORTS BAR (NTO), 86 BEDFORD AVENUE, 86 BEDFORD AVENUE, BENONI, BENONI, South Africa','geo':{'lat':-26.11358,'lng':28.18960}},
{'stp':697765,'address':'697765, EGL - ISANDO, ISANDO HYBRID TEAM, CAPPELLO MIDRAND (NTO), CNR HARRY GALAUME, AND 7TH AVENUE, #, MIDRAND, South Africa','geo':{'lat':-25.98685,'lng':28.11268}},
{'stp':698664,'address':'698664, EGL - ISANDO, ISANDO HYBRID TEAM, CAPPELLO ORTIA (NTO), OR INTERNATIONAL AIRPORT, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.12850,'lng':28.24290}},
{'stp':699576,'address':'699576, EGL - ISANDO, ISANDO HYBRID TEAM, BUDDHA TA, SHOP 72, DESIGN QUARTER CENTRE WIILIAM NICOL DR, LESLIE ROAD EAST MAGALIESSIG, FOURWAYS, South Africa','geo':{'lat':-26.03000,'lng':28.01110}},
{'stp':703128,'address':'703128, EGL - ISANDO, ISANDO HYBRID TEAM, THATCHES (NTO), MAIN RD FOURWAYS, #, #, FOURWAYS, South Africa','geo':{'lat':-26.01250,'lng':28.03675}},
{'stp':709535,'address':'709535, EGL - ISANDO, ISANDO HYBRID TEAM, FOOBA, SHOP NO 01, HELATH WORLD COMPLEX 02 MALHERBE STR, CNR OREILLY MERRY STREET BEING, BENONI, South Africa','geo':{'lat':-26.09916,'lng':28.19556}},
{'stp':710092,'address':'710092, EGL - ISANDO, ISANDO HYBRID TEAM, CHARLIE BROWNS (NTO), CNR WITKOPPEN & MAIN, LONEHILL, #, FOURWAYS, South Africa','geo':{'lat':-26.02779,'lng':28.02932}},
{'stp':718992,'address':'718992, EGL - ISANDO, ISANDO HYBRID TEAM, SEEMANS QUALITY MEAT (NTO), NO.4 EASTRAND RETAIL PARK, NORTHRAND ROAD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.18021,'lng':28.24899}},
{'stp':762614,'address':'762614, EGL - ISANDO, ISANDO HYBRID TEAM, TURN n TENDER BEDFORDVIEW (NTO), BEDFORVIEW CENTRE, BEDFORDVIEW, BEDFORDVIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.18908,'lng':28.12342}},
{'stp':763305,'address':'763305, EGL - ISANDO, ISANDO HYBRID TEAM, TWO TOMAHAWK SPUR (NTO), SPUR, DELMAS, #, DELMAS, South Africa','geo':{'lat':-26.14885,'lng':28.67621}},
{'stp':777466,'address':'777466, EGL - ISANDO, ISANDO HYBRID TEAM, THE BEACH HUT PUB AND DINER (NTO), C/O MONUMENT AND OLD PTA RD, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10230,'lng':28.23030}},
{'stp':791169,'address':'791169, EGL - ISANDO, ISANDO HYBRID TEAM, CHEERLEADERS BEDFORDVIEW (NTO), SHOP 3 GROUND FLOOR, CNR HAWLWY & NICOL STS, BEDFORDVIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.17930,'lng':28.13620}},
{'stp':308453,'address':'308453, EGL - ISANDO, ISANDO HYBRID TEAM, SUN CITY TAVERN, STAND NO, ERF NO 1203, UDOYE, BENONI, South Africa','geo':{'lat':-26.18988,'lng':28.39752}},
{'stp':309330,'address':'309330, EGL - ISANDO, ISANDO HYBRID TEAM, MJ LOUNGE, 30555 STAND NO, MTHIMUNYE STREET, NEW HORIZON,, DAVEYTON, South Africa','geo':{'lat':-26.16379,'lng':28.39940}},
{'stp':311603,'address':'311603, EGL - ISANDO, ISANDO HYBRID TEAM, THE TAP HOUSE LIQUORS, 961 STAND NO, VOORTREKKER STREET, SHOP 11C, MIDRAND, South Africa','geo':{'lat':-26.18897,'lng':28.32098}},
{'stp':311897,'address':'311897, EGL - ISANDO, ISANDO HYBRID TEAM, LE HOT PERI PERI, 1349 ERF, 27 PRINCESS AVENUE, #, BENONI, South Africa','geo':{'lat':-26.18935,'lng':28.32269}},
{'stp':383278,'address':'383278, EGL - ISANDO, ISANDO HYBRID TEAM, THANE S TAVERN, 1836 STAND NO, TULELE STREET, #, MIDRAND, South Africa','geo':{'lat':-26.22295,'lng':28.30093}},
{'stp':384854,'address':'384854, EGL - ISANDO, ISANDO HYBRID TEAM, NKOSIS TAVERN, 1450 STAND NO, UVENYANE STR, EXT 1, KINGSWAY, DAVEYTON, South Africa','geo':{'lat':-26.19248,'lng':28.39540}},
{'stp':387866,'address':'387866, EGL - ISANDO, ISANDO HYBRID TEAM, DO SOL LIQUORS, STAND NUMBER, CNR DUNSWART, MAIN REEF ROADS, BOKSBURG, South Africa','geo':{'lat':-26.20884,'lng':28.28116}},
{'stp':388984,'address':'388984, EGL - ISANDO, ISANDO HYBRID TEAM, CLUB AFRICA, 1179 ERF, 1ST FLOOR, NO 21, WOBURN AVENUE BENONI, BENONI, South Africa','geo':{'lat':-26.18762,'lng':28.32237}},
{'stp':390545,'address':'390545, EGL - ISANDO, ISANDO HYBRID TEAM, TAXIDOS TAVERN, 3453 STAND NO, MANDE STREET, CLOVERDENE, BENONI, South Africa','geo':{'lat':-26.15641,'lng':28.37867}},
{'stp':390735,'address':'390735, EGL - ISANDO, ISANDO HYBRID TEAM, BENONI STATION BOTTLE STORE, BENONI STATION, #, #, BENONI, South Africa','geo':{'lat':-26.19794,'lng':28.31037}},
{'stp':391268,'address':'391268, EGL - ISANDO, ISANDO HYBRID TEAM, E LIQUOR TOM JONES, 19 STAND NO, #, #, BOKSBURG, South Africa','geo':{'lat':-26.19003,'lng':28.31702}},
{'stp':391722,'address':'391722, EGL - ISANDO, ISANDO HYBRID TEAM, MAGEBAS PLACE, 15 STAND NO, KIMBOLTON STREET, #, BENONI, South Africa','geo':{'lat':-26.19018,'lng':28.31206}},
{'stp':462242,'address':'462242, EGL - ISANDO, ISANDO HYBRID TEAM, MPHEPHETHE TAVERN, 1234 STAND NO, CHIEF ALBERT LUTHULI PARK, EXT 2, BENONI, South Africa','geo':{'lat':-26.16274,'lng':28.37545}},
{'stp':463920,'address':'463920, EGL - ISANDO, ISANDO HYBRID TEAM, THEMBAS PLACE, 1152 ERF, CNR SWAZILAND AND EQUATOR STR, CHIEF ALBERT LUTHULI PARK, EXT 2, BENONI, South Africa','geo':{'lat':-26.16173,'lng':28.37529}},
{'stp':473308,'address':'473308, EGL - ISANDO, ISANDO HYBRID TEAM, SIPHO S TAVERN, ERF 6232,  WILD OLIVER STREET, CHIEF ALBERT LUTHULI PARK EXTENSION 6, BENONI, South Africa','geo':{'lat':-26.15788,'lng':28.38958}},
{'stp':477897,'address':'477897, EGL - ISANDO, ISANDO HYBRID TEAM, HILL RISE OASIS, 35 STAND NO, PRETORIA ROAD HILL RISE, ERF 35, BENONI, South Africa','geo':{'lat':-26.05680,'lng':28.38680}},
{'stp':484198,'address':'484198, EGL - ISANDO, ISANDO HYBRID TEAM, MZIMNONI S PLACE, 0667 STAND NO, NAMIBIA CLOVERDENE, ERF 667, BENONI, South Africa','geo':{'lat':-26.16522,'lng':28.37614}},
{'stp':484344,'address':'484344, EGL - ISANDO, ISANDO HYBRID TEAM, VILLA FUNCHAL, 17 STAND NUMBER, PORTIN 17 OF THE BENONI FARM, 3 RANGVIEW ROAD, BENONI, South Africa','geo':{'lat':-26.18365,'lng':28.29816}},
{'stp':485258,'address':'485258, EGL - ISANDO, ISANDO HYBRID TEAM, MZANZI BAR LOUNGE, 0083 STAND NO, PRINCES AVENUE, ERF 1293, BENONI, South Africa','geo':{'lat':-26.19179,'lng':28.31728}},
{'stp':490034,'address':'490034, EGL - ISANDO, ISANDO HYBRID TEAM, SAYI TAVERN, 2348 STAND NO, MVUBU STR, KINGSWAY EXT 1, DAVEYTON, South Africa','geo':{'lat':-26.19270,'lng':28.39710}},
{'stp':549357,'address':'549357, EGL - ISANDO, ISANDO HYBRID TEAM, MASILAHLAS PLACE, THLAPI STREET, ERF 2145, CHIEF ALBERT LUTHULI PARK, BENONI, South Africa','geo':{'lat':-26.15792,'lng':28.38737}},
{'stp':550243,'address':'550243, EGL - ISANDO, ISANDO HYBRID TEAM, THE WILLOWS, HOLDING 277 CRN  willow &, LARCH RD BENONI AGRICULT X 2, BENONI, BENONI, South Africa','geo':{'lat':-26.08790,'lng':28.34903}},
{'stp':550525,'address':'550525, EGL - ISANDO, ISANDO HYBRID TEAM, NELSONS PARADISE LIQUOR STORE, 36 STAND NO, TRICHARDT STREET ,RAVENSWOOD, BOKSBURG NORTH, BOKSBURG, South Africa','geo':{'lat':-26.19061,'lng':28.25340}},
{'stp':550611,'address':'550611, EGL - ISANDO, ISANDO HYBRID TEAM, PHILMORE LIQUOR WHOLESALERS, 546 STAND NO, 78 CASON STREET, PHILMORE COURT, BOKSBURG, South Africa','geo':{'lat':-26.20860,'lng':28.26010}},
{'stp':550680,'address':'550680, EGL - ISANDO, ISANDO HYBRID TEAM, SEDIBANE LIQUOR STORE, ERF 543, CHIEF A, LUTHULI PARK BENONI, BENONI, South Africa','geo':{'lat':-26.15810,'lng':28.37840}},
{'stp':550842,'address':'550842, EGL - ISANDO, ISANDO HYBRID TEAM, YUM YUM RESTAURANT, CNR WILSTEAD STREET, PRINCE AVENUE, BENONI, BENONI, South Africa','geo':{'lat':-26.18810,'lng':28.32530}},
{'stp':550883,'address':'550883, EGL - ISANDO, ISANDO HYBRID TEAM, JUMBO LIQUOR MARKET CASON ROAD, 34 CASON RD, BOKSBURG, #, BOKSBURG, South Africa','geo':{'lat':-26.20850,'lng':28.25380}},
{'stp':550991,'address':'550991, EGL - ISANDO, ISANDO HYBRID TEAM, BULLDOGS SPORTS DINER, CNR ELSTON AVE & WILSTEAD ST, BENONI, 1500, BENONI, South Africa','geo':{'lat':-26.18400,'lng':28.32290}},
{'stp':551243,'address':'551243, EGL - ISANDO, ISANDO HYBRID TEAM, CARLOMAN SPORT BAR AND RESTAURANT, SHOP 14 JUNCTION SHOPPING CNTR, DUNSWART RAILWAY STATION, BOKSBURG, BENONI, South Africa','geo':{'lat':-26.20916,'lng':28.28610}},
{'stp':551298,'address':'551298, EGL - ISANDO, ISANDO HYBRID TEAM, BENONI CONSTITUTIONAL CLUB, CNR LAKE & SWAN STREET, BENONI, #, BENONI, South Africa','geo':{'lat':-26.19053,'lng':28.32396}},
{'stp':551421,'address':'551421, EGL - ISANDO, ISANDO HYBRID TEAM, CLUB 45 DAVEYTON, PERSEEL 690, BELEMUSTRAAT, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.15530,'lng':28.41430}},
{'stp':551424,'address':'551424, EGL - ISANDO, ISANDO HYBRID TEAM, BOKSBURG NORTH HOTEL, 32 CASON ROAD, BOKSBURG NORTH, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.20846,'lng':28.25352}},
{'stp':551739,'address':'551739, EGL - ISANDO, ISANDO HYBRID TEAM, THE MOON, 78 A WOBURN AVE, BENONI, #, BENONI, South Africa','geo':{'lat':-26.18960,'lng':28.31700}},
{'stp':551914,'address':'551914, EGL - ISANDO, ISANDO HYBRID TEAM, PHENYANE JAZZ BAR AND RESTAURANT, 29 BUNYAN STREET, ERF 1277, BENONI, BENONI, South Africa','geo':{'lat':-26.19240,'lng':28.31580}},
{'stp':552289,'address':'552289, EGL - ISANDO, ISANDO HYBRID TEAM, BENONI REX HOTEL, CORNER TOM JONES STREET, AMPTHILLI AVENUE, BENONI, BENONI, South Africa','geo':{'lat':-26.19190,'lng':28.31830}},
{'stp':552343,'address':'552343, EGL - ISANDO, ISANDO HYBRID TEAM, SOUTHVAAL RESTAURANT, ERF 7878, HARPURLAAN 60, BENONI, BENONI, South Africa','geo':{'lat':-26.19240,'lng':28.32050}},
{'stp':552492,'address':'552492, EGL - ISANDO, ISANDO HYBRID TEAM, MBATHA DS, 37 JACANA STREET, CRYSTAL PARK, #, BENONI, South Africa','geo':{'lat':-26.12320,'lng':28.37970}},
{'stp':552494,'address':'552494, EGL - ISANDO, ISANDO HYBRID TEAM, BAFANA S GRILL, 47 CRANBOURNE AVENUE, CNR CRANBOURNE AND TAYLOR STREET, BENONI, BENONI, South Africa','geo':{'lat':-26.18940,'lng':28.32020}},
{'stp':552594,'address':'552594, EGL - ISANDO, ISANDO HYBRID TEAM, SHOPRITE LIQUORSHOP ANDERBOLT, JAMAICA WHOLESALE, RETAIL CENTRE, CNR TURF AND MAIN, REEF ROAD, BOKSBURG, South Africa','geo':{'lat':-26.20801,'lng':28.26984}},
{'stp':552748,'address':'552748, EGL - ISANDO, ISANDO HYBRID TEAM, DUNSWART LIQUOR STORE, MAIN REEF ROAD 4, DUNSWART, #, BOKSBURG, South Africa','geo':{'lat':-26.20870,'lng':28.28360}},
{'stp':552829,'address':'552829, EGL - ISANDO, ISANDO HYBRID TEAM, AMPTHILL LIQUORS, ERF 1377, 99A AMPTHILL STREET, #, BENONI, South Africa','geo':{'lat':-26.19250,'lng':28.31580}},
{'stp':552958,'address':'552958, EGL - ISANDO, ISANDO HYBRID TEAM, TSHWALA LIQUOR STORE, 1 SP BUILDING 5 DUNSWART AVE, DUNSWART TOWNSHIP, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.20810,'lng':28.28460}},
{'stp':553105,'address':'553105, EGL - ISANDO, ISANDO HYBRID TEAM, RISING SUN RESTAURANT, 96 CASON ROAD, BOKSBURG NORTH, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.20848,'lng':28.26102}},
{'stp':553231,'address':'553231, EGL - ISANDO, ISANDO HYBRID TEAM, RISING SUN LIQUOR STORE, ERF 555 BOKSBURG NORTH, 96 CASON ROAD, #, BOKSBURG, South Africa','geo':{'lat':-26.20830,'lng':28.26260}},
{'stp':553388,'address':'553388, EGL - ISANDO, ISANDO HYBRID TEAM, GALAXY LIQUOR STORE, PORTION 279 FARM PUTFONTEIN 26, NO 7 DURANT STREET, CRN JORDAAN STREET BENONI, BENONI, South Africa','geo':{'lat':-26.10230,'lng':28.41870}},
{'stp':553626,'address':'553626, EGL - ISANDO, ISANDO HYBRID TEAM, JOSIAHS POOL CLUB AND RESTAURANT, 37B 1ST STREET, BOKSBURG NORTH, 1474, BOKSBURG, South Africa','geo':{'lat':-26.20800,'lng':28.25420}},
{'stp':553738,'address':'553738, EGL - ISANDO, ISANDO HYBRID TEAM, FANEL FAST FOODS, ERF 7634, NO 7 BUNYAN STREET, BENONI, BENONI, South Africa','geo':{'lat':-26.18850,'lng':28.31350}},
{'stp':553958,'address':'553958, EGL - ISANDO, ISANDO HYBRID TEAM, MCANYANGWA MS, 293 PHASWANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15541,'lng':28.40398}},
{'stp':560297,'address':'560297, EGL - ISANDO, ISANDO HYBRID TEAM, PELO MF, 982 ANGOLA STREET, CHIEF ALBERT LUTHULI, #, TEMBISA, South Africa','geo':{'lat':-26.16270,'lng':28.37850}},
{'stp':560371,'address':'560371, EGL - ISANDO, ISANDO HYBRID TEAM, MAYISA PM, 721 LIBYA STREET, ALBERT LUTHULI PARK, #, BENONI, South Africa','geo':{'lat':-26.16326,'lng':28.38150}},
{'stp':560420,'address':'560420, EGL - ISANDO, ISANDO HYBRID TEAM, LIZZY S PLACE, 361 RASENGANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15502,'lng':28.41410}},
{'stp':560562,'address':'560562, EGL - ISANDO, ISANDO HYBRID TEAM, FREDDS INN, 310 RUVUMO STREET, LUTHULI PARK, #, BENONI, South Africa','geo':{'lat':-26.15870,'lng':28.38040}},
{'stp':560597,'address':'560597, EGL - ISANDO, ISANDO HYBRID TEAM, MAHLANGU TN, 883 DUMERI STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15450,'lng':28.41367}},
{'stp':560611,'address':'560611, EGL - ISANDO, ISANDO HYBRID TEAM, MEISIE MADUMO, 43 BUENO RIVER CRESCENT, CHIEF LUTHULI PARK, BENONI, BENONI, South Africa','geo':{'lat':-26.15760,'lng':28.38280}},
{'stp':581097,'address':'581097, EGL - ISANDO, ISANDO HYBRID TEAM, JAZZ INN, 192 ZAIRE RIVER STREET, CHIEF ALBERT LUTHULI PARK, #, BENONI, South Africa','geo':{'lat':-26.16140,'lng':28.38120}},
{'stp':581116,'address':'581116, EGL - ISANDO, ISANDO HYBRID TEAM, MSIMANGO ST, 168 MAGIGWANA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15840,'lng':28.41120}},
{'stp':606810,'address':'606810, EGL - ISANDO, ISANDO HYBRID TEAM, COUNTRY LIQUOR STORE, 217 STAND NO, HAZEL ROAD AGRICULTURAL HOLDING, BENONI, BENONI, South Africa','geo':{'lat':-26.09441,'lng':28.34434}},
{'stp':620919,'address':'620919, EGL - ISANDO, ISANDO HYBRID TEAM, WOBURN ARMS (NTO), NO 50 WOBURN AVENUE, BENONI, #, BENONI, South Africa','geo':{'lat':-26.18781,'lng':28.32119}},
{'stp':626117,'address':'626117, EGL - ISANDO, ISANDO HYBRID TEAM, KIT KAT CAFE, PORTION 34 OF FARM ZESFONTEIN 27, PLOT 49 OLD PRETORIA RD (petit), BENONI, BENONI, South Africa','geo':{'lat':-26.09780,'lng':28.37264}},
{'stp':627322,'address':'627322, EGL - ISANDO, ISANDO HYBRID TEAM, CHENS PLACE, 10 BISHOP STREET, CRYSTAL PARK, BENONI, BENONI, South Africa','geo':{'lat':-26.11950,'lng':28.36930}},
{'stp':627907,'address':'627907, EGL - ISANDO, ISANDO HYBRID TEAM, BJ CHILOANE, LERUARUA STREET, ERF 2375 CHIEF ALBERT LUTHULI, PARK EXTENSION 4, BENONI, South Africa','geo':{'lat':-26.16020,'lng':28.38730}},
{'stp':627935,'address':'627935, EGL - ISANDO, ISANDO HYBRID TEAM, MONARENG, 30705 TSWAEDI STREET, TSWAEDI STREET, #, DAVEYTON, South Africa','geo':{'lat':-26.16340,'lng':28.40060}},
{'stp':627936,'address':'627936, EGL - ISANDO, ISANDO HYBRID TEAM, EVAS PLACE, PLOT 3, HILLCREST (Daveyton), PUTFONTEIN, DAVEYTON, South Africa','geo':{'lat':-26.13781,'lng':28.39662}},
{'stp':628298,'address':'628298, EGL - ISANDO, ISANDO HYBRID TEAM, NTOMBIS TAVERN, 171 MAGIGWANA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15860,'lng':28.41100}},
{'stp':630099,'address':'630099, EGL - ISANDO, ISANDO HYBRID TEAM, THE VEGAS, 2603/04 STAND NO, SHOP NO 2, NO 40 KEMSTON AVENUE, BENONI, South Africa','geo':{'lat':-26.18494,'lng':28.31909}},
{'stp':630270,'address':'630270, EGL - ISANDO, ISANDO HYBRID TEAM, SWEETYS TUCKSHOP, 16833 PHOKANE CRESCENT, EXTENSION 2, #, DAVEYTON, South Africa','geo':{'lat':-26.15880,'lng':28.39950}},
{'stp':633606,'address':'633606, EGL - ISANDO, ISANDO HYBRID TEAM, FIFTY CENTS TAVERN, ERF 556 CASONS ROAD, CORNER 13 AVENUE, BOKSBURG NORTH, BOKSBURG, South Africa','geo':{'lat':-26.20860,'lng':28.26260}},
{'stp':634480,'address':'634480, EGL - ISANDO, ISANDO HYBRID TEAM, BIGBOY DISTRIBUTION, 21 HEALD STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.16009,'lng':28.40879}},
{'stp':635218,'address':'635218, EGL - ISANDO, ISANDO HYBRID TEAM, KEW RESTAURANT, 7 VOORTREKKER STREET, BENONI, #, BENONI, South Africa','geo':{'lat':-26.18620,'lng':28.31940}},
{'stp':635691,'address':'635691, EGL - ISANDO, ISANDO HYBRID TEAM, PRETTY\'S PLACE, 2164 DOTWANA, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15460,'lng':28.40270}},
{'stp':639732,'address':'639732, EGL - ISANDO, ISANDO HYBRID TEAM, 4 DEGREES EAST (NTO), 85 LEOPARD ROAD, PORTION 85 (A) FARM ZESFONEIN 27, REG. DIVISION IR, BENONI, South Africa','geo':{'lat':-26.07646,'lng':28.37981}},
{'stp':640845,'address':'640845, EGL - ISANDO, ISANDO HYBRID TEAM, SMALL\'S PLACE, 291 PHASWANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15570,'lng':28.40400}},
{'stp':641509,'address':'641509, EGL - ISANDO, ISANDO HYBRID TEAM, YEBO RESTAURANT, 1210 92 PRINCESS AVENUE, 92 PRINCESS AVENUE, #, BENONI, South Africa','geo':{'lat':-26.19180,'lng':28.31650}},
{'stp':641949,'address':'641949, EGL - ISANDO, ISANDO HYBRID TEAM, ZANZOLOS TAKE AWAYS, 2,28 SHOP NO 2, 28, TOM JONES STREET, BENONI, BENONI, South Africa','geo':{'lat':-26.19170,'lng':28.31760}},
{'stp':646483,'address':'646483, EGL - ISANDO, ISANDO HYBRID TEAM, CASTARS JAZZ JOING, 152 MAGIGWANE STREET   num 152, MAGIGWANE STREET, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.15806,'lng':28.41213}},
{'stp':646600,'address':'646600, EGL - ISANDO, ISANDO HYBRID TEAM, LAKESIDE DISCOUNT CELLARS, STAND NO  7443 AND 7637, SHOP NO 9A  1VOORTREKKER STR, BENONI, BENONI, South Africa','geo':{'lat':-26.18460,'lng':28.31885}},
{'stp':647255,'address':'647255, EGL - ISANDO, ISANDO HYBRID TEAM, BOBO\'S FAST FOODS, 1515 ERF, 36 ROTHSAY STREET, BENONI, BENONI, South Africa','geo':{'lat':-26.19200,'lng':28.32010}},
{'stp':647360,'address':'647360, EGL - ISANDO, ISANDO HYBRID TEAM, PICK AND EAT, 38 ROTHSAY STREET, CNR HARPUR AVENUE, #, BENONI, South Africa','geo':{'lat':-26.19240,'lng':28.32050}},
{'stp':649949,'address':'649949, EGL - ISANDO, ISANDO HYBRID TEAM, LOVE MART SHEBEEN, 2935 MAYFIELD EXT 5, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12481,'lng':28.35705}},
{'stp':657880,'address':'657880, EGL - ISANDO, ISANDO HYBRID TEAM, CHEERS FINE FOOD, ERF 1140, 26 ROTHSAY STREET, #, BENONI, South Africa','geo':{'lat':-26.18977,'lng':28.31894}},
{'stp':667187,'address':'667187, EGL - ISANDO, ISANDO HYBRID TEAM, VICKYS BAR AND RESTAURANT, MAIN REEF ROAD, #, BENONI SOUTH, BENONI, South Africa','geo':{'lat':-26.20790,'lng':28.28640}},
{'stp':674651,'address':'674651, EGL - ISANDO, ISANDO HYBRID TEAM, ZAMA ZAMA PUB AND GRILL, CNR 5TH STREET AND 14TH AVE, ERF 1127, BOKSBURG NORTH, BOKSBURG, South Africa','geo':{'lat':-26.20480,'lng':28.26490}},
{'stp':677477,'address':'677477, EGL - ISANDO, ISANDO HYBRID TEAM, DUCK INN (NTO), PORTION 27, FARM ZESFONTEIN 27 IR, ZESFONTEIN, BENONI, BENONI, South Africa','geo':{'lat':-26.09178,'lng':28.37587}},
{'stp':695220,'address':'695220, EGL - ISANDO, ISANDO HYBRID TEAM, COMEDUZE TAVERN, 2086 LEKALA STREET, CHIEF LUTHULI PARK EXTENSION 4, #, BENONI, South Africa','geo':{'lat':-26.15340,'lng':28.38340}},
{'stp':707443,'address':'707443, EGL - ISANDO, ISANDO HYBRID TEAM, COUNTRY INN (NTO), 20 CASON RD, BOKSBURG NORTH, #, BOKSBURG, South Africa','geo':{'lat':-26.20820,'lng':28.25195}},
{'stp':711048,'address':'711048, EGL - ISANDO, ISANDO HYBRID TEAM, KOPANO SPORTS BAR, ERF NO 1357, NO 19 PRINCESS AVE CNT SWAN STREET, #, BENONI, South Africa','geo':{'lat':-26.18920,'lng':28.32350}},
{'stp':716437,'address':'716437, EGL - ISANDO, ISANDO HYBRID TEAM, GOODLUCK RESTAURANT, 85 MAIN REEF ROAD, LILIANTON, #, BOKSBURG, South Africa','geo':{'lat':-26.19870,'lng':28.21720}},
{'stp':728547,'address':'728547, EGL - ISANDO, ISANDO HYBRID TEAM, THENI S TAVERN, ERF 537 NGOMANE STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.15840,'lng':28.40970}},
{'stp':732778,'address':'732778, EGL - ISANDO, ISANDO HYBRID TEAM, MARIA LIQUOR DISTRIBUTORS, 3330 RAMZA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.14362,'lng':28.41207}},
{'stp':733300,'address':'733300, EGL - ISANDO, ISANDO HYBRID TEAM, SPOT ON FAST FOODS AND RESTAURANT, ERF 600, 102 ELSTON AVENUE, CORNER BUNYAN STREET, BENONI, South Africa','geo':{'lat':-26.18860,'lng':28.31340}},
{'stp':735702,'address':'735702, EGL - ISANDO, ISANDO HYBRID TEAM, BENONI CORNER RESTAURANT, 35 VOORTREKKER STREET, CNR LAKE AVENUE, #, BENONI, South Africa','geo':{'lat':-26.19140,'lng':28.32230}},
{'stp':739026,'address':'739026, EGL - ISANDO, ISANDO HYBRID TEAM, LIQUOR CITY SESFIKILE SQUARE, HILL STREET, PORTION 11 OF ERF 30622, OP 23 SESFIKILE SQUARE, DAVEYTON, South Africa','geo':{'lat':-26.16349,'lng':28.40141}},
{'stp':745888,'address':'745888, EGL - ISANDO, ISANDO HYBRID TEAM, SPORTS CAFE, 38 STAND NO, TOM JONES STREET, #, BENONI, South Africa','geo':{'lat':-26.19340,'lng':28.31850}},
{'stp':769111,'address':'769111, EGL - ISANDO, ISANDO HYBRID TEAM, FELESA S, 58 CASON ROAD, ERF 536 BOKSBURG NORTH, #, BOKSBURG, South Africa','geo':{'lat':-26.20865,'lng':28.25757}},
{'stp':769671,'address':'769671, EGL - ISANDO, ISANDO HYBRID TEAM, MLANGENI TAVERN, 144 STAND NO, ERF 26 GELDENHUYS STR PUTFONTEIN, BENONI, BENONI, South Africa','geo':{'lat':-26.10303,'lng':28.38397}},
{'stp':778072,'address':'778072, EGL - ISANDO, ISANDO HYBRID TEAM, THE SANDY BUNKER (NTO), 145 MAIN REEF ROAD, BOKSBURG NORTH, #, BOKSBURG, South Africa','geo':{'lat':-26.20930,'lng':28.26951}},
{'stp':778282,'address':'778282, EGL - ISANDO, ISANDO HYBRID TEAM, THANDABANTU TAVERN, THUTWA STREET,  ERF 1837 CLOVADIN, #, BENONI, South Africa','geo':{'lat':-26.15517,'lng':28.38222}},
{'stp':780038,'address':'780038, EGL - ISANDO, ISANDO HYBRID TEAM, L J S PLACE, 2828 STAND NO, MADRID DRIVE CHIEF ALBERT LUTHULI PARK, EXT 4 DAVEYTON, BENONI, South Africa','geo':{'lat':-26.15792,'lng':28.38737}},
{'stp':781979,'address':'781979, EGL - ISANDO, ISANDO HYBRID TEAM, KWASS PLACE, 0095 MAGIGWANA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.15998,'lng':28.40762}},
{'stp':790729,'address':'790729, EGL - ISANDO, ISANDO HYBRID TEAM, ESIHLAHLENI BOTTLE STORE, MTHIMUNYE STREET, ERF 30755, DAVEYTON EXTENSION 6, DAVEYTON, South Africa','geo':{'lat':-26.16328,'lng':28.39875}},
{'stp':793441,'address':'793441, EGL - ISANDO, ISANDO HYBRID TEAM, PRINCESS TYATYEKA, 1 ISADOR STREET, NEW MODER STREET, #, BENONI, South Africa','geo':{'lat':-26.18394,'lng':28.36142}},
{'stp':798006,'address':'798006, EGL - ISANDO, ISANDO HYBRID TEAM, RELEBOHILE DORRIS MOHAPI, 2842 STREET NUMBER, ERF 2842 WASHINGTON DRIVE, CHIEF ALBERT LUTHULI EXT 4, BENONI, South Africa','geo':{'lat':-26.16217,'lng':28.39308}},
{'stp':863063,'address':'863063, EGL - ISANDO, ISANDO HYBRID TEAM, CORRECTIONAL SERVICES RECREATIONAL, CNR PAUL KRUGER & MODDER EAST ROAD, #, #, BENONI, South Africa','geo':{'lat':-26.17377,'lng':28.40526}},
{'stp':383311,'address':'383311, EGL - ISANDO, ISANDO HYBRID TEAM, GRILLOS PUB AND GRILL, STAND NO, F C DUMAT BUILDING, 47 SAREL CILLIERS STREET, DELMAS, South Africa','geo':{'lat':-26.14930,'lng':28.68720}},
{'stp':388424,'address':'388424, EGL - ISANDO, ISANDO HYBRID TEAM, ZWAKALA INN, 7293 STAND NO, 74, MATLAKANE STREET, EXTENTION 5,, DELMAS, South Africa','geo':{'lat':-26.10409,'lng':28.69980}},
{'stp':388882,'address':'388882, EGL - ISANDO, ISANDO HYBRID TEAM, KGABO  S TAVERN, 2531 STAND NO, NKADIBENG STREET, BOTLENG EXT 3, DELMAS, South Africa','geo':{'lat':-26.10448,'lng':28.68241}},
{'stp':392743,'address':'392743, EGL - ISANDO, ISANDO HYBRID TEAM, KHEHLA S PLACE, 893 STAND NO, TAU STREET, BOTLENG, DELMAS, South Africa','geo':{'lat':-26.13077,'lng':28.69477}},
{'stp':393098,'address':'393098, EGL - ISANDO, ISANDO HYBRID TEAM, GROOVIES NIGHT CLUB, 12 PORTION, OF THE FARM WITKLIP 229, DELMAS, DELMAS, South Africa','geo':{'lat':-26.14671,'lng':28.70415}},
{'stp':393289,'address':'393289, EGL - ISANDO, ISANDO HYBRID TEAM, SHOPRITE CHECKERS BOTLENG, 10 SHOP NO, BOTLENG MALL, ALONG R42 ROAD, CORNER OF DR NELSON, DELMAS, South Africa','geo':{'lat':-26.10105,'lng':28.67889}},
{'stp':463273,'address':'463273, EGL - ISANDO, ISANDO HYBRID TEAM, MOS PLACE, 7648 ERVEN 7647, BOTLENG EXT 5, #, DELMAS, South Africa','geo':{'lat':-26.09350,'lng':28.68971}},
{'stp':464761,'address':'464761, EGL - ISANDO, ISANDO HYBRID TEAM, TWO TOMAHAWK SPUR, STAND NO, WILLOWBROOK S/C SHOP 1, CNR SAMUEL &, VAN DER WALT STR, DELMAS, DELMAS, South Africa','geo':{'lat':-26.14936,'lng':28.67714}},
{'stp':468643,'address':'468643, EGL - ISANDO, ISANDO HYBRID TEAM, ZAPPAS (NTO), 51 DIE LAAN, ELOFF, DELMAS, DELMAS, South Africa','geo':{'lat':-26.16359,'lng':28.60325}},
{'stp':478279,'address':'478279, EGL - ISANDO, ISANDO HYBRID TEAM, SOL S PLACE, 20172 STAND NUMBER, MESH FUPHE, STR, DAVEYTON, South Africa','geo':{'lat':-26.11861,'lng':28.46539}},
{'stp':481176,'address':'481176, EGL - ISANDO, ISANDO HYBRID TEAM, UNCLE JOE S LIQUORS, 39 STAND NO, WITBANK AVE, #, DELMAS, South Africa','geo':{'lat':-26.18674,'lng':28.55149}},
{'stp':550159,'address':'550159, EGL - ISANDO, ISANDO HYBRID TEAM, DUMISANI LIQUORS, STAND 1357, PORTION 4, CNR EISLEN & MOTEBANG STREETS, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11990,'lng':28.46020}},
{'stp':550169,'address':'550169, EGL - ISANDO, ISANDO HYBRID TEAM, LEONARDS TAVERN TA STANDERTON TAV, 3396 EXT 4, PAHLAMUHLALA STREET, BOTLENG DELMAS, DELMAS, South Africa','geo':{'lat':-26.10530,'lng':28.68630}},
{'stp':550558,'address':'550558, EGL - ISANDO, ISANDO HYBRID TEAM, MABENA LIQUOR STORE, 3836 RUTH MOMPATHI, BOTLENG, EXT 3, DELMAS, South Africa','geo':{'lat':-26.10490,'lng':28.69670}},
{'stp':550666,'address':'550666, EGL - ISANDO, ISANDO HYBRID TEAM, GEORGES TAVERN, STAND 20551, ETWATWA WEST, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12290,'lng':28.46230}},
{'stp':550846,'address':'550846, EGL - ISANDO, ISANDO HYBRID TEAM, LERATONG LIQUOR STORE, STAND 4, LIFATENG SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01280,'lng':28.21062}},
{'stp':550940,'address':'550940, EGL - ISANDO, ISANDO HYBRID TEAM, SUNDRA RESTAURANT, 56 RENNIE AVENUE, SPRINGS AGGICULTURAL HOLDINGS, DELMAS, DELMAS, South Africa','geo':{'lat':-26.18000,'lng':28.53720}},
{'stp':550947,'address':'550947, EGL - ISANDO, ISANDO HYBRID TEAM, HEROLD S INN, 177 SHABANGU STREET, BOTLENG LOCATION, DELMAS, DELMAS, South Africa','geo':{'lat':-26.13102,'lng':28.69653}},
{'stp':551244,'address':'551244, EGL - ISANDO, ISANDO HYBRID TEAM, CALABASH RESTAURANT DELMAS, 2289 EXT 3 NDINISA STREET, BOTLENG DELMAS, 2210, DELMAS, South Africa','geo':{'lat':-26.10400,'lng':28.68220}},
{'stp':551439,'address':'551439, EGL - ISANDO, ISANDO HYBRID TEAM, BOTLENG LIQUOR STORE, SHOP 2 C/O DIAMAN & ROBYN STR, DELMAS, #, DELMAS, South Africa','geo':{'lat':-26.13273,'lng':28.68753}},
{'stp':551652,'address':'551652, EGL - ISANDO, ISANDO HYBRID TEAM, MKHOMAZI TAVERN, 1216 ETWATWA LOCATION, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12825,'lng':28.46557}},
{'stp':551897,'address':'551897, EGL - ISANDO, ISANDO HYBRID TEAM, MAGAGULA BOTTLE STORE, 1711 MBINGO STREET, BOTLENG, EXT 2 DELMAS, DELMAS, South Africa','geo':{'lat':-26.13510,'lng':28.69160}},
{'stp':551988,'address':'551988, EGL - ISANDO, ISANDO HYBRID TEAM, OUR PLACE LIQ STORE, PORTION 13 OF FARM LEEUPOORT, DELMAS, #, DELMAS, South Africa','geo':{'lat':-26.10560,'lng':28.68110}},
{'stp':552061,'address':'552061, EGL - ISANDO, ISANDO HYBRID TEAM, VEZUBUHLE BEER HALL, ERF 1973 SINDANI STREET, DELMAS, DELMAS, DELMAS, South Africa','geo':{'lat':-26.12830,'lng':28.69220}},
{'stp':552753,'address':'552753, EGL - ISANDO, ISANDO HYBRID TEAM, MNISIS TAVERN, 14296 BEN MYATAZA STREET, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12760,'lng':28.44800}},
{'stp':552973,'address':'552973, EGL - ISANDO, ISANDO HYBRID TEAM, SENTRAAL DRANKWINKEL, SAREL CILLIERS STR, DELMAS EXTENSION 8, #, DELMAS, South Africa','geo':{'lat':-26.15092,'lng':28.68381}},
{'stp':553078,'address':'553078, EGL - ISANDO, ISANDO HYBRID TEAM, FARMERS MEAT LIQUORS, PORTION 40 OF FARM GEIGERLE, 238 WITBANKWEG, SUNDRA, DELMAS, South Africa','geo':{'lat':-26.18798,'lng':28.52860}},
{'stp':553126,'address':'553126, EGL - ISANDO, ISANDO HYBRID TEAM, MANAKA\'S BOTTLE STORE, GIDEON NKOMO STR, DAVEYTON, 1500, DAVEYTON, South Africa','geo':{'lat':-26.11890,'lng':28.46410}},
{'stp':553562,'address':'553562, EGL - ISANDO, ISANDO HYBRID TEAM, EZIBANDAYO BOTTLE STORE, 512 ESITHAME SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.00590,'lng':28.22630}},
{'stp':553571,'address':'553571, EGL - ISANDO, ISANDO HYBRID TEAM, MOKOENA S TAVERN, STAND 20327, ETWATWA, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.12180,'lng':28.46660}},
{'stp':553645,'address':'553645, EGL - ISANDO, ISANDO HYBRID TEAM, MSIZA BOTTLE STORE, PERSEEL 894 VAN ZYLSTRAAT, BOTLENG, DELMAS, DELMAS, South Africa','geo':{'lat':-26.13100,'lng':28.69540}},
{'stp':553679,'address':'553679, EGL - ISANDO, ISANDO HYBRID TEAM, MOKOENA S TAVERN, 14028 NDWANDWE STREET, DAVEYTON, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.13020,'lng':28.45140}},
{'stp':553745,'address':'553745, EGL - ISANDO, ISANDO HYBRID TEAM, PALM DISCOUNT LIQUORS, CRN JOHN VOSTER STR NEXT TO, THE P29 RD PORTION OF 56 OF TH, FARM DROOGEFONTEIN 242 DELMAS, DELMAS, South Africa','geo':{'lat':-26.18670,'lng':28.54670}},
{'stp':560172,'address':'560172, EGL - ISANDO, ISANDO HYBRID TEAM, RAMPOU MM, 14138 MOSS MABONA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12940,'lng':28.45020}},
{'stp':560173,'address':'560173, EGL - ISANDO, ISANDO HYBRID TEAM, MOKOENA ZZ, 1926 SIBOYANA STREET, THULANI VILLAGE, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.12560,'lng':28.45010}},
{'stp':560175,'address':'560175, EGL - ISANDO, ISANDO HYBRID TEAM, MASENTSE MB, 1849 CHRIS HANI, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.13310,'lng':28.45100}},
{'stp':560178,'address':'560178, EGL - ISANDO, ISANDO HYBRID TEAM, THANDORA S PLACE, 2201 VICTOR DRIVE, THULANI, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.13032,'lng':28.48108}},
{'stp':560290,'address':'560290, EGL - ISANDO, ISANDO HYBRID TEAM, MAIN ROAD LIQUOR STORE, HOLDING 382 RIETKOL AGRICULTURAL, Holdings resitration division, delmas, DELMAS, South Africa','geo':{'lat':-26.15190,'lng':28.54170}},
{'stp':560425,'address':'560425, EGL - ISANDO, ISANDO HYBRID TEAM, MAREDI TJ, MKROZA STREET, ERF 3459 EXT 2, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.13560,'lng':28.45170}},
{'stp':560563,'address':'560563, EGL - ISANDO, ISANDO HYBRID TEAM, CINDI R, 426 ETWATWA WEST, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12396,'lng':28.46272}},
{'stp':580412,'address':'580412, EGL - ISANDO, ISANDO HYBRID TEAM, NGWENYA SP, 6 SOTLEKE CRESCENT, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.11810,'lng':28.46470}},
{'stp':580413,'address':'580413, EGL - ISANDO, ISANDO HYBRID TEAM, MASHINI VA, 5289 TAMBO CRESCENT, EXT 7 ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.11360,'lng':28.46800}},
{'stp':580728,'address':'580728, EGL - ISANDO, ISANDO HYBRID TEAM, NGOBESE L, 997 ETWATWA WEST, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12840,'lng':28.46760}},
{'stp':603991,'address':'603991, EGL - ISANDO, ISANDO HYBRID TEAM, MAJOLA MA, 288 NTSONALANGA SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.02010,'lng':28.18630}},
{'stp':604523,'address':'604523, EGL - ISANDO, ISANDO HYBRID TEAM, MZANZI PUB, MZANZI PUB, 14319 SEISO STREET, #, DAVEYTON, South Africa','geo':{'lat':-26.12632,'lng':28.44732}},
{'stp':607065,'address':'607065, EGL - ISANDO, ISANDO HYBRID TEAM, MOKOENA KG, 11447 IVORY PARK, #, #, IVORY PARK, South Africa','geo':{'lat':-25.98810,'lng':28.20030}},
{'stp':607312,'address':'607312, EGL - ISANDO, ISANDO HYBRID TEAM, MOKOENA PR, 216 MOEDI SECTION, #, #, TEMBISA, South Africa','geo':{'lat':-26.01280,'lng':28.19510}},
{'stp':624989,'address':'624989, EGL - ISANDO, ISANDO HYBRID TEAM, WESTBURY TAVERN DELMAS, STAND 3828 RUTH MPHATHI, BOTLENG, #, DELMAS, South Africa','geo':{'lat':-26.10580,'lng':28.69670}},
{'stp':626343,'address':'626343, EGL - ISANDO, ISANDO HYBRID TEAM, SITHOLE TAVERN, 788 DAY PARK EXT 2, #, #, DELMAS, South Africa','geo':{'lat':-26.14255,'lng':28.69147}},
{'stp':627303,'address':'627303, EGL - ISANDO, ISANDO HYBRID TEAM, FRANKS TAVERN, STAND 9872, IVORY PARK, #, TEMBISA, South Africa','geo':{'lat':-25.99300,'lng':28.21240}},
{'stp':627674,'address':'627674, EGL - ISANDO, ISANDO HYBRID TEAM, BORAKALO, 434 MVEVE, ETWATWA WEST, #, DAVEYTON, South Africa','geo':{'lat':-26.12370,'lng':28.46360}},
{'stp':627931,'address':'627931, EGL - ISANDO, ISANDO HYBRID TEAM, MA AFRICA, 21 NOMULODI STREET (No 2145), RAILWAY (Thulani section), #, DAVEYTON, South Africa','geo':{'lat':-26.12890,'lng':28.45320}},
{'stp':628690,'address':'628690, EGL - ISANDO, ISANDO HYBRID TEAM, MASHS PLACE, 4624 EVERLASTING STREET, ETWATWA (THULANI RAILWAY), #, DAVEYTON, South Africa','geo':{'lat':-26.13260,'lng':28.45660}},
{'stp':629454,'address':'629454, EGL - ISANDO, ISANDO HYBRID TEAM, NKELES PLACE, 20097 MVEVE STREET, ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.12340,'lng':28.46490}},
{'stp':629692,'address':'629692, EGL - ISANDO, ISANDO HYBRID TEAM, ZEEZ AFRCAN CUISINE, 20915 ETWATWA WEST, #, #, DAVEYTON, South Africa','geo':{'lat':-26.12720,'lng':28.46800}},
{'stp':630112,'address':'630112, EGL - ISANDO, ISANDO HYBRID TEAM, LINA\'S PLACE, 3833 OAKMOOR, EXTENSION 7, #, TEMBISA, South Africa','geo':{'lat':-26.01160,'lng':28.24800}},
{'stp':631165,'address':'631165, EGL - ISANDO, ISANDO HYBRID TEAM, JOHNS TAVERN, 22355 MOHOBE STREET, THULANI EXTENSION 1, ETWATWA, BENONI, DAVEYTON, South Africa','geo':{'lat':-26.12990,'lng':28.45520}},
{'stp':632147,'address':'632147, EGL - ISANDO, ISANDO HYBRID TEAM, LIQUOR VALU DELMAS NF30, WILLOWBROOKE SQUARE, C/N SAMUEL & VAN DER WALT STREETS, DELMAS, DELMAS, South Africa','geo':{'lat':-26.14970,'lng':28.67630}},
{'stp':633083,'address':'633083, EGL - ISANDO, ISANDO HYBRID TEAM, ZOZOS PLACE, 5181 ETWATWA EXT 7, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.11320,'lng':28.46790}},
{'stp':633618,'address':'633618, EGL - ISANDO, ISANDO HYBRID TEAM, GEORGES PLACE, 1070 ETWATWA WEST, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12893,'lng':28.46847}},
{'stp':635656,'address':'635656, EGL - ISANDO, ISANDO HYBRID TEAM, DANIES TUCKSHOP, 310 KGAMA STREET, KGATLAMPING SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.01530,'lng':28.20010}},
{'stp':636400,'address':'636400, EGL - ISANDO, ISANDO HYBRID TEAM, TOWA\'S PLACE, 5607 MODISE CRESCENT, ETWA TWA, #, DAVEYTON, South Africa','geo':{'lat':-26.11720,'lng':28.46630}},
{'stp':636405,'address':'636405, EGL - ISANDO, ISANDO HYBRID TEAM, EMZINI WEYINZIZWA PLACE, 5209 ALFRED CRESENT, ETWATWA EXT 7, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.11520,'lng':28.46760}},
{'stp':636627,'address':'636627, EGL - ISANDO, ISANDO HYBRID TEAM, EZWELEHLE TAVERN, 578  ENDULWENI SECTION, TEMBISA, #, TEMBISA, South Africa','geo':{'lat':-26.01380,'lng':28.22070}},
{'stp':637179,'address':'637179, EGL - ISANDO, ISANDO HYBRID TEAM, FLOYDS PLACE, 5908 WINNIE MANDELA, ZONE 5, TEMBISA, IVORY PARK, South Africa','geo':{'lat':-25.97840,'lng':28.22930}},
{'stp':638143,'address':'638143, EGL - ISANDO, ISANDO HYBRID TEAM, MA JOSELS PLACE, 14165 MASANGO STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12920,'lng':28.45060}},
{'stp':639228,'address':'639228, EGL - ISANDO, ISANDO HYBRID TEAM, BIG M LIQUORS, CNR 1ST & 3RD STREET, #, #, DELMAS, South Africa','geo':{'lat':-26.15160,'lng':28.67960}},
{'stp':639825,'address':'639825, EGL - ISANDO, ISANDO HYBRID TEAM, MAGIC TAVERN, CNR 1TH AND 3RD STREET, DELMAS, #, DELMAS, South Africa','geo':{'lat':-26.15144,'lng':28.67965}},
{'stp':640114,'address':'640114, EGL - ISANDO, ISANDO HYBRID TEAM, MMATHANDOS PLACE, 702 THAMBO CRESCENT, ETWATWA WEST, #, DAVEYTON, South Africa','geo':{'lat':-26.12288,'lng':28.46244}},
{'stp':640147,'address':'640147, EGL - ISANDO, ISANDO HYBRID TEAM, MASILELA\'S PLACE, 506 ETWATWA STREET, DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12230,'lng':28.46240}},
{'stp':640405,'address':'640405, EGL - ISANDO, ISANDO HYBRID TEAM, PETROS GENERAL DEALERS, C08002 ERF, 2920 MKHWANAZI STREET, CHRIS HANI, DAVEYTON, South Africa','geo':{'lat':-26.14882,'lng':28.42842}},
{'stp':640421,'address':'640421, EGL - ISANDO, ISANDO HYBRID TEAM, HUDSON\'S PLACE, 254 COLLIS STREET, ETWATWA WEST, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11970,'lng':28.46670}},
{'stp':641034,'address':'641034, EGL - ISANDO, ISANDO HYBRID TEAM, A TO Z PLACE, 5512 MOKABA CRESCENT, ETWATWA EXT 7, DAVEYTON, DAVEYTON, South Africa','geo':{'lat':-26.11518,'lng':28.46761}},
{'stp':641180,'address':'641180, EGL - ISANDO, ISANDO HYBRID TEAM, MIMOSA LIQUOR STORE, H/V MARK & SERFONTEINSTRAAT, (Eloff) DELMAS, #, DELMAS, South Africa','geo':{'lat':-26.16940,'lng':28.60160}},
{'stp':651429,'address':'651429, EGL - ISANDO, ISANDO HYBRID TEAM, SIZAKANCANE TAVERN, STAND NO 420, DELPARK EXT 2, #, DELMAS, South Africa','geo':{'lat':-26.13917,'lng':28.69647}},
{'stp':651900,'address':'651900, EGL - ISANDO, ISANDO HYBRID TEAM, MANHATTANS TAVERN, STAND NO 820, DELPARK X2, #, DELMAS, South Africa','geo':{'lat':-26.14292,'lng':28.69356}},
{'stp':664121,'address':'664121, EGL - ISANDO, ISANDO HYBRID TEAM, MAITES TAVERN (NTO), 245 245 ENDULWENI SECTION, 245 ENDULWENI SECTION, 245 ENDULWENI SECTION, TEMBISA, South Africa','geo':{'lat':-26.01690,'lng':28.22170}},
{'stp':665954,'address':'665954, EGL - ISANDO, ISANDO HYBRID TEAM, Pitstop Pub and Grill (NTO), 31 Witbank Road, 31 Witbank Road,     31 Witbank Road, DELMAS, South Africa','geo':{'lat':-26.18670,'lng':28.54670}},
{'stp':675556,'address':'675556, EGL - ISANDO, ISANDO HYBRID TEAM, THAMBO\'S TAVERN, STAND NO 931 DELPARK, BOITLENG EXT NO 2, #, DELMAS, South Africa','geo':{'lat':-26.14260,'lng':28.69160}},
{'stp':686484,'address':'686484, EGL - ISANDO, ISANDO HYBRID TEAM, WHITE HOUSE TAVERN, 3777 MAJOLA STREET, #, EXT 3 BOTLENG LOCATION, DELMAS, South Africa','geo':{'lat':-26.10690,'lng':28.69510}},
{'stp':688935,'address':'688935, EGL - ISANDO, ISANDO HYBRID TEAM, BELLIER TRADING, HOUSE NO 3116 SHOP 30, CNR ANGELFISH AND ARCHERS FISH, KAALFONTEIN, IVORY PARK, South Africa','geo':{'lat':-25.98850,'lng':28.17400}},
{'stp':692050,'address':'692050, EGL - ISANDO, ISANDO HYBRID TEAM, MADEIRA TAVERN, GOEDGEDAGHT 10, DRYDEN DELAMS, #, DELMAS, South Africa','geo':{'lat':-26.10580,'lng':28.75350}},
{'stp':708912,'address':'708912, EGL - ISANDO, ISANDO HYBRID TEAM, BOULEVARD LIQUOR STORE, SHOP NO 1, STAND NO 209 BOTLENG LOCATION, #, DELMAS, South Africa','geo':{'lat':-26.12800,'lng':28.69540}},
{'stp':709921,'address':'709921, EGL - ISANDO, ISANDO HYBRID TEAM, MARANTJIE TAVERN, STAND NO 976, EXT 4, DELPARK, DELMAS, South Africa','geo':{'lat':-26.13684,'lng':28.70496}},
{'stp':713677,'address':'713677, EGL - ISANDO, ISANDO HYBRID TEAM, SOLLY LIQUOR DISTRIBUTORS, 247/42 ANDREW MAPHETHO DRIVE, UMFUYANEI SECTION, TEMBISA, TEMBISA, South Africa','geo':{'lat':-26.02553,'lng':28.19470}},
{'stp':719032,'address':'719032, EGL - ISANDO, ISANDO HYBRID TEAM, ELOFF BOTTLE STORE, 498 SPOORWEG AVENUE, BEING STAND 498 ELLOF, #, DELMAS, South Africa','geo':{'lat':-26.17000,'lng':28.60200}},
{'stp':723274,'address':'723274, EGL - ISANDO, ISANDO HYBRID TEAM, MIC LIQUOR KINGS, 173 TAU STREET, BOTLENG, #, DELMAS, South Africa','geo':{'lat':-26.13050,'lng':28.69630}},
{'stp':730856,'address':'730856, EGL - ISANDO, ISANDO HYBRID TEAM, SHOPRITE DELMAS 30685, SHOP 7,WILLOW SHOPPING CENTRE, CNR SAREL CILLIERS & SECOND AVENUE, BEING PORTION 1 OF ERF 765, DELMAS, South Africa','geo':{'lat':-26.15050,'lng':28.68250}},
{'stp':737804,'address':'737804, EGL - ISANDO, ISANDO HYBRID TEAM, ZINHLE S TAVERN, 1236 VUKUZENZELE LOCATION, #, #, DELMAS, South Africa','geo':{'lat':-26.13710,'lng':28.70560}},
{'stp':758877,'address':'758877, EGL - ISANDO, ISANDO HYBRID TEAM, GREEN HOUSE TAVERN, STAND NO, ERF 4937 BLUE GRASS STREET ETWATWA, #, DAVEYTON, South Africa','geo':{'lat':-26.13360,'lng':28.46840}},
{'stp':762771,'address':'762771, EGL - ISANDO, ISANDO HYBRID TEAM, VOLSTOOM, 16 STAND NO, PLOT 72 THIRD STREET, #, DELMAS, South Africa','geo':{'lat':-26.12760,'lng':28.56060}},
{'stp':765609,'address':'765609, EGL - ISANDO, ISANDO HYBRID TEAM, BOTLENG SPORTS BAR, ROBYN AND DIAMANT STREET, BOTLENG MALL CORNER, ERF 1645, DELMAS, South Africa','geo':{'lat':-26.13260,'lng':28.68640}},
{'stp':775582,'address':'775582, EGL - ISANDO, ISANDO HYBRID TEAM, BATAU TAVERN, 0206 STAND NO, DELPARK EXTENSION, #, DELMAS, South Africa','geo':{'lat':-26.14201,'lng':28.69138}},
{'stp':780165,'address':'780165, EGL - ISANDO, ISANDO HYBRID TEAM, DUDU S PLACE, 20092 STAND NO, MAKAMBULE STR ETWATWA WEST DAVEYTON, #, DAVEYTON, South Africa','geo':{'lat':-26.12365,'lng':28.46686}},
{'stp':780188,'address':'780188, EGL - ISANDO, ISANDO HYBRID TEAM, MGOLOZI TAVERN, 3480 STAND NO, NDABA STR EXT 4 BOTLENG, #, DELMAS, South Africa','geo':{'lat':-26.10500,'lng':28.68950}},
{'stp':786870,'address':'786870, EGL - ISANDO, ISANDO HYBRID TEAM, SIMON S TAVERN, 5440 STAND NO, BOTLENG, #, DELMAS, South Africa','geo':{'lat':-26.09881,'lng':28.68834}},
{'stp':794541,'address':'794541, EGL - ISANDO, ISANDO HYBRID TEAM, K BRA BEN TAVERN, 3081 STAND NO, SKOSANA CRESCENT, EXT 4, DELMAS, South Africa','geo':{'lat':-26.10372,'lng':28.69090}},
{'stp':799809,'address':'799809, EGL - ISANDO, ISANDO HYBRID TEAM, MBONANI TAVERN, NGOQO STREET, ERF 923, ETWATWA, DAVEYTON, South Africa','geo':{'lat':-26.12808,'lng':28.46857}},
{'stp':393347,'address':'393347, EGL - ISANDO, ISANDO HIGH END TEAM, SBUDEAS PLACE, 6316 MALIBONGWE STREET, EXTENSION 3, #, IVORY PARK, South Africa','geo':{'lat':-25.99337,'lng':28.20129}},
{'stp':385623,'address':'385623, EGL - ISANDO, ISANDO HIGH END TEAM, DESTINY EXCLUSIVE HOTEL, 154 STAND NO, ESTATE 154 CNR 3RD AND 6TH AVE, #, KEMPTON PARK, South Africa','geo':{'lat':-26.08521,'lng':28.29440}},
{'stp':386316,'address':'386316, EGL - ISANDO, ISANDO HIGH END TEAM, CELCIUS CAFE AND BAR, ERF 2636 STAND NO, EXT 53, GLEN MARAIS,  M 57 PRETORIA ROAD, KEMPTON PARK, South Africa','geo':{'lat':-26.06736,'lng':28.23551}},
{'stp':390181,'address':'390181, EGL - ISANDO, ISANDO HIGH END TEAM, RJ BRANDS, 2 STREET NO, FREEWAY PARK, MEERLUS STREET, ISANDO, South Africa','geo':{'lat':-26.16213,'lng':28.22370}},
{'stp':390786,'address':'390786, EGL - ISANDO, ISANDO HIGH END TEAM, THE FARM HOUSE, DENEL KEMPTON PARK CAMPUS, OLD SMUTS FARM DENEL SOUTH ROAD, OFF ATLAS ROAD ERF NO 1 ATLAS EXT 2, KEMPTON PARK, South Africa','geo':{'lat':-26.14406,'lng':28.26666}},
{'stp':391225,'address':'391225, EGL - ISANDO, ISANDO HIGH END TEAM, THE CLASSIC INDIAN BANQUET SIZZLER, 104,105 STAND NO, KEY LARGO 5 C 1ST FLOOR, CNR NORTH RAND RD N TRICHARDT RD, BOKSBURG, South Africa','geo':{'lat':-26.17799,'lng':28.25474}},
{'stp':392185,'address':'392185, EGL - ISANDO, ISANDO HIGH END TEAM, THE HOUSE OF RIBS FAMILY RESTAURANT, FESTIVAL MALL, SHOP NO: 150, CNR C.R. SWART DRIVE AND KELVIN ROAD, ESTHER PARK EXT 09 ERF6-6865, KEMPTON PARK, South Africa','geo':{'lat':-26.10489,'lng':28.22271}},
{'stp':392186,'address':'392186, EGL - ISANDO, ISANDO HIGH END TEAM, BURGER BISTRO / THE RUSTIC PUKA SHA, ERF : 1066, 218 MONUMENT ROAD, GLEN MARAIS EXTENTION, #, KEMPTON PARK, South Africa','geo':{'lat':-26.07629,'lng':28.25558}},
{'stp':393368,'address':'393368, EGL - ISANDO, ISANDO HIGH END TEAM, NIKOS - BENONI (NTO), SHOP NO. 40.41, A ,B & C, CORNER OA, HANEKAM STREET, OAKSFIELDS SHOPPING, CENTRE, NORTHMEAD EXTENTION 4, BENONI, South Africa','geo':{'lat':-26.15013,'lng':28.31105}},
{'stp':393369,'address':'393369, EGL - ISANDO, ISANDO HIGH END TEAM, CULACCINO RESTAURANT (NTO), PORTION 1 OF ERF 1377, SHOP 3, CORNER OF JACK NICKLAUS BOULEVARD &, SERENGETI DRIVE, WITFONTEIN EXTENTION 32, BENONI, South Africa','geo':{'lat':-26.04256,'lng':28.29189}},
{'stp':463886,'address':'463886, EGL - ISANDO, ISANDO HIGH END TEAM, Airport Craft Brewery (NTO), JHB INTERNATIONAL AIRPORT, KEMPTON PARK, ISANDO, KEMPTON PARK, South Africa','geo':{'lat':-26.13581,'lng':28.23140}},
{'stp':464216,'address':'464216, EGL - ISANDO, ISANDO HIGH END TEAM, JACKSONS REPUBLIC 2 (NTO), SHOP NO 34 AIRSIDE LEVEL 1, TERMINAL 6 JHB INTERNATIONAL AIRPORT, KEMPTONPARK, KEMPTON PARK, South Africa','geo':{'lat':-26.13498,'lng':28.23152}},
{'stp':467064,'address':'467064, EGL - ISANDO, ISANDO HIGH END TEAM, PANAROTTIS BRENTWOOD PARK (NTO), CNR STANLEY & GREAT NORTH ROADS, KEMPTONPARK, BRENTWOOD PARK SHOPPING CENTRE, KEMPTON PARK, South Africa','geo':{'lat':-26.11762,'lng':28.28091}},
{'stp':475848,'address':'475848, EGL - ISANDO, ISANDO HIGH END TEAM, ALBA RESTAURANT AND FAST FOOD, 37  PLOT, SHOP 7 POMANA ROAD, BREDELL POMANA, KEMPTON PARK, South Africa','geo':{'lat':-26.09002,'lng':28.27246}},
{'stp':477219,'address':'477219, EGL - ISANDO, ISANDO HIGH END TEAM, CASE DE AMIGO, 0487 STAND NO ERF, ALLEN GLEN SHOPPING CENTRE, SHOP 8 GNR PARTRIDGE & CACTUS STREETS, KEMPTON PARK, South Africa','geo':{'lat':-26.08213,'lng':28.23288}},
{'stp':478159,'address':'478159, EGL - ISANDO, ISANDO HIGH END TEAM, CAPELLO BIRCHLEIGH (NTO), SHOP 40 BIRCHLEIGH NORTH SHOP CENTR, CNR STRYDOM & MOOIFONTEIN DRIVE, BIRCHLEIGH NORTH, KEMPTON PARK, South Africa','geo':{'lat':-26.05119,'lng':28.22939}},
{'stp':481577,'address':'481577, EGL - ISANDO, ISANDO HIGH END TEAM, ATLAS BOWLING CLUB (NTO), NO 3 - 8 ATLAS ROAD, PORTION 131, OF THE FARM WITKOPPIE 64 IR, BONAERO PARK (DENEL), KEMPTON PARK, South Africa','geo':{'lat':-26.13990,'lng':28.26890}},
{'stp':481596,'address':'481596, EGL - ISANDO, ISANDO HIGH END TEAM, SHORTY\'S PUB AND RESTAURANT (NTO), 10 POMONA ROAD, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.09450,'lng':28.26162}},
{'stp':481597,'address':'481597, EGL - ISANDO, ISANDO HIGH END TEAM, CLUB VEGAS KEMPTON (NTO), BONAERO PARK SHOPPING CENTRE, SHOP F8 CNR ATLAS AND GELDENHUYS ROAD, BONAERO PARK, ERF 1093, KEMPTON PARK, South Africa','geo':{'lat':-26.11988,'lng':28.25821}},
{'stp':487682,'address':'487682, EGL - ISANDO, ISANDO HIGH END TEAM, ESIBAYENI LOUNGE, 2739 ERF NO, PORTION 1, NO 30 CENTRAL AVENUE, KEMPTON PARK, South Africa','geo':{'lat':-26.10956,'lng':28.23235}},
{'stp':488209,'address':'488209, EGL - ISANDO, ISANDO HIGH END TEAM, Rocco Mamas Glen Acres (NTO), Shop 33A, Glen Acres Shopping Centr, Corner Dann and Monument Road, #, KEMPTON PARK, South Africa','geo':{'lat':-26.07879,'lng':28.24798}},
{'stp':490799,'address':'490799, EGL - ISANDO, ISANDO HIGH END TEAM, CHESA NYAMA, 3 SHOP NO, MIDRAND MARKET, CNR CHURCH AND MARKET STREET, MIDRAND, South Africa','geo':{'lat':-25.99638,'lng':28.12933}},
{'stp':499123,'address':'499123, EGL - ISANDO, ISANDO HIGH END TEAM, COOL RUNNING PUB AND GRILL, 49 STAND NO, HIGH RD, NORTONS HOME ESTATE,, AGRICULTURAL HOLDINGS, BENONI, South Africa','geo':{'lat':-26.11740,'lng':28.29435}},
{'stp':540457,'address':'540457, EGL - ISANDO, ISANDO HIGH END TEAM, UPSTAIRS SPORTS BAR (NTO), STAND NO 324, HOMESTEAD, GERMISTON, PRIMROSE, South Africa','geo':{'lat':-26.17710,'lng':28.18840}},
{'stp':548950,'address':'548950, EGL - ISANDO, ISANDO HIGH END TEAM, BRENTWOOD SPUR KEMPTON PARK (NTO), CNR GREAT NORTH AND STANLEY STS, BRENTWOOD PARK, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.11762,'lng':28.28091}},
{'stp':550027,'address':'550027, EGL - ISANDO, ISANDO HIGH END TEAM, SOARING EAGLE SPUR STEAK RANCH, FOOD COURT TERMINAL 5 BETWEEN, DOMESTIC ARRIVALS&DEPARTURES, JHB INTERNATIONAL AIRPORT PORT, KEMPTON PARK, South Africa','geo':{'lat':-26.13589,'lng':28.22886}},
{'stp':550381,'address':'550381, EGL - ISANDO, ISANDO HIGH END TEAM, SAA IN FLIGHT SERVICES DUTY UNPD, UNIT F, WINGFIELD JET PARK, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.10650,'lng':28.21130}},
{'stp':550575,'address':'550575, EGL - ISANDO, ISANDO HIGH END TEAM, CBD BOTTLE STORE DELMAS, SHOP 13 CHECKERS CENTRE, CNR CILLIERS ST & 5TH AVE, DELMAS, DELMAS, South Africa','geo':{'lat':-26.14960,'lng':28.68740}},
{'stp':550576,'address':'550576, EGL - ISANDO, ISANDO HIGH END TEAM, MODDERFONTEIN CLUB, CASINO RD, MODDERFONTEIN, #, EDENVALE, South Africa','geo':{'lat':-26.09890,'lng':28.16730}},
{'stp':550580,'address':'550580, EGL - ISANDO, ISANDO HIGH END TEAM, DON ALFREDO S, GLENMARAIS SHOPPING CENTRE, CRN VELD & DAN RD(Phone John to open), GLENMARAIS KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.07020,'lng':28.24610}},
{'stp':550785,'address':'550785, EGL - ISANDO, ISANDO HIGH END TEAM, EIRA MOURA PORTUGUESE RESTAURT, 72 12TH AVENUE, EDENVALE, GERMISTON, EDENVALE, South Africa','geo':{'lat':-26.14040,'lng':28.15500}},
{'stp':551006,'address':'551006, EGL - ISANDO, ISANDO HIGH END TEAM, SAWUBONA LODGE AND RESTAURANT, ERF 31 PLOT 22/31, CNR DACOSTA  AND 9TH ROAD, BREDELL, KEMPTON PARK, South Africa','geo':{'lat':-26.07797,'lng':28.31605}},
{'stp':551009,'address':'551009, EGL - ISANDO, ISANDO HIGH END TEAM, TWO BREWERS INN, 103 -12 TH AVE, TANDELLA BUILDING, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13590,'lng':28.15340}},
{'stp':551195,'address':'551195, EGL - ISANDO, ISANDO HIGH END TEAM, KEMPTON PARK COUNTRY CLUB, CHESTNUT STREET, SPARTAN, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.10581,'lng':28.21143}},
{'stp':551303,'address':'551303, EGL - ISANDO, ISANDO HIGH END TEAM, FOOTLOOSE TROUT FARM, PORTION 101 OF THE FARM, DIEPSLOOT 388, DIEPSLOOT, FOURWAYS, South Africa','geo':{'lat':-25.93830,'lng':28.02640}},
{'stp':551574,'address':'551574, EGL - ISANDO, ISANDO HIGH END TEAM, LSG SKY CHEFS DUTY PAID, BARLETT EX 14, CNR JONES AND SPRINGBOK RD, #, KEMPTON PARK, South Africa','geo':{'lat':-26.16680,'lng':28.23180}},
{'stp':551779,'address':'551779, EGL - ISANDO, ISANDO HIGH END TEAM, HOLSTEIN MEATS, 24 LOPER AVENUE, AEROPARK, EXT 2 SPARTAN, KEMPTON PARK, South Africa','geo':{'lat':-26.12250,'lng':28.21270}},
{'stp':551936,'address':'551936, EGL - ISANDO, ISANDO HIGH END TEAM, NEWS CAFE BOKSBURG, ERVEN 104 & 105 SHOP 21, KEY LARGO CENTRE, CNR NORTH RAND & TRICHARDT STR, BOKSBURG, South Africa','geo':{'lat':-26.17782,'lng':28.25459}},
{'stp':552000,'address':'552000, EGL - ISANDO, ISANDO HIGH END TEAM, THE WINNING STREAK, SHOP NO 1 ISANDO JUNCTION, 4 SANDVALE RAOD, ISANDOVALE EDENVALE, ISANDO, South Africa','geo':{'lat':-26.13643,'lng':28.19298}},
{'stp':552068,'address':'552068, EGL - ISANDO, ISANDO HIGH END TEAM, LSG SKY CHEFS no exicise, JAN SMUTS, BOKSBURG, #, KEMPTON PARK, South Africa','geo':{'lat':-26.16680,'lng':28.23180}},
{'stp':552150,'address':'552150, EGL - ISANDO, ISANDO HIGH END TEAM, THE HARVESTERS, CONSTANCE PLACE, BIRCHLEIGH, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.04997,'lng':28.23983}},
{'stp':552154,'address':'552154, EGL - ISANDO, ISANDO HIGH END TEAM, INTER CONTINENTAL AIRPORT SUN, TERMINAL 3, JOHANNESBURG INTERNATIONAL, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.13313,'lng':28.22931}},
{'stp':552203,'address':'552203, EGL - ISANDO, ISANDO HIGH END TEAM, PRIME TIME SPORTS BAR, NIC MEYER SENTRUM, ELGINWEG, BIRCHLEIGH, KEMPTON PARK, South Africa','geo':{'lat':-26.07020,'lng':28.21860}},
{'stp':552408,'address':'552408, EGL - ISANDO, ISANDO HIGH END TEAM, BENONI NORTHERN SPORTS CLUB, BRODIGANS ST, RYNFIRELD, BENONI, BENONI, South Africa','geo':{'lat':-26.15530,'lng':28.31760}},
{'stp':552413,'address':'552413, EGL - ISANDO, ISANDO HIGH END TEAM, CYBER CAFE, ASTON MANOR COMPLEX, SHOP NO 7 MONUMENT ROAD, ERF 168 KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.08309,'lng':28.24303}},
{'stp':552440,'address':'552440, EGL - ISANDO, ISANDO HIGH END TEAM, ALPHA DRANKWINKEL 2, STAND 700, #, #, DELMAS, South Africa','geo':{'lat':-26.16490,'lng':28.60260}},
{'stp':552462,'address':'552462, EGL - ISANDO, ISANDO HIGH END TEAM, NORKEM GARDENS REST, SHOP 30 NORKEM MALL, CNR MOOIRIVER & JAMES WRIGHT, NORKEM PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.06430,'lng':28.22002}},
{'stp':552551,'address':'552551, EGL - ISANDO, ISANDO HIGH END TEAM, EMPERORS PALACE, JONES STREET, JURGENSPARK, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.14586,'lng':28.22283}},
{'stp':552810,'address':'552810, EGL - ISANDO, ISANDO HIGH END TEAM, LIVE AT WILDBEEST, CNR NORTHRAND RD+TRICHARDT STR, KEY LARGO CENTRE SHOP 19, BARDENE  BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.17820,'lng':28.25490}},
{'stp':552813,'address':'552813, EGL - ISANDO, ISANDO HIGH END TEAM, LAZY LEOPARD KEG AND AVIATOR, SHOPD001 NEW DOMESTIC TERMINAL, JHB INTERNATIONAL AIRPORT, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.13580,'lng':28.22850}},
{'stp':552828,'address':'552828, EGL - ISANDO, ISANDO HIGH END TEAM, CLEOPATRA RESTAURANT, CAESARS, 64 JONES ROAD, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.14590,'lng':28.22280}},
{'stp':553008,'address':'553008, EGL - ISANDO, ISANDO HIGH END TEAM, ROSIE O GRADYS, GROUND FLOOR HOLIDAY INN GARDEN, COURT 2 HULLEY RD ISANDO, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.12900,'lng':28.22130}},
{'stp':553022,'address':'553022, EGL - ISANDO, ISANDO HIGH END TEAM, THE TRAIN RECREATION CENTRE, SAA TECHNICAL PARKING, PORTION, 258 (OF 69) FARM WITKOPPIE, 64 JONES RD JHB INTERNATIONAL, KEMPTON PARK, South Africa','geo':{'lat':-26.14620,'lng':28.22450}},
{'stp':553263,'address':'553263, EGL - ISANDO, ISANDO HIGH END TEAM, SOUTHERN SUN OR TAMBO, OR TAMBO INTERNATIONAL AIRPORT, REMAINING PORTION OF THE FARM, WITKOPPIE 64 IR, KEMPTON PARK, South Africa','geo':{'lat':-26.13440,'lng':28.22780}},
{'stp':553265,'address':'553265, EGL - ISANDO, ISANDO HIGH END TEAM, DELMONT HOTEL, PORTION 317 PORTION OF 09  OF, FARM RIETFONTEIN 63  REG DIV, IR NORTH REEF RD  OFF BARBARA, ISANDO, South Africa','geo':{'lat':-26.16870,'lng':28.19590}},
{'stp':553533,'address':'553533, EGL - ISANDO, ISANDO HIGH END TEAM, HELDERFONTEIN CONFERENCE CENTRE, RES OF PORT 5 OF FARM SEVENFON, TEIN 407 JR, ERLING STREET RANDBURG, FOURWAYS, South Africa','geo':{'lat':-25.98970,'lng':28.02310}},
{'stp':553637,'address':'553637, EGL - ISANDO, ISANDO HIGH END TEAM, S A A IN FLIGHT SERVICES DUTY PAID, KWE(KINTETSU WORLD EXPRESS), UNIT F, WINGFIELD JETPARK, KEMPTON PARK, South Africa','geo':{'lat':-26.16390,'lng':28.22890}},
{'stp':560453,'address':'560453, EGL - ISANDO, ISANDO HIGH END TEAM, CHATOGA SPUR, COR MONUMENT & DANN RD, GLEN MARAIS EXT 31, ERF 2264, KEMPTON PARK, South Africa','geo':{'lat':-26.07670,'lng':28.24850}},
{'stp':560580,'address':'560580, EGL - ISANDO, ISANDO HIGH END TEAM, BENONI ACTION SPORTS, NUMBER 12 ELSTON AVE, BENONI, #, BENONI, South Africa','geo':{'lat':-26.18436,'lng':28.32184}},
{'stp':580497,'address':'580497, EGL - ISANDO, ISANDO HIGH END TEAM, KELVIN ESTATES RECREATIONAL CLUB, PORTION 82 OF THE FARM, ZUURFONTEIN NO 33 IR, #, KEMPTON PARK, South Africa','geo':{'lat':-26.11790,'lng':28.19840}},
{'stp':581085,'address':'581085, EGL - ISANDO, ISANDO HIGH END TEAM, AIRPORT GRAND HOTEL, ERF 552, PORTION 1, 100 NORTHRAND ROAD, EXT 7, KEMPTON PARK, South Africa','geo':{'lat':-26.17770,'lng':28.24440}},
{'stp':603424,'address':'603424, EGL - ISANDO, ISANDO HIGH END TEAM, AVION PARK BOWING CLUB, AVION PARK RECREATION CLUB, HIGHVELD ROAD, #, KEMPTON PARK, South Africa','geo':{'lat':-26.09680,'lng':28.24280}},
{'stp':604886,'address':'604886, EGL - ISANDO, ISANDO HIGH END TEAM, CAFE MULLIGAN, CNR WOODMEAD & MAXWELL DRIVE, WOODMEAD, #, MIDRAND, South Africa','geo':{'lat':-26.05619,'lng':28.08990}},
{'stp':609461,'address':'609461, EGL - ISANDO, ISANDO HIGH END TEAM, ROCK PARADISE (NTO), 72 POMONA RD, KEMPTON PARK, KEMPTON PARK, Johannesburg, KEMPTON PARK, South Africa','geo':{'lat':-26.08984,'lng':28.27283}},
{'stp':610011,'address':'610011, EGL - ISANDO, ISANDO HIGH END TEAM, WOODSTOCK RESTAURANT (NTO), GLENVISTA NO 2058 AND MARABOE RD, BIRCHACRES, #, KEMPTON PARK, South Africa','geo':{'lat':-26.06082,'lng':28.21193}},
{'stp':610632,'address':'610632, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET - Emperors (NTO), CAESARS, #, Johannesburg, KEMPTON PARK, South Africa','geo':{'lat':-26.14570,'lng':28.22310}},
{'stp':614205,'address':'614205, EGL - ISANDO, ISANDO HIGH END TEAM, TRIBES (NTO), 64 JONES ROAD (CEASARS), JET PARK, BOKSBURG, Johannesburg, KEMPTON PARK, South Africa','geo':{'lat':-26.14570,'lng':28.22310}},
{'stp':614777,'address':'614777, EGL - ISANDO, ISANDO HIGH END TEAM, NEWTONS PUB (NTO), 17 NEWTON ROAD, SPARTAN, Johannesburg, KEMPTON PARK, South Africa','geo':{'lat':-26.11471,'lng':28.21569}},
{'stp':624713,'address':'624713, EGL - ISANDO, ISANDO HIGH END TEAM, Ocean Basket JHB Int Domestic Arriv, Johannesburg International Domestic, mezzanine level, Johannesburg, KEMPTON PARK, South Africa','geo':{'lat':-26.13040,'lng':28.23120}},
{'stp':640598,'address':'640598, EGL - ISANDO, ISANDO HIGH END TEAM, Brave Hawk Spur (NTO), C/O James Wright & Mooirivier, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.06434,'lng':28.22016}},
{'stp':640599,'address':'640599, EGL - ISANDO, ISANDO HIGH END TEAM, RUNNING WATERS SPUR (NTO), C/O CR SWART & KELVIN STREET, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10064,'lng':28.21955}},
{'stp':641932,'address':'641932, EGL - ISANDO, ISANDO HIGH END TEAM, KEMPTON PARK WOLWE RUGBY CLUB (NTO), C.R. SWART DRIVE, BARNARD STADIUM, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10049,'lng':28.20660}},
{'stp':642500,'address':'642500, EGL - ISANDO, ISANDO HIGH END TEAM, VIRGIN ATLANTIC (NTO), O.R TAMBO INTER DEPARTURES, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.13699,'lng':28.23307}},
{'stp':642501,'address':'642501, EGL - ISANDO, ISANDO HIGH END TEAM, AIR FRANCE (NTO), O.R. TAMBO INTER DEPARTURES, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.13815,'lng':28.23329}},
{'stp':642509,'address':'642509, EGL - ISANDO, ISANDO HIGH END TEAM, GOLD RUSH SPUR (NTO), SHOP 22 THE EMPORIUM @ PALACE, 64 JONES RD JURGENS PARK EXT 2, ERVEN 8 & 9 JURGENS PARK EXT 3, KEMPTON PARK, South Africa','geo':{'lat':-26.14682,'lng':28.22700}},
{'stp':642516,'address':'642516, EGL - ISANDO, ISANDO HIGH END TEAM, CASINO FLOOR - EMPERORS PALACE (NTO, 64 EMPERORS PALACE, JONES ROAD, JETPARK, KEMPTON PARK, South Africa','geo':{'lat':-26.14685,'lng':28.22702}},
{'stp':642517,'address':'642517, EGL - ISANDO, ISANDO HIGH END TEAM, ODION SHOW BAR (NTO), EMPERORS PALACE JONES ROAD, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.14687,'lng':28.22703}},
{'stp':642518,'address':'642518, EGL - ISANDO, ISANDO HIGH END TEAM, MARC ANTONIES, EMPERORS PALACE, JONES ROAD, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.14686,'lng':28.22709}},
{'stp':646481,'address':'646481, EGL - ISANDO, ISANDO HIGH END TEAM, 8\'s SPORTS AND POOL BAR (NTO), 148 MAIN RD, WITFIELD, BOKSBURG, ISANDO, South Africa','geo':{'lat':-26.17649,'lng':28.21039}},
{'stp':650905,'address':'650905, EGL - ISANDO, ISANDO HIGH END TEAM, THE CREW ROOM LOUNGE, NO 1 GELDENHUYS STREET, BONERO PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.12060,'lng':28.25830}},
{'stp':652009,'address':'652009, EGL - ISANDO, ISANDO HIGH END TEAM, DROS KEMPTON PARK, SHOP 10, THE CAVENDISH GLEN, 127 MONUMENT ROAD CNR RIETFONTEIN ROAD, ERF 3142, GLEN MARAIS EXT 25, KEMPTON PARK, South Africa','geo':{'lat':-26.08500,'lng':28.23950}},
{'stp':665228,'address':'665228, EGL - ISANDO, ISANDO HIGH END TEAM, TASTE OF MUMBAI (NTO), EMPERORS PALACE, 64 JONES RD, 64 JONES RD, KEMPTON PARK, South Africa','geo':{'lat':-26.14519,'lng':28.22229}},
{'stp':665229,'address':'665229, EGL - ISANDO, ISANDO HIGH END TEAM, COL\'CACCHIO EMPERORS PALACE (NTO), EMPERORS PALACE, 64 JONES RD, 64 JONES RD, KEMPTON PARK, South Africa','geo':{'lat':-26.14519,'lng':28.22229}},
{'stp':665230,'address':'665230, EGL - ISANDO, ISANDO HIGH END TEAM, 21 DEGREES (NTO), EMPERORS PALACE, 64 JONES RD, 64 JONES RD, KEMPTON PARK, South Africa','geo':{'lat':-26.14519,'lng':28.22229}},
{'stp':665231,'address':'665231, EGL - ISANDO, ISANDO HIGH END TEAM, AURELIUS (NTO), EMPERORS PALACE, 64 JONES RD, 64 JONES RD, KEMPTON PARK, South Africa','geo':{'lat':-26.14519,'lng':28.22229}},
{'stp':665233,'address':'665233, EGL - ISANDO, ISANDO HIGH END TEAM, SILVERMOON (NTO), EMPERORS PALACE, 64 JONES RD, 64 JONES RD, KEMPTON PARK, South Africa','geo':{'lat':-26.14519,'lng':28.22229}},
{'stp':665234,'address':'665234, EGL - ISANDO, ISANDO HIGH END TEAM, EMPERORS PRIVE (NTO), EMPERORS PALACE, 64 JONES RD, 64 JONES RD, KEMPTON PARK, South Africa','geo':{'lat':-26.14526,'lng':28.22059}},
{'stp':665256,'address':'665256, EGL - ISANDO, ISANDO HIGH END TEAM, NANDOS FESTIVAL MALL (NTO), FESTIVAL MALL, CNR CR SWART DRIVE AND KELVIN STREET, CNR CR SWART DRIVE AND KELVIN STREET, KEMPTON PARK, South Africa','geo':{'lat':-26.10495,'lng':28.22319}},
{'stp':665257,'address':'665257, EGL - ISANDO, ISANDO HIGH END TEAM, MUGG AND BEAN KEMPTON PARK (NTO), SHOP 11 WOODBRIGDE SQUARE, CNR MONUMENT AND DANN ROAD, GLEN MARAIS HYPER, KEMPTON PARK, South Africa','geo':{'lat':-26.10495,'lng':28.22319}},
{'stp':665258,'address':'665258, EGL - ISANDO, ISANDO HIGH END TEAM, PANAROTTI\'S FESTIVAL MALL (NTO), FESTIVAL MALL, CNR CR SWART DRIVE AND KELVIN STREET, CNR CR SWART DRIVE AND KELVIN STREET, KEMPTON PARK, South Africa','geo':{'lat':-26.10495,'lng':28.22319}},
{'stp':665262,'address':'665262, EGL - ISANDO, ISANDO HIGH END TEAM, NANDOS GLENACRES (NTO), GLENACRE SHOPPING MALL, 1 CNR MONUMENT & DANA ROADS, GLEN MARAIS, KEMPTON PARK, South Africa','geo':{'lat':-26.07797,'lng':28.24870}},
{'stp':665263,'address':'665263, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET GLENACRES (NTO), GLENACRE  SHOPPING MALL, 1 CNR MONUMENT & DANA ROADS, GLEN MARAIS, KEMPTON PARK, South Africa','geo':{'lat':-26.07951,'lng':28.24727}},
{'stp':665264,'address':'665264, EGL - ISANDO, ISANDO HIGH END TEAM, MIMMOS KEMPTON PARK (NTO), GLENACRE SHOPPING MALL, 1 CNR MONUMENT & DANA ROADS, GLEN MARAIS, KEMPTON PARK, South Africa','geo':{'lat':-26.07797,'lng':28.24870}},
{'stp':666254,'address':'666254, EGL - ISANDO, ISANDO HIGH END TEAM, GREEN LINE LIQUOR STORE, CNR BOSWELL AND ELGIN ROAD, SHOP 3 7 ELEVEN CENTRE, ERF 1786 X 13, KEMPTON PARK, South Africa','geo':{'lat':-26.06990,'lng':28.21870}},
{'stp':668296,'address':'668296, EGL - ISANDO, ISANDO HIGH END TEAM, ANTHEOS INTERNET BAR (NTO), CNR DEWIEKES AND P91, WATERFALL PARK, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.07789,'lng':28.21051}},
{'stp':668297,'address':'668297, EGL - ISANDO, ISANDO HIGH END TEAM, THE HALFWAY INN (NTO), CNR 7TH AND NEW ROAD, INDIGO GARDEN CENTRE CARLSWORLD, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.97564,'lng':28.10586}},
{'stp':673535,'address':'673535, EGL - ISANDO, ISANDO HIGH END TEAM, PLATIA (NTO), SHOP 23 EMPERORS PALACE, SHOP 23 EMPERORS PALACE, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.14570,'lng':28.22310}},
{'stp':673536,'address':'673536, EGL - ISANDO, ISANDO HIGH END TEAM, BRAZA EMPERORS PALACE (NTO), SHOP 22 EMPERORS PALACE, SHOP 22 EMPERORS PALACE, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.14570,'lng':28.22310}},
{'stp':676735,'address':'676735, EGL - ISANDO, ISANDO HIGH END TEAM, AVION TENNIS CLUB (NTO), 11 BOEKENHOUT STREET, BIRCHLEIGH, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.09780,'lng':28.24471}},
{'stp':680358,'address':'680358, EGL - ISANDO, ISANDO HIGH END TEAM, PROTEA HOTEL OR TAMBO, 0 CNR YORK AND GLADIATOR STREETS, #, RHODESFIELD, KEMPTON PARK, South Africa','geo':{'lat':-26.12574,'lng':28.23201}},
{'stp':685771,'address':'685771, EGL - ISANDO, ISANDO HIGH END TEAM, THE BARNYARD EMPERORS (NTO), EMPERORS PALACE, JONES ROAD, BOKSBURG, KEMPTON PARK, South Africa','geo':{'lat':-26.14590,'lng':28.22141}},
{'stp':688893,'address':'688893, EGL - ISANDO, ISANDO HIGH END TEAM, NEWS CAFE EMPERORS PALACE, SHOP NO 21, THE EMPORIUM AT THE EMPERORS PLACE, 64 JONES ROAD REMAINER OF PORTION 210, KEMPTON PARK, South Africa','geo':{'lat':-26.14603,'lng':28.22088}},
{'stp':691416,'address':'691416, EGL - ISANDO, ISANDO HIGH END TEAM, GOODIES COFFEE SHOP, VLEI STREET, GOODIES FOR GARDENS CENTRE, GLEN MARAIS EXT 24, KEMPTON PARK, South Africa','geo':{'lat':-26.06660,'lng':28.24070}},
{'stp':696530,'address':'696530, EGL - ISANDO, ISANDO HIGH END TEAM, PREMIER HOTEL OR TAMBO (NTO), 16 FORTRESS STREET, RHODESFIELD, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.12133,'lng':28.23231}},
{'stp':696958,'address':'696958, EGL - ISANDO, ISANDO HIGH END TEAM, MIMMOS OAKFIELDS (NTO), OAKFIELDS SHOPPING CENTRE, NORTHMEAD EXT 4, BENONI, BENONI, South Africa','geo':{'lat':-26.15011,'lng':28.31115}},
{'stp':696959,'address':'696959, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET OAKFIELDS (NTO), OAKFIELDS SHOPPING CENTRE, NORTHMEAD EXT 4, BENONI, BENONI, South Africa','geo':{'lat':-26.15035,'lng':28.31125}},
{'stp':696975,'address':'696975, EGL - ISANDO, ISANDO HIGH END TEAM, NEPTUNES (NTO), SHOP 14 UPPER LEVEL, TUDIR ROSE CENTRE, 212 NORTHRAND ROAD, BOKSBURG, South Africa','geo':{'lat':-26.17919,'lng':28.25527}},
{'stp':698681,'address':'698681, EGL - ISANDO, ISANDO HIGH END TEAM, CAPPELLO BOKSBURG, ERF 4277 TUDOR ROSE CENTRE, 135 NORTH RAND ROAD, #, BOKSBURG, South Africa','geo':{'lat':-26.17934,'lng':28.25541}},
{'stp':698683,'address':'698683, EGL - ISANDO, ISANDO HIGH END TEAM, BIDVEST INTERNATIONAL PREMIERE (NTO, OR INTL AIRPORT, OR INTL AIRPORT, #, KEMPTON PARK, South Africa','geo':{'lat':-26.16640,'lng':28.23150}},
{'stp':699669,'address':'699669, EGL - ISANDO, ISANDO HIGH END TEAM, EUROPA (NTO), OR TAMBO INTERNATIONAL AIRPORT, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.12900,'lng':28.22130}},
{'stp':700986,'address':'700986, EGL - ISANDO, ISANDO HIGH END TEAM, KEMPTON PARK FOOTBALL CLUB (NTO), 54 PLANE ROAD, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10861,'lng':28.20674}},
{'stp':701497,'address':'701497, EGL - ISANDO, ISANDO HIGH END TEAM, CITY LODGE AT OR TAMBO AIRPORT, MSP2 PARKADE AT OR TAMBO, INTERNATIONAL AIRPORT BEING PORTION 69, OF THE FARM WITKOPPIE 64 IR, KEMPTON PARK, South Africa','geo':{'lat':-26.13302,'lng':28.22936}},
{'stp':704124,'address':'704124, EGL - ISANDO, ISANDO HIGH END TEAM, PRIME TIME 2 (NTO), NORKEM PLAZA, 56 JAMES WRIGHT AVE, SHOP 4-7, KEMPTON PARK, South Africa','geo':{'lat':-26.05470,'lng':28.22410}},
{'stp':712173,'address':'712173, EGL - ISANDO, ISANDO HIGH END TEAM, FABZ ESTATE HOTEL (NTO), CONCOURSE CRESCENT, JOHANNESBURG, #, FOURWAYS, South Africa','geo':{'lat':-26.01761,'lng':28.02996}},
{'stp':712174,'address':'712174, EGL - ISANDO, ISANDO HIGH END TEAM, CAPPELLO KEMPTON PARK (NTO), ERF NO:3424/3425 GLEN MARAIS, CNR FIELD & LOAM STR, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.08262,'lng':28.25307}},
{'stp':717494,'address':'717494, EGL - ISANDO, ISANDO HIGH END TEAM, SERENGETI GOLF CLUB, ERF 1253 & 1254, WITFONTEIN X31 TOWNSHIP ON JACK, NICKLAUS DRIVE,SERENGETI GOLF CLUB, KEMPTON PARK, South Africa','geo':{'lat':-26.04270,'lng':28.29060}},
{'stp':724583,'address':'724583, EGL - ISANDO, ISANDO HIGH END TEAM, HOOTERS KEMPTON PARK (NTO), THE EMPORIUM @ EMPERORS PALACE, 62 JONES ROAD, ERVEN 8, 9, 13 & 14, JURGENS PARK EXT 2 & 3, KEMPTON PARK, South Africa','geo':{'lat':-26.14570,'lng':28.22310}},
{'stp':727088,'address':'727088, EGL - ISANDO, ISANDO HIGH END TEAM, PIATTO KEMPTON PARK (NTO), SHOP 31, GLEN BALAD SHOPPING CENTRE, GLEN MARIAS, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.08602,'lng':28.25260}},
{'stp':728873,'address':'728873, EGL - ISANDO, ISANDO HIGH END TEAM, STABLES, 25 NANYUKI ROAD, BEING STAND NO 25, #, SUNNINGHILL, South Africa','geo':{'lat':-26.02100,'lng':28.07120}},
{'stp':734911,'address':'734911, EGL - ISANDO, ISANDO HIGH END TEAM, 58 LIQUOR DISTRIBUTORS, 58 ELANDS STREET, #, #, ISANDO, South Africa','geo':{'lat':-26.15850,'lng':28.20510}},
{'stp':740489,'address':'740489, EGL - ISANDO, ISANDO HIGH END TEAM, HARVARD CAFE (NTO), SHOP 4,5,6 BONAERO PARK SHOPPING CE, CNR ATLAS RD & GELDENHUYS STR, BONAERO PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.12040,'lng':28.25765}},
{'stp':740819,'address':'740819, EGL - ISANDO, ISANDO HIGH END TEAM, MUGG & BEAN OR TAMBO INT (NTO), O.R TAMBO INTER DEPARTURES, INTERNATIONAL DEPARTURES, Johannesburg, KEMPTON PARK, South Africa','geo':{'lat':-26.13040,'lng':28.23120}},
{'stp':740853,'address':'740853, EGL - ISANDO, ISANDO HIGH END TEAM, JACKSONS REPUBLIC (NTO), SHOP NO 34 AIRSIDE LEVEL 1, TERMINAL 6 JHB INTERNATIONAL AIRPORT, #, KEMPTON PARK, South Africa','geo':{'lat':-26.13498,'lng':28.23152}},
{'stp':763306,'address':'763306, EGL - ISANDO, ISANDO HIGH END TEAM, RHAPSODY BYTE (NTO), OR TAMBO INTERNATIONAL, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.13040,'lng':28.23120}},
{'stp':771562,'address':'771562, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR LOONEYS, 210 ERF NO, 11 BUSHBUCK CLOSE CORPORATE PARK SOUTH, PORTIONS 16 & 28, MIDRAND, South Africa','geo':{'lat':-26.01132,'lng':28.12886}},
{'stp':772651,'address':'772651, EGL - ISANDO, ISANDO HIGH END TEAM, POOL JUNCTION, ERF 1690, SHOP NO 5 WATERFALL PARK, CORNER MODDERFONTEIN R25 AND DIE WIEKUS, KEMPTON PARK, South Africa','geo':{'lat':-26.07149,'lng':28.20800}},
{'stp':788566,'address':'788566, EGL - ISANDO, ISANDO HIGH END TEAM, PIAZZA PIZZERIA PUB, STAND NO, SHOP NO 8 ALEX SHOPPING CENTRE, CNR NAMAQUADUIF AND GEELVINK STR, KEMPTON PARK, South Africa','geo':{'lat':-26.06434,'lng':28.21898}},
{'stp':790371,'address':'790371, EGL - ISANDO, ISANDO HIGH END TEAM, BELUGA (NTO), ERF 1794,SHOP NO:5 WATERFALL CORNER, SHOPPING CENTRE, CNR WOODMEAD & MAXWELL, DRIVES, JUKSKEI VIEW EXT 51,WATERFALL, MIDRAND, South Africa','geo':{'lat':-25.99918,'lng':28.12629}},
{'stp':799678,'address':'799678, EGL - ISANDO, ISANDO HIGH END TEAM, Cappello Benoni (NTO), Health World Complex, 2 Malherbe str Cnr Oreilly str, Rynfield, BENONI, South Africa','geo':{'lat':-26.16539,'lng':28.32638}},
{'stp':821002,'address':'821002, EGL - ISANDO, ISANDO HIGH END TEAM, Isando Stakeholder, Isando Depot, #, #, ISANDO, South Africa','geo':{'lat':-26.13770,'lng':28.20320}},
{'stp':313638,'address':'313638, EGL - ISANDO, ISANDO HIGH END TEAM, HONGBO ENTERTAINMENT, 1 SHOP NO, LOWER LEVEL, CORNER MARKET AND SOUTH STREETS, MIDRAND, South Africa','geo':{'lat':-25.99814,'lng':28.12844}},
{'stp':319298,'address':'319298, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY LONGMEADOW DISTRI CNTR M, 137 ERF NOA, NGUNI DRIVE, LONGMEADOW BUSINESS ESTATE, EDENVALE, South Africa','geo':{'lat':-26.10630,'lng':28.13300}},
{'stp':319369,'address':'319369, EGL - ISANDO, ISANDO HIGH END TEAM, T A S T E, F1A SHOP NO, FIRST FLOOR MIDRAND ML ALEXANDRA AVENUE, LEXANDRA AVENUE BOUNDED BY SIXTEENTH RD, MIDRAND, South Africa','geo':{'lat':-25.99431,'lng':28.12852}},
{'stp':320670,'address':'320670, EGL - ISANDO, ISANDO HIGH END TEAM, JOHANNESBURG DOTCOZA CENTRE OR71, STAND NUMBER, GROWTHPOINT INDUSTRIAL ESTATE, BELL STREET, ISANDO, South Africa','geo':{'lat':-26.14981,'lng':28.18636}},
{'stp':388628,'address':'388628, EGL - ISANDO, ISANDO HIGH END TEAM, DISCOUNT LIQUOR STORE HALFWAY HOUSE, 20 STAND NO, NO 549 MARKET STREET, HALFWAY HOUSE, MIDRAND, South Africa','geo':{'lat':-25.99867,'lng':28.12883}},
{'stp':388792,'address':'388792, EGL - ISANDO, ISANDO HIGH END TEAM, EMGAZINI LODGE, 470 STAND NO, ACACIA ROAD MIDRAND, #, MIDRAND, South Africa','geo':{'lat':-25.96483,'lng':28.08838}},
{'stp':389099,'address':'389099, EGL - ISANDO, ISANDO HIGH END TEAM, PLANTATION CAFE MIDRAND, IBG PARK STREET, #, #, MIDRAND, South Africa','geo':{'lat':-25.97829,'lng':28.12097}},
{'stp':389110,'address':'389110, EGL - ISANDO, ISANDO HIGH END TEAM, CUBA LOUNGE, SHOP 45, SANRIDGE SQUARE, SHOPPING CENTRE, MIDRAND, South Africa','geo':{'lat':-25.97631,'lng':28.11748}},
{'stp':389829,'address':'389829, EGL - ISANDO, ISANDO HIGH END TEAM, LIFE GRAND CAFE CENTRAL KITCHEN WHO, 852 ERF NO, RICHARDS DRIVE, CRISCON PARK HALFWAY HOUSE, MIDRAND, South Africa','geo':{'lat':-26.01015,'lng':28.12477}},
{'stp':390302,'address':'390302, EGL - ISANDO, ISANDO HIGH END TEAM, SHAGUMA GARDEN CAFE, 86 PLOT NO, CORNER WHISKEN AND NEPTUNE ROAD, CROWTHORN, MIDRAND, South Africa','geo':{'lat':-25.98094,'lng':28.08532}},
{'stp':390442,'address':'390442, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT SPAR VAN RIEBEECK PARK, 45 BENNIE JACOBS SENTRUM, BLACK THRONE ROAD VAN RIEBEECK PARK, EXT 12 BEING ERF 1547 VAN RIEBEECK PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.08097,'lng':28.21041}},
{'stp':390503,'address':'390503, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY MIDRAND, 31 STAND NO, HALWAY SHOPPING CENTRE, #, MIDRAND, South Africa','geo':{'lat':-25.99696,'lng':28.12735}},
{'stp':390779,'address':'390779, EGL - ISANDO, ISANDO HIGH END TEAM, CUBANA LATINO CAFE (NTO), SHOP NO: 30, 31 & 32, CARLSWALD LIF, CNR NEW RD & HARRY GALAUN DR,CARLSWALD, HALFWAY GARDENS EXT:92, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.97891,'lng':28.11640}},
{'stp':393008,'address':'393008, EGL - ISANDO, ISANDO HIGH END TEAM, AJ LIQUOR STORE, 2544 ERF, NO 105 COMMISONER STR, #, KEMPTON PARK, South Africa','geo':{'lat':-26.09942,'lng':28.23944}},
{'stp':393222,'address':'393222, EGL - ISANDO, ISANDO HIGH END TEAM, RECHARGE LOUNGE, 561 ERF 346 NO, JAMES CRESCENT OLD PRETORIA ROAD, SHOP, NO 1 AND 2 , THE POND SHOPPING CENTRE,, MIDRAND, South Africa','geo':{'lat':-26.00657,'lng':28.12350}},
{'stp':460763,'address':'460763, EGL - ISANDO, ISANDO HIGH END TEAM, The Purple Lounge (NTO), 124 Richards Drive, Unit 13, The Home Gallery, Midrand, MIDRAND, South Africa','geo':{'lat':-26.01630,'lng':28.12022}},
{'stp':460765,'address':'460765, EGL - ISANDO, ISANDO HIGH END TEAM, The Boulders Lounge (NTO), 128 Tonnetti street, Halfway House, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.99704,'lng':28.12490}},
{'stp':463208,'address':'463208, EGL - ISANDO, ISANDO HIGH END TEAM, BEFORE DE RAIN, 490 STAND NO, NUMBER 45 COMMISSIONER STREET CORNER, #, KEMPTON PARK, South Africa','geo':{'lat':-26.09708,'lng':28.23069}},
{'stp':463292,'address':'463292, EGL - ISANDO, ISANDO HIGH END TEAM, AQUA LOUNGE (NTO), CNR 2ND STREET & SMUTS DRIVE, MIDRAND, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.99913,'lng':28.11168}},
{'stp':465735,'address':'465735, EGL - ISANDO, ISANDO HIGH END TEAM, DOPPIO ZERO MIDRAND (NTO), Shop 69 Blue Hills Centre, Cnr Olifantsfontein road & African view, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.95550,'lng':28.14373}},
{'stp':465771,'address':'465771, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY BLUE HILLS -GC82, 956,957 STAND NO, BLUE HILLS SHOPPING CENTRE, AFRICAN VIEW, DRIVE, BLUE HILLS X 71, MIDRAND, South Africa','geo':{'lat':-25.94523,'lng':28.10735}},
{'stp':472006,'address':'472006, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT MEGA, 31 OLD PRETORIA ROAD, CNR NORTH STREET, BORDERING MARKET AND CHURCH STREET, MIDRAND, South Africa','geo':{'lat':-25.99707,'lng':28.12947}},
{'stp':474342,'address':'474342, EGL - ISANDO, ISANDO HIGH END TEAM, BLUE BOTTLE LIQUOR STORE, 327 STAND NO, ON THE FARM ZUURFONTEIN, 33 IR, 12, PRETORIA RD, KEMPTON PARK, South Africa','geo':{'lat':-26.10309,'lng':28.22621}},
{'stp':478911,'address':'478911, EGL - ISANDO, ISANDO HIGH END TEAM, CASA BELLA MALL OF AFRICA (NTO),   1169 MALL OF AFRICA, WATERFALL CI, #, #, MIDRAND, South Africa','geo':{'lat':-26.01546,'lng':28.10683}},
{'stp':478915,'address':'478915, EGL - ISANDO, ISANDO HIGH END TEAM, ROCOMAMAS MALL OF AFRICA (NTO), 69 MALL OF AFRICA, WATERFALL CITY, #, #, MIDRAND, South Africa','geo':{'lat':-26.01546,'lng':28.10683}},
{'stp':480175,'address':'480175, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS LIQUORSHOP MALL OF AFRICA, 1 KARKLOOF CRESCENT CORNER, MAGWA CRESCENT AND JUKSKEI VIEW, ERF 3621 AND 3622, MIDRAND, South Africa','geo':{'lat':-26.01333,'lng':28.10607}},
{'stp':480802,'address':'480802, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT MIDRAND MALL, SHOP 4 MIDRAND MALL, SHOPPING CENTRE, CORNER ALEXANDRA AVENUE AND, MIDRAND, South Africa','geo':{'lat':-25.99379,'lng':28.12728}},
{'stp':480980,'address':'480980, EGL - ISANDO, ISANDO HIGH END TEAM, HINTERLAND - MALL OF AFRICA (NTO), SHOP 2057 MALL OF AFRICA, ALLANDALE ROAD, JUKSKEI PARK VIEW EXT 6, MIDRAND, MIDRAND, South Africa','geo':{'lat':-26.01546,'lng':28.10683}},
{'stp':480981,'address':'480981, EGL - ISANDO, ISANDO HIGH END TEAM, KREAM - MALL OF AFRICA (NTO), SHOP 2055, MALL OF AFRICA, MAGWA CRES, WATERFALL CITY, MIDRAND, South Africa','geo':{'lat':-26.01546,'lng':28.10683}},
{'stp':480982,'address':'480982, EGL - ISANDO, ISANDO HIGH END TEAM, LIFE GRAND CAFE - MALL OF AFRICA (N, SHOP 2061, MALL OF AFRICA, MAGWA CRES, WATERFALL CITY, MIDRAND, South Africa','geo':{'lat':-26.01502,'lng':28.10551}},
{'stp':480983,'address':'480983, EGL - ISANDO, ISANDO HIGH END TEAM, MYTHOS - MALL OF AFRICA (NTO), SHOP 2051, MALL OF AFRICA, MAGWA CRES, WATERFALL CITY, MIDRAND, South Africa','geo':{'lat':-26.01546,'lng':28.10683}},
{'stp':480984,'address':'480984, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET - MALL OF AFRICA (NTO), SHOP 2049, MALL OF AFRICA, MAGWA CRES, WATERFALL CITY, MIDRAND, South Africa','geo':{'lat':-26.01546,'lng':28.10683}},
{'stp':480985,'address':'480985, EGL - ISANDO, ISANDO HIGH END TEAM, WASABI - MALL OF AFRICA (NTO), SHOP 2053, MALL OF AFRICA, MAGWA CRES, WATERFALL CITY, MIDRAND, South Africa','geo':{'lat':-26.01546,'lng':28.10683}},
{'stp':480986,'address':'480986, EGL - ISANDO, ISANDO HIGH END TEAM, ZURI - MALL OF AFRICA (NTO), SHOP UK1, MALL OF AFRICA, MAGWA CRES, WATERFALL CITY, MIDRAND, South Africa','geo':{'lat':-26.01546,'lng':28.10683}},
{'stp':482369,'address':'482369, EGL - ISANDO, ISANDO HIGH END TEAM, COLCACCHIO BLUEHILLS (NTO), BLUEHILLS SHOPPING CENTRE, CNR OLIFANTSFONTEIN & AFRICAN VIEW, #, MIDRAND, South Africa','geo':{'lat':-25.94464,'lng':28.10649}},
{'stp':482412,'address':'482412, EGL - ISANDO, ISANDO HIGH END TEAM, CESCOS MIDRAND (NTO), INDIGO GARDEN CENTRE, CNR 7TH & NEW ROAD, #, MIDRAND, South Africa','geo':{'lat':-25.97553,'lng':28.10582}},
{'stp':482413,'address':'482413, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET - BLUEHILLS (NTO), BLUEHILLS SHOPPING CENTRE, CNR OLIFANTSFONTEIN & AFRICAN VIEW, #, MIDRAND, South Africa','geo':{'lat':-25.94464,'lng':28.10649}},
{'stp':482414,'address':'482414, EGL - ISANDO, ISANDO HIGH END TEAM, BUTCHERS BLOCK BLUEHILLS (NTO), BLUEHILLS SHOPPING CENTRE, CNR OLIFANTSFONTEIN & AFRICAN VIEW, #, MIDRAND, South Africa','geo':{'lat':-25.94464,'lng':28.10649}},
{'stp':486218,'address':'486218, EGL - ISANDO, ISANDO HIGH END TEAM, TOP SHELF LIQUORS PTY LTD, 6 ERF NO, 190 SOUTH STREET, HALFWAY HOUSE, MIDRAND, South Africa','geo':{'lat':-25.99810,'lng':28.12791}},
{'stp':487295,'address':'487295, EGL - ISANDO, ISANDO HIGH END TEAM, FREDDYS LIQUOR SHOP, 0197 STAND NO, MARKET GRAND CENTRAL EXT 7, HALFWAY HOUSE, MIDRAND, South Africa','geo':{'lat':-25.99631,'lng':28.12960}},
{'stp':490182,'address':'490182, EGL - ISANDO, ISANDO HIGH END TEAM, THE ORCHARDS EXECUTIVE ACCOMMODATIO, STAND NO, PORTION 2 OF HOLDING 164, 46 ALLAN ROAD, MIDRAND, South Africa','geo':{'lat':-25.98035,'lng':28.15452}},
{'stp':491106,'address':'491106, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS LIQUORSHOP NORKEM PARK 893, STAND NO, PORTION 1 OF ERF 2239, SHOP NO 28-30, NORKEM MALL SHOPPING CENT, KEMPTON PARK, South Africa','geo':{'lat':-26.06281,'lng':28.21974}},
{'stp':493117,'address':'493117, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY WATERFALL, 1838 STAND NO, PORTION OF GROUND FLOOR, WATERFALL CENTRE CRNER BEKKER, MIDRAND, South Africa','geo':{'lat':-26.00597,'lng':28.11748}},
{'stp':540962,'address':'540962, EGL - ISANDO, ISANDO HIGH END TEAM, PREMIER HOTEL MIDRAND (NTO), CNR 5TH ROAD, 5TH STREET, HALFWAY GARDENS EXT 20, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.98561,'lng':28.12219}},
{'stp':540972,'address':'540972, EGL - ISANDO, ISANDO HIGH END TEAM, CITY LODGE WATERFALL CITY (NTO), 3 AUGRABIES LANE, WATERFALL CITY, JUKSKEI VIEW, MIDRAND, South Africa','geo':{'lat':-26.01386,'lng':28.11196}},
{'stp':540976,'address':'540976, EGL - ISANDO, ISANDO HIGH END TEAM, THAVA INDIAN RESTAURANT (NTO), CARLSWALD DECO CENTRE, CNR 5TH & HARRY GALUAN ROAD, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.98235,'lng':28.11783}},
{'stp':550412,'address':'550412, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY FESTIVAL MALL, SHOP NO 100, FESTIVAL MALL, SHOP CENTRE, CR SWART, KELVIN,, H LEWIS STREETS ESTERPARK, KEMPTON PARK, South Africa','geo':{'lat':-26.10730,'lng':28.22480}},
{'stp':550421,'address':'550421, EGL - ISANDO, ISANDO HIGH END TEAM, GALLAGHER CONVENTION CENTRE, ERF 541, HALFWAYHOUSE, EXT 78, MIDRAND, South Africa','geo':{'lat':-26.00210,'lng':28.13020}},
{'stp':550531,'address':'550531, EGL - ISANDO, ISANDO HIGH END TEAM, VIVA ESPANA REST, CONSTANTIA PARK BUILDING1, OFF OLD PRETORIA RD, HALFWAY HOUSE, MIDRAND, South Africa','geo':{'lat':-25.97610,'lng':28.13430}},
{'stp':550660,'address':'550660, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT EDLEEN, STAND NO 2518, CNR GREEN AND HAAKDORING AVENUES, EDLEEN SHOP NO 3 AND 6, KEMPTON PARK, South Africa','geo':{'lat':-26.08880,'lng':28.21610}},
{'stp':550735,'address':'550735, EGL - ISANDO, ISANDO HIGH END TEAM, PLAZA LIQUORS, SHOP 1A CENTURY PLAZA, 95 PARKLAND STREET, ESTER PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.09900,'lng':28.19660}},
{'stp':550882,'address':'550882, EGL - ISANDO, ISANDO HIGH END TEAM, MIZO S TAVERN, SHOP 1 31 OLD PRETORIA RD, HALFWAYHOUSE, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.99650,'lng':28.12880}},
{'stp':551236,'address':'551236, EGL - ISANDO, ISANDO HIGH END TEAM, JUMBO LIQUORS CELLARS, CNR MODDERHILL & GREEN AVE, EDLEEN, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.09741,'lng':28.20694}},
{'stp':551467,'address':'551467, EGL - ISANDO, ISANDO HIGH END TEAM, TOWN LODGE MIDRAND LTD, LE ROUX AVE MONTROSE RD &, BEN SCHOEMAN HIGHWAY, WATERFALL PARK VORNA VALLEY, MIDRAND, South Africa','geo':{'lat':-26.00830,'lng':28.11780}},
{'stp':551962,'address':'551962, EGL - ISANDO, ISANDO HIGH END TEAM, THE VALLEY TAVERN, 13 LE ROUX AVE, HALFWAY HOUSE, 1685, MIDRAND, South Africa','geo':{'lat':-26.00532,'lng':28.11649}},
{'stp':551974,'address':'551974, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR MART, 100 HARPUR AVENUE, CRN BUNYAN STREET, BENONI, BENONI, South Africa','geo':{'lat':-26.19410,'lng':28.31670}},
{'stp':552287,'address':'552287, EGL - ISANDO, ISANDO HIGH END TEAM, MIDRAND PROTEA HOTEL, 14TH STREET, NOORDWYK X 20, HALFWAY HOUSE, MIDRAND, South Africa','geo':{'lat':-25.95630,'lng':28.13200}},
{'stp':552342,'address':'552342, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY BIRCHLEIGH, SHOP 2 PICK \'N PAY CENTRE, CRN MOOIFONTEIN & STRYDOM ST, BIRCHLEIGH NORTH EXT 2, KEMPTON PARK, South Africa','geo':{'lat':-26.05069,'lng':28.23152}},
{'stp':552355,'address':'552355, EGL - ISANDO, ISANDO HIGH END TEAM, MIU LIQUOR TRADING, 23 STAND NO, MAUTAME AVENUE, #, KEMPTON PARK, South Africa','geo':{'lat':-26.06280,'lng':28.23569}},
{'stp':552597,'address':'552597, EGL - ISANDO, ISANDO HIGH END TEAM, KEMPTON PARK LIQUOR LAND, MONUMENTWEG 67, KEMPTON PARK, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.09180,'lng':28.23630}},
{'stp':552694,'address':'552694, EGL - ISANDO, ISANDO HIGH END TEAM, NOORDWYK COUNTRY CLUB, ERF 574, NOORDWYK EXT 15, MIDRAND, RANDBURG, MIDRAND, South Africa','geo':{'lat':-25.95660,'lng':28.11720}},
{'stp':552714,'address':'552714, EGL - ISANDO, ISANDO HIGH END TEAM, ACTION SPORTS ASTRO CRICKET, MOTOR CITY COMPLEX, OLD PRETORIA RD, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.99050,'lng':28.12980}},
{'stp':552720,'address':'552720, EGL - ISANDO, ISANDO HIGH END TEAM, BAR 9, STAND NO, ERF 346 SHOP 2 THE POND S/C 561, JAMES CRECENT OLD PRETORIA RD HALFWAY, MIDRAND, South Africa','geo':{'lat':-26.00639,'lng':28.12337}},
{'stp':553026,'address':'553026, EGL - ISANDO, ISANDO HIGH END TEAM, TOP SPOT LIQUOR STORE, SHOP 48 CHINA GATE SHOP CENTRE, CNR ORANJERIVIER AND ZUURFONTEIN, KEMPTON PARK WEST, KEMPTON PARK, South Africa','geo':{'lat':-26.08150,'lng':28.18850}},
{'stp':553097,'address':'553097, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT SPAR NORKEM PARK, ERF NO 195,NORKEM PLAZASHOPP, ING CENTRE,SHOP 1&2 NO 56, JAMES WRIGHT STR,NORKEM PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.05470,'lng':28.22410}},
{'stp':553158,'address':'553158, EGL - ISANDO, ISANDO HIGH END TEAM, MAKRO MEADOWDALE, ERVEN 14-18, NORTH REEF ROAD, MEADOWDALE, ISANDO, South Africa','geo':{'lat':-26.15320,'lng':28.17090}},
{'stp':553592,'address':'553592, EGL - ISANDO, ISANDO HIGH END TEAM, NOORDWYK LIQUOR CELLARS, S/NO S 4&5 NOORDWYK S/CENTRE, LEVER ROAD BEING ERF 1225, NOORDWYK EXT 19, MIDRAND, South Africa','geo':{'lat':-25.95200,'lng':28.12370}},
{'stp':553633,'address':'553633, EGL - ISANDO, ISANDO HIGH END TEAM, MIDWAY MEWS LIQUOR STORE, STAND NO, SHOP G12 MIDWAY MEWS SHOPPING CENTRE, CNR HARRY GALAUN AND 7TH AVENUE, MIDRAND, South Africa','geo':{'lat':-25.98680,'lng':28.11316}},
{'stp':560230,'address':'560230, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY KEMPTON GATE, CNR C R SWART DRIVE AND RIENERT, EDLEEN EXT 1, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.09400,'lng':28.19660}},
{'stp':603377,'address':'603377, EGL - ISANDO, ISANDO HIGH END TEAM, BLUE HILLS LIQUOR STORE, PLOT 4 CRN SUMMIT RD & MAIN, BLUE HILLS, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.94353,'lng':28.10567}},
{'stp':605203,'address':'605203, EGL - ISANDO, ISANDO HIGH END TEAM, ACRES LIQUORS, SHOP NO1 BIRCH ACRES S CENTRE, MARABOE ROAD AND SUIKERBEKKIE STREET, ERF 316 BIRCH ACRES, KEMPTON PARK, South Africa','geo':{'lat':-26.06073,'lng':28.21136}},
{'stp':606199,'address':'606199, EGL - ISANDO, ISANDO HIGH END TEAM, PRIMI PIATTI MIDRAND, SHOP 45 SANDRIDGE MALL CRN, NEW RD & LEVER RD HALFWAY GARDENS, MIDRIDGE EXT 17, MIDRAND, South Africa','geo':{'lat':-25.97660,'lng':28.11800}},
{'stp':606693,'address':'606693, EGL - ISANDO, ISANDO HIGH END TEAM, NEWS CAFE MIDRIDGE SQUARE, SHOP 3 MIDRIDGE CENTRE, CNR NEW & LEVER ROADS, #, MIDRAND, South Africa','geo':{'lat':-25.97785,'lng':28.11763}},
{'stp':607510,'address':'607510, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET SAN RIDGE, ERF 20 SHOP 42, SUNRIDGE SQUARE LEVEL RD MIDRIDGE X17, #, MIDRAND, South Africa','geo':{'lat':-25.97605,'lng':28.11815}},
{'stp':625078,'address':'625078, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT CARLSWALD, 20 STAND NO, PORTION NO 219 HOLDINGS NO 89-90, EAST RAND AGRICULTURAL HOLDINGS, MIDRAND, South Africa','geo':{'lat':-25.97940,'lng':28.11550}},
{'stp':626784,'address':'626784, EGL - ISANDO, ISANDO HIGH END TEAM, DROS MIDRAND CARLSWALD, SHOP NO 28 CARLSWALD LIFESTYLE, SHOP CENTRE NEW RD AND HARRY GAULANST, GARDENS X 92 HALFWAY GARDENS, MIDRAND, South Africa','geo':{'lat':-25.97940,'lng':28.11750}},
{'stp':633639,'address':'633639, EGL - ISANDO, ISANDO HIGH END TEAM, O HAGANS MIDRAND, NO 6 VALUEFAIRE CENTRE, BONDED BY OLD POTCHEFSTROOM RD, HALFWAY HOUSE, MIDRAND, South Africa','geo':{'lat':-26.00255,'lng':28.12596}},
{'stp':635536,'address':'635536, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT BIRCHGATE, BIRCHGATE SHOPPING CENTRE, UNIT NO 2 CNR P91 AND KWARTEL STREET, BIRCHACRES, KEMPTON PARK, South Africa','geo':{'lat':-26.06920,'lng':28.20800}},
{'stp':635775,'address':'635775, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY VAN RIEBEECK PARK, SHOP 5 VAN RIEBEECK PARK CENTRE, 100 SOUTPANSBERG RD, STAND 2382 VAN RIEBEECK PARK TOWNSHIP, KEMPTON PARK, South Africa','geo':{'lat':-26.08170,'lng':28.21990}},
{'stp':639983,'address':'639983, EGL - ISANDO, ISANDO HIGH END TEAM, MAKRO DISTRIBUTION CENTRE, 5 BRINE AVENUE, CHLOORKOP, #, TEMBISA, South Africa','geo':{'lat':-26.05940,'lng':28.16860}},
{'stp':642504,'address':'642504, EGL - ISANDO, ISANDO HIGH END TEAM, PAPACHINOS PLANTATION (NTO), PLANTATION CENTRE,NEW RD, #, #, MIDRAND, South Africa','geo':{'lat':-25.98046,'lng':28.08633}},
{'stp':642628,'address':'642628, EGL - ISANDO, ISANDO HIGH END TEAM, CORNER LIQUOR STORE, STAND NO, WESTVOOR CENTRE, CNR VOORTREKKER AND WEST STREET, KEMPTON PARK, South Africa','geo':{'lat':-26.10482,'lng':28.22855}},
{'stp':648343,'address':'648343, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY ELGIN MALL, CNR ELGIN STR & OLIENHOT AVE, BIRCHLEIGH EXT 9, #, KEMPTON PARK, South Africa','geo':{'lat':-26.06732,'lng':28.23127}},
{'stp':649521,'address':'649521, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS VORNA VALLEY, 22 & 23 SHOP NO, VORNA VALLEY SHOPPING CENTRE, CNR HARRY GALAUN RD & ALBERTYN STR, MIDRAND, South Africa','geo':{'lat':-25.99959,'lng':28.10271}},
{'stp':665224,'address':'665224, EGL - ISANDO, ISANDO HIGH END TEAM, MUGG AND BEAN CARLSWORLD (NTO), SHOP NO:18 CARLSWALD SHOPPING CENTR, CNR MAIN & HARRY GALAUN RD\'S,PORTION 291, OF THE FARM RANDJESFONTEIN, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.97896,'lng':28.11691}},
{'stp':665248,'address':'665248, EGL - ISANDO, ISANDO HIGH END TEAM, ADEGA MIDRAND (NTO), CARSLWORLD DECO CENTRE, CNR 5TH & HARRY GALAUN RD, CNR 5TH & HARRY GALAUN RD, MIDRAND, South Africa','geo':{'lat':-25.98235,'lng':28.11783}},
{'stp':665249,'address':'665249, EGL - ISANDO, ISANDO HIGH END TEAM, MIMMOS MIDRAND (NTO), SHOP NO 8, GROUND LEVEL, MIDWAY MEWS SHOPPING CENTRE, CNR HARRY GALAUN & SEVENTH ROAD, GARDENS, MIDRAND, South Africa','geo':{'lat':-25.98689,'lng':28.11339}},
{'stp':665250,'address':'665250, EGL - ISANDO, ISANDO HIGH END TEAM, NANDOS MIDRAND (NTO), MIDWAY MEWS, CNR HARRY GALAUME & 7TH AVE, CNR HARRY GALAUME & 7TH AVE, MIDRAND, South Africa','geo':{'lat':-25.98685,'lng':28.11268}},
{'stp':668292,'address':'668292, EGL - ISANDO, ISANDO HIGH END TEAM, THE COURIER RESTAURANT (NTO), 31 MOERDYK ROAD, VORNA VALLEY, KEMPTON PARK, MIDRAND, South Africa','geo':{'lat':-25.99595,'lng':28.09712}},
{'stp':682267,'address':'682267, EGL - ISANDO, ISANDO HIGH END TEAM, UNION FAST FOODS, CNR MARKET AND SOUTH STREETS, SHOP 1 LOWER LEVEL, #, MIDRAND, South Africa','geo':{'lat':-25.99820,'lng':28.12840}},
{'stp':684407,'address':'684407, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY MIDRAND(THE BOULDERS0- G, STAND NO, SHOP M8 THE BUILDERS SHOPPING CENTRE, OLD PRETORIA ROAD ERF 413 HALFWAY HOUSE, MIDRAND, South Africa','geo':{'lat':-25.99594,'lng':28.12834}},
{'stp':685513,'address':'685513, EGL - ISANDO, ISANDO HIGH END TEAM, SEEKING THE SUN TRADING, 6 SOUTH STREET, HALFWAY HOUSE, #, MIDRAND, South Africa','geo':{'lat':-25.99800,'lng':28.12810}},
{'stp':689287,'address':'689287, EGL - ISANDO, ISANDO HIGH END TEAM, PAVLOVA BAR (NTO), SHOP 3 MIDRIDGE CENTRE, CNR NEW & LEVER ROADS, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.97790,'lng':28.11760}},
{'stp':695936,'address':'695936, EGL - ISANDO, ISANDO HIGH END TEAM, MINNETONKA SPUR CARLSWORLD, SHOP A5 TO A10 CARLSWALD DECOR, CENTRE BOUNDED HARRY GALAUN DR 5th RD, AND 6th RD EXT 53 HALFWAY HOUSE ERF 1372, MIDRAND, South Africa','geo':{'lat':-25.98200,'lng':28.11780}},
{'stp':726184,'address':'726184, EGL - ISANDO, ISANDO HIGH END TEAM, RAM S BOTTLE STORE, ERF 1100 SHOP 9, ALEX SHOPPING CENTRE, #, KEMPTON PARK, South Africa','geo':{'lat':-26.04773,'lng':28.20643}},
{'stp':728428,'address':'728428, EGL - ISANDO, ISANDO HIGH END TEAM, HARVARD CAFE (NTO), GRAND CENTRAL AIRPORT, MIDRAND, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.98984,'lng':28.14058}},
{'stp':728581,'address':'728581, EGL - ISANDO, ISANDO HIGH END TEAM, TRUTH NIGHT CLUB (NTO), OLD PRETORIA MAIN ROAD, MIDRAND, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.97008,'lng':28.13530}},
{'stp':761891,'address':'761891, EGL - ISANDO, ISANDO HIGH END TEAM, PIATTO MIDRAND (NTO), THE CARLS WORLD CENTRE, NEW RD & 7TH AVE, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.97872,'lng':28.11741}},
{'stp':770017,'address':'770017, EGL - ISANDO, ISANDO HIGH END TEAM, MR LIQUOR, STAND NO, ERF 599 SHOP 2 LOUVERDIS CENTRE 10, #, KEMPTON PARK, South Africa','geo':{'lat':-26.09729,'lng':28.23139}},
{'stp':775564,'address':'775564, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY GF52 SAN RIDGE SQUARE, CNR NEW & LEVER ROADS, SHOP 14 SAN RIDGE SQUARE, MIDRIDGE PARK EXT 14, MIDRAND, South Africa','geo':{'lat':-25.97581,'lng':28.11708}},
{'stp':777687,'address':'777687, EGL - ISANDO, ISANDO HIGH END TEAM, GAME FESTIVAL MALL 268, STAND NO, SHOP G1 FESTIVAL MALL C/O KELVIN RD & CR, SWARTS RDS ESTHER PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.10167,'lng':28.22290}},
{'stp':790330,'address':'790330, EGL - ISANDO, ISANDO HIGH END TEAM, PIZA E VINO MIDRAND (NTO), CNR 5TH & HARRY GALUAN RDS, CARLSWALD, CARLSWALD, MIDRAND, South Africa','geo':{'lat':-25.98235,'lng':28.11783}},
{'stp':705262,'address':'705262, EGL - ISANDO, ISANDO HIGH END TEAM, FARHENHEIT BOKSBURG (NTO), MERCURY ROAD, CNR CUMBERLAND ROAD, #, BENONI, South Africa','geo':{'lat':-26.14958,'lng':28.29884}},
{'stp':313101,'address':'313101, EGL - ISANDO, ISANDO HIGH END TEAM, PANTHERS TAVERN, 49 ERF 197, STAND NO, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10491,'lng':28.23798}},
{'stp':320248,'address':'320248, EGL - ISANDO, ISANDO HIGH END TEAM, 747 RESTAURANT, STAND NUMBER, PORTION 1 OF ERF 246, 56A KEMPTON ROAD, KEMPTON PARK, South Africa','geo':{'lat':-26.10509,'lng':28.23742}},
{'stp':383536,'address':'383536, EGL - ISANDO, ISANDO HIGH END TEAM, RIVIERA PUB (NTO), 71 HENDRIK POTGIETER, EDENVALE, #, EDENVALE, South Africa','geo':{'lat':-26.13860,'lng':28.15820}},
{'stp':386930,'address':'386930, EGL - ISANDO, ISANDO HIGH END TEAM, THE SHEPHARD RESTAURANT AND BAR, STAND NO, PORTION 5 OF ERF NO 2739 SHOP NO  2,3, 26 PARK STREET, KEMPTON PARK, South Africa','geo':{'lat':-26.10763,'lng':28.23625}},
{'stp':388036,'address':'388036, EGL - ISANDO, ISANDO HIGH END TEAM, ZONKE BOTTLE STORE, 14 58 SHOP NO, KEMPTONPARK ROAD KEMPTON PARK EXT 1, ERF 0058, KEMPTON PARK, South Africa','geo':{'lat':-26.10538,'lng':28.23776}},
{'stp':388997,'address':'388997, EGL - ISANDO, ISANDO HIGH END TEAM, CLUB 58, 58 STAND NO, #, #, ISANDO, South Africa','geo':{'lat':-26.15861,'lng':28.20505}},
{'stp':389476,'address':'389476, EGL - ISANDO, ISANDO HIGH END TEAM, PAPACHINOS STONERIDGE (NTO), STONERIDGE CENTRE, STONERIDGE DRIVE, GREENSTONE PARK EXT: 2, ERF: 11, 13, 15, EDENVALE, South Africa','geo':{'lat':-26.11332,'lng':28.14391}},
{'stp':389703,'address':'389703, EGL - ISANDO, ISANDO HIGH END TEAM, OSTERIA ROSSA (NTO), SHOP NO:14A & 15A, BEDFORD VILLAGE, NO:51 CNR NICOL & VAN BUUREN ROADS,, BEDFORDVIEW, ERF:1146, BEDFORDVIEW, South Africa','geo':{'lat':-26.18060,'lng':28.13534}},
{'stp':389855,'address':'389855, EGL - ISANDO, ISANDO HIGH END TEAM, MOSIME BROS DISTRIBUTORS, 735 ERF NO, FARM 23 GRADER ROAD SPARTAN, SEBENZA GAUTENG, KEMPTON PARK, South Africa','geo':{'lat':-26.11618,'lng':28.18307}},
{'stp':390725,'address':'390725, EGL - ISANDO, ISANDO HIGH END TEAM, HAUTE RESTO BAR, 1658 STAND NO, RIETFONTEIN, PRIM ROSE, PRIMROSE, South Africa','geo':{'lat':-26.18727,'lng':28.16649}},
{'stp':390769,'address':'390769, EGL - ISANDO, ISANDO HIGH END TEAM, PUB 95 SPORTS BAR, 07 SHOP NO, EREENE S/C 2ND STR STAND NO 23-26, #, KEMPTON PARK, South Africa','geo':{'lat':-26.18130,'lng':28.19315}},
{'stp':391513,'address':'391513, EGL - ISANDO, ISANDO HIGH END TEAM, BOM DIA CAFE (NTO), ERF:11/7, CNR MILKWAY AND COSMOS RO, LINBRO BUSINESS PARK,FRANKENWALLAD EXT 3, FRANKENWALLAD, MIDRAND, South Africa','geo':{'lat':-26.07051,'lng':28.11587}},
{'stp':391561,'address':'391561, EGL - ISANDO, ISANDO HIGH END TEAM, EROPLUS BOTTLES SHOP, 26 55 STAND NO, 55 WEST STREET, ERF 2790, KEMPTON PARK, South Africa','geo':{'lat':-26.11024,'lng':28.23062}},
{'stp':391841,'address':'391841, EGL - ISANDO, ISANDO HIGH END TEAM, CACOONS COVE, 152 18-17TH AVENUE, #, #, EDENVALE, South Africa','geo':{'lat':-26.14478,'lng':28.16198}},
{'stp':392187,'address':'392187, EGL - ISANDO, ISANDO HIGH END TEAM, THE GRATITUDE RESTAURANT (NTO), SHOP NO UG02, FLAMINGO SHOPPING CENTRE, QUEEN STREET, BEING PORTION 2 OF ERF 183, EDENVALE, South Africa','geo':{'lat':-26.10026,'lng':28.15179}},
{'stp':392192,'address':'392192, EGL - ISANDO, ISANDO HIGH END TEAM, BUONISSIMO PIZZA PASTA, 2 STAND NO, LAKESIDE SHOPPING CENTRE, QUEENS ROAD, EDENVALE, South Africa','geo':{'lat':-26.10016,'lng':28.15207}},
{'stp':392202,'address':'392202, EGL - ISANDO, ISANDO HIGH END TEAM, ERAWAN THAI RESTUARANT TA THE FIRER, ERF 179 BEDFORDVIEW EXTENSION 45, SHOP NO 32 VILLAGE VIEW SHOPPING CENTRE, CNR KLOOF & VAN BUUREN, FARM ELANDSFONTE, BEDFORDVIEW, South Africa','geo':{'lat':-26.18270,'lng':28.13540}},
{'stp':392348,'address':'392348, EGL - ISANDO, ISANDO HIGH END TEAM, MAMA JAPAN, 22 AND 23 SHOP,NO.37 WEST STREET AR, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10845,'lng':28.22960}},
{'stp':392993,'address':'392993, EGL - ISANDO, ISANDO HIGH END TEAM, BOLSHOI CAFE - GREENSTONE CRESENT (, ERF 1354, SHOP NO.13 (A) CORNER, STONERIDGE DRIVE AND GREENSTONE PLACE, GREENSTONE CRESENT, GREENSTONE HILL EXTE, ISANDO, South Africa','geo':{'lat':-26.12198,'lng':28.14277}},
{'stp':392995,'address':'392995, EGL - ISANDO, ISANDO HIGH END TEAM, NIKOS COALGRILL GREEK MODDERFONTEIN, ERF 182, PORTION 2 OF ERF 183 AND P, QUEENS AND NEW VALLEY ROADS, BOUNDED BY, FLAMINGO MARKET SHOPPING CENTRE, ISANDO, South Africa','geo':{'lat':-26.10006,'lng':28.15190}},
{'stp':460766,'address':'460766, EGL - ISANDO, ISANDO HIGH END TEAM, Grilled Cafe (NTO), 1st Avenue, 60 Galway avenue, Linbro Business Park, Sandton, EDENVALE, South Africa','geo':{'lat':-26.07886,'lng':28.11813}},
{'stp':466097,'address':'466097, EGL - ISANDO, ISANDO HIGH END TEAM, ROCOMAMAS BEDFORDVIEW (NTO), Bedford Village Shopping Centre, Van Buuren Road, Bedfordview, BEDFORDVIEW, South Africa','geo':{'lat':-26.18065,'lng':28.13586}},
{'stp':478119,'address':'478119, EGL - ISANDO, ISANDO HIGH END TEAM, BLACK BALL FEVER POOL PUB, 29 STAND NO, PRETORIA ROAD, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.10587,'lng':28.22768}},
{'stp':478912,'address':'478912, EGL - ISANDO, ISANDO HIGH END TEAM, HALF GLASS FISH & GRILL (NTO), 19 NORTH REEF ROAD, #, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.16830,'lng':28.16444}},
{'stp':478914,'address':'478914, EGL - ISANDO, ISANDO HIGH END TEAM, HARLEY\'S GRILL BAR TA BAILEYS (NTO), 61 VAN BUUREN ROAD, BEDFORDVIEW, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.17844,'lng':28.13694}},
{'stp':480651,'address':'480651, EGL - ISANDO, ISANDO HIGH END TEAM, PIZZA HUT EDENMEADOWS (NTO), SHOP 5 EDENMEADOWS SHOPPING CENTRE, CNR MODDERFONTEIN RD & VAN RIEBEECK AVE, #, EDENVALE, South Africa','geo':{'lat':-26.11958,'lng':28.13792}},
{'stp':481126,'address':'481126, EGL - ISANDO, ISANDO HIGH END TEAM, YAMITSUKI BEDFORDVIEW (NTO), 55 VAN BUUREN ROAD, #, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.17899,'lng':28.13639}},
{'stp':481127,'address':'481127, EGL - ISANDO, ISANDO HIGH END TEAM, VASCO\'S (NTO), BEDFORD VILLAGE, VAN BUUREN ROAD, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.18070,'lng':28.13580}},
{'stp':482410,'address':'482410, EGL - ISANDO, ISANDO HIGH END TEAM, THE PRIVE (NTO), CNR OLD PRETORIA RD & K101, #, #, MIDRAND, South Africa','geo':{'lat':-25.98824,'lng':28.13192}},
{'stp':490358,'address':'490358, EGL - ISANDO, ISANDO HIGH END TEAM, SHAGUMA GARDEN CAFE (NTO), D6 CORNER OF WHISKEY AND NEPTUNE RO, CROWTHORNE, MIDRAND, #, MIDRAND, South Africa','geo':{'lat':-25.98099,'lng':28.08530}},
{'stp':540971,'address':'540971, EGL - ISANDO, ISANDO HIGH END TEAM, MODDERFONTEIN BOWLING CLUB (NTO), CASINO ROAD, MODDERFONTEIN, MODDERFONTEIN, EDENVALE, South Africa','geo':{'lat':-26.09804,'lng':28.16646}},
{'stp':540977,'address':'540977, EGL - ISANDO, ISANDO HIGH END TEAM, OFFSIDE PUB (NTO), CNR 17TH AVENUE & 5TH STREET, EDENVALE, #, EDENVALE, South Africa','geo':{'lat':-26.14036,'lng':28.15986}},
{'stp':540980,'address':'540980, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET STONERIDGE (NTO), STONERIDGE SHOPPING CENTRE, CNR MODDERFONTEIN & HEREFORD RD, MODDERFONTEIN, EDENVALE, South Africa','geo':{'lat':-26.11544,'lng':28.14450}},
{'stp':541316,'address':'541316, EGL - ISANDO, ISANDO HIGH END TEAM, VILLA BIANCA REST (NTO), 4 REIER ROAD, CROYDEN, ISANDO, ISANDO, South Africa','geo':{'lat':-26.13465,'lng':28.19638}},
{'stp':547550,'address':'547550, EGL - ISANDO, ISANDO HIGH END TEAM, GRANDSLAM SPORTS DINER, 73 SYCAMORE DRIVE, SHOP 27 DOWERGLEN PLAZA, DOWEGLEN EXT 3, EDENVALE, South Africa','geo':{'lat':-26.14362,'lng':28.13788}},
{'stp':549048,'address':'549048, EGL - ISANDO, ISANDO HIGH END TEAM, BONI SPORTS BAR, STAND NO, SHOPS 1 AND 2 , 32 EMDEN RD, GERDVIEW, PRIMROSE, South Africa','geo':{'lat':-26.17503,'lng':28.18210}},
{'stp':550150,'address':'550150, EGL - ISANDO, ISANDO HIGH END TEAM, KEMPTON PARK HOTEL, 53 PRETORIA RD, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10870,'lng':28.22880}},
{'stp':550359,'address':'550359, EGL - ISANDO, ISANDO HIGH END TEAM, INNOCENT RESTAURANT, SHOP 3 QUATRO BUILDING, 83 WOLF STREET, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.10570,'lng':28.22940}},
{'stp':550360,'address':'550360, EGL - ISANDO, ISANDO HIGH END TEAM, GLENDOWER GOLF CLUB, 20 MARIAS RD, DOWERGLEN_,BEING REMAINING EXT, 5 OF THE FARM RIETFONTEIN 61, EDENVALE, South Africa','geo':{'lat':-26.15990,'lng':28.14130}},
{'stp':550493,'address':'550493, EGL - ISANDO, ISANDO HIGH END TEAM, OUR PLACE, 43 HARRIS AVE, SEBENZA, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13088,'lng':28.17803}},
{'stp':550513,'address':'550513, EGL - ISANDO, ISANDO HIGH END TEAM, VALUE SPORTS BAR, ERF 2640 SHOP 4A, WALTRUS BUILDING, WEST STREET KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.11120,'lng':28.23040}},
{'stp':550893,'address':'550893, EGL - ISANDO, ISANDO HIGH END TEAM, PARK FISH AND CHIPS, SHOP NO 23,45, SHOP 1 & 2,PRETORIA ROAD, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.10780,'lng':28.22830}},
{'stp':551113,'address':'551113, EGL - ISANDO, ISANDO HIGH END TEAM, PLANET POOL CLUB, 1ST FLOOR VAN RIEBEECK PLACE, 47 VAN RIEBEECK AVE PORTION 17, OF LOT 93 EDENDALE,EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.14420,'lng':28.15540}},
{'stp':551193,'address':'551193, EGL - ISANDO, ISANDO HIGH END TEAM, MODDERFONTEIN GOLF CLUB, MODDERFONTEIN, GOLF CORSE ROAD, #, EDENVALE, South Africa','geo':{'lat':-26.10210,'lng':28.16430}},
{'stp':551206,'address':'551206, EGL - ISANDO, ISANDO HIGH END TEAM, R A \'S SPORTS BAR, 26 PORTER STREET, ERF 318, SUNNYRIDGE, PRIMROSE, South Africa','geo':{'lat':-26.17130,'lng':28.17690}},
{'stp':551306,'address':'551306, EGL - ISANDO, ISANDO HIGH END TEAM, EDENVALE BOWLING CLUB, 6 TH AVENUE, EDENVALE, #, EDENVALE, South Africa','geo':{'lat':-26.12840,'lng':28.14270}},
{'stp':551455,'address':'551455, EGL - ISANDO, ISANDO HIGH END TEAM, BELGRAVIA BOWLING CLUB, 56 FLORENCE AVE, BEDFORDVIEW, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.17523,'lng':28.14719}},
{'stp':551599,'address':'551599, EGL - ISANDO, ISANDO HIGH END TEAM, THE PISTON RING, ERF NO 11111, 1 BLOEMFONTEIN RD, AVENUE MODDERFONTEIN, GERMISTON (not germiston), EDENVALE, South Africa','geo':{'lat':-26.09410,'lng':28.16290}},
{'stp':551864,'address':'551864, EGL - ISANDO, ISANDO HIGH END TEAM, THE DECK PUB AND GRILL, CNR WAGENER AND HARRIS ROADS, EDENGLEN EX 6, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13160,'lng':28.17800}},
{'stp':552055,'address':'552055, EGL - ISANDO, ISANDO HIGH END TEAM, EDENVALE DEUTCHER VEREIN, 8-10 PLANTATION RD, EASTLEIGH, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13480,'lng':28.15970}},
{'stp':552168,'address':'552168, EGL - ISANDO, ISANDO HIGH END TEAM, 33 HIGH STREET, ERF NO 733 HIGH STREET, FOUNDERSHILL MODDERFONTEIN, #, EDENVALE, South Africa','geo':{'lat':-26.09610,'lng':28.15980}},
{'stp':552210,'address':'552210, EGL - ISANDO, ISANDO HIGH END TEAM, CASTLE ACTION SOCCER, 17TH AVENUE, J B BEZUIDENHOUT PARK, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.14290,'lng':28.16160}},
{'stp':552321,'address':'552321, EGL - ISANDO, ISANDO HIGH END TEAM, NIGHT SHIFT ZONE BAR AND BRAAI, 71 STAND NO, ERF 2589 PRETORIA, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10898,'lng':28.22898}},
{'stp':552378,'address':'552378, EGL - ISANDO, ISANDO HIGH END TEAM, LEDWABAS TAVERN, STAND N O, GLADIATOR STREET, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.11220,'lng':28.23170}},
{'stp':552467,'address':'552467, EGL - ISANDO, ISANDO HIGH END TEAM, TOWN LODGE ORTIA, HARMON STREET, 8414 EDENGLEN EXT 2, 1600, ISANDO, South Africa','geo':{'lat':-26.14130,'lng':28.19250}},
{'stp':552579,'address':'552579, EGL - ISANDO, ISANDO HIGH END TEAM, JEPPE HIGH SCHOOL QUONDAM CLUB, QUONDAM PARK, GERMISTON, 1401, BEDFORDVIEW, South Africa','geo':{'lat':-26.16660,'lng':28.13860}},
{'stp':552601,'address':'552601, EGL - ISANDO, ISANDO HIGH END TEAM, DANILOS TRATTORIA, SHOP 27A GLEN CURVE SHOPPING, CENTRE, CRN ELM STR & BEECH ST, MARAIS STEYN PARK, EDENVALE, South Africa','geo':{'lat':-26.13860,'lng':28.13780}},
{'stp':553138,'address':'553138, EGL - ISANDO, ISANDO HIGH END TEAM, CITY LODGE JAN SMUTS, HARMELIAEXT 4, EDENVALE, #, ISANDO, South Africa','geo':{'lat':-26.13800,'lng':28.19410}},
{'stp':553253,'address':'553253, EGL - ISANDO, ISANDO HIGH END TEAM, ITALIAN CLUB JHB, 167 MARAIS RD, BEDFORDVIEW, 2008, BEDFORDVIEW, South Africa','geo':{'lat':-26.16398,'lng':28.13439}},
{'stp':553297,'address':'553297, EGL - ISANDO, ISANDO HIGH END TEAM, KEG AND CROW, SHOP G1  KEYWEST ENTERTAINMENT, 43 VAN BUUREN RD, BEDFORDVIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.18240,'lng':28.13510}},
{'stp':553528,'address':'553528, EGL - ISANDO, ISANDO HIGH END TEAM, RODIZIO RESTAURANT, 0 CNR VAN BUUREN AND KLOOF ROADS, ERF 179, SHOP 35 BEDFORDVIEW VILLAGE VIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.18380,'lng':28.13500}},
{'stp':553589,'address':'553589, EGL - ISANDO, ISANDO HIGH END TEAM, JAKOMELYS, SUNRISE CENTRE, 71 VAN RIEBEECK AVE, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.14110,'lng':28.15340}},
{'stp':553650,'address':'553650, EGL - ISANDO, ISANDO HIGH END TEAM, BRAZEN HEAD EDENVALE, ECRN TERRECE & VAN TONDER ROAD, EDENVALE, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.12580,'lng':28.16460}},
{'stp':553742,'address':'553742, EGL - ISANDO, ISANDO HIGH END TEAM, STREET CAFE, 136 VAN RIEBEECK AVENUE, EDENVALE, 1609, EDENVALE, South Africa','geo':{'lat':-26.13250,'lng':28.14800}},
{'stp':607511,'address':'607511, EGL - ISANDO, ISANDO HIGH END TEAM, COSMIC CURRIES, PORTION 1 ERF 1514 SHOP 2, KARAGLEN SQUARE EDENGLEN  CRN HARRIS ST, & BAKER ST, EDENVALE, South Africa','geo':{'lat':-26.13552,'lng':28.17981}},
{'stp':616019,'address':'616019, EGL - ISANDO, ISANDO HIGH END TEAM, COZY CORNER (NTO), CHARMION AVENUE, EDENVALE, Johannesburg, EDENVALE, South Africa','geo':{'lat':-26.14456,'lng':28.18170}},
{'stp':625818,'address':'625818, EGL - ISANDO, ISANDO HIGH END TEAM, MAREDIS TAVERN, 3338 MAKHANYA DRIVE, #, EXT 5 (Ext 2), TEMBISA, South Africa','geo':{'lat':-26.01580,'lng':28.18427}},
{'stp':627074,'address':'627074, EGL - ISANDO, ISANDO HIGH END TEAM, BOULAVARD CAFE, GROUND FLOOR (EAST WING), LEPPAN HOUSE NO 1 SKEEN BLVD ERF 325, (CRN HANLEY RD), BEDFORDVIEW, South Africa','geo':{'lat':-26.17590,'lng':28.13280}},
{'stp':628407,'address':'628407, EGL - ISANDO, ISANDO HIGH END TEAM, SIHLANGUS PLACE, 1 HATTINGH  STREET, ELANDSFONTEIN, #, ISANDO, South Africa','geo':{'lat':-26.15120,'lng':28.20260}},
{'stp':631322,'address':'631322, EGL - ISANDO, ISANDO HIGH END TEAM, THE HORSESHOE, LONGSTREET 43, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10607,'lng':28.23723}},
{'stp':635004,'address':'635004, EGL - ISANDO, ISANDO HIGH END TEAM, KLY PUB AND GRILL, 25 CENTRAL AVENUE, EASTLEIGH ERF NO 348, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13060,'lng':28.15990}},
{'stp':635060,'address':'635060, EGL - ISANDO, ISANDO HIGH END TEAM, ADEGA BEDFORDVIEW, 1 PARK STREET, CNR HOWEY, BEDFORDVIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.17800,'lng':28.13620}},
{'stp':636191,'address':'636191, EGL - ISANDO, ISANDO HIGH END TEAM, MASERU INN, 667 BAMAKO STREET, ISIPITETHWINI SECTION, #, TEMBISA, South Africa','geo':{'lat':-26.03030,'lng':28.22040}},
{'stp':636919,'address':'636919, EGL - ISANDO, ISANDO HIGH END TEAM, SUPERTAINMENT CENTRE, SUPERBOWLING, GREENSTONE SHOPPING CENTRE, MODDERFONTEIN, EDENVALE, South Africa','geo':{'lat':-26.11615,'lng':28.14078}},
{'stp':638360,'address':'638360, EGL - ISANDO, ISANDO HIGH END TEAM, 7 EAGLES SPUR, SHOPP L 103, GREENSTONE SHOP-PING CENTRE, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.11824,'lng':28.14104}},
{'stp':639109,'address':'639109, EGL - ISANDO, ISANDO HIGH END TEAM, THE FAHRENHEIT, NO 1 HUDSON ROAD, CNR TERRACE ROAD, EASTLEIGH, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.12780,'lng':28.15010}},
{'stp':639248,'address':'639248, EGL - ISANDO, ISANDO HIGH END TEAM, DIPERENG TAVERN, 2 KRAAYENBRINK ROAD, KEMPTONPARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10540,'lng':28.22640}},
{'stp':641224,'address':'641224, EGL - ISANDO, ISANDO HIGH END TEAM, VANICE PUB AND GRILL, 12 JUBILEE STREET, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10970,'lng':28.23180}},
{'stp':648930,'address':'648930, EGL - ISANDO, ISANDO HIGH END TEAM, BARON BEDFORDVIEW, SHOP 16 BEDFORD VILLAGE SHOPPING, CENTRE,CORNER OF NICOL AND VAN BUUREN, ROADS STAND 1146, BEDFORDVIEW, South Africa','geo':{'lat':-26.18110,'lng':28.13520}},
{'stp':652834,'address':'652834, EGL - ISANDO, ISANDO HIGH END TEAM, MISTY HILLS SPUR, PRIMROSE CENTRE, SHAMROCK ROAD, BETWEEN CHURCHILL &, BEACENAFIELD AVE, BEING ERF 3035, PRIMROSE, South Africa','geo':{'lat':-26.18680,'lng':28.15850}},
{'stp':657900,'address':'657900, EGL - ISANDO, ISANDO HIGH END TEAM, ACTION SPORTS EDENVALE, 4 BHALA ROAD, SABENZA ERF NO K2200400000267, #, EDENVALE, South Africa','geo':{'lat':-26.12860,'lng':28.18620}},
{'stp':664198,'address':'664198, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET SUNNINGHILL (NTO), CHILLI LANE SHOPPING CENTRE, CHILLI LANE SHOPPING CENTRE, CHILLI LANE SHOPPING CENTRE, SUNNINGHILL, South Africa','geo':{'lat':-26.01680,'lng':28.20980}},
{'stp':665167,'address':'665167, EGL - ISANDO, ISANDO HIGH END TEAM, PIATTO STONERIDGE (NTO), STONERIDGE SHOPPING CENTRE, GREENSTONE PARK, MODDERFONTEIN RD, GREENSTONE PARK, MODDERFONTEIN RD, EDENVALE, South Africa','geo':{'lat':-26.11663,'lng':28.14573}},
{'stp':665171,'address':'665171, EGL - ISANDO, ISANDO HIGH END TEAM, PRIMI PIATTI STONERIDGE (NTO), STONERIDGE SHOPPING CENTRE, GREENSTONE PARK, MODDERFONTEIN RD, GREENSTONE PARK, MODDERFONTEIN RD, EDENVALE, South Africa','geo':{'lat':-26.10439,'lng':28.17302}},
{'stp':665216,'address':'665216, EGL - ISANDO, ISANDO HIGH END TEAM, CAPPUCCINOS GREENSTONE (NTO), GREENSTONE SHOPPING MALL, CNR MODDERFONTEIN RD & VAN RIEBEECK AVE, CNR MODDERFONTEIN RD & VAN RIEBEECK AVE, EDENVALE, South Africa','geo':{'lat':-26.11934,'lng':28.13972}},
{'stp':665218,'address':'665218, EGL - ISANDO, ISANDO HIGH END TEAM, MUGG AND BEAN GREENSTONE (NTO), GREENSTONE SHOPPING MALL, CNR MODDERFONTEIN RD & VAN RIEBEECK AVE, CNR MODDERFONTEIN RD & VAN RIEBEECK AVE, EDENVALE, South Africa','geo':{'lat':-26.11934,'lng':28.13972}},
{'stp':665219,'address':'665219, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET EDEN MEADOWS (NTO), EDEN MEADOWS, CNR VAN RIEBEECK AND MODDERFONTEIN OFFRA, CNR VAN RIEBEECK AND MODDERFONTEIN OFFRA, EDENVALE, South Africa','geo':{'lat':-26.12040,'lng':28.13785}},
{'stp':665271,'address':'665271, EGL - ISANDO, ISANDO HIGH END TEAM, PIGALLE RESTAURANT (NTO), CNR VAN BUUREN & KLOOF RD, CNR VAN BUUREN & KLOOF RD, CNR VAN BUUREN & KLOOF RD, BEDFORDVIEW, South Africa','geo':{'lat':-26.18350,'lng':28.13534}},
{'stp':665272,'address':'665272, EGL - ISANDO, ISANDO HIGH END TEAM, RAZZIOLIS (NTO), 59 TERRACE RD, CNR VAN TONDER, 59 TERRACE RD, CNR VAN TONDER, EDENGLEN, EDENVALE, South Africa','geo':{'lat':-26.12537,'lng':28.16571}},
{'stp':668295,'address':'668295, EGL - ISANDO, ISANDO HIGH END TEAM, TASHAS BEDFORDVIEW (NTO), CNR VAN BUUREN AND KLOOF RD, VILLAGE VIEW, BEDFORDVIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.18350,'lng':28.13534}},
{'stp':673473,'address':'673473, EGL - ISANDO, ISANDO HIGH END TEAM, ADVENTURE GOLF STONERIDGE (NTO), STONERIDGE SHOPPING CENTRE, GREENTSTONE PARK MODDERFONTEIN ROAD, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.11644,'lng':28.14469}},
{'stp':674481,'address':'674481, EGL - ISANDO, ISANDO HIGH END TEAM, PUB 95 (NTO), NO 7 EREENIE SHOPPING CENTRE, 3RD STREET MERLANDS, PRIMROSE, PRIMROSE, South Africa','geo':{'lat':-26.18135,'lng':28.19332}},
{'stp':677478,'address':'677478, EGL - ISANDO, ISANDO HIGH END TEAM, NANDO\'S CROYDON (NTO), CROYDON, CROYDON, CROYDON, ISANDO, South Africa','geo':{'lat':-26.13489,'lng':28.19615}},
{'stp':680430,'address':'680430, EGL - ISANDO, ISANDO HIGH END TEAM, ENHLANGANO, 32 NOORDRAND ROAD, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10190,'lng':28.23540}},
{'stp':684865,'address':'684865, EGL - ISANDO, ISANDO HIGH END TEAM, TOP HAT (NTO), 171A RIETFONTEIN ROAD, 171A RIETFONTEIN ROAD, CONSTANTIA, PRIMROSE, South Africa','geo':{'lat':-26.18694,'lng':28.16597}},
{'stp':687573,'address':'687573, EGL - ISANDO, ISANDO HIGH END TEAM, BRIANS PUB AND DINER, NO 134 RIETFONTEIN ROAD, PRIMROSE, #, PRIMROSE, South Africa','geo':{'lat':-26.18870,'lng':28.16280}},
{'stp':695542,'address':'695542, EGL - ISANDO, ISANDO HIGH END TEAM, PROSPER LIQUOR STORE, 27A CENTRAL AVENUE, #, BEING PORTION 02 OF ERF 2725, KEMPTON PARK, South Africa','geo':{'lat':-26.10690,'lng':28.23540}},
{'stp':695914,'address':'695914, EGL - ISANDO, ISANDO HIGH END TEAM, NEWS CAFE BEDFORDVIEW, SHOP NO 1 AND 2, GROUND FLOOR STAND NO 1, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.17932,'lng':28.13633}},
{'stp':696692,'address':'696692, EGL - ISANDO, ISANDO HIGH END TEAM, CTFM GREENSTONE (NTO), SHOPS L105/107, GREENSTONE SHOPPING CENTRE, CNR MODDERFONTEIN & VAN RIEBEECK ROAD, EDENVALE, South Africa','geo':{'lat':-26.11934,'lng':28.13972}},
{'stp':698196,'address':'698196, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET BEDFORDVIEW (NTO), UPPER LEVEL BEDFORD VILLAGE, CNR VAN BUUREN AND NICOL, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.18060,'lng':28.13630}},
{'stp':698650,'address':'698650, EGL - ISANDO, ISANDO HIGH END TEAM, PRIMI PIATTI SUNNINGHILL (NTO), CHILLI LANE SHOPPING CENTRE, SUNNINGHILL, #, SUNNINGHILL, South Africa','geo':{'lat':-26.03057,'lng':28.06080}},
{'stp':699682,'address':'699682, EGL - ISANDO, ISANDO HIGH END TEAM, ROCKABILLYS (NTO), ERF.1544 EDEN TERRACE, SHOPPING CENTRE,FIRST FLOOR.SHOP NO.F8 ,, CNR TERRACE & VAN TONDER STR\'S, EDENGLEN, EDENVALE, South Africa','geo':{'lat':-26.12532,'lng':28.16602}},
{'stp':700896,'address':'700896, EGL - ISANDO, ISANDO HIGH END TEAM, CHIVALRY (NTO), 59 DEODAR ROAD, PRIMROSE, PO BOX 13434 WITFIELD, #, PRIMROSE, South Africa','geo':{'lat':-26.18700,'lng':28.18706}},
{'stp':705273,'address':'705273, EGL - ISANDO, ISANDO HIGH END TEAM, OCEAN BASKET KARAGLEN (NTO), PORTION 1 ERF 1514 SHOP 2, KARAGLEN SQUARE EDENGLEN, CRN HARRIS ST & BAKER ST, EDENVALE, South Africa','geo':{'lat':-26.13650,'lng':28.17890}},
{'stp':707745,'address':'707745, EGL - ISANDO, ISANDO HIGH END TEAM, SCARLET RIBBON, SHOP U61 AMD M3, STONERIDGE SHOP CEN BOUNDED, MODDERFONTEIN RD HEREFORD RD BLACK, EDENVALE, South Africa','geo':{'lat':-26.11680,'lng':28.14530}},
{'stp':710119,'address':'710119, EGL - ISANDO, ISANDO HIGH END TEAM, PERI-PERI (NTO), SHOP 1, CNR HARRIS & WAGENAAR, EDENGLEN, EDENVALE, South Africa','geo':{'lat':-26.13133,'lng':28.17833}},
{'stp':712175,'address':'712175, EGL - ISANDO, ISANDO HIGH END TEAM, DIVAS (NTO), 210 RIETFONTEIN ROAD, PRIMROSE, #, PRIMROSE, South Africa','geo':{'lat':-26.18536,'lng':28.17011}},
{'stp':722367,'address':'722367, EGL - ISANDO, ISANDO HIGH END TEAM, BEDFORDVIEW HEALTH & C/CLUB (NTO), CNR HARPER & VAN BUREN RD, BEDFORDVIEW, #, BEDFORDVIEW, South Africa','geo':{'lat':-26.16927,'lng':28.14565}},
{'stp':727086,'address':'727086, EGL - ISANDO, ISANDO HIGH END TEAM, OTTAWA SPUR (NTO), KARAGLEN MALL, CNR BAKER AND HARRIS RD, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13574,'lng':28.18054}},
{'stp':727090,'address':'727090, EGL - ISANDO, ISANDO HIGH END TEAM, GALAXY BINGO GREENSTONE (NTO), GREENSTONE SHOPPING MALL, CNR MODDERFONTEIN RD & VAN RIEBEECK AVE, GREENSTONE, EDENVALE, South Africa','geo':{'lat':-26.11831,'lng':28.14099}},
{'stp':728585,'address':'728585, EGL - ISANDO, ISANDO HIGH END TEAM, DROS EDENVALE (NTO), EDEN MEADOW SHOPPING CENTRE, EDENVALE, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.12109,'lng':28.13794}},
{'stp':729343,'address':'729343, EGL - ISANDO, ISANDO HIGH END TEAM, RIDGEWAY SPORTS CAFE, MODDERFONTEIN AND HEREFORD RD, ERF 2 SHOP L1A STONERIDGE CENTER, WONDERBOOM SOUTH, EDENVALE, South Africa','geo':{'lat':-26.11392,'lng':28.14377}},
{'stp':732712,'address':'732712, EGL - ISANDO, ISANDO HIGH END TEAM, VILLAGE FISH MARKET (NTO), BRABAZON ROAD, ISANDO, ISANDO, ISANDO, South Africa','geo':{'lat':-26.13489,'lng':28.19615}},
{'stp':742636,'address':'742636, EGL - ISANDO, ISANDO HIGH END TEAM, ATTIQUE LOUNGE (NTO), SHOP 23 DETROIT PLAZA, OLD PRETORIA ROAD, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.11349,'lng':28.22859}},
{'stp':753098,'address':'753098, EGL - ISANDO, ISANDO HIGH END TEAM, PHUTIS TAVERN, 10 KEMEEL STREET, #, #, ISANDO, South Africa','geo':{'lat':-26.15120,'lng':28.20400}},
{'stp':753997,'address':'753997, EGL - ISANDO, ISANDO HIGH END TEAM, LONG STREET BOTTLE STORE, 29 LONG STREET NO, PORTION 4 OF ERF 2700, SHOP 10, KEMPTON PARK, South Africa','geo':{'lat':-26.10500,'lng':28.23490}},
{'stp':762538,'address':'762538, EGL - ISANDO, ISANDO HIGH END TEAM, DE EXODUS, 43 WEST STREET, LEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10925,'lng':28.23020}},
{'stp':765801,'address':'765801, EGL - ISANDO, ISANDO HIGH END TEAM, STONES BEDFORDVIEW (NTO), SUITE 101 1ST FLOOR KEY WEST CNTRE, VAN BUUREN ROAD, BEDFORDVIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.18240,'lng':28.13510}},
{'stp':766639,'address':'766639, EGL - ISANDO, ISANDO HIGH END TEAM, Q BA STONERIDGE, STAND NO, SHOP 24A STONERIDGE SC MODDERFONTEIN RD, HEREFORD RD BLACK ROCK RD, EDENVALE, South Africa','geo':{'lat':-26.11545,'lng':28.14437}},
{'stp':772181,'address':'772181, EGL - ISANDO, ISANDO HIGH END TEAM, KAROO CARLSWORLD (NTO), CARLSWORLD LIFESTYLE CENTRE, CNR MAIN AND HARRY GALAIN, MIDRAND, MIDRAND, South Africa','geo':{'lat':-25.97946,'lng':28.11621}},
{'stp':773741,'address':'773741, EGL - ISANDO, ISANDO HIGH END TEAM, MALAGUATA\'S (NTO), PORTION 2 PLOT 548, 02 MAIN ROAD, EASTLEIGH, EDENVALE, South Africa','geo':{'lat':-26.14417,'lng':28.16683}},
{'stp':775599,'address':'775599, EGL - ISANDO, ISANDO HIGH END TEAM, PANAROTTIS GREENSTONE (NTO), CNR MODDERFONTEIN RD & VAN RIEBEECK, GREENSTONE, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.11628,'lng':28.14396}},
{'stp':778129,'address':'778129, EGL - ISANDO, ISANDO HIGH END TEAM, LOCAL GRIL GRAZE (NTO), CNR HERMAN & KRUIN AVE, HARMELIA, ISANDO, ISANDO, South Africa','geo':{'lat':-26.14120,'lng':28.19140}},
{'stp':790328,'address':'790328, EGL - ISANDO, ISANDO HIGH END TEAM, CHEERLEADERS (NTO), TERRACE RD SHOPPING CNT, TERRACE ROAD, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.08219,'lng':28.20954}},
{'stp':790329,'address':'790329, EGL - ISANDO, ISANDO HIGH END TEAM, SA EMERGENCY CARE (NTO), 1 ABERDEER ROAD, MODDERFONTEIN, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.09278,'lng':28.15275}},
{'stp':791170,'address':'791170, EGL - ISANDO, ISANDO HIGH END TEAM, O \'VICENTE (NTO), CNR SOVEREIGN & MULLIN STS, BEDFORDVIEW, BEDFORDVIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.19700,'lng':28.11979}},
{'stp':795040,'address':'795040, EGL - ISANDO, ISANDO HIGH END TEAM, NORTH RAND LIQUOR STORE, 4 CASUARINA STREET, ERF 64 SHOP 2 SOBETHU HOUSE, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10239,'lng':28.23562}},
{'stp':797735,'address':'797735, EGL - ISANDO, ISANDO HIGH END TEAM, THE MEDITERRANEAN HOTEL AND CONFERE, 72 CONCORD ROAD EAST, PORTION 3 OF HOLDING 331, GELSENHUIS EASTATE SMALL HOLDING, BEDFORDVIEW, South Africa','geo':{'lat':-26.17043,'lng':28.13501}},
{'stp':798180,'address':'798180, EGL - ISANDO, ISANDO HIGH END TEAM, TURN N TENDER BEDFORDVIEW, STAND NO, SHOP 33 VILLAGE VIEW S/C, CNR KLOOF AND VAN BUUREN RD, BEDFORDVIEW, South Africa','geo':{'lat':-26.18286,'lng':28.13516}},
{'stp':309452,'address':'309452, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR ON THE RUN, 25A STAND NO, RUDO NELL RD, JET PARK, ISANDO, South Africa','geo':{'lat':-26.17537,'lng':28.21840}},
{'stp':311460,'address':'311460, EGL - ISANDO, ISANDO HIGH END TEAM, SHOPRITE LIQUORSHOP CLOVERDENE 8932, 1544 ERF NO, 1 CLOVERDENE ROAD, CNR PUTFONTEIN ROAD, BENONI, South Africa','geo':{'lat':-26.15685,'lng':28.37656}},
{'stp':383399,'address':'383399, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS LIQUOR SHOP NORTHMEAD LC 0, 1 STAND NO, 14TH AVENUE CORNER O REILY MERY STREET, NORTHMEAD PORTION 190, BENONI, South Africa','geo':{'lat':-26.16340,'lng':28.32135}},
{'stp':384672,'address':'384672, EGL - ISANDO, ISANDO HIGH END TEAM, NIMROD LIQUOR STORE, 156 STAND NO, PINAAR AVENUE, NORKEM PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.09316,'lng':28.24294}},
{'stp':390795,'address':'390795, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY MERCURY, 9 SHOP NO, MERCURY SHOPPING CENTRE CORNER MERCURY R, #, BENONI, South Africa','geo':{'lat':-26.15024,'lng':28.29894}},
{'stp':391359,'address':'391359, EGL - ISANDO, ISANDO HIGH END TEAM, TREASURE CITY LIQUOR STORE, 9 STAND NO, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10317,'lng':28.23199}},
{'stp':391376,'address':'391376, EGL - ISANDO, ISANDO HIGH END TEAM, ASABBA BAR AND RESTAURANT, 243 STAND NO, SHOP 50, KEMPTON PARK ROAD, KEMPTON PARK, South Africa','geo':{'lat':-26.10418,'lng':28.23513}},
{'stp':392327,'address':'392327, EGL - ISANDO, ISANDO HIGH END TEAM, DEJA VU PUB AND LOUNGE, 78 CRABOURN AVENUE,, #, #, BENONI, South Africa','geo':{'lat':-26.19041,'lng':28.31750}},
{'stp':472629,'address':'472629, EGL - ISANDO, ISANDO HIGH END TEAM, GAME LIQUOR BENONI 274, SHOP NO L 26 A, LAKESIDE MALL SHOPPING CENTRE, TOM JONES 8346 AND 8347, BENONI, South Africa','geo':{'lat':-26.18588,'lng':28.31533}},
{'stp':481813,'address':'481813, EGL - ISANDO, ISANDO HIGH END TEAM, EVERGREENS LIQUOR STORE, 6760 STAND NO, SHOP 2 EVERGREENS CENTRE CORNER, CRANBOURNE AND BUNYAN STREET, BENONI, South Africa','geo':{'lat':-26.19125,'lng':28.31514}},
{'stp':488244,'address':'488244, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS LIQUORHSOP EAST RAND RETAI, 297 ERF NO, 113 NORTH RAND ROAD, EVERLEIGH EXT 22, BOKSBURG, South Africa','geo':{'lat':-26.17976,'lng':28.24845}},
{'stp':494253,'address':'494253, EGL - ISANDO, ISANDO HIGH END TEAM, ABSOLUTE LIQUOR, 1705 STAND NO, 43 ROLLS ROYCE STREET, IMPALA PARK, BOKSBURG, South Africa','geo':{'lat':-26.16629,'lng':28.27795}},
{'stp':496312,'address':'496312, EGL - ISANDO, ISANDO HIGH END TEAM, WORLD OCEAN CL, 277 STAND NO, MAPLE STR, POMONA, KEMPTON PARK, South Africa','geo':{'lat':-26.09321,'lng':28.27214}},
{'stp':550161,'address':'550161, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY RYNGATE, ERF 215 & 216 SHOP 11, RYNGATE TERRACE CRN PRETORIA, & VLEI RD RYNFIELD BENONI, BENONI, South Africa','geo':{'lat':-26.13700,'lng':28.34850}},
{'stp':550253,'address':'550253, EGL - ISANDO, ISANDO HIGH END TEAM, BRENTWOOD PARK LIQUOR STORE, 19 HIGH ROAD, BRENTWOOD PARK, #, BENONI, South Africa','geo':{'lat':-26.10929,'lng':28.29601}},
{'stp':550329,'address':'550329, EGL - ISANDO, ISANDO HIGH END TEAM, NORTH POINT TOPS, NORTH POINT SHOPPING CENTRE, CNR TRICHARDT AND FINDEL STREETS, IMPALA PARK EXTENTION, BOKSBURG, South Africa','geo':{'lat':-26.16599,'lng':28.25583}},
{'stp':550721,'address':'550721, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT SPAR GLEN ACRES, 7 LIQUOR CITY GLEN ACRES SHOPPING C, CNR DAN MONUMENT STREETS, #, KEMPTON PARK, South Africa','geo':{'lat':-26.07820,'lng':28.25010}},
{'stp':550995,'address':'550995, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY RYNFIELD, 36 MILES SHARPST, RYNFIELD, BENONI, BENONI, South Africa','geo':{'lat':-26.15680,'lng':28.33391}},
{'stp':551078,'address':'551078, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT CRYSTAL GATE, 111 TOTIUS ROAD, CRYSTAL PARK, #, BENONI, South Africa','geo':{'lat':-26.13550,'lng':28.37100}},
{'stp':551442,'address':'551442, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY GLEN MARAIS, SHOP NO 4 GLEN MARAIS S/C, C/O DANN ROAD & VELD STREET, GLEN MARAIS EXT 11, KEMPTON PARK, South Africa','geo':{'lat':-26.06986,'lng':28.24602}},
{'stp':551544,'address':'551544, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY TOWERS, SHOP 3 PLAZA SHOPPING CENTRE, 136 NORTH RAND ROAD, STAND 194 & 195 JANSEN PARK, BOKSBURG, South Africa','geo':{'lat':-26.18040,'lng':28.24180}},
{'stp':551568,'address':'551568, EGL - ISANDO, ISANDO HIGH END TEAM, ASTON MANOR LIQUORS, ASTON MANOR SHOPPING CENTRE, MONUMENT RD, ASTON MANOR, KEMPTON PARK, South Africa','geo':{'lat':-26.08320,'lng':28.24230}},
{'stp':551607,'address':'551607, EGL - ISANDO, ISANDO HIGH END TEAM, PICCADILLY LIQUORS, SHOP 21  MONA LISA S/CENTRE, 1 A SIXTH STREET, NORTHMEAD., BENONI, South Africa','geo':{'lat':-26.17355,'lng':28.33027}},
{'stp':551774,'address':'551774, EGL - ISANDO, ISANDO HIGH END TEAM, WITFIELD TOPS, SHOP 6 WITFIELD SQUARE, CNR MAIN AND LILLIAN STREETS, ERF 702 WITFIELD BERTON PARK, BOKSBURG, South Africa','geo':{'lat':-26.19206,'lng':28.20567}},
{'stp':551905,'address':'551905, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY LONG STREET, 23 LONG STREET, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10470,'lng':28.23240}},
{'stp':551973,'address':'551973, EGL - ISANDO, ISANDO HIGH END TEAM, THE FALLS SUPERM TA FALLS SUPERSPAR, THE FALLS SHOP CENTRE, CRN GREAT NORTH AND WEBB RD, ERF 3 4877 NORTHMEAD, BENONI, South Africa','geo':{'lat':-26.16700,'lng':28.31080}},
{'stp':552035,'address':'552035, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT ATLAS MALL, CNR FINCH AND REIER ROADS, ATLASVILLE SHOPPING CENTRE, ATLASVILLE EXT 1, BENONI, South Africa','geo':{'lat':-26.15950,'lng':28.28260}},
{'stp':552053,'address':'552053, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY WITFIELD, PORTION OF ERF 184, SHOP NO 4 AND 5 NO 184, ROSEDENE SHOPPING CENTRE PRETORIA STR, BOKSBURG, South Africa','geo':{'lat':-26.19060,'lng':28.22500}},
{'stp':552059,'address':'552059, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY BOKSBURG WEST BOTTLE ST, ERF 74 53 RIETFONTEIN ROAD, BOKSBURG WEST, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19840,'lng':28.24410}},
{'stp':552114,'address':'552114, EGL - ISANDO, ISANDO HIGH END TEAM, SANTA CRUZ SPUR, SHOP F1 NORTHMEAD SQUARE, 2 14 TH AVENUE, BENONI, BENONI, South Africa','geo':{'lat':-26.16340,'lng':28.32250}},
{'stp':552297,'address':'552297, EGL - ISANDO, ISANDO HIGH END TEAM, FARRAMERE LIQUOR STORE, ERF 5221 SHOP 2, BAYLEY STREET, FARRAMERE, BENONI, South Africa','geo':{'lat':-26.17470,'lng':28.30120}},
{'stp':552370,'address':'552370, EGL - ISANDO, ISANDO HIGH END TEAM, MC GINTYS BENONI, STAND NO, NORTHMEAD SQUARE CORNER 14TH AVENUE, O REILLY MERRY STREET BENONI, BENONI, South Africa','geo':{'lat':-26.16240,'lng':28.32390}},
{'stp':552449,'address':'552449, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT WESTWOOD, STAND NO, CNR ATLAS AND PHILIPS RD, BEYERSPARK BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.18413,'lng':28.28044}},
{'stp':552493,'address':'552493, EGL - ISANDO, ISANDO HIGH END TEAM, THE SQUARE TOPS, THE VILLAGE SQUARE SHOPPING CE, CNR RIETFONTEIN RD AND RIGG ROAD, BOKSBURG, BOKSBURG, South Africa','geo':{'lat':-26.19098,'lng':28.24089}},
{'stp':552697,'address':'552697, EGL - ISANDO, ISANDO HIGH END TEAM, PROTEA LIQUOR STORE, 32 STAND NO, GREAT NORTH ROAD, NORTHMEAD EXT 4, BENONI, South Africa','geo':{'lat':-26.14560,'lng':28.29850}},
{'stp':553060,'address':'553060, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY SATELITE, C/O CHARL CILLIERS STREET & 11, BOKSBURG NORTH, BOKSBURG NORTH, BOKSBURG, South Africa','geo':{'lat':-26.20350,'lng':28.25890}},
{'stp':606575,'address':'606575, EGL - ISANDO, ISANDO HIGH END TEAM, VIP\'S RESTAURANT, 23 AND 23A LONG STREET, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10470,'lng':28.23240}},
{'stp':614776,'address':'614776, EGL - ISANDO, ISANDO HIGH END TEAM, VILLA FUNCHAL (NTO), 2 RANGEVIEW ROAD, BENONI, Johannesburg, BENONI, South Africa','geo':{'lat':-26.18056,'lng':28.31175}},
{'stp':628362,'address':'628362, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY ATLAS PARK, SHOP 02 ATLAS PARK CENTRE, 28 STAR STREET ERF 1368; ATLASVILLE, #, BENONI, South Africa','geo':{'lat':-26.15340,'lng':28.28910}},
{'stp':630088,'address':'630088, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY BONAEROPARK, SHOP 1 BONAERO CENTRE, CNR TEMPLEHOF NORTH AND SOUTH, #, KEMPTON PARK, South Africa','geo':{'lat':-26.12150,'lng':28.25640}},
{'stp':631210,'address':'631210, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT BENONI, 1398 STAND NO, ENTRANCE FROM TOM JONES STREET, BETWEEN AMPTHILL N LAKE AVE, BENONI, South Africa','geo':{'lat':-26.19270,'lng':28.31830}},
{'stp':637743,'address':'637743, EGL - ISANDO, ISANDO HIGH END TEAM, DOG BOX LIQUOR STORE, PLOT 97 3RD AVENUE, BREDELL, #, KEMPTON PARK, South Africa','geo':{'lat':-26.08070,'lng':28.28810}},
{'stp':639077,'address':'639077, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY FARRAR SQUARE, SHOP 2 FARRAR SQUARE, KEI & MAALSTROOM STREET, FARRARMERE  BENONI, BENONI, South Africa','geo':{'lat':-26.16310,'lng':28.30330}},
{'stp':640443,'address':'640443, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY HYPER BOKSBURG, SHOP N PNPA,EAST RAND GALLERIA, CNR RIETFONTEIN & NORTHRAND, BEING ERWE 175&176 JANSENPARK, BOKSBURG, South Africa','geo':{'lat':-26.18028,'lng':28.23636}},
{'stp':648093,'address':'648093, EGL - ISANDO, ISANDO HIGH END TEAM, ABSOLUTE AT NO 7, ERF 571 BEYERSPARK SHOP NO 03, NORTH RAND ROAD, NORTH RAND CENTRE, BOKSBURG, South Africa','geo':{'lat':-26.17920,'lng':28.25690}},
{'stp':656484,'address':'656484, EGL - ISANDO, ISANDO HIGH END TEAM, PALMS TOPS, 0 PALMS VALUE CENTRE SHOP 2A, CNR NORTHRAND ROAD AND FIRST STREET, ERVEN 1097 AND 1098 BARDENE EXT 41, BOKSBURG, South Africa','geo':{'lat':-26.17745,'lng':28.24844}},
{'stp':664353,'address':'664353, EGL - ISANDO, ISANDO HIGH END TEAM, NESTPARK LIQUOR STORE, PLOT 81 CNR VAN RIEBEECK STREET, & KRUGER AVE, NEST PARK AGRICULTURAL, KEMPTON PARK, South Africa','geo':{'lat':-25.98370,'lng':28.43240}},
{'stp':666091,'address':'666091, EGL - ISANDO, ISANDO HIGH END TEAM, SHOPRITE JAN SMUTS 57104, 0 CNR CENTRAL AVE AND LANGEHOVEN RD, SHOP 12 SHOPRITE SUPERSTORE, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10800,'lng':28.23620}},
{'stp':667402,'address':'667402, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY GLEN MARAIS, 0 CNR FRIED AND LOAM ROADS, SHOP 02 GLAN BALAD MALL ERF 3426, GLEN MARIAS EXTENSION 85, KEMPTON PARK, South Africa','geo':{'lat':-26.08500,'lng':28.25220}},
{'stp':670097,'address':'670097, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY ELRIDGE, 52 RIDGE ROAD, SHOP NO 34 ETRIDGE SQUARE CNR CENTRE, ERF 1003 BARTLETT EXT 2, BOKSBURG, South Africa','geo':{'lat':-26.16710,'lng':28.27400}},
{'stp':681179,'address':'681179, EGL - ISANDO, ISANDO HIGH END TEAM, PIATTO FARAMERE (NTO), SHOP 80 THE SQUARE, FARRAMERE SHOPPING CENTRE, CNR KEI & MAALSTROOM, BENONI, South Africa','geo':{'lat':-26.16387,'lng':28.30349}},
{'stp':687116,'address':'687116, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY OAKFIELDS, SHOP 50, OAKFIELDS AHOPPING CENTRE, CNR OAK AND HANEKAM STREET, BENONI, South Africa','geo':{'lat':-26.15150,'lng':28.31130}},
{'stp':688626,'address':'688626, EGL - ISANDO, ISANDO HIGH END TEAM, THE LIQUOR COMPANY ATLASVILLE, SUMMERFIELD CENTRE, SHOP NO 08, CNR FINCH AND REIER STREET, BENONI, South Africa','geo':{'lat':-26.15980,'lng':28.28010}},
{'stp':694738,'address':'694738, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS BONAEROPARK 59017, SHOP NO 02, BONAERO PARK SHOPPING CENTRE CORNER, ATLAS AND GELDENHUYS STREET BEING ERF, KEMPTON PARK, South Africa','geo':{'lat':-26.11950,'lng':28.25800}},
{'stp':697992,'address':'697992, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY NORTHMEAD, SHOP NO 11, NORTHMEAD MALL 1 st STREET, BETWEEN 6th and 9th AVENNUE ERF 6757, BENONI, South Africa','geo':{'lat':-26.17200,'lng':28.33010}},
{'stp':698614,'address':'698614, EGL - ISANDO, ISANDO HIGH END TEAM, CAVENDISH GLEN LIQUORS, 127 MONUMENT ROAD, SHOP 17 ERF 3142, THE GAVENDISH GLEN EXT 25, KEMPTON PARK, South Africa','geo':{'lat':-26.08364,'lng':28.24020}},
{'stp':700638,'address':'700638, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY LAKEFIELD, ERF NO 572 AND 573, LAKEFIELD SUARE SHOP NO 18 LAKEFIELD, AVENUE LAKEFIELD AVENUE LAKEFIELD EXT 26, BENONI, South Africa','geo':{'lat':-26.17810,'lng':28.28747}},
{'stp':701036,'address':'701036, EGL - ISANDO, ISANDO HIGH END TEAM, OVERLAND LIQUOR STORE, SHOP NO 9A, K90 SHOPPING CENTRE CORNER OF NORTH, RONDEBULT ROAD  RAND ROAD AND, BOKSBURG, South Africa','geo':{'lat':-26.18010,'lng':28.24510}},
{'stp':728185,'address':'728185, EGL - ISANDO, ISANDO HIGH END TEAM, KLIPRAND GENERAL DEALER, PORTION 53, ELANDFONTEIN FARM, #, KEMPTON PARK, South Africa','geo':{'lat':-26.01920,'lng':28.31020}},
{'stp':741051,'address':'741051, EGL - ISANDO, ISANDO HIGH END TEAM, MANN MADE MEDIA, 34 BOWLING AVE, WOODMEAD, JHB, FOURWAYS, South Africa','geo':{'lat':-26.12910,'lng':28.16000}},
{'stp':744798,'address':'744798, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT BREDELL, 169 BREDELL AGRICULTURAL HOLDING, PORTION 464 OF FARM RIETFONTEIN 31 JR, SHOP 8 BREDELL SQUARE, KEMPTON PARK, South Africa','geo':{'lat':-26.08650,'lng':28.29360}},
{'stp':749344,'address':'749344, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY FAMILY GF88  BRENTWOOD P, 2 PORTION 169 SHOP NO, REMAINDER OF PORTION 46, FARM RIETFONTEIN 31 IR, KEMPTON PARK, South Africa','geo':{'lat':-26.11859,'lng':28.28014}},
{'stp':751154,'address':'751154, EGL - ISANDO, ISANDO HIGH END TEAM, RAILWAY ARMS PUB & GRILL (NTO), ERF NO:7858, J M I HOUSE, UNIT NO:25,RAILWAY STR WESTERN EXTENSION, BENONI, BENONI, South Africa','geo':{'lat':-26.18670,'lng':28.30624}},
{'stp':751519,'address':'751519, EGL - ISANDO, ISANDO HIGH END TEAM, JAY JAY LIQUOR STORE, STAND NO, SHOP 2 VENTER CENTRE, OLD PRETORIA RD BAPSFONTEIN, KEMPTON PARK, South Africa','geo':{'lat':-26.00375,'lng':28.41340}},
{'stp':756726,'address':'756726, EGL - ISANDO, ISANDO HIGH END TEAM, PNP FAMILY FARRARMERE - GF11, 6416 STAND NO, SHOP 16 FARRARMERE SHOPPING CENTRE NO 20, SHERIDAN ROAD CORNER OF BAILEY, BENONI, South Africa','geo':{'lat':-26.16190,'lng':28.29560}},
{'stp':766141,'address':'766141, EGL - ISANDO, ISANDO HIGH END TEAM, GAME LIQUOR BOKSBURG, STAND NO, NORTH RAND ROAD, KLIPFONTEIN 82 IR SHOP 2, BOKSBURG, South Africa','geo':{'lat':-26.18010,'lng':28.24080}},
{'stp':768149,'address':'768149, EGL - ISANDO, ISANDO HIGH END TEAM, SALSA RESTAURANT, STAND NO, PORTION 4 OF ERF 2700, CORNER CASUARINA AND LONG STREETS, KEMPTON PARK, South Africa','geo':{'lat':-26.10540,'lng':28.23440}},
{'stp':774627,'address':'774627, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY  BENONI, 106 STAND NO, TOM JONES STREET FARRAMERE BENONI, #, BENONI, South Africa','geo':{'lat':-26.16592,'lng':28.27546}},
{'stp':312242,'address':'312242, EGL - ISANDO, ISANDO HIGH END TEAM, OVERLAND LIQUORS EDENVALE, 88 ERF, SHOP 28E, 28 VAN RIEBEECK AVENUE, EDENVALE, South Africa','geo':{'lat':-26.14726,'lng':28.15584}},
{'stp':363378,'address':'363378, EGL - ISANDO, ISANDO HIGH END TEAM, ELIQUOR PRIMROSE MALL, 86 STAND NO, RIETFONTEIN ROADPRIMROSE MALL, ERF NO 780, PRIMROSE, South Africa','geo':{'lat':-26.19105,'lng':28.15564}},
{'stp':388703,'address':'388703, EGL - ISANDO, ISANDO HIGH END TEAM, FUEL UP LIQUORS, 397 ERF NO, MARTIZA HEIGHTS NO 01 HOWE CIRCLE STREET, #, PRIMROSE, South Africa','geo':{'lat':-26.17176,'lng':28.17632}},
{'stp':390765,'address':'390765, EGL - ISANDO, ISANDO HIGH END TEAM, 4 SHOT LIQUORS, 2842 ERF NO, SHOP NO 5, KEMPTON SQUARE NO 11 CENTRAL AVENUE, KEMPTON PARK, South Africa','geo':{'lat':-26.10763,'lng':28.22949}},
{'stp':391201,'address':'391201, EGL - ISANDO, ISANDO HIGH END TEAM, SA TRADING  PTY LTD CALL N COLLECT, MARTIN, HAMMERSCHLAG WAY, CULEMBORG WAY, BENONI, South Africa','geo':{'lat':-26.13536,'lng':28.29433}},
{'stp':391555,'address':'391555, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS LIQUORSHOP FLAMINGO 92570, 18 SHOP NO, LAKESIDE SHOPPING CENTRE,8 QUEENS ROAD, CNR JOHANNESBURG ROAD, LAKESIDE, EDENVALE, South Africa','geo':{'lat':-26.10027,'lng':28.14960}},
{'stp':391750,'address':'391750, EGL - ISANDO, ISANDO HIGH END TEAM, SPARTAN WAREHOUSE AND DISTRIBUTION, UNIT 3 FOREMAN ROAD, #, #, KEMPTON PARK, South Africa','geo':{'lat':-26.13735,'lng':28.20249}},
{'stp':391991,'address':'391991, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY MAYIBUYE, 2 STAND NO, MAYIBUYE CENTRE, CNR MASTIFF N MODDERFONTEIN RD, TEMBISA, South Africa','geo':{'lat':-26.03447,'lng':28.17626}},
{'stp':392387,'address':'392387, EGL - ISANDO, ISANDO HIGH END TEAM, OVERLAND DISTRIBUTION DEPOT ISANDO, 671 ERF, UNIT F, G AND H, ISANDO BUSINESS UNITS 36 AND 38 MONTEER, ISANDO, South Africa','geo':{'lat':-26.13558,'lng':28.20696}},
{'stp':393129,'address':'393129, EGL - ISANDO, ISANDO HIGH END TEAM, MJ AND SONS LIQUOR STORE, 41 STAND NO, VAN RIEBEECK AVENUE, SHOP NI 14 AND 15 EDEN MALL, EDENVALE, South Africa','geo':{'lat':-26.14342,'lng':28.15453}},
{'stp':393426,'address':'393426, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS LIQUORSHOP GREENSTONE MALL, 1 ERF NO, NO. 10 STONERIDGE DRIVE, SHOP U115, GREENSTONE STONERIDGE DRIVE, EDENVALE, South Africa','geo':{'lat':-26.13478,'lng':28.15014}},
{'stp':481195,'address':'481195, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR AND WINE SUPERSTORE, STAND NO, PORTION 5 OF ERF 2727, SHOP 4 ETERNA, BUILDING, KEMPTON PARK, South Africa','geo':{'lat':-26.10794,'lng':28.23040}},
{'stp':484476,'address':'484476, EGL - ISANDO, ISANDO HIGH END TEAM, DE BOTTLE SHOP LIQUOR LAND, SHOP NO 2 EDEN CENTRE, 60 VAN RIEBEECK AVENUE, EDENVALE  PORTION 3 OF ERF 79, EDENVALE, South Africa','geo':{'lat':-26.14290,'lng':28.15359}},
{'stp':544488,'address':'544488, EGL - ISANDO, ISANDO HIGH END TEAM, NEW ARRIVAL LIQUOR STORE, STAND NO, ERF 2571 SHOP NO 2, 39 PRETORIA ROAD, KEMPTON PARK, South Africa','geo':{'lat':-26.10690,'lng':28.22790}},
{'stp':550047,'address':'550047, EGL - ISANDO, ISANDO HIGH END TEAM, NELSONS LIQUOR CELLARS, NELSONS LIQUOR CELLARS, 36 KRAFT AVENUE, ELANDSFONTEIN, ISANDO, South Africa','geo':{'lat':-26.16770,'lng':28.20400}},
{'stp':550108,'address':'550108, EGL - ISANDO, ISANDO HIGH END TEAM, ELANDSFONTEIN TAVERN, 41 RIETFONTEIN 63 I R, 4 AMBER ROAD, ELANDSFONTEIN, ISANDO, South Africa','geo':{'lat':-26.16610,'lng':28.20390}},
{'stp':550228,'address':'550228, EGL - ISANDO, ISANDO HIGH END TEAM, SPARTAN REST AND TAKE AWAYS, SPARTAN SHOPPING CENTRE, 19 NEWTON STREET, SPARTAN, KEMPTON PARK, South Africa','geo':{'lat':-26.11690,'lng':28.21520}},
{'stp':550915,'address':'550915, EGL - ISANDO, ISANDO HIGH END TEAM, MARCELLOS GRILL, 330 STAND  330, NO 1 PLANTATION ROAD EASTLEIGH, #, EDENVALE, South Africa','geo':{'lat':-26.13560,'lng':28.16080}},
{'stp':551004,'address':'551004, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS EDENVALE 54619, SHOP 19 O K HYPERAMA, EDENVALE RD GERMISTON, VAT REG 4420106777, ISANDO, South Africa','geo':{'lat':-26.16265,'lng':28.17128}},
{'stp':551290,'address':'551290, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY DELMONT, SHOP 120, HIGHWAY MANSIONS 3, BARBARA ROAD, RUSTIVIA, PRIMROSE, South Africa','geo':{'lat':-26.17370,'lng':28.19080}},
{'stp':551524,'address':'551524, EGL - ISANDO, ISANDO HIGH END TEAM, SPARTAN LIQUOR WHOLESALE, 8 NEWTON AVENUE, SPARTAN KEMPTON PARK, 1620, KEMPTON PARK, South Africa','geo':{'lat':-26.11380,'lng':28.21730}},
{'stp':551562,'address':'551562, EGL - ISANDO, ISANDO HIGH END TEAM, HAPPY BITE EXPRESS BAR, ERF 2576, 3 CENTRAL AVENUE, KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.10780,'lng':28.22890}},
{'stp':551602,'address':'551602, EGL - ISANDO, ISANDO HIGH END TEAM, SAPRO INTERNATIONAL, 23 POMONA ROAD, #, POMONA, KEMPTON PARK, South Africa','geo':{'lat':-26.09600,'lng':28.26260}},
{'stp':551655,'address':'551655, EGL - ISANDO, ISANDO HIGH END TEAM, JUMBO LIQUOR MARKET KEMPTON PARK, 55 & 51 PRETORIA RD, KEMPTON PARK, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10910,'lng':28.22900}},
{'stp':551826,'address':'551826, EGL - ISANDO, ISANDO HIGH END TEAM, LAPA INN, HOOFWEG 57 (Main Road), EASTLEIGH, 1610, EDENVALE, South Africa','geo':{'lat':-26.13370,'lng':28.16290}},
{'stp':551830,'address':'551830, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS AT BEDFORD VILLAGE, STAND NO, BEDFORD VILAGE SC, CNR NICOL AND VAN BUUREN RD, BEDFORDVIEW, South Africa','geo':{'lat':-26.18000,'lng':28.13578}},
{'stp':551895,'address':'551895, EGL - ISANDO, ISANDO HIGH END TEAM, NELSONS TAVERN AND TAKE AWAY, CNR ISANDO & MONTEER RDS, #, #, ISANDO, South Africa','geo':{'lat':-26.14460,'lng':28.20220}},
{'stp':551933,'address':'551933, EGL - ISANDO, ISANDO HIGH END TEAM, QUEENIES RESTAURANT AND POOL BAR, 25 CENTRAL AVE, EASTLEIGH, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13070,'lng':28.15970}},
{'stp':552137,'address':'552137, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY KARAGLEN, SHOP 4 KARAGLEN CENTRE, CNR HARRIS AVE & BAKER RD, EDENGLEN EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13510,'lng':28.18020}},
{'stp':552164,'address':'552164, EGL - ISANDO, ISANDO HIGH END TEAM, KLOPPER PARK LIQUOR, 03 ERF NO, PORTION OF ERF 20 LENTE ROAD, KLOPPER PARK, ISANDO, South Africa','geo':{'lat':-26.14640,'lng':28.19640}},
{'stp':552736,'address':'552736, EGL - ISANDO, ISANDO HIGH END TEAM, OASIS LIQUOR STORE, ERF 959 SHOP2, EXT1, CNR KELVIN + HOUSEHOLD STREETS, RHODESFIELD CENTRE, KEMPTON PA, KEMPTON PARK, South Africa','geo':{'lat':-26.12110,'lng':28.22030}},
{'stp':552772,'address':'552772, EGL - ISANDO, ISANDO HIGH END TEAM, JO LO LIQUOR EXPRESS, SHOP NO 1 & 2 GLENCURVE SHOPPING CE, CNR ELM & BEECH STREET, DOWERGLEN, EDENVALE, South Africa','geo':{'lat':-26.13880,'lng':28.13770}},
{'stp':552865,'address':'552865, EGL - ISANDO, ISANDO HIGH END TEAM, AMAGENTS RESTAURANT, 40 KRAFT ROAD, ELANDSFONTEIN, #, ISANDO, South Africa','geo':{'lat':-26.16720,'lng':28.20420}},
{'stp':553150,'address':'553150, EGL - ISANDO, ISANDO HIGH END TEAM, VALUE LIQUOR CELLARS, 127 RIETFONTEIN ROAD, PRIMROSE, #, PRIMROSE, South Africa','geo':{'lat':-26.18846,'lng':28.16138}},
{'stp':553282,'address':'553282, EGL - ISANDO, ISANDO HIGH END TEAM, 10TH STREET LIQUORS, 0 136 VAN RIEBEECK STREET, PORTION 1 OF ERF 640, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.13222,'lng':28.14798}},
{'stp':553296,'address':'553296, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR LEGENDS TEMBISA, SHOP 11 MAYIBUYE SHOPPING CNT, CNR MASTIFF RD & K56 RD, COMMERCIAL EXT 9 ERVEN 3, TEMBISA, South Africa','geo':{'lat':-26.03484,'lng':28.17469}},
{'stp':553464,'address':'553464, EGL - ISANDO, ISANDO HIGH END TEAM, EASTLEIGH BOTTLE STORE, 29 CENTRAL AVE, EASTLEIGH, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.12970,'lng':28.16070}},
{'stp':553487,'address':'553487, EGL - ISANDO, ISANDO HIGH END TEAM, TOMMY LIQUORS, STAND 529 SHOP 2, LINKSFIELD RD DUNVEGAN, EDENVALE, EDENVALE, South Africa','geo':{'lat':-26.15050,'lng':28.15070}},
{'stp':553622,'address':'553622, EGL - ISANDO, ISANDO HIGH END TEAM, FRANKEN LIQUOR STORE, SHOP 2 BUSINESS BUILDING AT 9, ADDY ROAD ERF 467, CRESSLAWN KEMPTON PARK, KEMPTON PARK, South Africa','geo':{'lat':-26.11220,'lng':28.20380}},
{'stp':561201,'address':'561201, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS PRIMROSE, 141-143 RIETFONTEIN ROAD, CRN VIOLET STREET, ERF 1249,1257,1258, PRIMROSE, South Africa','geo':{'lat':-26.18770,'lng':28.16330}},
{'stp':606834,'address':'606834, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR BOUTIQUE, SHOP G8  EDEN TERRACE SHOP AND, LEISURE CENTRE  CNR TERRACE AND, #, EDENVALE, South Africa','geo':{'lat':-26.12510,'lng':28.16660}},
{'stp':636554,'address':'636554, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY GREENSTONE, SHOP U52 GREENSTONE CENTRE, CNR MODDERFONTEIN RD &, VAN RIEBEECK AVE, EDENVALE, South Africa','geo':{'lat':-26.13478,'lng':28.15014}},
{'stp':639364,'address':'639364, EGL - ISANDO, ISANDO HIGH END TEAM, EDENGLEN LIQUORS, 52 HARRIS STREET, SHOP 7, EDENGLEN CENTRE, EDENVALE, South Africa','geo':{'lat':-26.13039,'lng':28.17773}},
{'stp':640137,'address':'640137, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY PRIMROSE TJ LIQ STORE, 25 SHAMROCK STREET, PRIMROSE, #, PRIMROSE, South Africa','geo':{'lat':-26.18830,'lng':28.15930}},
{'stp':641567,'address':'641567, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY VILLAGE VIEW  GC91, 19 SHOP, VILLAGE VIEW SHOPPING CENTRE, CNR VAN BUUREN & WOOL RDS, BEDFORDVIEW, South Africa','geo':{'lat':-26.18360,'lng':28.13577}},
{'stp':646554,'address':'646554, EGL - ISANDO, ISANDO HIGH END TEAM, RED LINE LIQUOR STORE, 91 ERF 91, PORTION 10 CNR VAN RIEBEECK & HORWOOD ST, EDENGATE CORNER PORTION 10, EDENVALE, South Africa','geo':{'lat':-26.15010,'lng':28.15750}},
{'stp':647461,'address':'647461, EGL - ISANDO, ISANDO HIGH END TEAM, PRIMOSE LIQUORS, 201 RIETFONTEIN ROAD, #, #, PRIMROSE, South Africa','geo':{'lat':-26.18570,'lng':28.16970}},
{'stp':647580,'address':'647580, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY PRIMROSE, SHOP11 PRIMROSE SQUARE S/CENTRE, CNR SHAMROCK & CHURCHILL STREETS, PRIMROSE, PRIMROSE, South Africa','geo':{'lat':-26.18517,'lng':28.15850}},
{'stp':652160,'address':'652160, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY STONERIDGE, SHOP 74, STONERIDGE SHOPPING CENTRE, CNR HEREFORD & BLACKROACK ROADS, ERF 02, EXT 01, EDENVALE, South Africa','geo':{'lat':-26.11860,'lng':28.14570}},
{'stp':653774,'address':'653774, EGL - ISANDO, ISANDO HIGH END TEAM, SG CONVENIENCE KYASANDS, 308 ERF, CNR BARBARA AND BROLLO RDS, TUNNEY EXT 3, ISANDO, South Africa','geo':{'lat':-26.15407,'lng':28.20165}},
{'stp':658517,'address':'658517, EGL - ISANDO, ISANDO HIGH END TEAM, LIMPOPO DUTY FREE, STAND NO 9, #, MUSINA TOWNSHIP, BENONI, South Africa','geo':{'lat':-26.13536,'lng':28.29433}},
{'stp':663033,'address':'663033, EGL - ISANDO, ISANDO HIGH END TEAM, JC LIQUOR MARKET, 0 CNR KRAFT AND AMBER STREET, #, #, ISANDO, South Africa','geo':{'lat':-26.16630,'lng':28.20440}},
{'stp':671943,'address':'671943, EGL - ISANDO, ISANDO HIGH END TEAM, TOPS KLOPPERPARK, 0 CNR BARBARA AND LENTE ROADS, SUNSHINE SHOPPING COMPLEX ERF 622, KLOPPERPARK, ISANDO, South Africa','geo':{'lat':-26.14430,'lng':28.19780}},
{'stp':673047,'address':'673047, EGL - ISANDO, ISANDO HIGH END TEAM, TOP LIQUORS DOWERGLEN, STAND NO 676, SHOP 4 DOWERGLEN PLAZA 73, SYCAMORE DR CNR ELM ST DPWERGLEN EXT 3, EDENVALE, South Africa','geo':{'lat':-26.14450,'lng':28.13830}},
{'stp':680703,'address':'680703, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS GREENSTONE 58100, 0 VAN RIEBEECK STREET, SHOP 10 GREENSTONE HILL SHOPPING CENTRE, GREENSTONE HILL EXT 24, EDENVALE, South Africa','geo':{'lat':-26.12170,'lng':28.14340}},
{'stp':687479,'address':'687479, EGL - ISANDO, ISANDO HIGH END TEAM, TERENCE S PLACE, SHOP NO 40 41, VAN RIEBEECK AVENUE, #, EDENVALE, South Africa','geo':{'lat':-26.14446,'lng':28.15497}},
{'stp':687585,'address':'687585, EGL - ISANDO, ISANDO HIGH END TEAM, GLEN LIQUOR STORE, 114 PARTRIDGE AVENUE, SHOP 07 ALLEN GLEN SHOPPING CENTRE, ALLEN GROVE EXT 04, KEMPTON PARK, South Africa','geo':{'lat':-26.08230,'lng':28.23290}},
{'stp':687856,'address':'687856, EGL - ISANDO, ISANDO HIGH END TEAM, GLENVALE LIQUORZONE, ERF NO 1525, GLENVALE SHOPPING CENTRE, NO 15 HARRIS STREET EDENGLEN 47, EDENVALE, South Africa','geo':{'lat':-26.13480,'lng':28.18150}},
{'stp':689041,'address':'689041, EGL - ISANDO, ISANDO HIGH END TEAM, SEVEN ELEVEN (NTO), CNR BARBARA & BRABAZON ROADS, CNR BARBARA & BRABAZON ROAD, ISANDO, ISANDO, South Africa','geo':{'lat':-26.13592,'lng':28.19422}},
{'stp':695523,'address':'695523, EGL - ISANDO, ISANDO HIGH END TEAM, GREAT LIQUOR NO 5, SHOP NO 05 ERF 906, #, #, ISANDO, South Africa','geo':{'lat':-26.13130,'lng':28.20020}},
{'stp':699132,'address':'699132, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY EDENVALE, VANRIEBEECK MALL, CNR 3RD STR AND RIEBEECK AVE, #, EDENVALE, South Africa','geo':{'lat':-26.13778,'lng':28.15901}},
{'stp':704619,'address':'704619, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR EXPRESS, NUMBER 3, CENTRAL AVENUE, #, KEMPTON PARK, South Africa','geo':{'lat':-26.10760,'lng':28.22920}},
{'stp':708890,'address':'708890, EGL - ISANDO, ISANDO HIGH END TEAM, TEMBISA TOPS, SHOP 57 TEMBISA MALL, TEMBISA MALL CORNER ISIMUKU STREET, ANDREW MAPHETO DRIVE BIRCH ACRES EXT 36, TEMBISA, South Africa','geo':{'lat':-26.03940,'lng':28.19230}},
{'stp':716401,'address':'716401, EGL - ISANDO, ISANDO HIGH END TEAM, SPAR SOUTH RAND ICC SSBU, 401 WAREHOUSE NO, THE FARM DRIEFONTEIN 85, #, ISANDO, South Africa','geo':{'lat':-26.17650,'lng':28.21760}},
{'stp':726057,'address':'726057, EGL - ISANDO, ISANDO HIGH END TEAM, KOPIKO PRODUCTS, PORTION 124, FARM MODDERFONTEIN, BEING ERF 45,LIMBRO PARK X38, EDENVALE, South Africa','geo':{'lat':-26.08310,'lng':28.11880}},
{'stp':749334,'address':'749334, EGL - ISANDO, ISANDO HIGH END TEAM, ARUM ANIKE LIQUOR, 5 SHOP NO, OAK AVE, OFF WEST STREET, KEMPTON PARK, South Africa','geo':{'lat':-26.10860,'lng':28.23030}},
{'stp':756239,'address':'756239, EGL - ISANDO, ISANDO HIGH END TEAM, AIRPORT BOTTLE STORE, 54 WEST STREET, SHOP NO 7, PORTION 49 OF ERF 2772, KEMPTON PARK, South Africa','geo':{'lat':-26.11110,'lng':28.23130}},
{'stp':768049,'address':'768049, EGL - ISANDO, ISANDO HIGH END TEAM, TWO SISTERS PUB, STAND NO, ERF 619 SHOP NO 5, NO 105 VAN RIEBEECK AVENUE, EDENVALE, South Africa','geo':{'lat':-26.13633,'lng':28.15067}},
{'stp':769223,'address':'769223, EGL - ISANDO, ISANDO HIGH END TEAM, BAILEY LIQUOR STORE, STAND NO, ERVEN 1050 AND 1051 SHOP 15, EDENMEADOWS SHOPPING CENTRE, EDENVALE, South Africa','geo':{'lat':-26.11855,'lng':28.14587}},
{'stp':774476,'address':'774476, EGL - ISANDO, ISANDO HIGH END TEAM, GAME LIQUORMART GREENSTONE 235, 1 ERF, SHOP L009 GREENSTONE SHOPPING CENTRE, CORNER MODDERFONTEIN ROAD AND, EDENVALE, South Africa','geo':{'lat':-26.11683,'lng':28.14310}},
{'stp':778037,'address':'778037, EGL - ISANDO, ISANDO HIGH END TEAM, HAPPY S EATING HOUSE, 45 STAND NO, VAN RIEBEECK AVENUE, #, EDENVALE, South Africa','geo':{'lat':-26.14410,'lng':28.15530}},
{'stp':781310,'address':'781310, EGL - ISANDO, ISANDO HIGH END TEAM, CHECKERS LIQOURSHOP GREEVALLEY 8029, STAND NO, ERVEN 758 AND 759 CORNER GREEN STONE 1, AND STONERIDGE DRIVES GREENSTONE HILL, EDENVALE, South Africa','geo':{'lat':-26.11730,'lng':28.15677}},
{'stp':784030,'address':'784030, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY KEY WEST, 43 VAN BUUREN STREET, PORTION 1037 OF FARM ELANDSFONTEIN 90 IR, SHOP G02 WEST SHOPPING CENTRE, BEDFORDVIEW, South Africa','geo':{'lat':-26.17996,'lng':28.13548}},
{'stp':786788,'address':'786788, EGL - ISANDO, ISANDO HIGH END TEAM, CAMBRIDGE  KEMPSTAR, STAND NO, KEMPSTAR MALL, PRETORIA STREET, KEMPTON PARK, South Africa','geo':{'lat':-26.10748,'lng':28.22786}},
{'stp':790981,'address':'790981, EGL - ISANDO, ISANDO HIGH END TEAM, PICK N PAY FAMILY GF23 KEMPTON SQUA, STAND NO, PORTION 1 OF ERF 2866, KEMTON SQUARE CORNER MONUMENT, KEMPTON PARK, South Africa','geo':{'lat':-26.10647,'lng':28.23068}},
{'stp':766889,'address':'766889, EGL - ISANDO, ISANDO HIGH END TEAM, LIQUOR CITY BENONI (NTO), 106 TOM JONES ROAD, BENONI, BENONI, BENONI, South Africa','geo':{'lat':-26.18005,'lng':28.31142}},
{'stp':551378,'address':'551378, EGL - ISANDO, ISANDO EAST MAIN, J AND G LIQUOR COUNTRY, 21 WALKER STREET, RENSBURG, HEIDELBERG, HEIDELBURG (TVL), South Africa','geo':{'lat':-26.41843,'lng':28.34744}},
{'stp':387961,'address':'387961, EGL - ISANDO, ISANDO EAST MAIN, COSSAS PLACE, 10 STAND NO, LUVYO STREET, MAPLETON EXT 10, BOKSBURG, South Africa','geo':{'lat':-26.31911,'lng':28.21134}},
{'stp':482668,'address':'482668, EGL - ISANDO, ISANDO EAST MAIN, DUMIES LODGE, 5126 STAND NO ROODEKOP EXT 22, ROODEKOP EXT 22, PHUMULA, GERMISTON, South Africa','geo':{'lat':-26.31343,'lng':28.19461}},
{'stp':540464,'address':'540464, EGL - ISANDO, ISANDO EAST MAIN, THENDO BUY AND BRAAI TAVERN, 505 NDIPHE STREET, ROODEKOP RONDEBUILT, #, VOSLOORUS, South Africa','geo':{'lat':-26.32172,'lng':28.20008}},
{'stp':544414,'address':'544414, EGL - ISANDO, ISANDO EAST MAIN, VUKUZENZELE TAVERN, 2051 RONDEBUILT, EXT 2, #, SPRUITVIEW, South Africa','geo':{'lat':-26.31138,'lng':28.21159}},
{'stp':550320,'address':'550320, EGL - ISANDO, ISANDO EAST MAIN, DALASOL LIQUOR STORE, STAND NO, ERF 3043 LEONDALE HYPER/DALASOL BUILDING, 20 BLAUBOK AVENUE, SPRUITVIEW, South Africa','geo':{'lat':-26.30300,'lng':28.18020}},
{'stp':560302,'address':'560302, EGL - ISANDO, ISANDO EAST MAIN, SELBYS TAVERN PERMIT, 298 CESTRUM CRESCENT, PHUMULA EXT 10, LEONDALE, DAWN PARK, South Africa','geo':{'lat':-26.32390,'lng':28.20950}},
{'stp':637354,'address':'637354, EGL - ISANDO, ISANDO EAST MAIN, YARD OF ALE, 5 GATWICK PARK, RACEVIEW, ALBERTON, ALBERTON, South Africa','geo':{'lat':-26.26409,'lng':28.15363}},
{'stp':638290,'address':'638290, EGL - ISANDO, ISANDO EAST MAIN, BONGINKOSI\'S PLACE, 346 NCALA SECTION, GERMISTON, GERMISTON, KATLEHONG, South Africa','geo':{'lat':-26.32520,'lng':28.20960}},
{'stp':653217,'address':'653217, EGL - ISANDO, ISANDO EAST MAIN, ALFIS TAVERN, 325 BUHLE PARK, PHASE 4, GERMISTON, SPRUITVIEW, South Africa','geo':{'lat':-26.29400,'lng':28.19320}},
{'stp':653235,'address':'653235, EGL - ISANDO, ISANDO EAST MAIN, SUTHANI OFF SALES, 13476 LAPOLOGA STREET, SPRUITVIEW, SPRUITVIEW, VOSLOORUS, South Africa','geo':{'lat':-26.32711,'lng':28.18481}},
{'stp':667554,'address':'667554, EGL - ISANDO, ISANDO EAST MAIN, T K S INN, 501 ALMOND STREET, MAPELTON EXTENSION 10, MAPELTON, BOKSBURG, South Africa','geo':{'lat':-26.32617,'lng':28.20633}},
{'stp':671250,'address':'671250, EGL - ISANDO, ISANDO EAST MAIN, DAWNS TAVERN, 1554 LUTHANDO STREET, EXTENSION 10, MAPLETON, BOKSBURG, South Africa','geo':{'lat':-26.32513,'lng':28.20667}},
{'stp':765128,'address':'765128, EGL - ISANDO, ISANDO EAST MAIN, OFENTSE PHOLWANA TAVERN, 937 MULBBERY STREET, MAPLETON EXTENSION 10, #, VOSLOORUS, South Africa','geo':{'lat':-26.32190,'lng':28.20220}},
{'stp':765337,'address':'765337, EGL - ISANDO, ISANDO EAST MAIN, TIVA TA WENA, 1272 STAND NO 1272, CORAL BERG, TREE MAPLETON, BOKSBURG, South Africa','geo':{'lat':-26.32488,'lng':28.20227}},
{'stp':772657,'address':'772657, EGL - ISANDO, ISANDO EAST MAIN, LUKHELES INN, 40 STAND NO, BOUNDRY ROAD, MAPLETON VOSLORUS, VOSLOORUS, South Africa','geo':{'lat':-26.34576,'lng':28.24660}},
{'stp':794965,'address':'794965, EGL - ISANDO, ISANDO EAST MAIN, PHUMULA SHISANYAMA AND CAR WASH, STAND NO, ERF 5569 EXTENSION 22 ROODEKOP, PHUMULA GARDEN, SPRUITVIEW, South Africa','geo':{'lat':-26.31687,'lng':28.19565}},
{'stp':381559,'address':'381559, EGL - ISANDO, ISANDO EAST MAIN, WHISKY AND BRANDY MOVERS LOUNGE, 1874 ERF, BLUEGUM VIEW, #, DUDUZA, South Africa','geo':{'lat':-26.38152,'lng':28.38978}},
{'stp':468863,'address':'468863, EGL - ISANDO, ISANDO EAST MAIN, PHOKENG TAVERN, 1 MASECHABA VIEW, EXTENSION 3, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39517,'lng':28.39593}},
{'stp':473647,'address':'473647, EGL - ISANDO, ISANDO EAST MAIN, MDASHS TAVERN, 3882 STAND NO, MALOKA STREET ERF, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37746,'lng':28.40958}},
{'stp':476131,'address':'476131, EGL - ISANDO, ISANDO EAST MAIN, THE ROCK, 0683 STAND NO, HAWK STREET, BLUEGUMVIEW EXT 1 DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38478,'lng':28.40377}},
{'stp':484929,'address':'484929, EGL - ISANDO, ISANDO EAST MAIN, QOQA TAVERNEKU, 382 ERF NO, COOL DE BREEZ, MASECHABA VIEW, NIGEL, South Africa','geo':{'lat':-26.39154,'lng':28.40435}},
{'stp':488500,'address':'488500, EGL - ISANDO, ISANDO EAST MAIN, KHAYALAMI LOUNGE, 3147 STAND NO, PITSI STREET, #, NIGEL, South Africa','geo':{'lat':-26.37547,'lng':28.41279}},
{'stp':550939,'address':'550939, EGL - ISANDO, ISANDO EAST MAIN, RASEKGANE LS ACCU45658, CNR NDUDULA & MASHININI STREETS, MASHININI, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37000,'lng':28.40590}},
{'stp':551824,'address':'551824, EGL - ISANDO, ISANDO EAST MAIN, EASY INN, 1014 KHUMALO STREET, DUDUZA TOWNSHIP, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37040,'lng':28.40600}},
{'stp':552550,'address':'552550, EGL - ISANDO, ISANDO EAST MAIN, MONTANA INN, STAND 4618, ZAMANI, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38590,'lng':28.41720}},
{'stp':552731,'address':'552731, EGL - ISANDO, ISANDO EAST MAIN, NQABE TAVERN, STAND 4497, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39450,'lng':28.38350}},
{'stp':553794,'address':'553794, EGL - ISANDO, ISANDO EAST MAIN, MXO TAVERN PERMIT, 116 COOLBREEZE STREET, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39270,'lng':28.40170}},
{'stp':581060,'address':'581060, EGL - ISANDO, ISANDO EAST MAIN, SPRUIT INN, 3296 MASECHABA VIEW, EXT 3, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39480,'lng':28.40080}},
{'stp':581110,'address':'581110, EGL - ISANDO, ISANDO EAST MAIN, NEW YORK CITY SPORTS BAR, 1615 KOTANE STREET, BLUE GUM VIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37963,'lng':28.39250}},
{'stp':581315,'address':'581315, EGL - ISANDO, ISANDO EAST MAIN, ERICS TAVERN, ERF 5100 USUTHU STREET, ALRA PARK, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.44450,'lng':28.50790}},
{'stp':602855,'address':'602855, EGL - ISANDO, ISANDO EAST MAIN, SIMUNYES TAVERN, 87 KREEF AVENUE, ALRAPARK, ALRAPARK, ALRA PARK, South Africa','geo':{'lat':-26.44060,'lng':28.51330}},
{'stp':603114,'address':'603114, EGL - ISANDO, ISANDO EAST MAIN, ELLIOT MFUMANEKI BHEKEBU, 1656 GAZEL DRIVE, ALRA PARK, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.44430,'lng':28.50570}},
{'stp':603608,'address':'603608, EGL - ISANDO, ISANDO EAST MAIN, S D C TAVERN, 1696 MADIBA STREET, BLUEGUM VIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38070,'lng':28.39430}},
{'stp':626140,'address':'626140, EGL - ISANDO, ISANDO EAST MAIN, CHINKYS PLACE PERMIT, 8255 SITHOLE STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.36680,'lng':28.41090}},
{'stp':626684,'address':'626684, EGL - ISANDO, ISANDO EAST MAIN, NELLIES TAVERN PERMIT, 4843 STAND NO, EXT 06 BLUEGUMVIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38850,'lng':28.41080}},
{'stp':628387,'address':'628387, EGL - ISANDO, ISANDO EAST MAIN, NTUTHUS PLACE PERMIT, 8216 SELEPE STREET, ROSEVIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.36570,'lng':28.41090}},
{'stp':629745,'address':'629745, EGL - ISANDO, ISANDO EAST MAIN, PLATEAU SPORTS BAR PERMIT, 872 NDUDULA STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37070,'lng':28.40750}},
{'stp':630085,'address':'630085, EGL - ISANDO, ISANDO EAST MAIN, MNCONDO INN PERMIT, 733 DLAMINI STREET, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38990,'lng':28.38520}},
{'stp':631841,'address':'631841, EGL - ISANDO, ISANDO EAST MAIN, BROTHER S TAVERN, ERF 8441 LEDIGA STREET, ROSEVIEW, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37400,'lng':28.41650}},
{'stp':635069,'address':'635069, EGL - ISANDO, ISANDO EAST MAIN, MZI S TAVERN, 5171 LETLATLA STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37290,'lng':28.39900}},
{'stp':636189,'address':'636189, EGL - ISANDO, ISANDO EAST MAIN, CHOKKYS INN, 4433 SUTHER STREET, ZAMANE SECTION, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38670,'lng':28.41410}},
{'stp':637364,'address':'637364, EGL - ISANDO, ISANDO EAST MAIN, BOOZERS TAVERN, 5 ZAMBESI STREET, ALRA PARK, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.44330,'lng':28.50960}},
{'stp':638283,'address':'638283, EGL - ISANDO, ISANDO EAST MAIN, PHOOKAS PLACE, 2254 TSHWENE STREET, DUDUZA, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.39120,'lng':28.39620}},
{'stp':638329,'address':'638329, EGL - ISANDO, ISANDO EAST MAIN, FRANCOS TAVERN, 4119 MATHEWS STREET, ZAMANI SECTION, BLUEGUMVIEW EXT5, DUDUZA, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.37830,'lng':28.41740}},
{'stp':641442,'address':'641442, EGL - ISANDO, ISANDO EAST MAIN, SISTERS TAVERN, 1383 R SOBUKWE MASECHABA, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.39020,'lng':28.39320}},
{'stp':642720,'address':'642720, EGL - ISANDO, ISANDO EAST MAIN, MN MASANGOS INN, 173 COOL BREEZE SECTION, DUDUZA, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.39160,'lng':28.40360}},
{'stp':646324,'address':'646324, EGL - ISANDO, ISANDO EAST MAIN, LESEDI LAUNGE, 4187 ALXANDER STREET, ZAMINI, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38120,'lng':28.41650}},
{'stp':646755,'address':'646755, EGL - ISANDO, ISANDO EAST MAIN, COOPER\'S PALACE, 5390 SIBEKO STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37230,'lng':28.40940}},
{'stp':648521,'address':'648521, EGL - ISANDO, ISANDO EAST MAIN, NOMSA\'S PLACE, 630 BLUE GUM-VIEW, DUDUZA EXT 1, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.37224,'lng':28.41113}},
{'stp':648529,'address':'648529, EGL - ISANDO, ISANDO EAST MAIN, MEISIES PLACE, 3582 MONNODI STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38130,'lng':28.40920}},
{'stp':648628,'address':'648628, EGL - ISANDO, ISANDO EAST MAIN, ANGIE S PLACE, 4562 SOBUKWE STREET, ZAMANI, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38880,'lng':28.41110}},
{'stp':648729,'address':'648729, EGL - ISANDO, ISANDO EAST MAIN, SPECIAL 99 TAVERN, 4285 B FISHER STREET, ZAMANI SECTION, BLUEGUM VIEW, DUDUZA, South Africa','geo':{'lat':-26.37950,'lng':28.41680}},
{'stp':648794,'address':'648794, EGL - ISANDO, ISANDO EAST MAIN, MOPE CAPTAIN MORGAN INN, 1503 LUTHULI STREET, MASECHABA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38870,'lng':28.39500}},
{'stp':649900,'address':'649900, EGL - ISANDO, ISANDO EAST MAIN, NOMSAS TAVERN, 4011  MASECHABA, DUDUZA EXTENSION 3, NIGEL, DUDUZA, South Africa','geo':{'lat':-26.39710,'lng':28.39470}},
{'stp':653203,'address':'653203, EGL - ISANDO, ISANDO EAST MAIN, NKOSIS TAVERN, 20 WILGE AVENUE, EXTENSION 2, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.44677,'lng':28.50729}},
{'stp':654523,'address':'654523, EGL - ISANDO, ISANDO EAST MAIN, RAISEDES PLACE, 3476 POOA STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37840,'lng':28.41390}},
{'stp':660116,'address':'660116, EGL - ISANDO, ISANDO EAST MAIN, ZETHEMBE TAVERN, 4259 ZAMANI STREET, DUDUZA, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.38150,'lng':28.41630}},
{'stp':667219,'address':'667219, EGL - ISANDO, ISANDO EAST MAIN, THABANG PUB, LEKOPE STREET, ERF 5516, DUDUZA, DUDUZA, South Africa','geo':{'lat':-26.37670,'lng':28.40930}},
{'stp':699373,'address':'699373, EGL - ISANDO, ISANDO EAST MAIN, TIMOTHY TAVERN, 5049 5049  MASECHABA, EXT 2, #, DUDUZA, South Africa','geo':{'lat':-26.39317,'lng':28.38781}},
{'stp':705486,'address':'705486, EGL - ISANDO, ISANDO EAST MAIN, MACKENZIEVILLE LIQUOR STORE, 24 BALFOUR ROAD, MACKENZIEVILLE, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.44010,'lng':28.51970}},
{'stp':747767,'address':'747767, EGL - ISANDO, ISANDO EAST MAIN, FUNXION JUNXION, ERF 2/889 GAZELLE DRIVE,  ALRA PARK, NIGEL, ALRA PARK, South Africa','geo':{'lat':-26.43829,'lng':28.51381}},
{'stp':753807,'address':'753807, EGL - ISANDO, ISANDO EAST MAIN, TISHAS RESTAURANT, 134 BALFOUR ROAD, SHOP 1, #, NIGEL, South Africa','geo':{'lat':-26.44010,'lng':28.51970}},
{'stp':776023,'address':'776023, EGL - ISANDO, ISANDO EAST MAIN, MADOLIES TAVERN, LETSAPA STREET, ERF 3549 LETSAPA STREET, #, DUDUZA, South Africa','geo':{'lat':-26.38010,'lng':28.41040}},
{'stp':781864,'address':'781864, EGL - ISANDO, ISANDO EAST MAIN, BIG FIVE, 15 LEMON STREET, ERF 503, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.43422,'lng':28.51105}},
{'stp':308540,'address':'308540, EGL - ISANDO, ISANDO EAST HYBRID, MAVIS TAVERN, 7 STAND NO, MARULA AVENUE ESKOM ROAD, #, BALFOUR, South Africa','geo':{'lat':-26.76093,'lng':28.48239}},
{'stp':390720,'address':'390720, EGL - ISANDO, ISANDO EAST HYBRID, MPO S LOVE CORNER TAVERN, 89 LETSOKO STREET, #, #, BALFOUR, South Africa','geo':{'lat':-26.64930,'lng':28.61170}},
{'stp':550682,'address':'550682, EGL - ISANDO, ISANDO EAST HYBRID, PORTO RICO LIQUOR STORE, ERF 1067 NKUAGAE STREET, SIYATHEMBA, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.65260,'lng':28.60870}},
{'stp':551966,'address':'551966, EGL - ISANDO, ISANDO EAST HYBRID, ENJOY S TAVERN, ERF 29 EXT 1, GROOTVLEI, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.79400,'lng':28.50620}},
{'stp':552348,'address':'552348, EGL - ISANDO, ISANDO EAST HYBRID, HOLLYWOOD BOTTLE STORE, ERF 1225 SIYATHEMBA STREET, BALFOUR, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.65430,'lng':28.61280}},
{'stp':552606,'address':'552606, EGL - ISANDO, ISANDO EAST HYBRID, SS DRANKWINKEL, 627 ZWANE STREET, SIYATHEMBA, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.64923,'lng':28.61192}},
{'stp':553336,'address':'553336, EGL - ISANDO, ISANDO EAST HYBRID, LIQUOR KING, 20 STUART STRAAT, BALFOUR, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.66030,'lng':28.58820}},
{'stp':607470,'address':'607470, EGL - ISANDO, ISANDO EAST HYBRID, OU KRAAL SPORTSBAR CC, 168 JOUBERT STREET, BALFOUR, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.67360,'lng':28.58530}},
{'stp':630487,'address':'630487, EGL - ISANDO, ISANDO EAST HYBRID, SPOONYMATES, 462 ESKOM VILLAGE  EXT 2, GROOTVLEI, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.76600,'lng':28.48440}},
{'stp':647126,'address':'647126, EGL - ISANDO, ISANDO EAST HYBRID, EMILY S KITCHEN, 3160 STAND NO, SIYATHEMBA, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.65980,'lng':28.61020}},
{'stp':647141,'address':'647141, EGL - ISANDO, ISANDO EAST HYBRID, ROSE S TAVERN, ERF 553 MASOLANE STREET, NTHOANE, GREYLINGSTAD, BALFOUR, South Africa','geo':{'lat':-26.76470,'lng':28.77870}},
{'stp':651012,'address':'651012, EGL - ISANDO, ISANDO EAST HYBRID, GRASPALEIS PUB AND GRILL, 91 DYER STREET, BALFOUR, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.66030,'lng':28.59210}},
{'stp':653637,'address':'653637, EGL - ISANDO, ISANDO EAST HYBRID, PHOLA TAVERN, 3681 SIYATHEMBA, MANDELA SECTION, BALFOUR, BALFOUR, South Africa','geo':{'lat':-26.64940,'lng':28.61910}},
{'stp':705941,'address':'705941, EGL - ISANDO, ISANDO EAST HYBRID, EVERGREEN, 505 STAND NO, THUBELISHA, GROOTVLEI, BALFOUR, South Africa','geo':{'lat':-26.79890,'lng':28.50480}},
{'stp':738083,'address':'738083, EGL - ISANDO, ISANDO EAST HYBRID, MAPHUMZA NO2 PUB, 526 DLAMINI STREET, SIYATHEMBA LOCATION, #, BALFOUR, South Africa','geo':{'lat':-26.64647,'lng':28.61242}},
{'stp':795883,'address':'795883, EGL - ISANDO, ISANDO EAST HYBRID, RASTAS TAVERN, 5934 STAND NO, EXT 7 SIYATHEMBA, #, BALFOUR, South Africa','geo':{'lat':-26.65789,'lng':28.60253}},
{'stp':314822,'address':'314822, EGL - ISANDO, ISANDO EAST HYBRID, HALLGATE LIQUOR STORE, STAND NO, PLOT NO 41, HALLGATE PLOT VLAKFONTEIN ROAD, NIGEL, South Africa','geo':{'lat':-26.42350,'lng':28.52900}},
{'stp':551108,'address':'551108, EGL - ISANDO, ISANDO EAST HYBRID, NIGEL GOLF CLUB, CUB - NIGEL, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.39500,'lng':28.45570}},
{'stp':551440,'address':'551440, EGL - ISANDO, ISANDO EAST HYBRID, NIGEL RECREATION CLUB AND REST, ERF 9036, CHAMBERLIN STREET, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.43299,'lng':28.46364}},
{'stp':551630,'address':'551630, EGL - ISANDO, ISANDO EAST HYBRID, BONUS LIQUOR STORE, TWIN CITY 29 NORTHERN RD, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.41560,'lng':28.47010}},
{'stp':552776,'address':'552776, EGL - ISANDO, ISANDO EAST HYBRID, COSMOS LIQUOR STORE, 7 SEGAL ROAD, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.41680,'lng':28.46670}},
{'stp':628465,'address':'628465, EGL - ISANDO, ISANDO EAST HYBRID, NOMIS PLACE PERMIT, 733 DISA STREET, JAMESON PARK, ALRA PARK, ALRA PARK, South Africa','geo':{'lat':-26.44834,'lng':28.40950}},
{'stp':637687,'address':'637687, EGL - ISANDO, ISANDO EAST HYBRID, DROS GOLDFIELDS, 50 SPRINGS ROAD, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.41700,'lng':28.46520}},
{'stp':640295,'address':'640295, EGL - ISANDO, ISANDO EAST HYBRID, NEW JABULA RESTAURANT, 316 STAND NO, NO 9 1ST AVENUE, CORNER MAIN ROAD, NIGEL, South Africa','geo':{'lat':-26.41570,'lng':28.46860}},
{'stp':665328,'address':'665328, EGL - ISANDO, ISANDO EAST HYBRID, NIGEL PORTUGUESE CLUB (NTO), 18 VAN DER STEL ROAD, NIGEL, NIGEL, NIGEL, South Africa','geo':{'lat':-26.42986,'lng':28.46940}},
{'stp':689948,'address':'689948, EGL - ISANDO, ISANDO EAST HYBRID, GOLDEN BUFFALO SPUR NIGEL, SHOP NO 2 THE ANGELO SHOPPING, CENTRE BOUNDED BY DAVID STR RHODE AVE, MILNER AVE AND HEIDELBERG NIGEL SPRINGS, NIGEL, South Africa','geo':{'lat':-26.42230,'lng':28.45890}},
{'stp':754752,'address':'754752, EGL - ISANDO, ISANDO EAST HYBRID, DOLPHIN PUB, STAND NO, CORNER 4 AVENUE, 83 NORTHERN ROAD NIGEL, NIGEL, South Africa','geo':{'lat':-26.41851,'lng':28.47397}},
{'stp':756701,'address':'756701, EGL - ISANDO, ISANDO EAST HYBRID, RUMOURS PUB AND RESTAURANT, 148 HENDRICK VERWOERD STREET, VERWOERD NIGEL, #, NIGEL, South Africa','geo':{'lat':-26.42230,'lng':28.47780}},
{'stp':319344,'address':'319344, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, INANDA CLUB (NTO), NO 2, INANDA, RANDBURG, #, BRYANSTON, South Africa','geo':{'lat':-26.11783,'lng':28.05758}},
{'stp':379814,'address':'379814, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DIGGERS PUB & GRILL (NTO), DIGGERS RUGBY FOOTBALL CLUB BLDG, NO:58-60 SILVER PINE AVE,CNR OF REPUBLIC, RD, PRAEGVILLE, RANDBURG, South Africa','geo':{'lat':-26.09962,'lng':27.98246}},
{'stp':385745,'address':'385745, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BARUMA MUSIC BAR (NTO), UPPER GROUND FLOOR, 3 TUNGSTEN RD, CNR CR SWART DRIVE, STIJDOM PARK EXT 2, #, RANDBURG, South Africa','geo':{'lat':-26.07980,'lng':27.97448}},
{'stp':387960,'address':'387960, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NAMAK INDIAN RESTURANT (NTO), ERF:328 OF ERF:711 & PORTION 330 OF, CRAIGHALL PARK,SHOP NO:U1, RIVERVIEW, CENTRE NO:98 CONRAD DR ,BLAIRGOWRIE, RANDBURG, South Africa','geo':{'lat':-26.11437,'lng':28.01832}},
{'stp':388234,'address':'388234, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, QUARTERMAN HOTEL (NTO), 137 WEST STREET, SOUTH EXT 10, MORNINGSIDE, #, MORNINGSIDE, South Africa','geo':{'lat':-26.08447,'lng':28.05742}},
{'stp':388235,'address':'388235, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FALLSTAFF INN (NTO), 223 RIVONIA ROAD, SANDTON, #, MORNINGSIDE, South Africa','geo':{'lat':-26.08530,'lng':28.05832}},
{'stp':388775,'address':'388775, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCOMAMAS CANTERBURY CROSSING (NTO), STAND 1884, CANTERBURY CROSSING SHOPPING CENTRE, CORNER BRAM FISHER AND HUNTER STREET, HYDE PARK, South Africa','geo':{'lat':-26.10699,'lng':28.00119}},
{'stp':388932,'address':'388932, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARC FERME - MICHELANGELO (NTO), SHOP NO:01 GROUND FLOOR, MICHELANGELO TOWERS MALL, MAUDE STREET, CORNER 5th STREET, SANDOWN EXTENSION 49, HYDE PARK, South Africa','geo':{'lat':-26.10601,'lng':28.05421}},
{'stp':389075,'address':'389075, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIZZA DEL FORNO - BLAIRGOWRIE (NTO), SHOP NO:21, BLAIRGOWRIE PLAZA, CORNER CONRAD DRIVE AND SUSMAN STREET, BLAIRGOWRIE, RANDBURG, South Africa','geo':{'lat':-26.11543,'lng':28.00946}},
{'stp':389594,'address':'389594, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BIG BOSS DINER (NTO), 151 FOUNTAINS CENTRE, REPUBLIC ROAD, CORNER RABIE AND REPUBLIC, ROADS, FOUNTAINBLEAU, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.10403,'lng':27.97682}},
{'stp':389741,'address':'389741, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CAPITAL ON PARK (NTO), Portion 9 OF ERF 31, 101 KATHERINE STREET, SANDOWN, RANDBURG, South Africa','geo':{'lat':-26.10655,'lng':28.06295}},
{'stp':389866,'address':'389866, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCO MAMAS GREYSTON (NTO), ERF 211 AND 212 STRATHAVAN, X30 BEING SHOP 5A, GRAYSTON SHOPPING CEN, CNR GRAYSTON DRIVE & HELEN RD, STRATHAVE, MORNINGSIDE, South Africa','geo':{'lat':-26.10020,'lng':28.06600}},
{'stp':390906,'address':'390906, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MIAS SPICE KITCHEN (NTO), SHOP G 10, RIVONIA JUNCTION,, CNR RIVONIA AND MUTUAL ROADS (7TH AVENUE,  EDENBURG TOWNSHIP, MORNINGSIDE, South Africa','geo':{'lat':-26.06058,'lng':28.05967}},
{'stp':390962,'address':'390962, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SLOW IN THE CITY (NTO), SHOP NO. 19. GROUND FLOOR, RADISONBLU GAUTRAIN BUILDING, CNR WEST, AND RIVONIA STREETS, PORTION 1 OF ERF 13, MORNINGSIDE, South Africa','geo':{'lat':-26.10782,'lng':28.05831}},
{'stp':391486,'address':'391486, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, AFRO\'S CHICKEN SHOP (NTO), 135 RIVONIA ROAD, NEDBANK BUILDING, SANDOWN, #, MORNINGSIDE, South Africa','geo':{'lat':-26.10328,'lng':28.05939}},
{'stp':391984,'address':'391984, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GD SAINTS RESTAURANTS (NTO), THE MARC BUILDING, SHOP UR18, NO. 129 RIVONIA ROAD, CORNER MAUD ROAD, SANDOWN, MORNINGSIDE, South Africa','geo':{'lat':-26.10372,'lng':28.05786}},
{'stp':393408,'address':'393408, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALICE & FIFTH (NTO), SHOP NO UO1, GROUND FLOOR, CORNER OF ALICE LANE AND FIFTH STREET, SANDTON SUN HOTEL, SANDTON, HYDE PARK, South Africa','geo':{'lat':-26.10679,'lng':28.04951}},
{'stp':393451,'address':'393451, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SMACK PIZZA (RIVONIA) (NTO), SHOP NO GF02A AND 02B, 329 RIVONIA, BUILDING AT 329 RIVONIA BOULEVARD, EDENB, BEING PORTION 5 OF ERF 45 EDENBURG, RIVO, MORNINGSIDE, South Africa','geo':{'lat':-26.06019,'lng':28.04921}},
{'stp':461783,'address':'461783, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCOMAMAS RANDBURG (NTO), SHOP 19 APPLETONS VILLAGE CNR HILL, #, #, RANDBURG, South Africa','geo':{'lat':-26.09332,'lng':27.98389}},
{'stp':468866,'address':'468866, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DECK ON RIVONIA (NTO), San Deck, Bar & Restaurant, Sandton, #, #, MORNINGSIDE, South Africa','geo':{'lat':-26.04370,'lng':28.05961}},
{'stp':473327,'address':'473327, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE BIG MOUTH SANDTON (NTO), SHOP NO:13 SANDTON SQUARE, CNR MAUDE & FIFTH STREET, SANDOWN, BRYANSTON, South Africa','geo':{'lat':-26.10782,'lng':28.05483}},
{'stp':475757,'address':'475757, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MADISON AVE (NTO), WESSEL RD BETWEEN 9TH & 10TH AVE, RIVONIA, PORTION 1 OF LOT 76, EDENBURG, BRYANSTON, South Africa','geo':{'lat':-26.05599,'lng':28.06117}},
{'stp':476366,'address':'476366, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SILVANAS, STAND NO, PORTION 8 ERF 2 RESTAURANT PORTION, ON GROUND FLOOR FREDMAN TOWER, NORTHCLIFF, South Africa','geo':{'lat':-26.10161,'lng':28.05423}},
{'stp':480020,'address':'480020, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, POSSUMS ON BURNSIDE (NTO), 6 BURNSIDE AVE, CRAIGHAU, JOHANNESB, #, #, BRYANSTON, South Africa','geo':{'lat':-26.11431,'lng':28.02255}},
{'stp':486379,'address':'486379, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCOMAMAS THE COLONY (NTO), SHOP S012, THE COLONY SHOPPING CENT, 345 JAN SMUTS AVE, #, HYDE PARK, South Africa','geo':{'lat':-26.12187,'lng':28.02944}},
{'stp':488558,'address':'488558, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, 5\'s PREMIER LEAGUE RANDBURG (NTO), CNR MALIBONGWE & HANS SCHOEMAN, RANDBURG, #, RANDBURG, South Africa','geo':{'lat':-26.09714,'lng':27.98269}},
{'stp':494927,'address':'494927, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE ROYAL INDIAN RESTAURANT (NTO), THE FORUM BUILDING,62 MAUDE STR,CNR, PORTION 9 OF ERF: 575, SHOPS 1 & 2, STR, SANDOWN EXTENSION 49, BRYANSTON, South Africa','geo':{'lat':-26.10656,'lng':28.05350}},
{'stp':550096,'address':'550096, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HOLIDAY INN HOTEL TA KELSEYS GRILL, 115 KATHERINE STREET, SANDTON, SANDTON, HYDE PARK, South Africa','geo':{'lat':-26.10603,'lng':28.06600}},
{'stp':550132,'address':'550132, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SI MASLOW HOTEL, CNR RIVONIA+VERE RD, SANDTON, JOHANNESBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.09897,'lng':28.05967}},
{'stp':550393,'address':'550393, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, OAKHILL CELLARS, SHOP 123 3RD FLOOR RANDBURG SQUARE, PRETORIA STREET, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09190,'lng':28.00452}},
{'stp':550592,'address':'550592, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BALALAIKA PROTEA HOTEL, BALAALIKA PROTEA HOTEL,  20 MAUD STREET, SANDOWN, MORNINGSIDE, South Africa','geo':{'lat':-26.10388,'lng':28.05776}},
{'stp':550610,'address':'550610, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SCROOGE DINER RESTAURANT, SHOP G24,BRIGHTWATER COMMON\'S, REPUBLIC ROAD, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.10306,'lng':27.99269}},
{'stp':550811,'address':'550811, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FRATELLI PIZZERIA AND COFFEE SHOP, RIVERVIEW CENTRE, CNR CONRAD DR & HILLCREST AVE, BLAIRGOWRIE RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.11460,'lng':28.01880}},
{'stp':550943,'address':'550943, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MONTEGO BAY SEAFOOD RESTAURANT, SANDTON SQUARE SHOPPING CENTRE, SHOP 31 SQUARE LEVEL, MAUDE STREET SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.13378,'lng':28.05283}},
{'stp':551142,'address':'551142, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CITY LODGE SANDOWN, ERF NO 543, KATHERINE ST, SANDOWN EXT 46, SANDTON JOHANNESBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.10568,'lng':28.05446}},
{'stp':551561,'address':'551561, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TOWN LODGE SANDTON GRAYSTON DRIVE, CNR GREYSTON DR & WEBBER RD, SANDOWN, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.09867,'lng':28.06220}},
{'stp':551572,'address':'551572, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PADDY\'S POOL AND DART CLUB, CANTERBURY CROSSING, CNR HENDRIK VERWOERD & HUNTER, FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.10654,'lng':28.00072}},
{'stp':551620,'address':'551620, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HOTEL MICHELANGELO, NORTH TOWER SANDTON SQUARE, CNR WEST AND MAUD STREETS, SANDOWN, MORNINGSIDE, South Africa','geo':{'lat':-26.10575,'lng':28.05447}},
{'stp':551854,'address':'551854, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RANDBURG LODGE HOTEL, 259 MAIN AVENUE, FERNDALE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09830,'lng':27.99570}},
{'stp':552166,'address':'552166, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PAPPAS ON THE SQUARE, SANDTON SQUARE, SHOP 120B, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10812,'lng':28.05425}},
{'stp':552174,'address':'552174, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SCHAWABING RESTAURANT, 387 MAIN AVENUE, FERNDALE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.08452,'lng':27.99876}},
{'stp':552317,'address':'552317, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CATZ PYJAMAS, STAND 318,FIRST FLOOR, COMMERCIAL BUILDING, 12 MAIN ROAD, MELVILLE, South Africa','geo':{'lat':-26.17710,'lng':28.00170}},
{'stp':552332,'address':'552332, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GARDEN COURT SANDTON CITY, CNR WEST STREET & ALICE LANE, EXTEMTION 38, SANDOWN, MORNINGSIDE, South Africa','geo':{'lat':-26.10567,'lng':28.05341}},
{'stp':552439,'address':'552439, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HILTON SANDTON HOTEL, RIVONIA & MAUDE, SANDOWN, RANDBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.10160,'lng':28.06023}},
{'stp':552523,'address':'552523, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SANDTON SPORTS CLUB, HOLT STREET EAST, PARKMORE, JHB, MORNINGSIDE, South Africa','geo':{'lat':-26.09803,'lng':28.03540}},
{'stp':552742,'address':'552742, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIRATES CLUB GREENSIDE, SIR LIONEL PHILLIPS PARK, BRAESIDE ROAD, JOHANNESBURG, MELVILLE, South Africa','geo':{'lat':-26.14530,'lng':28.01480}},
{'stp':552745,'address':'552745, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RANDBURG ASTROSTADIUM, RANDBURG SPORTS COMPLEX, CNR HANS STRIDJOM DRIVE, HANS SCHOEMAN, RANDBURG, South Africa','geo':{'lat':-26.09515,'lng':27.98287}},
{'stp':552859,'address':'552859, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COLONY ARMS RESTAURANT, COLONY SHOPPING CENTRE, 343 JAN SMUTS AVENUE, CRAIGHALL PARK, HYDE PARK, South Africa','geo':{'lat':-26.12220,'lng':28.02960}},
{'stp':552917,'address':'552917, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE SHEIKS PALACE, CNR 9TH AVENUE & RIVONIA BOUL, RIVONIA, JOHANNESBURG, BRYANSTON, South Africa','geo':{'lat':-26.05764,'lng':28.05977}},
{'stp':552929,'address':'552929, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FERNDALE BOWLING CLUB, CNR HARLEY ST MAIN AND SURREY AV, FERNDALE, #, RANDBURG, South Africa','geo':{'lat':-26.09578,'lng':27.99663}},
{'stp':553166,'address':'553166, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TSAFRICA RESTAURANT, SHOP 4&5 ELNA RANDHOF, CNR BLAIRGOWRIE DR&SELKIRK RD, BLAIRGOWRIE, RANDBURG, South Africa','geo':{'lat':-26.11162,'lng':28.01735}},
{'stp':553245,'address':'553245, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALICAT S RESTAURANT, 11 SEDDON ROAD, KENSINGTON B, JOHANNESBURG, RANDBURG, South Africa','geo':{'lat':-26.08655,'lng':28.00821}},
{'stp':553452,'address':'553452, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CESCOS PUB AND PORTUEGESE REST, CNR TUNGSTEN RD AND CR SWART DRV, STRIJDOMPARK, JHB, RANDBURG, South Africa','geo':{'lat':-26.07960,'lng':27.97430}},
{'stp':553497,'address':'553497, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, OLD PARKTONIANS ASSOCIATION, CRAIGHALL ESTATE, KLIPFONTEIN NO 4, JOHANNESBURG, RANDBURG, South Africa','geo':{'lat':-26.10840,'lng':28.01890}},
{'stp':553555,'address':'553555, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIRATES BOWLING CLUB, SIR LIONEL PHILLIPS PARK, BRAESIDE ROAD, GREENSIDE, JOHANNESBURG, MELVILLE, South Africa','geo':{'lat':-26.14660,'lng':28.01710}},
{'stp':553573,'address':'553573, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GARDEN COURT MORNINGSIDE, NO1 CULLINAN CLOSE,, CNR RIVONIA & CULLINAN CLOSE, MORNINGSIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.08868,'lng':28.05817}},
{'stp':553689,'address':'553689, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CITY LODGE MORNINGSIDE, CNR HILL AND RIVONIA ROADS, MORNINGSIDE, 2146, MORNINGSIDE, South Africa','geo':{'lat':-26.09380,'lng':28.05580}},
{'stp':553692,'address':'553692, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SUNRISE PIZZA AND PASTA REATAURANT, SLIVER PINE SHOPPING CENTRE, CNR SLIVER PINE AND RIVER ROAD, MALANSHOF RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09220,'lng':27.97210}},
{'stp':553741,'address':'553741, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NEWS CAFE MELVILLE, ERF 1114 SHOP 1 CAMPUS SQUARE, CNR KINGSWAY & UNIVERSITY RD, AUCKLAND PARK, MELVILLE, South Africa','geo':{'lat':-26.18150,'lng':28.00300}},
{'stp':553760,'address':'553760, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NEWS CAFE RANDBURG, SHOP NO 1, NEW SHOPPING CENTRE, CNR CHURCH & HILL STREETS, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09300,'lng':27.98496}},
{'stp':553768,'address':'553768, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SANDTON SUN, CNR 5TH ST + RIVONIA RD, SANDHURST EXT 3, JOHANNESBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.10725,'lng':28.05174}},
{'stp':607192,'address':'607192, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MCGINTYS RESTAURANT, SHOP G 18 BRIGHTWATER COMONS, REPUBLIC ROAD PRESIDENT RIDGE EXT 7, #, RANDBURG, South Africa','geo':{'lat':-26.10290,'lng':27.99180}},
{'stp':607761,'address':'607761, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PRONTO DELI, COLONY SHOPPING CENTRE, 343 JAN SMU, CRAIGHALL, Johannesburg, HYDE PARK, South Africa','geo':{'lat':-26.12246,'lng':28.02971}},
{'stp':609514,'address':'609514, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GOLMOUTH (NTO), RANDBURG SPORT COMPLEX, CNR MALIBONGWE & HANS SCHOEMAN DRIVE, MALANSHOF, RANDBURG, South Africa','geo':{'lat':-26.09589,'lng':27.98216}},
{'stp':610511,'address':'610511, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIGALLE (NTO), SANDTON MICHELANGELO TOWERS SANDTON, #, Johannesburg, MORNINGSIDE, South Africa','geo':{'lat':-26.10730,'lng':28.05467}},
{'stp':610512,'address':'610512, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE RAJ (NTO), SHOP 117,3RD FLOOR,MICHELANGELO TOW, MAUD STR, CNR 5TH AVENUE, SANDTON, PORION 8 OF ERF 575, SANDOWN EXT 49, MORNINGSIDE, South Africa','geo':{'lat':-26.10354,'lng':28.05445}},
{'stp':611089,'address':'611089, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SAXON (NTO), 36 SAXON ROAD, SANDHURST, Johannesburg, MORNINGSIDE, South Africa','geo':{'lat':-26.11184,'lng':28.03480}},
{'stp':616808,'address':'616808, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RANDBURG CRICKET & HARRIER CLUB (NT, PORTION 45,98 & 115 OF THE FARM, KLIPFONTEIN 203 IQ. BOUNDED BY REPUBLIC, RD, HANS STRIJDOM DR & SILVER PINE RD, RANDBURG, South Africa','geo':{'lat':-26.09612,'lng':27.98220}},
{'stp':620213,'address':'620213, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIATTO SANDTON (NTO), SHOP L25 SANDTON SQUARE, SANDTON, Johannesburg, MORNINGSIDE, South Africa','geo':{'lat':-26.10735,'lng':28.05467}},
{'stp':631953,'address':'631953, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SOUL LOUNGE (NTO), WIG & PEN RESTAURANT ELNA, RANDHOF CENTRE, CNR SELKIRK &, BLAIRGOWRIE DRIVE, BLAIRGOWRIE, RANDBURG, South Africa','geo':{'lat':-26.09667,'lng':28.00556}},
{'stp':634721,'address':'634721, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CAFE NEW YORK, 50 GRANT AVENUE, NORWOOD, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.15965,'lng':28.07647}},
{'stp':635198,'address':'635198, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PADDY\'S SPORTS CAFE (NTO), CANTERBERRY CROSSING, CORNER HENDRIK VERWOERD DRIVE AND, HUNDER STREET, FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.10654,'lng':28.00072}},
{'stp':638325,'address':'638325, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DROS RANDBURG, HANS STRYDOM DRIVE, RANDBURG, #, RANDBURG, South Africa','geo':{'lat':-26.09527,'lng':27.98428}},
{'stp':642318,'address':'642318, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIVER CAFE (NTO), SANDTON FIELD & STUDY CENTRE, LOUISE AVENUE & 14th STREET, PARKMORE, MORNINGSIDE, South Africa','geo':{'lat':-26.09094,'lng':28.03117}},
{'stp':649429,'address':'649429, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HOLIDAY INN SANDTON, RIVONIA ROAD 123, MAUDE & DAISY STREET, SANDOWN BEING PORTION 3 OF ERF 11, MORNINGSIDE, South Africa','geo':{'lat':-26.10440,'lng':28.05960}},
{'stp':654562,'address':'654562, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, INTER CONTINENTAL SANDTON TOWERS, STANDS 525, 526, 527 & 528, CNR FIFTH & MAUDE STREETS, SANDTON EXT 38, MORNINGSIDE, South Africa','geo':{'lat':-26.10704,'lng':28.05294}},
{'stp':655480,'address':'655480, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, APOLDO 1 (BUTCHER SHOP) (NTO), NELSON MANDELA SQUARE, MORNINGSIDE, MORNINGSIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.10726,'lng':28.05475}},
{'stp':655515,'address':'655515, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JB RIVERS HYDE PARK CORNER (NTO), HYDE PARK SHOPPING CENTRE, CNR JAN SMUTS, HYDE PARK, HYDE PARK, South Africa','geo':{'lat':-26.12579,'lng':28.03435}},
{'stp':658645,'address':'658645, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WILLOUGHBY\'S (NTO), HYDE PARK SHOPPING CENTRE, HYDE PARK, HYDE PARK, HYDE PARK, South Africa','geo':{'lat':-26.12579,'lng':28.03435}},
{'stp':659925,'address':'659925, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RADISSON SANDTON (NTO), CNR DAISY & RIVONIA ROAD, SANDTON, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10394,'lng':28.06086}},
{'stp':660419,'address':'660419, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE GREEN PEPPERCORN (NTO), CNR RIVONIA & OUTSPAN ROAD, MORNINGSIDE, MORNINGSIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.08173,'lng':28.05974}},
{'stp':660420,'address':'660420, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, POMODORO (NTO), CNR RIVONIA & OUTSPAN, MORNINGSIDE, MORNINGSIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.08272,'lng':28.06047}},
{'stp':667963,'address':'667963, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAXIMILLEN RESTAURANT, SHOP NUMBER 408,LEVEL 4, DA VINCI HOTEL PREMISES,BOUNDED BY MAUDE, STREET.FOFTH STREET,WEST STREET AND, MORNINGSIDE, South Africa','geo':{'lat':-26.10162,'lng':28.06043}},
{'stp':668408,'address':'668408, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SOUTHERN SUN HYDE PARK, 6TH STREET AND 1ST AVENUE, HYDE PARK  CORNER SHOPPING CENTRE, ERVEN 214 HYDE PARK EXT 16, HYDE PARK, South Africa','geo':{'lat':-26.12434,'lng':28.03348}},
{'stp':668465,'address':'668465, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FRATELLI FERNDALE, ERF NO 2112, SHOP G48 FERNDALE VILLAGE CENTRE, CNR MAIN AND OXFORD ROADS FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.08143,'lng':27.99833}},
{'stp':671393,'address':'671393, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TRUMPS (NTO), SHOP NO:11 SANDTON SQUARE, CNR MAUNDE AND 5TH STREET, SANDOWN, SANDTON, PORTION 575, MORNINGSIDE, South Africa','geo':{'lat':-26.10540,'lng':28.05430}},
{'stp':672399,'address':'672399, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WALNUT GROVE EXCLUSIVE COFFEE SHOP, MEZZANINE FLOOR, CNR RIVONIA ROAD AND 5TH STREET, SANDTON CITY, BRYANSTON, South Africa','geo':{'lat':-26.10829,'lng':28.05296}},
{'stp':677640,'address':'677640, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BYBLOS (NTO), CNR 9TH AVENUE AND, RIVONIA BOULEVARD, RIVONIA, BRYANSTON, South Africa','geo':{'lat':-26.06085,'lng':28.05940}},
{'stp':680559,'address':'680559, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARCO POLO (NTO), GROUND LEVEL, DA VINCI HOTEL PREMISES, CNR MAUDE & WEST STREET, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10710,'lng':28.05290}},
{'stp':682900,'address':'682900, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ADA AWKA NIGERIAN RESTAURANT AND BA, CNR BLAIRGOWRIE DR AND, SELKIRK AVENUE, RANDHOF BUILDING SHOP 10, RANDBURG, South Africa','geo':{'lat':-26.09726,'lng':28.00762}},
{'stp':685130,'address':'685130, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RADISSON BLUE GAUTRAIN HOTEL (NTO), CNR RIVONIA RD AND WEST STREET, SANDTON, RIVONIA, MORNINGSIDE, South Africa','geo':{'lat':-26.10807,'lng':28.05892}},
{'stp':686152,'address':'686152, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE GRILLHOUSE SANDTON (NTO), ATHOLL SQUARE, KATHERINE STREET, MORNINGSIDE, HYDE PARK, South Africa','geo':{'lat':-26.10693,'lng':28.05082}},
{'stp':693189,'address':'693189, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HARLEY DAVIDSON (NTO), PORTION 1 OF ERF 963, 167 RIVONIA ROAD, MORNINGSIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.09564,'lng':28.05616}},
{'stp':699940,'address':'699940, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, Typsi Gypsy (NTO), 167 PERTH ROAD & LANCASTER, WESTDENE, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.18085,'lng':27.99508}},
{'stp':703129,'address':'703129, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HIGHER GROUND (NTO), PORTION 419 OF THE FARM DRIEFONTEIN, 41 IR, OLD STITHIAN ASSOC. GROUND,, NO: 54 PETER PLACE, LYME PARK EXT:3, BRYANSTON, South Africa','geo':{'lat':-26.08196,'lng':28.02426}},
{'stp':723829,'address':'723829, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LUGZ INDIAN CUISINE (NTO), CNR HENDRIK VERWORD & HUNTER, CANTERBURY CROSSING, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.10694,'lng':28.00003}},
{'stp':726041,'address':'726041, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RED CHILLI (NTO), 613 MALIBONGWE RD, SHOP 3, UNION TILES CENTRE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.07721,'lng':27.97624}},
{'stp':728628,'address':'728628, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JB\'S CORNER SANDTON (NTO), ERF:247 SHOPS U311 & U312, SANDTON CITY SHOPPING CENTRE,CNR RIVONIA, RD & 5TH STR, SANDHURST EXT 3, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10659,'lng':28.05198}},
{'stp':729114,'address':'729114, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VIP (NTO), SHOP P1, MICHAELANGELO TOWERS, MAUDE STREET, CNR 5TH STREET, SANDOWN EXT 9, MORNINGSIDE, South Africa','geo':{'lat':-26.10346,'lng':28.05860}},
{'stp':734288,'address':'734288, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DOPPIO ZERO SANDTON (NTO), ERF:1193 SHOP NO:U310, SANDTON CITY SHOPPING CENTRE,CNR RIVONIA, & 5TH STR, SANDHURST, EXT:3 SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.11002,'lng':28.05361}},
{'stp':745842,'address':'745842, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VSP (NTO), 123 RIVONIA RD, #, #, MORNINGSIDE, South Africa','geo':{'lat':-26.10440,'lng':28.05960}},
{'stp':748737,'address':'748737, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COL\'CACCHIO HYDE PARK (NTO), SHOP 58 UPPER MALL, #, #, HYDE PARK, South Africa','geo':{'lat':-26.12239,'lng':28.03311}},
{'stp':749976,'address':'749976, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RI STRETTO MORNINGSIDE (NTO), CNR RIVONIA & CULLINAN CLOSE, #, #, MORNINGSIDE, South Africa','geo':{'lat':-26.08868,'lng':28.05817}},
{'stp':778637,'address':'778637, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CALISTOS (NTO), RIVERVIEW SHOPPING CENTRE, CNR CONRAD DRIVE & HILLCREST STREET, BLAIRGOWRIE, RANDBURG, South Africa','geo':{'lat':-26.11460,'lng':28.01880}},
{'stp':778638,'address':'778638, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HARD ROCK CAFE SANDTON (NTO), SHOP NO:L10 & L10B GAZEBO,NELSON MA, @SANDTON CITY,CNR 5TH & MAUNDE STR, PORTION 5&7 OF ERF:239,SANDHURST  EXT.3, MORNINGSIDE, South Africa','geo':{'lat':-26.10982,'lng':28.05053}},
{'stp':779373,'address':'779373, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALPINE RESTAURANT (NTO), 83 BRAAMFISCHER DRIVE, BLAIRGOWRIE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.11362,'lng':27.99973}},
{'stp':783345,'address':'783345, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VICKI CHRISTINA (NTO), 343 JAN SMUTS AVENUE, COLONY SHOPPING CENTRE, CRAIGHALL PARK, HYDE PARK, South Africa','geo':{'lat':-26.12275,'lng':28.02960}},
{'stp':799910,'address':'799910, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCOMAMA\'S RIVONIA (NTO), SHOP G02,, RIVONIA VILLAGE, RIVONIA, BRYANSTON, South Africa','geo':{'lat':-26.06014,'lng':28.06037}},
{'stp':464390,'address':'464390, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KOOL OUT CONCEPTS, 77 COMMISSION STREET, ERF 5292 MEZZANINE SUITE, CORNER HOUSE, CBD, South Africa','geo':{'lat':-26.20581,'lng':28.03955}},
{'stp':483395,'address':'483395, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KING KONG, 6 STAND NUMBER, VERWEY STREET, TROYEVILLE, CBD, South Africa','geo':{'lat':-26.20160,'lng':28.06226}},
{'stp':550138,'address':'550138, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ELLISPARK DRANKWINKEL STADION, PORTION 92 OF THE FARM, DOORNFONTEIN, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19725,'lng':28.06233}},
{'stp':550201,'address':'550201, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CARFAX, 39 PIM STR, NEWTOWN, JHB, CBD, South Africa','geo':{'lat':-26.20090,'lng':28.02870}},
{'stp':550626,'address':'550626, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SANDTON CONVENTION CENTRE, SANDTON CONVENTION CENTRE, BETWEEN MAUD STR & ALICE LANE, SANDTON TOWERS & HOLI INN GAR, HYDE PARK, South Africa','geo':{'lat':-26.10578,'lng':28.05410}},
{'stp':550734,'address':'550734, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WITS UNIVERSITY SPORTS COMPLEX CLUB, CRN EMPIRE AND YALE RD, #, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19250,'lng':28.02320}},
{'stp':551635,'address':'551635, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, INANDA CLUB, REMAINING EXTENT OF FARM, CYFERFONTEIN NO 2, INANADA RANDBURG, HYDE PARK, South Africa','geo':{'lat':-26.11710,'lng':28.05500}},
{'stp':551684,'address':'551684, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SPECIAL EVENTS KES RUGBY FESTIVAL, 44 STREET PATRICK ROAD, HOUGHTON, JOHANNESBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.17450,'lng':28.06460}},
{'stp':553188,'address':'553188, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NIKI S OASIS RESTAURANT AND BAR, 138A BREESTREET, CNR WOLHUTER, NEWTOWN, CBD, South Africa','geo':{'lat':-26.20185,'lng':28.03293}},
{'stp':553623,'address':'553623, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RACKETS SPORTS CLUB, LINDHORST,CHURCH,FERREIRA &, VAN HULSTEYN STREETS, KENILWORTH, ROSETTENVILLE, South Africa','geo':{'lat':-26.24937,'lng':28.04080}},
{'stp':603319,'address':'603319, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SPECIAL EVENTS WITS RAG, Boarded by Enoch Sontaga,Empire,,  Yale, Jorrisen, Solomon & Jan Smuts Ave, JOHANNESBURG, MELVILLE, South Africa','geo':{'lat':-26.19040,'lng':28.03160}},
{'stp':606604,'address':'606604, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RANDBURG HOCKEY CRICKET AND HARRIER, PORT 45 AND 115 FARM KLIPFONTEIN, BOUND REPUBLIC AND HANS STRYDOM RD, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09830,'lng':27.98440}},
{'stp':610334,'address':'610334, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WANDERERS STADIUM (NTO), CORLETTE DRIVE, ILLOVO, Johannesburg, HYDE PARK, South Africa','geo':{'lat':-26.13175,'lng':28.06065}},
{'stp':610665,'address':'610665, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KANNIDOOD (NTO), UNIVERSITY OF JHB, CNR KINGSWAY & UNIVERSITY STREET, Johannesburg, MELVILLE, South Africa','geo':{'lat':-26.18270,'lng':28.00214}},
{'stp':611757,'address':'611757, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EGOLI (RAU) (NTO), OPPIERIF KOSHUIS, RAU UNIVERSITY, K, AUCKLAND PARK, Johannesburg, MELVILLE, South Africa','geo':{'lat':-26.18366,'lng':27.99605}},
{'stp':611758,'address':'611758, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WILD BOAR (RAU) (NTO), AFSLAAN KOSHUIS, RAU UNIVERSITY, KI, AUCKLAND PARK, Johannesburg, MELVILLE, South Africa','geo':{'lat':-26.18659,'lng':27.99521}},
{'stp':626447,'address':'626447, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DE LA SALLE HOLLY CROSS COLLEGE, DE LA SALLE HOLLY CROSS COLLEGE, 2-22 ROAD N0 3 VICTORY PARK, #, RANDBURG, South Africa','geo':{'lat':-26.11043,'lng':27.99880}},
{'stp':628768,'address':'628768, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIVONIA PRIMARY SCHOOL SPEC EVENT, CNR WESSEL RD AND 12TH STREET, ERF 245 PORTION 3, #, MORNINGSIDE, South Africa','geo':{'lat':-26.05010,'lng':28.06140}},
{'stp':636237,'address':'636237, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BRYANSTON PARALLEL MEDIUM SCHOOL, C/O SLOANE & MAIN STREET, BRYANSTON, #, BRYANSTON, South Africa','geo':{'lat':-26.04117,'lng':28.02690}},
{'stp':639287,'address':'639287, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE GAY PRIDE EVENT SE, CORNER CARDIGAN AVENUE, LOWER PARK DRIVE,PARKVIEW, #, MELVILLE, South Africa','geo':{'lat':-26.15588,'lng':28.03020}},
{'stp':650314,'address':'650314, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARKHURST VILLAGE FAIR, 14TH AVENUE, VERITY PARK, #, HYDE PARK, South Africa','geo':{'lat':-26.13800,'lng':28.01990}},
{'stp':651982,'address':'651982, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARKVIEW CHRISTMAS MARKET NICARELA, GEORGE HAY PARK, LURGAN ROAD, PARKVIEW, HYDE PARK, South Africa','geo':{'lat':-26.16358,'lng':28.02437}},
{'stp':655513,'address':'655513, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, INVESTEC BANK (NTO), 100 GRAYSTON DRIVE, GRAYSTON, MORNINGSIDE, MORNINGSIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.09929,'lng':28.05418}},
{'stp':665691,'address':'665691, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ELLISPARK STADIUM (NTO), PORTION 92 OF THE FARM, DOORNFONTEIN, JOHANNESBURG, GILLOOLYS, South Africa','geo':{'lat':-26.19752,'lng':28.06077}},
{'stp':669044,'address':'669044, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CREW RIVONIA (NTO), SHOP 11 RIVONIA BOULEVARD, RIVONIA, RIVONIA, MORNINGSIDE, South Africa','geo':{'lat':-26.05798,'lng':28.06017}},
{'stp':680432,'address':'680432, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIMPLY INDUSTRIES, UJ RUGBY CLUB, CORNER LEWES RD & GLOUCESTER STR, WESTDENE, MELVILLE, South Africa','geo':{'lat':-26.17815,'lng':27.99053}},
{'stp':683902,'address':'683902, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ST STITHIANS COLLEGE, 40 HENDRIK VERWOERD DRIVE, ST STITHIANS COLLEGE, LYME PARK EXT 2, RANDBURG, South Africa','geo':{'lat':-26.12250,'lng':27.99810}},
{'stp':683938,'address':'683938, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ST JOHNS COLLEGE EASTER RUGBY FESTI, ST DAVID ROAD, ST JOHNS COLLEGE, HOUGHTON, HYDE PARK, South Africa','geo':{'lat':-26.17952,'lng':28.05393}},
{'stp':684765,'address':'684765, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LUSITO LAND FESTIVAL, CNR 11TH STREET AND ROSETTENVILLE R, PIONEER PARK LUSITO LAND FESTIVAL, REGENTS PARK, ROSETTENVILLE, South Africa','geo':{'lat':-26.23420,'lng':28.05530}},
{'stp':688564,'address':'688564, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DISCOVERY 702 WALK THE TALK, MARKS PARK SPORTS CLUB, JUDITH ROAD STAND 09009000, EMMARENTIA, MELVILLE, South Africa','geo':{'lat':-26.16490,'lng':28.00390}},
{'stp':688755,'address':'688755, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PINK PUNTER SPECIAL EVENT, BOUNDED BY CORLETT DR RUDD RD, AND NORTH STREETS, WANDERERS CLUB LOWER CRICKET OVAL, HYDE PARK, South Africa','geo':{'lat':-26.13410,'lng':28.05630}},
{'stp':694680,'address':'694680, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, OGILVY (NTO), THE BRAND BUILDING, 15 SLOANE STREET, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.04169,'lng':28.01625}},
{'stp':694905,'address':'694905, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RANDLORDS (NTO), SOUTH POINT, 3 DE KORTE STREET, BRAAMFONTEIN, CBD, South Africa','geo':{'lat':-26.19405,'lng':28.03221}},
{'stp':695209,'address':'695209, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TM RESTAURANT (NTO), STAND NR 375/376, EAST BANK AVENUE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09860,'lng':28.10940}},
{'stp':704368,'address':'704368, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DEUTSCHE INTERNASONALE SCHULE JHB, 11 SANS SOUCI ROAD, PARKTOWN, #, HYDE PARK, South Africa','geo':{'lat':-26.17805,'lng':28.01727}},
{'stp':707511,'address':'707511, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MICROSOFT (NTO), 3012 WILLIAM NICOL DRIVE, BRYANSTON, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.04455,'lng':28.01846}},
{'stp':721754,'address':'721754, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SAPS LAPA RANDBURG (NTO), 20 SHEPHERD AVENUE, KENSINGTON B, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.07640,'lng':27.99690}},
{'stp':724430,'address':'724430, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, S BAR, 11 BICCARD STREET, ERF 4519, #, CBD, South Africa','geo':{'lat':-26.19998,'lng':28.05670}},
{'stp':730288,'address':'730288, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PINOS UJ (NTO), UJ, KINGSWAY CAMPUS, AKADEMIE ROAD, AUCKLAND PARK, MELVILLE, South Africa','geo':{'lat':-26.18334,'lng':27.99181}},
{'stp':730937,'address':'730937, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARKET THEATRE BAR & BISTRO (NTO), CNR BREE & WOLHUTER STR, NEWTOWN, JEPPESTOWN, CBD, South Africa','geo':{'lat':-26.20894,'lng':28.06966}},
{'stp':737095,'address':'737095, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EVENTS BAR, CNR THOMAS BOWLER AND OLIPHANTS RD, JOHANNESBURG BOTANICAL GARDENS, EMMARENTIA, CBD, South Africa','geo':{'lat':-26.15545,'lng':27.99807}},
{'stp':769890,'address':'769890, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NEIGHBOUR GOODS MARKET (NTO), 20 JUTA STREET, BRAAMFONTEIN, BRAAMFONTEIN, YEOVILLE, South Africa','geo':{'lat':-26.19433,'lng':28.03485}},
{'stp':773869,'address':'773869, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARKLIFE FESTIVAL, JOHANNESBURG STADIUM, CORNER CURRIE AND STAIB STREET, NEW DOORNFONTEIN, MORNINGSIDE, South Africa','geo':{'lat':-26.16719,'lng':28.01014}},
{'stp':863003,'address':'863003, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SAP ONTSPANNINGSKLUB JOHN WARE PARK, SAP JOHN WARE PARK, JOHN WARE PARK MAINWEG, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.20630,'lng':28.02930}},
{'stp':462175,'address':'462175, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LAPENG, 244 PALM STREET, RIVERPARK, LOMBARDY EAST, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11461,'lng':28.12105}},
{'stp':465132,'address':'465132, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, POPI\'S PLACE, STAND NO, 98-19 AVENUE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10584,'lng':28.10665}},
{'stp':465134,'address':'465134, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, STREET SIDE, 7674 STAND NO, EXTENSION 10, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09827,'lng':28.11876}},
{'stp':465400,'address':'465400, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GOGO\'S PLACE, STAND NO, NO 11 8TH AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10035,'lng':28.09255}},
{'stp':465875,'address':'465875, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PETERS PLACE, 120 STAND NO, 19TH AVENUE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10795,'lng':28.10724}},
{'stp':466386,'address':'466386, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MO S PLACE, 9 STAND NO, 17TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09787,'lng':28.10106}},
{'stp':466388,'address':'466388, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIBIYASS TAVERN, 179 STAND NO, BLOCK 4 A, 6TH AVENUE, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11490,'lng':28.09350}},
{'stp':467172,'address':'467172, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, REACH ALL AFRICANS IN, 220 STAND NO, NAMIBIA DRIVE, TSUTSUMANI, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09108,'lng':28.11371}},
{'stp':467433,'address':'467433, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CROSSROADS FAST FOOD, 25 STAND NO, 16 AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09989,'lng':28.10091}},
{'stp':468380,'address':'468380, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MACHAVA S TAVERN, 124 STAND NO, 9TH AVENUE ALEXANDRA RANDBURG, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11265,'lng':28.09815}},
{'stp':468381,'address':'468381, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAMAGIS S TAVERN, 3745 STAND NO, PHIL MACHITELA STREET FAR EAST BANK, ALEXANDRA RANDBURG, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09339,'lng':28.10914}},
{'stp':468383,'address':'468383, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GOGO ROSE\'S PLACE, 06 STAND NO, JABULANI CRESCENT, RIVER PARK, LOMBARDY EAST, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10870,'lng':28.11526}},
{'stp':468391,'address':'468391, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VELIS PLACE, 298 STAND NO, PHASE 2, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10945,'lng':28.09561}},
{'stp':468394,'address':'468394, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ESITHALENI BNB, 165 STAND NO, 7TH AVENUE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.11427,'lng':28.09632}},
{'stp':468763,'address':'468763, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JAZZ CORNER TAVERN, 845 STAND NO, MAURITITUS LOOP, TSUTSUMANI VILLAGE ALEXENDRA, CBD, South Africa','geo':{'lat':-26.09536,'lng':28.11544}},
{'stp':550143,'address':'550143, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MUDAU S TAVERN, STAND 232, NO 534TH AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10660,'lng':28.09040}},
{'stp':550524,'address':'550524, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOLOTO MM, 23 KINGSPLACE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10880,'lng':28.09880}},
{'stp':551031,'address':'551031, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THANDIS TAVERN, STAND 236 PHASE C, FAR EAST BANK, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09960,'lng':28.11520}},
{'stp':552078,'address':'552078, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KHAPHELA S INN, STAND NO, 24 - 12TH AVENUE ALEXANDRA TOWNSHIP,  RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10090,'lng':28.09720}},
{'stp':552253,'address':'552253, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MTHOMBENI TAVERN, ERF 724 BLOCK 8, 17 - 9 TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11260,'lng':28.09810}},
{'stp':552637,'address':'552637, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SAM S TAVERN, SHOP NO 1 MARLBORO S/CENTRE, C/N 1 ST AVENUE & 3RD STREET, MARLBORO, ALEXANDRA WEST, South Africa','geo':{'lat':-26.09920,'lng':28.08770}},
{'stp':553125,'address':'553125, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MASILOS TAVERN, 21-7TH AVENUE, ALEXANDER, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10199,'lng':28.09200}},
{'stp':553133,'address':'553133, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWA ZANE, ERF NO 20, 13-12TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09940,'lng':28.09650}},
{'stp':553442,'address':'553442, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SHEILA S TAVERN, ERF 379, BLOCK 9, 68 4TH AVENUE, CNR SELBOURN ST, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10770,'lng':28.09090}},
{'stp':553520,'address':'553520, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MSIMANGO MM, NO 4- 14TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09800,'lng':28.09810}},
{'stp':560217,'address':'560217, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CARPET HOUSE TAVERN, 32 A FIRST AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10510,'lng':28.08680}},
{'stp':560261,'address':'560261, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOLOTO A, 155-16TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11150,'lng':28.10500}},
{'stp':560423,'address':'560423, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MODIBA LR, 65 2ND AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10390,'lng':28.08627}},
{'stp':561191,'address':'561191, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAGAZI KT, 33 - 15 TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10090,'lng':28.10010}},
{'stp':580518,'address':'580518, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RAIDANI TS, 165 - 9TH AVE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11340,'lng':28.09830}},
{'stp':604050,'address':'604050, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, M M MASHIGO\'S TAVERN, STAND NUMBER 58 ALEXANDRA, SITUATED AT 58 - 3RD AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10705,'lng':28.08957}},
{'stp':604620,'address':'604620, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NOBELA SN, 161 10TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11350,'lng':28.09950}},
{'stp':604791,'address':'604791, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BALOYI S TAVERN, BALOYI\'S TAVERN, 98 - 3RD AVENUE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11060,'lng':28.09090}},
{'stp':604980,'address':'604980, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOFOLO P, 421 Caper Bush River, Lombardy East, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10910,'lng':28.12640}},
{'stp':605019,'address':'605019, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MASHIGO V, 29 - 4TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10370,'lng':28.08940}},
{'stp':606123,'address':'606123, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, AGNESS PLACE, AGNES\'S PLACE, 1 - 13TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09831,'lng':28.09678}},
{'stp':606237,'address':'606237, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALEX INN, 23 - 6TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10270,'lng':28.09090}},
{'stp':606438,'address':'606438, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MASOLO MM, ERF 29 - 4TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10380,'lng':28.08940}},
{'stp':626010,'address':'626010, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THEMBI S PLACE, 5153 STAND NO, 40 15TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10175,'lng':28.10063}},
{'stp':626018,'address':'626018, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PATRICIAS PLACE, 162 2nd Avenue, Alexandra, Randburg, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11560,'lng':28.09160}},
{'stp':626269,'address':'626269, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NTOMBIS PLACE, 160 4th Avenue, Alexandra, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11490,'lng':28.09350}},
{'stp':626294,'address':'626294, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DANCE SEVEN, NO 2 LANE ROAD, DUNSEVERN, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12702,'lng':28.11768}},
{'stp':626712,'address':'626712, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KAYS PLACE, NO 5 10TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09960,'lng':28.09440}},
{'stp':626840,'address':'626840, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KAMELA S PLACE, 11 - 15TH AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09847,'lng':28.09930}},
{'stp':626861,'address':'626861, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KOKWANAS PLACE, 2 14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09772,'lng':28.09822}},
{'stp':626868,'address':'626868, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TINYIKO SHEBEEN, 4476 EXT 8, FAR EAST BANK, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09280,'lng':28.10840}},
{'stp':626986,'address':'626986, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TWENTYNINE INN, 29-14TH AVENUE, ALEXANDRA TOWNSHIP, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10070,'lng':28.09903}},
{'stp':627309,'address':'627309, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, L AND J SORGHUM BEER, ERF 03 BLOCK 58, 97 2ND AVENUE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11080,'lng':28.08990}},
{'stp':627653,'address':'627653, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MA DIPUO\'S PLACE, 68-8TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10680,'lng':28.09480}},
{'stp':628204,'address':'628204, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAKIS INN, 58 9TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10560,'lng':28.09550}},
{'stp':629710,'address':'629710, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RED HOUSE TAVERN, 140 - 16th AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11030,'lng':28.10470}},
{'stp':630320,'address':'630320, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ADAMS PLACE, 48 17TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10187,'lng':28.10282}},
{'stp':631399,'address':'631399, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, STEVE\'S PLACE, 98-18TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10610,'lng':28.10540}},
{'stp':632782,'address':'632782, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NORMAN\'S TAVERN, 68-4TH AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10806,'lng':28.09126}},
{'stp':632855,'address':'632855, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALFIADO PUB, STAND 200 1ST STREET, WYNBERG, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11330,'lng':28.08980}},
{'stp':634566,'address':'634566, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MONS TAVERN, 3-4TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10100,'lng':28.08840}},
{'stp':634678,'address':'634678, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOKOMANE, 16 9TH AVENUE, ALEXANDRA, JOHANNESBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10070,'lng':28.09370}},
{'stp':640229,'address':'640229, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NOMSAS PLACE, 4-16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09727,'lng':28.10029}},
{'stp':642119,'address':'642119, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VIMBA TAVERN, 113 3RD AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11150,'lng':28.09150}},
{'stp':642132,'address':'642132, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PATS PLACE, 70 11TH AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10640,'lng':28.09790}},
{'stp':642601,'address':'642601, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HLANGANANI\'S PLACE, 5489 GAME STREET, EXT 7, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08570,'lng':28.11120}},
{'stp':646685,'address':'646685, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MATSEKES PLACE, 53 8TH AVENUE, 8TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10520,'lng':28.09430}},
{'stp':646815,'address':'646815, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PHALAS TAVERN, 15 LEOPARD STREET, EAST BANK, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09580,'lng':28.10660}},
{'stp':647285,'address':'647285, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JACK\'S PLACE, 56 4TH AVENUE, 4TH AVENUE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10670,'lng':28.09060}},
{'stp':647413,'address':'647413, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NGOBENIS CORNER, 5379 EXT 7, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08750,'lng':28.11230}},
{'stp':647510,'address':'647510, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JACK\'S INN, 23 13TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10060,'lng':28.09760}},
{'stp':647632,'address':'647632, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THOBEKA\'S PLACE, 5472 GAME STREET EXT 7, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08670,'lng':28.11190}},
{'stp':650714,'address':'650714, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RAMPIS PLACE, 62 2ND AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10024,'lng':28.08900}},
{'stp':653332,'address':'653332, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PROGRESS INN, 134-4TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11330,'lng':28.09270}},
{'stp':653496,'address':'653496, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAPUTHAS TUCK SHOP, 5 14TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09810,'lng':28.09790}},
{'stp':654502,'address':'654502, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VUKUZENZELE TUCKSHOP, 11 8TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10070,'lng':28.09260}},
{'stp':654504,'address':'654504, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RICHARDS TAVERN, G242 CEMENTRY CENTRE, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09645,'lng':28.10933}},
{'stp':663495,'address':'663495, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KHAYA S PLACE, 131 4TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11310,'lng':28.09230}},
{'stp':668382,'address':'668382, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GRANTS TAVERN, 130 RIVER PARK DRIVW, LOMBARDY,ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10880,'lng':28.11850}},
{'stp':669464,'address':'669464, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MASIYE RESTAURANT, SHOP NUMBER 4,632, LOUIS BOTHA BRAMLEY, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12265,'lng':28.08464}},
{'stp':700801,'address':'700801, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TODANIS TAVERN, 1 BLUEGUM STREET, PHASE 2 RIVERPARK, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10759,'lng':28.11714}},
{'stp':704425,'address':'704425, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TEXAS RESTAURANT AND TAKE AWAY, 603 LUIS BOTHA AVENUE, CNR EDEN AND RAUMA STREET, STAND 217 BRAMLEY, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12319,'lng':28.08428}},
{'stp':710321,'address':'710321, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DALA S VENUE, ROOM 8 AND 8A, NO 1 10TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09950,'lng':28.09423}},
{'stp':711902,'address':'711902, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIRATES INN, 1711 FAR EAST BANK, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10216,'lng':28.11648}},
{'stp':755191,'address':'755191, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWAITO S  BAR, STAND NO, 59-1ST AVENUE ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10185,'lng':28.08657}},
{'stp':771131,'address':'771131, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIYABONGA TUCKSHOP, 0118 STAND NO, 3RD AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11162,'lng':28.09117}},
{'stp':780967,'address':'780967, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALIS CORNER, 165-8 AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10612,'lng':28.09483}},
{'stp':795123,'address':'795123, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, YENDE\'S PLACE, STAND NO, 16-11TH AVENUE ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10014,'lng':28.09568}},
{'stp':311584,'address':'311584, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, IMMIGRANT BAR (NTO), 90 DE KORTE STREET, BRAAMFONTEIN, #, CBD, South Africa','geo':{'lat':-26.19375,'lng':28.03640}},
{'stp':312684,'address':'312684, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NORTHPARK PUB (NTO), 2 BOLTON ROAD, ERF NUMBER 301,  PARKWOOD, #, HYDE PARK, South Africa','geo':{'lat':-26.14909,'lng':28.03534}},
{'stp':319716,'address':'319716, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PERRON (NTO), 119 ILLOVO JUNCTION, 1 CORLETT DRIV, ILLOVO, #, HYDE PARK, South Africa','geo':{'lat':-26.13505,'lng':28.04831}},
{'stp':384860,'address':'384860, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, REPUBLIC OF 94 (NTO), ERF 2491/2483, 94 JUTA STREET, BRAAMFONTEIN, #, CBD, South Africa','geo':{'lat':-26.19450,'lng':28.03688}},
{'stp':389130,'address':'389130, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WITS RUGBY CLUB (NTO), EAST CAMPUS, ERF:401, BRAAMFONTEIN NUMBER 53 IR, #, CBD, South Africa','geo':{'lat':-26.18733,'lng':28.03124}},
{'stp':389595,'address':'389595, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ARTIVIST (NTO), SHOP NO: 14 CLIFTON HEIGHTS BUILDIN, SHOP NO: 1 GROUND FLOOR, BASEMENT, NO:87 DE KORTE STREET, BRAAMFONTEIN, CBD, South Africa','geo':{'lat':-26.19330,'lng':28.03627}},
{'stp':389629,'address':'389629, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MESH (NTO), SHOP NO:11 ENTIRE SECOND FLOOR, THE TRUMPET BUILDING, 21 KEYES AVENUE, ROSEBANK, PORTION 6 OF ERF:159, ROSEBANK, RANDBURG, South Africa','geo':{'lat':-26.14361,'lng':28.03647}},
{'stp':390687,'address':'390687, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE WING REPUBLIC, 2470 ERF NO, #, #, CBD, South Africa','geo':{'lat':-26.19478,'lng':28.03511}},
{'stp':391177,'address':'391177, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COALITION (NTO), NO:2 BOLTON ROAD, PARKWOOD, 301/00, #, RANDBURG, South Africa','geo':{'lat':-26.14929,'lng':28.03493}},
{'stp':391196,'address':'391196, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, A STREET BAR NAMED DESIRE (NTO), ERF 302, UNIT 2 PARKWOOD MANSIONS, 144 JAN SMUTS AVENUE, #, RANDBURG, South Africa','geo':{'lat':-26.14940,'lng':28.03463}},
{'stp':391199,'address':'391199, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BLAQUE WINE BAR AND GRILLHOUSE (NTO, 142 JAN SMUTS, ROSEBANK, #, RANDBURG, South Africa','geo':{'lat':-26.14965,'lng':28.03453}},
{'stp':391756,'address':'391756, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GRAPPINO RESTAURANT (NTO), ERF:328, SHOP NO: 3 ZAPION COURT, NO:20 SCOTT STREET CORNER KNOX STREET, W/LEY, WESTGATE, HYDE PARK, South Africa','geo':{'lat':-26.12937,'lng':28.07418}},
{'stp':392576,'address':'392576, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MYTHOS ROSEBANK (NTO), G07 ERF 245, TYRWHITT AVE, ROSEBANK, #, HYDE PARK, South Africa','geo':{'lat':-26.14544,'lng':28.02302}},
{'stp':392579,'address':'392579, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GHAZAL - MELROSE ARCH (NTO), SHOP 2 MELROSE SQUARE OFF MELROSE B, AND HIGH STREET, MELROSE PRECINCT, MELROSE NORTH, ERF:64, MELROSE NORTH, HYDE PARK, South Africa','geo':{'lat':-26.13407,'lng':28.06637}},
{'stp':393034,'address':'393034, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ORYANA BAKERY (NTO), ERF:82, SHOP NO:4, GROUND FLOOR, FIRST PLACE, CORNER 1ST AVENUE, 7 STANDA, PINE PARK EXTENSION 1, RANDBURG, South Africa','geo':{'lat':-26.12885,'lng':28.00010}},
{'stp':393066,'address':'393066, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE ROCK - POOL LOUNG (NTO), THE MANHATTAN, GROUND FLOOR & BASEMENT, NO:17 BICCARD STREET, CORNER SMIT STREET, HILLBROW, South Africa','geo':{'lat':-26.19487,'lng':28.03659}},
{'stp':393114,'address':'393114, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE CAPITAL - MELROSE (NTO), 61 ATHOL OAKLANDS ROAD, CORNER OR ATHOL OAKLANDS ROAD AND, KERNICK AVENUE, STAND 188 MELROSE NORTH, HYDE PARK, South Africa','geo':{'lat':-26.13579,'lng':28.04997}},
{'stp':393315,'address':'393315, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, METANOIA (NTO), ERF 4490, GROUND FLOOR, NO:73 JUTA STREET, CORNER JUTA & DE BEER STREETS, HILLBROW, South Africa','geo':{'lat':-26.19454,'lng':28.03476}},
{'stp':460743,'address':'460743, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VELO CAFE, 4495 ERF, SHOP 2 ARGON HOUSE 87 JUDA STREET, #, HILLBROW, South Africa','geo':{'lat':-26.19413,'lng':28.03628}},
{'stp':461639,'address':'461639, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MIMMOS BRAAMFONTEIN (NTO), AUCKLAND PLACE, SHOP NO:1, NO:185 SMIT STREET, CNR, BICCARD STR, ERF:2494, 2494A & 2495, HILLBROW, South Africa','geo':{'lat':-26.19483,'lng':28.03746}},
{'stp':473328,'address':'473328, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NEWS CAFE ROSEBANK (NTO), THE ZONE ROSEBANK SHOP NO 3, CNR CR, #, #, HYDE PARK, South Africa','geo':{'lat':-26.14491,'lng':28.04242}},
{'stp':473390,'address':'473390, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WESTCLIFF HOTEL (NTO), 67 JAN SMUTS AVE, WESTCLIFF, JOHANN, #, #, RANDBURG, South Africa','geo':{'lat':-26.11439,'lng':28.02261}},
{'stp':475615,'address':'475615, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DISCOVERY SOCCER PARK (NTO), BOUNDED BY CORLETT DRIVE, RUDD ROAD, NORTH RD & RUGBY, PAVILLION PORTION 281, #, HYDE PARK, South Africa','geo':{'lat':-26.13371,'lng':28.05487}},
{'stp':477045,'address':'477045, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE BANISTER HOTEL (NTO), ERF:2791, NO:9 DE BEER STREET, BRAAMFONTEIN, #, HILLBROW, South Africa','geo':{'lat':-26.19402,'lng':28.03462}},
{'stp':477046,'address':'477046, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SMOKEHOUSE GRILL (NTO), CORNER JUTA & DE BEER STREETS, BRAAMFONTEIN, #, HILLBROW, South Africa','geo':{'lat':-26.19433,'lng':28.03503}},
{'stp':477202,'address':'477202, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCOMAMA\'S ROSEBANK (NTO), THE ZONE ROSEBANK, CNR TYRWHITT & C, #, #, RANDBURG, South Africa','geo':{'lat':-26.14477,'lng':28.04230}},
{'stp':478091,'address':'478091, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROAST CAFE (NTO), SHOP 10A, HIGH STREET, MELROSE ARCH, JOHANNESBURG, RANDBURG, South Africa','geo':{'lat':-26.13322,'lng':28.06816}},
{'stp':486579,'address':'486579, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NORMAN GOODFELLOWS WYNBERG, 473 ERF NO, ROURTH STREET, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10722,'lng':28.08219}},
{'stp':487638,'address':'487638, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARBLE RESTAURANT (NTO), Cr Keyes Ave and Jellicoe , Roseban, #, #, HYDE PARK, South Africa','geo':{'lat':-26.14335,'lng':28.03647}},
{'stp':498788,'address':'498788, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BRIAN LARA (NTO), NO: 56, 4TH AVENUE, LINDEN, #, RANDBURG, South Africa','geo':{'lat':-26.14089,'lng':27.99256}},
{'stp':550044,'address':'550044, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MIMMOS BERGBRON, THE BERG SHOPPING CENTRE, CNR GORDON RD & BERGBRON DR, BERGBRON EXT 6, NORTHCLIFF, South Africa','geo':{'lat':-26.16300,'lng':27.95200}},
{'stp':550087,'address':'550087, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARK HYATT HOTEL, CNR OXFORD & BIERMAN ROADS, ROSEBANK, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.14400,'lng':28.04360}},
{'stp':550414,'address':'550414, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROSEBANK HOTEL CROWN PLAZA JOHANNES, STAND NO, CORNER OF TYRWHITT AND STURDEE AVENUE, NO 11, HYDE PARK, South Africa','geo':{'lat':-26.14482,'lng':28.03843}},
{'stp':550542,'address':'550542, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PROTEA HOTEL MELROSE ARCH, CNR OLD HOSE LANE & HIGH STR, MELROSE NORTH, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.13493,'lng':28.06812}},
{'stp':550579,'address':'550579, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WANDERERS GOLF CLUB, NORTH STREET AND CORLETT DRIVE, ILLOVO, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.13340,'lng':28.05240}},
{'stp':550695,'address':'550695, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOYO RESTUARANT, SHOP NO 5, GROUND FLOOR MELROSE SQUARE, MELROSE ARCH, HYDE PARK, South Africa','geo':{'lat':-26.13396,'lng':28.06819}},
{'stp':550838,'address':'550838, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LINDEN BOWLING CLUB, CNR 5 TH, COSMOS AVE & 3RD ST, EMMA PARK, LINDEN, RANDBURG, South Africa','geo':{'lat':-26.13790,'lng':27.98430}},
{'stp':551279,'address':'551279, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NINOS ITALIAN COFFEE ROSEBANK, SHOP 10A-C REGENT PLACE, CRADOCK AVE, MUTUAL GARDENS, HYDE PARK, South Africa','geo':{'lat':-26.14619,'lng':28.04258}},
{'stp':551321,'address':'551321, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PROTEA CLUB, STAND NO OF RE PORTION 33,FARM, BRAAMFONTEIN 53 IR,CNR ILLOVO, & ORANGE STREETS, EMMARENTIA, MELVILLE, South Africa','geo':{'lat':-26.16177,'lng':28.00481}},
{'stp':551419,'address':'551419, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DEVONSHIRE HOTEL, 49 CNR MELLE & DEVONSHIRE STR, ERF 4556, JHB, CBD, South Africa','geo':{'lat':-26.19240,'lng':28.03570}},
{'stp':551456,'address':'551456, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CASTLE WALK NORTHCLIFF, SHOPS 36 & 37, NORTHCLIFF CORNER CENTRE, CNR MULNER & BEYERS NAUDE, NORTHCLIFF, South Africa','geo':{'lat':-26.14520,'lng':27.98360}},
{'stp':551508,'address':'551508, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ZOO LAKE BOWLING CLUB, PRINCE OF WALES AVE, PARKWOOD, JOHANNESBURG, MELVILLE, South Africa','geo':{'lat':-26.16230,'lng':28.03310}},
{'stp':551588,'address':'551588, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TRANSVAAL AUTOMOBILE CLUB, COR 5TH & 14TH STREETS, HOUGHTON ESTATE, #, HYDE PARK, South Africa','geo':{'lat':-26.15170,'lng':28.05410}},
{'stp':551609,'address':'551609, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE GRILL HOUSE, THE FIRS BOUNDED BY OXFORD RD, BIERMAN AND CRADOCK AVE, ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14360,'lng':28.04253}},
{'stp':551732,'address':'551732, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JB S CORNER RESTAURANT, ERF 50 61 SHOP 1 BUILDING I 3, CNR CORLETT  AND ATHOL OAKLANDS, MELROSE NORTH, HYDE PARK, South Africa','geo':{'lat':-26.13411,'lng':28.06874}},
{'stp':551778,'address':'551778, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARKTONIAN ALL SUITE HOTEL, 120 DE KORTE STREET, BRAAMFONTEIN, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19400,'lng':28.03960}},
{'stp':552806,'address':'552806, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE FUN COMPANY, GALAXY WORLD, 1ST FLOOR, ZONE SHOPPING CENTRE, ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14603,'lng':28.04319}},
{'stp':552887,'address':'552887, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PROTEA HOTEL WANDERERS, CNR CORLETTE DRIVE & RUDD ROAD, ILLOVO, RANDBURG, HYDE PARK, South Africa','geo':{'lat':-26.13422,'lng':28.05223}},
{'stp':553281,'address':'553281, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARKS PARK CLUB, 30 ORANGE ROAD, EMMERENTIA, JOHANNESBURG, MELVILLE, South Africa','geo':{'lat':-26.16490,'lng':28.00423}},
{'stp':553285,'address':'553285, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TRABELLA RESTAURANT, SHOP 3, GRD FLOOR, GALEN HSE, PORT 1 OXFORD RD, CRN CORLETT DR, ILLOVO,, HYDE PARK, South Africa','geo':{'lat':-26.13460,'lng':28.04850}},
{'stp':553558,'address':'553558, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MCCOY S SPORTS DINER, 1 JOHAN MEYER STREET, LINMEYER, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.25770,'lng':28.06750}},
{'stp':553668,'address':'553668, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE IRISH CLUB, CNR 5TH AVE & 4TH ST, (EMMA PARK)LINDEN, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.13930,'lng':27.98460}},
{'stp':607074,'address':'607074, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SILVASALE EVENTS AND CATERING, 21 NORTH STREET, ILLOVO, #, HYDE PARK, South Africa','geo':{'lat':-26.13557,'lng':28.05457}},
{'stp':615565,'address':'615565, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BELLINI\'S (NTO), 2 CHAPLIN ROAD, ILLOVO, #, HYDE PARK, South Africa','geo':{'lat':-26.13185,'lng':28.04810}},
{'stp':618148,'address':'618148, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, OCEAN BASKET ROSEBANK (NTO), MUTUAL GARDENS, ROSEBANK, Johannesburg, HYDE PARK, South Africa','geo':{'lat':-26.14583,'lng':28.04264}},
{'stp':625711,'address':'625711, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE WANDERERS RUGBY CLUB (NTO), THE WANDERERS, CORLETTE DRIVE,, FAIRWAY, Johannesburg, HYDE PARK, South Africa','geo':{'lat':-26.13175,'lng':28.06065}},
{'stp':626035,'address':'626035, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DOPPIO ZERO GREENSIDE, CNR BARRY HERTZOG AND GLENEAGLES RD, GREENSIDE, #, MELVILLE, South Africa','geo':{'lat':-26.14672,'lng':28.00935}},
{'stp':646764,'address':'646764, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CIVIC THEATRE RESTAURANT (NTO), CNR HOOFD & SIMONDS STR, BRAAMFONTEIN, #, CBD, South Africa','geo':{'lat':-26.19170,'lng':28.03820}},
{'stp':657583,'address':'657583, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DARKIE CAFE (NTO), 10 ANDERSON STREET, MARSHALTOWN, MARSHALTOWN, CBD, South Africa','geo':{'lat':-26.20870,'lng':28.03662}},
{'stp':658631,'address':'658631, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE VENUE MELROSE ARCH (NTO), MELROSE ARCH, ALEXANDRA, ALEXANDRA, HYDE PARK, South Africa','geo':{'lat':-26.13324,'lng':28.06846}},
{'stp':659162,'address':'659162, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ZOO LAKE SPORTS CLUB, CNR WESTWOLD WAY AND, GLAMORGAN ROADS, PORTION 37 OF THE FARM BRAAMFONTEIN 53IR, MELVILLE, South Africa','geo':{'lat':-26.15470,'lng':28.03030}},
{'stp':659834,'address':'659834, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE GRILLHOUSE MELROSE (NTO), 24 THE PIAZZA SHOP HL20 & HL21, MELROSE ARCH, MELROSE ARCH, HYDE PARK, South Africa','geo':{'lat':-26.13130,'lng':28.06870}},
{'stp':664472,'address':'664472, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIZA E VINO MELROSE ARCH (NTO), THE PIAZZA, MELROSE ARCH, CORLETT DRIVE MELROSE, HYDE PARK, South Africa','geo':{'lat':-26.13173,'lng':28.06841}},
{'stp':664473,'address':'664473, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIGALLE MELROSE ARCH (NTO), THE PIAZZA, MELROSE ARCH, CORLETT DRIVE MELROSE, HYDE PARK, South Africa','geo':{'lat':-26.13129,'lng':28.06949}},
{'stp':664526,'address':'664526, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE ARTISAN (NTO), 12 GLENEAGLES ROAD, GREENSIDE, JHB, MELVILLE, South Africa','geo':{'lat':-26.14641,'lng':28.00946}},
{'stp':664529,'address':'664529, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BELLAGIO (NTO), CORNER OXFORD & CHAPLIN ROAD, ORMONDE, HYDE PARK, HYDE PARK, South Africa','geo':{'lat':-26.13104,'lng':28.04956}},
{'stp':665398,'address':'665398, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PAREA TAVERN GRILL MEZE CAFE (NTO), NO 3 CORLETT DRIVE, ILLOVO, ILLOVO, HYDE PARK, South Africa','geo':{'lat':-26.13510,'lng':28.04864}},
{'stp':665399,'address':'665399, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GRATE (NTO), SHOP 1, FRICKED BLVRD, ILLOVO, ILLOVO, HYDE PARK, South Africa','geo':{'lat':-26.13571,'lng':28.04520}},
{'stp':665420,'address':'665420, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAMAS SHEBEEN (NTO), 18 GLENEAGLES RD, GREENSIDE, GREENSIDE, MELVILLE, South Africa','geo':{'lat':-26.14611,'lng':28.00995}},
{'stp':668627,'address':'668627, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, OCEAN BASKET MELROSE ARCH (NTO), SHOP2/4 MELROSE SQUARE, GOUDVIS ROA, MELROSE ARCH, MELROSE, MORNINGSIDE, South Africa','geo':{'lat':-26.13391,'lng':28.06797}},
{'stp':668628,'address':'668628, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WINESENSE (NTO), 11 THE HIGH STREET, MELROSE ARCH, MELROSE, HYDE PARK, South Africa','geo':{'lat':-26.13358,'lng':28.06820}},
{'stp':668629,'address':'668629, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MEZEPOLI (NTO), SHOP HL26 MELROSE ARCH SHOPPING CEN, BOUNDED BY WHITELEY RD, MELROSE HIGH ST, PORTION 5 OF ERF 181 MELROSE NORTH, HYDE PARK, South Africa','geo':{'lat':-26.13151,'lng':28.06859}},
{'stp':669506,'address':'669506, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SARASOTA SPUR (NTO), 100 JORISSON STREET, BRAAMFONTEIN, JHB, CBD, South Africa','geo':{'lat':-26.19261,'lng':28.03743}},
{'stp':682568,'address':'682568, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PROTEA HOTEL FIRE AND ICE MELROSE A, 22 WHITELEY ROAD, PROTEA HOTEL MELROSE ARCH, MELROSE NORTH, HYDE PARK, South Africa','geo':{'lat':-26.13174,'lng':28.06754}},
{'stp':685515,'address':'685515, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HOLIDAY INN ROSEBANK (NTO), OXFORD ROAD, THE ZONE SHOPPING CENTRE, ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14400,'lng':28.04360}},
{'stp':687591,'address':'687591, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HUSH/KONG (NTO), 160 JAN SMUTS AVENUE, DESIGN DISTRICT, ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14541,'lng':28.03578}},
{'stp':690387,'address':'690387, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WAVERLEY BOWLING CLUB (NTO), CNR STERLING & JOUNCEY STREET, WAVERLEY, JOHANNESBURG, GILLOOLYS, South Africa','geo':{'lat':-26.13823,'lng':28.07202}},
{'stp':690527,'address':'690527, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE OFFICE (NTO), SHOP NO 5 GREENSIDE QUARTER CORNER, BARRY HERTZOG AND GLENEAGLES ROAD, GREENSIDE ERF NO 210, GREENSIDE, MELVILLE, South Africa','geo':{'lat':-26.14566,'lng':28.00971}},
{'stp':694001,'address':'694001, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NORMAN GOODFELLOWS MELROSE ARCH (NT, MELROSE ARCH, CORLET DRIVE, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.12953,'lng':28.06883}},
{'stp':698358,'address':'698358, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COFI ROSEBANK (NTO), SHOP F08, THE ZONE, OXFORD STREET, ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14588,'lng':28.04276}},
{'stp':698384,'address':'698384, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PIZA E VINO ROSEBANK (NTO), THE ZONE, NEW SECTION, OXFORD STREET, ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14588,'lng':28.04276}},
{'stp':698606,'address':'698606, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SPATADA (NTO), SHOP 20-21 THE FIRS, ROSEBANK, ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14408,'lng':28.04229}},
{'stp':700535,'address':'700535, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DOPPIO ZERO ROSEBANK, THE FIRS SHOP 18, CNR CRADOCK AVE AND BIERMAN AVE, #, HYDE PARK, South Africa','geo':{'lat':-26.14409,'lng':28.04209}},
{'stp':704111,'address':'704111, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOYO ZOO LAKE, 1 PRINCE OF WALES DRIVE, #, #, HYDE PARK, South Africa','geo':{'lat':-26.15764,'lng':28.02975}},
{'stp':704486,'address':'704486, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SOPHIA\'S BISTRO (NTO), SHOP7 ILLOVO SQUARE, 3 RIVONIA ROAD, ILLOVO, HYDE PARK, South Africa','geo':{'lat':-26.12769,'lng':28.04913}},
{'stp':704852,'address':'704852, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FISHMONGER ROSEBANK (NTO), THE FIRS SHOPPING CENTRE, CNR CRADOCK & BIERWANN AVE, ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14398,'lng':28.04240}},
{'stp':710001,'address':'710001, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TOPO GIGIO (NTO), SHOP NO 2, NO 12 GLEN EAGLES ROAD, GREENSIDE, #, MELVILLE, South Africa','geo':{'lat':-26.14658,'lng':28.00874}},
{'stp':719144,'address':'719144, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LA VIE EN ROSE (NTO), KILLARNEY COUNTRY CLUB, CNR 5TH & 14TH STREETS, HOUGHTON ESTATE, HYDE PARK, South Africa','geo':{'lat':-26.14939,'lng':28.05442}},
{'stp':720169,'address':'720169, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PG CLUB (NTO), THE WEST CAMPUS AT THE TOP OF, YALE ROAD, BOUNDED BY JAN SMUTS AVE, BRAAMFONTEIN, CBD, South Africa','geo':{'lat':-26.18988,'lng':28.03202}},
{'stp':729113,'address':'729113, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KITCHENER\'S CARVERY BAR (NTO), 71 JUTA STREET, BRAAMFONTEIN, #, YEOVILLE, South Africa','geo':{'lat':-26.19433,'lng':28.03485}},
{'stp':745730,'address':'745730, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GREAT DANE (NTO), 5 DE BEER STREET, ERF 2783 DE BEER STREET BUILDING, #, YEOVILLE, South Africa','geo':{'lat':-26.19433,'lng':28.03485}},
{'stp':749975,'address':'749975, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DUKES BURGERS (NTO), 14 GLENEAGLES RD, #, #, HYDE PARK, South Africa','geo':{'lat':-26.14621,'lng':28.00961}},
{'stp':757114,'address':'757114, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE GREENSPOT RESTAURANT, STAND NO, 217 AND 218 SHOP NO 2, ALZAACK CENTRE, ROSETTENVILLE, South Africa','geo':{'lat':-26.25830,'lng':28.06765}},
{'stp':766147,'address':'766147, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE GRIFFIN (NTO), CORNER CORLETT & OXFORD ROAD, ILLOVO, ILLOVO, HYDE PARK, South Africa','geo':{'lat':-26.13541,'lng':28.04781}},
{'stp':766847,'address':'766847, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROSEBANK MEWS CAFE, STAND NO, SHOP L1 AND L2 THE ROSEBANK MEWS, 173 OXFORD ROAD ROSEBANK, HYDE PARK, South Africa','geo':{'lat':-26.14586,'lng':28.04396}},
{'stp':770878,'address':'770878, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LETS GO BOWLING NORTHCLIFF, 239/289 STAND NO, SHOPS 39 AND 39A LOWER LEVEL, NORTHCLIFF SHOPPING CENTRE CORNER BEYERS, NORTHCLIFF, South Africa','geo':{'lat':-26.14546,'lng':27.98415}},
{'stp':772751,'address':'772751, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NORMAN GOODFELLOWS ILLOVO  BOTTLE S, 192 STAND NO, ERF 112 OXFORD ROAD, ILLOVO JOHANNESBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.13174,'lng':28.04896}},
{'stp':782035,'address':'782035, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CHURCHILLS (NTO), SHOP 52 HL, MELROSE ARCH PIAZZA, CORLETT DRIVE, HYDE PARK, South Africa','geo':{'lat':-26.12957,'lng':28.06883}},
{'stp':789171,'address':'789171, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, 86 PUBLIC (NTO), 94 JUTA STREET, ERF 2491/2483, BRAAMFONTEIN, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.19434,'lng':28.03604}},
{'stp':790921,'address':'790921, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WARLOCKS CLUB HOUSE (NTO), 34, 11TH STREET, ROSETTENVILLE, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.23631,'lng':28.05729}},
{'stp':388042,'address':'388042, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LINDIWE S PLACE, 56-12 STAND NO, AVENUE, ALEXENDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10412,'lng':28.09818}},
{'stp':461042,'address':'461042, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SKHOTHANES PLACE, 197 STAND NO, 1 ST AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11204,'lng':28.08974}},
{'stp':462103,'address':'462103, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DAVIDS PLACE, 75 STAND NO, 16TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10475,'lng':28.10281}},
{'stp':463691,'address':'463691, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ANGIES PALCE, 7291 STAND NO, EXTENSION 9 FAR EAST BANK, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09802,'lng':28.11013}},
{'stp':463946,'address':'463946, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MALULEKES PLACE, 8004 STAND NO, EXTENSION 10, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08925,'lng':28.11536}},
{'stp':464008,'address':'464008, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MADISA, 7219 STAND NO, EXTENSION 9, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10625,'lng':28.11457}},
{'stp':464631,'address':'464631, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PINKIS PLACE, 18 STAND NO, 2ND AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10302,'lng':28.08701}},
{'stp':464663,'address':'464663, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BONGANIS INN, 141 STAND NI, 14TH AVENUE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11072,'lng':28.10271}},
{'stp':545059,'address':'545059, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CKS TAVERN, 59 STAND NO, 1OTH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10617,'lng':28.09673}},
{'stp':545069,'address':'545069, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TANDISWAS PLACE, 58 STAND NO, 9TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10549,'lng':28.09541}},
{'stp':547103,'address':'547103, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOHNS PLACE, 58 STAND NO, 13TH AVENUE, ALEXENDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10096,'lng':28.09811}},
{'stp':549102,'address':'549102, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MJAVAS PLACE, 9 STAND NO, 6TH AVENUE, ALEXENDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.11073,'lng':28.08842}},
{'stp':549153,'address':'549153, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LUNGIS SHEBEEN, 4701 STAND NO, SOCCER STREET, EXT 7 ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08665,'lng':28.11078}},
{'stp':550078,'address':'550078, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KOPANENG BIG TIME TAVERN, 137 10TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11190,'lng':28.09860}},
{'stp':551696,'address':'551696, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SELLO S PLACE, 42-12 TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10280,'lng':28.09770}},
{'stp':580439,'address':'580439, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BOIKI INN, 36  4TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10440,'lng':28.08970}},
{'stp':581115,'address':'581115, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAVUNDA SM, 32 - 16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10050,'lng':28.10110}},
{'stp':604097,'address':'604097, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MABUZA RM, 31 - 15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10090,'lng':28.10030}},
{'stp':604904,'address':'604904, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NXUMALO NJ, 117 - 16tH AVENUE, STAND NO 01754-007), #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10830,'lng':28.10400}},
{'stp':605452,'address':'605452, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NETSHIVHONGWENI LJ, 136 - 14TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11070,'lng':28.10270}},
{'stp':605465,'address':'605465, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MABELE J, 23 - 16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09980,'lng':28.10060}},
{'stp':607576,'address':'607576, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GRACELAND TAVERN, 101 STAND NO, 2ND AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11013,'lng':28.09180}},
{'stp':626267,'address':'626267, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SELLOS PLACE NO 2, 22 - 14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11096,'lng':28.10281}},
{'stp':626340,'address':'626340, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, QUEENS PLACE, 52-16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10280,'lng':28.10190}},
{'stp':626367,'address':'626367, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROBERTS PLACE, 51 15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10281,'lng':28.10093}},
{'stp':626838,'address':'626838, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GAMA\'S INN, NO 63-12TH AVENUE, ALAXANDER, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10520,'lng':28.09850}},
{'stp':626869,'address':'626869, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THEMBIS PLACE, 66 -18TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10411,'lng':28.10461}},
{'stp':626956,'address':'626956, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RAYS PLACE, 3 - 15th AVE, ALEXANDRA RANDBURG, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09770,'lng':28.09900}},
{'stp':627018,'address':'627018, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWA MFUNDISI, 18 15TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09925,'lng':28.09956}},
{'stp':627132,'address':'627132, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NTABO SHEBEEN, 5 12TH AVE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09900,'lng':28.09620}},
{'stp':627586,'address':'627586, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAZALENIS PLACE, 54-14TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10360,'lng':28.10010}},
{'stp':628174,'address':'628174, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, IKES PLACE, 73-12TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10640,'lng':28.09890}},
{'stp':628188,'address':'628188, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NOMVULAS PLACE, 19 16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09920,'lng':28.10070}},
{'stp':628390,'address':'628390, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KEEP IT COMING, 125 14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10960,'lng':28.10230}},
{'stp':628989,'address':'628989, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIBS PLACE, 159 9th AVE, ALEXANDRA, RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11365,'lng':28.09829}},
{'stp':629025,'address':'629025, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BHEKINDABAZAKHO INN, 117 16TH AVAENU, ALEXANDRA, JOHANNESBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10851,'lng':28.10402}},
{'stp':631187,'address':'631187, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MUGWABANA INN, 98 - 14TH AVENUE, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10424,'lng':28.10029}},
{'stp':631631,'address':'631631, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIMPIWE\'S SHEBEEN, 138 - 15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11020,'lng':28.10370}},
{'stp':631788,'address':'631788, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, OSIZWENI, 48-14 AVENUE, ALEXANDRA, JOHANNESBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10210,'lng':28.09946}},
{'stp':632845,'address':'632845, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAKGOLO\'S INN, 22-14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09970,'lng':28.09870}},
{'stp':633151,'address':'633151, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAVIS PLACE, 28-15TH AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10020,'lng':28.09990}},
{'stp':633154,'address':'633154, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MONGO\'S PLACE, 5-13TH AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09850,'lng':28.09720}},
{'stp':633397,'address':'633397, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LENNYS INN, 123 STAND NO, 15TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10615,'lng':28.10533}},
{'stp':633610,'address':'633610, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MZEBAS PLACE, 149 15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10871,'lng':28.10052}},
{'stp':634440,'address':'634440, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JABUS PLACE, 286 PHASE 2, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10040,'lng':28.08820}},
{'stp':635658,'address':'635658, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MPANENG SHEBEEN,  17-16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09890,'lng':28.10060}},
{'stp':635760,'address':'635760, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KGOTSONG SHEBEEN, 42-10TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10330,'lng':28.09570}},
{'stp':637103,'address':'637103, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAKIYA\'S SHEBEEN, 146 14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10557,'lng':28.10407}},
{'stp':638873,'address':'638873, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MA WILLIES, 26 14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10228,'lng':28.09951}},
{'stp':638874,'address':'638874, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SM INN, 100 15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10750,'lng':28.10260}},
{'stp':638896,'address':'638896, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LUGAYENI, 44 14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10265,'lng':28.10013}},
{'stp':639877,'address':'639877, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NKOSI\'S PLACE, 51-13TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10370,'lng':28.09900}},
{'stp':641160,'address':'641160, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ZIZAMELE TUCK SHOP, 49-13TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10340,'lng':28.09900}},
{'stp':641893,'address':'641893, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ANDREW\'S PLACE, 51 13TH AVENUE NO, -, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10350,'lng':28.09900}},
{'stp':642131,'address':'642131, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAVINGO\'S PLACE, L2 PALACE COURT, 12 AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10878,'lng':28.10013}},
{'stp':642705,'address':'642705, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MPOTO\'S PLACE, 57 13TH AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10430,'lng':28.09930}},
{'stp':642709,'address':'642709, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SABI\'S PLACE, 36 12TH AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10200,'lng':28.09740}},
{'stp':642710,'address':'642710, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MABOHOS PLACE, 71 PHASE 1, ALEXANDRA, RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10990,'lng':28.09840}},
{'stp':646338,'address':'646338, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JERRY\'S TAVERN, 60 15TH AVENUE, -, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10430,'lng':28.10150}},
{'stp':646445,'address':'646445, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JIMBO\'S CORNER, 46 11 AVENUE, ALEXANDRE, ALEXANDRE, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10340,'lng':28.09680}},
{'stp':646542,'address':'646542, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MTHIMBANE PLACE, 26 13TH AVENUE, -, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10052,'lng':28.09820}},
{'stp':647792,'address':'647792, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THIBO\'S TUCK SHOP, 40-14TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10200,'lng':28.09960}},
{'stp':648023,'address':'648023, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOGALE\'S PLACE, 61 8th Avenue, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10100,'lng':28.09054}},
{'stp':649345,'address':'649345, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARCO\'S CORNER, NO 140 11TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11349,'lng':28.09944}},
{'stp':653532,'address':'653532, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MUSIC SHEBEEN, 156-9TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11340,'lng':28.09830}},
{'stp':653663,'address':'653663, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MANTWAS INN, 35 13TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10180,'lng':28.09840}},
{'stp':653816,'address':'653816, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MRS JONES PLACE, 56 14TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10370,'lng':28.10020}},
{'stp':654026,'address':'654026, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SHOMAS PLACE, 81-3RD AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10960,'lng':28.09040}},
{'stp':654669,'address':'654669, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIBS PLACE, 21 21 9TH AVENUE, STAND NO 00858 - 002, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10148,'lng':28.09387}},
{'stp':671309,'address':'671309, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DORAHS PLACE, 9 PRINCESS COURT, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10930,'lng':28.09990}},
{'stp':677198,'address':'677198, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EMGODINIS PLACE, 145 11TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10497,'lng':28.09779}},
{'stp':684782,'address':'684782, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LOYISOS PLACE (NTO), 1113 26 10TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10643,'lng':28.09682}},
{'stp':684885,'address':'684885, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FANA\'S PLACE (NTO), 35 14TH AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10164,'lng':28.09937}},
{'stp':685215,'address':'685215, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PHINDI NTSILA (NTO), 61 2ND AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10874,'lng':28.08911}},
{'stp':714676,'address':'714676, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWENAS PLACE, 1 15tH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09739,'lng':28.09675}},
{'stp':743727,'address':'743727, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KO STENENG, 92 14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10698,'lng':28.10139}},
{'stp':744429,'address':'744429, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HARRYS PLACE, 103 STAND NUMBER, 14TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10393,'lng':28.10457}},
{'stp':748348,'address':'748348, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FIKILE TUCK SHOP, 95 SHOP NO, 13TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10028,'lng':28.10211}},
{'stp':755665,'address':'755665, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, J AND P TAVERN, 20 STAND NO, 8TH AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10125,'lng':28.09290}},
{'stp':759271,'address':'759271, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MALULEKE N L, STAND NO, 27-14TH AVENUE ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.08693,'lng':28.11047}},
{'stp':760507,'address':'760507, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WINNIE, 163 STAND NO, 3RD AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11502,'lng':28.09250}},
{'stp':760554,'address':'760554, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ESTHERS PLACE, 27 16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10937,'lng':28.10117}},
{'stp':761322,'address':'761322, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KILLANEYS PLACE, STAND NO, BLOCK C, PHASE ONE, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11150,'lng':28.09793}},
{'stp':764361,'address':'764361, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIPHO, STAND NO, 36 - 4TH AVENUE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10949,'lng':28.09156}},
{'stp':768127,'address':'768127, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NTSEBE S PLACE, 2 STAND NO, PHASE 1 ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10968,'lng':28.09913}},
{'stp':771126,'address':'771126, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NGUMIKIES PLACE, 25 STAND NO,  PHASE 1 ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10668,'lng':28.10230}},
{'stp':773025,'address':'773025, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAVHUNGU PLACE, 133 STAND NO, 14 TH AVENUE ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10105,'lng':28.10130}},
{'stp':780378,'address':'780378, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MBUSO S PLACE, 0038 STAND NO, 7TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10204,'lng':28.09211}},
{'stp':780380,'address':'780380, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PUSH DOT COM, STAND NO, 7 - 8TH AVENUE, ALEXANDRA TOWNSHIP, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10066,'lng':28.09152}},
{'stp':785791,'address':'785791, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PAULS PLACE, 35 STAND NO, 7TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10361,'lng':28.09248}},
{'stp':789014,'address':'789014, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WHY NOT TUCKSHOP, 4611 STAND NO, SHIBOBO STREET EXT 7, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08680,'lng':28.10984}},
{'stp':794551,'address':'794551, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DIMAMZOS PLACE, 44 STAND NO, 7TH AVENUE ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10265,'lng':28.09227}},
{'stp':795165,'address':'795165, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KBL SHEBEEN, 123 STAND NO, 13TH AVENUE ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10315,'lng':28.09889}},
{'stp':795854,'address':'795854, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SINDIS PLACE, 5888 STAND NO, EXT 7 HALFTIME STREET ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10330,'lng':28.09764}},
{'stp':795869,'address':'795869, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MALEFELA GENERAL DEALER, 23 STAND NO, 11TH AVENUE ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10630,'lng':28.09786}},
{'stp':312483,'address':'312483, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RUMOURS ROCK CITY (NTO), ERF 38, 288 WELTERVERDEN ROAD,  BLACKHEATH, JOHANNESBURG, RANDBURG, South Africa','geo':{'lat':-26.13281,'lng':27.97488}},
{'stp':320538,'address':'320538, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MIKES KITCHEN (CRESTA) (NTO), SHOP 3, CRESTA SHOPPING MALL, CNR BEYERS NAUDE, WELTEVREDEN ROAD, #, RANDBURG, South Africa','geo':{'lat':-26.13107,'lng':27.97479}},
{'stp':388261,'address':'388261, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ANGELO\'S KITCHEN (SLOANE SQUARE) (N, 1 SLOANE SQUARE, SHOP FO 01, CNR WILLIAM NICOLE AND SLOANE, EPSON DOWNS, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.04323,'lng':28.01514}},
{'stp':388433,'address':'388433, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DIMI\'S RESTURANT (NTO), ERF 240-243 & ERF 257 SHOP NO:U47A, CRESTA SHOPPING CENTRE ,BAYERS NAUDE DR,, REPUBLIC RD,JUDGES AVE & WELTERVREDEN RD, NORTHCLIFF, South Africa','geo':{'lat':-26.13150,'lng':27.97598}},
{'stp':389212,'address':'389212, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TASHAS - NICOLWAY (NTO), ERF.5643 , SHOP NO.41D, NICOLWAY SHOPPING CENTRE, CNR WILLIAM, NICOL & WEDGELINK ROADS WEST BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.05291,'lng':28.02037}},
{'stp':391198,'address':'391198, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MUGG & BEAN - RANDBURG (NTO), SHOP NO: 205, CRESTA SHOPPING CENTR, BEYERS NAUDE DRIVE, BLACKHEATH, #, NORTHCLIFF, South Africa','geo':{'lat':-26.13102,'lng':27.97668}},
{'stp':391318,'address':'391318, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOHN DORY\'S CRESTA (NTO), SHOP NO: L204 - CRESTA SHOPPING CEN, CORNER BEYERS NAUDE AND WELTERVREDEN ROA, CRESTA EXTENSION 4, NORTHCLIFF, South Africa','geo':{'lat':-26.13162,'lng':27.97424}},
{'stp':392541,'address':'392541, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOHN DORY\'S FISH-GRILL-SUSHI CRESTA, L204 SHOP NO.CRESTA SHOPPING CENTRE, CORNER BEYERS NAUDE AND WELTER VREDEN, ROAD, CRESTA EXTENSION 4, NORTHCLIFF, South Africa','geo':{'lat':-26.13170,'lng':27.96548}},
{'stp':462177,'address':'462177, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIANES PLACE, 5414 STAND NO, MATCH STREET EXT 7, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.07448,'lng':28.10989}},
{'stp':473913,'address':'473913, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PHASHAS PLACE, 184 FAR EAST BANK, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10017,'lng':28.11428}},
{'stp':474761,'address':'474761, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MBUNGE, 222 ERF, 97 DERBY ROAD, JUDITH S PAARL  BERTRAMS, CBD, South Africa','geo':{'lat':-26.18981,'lng':28.07449}},
{'stp':475689,'address':'475689, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DUMIS INN, 191 ERF, ALBERTOS STREET, ORMONDE VIEW, RANDBURG, South Africa','geo':{'lat':-26.24785,'lng':27.98237}},
{'stp':480891,'address':'480891, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DYLAN\'S RESTAURANT (NTO), CNR 12TH & OLYMPIA AVENUE, PARKMORE, #, #, HILLBROW, South Africa','geo':{'lat':-26.09246,'lng':28.03611}},
{'stp':484276,'address':'484276, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GOLD RUSH CRESTA (NTO), SHOP B02 CRESTA SHOPPING MAL, JUDGE, #, #, RANDBURG, South Africa','geo':{'lat':-26.12959,'lng':27.97316}},
{'stp':486378,'address':'486378, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWANTU AFRICAN CUISINE (NTO), KELVIN VILLAGE SHOPPING CENTRE, CNR SOUTHWAY & RAYMOND, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.07828,'lng':28.09003}},
{'stp':488570,'address':'488570, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CAFE DEL SOL BRYANSTON (NTO), SHOP 89 BRYANSTON SHOPPING CENTRE, CNR WILLIAM NICOL & BALLYCLARE DRIVE, BRYANSTON, HYDE PARK, South Africa','geo':{'lat':-26.07519,'lng':28.02758}},
{'stp':545057,'address':'545057, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ESKHALENI FOOD VENUE, 160 DE VILLIERS STREET, KENNILWORTH, #, CROWN MINES, South Africa','geo':{'lat':-26.24577,'lng':28.04980}},
{'stp':545058,'address':'545058, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THULIS PLACE, 3402 STAND NO, FAR EAST BANK EXTENSION 2, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10383,'lng':28.09617}},
{'stp':549103,'address':'549103, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ISSACS PLACE, STAND NO, 4885 EXTENSION 7, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08756,'lng':28.11120}},
{'stp':550199,'address':'550199, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WEMBLEY TAVERNA RESTAURANT, 37 ROSETTENVILLE ROAD, VILLAGE MAIN, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.21430,'lng':28.04880}},
{'stp':550246,'address':'550246, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SPUR GOLDEN SPEAR CRESTA, SHOP L201, LOWER LEVEL, CRESTA SHOPPING CENTRE, CRESTA EXT 4, RANDBURG, South Africa','geo':{'lat':-26.13058,'lng':27.97753}},
{'stp':550657,'address':'550657, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAHARAJA RESTAURANT, SLOANE STREET SHOPPING CENTRE, CNR SLOANE STREET & WITKOPPEN, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.04340,'lng':28.01450}},
{'stp':550716,'address':'550716, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COL\'CACCHIO NICOLWAY, STAND NO, ERF 5643 SHOP U 30 NICOLWAY SHOPPING CTR, CNR WILLIAM NICOL DRIVE N WEDGEWOOD LINK, BRYANSTON, South Africa','geo':{'lat':-26.05208,'lng':28.01990}},
{'stp':551246,'address':'551246, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE OLD TAVERN, 147 ELOFF STR EXT., SELBY JOHANNESBURG, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.21950,'lng':28.04340}},
{'stp':551660,'address':'551660, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BUCK AND HOGG, SHOP 3 4 5 SANDELLA COURT, 2 OLIFANTS STREET, EMMERENTIA, RANDBURG, South Africa','geo':{'lat':-26.15256,'lng':27.99814}},
{'stp':552397,'address':'552397, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PAULS INN RESTAURANT, STAND 23 BLOCK 38, 66 FIRST AVE, ALEXANDER, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10860,'lng':28.08840}},
{'stp':552604,'address':'552604, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SUMMER HIGH LIQUOR STORE, 28 CNR 15TH AVE & JOHN BRAND ST, ALEXANDER, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10032,'lng':28.09988}},
{'stp':552882,'address':'552882, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PAT S TAVERN, STAND 64, CNR SPRINGBOK AND ROOSSEVELD ST, EAST BANK ALEXANDER, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09112,'lng':28.11380}},
{'stp':553179,'address':'553179, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PICOLA PIZZA AND PASTA, SHOP NO1,LINDEN SQUARE, CNR 4TH AND 8TH STREET, LINDEN, ERF 258, RANDBURG, South Africa','geo':{'lat':-26.14140,'lng':27.99340}},
{'stp':560237,'address':'560237, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BRH TAVERN, 3836 FAR EAST BANK, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09170,'lng':28.11210}},
{'stp':560244,'address':'560244, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TAU E, 49 FAR EAST BANK, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10170,'lng':28.11530}},
{'stp':560600,'address':'560600, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MADIHLABA TAVERN, 4 CNR MAHLOPHE AND RIVERPARK DRIVE, LOMBARDY EAST, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10920,'lng':28.11820}},
{'stp':560602,'address':'560602, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, AMICI PIZZERIA LTD, SHOP 3 - NICOLWAY CENTRE, 61 WOODLANDS AVENUE HURLINGHAM MANOR, EXT 5   RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09009,'lng':28.02405}},
{'stp':561247,'address':'561247, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SEDIBANE MA, STAND NO 1696 FAR EAST BANK, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10266,'lng':28.11513}},
{'stp':561268,'address':'561268, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MZEKEZEKES, 753B KROKODILE STREET, RIVERLEA EXT, #, CROWN MINES, South Africa','geo':{'lat':-26.21680,'lng':27.96960}},
{'stp':581108,'address':'581108, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RAMOLOTO RM, FLAT 1116 ETHOPIA DRIVE, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10170,'lng':28.11740}},
{'stp':603721,'address':'603721, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOKOENA NR, 1793 NIGERIA AVENUE, TSUTSUMANI, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09340,'lng':28.11243}},
{'stp':603724,'address':'603724, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAPHANGA TL, NO 142 RIVER PARK, LOMBARDY EAST, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10860,'lng':28.12010}},
{'stp':606239,'address':'606239, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TSUTSUMANI GENERAL DEALER, 599 H76B2 2241, TSUTSUMANI VILLAGE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09667,'lng':28.11547}},
{'stp':606856,'address':'606856, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MIRIAMS TAKE AWAY, STAND NO 1224 ANDREW LALLY RD, WESTBURY EXT 3, #, MAYFAIR, South Africa','geo':{'lat':-26.18460,'lng':27.97750}},
{'stp':607592,'address':'607592, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CESCOS PORTUGUESE PUB AND RESTAURAN, BLOCK K  GEORJIAN PLACE NO 18, SOUTHWAY ROAD KELVIN, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.07950,'lng':28.09060}},
{'stp':610596,'address':'610596, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DABULAMANZI (NTO), ERF 1109 EMMARENTIA DAM, LOUW GELDENHUYS DRIVE, EMMARENTIA EXTENSION 1, MELVILLE, South Africa','geo':{'lat':-26.15084,'lng':28.00636}},
{'stp':620270,'address':'620270, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, OCEAN BASKET WATERFRONT (NTO), SHOP NO:138, REPUBLIC ROAD, WATERFRONT RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.10319,'lng':27.99255}},
{'stp':620666,'address':'620666, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ADEGA DE MONGE GROSVENOR (NTO), PORTION 13 OF ERF: 4602, SHOP 8, BRYANSTON GROSEVENOR SHOPPING CENTRE, CNR WILLIAM NICOL DRIVE & MAIN ROAD, BRYANSTON, South Africa','geo':{'lat':-26.05949,'lng':28.02252}},
{'stp':623790,'address':'623790, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SACEL CLUB (NTO), 18 RIVERSDALE STREET, CORONATIONVILLE, Johannesburg, MAYFAIR, South Africa','geo':{'lat':-26.18904,'lng':27.97175}},
{'stp':623832,'address':'623832, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ADVENTURE GOLD RANDBURG WATERFRONT, REPUBLIC ROAD, BRIGHTWATER COMUNES, RANDBURG, Johannesburg, RANDBURG, South Africa','geo':{'lat':-26.10124,'lng':27.99124}},
{'stp':625528,'address':'625528, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOLATELO VIOLET MABAPA, 345 Injabulo Avenue, Lombardy East (River Park), #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10870,'lng':28.12420}},
{'stp':625563,'address':'625563, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAZO, 1858 4TH ROAD DRC, TSUTSUMANI VILLAGE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09016,'lng':28.11350}},
{'stp':626293,'address':'626293, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DANIELS INN, 69 EKUKHANYENI CRESENT, LOMBARDY EAST, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10740,'lng':28.11950}},
{'stp':626325,'address':'626325, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALIS PLACE, 3290 FAR EAST BANK PHASE 2, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09470,'lng':28.10910}},
{'stp':626351,'address':'626351, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MASENYAS INN CERT, 8 - 12th Ave, Alexandra, Randburg, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09876,'lng':28.09624}},
{'stp':626352,'address':'626352, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MATLAPENG, 965 Ghana Loop Street, Tsutsuman Alexandra, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09930,'lng':28.11820}},
{'stp':626369,'address':'626369, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LILYS PLACE, 1534 LESOTHO STREET, TSUTSUMANE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10295,'lng':28.11780}},
{'stp':626876,'address':'626876, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MATSHDISOS PLACE CERT, 191 UMHLABA STR PHASE 3, RIVER PARK LOMBARDY EAST, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10920,'lng':28.12140}},
{'stp':626885,'address':'626885, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TUMINYIKO SHEBEEN, 362 RHINO CRESCENT, EAST BANK, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09850,'lng':28.10650}},
{'stp':626886,'address':'626886, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VILLAGE PUB, 1269 FCMS, TSUTSUMANI, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10200,'lng':28.12023}},
{'stp':626930,'address':'626930, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SEWELA\'S PLACE, 3633 FAR EAST BANK, ALEXANDRA TOWNSHIP, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10230,'lng':28.10130}},
{'stp':626937,'address':'626937, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BETTYS INN, 194 NAMIOBIA RD, TSUTSUMANI  ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09100,'lng':28.11390}},
{'stp':627587,'address':'627587, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NWAMETSI INN, 3464 FAR EAST BANK, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09810,'lng':28.11190}},
{'stp':628412,'address':'628412, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VENDA SUN, 149 UMSENSE STREET, RIVER PARK, LOMBARDY EAST, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10870,'lng':28.12120}},
{'stp':628986,'address':'628986, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PULE S INN, 3381 FAR EAST BANK, ALEXANDRA, RANDBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.09590,'lng':28.11110}},
{'stp':629144,'address':'629144, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LASIE\'S PLACE, 837 STAND NO, MAURITIUS LOOP TSUTSUMANI, ALEXANDRA TOWENSHIP, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09882,'lng':28.10053}},
{'stp':629345,'address':'629345, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, METSIMA REKOA, 3400 FAR EAST BHEKILUMUA, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09670,'lng':28.11300}},
{'stp':631621,'address':'631621, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MDULA SHEBEEN, 22 STANTON STREET, #, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24470,'lng':28.03140}},
{'stp':632133,'address':'632133, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAMPE S SHEBEEN, 939 FAR EAST BANK, GRACE NTSELE STREET, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09050,'lng':28.11130}},
{'stp':633969,'address':'633969, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NTHABELENGS PLACE, 72 FAR EAST BANK, ALEXANDRA, RANDBURG, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10180,'lng':28.11520}},
{'stp':634218,'address':'634218, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARY\'S INN, 3616 FAR EAST BANK, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09030,'lng':28.11120}},
{'stp':635731,'address':'635731, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THOKO\'S PLACE, 3642 FAR EAST BANK, ALEXANDRA, JOHANNESBURG, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09090,'lng':28.11030}},
{'stp':636249,'address':'636249, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, AFTER TEARS INN, 144 RIVER PARK RD, LOMBARDY EAST, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10834,'lng':28.12064}},
{'stp':638397,'address':'638397, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAJIKIZA INN, 53 17TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10093,'lng':28.08833}},
{'stp':639229,'address':'639229, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SEJESONG\'S PLACE, 82 13TH AVENUE NO, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10624,'lng':28.10049}},
{'stp':640158,'address':'640158, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DICKS\'S PLACE, 26 TYPHOON STREET, ORMONDE VIEW, ORMONDE, CROWN MINES, South Africa','geo':{'lat':-26.24984,'lng':27.98456}},
{'stp':642406,'address':'642406, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, OCEAN BASKET CRESTA, U39 SHOP, CRESTA REGIONAL SHOPPING CENTRE, CNR BEYERSNAUDEDRIVE & WELTEVREDEN ROAD, RANDBURG, South Africa','geo':{'lat':-26.11667,'lng':27.96667}},
{'stp':642582,'address':'642582, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PETER\'S TAVERN, 3440 EXT 1, FAR EAST BANK, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09700,'lng':28.11090}},
{'stp':642595,'address':'642595, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SITHOLO INN, 83 1ST ROAD EGYPT, TSUTSUMANI VILLAGE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08870,'lng':28.11380}},
{'stp':642686,'address':'642686, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TELES PLACE, 4535 EXTENSION 8, FAR EAST BANK, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08940,'lng':28.11000}},
{'stp':647013,'address':'647013, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ZUMA\'S SPAZA, 50 SAUNDERS STREET, SAUNDERS STREET, YEOVILLE, CBD, South Africa','geo':{'lat':-26.18560,'lng':28.06250}},
{'stp':647140,'address':'647140, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MATSHEKE\'S TAVERN, 5156 NO, CLUB STREET EXTENSION 7 FAR EAST BANK, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08462,'lng':28.11317}},
{'stp':647753,'address':'647753, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOYOUS \'S PLACE, 4817 SCOCRE STREET, EXTENSION 7, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08690,'lng':28.11060}},
{'stp':647755,'address':'647755, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MASIBAMBANE TUCK SHOP, 5577 MIDFIELDER STREET, EXTENSION 7, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08440,'lng':28.11325}},
{'stp':649507,'address':'649507, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JACK\'S PLACE, 37 MARULA CRESCENT, RIVER PARK, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10814,'lng':28.12314}},
{'stp':653558,'address':'653558, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWANTOMBENHLE INN, 8030 STAND NO, ALEXENDRA FAR EAST BANK, EXTENSION 10, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08850,'lng':28.11508}},
{'stp':654671,'address':'654671, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SANZAS PLACE, 119 119 13TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10940,'lng':28.10120}},
{'stp':659030,'address':'659030, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NADUS TAVERN (NTO), 119 MARKET STREET, CBD, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20379,'lng':28.04793}},
{'stp':660130,'address':'660130, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, B.WELL (NTO), 8 FRAZER STREET, BOOYSENS RESERVE, BOOYSENS, CROWN MINES, South Africa','geo':{'lat':-26.22946,'lng':28.02198}},
{'stp':677551,'address':'677551, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FIDISHAS PLACE, 1616 ROSSEVELT, TSUTSUMANE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10300,'lng':28.11460}},
{'stp':680583,'address':'680583, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PLAKA RESTAURANT CRESTA (NTO), SHOP NO:U224 UPPER GROUND LEVEL, CRESTA SHOPPING CENTRE. EXT.4, ERF:267, CRESTA EXT.6, RANDBURG, South Africa','geo':{'lat':-26.12974,'lng':27.97143}},
{'stp':723412,'address':'723412, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CHAPLINS GRILL (NTO), CNR WILLIAM NICOL & REPUBLIC, HURLINGHAM MANOR, HURLINGHAM, RANDBURG, South Africa','geo':{'lat':-26.09020,'lng':28.02400}},
{'stp':729005,'address':'729005, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TURN N TENDER NICOLWAY (NTO), ERF 5643, SHOP NO: U47, NICOLWAY SHOPPING CENTRE, CNR WILLIAM, NICOL DR & WEDGEWOOD LINK, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.05198,'lng':28.02009}},
{'stp':729007,'address':'729007, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LICORISH BISTRO NICOLWAY (NTO), SHOP U32 NICOLWAY SHOPPING CENTRE, CNR WILLIAM NICOL DRIVE AND, & WEDGEWOOD LINK BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.05262,'lng':28.02023}},
{'stp':729008,'address':'729008, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FISHMONGER NICOLWAY (NTO), CNR WILLIAM NICOL DRIVE, & WEDGEWOOD LINK, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.05299,'lng':28.02006}},
{'stp':730631,'address':'730631, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PANAROTTIS CRESTA, ERF 255, CORNER DF MALAN AND WELTEVRESEN ROAD, CRESTA, RANDBURG, South Africa','geo':{'lat':-26.13258,'lng':27.97680}},
{'stp':754975,'address':'754975, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MA ERS PLACE, 47 STAND NO, RIVERPARK DRIVE, RIVERPARK, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10863,'lng':28.12313}},
{'stp':760508,'address':'760508, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WILLIE TAVERN, STAND NO, BC 5 TB SETTLEMENT, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.09788,'lng':28.10797}},
{'stp':763233,'address':'763233, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WHITE HOUSE, 343 INJABULO STREET, PHASE 2, RIVERPARK, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10860,'lng':28.12421}},
{'stp':768133,'address':'768133, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SONTO S PLACE, STAND NO, B C 3  T B, ALEXANDRA 2090, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10598,'lng':28.10967}},
{'stp':774969,'address':'774969, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MIKES KITCHEN BRYANSTON (NTO), 298 MAIN RD, BRYANSTON, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.06441,'lng':28.02023}},
{'stp':794233,'address':'794233, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EMILYS TAVERN, 3133 STAND NO, FAR EAST BANK, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09647,'lng':28.11368}},
{'stp':306925,'address':'306925, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THIRD AVENUE LOUNGE, 1986 STAND NO, NO. 69 CORNER, 3RD AVENUE AND SELBORNE ROAD, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10851,'lng':28.08994}},
{'stp':389105,'address':'389105, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ZIYAWA TAVERN, 71 ERF, 17TH AVENUE ALEXANDRA, #, ALEXANDRA, South Africa','geo':{'lat':-26.10466,'lng':28.10367}},
{'stp':392535,'address':'392535, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE ESTABLISHMENT, SHOP 1 CNR LONDON RD AND M3, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10583,'lng':28.11830}},
{'stp':463616,'address':'463616, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HLENGANI, 30 STAAND NO, 16TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10469,'lng':28.10269}},
{'stp':463627,'address':'463627, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TIRAS PLACE, 716 STAND NO, PHASE 10 20TH AVENUE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10590,'lng':28.10697}},
{'stp':465372,'address':'465372, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NORMANS PLACE, 96 STAND NO, 18TH AVENUE, ALEXENDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10602,'lng':28.10532}},
{'stp':544849,'address':'544849, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CHILLAS, 29 I ST ROAD EGYPT, TSUTSUMANI VILLAGE, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08870,'lng':28.11380}},
{'stp':546250,'address':'546250, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NOZIS PLACE, 56 STAND NO, 17 TH AVENUE ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10093,'lng':28.08836}},
{'stp':551017,'address':'551017, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LENS PLACE, 162 SONKE STREET, LOMBARDY EAST, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10928,'lng':28.12227}},
{'stp':551858,'address':'551858, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BORCH\'S PLACE, 700 SPARROW STREET, EASTBANK, RANDBURG, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10447,'lng':28.11167}},
{'stp':551902,'address':'551902, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LK AND SON LIQUOR ENTERPRISES, STAND 47 8TH AVENUE, ALEXANDRA, 2090, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10463,'lng':28.09407}},
{'stp':551918,'address':'551918, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIMUNYE LIQUOR STORE, STAND 1467 NO 1467-14TH AVE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10155,'lng':28.09930}},
{'stp':552585,'address':'552585, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOSHODI S, 3537 FAR EAST BANK, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08947,'lng':28.11251}},
{'stp':553490,'address':'553490, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NKABINDES TAVERN, 33 17 TH AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10007,'lng':28.10184}},
{'stp':561121,'address':'561121, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALEX BOULEVARD, 126 PHASE 1 AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11160,'lng':28.09740}},
{'stp':561206,'address':'561206, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MTHEMBU DP, 70A 9TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10620,'lng':28.09570}},
{'stp':561210,'address':'561210, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TEKANE PS, ERF 130 - 20TH AVENUE, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10790,'lng':28.10790}},
{'stp':580496,'address':'580496, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LEKGANYANE TJ, ERF 2279-011 ALEXANDRA, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10900,'lng':28.10897}},
{'stp':580519,'address':'580519, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARULE RM, 91 - 19 TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10520,'lng':28.10600}},
{'stp':603722,'address':'603722, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CHICKS PLACE, CHICKS PLACE, 3864 FAR EAST, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09257,'lng':28.11149}},
{'stp':606238,'address':'606238, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MJIMARO S TAVERN, MJIMARO S TAVERN, 124   14TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10950,'lng':28.10220}},
{'stp':606322,'address':'606322, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MALOKE FAST FOODS, NUMBER 61 - 7TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10656,'lng':28.09360}},
{'stp':626015,'address':'626015, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ADAMS, 1354/55 FAR EAST BANK PHASE 2, ALEXANDRA TOWNSHIP, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09360,'lng':28.11110}},
{'stp':626266,'address':'626266, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, STOEP TAVERN, 123 - 15TH AVENUE, ALEXANDRA TOWNSHIP, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10633,'lng':28.10320}},
{'stp':626284,'address':'626284, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THANDIS PLACE, 8018 STAND NO, FAR EAST BANK EXTENSION 10, ALEXANDRA TOWNSHIP, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11260,'lng':28.09710}},
{'stp':626321,'address':'626321, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THABELOS PLACE, 162  13TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11250,'lng':28.10230}},
{'stp':626392,'address':'626392, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARCUS PLACE, 45 - 10th Ave, Alexandra, Randburg, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10425,'lng':28.09591}},
{'stp':626469,'address':'626469, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MLIMAS PLACE, 66 - 19TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10350,'lng':28.10550}},
{'stp':626711,'address':'626711, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOES PLACE, 1833 NIGERIA CRESCENT, TSUTSUMANE, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09390,'lng':28.11270}},
{'stp':626854,'address':'626854, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIJOS KIOSK, 4 17TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09670,'lng':28.10080}},
{'stp':626863,'address':'626863, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LAMOUDE INN, 1075 TSUTSUMANE VILLAGE, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10210,'lng':28.11760}},
{'stp':626875,'address':'626875, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LATTERS PLACE, ROOM 6, 57 10TH AVANUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10510,'lng':28.09630}},
{'stp':626883,'address':'626883, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DUMAZILES INN, 170 PHASE 1, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11120,'lng':28.09740}},
{'stp':626913,'address':'626913, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BENNETS INN, 131-16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10950,'lng':28.10430}},
{'stp':627012,'address':'627012, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CALTONS PLACE, 138 21ST AVENUE, ALEXANDTRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10880,'lng':28.10950}},
{'stp':627140,'address':'627140, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MANYAKAZAS PLACE, 59 13TH AVE, ALEXANDRA RANDBURG, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10450,'lng':28.09930}},
{'stp':627568,'address':'627568, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ANDREW\'S PLACE, 70 18TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10410,'lng':28.10480}},
{'stp':628203,'address':'628203, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWA MANYATHELA, 92 19TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10658,'lng':28.10658}},
{'stp':628975,'address':'628975, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ALPHEUS PLACE, 575 DOVELANE STR EAST BANK, ALEXANDRA, JOHANNESBURG, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10500,'lng':28.10990}},
{'stp':628977,'address':'628977, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOES BUY AND BRAAI, 95 11TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10840,'lng':28.09840}},
{'stp':629142,'address':'629142, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MASOUFENG TAVERN, 229 PEACOCK LANE, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09760,'lng':28.10770}},
{'stp':629158,'address':'629158, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOKGADIS PLACE, 31-17TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10738,'lng':28.10046}},
{'stp':629713,'address':'629713, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIKHOTSOS PLACE, 36 - 17 th AVENUE, ALEXANDRA RANDBURG, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10050,'lng':28.10220}},
{'stp':629903,'address':'629903, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SHANGRILLA TAVERN, 96 - 12TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10777,'lng':28.09947}},
{'stp':629954,'address':'629954, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DAN S TAVERN, 53 RHINO CRESCENT, ALEXANDRA, JOHANNESBURG, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09820,'lng':28.10570}},
{'stp':630360,'address':'630360, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SISONKE INN, 4741 SOCCER CRESCENT, EXTENSION 7, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08780,'lng':28.11010}},
{'stp':631183,'address':'631183, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WIZARDS CORNER, 406/31 VAN DER LINDE CLOSE, BRAMLEY VIEW EXT 8, JOHANNESBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11990,'lng':28.10050}},
{'stp':631715,'address':'631715, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAVULA\'S PLACE, 138-17TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11170,'lng':28.10640}},
{'stp':631721,'address':'631721, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PATRICIA\'S PLACE, 50-17TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10030,'lng':28.10220}},
{'stp':631838,'address':'631838, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RADEBE AND SON LIQUOR OUTLET, 1602 ZIMBABWE LOOP, TSUTSUMANE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10410,'lng':28.11510}},
{'stp':632371,'address':'632371, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWA THEMBI, 67-18TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10192,'lng':28.10387}},
{'stp':632475,'address':'632475, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MBOPHAS PLACE, 7 17TH AVENUE, ALEXANDRA TOWNSHIP, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09752,'lng':28.10110}},
{'stp':633274,'address':'633274, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CAMEL LIQUOR, 165-11TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11320,'lng':28.09990}},
{'stp':634166,'address':'634166, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LE GIYANI, 95 17TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10640,'lng':28.10430}},
{'stp':634554,'address':'634554, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RATOMBOS PLACE, 98 10TH AVENUE, ALEXANDRA, JOHANNESBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10860,'lng':28.09763}},
{'stp':634677,'address':'634677, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KOKWANA INN, 69-20TH AVENUE, ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10360,'lng':28.10630}},
{'stp':634882,'address':'634882, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VENDA SUN 15, 15-15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09875,'lng':28.09963}},
{'stp':635287,'address':'635287, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MR MAFA SHEBEEN, 30 17TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10010,'lng':28.10210}},
{'stp':636416,'address':'636416, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FIFTEEN 15, 15-15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09900,'lng':28.09960}},
{'stp':637311,'address':'637311, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NKANYEZI SHEBEEN, 125 17TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10880,'lng':28.10520}},
{'stp':638384,'address':'638384, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KROLL S INN, 49-10TH AVENUE, ALEXANDRA, #, ALEXANDRA, South Africa','geo':{'lat':-26.10430,'lng':28.09610}},
{'stp':638884,'address':'638884, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TANITSHI\'S PLACE, 76-16TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10530,'lng':28.10290}},
{'stp':639870,'address':'639870, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, APA\'S PLACE, 5137-21ST AVENUE, ALEXANDRA TOWNSHIP, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10883,'lng':28.10953}},
{'stp':640112,'address':'640112, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MMULES PLACE, 73 19TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10380,'lng':28.10560}},
{'stp':640722,'address':'640722, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MALATJIS PLACE, 94-18TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10580,'lng':28.10520}},
{'stp':640856,'address':'640856, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COMFORT CORNER BAR, 48-13TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10300,'lng':28.09900}},
{'stp':641319,'address':'641319, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JUNIORS PLACE, 50 - 12TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10370,'lng':28.09800}},
{'stp':642181,'address':'642181, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KWA ZWELIS TAVERN, 7696 STAND NO, LOLO KEKANA STREET, TSUTSUMANI EXT 10 ALEXANDRA TOWEN SHIP, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10858,'lng':28.10409}},
{'stp':642361,'address':'642361, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NELLY\'S PLACE, 1183 SOUTH AFRICA BOULEVARD, ALEXANDRA, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10172,'lng':28.11760}},
{'stp':642579,'address':'642579, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MALULEKE\'S PLACE, ERF 5685, FAR EAST BANK EXTENXION 7, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08460,'lng':28.11260}},
{'stp':642657,'address':'642657, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FASHION INN, 16 5TH AV ENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10170,'lng':28.08970}},
{'stp':646297,'address':'646297, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, D AND C TAVERN, 354 RHINO CRESCENT, ALEXANDRA, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09840,'lng':28.10770}},
{'stp':646500,'address':'646500, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MIDWAY PLACE, 63 18TH AVENUE, 18TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10370,'lng':28.10450}},
{'stp':647411,'address':'647411, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MARIA\'S CORNER, 5486 GAME STREET, EXT 7, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08590,'lng':28.11130}},
{'stp':647716,'address':'647716, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MANDLA\'S PLACE, 777 PHASE 10, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10860,'lng':28.10740}},
{'stp':648123,'address':'648123, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MKHARI\'S PLACE, 161-10TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11350,'lng':28.09940}},
{'stp':648128,'address':'648128, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MADLISA, 7540 STAND NO, ALEXANDRA EXT 10 JOHANNESBURG, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09462,'lng':28.11674}},
{'stp':648244,'address':'648244, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DUDU\'S PLACE, 144-20TH AVENUE, #, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10920,'lng':28.10860}},
{'stp':650217,'address':'650217, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NWAJULY, 161 10TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11330,'lng':28.09930}},
{'stp':653533,'address':'653533, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MEISIES PLACE, 32 17TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10040,'lng':28.10220}},
{'stp':653557,'address':'653557, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GLADYS SHEBEEN, 32-17TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10140,'lng':28.10250}},
{'stp':654340,'address':'654340, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LWANDLE LOUNGE BAR, 10 4TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10150,'lng':28.08860}},
{'stp':654708,'address':'654708, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE DIPS PLACE, 61 18TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10330,'lng':28.10430}},
{'stp':654709,'address':'654709, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TONIS PLACE, 120 13TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10970,'lng':28.10120}},
{'stp':666262,'address':'666262, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EXECUTIVE SPORTS BAR (NTO), 84 4TH AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10945,'lng':28.09155}},
{'stp':676840,'address':'676840, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MASWANGANYES PLACE, 5181 STAND NO, FAR EASTBANK, EXTE  NO 7, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08650,'lng':28.11430}},
{'stp':684779,'address':'684779, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BOITUNELONG BEER DEPOT (NTO), 16 18TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10749,'lng':28.10587}},
{'stp':685164,'address':'685164, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TSHOTLHANG (NTO), ERF 5118 PHASE 10, 21TH AVENUE, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10901,'lng':28.10967}},
{'stp':685242,'address':'685242, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BARLOM MINI LIQUOR CAFEE (NTO), 124-17 AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10867,'lng':28.10522}},
{'stp':690384,'address':'690384, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, X-TREME (NTO), SHOP 26 L/LEVEL BRAMLEY GARDENS, SHOPPING CENTRE, 280 CORLETT DRIVE, BRAMLEY GARDENS, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12587,'lng':28.09319}},
{'stp':699037,'address':'699037, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIVERSIDE TAVERN, STND NO 1985, 17TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10546,'lng':28.10412}},
{'stp':699189,'address':'699189, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LETHABONG RESTAURANT (NTO), 99 17TH AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10705,'lng':28.10466}},
{'stp':743918,'address':'743918, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NONO S PLACE, 1565 LIBYA TURN, TSUTSUMANI VILLAGE, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10928,'lng':28.09996}},
{'stp':760746,'address':'760746, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MODERN STORE, STAND NO, ERF 210/7 11 A 93 19 TH AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10558,'lng':28.10623}},
{'stp':762504,'address':'762504, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KOKI S SHEBEEN, STAND NO, ERF 6627, RIVERPARK EXTENSION 9, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10707,'lng':28.11679}},
{'stp':764342,'address':'764342, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MSHINI\'S PLACE, 1226 STAND NO, S A AVENUE, TSUTSUMANI, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10340,'lng':28.12204}},
{'stp':771164,'address':'771164, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOJO S PLACE, STAND NO, 38 - 16TH AVENUE, ALEXANDRA TOWNSHIP, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10668,'lng':28.10230}},
{'stp':772789,'address':'772789, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DIAWELA RESTAURANT, VAN DER LINDE ROAD, ERF 462, BRAMLEY VIEW EXTENSION 11, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11915,'lng':28.10363}},
{'stp':780408,'address':'780408, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EBUMNANDINI, STAND NO, 9 TO 7TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10047,'lng':28.09146}},
{'stp':786535,'address':'786535, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIZANANI GENERAL DEALER, 6732 STAND NO, RIVER PARK, EXTENSION 9, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10691,'lng':28.12009}},
{'stp':789390,'address':'789390, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MALINDIS PLACE, 7626 STAND NO, EXT 10 TSUTSUMANI ALEXANDRA, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10383,'lng':28.09617}},
{'stp':790257,'address':'790257, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HOUSE OF GOMORA, 713 WESTERN SERVICE, ERF 34, MARLBORO, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10988,'lng':28.08733}},
{'stp':306129,'address':'306129, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DELI BISCOTTI (NTO), SHOP NO 05 ILLOVO CENTRE, NO 198 OXFORD, STAND 109, ILLOVO, #, HYDE PARK, South Africa','geo':{'lat':-26.13058,'lng':28.04972}},
{'stp':314541,'address':'314541, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WOMBLES STEAKHOUSE RESTURANT (NTO), 17 THIRD AVENUE, PARKTOWN NORTH, #, RANDBURG, South Africa','geo':{'lat':-26.14574,'lng':28.02848}},
{'stp':317299,'address':'317299, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TIGERS MILK BRYANSTON (NTO), ERF 1618, SHOP NO 1, GROUND FLOOR, RIVERSIDE JUNCTION SHOPPING CENTRE, BRYNSTON DRIVE CNR ST JAMES CRESCENT, BRYANSTON, South Africa','geo':{'lat':-26.05966,'lng':28.04609}},
{'stp':320219,'address':'320219, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCKETS - BRYANSTON (NTO), SHOP HOBARD SHOPPING CENTRE, CNR HOBART AND GROSVENOR ROAD, #, BRYANSTON, South Africa','geo':{'lat':-26.06223,'lng':28.02608}},
{'stp':377335,'address':'377335, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, AJ\'S IN THE PARK (NTO), 33 ABERCOM AVENUE, CRAIGHALL PARK, CRAIGHALL, RANDBURG, South Africa','geo':{'lat':-26.12096,'lng':28.02701}},
{'stp':382718,'address':'382718, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FRENCH CORNER BAKERY & COFFEE SHOP, 275 & 279 MAIN ROAD, SHOP 13A CRAMERVIEW VILLAGE CENTRE, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.07389,'lng':28.01255}},
{'stp':383702,'address':'383702, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BABYLON THE BAR (NTO), ERF 109, SHOP 11, 198 OXFORD ROAD, ILLOVO, JOHANNESBURG, #, MORNINGSIDE, South Africa','geo':{'lat':-26.13099,'lng':28.04999}},
{'stp':388029,'address':'388029, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GO SPICY CHINESE RESTURANT (NTO), SHOP NO 1, RIVONIA CENTRE, 369 RIVONIA BOULEVARD, RIVONIA, ERF 148, EDENBERG, RIVONIA, SANDTON, BRYANSTON, South Africa','geo':{'lat':-26.05120,'lng':28.05974}},
{'stp':388082,'address':'388082, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COFFEE EXHANGE (NTO), ERF:1282, SHOP NO:130, 11TH STREET, PARKMORE, MELVILLE, South Africa','geo':{'lat':-26.09949,'lng':28.04663}},
{'stp':388236,'address':'388236, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CAFE 28 (NTO), 54 INANDA GREEN OFFICE PARK, 54 WIERDA VALLY ROAD WEST, WIERDA VALLY, SANDTON, BRYANSTON, South Africa','geo':{'lat':-26.11482,'lng':28.05537}},
{'stp':388308,'address':'388308, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CAFE 28 (NTO), CLUB HOUSE 320, GRAYSTON OFFICE PAR, #, #, BRYANSTON, South Africa','geo':{'lat':-26.10145,'lng':28.07054}},
{'stp':389661,'address':'389661, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COTTON LOUNGE (NTO), ERF:5513, SHOP NO: 1002, POST HOUSE, CORNER MAIN ROAD AND POST HOUSE STREET, BRYANSTON, MORNINGSIDE, South Africa','geo':{'lat':-26.05288,'lng':28.02317}},
{'stp':389665,'address':'389665, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE ROYALE (NTO), NO:357, ERF:45, SHOP NO:03, ALBAN S, NO:357 JAN SMUTS AVE, CNR ST.ALBANS ROAD, CRAIGHALL PARK, RANDBURG, South Africa','geo':{'lat':-26.11948,'lng':28.02711}},
{'stp':389831,'address':'389831, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE THIEF RESTAURANT (NTO), SHOP NO: S1, PARKMORE GARDENS, NO:126, 11TH STREET OF, ERF: 1477, PARKMORE, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.09910,'lng':28.04607}},
{'stp':389903,'address':'389903, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOZI GIN (NTO), SHOP NO: 29,THE WEDGE SHOPPING CENT, 255 RIVONIA ROAD, MORNINGSIDE EXT:94, BEING ERVEN 1544 & 1/1543, MORNINGSIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.07455,'lng':28.06455}},
{'stp':391956,'address':'391956, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LA CUCINA DA CIRO (NTO), 43 7TH AVENUE, PARKTOWN NORTH, BEING RE/613, #, HYDE PARK, South Africa','geo':{'lat':-26.14858,'lng':28.01917}},
{'stp':392163,'address':'392163, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DOPPIO ZERO BRYANSTON (NTO), SHOP D4 & 5 HOBART GROVE, CORNER GROSVENOR & HOBART ROADS, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.05721,'lng':28.02729}},
{'stp':392468,'address':'392468, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BOTANIST (NTO), ERF NO: 1528 SHOP NO 1, 21 FOURTH AVENUE, PARKHURST, #, RANDBURG, South Africa','geo':{'lat':-26.13973,'lng':28.01755}},
{'stp':392511,'address':'392511, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TRABELLA RESTAURANT PARKVIEW (NTO), ERF 188, UNIT 1 & 5B GROUND FLOOR, NO 60 TYRONE AVENUE, THE VILLAGE CENTRE, MELVILLE, South Africa','geo':{'lat':-26.16010,'lng':28.02609}},
{'stp':392536,'address':'392536, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SHUCKED (NTO), ERF: 45, SHOPS NO: 10 & 11, NO: 357, JAN SMUTS AVENUE, CORNER ST ALBANS ROAD, CRAIGHALL PARK, HYDE PARK, South Africa','geo':{'lat':-26.11929,'lng':28.02484}},
{'stp':392574,'address':'392574, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KOLONAKI RESTAURANT (NTO), 18 4TH AVENUE, CORNER 9TH STREET, BEING ERF:1650, PARKHURST, #, RANDBURG, South Africa','geo':{'lat':-26.14123,'lng':28.01500}},
{'stp':392715,'address':'392715, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE LOCAL GRILL PARKTOWN NORTH, 40 NO, 7TH AVENUE CORNER OF THIRD AVENUE, ERF 00306 PARKTOWN NORTH, HYDE PARK, South Africa','geo':{'lat':-26.14489,'lng':28.02665}},
{'stp':393339,'address':'393339, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE LOCAL KITCHEN CO (NTO), 11 REMAINDER OF ERF: 5513, SHOP NO:, POSTHOUSE LINK SHOPPING CENTRE, CORNER, MAIN & POSTHOUSE STREETS,BRYANSTON,SANDT, BRYANSTON, South Africa','geo':{'lat':-26.05414,'lng':28.01384}},
{'stp':464042,'address':'464042, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE GENERATOR (NTO), ndton shop no 130, 11th st, Parkmor, #, #, MORNINGSIDE, South Africa','geo':{'lat':-26.09912,'lng':28.04676}},
{'stp':467606,'address':'467606, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EQYPTIAN LATINO (NTO), SHOP 3,4 & 5, ATRIUM MEWS, 344 RIVONIA BOULEVARD, RIVONIA, #, BRYANSTON, South Africa','geo':{'lat':-26.05356,'lng':28.05995}},
{'stp':468019,'address':'468019, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EATALIAN (PIZZA) (NTO), 126,11TH STREET, PARKMORE,SANDTON, #, #, HYDE PARK, South Africa','geo':{'lat':-26.09855,'lng':28.04569}},
{'stp':468718,'address':'468718, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE CAPITAL EMPIRE (NTO), CNR RIVONIA AND EMPIRE PLACE, #, #, MORNINGSIDE, South Africa','geo':{'lat':-26.11320,'lng':28.05163}},
{'stp':480883,'address':'480883, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BBQ WORKSHOP (NTO), NIA BLV SHOP G01 RIVONIA VILLAGE,CN, #, #, HILLBROW, South Africa','geo':{'lat':-26.05944,'lng':28.06045}},
{'stp':482645,'address':'482645, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SALSA PARKHURST (NTO), STAND NO:1026,1027,1028 & 1029, SHO, GROUND FLOOR, PARK SQUARE (PARKHURST), CNR 4TH AVE & 14TH STR, PARKHURST, RANDBURG, South Africa','geo':{'lat':-26.13796,'lng':28.01780}},
{'stp':488553,'address':'488553, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARLIAMENT (NTO), 110 VICTORIA AVE, PARKMORE, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.09893,'lng':28.04589}},
{'stp':488554,'address':'488554, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CRAFT (NTO), CNR 4TH AVE & 13TH STR, PARKHURST, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.13829,'lng':28.01762}},
{'stp':488556,'address':'488556, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GEMELLI (NTO), 13 CNR MAIN AND POST HOUSE, ERF 1300000000, BRYANSTON, HYDE PARK, South Africa','geo':{'lat':-26.05210,'lng':28.02310}},
{'stp':488557,'address':'488557, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PERRON HOBART (NTO), CNR HOBART GROVE SHOPPING CENTRE, BRYANSTON, BRYANSTON, HYDE PARK, South Africa','geo':{'lat':-26.06274,'lng':28.02594}},
{'stp':488559,'address':'488559, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LIBAN CAFE (NTO), 36 4TH AVE, PARKHURST, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.13804,'lng':28.01798}},
{'stp':541758,'address':'541758, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HOGSHEAD ILLOVO - DO NOT CALL, 106 STAND NO, UNIT 10 OXFORD ROAD 198, ILLOVO, HYDE PARK, South Africa','geo':{'lat':-26.13046,'lng':28.04928}},
{'stp':549026,'address':'549026, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE TAPHOUSE (NTO), STAND NO 1897, NO 7 FOURTH AVENUE, CORNER SEVENTH STREET, PARKHURST, HYDE PARK, South Africa','geo':{'lat':-26.14287,'lng':28.01668}},
{'stp':550017,'address':'550017, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIVER CLUB GOLF COURSE, LINKS ROAD, PARKMORE, JOHANNESBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.07810,'lng':28.03750}},
{'stp':550651,'address':'550651, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARK VIEW GOLF CLUB, WICKLOW ROAD, PARK VIEW, JOHANNESBURG, MELVILLE, South Africa','geo':{'lat':-26.16190,'lng':28.01930}},
{'stp':550807,'address':'550807, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BRYANSTON SPORTS CLUB, MAIN + GROSVENOR RD, & WILLIAM NICOL HIGHWAY, BRYANSTON, ROSETTENVILLE, South Africa','geo':{'lat':-26.06190,'lng':28.02220}},
{'stp':551098,'address':'551098, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FOUNDERSATGILES, 9 CRAFTON AVENUE, CRAIGHALL PARK, JOHANNESBURG, RANDBURG, South Africa','geo':{'lat':-26.13440,'lng':28.02920}},
{'stp':551218,'address':'551218, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MORNINGSIDE COUNTRY CLUB, MAIN ROAD, RIVONIA, JHB, BRYANSTON, South Africa','geo':{'lat':-26.06330,'lng':28.05820}},
{'stp':551387,'address':'551387, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BARON IN BRYANSTON, BRYANSTON WEDGE SHOPPING CENTR, CNR MAIN RD & POSTHOUSE STR, BRYANSTON , RANDBURG, BRYANSTON, South Africa','geo':{'lat':-26.05157,'lng':28.02340}},
{'stp':551517,'address':'551517, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ESPRESSO CAFFE AND BISTRO, CNR 4TH AVE & 10TH STR, STAND 1433, PARKHURST, HYDE PARK, South Africa','geo':{'lat':-26.14040,'lng':28.01735}},
{'stp':551805,'address':'551805, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NORMAN GOODFELLOWS ILLOVO PTY LTD, 192 OXFORD ROAD, ILLOVO, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.13160,'lng':28.04930}},
{'stp':551841,'address':'551841, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BUSHVELD PUB AND DINER, VALLEY CENTRE, 396 JAN SMUTS AVENUE, CRAIGHALL, HYDE PARK, South Africa','geo':{'lat':-26.11501,'lng':28.02239}},
{'stp':551903,'address':'551903, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOLLY ROGER TAVERN REST PARKHURST, 8 4TH AVE, PARKHURST, JHB, HYDE PARK, South Africa','geo':{'lat':-26.14300,'lng':28.01670}},
{'stp':551941,'address':'551941, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BRYANSTON COUNTRY CLUB, PORTION 1 OF FARM, BRYANSTON, N0 57, BRYANSTON, South Africa','geo':{'lat':-26.06240,'lng':28.01040}},
{'stp':552003,'address':'552003, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NEWS CAFE SANDTON, STAND NO, PORTION 20, ERF 07,SHOP 05,, GROUND FLOOR, JOHANNESBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.10167,'lng':28.05700}},
{'stp':552032,'address':'552032, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BARON AND QUAIL WOODMEAD, DUNWOODYCENTRECNR WOODLAND, & WESTERN SERVICE ROADS, WOODMEADX 15, MORNINGSIDE, South Africa','geo':{'lat':-26.05743,'lng':28.08997}},
{'stp':552225,'address':'552225, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BABYLON RESTAURANT, SHOP G22 BRIGHTWATER COMMANS, RANDBURG WATERFRONT CNR REPUBL, IC & CROSS RDS FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.10318,'lng':27.99251}},
{'stp':552239,'address':'552239, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TABOO GWEN LANE, SHOP 10,CNR GWEN LANE & FREDMA, N DRIVE,SANDOWN, RANDBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.10120,'lng':28.05670}},
{'stp':552334,'address':'552334, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE FISHERMAN S KITCHEN, SHOP 1 CRAMERVIEW VILLAGE, CENTRE, 277 MAIN RD, CRAMERVIEW, BRYANSTON, South Africa','geo':{'lat':-26.07450,'lng':28.01250}},
{'stp':552442,'address':'552442, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NEWS CAFE RIVONIA, SHOP ni 01A & 03A GROUND FLOOR, C/O RIVONIA BOULEVARD & 9TH STR, STN NO 239 & 373 EDENBURG & PTN 9 OF 23, BRYANSTON, South Africa','geo':{'lat':-26.05750,'lng':28.06033}},
{'stp':552572,'address':'552572, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COACHMANS INN, 29 PETER PLACE, LYME PARK, SANDTON, BRYANSTON, South Africa','geo':{'lat':-26.08099,'lng':28.01778}},
{'stp':552691,'address':'552691, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE COUNTRY CLUB WOODMEAD, PTN 64 A PTN OF PTN 63, OF FARM RIETFONTEIN NO 2, RANDBURG, MELVILLE, South Africa','geo':{'lat':-26.05177,'lng':28.07925}},
{'stp':553569,'address':'553569, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GOLDIGGERS GRILL, 772 SIXTH STREET, WYNBERG, WYNBERG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10530,'lng':28.08010}},
{'stp':607057,'address':'607057, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KATJUSHA\'S, SHOP 27 CRAMERVIEW CENTRE, 277 MAIN ROAD BRYANSTON RANDBURG, #, BRYANSTON, South Africa','geo':{'lat':-26.07302,'lng':28.01275}},
{'stp':609679,'address':'609679, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WOMBLES (NTO), 17 THIRD AVENUE, PARKTOWN NORTH, #, HYDE PARK, South Africa','geo':{'lat':-26.06261,'lng':28.02567}},
{'stp':614802,'address':'614802, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TEAZER\'S RIVONIA (NTO), ATRIUM MEWS SHOPPING CENTRE, 76 RIV, RIVONIA, Johannesburg, MORNINGSIDE, South Africa','geo':{'lat':-26.05603,'lng':28.06028}},
{'stp':624737,'address':'624737, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ACTION SPORTS SANDTON (NTO), 12 COMMERCE CRESENT, EASTGATE EXT 1, KRAMERVILLE, SANDTON, Johannesburg, MORNINGSIDE, South Africa','geo':{'lat':-26.08839,'lng':28.07778}},
{'stp':635660,'address':'635660, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE BARON SANDOWN, SHOP 11, 24 CENTRAL CNR, GWEN LANE, SANDOWN, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10280,'lng':28.05670}},
{'stp':642605,'address':'642605, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE LOCAL GRILL PARKTOWN NORTH (NTO, CNR 7th & 3rd AVENUE, PARKTOWN NORTH, HYDEPARK, HYDE PARK, South Africa','geo':{'lat':-26.14487,'lng':28.02884}},
{'stp':647745,'address':'647745, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NEWS CAFE WOODMEAD, SHOP 78 WOODMEAD RETAIL PARK, WOODMEAD DRIVE, BEING ERVEN 934 & 935, WOODMEAD EXT 40, MORNINGSIDE, South Africa','geo':{'lat':-26.04950,'lng':28.09100}},
{'stp':647797,'address':'647797, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FULL STOP CAFE, 50,7TH AVENUE, PORTION 1 OF ERF 311, PARK TOWN NORTH, HYDE PARK, South Africa','geo':{'lat':-26.14490,'lng':28.03053}},
{'stp':649273,'address':'649273, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CEDAR PARK HOTEL AND CONFERENCE CEN, ERF 713,PORTION 6 WOODMEAD EXT 19, NUMBER 120 WESTERN SERVICE ROAD, #, MORNINGSIDE, South Africa','geo':{'lat':-26.06749,'lng':28.08900}},
{'stp':650888,'address':'650888, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BOTTEGA PARKHURST, 3136 4TH AVENUE, PORTION OF LOT 1530, BETWEEN 9TH & 10TH STREET, HYDE PARK, South Africa','geo':{'lat':-26.14082,'lng':28.01737}},
{'stp':664524,'address':'664524, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, URBAN MOYO LIQUOR (NTO), SHOP NO: 1 GWEN LANE TOWER, CORNER FREDMAN DRIVE, SANDTON PORTION 20, ERF: 7, RANDBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.10196,'lng':28.05694}},
{'stp':664531,'address':'664531, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SOCIAL ON MAIN (NTO), PORTION 4 OF ERF: SHOP 3, GROUND FL, POST HOUSE CENTRE, CNR MAIN ROAD & POST, HOUSE STREET, BRYANSTON, SANDTON, BRYANSTON, South Africa','geo':{'lat':-26.05253,'lng':28.02352}},
{'stp':665390,'address':'665390, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DW ELEVEN 13 RESTAURANT (NTO), DUNKELD SHOPPING CENTRE, JAN SMUTS, DUNKELD, HYDE PARK, South Africa','geo':{'lat':-26.13110,'lng':28.03456}},
{'stp':665402,'address':'665402, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, POLPETTA (NTO), ERF 1544 & 2/940,EXTENSION 94 & 1/1, SHOPS NO: 06, 07A, NO:255 RIVONIA ROAD, MORNING SIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.07851,'lng':28.06270}},
{'stp':665415,'address':'665415, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TURN N TENDER PARKHURST (NTO), PARKTOWN QUARTER, CNR 3RD AND 7TH AVENUE, PARKTOWN NORTH, HYDE PARK, South Africa','geo':{'lat':-26.14483,'lng':28.02953}},
{'stp':665424,'address':'665424, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCKET RIVONIA (NTO), CNR RIVONIA BLVRD AND 11TH AVENUE, RIVONIA, RIVONIA, BRYANSTON, South Africa','geo':{'lat':-26.05206,'lng':28.05986}},
{'stp':665450,'address':'665450, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GHAZAL NORTH, SHOP NO 29 AND 29, COACHMAN CROSSING, CNR PETER PLACE AND KAREN STREET, BRYANSTON, South Africa','geo':{'lat':-26.08058,'lng':28.01778}},
{'stp':668625,'address':'668625, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOLLY COOL (NTO), SHOP NO 1 AND 2, CORNER 6TH STREET AND 4TH AVENUE, ERF 1833 PARKHURST, HYDE PARK, South Africa','geo':{'lat':-26.14270,'lng':28.01690}},
{'stp':668864,'address':'668864, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE OLD ROSE, CNR CAMBRIDGE RD AND HALS ST, SHOP 17 PETERVALE SHOPPING CENTRE, #, BRYANSTON, South Africa','geo':{'lat':-26.04002,'lng':28.04643}},
{'stp':671490,'address':'671490, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DELHI DHARBAR (NTO), 136A 11TH STREET, PARKMORE, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10030,'lng':28.04800}},
{'stp':680384,'address':'680384, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCKET LOLITA\'S (RUBY) (NTO), SHOP 24 C, 4TH AVENUE, PARKHURST, HYDE PARK, South Africa','geo':{'lat':-26.14010,'lng':28.01740}},
{'stp':684365,'address':'684365, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CACOON (NTO), SHOP 08, NO 24 CENTRAL GWEN LANE, SANDOWN, #, MORNINGSIDE, South Africa','geo':{'lat':-26.10162,'lng':28.05703}},
{'stp':686917,'address':'686917, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PARK INN SANDTON, PARK INN SANDTON, 123 PATRICIA ROAD ADDITIONAL ENTRANCE, FROM KATHERINE STREET OFF DENNIS ROAD, HYDE PARK, South Africa','geo':{'lat':-26.10620,'lng':28.06820}},
{'stp':689734,'address':'689734, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BISTRO VINE (NTO), 24 4TH AVENUE, PARKHIAT, PARKHURST, HYDE PARK, South Africa','geo':{'lat':-26.13999,'lng':28.01735}},
{'stp':690757,'address':'690757, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WBHO CONSTRUCTION (NTO), 53 ANDRIES STREET, WYNBERG, SANDTON, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10812,'lng':28.07799}},
{'stp':691966,'address':'691966, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BRAZENHEAD SANDTON, CNR ANN AND LINDEN ROADS, STRATHOVAN, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10120,'lng':28.06920}},
{'stp':693196,'address':'693196, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TURN N TENDER ILLOVO (NTO), SHOP NO G01, THRUPPS ILLOVO CENTRE, ERVEN 106-108 NO 24 OXFORD ROAD, ILLOVO, REGION B, HYDE PARK, South Africa','geo':{'lat':-26.12947,'lng':28.04983}},
{'stp':696064,'address':'696064, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BARRELS & BOTTLES (NTO), SHOP 15B CRAMERVIEW SHOPPING CENTRE, MAIN ROAD, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.07450,'lng':28.01250}},
{'stp':701566,'address':'701566, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DUNKELD BOWLS CLUB (NTO), ERF 104, 15 HUME ROAD, DUNKELD WEST, #, HYDE PARK, South Africa','geo':{'lat':-26.13425,'lng':28.03408}},
{'stp':707578,'address':'707578, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JERRY\'S BURGER BAR (NTO), SHOP G4 THRUPPS, ILLOVO CENTRE, 204 OXFORD ROAD, ILLOVO, HYDE PARK, South Africa','geo':{'lat':-26.12930,'lng':28.04990}},
{'stp':707957,'address':'707957, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FISHMONGER ILLOVO (NTO), 204 OXFORD ROAD, SHOPS G12,13,14 THRUPPS CENTRE, ILLOVO, HYDE PARK, South Africa','geo':{'lat':-26.12998,'lng':28.04952}},
{'stp':728361,'address':'728361, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SANDS (NTO), 58 WIERDA VALLEY EAST ROAD, WIERDA VALLEY, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.11084,'lng':28.05538}},
{'stp':747101,'address':'747101, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FOUNDRY (NTO), SHOP 7, 3RD AVENUNE, #, #, HYDE PARK, South Africa','geo':{'lat':-26.14483,'lng':28.02863}},
{'stp':749978,'address':'749978, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COOBS RESTAURANT (NTO), 4TH AVENUE, SHOP 5 GROUND FLOOR, #, #, HYDE PARK, South Africa','geo':{'lat':-26.14040,'lng':28.01735}},
{'stp':753537,'address':'753537, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BEEFCAKES (NTO), 198 OXFORD ROAD, THRUPPS CENTRE, ILLOVO, HYDE PARK, South Africa','geo':{'lat':-26.13050,'lng':28.04962}},
{'stp':755284,'address':'755284, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HUDSONS PARKHURST (NTO), 4TH AVENUE, PARKHURST, PARKHURST, HYDE PARK, South Africa','geo':{'lat':-26.13770,'lng':28.01798}},
{'stp':755288,'address':'755288, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ROCKET PARKHURST (NTO), 4TH AVENUE PARKHURST, PARKHURST, PARKHURST, HYDE PARK, South Africa','geo':{'lat':-26.14014,'lng':28.01746}},
{'stp':761311,'address':'761311, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, METRO BRYANSTON, STAND NO, SHOP 17 GROSVENOR CROSSING SHOOPING, CENTRE WILLIAM NICOL, BRYANSTON, South Africa','geo':{'lat':-26.06002,'lng':28.02207}},
{'stp':761852,'address':'761852, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BOOTH NIGHT CLUB (NTO), 130 11TH STREET, BENMORE, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.09921,'lng':28.04658}},
{'stp':773405,'address':'773405, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIMPLY ASIA RESTAURANT HORBART GROV, STAND NO, ERF 5605 SHOP D2 HOBART GROVE SHOPPING, CORNER HABART AND GROSVERNOR ROADS, BRYANSTON, South Africa','geo':{'lat':-26.06212,'lng':28.02608}},
{'stp':773840,'address':'773840, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KATY\'S PLACE RESTAURANT AND PUB (NT, ERF:40 NO 6 DESMOND STREET, KRAMERVILLE, #, MORNINGSIDE, South Africa','geo':{'lat':-26.08885,'lng':28.08106}},
{'stp':799909,'address':'799909, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE CODFATHER SANDTON (NTO), 3 STAN CLOSE, SANDTON, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.09853,'lng':28.05264}},
{'stp':380925,'address':'380925, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOHNSON INN, 8 5 ERF NO, 8 5TH AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10090,'lng':28.08940}},
{'stp':382698,'address':'382698, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FIRST CHICKEN 2, 3201 STAND NO, FAR EAST ROAD, EAST BANK, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09648,'lng':28.11367}},
{'stp':393000,'address':'393000, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NDANDATHO TAKEAWAY FOOD, ERF 15 CORNER FOURTH STREET, AND THORA AVENUE WYNPOL BUILDING, 679 UNIT 8 AND 9, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10254,'lng':28.08080}},
{'stp':462950,'address':'462950, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NOMPIE\'S KITCHEN, STAND NO, 14-16 TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09851,'lng':28.10037}},
{'stp':466413,'address':'466413, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MDEVA, STAND NO, 119 13TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10411,'lng':28.09939}},
{'stp':480043,'address':'480043, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SHAUNES PLACE, 98 3RD AVENUE, ELEXANDRA ERF 1590, EXT 27, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11036,'lng':28.09071}},
{'stp':550192,'address':'550192, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THE MAIZE BAR WOMENS HOSTEL, 5 DE LAAN 38, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10490,'lng':28.09070}},
{'stp':550197,'address':'550197, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LEE S TAVERN, 1248 STAND, 31 12TH AVE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10160,'lng':28.09730}},
{'stp':552915,'address':'552915, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, J S INN, STAND 46, NO 46 THIRD AVE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10580,'lng':28.08960}},
{'stp':553264,'address':'553264, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MATSEAS TAVERN, 19 2ND AVENUE, ALEXANDRA, 2090, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10290,'lng':28.08690}},
{'stp':561190,'address':'561190, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, XABA WT, NO 6 - 3RD AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10150,'lng':28.08750}},
{'stp':580493,'address':'580493, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NDINISA NJ, 31 - 15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10060,'lng':28.10020}},
{'stp':581065,'address':'581065, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MATHEBULA NM, PHASE 1 BLOCK G, 23 11TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10975,'lng':28.09891}},
{'stp':581109,'address':'581109, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MANDIWANA NP, 143 -11TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11190,'lng':28.09950}},
{'stp':604419,'address':'604419, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LEPHOTOS TAVERN, 72 - 3RD AVE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10870,'lng':28.09020}},
{'stp':604625,'address':'604625, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BASEMENT, 30 5TH AVENUE, ALEXANDRA, STAND NO 00475 007, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10340,'lng':28.09030}},
{'stp':605454,'address':'605454, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KOBUE DD, NO 23 - 5TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10250,'lng':28.09000}},
{'stp':605974,'address':'605974, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SIDEWALK, SIDEWALK, 1- 5TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10326,'lng':28.09472}},
{'stp':606021,'address':'606021, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FIRST CHICKEN, STAND NO, 1 - 2ND AVENUE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10110,'lng':28.08610}},
{'stp':625474,'address':'625474, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOTAUNG SHEBEEN, 22  3RD AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10298,'lng':28.08810}},
{'stp':626019,'address':'626019, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOSES, 39 - 8th Avenue, Alexandra, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10360,'lng':28.09360}},
{'stp':626309,'address':'626309, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DITLOU, 56-4TH AVENUE ALEXANDRA, RANDBURG, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10650,'lng':28.09070}},
{'stp':626310,'address':'626310, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, M S TAVERN, 131 - 3rd Avenue, Alexandra, Randburg, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11330,'lng':28.09150}},
{'stp':626366,'address':'626366, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KV TAVERN, 138 11TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11072,'lng':28.09963}},
{'stp':626448,'address':'626448, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TSHDIS PLACE, 129 - 13TH AVENUE, ALEXANDRA TOWNSHIP, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10951,'lng':28.10231}},
{'stp':626850,'address':'626850, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THABOS INN, 52-10TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10460,'lng':28.09620}},
{'stp':626856,'address':'626856, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, GIYANI INN, 98-2ND AVENUE, ALEXANDRA TOWNSHIP, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11100,'lng':28.09010}},
{'stp':626932,'address':'626932, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOTSHABI\'S PLACE, 63 - 6TH AVE ALEXANDRA, RANDBURG, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10700,'lng':28.09270}},
{'stp':627053,'address':'627053, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MOTIMALENYORA TAVERN, 5 - 9th AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09950,'lng':28.09322}},
{'stp':627286,'address':'627286, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAPLANKENG TAVERN, 15-11th AVE, ALEXANDRA RANDBURG, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11362,'lng':28.09837}},
{'stp':627559,'address':'627559, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JOHNS PLACE, 5 11TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09900,'lng':28.09520}},
{'stp':627567,'address':'627567, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CHONCO\'S PLACE, 132 2ND AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11370,'lng':28.09070}},
{'stp':627635,'address':'627635, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CORNER KICK TUCK SHOP, 103 3RD AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11120,'lng':28.09090}},
{'stp':627761,'address':'627761, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, ZAKHELES PLACE, 11 13TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09920,'lng':28.09740}},
{'stp':627975,'address':'627975, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NONOS INN, 146 9TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11270,'lng':28.09810}},
{'stp':628215,'address':'628215, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, JIKA PUB AND GRILL, 48 1ST AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10650,'lng':28.08740}},
{'stp':629308,'address':'629308, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BUCCUNIA INN, 284 WEAVER STR EASTBANK, ALEXANDRA, JOHANNESBURG, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09697,'lng':28.10770}},
{'stp':629382,'address':'629382, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RIB AND RIBS INN, 31 8TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10269,'lng':28.09333}},
{'stp':629887,'address':'629887, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EMMA\'S PLACE, 42 6TH AVENUE, ALEXANDRA, JOHANNESBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10450,'lng':28.09180}},
{'stp':629960,'address':'629960, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LUXY INN, 65 3RD AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10810,'lng':28.08990}},
{'stp':630082,'address':'630082, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NDLOVU INN, 132  2nd AVENUE, ALEXANDRA, RANDBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11350,'lng':28.09110}},
{'stp':630132,'address':'630132, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FLORAH\'S PLACE, 65-10TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10617,'lng':28.09673}},
{'stp':630361,'address':'630361, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VICTOR\'S INN, 23-18TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09870,'lng':28.10253}},
{'stp':632114,'address':'632114, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, THOKO\'S PLACE, 125-15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10940,'lng':28.10330}},
{'stp':632713,'address':'632713, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DUDUS TAVERN, 74 3RD AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10890,'lng':28.09020}},
{'stp':632740,'address':'632740, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FANKIES TAVERN, 55 - 3 RD AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10700,'lng':28.08930}},
{'stp':632992,'address':'632992, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, RONNIES PLACE, 23 - 11 AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10090,'lng':28.09590}},
{'stp':632997,'address':'632997, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MNTAKWETHU TAVERN, 165-4TH AVENUE, ALEXANDRA TOWNSHIP, RANDBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11523,'lng':28.09426}},
{'stp':634891,'address':'634891, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EMILYS PLACE, 107 13TH AVENUE, ALEXANDER TOWNSHIP, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10748,'lng':28.09721}},
{'stp':635016,'address':'635016, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, WINNIES PLACE, 8 11TH AVENUE, ALEXANDRA TOWNSHIP, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10579,'lng':28.09661}},
{'stp':635724,'address':'635724, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MINGU\'S TAVERN, 19-14TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09930,'lng':28.09850}},
{'stp':635732,'address':'635732, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BOOI\'S PLACE, 53-4TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10661,'lng':28.09038}},
{'stp':636922,'address':'636922, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DINANGWE, 39-9TH AVENUE, ALEXANDRA TOWN SHIP, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10340,'lng':28.09470}},
{'stp':637087,'address':'637087, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SHIRLEYS PLACE, 3521 FAR EAST BAN, ALEXANDRA TOWN SHIP, #, ALEXANDRA EAST, South Africa','geo':{'lat':-26.08930,'lng':28.11180}},
{'stp':637771,'address':'637771, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VIVIAN\'S PLACE, 125 - 8TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11250,'lng':28.09710}},
{'stp':638281,'address':'638281, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, FRANS FAST FOOD, ERF 1143 56-10TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10496,'lng':28.09625}},
{'stp':640902,'address':'640902, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, VUYELWA\'S PLACE, 46-4TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10620,'lng':28.09040}},
{'stp':642135,'address':'642135, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MJUSTO\'S PLACE, 17 2ND AVENUE, -, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10077,'lng':28.09370}},
{'stp':646641,'address':'646641, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAMBULO S PLACE, 20   5TH AVENUE, 5TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10863,'lng':28.08903}},
{'stp':647407,'address':'647407, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LILLY\'S PLACE, 14 4TH AVENUE, #, #, ALEXANDRA, South Africa','geo':{'lat':-26.10862,'lng':28.09125}},
{'stp':647545,'address':'647545, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DANZO\'S PLACE, 64  -  5TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10710,'lng':28.09170}},
{'stp':647715,'address':'647715, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MHAWU\'S PLACE, 49-4TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10610,'lng':28.09030}},
{'stp':647803,'address':'647803, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAHLEZA\'S PLACE, 137 4 TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11020,'lng':28.09180}},
{'stp':653559,'address':'653559, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CECILIAS PLACE, 40 17TH AVENUE, ALEXANDRA TOWNSHIP, RANDBURG, ALEXANDRA, South Africa','geo':{'lat':-26.10116,'lng':28.10248}},
{'stp':653560,'address':'653560, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, NDITSHENI PLACE, 160 9TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11345,'lng':28.09659}},
{'stp':654038,'address':'654038, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BON ALI INN, 32 15TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10011,'lng':28.09956}},
{'stp':654765,'address':'654765, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CAFE LE ESPRESSO, 64 5TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10780,'lng':28.09210}},
{'stp':655074,'address':'655074, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HERITAGE CORNER, 45 7TH AVENUE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10199,'lng':28.09200}},
{'stp':656248,'address':'656248, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SKATIS PLACE, 80 2ND AVENUE, ALEXANDRA TOWNSHIP, RANDBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10970,'lng':28.08950}},
{'stp':657833,'address':'657833, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CLUB ALEX (NTO), 59 1ST AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10533,'lng':28.08690}},
{'stp':665326,'address':'665326, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, TSA KASI RESTAURANT (NTO), 64 11TH AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10549,'lng':28.09764}},
{'stp':666749,'address':'666749, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, XOLISWAS TAVERN, 15 8TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10379,'lng':28.08939}},
{'stp':669540,'address':'669540, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SISTER DOLL, 160 8TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11355,'lng':28.09777}},
{'stp':672285,'address':'672285, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, HLANGANANI PLACE, NO 63 9TH AVENUE, #, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10393,'lng':28.09489}},
{'stp':677143,'address':'677143, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BANCANE TAVERN, 142 3RD AVENUE, #, JOHANNESBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11420,'lng':28.09220}},
{'stp':684799,'address':'684799, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DASA\'S PLACE (NTO), 56 8TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11030,'lng':28.09663}},
{'stp':685044,'address':'685044, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KHOZA\'S SHEBEEN (NTO), 56 4TH AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10670,'lng':28.09070}},
{'stp':687428,'address':'687428, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, SASEKISA, 88 2ND AVENUE, #, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10950,'lng':28.08937}},
{'stp':707137,'address':'707137, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, BELLAS TAVERN, 211 2ND STREET, WYNBERG, JOHANNESBURG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11412,'lng':28.08834}},
{'stp':750003,'address':'750003, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KA NGWANES PALCE, STAND NO, 18 11TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10635,'lng':28.09791}},
{'stp':760488,'address':'760488, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, PAPPAS CAR WASH, 24 1ST AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10388,'lng':28.08630}},
{'stp':760501,'address':'760501, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LORIS PLACE, 8 STAND NO, 5TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10258,'lng':28.09100}},
{'stp':760506,'address':'760506, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DZANIBE TUCK SHOP, 36 STAND N O, 17TH AVENUE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10614,'lng':28.10540}},
{'stp':760522,'address':'760522, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, LION CAFE, 65 STAND NO, 3RD AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10810,'lng':28.08985}},
{'stp':760531,'address':'760531, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAKIES PLACE, 4 STAND NO, 2ND AVENUE, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10105,'lng':28.08460}},
{'stp':760533,'address':'760533, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, E M MOEKETSI, 1 3RD AVENUE, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11317,'lng':28.09285}},
{'stp':764331,'address':'764331, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAWILLIES, 66 STAND NO, 4TH AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11098,'lng':28.08987}},
{'stp':765393,'address':'765393, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, CRAZY CORNER, STAND NO, 27 TH 7TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10251,'lng':28.09221}},
{'stp':765610,'address':'765610, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, KGADI S INN, 19 STREET NO 1ST, ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10660,'lng':28.10652}},
{'stp':771069,'address':'771069, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MA ROSE, 24 STAND NO, 2 ND AVENUE ALEXANDRA, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10367,'lng':28.08760}},
{'stp':771103,'address':'771103, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, EBUMNANDINI, STAND NO, 9 7TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10062,'lng':28.09149}},
{'stp':775831,'address':'775831, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MPEMBES INN, 23 STAND NO, 14 TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10043,'lng':28.09864}},
{'stp':781093,'address':'781093, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, MAKAMO S PLACE, 0066 STAND NO, 9TH AVENUE ROOM NO 11, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10375,'lng':28.09507}},
{'stp':781117,'address':'781117, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, COLOMBIA S PLACE, STAND NO, 24 - 7TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10186,'lng':28.09221}},
{'stp':781198,'address':'781198, EGL - JO\'BURG CENTRA, DENVER HYBRID NORTH, DMN TAVERN, STAND NO, 9-7TH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10087,'lng':28.09160}},
{'stp':309172,'address':'309172, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GAME AND GAME LIQUOR EASTGATE -77, 37B SHOP NO, LOWER LEVEL, EASTGATE SHOPPING CENTRE, ON PORTION 35, GILLOOLYS, South Africa','geo':{'lat':-26.17676,'lng':28.06207}},
{'stp':317059,'address':'317059, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ROMANN LIQUOR STORE, STAND NUMBER, SHOP NUMBER 2, IMPALA PLACE, RANDBURG, South Africa','geo':{'lat':-26.09169,'lng':27.99349}},
{'stp':382521,'address':'382521, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ATHOL SQUARE TOPS, STAND NO, ERF 101, 105 AND REM ERF 106 NO 100, KATHERINE ROAD CORNER, BRYANSTON, South Africa','geo':{'lat':-26.10615,'lng':28.06686}},
{'stp':388880,'address':'388880, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JEPPE STREET TOPS, BRADLOWS CORNER, STAND NO 4844 AND 1248, CNR JEPPE AND RISSIK STREET, JEPPE, South Africa','geo':{'lat':-26.20110,'lng':28.04216}},
{'stp':389435,'address':'389435, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GAME AND GAME LIQUOR JHB CITY 20, 1A SHOP NO, GAME CITY BUILDING, 64 PRITCHARD STREET ERF 4677, CBD, South Africa','geo':{'lat':-26.20382,'lng':28.04244}},
{'stp':390862,'address':'390862, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT THE WEDGE, 1 STAND NO, PORTION 1 OF ERF 1543 AND ERF 1544, 255 RIVONIA ROAD MORNINGSIDE, BRYANSTON, South Africa','geo':{'lat':-26.07827,'lng':28.06081}},
{'stp':392211,'address':'392211, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT THE ZONE AT ROSEBANK, 30 SHOP NO, THE ZANE@ROSEBANIC,, 177 OXFORD ROAD BOUNDED BY BAKER STREET, AND TYRWITT aVENUE AND CRADOCK AVENUE, HYDE PARK, South Africa','geo':{'lat':-26.14456,'lng':28.04249}},
{'stp':467364,'address':'467364, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS SANDRINGHAM, 78 GEORGE AVENUE, #, #, HYDE PARK, South Africa','geo':{'lat':-26.14497,'lng':28.10940}},
{'stp':478478,'address':'478478, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GORDON ROAD CELLARS, 571 STAND NUMBER, TERRAZZO SHOPPING MALL, SHOP NUMBER 28, NORTHCLIFF, South Africa','geo':{'lat':-26.16507,'lng':27.94396}},
{'stp':479146,'address':'479146, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT NEWTOWN, STAND NUMBER, CNR JEPPE AND NTEMI PILISO STR, OLD WEST STR, CBD, South Africa','geo':{'lat':-26.20212,'lng':28.03531}},
{'stp':479884,'address':'479884, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PARKMORE TOPS LIQUOR STORE, 16 SHOP, PARKMORE MEWS OLYMPIA AVENUE, BETWEEN 11TH AND 12TH STREET, CBD, South Africa','geo':{'lat':-26.09237,'lng':28.03678}},
{'stp':483679,'address':'483679, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, WINE AND LIQUOR AT CRAIGHALL, 290 ERF, CRAIGHALL PARK LANCASTER VILLAGE, CNR LANCASTER AND, HYDE PARK, South Africa','geo':{'lat':-26.13044,'lng':28.02666}},
{'stp':486134,'address':'486134, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, NORTHCLIFF WINE AND LIQUOR EMPORIUM, 329 ERF NO, SHOP 4 CRESTA CROSSING CORNER BEYERS, NAUDE DRIVE AND PENDORING ROAD, NORTHCLIFF, South Africa','geo':{'lat':-26.12925,'lng':27.97019}},
{'stp':487143,'address':'487143, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MARKET LIQUORS COMARO, STAND NO, 1 OAK, OAKDENE RE/2/8, ROSETTENVILLE, South Africa','geo':{'lat':-26.26572,'lng':28.05850}},
{'stp':547533,'address':'547533, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CRAIGHALL PARK TOPS LIQUOR STORE, 110 LANCASTER AVENUE, ERF 27 SHOP 4 LANCASTER CENTRE, CNR CLARENCE AVENUE CRAIGHALL PARK, HYDE PARK, South Africa','geo':{'lat':-26.13053,'lng':28.02625}},
{'stp':550248,'address':'550248, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SAVOY LIQUOR STORE, SHOP 1 PEARL HARBOUR COURT, 551 LOUIS BOTHA AVE, C/O GRENVILLE STREET SAVOY EST, GILLOOLYS, South Africa','geo':{'lat':-26.13040,'lng':28.08500}},
{'stp':550363,'address':'550363, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, S M S LIQUOR STORE, 48 GUS STREET, CNR FOX STREET, JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20350,'lng':28.06430}},
{'stp':550429,'address':'550429, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SOUTHHILLS LIQUOR MARKET, SOUTH HILL S/CENRE, SHOP 5, CNR SWIMBURNE & OUTSPAN STS, SOUTH HILLS, ROSETTENVILLE, South Africa','geo':{'lat':-26.24840,'lng':28.08770}},
{'stp':550551,'address':'550551, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DRINKS GALORE LIQUOR DISTRIBUTORS, HALL 2, FRESH PRODUCE MARKET, CITY DEEP, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.22739,'lng':28.07998}},
{'stp':550630,'address':'550630, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SK PARKVIEW, 52(A) TYRONE AVE, PARKVIEW, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.16080,'lng':28.02660}},
{'stp':550683,'address':'550683, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, THREE ANCHORS LIQUOR STORE, 172 SMIT STREET, FAIRLANDS, #, NORTHCLIFF, South Africa','geo':{'lat':-26.13000,'lng':27.94490}},
{'stp':550959,'address':'550959, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT MELVILLE, MELVILLE SWIMMING POOL CENTRE, CNR 4TH AVE & MAIN ROAD, MELVILLE X1, MELVILLE, South Africa','geo':{'lat':-26.17620,'lng':27.99980}},
{'stp':551163,'address':'551163, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, VERONA LIQUOR STORE, ERF 1291, CNR BOUQUET & VERONA STREET, ROSETTENVILLE EXT 1, ROSETTENVILLE, South Africa','geo':{'lat':-26.25570,'lng':28.05610}},
{'stp':551703,'address':'551703, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT SPAR BLACKHEATH, STAND NO, STEVES SHOPPING CENTRE, BEYUERS NAUDE DRIVE, NORTHCLIFF, South Africa','geo':{'lat':-26.13600,'lng':27.97610}},
{'stp':551799,'address':'551799, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SK JULES STR, 441 JULES STREET, MALVERN, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20238,'lng':28.10379}},
{'stp':551816,'address':'551816, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, THE LIQUOR WAREHOUSE, 143 JULES STREET, JEPPESTOWN, JHB, JEPPE, South Africa','geo':{'lat':-26.20560,'lng':28.07630}},
{'stp':552079,'address':'552079, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, FEDERAL HOTEL OFF SALES, 14 DERRICK AVENUE, CYRILDENE, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.17860,'lng':28.09960}},
{'stp':552143,'address':'552143, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS SUPER LIQUORS NORWOOD, ERF CONS 422 SHOP NO 4, 71 GRANT AVE NORWOOD PLACE, WILLIAM RD NORWOOD, HYDE PARK, South Africa','geo':{'lat':-26.15800,'lng':28.07570}},
{'stp':552426,'address':'552426, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ULTRA LIQ CORLETT DRIVE, 583-585 LOUIS BOTHA AVE &, CORLETTE DRIVE, BRAMLEY, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12650,'lng':28.08420}},
{'stp':552430,'address':'552430, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ROSETTENVILLE LIQUOR STORE, CNR MAIN RD + DAISY ST, ROSETTENVILLE, 2197, ROSETTENVILLE, South Africa','geo':{'lat':-26.24860,'lng':28.05210}},
{'stp':552784,'address':'552784, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GOOD HOPE BOTTLE STORE, 153 NUGGET STREET, HILLBROW, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.19311,'lng':28.05139}},
{'stp':552787,'address':'552787, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LOS ANGELES RESTAURANT, 44 GUS STREET, JEPPESTOWN, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20370,'lng':28.06400}},
{'stp':553108,'address':'553108, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MALANSHOF TOPS, SHOPS 5 & 6 MALANSHOF SHOPPING, CENTRE, ERF 560 CNR PHILLIP LE, ROUX & HANS SCHOEMAN STR, MALANSHOF, RANDBURG, South Africa','geo':{'lat':-26.09190,'lng':27.97690}},
{'stp':553351,'address':'553351, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ELECTRON HYPER LIQUOR STORE, CNR SOUTHERN KLIPRIVIERSBERG &, PROTON ROADS ELECTRON, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24268,'lng':28.10073}},
{'stp':553469,'address':'553469, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BUCCLEUCH TOPS, BRIDGE SHOPPING CENTRE, BUCCLEUCH DRIVE, #, MORNINGSIDE, South Africa','geo':{'lat':-26.05773,'lng':28.10301}},
{'stp':629705,'address':'629705, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, WINDSOR TOPS BOTTLE STORE, WINDSOR CITY, CNR BEATRICE & DUCHESS AVE, #, RANDBURG, South Africa','geo':{'lat':-26.12090,'lng':27.97670}},
{'stp':634804,'address':'634804, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT GORDON ROAD, 13,14 SHOP NO, THE BERG SHOPPING CENTRE, CORNER GORDON, ROAD AND BERGBORN DRIVE, ERF 462,463, NORTHCLIFF, South Africa','geo':{'lat':-26.16340,'lng':27.95260}},
{'stp':634861,'address':'634861, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, FERNDALE TOPS, SHOP G24, FERNDALE VILLAGE SHOPPING CENTRE BOUND, BY MAIN ROAD OXFORD STREET PINE AVENUE, RANDBURG, South Africa','geo':{'lat':-26.08098,'lng':27.99778}},
{'stp':634943,'address':'634943, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MORNINGSIDE TOPS, stand no, erf 1096 shop no 12 pavilion centre, corner rivonia road and kelvin drive, MORNINGSIDE, South Africa','geo':{'lat':-26.07180,'lng':28.06480}},
{'stp':638537,'address':'638537, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT RIDGEWAY GARDENS, RIDGEWAY GARDENS SHOPP CENTR, CNR SWARTGOUD TLETITI STR, RIDGEWAY, CROWN MINES, South Africa','geo':{'lat':-26.26650,'lng':28.00510}},
{'stp':639288,'address':'639288, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT TURFFONTEIN, 78 TRAMWAY STREET, TURFFONTEIN, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24570,'lng':28.03920}},
{'stp':639854,'address':'639854, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SKULLY LIQUORS, SHOP 6, FONTAINBLEAU VILLAGE, CNR RABIE STREET AND FOURTH, AVENUE NORTH PORTION 22 OF LOT 699, RANDBURG, South Africa','geo':{'lat':-26.10483,'lng':27.97544}},
{'stp':648696,'address':'648696, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT FONTAINBLEAU, FOUNTAINS SHOPPING CENTRE, CNR REPUBLIC ROAD AND RABIE STREET, ERF 736, RANDBURG, South Africa','geo':{'lat':-26.10440,'lng':27.97750}},
{'stp':650048,'address':'650048, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ULTRA EXPRESS BLAIRGOWRIE, TOFAN BUILDING CNR KANGNUSSIE, & BARKSTON DRIVE, #, RANDBURG, South Africa','geo':{'lat':-26.11670,'lng':28.00170}},
{'stp':650455,'address':'650455, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT LINDEN, SHOP7, LINDEN SQUARE SHOPP CENT, CNR 4TH AVENUE & 8TH STREET, ERF 258 LINDEN, RANDBURG, South Africa','geo':{'lat':-26.14032,'lng':27.99189}},
{'stp':653363,'address':'653363, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT ROBINDALE, ROBINS CNR SHOPP CENTRE, 16 MALIBONGWE DRIVE CNR GAIETY AVENUE, ERVEN 473,474,478 & 480 ROBINDALE EXT 1, RANDBURG, South Africa','geo':{'lat':-26.11683,'lng':27.99006}},
{'stp':653405,'address':'653405, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT KENILWORTH, MAIN STREET, BETWEEN FRASER AND BERTHA STREETS, ERF 234, ROSETTENVILLE, South Africa','geo':{'lat':-26.24500,'lng':28.05270}},
{'stp':684747,'address':'684747, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, POWERSAVE LIQUOR STORE, 175 BRAM FISHER DRIVE, SHOP 06 ERF 1369, FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.09260,'lng':28.00530}},
{'stp':691469,'address':'691469, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT SPAR LOS ARCOS, 105 SHOP NO, LOS ARCOS CENTRE, EXT 15, NORTHCLIFF, South Africa','geo':{'lat':-26.15750,'lng':27.95900}},
{'stp':698964,'address':'698964, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS HIGHPOINT, ERF NO 5219, HIGHPOINT BUILDING GROUND FLOOR, CNR KOTZE AND CLAIM STREET, HILLBROW, South Africa','geo':{'lat':-26.18980,'lng':28.04770}},
{'stp':702476,'address':'702476, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, HOBART TOPS, PORTION OF ERF 5605, SHOP F12 HOBART GROVE SHOPPING CENTRE, CORNER HOBART AND GROSVENOR ROADS, BRYANSTON, South Africa','geo':{'lat':-26.06268,'lng':28.02687}},
{'stp':716935,'address':'716935, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TOPS AT CRESTA, SHOP NO 14 HILLCREST CENTRE, BOUNDED BY BEYERS NAUDE DRIVE, JUDGES AVENUE,LOUIS & PREMIER STREET, RANDBURG, South Africa','geo':{'lat':-26.12717,'lng':27.96867}},
{'stp':747729,'address':'747729, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LYNDHURST TOPS, 04 ERF NO, SHOP NO LS  LYNDHURST SQUARE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12572,'lng':28.10822}},
{'stp':749170,'address':'749170, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GAME LIQUORMART ROSEBANK 211, STAND NO, GROUD FLOOR, MUTUAL SQUARE BUILDING 169, HYDE PARK, South Africa','geo':{'lat':-26.14367,'lng':28.04435}},
{'stp':752800,'address':'752800, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SUPER JUMBO CROWN MINES, STAND NO, ERF 44, CROWN MINES X 2, CROWN MINES, South Africa','geo':{'lat':-26.21651,'lng':28.01133}},
{'stp':762638,'address':'762638, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MARKET LIQUOR PARK MEADOWS, 32 SHOP NO, PARK MEADOWS SHOPPING CENTRE, #, GILLOOLYS, South Africa','geo':{'lat':-26.18373,'lng':28.11321}},
{'stp':767700,'address':'767700, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BOTIQUE LIQUOR STORE, STAND NO, ERF 148 SHOP 6 PARK GALLERIES EXT 2, CORNER ATHOL OAKLANDS AND CORLETT DRIVE, MORNINGSIDE, South Africa','geo':{'lat':-26.13102,'lng':28.06513}},
{'stp':315369,'address':'315369, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR STORE - SMAL STREET (N, ERF: 627, 20 SMALL STREET, CORNER OF FREDERICK STREET, MARSHALLTOWN, CBD, South Africa','geo':{'lat':-26.20793,'lng':28.04704}},
{'stp':315446,'address':'315446, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR STORE (VAN BEEK STREET, 53 VAN BEEK STR, SHOP 1 GROUND FLOO, TRANSPORT SQUARE, NEW DOORNFONTEIN, #, CBD, South Africa','geo':{'lat':-26.19946,'lng':28.05755}},
{'stp':317953,'address':'317953, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR STORE (ALBERTINA SISUL, GROUND FLOOR, REGENTS HOUSE, 1573 ALBERTINA SISULU RD,CNR VON BRANDIS, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20554,'lng':28.03287}},
{'stp':381839,'address':'381839, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SHOPRITE LIQUORSHOP NEWLANDS LS 845, 138 SHOP NO, CNR MAIN AND BROWN STREET, NEWLANDS, NORTHCLIFF, South Africa','geo':{'lat':-26.17611,'lng':27.96560}},
{'stp':388509,'address':'388509, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JEPPE STREET LIQUORS, 5282 ERF NO, NO 125 JEPPE STREET, JOHANNESBURG CBD, JEPPE, South Africa','geo':{'lat':-26.20174,'lng':28.03881}},
{'stp':388526,'address':'388526, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CHECKERS LIQUORSHOP BLUE BIRD LS 92, 26A SHOP NO, BLU BIRD SHOPPING ML ATHOLL OAKLANDS RD, BETWEEN FORT STREET AND MAIN RD ERF 115, HYDE PARK, South Africa','geo':{'lat':-26.12925,'lng':28.06657}},
{'stp':388759,'address':'388759, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ELIQUOR BRAAMFONTEIN, 49 STAND NO, JORISSEN STREET, #, CBD, South Africa','geo':{'lat':-26.20318,'lng':28.02987}},
{'stp':390963,'address':'390963, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR STORE - JOUBERT PARK (, SHOP 6, CATLE BLANEY, NO 29 LEYDS STREET, ERF 2118, #, HILLBROW, South Africa','geo':{'lat':-26.19530,'lng':28.04480}},
{'stp':392047,'address':'392047, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ELIQUOR EXPRESS FOUNDERS SQUARE, 705 FOUNDERS SQUARE, SHOP NO 4,23 6TH STREET, #, NORTHCLIFF, South Africa','geo':{'lat':-26.17146,'lng':27.94779}},
{'stp':392331,'address':'392331, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SKY-LIM BEVERAGE CC, 174 ANDERSON STREET,CONRNER GOUD, STREET,ERVEN 307,308,309,310,313,314 AND, 315 CITY AND SUBURBAN JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20932,'lng':28.05948}},
{'stp':392520,'address':'392520, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, E LIQUOR EXPRESS UNISA BUILDING, 110.25-29 ERF 242 248, SHOP 2B RISSIK STREET, UNISA BULDING, JEPPE, South Africa','geo':{'lat':-26.20603,'lng':28.04012}},
{'stp':392605,'address':'392605, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SHOPRITE LIQUORSHOP FAIRLANDS, 10 STAND NO, WORLD WEAR SHOPPING CENTRE, CNR BEYERS NAUDE DRIVE AND WILSON STREET, NORTHCLIFF, South Africa','geo':{'lat':-26.11891,'lng':27.95607}},
{'stp':392760,'address':'392760, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DISCOUNT LIQUORS, NEDBANK BUILDING, CNR KOTZE STREET, CATHERINE AVENUE & BANKET STREET, HILLBROW, South Africa','geo':{'lat':-26.18943,'lng':28.04875}},
{'stp':392844,'address':'392844, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR - SMIT STREET (NTO), SHOP NO:2 GOUND FLOOR, NO:250 SMIT STREET, ERF:2257, #, HILLBROW, South Africa','geo':{'lat':-26.19450,'lng':28.04180}},
{'stp':393035,'address':'393035, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GREYMONT RESTAURANT (NTO), ERF 1208, CORNER OF WEST AND LONG STREET, GREYMONT, NORTHCLIFF, South Africa','geo':{'lat':-26.16785,'lng':27.96574}},
{'stp':393211,'address':'393211, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR STORE - BEIT STREET (N, ERVEN: 405, 406 & 407, NO: 32 BEIT STREET, CORNER OF HEIGHT STR, DOORNFONTEIN, HILLBROW, South Africa','geo':{'lat':-26.19894,'lng':28.03368}},
{'stp':472758,'address':'472758, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CHECKERS LQRSHP 69913 RIVONIA, SHOP NO 71 A, PORTION 9 OF 23 EDENBURG, ERVEN 239 EDENBURG 373 EDENBERG, MORNINGSIDE, South Africa','geo':{'lat':-26.05888,'lng':28.06185}},
{'stp':483641,'address':'483641, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MR LIQUOR STORE, STAND NO, 130-132 HELEN JOSEPH STREET, ERF 4646, CBD, South Africa','geo':{'lat':-26.20320,'lng':28.04990}},
{'stp':545159,'address':'545159, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SHOPRITE LIQUORSHOP - NEWTOWN 91817, C/O CARR STREET, BETWEEN MIRRIAM MAKEBA ND HENRY NXUMALO, ERVEN 45 TO 58 , ERF 558 564 AND 568, CBD, South Africa','geo':{'lat':-26.20150,'lng':28.03024}},
{'stp':550461,'address':'550461, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PARKMORE DISCOUNT LIQUORS, CNR ELIZABETH AVE& 11TH ST, PARKMORE, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10003,'lng':28.04810}},
{'stp':550480,'address':'550480, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, RIVERSIDE LIQUORS, ERF 4180 RIVERSIDE CENTRE, BRYANSTON DRIVE, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.05877,'lng':28.04696}},
{'stp':551149,'address':'551149, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ROBERTSHAM LIQUOR MARKET, 11 GILES STREET, ROBERTSHAM, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.24658,'lng':28.01393}},
{'stp':551386,'address':'551386, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, FGT LIQUOR STORE, SONIA BUILDING, CNR CAROLINE & CLAIM STR, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18636,'lng':28.04859}},
{'stp':551479,'address':'551479, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LOCO LIQ BLAIRGOWRIE, BLAIRGOWRIE PLAZA, 70 CONRAD DRIVE, BLAIRGOWRIE, RANDBURG, South Africa','geo':{'lat':-26.11560,'lng':28.00960}},
{'stp':551619,'address':'551619, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GREYMONT DISCOUNT LIQUORS, CNR WEST, LONG &2ND ROADS, GREYMONT, JHB, NORTHCLIFF, South Africa','geo':{'lat':-26.16730,'lng':27.96620}},
{'stp':551683,'address':'551683, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BERTRAMS LIQUOR STORE, CNR FULLER AND BEZUIDENHOUT STR, BERTRAMS CENTRE, BERTRAMS, GILLOOLYS, South Africa','geo':{'lat':-26.19750,'lng':28.06790}},
{'stp':551871,'address':'551871, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, KINGSLEY SEELERS, 1884 ERF, FERNDALE ROAD SHOP 16, CANTUBURY CROSSING FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.10660,'lng':28.00140}},
{'stp':552221,'address':'552221, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, NEWGATE BOTTLE STORE COD, 184 BREE STR, NEWTOWN, 2113, CBD, South Africa','geo':{'lat':-26.20140,'lng':28.03660}},
{'stp':552319,'address':'552319, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR STORE, 56 END STREET, DOORNFONTEIN, JHB, CBD, South Africa','geo':{'lat':-26.20310,'lng':28.05400}},
{'stp':552907,'address':'552907, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BOULEVARD CELLARS, 333 RIVONIA BOULEVARD, SHOP 7 MUTUAL MEWS, RIVONIA, MORNINGSIDE, South Africa','geo':{'lat':-26.05880,'lng':28.05980}},
{'stp':553063,'address':'553063, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ROCKY LIQUOR CELLARS, 44 STAND NO, RALEIGH STREET, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18130,'lng':28.06400}},
{'stp':553206,'address':'553206, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR STORE HARROW ROAD, 0062 STAND NO, HARROW ROAD, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18379,'lng':28.05792}},
{'stp':553277,'address':'553277, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ROBBYS LIQUOR TOWN, 36 NEDBANK PLAZA, CNR TWIST & PRETORIA STREETS, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18895,'lng':28.04740}},
{'stp':553722,'address':'553722, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LOCO LIQUOR KELVIN, NO 4 KELVIN VILLAGE SHOPPING CENTRE, CNR SOUTHWAY AND RAYMOND STREETS, KELVIN, ALEXANDRA WEST, South Africa','geo':{'lat':-26.07850,'lng':28.09000}},
{'stp':618784,'address':'618784, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS 2 (NTO), 56 END STREET, JOHANNESBURG, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.20323,'lng':28.05419}},
{'stp':627064,'address':'627064, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DEW LIQUOR STORE, SHOP 8 CITIBLOCK, CNR KLEIN & DE VILLIERS STR, #, CBD, South Africa','geo':{'lat':-26.18870,'lng':28.05630}},
{'stp':629177,'address':'629177, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CHECKERS LIQUORSHOP BRYANSTON 64905, BRYANSTON SHOPPING CENTRE, CNR WILLIAM NICOL AND BALLY CLARE, #, BRYANSTON, South Africa','geo':{'lat':-26.07452,'lng':28.02839}},
{'stp':633193,'address':'633193, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SHOPRITE ELOFF STR 47664, NO. 2 SHOPRITE CENTRE, C/O PRITCHARDT & VON BRANDIS STRS, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20360,'lng':28.04460}},
{'stp':636997,'address':'636997, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PRICE BUSTERS LIQUORS, 170 HENDRICK, VERWORD STREET, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09080,'lng':28.00670}},
{'stp':638434,'address':'638434, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SOUTHDALE LIQUOR MARKET, SOUTHDALE SHOPPING CENTRE, CNR ALAMEIN AND LANDSBOROUGH, SOUTHDALE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24120,'lng':28.02300}},
{'stp':638620,'address':'638620, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS LIQUOR STORE HILLBROW, SHOP NO 82-84, CNR OF BLANKET AND KOTZE STREETS, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18953,'lng':28.05003}},
{'stp':648793,'address':'648793, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, WINDSOR GLEN LIQUOR STORE, SHOP 1,WINDSOR GLEN SHOPPING, CENTRE,CNR REPUBLIC ROAD AND CLUB STREET, ERF 92 WINDSOR GLEN, RANDBURG, South Africa','geo':{'lat':-26.11670,'lng':27.97240}},
{'stp':650391,'address':'650391, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JOZI FRIED CHICKEN DINER, 38 RALEIGH & CNR FORTISQUE ROAD, #, #, YEOVILLE, South Africa','geo':{'lat':-26.18130,'lng':28.06137}},
{'stp':652337,'address':'652337, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SHOPRITE WESTGATE STATION 54504, WESTGATE LIQUOR DISCOUNTERS, SHOP 11-13 WEST GATE SHOPP CENTRE, WEST STREET, CROWN MINES, South Africa','geo':{'lat':-26.21150,'lng':28.03490}},
{'stp':653756,'address':'653756, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, VASCO BOTTLE STORE CC, ERF 335, CNR ONTDEKKERS ROAD & 10 STREET, DELERAY, MAYFAIR, South Africa','geo':{'lat':-26.17460,'lng':27.94950}},
{'stp':658491,'address':'658491, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, OASIS - Van Der Merwe Street (NTO), 1 28 Van Der Merwe shop no2, #, #, CBD, South Africa','geo':{'lat':-26.20149,'lng':28.04586}},
{'stp':667620,'address':'667620, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DISCOUNT LIQUOR KERK, NUMBER 101 KERK STREET, BETWEEN TROY AND POLY STREET, #, CBD, South Africa','geo':{'lat':-26.20175,'lng':28.04900}},
{'stp':671757,'address':'671757, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SHOPRITE YEOVILLE 57138, 0 CNR RALEIGH AND BEDFORD STREETS, SHOP NO 2 SHOPRITE SHOPPING CENTRE, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18060,'lng':28.06430}},
{'stp':693725,'address':'693725, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CHECKERS NORTHCLIFF 63048, SHOP NO 16, CORNER SHOPPING CENTRE CORNER BEYERS, NAUDE DRIVE AND MILNER STREET BEING, NORTHCLIFF, South Africa','geo':{'lat':-26.14570,'lng':27.98280}},
{'stp':695286,'address':'695286, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CHECKERS BALFOUR PARK 58053, CNR ATHOL AND NORTHVIEW STREETS, BALFOUR PARK SHOPPING CENTRE, HIGHLANDS NORTH, GILLOOLYS, South Africa','geo':{'lat':-26.13632,'lng':28.08937}},
{'stp':700188,'address':'700188, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ULUJU LIQUORS, SHOP 1 2, ERF 4927, CNR BICCARD AND DE KORTE STREET, HILLBROW, South Africa','geo':{'lat':-26.19343,'lng':28.03707}},
{'stp':701460,'address':'701460, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CHECKERS BRAM FISHER 59724, SHOP N5, BRAM FISCHER SHOPPING CENTRE, GEORGE STREET BETWEEN BRAM FISHER, RANDBURG, South Africa','geo':{'lat':-26.11070,'lng':27.99970}},
{'stp':742011,'address':'742011, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SHOPRITE EMMERENTIA 56263, CNR BARRY HERTZOG DR AND LUZI STR, SHOP 2 CHECKERS SHOPPING CENTRE, EMMERENTIA EXT 1, MELVILLE, South Africa','geo':{'lat':-26.16070,'lng':28.01167}},
{'stp':749695,'address':'749695, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CHECKERS LIQUORSHOP NICOLWAY 33154, L 7 SHOP NO, NICOLWAY SHOPPING CENTRE, CNR WEDGEWOOD LINK WILLIAM NICOL DRIVE, BRYANSTON, South Africa','geo':{'lat':-26.05204,'lng':28.01966}},
{'stp':757186,'address':'757186, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CHECKERS SP PAVILION 59392, STAND NO, SHOP 10 SP PAVILION LIFESTYLE CENTRE, AMANDA ROAD GLENEGLES EXT 8, MELVILLE, South Africa','geo':{'lat':-26.26890,'lng':28.04280}},
{'stp':770840,'address':'770840, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, WWW ILIQUOR CO ZA, STAND NO, SHOP 309 ASIAN CITY 80 MARCIA STREET, CYRILDENE JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.17820,'lng':28.10681}},
{'stp':773422,'address':'773422, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BABSIES LIQUOR, 0299 NUGGET STREET, CNR BREE STREET, #, CBD, South Africa','geo':{'lat':-26.20020,'lng':28.04721}},
{'stp':774666,'address':'774666, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SHOPRITE LIQSHOP ROSETENVILLE 80761, STAND NO, CNR GUNNERS CIRCLE AND COCHRANE AVENUE, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24278,'lng':28.05293}},
{'stp':779937,'address':'779937, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MOGREEN BOTTLE STORE, STAND NO, ERF 821 FREDERICK HOUSE 7, LOVEDAY STR CNR FREDERICK STR, CBD, South Africa','geo':{'lat':-26.20840,'lng':28.04154}},
{'stp':784411,'address':'784411, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PALM LIQUORS (NTO), CORNER 17TH STREET & ONTDEKKERS, CORNER 17TH STREET & ONTDEKKERS, CORNER 17TH STREET & ONTDEKKERS, MAYFAIR, South Africa','geo':{'lat':-26.14742,'lng':27.89361}},
{'stp':797055,'address':'797055, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GOOD GUYS LIQUOR STORE, 125 NUGGET STREET, ERF 4565, CORNER BOK STREET, HILLBROW, South Africa','geo':{'lat':-26.19592,'lng':28.05123}},
{'stp':388226,'address':'388226, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DENVER RESTAURANT, 235,376 STAND NO, MAIN REEF ROAD, #, JEPPE, South Africa','geo':{'lat':-26.20633,'lng':28.09906}},
{'stp':389753,'address':'389753, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DE VILLIERS LIQUORS, 85 STAND NO, #, #, CBD, South Africa','geo':{'lat':-26.19810,'lng':28.04970}},
{'stp':391317,'address':'391317, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TG CONTINENTAL LIQUOR STORE, 607 STAND NO, #, #, JEPPE, South Africa','geo':{'lat':-26.20094,'lng':28.11783}},
{'stp':461004,'address':'461004, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PASITO LIQUOR STORE, 45 MOOI STREET, ERF 158 CNR POLLY AND ANDERSON STREETS, #, CBD, South Africa','geo':{'lat':-26.20753,'lng':28.04915}},
{'stp':462416,'address':'462416, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ALPEKA LIQUORS, 59 MCINTYRE STREET, ERF 145, JEPEESTOWN, CBD, South Africa','geo':{'lat':-26.20419,'lng':28.06229}},
{'stp':467509,'address':'467509, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MINATLOU TRADING BOTLE STORE, 18 STAND NO, JABULANI CRESCENT, RIVER PARK LOMBARDY EAST ERF 11181, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10764,'lng':28.11634}},
{'stp':477043,'address':'477043, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SIYABONGA LIQUOR STORE, 1101 STAND NUMBER, ALBERTINA SISULU ROAD, BEZUIDENHOUT VALLEY, CBD, South Africa','geo':{'lat':-26.18862,'lng':28.09244}},
{'stp':487503,'address':'487503, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ALEX METRO LIQUORS, STAND NO, ERF 466, 30 3RD STREET, WYNBERG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11140,'lng':28.08707}},
{'stp':491452,'address':'491452, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, THE BREAKERS, STAND NO, ERVEN 234 AND 236, NO 25 ABEL ROAD, HILLBROW, South Africa','geo':{'lat':-26.18824,'lng':28.05320}},
{'stp':545084,'address':'545084, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ALPEKA SPORTS BAR, 59 MCLNTYRE STREET, ERF 145, JEPPESTOWN, CBD, South Africa','geo':{'lat':-26.20419,'lng':28.06229}},
{'stp':550180,'address':'550180, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MOSS LIQUOR LODGE, 28 BEZUIDENHOUT STREET, CNR PRINCESS STREET, TROYEVILLE, JEPPE, South Africa','geo':{'lat':-26.19890,'lng':28.06690}},
{'stp':550212,'address':'550212, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MODISELENG LIQUOR STORE, 10 6TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10070,'lng':28.09040}},
{'stp':550337,'address':'550337, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TSAKAS LIQUOR STORE, ERF 8 BLOCK 69, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10470,'lng':28.10270}},
{'stp':550479,'address':'550479, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ALEX LIQUOR STORE WYNBERG, YARONA BUILDING, C/O WATT & THIRD STREETS, WYNBERG, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10990,'lng':28.08640}},
{'stp':550560,'address':'550560, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JULES STREET LIQUOR STORE, 292 JULES STREET, MALVERN, JHB, JEPPE, South Africa','geo':{'lat':-26.20430,'lng':28.09010}},
{'stp':550678,'address':'550678, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LALLAS LIQUOR WHOLESALERS, 32 5TH STREET, MARLBORO, SANDTON, ALEXANDRA WEST, South Africa','geo':{'lat':-26.09680,'lng':28.09030}},
{'stp':551151,'address':'551151, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SONNYBOY S BOTTLE STORE, 25 ABEL ROAD, BEREA, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.18830,'lng':28.05320}},
{'stp':551166,'address':'551166, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DIPANANA S TAVERN, 2468 STAND NO, NO 82 TENTH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10750,'lng':28.09720}},
{'stp':551220,'address':'551220, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DENVER LIQUOR MARKET, 376 MAIN REEF ROAD, DENVER, DENVER, JEPPE, South Africa','geo':{'lat':-26.20610,'lng':28.09900}},
{'stp':551304,'address':'551304, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, T AND M LIQUOR STORE, STAND 375 & 376, EASTBANK, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.09860,'lng':28.10940}},
{'stp':551426,'address':'551426, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BEREA LIQUOR STORE, 1355 BEREA BOULEVARD STAND NUMBER, 37 ABEL ROAD CNR TUDHOPE AVE SHOP 5, BEREA, HILLBROW, South Africa','geo':{'lat':-26.18821,'lng':28.05435}},
{'stp':551429,'address':'551429, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BELLEVUE BOTTLE STORE, 166 JULES STREET, #, #, JEPPE, South Africa','geo':{'lat':-26.20570,'lng':28.07800}},
{'stp':551516,'address':'551516, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MPEDIS BOTTLE STORE, 11 18THA VENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09750,'lng':28.10220}},
{'stp':551704,'address':'551704, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ELLIS PARK LIQUOR STORE, ERF 223 AND 224 NO 15 AND 17, DEBBY ROAD CORNER 19, LIDDLE STREET, CBD, South Africa','geo':{'lat':-26.19291,'lng':28.06542}},
{'stp':551784,'address':'551784, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MAROLEN LIQUOR, STAND 754, 47, 8TH AVE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10420,'lng':28.09383}},
{'stp':551876,'address':'551876, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, VILLAGE LIQUOR STORE, 41 GOUD STREET, CNR ANDERSON STREET, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20710,'lng':28.05190}},
{'stp':552169,'address':'552169, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ROCKY RESTAURANT AND TAKE AWAY, 16 ROCKY ST CNR SHERWELL ST, DOORNFONTEIN, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19898,'lng':28.05452}},
{'stp':552291,'address':'552291, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JEPPE LIQUOR STORE, 302-304 MARSHALL STREET, JEPPESTOWN, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20548,'lng':28.06131}},
{'stp':552311,'address':'552311, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, HAMILTON LIQUOR STORE, 69 HAMILTON STREET, NEWCLARE, #, MAYFAIR, South Africa','geo':{'lat':-26.18780,'lng':27.97030}},
{'stp':552443,'address':'552443, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, EXECUTIVE LIQUOR STORE, STAND 149, CNR 4 TH AVE & ROOSEVELT ST, ALEXANDRA, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11013,'lng':28.09180}},
{'stp':552610,'address':'552610, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, NKELES TAVERN, 70 18TH AVE, ALEXANDER, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10410,'lng':28.10460}},
{'stp':552634,'address':'552634, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CLAN LIQUOR SNACK, 592 JULES STR, CNR HERIOT STR, MALVERN, JEPPE, South Africa','geo':{'lat':-26.20110,'lng':28.11870}},
{'stp':552664,'address':'552664, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, POLANA LIQUOR STORE, 80 PLEIN STREET, CNR EDITH CAVELLE STREETS, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20012,'lng':28.04790}},
{'stp':552710,'address':'552710, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LES DISCOUNT LIQUOR STORE, GROUND FLOOR SHOP MEDWOOD, BLDNG, 76 DERBY RD, STAND NO:148, LORENTZVILLE, JHB, CBD, South Africa','geo':{'lat':-26.19100,'lng':28.07060}},
{'stp':552832,'address':'552832, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MBUTIS TAVERN, 1251 STAND, ELEVENTH AVENUE, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10220,'lng':28.09630}},
{'stp':552923,'address':'552923, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CENECON LIQUOR WHOLESALE, CNR 84 CLAIM ST, 38 KAPTEIJN STR, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.19080,'lng':28.04953}},
{'stp':552939,'address':'552939, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, POLLOCK S BOTTLE STORE, ERF 377 & 378, 50 POLLOCK BOTTLE STORE, NEWCLARE, MAYFAIR, South Africa','geo':{'lat':-26.18980,'lng':27.96630}},
{'stp':552961,'address':'552961, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TSHIDIS TAVERN, ERF 1134, 70-16th AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10452,'lng':28.10318}},
{'stp':553140,'address':'553140, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, STATION LIQUOR MARKET, SHOPS 4  TWINBRO HOUSE, 21 WANDERERS STREET, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19830,'lng':28.04490}},
{'stp':553141,'address':'553141, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MALVERN EAST LIQUOR STORE, 51B&C GELDENHUYS ROAD, MALVERN EAST, PRIMROSE, GERMISTON, JEPPE, South Africa','geo':{'lat':-26.19910,'lng':28.12480}},
{'stp':553151,'address':'553151, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MATHES BOTTLE STORE, SHOP NO 4 LANGENI SUPPLY STORE, 1, 11TH AVENUE C/O VASCO DA, GAMA & 14TH AVE ALEXANDRA,, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09860,'lng':28.09460}},
{'stp':553196,'address':'553196, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, NEWANY BOTTLE STORE, 143 STAND NO, JEPPE STREET JOHANNESBURG, JHB, CBD, South Africa','geo':{'lat':-26.20179,'lng':28.04085}},
{'stp':553198,'address':'553198, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JOUBERT PARK CELLARS, STAND 2023,2024, 37 B BOK STREET, HILLBROW, CBD, South Africa','geo':{'lat':-26.19540,'lng':28.04890}},
{'stp':553463,'address':'553463, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JOHNNY S LIQUOR STORE, ERF 2 , CNR FIRST & KRAUSE STR, VREDEDORP, 2001, MAYFAIR, South Africa','geo':{'lat':-26.19210,'lng':28.01480}},
{'stp':553550,'address':'553550, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MARA BOTTLE STORE, NO 26 -17 TH AVENUE, ALEXANDER, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09970,'lng':28.10190}},
{'stp':553752,'address':'553752, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MAVHUNGU PUB, 12 STAND NO, BLOCK 93 NO 100, 16TH AVENUE, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10710,'lng':28.10360}},
{'stp':580545,'address':'580545, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, THULIS PLACE, 31 DORIS STREET, BEREA, #, HILLBROW, South Africa','geo':{'lat':-26.18380,'lng':28.05550}},
{'stp':580592,'address':'580592, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR AND ICE, 192 - 195 LOUIS BOTHA AVE, ORANGE GROVE -  ERF 338, #, GILLOOLYS, South Africa','geo':{'lat':-26.16330,'lng':28.08350}},
{'stp':602917,'address':'602917, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MARLBORO HYPER LIQUORS, 3RD STREET, MARLBORO, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.09900,'lng':28.08760}},
{'stp':603054,'address':'603054, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JUMBO LIQUOR, 4 4 ROCKeY STREET, BELLEVUE, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.18130,'lng':28.06610}},
{'stp':605879,'address':'605879, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SISONKE LIQUOR STORE, STAND 2024  137- 18TH AVE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10958,'lng':28.10672}},
{'stp':606124,'address':'606124, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TAMOS TAVERN, TAMO\'S TAVERN, 43 - 2ND AVENUE (STAND NO 00002039), #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10803,'lng':28.08883}},
{'stp':606858,'address':'606858, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CAROLINE\'S PLACE, 1433 SUNFLOWER PLACE, WESTBURY, #, MAYFAIR, South Africa','geo':{'lat':-26.18654,'lng':27.97207}},
{'stp':626355,'address':'626355, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, B R LIQUOR STORE, 20 DE LAREY STREET, VREDEDORP, #, MAYFAIR, South Africa','geo':{'lat':-26.19630,'lng':28.01800}},
{'stp':626862,'address':'626862, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, KHULEKA INN, 36 7TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10370,'lng':28.09260}},
{'stp':629307,'address':'629307, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ALEX HOTEL, 55-15TH AVENUE, ALEXANDRA, JOHANNESBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10380,'lng':28.10130}},
{'stp':629346,'address':'629346, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MATLALAS PLACE, 38-9TH AVENUE, ALEXANDRA, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10523,'lng':28.09637}},
{'stp':632113,'address':'632113, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GLADYS TAVERN, 2075 STAND NO, 49 - 7TH AVENUE 165 ALFRED NZO STREET, EXTENSION 26, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10524,'lng':28.09316}},
{'stp':635932,'address':'635932, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PAULINE\'S PLACE, GATE 66 104, 13TH AVENUE, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10830,'lng':28.10070}},
{'stp':637770,'address':'637770, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, M AND M LIQUOR STORE, 29 SHOP NO C, RALEIGH STREET AND FORTESQUE AVENUE, ERF 709 & 710, YEOVILLE, South Africa','geo':{'lat':-26.18126,'lng':28.06149}},
{'stp':640188,'address':'640188, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BROADWAY PLACE, 26  16TH AVENUE, ALEXANDRA, JOHANNESBURG, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.09990,'lng':28.10100}},
{'stp':640935,'address':'640935, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, NORTH STREET LIQUOR STORE, 76 NOORD STREET, JOHANNESBURG, #, CBD, South Africa','geo':{'lat':-26.19780,'lng':28.04880}},
{'stp':646472,'address':'646472, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DRIVE THRU, 55 18TH AVENUE, -, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10260,'lng':28.10410}},
{'stp':648588,'address':'648588, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GREAT LIQUOR NO 3, ERF 4666,SHOP NO 1, HILLRAND BUILDING, 60 PRETORIA STREET, HILLBROW, South Africa','geo':{'lat':-26.18860,'lng':28.05040}},
{'stp':662492,'address':'662492, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ROCKY LIQUOR STORE, STAND 200, 16 ROCKEY CNR SHERWELL STREETS, DOORNFONTEIN, CBD, South Africa','geo':{'lat':-26.19900,'lng':28.05470}},
{'stp':695228,'address':'695228, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LETHABONG LIQUOR STORE (NTO), 100 17TH AVENUE, ALEXANDRA, ALEXANDRA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10871,'lng':28.10528}},
{'stp':749308,'address':'749308, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CYROMENS BOTTLE STORE, 246 JEPEE STREET, ERF 4834 S1 GROUND FLOOR BEDFIN HOUSE 1, #, JEPPE, South Africa','geo':{'lat':-26.20059,'lng':28.05106}},
{'stp':762734,'address':'762734, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BOXER SUPERLIQUOR ALEXANDRA, 1 STAND NO, CHADWICK AVENUE, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10453,'lng':28.08556}},
{'stp':773131,'address':'773131, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SAUNDERS LIQUOR STORE, SHOP NO 5, REMAINDER OF ERF 1248 HARROW CENTRE, 1 SAUNDERS STREET CORNER OLD HARROW ROAD, YEOVILLE, South Africa','geo':{'lat':-26.18553,'lng':28.05831}},
{'stp':773666,'address':'773666, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CAMBRIDGE FOOD DE VILLIERS, 42 DE VILLIERS, JOHANNESBURG CENTRAL, #, CBD, South Africa','geo':{'lat':-26.19882,'lng':28.04592}},
{'stp':794046,'address':'794046, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GARDEN CITY LIQUOR STORE, 118 HARRISON STREET, #, #, CBD, South Africa','geo':{'lat':-26.19595,'lng':28.03909}},
{'stp':318417,'address':'318417, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY ALEX MALL, 74 SHOP NO, ALEX MALL, INTERSECTION OF LONDON ROAD, (M54) & M3, FAR EAST BANK, ALEXANDRA, ALEXANDRA EAST, South Africa','geo':{'lat':-26.10544,'lng':28.11780}},
{'stp':381327,'address':'381327, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, NORTHCLIFF BLUE BOTTLE, 114 ERF NO, ERVEN 2498 2501 SHOP G03 N GO4, MOUNTAIN VIEW SHOPPING CENTRE, NORTHCLIFF, South Africa','geo':{'lat':-26.14515,'lng':27.95464}},
{'stp':381880,'address':'381880, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, THE FRIDGE, STAND NUMBER, CRN RIVONIA AND 5TH STREET, SANDTON, RANDBURG, South Africa','geo':{'lat':-26.10923,'lng':28.05619}},
{'stp':388773,'address':'388773, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LANGLAAGTE PUB AND TAB, 35 STAND NO, DE VILLE STREET, PAARLSHOP, MAYFAIR, South Africa','geo':{'lat':-26.20206,'lng':27.99065}},
{'stp':388847,'address':'388847, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR INN, 3 SHOP NO, BALFOUR COURT CORNER ATHOL ROAD, AND LOUIS BOTHA AVENUE, HYDE PARK, South Africa','geo':{'lat':-26.13820,'lng':28.08823}},
{'stp':388931,'address':'388931, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SAILORS FISH & CHICKEN RESTAURANT (, 1 SOUTH RAND ROAD, LINMEYER, ERF 1, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.25654,'lng':28.06239}},
{'stp':389929,'address':'389929, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TEX AND SONS LIQUOR STORE, 100 ERF NO, CORNER OF KEWIN STREET AND EDISON STREET, CROESUS INDUSTRIA, MAYFAIR, South Africa','geo':{'lat':-26.20886,'lng':28.00119}},
{'stp':390263,'address':'390263, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, THE FRIDGE WINE N SPIRIT EMPORIUM, 1732 ERF NO, MORNING SIDE SHOPPING CENTRE, CORNER OUTSPAN AND RIVONIA ROAD, RANDBURG, South Africa','geo':{'lat':-26.08213,'lng':28.05965}},
{'stp':390307,'address':'390307, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, COACHMAN S WINE AND LIQUOR EMPORIUM, 17 ERF NO 13,14,15,16,, SHOP NO 2 COACHMAN CROSSING SHOPPING, CENTRE CNR PETER PLACE KAREN STREET, BRYANSTON, South Africa','geo':{'lat':-26.08066,'lng':28.01621}},
{'stp':391262,'address':'391262, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CAMBRIDGE R L DISTRIBUTORS CAMBRIDG, 737 ERF NO, #, #, JEPPE, South Africa','geo':{'lat':-26.21007,'lng':28.09301}},
{'stp':391291,'address':'391291, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SOLLY KRAMERS SOUTHERN SUBURBS, 140 STAND NO, HIGH STREET CNR GREAT BRITTAIN STREET, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24926,'lng':28.03675}},
{'stp':391487,'address':'391487, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BRUMA CITY LIQUOR STORE, 8D STAND NO, NEWTIMES SQUARE, 36 ERNEST OPPENHEIMER, GILLOOLYS, South Africa','geo':{'lat':-26.18157,'lng':28.10729}},
{'stp':467689,'address':'467689, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DUKU BOTTLE STORE, 34 18 SHOP NO FC, ORMONDE SHOPPING CENTRE, ORMONDE EXT 8 ERF 376 & 377, CBD, South Africa','geo':{'lat':-26.24100,'lng':28.00550}},
{'stp':472761,'address':'472761, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ULTIMATE CORNER LIQUIOR STORE, 30 STAND NO, SHOP NO 3, OCKERT BUILDING, CBD, South Africa','geo':{'lat':-26.13150,'lng':28.09400}},
{'stp':473224,'address':'473224, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CAMBRIDGE LIQUOR HILBROW, STAND NO, ERF 4976 CORNER PRETORIUS AND TWIST STR, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18873,'lng':28.04695}},
{'stp':473497,'address':'473497, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BOULEVARD LIQUOR STORE, 158 MAIN ROAD, NEWLANDS, ERF NO 878946651, CBD, South Africa','geo':{'lat':-26.17615,'lng':27.96901}},
{'stp':476009,'address':'476009, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ZACK BOTTLE STORE, STAND NO, ERF 17,33 DAISY STREET, CNR PRAISE STREET, HILLBROW, South Africa','geo':{'lat':-26.24861,'lng':28.05560}},
{'stp':480324,'address':'480324, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, REAL MCCOY LIQUOR STORE, 0001 STAND NO, JOHAN MEYER STREET, LINMEYER , ERF 217 AND ERF 218, CBD, South Africa','geo':{'lat':-26.24951,'lng':28.03901}},
{'stp':482985,'address':'482985, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, WESTDENE LIQUOR STORE, 0001 STAND NO, LANCESTER ROAD, ERF 873, MAYFAIR, South Africa','geo':{'lat':-26.18086,'lng':27.99490}},
{'stp':483896,'address':'483896, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, THE LIQUOR SPECIALIST, 1 TO 25 WEST STREET CNR LONG ROAD, SHOP 9 NORTHCLIFF PIAZZA SHOPPING CENTRE, FARM WATERVAL 211 IQ AND GREYMONT EXT 1, NORTHCLIFF, South Africa','geo':{'lat':-26.16771,'lng':27.96493}},
{'stp':540259,'address':'540259, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, KINGS LIQUOR, 100 BORDEAUX DRIVE, RANDBURG, #, RANDBURG, South Africa','geo':{'lat':-26.09673,'lng':28.00904}},
{'stp':544713,'address':'544713, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BERARIO BOTTLE STORE, 177 ARKANSAS STREET, ERF 361, UNIT 3, BERARIO, MAYFAIR, South Africa','geo':{'lat':-26.13671,'lng':27.95344}},
{'stp':548211,'address':'548211, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, WINE AND LIQUOR AT GREENSIDE, ERF 1317, NO 20 GLEAGLES ROAD, GREENSIDE, HYDE PARK, South Africa','geo':{'lat':-26.14618,'lng':28.01023}},
{'stp':550029,'address':'550029, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BRAMLEY VIEW, CRN CANNING & DUBLIN ROAD, BRAMLEY VIEW EXT 6, 2090, ALEXANDRA WEST, South Africa','geo':{'lat':-26.11530,'lng':28.10570}},
{'stp':550334,'address':'550334, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LOOKING IN LIQUOR STORE, SWARTGOUD & STEFANS STREET, RIDGEWAY, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.25820,'lng':27.99570}},
{'stp':550459,'address':'550459, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, ITAL AFRICA LIQUORS, COR  LOUIS BOTHA &  5TH STREETS, ORANGE GROVE, JOHANNESBURG, GILLOOLYS, South Africa','geo':{'lat':-26.16500,'lng':28.08130}},
{'stp':550809,'address':'550809, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, POPS LIQUOR STOP, 184 STAND NO, NEWLANDS, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.17635,'lng':27.97045}},
{'stp':551955,'address':'551955, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, RANDBURGS LIQUOR STORE, CNR OXFORD & WEST AVE, FERNDALE, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.08190,'lng':27.98320}},
{'stp':551991,'address':'551991, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TEX AND SONS LIQUOR WHOLESALERS, CNR EDISON & KELVIN STS, INDUSTRIA, #, MAYFAIR, South Africa','geo':{'lat':-26.20158,'lng':27.97213}},
{'stp':552002,'address':'552002, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, STATION LIQUOR MARKET, 16B DE VILLE STREET, PAARLSHOOP, LANGLAAGTE, MAYFAIR, South Africa','geo':{'lat':-26.20197,'lng':27.99047}},
{'stp':552189,'address':'552189, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY BRIXTON, 121 HIGH STREET, BRIXTON, #, MELVILLE, South Africa','geo':{'lat':-26.19420,'lng':27.99930}},
{'stp':552831,'address':'552831, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY - TRIOMF, CNR MILLER & EDWARD STREETS, TRIOMF, JHB, MAYFAIR, South Africa','geo':{'lat':-26.17500,'lng':27.98070}},
{'stp':552976,'address':'552976, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, S AND J LIQUORS, STAND 1, SOUTH RAND LINMEYER, LINMEYER, ROSETTENVILLE, South Africa','geo':{'lat':-26.25660,'lng':28.06260}},
{'stp':552994,'address':'552994, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BERRY BOTTLES LIQUOR STORE, SHOPS 3&4 BRYANPARK SHOP CNTRE, COR GROSVENOR & CUMBERLAND RDS, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.05222,'lng':28.00427}},
{'stp':553088,'address':'553088, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, GRAYSTON WINE AND LIQUOR EMPORIUM, SHOP 5 GRAYSTON SHOPPING CENTR, GRAYSTONE DRIVE, STRATHAVON RANDBURG, MORNINGSIDE, South Africa','geo':{'lat':-26.09960,'lng':28.06560}},
{'stp':553260,'address':'553260, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, VB LIQUOR STORE, CNR 5TH & 9TH STREETS, ALBERTSKROON, JOHANNESBURG, NORTHCLIFF, South Africa','geo':{'lat':-26.16130,'lng':27.97550}},
{'stp':553365,'address':'553365, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, FOX DISCOUNT LIQUORS, 160 & 162 FOX STR CNR DELVERS, MARSHALLTOWN, JHB, CBD, South Africa','geo':{'lat':-26.20550,'lng':28.04780}},
{'stp':553382,'address':'553382, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, HANDY LIQUOR STORE, 79 ERF 267 NO, LANGERMAN DRIVE, SOUTH KENSINGTON, JOHANNESBURG, GILLOOLYS, South Africa','geo':{'lat':-26.18900,'lng':28.10890}},
{'stp':553391,'address':'553391, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SOLLY KRAMER S SOUTHERN SUBURBS, 104 HIGH STREET, TURFFONTEIN, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24950,'lng':28.03899}},
{'stp':553403,'address':'553403, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, INDUSTRIA DISCOUNT LIQUORS, CNR KELVIN & EDISON STREET, INDUSTRIA, 2000, MAYFAIR, South Africa','geo':{'lat':-26.20110,'lng':27.97150}},
{'stp':553667,'address':'553667, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY BEYERS NAUDE, 183 BEYERS NAUDE DRIVE, BETWEEN FREDERICK & HOCKY AVE, NORTHCLIFF, NORTHCLIFF, South Africa','geo':{'lat':-26.14430,'lng':27.98010}},
{'stp':627828,'address':'627828, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY HILLBROW, GUILDHALL, CNR EDITH CAVELL & ESSELEN STREET, #, HILLBROW, South Africa','geo':{'lat':-26.19060,'lng':28.04690}},
{'stp':637908,'address':'637908, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MONTY\'S LIQUOR BOTIQUE, SHOP 02 PARKTOWN QUARTERS, CNR 3RD & 7TH AVENUE, PARKTOWN NORTH, HYDE PARK, South Africa','geo':{'lat':-26.14480,'lng':28.02810}},
{'stp':648263,'address':'648263, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY - JAN SMUTS, CORNER JAN SMUTS &, RUTHLAND AVENUE, #, HYDE PARK, South Africa','geo':{'lat':-26.11900,'lng':28.02640}},
{'stp':649844,'address':'649844, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LADS CRAIGHALL PARK, 345 JAN SMUTS AVENUE, CRAIGHALL PARK, #, HYDE PARK, South Africa','geo':{'lat':-26.12190,'lng':28.02920}},
{'stp':650515,'address':'650515, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TURFFONTEIN LIQUOR STORE, ERF 212, CNR HAY & TURFF ROAD, #, #, CROWN MINES, South Africa','geo':{'lat':-26.24028,'lng':28.03900}},
{'stp':661521,'address':'661521, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, X TREME LIQUORS, 652 ONTDEKKERS ROAD, SHOP NO 1 GROUND FLOOR, ERF NO 440, MAYFAIR, South Africa','geo':{'lat':-26.17490,'lng':27.95220}},
{'stp':665449,'address':'665449, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR PLUS, ERF NO 95- 101, APPLETONS SHOPPING CENTRE SHOP NO 12, MALIBONGWE DRIVE RUITERSHOF, RANDBURG, South Africa','geo':{'lat':-26.09450,'lng':27.98500}},
{'stp':690852,'address':'690852, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY CRAIGHALL, 396 JAN SMUTS AVENUE, VALLEY CENTRE SHOP 13B, #, HYDE PARK, South Africa','geo':{'lat':-26.11535,'lng':28.02257}},
{'stp':694811,'address':'694811, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BARRELS AND BOTTLES, MAIN ROAD, SHOP 15A CRAMERVIEW SHOPPING CENTRE, BRYANSTON, BRYANSTON, South Africa','geo':{'lat':-26.07352,'lng':28.01260}},
{'stp':696349,'address':'696349, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DUNKELD WINE AND LIQUOR EMPORIUM, SHOP 29 DUNKELD WEST CENTRE, STAND NO 121 123 AND CONSOLIDATED, ERF 189 271 JAN SMUTS AVENUE, HYDE PARK, South Africa','geo':{'lat':-26.13109,'lng':28.03478}},
{'stp':696654,'address':'696654, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR HOUSE, STAND NO, SHOP NO 18 CARREIRA CENTRE CORNER PINE, REPUBLIC STREET FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.09880,'lng':27.99270}},
{'stp':698822,'address':'698822, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY CAROLINE STREET, 23 CAROLINE STREET, SHOP 8 MARTIQ BUILDING ER 3988, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18680,'lng':28.04840}},
{'stp':699108,'address':'699108, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DOORNSPRUIT CELLARS, CNR FRANS HALS AND CAMBRIDGE STR, PETERVALE SHOPPING CENTRE, #, MORNINGSIDE, South Africa','geo':{'lat':-26.03990,'lng':28.04650}},
{'stp':699212,'address':'699212, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR BOYS 1 CHINAL MALL DECOR CIT, SHOP NO B16, CHINA MALL DECO CITY ERVEN 1 WHIBSEY, DIP TOWNSHIP CORNER COMMANDO AND, MAYFAIR, South Africa','geo':{'lat':-26.20280,'lng':27.95760}},
{'stp':716652,'address':'716652, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SANDTON VIEW LIQUOR STORE, SHOP NO G2 SANDTON VIEW SHOPPING CE, NO 82 HOMESTEAD ROAD,CNR BRAAMFISCHER, DRIVE & NORTH STREET ERF 3786 BRAYNSTON, RANDBURG, South Africa','geo':{'lat':-26.07810,'lng':28.01017}},
{'stp':776345,'address':'776345, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY NORTHCLIFF, STAND NO, SHOP 15&16 NORTHCLIFF SHOPPING CENTRE, C/O BEYERS NOUDE AND MILNER STR MONTROUX, NORTHCLIFF, South Africa','geo':{'lat':-26.14421,'lng':27.98015}},
{'stp':781647,'address':'781647, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, 711 LIQUOR MARKET, CORNER JOHANNES STREET, 14TH AVENUE FAIRLANDS, #, RANDBURG, South Africa','geo':{'lat':-26.15086,'lng':27.94321}},
{'stp':784448,'address':'784448, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CAMBRIDGE LIQUOR RANDBURG, SHOP NO 2, ERVEN 1003 AND 1004, CAMBRIDGE FOOD BUILDING, RANDBURG, South Africa','geo':{'lat':-26.09450,'lng':28.00440}},
{'stp':790565,'address':'790565, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY ALEXANDER, THIRD STREET AND FIRST AVENUE, ERF 545 SHOPS 4A AND 47, ALEXANDRA PLAZA, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10757,'lng':28.08677}},
{'stp':795873,'address':'795873, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CASTROS BLUE BOTTLE LIQUORS, STAND NO, PORTION 137 OF ERF 495 SHOP 1, TURF CENTRE 30 TURF CLUB STREET, CROWN MINES, South Africa','geo':{'lat':-26.23894,'lng':28.03066}},
{'stp':799016,'address':'799016, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY ALEX, SECOND STREET, ERF 193 SHOPS GF1 AND FF1 SHOPS, CORNER WATT AND FIRST AVENUES, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.11047,'lng':28.08843}},
{'stp':317462,'address':'317462, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PNP GF18-PNP LIQUOR STORE ROSETTENV, 1990 ERF, CENTRO CONTINENTAL SHOPPING,  CORNER 209 PRAIRIE AND ROSE STREET, ROSETTENVILLE, South Africa','geo':{'lat':-26.24503,'lng':28.05543}},
{'stp':382702,'address':'382702, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, CONVINIENT LIQUOR STORE, 319 ERF NO, SHOP 1 MARIEL COURT, 11 RAUMA AVENUE, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12265,'lng':28.08335}},
{'stp':388349,'address':'388349, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY LIQUOR STORE PETERVALE G, STAND NUMBER, SHOP NUMBER 9, PETERVALE SHOPPING CENTRE, BRYANSTON, South Africa','geo':{'lat':-26.03987,'lng':28.04630}},
{'stp':388691,'address':'388691, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PNP GD 02 PNP LIQUOR STORE SENDERWO, 15 STAND NO, CHAUCER AVENUE, ON ERF 132 SENDERWOOD EXT 1, GILLOOLYS, South Africa','geo':{'lat':-26.15759,'lng':28.12439}},
{'stp':389947,'address':'389947, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, JINO LIQUOR STORE, 4 QUEENS STREET, WENDYWOOD, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.08312,'lng':28.08539}},
{'stp':390588,'address':'390588, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY COMARO CROSSING GM05 OAK, FF22 SHOP NO FF21, COMARO ROAD SHOPPING CENTRE, CNR OAK AND BOUNDARY ROADS, ROSETTENVILLE, South Africa','geo':{'lat':-26.26658,'lng':28.05906}},
{'stp':391119,'address':'391119, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PNP LNG ST VILLAGE W SC THE MARC GD, VILLAGE WALK SHOPPING CENTRE, CORNER OF MAUDE STREET AND, RIVONIA RD REMAINING PORTION 4 N 5 OF, MORNINGSIDE, South Africa','geo':{'lat':-26.10306,'lng':28.05794}},
{'stp':391553,'address':'391553, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY LS CAMPUS SQUARE GC25, 1114 STAND NO, CAMPUS SQUARE SHOPPING CENTRE, CNR OF KINGSWAY AVE N UNIVERSITY RD, MELVILLE, South Africa','geo':{'lat':-26.18278,'lng':27.99991}},
{'stp':393419,'address':'393419, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PNP LIQUOR STORE RAND STEAM FD20, ERF 271 RAND STEAM SHOPPING, CENTRE ON THE CORNER OF NAPIER ROAD, AND BARRY HERTZOG RICHMOND, MELVILLE, South Africa','geo':{'lat':-26.18189,'lng':28.01612}},
{'stp':461449,'address':'461449, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY GF20 BRAMLEY, 22 CORLETT AND HELEN ROAD, BRAMLEY GARDEN, #, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.12562,'lng':28.09261}},
{'stp':471907,'address':'471907, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY EPSOM DOWN GF 96, 03 SOLAN STREET, EPSOM DOWN SHOPPING CENTRE, SHOP NO 5 AND 6, BRYANSTON, South Africa','geo':{'lat':-26.04248,'lng':28.01458}},
{'stp':476010,'address':'476010, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, KENSINGTON B LIQUOR, 286 ERF NO, 219 BRAM FISCHER DRIVE, KENSINGTON B, HILLBROW, South Africa','geo':{'lat':-26.08168,'lng':28.00910}},
{'stp':476388,'address':'476388, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, VALUE SUPERSAVE LIQUOR STORE, ERVEN 559 AND 560, UNITS 3 AND 4, 18 EDWARD STREET, CORNER AUGUSTA ROAD AND EDWARD STREET, ROSETTENVILLE, South Africa','geo':{'lat':-26.24185,'lng':28.07089}},
{'stp':478700,'address':'478700, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CABIN,  36 INDUSTRIA CENTRE NO, COMMANDO STREET CNR MARAISBURY ROAD, ERF 1/107 INDUSTRIA WEST, MAYFAIR, South Africa','geo':{'lat':-26.19590,'lng':27.96299}},
{'stp':490666,'address':'490666, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, P N P BENMORE GARDENS LIQUOR STORE, STAND NUMBER, PICK N PAY BENMORE GARDENS SHOPPING CENT, CORNER GRAYSTON, HYDE PARK, South Africa','geo':{'lat':-26.09988,'lng':28.04864}},
{'stp':491569,'address':'491569, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PNP GF35- PNP LS- BRIXTON, stand no, ERF NO 967 PROTEA SHOPPING CENTRE, HIGH STREET, MAYFAIR, South Africa','geo':{'lat':-26.19509,'lng':27.99958}},
{'stp':540134,'address':'540134, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY GC83 NEWTOWN JUNCTION, ERVEN 558, 546 AND 621, SHO NO 137 NEW TOEN JUNCTION, CNR OF MIRIAM MAKEBA & BREE STREETS, CBD, South Africa','geo':{'lat':-26.20005,'lng':28.03108}},
{'stp':549148,'address':'549148, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY GF54 BRAAMFONTEIN, 23 JORISSEN STREET, ERF 4451 SHOP NO 24, BRAAMFONTEIN CENTRE, HILLBROW, South Africa','geo':{'lat':-26.19228,'lng':28.03392}},
{'stp':550094,'address':'550094, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY LIQUOR STORE THE GLEN, CNR ORPHEN AND LETABA STREETS, SHOP M4A THE GLEN SHOPPING CENTRE, STAND 33 GLENEAGLES EXT 2, ROSETTENVILLE, South Africa','geo':{'lat':-26.26772,'lng':28.05160}},
{'stp':550365,'address':'550365, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY DARRAS, CNR KITCHENER AND JUNO STREETS, SHOP 19 DARRAS SHOPPING CENTER, #, GILLOOLYS, South Africa','geo':{'lat':-26.19200,'lng':28.08740}},
{'stp':550418,'address':'550418, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, TULISA PARK LIQUOR DISCOUNTERS, CNR HEIDELBERG & PAUL KRUGER, TULISA PARK, TULISA PARK, ROSETTENVILLE, South Africa','geo':{'lat':-26.25082,'lng':28.10863}},
{'stp':550600,'address':'550600, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY CORPORATE GC04 BEDFORDVI, BEDFORDVIEW SHOPPING CENTRE, SMITH STREET, BEDFORVIEW, GILLOOLYS, South Africa','geo':{'lat':-26.18880,'lng':28.12170}},
{'stp':550737,'address':'550737, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BOOYSENS LIQUOR STORE, 31 BOOYSENS ROAD, BOOYSENS, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.23220,'lng':28.02270}},
{'stp':551616,'address':'551616, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LINDEN DISCOUNT LIQUORS, 50 4TH AVENUE, LINDEN, JOHANNESBURG, RANDBURG, South Africa','geo':{'lat':-26.14147,'lng':27.99382}},
{'stp':551987,'address':'551987, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MAKRO WOODMEAD, BETWEEN BEN SCHOEMAN H/WAY, & WOODMEAD ROAD, WOODMEAD, MORNINGSIDE, South Africa','geo':{'lat':-26.05925,'lng':28.09313}},
{'stp':552015,'address':'552015, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY KENSINGTON, SHOP 64B PAK MEADOWS SHOP CENT, CUMBERLAND ROAD, CE ALLAN ROAD KENSINGTON, GILLOOLYS, South Africa','geo':{'lat':-26.18217,'lng':28.11460}},
{'stp':552303,'address':'552303, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY BELLAVISTA, CNR BELLAVISTA & ROYAL STR, BELLAVISTA, JHB, ROSETTENVILLE, South Africa','geo':{'lat':-26.25430,'lng':28.03140}},
{'stp':552525,'address':'552525, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MAKRO SELBY, CNR MAIN REEF & HANOVER, SOUTH STREET, SELBY X 24, CROWN MINES, South Africa','geo':{'lat':-26.21220,'lng':28.01520}},
{'stp':552580,'address':'552580, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, MELVILLE LIQUOR DISCOUNTERS, NO 20 MAIN STREET, MELVILLE, JOHANNESBURG, MELVILLE, South Africa','geo':{'lat':-26.17720,'lng':28.00160}},
{'stp':552662,'address':'552662, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SILVA LIQUORS, 854 STAND NO,  65 AUGUSTA ROAD, CORNER ALICE STREET, ROSETTENVILLE, South Africa','geo':{'lat':-26.24120,'lng':28.06920}},
{'stp':552716,'address':'552716, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, BOOTLEGGERS LOMBARDY, ERF 1-16, PRETORIAROAD, DORELAN, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12670,'lng':28.11990}},
{'stp':553436,'address':'553436, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY NORWOOD HYPER HC05, 197 STAND NO, SHOP 42A NORWOOD MALL BOUNDED BY SARIE, MARAIS ROAD 6th AVENUE EXTENSION HAMLIN, GILLOOLYS, South Africa','geo':{'lat':-26.14920,'lng':28.08000}},
{'stp':553479,'address':'553479, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, DARRENWOOD DISCOUNT LIQUOR STORE, SHOP 6 WOODLEY CENTRE, REPUBLIC RD, DARRENWOOD, NORTHCLIFF, South Africa','geo':{'lat':-26.13020,'lng':27.98620}},
{'stp':553584,'address':'553584, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SUPER LIQUORS, SHOP 1,ERF 238, 30A LANG STREET & 36 ASCOT RD, JUDITHS PAARL, CBD, South Africa','geo':{'lat':-26.19003,'lng':28.07217}},
{'stp':604787,'address':'604787, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY STEELDALE, SHOP NO 5 LINROY STREET, STEELDALE, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24847,'lng':28.09283}},
{'stp':606551,'address':'606551, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY BLACKHEATH, SHOP NO21A,GROUND FLOOR, HEATHWAY SHOPPING CENTRE,CNR BEYERS NAUD, JOHANNESBURG, NORTHCLIFF, South Africa','geo':{'lat':-26.12810,'lng':27.96700}},
{'stp':631331,'address':'631331, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, VIVA RESTAURANT, 43 MENTZ STR, #, #, CROWN MINES, South Africa','geo':{'lat':-26.23205,'lng':28.02276}},
{'stp':641948,'address':'641948, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, SANTA ROSA LIQUOR STORE, ANSON BUILDING, CNR ANSON & KIMBERLEY STREET 104, #, CROWN MINES, South Africa','geo':{'lat':-26.24388,'lng':28.00790}},
{'stp':649267,'address':'649267, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY RANDBURG, ERF 1974 2011 2044  FERNDALE TOWNSH, AND ERVEN 168-170  PRESIDENT RIDGE EXT 7, BRIGHT WATER COMMONS CNR REPUBLIC RD, RANDBURG, South Africa','geo':{'lat':-26.10196,'lng':27.99097}},
{'stp':652690,'address':'652690, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, LIQUOR CITY RIDGEWAY, ERF 256 SHOP4, CNR SHAKESPEAR & LEIPOLDT STREETS, RIDGEWAY CONVENIENCE CENTRE, CROWN MINES, South Africa','geo':{'lat':-26.25420,'lng':27.99680}},
{'stp':653005,'address':'653005, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY WOODMEAD, PICK N PAY HYPERMARKET BUILDING, PART A OF SHOP NO1, WOODMEAD DRIVE, P66-1, VAN REENEN STR, BOUNDED BY M1& N1, MORNINGSIDE, South Africa','geo':{'lat':-26.05250,'lng':28.09330}},
{'stp':653372,'address':'653372, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, EMMY LIQUOR STORE, STAND 855, CNR HILL & OAK STREETS, RANDBURG HILLVIEW CENTRE FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.09283,'lng':28.00234}},
{'stp':690949,'address':'690949, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY SOUTHDALE, PORTION NO 2 OF ERF NO 129, SHOPPING CENTRE CNR LANSBOROUGH AND, ALAMEIN STREET, ROSETTENVILLE, South Africa','geo':{'lat':-26.24273,'lng':28.02333}},
{'stp':693917,'address':'693917, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY HURLINGHAM, SHOP NUMBER LS GROUND FLOOR, LEVEL HURLINGHAM RETAIL CENTRE WILLIAM, NICOLE DRIVE AT REPBLIC ROAD EXTENSION, BRYANSTON, South Africa','geo':{'lat':-26.08900,'lng':28.02750}},
{'stp':698710,'address':'698710, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY CRESTA GC35, SHOP NO U217 CRESTA SHOPPING CENTRE, CNR WESTERVREDEN STREET AND BEYERS, NAUDE DRIVE ERF NO 267 CRESTA EXT 6, NORTHCLIFF, South Africa','geo':{'lat':-26.13060,'lng':27.97720}},
{'stp':699182,'address':'699182, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY MORNINGSIDE, 100 SOUTH ROAD, MORNINGSIDE VIEW CENTRE, MORNINGSIDE, MORNINGSIDE, South Africa','geo':{'lat':-26.08895,'lng':28.06693}},
{'stp':709487,'address':'709487, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY ROSEBANK, SHOP 134C THE MALL OF ROSEBANK, 50 BATH AVENUE SURROUNDED BY BAKER STR, BATH ABD CRADOCK AVENUES ERF 233, HYDE PARK, South Africa','geo':{'lat':-26.14760,'lng':28.04130}},
{'stp':709503,'address':'709503, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY KILLARNEY, SHOP 88 PREVIOUS SHOP 47, KILLARNEY MALL SHOPPING CENTRE, BOUNDED BY RIVIERA ROAD, HYDE PARK, South Africa','geo':{'lat':-26.16730,'lng':28.05400}},
{'stp':756948,'address':'756948, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, KINGS LIQUOR, 282 COMMISSIONER STREET, ERF 663, CORNER KRUGER STREET, GILLOOLYS, South Africa','geo':{'lat':-26.20373,'lng':28.05923}},
{'stp':757216,'address':'757216, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY LIQUOR STORE HYDE PARK G, 8 SHOP NO, PICK N PAY MALL, HYDE PARK CORNER CENTRE, HYDE PARK, South Africa','geo':{'lat':-26.12494,'lng':28.03444}},
{'stp':757991,'address':'757991, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY FAIRMOUNT GENESIS GC71, STAND NO, ERF 219 GROUND FLOOR GENESIS ON FAIRMOUN, C/O LIVINGSTONE AND SANDLER ROAD EXT 2, GILLOOLYS, South Africa','geo':{'lat':-26.14715,'lng':28.09920}},
{'stp':759416,'address':'759416, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, VICTORY PARK CORP-GC29, STAND  NO, SHOP NO 13 VICTORY PARK SHOPPING CENTRE, RUSTENBURG &2ND AVENUE VICTORY PARK, RANDBURG, South Africa','geo':{'lat':-26.13722,'lng':28.00700}},
{'stp':776392,'address':'776392, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK N PAY ALEXANDRA GF75, STAND NO, ERF 40 42-47 56 AND 413 WYNBERG, SHOP G5 PAN AFRICAN SHOPPING CENTRE, ALEXANDRA CENTRAL, South Africa','geo':{'lat':-26.10988,'lng':28.08733}},
{'stp':791588,'address':'791588, EGL - JO\'BURG CENTRA, DENVER OFF PREMISE, PICK AND PAY GC 20 GALLO MANOR, STAND NO, MORNING GLEN SHOPPING CENTRE, BRAIDS AVENUE GALLO MANOR MORNING SIDE, BRYANSTON, South Africa','geo':{'lat':-26.06747,'lng':28.07476}},
{'stp':312080,'address':'312080, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TSONNA, 27 STAND NO, ROCKY STREET, BELLEVUE, HILLBROW, South Africa','geo':{'lat':-26.18111,'lng':28.06811}},
{'stp':320641,'address':'320641, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MULOPWE LIQOUR, 2016 STAND NO, LOUIS BOTHA, MIDRAN ERF NO 2016/00, YEOVILLE, South Africa','geo':{'lat':-26.16796,'lng':28.07748}},
{'stp':377315,'address':'377315, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LEKKI BAR, 4 STAND NO, ROCKEY STREET, BELLEVUE, CBD, South Africa','geo':{'lat':-26.18146,'lng':28.06591}},
{'stp':387954,'address':'387954, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DERBYS CORNER, 255 ERF NO, NO 30 DERBY ROAD, BERTRAMS, JEPPE, South Africa','geo':{'lat':-26.19259,'lng':28.06675}},
{'stp':388723,'address':'388723, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HOLLYWOOD, 27 STAND NO., ROCKY STREET BELLEVUE, #, YEOVILLE, South Africa','geo':{'lat':-26.18105,'lng':28.06812}},
{'stp':391193,'address':'391193, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, 40 ON KOTZE, NO 40 KOTZE STREET, #, #, HILLBROW, South Africa','geo':{'lat':-26.19026,'lng':28.04387}},
{'stp':391843,'address':'391843, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GP PLACE, 43 HIGH STREET, BEREA, #, YEOVILLE, South Africa','geo':{'lat':-26.19866,'lng':28.05990}},
{'stp':392485,'address':'392485, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ELVIS AND SONS LIQUOR STORE, 18 WADDER STREET CRYSTAL PARK, BENONI, #, YEOVILLE, South Africa','geo':{'lat':-26.21007,'lng':28.09928}},
{'stp':392566,'address':'392566, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOOM AND WOW LIQUOR, 89 STAND NO, CITY HALL STREET, ERF 4869, CBD, South Africa','geo':{'lat':-26.19966,'lng':28.03827}},
{'stp':392889,'address':'392889, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ELIQUOR EXPRESS BEIT STREET, 59 ERF, NEW DOORNFONTEIN CORNER 62 BEIT STREET, AND 105 SIVERWRIGHT AVENUE, HILLBROW, South Africa','geo':{'lat':-26.19483,'lng':28.05802}},
{'stp':393287,'address':'393287, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JUST GREENING TRADING ENTERPRISE, 3 SHOP, INTABA VIEWS, 27 KOTZE STREET, HILLBROW, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':477165,'address':'477165, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MASESE S SHEBEEN, 0009 STAND NO, BEDFORD ROAD, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18224,'lng':28.06457}},
{'stp':481872,'address':'481872, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TONY LIQUOR STORE, 71 OP DE BERGE STREET, TROYEVILLE, #, RANDBURG, South Africa','geo':{'lat':-26.20098,'lng':28.07404}},
{'stp':486027,'address':'486027, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LIGHT RAYS BOTTLE STORE, 16 STAND NUMBER, BRUCE STREET, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18602,'lng':28.04835}},
{'stp':487216,'address':'487216, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NGOBALE PANORAMIC GUEST HOUSE, 85 STAND NO, #, #, HILLBROW, South Africa','geo':{'lat':-26.18007,'lng':28.06842}},
{'stp':490668,'address':'490668, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MO LIQUOR STORE AND WINE MERCHANTS, 265 STAND NO,  LOUIS BOTHA AVENUE, ORCHARDS,, HILLBROW, South Africa','geo':{'lat':-26.15616,'lng':28.08529}},
{'stp':550020,'address':'550020, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, IWAHI, 34 DORIS STREET, ERF 651, BEREA, CBD, South Africa','geo':{'lat':-26.18380,'lng':28.05560}},
{'stp':550056,'address':'550056, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BAFANA BAFANA RESTAURANT AND BAR, 137 CLAIM STREET, HILLBROW, JHB, HILLBROW, South Africa','geo':{'lat':-26.18610,'lng':28.04850}},
{'stp':550231,'address':'550231, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OUT OF AFRICA, 11 PRETORIA STREET, GRANADA COURTS 1, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18912,'lng':28.04580}},
{'stp':550275,'address':'550275, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MANOS RESTAURANT AND TAKE AWAY, CNR CAROLINE & QUARTZ STREETS, HILLBROW, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.18640,'lng':28.04800}},
{'stp':550422,'address':'550422, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SLIMS RESTAURANT, 39 (A) KOTZE STREET, HILLBROW, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.18973,'lng':28.04640}},
{'stp':550796,'address':'550796, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CLUB 66, 28 STAND NO, LILIANA COURT, CNR QUARTZ AND GOLDREICH STREET, HILLBROW, South Africa','geo':{'lat':-26.18740,'lng':28.04820}},
{'stp':551154,'address':'551154, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SUMMIT CLUB, CNR PRETORIA & CLAIM STREETS, HILLBROW, #, HILLBROW, South Africa','geo':{'lat':-26.18852,'lng':28.04911}},
{'stp':551262,'address':'551262, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SKYLINE PUB AND RESTAURANT, CNR TWIST & PRETORIA STS, HILLBROW, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.18910,'lng':28.04760}},
{'stp':551636,'address':'551636, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JUNGLE INN, SHOP1 HILLSBOROU, GH MANSION KLEIN STREET, CNR PRETORIA HILLBROW AND KLEIN, HILLBROW, South Africa','geo':{'lat':-26.18940,'lng':28.04540}},
{'stp':552005,'address':'552005, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PRINCESS BAR AND RESTAURANT, 258 LOUIS BOTHA AVENUE, ORANGE GROVE, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.16463,'lng':28.08197}},
{'stp':552165,'address':'552165, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CALIFORNIA RESTAURANT AND BAR, STAND 101,128 SIVEWRIGHT AVENU, NEW DOORNFONTEIN, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.19360,'lng':28.05920}},
{'stp':552310,'address':'552310, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BRAMLEY LIQUOR STORE, TREVENNA CENTRE, 633 PRETORIA MAIN ROAD, KEW, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12150,'lng':28.08430}},
{'stp':552472,'address':'552472, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HOUGHTON LIQUOR STORE, STAND 1163 BLACK STEER CENTRE, CNR LOUIS BOTHA AVE & GEORG RD, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.17710,'lng':28.06360}},
{'stp':552530,'address':'552530, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, COTTON PUB AND GRILL, 50 KOTZE STREET, HILLBROW, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.19000,'lng':28.04680}},
{'stp':553137,'address':'553137, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SONNY\'S RESTAURANT, STAND NO, GERNADA COURT 11, PRETORIA  STREET, HILLBROW, South Africa','geo':{'lat':-26.19411,'lng':28.04555}},
{'stp':553213,'address':'553213, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JABULA EHUSUKU LODGE, ERF 184 17 SOPER ROAD, BEREA, JHB, CBD, South Africa','geo':{'lat':-26.18907,'lng':28.05422}},
{'stp':553310,'address':'553310, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GORETTE LIQUOR DROME (FR), 13 STAND NO, SHOP 7 NO.5 DURHAM STREET, RAEDENE, GILLOOLYS, South Africa','geo':{'lat':-26.14970,'lng':28.09160}},
{'stp':553425,'address':'553425, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DUN ROBIN RESIDENTIAL LODGE, ERF 367,44 JOEL STR, BEREA, JHB, HILLBROW, South Africa','geo':{'lat':-26.18640,'lng':28.05470}},
{'stp':553524,'address':'553524, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RAINBOW INTERNATIONAL EATING HOUSE, MEDICAL HILL BUILDING, G/FLOOR, C/O CLAIM & KOTZE STR, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18990,'lng':28.04910}},
{'stp':553649,'address':'553649, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DJ\'S BISTRO RESTAURANT, HILLSBROUGH MANSIONS, CNR KLEIN AND PRETORIUS STREET, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18900,'lng':28.04540}},
{'stp':561044,'address':'561044, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHEZ MUANGIZA 5 5, No 43 A YEO STREET, YEOVILLE, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.18270,'lng':28.06410}},
{'stp':580595,'address':'580595, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RICHMAN POORMAN RESTAURANT, 6 ROCKEY STREET, SHOP NO A ROCKFELLAS BUILDING, BELLEVLIE, YEOVILLE, South Africa','geo':{'lat':-26.18120,'lng':28.06580}},
{'stp':580596,'address':'580596, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GREENHOUSE, STAND 456, 9 RAYMOND STREET, #, YEOVILLE, South Africa','geo':{'lat':-26.18160,'lng':28.06730}},
{'stp':602865,'address':'602865, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SAFARI PUB AND EATING HOUSE, STAND 04450 , SAFARI INTERN BLDG, CNR BLANKET & YETTAH STREETS, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18310,'lng':28.04890}},
{'stp':604425,'address':'604425, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AFRICAN CORNER, 27 KENMERE STREET, YEOVILLE, #, YEOVILLE, South Africa','geo':{'lat':-26.18080,'lng':28.06300}},
{'stp':607580,'address':'607580, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HILLBROW PUB AND RESTAURANT, ERVEN 3593 / 3594, GROUND FLOOR, HILLBROW GALLARIES BUILDING, 48 PRETORIA STREET, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18842,'lng':28.04924}},
{'stp':608119,'address':'608119, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TIMESQUARE (NTO), 33 RALEIGH STREET, YEOVILLE, Johannesburg, YEOVILLE, South Africa','geo':{'lat':-26.18132,'lng':28.05872}},
{'stp':608983,'address':'608983, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, VIBES (NTO), STAND NO:01163 BLACK STEER CENTRE, CNR LOUIS BOTHA AVE & ST GEORGE ROAD,, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.17705,'lng':28.06313}},
{'stp':608988,'address':'608988, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AMBASSADOR HOTEL (NTO), CNR PRETORIA & BANKET ST, HILLBROW, Johannesburg, HILLBROW, South Africa','geo':{'lat':-26.18883,'lng':28.04976}},
{'stp':619468,'address':'619468, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CLUB DOLLARS (NTO), GOLDRICH STREET, BANKET STREET, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18749,'lng':28.04963}},
{'stp':621124,'address':'621124, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LONDONER (NTO), 36 RALEIGH STREET, FORTISQUE ROAD, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18130,'lng':28.06105}},
{'stp':622042,'address':'622042, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HILLBROW INN (NTO), 43 VAN DER MERWE STREET, HILLBROW, Johannesburg, HILLBROW, South Africa','geo':{'lat':-26.18774,'lng':28.04951}},
{'stp':626114,'address':'626114, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HARLEQUINS, SHOP 4, NEDBANK PLAZA,, CORNER TWIST & PRETORIA STREET, #, HILLBROW, South Africa','geo':{'lat':-26.18920,'lng':28.04680}},
{'stp':631337,'address':'631337, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THUTHUKA SHEBEEN, 87 REGENT STREET, BELLEVUE, #, YEOVILLE, South Africa','geo':{'lat':-26.17840,'lng':28.06740}},
{'stp':633376,'address':'633376, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LIMPOPO INN PUB, 14 BRUCE STREET, HILLBROW, #, HILLBROW, South Africa','geo':{'lat':-26.18570,'lng':28.04790}},
{'stp':639704,'address':'639704, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DANISA BAR AND TAKE AWAY, SHOP 5, HARROCK HEIGHTS, 444 LOUIS BOTHA AVEN, HIGHLANDS NORTH, YEOVILLE, South Africa','geo':{'lat':-26.14698,'lng':28.08679}},
{'stp':639738,'address':'639738, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TORO NEGRO PUB, HILLRAND, 1ST FLOOR, 60 PRETORIA STREET, CNR CATHERINE AVE, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18870,'lng':28.05050}},
{'stp':641499,'address':'641499, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BRAMAX GRILL HOUSE, SHOP NO 2, VANGELIA COURT, 9TH STREET, CNR LOUIS BOTHA AVE, #, YEOVILLE, South Africa','geo':{'lat':-26.16260,'lng':28.08450}},
{'stp':641939,'address':'641939, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TISANIZ UNDERGROUND TAVERN, 66 LOWER LEVEL, KOTZE STREET, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.19010,'lng':28.04883}},
{'stp':642018,'address':'642018, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHINNOS GARDEN, SHOP NO 7 CNR ESSELEN & EDITH, HILLBROW, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.19070,'lng':28.04660}},
{'stp':642670,'address':'642670, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PATIENCE TAVERN, 76 DUNBAR STREET, BELLEVUE YEOVILLE, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.18126,'lng':28.06164}},
{'stp':646654,'address':'646654, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, L CRECHE, 38 RALEIGH STREET, YEOVILLE, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.18131,'lng':28.06122}},
{'stp':657589,'address':'657589, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FIRST ANGEL PUB (NTO), 91 ESSELEWOL STREET, HILLBROW, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.19064,'lng':28.04761}},
{'stp':660786,'address':'660786, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROCKERS PALACE (NTO), ABACADE STREET, YEOVILLE, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.18361,'lng':28.05880}},
{'stp':661850,'address':'661850, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIMUNYE BEER PARLOUR, 2 WEBB STREET, #, #, YEOVILLE, South Africa','geo':{'lat':-26.18520,'lng':28.05800}},
{'stp':662336,'address':'662336, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DIVA\'S BAR (NTO), 208 ERF:1980, LOUIS BOTHA AVENUE, ORANGE GROVE, YEOVILLE, South Africa','geo':{'lat':-26.16793,'lng':28.07779}},
{'stp':662338,'address':'662338, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NADUS TAVERN (NTO), PROSPECT STREET, FIFTH STREET, BEREA, HILLBROW, South Africa','geo':{'lat':-26.19072,'lng':28.05339}},
{'stp':664776,'address':'664776, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ARENA TAVERN (NTO), 3 DERBY STREET, DOORNFONTEIN, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.19312,'lng':28.06439}},
{'stp':667660,'address':'667660, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CLUB 66, 66 LILY STREET, BEREA, #, HILLBROW, South Africa','geo':{'lat':-26.18220,'lng':28.05490}},
{'stp':670036,'address':'670036, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CONVENIENT LIQUOR (NTO), 11 RAUMA AVENUE, BRAMLEY, BRAMLEY, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12266,'lng':28.08338}},
{'stp':682899,'address':'682899, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HILLBROW SPORTS BAR AND EATING HOUS, KOTZE STREET, STAND NO 4419, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18940,'lng':28.05030}},
{'stp':686447,'address':'686447, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ELLISPARK HOTEL, CNR DERBY AND LIDDLE STREETS, #, BERTRAMS ERVEN 200,201,225,226, HILLBROW, South Africa','geo':{'lat':-26.19277,'lng':28.06561}},
{'stp':691418,'address':'691418, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AMBASSADER BAR AND RESTAURANT, 23 ROCKEY STREET, #, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18100,'lng':28.06780}},
{'stp':729352,'address':'729352, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE PILLAR, ERF 1982 65 SIXTH STREET, CNR 181 LOUIS BOTHA, #, YEOVILLE, South Africa','geo':{'lat':-26.16428,'lng':28.08223}},
{'stp':751332,'address':'751332, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FORTY FIVE, 45 MINORS STREET, #, #, YEOVILLE, South Africa','geo':{'lat':-26.18606,'lng':28.06183}},
{'stp':753649,'address':'753649, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CASANOVA, 47 RALEIGH STREET, YEOVILLE, #, YEOVILLE, South Africa','geo':{'lat':-26.18110,'lng':28.06209}},
{'stp':754465,'address':'754465, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DE KLASS CLUB, 27 PRETORIA STREET, ERF 4417, SHOP NO 3, HILLBROW, South Africa','geo':{'lat':-26.19053,'lng':28.04743}},
{'stp':771099,'address':'771099, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PEOPLE S PALACE, STAND NO, ERF 229 NO 28 FIVE AVENUE, #, HILLBROW, South Africa','geo':{'lat':-26.18848,'lng':28.05287}},
{'stp':773007,'address':'773007, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HARROW PUB AND GRILL, STAND NO, ERF 1248 SHOP 4 HARROW CENTRE, 1 SAUNDERS STREET, YEOVILLE, South Africa','geo':{'lat':-26.18558,'lng':28.05820}},
{'stp':781115,'address':'781115, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DE LA REY LIQUOR STORE, 27 10 TH STREET, #, #, HYDE PARK, South Africa','geo':{'lat':-26.12587,'lng':28.09319}},
{'stp':781124,'address':'781124, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BF KITCHEN, STAND NO, ERF 165 , NO 599, LOUIS BOTHA AVENUE BRAMLEY, ALEXANDRA WEST, South Africa','geo':{'lat':-26.12396,'lng':28.08478}},
{'stp':783763,'address':'783763, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EMMANUEL\'S RESTAURANT, 28 CATHRINE STREET, ERF 237 SHOP 1 BEREA, #, HILLBROW, South Africa','geo':{'lat':-26.18979,'lng':28.05127}},
{'stp':788054,'address':'788054, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE EDGE, 0018 STAND NO, CORNER BElT AND SIEMERT STREETS, NEW DOORNFONTEIN, CBD, South Africa','geo':{'lat':-26.19455,'lng':28.05807}},
{'stp':316411,'address':'316411, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MONATE FELA, 347 ERF NO, 133 MARSHALL STREET, CORNER VON BRANDIS AND MARSHALL STREETS, CBD, South Africa','geo':{'lat':-26.20708,'lng':28.04498}},
{'stp':317508,'address':'317508, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, COMPLETE LIQUOR STORE, 18 STAND NO, BATTERY AND MAIN REEF ROAD, DENVER 195, JEPPE, South Africa','geo':{'lat':-26.20626,'lng':28.09933}},
{'stp':332778,'address':'332778, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ELIQUOR WINDSOR GARDENS, 23 STAND NO, BOK STREET WINDSOR GARDENS, JOUBERT PARK ERF NO 4882, CBD, South Africa','geo':{'lat':-26.19619,'lng':28.04508}},
{'stp':379975,'address':'379975, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, VICTORIOUS LIQUOR STORE, 00074 ERF NO, 163/165 COMMISSIONER, DELVERS STREETS, CBD, South Africa','geo':{'lat':-26.20502,'lng':28.04826}},
{'stp':388721,'address':'388721, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AUNTY S PUB, 1733 ERF NO, NO 108 NOORD STREET, #, CBD, South Africa','geo':{'lat':-26.19747,'lng':28.05173}},
{'stp':388889,'address':'388889, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEWTOWN METRO LIQUORS, 1595 ERF NO, 77 HARRISON STREET, SHOP A/B NEWTOWN MALL, CBD, South Africa','geo':{'lat':-26.20094,'lng':28.03968}},
{'stp':391269,'address':'391269, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ELIQUOR GANDHI SQUARE, 1185 ERF NO 307, 311, GANDHI SQUARE PROPERTY HOLDINGS, NO 3AND 4, NO 41 ELOFF STREET, JEPPE, South Africa','geo':{'lat':-26.20746,'lng':28.04194}},
{'stp':391311,'address':'391311, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WHATEVER HOTEL AND RESTAURANT, 44 STAND NO, ERF 2032, #, HILLBROW, South Africa','geo':{'lat':-26.19515,'lng':28.04590}},
{'stp':392905,'address':'392905, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, QUINCY LIQUOR STORE, 374 STAND NO, #, #, JEPPE, South Africa','geo':{'lat':-26.20446,'lng':28.08090}},
{'stp':473165,'address':'473165, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OASIS LIQUOR STORE, 5148 ERF, SHOP 2 PARKADE BUILDING 127, NO 131, CBD, South Africa','geo':{'lat':-26.20489,'lng':28.04510}},
{'stp':473821,'address':'473821, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE RED APPLE, STAND NO, ERF 137, CNR HENRY NKUMALO AND BREE STREETS, CBD, South Africa','geo':{'lat':-26.20174,'lng':28.03001}},
{'stp':476995,'address':'476995, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HOLLYWOOD PLUS RESTAURANT, STAND NO, CORNER DE VILLIERS AND CLAIM STREET, JOUBERT PARK, CBD, South Africa','geo':{'lat':-26.19836,'lng':28.05026}},
{'stp':491432,'address':'491432, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PINKIS INN, 12 STAND NO, AURET AND JULES JEPPESTOWN, #, CBD, South Africa','geo':{'lat':-26.20739,'lng':28.06207}},
{'stp':540929,'address':'540929, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PROMISE LAND RESTAURANT, 94 MOOI STREET, ERF 943, #, CBD, South Africa','geo':{'lat':-26.20188,'lng':28.05081}},
{'stp':547086,'address':'547086, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAMA YORUBA, STAND NO, ERF 1451 SHOP 145 A CNR QUARTS AND BREE, STREET JHB, CBD, South Africa','geo':{'lat':-26.19996,'lng':28.04974}},
{'stp':547718,'address':'547718, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OSIMACO ENTERTAINMENT CENTRE, STAND NO, ERF 1705 TEJOY BUILDING 12, CNR BANKET AND PLEIN STREET, CBD, South Africa','geo':{'lat':-26.19897,'lng':28.05075}},
{'stp':549069,'address':'549069, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHILLY LANE PUB, 86 NOORD STREET, ERF 1761, #, CBD, South Africa','geo':{'lat':-26.19788,'lng':28.04974}},
{'stp':550324,'address':'550324, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CITY PUB AND RESTAURANT, EASTGATE, CNR TWIST AND BOK STREET, JOUBERTPARK, CBD, South Africa','geo':{'lat':-26.19560,'lng':28.04863}},
{'stp':550973,'address':'550973, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAXIME HOTEL, CNR BOK & BANKET STREETS, JOUBERT PARK, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19592,'lng':28.05123}},
{'stp':551391,'address':'551391, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KISS KISS RESTAURANT, KISS KISS, 147 JEPPE STREET, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20163,'lng':28.04084}},
{'stp':551565,'address':'551565, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MANHATTAN RESTAURANT, 1 ST  AND 2ND FLOORS, 36A AND 36B FRASER STREETS CORNER JEPPE, #, CBD, South Africa','geo':{'lat':-26.20170,'lng':28.03830}},
{'stp':551971,'address':'551971, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOYS LIQUOR STORE, FAIRVIEW, JHB, 2000, CBD, South Africa','geo':{'lat':-26.20240,'lng':28.06310}},
{'stp':552054,'address':'552054, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TIVOLI EL EM RESTAURANT, 109 CHURCH STREET, JHB, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20140,'lng':28.04990}},
{'stp':552082,'address':'552082, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, INTERNATIONAL LIQUOR STORE, 169 BREESTREET, NEWTOWN, JHB, CBD, South Africa','geo':{'lat':-26.20120,'lng':28.03570}},
{'stp':552091,'address':'552091, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, QUALITY PUB AND RESTAURANT, SHOP 10,GROUND FLOOR,, CONSTANTIA BUILDING, ERF 1924 AND 1923, CBD, South Africa','geo':{'lat':-26.19685,'lng':28.04475}},
{'stp':552265,'address':'552265, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JANE S JOINT, QUARTZ STREET , SHOP 3, HIECCA BUILDING, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18650,'lng':28.04770}},
{'stp':552356,'address':'552356, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OHUE TAVERN AND GENERAL STORE CC, 82 STAND NO, DE VILLIERS AND QUARTZ STREET, FLAT NO 209, CBD, South Africa','geo':{'lat':-26.19868,'lng':28.04920}},
{'stp':552737,'address':'552737, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, POLANA RESTAURANT, 21 A KLEIN STREET, J H B, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19850,'lng':28.04660}},
{'stp':552791,'address':'552791, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KO MOCHINI LIQUOR STORE, 102 STAND NO, NOORD STREET, #, CBD, South Africa','geo':{'lat':-26.19765,'lng':28.05131}},
{'stp':553006,'address':'553006, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ELOFF STREET TAVERN, 15 ELOFF STREET, JOHANNESBURG, JHB, CBD, South Africa','geo':{'lat':-26.20992,'lng':28.04460}},
{'stp':553165,'address':'553165, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TOP CHOICE, STAND 1431, 4 CLAIM STREET, JOUBERT PARK, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19949,'lng':28.05031}},
{'stp':553366,'address':'553366, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DOWN TOWN EATING HOUSE, STAND 1963 , 28 BOK STR, JOUBERTPARK, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19565,'lng':28.05003}},
{'stp':553515,'address':'553515, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TEXAS CHICKEN RESTAURANT, 23 EDITH CAVELL STREET, JOUBERTPARK HILLBROW, JOHANNESBURG, HILLBROW, South Africa','geo':{'lat':-26.19310,'lng':28.04690}},
{'stp':553545,'address':'553545, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PELES DINNER AND INN PUB, 17 VON WIELLEGH STREET, MARSHALLTOWN, JHB, CBD, South Africa','geo':{'lat':-26.20797,'lng':28.04797}},
{'stp':560334,'address':'560334, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TRUST ME TAVERN,  113 Corner Sauer and Jeppe, Johannesrburg, #, CBD, South Africa','geo':{'lat':-26.20180,'lng':28.03780}},
{'stp':604943,'address':'604943, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CLASS OF JOZI, 19 Von Wielligh,cnr Frederick sts, Marshalltown, #, CBD, South Africa','geo':{'lat':-26.20800,'lng':28.04760}},
{'stp':607601,'address':'607601, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GOLDEN FAST FOODS AND RESTAURANT, 52 BOK STR CNR BANKET STREET, JOUBERT PARK, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19550,'lng':28.05160}},
{'stp':608986,'address':'608986, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EMERALD RETAURANT - DE VILLIERS ST, SHOP NO:8, ERF: 4503, NO:65, DE VILLIERS STREET, CORNER KLEIN STREET, #, CBD, South Africa','geo':{'lat':-26.19952,'lng':28.04855}},
{'stp':611510,'address':'611510, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BELMONT LIQUOR STORE (NTO), 16 BOK STREET, JOUBERT PARK, Johannesburg, CBD, South Africa','geo':{'lat':-26.19380,'lng':28.03460}},
{'stp':612276,'address':'612276, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MEGASTAR LIQUORS (NTO), 70C NOORD STREET, JOHANNESBURG, Johannesburg, CBD, South Africa','geo':{'lat':-26.19849,'lng':28.04641}},
{'stp':617780,'address':'617780, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MANNY\'S SPORTS BAR (NTO), 80 PLEIN STREET, JOHANNESBURG, Johannesburg, CBD, South Africa','geo':{'lat':-26.19952,'lng':28.04285}},
{'stp':618166,'address':'618166, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MEGASTAR RESTAURANT (NTO), 70 C NOORD STREET, JOHANNESBURG, Johannesburg, CBD, South Africa','geo':{'lat':-26.19849,'lng':28.04652}},
{'stp':626774,'address':'626774, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AMAGENTS, SHOP 1 C/N NTEMBI PILISO &, GWIGWI MUREBI STR, #, CBD, South Africa','geo':{'lat':-26.20070,'lng':28.03560}},
{'stp':627588,'address':'627588, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MP CORNER STORE, 134 END STREET, CNR HENCOCK STREET  DOORNFONTEIN, #, CBD, South Africa','geo':{'lat':-26.19685,'lng':28.05243}},
{'stp':629381,'address':'629381, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW WORLD CAFE, CNR KRUIS & MARSHALL STREET, MARSHALLTOWN, #, CBD, South Africa','geo':{'lat':-26.20700,'lng':28.04600}},
{'stp':629826,'address':'629826, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIMUNYE RESTAURANT, 9 HOSPITAL STREET, BRAAMFONTEIN, #, HILLBROW, South Africa','geo':{'lat':-26.19320,'lng':28.04380}},
{'stp':631057,'address':'631057, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AMACHANGANINI, ERF 4895 SHOP 3, PARKLEIGH COURT, 95 WOLMARANS STR, JHB, CBD, South Africa','geo':{'lat':-26.19479,'lng':28.04565}},
{'stp':635260,'address':'635260, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHEILLA\'S PLACE, SHOP NO 2 CNR KING GEORGE AND SMITH, JOHANNESBURG, #, CBD, South Africa','geo':{'lat':-26.19440,'lng':28.04550}},
{'stp':635406,'address':'635406, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, VIRGIN CAFE, 99 PRITCHARD STREET, GROUND FLOOR AND FIRST FLOOR, ERF 907 CNR TROYE STREET, CBD, South Africa','geo':{'lat':-26.20250,'lng':28.04860}},
{'stp':636115,'address':'636115, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE TODOR INN, 116 HARRISSON STREET, BRAAMFONTEIN, #, HILLBROW, South Africa','geo':{'lat':-26.19590,'lng':28.03900}},
{'stp':637104,'address':'637104, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NIGGA SPORTS BAR, 36 CLAIM STREET, CLAIM  & BOK STREET, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19560,'lng':28.05000}},
{'stp':646674,'address':'646674, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EGOLI NIGHT CLUB AND RESTAURANT, 43 KING GEORGE STREET, STAND 4882, #, CBD, South Africa','geo':{'lat':-26.19590,'lng':28.04550}},
{'stp':647180,'address':'647180, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MARISTON HOTEL, 4593 STAND NO, BOUNDED BY CLAIM KOCH BANKET, AND HANCOCK, CBD, South Africa','geo':{'lat':-26.19608,'lng':28.05130}},
{'stp':648200,'address':'648200, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DE PEAK PUB, STAND RE OF 151, 153 BREE STREET, #, #, CBD, South Africa','geo':{'lat':-26.20174,'lng':28.03387}},
{'stp':648717,'address':'648717, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MARLIN S SPOT, SHOP 01,FAIRMONT BUILDING, STAND 4693,9 HOSPITAL STREET, #, HILLBROW, South Africa','geo':{'lat':-26.19340,'lng':28.04390}},
{'stp':654461,'address':'654461, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THANDANANI, ERF 1442, GROUND FLOOR AND BASEMENT, 277 BREE STREET REGION F, CBD, South Africa','geo':{'lat':-26.19990,'lng':28.05000}},
{'stp':654477,'address':'654477, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LEGGAE SPORTS BAR, 154 ANDERSON STREET, CNR POLLY STREET, STAND NO 282 CITY AND SUBURBAN, CBD, South Africa','geo':{'lat':-26.20740,'lng':28.05050}},
{'stp':655071,'address':'655071, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EC ABEL LIQUOR STORE, 41 ESSELEN STREET, SHOP 1 ESSELEN COURT, C/O TWIST STREET ERF 3519, HILLBROW, South Africa','geo':{'lat':-26.19037,'lng':28.04767}},
{'stp':655358,'address':'655358, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CULTURE INN, 245 JEPPE STREET, ERF 1376, REGION F1, CBD, South Africa','geo':{'lat':-26.20060,'lng':28.05090}},
{'stp':657530,'address':'657530, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, INTERNATIONAL BAR (NTO), 169 BREE STREET, CBD, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20143,'lng':28.03523}},
{'stp':657541,'address':'657541, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, STATION LIQUOR MARKET (NTO), 21 WANDERERS STREET, #, #, CBD, South Africa','geo':{'lat':-26.19854,'lng':28.04472}},
{'stp':657596,'address':'657596, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MINA TARVEN (NTO), VAN WEILIGH CBD, CBD, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20907,'lng':28.04806}},
{'stp':658486,'address':'658486, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OASIS - Noord street (NTO), Drill Lane Noord Street, #, #, CBD, South Africa','geo':{'lat':-26.19836,'lng':28.04554}},
{'stp':659824,'address':'659824, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE VILLAGE INN (NTO), 41 GOUD STREET, CBD, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20697,'lng':28.05227}},
{'stp':662337,'address':'662337, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ONE STOP PUB & RESTAURANT (NTO), LEIGH STATE BUILDING, NO:153-155, NUGGET STREET, BRAAMFONTEIN, CBD, South Africa','geo':{'lat':-26.19357,'lng':28.05039}},
{'stp':667523,'address':'667523, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE CAPITAL (NTO), 10 BESETVERNHOOD, JEPPESTOWN, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.20095,'lng':28.06398}},
{'stp':670563,'address':'670563, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIYAYA BAR (NTO), 243 JEPPE & MOOI STREET, JOHANNESBURG, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20039,'lng':28.05076}},
{'stp':673213,'address':'673213, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OJS SPORTS BAR, NUMBER 34 AND 35 SHERWELL STREET, #, #, CBD, South Africa','geo':{'lat':-26.19780,'lng':28.05430}},
{'stp':677284,'address':'677284, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOZI ART CAFE, 264 STAND NO, JEPPE STREET, PORTION 1 NO 86 BEDFORD AVENUE, CBD, South Africa','geo':{'lat':-26.20039,'lng':28.05176}},
{'stp':681099,'address':'681099, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LABAMBA TAVERN (NTO), 36 TWIST STREET, JOUBERT PARK, HILLBROW, CBD, South Africa','geo':{'lat':-26.19586,'lng':28.04854}},
{'stp':683829,'address':'683829, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOIKIS BAR, BANKET STREET, ERF 1737, #, CBD, South Africa','geo':{'lat':-26.19754,'lng':28.05110}},
{'stp':689755,'address':'689755, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MNANDI RESTAURANT AND PUB, 18 NOORD STRAAT, UNIVERSAL BUILDING, #, CBD, South Africa','geo':{'lat':-26.19760,'lng':28.05160}},
{'stp':692485,'address':'692485, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, J C RESTAURANT, 74 STAND, COMMISSIONER CNR DELVERS STREETS 165, #, CBD, South Africa','geo':{'lat':-26.20447,'lng':28.04924}},
{'stp':701311,'address':'701311, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAJESTIC RESTUARNT, 195 SMIT STREET, OLD VOLKSKAS BUILDING ERF 2506, #, HILLBROW, South Africa','geo':{'lat':-26.19476,'lng':28.03869}},
{'stp':702159,'address':'702159, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOZI CITY SHEBEEN (NTO), 70 NOORD STREET, JOUBERT PARK, CBD, CBD, South Africa','geo':{'lat':-26.19889,'lng':28.04628}},
{'stp':708134,'address':'708134, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ARISE AND SHINE RESTAURANT, 248 JEPEE STREET, CNR GOUD, #, #, CBD, South Africa','geo':{'lat':-26.20085,'lng':28.05114}},
{'stp':725611,'address':'725611, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LAS VEGAS TAVERN, SHOP 7, 190 BREE STREET, #, CBD, South Africa','geo':{'lat':-26.20154,'lng':28.03708}},
{'stp':750982,'address':'750982, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MPZ HOTEL, 96 ANDERSON STREET, ERF 358, MARSHALLTOWN, CBD, South Africa','geo':{'lat':-26.20792,'lng':28.04489}},
{'stp':762612,'address':'762612, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOY STEAK GRILLS, 12 PLEIN STREET, TELJOY BUILDING GROUND FLOOR, CORNER CLAIM STREET, CBD, South Africa','geo':{'lat':-26.19897,'lng':28.05040}},
{'stp':772757,'address':'772757, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SOLLY S PLACE, 99 STAND NO, 1035 POLLY STREET JOHANNESBURG, #, CBD, South Africa','geo':{'lat':-26.20024,'lng':28.04722}},
{'stp':773000,'address':'773000, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, IJEOMA PUB AND RESTAURANT, 320 BREE STREET, ERF 1370, JOUBERT PARK, CBD, South Africa','geo':{'lat':-26.20016,'lng':28.05005}},
{'stp':773395,'address':'773395, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PREMIER LIQUOR STORE, 285 BREE STREET, SHOP 2 BREE CHAMBERS, #, CBD, South Africa','geo':{'lat':-26.19977,'lng':28.05053}},
{'stp':773433,'address':'773433, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MICKY S RESTAURANT, 30 STAND NO, DE BEER STREET BRAAMFONTEIN, #, HILLBROW, South Africa','geo':{'lat':-26.19216,'lng':28.03480}},
{'stp':789025,'address':'789025, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHEEKY TIGER JHB, 208 BREE STREET, ERF 1206, #, CBD, South Africa','geo':{'lat':-26.20145,'lng':28.03881}},
{'stp':794775,'address':'794775, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW HEAVEN ENETRTAINMENT CENTRE, 33 STAND NO, 195 COMMISSIONER STREET, #, CBD, South Africa','geo':{'lat':-26.20428,'lng':28.05164}},
{'stp':799693,'address':'799693, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BAFANA CELLARS, 6 VON WIELIGH STREET NUMBER, ERF NUMBER 764, CORNER GRAHAMSTOWN STREET MARSHALLTOWN, CBD, South Africa','geo':{'lat':-26.22629,'lng':28.05132}},
{'stp':320100,'address':'320100, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, E LIQUOR MOTOR CITY, 26 STAND NUMBER, MAIN REEF ROAD, LANGLAAGTE, MAYFAIR, South Africa','geo':{'lat':-26.20293,'lng':27.97080}},
{'stp':351382,'address':'351382, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RIO CORNER INN, 59 STAND NUMBER, STEELPOORT STREET, SOUTH HILLS, ROSETTENVILLE, South Africa','geo':{'lat':-26.25319,'lng':28.09013}},
{'stp':389087,'address':'389087, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOITHABISONG SPORTS BAR, 95 MAIN ROAD, #, NEWLANDS, NORTHCLIFF, South Africa','geo':{'lat':-26.17379,'lng':27.96154}},
{'stp':389968,'address':'389968, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SPOT BAR, 160 MAIN ROAD, #, #, MAYFAIR, South Africa','geo':{'lat':-26.17623,'lng':27.96915}},
{'stp':390017,'address':'390017, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EASY BY NIGHT, 968 ERF NO, SHOP NO 7, CP BARNES CENTRE 314 OAK STREET, RANDBURG, South Africa','geo':{'lat':-26.09356,'lng':28.00005}},
{'stp':390207,'address':'390207, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SBU S PUB, 744 ERF NO, #, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24256,'lng':28.03380}},
{'stp':391108,'address':'391108, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ENUME LIQUOR STORE, 197 STAND NO, BRAMFISCHER DRIVE, KENSINGTON B,, ERF 485 PORTION 3, JEPPE, South Africa','geo':{'lat':-26.08701,'lng':28.00483}},
{'stp':393248,'address':'393248, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NOLLYWOOD PUB, 59 STAND NO, BEAUMONT STREET, BOOYSENS, CROWN MINES, South Africa','geo':{'lat':-26.23654,'lng':28.02400}},
{'stp':472374,'address':'472374, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ONE SPIRIT RESTAURANT, 103 DONNELLY STREET, PORTION 16 OF ERF 49, TURFONTEIN, CBD, South Africa','geo':{'lat':-26.24110,'lng':28.03989}},
{'stp':481194,'address':'481194, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BRAAI HUT, ERF 1600, SHOP 5 , 141 MAIN ROAD, NEWLANDS, MAYFAIR, South Africa','geo':{'lat':-26.17542,'lng':27.96705}},
{'stp':483017,'address':'483017, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SELLO M, 0085 STAND NO, GAUNTLET STREET, ORMONDE VIEW, ROSETTENVILLE, South Africa','geo':{'lat':-26.25201,'lng':27.98201}},
{'stp':483895,'address':'483895, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PALACE 57, STAND NO, SHOP 007-008 RAND CENTRE, NO 177 HENDRIEK VERWOERD DRIVE, RANDBURG, South Africa','geo':{'lat':-26.09086,'lng':28.00602}},
{'stp':543740,'address':'543740, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SOLLYS PUB AND GRILL, 104 HIGH STREET, ERF 01566 CORNER GREAT BRITIAN STREET, TURFFONTEIN, ROSETTENVILLE, South Africa','geo':{'lat':-26.25075,'lng':28.04354}},
{'stp':550325,'address':'550325, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TONYS BLUE SEVEN, 49 STAND NO, GERHARDUS STREET, STRIJDOMPARK EXT 2, RANDBURG, South Africa','geo':{'lat':-26.08080,'lng':27.97370}},
{'stp':551276,'address':'551276, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MNAKAS PLACE, 48 REGAL PLACE, CROWN GARDENS, #, CROWN MINES, South Africa','geo':{'lat':-26.25288,'lng':28.00567}},
{'stp':551345,'address':'551345, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TEX PUB AND GRUB, SHOP 5,KELVIN STREET, INDUSTRIA, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.19748,'lng':27.97403}},
{'stp':551646,'address':'551646, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FOUNTAIN HOTEL, CNR DU TOIT & KRUGER & MARAIS, LANGLAAGTE, #, MAYFAIR, South Africa','geo':{'lat':-26.20237,'lng':27.99023}},
{'stp':552315,'address':'552315, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CLUB FORTUNE, 78 AUGUSTA RD, STAND 507, REGENTS PARK, ROSETTENVILLE, South Africa','geo':{'lat':-26.24150,'lng':28.07050}},
{'stp':552399,'address':'552399, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WINE ROUTE LIQUORS, 886 STAND NO, SHOP 5 REPUBLIC PLACE, CNR RANDBURG MALL PRETORIA ROAD FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.09300,'lng':28.00460}},
{'stp':552556,'address':'552556, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHOSHOLOZA WINE AND DINE, 163 HAY STREET, TURFFONTEIN, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24060,'lng':28.03900}},
{'stp':552568,'address':'552568, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIGNAL RESTAURANT, P.O. BOX 43392, INDUSTRIA, 2091, CROWN MINES, South Africa','geo':{'lat':-26.20020,'lng':27.97230}},
{'stp':552896,'address':'552896, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, REPUBLIC RESTAURANT, ERF 1394, 8 TOBY STREET AND DUBLIN STREET, WESTDENE   2092, MAYFAIR, South Africa','geo':{'lat':-26.18151,'lng':27.98001}},
{'stp':553040,'address':'553040, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SINGERS RESTAURANT, SHOP NO 10 THE GARDEN S/MALL, 170 HENDRIK VERWOERD DRIVE, ERF 742   FERNDALE   RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09090,'lng':28.00770}},
{'stp':553233,'address':'553233, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LADUMA SPORTS TAVERN, 104 HIGH STREET, BRIXTON, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.19505,'lng':28.00070}},
{'stp':553499,'address':'553499, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW MAYFAIR RESTAURANT, CNR CENTRAL AVENUE & WEST STR, MAYFAIR, #, MAYFAIR, South Africa','geo':{'lat':-26.20300,'lng':28.00530}},
{'stp':553506,'address':'553506, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FOREST HILL HOTEL, 52 FOREST STREET, FOREST HILL, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24951,'lng':28.03901}},
{'stp':560450,'address':'560450, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THANDIS PALACE, 3 Royal Str, Turfontein, Johannesburg, ROSETTENVILLE, South Africa','geo':{'lat':-26.25405,'lng':28.03279}},
{'stp':603642,'address':'603642, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HAILEYS PLACE, 10 VINCENT STREET, ROSETTENVILLE EXT 1, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.25540,'lng':28.04900}},
{'stp':604003,'address':'604003, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NDILEKAS PLACE, 38 DEVILLIERS STREET, TURFONTEIN, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24290,'lng':28.03600}},
{'stp':604126,'address':'604126, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FLIPS CAFE, 13 HANGKLIP ST, CLAREMONT, #, MAYFAIR, South Africa','geo':{'lat':-26.18040,'lng':27.95590}},
{'stp':604162,'address':'604162, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MANDENI INN, 107 MENTZ STREET, BOOYSENS, #, CROWN MINES, South Africa','geo':{'lat':-26.23640,'lng':28.02500}},
{'stp':605101,'address':'605101, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ZODWA TAVERN, 232 Stanton Street, Kenilworth, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24854,'lng':28.05039}},
{'stp':605102,'address':'605102, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, STUNKIE ZIYAWA SHEBEEN, 1145 STAND NO, 37 GREAT BRITAIN STREET, TURFFONTEIN, ROSETTENVILLE, South Africa','geo':{'lat':-26.24910,'lng':28.03767}},
{'stp':605104,'address':'605104, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE BEER GARDEN, Shop 8, Ferndale Medical Centre, 168 Hendrick Verwoerd Drive,Ferndale, #, RANDBURG, South Africa','geo':{'lat':-26.09100,'lng':28.00740}},
{'stp':605457,'address':'605457, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FRIENDS NEWLANDS, Shop 3 Jegwa CNR, Cnr Waterval & 8th Street, #, NORTHCLIFF, South Africa','geo':{'lat':-26.16860,'lng':27.96590}},
{'stp':606091,'address':'606091, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WINNAS INN, 1 VAN RHYN STREET, CLAREMONT, #, MAYFAIR, South Africa','geo':{'lat':-26.18080,'lng':27.96120}},
{'stp':606640,'address':'606640, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DOLLYS TAVERN, 118 PHILLIP STREET, ROSETTENVILLE, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.25026,'lng':28.05197}},
{'stp':608992,'address':'608992, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LAS VEGAS RESTAURANT (NTO), 74 MAISBURG STREET, CROESUS, #, MAYFAIR, South Africa','geo':{'lat':-26.19930,'lng':27.97080}},
{'stp':609743,'address':'609743, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BONGOMAN SPORTS BAR (NTO), CNR 13 ONTDEKKERS ROAD & 10TH AVE, NEWLANDS, Johannesburg, MAYFAIR, South Africa','geo':{'lat':-26.17360,'lng':27.96450}},
{'stp':627145,'address':'627145, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JIMAROS TAVERN, 181 TRAMWAY STREET, KENILWORTH, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24897,'lng':28.03771}},
{'stp':631545,'address':'631545, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DAVID S PLACE, 70 NORTH RD, REGENTS PARK, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.23820,'lng':28.06910}},
{'stp':631623,'address':'631623, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EKUTHULENI, 64 LEONARD STREET, KENILWOTH TOWNSHIP, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24797,'lng':28.04522}},
{'stp':631626,'address':'631626, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OCTAVIA RESTAURANT, 02 MINT ROAD, CNR PIONEER ROAD, #, MAYFAIR, South Africa','geo':{'lat':-26.20830,'lng':28.02052}},
{'stp':631630,'address':'631630, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CATHYS PLACE, 149 LANG STREET, ROSETTENVILLE, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24700,'lng':28.06010}},
{'stp':631926,'address':'631926, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MENZO SHEBEEN, 08 STAND NO, BRIGADIER STREET, STEELDALE UNIGRAY, ROSETTENVILLE, South Africa','geo':{'lat':-26.24256,'lng':28.09454}},
{'stp':632183,'address':'632183, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PAUL\'S TAVERN, 14 MAIN ROAD, MELVILLE, #, MAYFAIR, South Africa','geo':{'lat':-26.17690,'lng':28.00120}},
{'stp':635143,'address':'635143, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THAPEDI SHEBEEN, 3 MOFFAT STREET, TURFONTEIN, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24727,'lng':28.04760}},
{'stp':637172,'address':'637172, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, POLLYANA ACTION BAR, ERF 317, CNR DAVIDSON & ELEVENTH STREET, FAIRLANDS, RANDBURG, South Africa','geo':{'lat':-26.14460,'lng':27.93690}},
{'stp':637682,'address':'637682, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NOMPUMELELO, 98 ITALIAN STREET, NEWLANDS, #, MAYFAIR, South Africa','geo':{'lat':-26.17420,'lng':27.96510}},
{'stp':637794,'address':'637794, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHEERS LIQUOR STORE, CNR SHANNON AND ESTANTIA CRESCENT, SOUTH HILL, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.25170,'lng':28.08890}},
{'stp':637999,'address':'637999, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RAND BAR AND RESTAURANT, 6 LA ROCHELLE RD, SPRINGFIELD, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.23100,'lng':28.04630}},
{'stp':638266,'address':'638266, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TROPICAL EDEN, ERF 00469 TURFFONTEIN, 152 HAY STREET, TURFFONTEIN, ROSETTENVILLE, South Africa','geo':{'lat':-26.24116,'lng':28.03889}},
{'stp':638521,'address':'638521, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHOSHOLOZA TAVERN, 101 HIGH STREET, BRIXTON, #, MAYFAIR, South Africa','geo':{'lat':-26.19300,'lng':27.99750}},
{'stp':639337,'address':'639337, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MABALENG SPORTS BAR, 386 HENDRICK VERWOERD, SHOP 386 ORIENTAL PLAZA, FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.09353,'lng':28.00593}},
{'stp':639702,'address':'639702, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SSCHNUZZ NIGHT CLUB AND CHON, 15 KRUGER STREET, CNR DU TOIT STR, PAARLSHOOP, LANGLAAGTE, MAYFAIR, South Africa','geo':{'lat':-26.20250,'lng':27.99060}},
{'stp':641397,'address':'641397, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TSHISA NYAMA, 0 1 JEANE AVENUE, SHOP 8, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09320,'lng':28.00680}},
{'stp':641986,'address':'641986, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MATSATSIS PLACE, 1550 STAND NO, 40 GREAT BRITIAN STREET, TURFFONTIEN, ROSETTENVILLE, South Africa','geo':{'lat':-26.25003,'lng':28.03849}},
{'stp':646417,'address':'646417, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AFRICAN KITCHEN AND PUB, 27 AUGUSTA ROAD NO, REGENTS PARK, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24019,'lng':28.06597}},
{'stp':647822,'address':'647822, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LETHLABILE PUB, 616 STAND NO,  64 TWIST STREET ALBERTVILLE, #, MAYFAIR, South Africa','geo':{'lat':-26.16220,'lng':27.97470}},
{'stp':648569,'address':'648569, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, C TO C PUB, ERF 1746,17 MAIN ROAD, #, #, MAYFAIR, South Africa','geo':{'lat':-26.17430,'lng':27.95503}},
{'stp':650570,'address':'650570, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW FUNCHAL RESTAURANT, STAND NO, ERF349, NO 17 PERTH ROAD, MAYFAIR, South Africa','geo':{'lat':-26.18490,'lng':27.98220}},
{'stp':650732,'address':'650732, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, REPUBLIC DISCOUNT LIQUOR, SHOP NO 2,REPUBLIC BUILDING, ERF 1394,1 DUBLIN STREET CNR 8 TOBY, STREET WESTDENE,REGION B, MAYFAIR, South Africa','geo':{'lat':-26.18160,'lng':27.98030}},
{'stp':652219,'address':'652219, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, IMPERIAL RESTAURANT, 26A AUGUSTA ROAD, #, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24040,'lng':28.06560}},
{'stp':653664,'address':'653664, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LAGOS INN, ERF 984 KEVILWORTH, #, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.25290,'lng':28.05100}},
{'stp':659812,'address':'659812, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MZANSI SPORTS BAR, 170 HENDRIK VERWOERD DRIVE, SHOP 22 RAND ARCADE, #, RANDBURG, South Africa','geo':{'lat':-26.09070,'lng':28.00770}},
{'stp':666317,'address':'666317, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, VICTORY PUB, 31 TURF CLUB STREET, #, ERF 107, ROSETTENVILLE, South Africa','geo':{'lat':-26.23870,'lng':28.03130}},
{'stp':667049,'address':'667049, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ZACHARIAS PLACE, 33 MARKET STREET, #, STAND NO 1942, MAYFAIR, South Africa','geo':{'lat':-26.17460,'lng':27.95880}},
{'stp':682634,'address':'682634, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE BEER GARDEN 2, CNR HF VERWOERD AND HILL STREET, ORIENTAL PLAZA, FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.09282,'lng':28.00597}},
{'stp':683458,'address':'683458, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TASCA MINHOTA RESTAURANT, 218 AND 220 TURF STREET, SHOP 2 AND 3 RACEVIEW COURT, LA ROCHELLE, CROWN MINES, South Africa','geo':{'lat':-26.24140,'lng':28.05360}},
{'stp':684162,'address':'684162, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHEEKY TIGER RANDBURG, 380 STAND NO, ORIENTAL PLAZA CNR HILL AND HENDRICK, VERWOED DRIVE, RANDBURG, South Africa','geo':{'lat':-26.09351,'lng':28.00593}},
{'stp':685402,'address':'685402, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BLUE ROSE PUB AND RESTAURANT, 48 VERONA ROAD, ERF 1484, ROSETTENVILLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.25590,'lng':28.05987}},
{'stp':688736,'address':'688736, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW NATION, 159 HAY STREET, ERF 460, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24140,'lng':28.03883}},
{'stp':690709,'address':'690709, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GRANMAS KITCHEN, 98 MAIN STREET, #, CNR MAIN & HAIG STREET, ROSETTENVILLE, South Africa','geo':{'lat':-26.25280,'lng':28.05130}},
{'stp':700786,'address':'700786, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THABANG TAVERN, D63 STAN NO, KATHARADA PARK, #, MAYFAIR, South Africa','geo':{'lat':-26.18370,'lng':27.96210}},
{'stp':704228,'address':'704228, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MANHATTAN TAVERN (NTO), 110 CENTRAL AVENUE, MAYFAIR, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.20460,'lng':28.01370}},
{'stp':715920,'address':'715920, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HOUSE OF LORDS, 126A MAIN ROAD, NEWLANDS, #, MAYFAIR, South Africa','geo':{'lat':-26.17522,'lng':27.96628}},
{'stp':727746,'address':'727746, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LAGOS TAVERN, ERF 28,SHOP3,TURF CLUB, 112C BELLAVISTA ROAD, BELLAVISTA EAST, ROSETTENVILLE, South Africa','geo':{'lat':-26.24971,'lng':28.03077}},
{'stp':728416,'address':'728416, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOHNNY S TAVERN, 9A OOSTHUIZEN ROAD, NEWLANDS, #, MAYFAIR, South Africa','geo':{'lat':-26.17978,'lng':27.96462}},
{'stp':731185,'address':'731185, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WINE ROUTE TAVERN (NTO), SHOP 8 PRETORIA AND MIKRO LAAN, RANDBURG, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.09277,'lng':28.00456}},
{'stp':755667,'address':'755667, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KLL LIQUOR STORE, 38 VILLAGE ROAD, WEMMER, #, CBD, South Africa','geo':{'lat':-26.21227,'lng':28.04363}},
{'stp':762291,'address':'762291, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LA CONGOLAISE RESTAURANT, 653 STAND NO, 191 PRAIRIE STREET ROSETTNEVILLE, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24598,'lng':28.05498}},
{'stp':762452,'address':'762452, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FIESTA PUB AND GRILL, 106 STAND NO, OXFORD STREET CORNER WEST AVENUE AND, FORD STREET FERNDALE, RANDBURG, South Africa','geo':{'lat':-26.08227,'lng':27.98310}},
{'stp':762453,'address':'762453, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OHIMOR PUB, STAND NO, ERF 252 SHOP 1 VICTOR BUILDING 24-6, STREET CORNER JOHANNERSBURG ROAD, ROSETTENVILLE, South Africa','geo':{'lat':-26.23965,'lng':28.05483}},
{'stp':768116,'address':'768116, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GWENS INN, 128 ANZAR STREET, NEWLANDS, #, MAYFAIR, South Africa','geo':{'lat':-26.17665,'lng':27.96583}},
{'stp':787720,'address':'787720, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, COLLINS PLACE, 00459 STAND NO, 19 ANTRIM CRESCENT, #, CBD, South Africa','geo':{'lat':-26.26030,'lng':28.00595}},
{'stp':788185,'address':'788185, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, COOL CORNER RESTAURANT, 140 HAY STREET, ERF 142, TUFFONTEIN, ROSETTENVILLE, South Africa','geo':{'lat':-26.24250,'lng':28.03873}},
{'stp':311473,'address':'311473, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AFRI KOFFI LOUNGE AND PUB, 166 ERF NO, NO 151 QUEEN STREET, SOUTH KENSINGTON, GILLOOLYS, South Africa','geo':{'lat':-26.18793,'lng':28.10731}},
{'stp':315443,'address':'315443, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GOLDEN POT LIQUOR STORE, 71 ERF NO, ELECTRON 377 SOUTHERN, KLIPRIVIERSBERG ROAD, ROSETTENVILLE, South Africa','geo':{'lat':-26.24344,'lng':28.09552}},
{'stp':318881,'address':'318881, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHEZ MUANGISA 5 5, 17 SHOP NO, CORNER 198 MAIN STREET, ROSETTENVILLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24356,'lng':28.05336}},
{'stp':320535,'address':'320535, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOILING PUB AND SHISANYAMA, 453 STAND NUMBER, JULES STREET, MAL\', CBD, South Africa','geo':{'lat':-26.20255,'lng':28.10491}},
{'stp':334876,'address':'334876, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW BROADWAY PUB ON BEZ VALLEY, 1167 STAND NO, ALBERTINA SISULU ROAD, BEZUIDENHOUT VALLEY, CBD, South Africa','geo':{'lat':-26.19047,'lng':28.08725}},
{'stp':377178,'address':'377178, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, STREETWAIZ LIQUOR STORE, 406 STAND NO,  JULES STREET, #, JEPPE, South Africa','geo':{'lat':-26.20316,'lng':28.10138}},
{'stp':387891,'address':'387891, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AFRICAN CUISINE PUB AND RESTAURANT, 1922 ERF, SHOP NO 1593, JULES STREET, GILLOOLYS, South Africa','geo':{'lat':-26.20090,'lng':28.11637}},
{'stp':387941,'address':'387941, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ANTS LIQOUR STORE, 160 STAND NO, BELLAVISTA ROAD,, TURF CLUB,ERF 14 TURFCLUB, ROSETTENVILLE, South Africa','geo':{'lat':-26.24645,'lng':28.03040}},
{'stp':389047,'address':'389047, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, STEPS PUB AND GRILL, 3 STAND NO, ROBERTS AVE, PORTION 4 OF ERF 7644, JEPPE, South Africa','geo':{'lat':-26.20140,'lng':28.07926}},
{'stp':389706,'address':'389706, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PALMERS, 345 ERF, MAIN STREET SHOP NO 1 CORNER FORD, AND JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20409,'lng':28.06689}},
{'stp':392344,'address':'392344, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MOJA LIQUOR STORE, 434 STAND NO, COMMISSIONER STREET, #, HILLBROW, South Africa','geo':{'lat':-26.20202,'lng':28.07096}},
{'stp':392483,'address':'392483, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MOJA SPORTS CLUB, 192 JULES STREET, MALVERN, #, JEPPE, South Africa','geo':{'lat':-26.20534,'lng':28.07985}},
{'stp':393367,'address':'393367, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, YELLOW DOZ LIQUOR BOUTIQUE, 32 STAND NO, VON BRANDIS STREET, #, JEPPE, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':393370,'address':'393370, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, 64 HOUSES OF AFRICA, 32 STAND NO, VON BRANDIS STREET, #, JEPPE, South Africa','geo':{'lat':-0.00000,'lng':0.00000}},
{'stp':461006,'address':'461006, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SENSATIONS GUEST AND LOUGE BAR, 937 STAND NO, 38 DIERING STREET, KENILWORTH, ROSETTENVILLE, South Africa','geo':{'lat':-26.25192,'lng':28.04757}},
{'stp':464366,'address':'464366, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DE GROOVES PUB AND GRILL, 08 DIAGONAL STREET, ROSETTENVILLE, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24304,'lng':28.05634}},
{'stp':467281,'address':'467281, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHUMBA\'S LIQUOR STORE, 1550 STAND NO, 1ST AND 5TH AVENUE, BEZVALLEY, CBD, South Africa','geo':{'lat':-26.18541,'lng':28.08453}},
{'stp':468774,'address':'468774, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAC LIQUOR STORE, SHOP NO 04, 24 CATHERINE AVENUE, HILLBROW BEREA, CBD, South Africa','geo':{'lat':-26.18968,'lng':28.05107}},
{'stp':472021,'address':'472021, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LIQUOR AT REGENTS, 15 JAMES STREET, REGENT PARK ESTATE, ERF 94, ROSETTENVILLE, South Africa','geo':{'lat':-26.24036,'lng':28.06466}},
{'stp':472757,'address':'472757, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NDLOVU PUB, 830 ERF, 567 A JULES STREET, MALVERN, CBD, South Africa','geo':{'lat':-26.20116,'lng':28.11441}},
{'stp':474140,'address':'474140, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BREAK AWAY LIQUOR STORE, STAND 50, 334 JULES STREET, MARLVERN, RANDBURG, South Africa','geo':{'lat':-26.20522,'lng':28.09343}},
{'stp':480044,'address':'480044, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BROADWAY ON JULES LIQUOR STORE, 526 JULES STREET, ERF 422000002, JEPPESTOWN, CBD, South Africa','geo':{'lat':-26.20167,'lng':28.10984}},
{'stp':480115,'address':'480115, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE BASE RESTAURANT, 3517 STAND NO, 3518, 99 TWIST STREET, HILL BROW, #, CBD, South Africa','geo':{'lat':-26.18981,'lng':28.04721}},
{'stp':481868,'address':'481868, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SENSATION BAR LOUNGE, 407 STAND NUMBER, 65 NAPIER STREET, FOREST HILL, ROSETTENVILLE, South Africa','geo':{'lat':-26.25466,'lng':28.03807}},
{'stp':481875,'address':'481875, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SOBELELA CAFE- LIQUORS AND TRADING, 0025 ERF 379, 7TH STREET LA ROCHELLE, ROSETTENVILLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.23884,'lng':28.05538}},
{'stp':486763,'address':'486763, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ABSOLUTELY FABULOUS COCKTAIL BAR, 69 STAND NO, OP DE BERGE STREET, TROYEVILLE, BRYANSTON, South Africa','geo':{'lat':-26.20099,'lng':28.07374}},
{'stp':550294,'address':'550294, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW NUGGET HOTEL, 27 NUGGET STREET, CITYSUBURBAN, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20610,'lng':28.05300}},
{'stp':550319,'address':'550319, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MACARENA SPORTS BAR, 118 JULES STREET, JEPPESTOWN, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20620,'lng':28.07360}},
{'stp':550522,'address':'550522, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THANDIES TAVERN AND RESTAURANT, PORTION 1 OF ERF 27 AND, PORTION 2 ERF 28 FARADAY STREET WEMMER, #, CBD, South Africa','geo':{'lat':-26.21262,'lng':28.04443}},
{'stp':550749,'address':'550749, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JEPPE FOOD, ERF 349,JEPPESTOWN, JEPPESTOWN, JHB, JEPPE, South Africa','geo':{'lat':-26.20340,'lng':28.06400}},
{'stp':551002,'address':'551002, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MANTONG RESTAURANT, 7 MARITZBURG STREET, JOHANNESBURG, JHB, CBD, South Africa','geo':{'lat':-26.20700,'lng':28.06030}},
{'stp':551073,'address':'551073, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GENT S TAVERN, 57A GUS STR, FAIRVIEW, JHB, JEPPE, South Africa','geo':{'lat':-26.20270,'lng':28.06390}},
{'stp':551788,'address':'551788, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EKUTHULENI TAVERN, 182 A JULES STR, JEPPESTOWN, JHB, JEPPE, South Africa','geo':{'lat':-26.20553,'lng':28.07942}},
{'stp':551827,'address':'551827, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MODERN REST, 18 LOWER GERMISTON ROAD, HERIOTDALE, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.21180,'lng':28.12320}},
{'stp':552034,'address':'552034, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE T SQUARE RESTAURANT, 435 COMMISSIONER ST CNR OP DIE, BERGEN STREET, FAIRVIEW, JEPPE, South Africa','geo':{'lat':-26.20109,'lng':28.07380}},
{'stp':552049,'address':'552049, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TROYVILLE ARCADE, 31 C BEZUIDENHOUT AVE, TROYEVILLE, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.19830,'lng':28.06710}},
{'stp':552316,'address':'552316, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BEAUTYS TRADITIONAL FOOD, SHOP 18, NUGGET STR, #, CBD, South Africa','geo':{'lat':-26.20680,'lng':28.05300}},
{'stp':553227,'address':'553227, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAFE PEPE RESTAURANT, 164 BROADWAY MANSIONS, 164 MABEL STREET, ROSETTENVILLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24380,'lng':28.05350}},
{'stp':580867,'address':'580867, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THANDA BANTU SHEBEEN, 83 BERG STREET, ROSETTENVILLE, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.25300,'lng':28.06080}},
{'stp':627578,'address':'627578, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BONGAS TAVERN INN, 16 ISIPINGO STREET, BELLEVUE EAST, #, YEOVILLE, South Africa','geo':{'lat':-26.18480,'lng':28.06610}},
{'stp':628351,'address':'628351, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAVUNDLAS PLACE, 113 CNR HIGH AND LILY STREET, ROSETTENVILLE, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24750,'lng':28.05870}},
{'stp':630996,'address':'630996, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AGHENUS SPORT BAR AND DSTV, 572 JULES STREET, STAND NO 494 AND 495, MALVERN, JEPPE, South Africa','geo':{'lat':-26.20120,'lng':28.11710}},
{'stp':632581,'address':'632581, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SANGITTA TAVERN, C/R 2ND STREET & TURF ROAD, LA ROCHELLE, LA ROCHELLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24240,'lng':28.05340}},
{'stp':634814,'address':'634814, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SPORTSMAN BAR, 401 COMMISSIONER STREET, FAIRVIEW, #, JEPPE, South Africa','geo':{'lat':-26.20220,'lng':28.07080}},
{'stp':635224,'address':'635224, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BROADWAY PUB, STAND 803  BROADWAY ROAD, BEZUIDENHOUT VALLEY, #, JEPPE, South Africa','geo':{'lat':-26.19040,'lng':28.08720}},
{'stp':636120,'address':'636120, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NELLIE S RESTAURANT, STAND NO 171  VILLAGE MAIN, NO 1 HEIDELBURG ROAD  VILLAGE MAIN, #, CBD, South Africa','geo':{'lat':-26.21200,'lng':28.04900}},
{'stp':638263,'address':'638263, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SUNSHINE SPORTS BAR, 551 JULES STREET, MALVERN, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20150,'lng':28.11340}},
{'stp':639183,'address':'639183, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OJ FUNKY CENTRE, 561 JULES STREET, MAALVERN, #, JEPPE, South Africa','geo':{'lat':-26.20140,'lng':28.11410}},
{'stp':642000,'address':'642000, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIBUSISO TAVERN, 621 CNR JULES AND HERIOT STREET, MALVERN, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20070,'lng':28.11910}},
{'stp':642676,'address':'642676, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOHNNY AND JOYCE SHEBEEN, 166 PRAISIE STREET, PRAIRIE STREET, ROSETTENVILLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24720,'lng':28.05530}},
{'stp':646387,'address':'646387, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOVARD\'S PUB, 153 KITCHENER AVENUE, KITCHENER AVENUE, BEZUIDENHOUT VALLEY, JEPPE, South Africa','geo':{'lat':-26.19110,'lng':28.08680}},
{'stp':646598,'address':'646598, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MONICA\'S PUB, 1975&1972 STAND A, CLARIDGE COURT, LEYD STREET, JOUBERT PARK, CBD, South Africa','geo':{'lat':-26.19546,'lng':28.04456}},
{'stp':648744,'address':'648744, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SAMPISI EATING HOUSE, ERF 2000 AD 2001, 201 JULE STREET CORNER SINCLAIR STREET, #, JEPPE, South Africa','geo':{'lat':-26.20518,'lng':28.08192}},
{'stp':648779,'address':'648779, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BG S TIN SHACK, SHOP NO 10 VERONA STREET, #, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.25530,'lng':28.05200}},
{'stp':651096,'address':'651096, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW SOUTH AFRICA LIQUOR STORE, ERF 294/5 ANDERSON CENTRE, CNR NTEMI PILSO & ANDERSON STREETS, FERREIRADORP, CBD, South Africa','geo':{'lat':-26.20900,'lng':28.03540}},
{'stp':652808,'address':'652808, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE BIG APPLE, 350 COMMISSIONER STREET AND, HANS STREET, ERF NO 91, #, JEPPE, South Africa','geo':{'lat':-26.20300,'lng':28.06560}},
{'stp':653492,'address':'653492, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GOOD TIMES LEISURE BAR, STAND NO 335, WESTGATE SHOPP CENTRE, #, CBD, South Africa','geo':{'lat':-26.21150,'lng':28.03480}},
{'stp':653647,'address':'653647, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, VIRGIN ISLE POOL CLUB, CENTRO CITY CNR TRUMP STREET, AND BOOYSENS ROAD, SELBY, CBD, South Africa','geo':{'lat':-26.21290,'lng':28.03800}},
{'stp':654518,'address':'654518, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THIBAUT LIQUOR STORE, 233 JOHANNESBURG ROAD CNR 2ND STR, GROUND FLOOR STAND 29, LA ROCHELLE BUILDING JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24220,'lng':28.05510}},
{'stp':654519,'address':'654519, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THIBAUT PUB, STAND 29, 233 JOHANNESBURG ROAD CORNER SECOND, STREET, LA ROCHELLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24220,'lng':28.05510}},
{'stp':654705,'address':'654705, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DEE JAYS ON JULES, 376 JULES STREET, #, #, JEPPE, South Africa','geo':{'lat':-26.20351,'lng':28.09837}},
{'stp':655290,'address':'655290, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DANIELS NITE CLUB, STAND NO 1503 158, JULES STREET, JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20593,'lng':28.07707}},
{'stp':657528,'address':'657528, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LEBOMBO TARVEN (NTO), 47 BETTY STREET SHOP NO 5, JOHANNESBURG CBD, JEPPE\'S TOWN, JEPPE, South Africa','geo':{'lat':-26.20509,'lng':28.06089}},
{'stp':658424,'address':'658424, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OASIS SPORTS BAR (NTO), 56 END STREET, JOHANNESBURG CBD, DOORNFONTEIN, CBD, South Africa','geo':{'lat':-26.20350,'lng':28.05425}},
{'stp':658869,'address':'658869, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FASTLANE TAVERN (NTO), ANDERSON STREET, CBD, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20896,'lng':28.03496}},
{'stp':662679,'address':'662679, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEWS SOUTH AFRICAN SPORTS BAR, CNR NTEMI PILISO AND ANDERSON STR, ERF 294 AND 5 ANDERSON CENTRE, FARREIRADORP, CBD, South Africa','geo':{'lat':-26.20880,'lng':28.03530}},
{'stp':664182,'address':'664182, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LAKERS LIQUOR, 1196 BROADWAY STREET, CORNER 5TH STREET, BEZ VALLEY, GILLOOLYS, South Africa','geo':{'lat':-26.19080,'lng':28.08670}},
{'stp':666352,'address':'666352, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAMS PUB, 48 SIVEWRIGHT AVENUE, #, DOORNFONTEIN, JEPPE, South Africa','geo':{'lat':-26.19968,'lng':28.05657}},
{'stp':666895,'address':'666895, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NTOKOZWENI TAVERN, 6 ANDERSON STREET, SHOP NO 5 ANDERSON CENTRE, #, CBD, South Africa','geo':{'lat':-26.20890,'lng':28.03500}},
{'stp':667743,'address':'667743, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BROADWAY ON JULES STREET, STAND NO 422,526 JULES STREET, #, MALVERN, JEPPE, South Africa','geo':{'lat':-26.20170,'lng':28.11250}},
{'stp':684169,'address':'684169, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HAPPY HOUR TAVERN (NTO), 204 PREIRIE STREET, ROSETTEVILLE, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24406,'lng':28.05514}},
{'stp':687252,'address':'687252, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, M MPUNGOSE MARSHALL TAVERN, 330A MARSHALL STREET, ERF 299 302A EPSTEIN BUILDING, JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20520,'lng':28.06390}},
{'stp':687295,'address':'687295, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OJ\'S PUB (NTO), 40 SHERWELL STREET, DOORNFONTEIN, DOORNFONTEIN, CBD, South Africa','geo':{'lat':-26.19763,'lng':28.05413}},
{'stp':690054,'address':'690054, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ZICO CAFE HOTEL, 373 STAND NO, JULES STREET JEPPESTOWN JOHANNESBURG, #, JEPPE, South Africa','geo':{'lat':-26.20330,'lng':28.09810}},
{'stp':693172,'address':'693172, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RAYLTON TAVERN, 35 END STREET, ERF 135, CITY AND SUBURBAN, CBD, South Africa','geo':{'lat':-26.20470,'lng':28.05420}},
{'stp':695610,'address':'695610, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BREAKAWAY PUB, 332 JULES STREET, CNR JULES AND THIRD STREET, ERF NO 49, JEPPE, South Africa','geo':{'lat':-26.20380,'lng':28.09410}},
{'stp':701562,'address':'701562, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ECONTRO DOS AMIGOS (NTO), 270 JOHANNESBURG ROAD, CNR 8TH STREET, ERF: 406, LA ROCHELLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.23831,'lng':28.05520}},
{'stp':705747,'address':'705747, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAFE DE SOBELELA, 379 ERF NO, NO 25 7  STREET LA ROCHELLE, ROSETTENVILLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24226,'lng':28.05508}},
{'stp':734517,'address':'734517, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LA CANDICE TAVERN, 55 GOUD STREET, #, #, CBD, South Africa','geo':{'lat':-26.20551,'lng':28.05208}},
{'stp':744617,'address':'744617, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KWA MOFOKENG, 15 QUEEN STREET, #, #, JEPPE, South Africa','geo':{'lat':-26.20319,'lng':28.11310}},
{'stp':751006,'address':'751006, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE PIE TAVERN, STREET NO, CNR BETTY AND MARSHAL STREET, JEPPESTOWN JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20505,'lng':28.06158}},
{'stp':762480,'address':'762480, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OUTLOOK INN, 422 JULES STREET, ERF 237, MALVERN, JEPPE, South Africa','geo':{'lat':-26.20300,'lng':28.10277}},
{'stp':769026,'address':'769026, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, COCOGIS, 1917 STAND N0, 197 JULES STREET, CORNER JULES AND SINCLAIR STREETS, JEPPE, South Africa','geo':{'lat':-26.20501,'lng':28.08157}},
{'stp':775137,'address':'775137, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DEE GLEN PUB, 502 JULES STREET, MALVERN, #, JEPPE, South Africa','geo':{'lat':-26.20193,'lng':28.11040}},
{'stp':781652,'address':'781652, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROSY S PLACE, 165 ALBERT STREET, ERF 1191 SHANGARILA COURT, CORNER BEREA, HILLBROW, South Africa','geo':{'lat':-26.20707,'lng':28.05776}},
{'stp':797697,'address':'797697, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TITANIC, ANDERSON STREET, ERVEN 294 AND 295, 6 ANDERSON CENTRE FERREIRAS TOWN, CBD, South Africa','geo':{'lat':-26.20900,'lng':28.03540}},
{'stp':311764,'address':'311764, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DINANGWE INN, 2 STAND NO, BONDMIN ROAD, MALVERN, JEPPE, South Africa','geo':{'lat':-26.20396,'lng':28.11062}},
{'stp':312787,'address':'312787, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CURTIS LIQUOR STORE, 0074960000 ERF NO, SHOP NO 20, ELOFF STREET, CBD, South Africa','geo':{'lat':-26.20935,'lng':28.04453}},
{'stp':318745,'address':'318745, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PAPA HENS LIQUOR STORE, 81 STAND NO, LEONARD CORNER BERTHA AVENUE, TURFONTAIN, ERF NO 552, ROSETTENVILLE, South Africa','geo':{'lat':-26.24302,'lng':28.04626}},
{'stp':319022,'address':'319022, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MOLOKS BAR AND LODGE, 195 STAND NO, 22 CHAMBERS STREET, BOOYSENS, CBD, South Africa','geo':{'lat':-26.23563,'lng':28.02296}},
{'stp':383544,'address':'383544, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FOX DEN LIQUOR STORE, 275 STAND NO, MAIN STREET, JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20523,'lng':28.05569}},
{'stp':387829,'address':'387829, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SMILIEES LIQUOR STORE, 21A ERF 429, STAND NO, KIMBERLEY ROAD, BETRAMS, JEPPE, South Africa','geo':{'lat':-26.19555,'lng':28.06831}},
{'stp':388989,'address':'388989, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, INFINITE LIQUORLAND, 5 STAND NO, #, #, CBD, South Africa','geo':{'lat':-26.20094,'lng':28.05849}},
{'stp':389867,'address':'389867, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AFRICAN LIQUOR SHOP, 79 STAND NO, ANDREWS, KENILWORTH, ROSETTENVILLE, South Africa','geo':{'lat':-26.22951,'lng':28.05194}},
{'stp':390602,'address':'390602, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MENTOR LIQUOR SUPPLIES, 02 SHOP NO, #, #, JEPPE, South Africa','geo':{'lat':-26.20745,'lng':28.06418}},
{'stp':392354,'address':'392354, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE XO LIQUOR STORE, 1165 ERF, 124 LOUIS BOTHA AVE YEOVILLE, #, YEOVILLE, South Africa','geo':{'lat':-26.17676,'lng':28.06207}},
{'stp':462751,'address':'462751, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROSH LIQUOR STORE, 1A STAND NO, HENRY NXUMALO STREET, NEWTOWN, CBD, South Africa','geo':{'lat':-26.20688,'lng':28.03037}},
{'stp':463947,'address':'463947, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHU CHU LIQUOR STORE, 136 JULE STREET, ERF 2835 OF THE FARM JEPPESTOWN 92 IR, MALVERN, CBD, South Africa','geo':{'lat':-26.20230,'lng':28.03748}},
{'stp':485001,'address':'485001, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE CAVE, 11 STAND NO, CORNWELL STREET, TURFFONTEIN WEST, ROSETTENVILLE, South Africa','geo':{'lat':-26.24188,'lng':28.02946}},
{'stp':485454,'address':'485454, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CENTURY LIQUOR STORE, 55 STAND NUMBER, WOLMARANS AND TWIST, JOUBERT PARK, GILLOOLYS, South Africa','geo':{'lat':-26.19407,'lng':28.04803}},
{'stp':486811,'address':'486811, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SAN SOUCI LIQUOR STORE, 1 STAND NUMBER, EMPIRE ROAD, RICHMOND, HYDE PARK, South Africa','geo':{'lat':-26.18353,'lng':28.01575}},
{'stp':491951,'address':'491951, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DAZZLE LIQUOR STORE, 312 STAND NO, ERF 1358, BREE STREET,, MONICA HOUSE, CBD, South Africa','geo':{'lat':-26.20046,'lng':28.04537}},
{'stp':550240,'address':'550240, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BLONDIES PLACE, 4067 IVY COURT, WESTBURY, EXT3, MAYFAIR, South Africa','geo':{'lat':-26.18340,'lng':27.97810}},
{'stp':550954,'address':'550954, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ADELE\'S PLACE, 1301 HIBISCUS COURT, WESTBURY, EXT 3, MAYFAIR, South Africa','geo':{'lat':-26.18530,'lng':27.97507}},
{'stp':550967,'address':'550967, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ATLANTIS RESTAURANT, 47 PURCELL STR, CNR PAUL KRUGER & HEIDELBERG R, TULISAPARK, ROSETTENVILLE, South Africa','geo':{'lat':-26.25080,'lng':28.09850}},
{'stp':551431,'address':'551431, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOHNY S TAVERN, 890B SEEKOEI STREET, RIVERLEA, #, CROWN MINES, South Africa','geo':{'lat':-26.21870,'lng':27.97640}},
{'stp':551819,'address':'551819, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHESA BAFANA, 7 BIDOU STREET, CLAREMONT, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.17610,'lng':27.95428}},
{'stp':552881,'address':'552881, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAYFAIR LIQUOR STORE, 110 CENTRAL AVE., MAYFAIR, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.20456,'lng':28.01353}},
{'stp':552982,'address':'552982, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SUPER LIQUOR STORE, 10 KOCH STREET, ERF 1927 CNR WANDERES, #, CBD, South Africa','geo':{'lat':-26.19690,'lng':28.04470}},
{'stp':553049,'address':'553049, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FRANCE S INN, 980B MOLOPO STREET, RIVERLEA, EXT 1, CROWN MINES, South Africa','geo':{'lat':-26.21890,'lng':27.97870}},
{'stp':553075,'address':'553075, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TSHIMANGES TAVERN, STD 1731,, DUNLIN AVENUE, RIVERLEA EXT 5,JHB, CROWN MINES, South Africa','geo':{'lat':-26.21590,'lng':27.98810}},
{'stp':553183,'address':'553183, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JANSENS SHEBEEN, 938A JUKSKEI DRIVE, #, #, CROWN MINES, South Africa','geo':{'lat':-26.21855,'lng':27.98006}},
{'stp':553200,'address':'553200, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BEN S FISH AND CHIPS, 111 MAIN STREET, BENROSE, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20850,'lng':28.07920}},
{'stp':553476,'address':'553476, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JABS TAVERN, 33 AANDBLOM STREET, JAN HOF MEYER, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.19277,'lng':28.01033}},
{'stp':553694,'address':'553694, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEWTOWN LIQUOR STORE, STAND 2701  SHOPS 4 TO 6, JOHN COURT  23 EDITH CAVELL STREET, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.19260,'lng':28.04690}},
{'stp':560363,'address':'560363, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOYKIES PLACE, 4198 LAWRENCE WISSENAAR STREET, #, #, MAYFAIR, South Africa','geo':{'lat':-26.18530,'lng':27.98090}},
{'stp':561218,'address':'561218, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JAMES PLACE, 950B KOMANI PLACE, RIVERLEA EXT, #, CROWN MINES, South Africa','geo':{'lat':-26.21920,'lng':27.98000}},
{'stp':561221,'address':'561221, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DU PONTS INN, 840 A JUKSKEI DRIVE, RIVERLEA EXT 7, #, CROWN MINES, South Africa','geo':{'lat':-26.21750,'lng':27.97730}},
{'stp':561245,'address':'561245, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BIG BROTHERS TAVERN, 936 A JUKSKEI RYLAAN, RIVERLEA EXT, #, CROWN MINES, South Africa','geo':{'lat':-26.21810,'lng':27.97920}},
{'stp':580528,'address':'580528, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ELIZABETHS INN, EFR 981C MOLOPO STREET, RIVERLEA EXT 1, #, CROWN MINES, South Africa','geo':{'lat':-26.21890,'lng':27.97860}},
{'stp':580787,'address':'580787, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RHANDU WHA LTD, 41 LOMALA STREET, RIVERLEA, #, CROWN MINES, South Africa','geo':{'lat':-26.21680,'lng':27.97910}},
{'stp':581165,'address':'581165, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHARIFFAS INN, 855A KARIEGA  STREET, RIVERLEA, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.21718,'lng':27.97547}},
{'stp':603862,'address':'603862, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KENNEYS PLACE, 21 KENTUCKY STREET, RIVERLEA, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.21500,'lng':27.97850}},
{'stp':604013,'address':'604013, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ELPHIAS JABULANI NGWENYA, 66 BELLAVISTA ROAD, TURFONTEIN, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.25290,'lng':28.03080}},
{'stp':604064,'address':'604064, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHINAS, 1516 CARNATION PLACE, WESTBURY EXT 3, #, MAYFAIR, South Africa','geo':{'lat':-26.18473,'lng':27.97658}},
{'stp':604093,'address':'604093, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MA POPPIE\'S INN, 1398 HAY AVENUE, WESTBURY EXT 111, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.18320,'lng':27.97780}},
{'stp':604107,'address':'604107, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WELLINGTONS PLACE, 81 BEAUMONT STREET, TURFONTEIN WEST, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.23840,'lng':28.02700}},
{'stp':605665,'address':'605665, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FANNY S TAVERN, ERF 1217 PERCY PEFFER STREET, WESTBURY EXT 3, #, MAYFAIR, South Africa','geo':{'lat':-26.18480,'lng':27.97650}},
{'stp':606161,'address':'606161, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAPES TAVERN, 1528 JOHN MILLEN RD, WESTBURY EXT 3, #, MAYFAIR, South Africa','geo':{'lat':-26.18540,'lng':27.97940}},
{'stp':606880,'address':'606880, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOES PLACE, 55 SHORTMARKET STREET, NEWLANDS, #, MAYFAIR, South Africa','geo':{'lat':-26.17640,'lng':27.96370}},
{'stp':607577,'address':'607577, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MABANGA BM, 20 - 4TH AVE, #, #, ALEXANDRA WEST, South Africa','geo':{'lat':-26.10270,'lng':28.08890}},
{'stp':627652,'address':'627652, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NORMANS INN, 122 RUBEN ST, EXT 3 WESTBURY, #, MAYFAIR, South Africa','geo':{'lat':-26.18570,'lng':27.97270}},
{'stp':627763,'address':'627763, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOYCE INN, 3086 SANDERLING AVENUE, EXTENSION 11, #, CROWN MINES, South Africa','geo':{'lat':-26.21740,'lng':27.98520}},
{'stp':627786,'address':'627786, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MASHAS PLACE, 883A MOGOL STR, RIVERLEA, #, CROWN MINES, South Africa','geo':{'lat':-26.21835,'lng':27.97667}},
{'stp':628050,'address':'628050, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WELLIES INN, 906B COUGA STREET, RIVERLEA EXTENTION, #, CROWN MINES, South Africa','geo':{'lat':-26.21770,'lng':27.98010}},
{'stp':628790,'address':'628790, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FAIRVIEW LIQUOR MARKET, 390 COMMISSIONER STREET, FAIRVIEW, #, JEPPE, South Africa','geo':{'lat':-26.20226,'lng':28.06962}},
{'stp':629083,'address':'629083, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, COZY CORNER, 4305 COLUMBINE STREET, WESTBURY, #, MAYFAIR, South Africa','geo':{'lat':-26.18634,'lng':27.98081}},
{'stp':632343,'address':'632343, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EASY CORNER, 8 PRIOR STREET, SOUTH HILLS, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24755,'lng':28.09005}},
{'stp':633414,'address':'633414, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BUCCANEERS INN, 951A KOMANI STREET,  RIVERLEA, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.21893,'lng':27.97961}},
{'stp':633926,'address':'633926, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MMA MEISIE\'S SHEBEEN, 85 ANZEC STREET, NEWLANDS, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.17540,'lng':27.96110}},
{'stp':641556,'address':'641556, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FAIRVIEW BACKPACKERS, 4 COLLEGE STREET, COLLEGE STREET, #, JEPPE, South Africa','geo':{'lat':-26.20180,'lng':28.07350}},
{'stp':642319,'address':'642319, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, STANHOPE TAVERN, 9 STANHOP STREET, MALVERN EAST, MALVERN EAST, JEPPE, South Africa','geo':{'lat':-26.20160,'lng':28.12220}},
{'stp':642367,'address':'642367, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JIMMY\'S SHEBEEN, 5 COSMOS CIRCLE, JAN HOFMEYER, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.19290,'lng':28.00970}},
{'stp':642385,'address':'642385, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NDLHAZI DISCOUNT CELLARS, 612 STAND NO, LA ROCHELLE, ROSETTENVILLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24237,'lng':28.05487}},
{'stp':646544,'address':'646544, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GAVIN LIQUORS, 145 Stand number, Builidng shop number 4, stand number 1594/6/7,main road, MAYFAIR, South Africa','geo':{'lat':-26.17541,'lng':27.96622}},
{'stp':646555,'address':'646555, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DULCIE\'S INN, 837A JUSKEI DRIVE, RIVERLEA EXT 1, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.21710,'lng':27.97680}},
{'stp':647984,'address':'647984, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIDNEY SWARTS, 1129 KRETCHMAR STREET, EXTENSION 3, #, MAYFAIR, South Africa','geo':{'lat':-26.18551,'lng':27.97516}},
{'stp':653493,'address':'653493, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CATHYS LIQUOR STORE, 70 ROCKEY STREET, #, #, YEOVILLE, South Africa','geo':{'lat':-26.18135,'lng':28.07154}},
{'stp':653685,'address':'653685, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HAPPY PEOPLE LIQUOR STORE, ERF 37 CITY & SUBURBAN, 206 COMMISSIONER STREET OFF NUGGET STR, GROUND FLOOR SHOPFRONT PREMISES, CBD, South Africa','geo':{'lat':-26.20440,'lng':28.05220}},
{'stp':654980,'address':'654980, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WEBSITE TAVERN, 578 JULES STREET, MAL SH 02 3 MALVERN, #, JEPPE, South Africa','geo':{'lat':-26.20090,'lng':28.11920}},
{'stp':684354,'address':'684354, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PAN ROAD LIQUOR STORE, STAND NO 492, PAN ROAD,CNR 9th STREET, LA ROCHELLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.23780,'lng':28.05710}},
{'stp':687214,'address':'687214, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SAVE MORE LIQUOR STORE, 30 TWIST STREET, HILLBROW, #, CBD, South Africa','geo':{'lat':-26.19650,'lng':28.04850}},
{'stp':689051,'address':'689051, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, COLORADO LIQUOR MARKET, SHOP NO 2, RIVERLEA SHOPPING CENTRE, COLORADO DRIVE, MAYFAIR, South Africa','geo':{'lat':-26.21300,'lng':27.97700}},
{'stp':703784,'address':'703784, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KENANG NONKE LIQUOR STORE, 1 SHOP NO, CNR ANDERSON AND TROYE STREET, #, CBD, South Africa','geo':{'lat':-26.20724,'lng':28.04977}},
{'stp':703937,'address':'703937, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SAVEMORE LIQUOR STORE TWO, 949 ACKER STREET, ORMONDE EXTENSION 21, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.25209,'lng':27.98855}},
{'stp':706866,'address':'706866, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DLOKS PLACE, 9 LENDRYN CLOSE, EXT 13,, REGENT\'S PARK, ROSETTENVILLE, South Africa','geo':{'lat':-26.23598,'lng':28.06505}},
{'stp':708278,'address':'708278, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FABITO LIQUOR STORE, 114 PLEIN STREET, CNR BANKET STREET, HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.19930,'lng':28.05095}},
{'stp':709793,'address':'709793, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, VIC\'S TAVERN, 9 GANGES STREET, #, #, CROWN MINES, South Africa','geo':{'lat':-26.21755,'lng':27.97733}},
{'stp':728381,'address':'728381, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW ARRIVAL, 60 BEUMONT STREET, PORTION 699 STAND NO 67, #, CROWN MINES, South Africa','geo':{'lat':-26.23778,'lng':28.02646}},
{'stp':750421,'address':'750421, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DEE GLEN BOTTLE STORE, 1 SHOP NO, ERF 47652 CHARLSTON HOUSE, 161 COMMISSIONER STREET CORNER, CBD, South Africa','geo':{'lat':-26.20478,'lng':28.04823}},
{'stp':754788,'address':'754788, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, M END M, 1597 DAFFODIL MEWS, EXT 3, #, MAYFAIR, South Africa','geo':{'lat':-26.18274,'lng':27.97609}},
{'stp':759279,'address':'759279, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DULLA S PLACE, 75 STAND NO, ERF 126 SIMMER CRESCENT MOFFAT VIEW, EXT 4, CROWN MINES, South Africa','geo':{'lat':-26.23840,'lng':28.08583}},
{'stp':760504,'address':'760504, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ANTIE S INN, 3240 MACMILLIAN STREET, #, #, MAYFAIR, South Africa','geo':{'lat':-26.18045,'lng':27.97327}},
{'stp':763993,'address':'763993, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LOS PEPPES, 64 STAND NO, CROESES STREET NEWCLARE JOHANNESBURG, ERF 473, MAYFAIR, South Africa','geo':{'lat':-26.18573,'lng':27.97077}},
{'stp':765666,'address':'765666, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, METRO LIQUOR STORE, STAND NO, SHOP 6 & 7 METRO CITY CORNER EDITH, CARELL AND PRETORIA STREET HILLBROW, HILLBROW, South Africa','geo':{'lat':-26.18920,'lng':28.04632}},
{'stp':766812,'address':'766812, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FOX DEN, 270 MAIN STREET, JEPPESTOWN, ERF 690, JEPPE, South Africa','geo':{'lat':-26.20462,'lng':28.06102}},
{'stp':768125,'address':'768125, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHRISTOPHER S PLACE,  1351 STAND  NO, FLORIDA ROAD WESTBURY, JOHANNESBURG, MAYFAIR, South Africa','geo':{'lat':-26.18298,'lng':27.97584}},
{'stp':779875,'address':'779875, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PK\'S LIQUOR STORE,  30 STAND NO, DRANKENSBURG THE HILL JOHANNESBURG, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24399,'lng':28.06473}},
{'stp':781852,'address':'781852, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FELLAS JOINT, STAND NO, 1738 DUNLIN DRIVE, RIVERLEA EXT 5, CROWN MINES, South Africa','geo':{'lat':-26.21475,'lng':27.98771}},
{'stp':782542,'address':'782542, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIYATHUTHUKA TAVERN, 210 LOUIS BOTHA AVENUE, PORTION 1 ERF 475, ORANGE GROVE, YEOVILLE, South Africa','geo':{'lat':-26.14311,'lng':28.08758}},
{'stp':782844,'address':'782844, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THEMS CHILLAS, STAND NO, PORTION 2 OF ERF 511, 2A FULLER STR, CBD, South Africa','geo':{'lat':-26.19677,'lng':28.06889}},
{'stp':784932,'address':'784932, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAVUSOS PLACE, JACOBA STREET, ERF 49 NO 10, #, YEOVILLE, South Africa','geo':{'lat':-26.20075,'lng':28.07195}},
{'stp':799379,'address':'799379, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GODFREYS PLACE, 4 GARDEN ROAD, #, #, RANDBURG, South Africa','geo':{'lat':-26.17307,'lng':27.96484}},
{'stp':317244,'address':'317244, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CRYSTAL LOUNGE, 0399 ERF NO, 2198 CAVENDISH & ROCKY STREET, BELLEVUWE YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18122,'lng':28.06558}},
{'stp':320785,'address':'320785, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MIKE\'S KITCHEN EASTGATE (NTO), 43 SHOP NO R16, 43 BRADFORD ROAD, BEDFORDVIEW, EASTGATE SHOPPING CENTRE, GILLOOLYS, South Africa','geo':{'lat':-26.18040,'lng':28.11681}},
{'stp':377357,'address':'377357, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BASS LINE (NTO), 10 HENDRY NXUMALO STREET, NEWTOWN, #, CBD, South Africa','geo':{'lat':-26.20418,'lng':28.03121}},
{'stp':383377,'address':'383377, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEWTOWN CHESA NYAMA, 171 ERF NO, 81 GWIGWI MRWEBI STREET, NEWTOWN, CBD, South Africa','geo':{'lat':-26.20086,'lng':28.03496}},
{'stp':383703,'address':'383703, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OASIS D\'S LOUNGE (NTO), SHOP 2, GROUND FLOOR, TRANSPORT SQUARE, 53 VAN BEEK STREET, NEW DOORFONTEIN, CBD, South Africa','geo':{'lat':-26.19949,'lng':28.05754}},
{'stp':387942,'address':'387942, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ZEN (NTO), ERF:2861 NO 18 ALBRECHT STREET, JEPPESTOWN, #, JEPPE, South Africa','geo':{'lat':-26.20386,'lng':28.06039}},
{'stp':388571,'address':'388571, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SAWUBONA LOUNGE AND REST (NTO), 305 FOX STREET, ERF: 52/00, JEPPESTOWN, NORTHCLIFF, South Africa','geo':{'lat':-26.20383,'lng':28.06160}},
{'stp':389041,'address':'389041, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RED BRICKS PIZZA, 108 STAND NO, JEPPE STREETS, NEWTOWN, CBD, South Africa','geo':{'lat':-26.20837,'lng':28.04762}},
{'stp':389758,'address':'389758, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BENS PIZZA, 0002 SHOP NO, LONDON HOUSE, 21 LOVE DAY STREET, MARSHALL TOWN, CBD, South Africa','geo':{'lat':-26.20817,'lng':28.04544}},
{'stp':389830,'address':'389830, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AGOG GALLERY (NTO), 12 LOWER ROSS STREET, NEW DOORNFONTEIN, ERF: 884, CBD, South Africa','geo':{'lat':-26.20163,'lng':28.05875}},
{'stp':389975,'address':'389975, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LOVE REVO RESTAURANT (NTO), ERF:2862, NO: 300 CORNER COMMISSIONER AND MAVERICK, #, CBD, South Africa','geo':{'lat':-26.20383,'lng':28.06084}},
{'stp':389976,'address':'389976, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RAVIOLI RESTAURANT (NTO), 289 FOX STREET, MABONENG, #, JEPPE, South Africa','geo':{'lat':-26.20603,'lng':28.05062}},
{'stp':389977,'address':'389977, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAMA MEXICANA (NTO), ERF: 1256, SHOP NO:29, ARTS ON MAIN, NO: 264 FOX STREET, CITY & SUBURBAN, CBD, South Africa','geo':{'lat':-26.20434,'lng':28.05761}},
{'stp':389988,'address':'389988, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHARP BRAAI MABONENG (NTO), SHOP NO:20, KRUGER STREET, MABONENG PRECINCT, ERF:0020, #, CBD, South Africa','geo':{'lat':-26.20430,'lng':28.05924}},
{'stp':390286,'address':'390286, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE BLACKANESE (NTO), 16 ALBRECHT STREET, CORNER FOX STREET, JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20385,'lng':28.06018}},
{'stp':390907,'address':'390907, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NONNA MIAS (NTO), SHOP NO: M3 & M4 BEDFORD CENTRE, SURROUNDED BY REGENT, SMITH,, BRADFORD & KIRBY STREETS, GILLOOLYS, South Africa','geo':{'lat':-26.18850,'lng':28.12290}},
{'stp':391238,'address':'391238, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE LIVING ROOM (NTO), ERVEN:674 & 1142M. ROOFTOP, NO:20 KRUGER STREET. CITY & SUBURBAN, #, CBD, South Africa','geo':{'lat':-26.20458,'lng':28.05943}},
{'stp':391437,'address':'391437, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAcGYYVERS (NTO), ERF: 398, NO:16 SOVEREIGN STREET, CORNER THEUNIS STREET, MALVERN EAST EXTENSION 3, BEDFORDVIEW, BEDFORDVIEW, South Africa','geo':{'lat':-26.19726,'lng':28.11998}},
{'stp':391994,'address':'391994, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RAND INN HOTEL (NTO), 290 BREE STREET, #, #, CBD, South Africa','geo':{'lat':-26.20037,'lng':28.04722}},
{'stp':392164,'address':'392164, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BLACK ANT LOUNGE (NTO), ERF 557, SHOP NO A, NUMSA BUILDING, 153 BREE STREET CORNER GWIGWI MORWEBI, AND GERALD SEKOTO STREET, NEWTOWN, HILLBROW, South Africa','geo':{'lat':-26.20072,'lng':28.03315}},
{'stp':392588,'address':'392588, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, STEVE S GUEST HOUSE, 469 ERVEN 461 463, NO 59-63 JOEL ROAD, BEREA, YEOVILLE, South Africa','geo':{'lat':-26.18620,'lng':28.05415}},
{'stp':392845,'address':'392845, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE SOCIAL CLUB (NTO), ERF:2861, SHOP NO: 5 MAVERICK BUILD, NO: 300 COMMISSIONER ST, CORNER ALBRECHT, JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20352,'lng':28.06017}},
{'stp':393036,'address':'393036, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LA CASA SHISANYAMA (NTO), UNIT 4 AND 5, 289 FOX STREET, MABONENG PRECINCT, #, JEPPE, South Africa','geo':{'lat':-26.20427,'lng':28.05950}},
{'stp':393065,'address':'393065, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAFE NOIR (NTO), SHOP NO:100, ANDERSON STREET, ERF:1, #, #, JEPPE, South Africa','geo':{'lat':-26.20867,'lng':28.04226}},
{'stp':393210,'address':'393210, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHAKERS PUB & BAR (NTO), COMMISSIONER AND CORNER, MAVERICKS STREET, JEPPESTOWN, #, JEPPE, South Africa','geo':{'lat':-26.20306,'lng':28.05408}},
{'stp':393212,'address':'393212, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROXA (NTO), ERF 1151, NO. 283 FOX STREET, 1ST & 2ND FLOOR, MABONENG PRECINCT, JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-28.05560,'lng':28.05560}},
{'stp':393317,'address':'393317, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JOJO LOUNGE (NTO), NO 288 COMMISSIONER STREET, FIFTH FLOOR, JEPPESTOWN, #, JEPPE, South Africa','geo':{'lat':-26.20426,'lng':28.05096}},
{'stp':393338,'address':'393338, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHAKERS EXTREME (NTO), NO 289 ON FOX STREET, UNIT 7 - 10 FIRST FLOOR MABONENG CENTRAL, JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20422,'lng':28.05076}},
{'stp':461005,'address':'461005, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEWS CAFE NEWTOWN, 623 ERF, SHOP 147, LOWER LEVEL, CBD, South Africa','geo':{'lat':-26.20026,'lng':28.03074}},
{'stp':464241,'address':'464241, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SPUR GOLDEN FALCON (NTO), arkway Gold Reef City Casino, North, #, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.23844,'lng':28.01200}},
{'stp':468368,'address':'468368, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, O GARFO RESTAURANT (NTO), 85 Langermann dr, Kensington, johan, #, #, GILLOOLYS, South Africa','geo':{'lat':-26.18873,'lng':28.10901}},
{'stp':468717,'address':'468717, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BROOKLYN BROTHERS BEDFORD (NTO), CNR SMITH & VAN DER LINDE RD BEDFOR, #, #, GILLOOLYS, South Africa','geo':{'lat':-26.18800,'lng':28.12311}},
{'stp':473721,'address':'473721, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH,  POTATO SHED NEWTOWN (NTO), SHOP 190, NEWTOWN JUNCTION, JOHANNE, #, #, CBD, South Africa','geo':{'lat':-26.20112,'lng':28.03201}},
{'stp':480890,'address':'480890, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SEAWAVE BAR (NTO), 16 SOVEREIGH STREET, BEDFORDVIEW, #, #, GILLOOLYS, South Africa','geo':{'lat':-26.19729,'lng':28.11995}},
{'stp':482817,'address':'482817, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CALISTOS RESTAURANT GOLD REEF CITY, NORTHERN PARKWAY GOLD REEF CITY, JOHANNESBURG, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.23942,'lng':28.01340}},
{'stp':484765,'address':'484765, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HERITAGE CAFE (NTO), 23 FOX STREET, FERREIDS STAND 109, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20678,'lng':28.03436}},
{'stp':485504,'address':'485504, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CLUB 115 PUB AND RESTAURANT, 0019 STAND NO, SMALL STREET CNR ANDERSON STREET, MARSHALLTOWN, CBD, South Africa','geo':{'lat':-26.20748,'lng':28.04688}},
{'stp':486904,'address':'486904, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CIRCA, STAND NO, CNR CORLETT DRIVE, H LOVO, BRYANSTON, South Africa','geo':{'lat':-26.19741,'lng':28.06069}},
{'stp':490076,'address':'490076, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAPELLO NEWTOWN, 1 STAND NO, CARR AND HENRY NXUMALO, NEWTOWN, CBD, South Africa','geo':{'lat':-26.20226,'lng':28.03273}},
{'stp':550102,'address':'550102, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MIKE\'S KITCHEN PARKTOWN, 15 ST ANDREWS ROAD, PARKTOWN, JHB, CBD, South Africa','geo':{'lat':-26.18240,'lng':28.03590}},
{'stp':550281,'address':'550281, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TROYEVILLE HOTEL, 25 BEZUIDENHOUT STREET, TROYEVILLE, 2146, CBD, South Africa','geo':{'lat':-26.19865,'lng':28.06680}},
{'stp':550372,'address':'550372, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GELDENHUIS DEEP BOWLING CLUB, GELDENHUISWEG, MALVERN-OOS, JHB, CBD, South Africa','geo':{'lat':-26.20000,'lng':28.12160}},
{'stp':550416,'address':'550416, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, 29 ON GILES, STAND 00720,29 GILES STREET, ROBERTSHAM, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.24610,'lng':28.01393}},
{'stp':550450,'address':'550450, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GOLD REEF CITY TA PROTEA HOTEL, SHAFT 14 NORTHERN PARKWAY, GOLD REEF CITY OMPLEX ERF 1211, ORMONDE, CROWN MINES, South Africa','geo':{'lat':-26.23525,'lng':28.01281}},
{'stp':550645,'address':'550645, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROYAL PARK HOTEL, 58 LEYDS STREET, JHB, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19510,'lng':28.05120}},
{'stp':550820,'address':'550820, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CLUB 22, 724,22 LOVERS WALK, FORDSBURG, JHB, CBD, South Africa','geo':{'lat':-26.20656,'lng':28.02489}},
{'stp':551012,'address':'551012, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SUNNYSIDE PARK HOTEL, 2 YORK STREET, PARKTOWN, 2193, MELVILLE, South Africa','geo':{'lat':-26.17983,'lng':28.04683}},
{'stp':551049,'address':'551049, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OPHIRTON HOTEL, P O BOX 11245, JOHANNESBURG, JHB, CROWN MINES, South Africa','geo':{'lat':-26.22450,'lng':28.02970}},
{'stp':551055,'address':'551055, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RAND CLUB, 33 LOVEDAY STREET, CNR FOX  LOVEDAY AND COMMISSIONER, R STR  MARSHALLTOWN, CBD, South Africa','geo':{'lat':-26.20640,'lng':28.04110}},
{'stp':551147,'address':'551147, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BUFFALO BILLS BAR AND CAFE, STAND NO, PORTION 1 ERF 5104 & REMAINDER OF 5705, CONCOURSE, CBD, South Africa','geo':{'lat':-26.19800,'lng':28.04193}},
{'stp':551334,'address':'551334, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROBERTSHAM HOTEL, CNR HARRY RD & DELAMERE STR, ROBERTSHAM, #, CROWN MINES, South Africa','geo':{'lat':-26.24500,'lng':28.01380}},
{'stp':551469,'address':'551469, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GALAXY WORLD CARLTON, SHOP 34-38B GROUND FLOOR, CARLTON SENTER CNR COMMISSIONE, & VON WIELLIGH ST JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20635,'lng':28.04683}},
{'stp':551632,'address':'551632, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WICHITA SPUR, EASTGATE SHOPPING CENTRE, BRADFORD RD, BEDFORDVIEW, GILLOOLYS, South Africa','geo':{'lat':-26.18132,'lng':28.11750}},
{'stp':551662,'address':'551662, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WERNERS BISTRO, STAND NO 94 25 BOEING ROAD, BEDFORDVIEW EXT 25, #, GILLOOLYS, South Africa','geo':{'lat':-26.17500,'lng':28.12200}},
{'stp':551751,'address':'551751, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE MALT AND BARLEY, SHOP 1 DARRAS CENTRE, KITCHENER AVE & CNR JUNO STR, KENSINGTON, CBD, South Africa','geo':{'lat':-26.19242,'lng':28.08827}},
{'stp':551800,'address':'551800, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOSTON BBQ GOLD REEF CITY, SHOP10&11,GOLD REEF CITY CASIN, NORTHERN PARKWAY DRIVE, CNR GOLD REEF CITY RD , ORMOND, CROWN MINES, South Africa','geo':{'lat':-26.23844,'lng':28.01200}},
{'stp':551881,'address':'551881, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OLD JOHANNIAN ASSOCIATION, 34 ST ANDREWS ROAD, CNR ST JOHN\'S ROAD, HOUGHTON, GILLOOLYS, South Africa','geo':{'lat':-26.17790,'lng':28.05780}},
{'stp':552039,'address':'552039, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, EKHAYA RESTUARANT, STAND NO 706, 35 RALEIGH STR, YOEVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18127,'lng':28.06073}},
{'stp':552077,'address':'552077, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WIMPY PARK STATION, SHOP C32A, WESTERN CONCOURSE, PARK CITY COMPLEX, JOHANNESBURG STATION 2000, CBD, South Africa','geo':{'lat':-26.19778,'lng':28.04207}},
{'stp':552295,'address':'552295, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GOLD REEF CITY VIP CASINO, SHOP 8 GOLD REEF CITY COMPL, EX NORTHERN PARKWAY ORMONDE, ERF 1211 & 1212 ORMONDE EXT 30, CROWN MINES, South Africa','geo':{'lat':-26.23905,'lng':28.01417}},
{'stp':552850,'address':'552850, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DIE KNIEPE GERMAN PUB, SHOP 1 & 2 SHERIS CENTRE, STAND NO 295 CNR QUEENS STREET, AND LANGERMAN DRIVE KENSINGTON, CBD, South Africa','geo':{'lat':-26.18899,'lng':28.10806}},
{'stp':552898,'address':'552898, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PETE S PLACE DINER RESTAURANT, 21 BEZUIDENHOUT STREET, TROYEVILLE, #, CBD, South Africa','geo':{'lat':-26.19942,'lng':28.06592}},
{'stp':553065,'address':'553065, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DIPLOMAT HOTEL, CNR BREE/KLEIN, JOHANNESBURG, 2001, CBD, South Africa','geo':{'lat':-26.20050,'lng':28.04690}},
{'stp':553076,'address':'553076, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HUNGER JACKS INN, 286 STAND NO, 27 RAYMOND CORNER REGENTS STREETS, BELLEVUE, YEOVILLE, South Africa','geo':{'lat':-26.19823,'lng':28.04943}},
{'stp':553209,'address':'553209, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CASA CANCO, 59B TROYE STREET, CENTRAL, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20253,'lng':28.04853}},
{'stp':553376,'address':'553376, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BOOYSENS HOTEL, 33 BOOYSENS RAOD, BOOYSENS, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.23140,'lng':28.02343}},
{'stp':553502,'address':'553502, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OLDEES DINER, 14 SHAFT NO, THEME PARK, GOLDREEF CITY NORTHREN PARKWAY, CROWN MINES, South Africa','geo':{'lat':-26.23525,'lng':28.01281}},
{'stp':553549,'address':'553549, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BACK OF THE MOON JAZZ BAR, SHOP 17, GOLD REEF CITY COMPLE, NORTHERN PARKWAY ORMDE, JHB, CROWN MINES, South Africa','geo':{'lat':-26.23890,'lng':28.01410}},
{'stp':553586,'address':'553586, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SOLLY KRAMERS PARKHURST, CR 4TH AVE & 6TH STREET, PARKHURST, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.14310,'lng':28.01650}},
{'stp':553640,'address':'553640, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, VILLAGE KIN MALEBO, 31/33 RALEIGH STREET, YEOVILLE, JOHANNESBURG, YEOVILLE, South Africa','geo':{'lat':-26.18140,'lng':28.06052}},
{'stp':561235,'address':'561235, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MERITING VENUE, 130 FOX STREET, CNR FOX & VON BRANDIS, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20319,'lng':28.04450}},
{'stp':580530,'address':'580530, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ESPELONG, STAND 123, (BASEMENT), KRUIS & COMMISIONER STREET, #, CBD, South Africa','geo':{'lat':-26.20530,'lng':28.04480}},
{'stp':607277,'address':'607277, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PARK STATION RESTAURANT, ERF 1927 KOCK,CNR WANDERESS, JOUBERT PARK, #, CBD, South Africa','geo':{'lat':-26.19686,'lng':28.04482}},
{'stp':608000,'address':'608000, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TAB BEDFORDVIEW (NTO), BEDFORD CENTRE, LOWER LEVEL 1, #, Johannesburg, GILLOOLYS, South Africa','geo':{'lat':-26.18832,'lng':28.12257}},
{'stp':610143,'address':'610143, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OCEAN BASKET EASTGATE (NTO), SHOP U5 UPPER LEVEL EASTGATE SHOPPI, COUNTY, BRADFORD & NICOL ST BEDFORDVIEW, Johannesburg, GILLOOLYS, South Africa','geo':{'lat':-26.18055,'lng':28.11679}},
{'stp':626958,'address':'626958, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PIATTO EASTGATE, SHOP L56 EASTGATE SHOPPING CENT, BRADFORD RD BEDFORDVIEW, #, GILLOOLYS, South Africa','geo':{'lat':-26.18036,'lng':28.11679}},
{'stp':631966,'address':'631966, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HIS MAJESTY\'S TAVERN, SHOP 2 MAJESTO\'S BUILDING, CNR FOX & JOUBERT, #, CBD, South Africa','geo':{'lat':-26.20635,'lng':28.04343}},
{'stp':632784,'address':'632784, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SPORTMAN INN (NTO), CABANA CENTRE, 31 GILES STR, CROWN MINES, #, CROWN MINES, South Africa','geo':{'lat':-26.24583,'lng':28.01361}},
{'stp':639893,'address':'639893, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CITY LIMITS GUEST HOUSE, 108 DUNBAR STREET, STAND NO 67-69, #, YEOVILLE, South Africa','geo':{'lat':-26.18125,'lng':28.06817}},
{'stp':642718,'address':'642718, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE CLUB, 114 RAILWAY STREET, MAYFAIR, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20380,'lng':28.01240}},
{'stp':646760,'address':'646760, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BETTY\'S PUB (NTO), STAND 1948, GROUND FLOOR, CNR JULES & HEREFORD STREETS, MALVERN, CBD, South Africa','geo':{'lat':-26.20072,'lng':28.11692}},
{'stp':654718,'address':'654718, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GARDEN COURT EASTGATE, ERF 476, PORTION 16, #, GILLOOLYS, South Africa','geo':{'lat':-26.17852,'lng':28.11387}},
{'stp':657540,'address':'657540, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SALLI BURY HOTEL (NTO), CNR ANDERSON AND GRACE STREET, CBD, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19673,'lng':28.04575}},
{'stp':664474,'address':'664474, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MYTHOS BEDFORD SQUARE (NTO), BEDFORD SQUARE, CNR VAN DER LINDE AND SMITH STR, BEDFORDVIEW, GILLOOLYS, South Africa','geo':{'lat':-26.18770,'lng':28.12100}},
{'stp':672281,'address':'672281, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAPPELLO GHANDI SQUARE, 0 CNR MAIN AND LOVEDAY STREETS, SHOP 1 CCMA HOUSE, MARSHALLTOWN, CBD, South Africa','geo':{'lat':-26.20710,'lng':28.04210}},
{'stp':674328,'address':'674328, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MANTOVANNIS, EASTGATE SHOPPING CENTRE, EASTGATE SHOPPING CENTRE, BEDFORVIEW, GILLOOLYS, South Africa','geo':{'lat':-26.18028,'lng':28.11693}},
{'stp':677636,'address':'677636, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RICH (NTO), SHOP NR 14-18 BEDFORD SQUARE, CNR VAN DER LINDE & SMIT, BEDFORDVIEW, GILLOOLYS, South Africa','geo':{'lat':-26.18802,'lng':28.12545}},
{'stp':692204,'address':'692204, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PLAKA EASTGATE (NTO), SHOP R15 EASTGATE CENTRE, 43 BRADFORD ROAD, EASTGATE, GILLOOLYS, South Africa','geo':{'lat':-26.18036,'lng':28.11674}},
{'stp':695101,'address':'695101, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ARTS ON MAIN (NTO), 245 MAIN STREET, JOHANNESBURG CBD, JOHANNESBURG, JEPPE, South Africa','geo':{'lat':-26.20418,'lng':28.05761}},
{'stp':699757,'address':'699757, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PALACE 57 GUEST HOUSE, ERF NO 849 AND 850, 57 FORTESQUE STREET, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18011,'lng':28.06123}},
{'stp':700550,'address':'700550, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ZEBRA POND (NTO), 1 RAYLEIGH STREET, YEOVILLE, NIGERIA, YEOVILLE, South Africa','geo':{'lat':-26.18130,'lng':28.06090}},
{'stp':703556,'address':'703556, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TEE BOX (NTO), 47 BOEING ROAD WEST, BEDFORDVIEW, BEDFORDVIEW, GILLOOLYS, South Africa','geo':{'lat':-26.17500,'lng':28.12200}},
{'stp':703932,'address':'703932, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PRIMI PIATTI EASTGATE, 43 BRADFORD ROAD, SHOP NO R11 ROOF LEVEL FOOD COURT, EASTGATE SHOPPING CENTRE, GILLOOLYS, South Africa','geo':{'lat':-26.18098,'lng':28.11600}},
{'stp':708249,'address':'708249, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WHITEHOUSE HOTEL, 152 MARSHALL STREET, #, #, CBD, South Africa','geo':{'lat':-26.20717,'lng':28.04668}},
{'stp':713041,'address':'713041, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PATA PATA (NTO), MARITZBURG & FOX STREET, JEPPESTOWN, JHB, JEPPE, South Africa','geo':{'lat':-26.20475,'lng':28.05791}},
{'stp':715293,'address':'715293, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CALISTOS BEDFORDVIEW (NTO), SHOP 1 BEDFORD SQUARE, CNR VAN DER LINDE & SMITH STREET, BEDFORDVIEW, GILLOOLYS, South Africa','geo':{'lat':-26.18770,'lng':28.11610}},
{'stp':715295,'address':'715295, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PRINCI (NTO), SHOP 13 BEDFORD CENTRE, CNR VAN DER LINDE & SMITH STREET, BEDFORDVIEW, GILLOOLYS, South Africa','geo':{'lat':-26.18770,'lng':28.11161}},
{'stp':722258,'address':'722258, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAPPELLO CARLTON CENTRE, SHOP 270 LEVEL 200 CARLTON C, 150 COMMISIONER STREET STAND 1120  1239, #, CBD, South Africa','geo':{'lat':-26.20653,'lng':28.04653}},
{'stp':723543,'address':'723543, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GINOS ROBERTSHAM, 9 CORNER KINDON ROAD, AND HARRY STREET, ROBERTSHAM, CROWN MINES, South Africa','geo':{'lat':-26.24737,'lng':28.01527}},
{'stp':730061,'address':'730061, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHAMINES RESTAURANT & PUB (NTO), 65 OP DE BERGEN STREET, TROYEVILLE, JOHANN, HILLBROW, South Africa','geo':{'lat':-26.20125,'lng':28.07316}},
{'stp':730938,'address':'730938, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SHIKISAS (NTO), GERHARD SEKOTO STREET, CNR PRESIDENT STREET, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20580,'lng':28.03452}},
{'stp':730973,'address':'730973, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WORLD OF BEER (NTO), CNR GERARD SEKOTO AND PRESIDENT STR, JHB CBD, NEWTOWN, CBD, South Africa','geo':{'lat':-26.20580,'lng':28.03452}},
{'stp':734289,'address':'734289, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MI HOUSE (NTO), 36 RODGERS STREET, SELBY, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.21554,'lng':28.03510}},
{'stp':749992,'address':'749992, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LI\'S RESTAURANT (NTO), 14 DERRICK AVENUE, #, #, YEOVILLE, South Africa','geo':{'lat':-26.17860,'lng':28.09960}},
{'stp':758270,'address':'758270, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WIMPY CARLTON CENTRE, STAND NO, SHOP NO. 251 CARLTON CENTRE SHOPPING, CENTRE CNR COMMISIONER, CBD, South Africa','geo':{'lat':-26.20710,'lng':28.05200}},
{'stp':759208,'address':'759208, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ENOS PUB, 37 STAND NO, MELVILLE STREET BOOYSENS BOOYSENS, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.20510,'lng':28.08227}},
{'stp':763278,'address':'763278, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHEROKEE SPUR (NTO), SHOP 9&10, UPPER LEVEL, BEDFORDVIEW SHOPPING CENTRE, BRADFORD ROAD, GILLOOLYS, South Africa','geo':{'lat':-26.18770,'lng':28.12100}},
{'stp':773871,'address':'773871, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DIG INN (NTO), GOLD REEF CITY, THEME PARK COMPLEX, GOLD REEF CITY, CROWN MINES, South Africa','geo':{'lat':-26.23525,'lng':28.01281}},
{'stp':775990,'address':'775990, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAFE 141 (NTO), 141 QUEENS STREET, KENSINGTON, KENSINGTON, GILLOOLYS, South Africa','geo':{'lat':-26.18870,'lng':28.10776}},
{'stp':785852,'address':'785852, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BUFFALO BILLS SPORTS BAR, 370 STAND NO, SHOPS 1 AND 2 NO 134 FOX STREET, MASHALLTOWN, CBD, South Africa','geo':{'lat':-26.20590,'lng':28.04540}},
{'stp':788400,'address':'788400, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MPUNGUBWE HOTEL (NTO), CNR MARSHALL & FERRIANA STREET, MARSHALLSTOWN, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20817,'lng':28.03681}},
{'stp':791032,'address':'791032, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE FASHION SHACK (NTO), 130 PRITCHARD STREET, CBD, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20273,'lng':28.04946}},
{'stp':792792,'address':'792792, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PIZZA DEL FORNO PARK MEADOWS (NTO), SHOP 16 PARK MEADOWS, CNR CUMBERLAND & ALLUM ROAD, KENSINGTON, GILLOOLYS, South Africa','geo':{'lat':-26.18355,'lng':28.11427}},
{'stp':794210,'address':'794210, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TIME OUT, STAND NO, ERF 2913 CNR ALBRECHT ST AND, ALBERTINA SISULU RD JEPPESTOWN, JEPPE, South Africa','geo':{'lat':-26.20278,'lng':28.05993}},
{'stp':794430,'address':'794430, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, GALAXY BOWLING BEDFORD, STAND NO, ERVEN 50 51 54 64 SHOPS M9 TO M11, MEZZANINE LEVEL BEDFORD CENTRE, GILLOOLYS, South Africa','geo':{'lat':-26.18745,'lng':28.12106}},
{'stp':795724,'address':'795724, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CHESA NYAMA MAIN STREET, STAND NO, SHOP 1 GROUND FLOOR, CORNER MAIN ROAD MARSHALLTOWN, CBD, South Africa','geo':{'lat':-26.20669,'lng':28.04223}},
{'stp':796800,'address':'796800, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, O\' VICENTE (NTO), 16 SOVEREIGN STREET, BEDFORDVIEW, #, GILLOOLYS, South Africa','geo':{'lat':-26.19692,'lng':28.12000}},
{'stp':687540,'address':'687540, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MI BAR (NTO), 160 JAN SMUTS AVENUE, ROSEBANK, JOHANNESBURG, HYDE PARK, South Africa','geo':{'lat':-26.14588,'lng':28.03636}},
{'stp':318134,'address':'318134, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, STANLEY BAR (NTO), 44 SHOP NO. G1B, STANLEY AVENUE, MILPARK, #, MAYFAIR, South Africa','geo':{'lat':-26.18503,'lng':28.01855}},
{'stp':387943,'address':'387943, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE LEOPARD RESTAURANT (NTO), ERF: 436 SHOP NO: 4TH AVENUE, CORNER 5TH STREET, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17513,'lng':28.00589}},
{'stp':387959,'address':'387959, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PERRON MELVILLE (NTO), SHOP NO: 4TH AVENUE & 5TH STREET, MELVILLE, #, MELVILLE, South Africa','geo':{'lat':-26.17518,'lng':28.00579}},
{'stp':388083,'address':'388083, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE FEDERAL (NTO), SHOP 2 & 3, MELVILLE PROPERTIES, CNR 7TH STREET & 2ND AVENUE, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17693,'lng':28.00873}},
{'stp':389148,'address':'389148, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LE ESCOBAR (NTO), SHOP NO:2, ERF:462 THE BERG SHOPPIN, CNR GORDON ROAD & BERGBRON DRIVE, BERGBRON EXTENSION 6, NORTHCLIFF, South Africa','geo':{'lat':-26.16270,'lng':27.95210}},
{'stp':392726,'address':'392726, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SMOKING KILLS BAR (NTO), ERF: 373 NO: 78A, 4TH AVENUE, MELVILLE, #, MELVILLE, South Africa','geo':{'lat':-26.17564,'lng':28.00758}},
{'stp':473329,'address':'473329, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PIZZA DEL FORNO (NTO), MINIART CENTER, 14TH AVE, FAIRLANDS, #, #, RANDBURG, South Africa','geo':{'lat':-26.15086,'lng':27.94361}},
{'stp':475756,'address':'475756, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROCCOMAMA\'S CAMPUS SQUARE (NTO), SHOP 41 GROUND FLOOR, CAMPUS SQUARE, SHOPPING CENTRE, VARSITY RD, BOUNDED BY, KINGSWAY RD & MAIN RD, ERF NO 1114, MELVILLE, South Africa','geo':{'lat':-26.18208,'lng':28.00276}},
{'stp':480045,'address':'480045, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROCCOMAMA\'S CRESTA (NTO), SUITE 482,PRIVATE BAG X09, WELTEVRE, #, #, HYDE PARK, South Africa','geo':{'lat':-26.12930,'lng':27.96980}},
{'stp':485495,'address':'485495, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FN BAR PTY LTD,  STAND, DONNELLY STR, CNR MAIN, ROSETTENVILLE, South Africa','geo':{'lat':-26.24362,'lng':28.05303}},
{'stp':492082,'address':'492082, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, Vera Vita Italian Restaurant And Pi, 255 Rifle Range Road, Haddon, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.26006,'lng':28.03391}},
{'stp':499262,'address':'499262, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LEGENDS PUB AND GRILL (NTO), MOUNTAIN VIEW SHOPPING CENTRE, 1ST FLOOR CNR 14TH AVE & WELTEVREDEN RD, NORTHCLIFF, JOHANNESBURG, NORTHCLIFF, South Africa','geo':{'lat':-26.14493,'lng':27.95294}},
{'stp':550070,'address':'550070, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, A CHURRASQUEIRA RESTAURANT, 122 MAIN STREET, ROSETTENVILLE, JHB, ROSETTENVILLE, South Africa','geo':{'lat':-26.25036,'lng':28.05176}},
{'stp':550077,'address':'550077, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROYAL JHB AND KENSINGTON GOLF CLUB, FAIRWAY AVENUE, LINKSFIELD NORTH, #, GILLOOLYS, South Africa','geo':{'lat':-26.15543,'lng':28.10817}},
{'stp':550205,'address':'550205, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FAIRMOUNT BOWLING CLUB, GED89 EN GED VAN GED 6, GED C VAN PLAAS RIETFONTEIN 61, GERMISTON, GILLOOLYS, South Africa','geo':{'lat':-26.14790,'lng':28.10150}},
{'stp':550239,'address':'550239, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ER BUCO, PO BOX 891158, LYNDHURST, #, GILLOOLYS, South Africa','geo':{'lat':-26.14815,'lng':28.12911}},
{'stp':550276,'address':'550276, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NORTHCLIFF COUNTRY CLUB, CNR PENDORING AND WELTERVREDEN RD, STAND 426, NORTHCLIFF, NORTHCLIFF, South Africa','geo':{'lat':-26.13590,'lng':27.96500}},
{'stp':550361,'address':'550361, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FERNS DINER AND SPORTS BAR, 502 ERF NO, 75 AUGUSTA STREET, REGENTS PARK, ROSETTENVILLE, South Africa','geo':{'lat':-26.24130,'lng':28.07010}},
{'stp':550368,'address':'550368, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, AUCKLANDPARK BOWLING CLUB NO 2, CNR JOHN ADAMSON & JAN CELLERS, MONTGOMMERY PARK, JHB, MELVILLE, South Africa','geo':{'lat':-26.15776,'lng':27.98620}},
{'stp':550437,'address':'550437, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SUBURBS SPORTS BOWLING CLUB, ERF 476, 1 SHORT STREET, CNR JULIUS STREET, OAKDENE, ROSETTENVILLE, South Africa','geo':{'lat':-26.25956,'lng':28.06075}},
{'stp':550454,'address':'550454, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KITSON S PUB AND GRILL REST, HERITAGE PARK, OFF LOWER GERMISTON ROAD, HERITAGE CRESENT ROSHERVILLE, ROSETTENVILLE, South Africa','geo':{'lat':-26.22425,'lng':28.11261}},
{'stp':550463,'address':'550463, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MANDEVILLE SPORTS CLUB, CNR 7TH AVENUE, 10TH AVENUE, BEZ VALLEY, CBD, South Africa','geo':{'lat':-26.18447,'lng':28.09753}},
{'stp':550566,'address':'550566, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WOODPECKER BOWLING CLUB, 2ND AVENUE, BEZUIDENHOUT VALLEY, 2094, CBD, South Africa','geo':{'lat':-26.18490,'lng':28.08780}},
{'stp':550595,'address':'550595, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KENSINGTON CLUB, IVANHOE & JUNO STREETS, KENSINGTON, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19470,'lng':28.09680}},
{'stp':550597,'address':'550597, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CARVERS OF RANDBURG, 51 MOUNTAIN AVENUE, ALDARAPARK, RANDBURG, RANDBURG, South Africa','geo':{'lat':-26.13180,'lng':27.98150}},
{'stp':550609,'address':'550609, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MACRIB MILPARK HOLIDAY INN PTY LTD, MACRIB MILPARK HOLIDAY INN, GARDEN COURT CNR EMPIRE &, BARRY HERTZOG & OWL STR AUCKLA, MELVILLE, South Africa','geo':{'lat':-26.18400,'lng':28.01960}},
{'stp':550803,'address':'550803, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HOUGHTON GOLF CLUB, 2ND AVENUE, LOWER HOUGHTON, #, GILLOOLYS, South Africa','geo':{'lat':-26.16413,'lng':28.06988}},
{'stp':550908,'address':'550908, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MAR ALTO RESTAURANT, 43 AUGUSTA ROAD, REGENTSPARK, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24080,'lng':28.06740}},
{'stp':551047,'address':'551047, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OUR PLACE, STAND NO, SHOP 21 NORTHCLIFF PIAZZA SHOPPING CENTR, #, NORTHCLIFF, South Africa','geo':{'lat':-26.16807,'lng':27.96517}},
{'stp':551184,'address':'551184, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PARREIRINHA RESTAURANT, 9 - 6TH STREET, LA ROCHELLE, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.23954,'lng':28.05396}},
{'stp':551232,'address':'551232, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SPUR TEXAMO, SHOP U5/6 THE GLEN S/CENTRE, ORPEN ST CNR LETABA ROAD, EASTCLIFF, ROSETTENVILLE, South Africa','geo':{'lat':-26.26685,'lng':28.05093}},
{'stp':551263,'address':'551263, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RIDGE PARK SPORTS CLUB, VAN HULSTEYN STRAAT, KENILWORTH, JHB, ROSETTENVILLE, South Africa','geo':{'lat':-26.25490,'lng':28.04490}},
{'stp':551351,'address':'551351, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE COUNTRY CLUB JOHANNESBURG, 1 NAPIER ROAD, AUCKLAND PARK, AUCKLAND PARK, MORNINGSIDE, South Africa','geo':{'lat':-26.18340,'lng':28.01057}},
{'stp':551352,'address':'551352, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DEL SOL RESTAURANT, 56 WINNY STREET, REGENTS PARK, JHB, ROSETTENVILLE, South Africa','geo':{'lat':-26.23870,'lng':28.07340}},
{'stp':551369,'address':'551369, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RHODES PARK BOWLING CLUB, MONTEQUE STREET, KENSINGTON, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.19220,'lng':28.09530}},
{'stp':551457,'address':'551457, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TONINO S PUB AND PIZZA, 3A DUNNOTTER STREET, SYDENHAM, SYDENHAM, GILLOOLYS, South Africa','geo':{'lat':-26.15650,'lng':28.08670}},
{'stp':552027,'address':'552027, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HERBERT PARK BOWLING CLUB, 71 TURF CLUB STREET, TURFONTEIN, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.23930,'lng':28.03560}},
{'stp':552064,'address':'552064, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TURFONTEIN BOWLING CLUB, 67 TURF CLUB STREET, TURFFONTEIN, JOHANNESBURG, CROWN MINES, South Africa','geo':{'lat':-26.23960,'lng':28.03730}},
{'stp':552172,'address':'552172, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RADIUM BEER HALL, 282 LOUIS BOTHA AVE, ORANGE GROVE, JOHANNESBURG, GILLOOLYS, South Africa','geo':{'lat':-26.16260,'lng':28.08450}},
{'stp':552175,'address':'552175, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SLUGGERS SPORTS BAR, STAND 530, 84 BISHOP STREET, TURFFONTEIN, CROWN MINES, South Africa','geo':{'lat':-26.24246,'lng':28.04403}},
{'stp':552312,'address':'552312, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NUNO S PORTUGESE RESTAURANT, SHOP 5 & 6 MELVILLE GARDENS, 7TH STREET & 2ND AVE, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17724,'lng':28.00843}},
{'stp':552400,'address':'552400, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RANDPARK CLUB, SETPERK AVENUE AND REPUBLIC RD, WINDSOR PARK, NORTHCLIFF, RANDBURG, South Africa','geo':{'lat':-26.11447,'lng':27.96507}},
{'stp':552458,'address':'552458, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HILSON PARK BOWLING CLUB, STAND NO, LOUIS BOTHA AVENUE, HIGHLANDS NORTH, GILLOOLYS, South Africa','geo':{'lat':-26.14799,'lng':28.08526}},
{'stp':552469,'address':'552469, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LUCIOS PIZZERIA, 231 DF MALAN DRIVE, BLACKHEATH, JHB, RANDBURG, South Africa','geo':{'lat':-26.13680,'lng':27.97570}},
{'stp':552506,'address':'552506, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ROOSEVELT PARK BOWLING CLUB, ROOSEVELT PARK BOWLING CLUB, MILNER AVENUE, JOHANNESBURG, NORTHCLIFF, South Africa','geo':{'lat':-26.15160,'lng':27.98110}},
{'stp':552582,'address':'552582, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KEG AND BEAGLE, SHOP 25 LINKSFIELD TERRACE, SHOPPING CENTRE, ERF 1230, LINKSFIELD ROAD, GILLOOLYS, South Africa','geo':{'lat':-26.14835,'lng':28.12833}},
{'stp':552616,'address':'552616, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, WINSTONS RESTAURANT AND PUB, SHOP 2, TUZLA MEWS BUILDING, 187 SMIT STREET, STAND 243, FAIRLANDS, NORTHCLIFF, South Africa','geo':{'lat':-26.13030,'lng':27.94480}},
{'stp':552734,'address':'552734, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, RATZ RESTAURANT, RATZ RESTAURANT, SHOP 96, SEVENTH STREET, MELVILLE_CBD,MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17620,'lng':28.00898}},
{'stp':552833,'address':'552833, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CALISTOS RESTAURANT AND TAKE AWAY, 69 TURF CLUB STR, JOHANNESBURG, #, CROWN MINES, South Africa','geo':{'lat':-26.26285,'lng':28.03112}},
{'stp':552988,'address':'552988, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LEMON TREE COFFEE SHOP, ERF 00415/005, 63 HANS STRYDOM DRIVE, LINDEN EXT, RANDBURG, South Africa','geo':{'lat':-26.12185,'lng':27.98897}},
{'stp':553080,'address':'553080, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, REGENT HILL BOWLING CLUB, ERF 821 THE HILL FIFTH AVENUE, WILLIAM WATT PARK, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24500,'lng':28.06810}},
{'stp':553106,'address':'553106, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CLUBE DA FESTA BRAVA RESTAURANT, 83 AUGUSTA RD, BETWEEN FRIEDA AND EDWARD STREET, BEING STAND 556, REGENTS PARK, ROSETTENVILLE, South Africa','geo':{'lat':-26.24170,'lng':28.07130}},
{'stp':553255,'address':'553255, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OBSERVATORY GOLF CLUB, STEYN STREET 5, OBSERVATORY, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.17857,'lng':28.07820}},
{'stp':553278,'address':'553278, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OLD EDWARDIAN CLUB, 11 9TH AVE, LOWER HOUGHTON, JOHANNESBURG, GILLOOLYS, South Africa','geo':{'lat':-26.15842,'lng':28.06310}},
{'stp':553354,'address':'553354, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TRIDENTS ARMS PUB AND GRILL,  40 STAND NO, ERF 1 TO 4 LINROY STREET, STEELDALE, ROSETTENVILLE, South Africa','geo':{'lat':-26.24530,'lng':28.09490}},
{'stp':553386,'address':'553386, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIESTA HOTEL, 406 MAIN STREET, JEPPESTOWN, JOHANNESBURG, CBD, South Africa','geo':{'lat':-26.20326,'lng':28.07296}},
{'stp':553431,'address':'553431, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, STONES MELVILLE, 1ST FLOOR, SHOP NO9, MAIN ROAD, MELLVILLE, MELVILLE, South Africa','geo':{'lat':-26.17759,'lng':28.00065}},
{'stp':553455,'address':'553455, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SOUTHERN SUBURBS BOWLING CLUB, CNR VAN HULSTEYN & DE VILLIERS, KENILWORTH, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.24530,'lng':28.04890}},
{'stp':553695,'address':'553695, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SOUTHERN TAVERN EETHUIS, 28 CARTER STR, FOREST HILL, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.25648,'lng':28.03947}},
{'stp':580845,'address':'580845, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ST VINCENTE PUB AND RESTAURANT, 27-6TH STREET, LA ROCHELLE, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.23970,'lng':28.05530}},
{'stp':602881,'address':'602881, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TANDOOR PIZZA BAR, STAND 444, 26 ROCKEY STREET, BELLEVUE, #, YEOVILLE, South Africa','geo':{'lat':-26.18127,'lng':28.06767}},
{'stp':603986,'address':'603986, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, POMBOS PORTUGUESE RESTAURANT, 57 5TH STREET, ALBERTSKROON, JOHANNESBURG, NORTHCLIFF, South Africa','geo':{'lat':-26.16170,'lng':27.97520}},
{'stp':604858,'address':'604858, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, DROS MELVILLE RESTAURANT, SHOP 35 CAMPUS SQUARE SHOPPING, CENTRE CNR LANE & UNIVERSITY RD, AUCKLANDPARK, MELVILLE, South Africa','geo':{'lat':-26.18152,'lng':28.00303}},
{'stp':606176,'address':'606176, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, F B S PUB AND GRUB, SOUTH HILLS CENTRE SHOP 7 CNR, SWIMBURN AND OUTSPAN STR  SOUTH HILLS, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.24820,'lng':28.08790}},
{'stp':607434,'address':'607434, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MIMMOS SENDERWOOD, SHOP 14   SENDERWOOD CENTRE, 17 CHAUCER AVE AND SHELLY AVE, #, GILLOOLYS, South Africa','geo':{'lat':-26.15725,'lng':28.12457}},
{'stp':607622,'address':'607622, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE MANHATTAN CLUB LTD, 19 WESSELS ROAD, RIVONIA, #, BRYANSTON, South Africa','geo':{'lat':-26.05580,'lng':28.06120}},
{'stp':609995,'address':'609995, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PIATTO CRESTA (NTO), SHOP 9201, CRESTA SHOPPING CENTRE,, NORTHCLIFF, Johannesburg, RANDBURG, South Africa','geo':{'lat':-26.13605,'lng':27.97543}},
{'stp':618436,'address':'618436, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, XAI XAI (NTO), SHOP 3 AND 4 MELVILLE GARDENS, ERF 109, 7TH STREET BETWEEN 1ST & 2ND AV, MELVILLE REGION B, MELVILLE, South Africa','geo':{'lat':-26.17569,'lng':28.00840}},
{'stp':622028,'address':'622028, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OCEAN BASKET MELVILLE (NTO), SHOP NO:1, REAL TIME INVESTMENT BUILDING, NO:8, MAIN ROAD, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17710,'lng':28.00170}},
{'stp':631563,'address':'631563, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, COL\'CACCHIO FAIRLANDS, SHOP 23, FAIRLANDS WORLD WEAR CENTRE, #, NORTHCLIFF, South Africa','geo':{'lat':-26.11950,'lng':27.95720}},
{'stp':631859,'address':'631859, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THUNDERGUN STEAKHOUSE, SHOP 586 PALACE CENTRE, BEYERS NAUDE AND LEWISHAM, #, NORTHCLIFF, South Africa','geo':{'lat':-26.13600,'lng':27.97510}},
{'stp':637107,'address':'637107, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PRIMI PIATTI MELROSE ARCH, SHOP 25A, THE HIGH STREET, MELROSE ARCH, HYDE PARK, South Africa','geo':{'lat':-26.13438,'lng':28.06862}},
{'stp':642173,'address':'642173, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LUSITANO CLUB, 22 FREIDA STREET, ERF 609 89 AUGUSTA ROAD, CNR 20 AND 22 REGION F, ROSETTENVILLE, South Africa','geo':{'lat':-26.24180,'lng':28.07170}},
{'stp':646440,'address':'646440, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, TOWNSVIEW SPORTSCLUB, CNR PHILIP ROUX & VALDA STREET, TOWNSVIEW, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.25853,'lng':28.05233}},
{'stp':657459,'address':'657459, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, IL GIARDINO (NTO), 44 STANLEY ROAD, AUCKLAND PARK, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.18517,'lng':28.01851}},
{'stp':657542,'address':'657542, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE GUILDHALL (NTO), 42 CNR MARKET STREET, JOBURG, JOBURG, CBD, South Africa','geo':{'lat':-26.20274,'lng':28.05744}},
{'stp':657544,'address':'657544, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, KUTLOANO LUNCH (NTO), 29 RAILEY STREET, YEOVILLE, YEOVILLE, YEOVILLE, South Africa','geo':{'lat':-26.18111,'lng':28.06036}},
{'stp':657708,'address':'657708, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SALVATION CAFE (NTO), 44 STANLEY, AUCKLAND PARK, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.18558,'lng':28.01948}},
{'stp':664517,'address':'664517, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MYTHOS ATHOL SQUARE (NTO), ATHOLL SQUARE, CNR KATHERINE AND WIERDA RD EAST, SANDTON, HYDE PARK, South Africa','geo':{'lat':-26.10685,'lng':28.06635}},
{'stp':665411,'address':'665411, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SIX COCKTAILS (NTO), 7TH STREET, MELVILLE, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17648,'lng':28.00865}},
{'stp':680595,'address':'680595, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JB RIVERS WORLDWEAR (NTO), WORLDWEAR CENTRE, CNR BEYERS NAUDE & WILDSON RD, NORTHCLIFF, NORTHCLIFF, South Africa','geo':{'lat':-26.12128,'lng':27.95629}},
{'stp':682145,'address':'682145, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE SHWARMA COMPANY (NTO), SHOP NR 2, 71 GRANT AVENUE, NORWOOD, CBD, South Africa','geo':{'lat':-26.15774,'lng':28.07604}},
{'stp':684140,'address':'684140, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, THE FAIRWAY HOTEL (NTO), SETPARK AVENUE, WINDSOR PARK, RANDPARK CLUB, RANDBURG, South Africa','geo':{'lat':-26.11460,'lng':27.96520}},
{'stp':686264,'address':'686264, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BALLBREAKERS CRESTA (NTO), BLACKHEATH PAVILLION, CNR BEYERS NAUDE & PENDORING ROAD, #, NORTHCLIFF, South Africa','geo':{'lat':-26.12924,'lng':27.96909}},
{'stp':687089,'address':'687089, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, FASHION SOCIETY (NTO), SHOP NO:1 GROUND FLOOR, DUNROSDIAN BUILDING, NO : 88 FRANCIS, STAND NO: 287/3 NORWOOD, YEOVILLE, South Africa','geo':{'lat':-26.15956,'lng':28.07562}},
{'stp':687228,'address':'687228, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CAFE LATINO, 119 MAIN STREET, STAND NO 00664, KENNILWORTH, ROSETTENVILLE, South Africa','geo':{'lat':-26.24959,'lng':28.05170}},
{'stp':690343,'address':'690343, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, LIQUID BLUE COCKTAIL LOUNGE, CNR 7TH STR AND 2ND AVE, SHOP 9 MELVILLE PROPERTIES GROUND FLOOR, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17670,'lng':28.00860}},
{'stp':696625,'address':'696625, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MIKE\'S KITCHEN FLORIDA GLEN (NTO), SHOP 10, TERRAZA CENTRE, CNR GORDON & LONGE ROADS, FLORIDA GLEN, NORTHCLIFF, South Africa','geo':{'lat':-26.16419,'lng':27.95013}},
{'stp':701651,'address':'701651, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, UNIAO CULTURAL RECREATIVE, DESPOATIVA PORTUGUESA, 4 EASTWOOD STREET, TURFONTEIN, ROSETTENVILLE, South Africa','geo':{'lat':-26.23846,'lng':28.03678}},
{'stp':702087,'address':'702087, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, POPPY\'S MELVILLE (NTO), UNIT 4, CNR 7TH STREET & 2ND AVENUE, MELVILLE ERF NO 7/8 9-3, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17735,'lng':28.00858}},
{'stp':702088,'address':'702088, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ANT CAFE (NTO), SHOP 11A, BUSINESS BUILDING, 11 - 7 STREET BETWEEN 3RD & 4TH AVENUE, MELVILLE, MELVILLE, South Africa','geo':{'lat':-26.17656,'lng':28.00938}},
{'stp':706725,'address':'706725, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NEW ARRIVAL GUEST HOUSE, PORTION 1 OF 1452, BEZ VALLEY, #, CBD, South Africa','geo':{'lat':-26.18593,'lng':28.08180}},
{'stp':709260,'address':'709260, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SLUG AND LETTUCE RIVONIA, ERF 224 SHOP G20, CLOISTERS SHOPPING CENTRE, CNR BOULEVARD AND 9TH STREET, MORNINGSIDE, South Africa','geo':{'lat':-26.05768,'lng':28.06026}},
{'stp':724483,'address':'724483, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BTJS PUB ABD DINER, STAND NO 27 NO 414, COMMISSIONER STREET, FAIRVIEW, CBD, South Africa','geo':{'lat':-26.20220,'lng':28.07182}},
{'stp':726043,'address':'726043, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, ADEGA NORTHCLIFF (NTO), 14TH AVENUE, OPPOSITE L CORRO CENTRE, NORTHCLIFF, NORTHCLIFF, South Africa','geo':{'lat':-26.14502,'lng':27.95250}},
{'stp':727486,'address':'727486, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, OLD OWENS (NTO), CNR ROSETTENVILLE RD AND 11TH AVE, LA ROCHELLE, JOHANNESBURG, ROSETTENVILLE, South Africa','geo':{'lat':-26.23612,'lng':28.05866}},
{'stp':758640,'address':'758640, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, JO-ANNA\'S (NTO), 77TH STREET, MELVILLE, #, MELVILLE, South Africa','geo':{'lat':-26.17695,'lng':28.00862}},
{'stp':762133,'address':'762133, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, SPUR HEATHWAY (NTO), SHOP 1042, HEATHWAY SQUARE, CNR BEYERS NAUDE & CASTLE HILL STREET, BLAKHEATH, RANDBURG, South Africa','geo':{'lat':-26.12649,'lng':27.96709}},
{'stp':773129,'address':'773129, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, PIZA E VINO MELVILLE (NTO), LOTHBURY, AUCKLAND PARK, PORTION OF 221, MELVILLE, South Africa','geo':{'lat':-26.18228,'lng':28.00660}},
{'stp':773838,'address':'773838, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, CURRY DELITES (NTO), 126 RIVONIA ROAD, SANDOWN, SANDTON, MORNINGSIDE, South Africa','geo':{'lat':-26.10807,'lng':28.05892}},
{'stp':779878,'address':'779878, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HUDDLE PARK, CLUB STREET, REMAINING EXTENT OF FARM BEDFORD 68 IR, CORNER SHELLY & ST ANDREWS AVENUE, GILLOOLYS, South Africa','geo':{'lat':-26.16376,'lng':28.11042}},
{'stp':781212,'address':'781212, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, MOZAMBIK LINKSFIELD (NTO), CORNER CLUB & LINKSFIELD ROAD, LINKSFIELD, LINKSFIELD, GILLOOLYS, South Africa','geo':{'lat':-26.14822,'lng':28.12590}},
{'stp':788814,'address':'788814, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, BLUE NAARTJIE, 48 STAND NO, 8TH STREET OFF LOUIS BOTHA AVENUE, ORANGE GROOVE, GILLOOLYS, South Africa','geo':{'lat':-26.16331,'lng':28.08344}},
{'stp':790740,'address':'790740, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, NUSLO (NTO), CNR VICTORIA & WEMMER PAN ROAD, WEMMER PAN, REGENTS PARK, ROSETTENVILLE, South Africa','geo':{'lat':-26.23852,'lng':28.06281}},
{'stp':794299,'address':'794299, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, D STONE SPORTS BAR, 38 MARJORIE STREET, ERF 339 CORNER VICTORIA ROAD, #, ROSETTENVILLE, South Africa','geo':{'lat':-26.23930,'lng':28.06846}},
{'stp':797876,'address':'797876, EGL - JO\'BURG CENTRA, DENVER HYBRID SOUTH, HELLS KITCHEN (NTO), 7TH STREET, MELVILLE, #, MELVILLE, South Africa','geo':{'lat':-26.17741,'lng':28.00866}},
  ]



;
export default STPUtils;
