import React from "react";
import Button from "@material-ui/core/Button";
import Utils from "./utils";
import { Auth } from "aws-amplify";
import aws_exports from "./aws-exports"; // if you are using Amplify CLI
import Amplify from "aws-amplify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
// import Chip from "@material-ui/core/Chip";
import ConsumableChipFixed from "./consumablechipsectionfixed";
import ConsumableChipConsumable from "./consumablechipsectionconsumable";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Download from "./download";
// import Promotions from "./promotions";
import ImageUploader from "react-images-upload";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StartCampIcon from "@material-ui/icons/FlashOn";
import EditIcon from "@material-ui/icons/Create";
import AddIcon from "@material-ui/icons/Add";
import AddClientIcon from "@material-ui/icons/LocationCity";
import Snackbar from "@material-ui/core/Snackbar";

Amplify.configure(aws_exports);
class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5,
          marginRight: 10
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}
class ClientsAndProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      component: <div />,
      newClient: "",
      newProductLine: "",
      newProducts: "",
      allclients: [],
      fixedAssetChipText: "",
      allproductlines: [],
      CLstatus: "",
      CLreportstatus: "",
      addClientLabel: "",
      consumableAssetChipText: "",
      filterpromolistenddate: new Date("2021-01-01T00:00:00"),
      filterpromoliststartdate: new Date("2019-07-01T02:00:00"),
      addProductsLabel: "",
      LLstatus: "",
      filtersection: false,
      disableclientlabelchange: true,
      disableproductlabelchange: true,
      Pstatus: "",
      filter: {},
      name: "",
      username: "",
      pwd: "",
      userlabel: "",
      allLabels: [],

      newLabel: "",
      WLallowed: false,
      disablefaqsave: false,
      faqstatus: "",
      allCampaigns: [],
      reorderallcampaign: [],
      addProductsLabelClientID: ""
    };
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  async doInvite(username, password, type, clients, name, label) {
    if (clients === "") clients = "all";
    try {
      let a = await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          email: username // optional
        }
      });
      this.doSnackBar("User created, activating.");

      if (type === "Admin") clients = "all";
      Auth.forgotPassword(username);
      // Utils.log(a);
      await Utils.createUser({
        id: a.userSub,
        name: name,
        email: username,
        type: type,
        clients: clients,
        label: label
      });
      this.setState({
        clients: "",
        username: "",
        name: "",
        type: "",
        pwd: "",
        status: "Invited",
        userlabel: ""
      });

      this.componentDidMount();
    } catch (err) {
      Utils.log(err);
      this.doSnackBar("Error creating account.");
    }
  }
  async update(input) {
    let cl = await Utils.getAllClients(this.state.filter);
    cl.forEach(z => (z.consumablesetup = JSON.parse(z.consumablesetup)));
    for (var i = 0; i < cl.length; i++) {
      cl[i].icon = await Utils.getPicFromS3(cl[i].icon);
    }

    let pl = await Utils.getAllProductLines(this.state.filter);
    let ll = await Utils.getAllLabels();
    var reorderallcampaign = cl;
    input.forEach(a => {
      reorderallcampaign.forEach(b => {
        if (a.client.id === b.id) {
          if (typeof b.promotions === "undefined") b.promotions = [];
          b.promotions.push(a);
        }
      });
    });

    // console.log(reorderallcampaign);
    // console.log(reorderallcampaign);
    this.setState({
      allclients: cl,
      allproductlines: pl,
      allLabels: ll,
      reorderallcampaign: reorderallcampaign
    });
    // Utils.log(cl);
    // Utils.log(pl);
  }

  async componentDidMount() {
    let p = await Auth.currentAuthenticatedUser();
    // Utils.log(s);
    let t = await Utils.getUser(p.username);
    if (t.label !== "All") this.setState({ userlabel: t.label });

    // Utils.log(t);
    this.doSnackBar("Collecting all campaigns...");
    if (t.label === "All") {
      this.setState({ filter: null });
      this.setState({
        WLallowed: true,
        disableclientlabelchange: false,
        disableproductlabelchange: false
      });
    } else {
      var promos = await Utils.getAllPromotions({ label: { eq: t.label } });
      this.setState({
        filter: { label: { eq: t.label } },
        addClientLabel: t.label,
        addProductsLabel: t.label,
        allCampaigns: promos
      });
    }
    var admin = false;
    if (t.type === "Admin") admin = true;
    if (!admin) this.props.history.push("/");

    this.update(promos);
  }

  async savereport(id) {
    // this.setState({ CLreportstatus: "  Saving..." });
    this.doSnackBar("Saving...");
    var reportconfig = "";
    this.state.allclients.forEach(a => {
      if (a.id === id) reportconfig = a.reportconfig;
    });
    if (reportconfig === "") reportconfig = null;
    await Utils.saveDesign({ id: id, reportconfig: reportconfig });
    this.doSnackBar("Saved");
    // this.timer = setInterval(() => {
    //   this.setState({ CLreportstatus: "" });
    // }, 4000);
  }

  async saveNewClient(input, label) {
    var C = await Utils.createClient(input, label);
    if (C) {
      this.doSnackBar("Client saved...");
      this.setState({
        // CLstatus: "Client saved",
        newClient: "",
        allclients: await Utils.getAllClients(this.state.filter)
      });
    }
    this.componentDidMount()
  }
  async saveNewLabel(input) {
    try {
      var C = await Utils.createLabel(input);
      if (C) {
        this.setState({
          LLstatus: "Label saved",
          newLabel: "",
          allLabels: await Utils.getAllLabels()
        });
      }
    } catch (err) {
      Utils.log(err);
    }
  }

  async deleteClient(input) {
    var C = await Utils.deleteClient(input);
    // Utils.log(C)
    if (C) {
      this.setState({
        CLstatus: "Client deleted",
        allclients: await Utils.getAllClients(this.state.filter)
      });
    }
  }
  async deleteLabel(input) {
    var C = await Utils.deleteLabel(input);
    // Utils.log(C)
    if (C) {
      this.setState({
        LLstatus: "Label deleted",
        allLabels: await Utils.getAllLabels()
      });
    }
  }
  async saveNewProductLine(inputPL, inputP, inputLabel, clientid) {
    // Utils.log(inputPL);
    // Utils.log(inputP);
    var C = await Utils.createPromoterPlusProduct(
      inputPL,
      inputP,
      inputLabel,
      clientid
    );
    // Utils.log(C);
    if (C) {
      this.componentDidMount();
      this.doSnackBar("Product line saved");
      // this.setState({
      //   Pstatus: "Products saved",
      //   newClient: "",
      //   allproductlines: await Utils.getAllProductLines(this.state.filter)
      // });
    }
  }

  async deleteProductLine(input) {
    var C = await Utils.deletePromoterPlusProduct(input);
    // Utils.log(C)
    if (C) {
      this.componentDidMount();
      this.doSnackBar("Product Deleted");
      // this.setState({
      //   Pstatus: "Products deleted",
      //   allproductlines: await Utils.getAllProductLines(this.state.filter)
      // });
    }
  }

  makeClientTableRows(input) {
    // Utils.log(input)
    return input.map((item, index) => {
      return (
        <TableRow key={index} style={{}}>
          <TableCell style={{ padding: 0, width: 222, paddingTop: 17 }}>
            {item.label}
          </TableCell>
          <TableCell style={{ padding: 0, width: 222, paddingTop: 17 }}>
            {item.clientname}
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.deleteClient(item.id);
              }}
            >
              Delete
            </Button>
          </TableCell>
          <TableCell style={{ padding: 0, width: 222, paddingTop: 17 }}>
            <TextField
              style={{ color: "#374799", margin: 0 }}
              id="outlined-name"
              label="Client report embed"
              value={item.reportconfig || ""}
              onChange={change => {
                var oldclients = this.state.allclients;
                oldclients.forEach(a => {
                  if (a.id === item.id) a.reportconfig = change.target.value;
                });
                this.setState({ allclients: oldclients });
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.savereport(item.id);
              }}
            >
              Save Report
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  makeLabelTableRows(input) {
    // Utils.log(input)
    return input.map((item, index) => {
      return (
        <TableRow key={index} style={{}}>
          <TableCell style={{ padding: 0, width: 222, paddingTop: 17 }}>
            {item.label}
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.deleteLabel(item.id);
              }}
            >
              Delete
            </Button>
          </TableCell>
          <TableCell>
            <TextField
              style={{ color: "#374799", margin: 0, width: 500 }}
              id="outlined-name"
              label="Config "
              value={item.config}
              onChange={change => {
                var oldlabels = this.state.allLabels;
                oldlabels[index].config = change.target.value;
                this.setState({ allLabels: oldlabels });
              }}
              margin="normal"
              variant="outlined"
            />
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.updateLabelCongif(item.id, item.config);
              }}
            >
              Save config
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  async updateLabelCongif(id, config) {
    await Utils.updateLabel({ id: id, config: config });
    // Utils.log(s)
  }

  async saveIconForClient(id, image) {
    this.doSnackBar("Saving...");
    let s = await Utils.saveFAQPicToS3({
      filename: Utils.generateName() + ".jpg",
      picture: image
    });
    await Utils.saveClientIcon({
      id: id,
      icon: s.key
    });
    this.doSnackBar("Saved...");
    this.componentDidMount();
  }

  async saveIconForFaq(id, image) {
    this.setState({ disablefaqsave: true });
    let s = await Utils.saveFAQPicToS3({
      filename: Utils.generateName(),
      picture: image
    });
    var oldproductlines = this.state.allproductlines;
    oldproductlines.forEach(a => {
      if (a.id === id) a.faqicon = s.key;
    });
    this.setState({ allproductlines: oldproductlines, disablefaqsave: false });
  }

  async saveFAQ(id) {
    this.doSnackBar("Saving...");
    var itemtosave = {};
    var oldproductlines = this.state.allproductlines;
    oldproductlines.forEach(a => {
      if (a.id === id) itemtosave = a;
    });
    if (itemtosave.faqpdf.trim() === "") itemtosave.faqpdf = null;
    if (itemtosave.faqtext.trim() === "") itemtosave.faqtext = null;
    await Utils.updateFAQofProduct({
      id: itemtosave.id,
      faqpdf: itemtosave.faqpdf,
      faqicon: itemtosave.faqicon,
      faqtext: itemtosave.faqtext
    });
    this.doSnackBar("Saved");
  }

  async saveproduct(id) {
    // this.setState({ faqstatus: "  Saving..." });
    this.doSnackBar("Saving...");
    var itemtosave = {};

    var oldproductlines = this.state.allproductlines;
    oldproductlines.forEach(a => {
      if (a.id === id) itemtosave = a;
    });
    // console.log(itemtosave)
    await Utils.updateProductInProductLine({
      id: itemtosave.id,
      products: itemtosave.products,
      clientid: itemtosave.clientid
    });

    // console.log(faq)
    this.doSnackBar("Saved");
    // this.setState({ faqstatus: "   Saved" });
    // this.timer = setInterval(() => {
    //   this.setState({ faqstatus: "" });
    // }, 4000);
  }
  async saveConsumeSetupSection(input) {
    await Utils.updateConsumeSetup(input);
    this.doSnackBar("Saved");
  }
  makeProductTableRows(input) {
    // Utils.log(input);
    return input.map((item, index) => {
      return (
        <TableRow key={index} style={{}}>
          <TableCell style={{ padding: 0 }}>
            <FormControl
              variant="outlined"
              style={{ padding: 0, margin: 0, width: 200 }}
            >
              <InputLabel>Client</InputLabel>
              <Select
                value={item.clientid}
                onChange={change => {
                  var temp = this.state.allproductlines;
                  console.log(temp)
                  temp.forEach(z => {
                    if (z.id === item.id) z.clientid = change.target.value;
                  });
                  this.setState({ allproductlines: temp });
                }}
                input={<OutlinedInput labelWidth={73} />}
              >
                {this.state.allclients.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.clientname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell style={{ padding: 0, paddingLeft: 20 }}>
            {item.productline}
          </TableCell>
          <TableCell style={{ padding: 0 }}>
            {" "}
            <TextField
              style={{ color: "#374799", margin: 0 }}
              id="outlined-name"
              multiline
              label="FAQ title text"
              value={item.products || ""}
              onChange={change => {
                var oldproductlines = this.state.allproductlines;
                oldproductlines[index].products = change.target.value;

                this.setState({ allproductlines: oldproductlines });
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.saveproduct(item.id);
              }}
            >
              Save
            </Button>
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.deleteProductLine(item.id);
              }}
            >
              Delete
            </Button>
          </TableCell>
          <TableCell style={{ padding: 0 }}>
            <TextField
              style={{ color: "#374799", margin: 0 }}
              id="outlined-name"
              multiline
              label="FAQ title text"
              value={item.faqtext || ""}
              onChange={change => {
                var oldproductlines = this.state.allproductlines;
                oldproductlines.forEach(a => {
                  if (a.id === item.id) a.faqtext = change.target.value;
                });
                this.setState({ allproductlines: oldproductlines });
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell style={{ padding: 0 }}>
            <ImageUploader
              withIcon={false}
              buttonText="Upload FAQ icon (JPG/PNG)"
              withPreview={false}
              label=""
              buttonStyles={{
                backgroundColor: item.faqicon === null ? "#666" : "#558B2F"
              }}
              fileContainerStyle={{
                margin: 0,
                padding: 0,
                backgroundColor: "#ddd",
                shadowColor: "#ddd",
                borderWidth: 0,
                borderRadius: 0,
                borderColor: "#ddd",
                boxShadow: `0px 0px 0px 0`,
                display: "flex",
                flexDirection: "row",
                alignSelf: "center"
              }}
              singleImage={true}
              onChange={(pictureFiles, pictureDataURLs) => {
                // console.log(pictureFiles);
                // console.log(pictureDataURLs[0].split(",")[1]);
                this.saveIconForFaq(
                  item.id,
                  pictureDataURLs[pictureDataURLs.length - 1].split(",")[1]
                );
              }}
              imgExtension={[".jpg", ".png", "jpeg"]}
              maxFileSize={5242880}
            />
          </TableCell>
          <TableCell style={{ padding: 0 }}>
            <TextField
              style={{ color: "#374799", margin: 0 }}
              id="outlined-name"
              multiline
              label="FAQ PDF URL"
              value={item.faqpdf || ""}
              onChange={change => {
                var oldproductlines = this.state.allproductlines;
                oldproductlines.forEach(a => {
                  if (a.id === item.id) a.faqpdf = change.target.value;
                });
                this.setState({ allproductlines: oldproductlines });
              }}
              margin="normal"
              variant="outlined"
            />
          </TableCell>
          <TableCell>
            <Button
              style={{ marginLeft: 20 }}
              variant="contained"
              color="primary"
              disabled={this.state.disablefaqsave}
              onClick={() => {
                this.saveFAQ(item.id);
              }}
            >
              Save FAQ
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  filterSection(input) {
    if (!input)
      return (
        <Button
          variant="contained"
          style={{ color: "#222", backgroundColor: "#1da4f4" }}
          color="primary"
          onClick={() => this.setState({ filtersection: true })}
        >
          Filter
        </Button>
      );
    else
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            style={{ color: "#374799", margin: 10 }}
            id="outlined-name"
            label="Campaign start date"
            defaultValue={
              this.state.filterpromoliststartdate.toISOString().split("T")[0]
            }
            onChange={change => {
              // console.log(change.target.value);
              if (change.target.value !== "") {
                var oldOptions = this.props.filterpromoliststartdate;
                oldOptions = new Date(change.target.value + "T00:00:00");
                this.setState({ filterpromoliststartdate: oldOptions });
              }
            }}
            type="date"
            margin="normal"
            variant="outlined"
          />
          <TextField
            style={{ color: "#374799", margin: 10 }}
            id="outlined-name"
            label="Campaign end date"
            defaultValue={
              this.state.filterpromolistenddate.toISOString().split("T")[0]
            }
            onChange={change => {
              // console.log(change.target.value);
              if (change.target.value !== "") {
                var oldOptions = this.props.filterpromolistenddate;
                oldOptions = new Date(change.target.value + "T00:00:00");
                this.setState({ filterpromolistenddate: oldOptions });
              }
            }}
            type="date"
            margin="normal"
            variant="outlined"
          />
          <Button
            color="primary"
            onClick={() => this.setState({ filtersection: false })}
          >
            Close
          </Button>
        </div>
      );
  }

  render() {
    // console.log(this.state.filtersection);
    return (
      <div style={{ color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          autoHideDuration={3000}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Paper style={{ margin: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: 10,
              marginRight: 10
            }}
          >
            <h1
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <PIcon
                icon={<StartCampIcon style={{ fontSize: 37 }} />}
                size="40px"
                colour="#7ed221"
              />
              Campaigns
            </h1>
            <div>{this.filterSection(this.state.filtersection)}</div>
          </div>
        </Paper>
        {
          null // <ExpansionPanel style={{ margin: 10, backgroundColor: "#fff" }}>
          //   <ExpansionPanelSummary
          //     expandIcon={<AddIcon style={{ fontSize: 40 }} />}
          //     onClick={() => this.props.history.push("/promotions?new")}
          //   >
          //     {" "}
          //     <div
          //       style={{
          //         display: "flex",
          //         flexDirection: "row",
          //         alignItems: "center"
          //         // justifyContent: "center",
          //         // backgroundColor: "#fff",
          //         // color: "#000",
          //         // borderRadius: 5,
          //         // marginRight: 10
          //       }}
          //     >
          //       <div
          //         style={{
          //           width: 70,
          //           height: 70,
          //           display: "flex",
          //           alignItems: "center",
          //           justifyContent: "center",
          //           backgroundColor: "#888",
          //           color: "#000",
          //           borderRadius: 5,
          //           marginRight: 10
          //         }}
          //       >
          //         <StartCampIcon style={{ fontSize: 60, color: "#eee" }} />
          //       </div>
          //       <h1>Create New Campaign</h1>
          //     </div>
          //   </ExpansionPanelSummary>
          // </ExpansionPanel>
        }
        {this.state.WLallowed ? (
          <ExpansionPanel style={{ margin: 10, backgroundColor: "#fff" }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h1>White Label</h1>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: "column" }}>
              <div>
                <Table>
                  <TableBody style={{ padding: 0 }}>
                    {this.makeLabelTableRows(this.state.allLabels || null)}
                  </TableBody>
                </Table>
              </div>
              <div>
                <h2>Add Label</h2>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    style={{ color: "#374799", margin: 0 }}
                    id="outlined-name"
                    multiline
                    label="Label"
                    value={this.state.newLabel}
                    onChange={change => {
                      this.setState({ newLabel: change.target.value });
                    }}
                    margin="normal"
                    variant="outlined"
                  />

                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.saveNewLabel(this.state.newLabel);
                    }}
                  >
                    Add new Label to database
                  </Button>
                  {this.state.LLstatus}
                </div>
              </div>
              <div style={{ marginTop: 50 }}>
                <h4>Download all data (slow)</h4>
                <Download
                  file={"listPromoterPlusPromotions"}
                  title={"promotions"}
                />
                <Download file={"listPromoterPlusPhotoss"} title={"photos"} />
                <Download
                  file={"listPromoterPlusResponsess"}
                  title={"responses"}
                />
                <Download
                  file={"listPromoterPlusSchedules"}
                  title={"schedules"}
                />
                <Download file={"listPromoterPlusClients"} title={"clients"} />
                <Download
                  file={"listPromoterPlusProducts"}
                  title={"products"}
                />
                <Download file={"listPromoterPlusUsers"} title={"users"} />
                <Download file={"listPromoterPlusLabelss"} title={"labels"} />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <div />
        )}
        <ExpansionPanel style={{ margin: 10, backgroundColor: "#fff" }}>
          <ExpansionPanelSummary
            expandIcon={<AddIcon style={{ fontSize: 40 }} />}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
                // justifyContent: "center",
                // backgroundColor: "#fff",
                // color: "#000",
                // borderRadius: 5,
                // marginRight: 10
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <AddClientIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h1>Create New Client {this.state.CLreportstatus}</h1>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ flexDirection: "column" }}>
            <div>
              <h2>Add Client</h2>
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  style={{ color: "#374799", margin: 0 }}
                  id="outlined-name"
                  multiline
                  label="Client Name"
                  value={this.state.newClient}
                  onChange={change => {
                    this.setState({ newClient: change.target.value });
                  }}
                  margin="normal"
                  variant="outlined"
                />

                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.saveNewClient(
                      this.state.newClient,
                      this.state.addClientLabel
                    );
                  }}
                >
                  Add Client to database
                </Button>
                {this.state.CLstatus}
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {this.state.allproductlines.filter(z => z.clientid === null).length >
        0 ? (
          <ExpansionPanel style={{ margin: 10, backgroundColor: "#fff" }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon style={{ fontSize: 40 }} />}
            >
              <h1>Products</h1>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: "column" }}>
              <div>
                <h2>Product list{this.state.faqstatus}</h2>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Client</TableCell>
                      <TableCell>Product line</TableCell>
                      <TableCell>Products</TableCell>
                      <TableCell>Save</TableCell>
                      <TableCell>Delete</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Icon</TableCell>
                      <TableCell>PDF</TableCell>
                      <TableCell>Save</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ padding: 0 }}>
                    {this.makeProductTableRows(
                      this.state.allproductlines.filter(
                        z => z.clientid === null
                      ) || null
                    )}
                  </TableBody>
                </Table>
                {this.state.Pstatus}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <div />
        )}
        {console.log(this.state.reorderallcampaign)}
        {this.state.reorderallcampaign.sort((a,b)=>{
          if (a.clientname>b.clientname) return 1
          else if (a.clientname<b.clientname) return -1
          else return 0
        }).map((itm, idxtop) => (
          <ExpansionPanel
            key={idxtop}
            style={{ margin: 10, backgroundColor: "#fff" }}
          >
            <ExpansionPanelSummary
              expandIcon={<EditIcon style={{ fontSize: 40 }} />}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                  // justifyContent: "center",
                  // backgroundColor: "#fff",
                  // color: "#000",
                  // borderRadius: 5,
                  // marginRight: 10
                }}
              >
                <div
                  style={{
                    width: 70,
                    height: 70,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#888",
                    color: "#000",
                    borderRadius: 5,
                    marginRight: 10
                  }}
                >
                  <img
                    alt=" "
                    src={itm.icon}
                    style={{
                      flex: 1,
                      width: "100%",
                      borderRadius: 5,
                      height: "100%",
                      overflow: "hidden",
                      resizeMode: "contain"
                    }}
                  />
                </div>
                <h1>{itm.clientname}</h1>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ flexDirection: "column" }}>
              <div>
                <ExpansionPanel style={{ margin: 10, backgroundColor: "#eee" }}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <h2>Settings</h2>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails style={{ flexDirection: "column" }}>
                    <div>
                      <h3>Icon</h3>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between"
                        }}
                      >
                        <div style={{ width: "50%" }}>
                          <ImageUploader
                            withIcon={false}
                            buttonText="Upload main image for client icon (JPG/PNG)"
                            withPreview={false}
                            label=""
                            fileContainerStyle={{
                              margin: 0,
                              padding: 0,
                              backgroundColor: "#eee",
                              shadowColor: "#ddd",
                              borderWidth: 0,
                              borderRadius: 0,
                              borderColor: "#fff",
                              boxShadow: `0px 0px 0px 0`,
                              display: "flex",
                              flexDirection: "row",
                              alignSelf: "center"
                            }}
                            singleImage={true}
                            onChange={(pictureFiles, pictureDataURLs) => {
                              // console.log(pictureFiles);
                              // console.log(pictureDataURLs[0].split(",")[1]);
                              this.saveIconForClient(
                                itm.id,
                                pictureDataURLs[
                                  pictureDataURLs.length - 1
                                ].split(",")[1]
                              );
                            }}
                            imgExtension={[".jpg", ".png", "jpeg"]}
                            maxFileSize={5242880}
                          />
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around"
                          }}
                        >
                          <TextField
                            style={{ color: "#374799", margin: 0 }}
                            id="outlined-name"
                            label="Client report embed"
                            value={itm.reportconfig || ""}
                            onChange={change => {
                              var oldclients = this.state.allclients;
                              oldclients.forEach(a => {
                                if (a.id === itm.id)
                                  a.reportconfig = change.target.value;
                              });
                              this.setState({ allclients: oldclients });
                              oldclients = this.state.reorderallcampaign;
                              oldclients.forEach(a => {
                                if (a.id === itm.id)
                                  a.reportconfig = change.target.value;
                              });
                              this.setState({ reorderallcampaign: oldclients });
                            }}
                            margin="normal"
                            variant="outlined"
                          />
                          <Button
                            style={{ marginLeft: 20 }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.savereport(itm.id);
                            }}
                          >
                            Save Report
                          </Button>
                        </div>
                      </div>
                      <h3>Products List</h3>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Client</TableCell>
                            <TableCell>Product line</TableCell>
                            <TableCell>Products</TableCell>
                            <TableCell>Save</TableCell>
                            <TableCell>Delete</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Icon</TableCell>
                            <TableCell>PDF</TableCell>
                            <TableCell>Save</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody style={{ padding: 0 }}>
                          {this.makeProductTableRows(
                            this.state.allproductlines.filter(
                              z => z.clientid === itm.id
                            ) || null
                          )}
                        </TableBody>
                      </Table>
                    </div>
                    <div>
                      <h3>Add Products</h3>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          style={{ color: "#374799", margin: 0 }}
                          id="outlined-name"
                          multiline
                          label="Product Line"
                          value={this.state.newProductLine}
                          onChange={change => {
                            this.setState({
                              newProductLine: change.target.value
                            });
                          }}
                          margin="normal"
                          variant="outlined"
                        />
                        <TextField
                          style={{
                            color: "#374799",
                            margin: 0,
                            marginLeft: 10,
                            width: 300
                          }}
                          id="outlined-name"
                          multiline
                          label="Products list (comma seperated)"
                          value={this.state.newProducts}
                          onChange={change => {
                            this.setState({ newProducts: change.target.value });
                          }}
                          margin="normal"
                          variant="outlined"
                        />

                        <Button
                          style={{ marginLeft: 10 }}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.saveNewProductLine(
                              this.state.newProductLine,
                              this.state.newProducts,
                              this.state.addProductsLabel,
                              itm.id
                            );
                          }}
                        >
                          Add products
                        </Button>

                        {this.state.Pstatus}
                      </div>
                    </div>
                    <div>
                      <h3>Invite Admin Users</h3>
                      <TextField
                        style={{ color: "#374799", margin: 0 }}
                        id="outlined-name"
                        multiline
                        label="Name"
                        value={this.state.name}
                        onChange={change => {
                          this.setState({ name: change.target.value });
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                      <TextField
                        style={{ color: "#374799", margin: 0 }}
                        id="outlined-name"
                        multiline
                        label="Email"
                        value={this.state.username}
                        onChange={change => {
                          this.setState({ username: change.target.value });
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                      <TextField
                        style={{ color: "#374799", margin: 0 }}
                        id="outlined-name"
                        multiline
                        label="Password"
                        value={this.state.pwd}
                        onChange={change => {
                          this.setState({ pwd: change.target.value });
                        }}
                        margin="normal"
                        variant="outlined"
                      />

                      <FormControl
                        variant="outlined"
                        style={{ padding: 0, margin: 0, width: 200 }}
                      >
                        <InputLabel>Type</InputLabel>
                        <Select
                          value={this.state.type || ""}
                          onChange={change => {
                            this.setState({ type: change.target.value });
                          }}
                          input={<OutlinedInput labelWidth={73} />}
                        >
                          <MenuItem value={"Admin"}> Admin</MenuItem>
                          <MenuItem value={"Client"}> Client</MenuItem>
                        </Select>
                      </FormControl>

                      <Button
                        style={{ marginLeft: 20, alignSelf: "center" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.doInvite(
                            this.state.username,
                            this.state.pwd,
                            this.state.type,
                            itm.id,
                            this.state.name,
                            this.state.userlabel
                          );
                        }}
                      >
                        Invite
                      </Button>
                    </div>
                    <div>
                      <h3>Consumables</h3>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <ConsumableChipFixed
                            input={this.state.allclients}
                            id={itm.id}
                            onChange={allclients =>
                              this.setState({ allclients, edit: true })
                            }
                          />
                          <ConsumableChipConsumable
                            input={this.state.allclients}
                            id={itm.id}
                            onChange={allclients =>
                              this.setState({ allclients, edit: true })
                            }
                          />
                        </div>
                        <Button
                          color="primary"
                          disabled={!this.state.edit}
                          variant="contained"
                          onClick={() => {
                            if (this.state.edit) {
                              this.saveConsumeSetupSection(
                                this.state.allclients.filter(
                                  z => z.id === itm.id
                                )[0]
                              );
                            }
                          }}
                        >
                          Save Setup
                        </Button>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.props.history.push("/promotions?new&client=" + itm.id)
                  }
                >
                  New campaign
                </Button>
                {typeof itm.promotions === "undefined" ? (
                  <div />
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Campaign name</TableCell>
                        <TableCell>Start date</TableCell>
                        <TableCell>End date</TableCell>
                        <TableCell>Edit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itm.promotions
                        .filter(
                          s =>
                            new Date(s.startdate) >=
                            this.state.filterpromoliststartdate
                        )
                        .filter(
                          s =>
                            new Date(s.enddate) <=
                            this.state.filterpromolistenddate
                        )
                        .map((a, idx) => (
                          <TableRow key={idx}>
                            <TableCell style={{ color: "#666" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}
                              >
                                <h4>{a.promotionName}</h4>
                              </div>
                            </TableCell>
                            <TableCell style={{ color: "#666" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}
                              >
                                {a.startdate.substr(0, 10)}
                              </div>
                            </TableCell>
                            <TableCell style={{ color: "#666" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}
                              >
                                {a.enddate.substr(0, 10)}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                onClick={() =>
                                  this.props.history.push(
                                    "/promotions?edit=" +
                                      a.id +
                                      "&client=" +
                                      itm.id
                                  )
                                }
                              >
                                <EditIcon />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}

export default ClientsAndProducts;
