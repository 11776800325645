import React from "react";
import { Auth } from "aws-amplify";
export default class Temppage extends React.Component {
  async componentDidMount() {
    console.log("init " + new Date().toISOString());
    let cognitouser = await Auth.currentAuthenticatedUser();
    console.log(cognitouser);
    console.log("init3 " + new Date().toISOString());
    let tempcheck2 = await Auth.userAttributes(cognitouser);
    console.log(tempcheck2.filter(item => item.Name==="custom:dynamoid")[0].Value);
    console.log("init3 " + new Date().toISOString());
  }
  render() {
    return <div />;
  }
}
