import React from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
// import { isMobile } from "react-device-detect";
import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
import "../node_modules/react-simple-tree-menu/dist/main.css";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import DeleteIcon from "@material-ui/icons/Delete";
import PromoConsumeSection from "./promoterconsumesection";

import MultipleDatePicker from "react-multiple-datepicker";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PromotionsMaps from "./promotionsmapstree";
// import AddLocationIcon from "@material-ui/icons/AddLocation";
import Button from "@material-ui/core/Button";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
// import { FixedSizeList as List } from "react-window";
import SearchFilter from "./searchfiltertree";
import GeoSearchFilter from "./searchfiltertreegeofence";

const AnyReactComponent = ({ text }) => (
  <div style={{ fontSize: 20 }}>{text}</div>
);

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = address => {
    // console.log(address)
    this.setState({ address });
    this.props.output({ address: address });
  };

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.output({ address: address, geo: latLng });
        // Utils.log("Success", latLng);
      })
      .catch(error => console.error("Error", error));
  };

  componentDidMount() {
    this.setState({ address: this.props.value });
  }

  render() {
    return (
      <PlacesAutocomplete
        value={typeof this.props.value === "undefined" ? "" : this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          componentRestrictions: { country: "za" }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              style={{ width: 400 }}
              variant="outlined"
              label="Location"
              {...getInputProps({
                placeholder: "",
                className: "location-search-input"
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

class PromotionsPromoters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dummy: true,
      location: { address: "" }
    };
  }

  getTimes() {
    return Utils.getTimeOptionsShort().map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));
  }

  getPromoterNames() {
    return this.props.users.map((item, index) => (
      <MenuItem key={index} value={item.id} name={item.name}>
        {item.name + " " + item.email}
      </MenuItem>
    ));
  }

  showAddNewPromoter(input, index) {
    //Utils.log(this.state.promoters[index].promoterName);
    // Utils.log(input.promoterName);
    if (input.promoterName.trim() === "0") return <div />;
    else
      return (
        <FormControl
          variant="outlined"
          style={{ padding: 0, margin: 0, width: 222 }}
        >
          <InputLabel>Promoter name</InputLabel>
          <Select
            value={this.props.input[index].promoterId}
            onChange={change => {
              var oldPromoters = this.props.input;
              oldPromoters[index].promoterId = change.target.value;
              this.props.users.forEach(function(e) {
                if (e.id === change.target.value)
                  oldPromoters[index].promoterName = e.name;
              });

              this.props.onChange(oldPromoters);
            }}
            input={<OutlinedInput labelWidth={123} />}
          >
            {this.getPromoterNames()}
          </Select>
        </FormControl>
      );
  }

  makeMapMarkers(input) {
    var output = [];
    input.forEach(function(e) {
      if (e.promoterLocation.geo) output.push(e);
    });

    if (output.length > 0)
      return output.map((item, index) => (
        <AnyReactComponent
          key={index}
          lat={item.promoterLocation.geo.lat}
          lng={item.promoterLocation.geo.lng}
          text={item.name}
        />
      ));
    else return null;
  }

  getNames(input) {
    var output = "";
    input.forEach(function(e, idx) {
      if (idx > 0) output += ", ";
      output += e.name;
    });
    return output;
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <LocationSearchInput
            value={this.state.location.address}
            disabled={true}
            output={change => {
              this.setState({ location: change });
            }}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={
              typeof this.state.location.geo !== "undefined" ? false : true
            }
            onClick={() => {
              if (typeof this.state.location.geo !== "undefined") {
                let input = this.props.input;
                if (
                  this.props.input.filter(
                    z => z.key === this.state.location.address
                  ).length === 0
                ) {
                  let item = {
                    location: this.state.location,
                    key: this.state.location.address,
                    label: this.state.location.address.split(",")[0]
                  };
                  input.push(item);
                  this.setState({ location: { address: "" } });
                  this.props.onChange(input);
                }
              }
            }}
          >
            Add Location
          </Button>
        </div>
        <br />
        <TreeMenu data={this.props.input}>
          {({ search,items }) => (
            <ul>
 <TextField   margin="normal"
   variant="outlined" onChange={e => search(e.target.value)} placeholder="Type and search" />
              {items.map(
                ({
                  level,
                  dates,
                  location,
                  startTime,
                  endTime,
                  parent,
                  key,
                  ...props
                }) => (
                  <div
                    key={key}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <ItemComponent
                      {...props}
                      style={{ marginLeft: level * 30 , height: 40 , display: "flex", alignItems: 'center'}}
                    />

                    <Button
                      onClick={() => {
                        if (level === 0) {
                          let input = [];
                          this.props.input.forEach(z => {
                            if (z.key !== key) input.push(z);
                          });
                          this.props.onChange(input);
                        }
                        if (level === 1) {
                          let input = this.props.input;
                          input.forEach(a => {
                            a.nodes.forEach((b, idx) => {
                              if (
                                b.key === key.split("/")[1] &&
                                a.key === key.split("/")[0]
                              )
                                a.nodes.splice(idx, 1);
                            });
                          });
                          this.props.onChange(input);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                    {level === 0 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <SearchFilter
                          users={this.props.users}
                          selected="asd"
                          title={"Add promoter"}
                          nodes={this.props.input.filter(z=>z.key===key)[0].nodes}
                          startTime={this.props.startTime}
                          endTime={this.props.endTime}
                          onChange={item => {
                            let input = this.props.input;
                            input.forEach(z => {
                              if (z.key === key) {
                                if (typeof z.nodes === "undefined")
                                  z.nodes = [];
                                if (
                                  z.nodes.filter(r => r.key === item.name)
                                    .length === 0
                                )
                                  z.nodes.push({
                                    promoter: item,
                                    key: item.name,
                                    label: item.name,
                                    startTime: this.props.startTime,
                                    endTime: this.props.endTime
                                  });
                              }
                            });
                            this.props.onChange(input);
                          }}
                        />
                        <GeoSearchFilter
                          users={this.props.users}
                          selected="asd"
                          locationInput={location}
                          title={"Add promoter"}
                          startTime={this.props.startTime}
                          endTime={this.props.endTime}
                          onChange={item => {
                            let input = this.props.input;
                            input.forEach(z => {
                              if (z.key === key) {
                                if (typeof z.nodes === "undefined")
                                  z.nodes = [];
                                if (
                                  z.nodes.filter(r => r.key === item.name)
                                    .length === 0
                                )
                                  z.nodes.push({
                                    promoter: item,
                                    key: item.name,
                                    label: item.name,
                                    startTime: this.props.startTime,
                                    endTime: this.props.endTime
                                  });
                              }
                            });
                            this.props.onChange(input);
                          }}
                        />
                      </div>
                    ) : null}
                    {level === 1 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Dates:{" "}
                        <MultipleDatePicker
                          selectedDates={dates}
                          onSubmit={dates => {
                            let input = this.props.input;
                            input.forEach(a => {
                              a.nodes.forEach((b, idx) => {
                                if (
                                  b.key === key.split("/")[1] &&
                                  a.key === key.split("/")[0]
                                )
                                  b.dates = dates;
                              });
                            });
                            this.props.onChange(input);
                          }}
                        />
                        <FormControl
                          variant="outlined"
                          style={{ padding: 0, margin: 0, width: 100}}
                        >
                          <InputLabel>Start time</InputLabel>
                          <Select

                            value={startTime}
                            onChange={change => {
                              let input = this.props.input;
                              input.forEach(a => {
                                a.nodes.forEach((b, idx) => {
                                  if (
                                    b.key === key.split("/")[1] &&
                                    a.key === key.split("/")[0]
                                  )
                                    b.startTime = change.target.value;
                                });
                              });
                              this.props.onChange(input);
                            }}
                            input={<OutlinedInput labelWidth={73} />}
                          >
                            {this.getTimes()}
                          </Select>
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          style={{ padding: 0, margin: 0, width: 100}}
                        >
                          <InputLabel>End time</InputLabel>
                          <Select
                            value={endTime}
                            onChange={change => {
                              let input = this.props.input;
                              input.forEach(a => {
                                a.nodes.forEach((b, idx) => {
                                  if (
                                    b.key === key.split("/")[1] &&
                                    a.key === key.split("/")[0]
                                  )
                                    b.endTime = change.target.value;
                                });
                              });
                              this.props.onChange(input);
                            }}
                            input={<OutlinedInput labelWidth={73} />}
                          >
                            {this.getTimes()}
                          </Select>
                        </FormControl>
                        <PromoConsumeSection
                          consumeData={this.props.consumeData}
                            liveConsumeData= {this.props.liveConsumeData}
                            onUpdateUserConsume={id=>{this.props.onUpdateUserConsume(id)}}
                          userID={this.props.users.filter(
                            z => z.name === key.split("/")[1]
                          )[0].id}
                        />
                      </div>
                    ) : null}
                  </div>
                )
              )}
            </ul>
          )}
        </TreeMenu>
        <div style={{ width: "100%", marginTop: 10 }}>
          <PromotionsMaps
            markers={this.props.input || []}
            users={this.props.users.filter(z => z.homelocation !== null)}
            liveConsumeData= {this.props.liveConsumeData}
          />
        </div>
      </div>
    );
  }
}
export default withRouter(PromotionsPromoters);
