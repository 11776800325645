import React from "react";
import { isMobile } from "react-device-detect";
import Background from "./bglogin.jpg";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";

class SignInMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      pin: "",
      status: ""
    };
  }
  async signin(email, pin1) {
    // if (phone.substr(1,phone.length))
      this.setState({ status: "Signing you in..." });
    var username = null;

    try {
      let s = await Auth.signIn({
        username: email,
        password: pin1
      });
      if (s) {

        this.props.history.push("/");
        window.location.reload();
      }
    } catch (e) {
      this.setState({ status: e.message });
    }

    // .then(result => {
    //   // this.props.testprop()
    //   // this.props.history.push("/mainpage");
    //   // render={()=>{<Redirect to="/mainpage">}}
    // })
    // .catch(result => this.setState({ status: result.message }));
    //
    return username;
  }

componentDidMount(){
    if (isMobile) this.props.history.push("/signinmobile");
}
  render() {
    return (
      <div style={{   backgroundImage: `url(${Background})`, height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", marginTop: 0, padding :0}}>
    <div style={{display: "flex", justifyContent: 'center'}}>
      <div
        style={{ color: "#374799", width: "20%" }}
        >

        <TextField
          style={{ color: "#fff", minWidth: "100%" , marginTop: "60%", backgroundColor: "#eee"}}
          id="outlined-name"
          label="Email"
          value={this.state.phone}
          onChange={change => {
            this.setState({ phone: change.target.value });
          }}
          onKeyDown={change => {
            // console.log(change.keyCode)
            if (change.keyCode === 13)
            this.signin(this.state.phone.trim(), this.state.pin.trim());

          }}
          margin="normal"
          variant="outlined"
          />
        <TextField
          style={{ color: "#374799", minWidth: "100%" ,backgroundColor: "#eee"}}
          id="outlined-password-input"
          type="password"
          label="Password"
          value={this.state.pin}
          onKeyDown={change => {
            // console.log(change.keyCode)
            if (change.keyCode === 13)
            this.signin(this.state.phone.trim(), this.state.pin.trim());

          }}
          onChange={change => {
            this.setState({ pin: change.target.value });
          }}
          margin="normal"
          variant="outlined"
          />
        <div style ={{display:'flex', flexDirection:'row'}}>
          <Button
            style={{ background: "#03a9fc", width: "100%" , margin :5}}
            variant="contained"
            color="primary"
            onClick={() => {
              this.signin(this.state.phone.trim(), this.state.pin.trim());
            }}
            >
            Sign in
          </Button>
          <Button
            style={{ background: "#03a9fc", width: "100%" , margin :5}}
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push("/forgot")}
            >
            Forgot
          </Button>
        </div>
        <div style={{color: "#fff" , fontSize: 30}}>

          {this.state.status}
        </div>
    </div>
      </div>
      </div>
    );
  }
}

export default withRouter(SignInMobile);
