import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import './index.css';
import { isIOS} from "react-device-detect";
import 'whatwg-fetch';
import App from './App';
import * as serviceWorker from './serviceWorker';
Sentry.init({dsn: "https://03677928e412497b8dd0fd64039a8c0c@sentry.io/1542006"});
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
  if (!isIOS) serviceWorker.register();
