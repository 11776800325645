import React from "react";
// import Utils from "./utils";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class Desktop extends React.Component {
  simpleCount(input, type) {
    var output = 0;
    input.responses.forEach(function(e) {
      e.response.forEach(function(r) {
        if (r.question === "Saletype" && r.response === type) output += 1;
      });
    });
    return output;
  }

  makePromoterBody(input) {
    // var schedules = [];
    var stores = [];
    var stores2 = [];
    input.forEach(function(e) {
      e.promotionScheduler.items.forEach(i => {
        stores.push(i.location.address);
      });
    });

    stores = [...new Set(stores)];
    stores.forEach(a => {
      stores2.push({ storename: a, responses: [] });
    });
    stores2.forEach(a => {
      input.forEach(function(e) {
        e.promotionScheduler.items.forEach(i => {
          if (a.storename === i.location.address)
            a.responses = a.responses.concat(i.responses.items);
        });
      });
    });

    stores2.forEach(a => {
      a.products = a.responses.map(b => {
        return b.response.filter(c => c.question === "product")[0].response;
      });
      a.productsUniques = [...new Set(a.products)];

      var maxcounter = 0;
      var maxcountername = "";
      a.productsUniques.forEach(b => {
        var counter = 0;
        a.products.forEach(d => {
          if (b === d) counter += 1;
        });
        if (counter > maxcounter) {
          maxcounter = counter;
          maxcountername = b;
        }
      });
      a.products = maxcountername;
      delete a.productsUniques;
    });

    return stores2.map((item, index) => (
      <TableRow key={index} style={{ flexWrap: "wrap" }}>
        <TableCell>
          <div>{" " + item.storename.split(",")[0]}</div>
        </TableCell>
        <TableCell >
          <div>{this.simpleCount(item, "Sale")} </div>
        </TableCell>
        <TableCell>
          <div>{this.simpleCount(item, "Taste")}</div>
        </TableCell>
        <TableCell>
          <div>{item.products}</div>
        </TableCell>
      </TableRow>
    ));
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow style={{ flexWrap: "wrap" }}>
              <TableCell>Store name</TableCell>
              <TableCell>Sales</TableCell>
              <TableCell>Tasters</TableCell>
              <TableCell>Top product</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.makePromoterBody(this.props.data)}</TableBody>
        </Table>
      </div>
    );
  }
}
export default Desktop;
