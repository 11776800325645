import React from "react";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";

class PromotionsQuestionsPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  updateQuestion(question, index, newItemValue) {
    var oldQuestions = this.props.input;
    oldQuestions[index][question] = newItemValue;
    this.setState({ questions: oldQuestions });
    this.props.updater(oldQuestions);
  }

  makeQuestionRows(input) {
    return input
      .sort((a, b) => a.order - b.order)
      .map((item, index) => {
        return (
          <TableRow key={index} style={{}}>
            <TableCell style={{ padding: 0, width: 180 }}>
              <FormControl
                variant="outlined"
                style={{ width: 200, marginTop: 8 }}
              >
                <InputLabel>Photo type</InputLabel>
                <Select
                  value={item.repeating}
                  onChange={change => {
                    this.updateQuestion(
                      "repeating",
                      index,
                      change.target.value
                    );
                  }}
                  input={<OutlinedInput labelWidth={100} />}
                >
                  <MenuItem value={false}> Once off</MenuItem>
                  <MenuItem value={true}> Repeating</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell style={{ padding: 0, width: 250 }}>
              <TextField
                style={{ color: "#374799", width: 400 }}
                id="outlined-name"
                label="Name of photo"
                value={item.name}
                onChange={change => {
                  this.updateQuestion("name", index, change.target.value);
                }}
                margin="normal"
                variant="outlined"
              />
            </TableCell>

            <TableCell>
              <Button
                style={{}}
                variant="contained"
                color="primary"
                onClick={() => {
                  var oldQuestions = this.props.input;
                  oldQuestions.splice(index, 1);
                  this.props.updater(oldQuestions);
                }}
              >
                Delete
              </Button>
              <DownIcon
                style={{}}
                onClick={() => {
                  var oldQuestions = this.props.input;
                  if (index < this.props.input.length - 1) {
                    oldQuestions[index].order = oldQuestions[index].order + 1;
                    oldQuestions[index + 1].order =
                      oldQuestions[index + 1].order - 1;
                  }

                  this.props.updater(oldQuestions);
                }}
              />
              <UpIcon
                onClick={() => {
                  var oldQuestions = this.props.input;
                  if (index > 0) {
                    oldQuestions[index].order = oldQuestions[index].order - 1;
                    oldQuestions[index - 1].order =
                      oldQuestions[index - 1].order + 1;
                  }

                  this.props.updater(oldQuestions);
                }}
              />
            </TableCell>
          </TableRow>
        );
      });
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  render() {
    return (
      <div>
        <Table>
          <TableBody style={{ padding: 0 }}>
            {this.makeQuestionRows(this.props.input || null)}
          </TableBody>
        </Table>
        <Button
          style={{ width: 200 }}
          variant="contained"
          color="primary"
          onClick={() => {
            var oldQuestions = this.props.input;
            var maxorder = 0;
            oldQuestions.forEach(a => {
              if (a.order > maxorder) maxorder = a.order;
            });
            oldQuestions.push({
              name: "",
              repeating: true,
              order: maxorder + 1
            });
            this.setState({ questions: oldQuestions });
          }}
        >
          Add another question
        </Button>
      </div>
    );
  }
}
export default withRouter(PromotionsQuestionsPhotos);
