import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Auth } from "aws-amplify";
import Utils from "./utils";

 import Amplify from "aws-amplify";
 import aws_exports from "./aws-exports"; // if you are using Amplify CLI

 Amplify.configure(aws_exports);

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      pin: "",
      pin2: "",
      error: true,
      status: ""
    };
  }

  async signup(phone, pin1, pin2) {
    // if (phone.substr(1,phone.length))
    Utils.log(phone);
    Utils.log(pin1);
    Utils.log(pin2);
    try {

      let s = await Auth.signUp({
        username: phone,
        password: pin1,
        attributes: {
          email:phone // optional
        }
        // other custom attributes
      });
      Utils.log(s);
    } catch (err) {Utils.log(err)}

    //
  }

  render() {
    return (
      <div
        style={{ color: "#374799", margin: 5, marginLeft: 10, marginRight: 10 }}
      >
        <TextField
          style={{ color: "#374799", minWidth: "100%" }}
          id="outlined-name"
          label="Email"
          value={this.state.phone}
          onChange={change => {
            this.setState({ phone: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          style={{ color: "#374799", minWidth: "100%" }}
          id="outlined-password-input"
          type="password"
          label="Password"
          value={this.state.pin}
          onChange={change => {
            this.setState({ pin: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          style={{ color: "#374799", minWidth: "100%" }}
          error={this.state.error}
          id="outlined-password-input"
          label="Confirm Password"
          type="password"
          value={this.state.pin2}
          onChange={change => {
            this.setState({ pin2: change.target.value });

            if (change.target.value.trim() === this.state.pin.trim())
              this.setState({ error: false });
            else this.setState({ error: true });
          }}
          margin="normal"
          variant="outlined"
        />
        <Button
          style={{ background: "#03a9fc", marginTop: 15, width: "100%" }}
          variant="contained"
          color="primary"
          onClick={() =>
            this.signup(
              this.state.phone.toLowerCase().trim(),
              this.state.pin.trim(),
              this.state.pin2.trim()
            )
          }
        >
          Sign up
        </Button>
        {this.state.status}
      </div>
    );
  }
}

export default SignUp;
