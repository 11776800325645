import React from "react";
import { withRouter } from "react-router-dom";
// import Utils from "./utils";
import GoogleMapReact from "google-map-react";
import Place from '@material-ui/icons/MyLocation';
import Tooltip from '@material-ui/core/Tooltip';

const AnyReactComponent = ({ text }) => (
  <Tooltip title={text} enterTouchDelay = {700}>

    <Place style={{marginLeft: -11, marginTop: -11, color: "#f00"}}/>
  </Tooltip>

);

class PromotionsMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  makeMapMarkers(input) {
    var output = [];
    input.forEach(function(e) {
      if (e.promoterLocation.geo) output.push(e);
    });
     // Utils.log("output");
     // Utils.log(output);
    if (output.length > 0)
      return output.map((item, index) => (
        <AnyReactComponent
          key={index}
          lat={item.promoterLocation.geo.lat}
          lng={item.promoterLocation.geo.lng}
          text={item.promoterName}
        />
      ));
    else return null;
  }

  render() {
    // Utils.log("this.props.markers")
    // Utils.log(this.props.markers)
    return (
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDqX0qSKZ-GHCA-_7FFmtAsFtC_INd9UR4"
          }}
          defaultCenter={{
            lat: -26.04731,
            lng: 28.095623
          }}
          defaultZoom={11}
        >

      {this.makeMapMarkers(this.props.markers)}
        </GoogleMapReact>
      </div>
    );
  }
}
export default withRouter(PromotionsMaps);
