import React from "react";
import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import TreeMenu from "react-simple-tree-menu";

class ModalImage extends React.Component {
  render() {
    let data = [];
    if (this.props.data.length > 0) {
      data = this.props.data[0].items;
      data.forEach(z => {
        z.key = z.item;
        let total = 0;
        z.amounts.forEach(t => (total += t.amount));
        let usage = z.amounts.filter(t=>t.type==="consumed")
        let totalusage = 0;
        usage.forEach(t => (totalusage += t.amount));
        let usagerate = parseFloat(totalusage) / parseFloat(usage.length)
        if (isNaN(usagerate)) usagerate =0.00
        let runout = ""
        if (usagerate<0) runout = total/usagerate*-1
        z.label = z.item + "       Balance: " + total + (runout!==""?" Runout in "+runout.toFixed(1)+" days":"");
        z.nodes = z.amounts;
        z.nodes.forEach(x => {
          x.key = x.timestamp + " " + x.amount + " " + x.type;
          x.label =
            "Date: " +
            x.timestamp +
            "      Change: " +
            x.amount +
            " (" +
            x.type +
            ")";
        });
      });
    }
    return (
      <Modal
        style={{
          content: {
            width: "70%",
            height: "70%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }
        }}
        maxWidth="80%"
        isOpen={this.props.isOpen}
        ariaHideApp={false}
      >
        <CloseIcon
          style={{
            position: "sticky",
            left: 10,
            top: 10,
            marginLeft: "90%",
            fontSize: 60,
            color: "#666",
            justifyContent: "flex-end"
          }}
          onClick={() => this.props.closeMe()}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <TreeMenu data={data} />
        </div>
      </Modal>
    );
  }
}
export default ModalImage;
