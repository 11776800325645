import React from "react";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
// import { isMobile } from "react-device-detect";
import Utils from "./utils";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import IntegrationAutosuggest from "./autosuggest";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
// import PromotionsMaps from "./promotionsmaps";
import StartCampIcon from "@material-ui/icons/FlashOn";
import DateIcon from "@material-ui/icons/DateRange";
import QuestionIcon from "@material-ui/icons/LiveHelp";
import PhotoIcon from "@material-ui/icons/PhotoCamera";
import CupIcon from "@material-ui/icons/LocalCafe";
import LocationIcon from "@material-ui/icons/AddLocation";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import PromotionsPromoters from "./promotionspromoters2";
import SearchFilter from "./searchfilter";
import PromotionsQuestions from "./promotionsquestions";
import PromotionsQuestionsPhotos from "./promotionsquestionsphotos";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SwatchesPicker } from "react-color";
import Button from "@material-ui/core/Button";
// import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";

const AnyReactComponent = ({ text }) => (
  <div style={{ fontSize: 20 }}>{text}</div>
);

class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5,
          marginRight: 10
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    var date = new Date(Date.now());
    date.setHours(date.getHours() + 2);
    this.state = {
      statusbarstayopen: false,
      promotionName: "",
      startDate: date,
      endDate: date,
      startTime: "07:00",
      endTime: "17:00",
      client: "",
      productline: "",
      products: "",
      fixedAssetChips: ["Table", "Coffee Machine", "Charger"],
      fixedAssetChipText: "",
      consumableAssetChips: ["Cup", "Spoon", "BSugar", "WSugar"],
      consumableAssetPattern: ["1", "1", "0", "1"],
      consumableAssetChipText: "",
      consumablehistory: [],
      consumableAdjustments: [],
      consumablehistoryheader: [],
      promoters: [],
      users: [],
      allclients: [],
      photos: [
        { name: "Sign in", repeating: false, order: 0 },
        { name: "Promoter", repeating: false, order: 1 },
        { name: "Stand", repeating: false, order: 2 },
        { name: "Other photos", repeating: true, order: 3 }
      ],
      questions: [
        {
          question: "Saletype",
          type: "toggle",
          items: "Sale,Taste"
        },
        {
          question: "product",
          type: "toggle",
          items: "This will come from the promotion details section"
        },
        {
          question: "repeattype",
          type: "toggle",
          items: "First time, Repeat"
        },
        {
          question: "carttype",
          type: "toggle",
          items: "Basket, Trolley, None"
        },
        {
          question: "gender",
          type: "toggle",
          items: "Female, Other, Male"
        },
        {
          question: "race",
          type: "toggle",
          items: "Asian, Black, Coloured, Indian, White"
        },
        {
          question: "taste",
          type: "multi",
          items: "Sour, Salty, Sweet, Bitter, Spicy"
        },
        {
          question: "age",
          type: "toggle",
          items: "16-20, 21-30, 31-50, 51+"
        },
        {
          type: "additionalQuestions",
          question: "additionalQuestions",
          items: "How much did the customer ...?"
        },
        {
          type: "additionalQuestions",
          question: "additionalQuestions",
          items: "How much did the customer ...?"
        }
      ],
      allproductlines: [],
      colours: {
        background: "#fff",
        backgroundfont: "#000",
        inactivebackground: "#90a4ae",
        inactivefont: "#000",
        activebackground: "#0d47a1",
        activefont: "#fff"
      },
      status: "",
      consumableSetup: [],
      locations: [],
      edit: false,
      editid: "",
      label: "",
      alert: ""
    };
  }
  doSnackBar(input, statusbarstayopen) {
    this.setState({ statusbar: true, statusbartext: input, statusbarstayopen });
  }

  validationCheck() {
    var valid = true;
    if (this.state.promoters.length === 0) {
      this.doSnackBar("No promoters added...");
      valid = false;
    } else if (this.state.questions.length === 0) {
      this.doSnackBar("No questions added...");
      valid = false;
    } else if (this.state.promotionName === "") {
      this.doSnackBar("No promotion name...");
      valid = false;
    } else if (this.state.client === "") {
      this.doSnackBar("No client selected...");
      valid = false;
    } else if (this.state.productline === "") {
      this.doSnackBar("No productline selected...");
      valid = false;
    } else if (this.state.products === "") {
      this.doSnackBar("No products selected...");
      valid = false;
    }
    var d1 =
      this.state.startDate.toISOString().substr(0, 10) +
      "T00:00:00.000Z"
      ;
    var sd1 = new Date(d1);
    var d2 =
      this.state.endDate.toISOString().substr(0, 10) + "T23:59:59.000Z"  ;
    var ed1 = new Date(d2);
    if (ed1 < sd1) {
      this.doSnackBar("Campaign dates invalid");
      valid = false;
    }

    this.state.promoters.forEach((z,idx) => {
      if (typeof z.promoterLocation.geo === "undefined") {
        this.doSnackBar(
          "Promoter " + z.promoterName + idx+" location invalid",
          true
        );
        valid = false;
      }
      var d3 = z.promoterStartDate + "T" + z.promoterStartTime;
      var sd2 = new Date(d3);
      if (sd2 < sd1 || sd2 > ed1) {
        this.doSnackBar(
          "Promoter " + z.promoterName + " dates not within campaign dates",
          true
        );
        valid = false;
      }
    });

    return valid;
  }

  async saveConsumeAdjustments() {
    var s = this.state.consumableAdjustments;
    s.forEach(z => {
      z.usage = JSON.stringify([{ item: z.element, amount: -z.value }]);
      delete z.element;
      delete z.value;
      z.scheduleID = "adjustment";
      z.promoterPlusConsumedConsumableIDId = this.state.editid;
      z.timestamp = new Date(Date.now());
      z.userID = z.id;
      delete z.id;
    });
    this.doSnackBar("Saving please wait...");
    await Promise.all(s.map(Utils.createConsumeResponse));
    this.doSnackBar("Refreshing...");
    this.getConsumableData();
  }

  async getConsumableData() {
    if (!this.state.editid) {
      console.log(this.state.promoters);
      var promoterids = this.state.promoters.map(a => a.promoterId);
      promoterids = [...new Set(promoterids)];
      var items = [];
      promoterids.forEach(z => {
        items.push({
          userID: z,
          scheduleID: "new",
          usage: [],
          timestamp: new Date(Date.now())
        });
      });
    }
    if (this.state.editid) {
      this.doSnackBar("Collecting transactions",true);
      let con = await Utils.getConsumableData(this.state.editid, null);
      items = [];
      items = items.concat(con.usage.items);
      while (con.usage.nextToken !== null) {
        this.doSnackBar("Collecting transactions",true);
        con = await Utils.getConsumableData(
          this.state.editid,
          con.usage.nextToken
        );
        items = items.concat(con.usage.items);
      }
    }
    promoterids = this.state.promoters.map(a => a.promoterId);
    promoterids = [...new Set(promoterids)];
    var oldids = [];
    items.forEach(z => {
      oldids.push(z.userID);
    });
    oldids = [...new Set(oldids)];
    var missing = [];
    promoterids.forEach(a => {
      var found = false;
      oldids.forEach(b => {
        if (a === b) found = true;
      });
      if (!found) missing.push(a);
    });
    missing = [...new Set(missing)];
    missing.forEach(z => {
      items.push({ userID: z, scheduleID: "new", usage: [] });
    });

    var newitems = [];
    items.forEach(z => {
      if (
        newitems.filter(
          a => a.userID === z.userID && a.scheduleID === z.scheduleID
        ).length === 0
      ) {
        newitems.push(z);
      } else {
        newitems.forEach(a => {
          if (a.userID === z.userID && a.scheduleID === z.scheduleID) {
            a.usage = a.usage.concat(z.usage);
          }
        });
      }
    });

    newitems.forEach(z => {
      z.timestamp = new Date(z.timestamp);
      var result = Object.values(
        z.usage.reduce((c, { item, amount }) => {
          c[item] = c[item] || { item, amount: 0 };
          c[item].amount += amount;
          return c;
        }, {})
      );
      result.forEach(p => {
        z[p.item] = p.amount;
      });
      delete z.usage;
      delete z.id;
      delete z.scheduleID;
    });
    items = newitems;

    items.forEach(z => {
      z.userID = this.state.users.filter(p => p.id === z.userID)[0];
    });
    newitems = newitems.sort((a, b) => {
      if (
        a.userID.name + a.timestamp.toDateString() <
        b.userID.name + b.timestamp.toDateString()
      )
        return 1;
      else if (
        a.userID.name + a.timestamp.toDateString() >
        b.userID.name + b.timestamp.toDateString()
      )
        return -1;
      else return 0;
    });

    var consumablehistoryheader = this.state.fixedAssetChips.concat(
      this.state.consumableAssetChips
    );

    var itemswithtotals = [];
    var total = {};
    // console.log(JSON.stringify(items,null,4))

    items.forEach((z, i) => {
      total.timestamp = new Date(Date.now());
      consumablehistoryheader.forEach(a => {
        if (isNaN(z[a]) || typeof z[a] === "undefined") z[a] = 0;
        z[a] = -z[a];
        if (typeof total[a] === "undefined") total[a] = z[a];
        else total[a] += z[a];
        if (typeof total[a] === "undefined") total[a] = 0;
        if (isNaN(total[a])) total[a] = 0;
      });
      itemswithtotals.push(z);

      if (i === items.length - 1) {
        total.userID = z.userID;
        total.isTotal = true;
        itemswithtotals.push(total);
        total = {};
      } else if (z.userID.id !== items[i + 1].userID.id) {
        total.userID = z.userID;
        total.isTotal = true;
        itemswithtotals.push(total);
        total = {};
      }
    });
    // console.log(JSON.stringify(items,null,4))
    itemswithtotals.forEach((z, i) => {
      consumablehistoryheader.forEach(a => {
        if (isNaN(z[a])) z[a] = 0;
      });
    });

    this.setState({
      consumablehistory: itemswithtotals,
      consumablehistoryheader
    });
  }

  async saveConsumeSetupSection() {
    await Utils.updateConsumeSetup({
      id: this.state.editid,
      setup: JSON.stringify({
        fixedAsset: this.state.fixedAssetChips,
        consumables: this.state.consumableAssetChips
      }),
      defaultConsumptionPattern: JSON.stringify(
        this.state.consumableAssetPattern
      )
    });
    this.doSnackBar("Saved consumables setup",true);
    this.componentDidMount();
  }

  async deletePromoterPlusPromotion(input) {
    // Utils.log(this.props.location.state.id)
    let s = await Utils.deletePromoterPlusPromotion(
      this.props.history.location.search.replace("?edit=", "")
    );
    // console.log(s);

    for (var i = 0; i < s.promotionScheduler.items.length; i++) {
      await Utils.deletePromoterPlusSchedule(s.promotionScheduler.items[i].id);
    }

    this.props.history.push("/");
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser().catch(() =>
      this.props.history.push("/signin")
    );
    // this.doSnackBar("Getting data. Please wait...");
    // if (isMobile) this.props.history.push("/mobile");
    // var date = new Date(Date.now());
    // date.setHours(date.getHours() + 2);
    //
    this.doSnackBar("Getting data...");

    let p = await Auth.currentAuthenticatedUser();

    let t = await Utils.getUserAlwaysOnline(p.username);
    // if (t.label === "All") this.setState({ alert: "DONT DO THIS IN RED MODE" });
    if (t.type !== "Admin") this.props.history.push("/");
    this.setState({ label: t.label });
    let cl = await Utils.getAllClients({ label: { eq: t.label } });
    let pl = await Utils.getAllProductLines({ label: { eq: t.label } });
    this.setState({
      users: await Utils.getAllUsers({
        label: { eq: t.label },
        name: { notContains: "~" }
      }),
      allclients: cl,
      allproductlines: pl
    });
    var promo = {};
    // console.log(this.props.history.location)

    var urltype = this.props.history.location.search;
    // if (urltype.indexOf("edit") > -1) {
    // this.setState({ status: "Getting data. Please wait..." });
    // this.doSnackBar("Getting data. Please wait...");
    promo = await Utils.getPromotionByPromotionIdNEW(
      urltype.replace("?edit=", "")
    );
    // var tt = [];
    // var r = promo.promotionScheduler.items[0];
    // r.promotionid = promo.id
    // r.promoterId = r.user.id
    // r.promoterLocation = r.location
    // r.promoterStartDate = new Date(r.startdate);
    // r.promoterEndDate = new Date(r.enddate);
    // for (var i = 0; i < 3000; i++) {
    //   tt.push(r);
    // }
    // await Promise.all(tt.map(Utils.createPromotionScheduleNORETURN));
    // var scheduleids = [];
    // promo.promotionScheduler.items.forEach(function(s) {
    //   scheduleids.push(s.id);
    // });
    // let users = await Promise.all(scheduleids.map(Utils.getScheduleById));
    // promo.promotionScheduler.items.forEach(function(s) {
    //   users.forEach(function(u) {
    //     if (s.id === u.id) {
    //       s.userid = u.user.id;
    //       s.username = u.user.name;
    //     }
    //   });
    // });
    var promoters = [];
    if (promo.photos === null) promo.photos = JSON.stringify(this.state.photos);
    promo.promotionScheduler.items.forEach(function(s) {
      var item = {};
      item.promoterEndDate = s.enddate.split("T")[0];
      item.promoterEndTime = s.enddate.split("T")[1].substr(0, 5);
      item.promoterStartDate = s.startdate.split("T")[0];
      item.promoterStartTime = s.startdate.split("T")[1].substr(0, 5);
      item.promoterLocation = JSON.parse(s.location);
      if (item.promoterLocation === null)
        item.promoterLocation = { address: "" };
      item.promoterId = s.user.id;
      item.promoterName = s.user.name;
      item.scheduleID = s.id;
      promoters.push(item);
    });

    promoters = promoters.sort((a, b) => {
      if (
        a.promoterName + a.promoterStartDate >
        b.promoterName + b.promoterStartDate
      )
        return 1;
      else if (
        a.promoterName + a.promoterStartDate <
        b.promoterName + b.promoterStartDate
      )
        return -1;
      else return 0;
    });
    let cons = await Utils.getPromoterPlusConsumeSetup(promo.id);
    this.setState({
      promotionName: promo.promotionName,
      startDate: new Date(promo.startdate),
      endDate: new Date(promo.enddate),
      startTime: promo.startdate.split("T")[1].substr(0, 5),
      endTime: promo.enddate.split("T")[1].substr(0, 5),
      client: promo.client.id,
      productline: promo.productLine,
      products: JSON.parse(promo.products),
      promoters: promoters,
      edit: true,
      fixedAssetChips: JSON.parse(cons.setup).fixedAsset,
      consumableAssetChips: JSON.parse(cons.setup).consumables,
      consumableAssetPattern: JSON.parse(cons.defaultConsumptionPattern),
      editid: promo.id,
      questions: JSON.parse(promo.questions),
      photos: JSON.parse(promo.photos),
      colours: JSON.parse(promo.colours)
    });
    // }
    this.setState({ statusbar: false });
  }

  getTimes() {
    return Utils.getTimeOptions().map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));
  }

  makeMapMarkers(input) {
    var output = [];
    input.forEach(function(e) {
      if (e.promoterLocation.geo) output.push(e);
    });
    // Utils.log(this.state.output);
    if (output.length > 0)
      return output.map((item, index) => (
        <AnyReactComponent
          key={index}
          lat={item.promoterLocation.geo.lat}
          lng={item.promoterLocation.geo.lng}
          text={item.name}
        />
      ));
    else return null;
  }

  getNames(input) {
    var output = "";
    input.forEach(function(e, idx) {
      if (idx > 0) output += ", ";
      output += e.name;
    });
    return output;
  }

  getClientNames() {
    return this.state.allclients.map((item, index) => (
      <MenuItem key={index} value={item.id} name={item.name}>
        {item.clientname}
      </MenuItem>
    ));
  }

  getProductNames() {
    return this.state.allproductlines.map((item, index) => (
      <MenuItem key={index} value={item.id} name={item.name}>
        {item.productline}
      </MenuItem>
    ));
  }

  checker(input) {
    // console.log(input);
  }

  async deleteSchedule(input) {
    this.doSnackBar("Deleting. Please wait...");
    await Utils.deletePromoterPlusSchedule(input);
    this.componentDidMount();
  }
  async saveSchedule(input) {
    this.doSnackBar("Saving. Please wait...");
    input.id = input.scheduleID;

    input.promoterLocation = JSON.stringify(input.promoterLocation);
    var t3 = new Date(input.promoterStartDate + "T" + input.promoterStartTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterStartDate = t3;
    delete input.promoterStartTime;
    t3 = new Date(input.promoterEndDate + "T" + input.promoterEndTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterEndDate = t3;
    delete input.promoterEndTime;
    await Utils.updatePromotionSchedule(input);
    this.componentDidMount();
  }

  async saveNewSchedule(input) {
    this.doSnackBar("Saving. Please wait...");
    input.promotionid = this.state.editid;

    input.promoterLocation = JSON.stringify(input.promoterLocation);
    var t3 = new Date(input.promoterStartDate + "T" + input.promoterStartTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterStartDate = t3;
    delete input.promoterStartTime;
    t3 = new Date(input.promoterEndDate + "T" + input.promoterEndTime);
    t3.setHours(t3.getHours() + 2);
    input.promoterEndDate = t3;
    delete input.promoterEndTime;
    await Utils.createPromotionSchedule(input);
    this.componentDidMount();
  }

  async savePromotion(input) {
    // if (this.state.edit) {
    input.id = this.state.editid;
    await Utils.updatePromotion(input);
    var newpromoters = [];
    var existingpromoters = [];
    // var listcurrent = [];
    // s.promotionScheduler.items.forEach(function(e) {
    //   listcurrent.push(e.id);
    // });
    // listcurrent.forEach(function(e) {
    //   var del = true;
    //   input.promoters.forEach(function(q) {
    //     if (q.scheduleID === e) del = false;
    //   });
    //   if (del) {
    //     Utils.deletePromoterPlusSchedule(e);
    //   }
    // });
    console.log("input");
    console.log(input);
    console.log(this.state.editid);
    input.promoters.forEach(function(e) {
      if (!e.scheduleID) newpromoters.push(e);
      else existingpromoters.push(e);
    });
    // await Utils.getPromotionByPromotionId(
    //   this.props.history.location.search.replace("?edit=", "")
    // );
    newpromoters.forEach(function(e) {
      e.promotionid = this.state.editid;
      e.promoterLocation = JSON.stringify(e.promoterLocation);
      var t3 = new Date(e.promoterStartDate + "T" + e.promoterStartTime);
      t3.setHours(t3.getHours() + 2);
      e.promoterStartDate = t3;
      delete e.promoterStartTime;
      t3 = new Date(e.promoterEndDate + "T" + e.promoterEndTime);
      t3.setHours(t3.getHours() + 2);
      e.promoterEndDate = t3;
      delete e.promoterEndTime;
    });

    existingpromoters.forEach(function(e) {
      e.id = e.scheduleID;
      e.promoterLocation = JSON.stringify(e.promoterLocation);
      var t3 = new Date(e.promoterStartDate + "T" + e.promoterStartTime);
      t3.setHours(t3.getHours() + 2);
      e.promoterStartDate = t3;
      delete e.promoterStartTime;
      t3 = new Date(e.promoterEndDate + "T" + e.promoterEndTime);
      t3.setHours(t3.getHours() + 2);
      e.promoterEndDate = t3;
      delete e.promoterEndTime;
    });
    console.log(newpromoters.length);
    console.log(existingpromoters.length);
    await Promise.all(newpromoters.map(Utils.createPromotionSchedule));

    await Promise.all(existingpromoters.map(Utils.updatePromotionSchedule));
    await Utils.updateConsumeSetup({
      id: this.state.editid,
      setup: JSON.stringify({
        fixedAsset: this.state.fixedAssetChips,
        consumables: this.state.consumableAssetChips
      }),
      defaultConsumptionPattern: JSON.stringify(
        this.state.consumableAssetPattern
      )
    });
    // this.setState({ status: "Saved" });

    this.setState({ statusbar: false });

    //this.props.history.push("/");
    // } else {
    //   // Utils.log("creationinput");
    //   // Utils.log(input);
    //   let s = await Utils.createPromotion(input);
    //   // Utils.log("s");
    //   // Utils.log(s);
    //   input.promoters.forEach(function(e) {
    //     e.promotionid = s.id;
    //     e.promoterLocation = JSON.stringify(e.promoterLocation);
    //     var t3 = new Date(e.promoterStartDate + "T" + e.promoterStartTime);
    //     t3.setHours(t3.getHours() + 2);
    //     e.promoterStartDate = t3;
    //     delete e.promoterStartTime;
    //     t3 = new Date(e.promoterEndDate + "T" + e.promoterEndTime);
    //     t3.setHours(t3.getHours() + 2);
    //     e.promoterEndDate = t3;
    //     delete e.promoterEndTime;
    //   });
    //
    //   // Utils.log("input.promoters");
    //   // Utils.log(input.promoters);
    //   // let f = await Utils.createPromotionSchedule(input.promoters[0]);
    //   // Utils.log(f);
    //   const promises = input.promoters.map(Utils.createPromotionSchedule);
    //   await Promise.all(promises);
    //   await Utils.createConsumeSetup({
    //     id: s.id,
    //     setup: JSON.stringify({
    //       fixedAsset: this.state.fixedAssetChips,
    //       consumables: this.state.consumableAssetChips
    //     }),
    //     defaultConsumptionPattern: JSON.stringify(
    //       this.state.consumableAssetPattern
    //     )
    //   });
    //
    //   var sw = this.state.consumableAdjustments;
    //   sw.forEach(z => {
    //     z.usage = JSON.stringify([{ item: z.element, amount: -z.value }]);
    //     delete z.element;
    //     delete z.value;
    //     z.scheduleID = "adjustment";
    //     z.promoterPlusConsumedConsumableIDId = s.id;
    //     z.timestamp = new Date(Date.now());
    //     z.userID = z.id;
    //     delete z.id;
    //   });
    //   // this.doSnackBar("Saving please wait...");
    //   await Promise.all(sw.map(Utils.createConsumeResponse));
    //
    //   // Utils.log(results);
    //   // Utils.log("done");
    //   this.setState({ promoters: [] });
    //   // this.setState({ status: "Saved" });
    //
    //   this.doSnackBar("Saved");
      this.props.history.push("/");
    // }
  }

  render() {
    // console.log(this.state.promoters)
    return (
      <div style={{ color: "#666" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.statusbar}
          autoHideDuration={this.state.statusbarstayopen ? 3000 : null}
          onClose={() => this.setState({ statusbar: false })}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.statusbartext}</span>}
        />
        <Paper style={{ margin: 10, backgroundColor: "#ddd" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: 10,
              marginRight: 10
            }}
          >
            <h1
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <PIcon
                icon={<StartCampIcon style={{ fontSize: 37 }} />}
                size="40px"
                colour="#7ed221"
              />
              Start Campaign
            </h1>
          </div>
        </Paper>
        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
                // justifyContent: "center",
                // backgroundColor: "#fff",
                // color: "#000",
                // borderRadius: 5,
                // marginRight: 10
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <DateIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Promotion details</h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div style={{ width: "100%" }}>
                <TextField
                  style={{ color: "#374799" }}
                  id="outlined-name"
                  label="Promotion Name"
                  value={this.state.promotionName}
                  onChange={change => {
                    this.setState({ promotionName: change.target.value });
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <br />
                <TextField
                  style={{ color: "#374799" }}
                  id="outlined-name"
                  label="Start date"
                  value={this.state.startDate.toISOString().substr(0, 10)}
                  onChange={change => {
                    var date = new Date(change.target.value + "T02:00:00");
                    this.setState({ startDate: date });
                  }}
                  type="date"
                  margin="normal"
                  variant="outlined"
                />

                <FormControl variant="outlined" style={{ marginTop: 16 }}>
                  <InputLabel>Start time</InputLabel>
                  <Select
                    value={this.state.startTime}
                    onChange={change =>
                      this.setState({ startTime: change.target.value })
                    }
                    input={<OutlinedInput labelWidth={73} />}
                  >
                    {this.getTimes()}
                  </Select>
                </FormControl>

                <TextField
                  style={{ color: "#374799" }}
                  id="outlined-name"
                  label="End date"
                  value={this.state.endDate.toISOString().substr(0, 10)}
                  onChange={change => {
                    var date = new Date(change.target.value + "T02:00:00");

                    this.setState({ endDate: date });
                  }}
                  type="date"
                  margin="normal"
                  variant="outlined"
                />
                <FormControl variant="outlined" style={{ marginTop: 16 }}>
                  <InputLabel>End time</InputLabel>
                  <Select
                    value={this.state.endTime}
                    onChange={change =>
                      this.setState({ endTime: change.target.value })
                    }
                    input={<OutlinedInput labelWidth={73} />}
                  >
                    {this.getTimes()}
                  </Select>
                </FormControl>
                <br />
                <FormControl
                  variant="outlined"
                  style={{ padding: 0, margin: 0, width: 222 }}
                >
                  <InputLabel>Client name</InputLabel>
                  <Select
                    value={this.state.client || ""}
                    onChange={change => {
                      // Utils.log(change.target);
                      this.setState({ client: change.target.value });
                    }}
                    input={<OutlinedInput labelWidth={123} />}
                  >
                    {this.getClientNames()}
                  </Select>
                </FormControl>
                <br />
                <FormControl
                  variant="outlined"
                  style={{ padding: 0, marginTop: 10, width: 222 }}
                >
                  <InputLabel>Product Line</InputLabel>
                  <Select
                    value={this.state.productline || ""}
                    onChange={change => {
                      var prods = "";
                      this.state.allproductlines.forEach(function(e) {
                        if (e.id === change.target.value) prods = e.products;
                      });
                      // Utils.log(change.target.value)
                      this.setState({
                        productline: change.target.value,
                        products: prods
                      });
                    }}
                    input={<OutlinedInput labelWidth={123} />}
                  >
                    {this.getProductNames()}
                  </Select>
                </FormControl>
                <br />
                <TextField
                  style={{ color: "#374799", marginTop: 10, width: 600 }}
                  id="outlined-name"
                  multiline
                  label="Products"
                  value={this.state.products}
                  disabled={true}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    marginRight: 20,
                    color: this.state.colours.backgroundfont,
                    backgroundColor: this.state.colours.background
                  }}
                >
                  Sample Colour Choice
                  <br />
                  <Button
                    style={{
                      backgroundColor: this.state.colours.inactivebackground,
                      color: this.state.colours.inactivefont
                    }}
                    variant="contained"
                  >
                    Inactive
                  </Button>
                  <Button
                    style={{
                      backgroundColor: this.state.colours.activebackground,
                      color: this.state.colours.activefont
                    }}
                    variant="contained"
                  >
                    Active
                  </Button>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>
                  Background colour:{" "}
                  <SwatchesPicker
                    width="600"
                    height="200"
                    onChange={change => {
                      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                        change.hex
                      );
                      var L =
                        0.2126 * parseInt(result[1], 16) +
                        0.7152 * parseInt(result[2], 16) +
                        0.0722 * parseInt(result[3], 16);
                      var fontcolour = "#ffffff";
                      // Utils.log(L);
                      if (L > 128) fontcolour = "#000000";
                      var oldColour = this.state.colours;
                      oldColour.background = change.hex;
                      oldColour.backgroundfont = fontcolour;
                      this.setState({ colours: oldColour });
                    }}
                    color={this.state.colours.background}
                  />
                  Inactive button colour:
                  <SwatchesPicker
                    width="600"
                    height="200"
                    color={this.state.colours.inactivebackground}
                    onChange={change => {
                      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                        change.hex
                      );
                      var L =
                        0.2126 * parseInt(result[1], 16) +
                        0.7152 * parseInt(result[2], 16) +
                        0.0722 * parseInt(result[3], 16);
                      var fontcolour = "#ffffff";
                      // Utils.log(L);
                      if (L > 128) fontcolour = "#000000";
                      var oldColour = this.state.colours;
                      oldColour.inactivebackground = change.hex;
                      oldColour.inactivefont = fontcolour;
                      this.setState({ colours: oldColour });
                    }}
                  />
                  Active colour:{" "}
                  <SwatchesPicker
                    width="600"
                    height="200"
                    color={this.state.colours.activebackground}
                    onChange={change => {
                      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                        change.hex
                      );
                      var L =
                        0.2126 * parseInt(result[1], 16) +
                        0.7152 * parseInt(result[2], 16) +
                        0.0722 * parseInt(result[3], 16);
                      var fontcolour = "#ffffff";
                      // Utils.log(L);
                      if (L > 128) fontcolour = "#000000";
                      var oldColour = this.state.colours;
                      oldColour.activebackground = change.hex;
                      oldColour.activefont = fontcolour;
                      this.setState({ colours: oldColour });
                    }}
                  />
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
                // justifyContent: "center",
                // backgroundColor: "#fff",
                // color: "#000",
                // borderRadius: 5,
                // marginRight: 10
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <QuestionIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Questions ({this.state.questions.length})</h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PromotionsQuestions
              input={this.state.questions}
              updater={input => this.setState({ questions: input })}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
                // justifyContent: "center",
                // backgroundColor: "#fff",
                // color: "#000",
                // borderRadius: 5,
                // marginRight: 10
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <PhotoIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Photos ({this.state.photos.length})</h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PromotionsQuestionsPhotos
              input={this.state.photos}
              updater={input => {
                // console.log(input)
                this.setState({ photos: input });
              }}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <LocationIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Promoters ({this.state.promoters.length})</h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}
            >
            <div>Max 500</div>
              <SearchFilter
                users={this.state.users}
                input={this.state.promoters}
                startTime={this.state.startTime}
                endTime={this.state.endTime}
                onChange={input => this.setState({ promoters: input })}
                title={"Add promoters"}
              />

              <div style={{ width: "100%" }}>
                <PromotionsPromoters
                  input={this.state.promoters}
                  users={this.state.users}
                  startTime={this.state.startTime}
                  endTime={this.state.endTime}
                  onChange={input => {
                    this.setState({ promoters: input });
                  }}
                  onDelete={id => {
                    this.deleteSchedule(id);
                  }}
                  onSave={id => {
                    // console.log(id);
                    if (typeof id.scheduleID === "undefined")
                      this.saveNewSchedule(id);
                    else this.saveSchedule(id);
                  }}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel style={{ margin: 10, backgroundColor: "#ddd" }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  width: 70,
                  height: 70,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#888",
                  color: "#000",
                  borderRadius: 5,
                  marginRight: 10
                }}
              >
                <CupIcon style={{ fontSize: 60, color: "#fff" }} />
              </div>
              <h2>Consumables</h2>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <h4 style={{ marginRight: 10 }}>Fixed asset</h4>
                <TextField
                  style={{ color: "#374799", marginRight: 10 }}
                  id="outlined-name"
                  label="Add fixed asset"
                  onChange={change => {
                    this.setState({
                      fixedAssetChipText: change.target.value.trim()
                    });
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <Button
                  onClick={() => {
                    var fixedAssetChips = this.state.fixedAssetChips;
                    fixedAssetChips.push(this.state.fixedAssetChipText);
                    this.setState({ fixedAssetChips, fixedAssetChipText: "" });
                  }}
                >
                  <AddIcon />
                </Button>
                {this.state.fixedAssetChips.map((e, i) => (
                  <Chip
                    key={i}
                    label={e}
                    style={{ backgroundColor: "#aaa", marginRight: 10 }}
                    onDelete={() => {
                      var update = this.state.fixedAssetChips;
                      update.splice(i, 1);
                      this.setState({
                        fixedAssetChips: update
                      });
                    }}
                  />
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <h4 style={{ marginRight: 10 }}>Consumables</h4>
                <TextField
                  style={{ color: "#374799", marginRight: 10 }}
                  id="outlined-name"
                  label="Add Consumable"
                  onChange={change => {
                    this.setState({
                      consumableAssetChipText: change.target.value.trim()
                    });
                  }}
                  margin="normal"
                  variant="outlined"
                />
                <Button
                  onClick={() => {
                    var consumableAssetChips = this.state.consumableAssetChips;
                    var updatePattern = this.state.consumableAssetPattern;
                    consumableAssetChips.push(
                      this.state.consumableAssetChipText
                    );
                    updatePattern.push(0);
                    this.setState({
                      consumableAssetChips,
                      consumableAssetChipText: "",
                      consumableAssetPattern: updatePattern
                    });
                  }}
                >
                  <AddIcon />
                </Button>
                {this.state.consumableAssetChips.map((e, i) => (
                  <Chip
                    key={i}
                    label={e}
                    style={{ backgroundColor: "#aaa", marginRight: 10 }}
                    onDelete={() => {
                      var update = this.state.consumableAssetChips;
                      var updatePattern = this.state.consumableAssetPattern;
                      update.splice(i, 1);
                      updatePattern.splice(i, 1);
                      this.setState({
                        consumableAssetChips: update,
                        consumableAssetPattern: updatePattern
                      });
                    }}
                  />
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <h4 style={{ marginRight: 10 }}>Typical consumption pattern</h4>
                {this.state.consumableAssetChips.map((e, i) => (
                  <TextField
                    style={{ color: "#374799", marginRight: 10, width: 80 }}
                    id="outlined-name"
                    key={i}
                    label={e}
                    value={this.state.consumableAssetPattern[i]}
                    onChange={change => {
                      var consumableAssetPattern = this.state
                        .consumableAssetPattern;
                      consumableAssetPattern[i] = change.target.value;
                      this.setState({ consumableAssetPattern });
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                ))}
              </div>
              <Button
                color="primary"
                disabled={!this.state.edit}
                variant="contained"
                onClick={() => {
                  if (this.state.edit) {
                    this.saveConsumeSetupSection();
                  }
                }}
              >
                Save Setup
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center"
                }}
              >
                <h3 style={{ marginRight: 10 }}>
                  Promoters{" "}
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      this.getConsumableData();
                    }}
                  >
                    Get consumables data
                  </Button>
                </h3>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Promoter</TableCell>
                      <TableCell>Date</TableCell>
                      {this.state.consumablehistoryheader.map((e, i) => (
                        <TableCell key={i}>{e}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.consumablehistory.map((e, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            {e.isTotal ? <b>{e.userID.name}</b> : e.userID.name}
                          </TableCell>
                          <TableCell>
                            {e.isTotal ? (
                              <b>{e.timestamp.toDateString()}</b>
                            ) : (
                              e.timestamp.toDateString()
                            )}
                          </TableCell>
                          {this.state.consumablehistoryheader.map(
                            (element, index) => {
                              return (
                                <TableCell key={index}>
                                  {e.isTotal ? (
                                    <div>
                                      <b
                                        style={{
                                          display: "flex",
                                          alignItems: "center"
                                        }}
                                      >
                                        {e[element]}
                                        <TextField
                                          style={{
                                            width: 60,
                                            marginLeft: 5,
                                            fontStyle: "normal"
                                          }}
                                          value={
                                            typeof this.state.consumableAdjustments.filter(
                                              z =>
                                                z.id === e.userID.id &&
                                                z.element === element
                                            )[0] !== "undefined"
                                              ? this.state.consumableAdjustments.filter(
                                                  z =>
                                                    z.id === e.userID.id &&
                                                    z.element === element
                                                )[0].value
                                              : ""
                                          }
                                          label="Adjust"
                                          onChange={change => {
                                            var consumableAdjustments = this
                                              .state.consumableAdjustments;
                                            if (
                                              consumableAdjustments.filter(
                                                z =>
                                                  z.id === e.userID.id &&
                                                  z.element === element
                                              ).length === 0
                                            )
                                              consumableAdjustments.push({
                                                id: e.userID.id,
                                                element,
                                                value: change.target.value
                                              });
                                            else {
                                              consumableAdjustments.forEach(
                                                zz => {
                                                  if (
                                                    zz.id === e.userID.id &&
                                                    zz.element === element
                                                  )
                                                    zz.value =
                                                      change.target.value;
                                                }
                                              );
                                            }
                                            this.setState({
                                              consumableAdjustments
                                            });
                                          }}
                                        />
                                      </b>
                                    </div>
                                  ) : (
                                    e[element]
                                  )}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <Button
                style={{ margin: 10 }}
                color="primary"
                disabled={!this.state.editid}
                variant="contained"
                onClick={() => {
                  this.saveConsumeAdjustments();
                }}
              >
                Save Adjustments
              </Button>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Button
          style={{ margin: 10 }}
          color="primary"
          variant="contained"
          onClick={() => {
            if (this.validationCheck()) {
              this.doSnackBar("Saving...");
              var promotioncomplete = {};
              promotioncomplete.promoters = this.state.promoters;

              promotioncomplete.questions = this.state.questions;
              promotioncomplete.products = JSON.stringify(this.state.products);
              promotioncomplete.questions.forEach(function(e) {
                if (e.question === "product") {
                  e.items = promotioncomplete.products;
                }
              });
              promotioncomplete.questions = JSON.stringify(
                promotioncomplete.questions
              );
              promotioncomplete.photos = JSON.stringify(this.state.photos);
              promotioncomplete.promotionName = this.state.promotionName;
              promotioncomplete.promoterPlusPromotionClientId = this.state.client;
              promotioncomplete.productline = this.state.productline;
              promotioncomplete.colours = JSON.stringify(this.state.colours);
              var t2 =
                this.state.startDate.toISOString().substr(0, 10) +
                "T" +
                this.state.startTime;
              var t3 = new Date(t2);
              t3.setHours(t3.getHours() + 2);
              promotioncomplete.startDate = t3;
              t2 =
                this.state.endDate.toISOString().substr(0, 10) +
                "T" +
                this.state.endTime;
              t3 = new Date(t2);
              t3.setHours(t3.getHours() + 2);
              promotioncomplete.endDate = t3;
              promotioncomplete.label = this.state.label;
              // console.log(JSON.stringify(promotioncomplete, null, 4));

              this.savePromotion(promotioncomplete);
            }
          }}
        >
          Save Promotion
        </Button>

        <Button
          style={{ margin: 10 }}
          color="primary"
          variant="contained"
          disabled={!this.state.edit}
          onClick={() => {
            this.deletePromoterPlusPromotion(
              this.props.history.location.search.replace("?edit=", "")
            );
          }}
        >
          Delete Promotion
        </Button>
        {this.state.status}
      </div>
    );
  }
}
export default withRouter(Promotions);
