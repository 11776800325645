import React, { forwardRef } from "react";
import { withRouter } from "react-router-dom";
import Utils from "./utils";
// import { isMobile } from "react-device-detect";

import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PromotionsMaps from "./promotionsmaps";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import Button from "@material-ui/core/Button";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { FixedSizeList as List } from "react-window";

const AnyReactComponent = ({ text }) => (
  <div style={{ fontSize: 20 }}>{text}</div>
);
const outerElementType = forwardRef((props, ref) => (
  <div ref={ref} proponChange={data=>{
    console.log(data)
  }} {...props} />
));

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = address => {
    // console.log(address)
    this.setState({ address });
    this.props.output({ address: address });
  };

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.output({ address: address, geo: latLng });
        // Utils.log("Success", latLng);
      })
      .catch(error => console.error("Error", error));
  };

  componentDidMount() {
    this.setState({ address: this.props.value });
  }

  render() {
    return (
      <PlacesAutocomplete
        value={typeof this.props.value === "undefined" ? "" : this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          componentRestrictions: { country: "za" }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              style={{ width: 400 }}
              variant="outlined"
              label="Location"
              {...getInputProps({
                placeholder: "",
                className: "location-search-input"
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

class PromotionsPromoters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dummy: true
      // startDate: "",
      // endDate: "",
      // promoters: props.input,
      // locations: [],
      // users: [],
    };
  }

  Row({ data, index, style, outerElementType }) {
    function getPromoterNames() {
      return data.users.map((item, index) => (
        <MenuItem key={index} value={item.id} name={item.name}>
          {item.name + " " + item.email}
        </MenuItem>
      ));
    }
    return (
      <div style={style}>
        Row {index} {data.data[index].promoterName}
        <FormControl
          variant="outlined"
          style={{ padding: 0, margin: 0, width: 222 }}
        >
          <InputLabel>Promoter name</InputLabel>
          <Select
            value={data.data[index].promoterId}
            onChange={change => {
              var oldPromoters = data.data;
              console.log(oldPromoters[index]);
              oldPromoters[index].promoterId = change.target.value;
              data.users.forEach(function(e) {
                if (e.id === change.target.value)
                  oldPromoters[index].promoterName = e.name;
              });
              console.log(oldPromoters[index]);
              outerElementType(oldPromoters);
            }}
            input={<OutlinedInput labelWidth={123} />}
          >
            {getPromoterNames()}
          </Select>
        </FormControl>
      </div>
    );
  }


  Example(datain, users) {
    let data = { data: datain, users };
    return (
      <List
        outerElementType={outerElementType}
        height={1000}
        itemData={data}
        itemCount={datain.length}
        itemSize={80}
        width={1400}
      >
        {this.Row}
      </List>
    );
  }

  async componentDidMount() {
    // var date = new Date(Date.now());
    // date.setHours(date.getHours() + 2);
    // var users = await Utils.getAllUsers();
    // // users.push({ id: "0", name: "+ ADD new promoter", email: "" });
    // this.setState({
    //   startDate: date,
    //   endDate: date,
    //   users: users
    // });
    //
    // // Utils.log(await Utils.getAllUsers())
  }

  getTimes() {
    return Utils.getTimeOptions().map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));
  }

  getPromoterNames() {
    return this.props.users.map((item, index) => (
      <MenuItem key={index} value={item.id} name={item.name}>
        {item.name + " " + item.email}
      </MenuItem>
    ));
  }

  showAddNewPromoter(input, index) {
    //Utils.log(this.state.promoters[index].promoterName);
    // Utils.log(input.promoterName);
    if (input.promoterName.trim() === "0") return <div />;
    else
      return (
        <FormControl
          variant="outlined"
          style={{ padding: 0, margin: 0, width: 222 }}
        >
          <InputLabel>Promoter name</InputLabel>
          <Select
            value={this.props.input[index].promoterId}
            onChange={change => {
              var oldPromoters = this.props.input;
              oldPromoters[index].promoterId = change.target.value;
              this.props.users.forEach(function(e) {
                if (e.id === change.target.value)
                  oldPromoters[index].promoterName = e.name;
              });

              this.props.onChange(oldPromoters);
            }}
            input={<OutlinedInput labelWidth={123} />}
          >
            {this.getPromoterNames()}
          </Select>
        </FormControl>
      );
  }
  makeSingleRow2({ index, style }) {
    console.log(index);
    return <div>hello</div>;

    // return <div>{this.props.input[index].promoterName}</div>;
  }
  makeSingleRow({ index, key, style }) {
    return (
      <div
        key={key}
        style={{ display: "flex", flexDirection: "row", marginTop: 5 }}
      >
        {this.showAddNewPromoter(this.props.input[index], index)}
        <TextField
          style={{ color: "#374799", padding: 0, margin: 0 }}
          id="outlined-name"
          label="Start date"
          value={
            this.props.input[index].promoterStartDate ||
            new Date(Date.now()).toISOString().substr(0, 10)
          }
          onChange={change => {
            var oldPromoters = this.props.input;
            oldPromoters[index].promoterStartDate = change.target.value;
            oldPromoters[index].promoterEndDate = change.target.value;

            this.props.onChange(oldPromoters);
          }}
          type="date"
          margin="normal"
          variant="outlined"
        />
        <FormControl variant="outlined" style={{ padding: 0, margin: 0 }}>
          <InputLabel>Start time</InputLabel>
          <Select
            value={this.props.input[index].promoterStartTime || "07:00"}
            onChange={change => {
              var oldPromoters = this.props.input;
              oldPromoters[index].promoterStartTime = change.target.value;

              this.props.onChange(oldPromoters);
            }}
            input={<OutlinedInput labelWidth={73} />}
          >
            {this.getTimes()}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ padding: 0, margin: 0 }}>
          <InputLabel>End time</InputLabel>
          <Select
            value={this.props.input[index].promoterEndTime || "07:00"}
            onChange={change => {
              var oldPromoters = this.props.input;
              oldPromoters[index].promoterEndTime = change.target.value;

              this.props.onChange(oldPromoters);
            }}
            input={<OutlinedInput labelWidth={73} />}
          >
            {this.getTimes()}
          </Select>
        </FormControl>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start"
          }}
        >
          <LocationSearchInput
            value={this.props.input[index].promoterLocation.address}
            disabled={true}
            output={change => {
              var oldPromoters = this.props.input;

              oldPromoters[index].promoterLocation = change;

              this.props.onChange(oldPromoters);
              // Utils.log(change);
            }}
          />

          <Button
            style={{
              width: this.props.setWidth,
              margin: 5,
              textTransform: "none"
            }}
            disabled={index > 0 ? false : true}
            color="primary"
            onClick={() => {
              var oldPromoters = this.props.input;
              // Utils.log("oldPromoters")
              // Utils.log(oldPromoters)
              oldPromoters[index].promoterLocation =
                oldPromoters[index - 1].promoterLocation;
              //oldPromoters.splice(item.index, 1);

              // var oldLocations = this.state.locations
              // oldLocations.splice(item.index,1);
              this.props.onChange(oldPromoters);
              this.setState({ dummy: !this.state.dummy });
            }}
          >
            <AddLocationIcon />
          </Button>
        </div>
        <Button
          style={{ margin: 5 }}
          disabled={this.props.location.search === "?new"}
          variant="contained"
          color="primary"
          onClick={() => {
            // var oldPromoters = this.props.input;
            //
            // oldPromoters.splice(item.index, 1);
            //
            // // var oldLocations = this.state.locations
            // // oldLocations.splice(item.index,1);
            // this.props.onChange(oldPromoters);
            this.props.onSave(this.props.input[index]);
          }}
        >
          save
        </Button>
        <Button
          style={{ margin: 5 }}
          variant="contained"
          color="primary"
          onClick={() => {
            if (this.props.location.search === "?new") {
              var oldPromoters = this.props.input;

              oldPromoters.splice(index, 1);

              // var oldLocations = this.state.locations
              // oldLocations.splice(item.index,1);
              this.props.onChange(oldPromoters);
            } else this.props.onDelete(this.props.input[index].scheduleID);
          }}
        >
          Delete
        </Button>
      </div>
    );
  }
  // maketablerows(inputitems) {
  //   Utils.log("inputitems");
  //   Utils.log(inputitems);
  //   inputitems.forEach((z,index)=>{z.index=index})
  //   // return inputitems.map(item => {
  //   // return (this.makeSingleRow(inputitems[0]));
  //   return <List
  //     width={800}
  //     height={200}
  //     rowHeight={50}
  //     rowRenderer={this.makeSingleRow}
  //     rowCount={inputitems.length}
  //   />
  // }

  makeMapMarkers(input) {
    var output = [];
    input.forEach(function(e) {
      if (e.promoterLocation.geo) output.push(e);
    });

    if (output.length > 0)
      return output.map((item, index) => (
        <AnyReactComponent
          key={index}
          lat={item.promoterLocation.geo.lat}
          lng={item.promoterLocation.geo.lng}
          text={item.name}
        />
      ));
    else return null;
  }

  getNames(input) {
    var output = "";
    input.forEach(function(e, idx) {
      if (idx > 0) output += ", ";
      output += e.name;
    });
    return output;
  }

  render() {
    // Utils.log("this.props PROMOTERS")
    // Utils.log(this.props.location.search === "?new");
    console.log(this.props.input);
    return (
      <div style={{ width: "100%" }}>
        <br />
        {this.Example(this.props.input, this.props.users)}
        <div style={{ width: "100%", marginTop: 10 }}>
          <PromotionsMaps markers={this.props.input || []} />
        </div>
      </div>
    );
  }
}
export default withRouter(PromotionsPromoters);
