import React from "react";
import Utils from "./utils";
import { Auth } from "aws-amplify";
// import PivotTableUI from "react-pivottable/PivotTableUI";
// import PivotTable from "react-pivottable/PivotTable";
import "react-pivottable/pivottable.css";
// import TextField from "@material-ui/core/TextField";
// import Button from "@material-ui/core/Button";
// import EditIcon from "@material-ui/icons/Edit";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import Script from "react-load-script";
// import TableRenderers from "react-pivottable/TableRenderers";
// import createPlotlyComponent from "react-plotly.js/factory";
// import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
// import { CSVLink } from "react-csv";
// create Plotly React component via dependency injection

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userlabel: "",
      responses: [],
      config: "",
      zaggregatorName: "",
      zcolOrder: "",
      ztitle: "Graph",
      zcols: [],
      zrendererName: "",
      zrowOrder: "",
      zrows: [],
      zvalueFilter: {},
      showDesigner: false,
      clients: [],
      client: "",
      admintype: "",
      scriptLoaded: false,
      plot: {},

      PlotlyRenderers: {}
    };
  }

  // async getAllpromotions(idlist) {
  //   // Utils.log(idlist);
  //   var s = await Promise.all(idlist.map(Utils.getsummaryByID));
  //   // Utils.log(s);
  //   return s;
  // }

  componentDidMount() {

     this.test();
  }

  async test() {
    let p = await Auth.currentAuthenticatedUser();
    let t = await Utils.getUser(p.username);
    let cl = await Utils.getAllClients();
    var theclients = []
    cl.forEach(a=>{
      if (a.label === t.label) theclients.push(a)
    })
    var reportslist=[]
    theclients.forEach(a=>{
      if (a.reportconfig !== null) reportslist.push(a)
    })
    // Utils.log(this.props.location);
    var thisreport = this.props.location.pathname.replace("/reportBI/","")
    // Utils.log(thisreport);
    var thisembed = ""
    theclients.forEach(a=>{
      if (a.clientname === thisreport) thisembed = a.reportconfig
    })

    var newembed = '<iframe width="100%" height="800" title="bi"'+thisembed.substr(thisembed.indexOf("src"))
    // Utils.log(thisembed);
    // Utils.log(newembed);

    this.setState({ userlabel: t.label, clients: cl, admintype: t.type ,thisembed:newembed });

  }


  render() {
    // console.log(window);
    // console.log(this.state.scriptLoaded);
    // <Script
    //   url="https://cdn.plot.ly/plotly-latest.min.js"
    //   onCreate={this.handleScriptCreate.bind(this)}
    //   onError={this.handleScriptError.bind(this)}
    //   onLoad={this.handleScriptLoad.bind(this)}
    //   />
    return (
      <div style={{ color: "#666", margin: 10 }}>
        <style>{'body { background-color: "#eeeeee"; }'}</style>
        <h2>Reports</h2>
        <div

        >
          <h3>{this.props.location.pathname.replace("/reportBI/","")}</h3>
          <div dangerouslySetInnerHTML={{__html: this.state.thisembed}}/>


        </div>
      </div>
    );
  }
}
export default Report;
