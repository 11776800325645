import React from "react";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { isMobile } from "react-device-detect";
import * as Sentry from "@sentry/browser";
import Utils from "./utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import ArrowIcon from "@material-ui/icons/UnfoldMore";

class SignOffSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promotions: [],
      toggelReverse:false,
      sorter: "username",
      filterOptions: {
        promolistenddate: new Date("2021-01-01T00:00:00"),
        promoliststartdate: new Date("2019-07-01T02:00:00"),
        firstdate: new Date("2019-07-01T02:00:00"),
        lastdate: new Date("2021-01-01T00:00:00"),
      }
    };
  }

  async initialise(startdate, enddate,fdt,ldt) {
    let p = await Auth.currentAuthenticatedUser();
    Sentry.setUser({ email: p.attributes.email });
    let t = await Utils.getUser(p.username);
    var APIfilter = {
      label: { eq: t.label },
      enddate: { le: enddate },
      startdate: { ge: startdate },
      cdates:{fdt:fdt,ldt:ldt}
    };

    if (t.access !== "all") APIfilter.client = { id: { eq: t.access } };
    this.setState({ APIfilter: APIfilter });
    // Utils.log("got here");
    // Utils.log(APIfilter)
    var promotions = await Utils.getAllPromotions2(APIfilter);
    // Utils.log("got here");
    // Utils.log(promotions);
    promotions.forEach(a => {
      a.promotionScheduler.items.forEach(b => {
        b.username = b.user.name;
        b.locs = b.location.address || "";
      });
    });

    this.setState({
      promotions: promotions
    });
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().catch(() => {
      if (isMobile) this.props.history.push("/signinmobile");
      else this.props.history.push("/signin");
    });
    this.initialise(
      this.state.filterOptions.promoliststartdate,
      this.state.filterOptions.promolistenddate,
      this.state.filterOptions.firstdate,
      this.state.filterOptions.lastdate,
    );
  }

  render() {
    return (
      <div style={{ color: "#666" }}>
          <h1>Signoff Summary</h1>
        {this.state.promotions.map((item, index) => (
          <div key={index}>
            <Paper style={{ margin: 10, padding: 10 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 20,
                    marginLeft: 20
                  }}
                >
                  <Avatar
                    aria-label="Recipe"
                    style={{
                      backgroundColor: JSON.parse(item.colours).activebackground
                    }}
                  >
                    {item.client.clientname.substr(0, 1).toUpperCase()}
                  </Avatar>
                </div>
                <div>
                  <b>Promotion Name:</b>
                  {" " + item.promotionName} <br />
                  <b>Client Name:</b>
                  {" " + item.client.clientname} <br />
                  <b>Start date:</b>
                  {" " + item.startdate.toISOString().substr(0, 10)} <br />
                  <b>End date:</b>
                  {" " + item.enddate.toISOString().substr(0, 10)} <br />
                </div>
              </div>
              <Paper style={{ margin: 10, padding: 10 }}>
                <Table>
                  <TableHead>
                    <TableRow >
                      <TableCell style={{ fontSize: 16 , width: "12%"}}>
                        Promoter name{" "}
                        <ArrowIcon
                          onClick={() => this.setState({ sorter: "username" ,toggelReverse:!this.state.toggelReverse})}
                        />
                      </TableCell>
                      <TableCell style={{ fontSize: 16 , width: "12%" }}>
                        Start date
                        <ArrowIcon
                          onClick={() => this.setState({ sorter: "startdate"  ,toggelReverse:!this.state.toggelReverse})}
                        />
                      </TableCell>
                      <TableCell style={{ fontSize: 16  , width: "12%"}}>
                        End date
                        <ArrowIcon
                          onClick={() => this.setState({ sorter: "enddate" ,toggelReverse:!this.state.toggelReverse })}
                        />
                      </TableCell>
                      <TableCell style={{ fontSize: 16 , width: "12%" }}>
                        Location
                        <ArrowIcon
                          onClick={() => this.setState({ sorter: "locs" ,toggelReverse:!this.state.toggelReverse })}
                        />
                      </TableCell>
                      <TableCell style={{ fontSize: 16  , width: "12%"}}>
                        Manager name
                        <ArrowIcon
                          onClick={() =>
                            this.setState({ sorter: "signoffmanagername"  ,toggelReverse:!this.state.toggelReverse})
                          }
                        />
                      </TableCell>
                      <TableCell style={{ fontSize: 16  , width: "12%"}}>
                        Manager rating
                        <ArrowIcon
                          onClick={() =>
                            this.setState({ sorter: "signoffmanagerrating" ,toggelReverse:!this.state.toggelReverse })
                          }
                        />
                      </TableCell>
                      <TableCell style={{ fontSize: 16  , width: "12%"}}>
                        Manager comments
                      </TableCell>
                      <TableCell style={{ fontSize: 16  , width: "12%"}}>Contact</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.promotionScheduler.items
                      .sort((a, b) => {
                        if (a[this.state.sorter] < b[this.state.sorter]) {
                          return -1*(this.state.toggelReverse?1:-1);
                        }
                        if (a[this.state.sorter] > b[this.state.sorter]) {
                          return 1*(this.state.toggelReverse?1:-1);
                        }

                        // names must be equal
                        return 0;
                      })
                      .map((item2, index2) => (
                        <TableRow key={index2}>
                          <TableCell>{item2.user.name}</TableCell>
                          <TableCell>
                            {item2.startdate.toISOString().substr(0, 10)}
                          </TableCell>
                          <TableCell>
                            {item2.enddate.toISOString().substr(0, 10)}
                          </TableCell>
                          <TableCell>
                            {item2.location.address.split(",")[0]}
                          </TableCell>
                          <TableCell>{item2.signoffmanagername}</TableCell>
                          <TableCell>{item2.signoffmanagerrating}</TableCell>
                          <TableCell>{item2.signoffmanagercomments}</TableCell>
                          <TableCell>
                            {item2.signoffmanagerphonenumber === null
                              ? ""
                              : item2.signoffmanagerphonenumber +
                                  " " +
                                  item2.signoffmanageremail ===
                                null
                              ? ""
                              : item2.signoffmanageremail}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Paper>
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(SignOffSummary);
