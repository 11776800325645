import React from "react";

import { Cell, Tooltip, PieChart, Pie, ResponsiveContainer } from "recharts";
// import { isMobile } from "react-device-detect";
const COLORS = ["#F44336", "#8BC34A", "#FFC107"];

class GraphSales extends React.Component {
  render() {
    
    return (
      <div
        style={{

          width: this.props.width,
          minWidth: this.props.minWidth
        }}
      >
      <div style={{display: 'flex', justifyContent: 'center' , color: "#666"}}><b>{this.props.title}</b></div>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie dataKey="value" data={this.props.data}>
              {this.props.data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip
              itemStyle={{ color: "#666" }}
              contentStyle={{ color: "#666" }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
export default GraphSales;
