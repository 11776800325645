import "whatwg-fetch";
import React from "react";
import Button from "@material-ui/core/Button";
import * as Sentry from "@sentry/browser";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Utils from "./utils";

import {
  mobileVendor,
  mobileModel,
  fullBrowserVersion,
  osVersion,
  osName,
  isIOS
} from "react-device-detect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import * as serviceWorker from "./serviceWorker";

class Compat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geo: false,
      camera: false,
      netspeed: 0,
      bug: ""
    };
  }
  componentDidMount() {
    if (!isIOS) {
      this.setState({ netspeed: navigator.connection.downlink });
      navigator.permissions.query({ name: "geolocation" }).then(a => {
        if (a.state === "granted") this.setState({ geo: true });
        // console.log(a);
      });
      navigator.permissions.query({ name: "camera" }).then(a => {
        // console.log(a);
        if (a.state === "granted") this.setState({ camera: true });
      });
    }
  }

  async doCamera() {
    let s = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true
    });
    // console.log(s);
    if (s.active === true) this.setState({ camera: true });

    // navigator.mediaDevices
    //   .getUserMedia({ audio: false, video: true })
    // .then(function(stream) {
    //   // this.setState({ camera: true });
    //   console.log("Got stream, time diff :", Date.now() - now);
    //   //
    // })
    // .catch(function(err) {
    //   console.log(err);
    //   console.log("GUM failed with error, time diff: ", Date.now() - now);
    // });
  }

  runRefresh() {
    setTimeout(function() {
      window.location = window.location.href.replace("/compat", "");
      // console.log("window.location");
      // console.log(window.location);
      window.location.reload(true);
    }, 100);
  }

  getbug() {
    Sentry.captureMessage("Something went wrong");
  }

  render() {
    let appVersion = require("./version.json").version;

    return (
      <div style={{ margin: 5, color: "#666"}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Test</TableCell>
              <TableCell>Result</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>Promoter.Plus app version </TableCell>
              <TableCell>{appVersion}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Device OS Version</TableCell>
              <TableCell>{osVersion}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Device Make</TableCell>
              <TableCell>{mobileVendor}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Device Model</TableCell>
              <TableCell>{mobileModel}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Browser</TableCell>
              <TableCell>{fullBrowserVersion}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Device OS</TableCell>
              <TableCell>{osName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Device OS Version</TableCell>
              <TableCell>{osVersion}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Internet</TableCell>
              <TableCell>{navigator.onLine ? "Online" : "Offline"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Internet down speed</TableCell>
              <TableCell>
                {Utils.isFastInternet() ? "Good" : "Weak signal (retry in 5min)"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GPS</TableCell>
              <TableCell>{!this.state.geo ? "Not Active" : "Active"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>GPS test</TableCell>
              <TableCell>
                {" "}
                <Button
                  style={{
                    margin: 5,

                    textTransform: "none",
                    display: "flex",
                    justifyContent: "center"
                  }}
                  disabled={this.state.geo}
                  color="primary"
                  variant="contained"
                  onClick={e => {
                    window.navigator.geolocation.getCurrentPosition(success => {
                      console.log(success);
                      this.setState({ geo: true });
                    });
                  }}
                >
                  Test GPS
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Camera</TableCell>
              <TableCell>
                {!this.state.camera ? "Not Active" : "Active"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Camera Test{" "}
                {isIOS ? " (camera only available in safari)" : ""}
              </TableCell>
              <TableCell>
                <Button
                  style={{
                    margin: 5,

                    textTransform: "none",
                    display: "flex",
                    justifyContent: "center"
                  }}
                  disabled={this.state.camera}
                  color="primary"
                  variant="contained"
                  onClick={e => {
                    this.doCamera();
                  }}
                >
                  Test camera
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <h2>Result</h2>
        <div>
          {isIOS ? "Please use safari on iphone. Do not use private mode.  If signal becomes weak, use your phone's build-in camera to take pictures and upload later" : ""} <br />
          {this.state.netspeed < 1
            ? "If internet signal is weak, switch to airplane mode and sync when you have good signal"
            : "Internet speed is good"}
          <br />
          <br />
          {this.state.camera
            ? "Camera & gallery can be used for photos"
            : "Camera unavailable, use gallery to upload pictures."}
          <br />
          <br />
          {this.state.geo
            ? "GPS can be sent during promotion"
            : "GPS unavailable"}
          <br />
          <br />
            {isIOS ? <div>
              If your GPS fails to activate in the test, you can try these options: <br/>
            1) Home>Settings>Privacy>Location Services> turn on location services<br/>
          2) Home>Settings>Privacy>Location Services>Safari Websites> change to 'while using the app' <br/>
        3) Or, in Safari, click the share icon, click Request Desktop Site. Grant location access. Go back to the promtoer website to load the mobile site again.
            </div>:""}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
        <h2>Submit bug report</h2>
          <TextField
            id="outlined-name"
            label="Bug report"
            value={this.state.bug}
            onChange={change => {
              this.setState({ bug: change.target.value });
            }}
            multiline

            margin="normal"
            variant="outlined"
          />

          <Button
            style={{
              margin: 5,

              textTransform: "none",
              display: "flex",
              justifyContent: "center"
            }}
            color="primary"
            variant="contained"
            onClick={e => {
              var fullreport = "bugreport\n"+this.state.bug+"\n\n\n"
              fullreport +="Telemetry readout\n"
              fullreport +="Promoter.Plus app version:"+appVersion+"\n"
              fullreport +="Device OS Version:"+osVersion+"\n"
              fullreport +="Device Make:"+mobileVendor+"\n"
              fullreport +="Device Model:"+mobileModel+"\n"
              fullreport +="Browser:"+fullBrowserVersion+"\n"
              fullreport +="Device OS:"+osName+"\n"
              fullreport +="Device OS Version:"+osVersion+"\n"
              fullreport +="Internet:"+navigator.onLine+"\n"
              fullreport +="Internet down speed:"+isIOS ? "Undetermined in iphones\n" : this.state.netspeed+"\n"
              fullreport +="GPS:"+this.state.geo+"\n"
              fullreport +="Camera:"+this.state.camera+"\n"

              Sentry.captureMessage(fullreport);
              this.setState({ bug: "Sent. Thank you." });
            }}
          >
            Submit bug report
          </Button>

          <Button
            style={{
              margin: 5,

              textTransform: "none",
              display: "flex",
              justifyContent: "center"
            }}
            color="primary"
            variant="contained"
            onClick={e => {
              if (!isIOS) {
                e.preventDefault();
                window.location.reload(true);
              } else {
                this.runRefresh();
                // console.log("asd")
                // var a = window.location.href;
                // window.location.reload(true);
                // window.location =  window.location.href;
                // window.location.reload(true);
                // document.location.reload(true);
              }
            }}
          >
            Update app to latest version{" "}

          </Button>

            <Button
              style={{
                margin: 5,

                textTransform: "none",
                display: "flex",
                justifyContent: "center"
              }}
              color="primary"
              variant="contained"
              onClick={e => {
                this.props.history.push("/");
              }}
            >
              Back
            </Button>

        </div>
      </div>
    );
  }
}

export default withRouter(Compat);
