import React from "react";
import Button from "@material-ui/core/Button";
import Utils from "./utils";
import { Auth } from "aws-amplify";
import aws_exports from "./aws-exports"; // if you are using Amplify CLI
import Amplify from "aws-amplify";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
// import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import Snackbar from "@material-ui/core/Snackbar";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddUserIcon from "@material-ui/icons/PersonAdd";
// import { CognitoUser } from "amazon-cognito-identity-js";
// import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
// import MenuItem from "@material-ui/core/MenuItem";

Amplify.configure(aws_exports);
class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = address => {
    // console.log(address)
    this.setState({ address });
    this.props.output({ address: address });
  };

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.output({ address: address, geo: latLng });
        // Utils.log("Success", latLng);
      })
      .catch(error => console.error("Error", error));
  };

  componentDidMount() {
    this.setState({ address: this.props.value });
  }

  render() {
    return (
      <PlacesAutocomplete
        value={typeof this.props.value === "undefined" ? "" : this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          componentRestrictions: { country: "za" }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <TextField
              style={{ width: 400 }}
              variant="outlined"
              label="Location"
              {...getInputProps({
                placeholder: "",
                className: "location-search-input"
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5,
          marginRight: 10
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}

class InviteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: "",
      username: "",
      name: "",
      allclients: [],
      userlabel: "",
      allLabels: [],
      type: "",
      searchnames: "",
      pwd: "promoter",
      status: "",
      allusers: [],
      promocode: "",
      disablelabel: false,
      promotername: ""
    };
  }

  async updateList(allclients) {
    let s = await Utils.getAllUsers({ name: { notContains: "~" } });
    // Utils.log("all users");
    // Utils.log(s);
    // Utils.log(this.state.userlabel);
    var mylabel = this.state.userlabel;
    var s2 = [];
    s.forEach(function(e) {

      if (e.homelocation !== null) {
        e.homelocation = JSON.parse(e.homelocation);}
        else e.homelocation = {address:""}
      allclients.forEach(function(c) {
        if (e.access === c.id) e.access = c.clientname;
      });
    });
    s.forEach(function(e) {
      if (e.label === mylabel || e.label === "All") s2.push(e);
    });

    this.setState({ allusers: s2 });
  }

  async doInvite(username, password, type, clients, name, label) {
    if (clients === "") clients = "all";
    try {
      let a = await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          email: username // optional
        }
      });
      this.setState({ status: "User created, activating." });

      if (type === "Admin") clients = "all";
      Auth.forgotPassword(username);
      // Utils.log(a);
      await Utils.createUser({
        id: a.userSub,
        name: name,
        email: username,
        type: type,
        clients: clients,
        label: label
      });
      this.setState({
        clients: "",
        username: "",
        name: "",
        type: "",
        pwd: "",
        status: "Invited",
        userlabel: ""
      });

      this.updateList(this.state.allclients);
    } catch (err) {
      Utils.log(err);
      this.setState({ status: "Error creating account. User exists." });
    }
  }

  async doPromoterInvite(promotername, username, label) {
    try {
      let a = await Auth.signUp({
        username: username,
        password: username,
        attributes: {
          email: username // optional
        }
      });
      this.setState({ status: "User created, activating." });
      await Utils.createUser({
        id: a.userSub,
        name: promotername,
        email: username,
        type: "Promoter",
        clients: "all",
        label: label
      });
      this.setState({
        promotername: "",
        promocode: "",
        status: "Promoter created"
      });
      this.updateList(this.state.allclients);
    } catch (err) {
      Utils.log(err);
      this.setState({ status: "Error creating account. Promoter exists." });
    }
  }

  async componentDidMount() {
    let p = await Auth.currentAuthenticatedUser();
    // Utils.log(s);
    let t = await Utils.getUser(p.username);
    let cl = await Utils.getAllClients();
    let ll = await Utils.getAllLabels();
    this.setState({ allclients: cl, allLabels: ll });
    if (t.label !== "All")
      this.setState({ userlabel: t.label, disablelabel: true });

    // Utils.log(t);
    var admin = false;
    if (t.type === "Admin") admin = true;
    if (!admin) this.props.history.push("/");
    this.updateList(cl);
  }

  async tryDelete(input) {
    // Utils.log(input);
    // console.log(window.location.href);
    this.setState({ status: "Deleting. Steps 1 of 2." });
    var pool = "eu-west-1_flc5tJPnU";
    if (window.location.href === "https://promoter.plus/inviteuser")
      pool = "eu-west-1_E90LtdumO";
    var userid = input.cognitoid;
    var fet = await fetch(
      "https://1krl0jzrfk.execute-api.eu-west-1.amazonaws.com/production/deleteuser?pool=" +
        pool +
        "&userid=" +
        userid,
      {
        credentials: "same-origin",
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        cors: "true",
        headers: {
          "Content-Type": "application/json"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    );
    var response = await fet.json();
    // Utils.log(response);
    if (response.body === "success") {
      this.setState({ status: "Step 1 success. Steps 2 of 2." });
      await Utils.deleteuser(input.id);
      this.setState({ status: "Success" });
    }
    if (response.errorMessage === "err") {
      //await Utils.deleteuser(input.id);
      this.setState({ status: "Step 1 fail. Step 2 of 2" });
      await Utils.deleteuser(input.id);
    }

    this.updateList(this.state.allclients);
    this.setState({ status: "" });
  }
  async savelocation(item) {
    let itemclean = {
      id: item.id,
      homelocation: JSON.stringify(item.homelocation)
    };
    await Utils.updateUserlocation(itemclean);
    this.doSnackBar("Saved")
  }
  maketablerows(input) {
    input = input.sort(function(a, b) {
      var x = a.name;
      var y = b.name;

      if (typeof x == "string") {
        x = ("" + x).toLowerCase();
      }
      if (typeof y == "string") {
        y = ("" + y).toLowerCase();
      }

      return x < y ? -1 : x > y ? 1 : 0;
    });
    // Utils.log(input);
    return input.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell style={{ fontSize: 20 }}>{item.name}</TableCell>
          <TableCell style={{ fontSize: 20 }}>{item.email}</TableCell>
          <TableCell style={{ fontSize: 20 }}>{item.type}</TableCell>
          <TableCell style={{ fontSize: 20 }}>{item.access}</TableCell>

          <TableCell style={{ fontSize: 20 }}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <LocationSearchInput
              value={item.homelocation.address || ""}
              disabled={true}
              output={change => {
                let allusers = this.state.allusers;
                allusers.forEach(z => {
                  if (z.id === item.id) z.homelocation = change;
                });

                this.setState({ allusers });
              }}
            />
            <Button
              style={{ alignSelf: "center" }}
              variant="contained"
              color="primary"
              onClick={() => {
                this.savelocation(item);
              }}
            >
              Save
            </Button>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  }
  doSnackBar(input) {
    this.setState({ statusbar: true, statusbartext: input });
  }
  render() {
    return (
      <div style={{ color: "#666", margin: 10 }}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.statusbar}
        autoHideDuration={3000}
        onClose={() => this.setState({ statusbar: false })}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{this.state.statusbartext}</span>}
      />
        <Paper style={{ margin: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: 10,
              marginRight: 10
            }}
          >
            <h1
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 10
              }}
            >
              <PIcon
                icon={<AddUserIcon style={{ fontSize: 37 }} />}
                size="40px"
                colour="#f52352"
              />
              Users
            </h1>
          </div>
        </Paper>

        <h4>Add promoter</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            style={{ color: "#374799", margin: 0 }}
            id="outlined-name"
            multiline
            label="Promoter name"
            value={this.state.promotername}
            onChange={change => {
              this.setState({ promotername: change.target.value });
            }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            style={{ color: "#374799", margin: 0 }}
            id="outlined-name"
            multiline
            label="Promoter code (no spaces)"
            value={this.state.promocode}
            onChange={change => {
              this.setState({ promocode: change.target.value });
            }}
            margin="normal"
            variant="outlined"
          />
          <Button
            style={{ marginLeft: 20, alignSelf: "center" }}
            variant="contained"
            color="primary"
            onClick={() => {
              this.doPromoterInvite(
                this.state.promotername,
                this.state.promocode.toLowerCase().trim() + "@promoterplus.com",
                this.state.userlabel
              );
            }}
          >
            Invite Promoter
          </Button>
        </div>
        <br />
        {this.state.status}
        <br />
        <TextField
          style={{ color: "#374799", margin: 0 }}
          id="outlined-name"
          multiline
          label="Search Users"
          value={this.state.searchnames}
          onChange={change => {
            this.setState({ searchnames: change.target.value });
          }}
          margin="normal"
          variant="outlined"
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontSize: 30 }}>Name</TableCell>
              <TableCell style={{ fontSize: 30 }}>
                Promoter Code (Email)
              </TableCell>
              <TableCell style={{ fontSize: 30 }}>User type</TableCell>
              <TableCell style={{ fontSize: 30 }}>Client</TableCell>

              <TableCell style={{ fontSize: 30 }}>Home location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.maketablerows(
              this.state.allusers.filter(
                z =>
                  z.name
                    .toLowerCase()
                    .indexOf(this.state.searchnames.toLowerCase()) > -1
              ) || null
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default InviteUser;
