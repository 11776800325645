// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:50aa7ab1-61fd-4cf2-a0f3-1e2038f46fb4",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_flc5tJPnU",
    "aws_user_pools_web_client_id": "1b4cc6aoiaqfnn1oflcai2l99r",
    "oauth": {},
    "aws_user_files_s3_bucket": "promoterplus-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://3rfrxtsmizdh3kr6pkfri3n2li.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
