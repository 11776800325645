import React from "react";
class Downloader extends React.Component {
  render() {
    console.log(React.contex)
    return (
      <div><button onClick={()=>{console.log("as")}}>check </button></div>
    );
  }
}
export default Downloader
