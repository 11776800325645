import React from "react";



class Summary extends React.Component {

  render() {
    return <div>  summary page</div>;
  }
}
export default Summary;
