import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import Paper from '@material-ui/core/Paper';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


export default class ConsumableChipFixed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
  }

  FixNullsConsumeSection(input) {
      if (typeof input === "string") input = JSON.parse(input)
    if (input === null)
      return { fixedAssetChips: [], consumableAssetChips: [] };
    else {
      if (typeof input.fixedAssetChips === "string") input.fixedAssetChips = JSON.parse(input.fixedAssetChips)
      if (typeof input.consumableAssetChips === "string") input.consumableAssetChips = JSON.parse(input.consumableAssetChips)
      return input};
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center"
        }}
      >
        <h4 style={{ marginRight: 10 }}>Consumables</h4>
        <TextField
          style={{ color: "#374799", marginRight: 10 }}
          id="outlined-name"
          label="Add consumable item"
          value={this.state.text}
          onChange={change => {
            this.setState({
              text: change.target.value
            });
          }}
          margin="normal"
          variant="outlined"
        />
        <Button
          onClick={() => {
            var allclients = this.props.input;

            allclients.forEach(z => {
              if (z.id === this.props.id) {
                z.consumablesetup = this.FixNullsConsumeSection(
                  z.consumablesetup
                );
                z.consumablesetup.consumableAssetChips.push({item:this.state.text.trim(), pattern:1});
                z.consumablesetup.consumableAssetChips = [...new Set(z.consumablesetup.consumableAssetChips)]
              }
            });
            this.setState({text:""})
            this.props.onChange(allclients);
          }}
        >
          <AddIcon />
        </Button>

        {this.FixNullsConsumeSection(
          this.props.input.filter(z => z.id === this.props.id)[0]
            .consumablesetup
        ).consumableAssetChips.map((e, i) => (
          <Paper key={i} style={{display: 'flex', marginRight: 10,flexDirection: 'column' ,backgroundColor: "#ddd"}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}> {e.item} <HighlightOffIcon onClick={()=>{
            var allclients = this.props.input;
            allclients.forEach(z => {
              if (z.id === this.props.id) {
                z.consumablesetup.consumableAssetChips.splice(i, 1);
              }
            });
            this.props.onChange(allclients);
          }}/></div>
          <TextField
          style={{ width: 100, margin: 2}}
          id="outlined-name"
          label="Pattern"
          value={e.pattern}
          onChange={change => {
            var allclients = this.props.input;
            allclients.forEach(z => {
              if (z.id === this.props.id) {
                z.consumablesetup.consumableAssetChips[i].pattern=change.target.value
              }
            });
            this.props.onChange(allclients);
          }}
          margin="normal"
          variant="filled"
          />
          </Paper>

        ))}
      </div>
    );
  }
}
