import React from "react";
import Utils from "./utils";
import Gallery from "react-grid-gallery";

import { Auth } from "aws-amplify";
import "react-pivottable/pivottable.css";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Dash2Filters from "./dash2filters";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import ArrowIcon from "@material-ui/icons/UnfoldMore";
import DownIcon from "@material-ui/icons/ExpandMore";
import UpIcon from "@material-ui/icons/ExpandLess";
import * as Sentry from "@sentry/browser";
import ReportIcon from "@material-ui/icons/Dashboard";
import zip from "jszip";
class PIcon extends React.Component {
  render() {
    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: this.props.colour,
          color: "#000",
          borderRadius: 5,
          marginRight: 10
        }}
      >
        {this.props.icon}
      </div>
    );
  }
}

class Report extends React.Component {
  constructor(props) {
    super(props);
    var promoenddate = new Date(Date.now()).toISOString().split("T")[0];
    var enddate = new Date(promoenddate + "T23:59:59");
    var startdate = new Date(promoenddate + "T00:00:00");
    startdate.setHours(startdate.getHours() + 2);
    enddate.setHours(enddate.getHours() + 2);
    this.state = {
      userlabel: "",
      imgs: [],
      clients: [],
      admintype: "",
      photodlstatus: "",
      reportslist: [],
      promotionsFiltered: [],
      section: 1,
      showFilter: false,
      promotions: [],
      toggelReverse: false,
      sorter: "username",
      filterOptions: {
        promolistenddate: new Date("2021-01-01T00:00:00"),
        promoliststartdate: new Date("2019-07-01T02:00:00"),
        products: [],
        promoters: [],
        promotions: [],
        regions: [],
        clients: [],
        promotersSelected: [],
        productsSelected: [],
        markers: [],
        regionsSelected: [],
        clientsSelected: [],
        firstdate: startdate,
        lastdate: enddate
      }
    };
  }
  async getpics() {
    var zipper = new zip();
    // console.log(this.state.promotionsFiltered)
    // var filestructure=[]
    var geturis = [];

    this.state.promotionsFiltered.forEach(function(a) {
      var folder = zipper.folder(a.client.clientname);
      a.promotionScheduler.items.forEach(function(b) {
        var loc = folder.folder(b.location.address);
        var username = loc.folder(b.user.name);
        b.promotionPhotos.items.forEach(function(c) {
          username.folder(c.phototype);
          geturis.push({
            file:
              a.client.clientname +
              "/" +
              b.location.address +
              "/" +
              b.user.name +
              "/" +
              c.phototype +
              "/" +
              c.timestamp +
              "." +
              c.filename.split(".")[1],
            filename: c.filename
          });
        });
      });
    });

    for (var i = 0; i < geturis.length; i++) {
      var fname = await Utils.getPicFromS3(geturis[i].filename);
      // console.log(fname)
      var a = await fetch(fname);
      var b = await a.blob();
      this.setState({ photodlstatus: i + 1 + "/" + geturis.length });
      // console.log(geturis[0])
      zipper.file(geturis[i].file, b);
    }

    zipper
      .generateAsync({ type: "blob" })
      // console.log(zipper);import Eye from "@material-ui/icons/RemoveRedEye";
      .then(function(content) {
        // see FileSaver.js
        // saveAs(content, "example.zip");
        var data = new Blob([content], { type: "application/zip" });
        var myURL = window.URL || window.webkitURL;
        var csvURL = myURL.createObjectURL(data);
        var tempLink = document.createElement("a");
        document.body.appendChild(tempLink);

        tempLink.href = csvURL;
        tempLink.setAttribute("download", "filename.zip");
        tempLink.click();
        setTimeout(function() {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(csvURL);
        }, 100);
      });
  }
  componentDidMount() {
    this.test();
  }

  async test() {
    let p = await Auth.currentAuthenticatedUser();
    let t = await Utils.getUser(p.username);
    let cl = await Utils.getAllClients();
    var theclients = [];
    cl.forEach(a => {
      if (a.label === t.label) theclients.push(a);
    });
    var reportslist = [];
    theclients.forEach(a => {
      if (a.reportconfig !== null) reportslist.push(a);
    });
    if (t.access !== "all") {
      var list = [];
      reportslist.forEach(a => {
        if (a.id === t.access) list.push(a);
      });
      reportslist = list;
    }
    this.setState({
      userlabel: t.label,
      clients: theclients,
      admintype: t.type,
      reportslist: reportslist
    });
    this.initialise(
      this.state.filterOptions.promoliststartdate,
      this.state.filterOptions.promolistenddate,
      this.state.filterOptions.firstdate,
      this.state.filterOptions.lastdate
    );
  }
  async initialise(startdate, enddate, fdt, ldt) {
    let p = await Auth.currentAuthenticatedUser();
    Sentry.setUser({ email: p.attributes.email });
    let t = await Utils.getUser(p.username);
    var APIfilter = {
      label: { eq: t.label },
      enddate: { le: enddate },
      startdate: { ge: startdate },
      cdates: { fdt: fdt, ldt: ldt }
    };

    if (t.access !== "all") APIfilter.client = { id: { eq: t.access } };
    this.setState({ APIfilter: APIfilter });
    var promotions = await Utils.getAllPromotions2(APIfilter);
    var filteroptions = this.getFilterOptions(promotions);
    var temppromo = JSON.parse(JSON.stringify(promotions));
    var results = this.applyFilters(temppromo, filteroptions);
    promotions.forEach(a => {
      a.promotionScheduler.items.forEach(b => {
        if (b.user !== null) {
          b.username = b.user.name;
          b.locs = b.location.address || "";
        }
      });
    });

    this.setState({
      promotions: promotions,
      promotionsFiltered: results,
      filterOptions: filteroptions
    });
  }
  applyFilters(input, filters) {
    var output = [];
    input = JSON.parse(JSON.stringify(input));
    input.forEach(function(a) {
      filters.clientsSelected.forEach(function(b) {
        filters.promotions.forEach(function(c) {
          if ((c.id === a.id) & c.selected && b === a.client.clientname)
            output.push(a);
        });
      });
    });

    output.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        // console.log(b.gps)
        filters.regionsSelected.forEach(function(c) {
          if (b.location.region === c) newitems.push(b);
        });
      });
      a.promotionScheduler.items = newitems;
    });
    output.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        filters.promotersSelected.forEach(function(c) {
          if (b.user !== null && b.user.name === c) newitems.push(b);
        });
      });
      a.promotionScheduler.items = newitems;
    });
    output.forEach(function(a) {
      a.promotionScheduler.items.forEach(function(b) {
        if (b.gps !== null) {
          if (typeof b.gps.date === "string") {
            b.gps.date = new Date(b.gps.date);
            b.gps.date.setHours(b.gps.date.getHours() + 2);
          }
        }
      });
    });
    output.forEach(function(a) {
      a.promotionScheduler.items.forEach(function(b) {
        var newitems = [];
        b.responses.items.forEach(function(c) {
          c.timestamp = new Date(c.timestamp);
          c.timestamp.setHours(c.timestamp.getHours() - 2);
          c.response.forEach(function(d) {
            filters.productsSelected.forEach(function(e) {
              if (d.question === "product" && d.response === e)
                newitems.push(c);
            });
          });
        });
        b.responses.items = newitems;
      });
    });
    var o2 = JSON.parse(JSON.stringify(output));
    o2.forEach(function(a) {
      var newitems = [];
      a.promotionScheduler.items.forEach(function(b) {
        b.startdate = new Date(b.startdate);
        if (b.startdate > filters.firstdate && b.startdate < filters.lastdate)
          newitems.push(b);
      });
      a.promotionScheduler.items = newitems;
    });
    this.setimages(JSON.parse(JSON.stringify(o2)));
    // console.log(o2);
    return JSON.parse(JSON.stringify(o2));
  }
  getFilterOptions(promos) {
    var promoters = [];
    var promotions = [];
    var products = [];
    var regions = [];
    var clients = [];
    promos.forEach(function(a) {
      clients.push(a.client.clientname);
      products = products.concat(a.products);
      promotions.push({
        client: a.client,
        colours: a.colours,
        selected: true,
        promotionName: a.promotionName,
        products: a.products.join(","),
        id: a.id,
        startdate: a.startdate,
        enddate: a.enddate
      });
      a.promotionScheduler.items.forEach(function(b) {
        // console.log(b)
        if (b.user !== null) {
          promoters.push(b.user.name);
          regions.push(b.location.region);
        }
      });
    });
    var oldfilterOptionsAll = this.state.filterOptions;
    oldfilterOptionsAll.promotions = promotions;
    oldfilterOptionsAll.promoters = Utils.removeDuplicates(promoters);
    oldfilterOptionsAll.products = Utils.removeDuplicates(products);
    oldfilterOptionsAll.clients = Utils.removeDuplicates(clients);
    oldfilterOptionsAll.regions = Utils.removeDuplicates(regions);
    oldfilterOptionsAll.promotersSelected = Utils.removeDuplicates(promoters);
    oldfilterOptionsAll.productsSelected = Utils.removeDuplicates(products);
    oldfilterOptionsAll.clientsSelected = Utils.removeDuplicates(clients);
    oldfilterOptionsAll.regionsSelected = Utils.removeDuplicates(regions);
    return oldfilterOptionsAll;
  }

  async setimages(input) {
    // console.log(input);
    var newpics = [];
    input.forEach(a => {
      a.promotionScheduler.items.forEach(b => {
        b.promotionPhotos.items.forEach(c => {
          newpics.push({
            filename: c.filename,
            caption: b.user.name + " at " + b.location.address.split(",")[0],
            tags: [
              { value: c.phototype, title: c.phototype },
              {
                value: b.location.address.split(",")[0],
                title: b.location.address.split(",")[0]
              },
              { value: b.user.name, title: b.user.name }
            ]
          });
        });
      });
    });

    async function getpic(imgrec) {
      let s = await Utils.getPicFromS3(imgrec.filename);
      // console.log( s)
      // let f = await fetch(s);
      // let f2 = await f.blob();
      // var buffer = Buffer.from(await f2.arrayBuffer());
      // // console.log( buffer)
      // var dimensions = sizeOf(buffer);
      // // console.log(dimensions.width, dimensions.height);
      // let f3 = URL.createObjectURL(f2);

      imgrec.src = s;
      delete imgrec.filename;
      imgrec.thumbnail = s;
      imgrec.thumbnailWidth = "100%";
      imgrec.thumbnailHeight = "100%";
      return imgrec;
    }

    var newpics2 = await Promise.all(newpics.map(getpic));
    this.setState({ imgs: newpics2 });
  }

  filterSection(input) {
    if (!input)
      return (
        <Button
          variant="contained"
          style={{ color: "#222", backgroundColor: "#1da4f4" }}
          color="primary"
          onClick={() => this.setState({ filtersection: true })}
        >
          Filter <DownIcon />
        </Button>
      );
    else
      return (
        <Button
          style={{ color: "#222", backgroundColor: "#1da4f4" }}
          variant="outlined"
          color="primary"
          onClick={() => this.setState({ filtersection: false })}
        >
          Close <UpIcon />
        </Button>
      );
  }

  render() {
    // console.log(this.state.promotions)
    // console.log(this.state.promotionsFiltered)
    return (
      <div style={{ color: "#666", margin: 10 }}>
        {" "}
        <Paper style={{ marginBottom: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: 10,
              marginRight: 10
            }}
          >
            <h1
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <PIcon
                icon={<ReportIcon style={{ fontSize: 37 }} />}
                size="40px"
                colour="#f5a623"
              />
              Reports
            </h1>
            <div>{this.filterSection(this.state.filtersection)}</div>
          </div>
        </Paper>
        {this.state.filtersection ? (
          <Dash2Filters
            expanded={this.state.filtersection}
            onExpand={input => this.setState({ filtersection: input })}
            initialOptions={this.state.filterOptions}
            filterOutput={change => {
              var original = JSON.parse(JSON.stringify(this.state.promotions));

              this.setState({
                filterOptions: change,
                promotionsFiltered: this.applyFilters(original, change)
              });
            }}
            campaignFilterOutput={change => {
              this.setState({ filterOptions: change });
              this.initialise(
                change.promoliststartdate,
                change.promolistenddate,
                change.firstdate,
                change.lastdate
              );
            }}
            goToPromotion={id => {
              this.props.history.push("/promotions?edit=" + id);
            }}
          />
        ) : (
          <div />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            backgroundColor: "#fff"
          }}
        >
          <Button
            style={{
              width: "32%",
              padding: 0,
              paddingLeft: 50,
              paddingRight: 50,
              margin: 10,
              backgroundColor: this.state.section === 1 ? "#666" : "#fff",
              color: this.state.section === 1 ? "#fff" : "#666",
              justifyContent: "center",
              display: "flex"
            }}
            onClick={() => this.setState({ section: 1 })}
          >
            <h2>Media</h2>
          </Button>
          <Button
            style={{
              padding: 0,
              paddingLeft: 50,
              paddingRight: 50,
              width: "32%",
              margin: 10,
              backgroundColor: this.state.section === 2 ? "#666" : "#fff",
              color: this.state.section === 2 ? "#fff" : "#666",
              justifyContent: "center",
              display: "flex"
            }}
            onClick={() => this.setState({ section: 2 })}
          >
            <h2>Analytics</h2>
          </Button>
          <Button
            style={{
              padding: 0,
              paddingLeft: 50,
              paddingRight: 50,
              width: "32%",
              margin: 10,
              backgroundColor: this.state.section === 3 ? "#666" : "#fff",
              color: this.state.section === 3 ? "#fff" : "#666",
              justifyContent: "center",
              display: "flex"
            }}
            onClick={() => this.setState({ section: 3 })}
          >
            <h2>Promoter feedback</h2>
          </Button>
        </div>
        {this.state.section === 1 ? (
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <div style={{ marginTop: 30, marginBottom: 30 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: 10
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "#222",
                  backgroundColor: "#1da4f4",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
                color="primary"
                onClick={() => this.getpics()}
              >
                Download {this.state.photodlstatus}
              </Button>
            </div>
            <Gallery enableImageSelection={false} images={this.state.imgs} />
          </div>
        ) : (
          <div />
        )}
        {this.state.section === 2 ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "30%" }}
          >
            {this.state.reportslist.map((item, index) => (
              <Button
                variant="contained"
                color="primary"
                key={index}
                style={{ margin: 10 }}
                onClick={() => {
                  this.props.history.push("/reportBI/" + item.clientname);
                }}
              >
                <h3>{item.clientname}</h3>
              </Button>
            ))}
          </div>
        ) : (
          <div />
        )}
        {this.state.section === 3 ? (
          <div style={{ color: "#666" }}>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              {this.state.filtersection ? (
                <Dash2Filters
                  expanded={this.state.filtersection}
                  initialOptions={this.state.filterOptions}
                  filterOutput={change => {
                    var original = JSON.parse(
                      JSON.stringify(this.state.promotions)
                    );

                    this.setState({
                      filterOptions: change,
                      promotionsFiltered: this.applyFilters(original, change)
                    });
                  }}
                  campaignFilterOutput={change => {
                    this.setState({ filterOptions: change });
                    this.initialise(
                      change.promoliststartdate,
                      change.promolistenddate,
                      change.firstdate,
                      change.lastdate
                    );
                  }}
                  goToPromotion={id => {
                    this.props.history.push("/promotions?edit=" + id);
                  }}
                />
              ) : (
                <div />
              )}
              <div style={{ marginTop: 30, marginBottom: 30 }} />
            </div>
            {this.state.promotionsFiltered.map((item, index) => (
              <div key={index}>
                <Paper style={{ margin: 10, padding: 10 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 20,
                        marginLeft: 20
                      }}
                    >
                      <Avatar
                        aria-label="Recipe"
                        style={{
                          backgroundColor: JSON.parse(item.colours)
                            .activebackground
                        }}
                      >
                        {item.client.clientname.substr(0, 1).toUpperCase()}
                      </Avatar>
                    </div>
                    <div>
                      <b>Promotion Name:</b>
                      {" " + item.promotionName} <br />
                      <b>Client Name:</b>
                      {" " + item.client.clientname} <br />
                      <b>Start date:</b>
                      {" " + item.startdate.substr(0, 10)} <br />
                      <b>End date:</b>
                      {" " + item.enddate.substr(0, 10)} <br />
                    </div>
                  </div>
                  <Paper style={{ margin: 10, padding: 10 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontSize: 16, width: "12%" }}>
                            Promoter name{" "}
                            <ArrowIcon
                              onClick={() =>
                                this.setState({
                                  sorter: "username",
                                  toggelReverse: !this.state.toggelReverse
                                })
                              }
                            />
                          </TableCell>
                          <TableCell style={{ fontSize: 16, width: "12%" }}>
                            Start date
                            <ArrowIcon
                              onClick={() =>
                                this.setState({
                                  sorter: "startdate",
                                  toggelReverse: !this.state.toggelReverse
                                })
                              }
                            />
                          </TableCell>
                          <TableCell style={{ fontSize: 16, width: "12%" }}>
                            End date
                            <ArrowIcon
                              onClick={() =>
                                this.setState({
                                  sorter: "enddate",
                                  toggelReverse: !this.state.toggelReverse
                                })
                              }
                            />
                          </TableCell>
                          <TableCell style={{ fontSize: 16, width: "12%" }}>
                            Location
                            <ArrowIcon
                              onClick={() =>
                                this.setState({
                                  sorter: "locs",
                                  toggelReverse: !this.state.toggelReverse
                                })
                              }
                            />
                          </TableCell>
                          <TableCell style={{ fontSize: 16, width: "12%" }}>
                            Manager name
                            <ArrowIcon
                              onClick={() =>
                                this.setState({
                                  sorter: "signoffmanagername",
                                  toggelReverse: !this.state.toggelReverse
                                })
                              }
                            />
                          </TableCell>
                          <TableCell style={{ fontSize: 16, width: "12%" }}>
                            Manager rating
                            <ArrowIcon
                              onClick={() =>
                                this.setState({
                                  sorter: "signoffmanagerrating",
                                  toggelReverse: !this.state.toggelReverse
                                })
                              }
                            />
                          </TableCell>
                          <TableCell style={{ fontSize: 16, width: "12%" }}>
                            Manager comments
                          </TableCell>
                          <TableCell style={{ fontSize: 16, width: "12%" }}>
                            Contact
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.promotionScheduler.items
                          .sort((a, b) => {
                            if (a[this.state.sorter] < b[this.state.sorter]) {
                              return -1 * (this.state.toggelReverse ? 1 : -1);
                            }
                            if (a[this.state.sorter] > b[this.state.sorter]) {
                              return 1 * (this.state.toggelReverse ? 1 : -1);
                            }

                            // names must be equal
                            return 0;
                          })
                          .map((item2, index2) => (
                            <TableRow key={index2}>
                              <TableCell>{item2.user.name}</TableCell>
                              <TableCell>
                                {item2.startdate.substr(0, 10)}
                              </TableCell>
                              <TableCell>
                                {item2.enddate.substr(0, 10)}
                              </TableCell>
                              <TableCell>
                                {item2.location.address.split(",")[0]}
                              </TableCell>
                              <TableCell>{item2.signoffmanagername}</TableCell>
                              <TableCell>
                                {item2.signoffmanagerrating}
                              </TableCell>
                              <TableCell>
                                {item2.signoffmanagercomments}
                              </TableCell>
                              <TableCell>
                                {item2.signoffmanagerphonenumber === null
                                  ? ""
                                  : item2.signoffmanagerphonenumber +
                                      " " +
                                      item2.signoffmanageremail ===
                                    null
                                  ? ""
                                  : item2.signoffmanageremail}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Paper>
              </div>
            ))}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
export default Report;
